import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'page-placeholder',
    templateUrl: './placeholder.page.html',
    styleUrls: ['./placeholder.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PlaceholderPage {
    constructor() {}
}
