component-basket-history {
    padding: 16px;

    .basket-quick-action-cnt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px;
    }

    .btn-close {
        background: transparent;
        padding: 4px;

        .mat-icon {
            font-size: 40px;
            width: 40px;
            height: 40px;
            color: #b0b7c1;
        }

        &:hover,
        &:focus {
            background: transparent;
        }
    }

    .basket-history-header {
        margin: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        padding-bottom: 15px;

        h5 {
            font-family: font('opensans');
            font-size: clamp(1.333rem, 1vw + 1.333rem, 1.777rem);
            font-weight: 600;
            letter-spacing: -0.05rem;
            line-height: clamp(1.333rem, 1vw + 1.333rem, 1.777rem);
            margin: 0;
            color: rgba(0, 18, 36, 0.9);
        }
    }

    .success-label {
        color: #28a745;
        margin-top: 5px;
        margin-left: -45px;
        font-weight: bold;
        font-size: 13px;
        text-transform: uppercase;
        font-family: 'Open Sans', sans-serif;
    }

    .danger-label {
        color: #FC575E;
        margin-top: 5px;
        margin-left: -45px;
        font-weight: bold;
        font-size: 13px;
        text-transform: uppercase;
        font-family: 'Open Sans', sans-serif;
    }

    .date {
        margin-top: 5px;
        font-family: font('opensans');
        font-size: clamp(0.75rem, 1vw + 0.75rem, 0.75rem);
        font-style: normal;
        font-weight: 300;
        letter-spacing: 0rem;
        line-height: clamp(1.333rem, 1vw + 1.333rem, 1.333rem);
        margin-bottom: 0;
        color: rgba(0, 18, 36, 0.9);
    }

    .basket-history-detail {
        font-family: font('opensans');
        font-size: clamp(1rem, 1vw + 1rem, 1rem);
        font-style: normal;
        font-weight: 300;
        letter-spacing: 0rem;
        line-height: clamp(1.618rem, 1vw + 1.618rem, 1.618rem);
        color: rgba(0, 18, 36, 0.9);
    }

    .basket-history-lbl-item {
        span {
            font-family: font('opensans');
            font-size: clamp(0.75rem, 1vw + 0.75rem, 0.75rem);
            font-style: normal;
            font-weight: 300;
            letter-spacing: 0rem;
            line-height: clamp(1.333rem, 1vw + 1.333rem, 1.333rem);
            color: rgba(0, 18, 36, 0.9);
        }
    }

    .success-icon {
        margin-top: 5px;
        color: #28a745 !important;
    }

    .danger-icon {
        margin-top: 5px;
        color: #FC575E !important;
    }

    .basket-history-list {
        max-height: calc(100vh - 100px);
        overflow: auto;

        .basket-history-item {
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        }
    }
}