@import '/src/theme/variables';

page-about-credit-rating {
    .rating {
        color: color('green');
        font-weight: 400;
    }

    .table-striped {
        margin-bottom: 25px;
        border-collapse: collapse;
        width: 100%;

        th {
            padding: 20px;
            border-color: #dee2e6;
            background-color: #e9ecef;
            color: #495057;
            font-size: 0.8125rem;
            font-weight: 700;
            text-align: left;
        }

        tr {
            &:nth-of-type(odd) {
                background-color: rgba(0, 0, 0, .05);
            }

            td {
                padding: 20px;
                border-top: 1px solid #dee2e6;
                font-size: 0.8125rem;
                font-weight: 400;
            }
        }
    }
}