import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { StorageService } from '../storage.service';
import { Config } from '@app/config';

@Injectable()
export class CacheLocalInterceptor implements HttpInterceptor {
    constructor(private config: Config, private storage: StorageService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const cacheLocal = req.headers.has('cache-local');
        const cacheKey = req.headers.get('cache-local') || null;
        const cacheModify = req.headers.has('cache-modified');
        const cacheModified = parseInt(req.headers.get('cache-modified') || '0', 10);
        let headers = req.headers;
        headers = cacheLocal ? headers.delete('cache-local') : headers;
        headers = cacheModify ? headers.delete('cache-modified') : headers;
        const handledReq = cacheLocal || cacheModify ? req.clone({ headers }) : req.clone();

        if (cacheKey) {
            return from(this.storage.get(cacheKey)).pipe(
                mergeMap((data: any) => {
                    let cache = false;
                    if (data) {
                        cache = true;

                        if (cacheModify && !(cacheModified > new Date().getTime() - this.config.api.cache)) {
                            cache = false;
                        }
                    }

                    if (cache) {
                        return of(new HttpResponse<any>({
                            body: data
                        }));
                    } else {
                        return next.handle(handledReq).pipe(
                            mergeMap((event: HttpEvent<any>) => {
                                if (event instanceof HttpResponse && cacheKey) {
                                    return from(
                                        new Promise((resolve, reject) => {
                                            const body = (event.body && event.body.noChange !== true ? event.body : data) || null;

                                            if (cacheModify && body) {
                                                body.modified = new Date().getTime();
                                            }

                                            const eventResponse = event.clone({ body });

                                            this.storage
                                                .set(cacheKey, body)
                                                .then(() => resolve(eventResponse))
                                                .catch((error) => reject(error));
                                        })
                                    );
                                }

                                return of(event);
                            })
                        );
                    }
                })
            );
        } else {
            return next.handle(handledReq);
        }
    }
}
