import {
    Component, ViewEncapsulation, Input, Output, OnInit, OnDestroy, EventEmitter
} from '@angular/core';
import { Config } from '@app/config';
import { Subscription } from 'rxjs';
import { ActiveRange } from '@app/services/catalogue/models';
import { CatalogueService } from '@app/services/catalogue';

@Component({
    selector: 'component-option-bar',
    templateUrl: './option-bar.component.html',
    styleUrls: ['./option-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class OptionBarComponent implements OnInit, OnDestroy {
    @Input() filters: any[] = [];
    @Input() showSort: boolean = true;
    @Input() showToggles: boolean = true;
    @Input() showActiveRange: boolean = true;
    @Output() filterChange = new EventEmitter<string>();
    @Output() sortChange = new EventEmitter<string>();
    @Output() softCloseChange = new EventEmitter<boolean>();

    public filtersOnBar: string[] = ['Width', 'Height', 'Depth'];
    public sideMenuVisible = false;
    public sideMenuSortVisible = false;
    public expandedFilter: string;
    public sortOrder = 'popularity';
    private clickOutside = 0;

    public activeRange: ActiveRange;

    private activeRangeSubscription: Subscription;

    constructor(
        public config: Config,
        private catalogueService: CatalogueService
    ) { }

    ngOnInit(): void {
        this.activeRangeSubscription = this.catalogueService.activeRange$.subscribe(
            (rangeInfo: ActiveRange) => {
                this.activeRange = rangeInfo;
            }
        );
    }

    ngOnDestroy(): void {
        this.activeRangeSubscription?.unsubscribe();
    }

    public clearFilters(): void {
        this.filters.forEach((filter: any) => {
            filter.opts?.forEach((opt: any) => {
                opt.tick = false;
            });
        });
        this.filterChange.emit();
    }

    public expansionPanelChange(expanded: boolean, filter: any): void {
        filter['expanded'] = expanded;
    }

    public toggleFilter(filterTitle?: string): void {
        this.sideMenuVisible = !this.sideMenuVisible;
        this.sideMenuSortVisible = false;
        this.filters.forEach((filter) => {
            filter['expanded'] = false;
        });
        if (filterTitle === 'sort') {
            this.sideMenuSortVisible = true;
        } else if (filterTitle) {
            this.filters.filter(filter => filter.title === filterTitle)[0]['expanded'] = true;
        }
        this.clickOutside = 0;
    }

    public clickOption(option: any, filter?: any, multiSelect?: boolean): void {
        this.clickOutside = 0;
        if (filter && !multiSelect) {
            if (option.tick) {
                option.tick = false;
                this.filterChange.emit();
            } else {
                let selected = filter.opts?.find(option => option.tick);
                if (selected) {
                    selected.tick = false;
                }
                option.tick = true;
                this.filterChange.emit(option.value);
            }
        } else {
            option.tick = !option.tick;
            this.filterChange.emit(option.name);
        }
    }

    public clickSoftClose(): void {
        this.config.settings.softClose = !this.config.settings.softClose;
        this.config.saveSettings();
        this.softCloseChange.emit(this.config.settings.softClose);
    }

    public getTickedFilter(filterName: string): any[] {
        const filter = this.filters.find((filter) => filter.title === filterName);
        return filter?.opts?.filter(opt => opt.tick) || [];
    }

    public sortChanged(): void {
        this.sortChange.emit(this.sortOrder);
    }

    public clickOutsider(): void {
        if (this.sideMenuVisible && this.clickOutside > 0) {
            this.sideMenuVisible = false;
            this.clickOutside = 0;
        } else {
            this.clickOutside++;
        }
    }

    public getRangeDoorImage(): string {
        return `https://static.diy-kitchens.com/doors/${(this.activeRange?.colourDetail?.door_image) ? this.activeRange?.colourDetail?.door_image : 'clayton_alabaster_straight_on_500px.jpg'}`;
    }

    public selectRange(): void {
        this.catalogueService.openRangeSelector();
    }
}
