import { MegaMenuTemplates, IMegaMenu } from './models';

export const mockData: IMegaMenu = {
    kitchens: {
        title: 'Kitchens',
        subCategories: [
            {
                id: 0,
                name: 'Styles',
                items: [
                    {
                        name: 'Handleless Kitchens',
                        url: '/kitchens/handleless',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_kitchens_handleless2.jpg'
                        }
                    },
                    {
                        name: 'Shaker Kitchens',
                        url: '/kitchens/shaker',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_kitchens_shaker2.jpg'
                        }
                    },
                    {
                        name: 'Slab Door',
                        url: '/kitchens/slab-door',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_kitchens_slab.jpg'
                        }
                    },
                    {
                        name: 'Modern Kitchens',
                        url: '/kitchens/modern',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_kitchens_modern2.jpg'
                        }
                    },
                    {
                        name: 'Traditional Kitchens',
                        url: '/kitchens/traditional',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_kitchens_traditional2.jpg'
                        }
                    },
                    {
                        name: 'Inframe Kitchens',
                        url: '/kitchens/inframe',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_kitchens_in-frame2.jpg'
                        }
                    },
                    {
                        name: 'High Gloss Kitchens',
                        url: '/kitchens/high-gloss',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_kitchens_high-gloss2.jpg'
                        }
                    },
                    {
                        name: 'Solid Wood',
                        url: '/kitchens/solid-wood',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_kitchens_traditional2.jpg'
                        }
                    },
                    // {
                    //     name: 'Painted Kitchens',
                    //     url: '/kitchens/painted',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/category_kitchens_painted2.jpg'
                    //     }
                    // },
                    {
                        name: 'Bespoke Painted Kitchens',
                        url: '/kitchens/bespoke-painted',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_kitchens_handleless2.jpg'
                        }
                    }
                ],
                additionalItems: [
                    {
                        name: 'Appliances',
                        url: '/appliances',
                        image: {
                            url: 'https://static.diy-kitchens.com/appliances/appliance_choice/top_double_ovens.jpg'
                        }
                    },
                    {
                        name: 'Sinks & Taps',
                        url: '/sinks-and-taps',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_sinks-and-taps_undermount.jpg'
                        }
                    },
                    {
                        name: 'Worktops',
                        url: '/worktops',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_worktops_quartz.jpg'
                        }
                    },
                    {
                        name: 'Handles',
                        url: '/handles',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_handles_bar.jpg'
                        }
                    }
                ],
                template: MegaMenuTemplates.IMAGE
            },
            {
                id: 1,
                name: 'Colours',
                items: [
                    {
                        name: 'Alabaster',
                        colour: 'innova-alabaster',
                        anchor: {
                            url: '/kitchens/allstyles/alabaster/allbrands/all-finishes/straight-run/low-high',
                            title: 'Alabaster'
                        }
                    },
                    {
                        name: 'Black',
                        colour: 'innova-black',
                        anchor: {
                            url: '/kitchens/allstyles/black/allbrands/all-finishes/straight-run/low-high',
                            title: 'Black'
                        }
                    },
                    {
                        colour: 'innova-carbon',
                        name: 'Carbon',
                        anchor: {
                            url: '/kitchens/allstyles/carbon/allbrands/all-finishes/straight-run/low-high',
                            title: 'Carbon'
                        }
                    },
                    {
                        colour: 'innova-cashmere',
                        name: 'Cashmere',
                        anchor: {
                            url: '/kitchens/allstyles/cashmere/allbrands/all-finishes/straight-run/low-high',
                            title: 'Cashmere'
                        }
                    },
                    {
                        colour: 'innova-champagne',
                        name: 'Champagne',
                        anchor: {
                            url: '/kitchens/allstyles/champagne/allbrands/all-finishes/straight-run/low-high',
                            title: 'Champagne'
                        }
                    },
                    {
                        colour: 'innova-graphite',
                        name: 'Graphite',
                        anchor: {
                            url: '/kitchens/allstyles/graphite/allbrands/all-finishes/straight-run/low-high',
                            title: 'Graphite'
                        }
                    },
                    {
                        colour: 'innova-heritage-green',
                        name: 'Green',
                        anchor: {
                            url: '/kitchens/allstyles/heritage-green/allbrands/all-finishes/straight-run/low-high',
                            title: 'Green'
                        }
                    },
                    {
                        colour: 'innova-light-grey',
                        name: 'Grey',
                        anchor: {
                            url: '/kitchens/allstyles/light-grey/allbrands/all-finishes/straight-run/low-high',
                            title: 'Grey'
                        }
                    },
                    {
                        colour: 'innova-petrol-blue',
                        name: 'Petrol Blue',
                        anchor: {
                            url: '/kitchens/allstyles/petrol-blue/allbrands/all-finishes/straight-run/low-high',
                            title: 'Petrol Blue'
                        }
                    },
                    {
                        colour: 'innova-sage',
                        name: 'Sage',
                        anchor: {
                            url: '/kitchens/allstyles/sage/allbrands/all-finishes/straight-run/low-high',
                            title: 'Sage'
                        }
                    },
                    {
                        colour: 'innova-white',
                        name: 'White',
                        anchor: {
                            url: '/kitchens/allstyles/white/allbrands/all-finishes/straight-run/low-high',
                            title: 'White'
                        }
                    },
                    {
                        colour: 'innova-oak',
                        name: 'Wood',
                        anchor: {
                            url: '/kitchens/allstyles/oak/allbrands/all-finishes/straight-run/low-high',
                            title: 'Wood'
                        }
                    }
                ],
                template: MegaMenuTemplates.SWATCH
            },
            {
                id: 2,
                name: 'Finishes',
                items: [
                    {
                        name: 'High Gloss Kitchens',
                        url: '/kitchens/allstyles/allcolours/high-gloss/allmaterial/straight-shaped/low-high',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_kitchens_materials_gloss.jpg'
                        }
                    },
                    {
                        name: 'Matt Kitchens',
                        url: '/kitchens/allstyles/allcolours/matt/allmaterial/straight-shaped/low-high',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_kitchens_materials_matt.jpg'
                        }
                    },
                    {
                        name: 'Solid Wood Kitchens',
                        url: '/kitchens/allstyles/allcolours/wood-effect/allmaterial/straight-shaped/low-high',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_kitchens_materials_solid-wood.jpg'
                        }
                    },
                    {
                        name: 'Painted Kitchens',
                        url: '/kitchens/allstyles/allcolours/painted/allmaterial/straight-shaped/low-high',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_kitchens_materials_painted.jpg'
                        }
                    },
                    {
                        name: 'Smooth Painted Kitchens',
                        url: '/kitchens/allstyles/allcolours/smooth/allmaterial/straight-shaped/low-high',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_kitchens_materials_smooth-painted.jpg'
                        }
                    }
                ],
                template: MegaMenuTemplates.IMAGE
            }
        ],
        template: MegaMenuTemplates.CATEGORIES,
        viewAll: '/kitchens/allstyles'
    },
    units: {
        title: 'Units',
        subCategories: [
            {
                id: 0,
                name: 'Base',
                items: [
                    {
                        name: 'Highline',
                        url: '/kitchen-units/base/highline',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/base_highline.jpg'
                        }
                    },
                    {
                        name: 'Drawerline',
                        url: '/kitchen-units/base/drawerline',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/base_drawerline.jpg'
                        }
                    },
                    {
                        name: 'Pan & Multi Drawer',
                        url: '/kitchen-units/base/pan-and-multi-drawer',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/base_pan_&_multi_drawer_unit.jpg'
                        }
                    },
                    {
                        name: 'Sink',
                        url: '/kitchen-units/base/sink',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/base_sink_unit.jpg'
                        }
                    },
                    {
                        name: 'Pull Out Storage',
                        url: '/kitchen-units/base/pull-out-storage',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/base_pull_out_unit.jpg'
                        }
                    },
                    {
                        name: 'Pull Out Waste Bin',
                        url: '/kitchen-units/base/pull-out-waste-units',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/base_bin_unit.jpg'
                        }
                    },
                    {
                        name: 'Curved',
                        url: '/kitchen-units/base/curved-and-s-shaped',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/base_curved_unit.jpg'
                        }
                    },
                    {
                        name: 'Reduced Depth',
                        url: '/kitchen-units/base/slimline',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/base_reduced_depth_unit.jpg'
                        }
                    },
                    {
                        name: 'Open',
                        url: '/kitchen-units/base/open',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/base_open_unit.jpg'
                        }
                    },
                    {
                        name: 'Wine Rack',
                        url: '/kitchen-units/base/wine-rack',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/base_wine_rack.jpg'
                        }
                    },
                    // {
                    //     name: 'End Quadrant',
                    //     url: '/kitchen-units/base/end-quadrant',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/units/base_open_end_&_quadrant.jpg'
                    //     }
                    // },
                    // {
                    //     name: 'Peninsular',
                    //     url: '/kitchen-units/base/peninsular',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/units/base_peninsular_unit.jpg'
                    //     }
                    // },
                    {
                        name: 'Reduced Height',
                        url: '/kitchen-units/base/reduced-height',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/base_reduced_height_unit.jpg'
                        }
                    },
                    {
                        name: '900mm High',
                        url: '/kitchen-units/base/900mm-high',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/base_tall_base.jpg'
                        }
                    },
                    {
                        name: '1250mm High',
                        url: '/kitchen-units/base/1250mm-high',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/mid_unit.jpg'
                        }
                    },
                    // {
                    //     name: 'Oven / Microwave Housing',
                    //     url: '/kitchen-units/base/appliance-housing',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/units/base_appliance_housing.jpg'
                    //     }
                    // },
                    // {
                    //     name: 'Angled',
                    //     url: '/kitchen-units/base/angled',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/units/base_angled_unit.jpg'
                    //     }
                    // }
                ],
                template: MegaMenuTemplates.IMAGE
            },
            {
                id: 1,
                name: 'Corner Base',
                items: [
                    {
                        name: 'Standard Corner',
                        url: '/kitchen-units/corner-base/standard',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/corner-base_standard_corner.jpg'
                        }
                    },
                    {
                        name: 'Corner Sink',
                        url: '/kitchen-units/corner-base/sink',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/corner-base_sink_corner.jpg'
                        }
                    },
                    {
                        name: 'Pull Out Corner',
                        url: '/kitchen-units/corner-base/pull-out',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/corner-base_pull_out_corner.jpg'
                        }
                    },
                    {
                        name: 'L-Shaped Corner',
                        url: '/kitchen-units/corner-base/l-shaped',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/corner-base_l-shape.jpg'
                        }
                    },
                    // {
                    //     name: 'Diagonal Corner',
                    //     url: '/kitchen-units/corner-base/diagonal',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/units/corner-base_diagonal_corner_unit.jpg'
                    //     }
                    // },
                    {
                        name: 'Internal Curved Corner',
                        url: '/kitchen-units/corner-base/internal-curved',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/corner-base_internal_curve.jpg'
                        }
                    }
                ],
                template: MegaMenuTemplates.IMAGE
            },
            {
                id: 2,
                name: 'Wall',
                items: [
                    {
                        name: 'Standard',
                        url: '/kitchen-units/wall/standard',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/wall_standard_unit.jpg'
                        }
                    },
                    // {
                    //     name: 'Glazed',
                    //     url: '/kitchen-units/wall/glazed',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/units/wall_glazed_unit.jpg'
                    //     }
                    // },
                    // {
                    //     name: 'Corner',
                    //     url: '/kitchen-units/wall/corner',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/units/corner-wall_standard_corner.jpg'
                    //     }
                    // },
                    {
                        name: 'Curved',
                        url: '/kitchen-units/wall/curved-and-s-shaped',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/wall_curved_unit.jpg'
                        }
                    },
                    // {
                    //     name: 'Microwave',
                    //     url: '/kitchen-units/wall/microwave',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/units/wall_microwave.jpg'
                    //     }
                    // },
                    {
                        name: 'Open',
                        url: '/kitchen-units/wall/open',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/wall_open_unit.jpg'
                        }
                    },
                    // {
                    //     name: 'Angled',
                    //     url: '/kitchen-units/wall/angled',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/units/wall_angled_unit.jpg'
                    //     }
                    // },
                    // {
                    //     name: 'Peninsular',
                    //     url: '/kitchen-units/wall/peninsular',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/units/wall_peninsular_unit.jpg'
                    //     }
                    // },
                    // {
                    //     name: 'Quadrant',
                    //     url: '/kitchen-units/wall/end-quadrant',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/units/wall_open_end_&_quadrant.jpg'
                    //     }
                    // },
                    {
                        name: 'Wine Rack',
                        url: '/kitchen-units/wall/wine-rack',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/wall_wine_rack.jpg'
                        }
                    },
                    {
                        name: 'Bi-Fold',
                        url: '/kitchen-units/wall/bi-fold',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/wall_bi-fold_unit.jpg'
                        }
                    },
                    {
                        name: 'Top Boxes',
                        url: '/kitchen-units/wall/top-boxes',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/wall_top_boxes.jpg'
                        }
                    },
                    {
                        name: 'Boiler Housing',
                        url: '/kitchen-units/wall/boiler-housing',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/wall_boiler_unit.jpg'
                        }
                    },
                    // {
                    //     name: 'Canopy',
                    //     url: '/kitchen-units/wall/canopy',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/category_units_wall-canopy.jpg'
                    //     }
                    // }
                ],
                template: MegaMenuTemplates.IMAGE
            },
            {
                id: 3,
                name: 'Corner Wall',
                items: [
                    {
                        name: 'Standard Corner',
                        url: '/kitchen-units/corner-wall/standard-corner',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/corner_wall-standard.jpg'
                        }
                    },
                    {
                        name: 'L-Shape',
                        url: '/kitchen-units/corner-wall/l-shape',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/corner_wall-lshape.jpg'
                        }
                    },
                    {
                        name: 'Internal Curve',
                        url: '/kitchen-units/corner-wall/internal-curve',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/corner_wall-internal-curved.jpg'
                        }
                    },
                    // {
                    //     name: 'Angled',
                    //     url: '/kitchen-units/corner-wall/angled',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/units/corner_wall-angled.jpg'
                    //     }
                    // }
                ],
                template: MegaMenuTemplates.IMAGE
            },
            {
                id: 4,
                name: 'Appliance Housings',
                items: [
                    {
                        name: 'Oven / Microwave Housing',
                        url: '/kitchen-units/appliance-housings/microwave',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/base_appliance_housing.jpg'
                        }
                    },
                    {
                        name: 'Single Oven Housing',
                        url: '/kitchen-units/appliance-housings/single-oven',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/single_oven_tall.jpg'
                        }
                    },
                    {
                        name: 'Single Oven and Microwave Housing',
                        url: '/kitchen-units/appliance-housings/single-oven-&-microwave',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/single_oven_tall.jpg'
                        }
                    },
                    {
                        name: 'Double Oven Housing',
                        url: '/kitchen-units/appliance-housings/double-oven',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/double_oven_tall.jpg'
                        }
                    },
                    {
                        name: 'Double Oven and Microwave Housing',
                        url: '/kitchen-units/appliance-housings/double-oven-&-microwave',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/double_oven_tall.jpg'
                        }
                    },
                    {
                        name: 'Integrated Fridge Freezer Housing',
                        url: '/kitchen-units/appliance-housings/refrigeration',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/integrated_50_50_fridge_freezer.jpg'
                        }
                    },
                    {
                        name: 'Compact Housing',
                        url: '/kitchen-units/appliance-housings/compact-appliance',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/microwaves_compact_appliances.jpg'
                        }
                    },
                    {
                        name: 'x2 Compact Housing',
                        url: '/kitchen-units/appliance-housings/x2-compact-appliance',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/microwaves_compact_appliances.jpg'
                        }
                    }
                ],
                template: MegaMenuTemplates.IMAGE
            },
            {
                id: 5,
                name: 'Tall',
                items: [
                    {
                        name: 'Tall Broom',
                        url: '/kitchen-units/tall/broom',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/tall_broom_unit.jpg'
                        }
                    },
                    {
                        name: 'Tall Larder',
                        url: '/kitchen-units/tall/larder',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/tall_larder_unit.jpg'
                        }
                    },
                    {
                        name: 'Tall Pull Out Larder',
                        url: '/kitchen-units/tall/pull-out-larder',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/tall_pull_out_unit.jpg'
                        }
                    },
                    {
                        name: 'Internal Drawer',
                        url: '/kitchen-units/tall/internal-drawer',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/tall_internal_drawer_unit.jpg'
                        }
                    },
                    // {
                    //     name: 'Tall Angled',
                    //     url: '/kitchen-units/tall/angled',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/units/tall_angled_unit.jpg'
                    //     }
                    // },
                    {
                        name: 'Tall Wine Rack',
                        url: '/kitchen-units/tall/wine-rack',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/tall_wine_rack.jpg'
                        }
                    },
                    // {
                    //     name: 'Tandem Larder and Pantry',
                    //     url: '/kitchen-units/tall/tandem-larder-units',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/units/tall_internal_drawer_unit.jpg'
                    //     }
                    // },
                    // {
                    //     name: 'Tall Open End Shelf/Quadrant',
                    //     url: '/kitchen-units/tall/quadrant',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/units/tall_open_end_&_quadrant.jpg'
                    //     }
                    // }
                ],
                template: MegaMenuTemplates.IMAGE
            },
            {
                id: 6,
                name: 'Tall Corner',
                items: [
                    {
                        name: 'Walk In Larder',
                        url: '/kitchen-units/tall-corner/walk-in-larder',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/tall-corner_walk_in_larder.jpg'
                        }
                    },
                    {
                        name: 'Diagonal Corner',
                        url: '/kitchen-units/tall-corner/diagonal',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/tall-corner_diagonal_corner_unit.jpg'
                        }
                    },
                    {
                        name: 'Tall Corner',
                        url: '/kitchen-units/tall-corner/tall-corner',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/tall-corner_tall_corner.jpg'
                        }
                    },
                    {
                        name: 'Reduce Depth Tall Corner',
                        url: '/kitchen-units/tall-corner/reduced-depth-tall-corner',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/tall-corner_reduced_depth_tall_corner.jpg'
                        }
                    }
                ],
                template: MegaMenuTemplates.IMAGE
            },
            {
                id: 7,
                title: 'Worktop Dresser',
                name: 'Dresser',
                items: [
                    {
                        name: 'Open',
                        url: '/kitchen-units/dresser/open',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/dresser-units-&-mantles_open_dresser_unit.jpg'
                        }
                    },
                    // {
                    //     name: 'Tambour',
                    //     url: '/kitchen-units/dresser/tambour',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/units/dresser-units-&-mantles_tambour_unit.jpg'
                    //     }
                    // },
                    {
                        name: 'Standard & Glazed',
                        url: '/kitchen-units/dresser/standard',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/dresser-units-&-mantles_dresser_unit.jpg'
                        }
                    },
                    {
                        name: 'Mantles & Canopies',
                        url: '/kitchen-units/dresser/mantles-canopies',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/dresser-units-&-mantles_mantle.jpg'
                        }
                    }
                ],
                template: MegaMenuTemplates.IMAGE
            },
            {
                name: 'Appliance Doors',
                url: '/kitchen-units/accessories/appliance-doors',
                items: [],
                template: MegaMenuTemplates.LINK
            },
            {
                id: 8,
                name: 'Panels & Accessories',
                items: [
                    {
                        name: 'Appliance Doors',
                        url: '/kitchen-units/accessories/appliance-doors',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/appliance_doors.jpg'
                        }
                    },
                    {
                        name: 'Cornice, Pelmet & Plinths',
                        url: '/kitchen-units/accessories/cornice-pelmet-plinths',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/pelmet.jpg'
                        }
                    },
                    {
                        name: 'Panels & Fillers',
                        url: '/kitchen-units/accessories/end-panels',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/plinth_panels.jpg'
                        }
                    },
                    {
                        name: 'Corner Posts',
                        url: '/kitchen-units/accessories/corner-posts',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/cornerpost.jpg'
                        }
                    },
                    // {
                    //     name: 'Mantles & Canopies',
                    //     url: '/kitchen-units/accessories/mantles-canopies',
                    //     image: {
                    //         url: 'https://static.diy-kitchens.com/assets/images/menu/units/dresser-units-&-mantles_mantle.jpg'
                    //     }
                    // },
                    {
                        name: 'Decorative Items',
                        url: '/kitchen-units/accessories/decorative-items',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/posts,-panels-&-shelves_column.jpg'
                        }
                    },
                    {
                        name: 'Plinths & Fillers (Carcase Material)',
                        url: '/kitchen-units/accessories/carcase-material',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/units/carcaccess.jpg'
                        }
                    }
                ],
                template: MegaMenuTemplates.IMAGE
            }
        ],
        template: MegaMenuTemplates.CATEGORIES,
        viewAll: '/kitchen-units'
    },
    doors: {
        title: 'Doors',
        subCategories: [
            {
                id: 0,
                name: 'styles',
                items: [
                    {
                        name: 'Handleless',
                        url: '/kitchen-doors/handleless',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_doors_handleless.jpg'
                        }
                    },
                    {
                        name: 'Shaker',
                        url: '/kitchen-doors/shaker',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_doors_shaker.jpg'
                        }
                    },
                    {
                        name: 'Slab Door',
                        url: '/kitchen-doors/slab-door',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_doors_modern.jpg'
                        }
                    },
                    {
                        name: 'Modern',
                        url: '/kitchen-doors/modern',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_doors_modern.jpg'
                        }
                    },
                    {
                        name: 'Traditional',
                        url: '/kitchen-doors/traditional',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_doors_traditional.jpg'
                        }
                    },
                    {
                        name: 'High Gloss',
                        url: '/kitchen-doors/high-gloss',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_doors_high-gloss.jpg'
                        }
                    },
                    {
                        name: 'Solid Wood',
                        url: '/kitchen-doors/solid-wood',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_doors_traditional.jpg'
                        }
                    },
                    {
                        name: 'Bespoke Painted',
                        url: '/kitchen-doors/bespoke-painted',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_doors_bespoke-painted.jpg'
                        }
                    }
                ],
                template: MegaMenuTemplates.IMAGE
            }
        ],
        template: MegaMenuTemplates.CATEGORIES,
        viewAll: '/kitchen-doors'
    },
    appliances: {
        title: 'Appliances',
        subCategories: [
            {
                id: 0,
                name: 'Cooking',
                items: [
                    {
                        name: 'Single Ovens',
                        url: '/appliances/cooking/single-ovens',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_appliances_cooking_single-ovens.jpg'
                        }
                    },
                    {
                        name: 'Double Ovens',
                        url: '/appliances/cooking/double-ovens',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_appliances_cooking_double-ovens.jpg'
                        }
                    },
                    {
                        name: 'Compact Ovens',
                        url: '/appliances/cooking/compact-ovens',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_appliances_cooking_compact-ovens.jpg'
                        }
                    },
                    {
                        name: 'Microwaves',
                        url: '/appliances/cooking/microwaves',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_units_wall-microwave.jpg'
                        }
                    },
                    {
                        name: 'Warming Drawers',
                        url: '/appliances/cooking/warming-drawers',
                        image: {
                            url: 'https://static.diy-kitchens.com/appliances/appliance_choice/warming_drawer.jpg'
                        }
                    },
                    {
                        name: 'Hobs',
                        url: '/appliances/cooking/hobs',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_appliances_cooking_hobs.jpg'
                        }
                    },
                    {
                        name: 'Hoods/Extractors',
                        url: '/appliances/cooking/extractors',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_appliances_cooking_extractors.jpg'
                        }
                    }
                ],
                template: MegaMenuTemplates.IMAGE
            },
            {
                id: 1,
                name: 'Laundry',
                items: [
                    {
                        name: 'Washing Machines',
                        url: '/appliances/laundry/washing-machines',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_appliances_laundry_washing-machines.jpg'
                        }
                    },
                    {
                        name: 'Washer Dryers',
                        url: '/appliances/laundry/washer-dryers',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_appliances_laundry_washers-dryers.jpg'
                        }
                    },
                    {
                        name: 'Tumble Dryers',
                        url: '/appliances/laundry/tumble-dryer',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_appliances_laundry_tumble-dryers.jpg'
                        }
                    }
                ],
                template: MegaMenuTemplates.IMAGE
            },
            {
                id: 2,
                name: 'Refrigeration',
                items: [
                    {
                        name: 'American Fridge Freezers',
                        url: '/appliances/refrigeration/american-fridge-freezer',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_appliances_refrigeration_american.jpg'
                        }
                    },
                    {
                        name: '50:50 Fridge Freezers',
                        url: '/appliances/refrigeration/50-50-fridge-freezers',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_appliances_refrigeration_fridge-freezer-5050.jpg'
                        }
                    },
                    {
                        name: '70:30 Fridge Freezers',
                        url: '/appliances/refrigeration/70-30-fridge-freezers',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_appliances_refrigeration_fridge-freezer-7030.jpg'
                        }
                    },
                    {
                        name: 'Fridges',
                        url: '/appliances/refrigeration/fridges',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_appliances_refrigeration_fridge.jpg'
                        }
                    },
                    {
                        name: 'Freezers',
                        url: '/appliances/refrigeration/freezers',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_appliances_refrigeration_freezer.jpg'
                        }
                    },
                    {
                        name: 'Fridge Freezers',
                        url: '/appliances/refrigeration/fridge-freezers',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_appliances_refrigeration_fridge-freezers.jpg'
                        }
                    },
                    {
                        name: 'Wine Coolers',
                        url: '/appliances/refrigeration/wine-coolers',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_appliances_refrigeration_wine-coolers.jpg'
                        }
                    }
                ],
                template: MegaMenuTemplates.IMAGE
            },
            {
                id: 3,
                name: 'Dishwashers',
                items: [
                    {
                        name: 'Integrated Dishwashers',
                        url: '/appliances/dishwashers/integrated',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_appliances_dishwashers_fully-integrated.jpg'
                        }
                    },
                    {
                        name: 'Slimline Integrated Dishwashers',
                        url: '/appliances/dishwashers/slimline-integrated',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_appliances_dishwashers_slimline-fully-integrated.jpg'
                        }
                    }
                ],
                template: MegaMenuTemplates.IMAGE
            }
        ],
        template: MegaMenuTemplates.CATEGORIES,
        viewAll: '/appliances'
    },
    'sinks-and-taps': {
        title: 'Sinks and Taps',
        subCategories: [
            {
                id: 0,
                name: 'Sinks',
                items: [
                    {
                        name: 'Inset',
                        url: '/sinks-and-taps/sinks/inset',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_sinks-and-taps_inset.jpg'
                        }
                    },
                    {
                        name: 'Undermount',
                        url: '/sinks-and-taps/sinks/undermount',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_sinks-and-taps_undermount.jpg'
                        }
                    },
                    {
                        name: 'Belfast',
                        url: '/sinks-and-taps/sinks/belfast',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_sinks-and-taps_belfast.jpg'
                        }
                    }
                ],
                template: MegaMenuTemplates.IMAGE
            },
            {
                id: 1,
                name: 'Taps',
                items: [
                    {
                        name: 'Mixer',
                        url: '/sinks-and-taps/taps/mixer',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_sinks-and-taps_taps_mixer.jpg'
                        }
                    },
                    {
                        name: 'Spout',
                        url: '/sinks-and-taps/taps/spout',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_sinks-and-taps_taps_spout.jpg'
                        }
                    },
                    {
                        name: 'Pull Out',
                        url: '/sinks-and-taps/taps/pull-out',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/category_sinks-and-taps_taps_pull-out.jpg'
                        }
                    },
                    {
                        name: 'Quooker Taps',
                        url: '/sinks-and-taps/taps/quooker-boiling-water',
                        image: {
                            url: 'https://static.diy-kitchens.com/assets/images/menu/hot-water-taps.png',
                            logo: 'https://static.diy-kitchens.com/assets/images/products/sinks/brand_logo/quooker_logo.gif'
                        }
                    }
                ],
                template: MegaMenuTemplates.IMAGE
            }
        ],
        template: MegaMenuTemplates.CATEGORIES,
        viewAll: '/sinks-and-taps'
    },
    worktops: {
        title: 'Worktops',
        items: [
            {
                name: 'Laminate Worktops',
                url: '/laminate-worktops',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_worktops_laminate.jpg'
                }
            },
            {
                name: 'Solid Wood Worktops',
                url: '/wood-worktops',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_worktops_solid-wood.jpg'
                }
            },
            {
                name: 'Granite Worktops',
                url: '/solid-surfaces/granite-worktops',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_worktops_quartz.jpg'
                }
            },
            {
                name: 'Dekton Worktops',
                url: '/solid-surfaces/dekton-worktops',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_worktops_dekton.jpg'
                }
            },
            {
                name: 'Quartz Worktops',
                url: '/solid-surfaces/quartz-worktops',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_worktops_quartz.jpg'
                }
            },
            {
                name: 'Ceramic Worktops',
                url: '/solid-surfaces/ceramic-worktops',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_worktops_ceramic.jpg'
                }
            }
        ],
        viewAll: '/worktops',
        template: MegaMenuTemplates.ITEMS
    },
    handles: {
        title: 'Handles',
        items: [
            {
                name: 'Bow',
                url: '/handles/bow-and-bridge-handles',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_handles_bow.jpg'
                }
            },
            {
                name: 'Knobs',
                url: '/handles/knobs',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_handles_knob.jpg'
                }
            },
            {
                name: 'Bar',
                url: '/handles/bar-handles',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_handles_bar.jpg'
                }
            },
            {
                name: 'Profile',
                url: '/handles/profile-handles',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_handles_profile.jpg'
                }
            },
            {
                name: 'Cup',
                url: '/handles/cup-and-pull-handles',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_handles_cup.jpg'
                }
            }
        ],
        viewAll: '/handles',
        template: MegaMenuTemplates.ITEMS
    },
    samples: {
        title: 'Samples',
        items: [
            {
                name: 'Door samples',
                url: '/samples/door-samples',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_doors_shaker.jpg'
                }
            },
            {
                name: 'Carcase samples',
                url: '/samples/carcase-samples',
                image: {
                    url: 'https://static.diy-kitchens.com/categories/units/base_open_unit.jpg'
                }
            },
            {
                name: 'Quartz Worktop Samples',
                url: '/samples/solid-surface-worktop-samples/quartz',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_worktops_quartz.jpg'
                }
            },
            {
                name: 'Granite Worktop Samples',
                url: '/samples/solid-surface-worktop-samples/granite',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_worktops_granite.jpg'
                }
            },
            {
                name: 'Wood Worktop samples',
                url: '/samples/solid-surface-worktop-samples/solid-wood',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_worktops_solid-wood.jpg'
                }
            }
        ],
        viewAll: '/samples',
        template: MegaMenuTemplates.ITEMS
    },
    accessories: {
        title: 'Accessories',
        items: [
            {
                name: 'Components',
                url: '/accessories/components',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_accessories_components.jpg'
                }
            },
            {
                name: 'Cutlery Inserts',
                url: '/accessories/cutlery-inserts',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_accessories_cutlery-inserts.jpg'
                }
            },
            {
                name: 'Drawer Boxes',
                url: '/accessories/drawer-boxes',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_accessories_drawer-boxes.jpg'
                }
            },
            {
                name: 'Glass Shelves',
                url: '/accessories/glass-shelves',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_accessories_glass-shelves.jpg'
                }
            },
            {
                name: 'Lighting',
                url: '/accessories/lighting',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_accessories_lighting.jpg'
                }
            },
            {
                name: 'Unit Accessories',
                url: '/accessories/unit-accessories',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_accessories_panels.jpg'
                }
            },
            {
                name: 'Waste Systems',
                url: '/accessories/waste-systems',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_accessories_waste-systems.jpg'
                }
            },
            {
                name: 'Worktop Accessories',
                url: '/accessories/worktops',
                image: {
                    url: 'https://static.diy-kitchens.com/assets/images/menu/category_worktops_solid-wood.jpg'
                }
            },
            // {
            //     name: 'Sink Accessories',
            //     url: '/accessories/sinks',
            //     image: {
            //         url: 'https://static.diy-kitchens.com/categories/sinks-and-taps/category_sinks_1000px.jpg'
            //     }
            // }
        ],
        viewAll: '/accessories',
        template: MegaMenuTemplates.ITEMS
    }
};
