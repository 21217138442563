<div class="image-gallery-container" aria-label="Image Gallery">
    <div class="focussed-image">
        <img [src]="focussedImage.image" (click)="focusImageClick(focussedImage)" aria-label="Focused Image" [alt]="focussedImage.alt || 'Product Image'" />
    </div>
    <ng-container *ngIf="images?.length > 1">
        <div class="thumbnails" role="region" aria-label="Image Thumbnails">
            <img *ngFor="let image of images" [src]="image.image" [ngClass]="{ 'selected': image === selectedImage }" (click)="thumbnailClick(image)"
                [alt]="image.alt || 'Product Image'" [attr.aria-current]="(image === selectedImage) ? 'true' : 'false'" />
        </div>
    </ng-container>
</div>
