@import '/src/theme/variables';

page-basket {
    .basket-id {
        font-size: 14px;
        font-weight: initial;
    }

    .error-box {
        border: 1px solid color('bright-red');
        padding: 5px;
        margin-bottom: 15px;

        h4 {
            color: color('bright-red');
        }
    }

    .basket-header {
        display: flex;
        justify-content: space-between;
    }

    .view-baskets {
        text-align: center
    }

    .rank {
        width: 80px;
        padding: 0 25px;
        border-right: 1px solid #BBB;
    }

    .qty-input {
        width: 100px;
    }

    .group-description {
        display: flex;
        justify-content: flex-start;
        padding: 0;
        height: 50px;
        
        .description {
            display: inline-flex;
            flex-grow: 1;
            align-items: center;
            padding-left: 15px;
            background: color('brand-primary');
            height: 50px;
            color: color('white');
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.04em;
            text-transform: uppercase;
        }
    }

    .sub-section-description {
        display: flex;
        justify-content: flex-start;
        padding: 20px 0;
        background: #CFD8DC;
        text-transform: uppercase;

        .rank {
            padding: 0 15px;
            font-size: 24px;
            font-weight: 600;
            border-right: 1px solid #BBB;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: auto;
        }

        .description {
            padding-left: 15px;
            flex-grow: 1;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.04em;
            display: inline-flex;
            align-items: center;
        }

        .item-total {
            .item-total-text {
                margin-right: 5px;
                font-size: 0.75rem;
                font-weight: 400;
            }

            .item-total-cost {
                margin-right: 15px;
                font-weight: 600;
            }
        }

        .link {
            opacity: 0.6;
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 30px;
            font-size: 0.75rem;
            font-weight: 400;

            .mat-icon {
                vertical-align: middle;
                font-size: 24px;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .sub-section-drawer-upgrade {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        background-color: color('wisteria');

        .drawer-upgrade-text {
            margin: 0;
            color: color('white');
            font-size: 0.875rem;
            font-weight: 400;
        }

        .mdc-button {
            background-color: color('white');
        }
    }

    .section-header {
        display: flex;
        gap: 10px;
        align-items: flex-start;
        margin-bottom: 15px;

        .section-title {
            flex-grow: 1;
            padding: 0 20px;
            width: 100%;
            height: 60px;

            h5 {
                margin: 12px 0;
            }
        }
    }

    .line-item-children {
        background-color: color('ghost');
        color: color('midnight');
        margin-left: 50px;

        .sub-section {
            .sub-section-image {
                img {
                    max-width: 45%;
                    max-height: 150px;

                    &.flip {
                        transform: scaleX(-1);
                    }
                }
            }
        }
    }

    .sub-section {
        display: flex;
        align-items: center;
        padding: 12px 0 24px 0;

        &>div {
            margin-right: 20px;
        }

        .sub-section-image {
            flex: 0 0 25%;
            margin: 0;
            text-align: center;
            width: 100%;

            img {
                max-width: 80%;
                max-height: 200px;

                &.flip {
                    transform: scaleX(-1);
                }
            }

            .mat-icon {
                width: 75px;
                height: 75px;
                color: color('black', 0.6);
                font-size: 75px;
            }
        }

        .sub-section-details {
            display: flex;
            flex-direction: column;
            flex: 0 0 40%;
            margin: 0;
            font-weight: 400;

            table {
                margin: 0 15px;
            }

            th {
                height: 35px;
                font-weight: 600;
                text-align: left;
            }

            td {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 35px;
            }
        }

        .sub-section-field {
            padding: 0 15px;

            p {
                margin: 0;
            }
        }

        .quantity {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.3rem;
            flex: 0 0 20%;
            margin: 0;
            text-align: center;

            p {
                margin-bottom: 5px;
            }

            div {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;

                input[two-digit-input] {
                    width: 5ch;
                }
            }
        }

        .sub-section-cost {
            flex: 0 0 15%;
            font-size: 16px;
            line-height: 1.3rem;
            font-weight: 400;
            min-width: 100px;
            color: color('wisteria');
            margin: 0;
            text-align: left;

            .sub-section-field {
                padding-right: 25px;

                &.offer-container {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                }

                .offer {
                    color: red;
                }

                .strike-through {
                }
            }
        }
    }

    .line-item {
        margin-bottom: 20px;
        margin-left: 50px;

        .item-actions {
            display: flex;
            justify-content: space-between;
            padding: 15px;
            background: rgba(207, 216, 220, 0.4);
            text-align: right;
            text-align: right;

            .item-action {
                flex-shrink: 0;
            }

            .notes {
                text-align: left;

                h5 {
                    margin: 0;
                    font-size: 0.75rem;
                    line-height: 0.75rem;
                }

                .note {
                    display: flex;
                    align-items: center;

                    p {
                        margin-top: 12px;
                    }

                    button {
                        margin: 0;
                        padding: 0;
                    }
                }
            }

            button {
                margin-left: 15px;
            }

            .mdc-button {
                &.delete {
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: 0.1429em;
                    background-color: #dc3545;
                    border-color: #dc3545;
                    color: color('white');

                    .mat-icon {
                        display: none;
                    }
                }
            }
        }
    }

    .section-title {
        font-size: 1.57rem;
        font-weight: 700;
        color: rgba(0, 18, 36, 0.9);
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        line-height: normal;
    }

    .totals {
        width: 100%;
        padding-top: 20px;

        .total {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;

            .total-label {
                font-size: 14px;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.9);
                text-align: left;
                letter-spacing: 0.04em;
                line-height: 24px;
                text-transform: uppercase;
            }

            .total-value {
                font-weight: 400;
                color: rgba(0, 18, 36, 0.9);
                margin: 0;
                text-align: right;
            }

            &.total-grand {
                border-top: 1px solid #EFEFEF;
                padding-top: 36px;
                margin-bottom: 26px;

                .total-value {
                    font-size: 24px;
                    font-weight: 600;
                    color: rgba(126, 100, 197, 1);
                    letter-spacing: 0.04em;
                    line-height: 32px;
                }
            }
        }
    }

    .actions {
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 20px;
        gap: 1.5rem;

        .mat-mdc-outlined-button:not(:disabled) {
            &.delete {
                color: color('white');
            }
        }
    }

    .alerts {
        background-color: color('warning');
        color: color('white');
        text-align: right;

        .alert-message {
            margin-left: 5px;
        }

        p {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 400;
            padding-bottom: 20px;
        }
    }

    .delete {
        background: #C00;
        color: #FFF;
    }

    .to-be-deleted {
        position: relative;
    }

    .to-be-deleted::after {
        content: "";
        position: absolute;
        top: 0; 
        left: 0;
        width: 100%; 
        height: 100%;
        background: rgba(0, 0, 0, 0.5); /* semi-transparency */
        pointer-events: none; /* so it's just a visual layer */
    }

    .basket-title {
        margin-top: 5px;

        h2 {
            font-size: 1.57rem;
            font-weight: 700;
            color: rgba(0, 18, 36, 0.9);
            text-transform: uppercase;
            line-height: normal;
            margin-bottom: 20px;
        }
    }

    .basket-title-outer {
        position: relative;
        margin-bottom: 2rem;
        padding: 1rem 0 0.1rem;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        background: #ffffff;
    }

    .drawer {
        min-width: 100px;
    }

    .pay-ess-banner-section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 90px;

        a {
            display: block;
            cursor: pointer;
        }

        .pay-less-banner-main {
            width: 100%;
            height: auto;

            img {
                max-width: 100%;
                height: auto;
            }

            &.pay-less-banner {
                @media screen and (max-width: 767px) {
                    display: none;
                }
            }

            &.pay-less-banner-mobile {
                display: none;

                @media screen and (max-width: 767px) {
                    display: block;
                }
            }
        }
    }

    .important-notice-section {
        display: flex;
        gap: 48px;
        align-items: center;
        justify-content: flex-start;
        margin-top: 40px;
        padding: 43px 48px;
        background: color('warning');
        border-radius: 4px;

        .mat-icon {
            flex-shrink: 0;
            font-size: 102px;
            width: 114px;
            height: 102px;
            color: #ffffff;
        }

        .imp-notice-content {
            h3 {
                margin-bottom: 8px;
                font-family: 'Lato';
                font-size: 21px;
                font-weight: 700;
                text-align: left;
                letter-spacing: 0.05em;
                line-height: 32px;
                color: #ffffff;
                margin-top: 0;
            }

            p {
                font-family: 'Lato', sans-serif;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.9);
                text-align: left;
                letter-spacing: 0.03em;
                line-height: 28px;
                font-weight: 400;
                margin: 0;
            }
        }
    }

    .total-outer {
        margin-top: 20px;
        border-top: 1px solid #EFEFEF;

        .total-inner {
            margin-left: auto;
            margin-right: 0;
            width: 45%;
        }
    }

    .basket-item-details {
        .mat-mdc-card {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);

            .mat-mdc-card-content {
                padding: 0;
            }
        }
    }

    .mat-drawer-inner-container {
        min-width: 400px !important;
    }

    .item-details-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 32px 0 16px 0;
        margin-bottom: 32px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.9);
        position: sticky;
        top: 0;
        z-index: 9;
        background: #fafafa;
        margin-top: 15px;

        .item-details-header-col {
            font-size: 16px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.9);
            text-align: left;
            letter-spacing: 0.06em;
            line-height: 24px;
            text-transform: uppercase;

            &:first-child {
                flex: 2.85;
            }

            &:nth-child(2) {
                flex: 1;
            }
        }
    }

    .basket-actions {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 15px;

        .chunky-dark {
            max-width: 300px;

            &:nth-child(even) {
                justify-self: end;
            }
        }
    }

    .warnings-container {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        background-color: color('bright-red', 0.5);
        border-radius: 5px;
        margin-top: 20px;
        padding: 43px 48px;

        .warning {
            display: flex;
            grid-gap: 48px;
            transition: height 0.5s ease-out;
            opacity: 1;
            height: 130px;

            .warning-icon {
                min-width: 100px;
                min-height: 100px;
                max-width: 100px;

                .mat-icon {
                    flex-shrink: 0;
                    font-size: 102px;
                    width: 114px;
                    height: 102px;
                    color: #ffffff;
                }
            }

            .warning-body {
                display: flex;
                flex-direction: column;
                justify-self: flex-start;

                .warning-title h3{
                    margin-bottom: 8px;
                    font-family: "Lato";
                    font-size: 21px;
                    font-weight: 700;
                    text-align: left;
                    letter-spacing: 0.05em;
                    line-height: 32px;
                    color: #ffffff;
                    margin-top: 0;
                }

                .warning-label {
                    display: flex;
                    align-items: center;
                    grid-gap: 10px;

                    p {
                        font-family: "Lato", sans-serif;
                        font-size: 16px;
                        color: rgba(255, 255, 255, 0.9);
                        text-align: left;
                        letter-spacing: 0.03em;
                        line-height: 28px;
                        font-weight: 400;
                        margin: 0;
                    }
                    
                    button {
                        max-width: 100px;
                        /*background-color: #dc3545;*/
                    }
                }
            }
        }
    }

    #basket-print-logo {
        display: none;

        @media print {
            display: block;
        }
    }

    @keyframes spinner {
    }

    .spinner:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 30%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 2px solid #ffffff;
        border-top-color: #000000;
        animation: spinner .8s linear infinite;
    }

    .basket-drawer {
        &.mat-drawer-container-has-open {
            position: fixed;
            top: 0;
            height: 100vh;
            left: 0;
            right: 0;
            z-index: 9999;
        }
    }

    @media only screen and (max-width: 768px) {
        .sticky {
            position: initial;
            padding: initial;
            margin: initial;
        }

        .sub-section {
            display: grid;
            grid-template-columns: 1fr 2fr;
            row-gap: 20px;

            .sub-section-field {
                p {
                    text-align: right;
                }
            }
        }

        .sub-section-description {
            flex-wrap: wrap;
            row-gap: 5px;

            .link {
                width: 100%;
                padding-right: 10px;
            }
        }

        .line-item {
            .item-actions {
                .item-action {
                    display: flex;
                    flex-direction: column;
                    row-gap: 10px;
                }
            }
        }

        .total-outer {
            .total-inner {
                min-width: 350px;
            }
        }

        .item-details-header {
            .item-details-header-col {
                display: none;
            }
        }

        .warnings-container {
    
            .warning {
                height: auto;
    
                .warning-body {
    
                    .warning-label {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 480px) {
        .sub-section {
            grid-template-columns: 1fr;

            .sub-section-field {
                p {
                    text-align: right;
                }
            }

            .sub-section-image img {
                max-width: 30%;
            }
        }

        .line-item {
            .item-actions {
                flex-direction: column;
                row-gap: 10px;
            }
        }

        .warnings-container {
    
            .warning {    

                .warning-icon {
                    display: none;
                }
            }
        }
    }
}
