import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AboutShopOnlinePage } from './shop-online.page';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: AboutShopOnlinePage
            },
            {
                path: '**',
                redirectTo: ''
            }
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class AboutShopOnlineRouting { }
