import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppliancesPage } from './appliances.page';
import { ApplianceListingPage } from './listing/appliance-listing.page';
import { ApplianceDetailPage } from './detail/appliance-detail.page';

import { PageNotFoundComponent } from '@app/shared/page-not-found.component';
import { ProductCategoryGuard } from '@app/utilities/guards/product-category.guard';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: AppliancesPage,
                pathMatch: 'full'
            },
            {
                path: ':category',
                component: ApplianceListingPage,
                canActivate: [ProductCategoryGuard],
                data: { reuse: true, vatToggle: true }
            },
            {
                path: ':category/:subCategory',
                component: ApplianceListingPage,
                canActivate: [ProductCategoryGuard],
                data: { reuse: true, vatToggle: true }
            },
            {
                path: ':category/:subCategory/:details',
                component: ApplianceDetailPage,
                data: { vatToggle: true }
            },
            { path: '**', component: PageNotFoundComponent }
        ])
    ],
    exports: [RouterModule]
})
export class AppliancesRouting { }
