@import '/src/theme/variables';

component-product-listing-column {
    .product {
        height: calc(100% - 20px);
        display: flex;
        flex-direction: column;
        padding: 10px;
        background-color: color('white');
        border-radius: 5px;
        position: relative;

        .image {
            display: inline-block;
	        position: relative;
            margin: 0 auto;

            img {
                max-width: 100%;
                height: 150px;

                &.flip {
                    transform: scaleX(-1);
                }
            }
        }

        .title {
            height: 2rem;
            min-height: 2rem;
            margin-bottom: 5px;
            font-size: 1rem;
            font-weight: 600;

            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        
            @supports (-webkit-line-clamp: 2) {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: initial;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            &:hover {
                display: block;
                overflow: visible;
                white-space: inherit;
                height: auto;
            }
        }

        .small {
            font-size: 0.8rem;
            text-transform: uppercase;
        }

        .selected-size {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 5px;
            font-weight: 400;

            span {
                background-color: lightgrey;
                padding: 7px;
                width: 33%;
                font-size: 0.9rem;
                text-align: center;
            }
        }

        .link-style {
            margin: 10px 0;
            font-size: 0.875rem;
            font-weight: 400;
            text-align: right;
            text-decoration: underline;
        }

        .width-container {
            height: 60px;
            display: flex;
            align-items: center;

            .simple-select {
                width: 100%;
                border: 2px solid color('copy-border');
                border-radius: 5px;
            }
        }

        .config {
            display: flex;
            flex-direction: row;
            gap: 2px;
            margin: 10px 0;

            button {
                width: 50%;
                padding: 10px;

                &.selected {
                    border: 1px solid color('brand-primary');
                }
            }

            &.soft-close, &.dry-assembled {
                align-items: center;
                gap: 10px;

                label {
                    width: 100%;
                    font-size: 0.75rem;
                    word-wrap: normal;
                }

                .options {
                    width: 100%;
                    display: flex;
                    justify-content: space-evenly;
                    gap: 10px;

                    .option {
                        display: flex;
                        align-items: center;

                        mat-icon {
                            color: color('brand-primary');
                            font-size: 2rem;
                            width: 2rem;
                            height: 2rem;
                        }

                        .label {
                            padding-left: 3px;
                            font-size: 0.875rem;
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        .dims {
            margin: 10px 0;

            button {
                width: 100%;
                padding: 10px;
                margin-bottom: 10px;

                &.selected {
                    border: 1px solid color('brand-primary');
                }
            }
        }

        .drawer-upgrade {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;
            // background-color: color('wisteria');
    
            .drawer-upgrade-text {
                color: color('white');
                font-size: 0.875rem;
                font-weight: 400;
            }
    
            .mdc-button {
                background-color: color('white');
                width: 100%;
            }
        }

        .align-bottom {
            margin: auto 0 0 0;

            .brand-logo {
                margin-bottom: 20px;

                img {
                    max-height: 40px;
                }
            }

            .offers {
                display: flex;
                margin-bottom: 15px;

                .offer {
                    &+.offer {
                        margin-left: 15px;
                    }

                    &:hover {
                        p {
                            text-decoration: underline;
                        }
                    }

                    p {
                        display: flex;
                        align-items: center;
                        margin: 0;
                        padding: 5px 10px;
                        border-radius: 5px;
                        color: white;
                        font-weight: 400;
                    }
                }
            }

            .price {
                display: flex;
                flex-direction: column;
                margin: 10px 0;
                font-size: 1.2rem;

                .offer::before {
                    content: 'NOW ';
                    font-size: 0.9rem;
                }
                
                .strike-through {
                    margin: 0;
                    font-size: 1rem;
                    
                    .currency {
                        text-decoration: line-through;
                    }
                }
            }

            .actions {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 10px;

                .select-style {
                    display: flex;
                    gap: 10px;

                    >* {
                        max-width: 50%;
                    }
                }

                .basket-actions {
                    display: flex;
                    justify-content: flex-end;
                    gap: 10px;

                    .quantity {
                        max-width: 60px;
                    }
                }
            }
        }
    }
}
