import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { MergeUniquePipe } from '@app/pipes';

import { CatalogueService } from '@app/services/catalogue';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';

@Component({
    selector: 'page-worktops',
    templateUrl: './worktops.page.html',
    styleUrls: ['./worktops.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class WorktopsPage implements OnInit {
    public loaded = false;

    public tags: string[] = ['diy kitchens - website', 'worktops'];
    public categories = [];
    public items: any;

    private mergeUnique = new MergeUniquePipe();

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) {
        this.navigationService.setNavigation({
            title: 'Worktops',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { property: 'og:description', content: 'See our range of Worktops available at trade prices.' },
                { name: 'Description', content: 'See our range of Worktops available at trade prices.' },
            ],
            routeHistory: [
                { title: 'Worktops', route: '/worktops' },
            ]
        });
    }

    ngOnInit() {
        this.catalogueService
            .getWorktops()
            .then((worktops: any) => {
                if (worktops) {

                    let categories = this.mergeUnique.transform(worktops.map(worktop => worktop.cat));

                    const index = categories.indexOf('Square Edge Laminate');
                    if (index > -1) {
                        categories.splice(index, 1);
                    }
                    this.categories = categories.map(category => {
                        this.items = worktops.filter(product => product.cat === category);
                        if (category === 'Standard Laminate') {
                            this.items = worktops.filter((product) => {
                                return product.cat === 'Standard Laminate' || product.cat === 'Square Edge Laminate';
                            });
                        } else {
                            this.items = worktops.filter((product) => product.cat === category);
                        }

                        let itemCount = this.items.length;

                        return {
                            name: category,
                            route: category.toLowerCase().replace(/ /g, '-'),
                            tag: category.toLowerCase().replace(/ /g, ' '),
                            itemCount: itemCount,
                            countName: 'worktops'
                        };
                    });
                    this.loaded = true;
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

}
