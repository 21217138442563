import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { StringHelper } from '@app/utilities/helpers';

import { BasketService } from '@app/services/basket';
import { BasketItemHanding, IBasketUpdateItem } from '@app/services/basket/models';
import { CatalogueService } from '@app/services/catalogue';
import { HandingType, ProductType } from '@app/services/catalogue/models';
import { ICabinet } from '@app/services/catalogue/models/product.models';
import { DialogService } from '@app/services/dialog';

@Component({
    selector: 'component-change-cabinet-options-dialog',
    templateUrl: './change-cabinet-options.dialog.component.html',
    styleUrls: ['./change-cabinet-options.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ChangeCabinetOptionsDialogComponent {
    public changeOptionsForm: FormGroup;

    public ProductType = ProductType;

    public item: any;
    public carcaseColours: any;

    public isHanded: boolean;
    public needSoftClose: boolean;

    constructor(
        private formBuilder: FormBuilder,
        private basketService: BasketService,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private dialogRef: MatDialogRef<ChangeCabinetOptionsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data) {
            if (data.item) {
                this.item = data.item;
            }

            if (data.carcaseColours) {
                this.carcaseColours = data.carcaseColours;
            }
        }

        this.changeOptionsForm = this.formBuilder.group({
            carcaseColour: new FormControl(this.item?.carcaseColour || null),
            softClose: new FormControl(StringHelper.toBoolean(this.item?.softClose)),
            handing: new FormControl((this.item?.handing) || null),
            isDryAssembled: new FormControl(StringHelper.toBoolean(this.item?.isDryAssembled))
        });

        this.isHanded = StringHelper.toBoolean(this.item?.handed);
        this.needSoftClose = !!this.item?.hingeCost;
    }

    public updateItem() {
        const params: IBasketUpdateItem = {
            carcaseColour: this.changeOptionsForm.controls.carcaseColour.value,
            softClose: this.changeOptionsForm.controls.softClose.value,
            handing: this.changeOptionsForm.controls.handing.value,
            isDryAssembled: this.changeOptionsForm.controls.isDryAssembled.value,
            qty: this.item.qty
        };

        this.basketService.updateItem(this.item.id, params)
            .then(() => this.close())
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public close() {
        this.dialogRef.close();
    }

    public hingeWording(item: ICabinet): string {
        this.catalogueService.setHandingType(item);
        return this.catalogueService.getHingeWording(item);
    }
}
