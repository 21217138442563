<section class="dark content-header-background worktops-header-bg">
    <div class="contained">
        <h1>Worktops</h1>
        <div class="sub-head">
            Every bit as important as the doors and colours you select is your choice of kitchen
            worktop surface. To match your budget and lifestyle, we offer a variety of options; from the cool
            beauty of natural granite to ultra-durable Dekton.
        </div>
    </div>
</section>
<component-product-category *ngIf="loaded" type="worktops" [categories]="categories"></component-product-category>