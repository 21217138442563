import { IAccessory } from './accessory.model';
import { IAppliance } from './appliance.model';
import { ICabinet } from './cabinet.model';
import { IDrawerbox } from './common';
import { IDoor } from './door.model';
import { IHandle } from './handle.model';
import { IRangeProduct } from './range_product.model';
import { ISinkAndTaps } from './sink_and_taps.model';
import { IWorktop } from './worktop.model';

const cabinetCategoryLinks = ['base', 'corner-base', 'wall', 'corner-wall', 'appliance-housings', 'tall', 'tall-corner', 'dresser', 'tall-units---medium'];

/**
 * Type guard for IAccessory based on unique fields
 * @param obj any
 * @returns true of obj is of type IAccessory
 */
export function isAccessory(obj: any): obj is IAccessory {
    return 'incfts' in obj &&
        'upsell_tags' in obj &&
        'supplier' in obj;
}

/**
 * Type guard for IAppliance based on unique fields
 * @param obj any
 * @returns true of obj is of type IAppliance
 */
export function isAppliance(obj: any): obj is IAppliance {
    return 'brand' in obj &&
        'colour_finish' in obj &&
        'generic_appliance_id' in obj;
}

/**
 * Type guard for ICabinet based on unique fields
 * @param obj any
 * @returns true of obj is of type ICabinet
 */
export function isCabinet(obj: any): obj is ICabinet {
    return 'cab_price' in obj &&
        'if_available' in obj &&
        'doors' in obj &&
        'categoryLink' in obj &&
        cabinetCategoryLinks.includes(obj.categoryLink);
}

/**
 * Type guard for IDoor based on unique fields
 * @param obj any
 * @returns true of obj is of type IDoor
 */
export function isDoor(obj: any): obj is IDoor {
    return 'range_id' in obj &&
        'isPrimed' in obj &&
        'appliance_door_style' in obj;
}

/**
 * Type guard for IDrawerbox based on unique fields
 * @param obj any
 * @returns true of obj is of type IDrawerbox
 */
export function isDrawerbox(obj: any): obj is IDrawerbox {
    return 'box_code' in obj &&
        'cost_type' in obj &&
        'type' in obj;
}

/**
 * Type guard for IHandle based on unique fields
 * @param obj any
 * @returns true of obj is of type IHandle
 */
export function isHandle(obj: any): obj is IHandle {
    return 'code' in obj &&
        'product_code' in obj &&
        !('admin_only' in obj) &&
        !('unit_code' in obj) &&
        !('brand' in obj) &&
        !('thumb_img' in obj);
}

/**
 * Type guard for IRangeProduct based on unique fields
 * @param obj any
 * @returns true of obj is of type IRangeProduct
 */
export function isRangeProduct(obj: any): obj is IRangeProduct {
    return (
        ('appliance_door_style' in obj &&
            'cat_spec' in obj &&
            'size_tag' in obj) ||
        ('aperture' in obj &&
            !('component_1' in obj)) &&
        !cabinetCategoryLinks.includes(obj.categoryLink)
    );
}

/**
 * Type guard for ISinkAndTaps based on unique fields
 * @param obj any
 * @returns true of obj is of type ISinkAndTaps
 */
export function isSinkAndTaps(obj: any): obj is ISinkAndTaps {
    return 'supplier' in obj &&
        'genid' in obj;
}

/**
 * Type guard for IWorktop based on unique fields
 * @param obj any
 * @returns true of obj is of type IWorktop
 */
export function isWorktop(obj: any): obj is IWorktop {
    return 'cat_type' in obj &&
        'colour_filter' in obj &&
        'texture_filter' in obj;
}

/**
 * Determines the interface type of obj
 * @param obj any
 * @returns InterfaceTypes
 */
export function typeOfItem(obj: any): 'IAccessory' | 'IAppliance' | 'ICabinet' | 'IDoor' | 'IDrawerbox' |
    'IHandle' | 'IRangeProduct' | 'ISinkAndTaps' | 'IWorktop' | 'unknown' {
    if (isAccessory(obj)) return 'IAccessory';
    if (isAppliance(obj)) return 'IAppliance';
    if (isCabinet(obj)) return 'ICabinet';
    if (isDoor(obj)) return 'IDoor';
    if (isDrawerbox(obj)) return 'IDrawerbox';
    if (isHandle(obj)) return 'IHandle';
    if (isRangeProduct(obj)) return 'IRangeProduct';
    if (isSinkAndTaps(obj)) return 'ISinkAndTaps';
    if (isWorktop(obj)) return 'IWorktop';
    return 'unknown';
}
