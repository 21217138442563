import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'component-solid-surface-config-cutout-options-dialog',
    templateUrl: './cutout-options.dialog.component.html',
    styleUrls: ['./cutout-options.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SolidSurfaceConfigCutoutOptionsDialogComponent {
    public type: any = false;
    public hobs: any;
    public hobExtras: any;
    public sinks: any;
    public sinkExtras: any;
    public misc: any;
    public drainers: any;
    public piece: any;

    private options = [];

    constructor(
        private dialogRef: MatDialogRef<SolidSurfaceConfigCutoutOptionsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.piece = this.data.piece;

        let cutoutOpts = this.data.graniteOptions.filter(opt => opt.img.match(/^cutouts/i));
        const alpha = (a, b) => (a.product_name || '').localeCompare(b.product_name || '');
        this.hobs = cutoutOpts.filter(opt => opt.type === 'hobs').sort(alpha);
        this.hobExtras = cutoutOpts.filter(opt => opt.type === 'hobextras').sort(alpha);
        this.sinks = cutoutOpts.filter(opt => opt.type === 'sinks').sort(alpha);
        this.sinkExtras = cutoutOpts.filter(opt => opt.type === 'sinkextras').sort(alpha);
        this.misc = cutoutOpts.filter(opt => opt.type === 'cutoutother').sort(alpha);
        this.drainers = this.data.graniteOptions.filter(opt => opt.type === 'drainers').sort(alpha);
    }

    public cutout(type) {
        this.type = type;
    }

    public addRange(width) {
        this.dialogRef.close({
            gap: width
        });
    }

    public add(opt?, next?) {
        if (opt) {
            this.options.push(opt);
            if (next) {
                return (this.type = next);
            }
        } else if (next) {
            this.dialogRef.close({});
        }

        this.dialogRef.close({
            options: this.options
        });
    }
}
