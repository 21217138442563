import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';

import { KitchensRouting } from './kitchens.routing';
import { KitchensPage } from './kitchens.page';
import { KitchensListingsPage } from './range-listing/range-listing.page';
import { RangeDetailPage } from './range-detail/range-detail.page';

@NgModule({
    imports: [
        SharedModule,
        KitchensRouting
    ],
    declarations: [
        KitchensPage,
        KitchensListingsPage,
        RangeDetailPage
    ]
})
export class KitchensPageModule { }
