@import '/src/theme/variables';

page-appliances {
    
    h1 {
        margin-top: 0;
        margin-bottom: 2rem;
        font-size: 3.157rem;
        font-weight: 300;
        line-height: 4.209rem;
    }

    .intro {
        img {
            width: 100%;
        }

        button {
            padding: 5px 30px;
        }
    }
}
