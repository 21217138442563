@import '/src/theme/variables';

component-product-image-gallery-dialog {

    .gallery-container {
        padding: 10px;

        .focussed-image-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
                max-width: 80%;
                max-height: 80vh;
                margin: 0 auto;
                justify-self: center;
            }

            .arrow-control {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: color('brand-dark');
                border-radius: 8px;
                color: color('white');
                font-size: 2rem;
                width: 6vw;
                height: 6vw;
                max-width: 70px;
                max-height: 70px;
                min-width: 50px;
                min-height: 50px;
                cursor: pointer;
            }
        }

        .thumbnails {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;

            img {
                border: 1px solid color('brand-dark');
                max-width: 15%;
                max-height: 100px;
                cursor: pointer;
            }

            .selected {
                border: 2px solid color('brand-primary');
            }

        }
    }

    @media only screen and (max-width: 480px) {
        .gallery-container {
            .focussed-image-container {

                img {
                    width: 100%;
                }

                .arrow-control {
                    display: none;
                }
            }
        }

    }

}