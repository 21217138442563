import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';

import { CatalogueService } from '@app/services/catalogue';
import { DialogService } from '@app/services/dialog';

@Component({
    selector: 'component-product-category',
    templateUrl: './product-category.component.html',
    styleUrls: ['./product-category.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ProductCategoryComponent implements OnInit {
    @Input() categories: any;
    @Input() type: string;

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        this.catalogueService.getCategories(this.type)
            .then((categoryContent) => {
                if (categoryContent) {
                    this.categories.map(category => {
                        if (this.type === 'worktops') {
                            if (categoryContent[category.route]) {
                                category.name = categoryContent[category.route].name;
                                category.desc = categoryContent[category.route].desc;
                                category.rank = categoryContent[category.route].rank;
                                category.image = categoryContent[category.route].image;
                                if (category.route === 'dekton' || category.route === 'quartz' || category.route === 'granite' || category.route === 'ceramic') {
                                    category.route = '/solid-surfaces/' + category.route + '-worktops'
                                } else if (category.route === 'square-edge-laminate' || category.route === 'standard-laminate') {
                                    category.route = '/laminate-worktops'
                                } else if (category.route === 'solid-wood') {
                                    category.route = '/wood-worktops'
                                }

                            } else {
                                console.warn('missing category', this.type, category.route);
                            }
                        } else {
                            if (categoryContent[category.route]) {
                                category.name = categoryContent[category.route].name;
                                category.desc = categoryContent[category.route].desc;
                                category.rank = categoryContent[category.route].rank;
                                category.image = categoryContent[category.route].image;
                            } else {
                                console.warn('missing category', this.type, category.route);
                            }
                        }
                    });

                    this.categories.sort((a, b) => a.rank - b.rank);
                } else {
                    console.warn('category data needs for ', this.type);
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
