import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deflate, inflate } from 'pako';

@Injectable()
export class CompressionInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let handledReq = req;

        try {
            if (req.method !== 'GET') {
                const compress = req.headers.has('Compress');
                const compressKey = req.headers.get('Compress') || null;

                if (compress) {
                    let body = JSON.parse(JSON.stringify(req.body));
                    if (compressKey && compressKey !== '') {
                        if (body[compressKey]) {
                            body[compressKey] = btoa(deflate(JSON.stringify(body[compressKey])).toString());
                        }
                    } else {
                        body = btoa(deflate(JSON.stringify(body)).toString());
                    }

                    handledReq = req.clone({ body });
                }
            }
        } catch (error) { }

        return next.handle(handledReq).pipe(
            map((event: HttpEvent<any>) => {
                let eventResponse = event;

                if (event instanceof HttpResponse) {
                    const compress = event.headers.has('Compress');
                    const compressKey = event.headers.get('Compress') || '';

                    if (compress) {
                        try {
                            let response = event.body;
                            if (response && response.body) {
                                if (compressKey && compressKey !== '') {
                                    if (response.body[compressKey]) {
                                        let uint8Array: any;
                                        let indirectEval = eval;
                                        indirectEval('uint8Array = [' + atob(response.body[compressKey]) + ']');  // eslint-disable-line
                                        // eval('uint8Array = [' + atob(response.body[compressKey]) + ']');  // eslint-disable-line

                                        response.body[compressKey] = JSON.parse(
                                            inflate(uint8Array, { to: 'string' })
                                        );
                                    }
                                } else {
                                    let uint8Array: any;
                                    let indirectEval = eval;
                                    indirectEval('uint8Array = [' + atob(response.body) + ']'); // eslint-disable-line
                                    // eval('uint8Array = [' + atob(response.body) + ']'); // eslint-disable-line

                                    response.body = JSON.parse(inflate(uint8Array, { to: 'string' }));
                                }
                            }

                            eventResponse = event.clone({ body: response });
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }

                return eventResponse;
            })
        );
    }
}
