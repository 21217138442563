<section class="dark accessories-header-bg">
    <div class="contained">
        <h3>Order - {{ order.orderNumber }}</h3>
        <mat-vertical-stepper *ngIf="loaded" [linear]="true" animationDuration="0" #stepper (selectionChange)="stepperUpdate($event)">
            <mat-step [stepControl]="detailsFormGroup">
                <ng-template matStepLabel>DELIVERY & BILLING ADDRESS</ng-template>
                <component-customer-details [stepper]="stepper"
                [order]="order" [formGroup]="detailsFormGroup">
                </component-customer-details>
            </mat-step>
  
            <mat-step [stepControl]="deliveryFormGroup">
                <ng-template matStepLabel>CHOOSE DELIVERY TYPE & DATE</ng-template>
                <component-delivery-options *ngIf="detailsFormGroup.valid" [stepper]="stepper" [order]="order" [formGroup]="deliveryFormGroup" (setAccessSurchargeCheck)="setAccessSurchargeCheck($event)">
                </component-delivery-options>
            </mat-step>
       
            <mat-step #accessStep [stepControl]="accessFormGroup" *ngIf="accessSurchargeCheckRequired">
                <ng-template matStepLabel>DELIVERING YOUR ORDER</ng-template>
                <component-delivery-access *ngIf="deliveryFormGroup.valid" [step]="accessStep" [stepSelected]="stepper.selected" [stepper]="stepper" [formGroup]="accessFormGroup" [order]="order"></component-delivery-access>
            </mat-step>
        </mat-vertical-stepper>
    </div>
</section>