import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthService } from './auth.service';
import { AuthCustomerService } from './auth-customer.service';
import { AuthStaffService } from './auth-staff.service';
import { AuthTokenInterceptor } from './interceptors';

@NgModule({
    providers: [
        AuthService,
        AuthCustomerService,
        AuthStaffService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true }
    ]
})
export class AuthServiceModule { }
