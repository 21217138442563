import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { BasketRouting } from './basket.routing';
import { BasketPage } from './basket.page';
import { LinkBasketPage } from './link-basket/link-basket.page';

import { BasketHistoryComponent } from './components/basket-history';

@NgModule({
    imports: [
        SharedModule,
        BasketRouting
    ],
    declarations: [
        BasketPage,
        LinkBasketPage,
        BasketHistoryComponent
    ]
})
export class BasketPageModule { }
