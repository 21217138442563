import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { CatalogueService } from '@app/services/catalogue';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';

@Component({
    selector: 'page-units',
    templateUrl: './units.page.html',
    styleUrls: ['./units.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class UnitsPage implements OnInit {
    public categories;

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) {
        this.navigationService.setNavigation({
            title: 'Kitchen Units',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'Description', content: `See our range of Kitchens & kitchen units. All of our Kitchens & kitchen units are available at trade prices.` },
                { property: 'og:locale', content: 'en_GB' },
                { property: 'og:type', content: 'website' },
                { property: 'og:site_name', content: 'DIY Kitchens' },
                { property: 'og:url', content: 'Kitchen Units - DIY Kitchens ' },
                { property: 'og:title', content: 'Kitchen Units - DIY Kitchens ' },
                { property: 'og:description', content: `See our range of Kitchens & kitchen units. All of our Kitchens & kitchen units are available at trade prices.` },
                { property: 'og:image', content: 'https://static.diy-kitchens.com/assets/images/menu/units/base_curved_unit.jpg' },
                { property: 'og:image:secure_url', content: 'https://static.diy-kitchens.com/assets/images/menu/units/base_curved_unit.jpg' },
                { property: 'og:image:width', content: '600' },
                { property: 'og:image:height', content: '400' }
            ],
            routeHistory: [{ title: 'Units', route: '/kitchen-units' }]
        });
    }

    ngOnInit() {
        this.catalogueService.getCabinetsFlat()
            .then((cabinets: any) => {
                if (cabinets) {
                    let categories = {};
                    cabinets.forEach((cabinet) => {
                        if (!categories[cabinet.categoryLink]) {
                            categories[cabinet.categoryLink] = 0;
                        }

                        categories[cabinet.categoryLink]++;
                    });

                    this.categories = Object.keys(categories).map((categoryLink) => {
                        return {
                            route: categoryLink,
                            itemCount: categories[categoryLink],
                            countName: 'units'
                        };
                    });
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
