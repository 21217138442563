import { Injectable } from '@angular/core';

import { CanComponentDeactivate } from './models';

@Injectable()
export class CanDeactivateGuard {
    public canDeactivate(component: CanComponentDeactivate) {
        return component.canDeactivate ? component.canDeactivate() : true;
    }
}
