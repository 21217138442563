import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mergeUnique',
    standalone: false
})
export class MergeUniquePipe implements PipeTransform {
    transform(args: any[][]): any[] {
        /**
         * Merge the nested arrays into a single array
         * This array may contain duplicates
         * [].concat(...args)
         *
         * Set will create a new array Set
         * It will remove any duplicates
         * [...new Set([...])]
         *
         * Sorts the array
         * .sort()
         */
        // es6+
        // return [...new Set([...[].concat(...args)])].sort();

        // es5
        const mergedItems = [];
        [].concat(...args).forEach((item) => {
            if (mergedItems.indexOf(item) === -1) {
                mergedItems.push(item);
            }
        });

        return mergedItems.sort();
    }
}
