export enum MessageGroup {
    SOFTWARE_DEVELOPMENT = 1,
    HR = 2,
    AFTERSALES = 3,
    IT_HARDWARE = 4,
    UNIT_34_PICKING = 5,
    UNIT_30_GOODS_IN = 6,
    UNIT_30_PREPPING = 7,
    UNIT_30_HAND_SPRAY = 9,
    UNIT_30_MITO = 10,
    UNIT_30_GIARDINA = 11,
    UNIT_30_IGIOTTO = 13,
    UNIT_30_ROCTRE = 15,
    UNIT_30_QC = 17,
    UNIT_30_DESPATCH = 19,
    SHERBURN_DESPATCH = 20,
    PROCUREMENT_STOCK_PURCHASING = 21,
    UNIT_25 = 22,
    PRESALES = 23,
    ORDER_PROCESSING = 24,
    DELIVERIES = 25,
    QUOTES = 26,
    PLANNER = 27,
    MISSING = 28,
    SUPPLIERS = 29,
    CALLBACK = 30,
    COMPLAINTS = 31,
    RETURNS = 32,
    ACCOUNTS = 33,
    CUSTOMER_SUPPORT = 34,
    PIT = 35,
    CAREERS = 36,
    SURVEY = 37,
    IT_PRODUCT_DATA = 38,
    IT_MCS_CONFIG = 39,
    IT_SOFTWARE = 40,
    DELIVERY_SURCHARGE = 41,
    SHOWROOM = 42,
    FEEDBACK = 43,
    REMEDIALS = 44
}
