<header class="content-contained">
    <h1>Which? Best Buy</h1>
    <p>We have some very exciting news to tell you that Which? have just published in their Best and worst kitchen brands report for 2023!</p>
    <p>Which? surveyed thousands of kitchen buyers and tested kitchens in their lab to find the best and worst fitted kitchen brands and the figures are now in! Read on to learn more...</p>
</header>

<hr class="narrow" />

<section class="content-contained">
    <h2>Exciting News!</h2>

    <img class="which-logo" src="https://static.diy-kitchens.com/assets/images/which/which-logo-kitchen-units-may-260.png" alt="Which logo"/>
    <p>We are thrilled to announce that we have gained the prestigious <em><strong>Which? Best Buy for our Innova kitchen units!</strong></em> This accolade is a testament to our commitment to providing our customers with some of the <em><strong>highest quality kitchens</strong></em> on the market, outstanding customer satisfaction and value for money.</p>
    <p>We are proud to be up there with<em><strong> some of the biggest names</strong></em> in the kitchen industry and we are grateful to our customers for their continued support. We will continue to work hard to ensure that we offer the best possible products and services to our customers.</p>
    <p>The Which? Best Buy accolade is synonymous with <em><strong>quality and excellence</strong></em>, and it is only given to products and services that deliver the best results in expert assessments.</p>
    <p>An overall score of 75% or above; a customer score of at least 70% and at least 3 stars in all survey categories, is required for a Which? Best Buy. We achieved this and we are now recognized as one of the <em><strong>highest-rated kitchen retailers</strong></em> in the UK.</p>
    <p><em>"We are thrilled with the Which? Best Buy for our Innova kitchen units," said DIY Kitchens' Marketing Director, Matthew Ellis.</em></p>
    <p><em>"This recognition is a testament to our commitment to providing our customers with high quality kitchens that are not only affordable but also exceed their high expectations. We are delighted that our efforts have been recognised by gaining this prestigious Which? Best Buy endorsement."</em></p>

    <div class="button-wrapper">
        <a routerLink="/kitchens/allstyles">
            <button mat-flat-button color="primary">See Our Range Of Which? Best Buy Innova Kitchens</button>
        </a>
        <a routerLink="/samples/door-samples">
            <button mat-flat-button color="primary">Get 3 FREE Door Samples</button>
        </a>
    </div>
</section>

<hr class="narrow" />

<section class="content-contained">
    <h2>Which? Trusted Traders</h2>

    <div class="panel">
        <div class="panel-left">
            <p>Did you know that Which? have their very own Trusted Traders that they have assesed and endorsed?</p>
            <p>All traders listed on the Which? website have successfully completed the comprehensive evaluation conducted by their team of trading standards experts.</p>
            <p>Moreover, each customer review you come across on their site has been carefully authenticated by their dedicated moderators, to ensure its authenticity.</p>
            <p>If you are looking to get someone to fit your kitchen then why not give their Trusted Traders a quick look first.</p>
            <p>Just type in your postcode on the right and the results will come back instantly.</p>
        </div>
        <div class="panel-right">
            <iframe style="min-height: 450px; border: none;" src="https://trustedtraders.which.co.uk/widgets/search/traders?dark=true&amp;partner=DIYKITCHENS&amp;businesses=false&amp;compact=true&amp;service=kitchen fitters" target="new"></iframe>
        </div>
    </div>
</section>

<component-latest-offers></component-latest-offers>