import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';

import { Config } from '@app/config';

import { BasketService } from '@app/services/basket'
import { DialogService } from '@app/services/dialog';
import { BannerLocation } from '@app/services/marketing/models';
import { NavigationService } from '@app/services/navigation';
import { ViewType } from '@app/services/navigation/models';
import { StorageService } from '@app/services/storage';

type Section = { name: string, route: string }
declare var fbq: any; // Facebook Pixel's track function implemented on the global Window obj

@Component({
    selector: 'app-root',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AppComponent implements OnInit {
    public fixedHeader = false;
    public fixedFilters = false;

    public menu = [];
    public section: string = null;
    public sections: Section[] = [
        {
            name: 'kitchens',
            route: '/kitchens'
        },
        {
            name: 'units',
            route: '/kitchen-units'
        },
        {
            name: 'doors',
            route: '/kitchen-doors'
        },
        {
            name: 'appliances',
            route: '/appliances'
        },
        {
            name: 'worktops',
            route: '/worktops'
        },
        {
            name: 'samples',
            route: '/samples'
        },
        {
            name: 'accessories',
            route: '/accessories'
        }
    ];

    public BannerLocation = BannerLocation;

    public viewType$: Observable<ViewType>;

    constructor(
        private config: Config,
        private basketService: BasketService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private storageService: StorageService,
        private router: Router
    ) {
        this.loadUserSettings();
    }

    ngOnInit() {
        this.viewType$ = this.navigationService.viewType;

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // Facebook Pixel's 'track' function for page views
                if (typeof fbq !== 'undefined') {
                    fbq('track', 'PageView');
                }

                // Set the canonical link in the head of the document
                const urlWithNoQueryParams = event.urlAfterRedirects?.split('?')[0] || null;
                this.navigationService.setCanonical(urlWithNoQueryParams);
            }
        });

        if (this.config.isBrowser) {
            this.basketService.recoverLocal()
                .catch((error) => console.error(error));
        }
    }

    private loadUserSettings() {
        this.config.storageService = this.storageService;
        this.config.dialogService = this.dialogService;
        this.config.loadSettings();
    }
}
