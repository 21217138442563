@import '/src/theme/variables';

page-orders {
    h3 {
        line-height: initial;
    }

    h4 {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: initial;
    }

    p {
        margin-bottom: 0;
        font-size: 0.75rem;
        line-height: initial;
    }

    .pill-notice {
        & + .pill-notice {
            margin-top: 5px;
        }
    }

    .panels {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .panel {
            & + .panel {
                display: flex;
                flex-direction: column;
                margin-left: 15px;
            }
        }
    }

    table {
        &.mat-mdc-table {
            border-collapse: separate;
            -webkit-border-vertical-spacing: 15px;

            .mdc-data-table__row {
                &:last-child {
                    .mat-mdc-cell {
                        border-bottom: 1px solid;
                    }
                }
            }
        }
    }

    .orders-table {
        background: transparent;
        width: 100%;

        .orders-table-header {
            th {
                padding-left: 10px;
                border-top: 1px solid color('ultima');
                border-bottom: 1px solid color('ultima');
                background: color('white');

                &:first-of-type {
                    border-left: 1px solid color('ultima');
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                &:last-of-type {
                    border-right: 1px solid color('ultima');
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
        }

        .orders-table-row {
            position: relative;
            margin-top: 15px;
            box-shadow: 5px 5px 5px 0 color('black', 0.2);
            border-radius: 5px;
            background: color('white');

            &.removed {
                td {
                    background-color: color('error', 0.2);
                }
            }

            &.not-remedial {
                td {
                    background-color: color('black', 0.2);
                }
            }

            &.exported {
                td {
                    background-color: color('diy-light-blue', 0.2);
                }

                .order-container,
                .row-actions {
                    padding-bottom: 85px;
                }
            }

            .mat-icon {
                width: 16px;
                height: 16px;
                font-size: 16px;
            }

            td {
                border-top: 1px solid color('ultima');
                border-bottom: 1px solid color('ultima');
                background: color('white');

                &:first-of-type {
                    border-left: 1px solid color('ultima');
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                &:last-of-type {
                    border-right: 1px solid color('ultima');
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }

            .order-container {
                display: flex;
                flex-direction: column;
                padding: 10px;

                &.order-details-column {
                    .order-details {
                        max-width: 300px;

                        .order-detail {
                            max-width: 100%;
                        }
                    }
                }

                .order-details-container {
                    min-height: 100px;

                    .order-details {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 5px;

                        .order-detail {
                            & + .order-detail {
                                margin-left: 10px;
                                padding-top: 3px;
                            }

                            .plan-name {
                                overflow: hidden;
                                max-width: 150px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            h3 {
                                display: flex;

                                .order-icon {
                                    margin-left: 5px;

                                    .order-icon-orderplaced {
                                        width: 20px;
                                    }

                                    .order-icon-presales {
                                        width: 40px;
                                    }
                                }
                            }
                        }

                        h3 {
                            margin: 0;
                            font-size: 1.25rem;
                        }

                        p {
                            font-size: 0.75rem;
                        }

                        .order-date {
                            white-space: nowrap;

                            span {
                                font-size: 0.5rem;
                            }
                        }
                    }

                    .order-plan-id {
                        margin-top: 10px;
                        margin-bottom: 5px;
                        font-size: 0.75rem;
                        white-space: nowrap;
                    }

                    h4 {
                        margin: 0;
                        text-transform: uppercase;
                    }

                    .plain-button {
                        margin: 0;
                        padding: 0;
                    }

                    .customer-details {
                        margin-bottom: 10px;
                    }

                    .info-item {
                        display: flex;
                        align-items: center;
                        font-size: 0.75rem;

                        & + .info-item {
                            margin-top: 3px;
                            padding-top: 3px;
                            border-top: 1px solid color('copy-border');
                        }

                        strong {
                            padding-left: 3px;
                        }
                    }
                }

                .order-actions-container {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;
                    min-height: 36px;

                    .mat-stroked-button:not(.mat-button-disabled) {
                        border-color: color('ultima');
                    }

                    button {
                        & + button {
                            margin-left: 5px;
                        }
                    }

                    .mat-button-wrapper {
                        display: flex;
                        align-items: center;
                        font-weight: 100;

                        .mat-icon {
                            margin-right: 5px;
                            width: 18px;
                            height: 18px;
                            font-size: 18px;
                        }
                    }
                }

                .order-removed {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    right: 1px;
                    bottom: 1px;
                    left: calc(67px + 24px + 24px);
                    padding: 10px;
                    border-bottom-right-radius: 5px;
                    background-color: color('error');
                    height: 55px;
                    color: color('white');

                    .order-removed-text {
                        font-size: 1.5rem;
                    }

                    .order-removed-details {
                        display: flex;
                        align-items: center;

                        .order-removed-at {
                            margin-right: 10px;
                            font-size: 0.5rem;
                        }
                    }
                }

                .order-exported {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    right: 1px;
                    bottom: 1px;
                    left: calc(67px + 24px + 24px);
                    padding: 10px;
                    border-bottom-right-radius: 5px;
                    background-color: color('diy-light-blue');
                    height: 55px;
                    color: color('white');

                    .order-exported-text {
                        font-size: 1.5rem;
                    }

                    .order-exported-details {
                        display: flex;
                        align-items: center;

                        .order-detail {
                            margin-right: 10px;
                            font-size: 0.5rem;
                        }
                    }
                }
            }

            .actions {
                display: flex;
                flex-direction: column;

                .action {
                    margin: 3px 0;

                    .action-button {
                        width: 100%;

                        &.button-error {
                            background-color: color('error');
                            color: color('white');
                        }

                        &.button-success {
                            background-color: color('success');
                        }

                        &.button-warning {
                            background-color: #FF5733;
                            color: color('white');
                        }

                        &.fit {
                            line-height: 19px;
                            text-align: left;
                        }

                        .mat-button-wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }

                        .mat-icon {
                            margin-left: 5px;
                        }
                    }

                    &.report-issue {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                    }
                }
            }

            .tools-checkbox {
                margin-top: 30px;
            }

            .row-user {
                padding-right: 24px;
                border-right: 1px solid color('pale-sky');
                width: 65px;

                .profile-icon-container {
                    padding: 10px 0;
                }

                .assign-user-button {
                    padding: 0;
                    background: transparent;
                    text-decoration: underline;
                    text-transform: uppercase;
                }
            }

            .row-style {
                .order-container {
                    min-height: 111px;

                    .styles {
                        & + .styles {
                            margin-top: 5px;
                        }
                    }

                    .style-colour {
                        display: flex;
                        align-items: center;
                        margin-bottom: 2px;

                        .swatch {
                            width: 24px;
                            height: 24px;
                        }

                        p {
                            margin-left: 5px;
                            font-size: 0.75rem;
                        }
                    }
                }
            }

            .row-status {
                .order-container {
                    min-height: 111px;
                }
            }
        }
    }
}
