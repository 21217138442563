import { signal, Signal, effect, CreateSignalOptions, WritableSignal, CreateEffectOptions } from '@angular/core';
import { toSignal , ToSignalOptions} from '@angular/core/rxjs-interop';
import { Observable, Subscribable } from 'rxjs';


export class SignalHelper {

    /**
     * Create a `Signal` that can be set or updated directly.
     */
    public static signal<T>(initialValue: T, options?: CreateSignalOptions<T>): WritableSignal<T> {

        return signal<T>(initialValue, options);
    }

    /**
     * Create a `Signal` from an Observable or Subscribable.
     * toSignal() can only be used within an injection context such as a constructor, a factory function, a field initializer, or a function used with `runInInjectionContext`.
     */
    public static toSignal<T>(source: Observable<T> | Subscribable<T>): Signal<T | undefined>;

    public static toSignal<T>(source: Observable<T> | Subscribable<T>, options: ToSignalOptions<T> & {
        initialValue?: undefined;
        requireSync?: false;
    }): Signal<T | undefined>;

    public static toSignal<T>(source: Observable<T> | Subscribable<T>, options: ToSignalOptions<T> & {
        initialValue?: null;
        requireSync?: false;
    }): Signal<T | null>;

    public static toSignal<T>(source: Observable<T> | Subscribable<T>, options: ToSignalOptions<T> & {
        initialValue?: undefined;
        requireSync: true;
    }): Signal<T>;

    public static toSignal<T, const U extends T>(source: Observable<T> | Subscribable<T>, options: ToSignalOptions<T> & {
        initialValue: U;
        requireSync?: false;
    }): Signal<T | U>;

    public static toSignal<T>(source: Observable<T> | Subscribable<T>, options?: ToSignalOptions<T> & { initialValue?: T | undefined | null, requireSync?: boolean }): Signal<T | undefined | null> {
        if (!options) {
            return toSignal<T>(source);
        }

        if (options.initialValue === undefined && !options.requireSync) {
            return toSignal(source, {
                initialValue: undefined,
                requireSync: false
            }) as Signal<T | undefined>;
        }

        if (options.initialValue === null && !options.requireSync) {
            return toSignal(source, {
                initialValue: null,
                requireSync: false
            }) as Signal<T | null>;
        }

        if (options.initialValue === undefined && options.requireSync === true) {
            return toSignal(source, {
                initialValue: undefined,
                requireSync: true
            }) as Signal<T | undefined>;
        }
    
        return toSignal(source, {
            initialValue: options.initialValue,
            requireSync: false
        }) as Signal<T | typeof options.initialValue>;
    }

    /**
     * Create a `Signal` from an Observable or Subscribable and a global `Effect` for the given reactive function.
     * @param allowSignalWrites Whether the `effect` should allow writing to signals. Using effects to synchronize data by writing to signals can lead to confusing and potentially ncorrect behavior, and should be enabled only when necessary.
     */
    public static toSignalWithEffect<T>(source: Observable<T>, effectFn: (value: T) => void, allowSignalWrites: boolean = false): Signal<T> {
        const signal = this.toSignal<T>(source);
        const effectOptions: CreateEffectOptions = {
            allowSignalWrites: allowSignalWrites
        }
        // Create an effect to handle the side effect
        effect(() => {
            const value = signal();
            effectFn(value);
        }, effectOptions);
    
        return signal;
    }
}
