@import '/src/theme/variables';

.component-drawer-upgrade-dialog {
    height: 100%;
    max-height: 875px;

    .mat-mdc-dialog-container {
        margin: auto;
        width: 90%;
        height: 90%;
    }

    @media (max-width: 480px) {
        .mat-mdc-dialog-container {
            width: 100%;
            height: 100%;
        }
    }
}

component-drawer-upgrade-dialog {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    .mat-mdc-dialog-title {
        &::before {
            height: auto;
        }

        h2 {
            margin-bottom: 5px;
            font-size: 2rem;
            font-weight: 600;
            line-height: 38px;
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }

    .mat-mdc-dialog-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100%;

        hr {
            width: 100%;
        }

        h3 {
            margin: 0;
            font-weight: 400;
        }

        .header-block {
            position: relative;
            text-align: center;

            .back {
                position: absolute;
                margin-top: 10px;
                margin-left: 10px;
            }
        }

        .upgrade-options {
            display: flex;
            gap: 30px;
            flex-wrap: wrap;
            justify-content: center;

            .upgrade-group {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                width: 100%;
                max-width: 300px;
            }

            .upgrade-option {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                border: 1px solid color('white');
                border-radius: 8px;
                padding: 10px;
                width: 100%;
                max-width: 245px;

                &.selected {
                    border-color: color('brand-primary');
                }

                h4 {
                    margin: 5px 0;
                    font-size: 1.4rem;
                    font-weight: 600;
                    line-height: 1.6rem;
                }

                p {
                    margin-bottom: 10px;
                }

                .upgrade-button {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    justify-content: flex-end;
                    margin-top: 10px;
                }
            }
        }

        .upgrade-continue {
            margin-top: 15px;
            margin-left: auto;
            margin-right: auto;
            width: 320px;

            .mdc-button {
                width: 100%;
            }
        }
    }

    @media (max-width: 992px) {
        .mat-mdc-dialog-content {
            .header-block {
                .back {
                    margin-top: 0px;
                }
            }
        }
    }
}
