import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { StringHelper } from '@app/utilities/helpers';

import { CatalogueService } from '@app/services/catalogue';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';

@Component({
    selector: 'page-laminate-worktops-page',
    templateUrl: './laminate-worktops.page.html',
    styleUrls: ['./laminate-worktops.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class LaminateWorktopsPage implements OnInit {
    public items: any; // Products in this category - gets sent to the filters component

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: 'Laminate Worktops',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' }
            ],
            routeHistory: [
                { title: 'Worktops', route: '/worktops' },
                { title: 'Duropal Laminate Worktops', route: '/laminate-worktops' }
            ]
        });

        this.navigationService.setMetaTags([
            { property: 'og:image', content: 'https://static.diy-kitchens.com/assets/images/seo/laminate-worktops/laminate-worktops.jpg' },
            { property: 'og:description', content: 'Duropal Laminate Worktops' },
            { property: 'og:description', content: `See our range of Laminate Worktops available at trade prices.` },
            { name: 'Description', content: `See our range of Laminate Worktops available at trade prices.` },
        ]);

        this.catalogueService.getWorktops()
            .then((products: any) => {
                this.initProducts(products);
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    private initProducts(products) {
        if (products) {
            let items: any = {};

            products.forEach((item) => {
                if (item.cat === 'Standard Laminate' || item.cat === 'Square Edge Laminate') {
                    if (!items[item.sub_cat]) {
                        items[item.sub_cat] = {
                            name: item.sub_cat,
                            price: 0,
                            isStandard: false,
                            isSquareEdge: false,
                            image: null
                        };
                    }

                    let i = items[item.sub_cat];
                    if (item.cat_type !== 'Edging Strip') {
                        if (i.price === 0 || i.price > item.price) {
                            i.price = item.price;
                        }
                    }

                    if (!i.isStandard && item.cat === 'Standard Laminate') {
                        i.isStandard = true;
                    }

                    if (!i.isSquareEdge && item.cat === 'Square Edge Laminate') {
                        i.isSquareEdge = true;
                    }

                    if (!i.image && item.media && item.media.image) {
                        i.image = item.media.image;
                    }
                }
            });

            items = Object.values(items);
            items.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                } else if (a.name > b.name) {
                    return 1;
                }

                return 0;
            });

            // this.items.forEach((item) => {
            //     if (Number(worktop.depth) > 300) {
            //         let exists = subcats.filter(product => product.item.sub_cat === worktop.sub_cat);
            //         if (!exists[0]) {
            //             subcats.push(entry);
            //         }
            //     }
            // });

            this.items = items;
        }
    }

    public edgeProfileRoute(item) {
        if (item.isStandard && item.isSquareEdge) {
            return `/laminate-worktops/${StringHelper.spaceToDash(item.name)}/edge-profiles`;
        } else if (item.isStandard) {
            return `/laminate-worktops/options/standard/${StringHelper.spaceToDash(item.name)}/all-types`;
        } else if (item.isSquareEdge) {
            return `/laminate-worktops/options/square-edge-laminate/${StringHelper.spaceToDash(item.name)}/all-types`;
        } else {
            this.dialogService.error(this.constructor.name, 'Not Square or Standard', item);
            return '';
        }
    }
}
