import { Injectable } from '@angular/core';

import { StringHelper } from './string.helper';

import { CatalogueService } from '@app/services/catalogue';
import { ActiveRange } from '@app/services/catalogue/models';
import * as Interfaces from '@app/services/catalogue/models/product.models';
import { IRoute } from '@app/services/catalogue/models/product.models/common/route.model';
import { DialogService } from '@app/services/dialog';

@Injectable({
    providedIn: 'root',
})
export class URLHelper {
    private activeRange: ActiveRange;
    private ranges: any;

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService
    ) {
        this.catalogueService.activeRange$.subscribe(
            (activeRange) => {
                this.activeRange = activeRange || null;
            }
        );
        this.catalogueService.getRanges()
            .then(ranges => {
                this.ranges = ranges;
            }).catch(error => this.dialogService.error(this.constructor.name, error));
    }

    /**
     * Formats the route string and routeEnd string for the type of item
     * @param item A product, such as unit, door, panel etc.
     * @returns obj containing fullRoute: string, routeEnd: string
     */
    public route(item: Interfaces.AllProductsUnion): IRoute {
        if (Interfaces.isAccessory(item)) {
            const category = StringHelper.cleanUrl(item.cat);
            const subCategory = StringHelper.cleanUrl(item.sub_cat || 'component');
            const routeEnd = encodeURIComponent(
                StringHelper.cleanUrl(`${item.product_code} ${item.desc}`)
                    .toLowerCase());
            return <IRoute>{
                fullRoute: `/accessories/${category}/${subCategory}/${routeEnd}`.toLowerCase(),
                routeEnd: routeEnd
            };
        }

        if (Interfaces.isAppliance(item)) {
            const category = StringHelper.cleanUrl(item.category);
            const subCategory = StringHelper.cleanUrl(item.subcat);
            const routeEnd = encodeURIComponent(
                StringHelper.cleanUrl(`${item.description}-${item.code}`)
                    .toLowerCase());
            return <IRoute>{
                fullRoute: `/appliances/${category}/${subCategory}/${routeEnd}`.toLowerCase(),
                routeEnd: routeEnd
            };
        }

        if (Interfaces.isCabinet(item)) {
            if (item.unit_code.toLowerCase() === 'dfep1210') {
                console.warn('Mismatching accessory:', item);
            }
            const category = StringHelper.cleanUrl(item.categoryLink || 'misc');
            const subCategory = StringHelper.cleanUrl(item.subCategoryLink || 'misc');
            const routeEnd = encodeURIComponent(
                StringHelper.cleanUrl(`${item.width}mm ${item.desc} ${item.unit_code}`)
                    .toLowerCase());
            return <IRoute>{
                fullRoute: `/kitchen-units/${category}/${subCategory}/${routeEnd}`.toLowerCase(),
                routeEnd: routeEnd
            };
        }

        if (Interfaces.isHandle(item)) {
            const category = StringHelper.cleanUrl(item.cat);
            const routeEnd = encodeURIComponent(
                StringHelper.cleanUrl(`${item.product_code} ${item.desc}`)
                    .toLowerCase());
            return <IRoute>{
                fullRoute: `/handles/${category}/${routeEnd}`.toLowerCase(),
                routeEnd: routeEnd
            };
        }

        if (Interfaces.isRangeProduct(item)) {
            let rangeName: string;
            let rangeColour: string;

            if (this.ranges && item.range_id) {
                const rangeDetails = this.ranges.find(range => range.range_id === item.range_id);
                rangeName = rangeDetails.name;
                rangeColour = item.colour;
            } 
            else if (this.activeRange) {
                rangeName = this.activeRange.range.name;
                rangeColour = this.activeRange.rangeColour;
            } 
            else {
                rangeName = 'Luca';
                rangeColour = 'Alabaster';
            }

            rangeName = rangeName.replace(/ /g, '-');
            rangeColour = rangeColour.replace(/ /g, '-');

            const subCategory = StringHelper.cleanUrl(item.subCategoryLink || '');
            const routeEnd = encodeURIComponent(
                `${item.code}-${rangeName}-${rangeColour}` +
                StringHelper.cleanUrl(` ${item.width}mm ${item.desc}`)
            ).toLowerCase();
            return <IRoute>{
                fullRoute: `/kitchen-units/accessories/${subCategory}/${routeEnd}`.toLowerCase(),
                routeEnd: routeEnd
            };
        }

        if (Interfaces.isSinkAndTaps(item)) {
            const category = StringHelper.spaceToDash(item.category);
            const subCategory = item.subCategoryLink;
            const routeEnd = encodeURIComponent(
                StringHelper.cleanUrl(`${item.desc}`)
                    .toLowerCase()
            );
            return <IRoute>{
                fullRoute: `/sinks-and-taps/${category}/${subCategory}/${routeEnd}`.toLowerCase(),
                routeEnd: routeEnd
            };
        }

        if (Interfaces.isWorktop(item)) {
            const configRouteEnd = encodeURIComponent(
                StringHelper.cleanUrl(`${item.sub_cat} ${item.thickness}mm`)
                    .toLowerCase()
            );
            const config = `/solid-surfaces/config/${configRouteEnd}`;

            if (item.cat === 'Standard Laminate' || item.cat === 'Square Edge Laminate') {
                let section = (item.cat_type) 
                              ? StringHelper.spaceToDash(item.cat_type).toLowerCase()
                              : 'all-types';
                if (section.match(/upstand|back-panel/i)) {
                    section = section + 's';
                }
                const routeEnd = (item.cat.match(/standard/i))
                               ? `options/standard/${encodeURIComponent(StringHelper.cleanUrl(item.sub_cat)).toLowerCase()}/${section}`
                               : `options/square-edge-laminate/${encodeURIComponent(StringHelper.cleanUrl(item.sub_cat)).toLowerCase()}/${section}`;
                return <IRoute>{
                    fullRoute: `/laminate-worktops/${routeEnd}`,
                    routeEnd: routeEnd,
                    configRoute: config,
                    configRouteEnd: configRouteEnd
                };
            } else if (item.cat && item.cat === 'Solid Wood') {
                const routeEnd = '/wood-worktops';
                return <IRoute>{
                    fullRoute: routeEnd,
                    routeEnd: routeEnd,
                    configRoute: config,
                    configRouteEnd: configRouteEnd
                };
            } else {
                const routeEnd = `${encodeURIComponent(StringHelper.cleanUrl(item.cat ?? '')).toLowerCase()}-worktops`;
                return <IRoute>{
                    fullRoute: `/solid-surfaces/${routeEnd}`,
                    routeEnd: routeEnd,
                    configRoute: config,
                    configRouteEnd: configRouteEnd
                };
            }
        }

        //Catch-all should item not be of any of the above types
        console.warn('No match for item type:', item);
        const code = 'code' in item
            ? item.code
            : 'unit_code' in item
                ? item.unit_code
                : 'product_code' in item
                    ? item.product_code
                    : '';
        const desc = 'desc' in item
            ? item.desc
            : 'description' in item
                ? item.description
                : '';
        const routeEnd = encodeURIComponent(
            StringHelper.cleanUrl(`${code} ${desc}`)
                .toLowerCase());
        return <IRoute>{
            fullRoute: routeEnd,
            routeEnd: routeEnd
        };
    }

    /**
     * Formats the routeEnd string for the type of item
     * @param item A product, such as unit, door, panel etc.
     * @returns the url route end for the browser links
     */
    public routeEnd(item: Interfaces.AllProductsUnion): string {
        return this.route(item).routeEnd;
    }

    /**
     * Formats the full route string for the type of item
     * @param item A product, such as unit, door, panel etc.
     * @returns the url route for the browser links
     */
    public fullRoute(item: Interfaces.AllProductsUnion): string {
        return this.route(item).fullRoute;
    }

    /**
     * Formats the config route string for worktops, etc.
     * @param item A product, such as unit, door, panel etc.
     * @returns the url route for the browser links
     */
    public configRoute(item: Interfaces.AllProductsUnion): string | null {
        return this.route(item).configRoute ?? null;
    }

    /**
     * Formats the config routeEnd string for worktops, etc.
     * @param item A product, such as unit, door, panel etc.
     * @returns the url route for the browser links
     */
    public configRouteEnd(item: Interfaces.AllProductsUnion): string | null {
        return this.route(item).configRouteEnd ?? null;
    }

    /**
     * @deprecated Use getRangeProductByURL() instead.
     * Extracts the details of an encoded URI routeEnd string to return details of
     * product code, range name, range colour, width and product description.
     * @param URI An encoded URI, as produced by routeEnd() method.
     * @returns An object containing strings.
     */
    public extractURI(URI: string): {
        code: string,
        rangeName: string,
        rangeColour: string,
        width: string,
        desc: string,
        uniformURI: string
    } {
        let parts = URI.split('-');

        const safeShift = () => parts.length > 0 ? parts.shift() : null;

        let code = safeShift();
        let rangeName = StringHelper.titleCase(safeShift().replace(/_/g, ' '));
        let rangeColour = StringHelper.titleCase(safeShift().replace(/_/g, ' '));
        let width = safeShift();
        let desc = parts.join('-');
        let title = `${width}-${desc}`;

        // Check for invalid rangeName as this can be caused by dashes being present in product-code
        if (rangeName.length < 3) { // Obi-wan last-ditched attempt
            parts = URI.split('-');
            code = `${safeShift()}-${safeShift()}`;
            rangeName = StringHelper.titleCase(safeShift().replace(/_/g, ' '));
            rangeColour = StringHelper.titleCase(safeShift().replace(/_/g, ' '));
            width = safeShift();
            desc = parts.join('-');
            title = `${width}-${desc}`;
        }

        return {
            code: code || null,
            rangeName: rangeName || null,
            rangeColour: rangeColour || null,
            width: width || null,
            desc: desc || null,
            uniformURI: this.activeRange ? URI : `${code}-${width}mm-${desc}`
        };
    }

    /**
     * Uses the route-end to find a range product (from rangeDetails)
     * Supercedes the extractURI method.
     * @param url The route-end url
     * @returns Range product or null if not found
     */
    public getRangeProductByURL(url: string): Promise<Interfaces.IRangeProduct | null> {
        return new Promise((resolve, reject) => {
            this.catalogueService.getRanges()
                .then((ranges) => {
                    return Promise.all(ranges.map(range => 
                        this.catalogueService.getRangeDetails(range.range_id)
                    ));
                })
                .then((rangeDetails) => {
                    for (const rangeDetail of rangeDetails) {
                        if (Array.isArray(rangeDetail.products)) {
                            for (const product of rangeDetail.products) {
                                const productUrl = this.route(product).routeEnd;
                                if (productUrl === url) {
                                    resolve(product);
                                    return;
                                }                   
                            }
                        }
                    }
                    resolve(null);
                })
                .catch((error) => reject(error));
        });
    }
    

}
