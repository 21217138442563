import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'component-solid-surface-config-corner-options-dialog',
    templateUrl: './corner-options.dialog.component.html',
    styleUrls: ['./corner-options.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SolidSurfaceConfigCornerOptionsDialogComponent {
    public option: string;
    public isInternal: boolean;
    public cornerOpts: any;

    constructor(
        private dialogRef: MatDialogRef<SolidSurfaceConfigCornerOptionsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.option = this.data.option;
        this.isInternal = this.data.isInternal;
        this.cornerOpts = this.data.cornerOpts;
    }

    public select(option) {
        this.dialogRef.close({
            option: option
        });
    }
}
