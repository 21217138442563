<section *ngIf="loading" class="loader">
    <mat-spinner></mat-spinner>
</section>

<header class="content-contained">
    <div class="panel">
        <div class="panel-left">
            <img class="header-logo" src="https://static.diy-kitchens.com/assets/images/general/invoice_logo2.gif" width="262" />

            <p>
                <span>Unit 26, Lidgate Crescent</span><br />
                <span>Langthwaite Grange Business Park</span><br />
                <span>South Kirkby</span><br />
                <span>West Yorkshire</span><br />
                <span>WF9 3NR</span>
            </p>
        </div>
        <div class="panel-right">
            <p *ngIf="isInvoice">Invoice Number: <strong>{{order?.orderNumber}}</strong></p>
            <p *ngIf="!isInvoice">Order Number: <strong>{{order?.orderNumber}}</strong></p>
            <p>
                <span><strong>Website:</strong> www.diy-kitchens.com</span><br />
                <span><strong>Email:</strong> orders&#x40;diy-kitchens.com</span><br />
                <span><strong>Contact Number:</strong> 01977 608418 </span><br />
                <span><strong>Fax Number:</strong> 01977 649243</span>
            </p>
            <p><strong>VAT Registration No:</strong> 436 502 956</p>
        </div>
    </div>
</header>

<section class="content-contained">
    <div class="error-message" *ngIf="errorMessage">
        <h4>{{errorMessage}}</h4>
    </div>

    <div class="order" *ngIf="order">
        <div class="order-confirmation">
            <div class="hr-1">
                <hr />
            </div>
            <div *ngIf="isInvoice" class="hr-2">Order Invoice</div>
            <div *ngIf="!isInvoice" class="hr-2">Order Confirmation</div>
            <div class="hr-3">
                <hr />
            </div>
        </div>

        <div class="address">
            <div class="address-section">
                <p><strong>Billing</strong></p>
                <div class="address-section-detail">
                    <p>{{order.firstName}} {{order.lastName}}</p>
                    <p>{{order.billingAddress1}}</p>
                    <p>{{order.billingAddress2}}</p>
                    <p>{{order.billingCity}}</p>
                    <p>{{order.billingCounty}}</p>
                    <p>{{order.billingCountry}}</p>
                    <p>{{order.billingPostcode}}</p>
                </div>
            </div>

            <div class="address-section">
                <p><strong>Delivery</strong></p>
                <div class="address-section-detail">
                    <p>{{order.deliveryAddress1}}</p>
                    <p>{{order.deliveryAddress2}}</p>
                    <p>{{order.deliveryCity}}</p>
                    <p>{{order.deliveryCounty}}</p>
                    <p>{{order.deliveryCountry}}</p>
                    <p>{{order.deliveryPostcode}}</p>
                </div>
            </div>
        </div>

        <div class="details-misc">
            <div class="panel">
                <div class="panel-left">
                    <p>
                        <ng-container *ngIf="isInvoice">
                            <strong>Invoice Date:</strong> {{order.orderDate | date:"dd/MM/YYYY"}}
                        </ng-container>
                        <ng-container *ngIf="!isInvoice">
                            <strong>Order Date:</strong> {{order.orderDate | date:"dd/MM/YYYY"}}
                        </ng-container><br />
                        <ng-container *ngIf="order.weekCommencing">
                            <strong>Week Commencing Date:</strong> {{order.weekCommencing | date:"dd/MM/YYYY"}}
                        </ng-container>
                        <ng-container *ngIf="!order.weekCommencing && order.deliveryDate">
                            <strong>Delivery Date:</strong> {{order.deliveryDate | date:"dd/MM/YYYY"}}
                        </ng-container><br />
                        <strong>Delivery Type:</strong> {{deliveryTypeName(order)}}<br />
                    </p>
                </div>
                <div class="panel-right">
                    <p>
                        <strong>Contact Tel:</strong> {{order.landline}} <br />
                        <strong>Second Contact Tel:</strong> {{order.mobile}} <br />
                        <strong>Email:</strong> {{order.email}} <br />
                    </p>
                </div>
            </div>
        </div>

        <table class="items-table">
            <tr>
                <th>Ref</th>
                <th class="number-display">QTY</th>
                <th>Product Description</th>
                <th>Range</th>
                <th>Colour</th>
                <ng-container *ngIf="order.orderTotal !== null">
                    <th class="number-display">Item Cost </th>
                    <th class="number-display">Total</th>
                </ng-container>
            </tr>
            <tr *ngFor="let orderItem of order.items">
                <td>{{orderItem.code}}</td>
                <td class="number-display">{{orderItem.qty}}</td>
                <td class="description">
                    <p [innerHTML]="orderItem.description"></p>
                    <ng-container *ngIf="orderItem.group === ProductType.CABINETS">
                        <p class="small" *ngIf="orderItem.carcaseColour">Carcase Colour: <strong>{{orderItem.carcaseColour}}</strong></p>
                        <p class="small" *ngIf="orderItem.handing">Handing: <strong>{{orderItem.handing === 'RH' ? 'Right' : 'Left'}}</strong></p>
                    </ng-container>
                    <p *ngIf="orderItem.notes && orderItem.notes.length">
                        <span class="note" *ngFor="let note of orderItem.notes">{{ note.note }}</span>
                    </p>
                </td>
                <td class="number-display">{{orderItem.range}}</td>
                <td class="number-display">{{orderItem.colour === 'Special Paint Colour' ? orderItem.bespokeColour : orderItem.rangeColour || orderItem.colour || orderItem.bespokeColour}}</td>
                <ng-container *ngIf="order.orderTotal !== null">
                    <td class="number-display">{{(orderItem.salePrice || orderItem.price || 0) | currencyFormat}}</td>
                    <td class="number-display">{{(orderItem.salePrice || orderItem.price || 0) * orderItem.qty | currencyFormat}}</td>
                </ng-container>
            </tr>
            <ng-container *ngIf="order.orderTotal !== null">
                <tr>
                    <td class="spanned" colspan="6"><strong>Product Total:</strong></td>
                    <td class="spanned-result">{{order.productTotal | currencyFormat}}</td>
                </tr>
                <tr>
                    <td class="spanned" colspan="6"><strong>Delivery Charge:</strong></td>
                    <td class="spanned-result">{{order.deliveryCost + order.deliverySurcharge | currencyFormat}}</td>
                </tr>
                <tr>
                    <td class="spanned" colspan="6"><strong>VAT:</strong></td>
                    <td class="spanned-result">{{order.vat | currencyFormat}}</td>
                </tr>
                <tr>
                    <td class="spanned" colspan="6"><strong>TOTAL:</strong></td>
                    <td class="spanned-result">{{order.orderTotal | currencyFormat}}</td>
                </tr>
            </ng-container>
        </table>
    </div>
</section>