<mat-dialog-content>
    <div class="categories">
        <ng-container *ngFor="let cat of categories">
            <button mat-stroked-button color="secondary" *ngIf="cat !== category" (click)="selectCat(cat)">{{ cat }}</button>
            <button mat-flat-button color="primary" *ngIf="cat === category" (click)="selectCat(cat)">{{ cat }}</button>
        </ng-container>
    </div>
    <div class="options">
        <a class="option" *ngFor="let option of options" [ngClass]="{ selected: (option.product_code === worktop.product_code) }" (click)="setStyle(option)">
            <img [src]="option.media.image" [alt]="option.sub_cat + ' ' + option.thickness + 'mm'"/>
            <div class="details">
                <p>{{ option.sub_cat }} ({{ option.thickness }}mm)</p>
                <p [innerHTML]="option.configPrice.total | currencyFormat:true:true"></p>
            </div>
        </a>
    </div>
</mat-dialog-content>