import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { Config } from '@app/config';
import { StringHelper } from '@app/utilities/helpers';

import { CatalogueService } from '@app/services/catalogue';
import { ActiveRange, ListingProduct, ProductType } from '@app/services/catalogue/models';
import { CostCalculator } from '@app/services/catalogue/cost-calculator';
import { DialogService } from '@app/services/dialog';
import { FilterService } from '@app/services/filter';
import { IFilterOption } from '@app/services/filter/models';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';

import { IMegaMenuSubCategory } from '@app/shared/components/mega-menu/models';
import { mockData } from '@app/shared/components/mega-menu/mock-data';

import { GalleryItem } from './components/gallery/models';
import { ICabinet, isCabinet } from '@app/services/catalogue/models/product.models';

@Component({
    selector: 'page-unit-listing',
    templateUrl: './unit-listing.page.html',
    styleUrls: ['./unit-listing.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class UnitListingPage implements OnInit, OnDestroy {
    public diy = this.config.diy;

    public type: ProductType = ProductType.CABINETS;

    public category: string; // Category to display ie (Base units or Dishwashers)
    public categoryDisplay: string;
    public subCategory: string;
    public subCategoryDisplay: string;
    public headerCssClass: string;

    public sectionCats: any[] = []; // A list of all categories for this type (ie different unit types or appliance types)

    public products: any; // All products of this type (ie All Units or Appliances)
    public items: ListingProduct[]; // Products in this category - gets sent to the filters component
    public filteredItems: any[] = []; // Filtered products to show as results
    public hasResults: boolean;
    public categories;
    private categoryType = 'kitchen-units';

    public subCategoryData: IMegaMenuSubCategory;
    public categoryTitle: string;
    public galleryItems: GalleryItem[];

    public galleryFilter;
    public filterFields: any = [
        {
            title: 'Sub Category',
            field: 'subCategory',
            rankField: 'subCatRank'
        },
        {
            title: 'Width',
            field: '_width',
            token: 'mm'
        },
        {
            title: 'Height',
            field: 'height',
            token: 'mm'
        },
        {
            title: 'Depth',
            field: 'depth',
            token: 'mm'
        }
    ];

    public activeRange: ActiveRange;

    private costCalculator: CostCalculator = new CostCalculator();
    private sortOrder: string = '';

    private routeHistory: RouteHistory[] = [
        { title: 'Units', route: '/kitchen-units' }
    ];

    private routeSubscription: Subscription;
    private activeRangeSubscription: Subscription;

    constructor(
        public config: Config,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private filterService: FilterService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                this.category = route.params.category || null;
                this.categoryDisplay = (this.category) ? StringHelper.titleCase(StringHelper.dashToSpace(this.category)) : null;
                this.subCategory = route.params.subCategory || null;
                this.subCategoryDisplay = (this.subCategory) ? this.catalogueService.getCategoryTitle(this.subCategory) : null;

                this.headerCssClass = (this.subCategory) ? `content-header-units-${this.category}-${this.subCategory}` : `content-header-units-${this.category}`;

                this.categoryTitle = StringHelper.titleCase(StringHelper.dashToSpace(this.category));
                if (this.categoryTitle === 'Accessories') {
                    this.categoryTitle = 'Panels & Accessories';
                }

                if (this.category === 'accessories') {
                    this.type = ProductType.RANGE_PRODUCTS;
                } else {
                    this.type = ProductType.CABINETS;
                }

                this.subCategoryData = JSON.parse(JSON.stringify(mockData.units.subCategories.find(unitCat => unitCat.name === this.categoryTitle)));
                if (this.subCategoryData && this.subCategoryData.items) {
                    const allFilter = [{
                        name: `All ${this.categoryDisplay}`,
                        url: `/kitchen-units/${this.category}/all`,
                        image: {
                            url: this.subCategoryData.items[0].image.url
                        }
                    }];
                    this.subCategoryData.items = [...allFilter, ...this.subCategoryData.items];
                }

                const metaCatagoryImages = {
                    inset: 'https://static.diy-kitchens.com/assets/images/products/sinks/cimageurl/UNO965.jpg',
                    belfast: 'https://static.diy-kitchens.com/assets/images/products/sinks/cimageurl/BAK710WH-1.jpg',
                    undermount: 'https://static.diy-kitchens.com/assets/images/products/sinks/cimageurl/IN-LAG400.jpg',
                    mixer: 'https://static.diy-kitchens.com/assets/images/products/misc/cimageurl/IN-TIBS.jpg',
                    "pull-out": 'https://static.diy-kitchens.com/assets/images/products/misc/cimageurl/IN-FOBS.jpg',
                    "quooker-boiling-water": 'https://static.diy-kitchens.com/assets/images/products/misc/cimageurl/3NRCHR.jpg',
                    spout: 'https://static.diy-kitchens.com/assets/images/products/misc/cimageurl/IN-SDC.jpg',
                    accessory: 'https://static.diy-kitchens.com/assets/images/products/misc/cimageurl/CWF.jpg'
                };

                const metaDescription = `DIY Kitchens stocks a range of ${this.subCategoryDisplay?.toLowerCase()} kitchen ${this.categoryDisplay?.toLowerCase()} units. Design your kitchen online, all units are trade price & come fully assembled, ${this.diy.ranges}+ styles to chose from.`
                this.navigationService.setMetaTags([
                    { name: 'no-meta', content: 'no metadata' },
                    { name: 'Description', content: `See our range of ${metaDescription} available at trade prices.` },
                    { property: 'og:locale', content: 'en_GB' },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:site_name', content: 'DIY Kitchens' },
                    { property: 'og:url', content: 'Sinks & Taps - DIY Kitchens ' },
                    { property: 'og:title', content: 'Sinks & Taps - DIY Kitchens ' },
                    { property: 'og:description', content: `See our range of ${metaDescription} available at trade prices.` },
                    { property: 'og:image', content: 'https://static.diy-kitchens.com/assets/images/seo/kitchen-sales/kitchen-sales.jpg' },
                    { property: 'og:image:secure_url', content: 'https://static.diy-kitchens.com/assets/images/seo/kitchen-sales/kitchen-sales.jpg' },
                    { property: 'og:image:width', content: '600' },
                    { property: 'og:image:height', content: '400' }
                ]);

                let routeHistory = this.routeHistory.slice();
                routeHistory.push({
                    title: this.categoryDisplay,
                    route: `/kitchen-units/${this.category}`
                });

                if (this.subCategory) {
                    routeHistory.push({
                        title: this.subCategoryDisplay,
                        route: `/kitchen-units/${this.category}/${this.subCategory}`
                    });

                    let filterField = this.filterFields?.find((filter) => filter.field === 'subCategory');
                    if (filterField) {
                        filterField.preselected = this.subCategory
                    }
                }

                let title = this.categoryDisplay;

                if (this.subCategoryDisplay) {
                    title = `${this.subCategoryDisplay} ${this.categoryDisplay}`;
                }

                this.navigationService.setNavigation({
                    title: `${title} - Kitchen Units`,
                    metaTags: [{ name: 'no-meta', content: 'no metadata' }],
                    routeHistory: routeHistory
                });

                let subCategoryOptions = this.filterFields?.find(filter => filter.title === 'Sub Category')?.opts;
                if (subCategoryOptions) {
                    subCategoryOptions.forEach(option => option.tick = false);
                    let subCatOption = subCategoryOptions.find(option => option.value == this.subCategory);
                    if (subCatOption) {
                        subCatOption.tick = true;
                    }
                }
                this.setCategories();
            }
        );

        this.catalogueService.activeRangeLoaded$
            .pipe(first((value) => value))
            .subscribe(() => {
                this.activeRangeSubscription = this.catalogueService.activeRange$.subscribe(
                    (rangeInfo: ActiveRange) => {
                        this.processActiveRange(rangeInfo);
                    }
                );
            });
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }

        if (this.activeRangeSubscription) {
            this.activeRangeSubscription.unsubscribe();
            this.activeRangeSubscription = null;
        }
    }

    public onFilterChange(): void {
        this.filteredItems = this.filterService.filterItems(this.filterFields, this.items, 'kitchen-units', this.sortOrder);
        this.updateItems(this.filteredItems);
        this.filterFields = this.filterService.limitFilters(this.filterFields, this.items, 'kitchen-units');
        this.galleryFilter = this.filterFields.filter(filter => filter.title === 'Sub Category')[0];
    }

    public onSortChange(order: string): void {
        this.sortOrder = order;
        if (this.filteredItems.length) {
            this.filteredItems = this.filterService.updateOrder(this.filteredItems, order);
            this.updateItems(this.filteredItems)
        }
    }

    public onSoftCloseChange(softClose: boolean): void {
        this.filteredItems.forEach((product) => {
            product.item.softClose = softClose;
        });
    }

    private processActiveRange(rangeInfo) {
        this.activeRange = rangeInfo;

        const filterRangeExtras = (rangeExtras): any[] => {
            let products = [];
            if (rangeExtras) {
                let colourName = (this.activeRange?.colour) ? this.activeRange?.colour : 'Alabaster';
                if (this.activeRange?.rangeColour === 'Bespoke') {
                    colourName = 'Bespoke';
                }
                rangeExtras.products.forEach((product) => {
                    if (product.colour === colourName && !product.desc.match(/sample/i)) {
                        if (
                            product.subCategoryName !== 'Kitchen Door' &&
                            product.subCategoryName !== 'Kitchen Drawer' &&
                            product.subCategoryName !== 'Kitchen Sample'
                        ) {
                            products.push(product);
                        }
                    }
                });
            }
            return products;
        }

        this.catalogueService.getCabinetsFlat()
            .then((productsReturned: any) => {
                this.products = JSON.parse(JSON.stringify(productsReturned));
                const rangeId = this.activeRange ? this.activeRange.range.id : 'range001';
                this.catalogueService.getRange(rangeId)
                    .then((rangeExtras) => {
                        const accessories = this.products.filter((product) => product.categoryLink === 'accessories');
                        accessories.forEach((product) => this.catalogueService.setSubCategory(product));
                        this.products = [...this.products, ...filterRangeExtras(rangeExtras)];
                        this.setCategories();
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public setCategories() {
        this.catalogueService.getCategories(this.categoryType)
            .then((categories) => {
                this.categories = categories;
                this.initUnits();
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public updateItems(filtered) {
        this.filteredItems = filtered.filter((entry) => entry?.item?._cost);
        this.hasResults = !!filtered.length;
    }

    public yesNoToBool(yesNo: string): boolean {
        return (yesNo || '').toLowerCase() === 'yes';
    }

    private initUnits() {
        if (this.products && this.products instanceof Array) {
            this.sectionCats = [];
            this.items = [];

            this.products.map((product) => {
                product._code = product.unit_code;
                product._description = product.desc;
                product._details = product.detail;
                product._finish = product.finish || null;
                product.softClose = this.config.settings.softClose;
                product._cost = this.cost(product);

                if (!product.depth && product.categoryLink === 'accessories') {
                    product.depth = 'N/A';
                }

                const exists = this.items.find((entry) => {
                    if (product.height !== entry.item.height || product.depth !== entry.item.depth) {
                        return false;
                    }

                    if (product.type) {
                        return product.type === entry.item.type;
                    }

                    return (
                        product.categoryLink === entry.item.categoryLink
                        && product.desc === entry.item.desc
                        && product.subCategoryLink === entry.item.subCategoryLink
                    );
                });

                if (exists && product._cost) {
                    if (exists.maxPopularity < product.popularity) {
                        exists.maxPopularity = product.popularity;
                        exists.item = product;
                    } else if (exists.maxPopularity === product.popularity) {
                        if (product.width === 600) {
                            exists.item = product;
                        }
                    }

                    const existingWidth = exists.widths.find((width) => width.width === product.width);

                    if (existingWidth) {
                        existingWidth.variants.push({
                            code: product._code,
                            variant: product.variant || product._code // TODO replace variant name with data from API
                        });
                    } else {
                        exists.widths.push({
                            width: product.width,
                            variants: [{
                                code: product._code,
                                variant: product.variant || product._code // TODO replace variant name with data from API
                            }],
                            cost: product._cost
                        });
                    }
                } else {
                    const item: ListingProduct = {
                        item: product,
                        widths: [{
                            width: product.width,
                            variants: [{
                                code: product._code,
                                variant: product.variant || product._code // TODO replace variant name with data from API
                            }],
                            cost: product._cost
                        }],
                        maxPopularity: product.popularity,
                        options: {
                            hingeSide: null,
                            softClose: true
                        }
                    };

                    if (this.activeRange) {
                        if (!this.yesNoToBool(this.activeRange?.rangeDetail?.inframe)) {
                            if (!product.inframe_only) {
                                this.items.push(item);
                            }
                        } else if (product._cost) {
                            this.items.push(item);
                        }
                    } else {
                        this.items.push(item);
                    }

                    const catExists = this.sectionCats.find((opt) => opt.name === product.categoryLink);
                    if (!catExists) {
                        let rank = 0;
                        let productTitle = StringHelper.titleCase(StringHelper.dashToSpace(product.categoryLink));

                        if (this.categories && this.categories[product.categoryLink]) {
                            const category = this.categories[product.categoryLink];
                            productTitle = category.name;
                            rank = category.rank;
                        } else {
                            this.dialogService.error(this.constructor.name, 'Missing Category: ', product.categoryLink);
                        }

                        this.sectionCats.push({
                            title: productTitle,
                            name: product.categoryLink,
                            count: 1,
                            popularity: product.popularity,
                            rank: rank
                        });
                    } else {
                        catExists.count++;
                        catExists.popularity += product.popularity;
                    }
                }
            });

            this.items = this.items.filter((entry) => {
                entry.widths.sort((a, b) => {
                    if (a.width < b.width) {
                        return -1;
                    } else if (a.width > b.width) {
                        return 1;
                    }

                    return 0;
                });

                return entry.item.categoryLink === this.category;
            });

            this.items.forEach((product) => product.item.salePrice = this.getItemSalePrice(product.item));

            if (this.sectionCats.length > 0) {
                this.sectionCats.sort((a, b) => {
                    if (a.rank > b.rank) {
                        return 1;
                    } else if (a.rank < b.rank) {
                        return -1;
                    }

                    return b.popularity - a.popularity;
                });
            }

            const urlToValue = (url: string): string => {
                if (url[url.length - 1] === '/') {
                    url = url.slice(0, url.length - 1);
                }
                const urlParts = url.split('/');
                let value = urlParts[urlParts.length - 1];
                // Alterations for inconsistent data
                switch (value) {
                    case 'single-oven-and-microwave':
                        value = 'single-oven-&-microwave';
                        break;
                    case 'double-oven-and-microwave':
                        value = 'double-oven-&-microwave';
                        break;
                    case 'integrated-fridge-freezer':
                        value = 'refrigeration';
                        break;
                    case 'compact':
                        value = 'compact-appliance';
                        break;
                    case 'x2-compact':
                        value = 'x2-compact-appliance';
                        break;
                    case 'oven-microwave':
                        value = 'microwave';
                        break;
                    default:
                        value = value;
                        break;
                }

                return value;
            }

            const setOrRestoreSubCatFilter = (): void => {
                const subCatFilter = this.filterFields?.find(filter => filter.title === 'Sub Category');
                const activeOption = subCatFilter?.opts?.find(option => option.tick)?.value || subCatFilter?.preselected;

                if (!this.subCategory) {
                    subCatFilter.preselected = 'all';
                }

                this.filterFields = this.filterService.initFilters(this.items, this.filterFields, this.sectionCats, this.category, 'kitchen-units');
                if (subCatFilter) {
                    subCatFilter.opts = this.unitSubCategoriesFromMockData(subCatFilter)
                }
                if (this.subCategory && subCatFilter) {
                    const subCategoryFilter = subCatFilter.opts?.find((filter) => filter.value === this.subCategory);
                }

                this.galleryFilter = subCatFilter;
                this.galleryItems = this.subCategoryData?.items?.map(item => ({
                    ...item,
                    value: urlToValue(item.url)
                }));

                this.galleryItems = this.galleryItems?.filter(item =>
                    this.galleryFilter?.opts?.some(filterItem => filterItem.value === item.value)
                );

                let restore = subCatFilter?.opts?.find(option => option.value === activeOption);
                if (restore) {
                    restore.tick = true;
                } else if (activeOption) {
                    subCatFilter?.opts?.forEach(option => {
                        option.tick = false;
                    });
                }
            }

            setOrRestoreSubCatFilter();

            this.filteredItems = this.filterService.filterItems(this.filterFields, this.items, 'kitchen-units', this.sortOrder);
            this.updateItems(this.filteredItems);
        }
    }

    /** Generate fixed subfilters for units from the mock data, rather than dynamically from the list of products */
    private unitSubCategoriesFromMockData(subCatFilter): IFilterOption[] {
        let filterOpts: IFilterOption[] = [
            {
                name: '',
                value: 'all',
                count: 1,
                rank: 1,
                tick: subCatFilter.preselected === 'all'
            }
        ];
        if (Array.isArray(mockData?.units?.subCategories)) {
            const unitCategory = mockData.units.subCategories.find((category) => {
                if (category && category.items && category.items.length) {
                    const urlParts = category?.items[0].url.split('/');

                    return urlParts[urlParts.length - 2] === this.category;
                }

                return false;
            });
            if (unitCategory && Array.isArray(unitCategory?.items)) {
                unitCategory.items.forEach((subCategoryOption) => {
                    const subCatLink = subCategoryOption.url.split('/').pop();
                    filterOpts.push(
                        {
                            name: subCategoryOption.name,
                            value: subCatLink,
                            count: 2,
                            rank: null,
                            tick: (subCatFilter.preselected) ? (subCatFilter.preselected === subCatFilter.subCategoryLink) : false
                        }
                    );
                });
            }
        }
        return filterOpts;
    }

    private getItemSalePrice(item: ICabinet): number | null {
        if (this.type !== ProductType.CABINETS) {
            return null;
        }
        if (item.promotion && item._cost && isCabinet(item)) {
            const cabPrice = (item.cab_salePrice) ? item.cab_salePrice : item.cab_price;
            const frontals = (this.activeRange) ?
                this.catalogueService.getDoorForUnit(item.unit_code, this.activeRange?.rangeColour || 'Alabaster') :
                null;

            let totalCost: number = cabPrice;
            if (frontals) {
                Object.values(frontals).forEach((door: any) => {
                    let doorCost = (door.salePrice) ? door.salePrice : door._cost;
                    totalCost += doorCost;
                });
            }
            totalCost = Math.floor(totalCost * 100) / 100;  // Round down to the nearest penny
            return totalCost;
        }
        return null;
    }

    private cost(product) {
        if (this.activeRange) {
            if (product.categoryLink === 'accessories') {
                return product.price;
            } else {
                return this.costCalculator.getUnitPriceRangeAndColour(
                    this.activeRange.rangeDetail,
                    this.activeRange.rangeColour,
                    product._code
                );
            }
        } else {
            return product.cab_price || product.price || 0;
        }
    }

    public carcaseImage() {
        this.activeRange?.carcaseColour
        if (this.activeRange?.carcaseColour) {
            if (this.activeRange?.rangeDetail?.isTrueHandleless) {
                return `${this.config.api.endpoints.cdn}/carcases/true_handleless_${this.activeRange?.carcaseColour.replace(/\s/g, '_').toLowerCase()}_500px.jpg`;
            }

            return `${this.config.api.endpoints.cdn}/carcases/Unit_${((this.activeRange?.carcaseColour || '').replace(/\s/g, '_'))}.png`;
        } else {
            return 'https://static.diy-kitchens.com/3q_doors_units/unit_default_500px.png';
        }
    }

    public unitDoorImage() {
        const filise = (str) => str.toLowerCase().replace(/ /g, '_').replace(/_painted_colours/, '');

        if (this.activeRange?.range?.name && this.activeRange?.rangeColour) {
            return `${this.config.api.endpoints.cdn}/3q_doors_units/` +
                filise(this.activeRange?.range?.name) + '_' +
                filise(this.activeRange?.rangeColour).replace('_gloss', '') + '_3q_500px.png';
        }

        return '';
    }

    public getRangeDoorImage(): string {
        return `https://static.diy-kitchens.com/doors/${(this.activeRange?.colourDetail?.door_image) ? this.activeRange?.colourDetail?.door_image : 'clayton_alabaster_straight_on_500px.jpg'}`;
    }

    public selectRange(): void {
        this.catalogueService.openRangeSelector();
    }
}
