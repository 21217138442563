import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { StringHelper } from '@app/utilities/helpers';

import { CatalogueService } from '@app/services/catalogue';
import { ImageTypes } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';

@Component({
    selector: 'page-doors-listing',
    templateUrl: './doors-listing.page.html',
    styleUrls: ['./doors-listing.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DoorsListingPage implements OnInit, OnDestroy {
    public header: any;

    public selectedRangeType: string;
    public selectedColour: string;
    public filters;
    public filteredRanges: any;

    private ranges;

    private routeHistory: RouteHistory[] = [
        { title: 'Kitchen Doors', route: '/kitchen-doors' }
    ];

    private route: Subscription;

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.route = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                this.selectedRangeType = (route.params.rangeType) ? route.params.rangeType.toLowerCase() : 'allstyles';
                this.selectedColour = (route.params.colour) ? route.params.colour.toLowerCase() : 'allcolours';

                const rangeType = (this.selectedRangeType === 'allstyles') ? '' :
                    StringHelper.titleCase(StringHelper.dashToSpace(this.selectedRangeType)) + ' ';

                let routeHistory = this.routeHistory.slice();

                if (rangeType === '') {
                    routeHistory.push({
                        title: 'All Styles',
                        route: `/kitchen-doors/${this.selectedRangeType}/`
                    });
                } else if (rangeType !== '') {
                    routeHistory.push({
                        title: rangeType,
                        route: `/kitchen-doors/${this.selectedRangeType}/`
                    });
                }

                const titleColour = route.params.colour ? StringHelper.titleCase(StringHelper.dashToSpace(route.params.colour)) + ' ' : '';
                this.navigationService.setNavigation({
                    title: `${rangeType} ${titleColour}Kitchen Doors`,
                    metaTags: [
                        { name: 'no-meta', content: 'no metadata' }
                    ],
                    routeHistory: routeHistory
                });

                this.catalogueService.getRanges()
                    .then((ranges: any) => {
                        this.ranges = JSON.parse(JSON.stringify(ranges)).filter((range) => {
                            return range.inframe === 'No';
                        });
                        this.setFilters();
                        this.filterRanges();
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));

                // TODO; Get the kitchens styles header data from API.
                this.catalogueService.getKitchensHeader(this.selectedRangeType)
                    .then((header) => {
                        this.header = header;
                        this.header.colour = titleColour;
                        const metaDescription = `See our range of ${this.header.name} Doors.`;
                        this.navigationService.setMetaTags([
                            { property: 'og:image', content: `https://static.diy-kitchens.com/assets/images/seo/samples/samples/door-samples.jpg` },
                            { name: 'description', content: metaDescription},
                            { property: 'og:description', content: metaDescription}
                        ]);
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            }
        );
    }

    ngOnDestroy() {
        if (this.route) {
            this.route.unsubscribe();
            this.route = null;
        }
    }

    public getId(name: string): string {
        return StringHelper.spaceToDash(name);
    }

    private setFilters() {
        let filters = {
            results: 0,
            rangeType: {
                selected: this.selectedRangeType,
                options: [{
                    id: 'allstyles',
                    name: 'All Styles',
                    link: '/kitchen-doors/allstyles/',
                    image: 'https://static.diy-kitchens.com/assets/images/kitchens/highgloss-icon.jpg'
                }]
            },
            colour: {
                selected: this.selectedColour,
                options: [{
                    id: 'allcolours',
                    name: 'All Colours',
                    link: this.getFullUrl('colour', 'allcolours'),
                    image: 'all'
                }]
            }
        };

        if (this.ranges && this.ranges.length) {
            let rangeTypes = [];
            let colours = [];
            let colourClass = {};

            this.ranges.forEach((range) => {
                if (this.selectedRangeType === 'allstyles') {
                    range.displayRange = true;
                } else if (range.filters.map((filter) => StringHelper.spaceToDash(filter)).indexOf(this.selectedRangeType) !== -1) {
                    range.displayRange = true;
                } else {
                    range.displayRange = false;
                }

                if (range.filters && range.filters.length) {
                    range.filters.forEach((rangeType) => {
                        if (rangeTypes.indexOf(rangeType) === -1) {
                            rangeTypes.push(rangeType);
                        }
                    });
                }

                if (range.displayRange) {
                    if (range.colours && range.colours.length) {
                        range.colours.forEach((colour) => {
                            colourClass[colour.name] = colour.css_class;

                            if (colours.indexOf(colour.name) === -1) {
                                colours.push(colour.name);
                            }
                        });
                    }
                }
            });

            filters.rangeType.options = filters.rangeType.options.concat(rangeTypes.map((rangeType) => {
                const id = StringHelper.spaceToDash(rangeType);

                return {
                    id: id,
                    name: StringHelper.titleCase(rangeType),
                    link: `/kitchen-doors/${id}/`,
                    image: `https://static.diy-kitchens.com/assets/images/kitchens/${id}-icon.jpg`
                };
            }));

            filters.colour.options = filters.colour.options.concat(colours.sort().map((colour) => {
                const id = StringHelper.spaceToDash(colour);

                return {
                    id: id,
                    name: colour,
                    link: this.getFullUrl('colour', id),
                    image: colourClass[colour]
                };
            }));
        }

        this.filters = filters;
    }

    private filterRanges() {
        let filteredRanges = [];

        this.ranges.forEach((range) => {
            range.colours = range.colours.filter((colour) => {
                if (!colour.availability) {
                    const colourId = StringHelper.spaceToDash(colour.name);
                    if (colourId === this.selectedColour) {
                        range.selectedColour = colour;
                    }

                    return true;
                }

                return false;
            });

            if (!range.selectedColour) {
                range.selectedColour = range.colours[0];
            }

            if (this.selectedColour !== 'allcolours') {
                if (range.displayRange) {
                    if (!range.colours.find((colour) => {
                        return StringHelper.spaceToDash(colour.name) === this.selectedColour;
                    })) {
                        range.displayRange = false;
                    }
                }
            }

            if (range.displayRange) {
                filteredRanges.push(range);
            }
        });

        this.filters.results = filteredRanges.length;

        filteredRanges.sort((a, b) => {
            if (a.rank > b.rank) {
                return 1;
            } else if (a.rank < b.rank) {
                return -1;
            }

            return 0;
        });

        this.filteredRanges = filteredRanges;
    }

    private getFullUrl(param, value) {
        const url = [
            'kitchen-doors',
            this.selectedRangeType,
            (param === 'colour') ? value : this.selectedColour
        ].join('/');

        return `/${url}/`;
    }

    public rangeLink(range) {
        const style = StringHelper.spaceToDash(`${range.name} ${range.selectedColour.name}`);

        return `/kitchen-doors/${style}/doors-only-ordering/`;
    }

    public selectRange(range) {
        this.catalogueService.updateActiveRange(range, range.selectedColour.colour, null, null, this.catalogueService.activeRange?.carcaseColour || 'White');
    }

    public switchRangeColour(range, colour) {
        range.selectedColour = colour;
    }

    public getRangeImage(range) {
        return this.catalogueService.image({
            rangeId: range.range_id,
            colour: range.selectedColour.name,
            type: ImageTypes.STYLES
        });
    }

    public getRangeDoorImage(range) {
        return 'https://static.diy-kitchens.com/doors/' + range.selectedColour.door_image;
    }
}
