import { NgModule } from '@angular/core';

import { AboutService } from './about.service';

@NgModule({
    providers: [
        AboutService
    ]
})
export class AboutServiceModule { }
