import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Config } from '@app/config';

import { ProductType } from '@app/services/catalogue/models';
import { CheckoutService } from '@app/services/checkout';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { ViewType, NavigationRoute } from '@app/services/navigation/models';
import { OrdersService } from '@app/services/orders';

@Component({
    selector: 'page-order',
    templateUrl: './order.page.html',
    styleUrls: ['./order.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class OrderPage implements OnInit, OnDestroy {
    public ProductType = ProductType;

    public loading: boolean = false;
    public isInvoice: boolean = false;

    public order: any;
    public errorMessage: string = null;

    private orderNumber: string;
    private currentViewType;
    private route: Subscription;

    constructor(
        private config: Config,
        private checkoutService: CheckoutService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private ordersService: OrdersService
    ) {
        this.navigationService.setTitle('DIY Kitchens - View Order');

        this.route = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                this.orderNumber = (route.params && route.params.orderNumber) ? route.params.orderNumber : null;

                if (route.segments && route.segments.length && route.segments.indexOf('invoice') !== -1) {
                    this.isInvoice = true;
                    this.navigationService.setView(ViewType.NONE);
                } else {
                    this.navigationService.setRouteHistory([
                        { title: 'Support', route: '/support' },
                        { title: 'Your Orders', route: '/account/orders' },
                        { title: this.orderNumber || 'Order', route: `/account/order/${this.orderNumber}` }
                    ]);
                }
            }
        );
    }

    ngOnInit() {
        this.getOrder();
    }

    ngOnDestroy(): void {
        if (this.route) {
            this.route.unsubscribe();
            this.route = null;
        }

        if (this.currentViewType) {
            this.navigationService.setView(this.currentViewType);
        }
    }

    public deliveryTypeName(order) {
        const deliveryType = this.checkoutService.deliveryTypeName(order.deliveryType);

        if (deliveryType === '') {
            return order.deliveryType;
        }

        return deliveryType || order.deliveryType;
    }

    private getOrder() {
        this.ordersService.orderDetails(this.orderNumber)
            .then((response: any) => {
                this.order = response;

                if (this.isInvoice) {
                    setTimeout(() => {
                        if (this.config.isBrowser) {
                            window.print();
                        }
                    }, 500);
                }
            })
            .catch((error) => {
                if (error && error.body && error.body.message && error.body.message === 'Access Restricted') {
                    this.errorMessage = error.body.message;
                } else {
                    this.dialogService.error(this.constructor.name, error);
                }
            });
    }
}
