<header class="content-contained">
    <h1>Reset Password</h1>
    <p>Enter your email address and we'll send you a link to reset your password.</p>
</header>

<hr class="narrow" />

<section class="content-contained">
    <div class="reset-form">
        <form [formGroup]="resetEmailFormGroup" *ngIf="!resetToken">
            <div class="customer-details">
                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" />
                </mat-form-field>
            </div>

            <p *ngIf="errorMessage" class="error-message">{{errorMessage}}</p>
            <p *ngIf="noticeMessage" class="notice-message">{{noticeMessage}}</p>

            <button mat-flat-button color="primary" class="chunky" [disabled]="resetEmailFormGroup.invalid || reseting" (click)="resetEmail()">
                Reset Password
            </button>
        </form>

        <form [formGroup]="resetPasswordFormGroup" *ngIf="resetToken">
            <div class="customer-details">
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="password" type="password" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Confirm Password</mat-label>
                    <input matInput formControlName="passwordConfirm" type="password" />
                </mat-form-field>
            </div>

            <p *ngIf="errorMessage" class="error-message">{{errorMessage}}</p>
            <p *ngIf="noticeMessage" class="notice-message">{{noticeMessage}}</p>

            <button mat-flat-button color="primary" class="chunky" [disabled]="resetPasswordFormGroup.invalid || reseting" (click)="resetPassword()">
                Reset Password
            </button>
        </form>
    </div>
</section>
