import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'component-page-not-found',
    templateUrl: 'page-not-found.component.html',
    styleUrls: ['page-not-found.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PageNotFoundComponent {
    constructor() { }
}
