import { Directive, ElementRef, Input, OnInit, OnChanges } from '@angular/core';

import { Config } from '@app/config';

@Directive({
    selector: '[cdn]',
    standalone: false
})
export class CdnDirective implements OnInit, OnChanges {
    @Input('cdn') url: string;

    constructor(
        private config: Config,
        private element: ElementRef
    ) { }

    ngOnInit() {
        this.addCDN();
    }

    ngOnChanges() {
        this.addCDN();
    }

    private addCDN() {
        if (this.url) {
            if (this.element.nativeElement.nodeName === 'IMG') {
                this.element.nativeElement.src = `${this.config.api.endpoints.cdn}/${this.url}`;
            } else {
                this.element.nativeElement.style.backgroundImage = `url('${this.config.api.endpoints.cdn}/${this.url}')`;
            }
        }
    }
}
