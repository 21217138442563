import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Config } from '@app/config';
import { StringHelper } from '@app/utilities/helpers';

import { Article } from '@app/services/articles/models';
import { BasketService } from '@app/services/basket';
import { BasketItem, BasketItemHanding } from '@app/services/basket/models';
import { CatalogueService } from '@app/services/catalogue';
import { CostCalculator } from '@app/services/catalogue/cost-calculator';
import { ActiveRange, HandingType, ProductType } from '@app/services/catalogue/models';
import { IAppliance, ICabinet, isCabinet } from '@app/services/catalogue/models/product.models';
import { IMedia, IProductSpecification } from '@app/services/catalogue/models/product.models/common';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';

@Component({
    selector: 'page-appliance-detail',
    templateUrl: './appliance-detail.page.html',
    styleUrls: ['./appliance-detail.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ApplianceDetailPage implements OnInit, OnDestroy {
    public ProductType = ProductType;
    public item: IAppliance;
    public traditionals: Article[];
    public traditionalTags = ['diy kitchens - website', 'units and doors', 'create your look', 'traditional'];
    public headerSections: string[] = ['description', 'included', 'dimensions', 'specifications', 'warranty'];
    public hideHeader: boolean;
    public housings: any[] = [];
    public units: ICabinet[] = [];
    public type: ProductType = ProductType.APPLIANCES;
    public productImages: IMedia[] = [];

    public Handing = BasketItemHanding;
    public HandingType = HandingType;

    public warranties: any = {
        CDA: {
            // '5-year': {
            //     url: 'https://www.cda.co.uk/warranty/',
            //     image: 'https://static.diy-kitchens.com/assets/images/products/appliances/warranties/cda/cda-5-year-warranty.png'
            // }
            '5-year': {
                url: 'https://www.cda.co.uk/ovens-promotion/',
                image: 'https://static.diy-kitchens.com/assets/images/products/appliances/warranties/cda/cda-5-year-promo-warranty.png'
            }
        },
        Zanussi: {
            '1-year': {
                url: 'https://www.zanussi.co.uk/support/warranties/',
                image: 'https://static.diy-kitchens.com/assets/images/products/appliances/warranties/zanussi/zanussi-1-year-warranty.png'
            }
        },
        AEG: {
            '5-year': {
                url: 'https://www.aeg.co.uk/support/warranties/',
                image: 'https://static.diy-kitchens.com/assets/images/products/appliances/warranties/aeg/aeg-5-year-warranty.png'
            }
        }
    }

    public offers: any = {
        CDA: [
            // {
            //     route: '/kitchen-sales',
            //     fragment: 'cdaoffer1',
            //     colour: '#E84339',
            //     title: 'Up to £150 cashback'
            // },
            {
                route: '/kitchen-sales',
                fragment: 'cdaoffer5',
                colour: '#3F6E6B',
                title: 'Extended Warranty'
            },
            // {
            //     route: '/kitchen-sales',
            //     fragment: 'cdaoffer2',
            //     colour: '#222222',
            //     title: '10% Off 3 or more'
            // }
        ],
        AEG: [
            {
                route: '/kitchen-sales',
                fragment: 'aegoffer1',
                colour: '#21201F',
                title: 'Up to £1000 cashback'
            },
            {
                route: '/kitchen-sales',
                fragment: 'aegoffer2',
                colour: '#7444E3',
                title: 'Free dishwasher'
            }
        ]
    }

    public noResults = false;

    private activeRangeSubscription: Subscription;
    public activeRange: ActiveRange;
    private costCalculator: CostCalculator = new CostCalculator();
    private routeSubscription: Subscription;
    private routeHistory: RouteHistory[] = [{ title: 'Appliances', route: '/appliances' }];

    constructor(
        private config: Config,
        private basketService: BasketService,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                this.activeRangeSubscription = this.catalogueService.activeRange$.subscribe((rangeInfo: ActiveRange) => {
                    this.activeRange = rangeInfo;
                    this.getCatalogueData(route.params.details || null);
                });

            }
        );


    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }

        if (this.activeRangeSubscription) {
            this.activeRangeSubscription.unsubscribe();
            this.activeRangeSubscription = null;
        }
    }

    private getCatalogueData(details: string) {
        this.catalogueService.getApplianceByDetails(details)
            .then((appliance: any) => {
                let routeHistory = this.routeHistory.slice();

                if (appliance) {
                    this.item = appliance;
                    this.item.qty = 1;
                    this.item._cost = this.item.cost || 0;
                    // this.item.title = this.item.title.replace(this.item.code, '').replace(' -', '')

                    this.productImages = this.item.extended_details?.images?.map(image => ({
                        image: `${this.config.api.endpoints.cdn}/assets/images/products/appliances/large/${image}`
                    }));

                    const removeDuplicates = (arr: IProductSpecification[]): IProductSpecification[] => {
                        return arr.filter((obj, index) => {
                            return !arr.some((otherObj, otherIndex) => {
                                return otherIndex < index && obj.name === otherObj.name && obj.value === otherObj.value;
                            });
                        });
                    }

                    if (this.item.extended_details?.specifications) {
                        this.item.extended_details.specifications = removeDuplicates(this.item.extended_details.specifications);
                    }

                    this.item.extended_details?.specifications?.forEach((specification => {
                        specification.name = StringHelper.titleCase(specification.name.replace(/_/g, ' '));
                    }));

                    const categoryLink = StringHelper.spaceToDash(this.item.category);
                    routeHistory.push({
                        title: this.item.category,
                        route: `/appliances/${categoryLink}`
                    });

                    const subCategoryLink = StringHelper.spaceToDash(this.item.subcat);
                    routeHistory.push({
                        title: this.item.subcat,
                        route: `/appliances/${categoryLink}/${subCategoryLink}`
                    });

                    routeHistory.push({
                        title: this.item.code,
                        route: `/appliances/${categoryLink}/${subCategoryLink}/${details}`
                    });

                    const metaDescription = `${this.item.description} from the DIY Kitchens ${this.item?.subcat.toLowerCase() || this.item?.category.toLowerCase() || 'appliances'} range.`
                    this.navigationService.setNavigation({
                        title: this.item.description,
                        metaTags: [
                            { name: 'no-meta', content: 'no metadata' },
                            { name: 'description', content: metaDescription},
                            { property: 'og:description', content: metaDescription}
                        ]
                    });
                }

                this.navigationService.setRouteHistory(routeHistory);

                this.catalogueService.getApplianceHousingsByAperture(appliance?.generic_appliance_id)
                    .then((units: any[]) => {
                        this.units = units;
                        if (units.length > 0) {
                            this.initHousings(units);
                        }
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public addApplianceToBasket() {
        const basketItem = new BasketItem(this.item, this.catalogueService.activeRange, this.type);
        this.basketService.addItem(basketItem, this.type);
    }

    public itemCost(item) {
        if (this.activeRange) {
            item._cost = this.costCalculator.getUnitPriceRangeAndColour(
                this.activeRange.rangeDetail,
                this.activeRange.rangeColour,
                item.unit_code
            );
        } else {
            item._cost = item.cab_price || 0;
        }

        return item._cost;
    }

    public sortWidths(widths) {
        return widths.sort((a, b) => {
            if (a.width !== b.width) {
                return a.width - b.width;
            }

            return a.cost - b.cost;
        });
    }

    private initHousings(units) {
        if (units) {
            this.housings = [];
            units.map((item) => {
                if (this.itemCost(item) > 0) {
                    if (item.promotion) {
                        item.salePrice = this.getItemSalePrice(item);
                    }
                    const exists = this.housings.filter((entry) => {
                        if (item.height !== entry.item.height || item.depth !== entry.item.depth) {
                            return false;
                        }

                        if (item.type) {
                            return item.type === entry.item.type;
                        }

                        return (item.category === entry.item.category
                            && item.desc === entry.item.desc
                            && item.subcat === entry.item.subcat);
                    });

                    if (exists && exists[0]) {
                        if (exists[0].maxPopularity < item.popularity) {
                            exists[0].maxPopularity = item.popularity;
                            exists[0].item = item;
                        } else if (exists[0].maxPopularity === item.popularity) {
                            if (item.width === 600) {
                                exists[0].item = item;
                            }
                        }

                        const existingWidth = exists[0].widths.find(width => width.width === item.width);
                        if (existingWidth) {
                            existingWidth.variants.push({
                                code: item.unit_code,
                                variant: item.variant || item.unit_code // TODO replace variant name with data from API
                            });
                        } else {
                            exists[0].widths.push({
                                width: item.width,
                                variants: [{
                                    code: item.unit_code,
                                    variant: item.variant || item.unit_code // TODO replace variant name with data from API
                                }],
                                cost: item._cost
                            });
                        }
                    } else {
                        this.housings.push({
                            item: item,
                            maxPopularity: item.popularity,
                            widths: [{
                                width: item.width,
                                variants: [{
                                    code: item.unit_code,
                                    variant: item.variant || item.unit_code // TODO replace variant name with data from API
                                }],
                                cost: item._cost
                            }]
                        });
                    }
                }
            });
        }
    }

    public setHinge(item, side) {
        item._hingeSide = side;
    }

    public hingeWording(item: ICabinet): string {
        return this.catalogueService.getHingeWording(item);
    }

    public toggleSoft(item) {
        item._softClose = !item._softClose;
    }

    public variants(entry) {
        const selectedWidth = entry.widths.find(width => width.width === entry.item.width);

        return selectedWidth.variants;
    }

    public selectWidth(entry, width) {
        const newItem = this.units.find(cab => cab.unit_code === width.variants[0].code);
        if (newItem) {
            entry.item = newItem;
        }
    }

    public selectVariant(entry, variant) {
        const newItem = this.units.find(cab => cab.unit_code === variant.code);
        if (newItem) {
            entry.item = newItem;
        }
    }

    public addUnitToBasket(entry) {
        this.basketService.addItem(entry, ProductType.CABINETS)
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    private getItemSalePrice(item: ICabinet): number | null {
        if (item.promotion && item._cost && isCabinet(item)) {
            const cabPrice = (item.cab_salePrice) ? item.cab_salePrice : item.cab_price;
            const frontals = (this.activeRange) ?
                this.catalogueService.getDoorForUnit(item.unit_code, this.activeRange?.rangeColour || 'Alabaster') :
                null;
            let totalCost: number = cabPrice;
            if (frontals) {
                Object.values(frontals).forEach((door: any) => {
                    let doorCost = (door.salePrice) ? door.salePrice : door._cost;
                    totalCost += doorCost;
                });
            }
            return totalCost;
        }
        return null;
    }
}
