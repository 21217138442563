import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';

import { DeepMergeHelper } from '@app/utilities/helpers';

import { DialogService } from '@app/services/dialog';
import { StorageService } from '@app/services/storage';

import { environment } from '../environments/environment';

@Injectable()
export class Config {
    protected params: ConfigDefault;

    public settings: ConfigSettings;

    get diy(): ConfigDIY {
        return this.params.diy;
    }

    get api(): ConfigApi {
        return this.params.api;
    }

    get app(): ConfigApp {
        return this.params.app;
    }

    get auth(): ConfigAuth {
        return this.params.auth;
    }

    get payments(): ConfigPayments {
        return this.params.payments;
    }

    get pipes(): ConfigPipes {
        return this.params.pipes;
    }

    get storage(): ConfigStorage {
        return this.params.storage;
    }

    get trusted(): ConfigTrusted {
        return this.params.trusted;
    }

    get tracking(): ConfigTracking {
        return this.params.tracking;
    }

    public storageService: StorageService;
    public dialogService: DialogService;

    public isBrowser = true;

    private _includeVatSubject: BehaviorSubject<boolean>;
    public includeVatChanges: Observable<boolean>;

    constructor(@Inject(PLATFORM_ID) private platformId) {
        this.params = DeepMergeHelper.merge(environment.config, {});

        this.settings = {
            includeVat: false,
            softClose: true,
        };

        this.isBrowser = isPlatformBrowser(platformId);

        this._includeVatSubject = new BehaviorSubject<boolean>(this.settings.includeVat);
        this.includeVatChanges = this._includeVatSubject.asObservable();
    }

    public loadSettings() {
        if (this.storageService) {
            this.storageService
                .get('settings')
                .then((response) => {
                    if (response) {
                        Object.keys(this.settings).forEach((key) => {
                            if (typeof response[key] !== 'undefined') {
                                this.settings[key] = response[key];
                            }
                        });
                    }
                })
                .catch((error) => {
                    if (this.dialogService) {
                        this.dialogService.error(this.constructor.name, error);
                    } else {
                        console.error(error);
                    }
                });
        } else {
            if (this.dialogService) {
                this.dialogService.error(this.constructor.name, 'No Settings Loader');
            } else {
                console.error('No Settings Loader');
            }
        }
    }

    public saveSettings() {
        this._includeVatSubject.next(this.settings.includeVat);
        if (this.storageService) {
            this.storageService.set('settings', this.settings).catch((error) => {
                if (this.dialogService) {
                    this.dialogService.error(this.constructor.name, error);
                } else {
                    console.error(error);
                }
            });
        } else {
            if (this.dialogService) {
                this.dialogService.error(this.constructor.name, 'No Settings Loader');
            } else {
                console.error('No Settings Loader');
            }
        }
    }
}

export interface ConfigDefault {
    diy: ConfigDIY;
    api: ConfigApi;
    app: ConfigApp;
    auth: ConfigAuth;
    payments: ConfigPayments;
    pipes: ConfigPipes;
    storage: ConfigStorage;
    trusted: ConfigTrusted;
    tracking: ConfigTracking;
}

export interface ConfigDIY {
    ranges: number;
    carcases: number;
}

export interface ConfigApi {
    cache: number;
    endpoints: ConfigApiEndpoints;
    timeout: number;
}

export interface ConfigApiEndpoints {
    cdn: string;
    universal: string;
    articles: string;
    liveDiy: string;
    diy: string;
    orders: string;
    catalogue: ConfigApiEndpoint;
    catalogueRange: ConfigApiEndpoint;
    customerReviews: ConfigApiEndpoint;
    productDetails: ConfigApiEndpoint;
    legacyImageCatalogue: ConfigApiEndpoint;  // Temporary for legacy basket migration
    searchTermCatalogue: ConfigApiEndpoint;
    payment: string;
    reviews: string;
    comparison: string;
    support: string;
    planner: string;
    messagingUI: string;
    messaging: string;
    plannerUI: string;
    universalUI: string;
}

export interface ConfigApiEndpoint {
    url: string;
    inflate: boolean;
}

export interface ConfigApp {
    id: string;
    name: string;
    icon: string;
}

export interface ConfigAuth {
    authorization: string;
    login: string;
    logout: string;
    returnUrl: string;
    redirect: string;
    storage: string;
    type: string;
    timeout: number;
}

export interface ConfigPipes {
    currency: ConfigPipesCurrency;
}

export interface ConfigPipesCurrency {
    unit: string;
    separator: string;
}

export interface ConfigPayments {
    vatRate: number;
    minPayment: number;
    minOrderValue: number;
    stripe: ConfigPaymentsStripe;
}

export interface ConfigPaymentsStripe {
    publishableKey: string;
}

export interface ConfigStorage {
    name: string;
    storeName: string;
    universal: string;
    worktopConfig: string;
}

export interface ConfigTrusted {
    [x: string]: string[];
}

export interface ConfigTracking {
    gtag: ConfigTrackingGtag;
}

export interface ConfigTrackingGtag {
    id: string;
    code: string;
}

export interface ConfigSettings {
    includeVat: boolean;
    softClose: boolean;
}
