import * as config from './config.prod.json';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const Config = <any>config;

export const environment = {
    production: true,
    config: (Config && Config.default) ? Config.default : Config
};
