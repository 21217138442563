import { Pipe, PipeTransform } from '@angular/core';

import { StringHelper } from '@app/utilities/helpers';

@Pipe({
    name: 'sentenceCase',
    standalone: false
})
export class SentenceCasePipe implements PipeTransform {
    transform(text: string): string {
        return StringHelper.sentenceCase(text || '');
    }
}
