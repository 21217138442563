import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Config } from '@app/config';

@Component({
    selector: 'component-comparison-details-dialog',
    templateUrl: './details.dialog.component.html',
    styleUrls: ['./details.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ComparisonDetailsDialogComponent {
    public title;
    public description;
    public references;
    public videoLink;
    public brand;

    constructor(
        private dialogRef: MatDialogRef<ComparisonDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private config: Config
    ) {
        if (data) {
            if (data.title) {
                this.title = data.title;
            }

            if (data.popup) {
                if (data.popup.description) {
                    this.description = data.popup.description;
                }

                if (data.popup.references) {
                    this.references = data.popup.references;
                }

                if (data.popup.videoLink) {
                    this.videoLink = data.popup.videoLink;
                }

                if (data.brand) {
                    this.brand = data.brand;
                }
            }
        }
    }

    public getVideoLink() {
        return `https://www.youtube.com/embed/${this.videoLink}?rel=0`;
    }

    public viewReferenceData() {
        if (this.config.isBrowser) {
            this.references.forEach((ref)=>{
                if (ref.brand === this.brand) {
                    window.open(ref.url);
                    this.close();
                }
            });
        }
    }

    public close() {
        this.dialogRef.close();
    }
}
