@import '/src/theme/variables';

page-laminate-worktops-page {
    .products {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: auto;
        padding: 15px 0 15px 35px;
        /* padding: 110px 0 15px 67px;*/
        max-width: calc(100% - 280px);
        height: -moz-fit-content;
        height: fit-content;
    }

    .doors-listing-top-banner {
        display: flex;
        align-items: flex-start;
        gap: 30px;
        padding: 15px 0 30px 0;
        margin: 0;
        position: relative;

        @include responsive-breakpoint-down(lg) {
            padding-top: 10px;
        }

        @include responsive-breakpoint-down(md) {
            flex-direction: column;
            gap: 20px;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: -15px;
            right: -15px;
            height: 1px;
            background: rgba(0, 0, 0, .1);
        }

        .doors-listing-item {
            flex: 0 0 calc(50% - 15px);
            max-width: calc(50% - 15px);

            @include responsive-breakpoint-down(md) {
                flex: 1;
                max-width: inherit;
            }

            img {
                max-width: 100%;
                height: auto;
                object-fit: contain;
                object-position: center center;
            }
        }
    }

    .content-header-background {
        background-position-x: 100% !important;
        background-repeat: no-repeat;
        background-size: 50% !important;
        background-image: url(https://static.diy-kitchens.com/categories/worktops/category-images_page-header/category-image_page-header_worktops_laminate.png);

        @include responsive-breakpoint-down(xl) {
            padding: 0 30px;
        }
    }

    .categories {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 5px;

        .item-container {
            flex: 0 0 100%;
            width: 100%;

            .item {
                display: flex;
                align-self: flex-start;
                gap: 30px;
                background: transparent;
                box-shadow: none;
                margin-bottom: 2.5rem;

                @include responsive-breakpoint-between(md, lg) {
                    margin-bottom: 1rem;
                }

                @include responsive-breakpoint-down(md) {
                    gap: 20px;
                    margin: 0.75rem 0;
                }

                .description {
                    font-family: 'Open Sans';
                    font-size: clamp(1rem, 1vw + 1rem, 1rem);
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: clamp(1.333rem, 1vw + 1.333rem, 1.333rem);
                    color: rgba(0, 18, 36, 0.9);
                    margin-bottom: 2.5rem;

                    @include responsive-breakpoint-down(md) {
                        margin-bottom: 2rem;
                    }

                    a {
                        color: color('link');
                        text-transform: uppercase;
                        cursor: pointer;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .price {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    @include responsive-breakpoint-down(md) {
                        justify-content: flex-start;
                    }
                }
            }

            .image {
                flex: 0 0 209px;
                max-width: 209px;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                padding: 20px 0;
                height: 209px;

                @include responsive-breakpoint-between(md, xl) {
                    height: 184px;
                }

                @media screen and (min-width: 768px) and (max-width: 1000px) {
                    width: 184px;
                    flex: inherit;
                }

                @include responsive-breakpoint-down(md) {
                    height: 112px;
                    width: 112px;
                    padding: 10px 0;
                    flex: inherit;
                }

                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                img {
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .details {
                flex: 1;
                padding: 20px 10px 20px 0;

                @include responsive-breakpoint-between(md, lg) {
                    padding: 20px 0;
                }

                @include responsive-breakpoint-down(md) {
                    padding: 10px 0;
                }

                >.flex-space-between {
                    @include responsive-breakpoint-down(md) {
                        flex-direction: column;
                    }
                }

                .product-brand-cnt {
                    width: auto;
                    height: 24px;
                    margin-bottom: 1rem;

                    @include responsive-breakpoint-down(md) {
                        height: 18px;
                    }

                    img {
                        height: 100%;
                        width: auto;
                        object-fit: contain;
                    }
                }

                p {
                    color: color('black');
                    font-family: 'Open Sans';
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 32px;
                    letter-spacing: 0.48px;
                    text-align: left;
                }

                .actions {
                    display: flex;
                    gap: 30px;
                    justify-content: flex-end;
                    margin-left: auto;
                    max-width: 150px;

                    >button {
                        flex: 0 1 50%;

                        @include responsive-breakpoint-down(md) {
                            flex: 1;
                        }
                    }
                }                
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .products {
            margin-top: 10px;
            margin-left: 0;
            max-width: 100%;
            padding: 15px 0;

            .item {
                flex-wrap: nowrap;
                margin: 15px 0;
                max-width: 100%;
            }
        }
    }

    @media only screen and (max-width: 800px) {
        .products {
            .item {
                flex-wrap: wrap;
                margin: 15px 0;

                .details {
                    .price {
                        .currency {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
    }

    .alert-info-section {
        background-color: #e2e3e5;
        border: 1px solid #d6d8db;
        padding: .75rem 1.25rem;
        margin-bottom: 1.5rem;
        margin-top: 1rem;
        border-radius: 0.25rem;

        p {
            font-family: 'Open Sans';
            font-size: 13px;
            color: #383d41;
            line-height: 1.618rem;
            margin-bottom: 1.5rem;

            strong {
                font-weight: bold;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .note-info-container {
        background: #c00;
        padding: 30px 15px;
        margin-top: 30px;

        @include responsive-breakpoint-down(lg) {
            padding: 15px;
        }

        h5 {
            font-family: 'Open Sans';
            font-size: 15px;
            font-weight: bold;
            line-height: 1.618rem;
            color: color('white');
            margin: 0 0 3px;
            letter-spacing: 0;
        }

        p {
            font-family: 'Open Sans';
            font-size: 15px;
            font-weight: 400;
            color: color('white');
            line-height: 1.618rem;
            margin: 0;
            letter-spacing: 0;
        }
    }

    .main-content-row {
        @media screen and (max-width: 1000px) {
            flex-direction: column;
        }
    }
}
