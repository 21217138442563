import { Component, ViewEncapsulation } from '@angular/core';

import { AuthService } from '@app/services/auth';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';

@Component({
    selector: 'page-account',
    templateUrl: './account.page.html',
    styleUrls: ['./account.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AccountPage {
    public get isAuthenticated(): boolean {
        return this.authService.isAuthenticated;
    }

    constructor(
        private authService: AuthService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
    ) {
        this.navigationService.setNavigation({
            title: 'DIY Kitchens - Support Portal',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' }
            ],
            routeHistory: [{ title: 'Support', route: '/support' }]
        });
    }

    public openOrders() {
        this.authService.loginPrompt()
            .then(() => this.navigationService.navigate(['/account/orders']))
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public openBaskets() {
        this.authService.loginPrompt()
            .then(() => this.navigationService.navigate(['/account/baskets']))
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
