<section class="video-banner">
    <div class="video">
        <div class="video-with-control">
            <video muted autoplay loop playsinline #videoBanner width="1920" height="1080" aria-label="This video has no spoken audio">
                <source src="https://static.diy-kitchens.com/advice/video/homepage2024.mp4" type="video/mp4" />
                <track kind="captions" srclang="en" label="No spoken audio" default>
            </video>
            <div class="play-button" id="playButton" aria-label="Play Video" [class.show]="!videoBannerPlaying" (click)="playVideoBanner()"><mat-icon>play_circle_filled</mat-icon></div>
        </div>
        <div class="num1">
            <div class="planner-promo-content">
                <span>Expert help 7 days a week</span>
                <p class="heading">Try our revolutionary<br />free planning app</p>
                <a href="https://planner.diy-kitchens.com" target="_blank" title="Start your plan">START YOUR PLAN</a>
            </div>
        </div>
    </div>

    <div class="info-banner">
        <div class="contained">
            <div class="panel">
                <p class="heading">Incredible choice</p>
                <p class="content">
                    With over 200 different styles and 900 fully built and colour co-ordinated units, you get
                    unbeatable choice, delivered right to your home.
                </p>
                <p class="bold"><a class="button-link" routerLink="/kitchens" title="Create Your Dream Kitchen">CREATE YOUR DREAM KITCHEN</a></p>
            </div>
            <div class="panel">
                <p class="heading">Unmistakable quality</p>
                <p class="content">
                    Our ethos is simple. For over 40 years, we've manufactured the highest quality
                    kitchens, using the latest technology and handcrafted skills.
                </p>
                <p class="bold"><a class="button-link" routerLink="/about-diy-kitchens" title="About Us">FIND OUT MORE</a></p>
            </div>
            <div class="panel">
                <p class="heading">Unbeatable savings</p>
                <p class="content">
                    Save £1000s over buying the same kitchen from other major retailers, by ordering
                    online, directly from the manufacturer.
                </p>
                <p class="bold"><a class="button-link" routerLink="/about-diy-kitchens/price-and-quality-comparison" title="Price & Quality Comparison">SEE HOW WE COMPARE</a></p>
            </div>
        </div>
    </div>

    <div class="round-alls">
        <a routerLink="/about-diy-kitchens/showroom" title="Visit Our Showroom">
            <img src="https://static.diy-kitchens.com/assets/images/homepage/slider/homepage-silder-showroom-open-overlay-v4.png"
                 alt="Book your showroom visit" />
        </a>
        <a routerLink="/about-diy-kitchens/price-and-quality-comparison" title="Price & Quality Comparison">
            <img src="https://static.diy-kitchens.com/assets/images/homepage/slider/homepage-slider-trustpilot-overlay.png"
                 alt="See how we compare" />
        </a>
    </div>
</section>

<section class="contained true-handleless">
    <h1>True Handleless</h1>
    <h5>Now available to plan and order!</h5>
</section>

<section class="contained true-handleless tri-usp">
    <div class="tri-block">
        <div class="tri-image">
            <img src="https://static.diy-kitchens.com/ranges/range041_light_grey_900px.jpg" width="100%" alt="Menet True Handleless" />
        </div>
    </div>

    <div class="tri-block">
        <div class="tri-image">
            <img src="https://static.diy-kitchens.com/ranges/range042_wenge_900px.jpg" width="100%" alt="Verona True Handleless" />
        </div>
    </div>

    <div class="tri-block">
        <div class="tri-image">
            <img src="https://static.diy-kitchens.com/ranges/range040_concrete_900px.jpg" width="100%" alt="Cento True Handleless" />
        </div>
    </div>
</section>

<section class="contained true-handleless">
    <h5>Available in Cento, Menet, Verona, Sienna & Palazzo Door Styles</h5>

    <p>True Handleless kitchens from DIY Kitchens are now available on our FREE online kitchen planner.</p>
    <p>Designing a True Handleless kitchen is slightly more complicated than a standard kitchen, therefore you can only place an order by creating a plan first. The planner will ensure you have all the components necessary to complete installation and our expert team are only a click away if you have a query about designing your kitchen.</p>
    <p><strong>Please check the planner for accurate lead times.</strong></p>

    <a href="https://planner.diy-kitchens.com" target="_blank" title="Planner">
        <button mat-flat-button color="primary">Start Planning your True Handleless Kitchen</button>
    </a>
</section>

<div class="contained feature-banner">
    <a class="flex-item a-fifth" routerLink="/about-diy-kitchens/price-and-quality-comparison" title="Price & Quality Comparison">
        <div>
            <img src="https://static.diy-kitchens.com/assets/images/homepage/icon_homepage_worlds-number-1.svg" alt="Number 1 icon" loading="lazy"/>
            <p>World's #1 online kitchen retailer</p>
        </div>
    </a>
    <a class="flex-item a-fifth" routerLink="/about-diy-kitchens/history" title="Our History">
        <div>
            <img src="https://static.diy-kitchens.com/assets/images/menu/icon_made-in-britain.svg" alt="British" loading="lazy"/>
            <p>Made in Britain for 40 years</p>
        </div>
    </a>
    <!-- <a class="flex-item a-fifth" routerLink="/about-diy-kitchens/which-best-buy">
        <div>
            <img src="https://static.diy-kitchens.com/assets/images/homepage/icon_logobanner_which2023.svg" alt="Which Best Buy in lab tests for Innova units" loading="lazy"/>
            <p>Best Buy in lab tests for Innova units</p>
        </div>
    </a> -->
    <a class="flex-item a-fifth" href="https://uk.trustpilot.com/review/www.diy-kitchens.com" target="_blank" title="Our Trustpilot rating">
        <div>
            <img src="https://static.diy-kitchens.com/assets/images/homepage/icon_homepage_trustpilot.svg" alt="Trustpilot logo" loading="lazy"/>
            <p>Rated Excellent on Trustpilot</p>
        </div>
    </a>
    <a class="flex-item a-fifth" routerLink="/support" title="Customer Support">
        <div>
            <img src="https://static.diy-kitchens.com/assets/images/homepage/icon_homepage_7-day-support.svg" alt="Seven" loading="lazy"/>
            <p>Free expert help 7 days a week</p>
        </div>
    </a>
</div>

<section class="contained welcome">
    <h1>Revolutionising beautiful kitchens online</h1>
    <p>
        Welcome to DIY Kitchens - revolutionising the way to order your <a routerLink="/kitchens"
            title="Order your kitchen online">new kitchen online</a>. We are committed
        to supplying <a routerLink="/about-diy-kitchens/showroom" title="Kitchen Showroom">showroom quality
            kitchens</a>, direct to you, at a price far less than high street kitchen retailers.
    </p>
    <p>
        You can achieve huge savings by designing the kitchen yourself, with support
        every step of the way with our online planner and expert customer support team.
    </p>
</section>

<section class="contained tri-usp">
    <div class="tri-block">
        <div class="tri-image">
            <mat-icon>play_circle_filled</mat-icon>
            <img src="https://static.diy-kitchens.com/assets/images/home/uk.jpg" width="100%" alt="Made in Britain" />
        </div>
        <div class="tri-content">
            <h2 class="playfair">Made in Britain</h2>
            <p>Quality and value are at the heart of everything our family company has created for over 40 years.</p>
        </div>
        <div class="tri-button">
            <a routerLink="/about-diy-kitchens" title="Made in Britain">
                <button mat-flat-button color="primary">See how they're made</button>
            </a>
        </div>
    </div>
    <div class="tri-block">
        <div class="tri-image">
            <mat-icon>play_circle_filled</mat-icon>
            <img src="https://static.diy-kitchens.com/assets/images/home/customers.jpg" width="100%"
                alt="The very best kitchens" />
        </div>
        <div class="tri-content">
            <h2 class="playfair">Loved by customers</h2>
            <p>We are proud to be independently rated 'EXCELLENT' on Trustpilot by thousands of happy customers.</p>
        </div>
        <div class="tri-button">
            <a routerLink="/customer-reviews" title="Customer Reviews">
                <button mat-flat-button color="primary">Watch customer reviews</button>
            </a>
        </div>
    </div>
    <div class="tri-block">
        <div class="tri-image">
            <img src="https://static.diy-kitchens.com/assets/images/home/kitchen.jpg" width="100%"
                alt="Real kitchen reviews" />
        </div>
        <div class="tri-content">
            <h2 class="playfair">The best for less</h2>
            <p>Buying your kitchen online, direct from DIY Kitchens, can save you £000's by cutting out expensive
                showroom costs.</p>
        </div>
        <div class="tri-button">
            <a routerLink="/about-diy-kitchens/price-and-quality-comparison" title="Price & Quality Comparison">
                <button mat-flat-button color="primary">Competitor comparison</button>
            </a>
        </div>
    </div>
    <div class="tri-block">
        <div class="tri-image">
            <img src="https://static.diy-kitchens.com/assets/images/showroom/new_showroom.jpg" width="100%"
                alt="360° ONLINE SHOWROOM TOUR" />
        </div>
        <div class="tri-content">
            <h2 class="playfair">360° Online Showroom Tour</h2>
            <p>Featuring {{diy.ranges}} stunning kitchen displays.</p>
        </div>
        <div class="tri-button">
            <a routerLink="/about-diy-kitchens/showroom" title="Visit Our Showroom">
                <button mat-flat-button color="primary">Take the tour</button>
            </a>
        </div>
    </div>
    <div class="tri-block">
        <div class="tri-image">
            <img src="https://static.diy-kitchens.com/assets/images/general/sample-door-pack.png" width="100%"
                height="100%" alt="GET FREE SAMPLES" loading="lazy" />
        </div>
        <div class="tri-content">
            <h2 class="playfair">Unsure?</h2>
            <p>View our range of kitchens in the comfort of your own home with our FREE sample door service.</p>
        </div>
        <div class="tri-button">
            <a routerLink="/samples/door-samples" title="Order Free Samples">
                <button mat-flat-button color="primary">ORDER FREE SAMPLES</button>
            </a>
        </div>
    </div>
    <div class="tri-block">
        <div class="tri-image">
            <img src="https://static.diy-kitchens.com/assets/images/home/kitchen.jpg" width="100%"
                alt="Online Kitchen Planner" />
        </div>
        <div class="tri-content">
            <h2 class="playfair">Free Online Planner</h2>
            <p>This revolutionary way to buy your new kitchen enables you to take control of your project, letting you
                explore all of the possibilities.</p>
        </div>
        <div class="tri-button">
            <a href="https://planner.diy-kitchens.com" target="_blank" title="Planner">
                <button mat-flat-button color="primary">Online Kitchen Planner</button>
            </a>
        </div>
    </div>
</section>

<section class="showroom">
    <div class="contained text-center">
        <h2 class="playfair">Visit our stunning showrooms in<br />West Yorkshire & Oxford</h2>
        <video muted autoplay loop playsinline #videoShowroom width="80%" aria-label="This video has no spoken audio">
            <source src="https://static.diy-kitchens.com/advice/video/diy-kitchens-showroom-fly-through.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        <a routerLink="/about-diy-kitchens/showroom">
            <button mat-flat-button color="primary">Showroom Details</button>
        </a>
    </div>
</section>

<section class="style">
    <div class="contained text-center">
        <h3 class="style-h4">{{totalStyles}} kitchen styles online</h3>
        <h4 class="style-h5">From only {{minPrice | currencyFormat }}</h4>
    </div>

    <div class="contained">
        <mat-tab-group mat-align-tabs="center">
            <mat-tab label="Styles">
                <div class="styles-grid">
                    <div *ngFor="let style of styles" class="a-third style-type" [style.backgroundColor]="style.colour">
                        <img [src]="style.image" alt="Handleless kitchens" height="auto" width="100%" loading="lazy">
                        <h4 class="style-h4">{{style.name}}</h4>
                        <p>FROM {{style.price | currencyFormat}}</p>
                        <a [routerLink]="'/kitchens/' + style.link">SHOP STYLES</a>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Finishes">
                <div class="flex-center wrap wrap-under-800 tab-container">
                    <div *ngFor="let finish of finishes" class="flex-item kitchen-finish"
                        [style.backgroundColor]="finish.colour"
                        [routerLink]="'kitchens/finish/' + finish.name.replace(' ', '-').toLowerCase()">
                        <div class="finishes-content">
                            <p class="kitchen-finish-title">{{ finish.name }}</p>
                            <p>{{ finish.description }}</p>
                            <p class="kitchen-price">
                                See {{ finish.count }} ranges from {{ finish.price | currencyFormat }} <mat-icon
                                    class="brand-primary">play_arrow</mat-icon>
                            </p>
                        </div>
                        <img [cdn]="finish.image" alt="{{finish.name}}" loading="lazy" />
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Colours">
                <div class="flex-center wrap tab-container">
                    <div *ngFor="let colour of colours" class="flex-item colour-swatch"
                        [ngClass]="'swatch-'+colour.css_class" [routerLink]="'kitchens/allstyles/' + colour.ref">
                        <p class="colour-title">{{ colour.name }}</p>
                        <p>{{colour.count}} Ranges <mat-icon class="brand-primary">play_arrow</mat-icon>
                        </p>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</section>

<section class="contained">
    <div class="about three-decades-section">
        <div class="img">
            <img src="https://static.diy-kitchens.com/company-history/three_decades_leading_the_way.jpg"
                alt="Robotic Kitchen manufacture" loading="lazy">
        </div>
        <div class="text">
            <h4 class="style-h4">Leading the way for over four decades</h4>
            <p>Established in 1982 as a family business and with 20 years of online experience,
                we're proud to be the UK's leading online provider of affordable kitchens.
                With 16 state-of-the-art facilities, spread across Yorkshire, we have a manufacturing
                capacity of over 650,000 sq ft, allowing us to keep up with the demands of our growing and valued
                customers.
            </p>
            <p>We continuously invest in our facilities to ensure we can meet the needs of our customers and our
                extensive range of quality products includes Innova Kitchen units,
                <a routerLink="/kitchen-doors" title="Kitchen Doors">doors</a>,
                <a routerLink="/appliances" title="Appliances">appliances</a>,
                <a routerLink="/sinks-and-taps" title="Sinks & Taps">sinks, taps</a>,
                <a routerLink="/worktops" title="Kitchen Wortops">worktops</a>,
                <a routerLink="/handles" title="Kitchen Handles">handles</a> and
                <a routerLink="/accessories" title="Kitchen Accessories">accessories</a>.
            </p>
            <p>
                <a routerLink="/about-diy-kitchens">
                    <button>FIND OUT MORE</button>
                </a>
            </p>
        </div>
    </div>
</section>

<section class="contained">
    <component-latest-offers></component-latest-offers>
</section>
