import { Component, ViewEncapsulation, Input, Signal } from '@angular/core';
import { BasketItemHanding } from '@app/services/basket/models';
import { CatalogueService } from '@app/services/catalogue';
import { DialogService } from '@app/services/dialog';
import { ActiveRange, ListingProduct, ProductType } from '@app/services/catalogue/models';
import { DimensionsInputCalculatorDialogComponent } from '@app/shared/dialogs/dimensions-input-calculator';
import { URLHelper } from '@app/utilities/helpers';
import { SignalHelper } from '@app/utilities/helpers';

@Component({
    selector: 'component-product-listing',
    templateUrl: './product-listing.component.html',
    styleUrls: ['./product-listing.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ProductListingComponent {
    @Input() activeRange: ActiveRange;
    @Input() type: ProductType;
    @Input() route: string;
    @Input() products: any = [];
    @Input() product: ListingProduct;
    @Input() showQuantity: boolean = false;

    get isUnit(): boolean {
        return this.type === ProductType.CABINETS;
    }

    get routeLink(): string {
        return this.urlHelper.route(this.product.item).fullRoute;
    }

    public qty: number = 1;

    public warranties: any = {
        CDA: {
            // '5-year': {
            //     url: 'https://www.cda.co.uk/warranty/',
            //     image: 'https://static.diy-kitchens.com/assets/images/products/appliances/warranties/cda/cda-5-year-warranty.png'
            // }
            '5-year': {
                url: 'https://www.cda.co.uk/ovens-promotion/',
                image: 'https://static.diy-kitchens.com/assets/images/products/appliances/warranties/cda/cda-5-year-promo-warranty.png'
            }
        },
        Zanussi: {
            '1-year': {
                url: 'https://www.zanussi.co.uk/support/warranties/',
                image: 'https://static.diy-kitchens.com/assets/images/products/appliances/warranties/zanussi/zanussi-1-year-warranty.png'
            }
        },
        AEG: {
            '5-year': {
                url: 'https://www.aeg.co.uk/support/warranties/',
                image: 'https://static.diy-kitchens.com/assets/images/products/appliances/warranties/aeg/aeg-5-year-warranty.png'
            }
        }
    }

    public offers: any = {
        CDA: [
            // {
            //     route: '/kitchen-sales',
            //     fragment: 'cdaoffer1',
            //     colour: '#E84339',
            //     title: 'Up to £150 cashback'
            // },
            {
                route: '/kitchen-sales',
                fragment: 'cdaoffer5',
                colour: '#3F6E6B',
                title: 'Extended Warranty'
            },
            // {
            //     route: '/kitchen-sales',
            //     fragment: 'cdaoffer2',
            //     colour: '#222222',
            //     title: '10% Off 3 or more'
            // }
        ],
        AEG: [
            {
                route: '/kitchen-sales',
                fragment: 'aegoffer1',
                colour: '#21201F',
                title: 'Up to £1000 cashback'
            },
            {
                route: '/kitchen-sales',
                fragment: 'aegoffer2',
                colour: '#7444E3',
                title: 'Free dishwasher'
            }
        ]
    }

    public Handing = BasketItemHanding;

    public isCarcaseMaterial = this.catalogueService.isCarcaseMaterial;

    private activeRangeSignal: Signal<ActiveRange>;

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private urlHelper: URLHelper
    ) {
        this.activeRangeSignal = SignalHelper.toSignal<ActiveRange>(this.catalogueService.activeRange$, { initialValue: null });
    }

    public selectWidth(entry, width) {
        let newItem = this.products.find((product) => product._code === width.variants[0].code);
        if (newItem) {
            entry.item = newItem;
        }
    }

    public setHinge(product, side) {
        product.options.hingeSide = side;
    }

    public toggleSoft(product) {
        product.options.softClose = !product.options.softClose;
    }

    public variants(product) {
        if (product.widths && product.widths.length) {
            let selectedWidth = product.widths.find((itemWidth) => itemWidth.width === product.item.width);

            return (selectedWidth) ? selectedWidth.variants : [];
        } else {
            return [];
        }
    }

    public selectVariant(entry, variant) {
        let newItem = this.products.find((product) => product._code === variant.code);
        if (newItem) {
            entry.item = newItem;
        }
    }

    public selectRange() {
        this.catalogueService.openRangeSelector();
    }

    public openDimensionsInputDialog(): void {
        this.catalogueService.getCabinetColours()
            .then((carcaseColours: any) => {
                this.dialogService.custom(DimensionsInputCalculatorDialogComponent, {
                    maxWidth: '500px',
                    data: {
                        item: this.product.item,
                        carcaseColours: carcaseColours,
                        activeRange: this.activeRangeSignal
                    }
                })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
