import { NgModule } from '@angular/core';

import { CustomerService } from './customer.service';

@NgModule({
    providers: [
        CustomerService
    ]
})
export class CustomerServiceModule { }
