import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';
import { PipesModule } from '@app/pipes';

import { MonekLegacyPaymentComponent } from './monek-legacy-payment.component';

@NgModule({
    imports: [
        DesignModule,
        PipesModule
    ],
    exports: [
        MonekLegacyPaymentComponent
    ],
    declarations: [
        MonekLegacyPaymentComponent
    ]
})
export class MonekLegacyPaymentComponentModule { }
