import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ISolidSurfaceConfigItem, ISolidSurfaceConfigPiece } from '@app/shared/components/solid-surface-config/models';

@Component({
    selector: 'component-solid-surface-config-splashback-cill-dialog',
    templateUrl: './splashback-cill.dialog.component.html',
    styleUrls: ['./splashback-cill.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SolidSurfaceConfigSplashbackCillDialogComponent {
    public splashback: ISolidSurfaceConfigItem = {
        length: null,
        height: null
    };    
    public cill: ISolidSurfaceConfigItem = {
        length: null,
        height: null
    };

    public minLength: number = 100;
    public maxLength: number = 10000;
    public minHeight: number = 100;
    public maxHeight: number = 3000;

    private piece: ISolidSurfaceConfigPiece

    constructor(
        private dialogRef: MatDialogRef<SolidSurfaceConfigSplashbackCillDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (this.data && this.data.piece) {
            this.piece = this.data.piece;

            if (this.data.piece.splashback) {
                if (this.data.piece.splashback.length) {
                    this.splashback.length = this.data.piece.splashback.length;
                }

                if (this.data.piece.splashback.height) {
                    this.splashback.height = this.data.piece.splashback.height;
                }
            }

            if (this.data.piece.cill) {
                if (this.data.piece.cill.length) {
                    this.cill.length = this.data.piece.cill.length;
                }

                if (this.data.piece.cill.height) {
                    this.cill.height = this.data.piece.cill.height;
                }
            }
        }
    }

    public valid(type: 'cill' | 'splashback') {
        if (this[type].length) {
            this[type].length = parseInt(this[type].length + '', 10) || null;
        }

        if (this[type].height) {
            this[type].height = parseInt(this[type].height + '', 10) || null;
        }

        if (!this[type].length) {
            this[type].length = null;
        }

        if (!this[type].height) {
            this[type].height = null;
        }

        if (!this[type].length && !this[type].height) {
            return true;
        } else if (
            this[type].length >= this.minLength && this[type].length <= this.maxLength &&
            this[type].height >= this.minHeight && this[type].height <= this.maxHeight
        ) {
            return true;
        }

        return false;
    }

    public add() {
        if (this.splashback.length && this.splashback.height) {
            this.piece.splashback.length = this.splashback.length;
            this.piece.splashback.height = this.splashback.height;
        } else {
            this.piece.splashback.length = null;
            this.piece.splashback.height = null;
        }

        if (this.cill.length && this.cill.height) {
            this.piece.cill.length = this.cill.length;
            this.piece.cill.height = this.cill.height;
        } else {
            this.piece.cill.length = null;
            this.piece.cill.height = null;
        }

        this.dialogRef.close(true);
    }
}
