import { NgModule } from '@angular/core';

import { DesignModule } from '@app/design';
import { PipesModule } from '@app/shared/pipes';

import { ChangeCabinetOptionsDialogModule } from './change-cabinet-options/change-cabinet-options.dialog.module';
import { LoginDialogModule } from '@app/services/auth/dialogs/login/login.dialog.module';
import { KitchenLayoutsDialogModule } from './kitchen-layouts/kitchen-layouts.dialog.module';
import { MediaPlayerDialogModule } from './media-player/media-player.dialog.module';
import { NotesDialogModule } from './notes/notes.dialog.module';
import { OrderTermsDialogModule } from './order-terms/order-terms.dialog.module';
import { RangeSelectorDialogModule } from './range-selector/range-selector.dialog.module';
import { YouTubeVideoDialogModule } from './youtube-video/youtube-video.dialog.module';
import { ChangeStyleDialogModule } from './change-style/change-style.dialog.module';
import { TransferBasketDialogModule } from './transfer-basket/transfer-basket.dialog.module';
import { DimensionsInputCalculatorDialogModule } from './dimensions-input-calculator/dimensions-input-calculator.dialog.module';

@NgModule({
    imports: [
        DesignModule,
        PipesModule,

        ChangeCabinetOptionsDialogModule,
        LoginDialogModule,
        KitchenLayoutsDialogModule,
        MediaPlayerDialogModule,
        NotesDialogModule,
        OrderTermsDialogModule,
        RangeSelectorDialogModule,
        YouTubeVideoDialogModule,
        ChangeStyleDialogModule,
        TransferBasketDialogModule,
        DimensionsInputCalculatorDialogModule
    ]
})
export class DialogsModule { }
