import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CatalogueService } from '@app/services/catalogue';
import { Layouts } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';

@Component({
    selector: 'component-kitchen-layouts-dialog',
    templateUrl: './kitchen-layouts.dialog.component.html',
    styleUrls: ['./kitchen-layouts.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class KitchenLayoutsDialogComponent {
    public style: string = '';
    public layoutId: Layouts = Layouts.L_SHAPED;
    public layoutName: string = this.catalogueService.getLayoutName();
    public layoutData: any;
    public layoutImage: string = this.catalogueService.getLayoutImage();;
    public items: any;

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private dialogRef: MatDialogRef<KitchenLayoutsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data && data.style) {
            this.style = data.style;
        }

        if (data && data.layoutId) {
            this.layoutId = data.layoutId;
            this.layoutName = this.catalogueService.getLayoutName(data.layoutId);
        }

        if (data && data.layoutData) {
            this.layoutData = data.layoutData;
        }

        this.processData();
    }

    public close() {
        this.dialogRef.close(null);
    }

    private processData() {
        if (this.layoutId) {
            this.layoutImage = this.catalogueService.getLayoutImage(this.layoutId);
        }

        if (this.layoutData && this.layoutData.items && this.layoutData.items.length) {
            this.catalogueService.getCatalogue()
                .then((catalogue) => {
                    let items = [];
                    let doors = 0;

                    this.layoutData.items.forEach((item) => {
                        const product = (catalogue && catalogue.products && catalogue.products[item.unit_code]) ?
                            catalogue.products[item.unit_code] : null;

                        doors += (product && product.doors) ? product.doors : 0;

                        items.push({
                            code: item.unit_code,
                            description: (product) ? product.detail : null
                        });
                    });

                    if (doors > 0) {
                        items.push({
                            description: `${doors} ${this.style} Doors (For above units)`
                        });
                    }

                    if (this.layoutData.plinth && this.layoutData.plinth.lengths) {
                        items.push({
                            description: `${this.layoutData.plinth.lengths} x ${this.style} Plinth`
                        });
                    }

                    if (this.layoutData.cornice && this.layoutData.cornice.lengths) {
                        items.push({
                            description: `${this.layoutData.cornice.lengths} x ${this.style} Cornice`
                        });
                    }

                    if (this.layoutData.endPanel && this.layoutData.endPanel.lengths) {
                        items.push({
                            description: `${this.layoutData.endPanel.lengths} x ${this.style} Base End Panels`
                        });
                    }

                    if (this.layoutData.cornerPost && this.layoutData.cornerPost.lengths) {
                        items.push({
                            description: `${this.layoutData.cornerPost.lengths} x ${this.style} Corner Posts`
                        });
                    }

                    this.items = items;
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        }
    }
}
