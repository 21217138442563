@import '/src/theme/variables';

component-change-cabinet-options-dialog {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h5 {
            margin: 0;
        }

        .close {
            margin-top: 10px;
            margin-right: 10px;
            cursor: pointer;
        }
    }

    table {
        margin-bottom: 25px;
        width: 100%;

        tr {
            td {
                &:first-child {
                    padding-right: 15px;
                }
            }
        }
    }
}