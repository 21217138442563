import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';

import { OrderTermsDialogComponent } from './order-terms.dialog.component';

@NgModule({
    imports: [
        DesignModule
    ],
    declarations: [
        OrderTermsDialogComponent
    ]
})
export class OrderTermsDialogModule { }
