import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';

import { SupportRouting } from './support.routing';
import { SupportPage } from './support.page';

import { BasketRecoveryPage } from './content/basket-recovery/basket-recovery.page';
import { ContactPage } from './content/contact/contact.page';
import { MessagingPage } from './content/messaging/messaging.page';

@NgModule({
    imports: [
        SharedModule,
        SupportRouting
    ],
    declarations: [
        SupportPage,
        BasketRecoveryPage,
        ContactPage,
        MessagingPage
    ]
})
export class SupportPageModule { }
