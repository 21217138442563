<div class="email-lookup">
    <mat-form-field appearance="outline">
        <mat-label>Email Address</mat-label>
        <input matInput type="text" #emailSearch placeholder="Email Address" [(ngModel)]="email" [disabled]="searching" [matAutocomplete]="emailAutocomplete" />
        <mat-autocomplete autoActiveFirstOption #emailAutocomplete="matAutocomplete" (optionSelected)="selectCustomer($event)">
            <mat-option [value]="customer.email" *ngFor="let customer of customers">
                <span class="multi-line">{{customer.firstName}} {{customer.lastName}}</span>
                <small class="multi-line">{{customer.email}}</small>
            </mat-option>
        </mat-autocomplete>
        <mat-icon matSuffix color="warn" *ngIf="email && email !== searchText" (click)="searchCustomer()">search</mat-icon>
        <mat-icon matSuffix color="warn" *ngIf="email && email === searchText" (click)="clearSearch()">close</mat-icon>
    </mat-form-field>
</div>

<p class="error-message" *ngIf="errorMessage">{{errorMessage}}</p>

<div class="create-customer" *ngIf="createNewCustomer">
    <component-create-customer (customerCreatedEvent)="customerCreated($event)"></component-create-customer>
</div>
