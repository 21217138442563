<header class="content-contained">
    <h1>Price Match Promise</h1>
    <div class="panel">
        <div>
            <p>DIY Kitchens are dedicated to offering you the best possible quality and value for money when purchasing your new kitchen and our prices are checked against our competition on a weekly basis.</p>
            <p>We understand the benefit to you of ordering as many items for your kitchen from the same supplier, therefore, if you find any like for like products cheaper elsewhere, we will endeavour to match this.</p>
            <p>Please note that price matching of sinks, taps, worktops, appliances and accessories only applies when ordered with a complete kitchen.</p>
            <p>Terms and conditions apply - please see below.</p>
        </div>
        <img src="https://static.diy-kitchens.com/assets/images/general/price-match-promise-logo.svg" alt="Price Promise" />
    </div>


</header>

<hr class="narrow" />

<component-sticky-tabs [tabs]="tabs" type="scroller"></component-sticky-tabs>

<section class="content-contained" [id]="StickyTabs.PROMISE">
    <h3 class="playfair">Our promise</h3>
    <p>If you find any like-for-like products cheaper elsewhere we will consider price matching. Please see further information below for specific terms relating to different products.</p>
    <p><strong>How to order products that need to be price matched?</strong> If you do find any like-for-like products cheaper elsewhere, please contact the customer service team by creating a support ticket. Please include the details of the items you would like us to consider for price matching, along with links to the relevant supplier. We will then confirm whether we are able price match by reply, before you place your order with us.</p>
    <p><strong>PLEASE NOTE THAT PRICE MATCHING ON ALL PRODUCTS IS AT OUR DISCRETION AND IS NOT GUARANTEED.</strong></p>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.DOORS">
    <div class="panel">
        <div class="panel-left">
            <img src="https://static.diy-kitchens.com/assets/images/menu/category_units_base-highline.jpg" width="100%" alt="Kitchen unit"/>
        </div>
        <div class="panel-right">
            <h3 class="playfair">Kitchen Units</h3>
            <h4>Price promise</h4>
            <p>When units are added to the shopping cart the price of the unit and the door will clearly be stated. We do this for transparency of pricing. You can see what the two main components (Door + Unit) cost. If you find anywhere quoting a cheaper price for a like for like complete kitchen order please let us know and we will endeavour to match this. Necessary specification for price matching.</p>
            <ul>
                <li>Fully rigid.</li>
                <li>Glue and doweled construction.</li>
                <li>Colour coded cabinet (inside and out).</li>
                <li>Blum soft close hinges and drawers.</li>
                <li>Same door supplier (Second Nature/Multiwood etc...).</li>
                <li>Same <a href="/kitchen-units/base" routerLink="/kitchen-units/base" title="Kitchen Base Units">range of units</a> on both orders / quotes.</li>
                <li>Kessebohmer wirework systems if applicable (If you have Units fitted with pull out storage systems).</li>
            </ul>
            <p><strong>PLEASE NOTE THAT PRICE MATCHING IS AT OUR DISCRETION</strong></p>
        </div>
    </div>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.SINKS">
    <div class="panel">
        <div class="panel-left">
            <img src="https://static.diy-kitchens.com/assets/images/menu/category_sinks-and-taps_undermount.jpg" width="100%" alt="Undermount sink"/>
        </div>
        <div class="panel-right">
            <h3 class="playfair">Sinks &amp; Taps</h3>
            <h4>Quality</h4>
            <p>We source our sinks and taps from leading sink and tap suppliers worldwide.</p>
            <h4>Price Promise</h4>
            <p>Our prices are regularly checked. If you do find an individual sink or tap cheaper elsewhere we will endeavour to match this.</p>
            <p><strong>PLEASE NOTE THAT PRICE MATCHING IS AT OUR DISCRETION</strong></p>
        </div>
    </div>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.APPLIANCES">
    <div class="panel">
        <div class="panel-left">
            <img src="https://static.diy-kitchens.com/assets/images/menu/category_appliances_cooking_compact-ovens.jpg" width="100%" alt="Integrated oven"/>
        </div>
        <div class="panel-right">
            <h3 class="playfair">Appliances</h3>
            <p>We currently supply appliances from leading brands such as CDA and AEG. Please note that only products from these suppliers and those that are available to order via our website will be considered for price matching.</p>
            <p><strong>PLEASE NOTE THAT PRICE MATCHING IS AT OUR DISCRETION</strong></p>
        </div>
    </div>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.WORKTOPS">
    <div class="panel">
        <div class="panel-left">
            <img src="https://static.diy-kitchens.com/assets/images/menu/category_worktops_quartz.jpg" width="100%" alt="Quartz worktop"/>
        </div>
        <div class="panel-right">
            <h3 class="playfair">Worktops</h3>
            <p>If you find the same Duropal worktop cheaper elsewhere we will endeavour to match this.</p>
            <h4>Price Promise</h4>
            <p><strong>PLEASE NOTE THAT PRICE MATCHING IS AT OUR DISCRETION</strong></p>
        </div>
    </div>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.STORAGE">
    <div class="panel">
        <div class="panel-left">
            <img src="https://static.diy-kitchens.com/categories/units/tall_pull_out_unit.jpg" width="100%" alt="Tall pull out unit"/>
        </div>
        <div class="panel-right">
            <h3 class="playfair">Storage Solutions</h3>
            <h4>Price Promise</h4>
            <p>All our storage solutions are sourced from Kessebohmer, Germany and are manufactured to a very high specification. Whilst cheaper wirework products exist, we will only consider price matching for kitchen units that also include Kessebohmer products.</p>
            <p><strong>PLEASE NOTE THAT PRICE MATCHING IS AT OUR DISCRETION</strong></p>
        </div>
    </div>
</section>

<hr class="narrow" />

<!-- <section class="content-contained" [id]="StickyTabs.ACCESSORIES">
    <div class="panel">
        <div class="panel-left">
            <img src="https://static.diy-kitchens.com/assets/images/products/misc/cimageurl/BTPCI400GY.jpg" width="100%" alt="Cutlery tray insert"/>
        </div>
        <div class="panel-right">
            <h3 class="playfair">Kitchen Accessories</h3>
            <p>We supply a range of accessories from several brands, as well as a range of own brand accessories. We will endeavour to price match any product you have found that is cheaper elsewhere, is of comparable specification and is currently available to order via our website.</p>
            <p><strong>Please note</strong> Please note that price matching of accessories is at our discretion.</p>
        </div>
    </div>
</section> -->

<component-latest-offers></component-latest-offers>
