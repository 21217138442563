<header class="content-contained">
    <h1>Unit specification</h1>
    <p>We have over 900 kitchen units available that are made with the finest components and supplied to you fully built. Why not take some time to familiarise yourself with our kitchen units below and see exactly how they are built, which recommend colour matched units to use with your chosen door style and which materials we use, to create the perfect kitchen!</p>
</header>

<hr class="narrow" />

<component-sticky-tabs [tabs]="tabs" type="scroller"></component-sticky-tabs>

<section class="content-contained" [id]="StickyTabs.UNITS">
    <div class="centered-block">
        <h2 class="playfair">Units</h2>
        <p>With an industry-leading choice of over 900 made-to-order units, your next kitchen is only limited by your imagination. Every unit comes supplied fully-rigid, and can be fitted with soft-close hinges and ultra smooth runners.</p>
    </div>

    <div class="centered-block">
        <div class="selector" *ngIf="units && units.menu">
            <div class="selector-title" (click)="unitMenuOpen = !unitMenuOpen">
                <span>Select Unit Type</span>
            </div>
            <div class="selector-options" *ngIf="unitMenuOpen">
                <div class="selector-option" [ngClass]="{ selected: unitMenu === unitSelected }" *ngFor="let unitMenu of units.menu" (click)="selectUnitType(unitMenu)">
                    <span>{{unitMenu}}</span>
                </div>
            </div>
        </div>

        <div class="unit-display" *ngIf="units && units.items && units.items[unitSelected]">
            <h3>{{units.items[unitSelected].title}}</h3>
            <div class="unit-overview">
                <img [src]="units.items[unitSelected].image" width="100%" [alt]="units.items[unitSelected].title"/>

                <div class="unit-information" *ngFor="let popup of units.items[unitSelected].popups" [ngStyle]="{ top: popup.top, left: popup.left }">
                    <mat-icon>control_point</mat-icon>
                    <div class="popup">
                        <p>{{popup.information}}</p>
                        <div class="arrow-down"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.DRAWER">
    <div class="centered-block">
        <h2 class="playfair">Drawer options</h2>
        <p>Your new kitchen should look just as good on the inside as it does on the outside. Below is a small selection of the functional and decorative accessories available to compliment your DIY Kitchen.</p>
    </div>

    <div class="image-gallery">
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/carcase/pandrawer_standard.jpg" width="100%" alt="Standard Pan-drawer"/>
        </div>
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/carcase/pandrawer_high_sided.jpg" width="100%" alt="High-sided Pan-drawer"/>
        </div>
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/carcase/pandrawer_glass.jpg" width="100%" alt="Glass Pan-drawer"/>
        </div>
    </div>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.WIREWORK">
    <div class="centered-block">
        <h2 class="playfair">Wirework storage solutions</h2>
        <p>Your new kitchen should look just as good on the inside as it does on the outside. Not only that, it should also provide useful storage space for everyday essentials - from utensils, cookware and china, to food 	and household items. Everything in its place and within easy reach - just as it should be.</p>
        <p>At DIY Kitchens we use Clever Storage by Kessebohmer. They are experts at maximising potential storage space in your kitchen. Their range of products ensures that the internal cabinet space of your kitchen furniture is utilised more effectively than in some storage systems. Kessebohmer products are manufactured to exacting standards and have won many European awards for design and innovation. In specifying Kessebohmer products you can be confident your kitchen storage solutions will provide a lifetime of trouble-free service.</p>
    </div>

    <div class="image-gallery">
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/specification/wirework/kessebohmer-classic-small.jpg" width="100%" alt="Kessebohmer classic wirework"/>
        </div>
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/specification/wirework/kessebohmer-arena-classic-small.jpg" width="100%" alt="Kessebohmer arena style wirework"/>
        </div>
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/specification/wirework/kessebohmer-arena-style-small.jpg" width="100%" alt="Kessebohmer arena style wirework"/>
        </div>
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/specification/wirework/kessebohmer-arena-style-larder-pullout-small.jpg" width="100%" alt="Kessebohmer arena style larder pullout"/>
        </div>
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/specification/wirework/kessebohmer-tandem-arena-style-chefs-pantry-small.jpg" width="100%" alt="Kessebohmer chefs' style pantry"/>
        </div>
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/specification/wirework/kessebohmer-tandem-arena-style-larder-small.jpg" width="100%" alt="Kessebohmer tandem arena style larder"/>
        </div>
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/specification/wirework/kessebohmer-arena-style-base-pullout-small.jpg" width="100%" alt="Kessebohmer arena style base pullout"/>
        </div>
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/specification/wirework/kessebohmer-classic-inside-pullout-small.jpg" width="100%" alt="Kessebohmer classic inside pullout"/>
        </div>
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/specification/wirework/kessebohmer-arena-style-3quarters-carousel-small.jpg" width="100%" alt="Kessebohmer arena style 3-quarters carousel"/>
        </div>
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/specification/wirework/kessebohmer-arena-style-revo90-carousel-small.jpg" width="100%" alt="Kessebohmer arena 90-degree carousel"/>
        </div>
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/specification/wirework/kessebohmer-arena-style-magic-corner-small.jpg" width="100%" alt="Kessebohmer arena style magic corder"/>
        </div>
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/specification/wirework/kessebohmer-arena-style-le-mans-small.jpg" width="100%" alt="Kessebohmer arena style le mans"/>
        </div>
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/specification/wirework/kessebohmer-arena-style-le-mans-mid-small.jpg" width="100%" alt="Kessebohmer arena style mid le mans"/>
        </div>
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/specification/wirework/kessebohmer-tandem-swivel-pullout-small.jpg" width="100%" alt="Kessebohmer tandem swivel pullout"/>
        </div>
        <div class="image-block">
            <img src="https://static.diy-kitchens.com/assets/images/specification/wirework/kessebohmer-arena-style-towel-pullout-small.jpg" width="100%" alt="Kessebohmer arena style towel pullout"/>
        </div>
    </div>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.COLOUR">
    <div class="centered-block">
        <h2 class="playfair">Colour Match</h2>
        <p>The cabinet colour match is gives a much better effect when opening the cabinet doors on the interior but can also save on the cost of end panels where a exposed side of the cabinet is visible.</p>
        <p>Hover over each door below to find out the best choices you should make with your chosen door style on how good our colour matches are. This will hopefully assist in the decision making process on whether you require end panels, and if we feel you do require panels which section/material panels they need to be ordered in. There is also information that will hopefully help with the choice of plinths/cornice/pelmet.</p>
        <p>Our aim is to give you the customer the neccesary information so that in certain door styles you can make huge savings.</p>
    </div>

    <div class="colour-gallery">
        <div class="image-block" *ngFor="let door of doors">
            <img [src]="door.image" width="100%" [alt]="'Door' + door.style" />

            <div class="popup">
                <div class="arrow-up"></div>
                <p><strong>Style:</strong> {{door.style}}</p>
                <p><strong>Recommended carcase colour:</strong><br />{{door.recommendedCarcaseColour}}</p>
                <p><strong>Recommended end panels:</strong><br /> {{door.recommendedPanels}}</p>
                <p><strong>Recommended plinths:</strong><br /> {{door.recommendedPlinths}}</p>
                <p><strong>Recommended cornice:</strong><br /> {{door.recommendedCornice}}</p>
            </div>
        </div>
    </div>
</section>

<component-latest-offers></component-latest-offers>