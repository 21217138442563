@import '/src/theme/variables';

@include page-padding('page-messaging', 0);

page-messaging {
    display: block;
    height: 800px;

    iframe {
        border: 0;
        width: 100%;
        height: 100%;
    }
}
