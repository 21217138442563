@import '/src/theme/variables';

component-header {
    color: #FFF;
    position: relative;

    .header-banner {
        @media screen and (min-width: 992px) {
            position: relative;
        }

        &:before {
            @media screen and (min-width: 992px) {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 46px;
                height: 1px;
                background: rgba(0, 140, 200, 0.5);
            }
        }
    }

    .header {
        padding: 0;
        position: relative;

        .header-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 1rem 0;
            width: 100%;
            font-size: 0.75rem;

            section {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                // transition: 0.7s ease-in-out;
                &.searching {
                    transform: scale(0, 0.5);
                    opacity: 0;
                }
            }

            .search {
                margin: 0 0 0 20px;

                .mdc-text-field {
                    border: 1px solid color('white');
                    outline: transparent;
                    display: flex;
                    flex-direction: column;
                    align-self: center;
                    justify-content: center;

                    &:hover,
                    &:active {
                        border: 1px solid color('white');
                        box-shadow: 0px 0px 20px 2px rgba(255, 255, 255, 0.5);
                    }

                    .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
                        .mdc-notched-outline__trailing {
                            border-color: color('white');
                            border-width: 1px;
                        }
                    }
                }

                .mat-mdc-input-element {
                    color: color('white');
                }

                .mat-mdc-form-field-infix {
                    min-height: 25px;
                }

                .mat-form-field-appearance-outline {

                    .mat-form-field-outline,
                    .mat-mdc-text-field-wrapper {
                        color: color('white');
                        border-color: color('white');
                    }

                    .mat-form-field-infix {
                        padding-top: 0.5em;
                        width: 120px;
                        transition: 0.7s ease-in-out;
                    }
                }

                input {
                    &.mat-input-element {
                        color: color('white');
                        font-size: 16px;
                        text-transform: uppercase;
                    }

                    &::placeholder {
                        color: color('white');
                    }
                }

                &.searching {
                    .mat-form-field-appearance-outline {
                        .mat-form-field-infix {
                            width: 300px;
                        }
                    }

                    .mat-form-field-outline-start,
                    .mat-form-field-outline-gap,
                    .mat-form-field-outline-end {
                        border-color: #FFF;
                    }
                }
            }

            .mat-icon {
                padding-left: 5px;
            }

            .mat-flat-button {
                font-size: 13px;
                font-weight: 350;
            }
        }
    }

    .header-menu {
        display: flex;
        justify-content: space-between;
        overflow-x: auto;
        clear: both;
        max-width: 100%;
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
        z-index: 100;
        position: relative;

        a {
            display: inline-block;
            padding: 16px 0;
            color: color('white');
            font-size: 14px;
            text-align: center;
            white-space: nowrap;

            &:hover {
                cursor: pointer;
            }

            p {
                margin: 0;
                padding: 4px 0 0;
                font-size: 11px;
                font-weight: 300;
                line-height: initial;
                text-transform: uppercase;
            }
        }
    }

    .sub-menu {
        background-color: color('menu-dark');
        border-bottom: 1px solid #ffffff;

        &.cycle {
            display: none;
            height: 46px;

            .header-menu {
                position: relative;
                overflow: hidden;
                height: 48px;
            }

            a {
                position: absolute;
                left: 0;
                padding: 12px 0;
                width: 100%;
                transition: all 0.5s ease-in-out;

                &:hover {
                    cursor: pointer;
                }

                mat-icon {
                    margin-right: 10px;
                    vertical-align: middle;
                }

                &.left {
                    transform: translateX(-100vw);
                }

                &.right {
                    transform: translateX(100vw);
                }
            }
        }

        .header-menu {
            display: flex;
            text-transform: initial;
            justify-content: space-between;

            a {
                padding-left: 30px;
                padding-right: 30px;
                font-size: 14px;
                font-weight: 400;
                color: color('white');
                display: flex;
                text-align: center;
                white-space: nowrap;
                border-right: 1px solid rgba(25, 41, 57, 1);
                width: 100%;
                display: block;
                cursor: pointer;

                &:last-child {
                    border-right: none;
                }

                &:hover {
                    background: rgba(25, 41, 57, 1);
                }
            }
        }
    }

    .mobile-header {
        display: none;

        .header-tint {
            flex-wrap: nowrap;
            align-items: flex-start;
            padding: 10px;
            height: 45px;
            font-size: 12px;

            .menu-icon {
                margin-right: 10px;
                padding: 5px 6px;
                color: color('white');
                font-size: 10px;
                font-weight: 600;
                line-height: 0.5rem;
                text-align: center;
            }

            .logo {
                margin-bottom: 10px;
                font-size: 32px;
            }

            .menu {
                position: absolute;
                top: 112px;
                left: 0;
                width: 100vw;
                height: calc(100vh - 112px);
                transition: all 0.25s ease-in-out;
                z-index: 999999;
                transform: translateX(-100%);

                .menu-tint {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: calc(100vh - 112px);
                    transition: all 0.25s ease-in-out;
                    transform: translateX(-100%);
                    overflow-y: auto;
                    -webkit-overflow-scrolling: touch;

                    .sub-menu-wrapper {
                        display: flex;

                        p {
                            text-transform: uppercase;
                            margin: 8px;
                            font-size: 20px;
                            font-weight: 500;
                        }
                    }

                    a {
                        display: block;
                        padding: 15px 10px;
                        border-bottom: 1px solid color('brand-lightgrey');
                        color: color('white');
                        font-size: 1.1rem;

                        .mat-icon {
                            float: right;
                        }
                    }

                    // div {
                    //     display: flex;
                    //     align-items: center;
                    //     padding-top: 10px;
                    //     cursor: pointer;
                    //     .mat-icon {
                    //         float: left;
                    //     }
                    // }
                    h5 {
                        margin: 0;
                        padding: 10px;
                        // border-bottom: 3px solid color('brand-primary');
                        border-bottom: 3px solid color('brand-lightgrey');
                        // font-weight: 300;
                        font-weight: 400;
                    }

                    a:first-child {
                        border-top: 1px solid color('brand-lightgrey');
                    }

                    .menu-item-wrapper {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 10px;
                        padding: 5px;
                        padding-bottom: 150px;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;

                        .menu-item {
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                            background-color: rgba(255, 255, 255, 1);
                            color: color('brand-primary');
                            border-radius: 10px;
                            padding: 5px;

                            .menu-item-img {
                                height: 112px;
                                padding: 0;
                                border-radius: 5px;
                                overflow-y: hidden;

                                img {
                                    border-radius: 5px;
                                    width: 100%;
                                }

                                .swatch {
                                    height: 100%;
                                }
                            }

                            p {
                                width: 100%;
                                text-align: center;
                                text-transform: uppercase;
                                margin-bottom: 0;
                                font-weight: 400;
                            }
                        }
                    }
                }

                .footer-menu-wrapper {
                    position: sticky;
                    top: calc(100% - 50px);
                    bottom: 0;
                    left: 0;
                    width: calc(100% - 20px);
                    display: flex;
                    justify-content: space-between;
                    background-color: rgba(255, 255, 255, 0.95);
                    color: rgba(0, 0, 0, 1);
                    height: 50px;
                    padding: 4px 10px;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;

                    .footer-item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-transform: uppercase;
                        cursor: pointer;
                    }
                }

                &.show {
                    transform: translateX(0);

                    .main {
                        transform: translate(0);
                    }

                    &.subbuy {
                        .main {
                            transform: translate(-100%);
                        }

                        .buy-menu {
                            transform: translate(0);
                        }
                    }

                    &.subabout {
                        .main {
                            transform: translate(-100%);
                        }

                        .about-menu {
                            transform: translate(0);
                        }
                    }
                }
            }

            .search {
                margin: 0 20px 0 0;
                height: 38px;
                align-self: center;

                .mdc-text-field {
                    border: 1px solid color('white');
                    outline: transparent;
                    display: flex;
                    flex-direction: column;
                    align-self: center;
                    justify-content: center;

                    &:hover,
                    &:active {
                        border: 1px solid color('white');
                        box-shadow: 0px 0px 20px 2px rgba(255, 255, 255, 0.5);
                    }

                    .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
                        .mdc-notched-outline__trailing {
                            border-color: color('white');
                            border-width: 1px;
                        }
                    }
                }

                .mat-mdc-input-element {
                    color: color('white');
                }

                .mat-mdc-form-field-infix {
                    min-height: 25px;
                }

                .mat-form-field-appearance-outline {

                    .mat-form-field-outline,
                    .mat-mdc-text-field-wrapper {
                        color: color('white');
                        border-color: color('white');
                    }

                    .mat-form-field-infix {
                        padding-top: 0.5em;
                        width: 120px;
                        transition: 0.7s ease-in-out;
                    }
                }

                input {
                    &.mat-input-element {
                        color: color('white');
                        font-size: 16px;
                        text-transform: uppercase;
                    }

                    &::placeholder {
                        color: color('white');
                    }
                }

                &.searching {
                    .mat-form-field-appearance-outline {
                        .mat-form-field-infix {
                            width: 300px;
                        }
                    }

                    .mat-form-field-outline-start,
                    .mat-form-field-outline-gap,
                    .mat-form-field-outline-end {
                        border-color: #FFF;
                    }
                }

                .mat-mdc-text-field-wrapper {
                    border-radius: 0;
                    border-color: rgba(255, 255, 255, 0.8) !important;

                    &.mdc-text-field--outlined {
                        .mat-mdc-form-field-infix {
                            padding-top: 10px;
                            padding-bottom: 10px;
                        }
                    }
                }

                .mat-mdc-form-field-icon-suffix {
                    >.mat-icon {
                        padding: 5px 12px;
                    }
                }
            }

            .search-toggle {
                display: none;
                height: 30px;
                width: 30px;
                margin-right: 5px;
                align-self: center;

                .mdc-button {
                    height: 24px;
                    min-width: 30px;
                    padding-left: 0;
                    padding-right: 0;
                    margin: 0;
                    background-color: unset;

                    .mat-icon {
                        padding: 0;
                        margin: 0;
                        width: 24px;
                        height: 24px;
                        font-size: 24px;
                    }
                }
            }

            .action {
                align-self: center;

                &.basket {
                    font-weight: bold;

                    .basket-view {
                        padding-top: 0;
                        padding-bottom: 0;
                        height: 30px;
                    }

                    .vat-toggle {
                        color: color('white');
                        font-size: 0.75rem;
                        font-weight: normal;

                        .mat-mdc-slide-toggle {
                            font-size: 0.625rem;

                            .mdc-switch {
                                transform: scale(0.8);
                            }

                            .mdc-form-field {
                                color: white;
                            }
                        }

                        @include responsive-breakpoint-down(lg) {
                            white-space: nowrap;
                        }

                        @include responsive-breakpoint-down(md) {
                            margin: 0;
                        }
                    }

                    .mdc-button {
                        height: 24px;
                        min-width: 30px;
                        padding-left: 0;
                        padding-right: 0;
                        margin: 0;
                        flex-direction: row-reverse;
                        background-color: transparent;
                        color: color('white');
                        font-size: 0.875rem;

                        .mat-icon {
                            padding: 0;
                            margin: 0 0 0 6px;
                            width: 24px;
                            height: 24px;
                            font-size: 24px;
                        }
                    }

                    .currency-format {
                        .currency-vat {
                            display: none;
                        }
                    }
                }
            }
        }

        .header-menu {
            max-width: unset;
            gap: 20px;
            padding: 5px 10px;
            overflow-x: scroll;
            border-top: 1px solid rgba(0, 140, 200, 0.5);
        }
    }

    .mobile-search-banner {
        display: flex;
        justify-content: flex-end;
        height: 45px;
        padding: 0 10px;
        margin-top: -5px;
        font-size: 12px;
        background-color: color('brand-primary');

        &.animate-top {
            animation: slide-in-top 0.5s forwards;
            overflow: hidden;
            max-height: 0;
        }

        @keyframes slide-in-top {
            from {
                max-height: 5px;
            }

            to {
                max-height: 45px;
            }
        }

        .search {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            width: 100%;
            height: 38px;

            &.searching {
                .mat-form-field-appearance-outline {
                    .mat-form-field-infix {
                        width: 300px;
                    }
                }

                .mat-form-field-outline-start,
                .mat-form-field-outline-gap,
                .mat-form-field-outline-end {
                    border-color: #FFF;
                }
            }

            .mat-mdc-form-field {
                width: 100%;
                max-width: 320px;
            }

            .mdc-text-field {
                border: 1px solid color('white');
                outline: transparent;
                display: flex;
                flex-direction: column;
                align-self: center;
                justify-content: center;

                &:hover,
                &:active {
                    border: 1px solid color('white');
                    box-shadow: 0px 0px 20px 2px rgba(255, 255, 255, 0.5);
                }

                .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
                    .mdc-notched-outline__trailing {
                        border-color: color('white');
                        border-width: 1px;
                    }
                }
            }

            .mat-mdc-text-field-wrapper {
                border-radius: 0;
                border-color: rgba(255, 255, 255, 0.8) !important;

                &.mdc-text-field--outlined {
                    .mat-mdc-form-field-infix {
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                }
            }

            .mat-form-field-appearance-outline {

                .mat-form-field-outline,
                .mat-mdc-text-field-wrapper {
                    color: color('white');
                    border-color: color('white');
                }

                .mat-form-field-infix {
                    padding-top: 0.5em;
                    width: 120px;
                    transition: 0.7s ease-in-out;
                }
            }

            input {
                &.mat-input-element {
                    color: color('white');
                    font-size: 16px;
                    text-transform: uppercase;

                    &::placeholder {
                        color: color('white');
                    }
                }
            }

            .mat-mdc-input-element {
                color: white;

                &::placeholder {
                    color: white;
                }
            }

            .mat-mdc-form-field-infix {
                min-height: 25px;
            }

            .mat-mdc-form-field-icon-suffix {
                >.mat-icon {
                    padding: 5px 12px;
                }
            }
        }
    }

    .breadcrumbs-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include responsive-breakpoint-between(md, xl) {
            margin: 0;
            gap: 30px;
        }
    }

    .search-input {
        padding: 5px 15px;
        border: 1px solid #CCC;
        font-size: 15px;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0;
    }

    .search-results {
        position: relative;
        height: 0;
        width: 100vw;
        background: #FFF;
        color: #444;
        z-index: 999;

        .container {
            display: flex;
            flex-direction: column;
            background-color: rgba(255, 255, 255, 0.98);
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
        }
    }

    .header-right {
        font-weight: 400;

        .mdc-button {
            flex-direction: row-reverse;

            .mat-icon {
                width: 24px;
                height: 24px;
                font-size: 24px;
            }
        }

        .search {
            height: 38px;

            .mat-mdc-text-field-wrapper {
                border-radius: 0;
                border-color: rgba(255, 255, 255, 0.8) !important;

                &.mdc-text-field--outlined {
                    .mat-mdc-form-field-infix {
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                }
            }

            .mat-mdc-form-field-icon-suffix {
                >.mat-icon {
                    padding: 5px 12px;
                }
            }
        }

        .action {
            &.login {
                display: flex;
                flex-direction: column;
                align-content: flex-start;
                height: 25px;

                .cut-off-timer {
                    text-align: center;
                    font-weight: bold;
                }
            }

            &.basket {
                margin-left: 24px;
                font-weight: bold;

                .basket-view {
                    padding-top: 0;
                    padding-bottom: 0;
                    height: 30px;
                }

                .vat-toggle {
                    color: color('white');
                    font-size: 0.75rem;
                    font-weight: normal;

                    .mat-mdc-slide-toggle {
                        font-size: 0.625rem;

                        .mdc-switch {
                            transform: scale(0.8);
                        }

                        .mdc-form-field {
                            color: white;
                        }
                    }

                    @include responsive-breakpoint-down(lg) {
                        white-space: nowrap;
                    }

                    @include responsive-breakpoint-down(md) {
                        margin: 0;
                    }
                }

                .mdc-button {
                    background-color: transparent;
                    color: color('white');
                    font-size: 0.875rem;

                    .mat-icon {
                        margin-left: 2px;
                    }
                }

                .currency-format {
                    .currency-vat {
                        display: none;
                    }
                }
            }
        }
    }

    .made-in-britain-logo {
        margin: 15px 0;
        background-image: url(https://static.diy-kitchens.com/assets/images/home/made-in-britain-logo-ffffff.svg);
        background-size: cover;
        width: 78px;
        height: 16px;
    }

    .chooseStyleCard {
        width: 400px;
        height: 300px;
        margin-bottom: 20px;
        display: flex;
    }

    .kitchen-color-item {
        width: calc(25% - 23px);
        height: 52px;
    }

    .mat-mdc-menu-panel.mat-mdc-menu-panel {
        position: absolute !important;
        width: 100%;
        min-width: 100% !important;
        min-height: 400px;
    }

    .cdk-overlay-connected-position-bounding-box {
        position: absolute !important;
        left: 0 !important;
        width: 100% !important;
    }

    .search .mat-mdc-input-element::placeholder {
        color: rgba(255, 255, 255, 1) !important;
        font-weight: 600;
    }

    .search .mat-mdc-input-element:focus::placeholder {
        color: rgba(255, 255, 255, 0.7) !important;
    }

    .search .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
        border-color: rgba(255, 255, 255, 0.5) !important;
        color: rgba(255, 255, 255, 0.5);
    }

    .search .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper:focus-within {
        border-color: white !important;
    }

    .search .mat-mdc-input-element:focus {
        color: rgba(255, 255, 255, 1);
        caret-color: white;
        font-weight: 600;
    }

    @media only screen and (max-width: 1023px) {
        .header {
            .header-right {
                .mat-button {
                    padding: 0 8px;
                }

                .search {
                    margin: 0 0 0 10px;

                    .mat-form-field-appearance-outline {
                        .mat-form-field-infix {
                            width: 80px;
                        }
                    }
                }

                .here-to-help {
                    margin: 0 10px;
                }
            }
        }

        .sub-menu {
            &.normal {
                // display: none;
                max-width: unset;
                overflow-x: scroll;
            }

            // &.cycle {
            //     display: block;
            // }
        }
    }

    @media only screen and (max-width: 992px) {
        .header-banner {
            display: none;
        }

        .mobile-header {
            display: block;
        }
    }

    @media only screen and (max-width: 768px) {
        .mobile-header {
            .header-tint {
                .search {
                    display: none;
                }

                .search-toggle {
                    display: block;
                }
            }
        }
    }

    @media only screen and (min-width: 769px) {
        .mobile-search-banner {
            display: none;
        }
    }

    @media only screen and (min-width: 500px) {
        .mobile-header {
            .header-tint {
                .menu {
                    .menu-tint {
                        .menu-item-wrapper {
                            grid-template-columns: 1fr 1fr 1fr;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 400px) {
        .basket {
            .label {
                display: none;
            }
        }
    }
}