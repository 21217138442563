import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { MergeUniquePipe } from '@app/pipes';

import { CatalogueService } from '@app/services/catalogue';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';

@Component({
    selector: 'page-handles',
    templateUrl: './handles.page.html',
    styleUrls: ['./handles.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class HandlesPage implements OnInit {
    public loaded: boolean = false;

    public tags: string[] = ['diy kitchens - website', 'handles section'];
    public categories = [];
    public items: any;
    public handleCount: number;

    private mergeUnique = new MergeUniquePipe();

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) {
        this.navigationService.setNavigation({
            title: 'Handles',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'Description', content: `See our range of Kitchen Handles available at trade prices.` },
                { property: 'og:locale', content: 'en_GB' },
                { property: 'og:type', content: 'website' },
                { property: 'og:site_name', content: 'DIY Kitchens' },
                { property: 'og:url', content: 'Handles - DIY Kitchens ' },
                { property: 'og:title', content: 'Handles - DIY Kitchens ' },
                { property: 'og:description', content: `See our range of Kitchen Handles available at trade prices.` },
                { property: 'og:image', content: 'https://static.diy-kitchens.com/assets/images/products/handles/cimageurl/IN-M-BHBN174.jpg' },
                { property: 'og:image:secure_url', content: 'https://static.diy-kitchens.com/assets/images/products/handles/cimageurl/IN-M-BHBN174.jpg' },
                { property: 'og:image:width', content: '600' },
                { property: 'og:image:height', content: '400' }
            ],
            routeHistory: [{ title: 'Handles', route: '/handles' }]
        });
    }

    ngOnInit() {
        this.catalogueService
            .getHandles()
            .then((handles: any) => {
                if (handles) {
                    let categories = this.mergeUnique.transform(handles.map(handle => handle.cat));
                    this.categories = categories.map(category => {
                        this.items = handles.filter(product => product.cat === category);
                        let itemCount = this.items.length;

                        return {
                            name: category,
                            route: category.toLowerCase().replace(/ /g, '-'),
                            tag: category.toLowerCase().replace(/ /g, ' '),
                            itemCount: itemCount,
                            countName: 'handles'
                        };
                    });
                    this.loaded = true;
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
