import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AboutPage } from './about.page';
import {
    AboutCareersPage,
    AboutComparisonPage,
    AboutCreditRatingPage,
    AboutEasyOnlineOrderingPage,
    AboutHistoryPage,
    AboutOurPartnersPage,
    AboutPricePromisePage,
    AboutShowroomPage,
    AboutWhichPage,
    AboutWhyDiyKitchensPage
} from './content';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                pathMatch: 'full',
                component: AboutPage,
            },
            {
                path: 'price-and-quality-comparison',
                component: AboutComparisonPage,
                data: { section: 'compare' }
            },
            {
                path: 'careers',
                component: AboutCareersPage
            },
            {
                path: 'credit-rating',
                component: AboutCreditRatingPage
            },
            {
                path: 'our-partners',
                component: AboutOurPartnersPage
            },
            {
                path: 'price-promise',
                component: AboutPricePromisePage,
            },
            {
                path: 'why-diy-kitchens',
                component: AboutWhyDiyKitchensPage
            },
            {
                path: 'easy-online-ordering',
                component: AboutEasyOnlineOrderingPage
            },
            {
                path: 'history',
                component: AboutHistoryPage
            },
            {
                path: 'showroom',
                component: AboutShowroomPage
            },
            {
                path: 'which-best-buy',
                component: AboutWhichPage
            },
            {
                path: '**',
                redirectTo: ''
            }
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class AboutRouting { }
