@import '/src/theme/variables';

component-login {
    .mat-mdc-form-field-focus-overlay,
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: transparent;
    }

    .notice-message {
        margin-top: 15px;
    }

    .universal-login {
        position: absolute;
        top: 0;
        right: 0;
        overflow: hidden;
        transform: scale(0);
        opacity: 0;
        z-index: 0;
    }

    .customer-login-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .customer-login-dialog {
            overflow: auto;
            padding: 25px;
            border-radius: 5px;
            background-color: color('white');
            width: 100%;
            max-height: calc(100% - 50px);
            text-align: center;

            &.expand {
                max-width: 420px;

                .details {
                    display: flex;
                    margin: auto;

                    .mat-mdc-form-field {
                        flex-basis: 50%;

                        &:first-child {
                            padding-right: 10px;
                        }

                        + .mat-mdc-form-field {
                            padding-left: 10px;
                        }
                    }
                }

                .marketing-consent {
                    font-size: 0.75rem;
                }
            }

            .actions {
                display: flex;
                justify-content: space-between;
                margin: 0 auto;
                margin-bottom: 15px;
                width: 100%;
                max-width: 420px;
            }

            p {
                margin-bottom: 15px;
            }
        }

        .login-form {
            margin: 0 auto;
            width: 90%;
            max-width: 420px;
        }

        .submit-button {
            margin-top: 25px;
        }

        .mat-mdc-form-field {
            margin-bottom: 10px;
            width: 100%;
        }

        .mat-mdc-raised-button {
            margin-bottom: 20px;
            width: 90%;
        }

        .switch-login-type {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
        }

        .footer-text {
            padding-top: 20px;
            font-size: 0.75rem;
        }

        input {
            height: 35px;
            line-height: 35px;

            &:-webkit-autofill { // sass-lint:disable-line no-vendor-prefixes
                background-color: transparent !important; // sass-lint:disable-line no-important
                -webkit-box-shadow: 0 0 0 1000px color('white') inset; // sass-lint:disable-line no-vendor-prefixes
            }
        }

        .loader-status {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-top: 75px;
            margin-bottom: 50px;

            .mat-mdc-process-spinner {
                circle {
                    transition: stroke-dashoffset 0.4s ease-in-out;
                }
            }

            .done {
                position: absolute;
                transform: scale(0);
                transition: all 0.4s ease-in-out;
                opacity: 0;

                &.show {
                    transform: scale(1);
                    opacity: 1;
                }

                .mat-icon {
                    width: 55px;
                    height: 55px;
                    font-size: 55px;
                    font-weight: 600;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .customer-login-container {
            .customer-login-dialog {
                padding: 15px;
                max-height: calc(100% - 30px);

                &.expand {
                    .details {
                        flex-direction: column;

                        .mat-mdc-form-field {
                            &:first-child,
                            + .mat-mdc-form-field {
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
