import { Component, ViewEncapsulation, Input, OnInit, OnDestroy, OnChanges, AfterViewInit, Output, EventEmitter, Renderer2, ElementRef, Inject, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

import { Config } from '@app/config';
import { StringHelper, URLHelper } from '@app/utilities/helpers';

import { BasketService } from '@app/services/basket';
import { CatalogueService } from '@app/services/catalogue';
import { CostCalculator } from '@app/services/catalogue/cost-calculator';
import { ActiveRange, ProductType } from '@app/services/catalogue/models';
import { IAccessory, IAppliance, ICabinet, IDoor, IHandle, IRangeProduct, ISinkAndTaps, IWorktop } from '@app/services/catalogue/models/product.models';
import { DialogService } from '@app/services/dialog';

import { mockData } from '../mega-menu/mock-data';
import { IMegaMenuCategory, IMegaMenuSubCategory } from '../mega-menu/models';
import { IProductContainer, IProductContainerButton } from './models';

@Component({
    selector: 'component-header-search',
    templateUrl: './header-search.component.html',
    styleUrls: ['./header-search.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class HeaderSearchComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() searchText: string;
    @Input() searchInput: ElementRef | HTMLInputElement;
    @Output() closeSearch = new EventEmitter();
    @ViewChild('resultsContainer', { static: false, read: ElementRef }) resultsContainer: ElementRef;

    public units: ICabinet[] = [];
    public unitHits: ICabinet[] = [];
    public appliances: IAppliance[] = [];
    public applianceHits: IAppliance[] = [];
    public sinksTaps: ISinkAndTaps[] = [];
    public sinksTapsHits: ISinkAndTaps[] = [];
    public accessories: IAccessory[] = [];
    public accessoryHits: IAccessory[] = [];
    public handles: IHandle[] = [];
    public handleHits: IHandle[] = [];
    public worktops: IWorktop[] = [];
    public worktopHits: IWorktop[] = [];
    public worktopHitsGrouped: IProductContainer<IWorktop>[] = [];
    public rangeProducts: IRangeProduct[] = [];
    public panels: IRangeProduct[] = [];
    public panelHits: IRangeProduct[] = [];
    public doors: IDoor[] = [];
    public doorHits: IDoor[] = [];
    public megaMenuData = mockData;
    public categoryHits: IMegaMenuCategory[] = [];

    public ProductType = ProductType;
    public type: ProductType | 'Keywords' | string;

    public keywords: any;

    public activeRange: ActiveRange;
    private activeRangeSubscription: Subscription;
    private costCalculator: CostCalculator = new CostCalculator();

    constructor(
        private config: Config,
        private renderer: Renderer2,
        private urlHelper: URLHelper,
        private basketService: BasketService,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        @Inject(DOCUMENT) private document: any
    ) { }

    ngOnInit() {
        this.catalogueService
            .getCatalogue()
            .then((catalogue: any) => {
                if (catalogue) {
                    this.units = Object.keys(catalogue.cabinetsFlat).map(code => {
                        catalogue.cabinetsFlat[code].currentPrice = this.itemCost(catalogue.cabinetsFlat[code]);

                        return catalogue.cabinetsFlat[code];
                    });

                    this.appliances = catalogue.productAppliances;
                    this.sinksTaps = catalogue.sinkstaps;

                    this.getRangeExtras()
                        .then((extras) => {
                            this.rangeProducts = extras;
                            this.doors = this.getRangeDoors();
                            this.panels = this.getRangePanels();
                            this.updateSearch();
                        })
                        .catch((error) => this.dialogService.error(this.constructor.name, error));

                    // Worktops
                    this.worktops = catalogue.worktops;
                    this.worktops.forEach(top => {
                        top.media.image = top.media.image.replace(/[()]/g, '');
                    });

                    this.accessories = catalogue.extras;
                    this.handles = catalogue.handles;

                    this.updateSearch();
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));

        this.activeRangeSubscription = this.catalogueService.activeRange$
            .subscribe(activeRange => (this.activeRange = activeRange || null));
        this.disableBodyScroll();
    }

    ngOnDestroy() {
        if (this.activeRangeSubscription) {
            this.activeRangeSubscription.unsubscribe();
            this.activeRangeSubscription = null;
        }
        this.enableBodyScroll();
    }

    ngOnChanges() {
        this.updateSearch();
    }

    ngAfterViewInit() {
        const rect = this.resultsContainer.nativeElement.getBoundingClientRect();
        const topOffset = rect.top;
        this.resultsContainer.nativeElement.style.height = `calc(100vh - ${topOffset}px)`;
    }

    public focusSearch() {
        if (this.searchInput instanceof ElementRef) {
            this.searchInput.nativeElement.focus();
        } else if (this.searchInput instanceof HTMLInputElement) {
            this.searchInput.focus();
        }
    }

    private getRangeExtras(): Promise<IRangeProduct[]> {
        return new Promise((resolve, reject) => {
            let extras: IRangeProduct[] = [];
            this.catalogueService.getRangeDetails(this.activeRange?.range?.id || null)
                .then((rangeExtras) => {
                    if (Array.isArray(rangeExtras?.products)) {
                        extras = rangeExtras?.products;
                        if (this.activeRange?.rangeColour) {
                            extras = extras.filter((door) => door.colour === this.activeRange.rangeColour);
                        }
                        resolve(extras);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    private getRangeDoors(): IDoor[] {
        if (Array.isArray(this.rangeProducts) && this.rangeProducts.length) {
            return this.rangeProducts.filter((product) => !product.desc.match(/panel/i));
        }
        return [];
    }

    private getRangePanels(): IRangeProduct[] {
        if (Array.isArray(this.rangeProducts) && this.rangeProducts.length) {
            return this.rangeProducts.filter((product) => product.desc.match(/panel/i));
        }
        return [];
    }

    private itemCost(item) {
        if (this.activeRange) {
            const pricing = this.costCalculator.getPricing(this.activeRange.rangeDetail, item);
            if (pricing) {
                if (this.activeRange.rangeColour) {
                    return this.costCalculator.getBaseCost(pricing, this.activeRange.rangeColour);
                }

                return pricing.cost || item.cost || 0;
            }
        }

        return item.cost || 0;
    }

    public setTab(newType: ProductType | 'Keywords' | string) {
        if (this.config.isBrowser) {
            this.type = newType;
            const scroller = document.getElementById('scroller');
            scroller.scrollTo(0, 0);
        }
    }

    public close() {
        this.closeSearch.emit();
        this.enableBodyScroll();
    }

    public externalUrl(url) {
        return !!url.match(/^http/i);
    }

    public updateSearch() {
        this.keywords = null;

        if (this.searchText.length > 2) {
            this.searchText = this.searchText.replace('/', '-');
            let term = this.searchText,
                matchHeight,
                matchDepth,
                matchWidth;
            let matches = term.match(/([0-9]{3,4})([ ]*m{1,2}[ ]*)?[ ]*(high\b|height|tall)/i);
            if (matches) {
                matchHeight = Number(matches[1]);
                term = term.replace(/([0-9]{3,4})([ ]*m{1,2}[ ]*)?[ ]+(high|height|tall)/ig, '');
            }
            matches = term.match(/([0-9]{3,4})([ ]*m{1,2}[ ]*)?[ ]+(deep|depth)/i);
            if (matches) {
                matchDepth = Number(matches[1]);
                term = term.replace(/([0-9]{3,4})([ ]*m{1,2}[ ]*)?[ ]+(deep|depth)/ig, '');
            }
            matches = term.match(/([0-9]{3,4})([ ]*m{1,2}[ ]*)?[ ]+(wide|width)/i);
            if (matches) {
                matchWidth = Number(matches[1]);
                term = term.replace(/([0-9]{3,4})([ ]*m{1,2}[ ]*)?[ ]+(wide|width)/ig, '');
            }
            matches = term.match(/([0-9]{3,4})([ ]*m{0,2})?/i);
            if (matches && !matchWidth) {
                matchWidth = Number(matches[1]);
                term = term.replace(/([0-9]{3,4})([ ]*m{0,2})?/i, '');
            }

            // Replace some words with others to aid search
            const replacements = [[/\bglass\b/gi, 'glazed'], [/\bunits*\b/gi, ''], [/\bor\b/gi, ''], [/\band\b/gi, '']];
            replacements.forEach(rep => term = term.replace(rep[0], rep[1] as string));

            const terms = term.split(' ').filter(t => t.length > 1);
            const termREs = terms.map(t => {
                t = t.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapes special characters
                if (t.match(/^\d+m+$/i)) {
                    t = t.replace(/m+/, '');
                }

                return new RegExp(t, 'i');
            });

            if (this.units) {
                // Search units if we have dimensions or still have search terms
                this.units = this.units.filter((unit) => !unit?.hideInListings);
                this.unitHits = this.units;
                let unitCodeSearch = false;

                if (terms.length === 1) {
                    // Single word search term, so search unit codes
                    this.unitHits = this.unitHits.filter((cab) => cab.unit_code.includes(this.searchText.toUpperCase()));
                    if (this.unitHits.length) {
                        unitCodeSearch = true;
                    } else {
                        this.unitHits = this.units;
                    }
                }

                if (matchWidth || matchDepth || matchHeight) {
                    // Preserve unit code hits
                    let unitCodeHits = [];
                    if (unitCodeSearch) {
                        unitCodeHits = JSON.parse(JSON.stringify(this.unitHits));
                    }
                    this.unitHits = this.unitHits.filter((cab) => {
                        return (
                            (!matchWidth || matchWidth === cab.width) &&
                            (!matchHeight || matchHeight === cab.height) &&
                            (!matchDepth || matchDepth === cab.depth)
                        );
                    });
                    this.unitHits = [...unitCodeHits, ...this.unitHits];
                }

                if (terms.length > 0 && !unitCodeSearch) {
                    this.unitHits = this.unitHits.filter((cab) => {
                        return termREs.every((termRE) => cab.desc.match(termRE));
                    });
                }

                this.unitHits.forEach((unit) => unit.softClose = this.config.settings.softClose);
                if (this.activeRange) {
                    this.unitHits = this.unitHits.filter((unit) => unit?.currentPrice);
                }
            }

            this.applianceHits = [];
            this.sinksTapsHits = [];
            this.worktopHits = [];
            this.accessoryHits = [];
            this.handleHits = [];
            this.categoryHits = [];

            if (terms.length > 0) {
                if (this.appliances) {
                    this.applianceHits = this.appliances.filter(app => {
                        let hits = 0;
                        for (let j = terms.length; j--;) {
                            if (terms.length === 1 && app.code.includes(this.searchText.toUpperCase())) {
                                // Single word search term, so search product codes
                                return true;
                            } else if (app.description.match(termREs[j])) {
                                hits++;
                            } else if ((app.subcat.match(termREs[j])) || app.description.match(termREs[j])) {
                                hits++;
                            }
                        }
                        return hits === terms.length;
                    });
                }
                if (this.sinksTaps) {
                    this.sinksTapsHits = this.sinksTaps.filter(prod => {
                        let hits = 0;
                        for (let j = terms.length; j--;) {
                            if (terms.length === 1 && prod.code.includes(this.searchText.toUpperCase())) {
                                // Single word search term, so search product codes
                                return true;
                            } else if (prod.desc.match(termREs[j])) {
                                hits++;
                            } else if ((prod.brand.match(termREs[j])) || prod.subCategoryName.match(termREs[j])) {
                                hits++;
                            }
                        }
                        return hits === terms.length;
                    });
                }
                if (this.worktops) {
                    this.worktopHits = this.worktops.filter(top => {
                        let hits = 0;
                        for (let j = terms.length; j--;) {
                            if (terms.length === 1 && top.code.includes(this.searchText.toUpperCase())) {
                                // Single word search term, so search product codes
                                return true;
                            } else if (top.desc.match(termREs[j])) {
                                hits++;
                            } else if ((top.cat.match(termREs[j])) || top.sub_cat.match(termREs[j])) {
                                hits++;
                            }
                        }
                        return hits === terms.length;
                    });
                }
                if (this.accessories) {
                    this.accessoryHits = this.accessories.filter(prod => {
                        let hits = 0;
                        for (let j = terms.length; j--;) {
                            if (terms.length === 1 && prod.product_code.includes(this.searchText.toUpperCase())) {
                                // Single word search term, so search product codes
                                return true;
                            } else if (prod.desc.match(termREs[j])) {
                                hits++;
                            } else if ((prod.brand && prod.brand.match(termREs[j])) || prod.sub_cat && prod.sub_cat.match(termREs[j])) {
                                hits++;
                            }
                        }
                        return hits === terms.length;
                    });
                }
                if (this.handles) {
                    this.handleHits = this.handles.filter(prod => {
                        let hits = 0;
                        for (let j = terms.length; j--;) {
                            if (terms.length === 1 && prod.product_code.includes(this.searchText.toUpperCase())) {
                                // Single word search term, so search product codes
                                return true;
                            } else if (prod.desc.match(termREs[j])) {
                                hits++;
                            } else if (prod.sub_cat && prod.sub_cat.match(termREs[j])) {
                                hits++;
                            }
                        }
                        return hits === terms.length;
                    });
                }
                if (this.doors) {
                    this.doorHits = this.doors;
                    let doorCodeSearch = false;

                    if (termREs.length === 1) {
                        // Single word search term, so search door codes
                        this.doorHits = this.doorHits.filter((door) => door.unit_code.includes(this.searchText.toUpperCase()) || door?.diy_code === this.searchText);
                        if (this.doorHits.length) {
                            doorCodeSearch = true;
                        } else {
                            this.doorHits = this.doors;
                        }
                    }

                    if (terms.length > 0 && !doorCodeSearch) {
                        this.doorHits = this.doorHits.filter((door) => {
                            return termREs.every((termRE) => door.desc.match(termRE));
                        });
                    }
                }
                if (this.panels) {
                    this.panelHits = this.panels;
                    let panelCodeSearch = false;

                    if (termREs.length === 1) {
                        // Single word search term, so search panel codes
                        this.panelHits = this.panelHits.filter((panel) => panel.unit_code.includes(this.searchText.toUpperCase()));
                        if (this.panelHits.length) {
                            panelCodeSearch = true;
                        } else {
                            this.panelHits = this.panels;
                        }
                    }

                    if (terms.length > 0 && !panelCodeSearch) {
                        this.panelHits = this.panelHits.filter((panel) => {
                            return termREs.every((termRE) => panel.desc.match(termRE));
                        });
                    }
                }
            }

            // Category Search
            if (this.megaMenuData && Array.isArray(terms)) {
                terms.forEach((searchTerm) => {
                    const categoryKeys: string[] = Object.keys(this.megaMenuData);
                    categoryKeys.forEach((categoryKey) => {
                        let category: IMegaMenuCategory = this.megaMenuData[categoryKey];
                        let filteredCategory: IMegaMenuCategory = JSON.parse(JSON.stringify(category));
                        let filteredSubCategories: IMegaMenuSubCategory[] = [];
                        filteredCategory.subCategories = filteredSubCategories;
                        if (Array.isArray(category.subCategories)) {
                            category.subCategories.forEach((subCategory) => {
                                let partialSubCategory: IMegaMenuSubCategory = JSON.parse(JSON.stringify(subCategory));
                                partialSubCategory.items = subCategory.items.filter((item) =>
                                    item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
                                    item.url &&
                                    item.image
                                );
                                if (partialSubCategory.items.length) {
                                    filteredCategory = this.addMegaMenuSubCategory(filteredCategory, partialSubCategory);
                                }
                            });
                        }
                        if (filteredCategory.subCategories.length) {
                            this.categoryHits = this.addMegaMenuCategory(this.categoryHits, filteredCategory);
                        }
                    });
                });
            }

            if (this.unitHits.length > 0) {
                this.type = ProductType.CABINETS;
            } else if (this.applianceHits.length > 0) {
                this.type = ProductType.APPLIANCES;
            } else if (this.sinksTapsHits.length > 0) {
                this.type = ProductType.SINK_AND_TAPS;
            } else if (this.worktopHits.length > 0) {
                this.type = ProductType.WORKTOPS;
            } else if (this.accessoryHits.length > 0) {
                this.type = ProductType.ACCESSORIES;
            } else if (this.handleHits.length > 0) {
                this.type = ProductType.HANDLES;
            } else if (this.doorHits.length > 0) {
                this.type = ProductType.DOORS;
            } else if (this.panelHits.length > 0) {
                this.type = ProductType.RANGE_PRODUCTS;
            } else if (this.categoryHits.length > 0) {
                this.type = `${this.categoryHits[0].title} Categories`;
            } else {
                this.type = null;
            }

            const matchProductCode = terms.length === 1;
            if (matchProductCode) {
                this.unitHits = this.orderByProductCode<ICabinet>(terms[0], this.unitHits);
                this.applianceHits = this.orderByProductCode<IAppliance>(terms[0], this.applianceHits);
                this.sinksTapsHits = this.orderByProductCode<ISinkAndTaps>(terms[0], this.sinksTapsHits);
                this.accessoryHits = this.orderByProductCode<IAccessory>(terms[0], this.accessoryHits);
                this.handleHits = this.orderByProductCode<IHandle>(terms[0], this.handleHits);
                this.worktopHits = this.orderByProductCode<IWorktop>(terms[0], this.worktopHits);
                this.panelHits = this.orderByProductCode<IRangeProduct>(terms[0], this.panelHits);
                this.doorHits = this.orderByProductCode<IDoor>(terms[0], this.doorHits);
            }

            if (this.worktopHits) {
                const worktopsGroupedBySubCat = this.groupWorktopsBySubCategory(this.worktopHits.filter((top) => top.depth > 500));
                const worktopsGroupedByEdge = this.groupWorktopsByEdgeType(this.worktopHits.filter((top) => top.depth <= 500));
                this.worktopHitsGrouped = [...worktopsGroupedBySubCat, ...worktopsGroupedByEdge];
            }

            this.catalogueService.search(term)
                .then((response) => {
                    this.keywords = response || null;

                    if (!this.type) {
                        this.type = 'Keywords';
                    }
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        }
    }

    /**
     * Orders by productCode - closest match first
     * @todo improve this algoritm, made basic to put the exact matches at the front but needs to follow with next closest match and so on.
     */
    private orderByProductCode<T>(matchProductCode: string, products: ICabinet[] | IAppliance[] | ISinkAndTaps[] | IAccessory[] | IHandle[] | IWorktop[] | IRangeProduct[] | IDoor[]): T[] {
        const exactMatches = products.filter((product) => product.code === matchProductCode);
        const nonExactMatches = products.filter((product) => product.code !== matchProductCode);
        return [...exactMatches, ...nonExactMatches] as T[];
    }

    /**
     * Adds a category to a category list, checking for presence of the category first.
     * If already present it will merge the category's subcategories with the existing category.
     * @param categoryArray 
     * @param category 
     */
    private addMegaMenuCategory(categoryArray: IMegaMenuCategory[], category: IMegaMenuCategory): IMegaMenuCategory[] {
        let existingCategory = categoryArray.find((existingCategory) => existingCategory.title === category.title);
        if (existingCategory) {
            category.subCategories.forEach((subCategory) => {
                existingCategory = this.addMegaMenuSubCategory(existingCategory, subCategory);
            });
            return categoryArray;
        }
        categoryArray.push(category);
        return categoryArray;
    }

    /**
     * Adds a subCategory to a category, checking for presence of subcategory first.
     * If already present it will merge the subcategory items with the existing subcategory.
     * @param category 
     * @param subCategory 
     */
    private addMegaMenuSubCategory(category: IMegaMenuCategory, subCategory: IMegaMenuSubCategory): IMegaMenuCategory {
        let exists = category.subCategories.find((existingSubCategory) => existingSubCategory.id === subCategory.id);
        if (exists) {
            exists = this.mergeMegaMenuSubCategories(exists, subCategory);
            return category;
        }
        category.subCategories.push(subCategory);
        return category;
    }

    /**
     * Shallow merge of IMegaMenuSubCategory items, to provide a unique set of items
     * @param subCategoryA 
     * @param subCategoryB 
     */
    private mergeMegaMenuSubCategories(subCategoryA: IMegaMenuSubCategory, subCategoryB: IMegaMenuSubCategory): IMegaMenuSubCategory {
        const combinedItems = [...subCategoryA.items, ...subCategoryB.items];
        subCategoryA.items = Array.from(new Set(combinedItems));
        return subCategoryA;
    }

    /**
     * Products that share the same productCode, but are available in different edge styles: Quadra & Square.
     * Creates a container for each worktop that is displayed in the results
     */
    private groupWorktopsByEdgeType(worktops: IWorktop[]): IProductContainer<IWorktop>[] {
        const uniqueWorktopProductCodes = new Set<string>();
        worktops.forEach((worktop) => uniqueWorktopProductCodes.add(worktop.code));
        return Array.from(uniqueWorktopProductCodes).map((worktopCode) => {
            const matchingWorktops = worktops.filter((product) => product.code === worktopCode);
            const worktopCategories = Array.from(new Set<string>(matchingWorktops.map((worktop) => worktop.cat)));
            const buttonDetails: IProductContainerButton[] = worktopCategories.map((cat) => {
                return {
                    url: this.itemLink(matchingWorktops.find((worktop) => worktop.cat === cat), ProductType.WORKTOPS),
                    text: (worktopCategories.length > 1)
                        ? cat.replace(/laminate/i, '').replace(/standard/i, 'Quadra Edge')
                        : 'Details'
                }
            });
            return {
                code: matchingWorktops[0].code,
                description: matchingWorktops[0].desc,
                media: matchingWorktops[0].media,
                products: matchingWorktops,
                buttons: buttonDetails,
                fromPrice: matchingWorktops.find((product) => product.price < matchingWorktops[0].price)?.price || matchingWorktops[0].price
            }
        });
    }

    /**
     * Prevents the same worktop being displayed 40+ times for its size variants.
     * Creates a container for each worktop that is displayed in the results. The container 
     * has buttons added to it dependent upon the type of worktop to link directly to Quadra and 
     * Straight edge as well as an 'Order Sample' button for adding a sample directly to the basket.
     * @param worktops 
     * @returns 
     */
    private groupWorktopsBySubCategory(worktops: IWorktop[]): IProductContainer<IWorktop>[] {
        const uniqueWorktopSubCats = new Set<string>();
        worktops.forEach((worktop) => uniqueWorktopSubCats.add(worktop.sub_cat));
        return Array.from(uniqueWorktopSubCats).map((worktopSubCat) => {
            let matchingWorktops = worktops.filter((product) => product.sub_cat === worktopSubCat);
            const worktopCategories = Array.from(new Set<string>(matchingWorktops.map((worktop) => worktop.cat)));
            let buttonDetails: IProductContainerButton[] = worktopCategories.map((cat) => {
                return {
                    url: this.itemLink(matchingWorktops.find((worktop) => worktop.cat === cat), ProductType.WORKTOPS),
                    text: (worktopCategories.length > 1)
                        ? cat.replace(/laminate/i, '').replace(/standard/i, 'Quadra Edge')
                        : 'Details'
                }
            });
            if (buttonDetails.length === 1 && buttonDetails[0].text === 'Details' && !matchingWorktops[0].cat.match(/laminate/i)) {
                const newButtonDetails = [
                    {
                        url: '/samples/solid-surface-worktop-samples/granite',
                        text: 'Order Sample'
                    }
                ];
                if (!matchingWorktops[0].cat.match(/solid wood/i)) {
                    newButtonDetails.push({
                        url: `/solid-surfaces/config/${StringHelper.spaceToDash(matchingWorktops[0].code.toLowerCase())}-${matchingWorktops[0].thickness}mm`,
                        text: 'Configure Worktop'
                    });
                } else {
                    newButtonDetails.push(buttonDetails[0]);
                }
                buttonDetails = newButtonDetails;
            }

            const priceFrom = matchingWorktops.find((product) => product.price < matchingWorktops[0].price)?.price || matchingWorktops[0].price;

            // Add the sample product to the list of products (it has to be first!)
            if (buttonDetails[0].text === 'Order Sample') {
                const top = matchingWorktops[0];
                const sampleProduct = {
                    code: `${(StringHelper.spaceToDash(top.sub_cat || '')).toUpperCase()}-SMP`,
                    category: top.cat,
                    desc: 'Worktop Sample - ' + top.cat + ' ' + top.sub_cat,
                    cat: top.cat,
                    sub_cat: top.sub_cat,
                    cost: top.cat.toLowerCase() === 'solid wood' ? this.costCalculator.worktopWoodSampleCost : this.costCalculator.worktopSampleCost,
                    _cost: top.cat.toLowerCase() === 'solid wood' ? this.costCalculator.worktopWoodSampleCost : this.costCalculator.worktopSampleCost,
                    image: 'worktops/thumbs/' + (top.thumb_img || '').toLowerCase(),
                    media: top.media
                } as unknown as IWorktop;
                matchingWorktops = [sampleProduct, ...matchingWorktops];
            }
            return {
                code: (buttonDetails[0].text !== 'Order Sample' || matchingWorktops[0].cat.match(/solid wood/i)) ? `Available in ${matchingWorktops.length} sizes` : 'Configure',
                description: `${matchingWorktops[0].sub_cat} - ${matchingWorktops[0].cat.replace(/standard |square edge /i, '')} Worktop`,
                media: matchingWorktops[0].media,
                products: matchingWorktops,
                buttons: buttonDetails,
                fromPrice: priceFrom
            }
        });
    }

    public addToBasket(item) {
        if (this.type !== 'Keywords') {
            this.basketService.addItem(item, <ProductType>this.type);
        }

        this.closeSearch.emit();
    }

    public itemLink(item, type: ProductType) {
        return this.urlHelper.route(item).fullRoute;
    }

    private disableBodyScroll(): void {
        if (this.config.isBrowser) {
            this.renderer.setStyle(this.document.body, 'overflow', 'hidden');
        }
    }

    private enableBodyScroll(): void {
        if (this.config.isBrowser) {
            this.renderer.removeStyle(this.document.body, 'overflow');
        }
    }

    /**
     * Avoid repetition of category name if it is already present in item name.
     * @param itemName 
     * @param categoryName 
     */
    public getCategoryItemName(itemName: string, categoryName: string): string {
        const itemNameWords = itemName.split(' ');

        if (categoryName.includes(itemNameWords[itemNameWords.length - 1])) {
            return itemName;
        }
        return `${itemName} ${categoryName}`;
    }

}
