@import '/src/theme/variables';

component-basket-add-register-first-dialog {
    .text-wrap {
        max-width: 550px;
    }

    .button-wrapper {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
    }
}