import { Component, ViewEncapsulation } from '@angular/core';

import { DialogService } from '@app/services/dialog';

@Component({
    selector: 'component-latest-offers',
    templateUrl: './latest-offers.component.html',
    styleUrls: ['./latest-offers.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class LatestOffersComponent {
    constructor(
        private dialogService: DialogService
    ) { }

    public openSignUp() {
        this.dialogService.iframe(
            'https://form.jotform.com/231091681077052',
            {
                width: '100vw',
                maxWidth: '800px',
                height: '100vh',
                maxHeight: '495px',
                data: {
                    closePosition: {
                        top: '80px'
                    }
                }
            }
        );
    }
}
