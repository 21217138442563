import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { DialogButton, DialogConfirmConfig } from './models';

import { DialogComponent } from './components/dialog';
import { DialogInputResponse } from './components/dialog-input/models';
import { DialogIFrameComponent } from './components/dialog-iframe';
import { DialogInputComponent } from './components/dialog-input';

@Injectable()
export class DialogService {
    constructor(
        private dialog: MatDialog,
        private snackBar: MatSnackBar
    ) { }

    public error(...values) {
        console.error(...values);

        // let notice = JSON.stringify(values);
        // try {
        //     if (values && values.length === 1) {
        //         if (typeof values[0] === 'string') {
        //             notice = values[0];
        //         }
        //     }
        // } catch (e) { }

        // this.notice('Error', notice, true);
    }

    public prompt(title: string, content: string, options: MatDialogConfig = {}): Promise<boolean> {
        let defaults: MatDialogConfig = Object.assign(
            {
                maxWidth: '330px',
                disableClose: true
            },
            options
        );

        defaults.data = Object.assign({
            title,
            content
        }, options.data || {});

        let dialog = this.dialog.open(DialogComponent, defaults);

        if (options.data && options.data.externalDialogFunctions) {
            options.data.externalDialogFunctions.closeExternally = () => {
                dialog.close();
            };
        }

        return dialog
            .afterClosed()
            .toPromise();
    }

    public confirm(title: string, content: string, options: DialogConfirmConfig) {
        let defaults: MatDialogConfig = {
            maxWidth: '450px',
            panelClass: 'modal-no-gutter',
            data: {
                title: title,
                content: content
            }
        };

        let buttons: DialogButton[] = [
            { label: options.confirm, action: true },
            { label: options.cancel || 'cancel', action: false }
        ];

        defaults.data.buttons = buttons;

        return this.dialog
            .open(DialogComponent, defaults)
            .afterClosed()
            .toPromise();
    }

    public notice(title: string, content: string, allowClose: boolean = true) {
        let defaults: MatDialogConfig = {
            maxWidth: '330px',
            disableClose: true,
            data: {
                title,
                content,
                buttons: null
            }
        };

        if (allowClose) {
            defaults.data.buttons = [{ label: 'okay', action: true }];
        }

        let dialog = this.dialog.open(DialogComponent, defaults);

        return {
            dialog: dialog.afterClosed().toPromise(),
            close: () => {
                dialog.close();
            }
        };
    }

    public html(title: string, content: string, options: MatDialogConfig = {}) {
        let defaults: MatDialogConfig = Object.assign(
            {
                minHeight: '450px',
                disableClose: false
            },
            options
        );
        defaults.data = Object.assign(
            {
                title,
                content,
                isHtml: true
            },
            options.data || {}
        );

        return this.dialog
            .open(DialogComponent, defaults)
            .afterClosed()
            .toPromise();
    }

    public custom<T>(component: ComponentType<{}>, options: MatDialogConfig<T> = {}): Promise<any> {
        let defaults: MatDialogConfig<T> = Object.assign({}, options);

        return this.dialog
            .open(component, defaults)
            .afterClosed()
            .toPromise();
    }

    public input(title: string, content: string, options: MatDialogConfig = {}): Promise<DialogInputResponse> {
        let defaults: MatDialogConfig = Object.assign({
            maxWidth: '330px',
            disableClose: true
        }, options);

        defaults.data = Object.assign({
            title,
            content,
            requestInput: true
        }, options.data || {});

        return this.dialog.open(DialogInputComponent, defaults).afterClosed().toPromise();
    }

    public iframe(url: string, options: MatDialogConfig = {}): Promise<any> {
        let defaults: MatDialogConfig = Object.assign({
            maxWidth: '330px',
            disableClose: false
        }, options);

        defaults.data = Object.assign({
            url
        }, options.data || {});

        return this.dialog.open(DialogIFrameComponent, defaults).afterClosed().toPromise();
    }

    public snackbar(message: string, duration: number = 3000) {
        this.snackBar.open(message, null, {
            duration: duration
        });
    }
}
