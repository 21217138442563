<header class="content-header-corner content-header-background" [ngClass]="headerCssClass">
    <header class="content-contained">
        <h1>{{ title }}</h1>
        <ng-container *ngIf="category === 'sinks' && !subCategory">
            <p>You may think that it will be a simple decision to choose a sink for your kitchen but there are 3 different types to choose from. Inset, undermount and Belfast sinks. All these sinks would look great in your new kitchen, now you just need to decide which one!</p>
        </ng-container>
        <ng-container *ngIf="category === 'sinks' && subCategory === 'inset'">
            <p>Inset sinks are extremely versatile and can be used with all types of worktops. They are easily fitted by placing them on top of the worktop and clipping into position. Why not take a quick look through our range of inset sinks and see if the ceramic, composite or stainless steel ones are right for your kitchen.</p>
        </ng-container>
        <ng-container *ngIf="category === 'sinks' && subCategory === 'undermount'">
            <p>An undermount sink sits seamlessly under your worktop and looks fantastic with our range of solid surfaces, such as granite, quartz & Dekton. Undermount sinks have no draining board but this usually gets cut into the worktop and looks fantastic against our stainless steel sinks!</p>
            <p>Please be aware that under mount sinks are not compatible for use with laminate worktops. Under mount sinks should only be installed with solid timber, granite, marble or other solid surface worktop materials.</p>
        </ng-container>
        <ng-container *ngIf="category === 'sinks' && subCategory === 'belfast'">
            <p>A Belfast sink sits on top of a kitchen unit and is positioned under the edges of an overlapping worktop, such as a solid wood worktop or solid surface. These types of ceramic sinks have been in use since Victorian times and their charm and appeal adds a touch of elegance to any kitchen. Please note that it's not recommended that you use a Belfast sink with a laminate worktop.</p>
            <p>Please be aware that belfast sinks are not compatible for use with laminate worktops. Belfast sinks should only be installed with solid timber, granite, marble or other solid surface worktop materials.</p>
        </ng-container>
        <ng-container *ngIf="category === 'taps' && !subCategory">
            <p>Add those final finishing touches to your new kitchen design with a stylish feature tap to compliment your sink and worktop.</p>
        </ng-container>
        <ng-container *ngIf="category === 'taps' && subCategory === 'accessory'">
            <p><strong>In addition to our wide selection of taps, we offer a range of accessories to enhance your kitchen experience. Explore our selection of boiling water taps, cold water filters, sparkling water tanks & soap dispensers. Browse the tap accessories below, to see if you can find the perfect finishing touches for your kitchen.</strong></p>
        </ng-container>
        <ng-container *ngIf="category === 'taps' && subCategory === 'bridge'">
            <p><strong>Place holder text</strong></p>
        </ng-container>
        <ng-container *ngIf="category === 'taps' && subCategory === 'mixer'">
            <p>What better way to finish off your kitchen and compliment your sink than with a stylish Innova mixer tap. A mixer tap allows you to combine the hot and cold water, so that it pours out at the correct temperature, to suit your needs. Our Innova mixer taps are available in a choice of brushed steel or chrome and they all come with a 5-year guarantee.</p>
        </ng-container>
        <ng-container *ngIf="category === 'taps' && subCategory === 'pull-out'">
            <p>A pull out tap is a fantastic addition to your kitchen and really does give you the 'flexibility', to get to the hard to reach areas of your sink with the flexible hose, that is attached to the tap.</p>
        </ng-container>
        <ng-container *ngIf="category === 'taps' && subCategory === 'quooker-boiling-water'">
            <p>Quooker boiling water taps, aren’t just any kind of tap! These taps can give you instant boiling water, without ever having to boil a kettle again. The boiling water when it comes out of the Quooker tap is 100°C (boiling point), so it’s just perfect for making tea & coffee in seconds!</p>
        </ng-container>
        <ng-container *ngIf="category === 'taps' && subCategory === 'tap'">
            <p><strong>Place holder text</strong></p>
        </ng-container>
        <ng-container *ngIf="category === 'taps' && subCategory === 'spout'">
            <p>Add that finishing touch to your kitchen with a stylish & elegant spout tap, in a choice of chrome or brushed steel finishes.</p>
        </ng-container>
    </header>
</header>
<section class="lightgrey">
    <div class="contained flex-space-between">
        <div>
            <component-filters [items]="items" [filters]="filterFields" [sectionCats]="sectionCats" [section]="category"
                [sectionURL]="'sinks-and-taps'" (filterChange)="updateItems($event)" sortOrder="low-high"></component-filters>
        </div>
        <div class="products" *ngIf="items">
            <div class="banners">
                <div class="panel">
                    <div class="panel-left">
                        <a routerLink="/about-diy-kitchens/price-and-quality-comparison" title="Price & Quality Comparison">
                            <img src="https://static.diy-kitchens.com/assets/images/banner/listings-banner-trustpilot.jpg" width="100%" alt="Trustpilot Best rated national kitchen retailer."/>
                        </a>
                    </div>
                    <div class="panel-right">
                        <a href="https://advice.diy-kitchens.com/customer-questions/instant-hot-water-taps/" target="_blank" title="World's first instant 100°C boiling water tap">
                            <img src="https://static.diy-kitchens.com/assets/images/banner/quooker.png" width="100%" alt="World's first instant 100°C boiling water tap" />
                        </a>
                    </div>
                </div>
            </div>
            <component-product-listing *ngFor="let entry of filteredItems"
                [type]="type" [route]="route" [products]="products" [product]="entry">
            </component-product-listing>

            <div *ngIf="noResults" class="no-results">
                <em class="common-em">No items were found that match your filters...</em>
                Try removing a filter.
            </div>
        </div>
    </div>
</section>
