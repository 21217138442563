import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';

import { ReviewsRouting } from './reviews.routing';
import { ReviewsPage } from './reviews.page';

@NgModule({
    imports: [
        SharedModule,
        ReviewsRouting
    ],
    declarations: [
        ReviewsPage
    ]
})
export class ReviewsPageModule { }
