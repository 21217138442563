<div class="contain-content">
    <div class="contained">
        <table class="orders-table" mat-table [dataSource]="tableData.source" matSort *ngIf="ordersLoaded" multiTemplateDataRows>
            <ng-container matColumnDef="order">
                <th mat-header-cell *matHeaderCellDef>Order</th>
                <td mat-cell *matCellDef="let row">
                    <div class="order-container order-details-column">
                        <div class="order-details-container">
                            <div class="order-details">
                                <div class="order-detail">
                                    <h3>{{row.diyId}}</h3>
                                    <h4>Ref: {{row.orderNumber}}</h4>
                                </div>
                                <div class="order-detail">
                                    <p class="order-date">Ordered:</p>
                                    <p class="order-date" [matTooltip]="row.orderPlacedAt || row.originalOrderDate"><strong>{{row.orderPlacedAt || row.originalOrderDate | date:"dd/MM/YYYY"}}</strong></p>
                                    <br />
                                    <ng-container *ngIf="row.weekCommencing">
                                        <p class="order-delivery">Week Commencing:</p>
                                        <p class="order-delivery" [matTooltip]="row.weekCommencing"><strong>{{row.weekCommencing | date:"dd/MM/YYYY"}}</strong></p>
                                    </ng-container>
                                    <ng-container *ngIf="!row.weekCommencing && row.deliveryDate">
                                        <p class="order-delivery">Delivery Date:</p>
                                        <p class="order-delivery" [matTooltip]="row.deliveryDate"><strong>{{row.deliveryDate | date:"dd/MM/YYYY"}}</strong></p>
                                    </ng-container>
                                    <ng-container *ngIf="!row.weekCommencing && !row.deliveryDate">
                                        <p class="order-delivery">Delivery Date:</p>
                                        <p class="order-delivery"><strong>Not Selected</strong></p>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="order-actions-container">
                            <button mat-stroked-button class="secondary" (click)="openOrder(row)">
                                <mat-icon>description</mat-icon> View
                            </button>
                            <button mat-stroked-button class="secondary" *ngIf="row.planId" (click)="openPlan(row.planId)">
                                <mat-icon>build</mat-icon> Plan
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="payment">
                <th mat-header-cell *matHeaderCellDef>Cost</th>
                <td mat-cell *matCellDef="let row">
                    <div class="order-container">
                        <div class="order-details-container">
                            <h4 *ngIf="row.orderTotal">{{row.orderTotal | currencyFormat:false:false}}</h4>
                            <ng-container *ngIf="row.type === 2">
                                <div class="pill-notice" [ngClass]="{'pill-success': (row.outstandingAmount === 0), 'pill-error': (row.outstandingAmount !== 0)}">
                                    <p *ngIf="row.outstandingAmount === 0">Paid</p>
                                    <p *ngIf="row.outstandingAmount > 0" (click)="makePayment(row)">{{row.outstandingAmount | currencyFormat:false:false}} to pay</p>
                                    <p *ngIf="row.outstandingAmount < 0" (click)="makePayment(row)">
                                        {{row.outstandingAmount | currencyFormat:false:false}} to refund*
                                    </p>
                                </div>
                                <p class="small" *ngIf="row.outstandingAmount < 0">
                                    * This will be refunded automatically
                                </p>
                            </ng-container>
                        </div>
                        <div class="order-actions-container"></div>
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="customer">
                <th mat-header-cell *matHeaderCellDef>Delivery</th>
                <td mat-cell *matCellDef="let row">
                    <div class="order-container">
                        <div class="order-details-container">
                            <div class="customer-details">
                                <h4>{{row.firstName}} {{row.lastName}}</h4>
                                <!-- <p>{{row.mobile || row.deliveryMobile}}</p> -->
                                <!-- <p>{{row.email}}</p> -->
                            </div>
                            <div class="customer-delivery" *ngIf="row.deliveryPostcode">
                                <p>{{row.deliveryLine1}}</p>
                                <p>{{row.deliveryLine2}}</p>
                                <p>{{row.deliveryCity}}</p>
                                <p>{{row.deliveryCounty}}</p>
                                <p>{{row.deliveryPostcode}}</p>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="style">
                <th mat-header-cell *matHeaderCellDef>Style</th>
                <td mat-cell *matCellDef="let row" class="row-style">
                    <div class="order-container">
                        <div class="order-details-container">
                            <div class="styles" *ngFor="let style of row.styles">
                                <ng-container *ngIf="row.type !== 2">
                                    <h4>{{style}}</h4>
                                </ng-container>
                                <ng-container *ngIf="row.type === 2">
                                    <h4>{{style.rangeName}}</h4>
                                    <div class="style-colour" *ngFor="let colour of style.colours">
                                        <div class="swatch" [ngClass]="'swatch-' + colour.swatch"></div>
                                        <p>{{colour.name}}</p>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="style-colour" *ngIf="row.stylesMissing && row.stylesMissing.length">
                                <p *ngFor="let style of row.stylesMissing">{{style}}</p>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Info</th>
                <td mat-cell *matCellDef="let row" class="row-status">
                    <div class="order-container">
                        <div class="order-details-container">
                            <div class="info-item-split" *ngIf="row.deliveryType">
                                <p class="error tiny">Delivery Type:</p>
                                <p><strong>{{deliveryTypeName(row) | titlecase}}</strong></p>
                            </div>

                            <p class="info-item" *ngIf="row.sinks">
                                Sink <mat-icon>verified</mat-icon>
                            </p>
        
                            <p class="info-item" *ngIf="row.taps">
                                Tap <mat-icon>verified</mat-icon>
                            </p>
        
                            <p class="info-item" *ngIf="row.handles">
                                No. of handles: <strong>{{row.handles}}</strong>
                            </p>
        
                            <p class="info-item" *ngIf="row.appliances">
                                No. of appliances: <strong>{{row.appliances}}</strong>
                            </p>
        
                            <!-- <a class="info-item link-button" *ngIf="row.worktopId || row.hasSolidSurface === 1" (click)="openSolidSurfaceViewer(row)">
                                <mat-icon>pivot_table_chart</mat-icon> Solid Surface
                            </a> -->
        
                            <p class="info-item" *ngIf="row.woodWorktops">
                                Wood worktops <mat-icon>verified</mat-icon>
                            </p>
        
                            <p class="info-item" *ngIf="row.laminates">
                                Laminates <mat-icon>verified</mat-icon>
                            </p>
                        </div>
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="header-actions"></th>
                <td mat-cell *matCellDef="let row" class="row-actions">
                    <div class="actions">
                        
                        <div class="action" *ngIf="!this.reportIssue">
                            <button mat-stroked-button [matMenuTriggerFor]="actionsMenu" class="action-button">
                                Actions <mat-icon>keyboard_arrow_down</mat-icon>
                            </button>
                            <mat-menu #actionsMenu="matMenu">
                                <button mat-menu-item (click)="openOrder(row)">
                                    <mat-icon>description</mat-icon> View
                                </button>

                                <button mat-menu-item *ngIf="row.planId" (click)="openPlan(row.planId)">
                                    <mat-icon>build</mat-icon> Plan
                                </button>

                                <button mat-menu-item *ngIf="showPrint(row)" (click)="openOrder(row, true)">
                                    <mat-icon>receipt</mat-icon> Print Invoice
                                </button>

                                <button mat-menu-item *ngIf="showEdit(row)" (click)="editOrderDetails(row)">
                                    <mat-icon>local_shipping</mat-icon> Update Delivery Details
                                </button>

                                <button mat-menu-item *ngIf="showEdit(row)" (click)="editOrder(row)">
                                    <mat-icon>edit</mat-icon> Edit
                                </button>

                                <button mat-menu-item *ngIf="showPayment(row)" (click)="makePayment(row)">
                                    <mat-icon>payments</mat-icon>
                                    Make Payment
                                    <!-- <ng-container *ngIf="row.outstandingAmount > 0">Make Payment</ng-container> -->
                                    <!-- <ng-container *ngIf="row.outstandingAmount < 0">Refund</ng-container> -->
                                </button>

                                <button mat-menu-item *ngIf="showRemedial(row)" (click)="createRemedial(row, 'returns')">
                                    <mat-icon>local_shipping</mat-icon> Returns
                                </button>

                                <button mat-menu-item *ngIf="showRemedial(row)" (click)="createRemedial(row, 'report')">
                                    <mat-icon>report</mat-icon> Issues
                                </button>

                                <button mat-menu-item *ngIf="showRemedial(row)" (click)="createRemedial(row, 'missings')">
                                    <mat-icon>rule</mat-icon> Missings
                                </button>

                                <button mat-menu-item *ngIf="showCancel(row)" (click)="cancelOrder(row)">
                                    <mat-icon>cancel</mat-icon> Cancel Order
                                </button>
                            </mat-menu>
                        </div>

                        <div class="action report-issue" *ngIf="this.reportIssue">
                            <button mat-stroked-button class="secondary" *ngIf="showRemedial(row)" (click)="createRemedial(row, 'report')">
                                <mat-icon>report</mat-icon>Report Damage
                            </button>

                            <button mat-stroked-button class="secondary" *ngIf="showRemedial(row)" (click)="createRemedial(row, 'missings')">
                                <mat-icon>rule</mat-icon>Report Missing
                            </button>

                            <ng-container *ngIf="!showRemedial(row)">Unable to report issue for this order.</ng-container>
                        </div>
                    </div>
                </td>
            </ng-container>
    
            <thead>
                <tr mat-header-row class="orders-table-header" *matHeaderRowDef="displayColumns"></tr>
            </thead>
            <tbody>
                <tr mat-row *matRowDef="let row; columns: displayColumns;" class="orders-table-row" [ngClass]="{ 'removed': row.removedAt, 'not-remedial': this.reportIssue && !showRemedial(row) }"></tr>
            </tbody>
        </table>
    </div>
</div>
