import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { CatalogueService } from '@app/services/catalogue';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';

@Component({
    selector: 'page-samples',
    templateUrl: './samples.page.html',
    styleUrls: ['./samples.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SamplesPage implements OnInit {
    public loaded: boolean = false;

    public categories: any = {};
    public freeSamples = [
        {
            route: 'door-samples',
            button: 'Order Door Samples'
        },
        {
            route: 'carcase-samples',
            button: 'Order Cabinet Colour Samples'
        }
    ];
    public worktopSamples = [
        {
            route: 'solid-surface-worktop-samples/quartz',
            button: 'Order Quartz Worktop Samples'
        },
        {
            route: 'solid-surface-worktop-samples/granite',
            button: 'Order Granite Worktop Samples'
        },
        {
            route: 'solid-surface-worktop-samples/dekton',
            button: 'Order Dekton Worktop Samples'
        },
        {
            route: 'solid-surface-worktop-samples/solid-wood',
            button: 'Order Timber Worktop Samples'
        }
    ];

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) {
        this.navigationService.setNavigation({
            title: 'Samples',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { property: 'og:description', content: `Order samples to help you choose between the different carcase, door, and worktop finishes available.` },
                { name: 'Description', content: `Order samples to help you choose between the different carcase, door, and worktop finishes available.` },
            ],
            routeHistory: [{ title: 'Samples', route: '/samples' }]
        });
    }

    ngOnInit() {
        this.catalogueService.getCategories('samples')
            .then((categories) => {
                this.categories = categories;
                this.loaded = true;
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
