import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';
import { PipesModule } from '@app/pipes';

import { MobileMenuComponent } from './mobile-menu.component';

@NgModule({
    imports: [
        DesignModule,
        PipesModule,
    ],
    exports: [
        MobileMenuComponent
    ],
    declarations: [
        MobileMenuComponent
    ]
})
export class MobileMenuComponentModule { }
