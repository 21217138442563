import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';

import { CreateCustomerComponent } from './create-customer.component';

@NgModule({
    imports: [
        DesignModule
    ],
    exports: [
        CreateCustomerComponent
    ],
    declarations: [
        CreateCustomerComponent
    ]
})
export class CreateCustomerComponentModule { }
