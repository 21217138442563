<header class="content-contained">
    <h1>KITCHEN DOORS</h1>
    <p>
        With over 113 different styles of kitchen doors, from Gloss Handleless to Bespoke Painted, we're confident we've
        got a style to suit you!

        We only source the best quality kitchen doors, panels and decorative accessories using the UK's leading
        distributors Innova, Second Nature & Multiwood. These products are usually found in designer kitchen showrooms
        and huge savings can be made by placing your doors & kitchen units order with us online. Our doors, panels and
        accessories are stocked in the UK and available for immediate dispatch.
    </p>
</header>

<hr class="narrow" />

<section class="content-contained">
    <div class="kitchen-blocks">
        <div class="kitchen" *ngFor="let style of styles">
            <a [routerLink]="style.url" [title]="style.name">
                <h2>{{ style.name }}</h2>
            </a>

            <img class="kitchen-image" [cdn]="style.backgroundImage" width="100%" [alt]="style.name"/>

            <p class="large">{{style.title}}</p>
            <p class="small">{{style.description}}</p>

            <p class="bold">{{style.count}} kitchen styles from <span
                    [innerHTML]="style.price | currencyFormat:true:true"></span></p>

            <a [routerLink]="style.url" [title]="style.name">
                <button mat-flat-button color="primary" [routerLink]="style.url">
                    See all {{ style.name }}
                </button>
            </a>
        </div>
    </div>
</section>

<hr class="narrow" />

<section class="content-contained">
    <div class="kitchen-blocks">
        <div class="kitchen">
            <h2>How much will it cost?</h2>

            <img class="kitchen-image"
                src="https://static.diy-kitchens.com/assets/images/kitchens/kitchen-budget-video-box.jpg"
                media-thumb="https://www.youtube.com/embed/WLd2J0Kzz8U" width="100%" alt="Buying guide video control"/>

            <p class="large">Kitchen buying guide</p>
            <p class="small">When it comes to choosing your new kitchen there's a few things you need to know before you
                buy. So we've put together this simple guide to help you get a clearer picture of your ideal kitchen.
            </p>

            <button mat-flat-button color="primary" media-thumb="https://www.youtube.com/embed/WLd2J0Kzz8U">
                See our tips on budgeting
            </button>
        </div>

        <div class="kitchen">
            <h2>How to choose a style?</h2>

            <img class="kitchen-image"
                src="https://static.diy-kitchens.com/assets/images/kitchens/kitchens-video-box.jpg"
                media-thumb="https://www.youtube.com/embed/s5riE8593KM" width="100%" alt="Buying guide video control" />

            <p class="large">With over 100 kitchen styles, choosing can be tricky</p>
            <p class="small">With fully constructed cabinets, your kitchen will be much quicker and cheaper to fit. Our
                kitchens also come with a 30 year guarantee and are made with industry leading components. We also have
                the widest choice of colours, materials and cabinets styles, which will ensure you get exactly what you
                want from your new kitchen.</p>

            <button mat-flat-button color="primary" media-thumb="https://www.youtube.com/embed/s5riE8593KM">
                Tips on choosing your style
            </button>
        </div>

        <div class="kitchen">
            <h2>How our kitchens are made</h2>

            <img class="kitchen-image"
                src="https://static.diy-kitchens.com/assets/images/kitchens/kitchens-factory-box.jpg"
                media-thumb="https://www.youtube.com/embed/VlDQGU7h4cE" width="100%" alt="How our kitchens are made video control" />

            <p class="large">State of the art factory and manufacturing equipment</p>
            <p class="small">Our custom built to order kitchens are made in our 2 manufacturing plants, which cover over
                75,000 sq ft. Our ethos is to manufacturer quality products by using the latest manufacturing technology
                and to give you great cost savings.</p>

            <button mat-flat-button color="primary" media-thumb="https://www.youtube.com/embed/VlDQGU7h4cE">
                See how it's made
            </button>
        </div>

        <div class="kitchen">
            <h2>Online showroom tour</h2>

            <img class="kitchen-image"
                src="https://static.diy-kitchens.com/assets/images/kitchens/kitchens-showroom-box.jpg" width="100%" alt="Online showroom tour"/>

            <p class="large">See our beautiful showroom from the comfort of your home</p>
            <p class="small">Want to see how great our kitchens look? Well, you can do that from the comfort of your own
                home with our 360° virtual showroom tour. Navigate through our recently extended 2 floor kitchen
                showroom to find the kitchen that's right for you.</p>

            <a routerLink="/about-diy-kitchens/showroom" title="Visit Our Showroom">
                <button mat-flat-button color="primary">
                    Our spectacular showroom
                </button>
            </a>
        </div>

        <div class="kitchen">
            <h2>Real customer kitchens</h2>

            <img class="kitchen-image"
                src="https://static.diy-kitchens.com/assets/images/kitchens/kitchens-reviews-box.jpg" width="100%" alt="Real customer kitchens"/>

            <p class="large">See our craftsmanship firsthand in our customers' homes</p>
            <p class="small">We have created an area where you can see some of the real customer kitchens that we have
                supplied to our customers, that they have had fitted themselves. Use these kitchens to get some
                inspiration when choosing your own new kitchen.</p>

            <a routerLink="/customer-reviews" title="Customer Reviews">
                <button mat-flat-button color="primary">
                    See all real kitchen reviews
                </button>
            </a>
        </div>

        <div class="kitchen">
            <h2>Independently reviewed</h2>

            <div class="kitchen-image trustpilot">
                <h2>Excellent</h2>
                <div class="trustpilot-content">
                    <div class="rating">
                        <span class="trustpilot-green">4.7</span>/<span>5</span>
                    </div>
                    <p class="small">based on <span class="votes">6719</span> customer reviews</p>
                </div>
                <div class="trustpilot-logo">
                    <img src="https://static.diy-kitchens.com/assets/images/kitchens/trustpilot-logo-white.svg"
                        height="34" alt="Trustpilot logo"/>
                </div>
            </div>

            <p class="large">Our goal is to provide a first class service every time</p>
            <p class="small">We believe we offer a first class product and a first class service and we're not alone
                with that thought! Visit our independent Trustpilot page where you can see some reviews from our
                customers and see their thoughts and recommendations.</p>

            <a href="https://www.trustpilot.co.uk/review/www.diy-kitchens.com" target="_blank" title="Trustpilot">
                <button mat-flat-button color="primary">
                    See all trustpilot reviews
                </button>
            </a>
        </div>
    </div>
</section>

<component-latest-offers></component-latest-offers>
