<header class="content-contained">
    <h1>About DIY Kitchens</h1>
    <p>We are a family run business that has been manufacturing and supplying high specification Innova kitchens to both trade and retail customers for over 30 years. We have been trading online as diy-kitchens.com since 2003.</p>
</header>

<hr class="narrow" />

<section class="content-contained how-kitchens-made">
    <div class="how-kitchens-made-video" (click)="showVideo('https://www.youtube.com/embed/VlDQGU7h4cE?rel=0')">
        <mat-icon>play_circle_filled</mat-icon>
        <img src="https://static.diy-kitchens.com/assets/images/about/about-diy-kitchens_video.jpg" width="100%" alt="Kitchen cabinet being machined"/>
    </div>

    <div class="how-kitchens-made-content">
        <h2>How our kitchens are made</h2>
        <p>We now have 12 state of the art facilities across Yorkshire that cover over
            540,000 sq ft. We are committed to re-investing in our facilities to cope with
            increased demands from our customers and have an exciting new showroom due to
            open next year.</p>
        <p>Our ethos is simple, we manufacture quality products using a combination of the
            latest manufacturing technology and hand crafted skills. By dealing with
            diy-kitchens.com you are dealing directly with a manufacturer allowing you to
            'cut out the middle man', passing the savings onto you. That's why you pay diy
            store prices but receive the quality and specification of kitchen that you will
            find in a premium high street studio.</p>
        <p>Our manufacturing facilities are equipped to manufacture on a kitchen by kitchen
            basis. This flexible approach to manufacturing means each kitchen is produced to
            your exact specification and not restricted to a range of products that may not
            meet your requirements.</p>
    </div>
</section>

<div class="content-contained">
    <hr class="narrow" />

    <div class="about-diy-topics">
        <div class="about-diy-topic">
            <a routerLink="/about-diy-kitchens/why-diy-kitchens" title="Why diy kitchens">
                <img src="https://static.diy-kitchens.com/assets/images/about-us/about-us_why-diy-kitchens.jpg" width="100%" alt="Beautiful kitchen"/>
            </a>
            <h3>Why DIY Kitchens?</h3>
            <div class="about-diy-topic-content">
                <p>There are so many reasons why buying a kitchen from us makes perfect sense.</p>
            </div>
            <a mat-flat-button color="primary" routerLink="/about-diy-kitchens/why-diy-kitchens" title="Why diy kitchens">Find out more</a>
        </div>
        <div class="about-diy-topic">
            <a routerLink="/about-diy-kitchens/easy-online-ordering" title="Online Ordering">
                <img src="https://static.diy-kitchens.com/assets/images/about-us/about-us_easy-online-ordering.jpg" width="100%" alt="Order online with ease"/>
            </a>
            <h3>Easy online ordering</h3>
            <div class="about-diy-topic-content">
                <p>Tips and information that will make ordering your kitchen online as simple as possible.</p>
            </div>
            <a mat-flat-button color="primary" routerLink="/about-diy-kitchens/easy-online-ordering" title="Online Ordering">Find out more</a>
        </div>
        <div class="about-diy-topic">
            <a routerLink="/about-diy-kitchens/price-and-quality-comparison" title="See how we compare">
                <img src="https://static.diy-kitchens.com/assets/images/about-us/about-us_comparison-v2.jpg" width="100%" alt="Price comparison shown against competitors"/>
            </a>
            <h3>How we compare</h3>
            <div class="about-diy-topic-content">
                <p>High specification kitchens with quality components at extremely affordable prices.</p>
            </div>
            <a mat-flat-button color="primary" routerLink="/about-diy-kitchens/price-and-quality-comparison" title="See how we compare">Find out more</a>
        </div>
        <div class="about-diy-topic">
            <a routerLink="/specification/unit-specifications" title="Unit Specifications">
                <img src="https://static.diy-kitchens.com/assets/images/about-us/about-us_unit-specification.jpg" width="100%" alt="High spec larder unit with in-built wire racking"/>
            </a>
            <h3>Unit specification</h3>
            <div class="about-diy-topic-content">
                <p>With an over 900 made-to-order units, your next kitchen is only limited by your imagination.</p>
            </div>
            <a mat-flat-button color="primary" routerLink="/specification/unit-specifications" title="Specifications">Find out more</a>
        </div>
        <div class="about-diy-topic">
            <a routerLink="/about-diy-kitchens/history" title="Our History">
                <img src="https://static.diy-kitchens.com/assets/images/about-us/about-us_company-history.jpg" width="100%" alt="Historic newspaper advertisement"/>
            </a>
            <h3>Our History</h3>
            <div class="about-diy-topic-content">
                <p>A family run business manufacturing high specification kitchens and kitchen units since 1982.</p>
            </div>
            <a mat-flat-button color="primary" routerLink="/about-diy-kitchens/history" title="Our History">Find out more</a>
        </div>
        <div class="about-diy-topic">
            <a routerLink="/about-diy-kitchens/price-promise" title="Price Promise">
                <img src="https://static.diy-kitchens.com/assets/images/about-us/about-us_price_promise.jpg" width="100%" alt="Customer saving money after buying from DIY Kitchens"/>
            </a>
            <h3>Price Promise</h3>
            <div class="about-diy-topic-content">
                <p>Dedicated to offering you the best quality / value for money for purchasing your new kitchen.</p>
            </div>
            <a mat-flat-button color="primary" routerLink="/about-diy-kitchens/price-promise" title="Price Promise">Find out more</a>
        </div>
        <div class="about-diy-topic">
            <a routerLink="/about-diy-kitchens/showroom" title="Vist Our Showroom">
                <img src="https://static.diy-kitchens.com/assets/images/about-us/about-us_showroom.jpg" width="100%" alt="Customers looking at samples in our showroom"/>
            </a>
            <h3>Kitchen Showroom</h3>
            <div class="about-diy-topic-content">
                <p>See {{diy.ranges}} stunning kitchen displays, packed with inspiring layouts, inventive unit combinations.</p>
            </div>
            <a mat-flat-button color="primary" routerLink="/about-diy-kitchens/showroom" title="Vist Our Showroom">Find out more</a>
        </div>
        <div class="about-diy-topic">
            <a routerLink="/about-diy-kitchens/credit-rating" title="Credit Rating">
                <img src="https://static.diy-kitchens.com/assets/images/about-us/about-us_credit-rating.jpg" width="100%" alt="Credit rating dial showing needle pointing to Excellent"/>
            </a>
            <h3>Credit rating</h3>
            <div class="about-diy-topic-content">
                <p>In the recent 'recession' years we know customers wariness of who they are ordering with.</p>
            </div>
            <a mat-flat-button color="primary" routerLink="/about-diy-kitchens/credit-rating" title="Credit Rating">Find out more</a>
        </div>
        <div class="about-diy-topic">
            <a routerLink="/about-diy-kitchens/our-partners" title="Our Partners">
                <img src="https://static.diy-kitchens.com/assets/images/about-us/about-us_partners.jpg" width="100%" alt="Beautiful Kitchen"/>
            </a>
            <h3>Our partners</h3>
            <div class="about-diy-topic-content">
                <p>Building mutually beneficial partnerships with our suppliers matters to us.</p>
            </div>
            <a mat-flat-button color="primary" routerLink="/about-diy-kitchens/our-partners" title="Our Partners">Find out more</a>
        </div>
    </div>
</div>

<component-latest-offers></component-latest-offers>
