export class DeepMergeHelper {
    public static merge(target, source) {
        let output;
        try {
            output = JSON.parse(JSON.stringify(target));
        } catch (e) { }

        if (DeepMergeHelper.isObject(target) && DeepMergeHelper.isObject(source)) {
            Object.keys(source).forEach(key => {
                if (DeepMergeHelper.isObject(source[key])) {
                    if (!(key in target)) {
                        Object.assign(output, { [key]: source[key] });
                    } else {
                        output[key] = DeepMergeHelper.merge(target[key], source[key]);
                    }
                } else {
                    Object.assign(output, { [key]: source[key] });
                }
            });
        }

        return output;
    }

    private static isObject(item) {
        return item && typeof item === 'object' && !Array.isArray(item);
    }
}
