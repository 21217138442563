import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SinksAndTapsPage } from './sinks-and-taps.page';
import { SinksAndTapsListingPage } from './listing/sinks-and-taps-listing.page';
import { SinksAndTapsDetailPage } from './detail/sinks-and-taps-detail.page';

import { PageNotFoundComponent } from '@app/shared/page-not-found.component';
import { ProductCategoryGuard } from '@app/utilities/guards/product-category.guard';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: SinksAndTapsPage,
                pathMatch: 'full'
            },
            {
                path: ':category',
                component: SinksAndTapsListingPage,
                canActivate: [ProductCategoryGuard],
                data: { reuse: true, vatToggle: true }
            },
            {
                path: ':category/:subCategory',
                component: SinksAndTapsListingPage,
                canActivate: [ProductCategoryGuard],
                data: { reuse: true, vatToggle: true }
            },
            {
                path: ':category/:subCategory/:description',
                component: SinksAndTapsDetailPage,
                data: { vatToggle: true }
            },
            { path: '**', component: PageNotFoundComponent }
        ])
    ],
    exports: [RouterModule]
})
export class SinksAndTapsRouting { }
