import { AllProductsUnion, ICabinet, isAccessory } from './models/product.models';
import { BasketItem, BasketItemHanding } from '../basket/models';
import { HandingType} from './models';

// Here to test the worker service
export function test(params: Object): Promise<any> {
    return new Promise((resolve, reject) => {
        resolve('Test Response From Util');
    });
}

/**
* Test whether an item is a carcase material product or not.
* !!! IMPORTANT: Any changes made here MUST be replicated in the Customer Admin API catalogue.utils.ts !!!
*/
export function isCarcaseMaterial(item: AllProductsUnion | BasketItem): boolean {
   const productCodes = ['CM1', 'CM2', 'CM3', 'CM2TG', 'CM3TG', 'CM8'];
   if (isAccessory(item) && productCodes.includes(item.product_code)) {
       return true;
   }

   if (typeof item === 'object' && Object.hasOwn(item, 'code')) {
       return productCodes.includes((item as BasketItem).code);
   }
   return false;
}

/**
 * !!! IMPORTANT: Any changes made here MUST be replicated in the Customer Admin API catalogue.utils.ts !!!
 */
export function isCarcaseShelf(item: AllProductsUnion | BasketItem): boolean {
    if (isAccessory(item)) {
        if (item?.sub_cat === 'Shelves' && item?.material === 'Carcase') {
            return true;
        }
    }

    if (typeof item === 'object' && Object.hasOwn(item, 'code')) {
        return !!(item as BasketItem).code.match(/^(BS|WS)/i);
    }

    return false;
}

/**
 * !!! IMPORTANT: Any changes made here MUST be replicated in the Customer Admin API catalogue.utils.ts !!!
 */
export function isCarcaseColoured(item: AllProductsUnion | BasketItem): boolean {
   if (isCarcaseShelf(item) || isCarcaseMaterial(item)) {
        return true;
   }

   return false;
}

/**
 * !!! IMPORTANT: Any changes made here MUST be replicated in the Customer Admin API catalogue.utils.ts !!!
 */
export function fixCode(code: string) {
    if (code) {
        return code.replace(/\//g, '-').replace('+', '-');
    }

    return code;
}

/**
 * Adds handingType attribute to object based on the unit.code attribute.
 * HandingType.PANEL || HandingType.DRAWER || HandingType.HINGE
 * Additionally sets the _hingeSide attribute
 * !!! IMPORTANT: Any changes made here MUST be replicated in the Customer Admin API catalogue.utils.ts !!!
 * @param unit
 * @returns unit with handingType & _hingeSide attributes set
 */
export function setHandingType(unit: ICabinet): ICabinet {
    if (unit.code) {
        if (unit.handed === BasketItemHanding.LH || unit.handed === BasketItemHanding.RH) {
            unit._hingeSide = unit.handed;
        } else if (unit.handed === 'Y') {
            if (unit.handingType === HandingType.DRAWER) {
                unit._hingeSide = BasketItemHanding.LH;
            } else {
                unit._hingeSide = BasketItemHanding.RH;
            }
        } else {
            unit._hingeSide = null;
        }

        if (unit.code.match(/^(SBDC|SBFC|FDMC|BDCB|FDCB)/)) {
            // Units with a blank panel side, alters the option from 'hinge side' to 'blank panel side'.
            unit.handingType = HandingType.PANEL;
        } else if (unit.code.match(/^SBD\d+/)) {
            unit.handingType = HandingType.DRAWER;
            unit._hingeSide = BasketItemHanding.LH;
        } else if (unit.code.match(/^(BEQ|BES|TEQ|TES|MWQ|TWQ|WES|SWQ|HBEQ)/i)) {
            unit.handingType = HandingType.QUADRANT;
        } else {
            unit.handingType = HandingType.HINGE;
        }
       
    }
    return unit;
}
