import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';

import { AboutUnitSpecificationsRouting } from './unit-specifications.routing';
import { AboutUnitSpecificationsPage } from './unit-specifications.page';

@NgModule({
    imports: [
        SharedModule,
        AboutUnitSpecificationsRouting
    ],
    declarations: [
        AboutUnitSpecificationsPage
    ]
})
export class AboutUnitSpecificationsPageModule { }
