import { AbstractControl, ValidationErrors } from '@angular/forms';

import { FormValidator } from './models';

export class PostcodeValidator implements FormValidator {
    // eslint-disable-next-line max-len
    private postcodeRegExp = new RegExp(/$([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/);

    public validate(control: AbstractControl): ValidationErrors | null {
        if (control.value) {
            const cleanValue = control.value.toUpperCase().replace(/\s/g, '').replace(/([\w]{3})$/i, ' $1');

            // TODO: get custom form group interface working
            control['cleanValue'] = cleanValue;

            if (cleanValue.match(this.postcodeRegExp)) {
                return null;
            } else {
                return { postcodeValid: false };
            }
        } else {
            return null;
        }
    }
}
