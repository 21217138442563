<mat-dialog-content>
    <div class="container-fluid">
        <div *ngIf="isInternal" class="options">
            <div class="option" [ngClass]="{ selected: (!option || option === 'square') }" (click)="select(false)">
                <img src="https://static.diy-kitchens.com/worktops/icons/shapings/straight_corner.jpg" alt="Straight Corner"/>
                <div class="details">
                    <em>Square corner</em>
                    <p><span [innerHTML]="cornerOpts['STDSQ'].price | currencyFormat"></span></p>
                </div>
            </div>
            <div class="option" [ngClass]="{ selected: (option === 'interal-curve') }" (click)="select('internal')">
                <img src="https://static.diy-kitchens.com/worktops/icons/shapings/internal_curve.jpg" alt="Internal Curve" />
                <div class="details">
                    <em>Internal curver</em>
                    <p><span [innerHTML]="cornerOpts['RIC63'].price | currencyFormat"></span></p>
                </div>
            </div>
        </div>
        <div *ngIf="!isInternal" class="options">
            <div class="option" [ngClass]="{ selected: (!option) }" (click)="select(false)">
                <img src="https://static.diy-kitchens.com/worktops/icons/shapings/straight_corner.jpg" alt="Square Corner"/>
                <div class="details">
                    <em>Square corner</em>
                    <p><span [innerHTML]="cornerOpts['STDSQ'].price | currencyFormat"></span></p>
                </div>
            </div>
            <div class="option" [ngClass]="{ selected: (option === 'soft') }" (click)="select('soft')">
                <img src="https://static.diy-kitchens.com/worktops/icons/shapings/soft_rounded.jpg" alt="Soft Rounded"/>
                <div class="details">
                    <em>Soft round</em>
                    <p><span [innerHTML]="cornerOpts['RRR13'].price | currencyFormat"></span></p>
                </div>
            </div>
            <div class="option" [ngClass]="{ selected: (option === 'radius') }" (click)="select('radius')">
                <img src="https://static.diy-kitchens.com/worktops/icons/shapings/radius_feature.jpg" alt="Radius Feature"/>
                <div class="details">
                    <em>Radius</em>
                    <p><span [innerHTML]="cornerOpts['RRR53'].price | currencyFormat"></span></p>
                </div>
            </div>
            <div class="option" [ngClass]="{ selected: (option === 'curve') }" (click)="select('curve')">
                <img src="https://static.diy-kitchens.com/worktops/icons/shapings/curved_base_unit.jpg" alt="Curved Base Unit"/>
                <div class="details">
                    <em>Curved</em>
                    <p><span [innerHTML]="cornerOpts['RRR63'].price | currencyFormat"></span></p>
                </div>
            </div>
            <div class="option" [ngClass]="{ selected: (option === 'angle') }" (click)="select('angle')">
                <img src="https://static.diy-kitchens.com/worktops/icons/shapings/angle_end.jpg" alt="Angle End"/>
                <div class="details">
                    <em>Angled</em>
                    <p><span [innerHTML]="cornerOpts['RREU3'].price | currencyFormat"></span></p>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>