@import '/src/theme/variables';

page-appliance-detail {
    .product {
        padding-bottom: 30px;
    }

    .product-image {
        position: relative;
        width: 40%;
    }

    .appliance-details {
        width: 60%;

        .key-features {
            display: grid;
            grid-template-rows: repeat(5, auto);
            grid-template-columns: repeat(auto-fill, minmax(auto, 1fr));
            grid-auto-flow: column;
            column-gap: 25px;
            padding: 0 0 30px 20px;
            line-height: 1.5rem;
        }

        .price {
            text-align: right;
            justify-content: right;
        }
    }

    .warranty {
        padding-right: 20px;
    }

    .price-match {
        padding-left: 20px;
    }

    .brand {
        height: 32px;
    }

    .warranty-logo {
        float: right;
        margin-bottom: 15px;
        margin-left: 10px;
        max-width: 65px;
    }

    .offers {
        display: flex;
        margin-bottom: 15px;
    }

    .code {
        margin-bottom: 20px;
        color: #AAA;
        font-weight: 600;
    }

    .title {
        margin-top: 0;
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.2rem;
    }

    .product-description {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        .product-details {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 40%;
            padding: 0;
        }

        .warranty-info {
            width: 60%;

            .sub-title {
                font-weight: bold;
            }
        }
    }

    .buy {
        display: flex;
        justify-content: flex-end;
        gap: 10px;

        .basket-button {
            min-width: 130px;
            max-width: 150px;
        }
    }

    .quantity {
        padding: 6px 20px;
        font-size: 17px;
        font-weight: 600;
    }

    .details {
        padding: 56px 0;
    }

    .housings {
        padding: 30px 0;
        background-color: color('brand-lightgrey');
        text-align: center;
    }

    .products {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .item {
            display: flex;
            flex: 1 1 auto;
            flex-direction: row;
            margin: 15px 0 0 40px;
            box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
            background-color: color('white');

            .left {
                flex: 0 0 auto;
                padding: 30px;
                border-right: 0.5px solid color('brand_lightgrey');
                width: 380px;

                .image {
                    display: flex;
                    position: relative;
                    flex-basis: 275px;
                    align-items: flex-end;

                    a {
                        display: block;
                        width: 100%;
                    }

                    img:not(.promotion-directive-unit-detail-offer-overlay) {
                        display: block;
                        margin: 0 auto;
                        margin-bottom: 32px;
                        max-width: 100%;
                        max-height: 300px;
                        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0*/
                        filter: grayscale(100%);

                        &.flip {
                            transform: scaleX(-1);
                        }
                    }
                }
            }

            @media only screen and (max-width: 1200px) {
                .left {
                    width: 280px;
                }
            }

            .details {
                padding: 30px;
                width: 100%;

                .price {
                    float: right;
                    margin-left: 40px;
                    text-align: right;
                    font-size: 1.2rem;

                    strong {
                        font-size: 1.2rem;
                        font-weight: 600;
                    }

                    p {
                        font-size: 12px;
                        line-height: 19px;
                        text-transform: uppercase;
                    }
                }

                .title {
                    margin-bottom: 5px;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 21px;
                    letter-spacing: 0.03em;
                    text-align: left;
                }

                p {
                    color: color('black');
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 32px;
                    letter-spacing: 0.48px;
                    text-align: left;
                }

                .small {
                    font-size: 12px;
                    line-height: 19px;
                    letter-spacing: 3px;
                    text-align: left;
                    text-transform: uppercase;
                }

                .desc {
                    line-height: 26px;
                }

                .dims {
                    font-weight: 600;
                    line-height: 1.5rem;

                    label {
                        flex: 0 0 auto;
                        margin: 0;
                        width: 80px;
                        color: color('pale-sky');
                        font-weight: 400;
                    }
                }

                .config {
                    display: flex;
                    flex-direction: row;
                    justify-items: flex-start;
                    margin-top: 30px;

                    button {
                        margin: 0 10px 0 0;
                        padding: 8px 15px;
                        border: 1px solid rgba(0, 0, 0, 0.12);
                        border-radius: 20px;
                        background-color: color('white');
                        color: color('black');
                        font-size: 0.8rem;
                        letter-spacing: 0.05rem;
                        cursor: pointer;

                        &.left-hand {
                            margin: -5px -1px 0px 10px;
                            border-radius: 20px 0 0 20px;
                        }

                        &.right-hand {
                            margin: -5px 0 0 0;
                            border-radius: 0 20px 20px 0;
                        }

                        &.right {
                            margin-right: 0;
                            margin-left: auto;
                        }
                    }
                }

                .widths,
                .variants {
                    button {
                        margin: 5px 5px 5px 0;
                        padding: 5px 10px;
                        min-width: 48px;
                        font-weight: 600;
                        letter-spacing: 0.05rem
                    }
                }

                .variants {
                    button {
                        text-align: left;
                    }
                }
            }

            .actions {
                display: flex;
                margin-top: 30px;
                text-align: right;

                button {
                    margin: 0 10px;
                    padding: 10px 20px;
                    letter-spacing: 0.05rem;
                }

                button:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .desc-grid {
        display: grid;

        .desc-grid-item {
            display: flex;
            gap: 20px;
            padding-top: 10px;

            label {
                font-family: font('opensans');
                font-size: 15px;
                font-weight: 700;
                color: rgba(0, 18, 36, 0.9);
                line-height: 1.618rem;
                letter-spacing: 0;
                white-space: normal;
                word-wrap: break-word;
                width: 50%;

                @include responsive-breakpoint-down(md) {
                    line-height: normal;
                }
            }

            span {
                font-family: font('opensans');
                font-size: 15px;
                font-weight: 400;
                color: rgba(0, 18, 36, 0.9);
                line-height: 1.618rem;
                letter-spacing: 0;
                white-space: normal;
                word-wrap: break-word;
                width: 50%;
            }
        }
    }

    p {
        font-family: font('opensans');
        font-size: 16px;
        font-weight: 300;
        color: rgba(0, 18, 36, 0.9);
        line-height: clamp(1.618rem, 1vw + 1.618rem, 1.618rem);
        margin-bottom: 1.333rem;
        letter-spacing: 0;

        strong {
            font-weight: 500;
            color: #878787;
        }
    }

    @media only screen and (max-width: 1000px) {
        .products {
            margin-left: 0;
            max-width: 100%;

            .item {
                flex-wrap: nowrap;
                margin: 15px 0;
                max-width: 100%;

                .details {
                    width: auto;
                    min-width: 300px;
                }
            }
        }

        .appliance-details {
            width: 60%;

            .key-features {
                grid-template-rows: repeat(7, auto);
            }
        }
    }

    @media only screen and (max-width: 800px) {
        .top {
            flex-direction: column-reverse;
        }

        .product-image {
            width: 60% !important;
        }

        .products {
            .item {
                flex-wrap: wrap;
                justify-content: center;
                margin: 15px 0;

                .left {
                    padding: 20px 20px 0;
                    border: 0;
                    text-align: center;

                    .image {
                        img {
                            margin-bottom: 0;
                        }
                    }
                }

                .details {
                    width: 100%;

                    .price {
                        strong {
                            font-size: 1.2rem;
                        }
                    }
                }

                .actions {
                    button {
                        padding: 10px 20px;
                    }
                }
            }
        }

        .product-description {
            flex-direction: column;
            gap: 20px;

            .product-details,
            .warranty-info {
                width: 100%;
            }
        }

        .appliance-details {
            width: 60%;

            .key-features {
                grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
                grid-auto-flow: row;
            }
        }

        .warranty {
            padding-right: 0;
        }

        .price-match {
            padding-left: 0;
        }
    }

    @media only screen and (max-width: 480px) {
        .product-image {
            width: 100% !important;
        }
    }
}
