export enum StickyTabsWhyDiyKitchens {
    SELECTION = 'selection',
    TRANSPARENT = 'transparent',
    COORDINATED = 'co-ordinated',
    WIDEST = 'widest',
    INNOVATIVE = 'innovative',
    RIGID = 'rigid',
    BLUM = 'blum',
    KESSEBOHMER = 'kessebohmer',
    PAINTED = 'painted',
    DELIVERY = 'delivery'
};
