import { NgModule } from '@angular/core';

import { PaymentsService } from './payments.service';

@NgModule({
    providers: [
        PaymentsService
    ]
})
export class PaymentsServiceModule { }
