import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';
import { PipesModule } from '@app/pipes';

import { FiltersComponent } from './filters.component';

@NgModule({
    imports: [
        DesignModule,
        PipesModule
    ],
    exports: [
        FiltersComponent
    ],
    declarations: [
        FiltersComponent
    ]
})
export class FiltersComponentModule { }
