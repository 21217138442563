import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { FormValidators } from '@app/utilities/validators';

import { AuthCustomerService } from '@app/services/auth';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';

@Component({
    selector: 'page-reset-password',
    templateUrl: './reset-password.page.html',
    styleUrls: ['./reset-password.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ResetPasswordPage implements OnInit, OnDestroy {
    public resetEmailFormGroup: FormGroup;
    public resetPasswordFormGroup: FormGroup;

    public reseting: boolean = false;
    public resetToken: string = null;

    public errorMessage: string = null;
    public noticeMessage: string = null;

    private routeSubscription: Subscription;

    private routeHistory: RouteHistory[] = [
        { title: 'Account', route: '/account' },
        { title: 'Reset Password', route: '/account/reset-password' }
    ];

    constructor(
        private authCustomerService: AuthCustomerService,
        private formBuilder: FormBuilder,
        private navigationService: NavigationService
    ) {
        this.resetEmailFormGroup = this.formBuilder.group({
            email: new FormControl('', [Validators.required, FormValidators.email]),
        });

        this.resetPasswordFormGroup = this.formBuilder.group({
            password: new FormControl('', [Validators.required]),
            passwordConfirm: new FormControl('', [Validators.required])
        });
    }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                const resetToken = route.params?.resetToken || null;

                if (resetToken) {
                    this.resetToken = resetToken;
                }
            }
        );

        this.navigationService.setNavigation({
            title: 'Reset Password',
            metaTags: [{ name: 'no-meta', content: 'no metadata' }],
            routeHistory: this.routeHistory
        });
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }

    public resetEmail() {
        if (this.resetEmailFormGroup.valid) {
            this.errorMessage = null;
            this.noticeMessage = null;
            this.reseting = true;
            this.resetEmailFormGroup.disable();

            // TODO: get custom form group interface working
            const email = this.resetEmailFormGroup.controls.email['cleanValue'];
            this.authCustomerService.requestPasswordReset(email)
                .then(() => {
                    this.noticeMessage = 'If the email address exists, an email will be sent with instructions on resetting your password.';
                })
                .catch((error) => {
                    this.errorMessage = 'Something went wrong. Please try again in a moment. If the problem persists please contact support.';
                    this.reseting = false;
                    this.resetEmailFormGroup.enable();
                });
        }
    }

    public resetPassword() {
        if (
            this.resetPasswordFormGroup.valid &&
            this.resetPasswordFormGroup.controls.password.value.length > 6 &&
            this.resetPasswordFormGroup.controls.password.value === this.resetPasswordFormGroup.controls.passwordConfirm.value
        ) {
            this.errorMessage = null;
            this.noticeMessage = null;
            this.reseting = true;
            this.resetPasswordFormGroup.disable();

            this.authCustomerService.resetPassword(this.resetPasswordFormGroup.controls.password.value, this.resetToken)
                .then(() => {
                    this.noticeMessage = 'Password reset successfully. Please sign in.';
                })
                .catch((error) => {
                    this.errorMessage = 'Something went wrong. Please try again in a moment. If the problem persists please contact support.';
                    this.reseting = false;
                    this.resetPasswordFormGroup.enable();
                });
        } else {
            this.errorMessage = 'Please make sure that your passwords match each other.';
        }
    }
}
