import { Inject, Injectable, Renderer2, RendererFactory2  } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Router, NavigationEnd, NavigationExtras, Scroll, NavigationStart } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { HttpApi } from '@app/services/api';
import { Config } from '@app/config';

import {
    NavigationAppMenuItem,
    NavigationMenuItem,
    NavigationMetaData,
    NavigationOptions,
    NavigationParams,
    NavigationRoute,
    RouteHistory,
    ViewType
} from './models';

@Injectable()
export class NavigationService {
    public menuOpen = false;
    public appMenuItems: BehaviorSubject<NavigationAppMenuItem[]> = new BehaviorSubject<NavigationAppMenuItem[]>([]);
    public menuItems: BehaviorSubject<NavigationMenuItem[]> = new BehaviorSubject<NavigationMenuItem[]>([]);

    public routeHistory: BehaviorSubject<RouteHistory[]> = new BehaviorSubject<RouteHistory[]>(null);
    public route: BehaviorSubject<NavigationRoute> = new BehaviorSubject<NavigationRoute>({
        data: {},
        params: {},
        query: {},
        segments: [],
        url: ''
    });

    public viewType: BehaviorSubject<ViewType> = new BehaviorSubject<ViewType>(ViewType.PARTIAL);
    public section: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public isIframe = false;
    public iframeSrc: any = null;

    private metaData: NavigationMetaData = {};

    private renderer: Renderer2;

    constructor(
        private config: Config,
        private meta: Meta,
        private title: Title,
        private router: Router,
        private httpApi: HttpApi,
        private viewportScroller: ViewportScroller,
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.monitorRoutes();
        this.loadMetaData();
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    public setTitle(title: string) {
        this.title.setTitle(title);
    }

    public setMetaTags(metaTags: MetaDefinition[]) {
        this.meta.addTags(metaTags);
    }

    public setMetaData() {
        let url = this.route.getValue().url;

        if (this.metaData[url]) {
            let metaData = this.metaData[url];

            if (metaData.title) {
                this.setTitle(metaData.title);
            }

            if (metaData.metaTag) {
                this.setMetaTags([metaData.metaTag]);
            }
        }
    }

    /**
     * Sets the canonical link in the head of the document.
     * @param url If provided the canonical link is set to @url otherwise it is set to be self-referencial
     */    
    public setCanonical(url?: string): void {
        if (!url && !this.config.isBrowser) {
            return;
        }

        let canonicalURL = url || window.location.href;
        if (!canonicalURL.startsWith('https')) {
            canonicalURL = `https://www.diy-kitchens.com${canonicalURL}`;
        }
        let link: HTMLLinkElement = this.document.querySelector('link[rel="canonical"]');
        
        if (!link) {
            link = this.renderer.createElement('link');
            this.renderer.setAttribute(link, 'rel', 'canonical');
            this.renderer.appendChild(this.document.head, link);
        }
        
        if (!canonicalURL.match(/\/placeholder/i)) {
            this.renderer.setAttribute(link, 'href', canonicalURL);
        }
    }

    public setSection(section: string) {
        this.section.next(section);
    }

    public setNavigation(options: NavigationOptions) {
        if (options.title) {
            if (options.title.startsWith('DIY Kitchens |')) {
                this.setTitle(options.title);
            } else {
                this.setTitle(`${options.title} | DIY Kitchens`);
            }
        }

        if (options.metaTags) {
            this.setMetaTags(options.metaTags);
        }

        if (options.section) {
            this.setSection(options.section);
        } else {
            this.setSection(null);
        }

        if (options.canonical) {
            this.setCanonical(options.canonical);
        } else {
            this.setCanonical(null);
        }

        if (options.routeHistory) {
            this.setRouteHistory(options.routeHistory);
        } else {
            this.setRouteHistory([]);
        }
    }

    public setRouteHistory(routeHistory: RouteHistory[]) {
        let payload = routeHistory.slice();
        payload.unshift({ title: 'Home', route: '/' });
        this.routeHistory.next(payload);
    }

    public setAppMenu(menu: NavigationAppMenuItem[]) {
        let currentMenu = JSON.stringify(this.appMenuItems.value);

        if (currentMenu !== JSON.stringify(menu)) {
            this.appMenuItems.next(menu);
        }
    }

    public setMenu(menu: NavigationMenuItem[]) {
        let currentMenu = JSON.stringify(this.menuItems.value);

        if (currentMenu !== JSON.stringify(menu)) {
            this.menuItems.next(menu);
        }
    }

    public setView(view: ViewType) {
        this.viewType.next(view);
    }

    public navigate(route: any[], navigationExtras?: NavigationExtras) {
        this.router.navigate(route, navigationExtras);
    }

    public getRouteSegments(cutoff: string) {
        let route = this.route.getValue();
        if (route.segments.length) {
            return route.segments.slice(route.segments.indexOf(cutoff) + 1);
        }

        return [];
    }

    public toggleMenu() {
        this.menuOpen = !this.menuOpen;
    }

    public goBack() {
        let routeHistory: RouteHistory[] = this.routeHistory.getValue();

        if (routeHistory && routeHistory.length >= 2) {
            routeHistory.pop();

            let previous: RouteHistory = routeHistory.pop();

            this.navigate([previous.route]);
        }
    }

    public toggleBodyLock() {
        if (this.config.isBrowser) {
            this.document.body.classList.toggle('lock-body');
        }
    }

    private loadMetaData() {
        // this.httpApi.get('http://localhost:9491/test').subscribe(
        //     (response: any) => {
        //         if (response && response.body) {
        //             this.metaData = response.body;
        //             this.setMetaData();
        //         }
        //     },
        //     (error) => this.dialogService.error(this.constructor.name, 'Meta Data Error', error)
        // );
    }

    public setIframe(src: any) {
        if (src || src === null) {
            this.iframeSrc = src;
        } else {
            try {
                this.isIframe = (window.self !== window.top) ? true : false;
            } catch (e) { }

            if (this.isIframe) {
                this.setView(ViewType.NONE);
            } else {
                this.setView(ViewType.PARTIAL);
            }
        }
    }

    public post(payload: any, origin = '*') {
        if (this.iframeSrc) {
            this.iframeSrc.postMessage(payload, origin);
        } else {
            console.error('No IFrame Source Set');
        }
    }

    private monitorRoutes() {
        let scroll = {
            x: 0,
            y: 0
        };
        let scrollTo = false;
        let states: any = {};

        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                scrollTo = false;
                scroll.x = 0;
                scroll.y = 0;

                const navigation = this.router.getCurrentNavigation();
                if (this.config.isBrowser && navigation) {
                    if (event.restoredState && states[event.restoredState.navigationId]) {
                        scroll.x = states[event.restoredState.navigationId].x;
                        scroll.y = states[event.restoredState.navigationId].y;
                        scrollTo = true;
                    } else {
                        if (navigation.previousNavigation) {
                            states[navigation.previousNavigation.id] = {
                                x: window.scrollX,
                                y: window.scrollY
                            };
                        }
                    }

                    if (!scrollTo && navigation.extras?.state?.scroll) {
                        if (navigation.extras.state.scroll === 'none') {
                            scroll.x = window.scrollX;
                            scroll.y = window.scrollY;
                        }
                    }
                }
            }

            if (event instanceof NavigationEnd) {
                let data: NavigationParams = {};
                let params: NavigationParams = {};
                let query: NavigationParams = {};
                let segments: string[] = [];
                let snapshot = this.router.routerState.snapshot;
                let url: string = snapshot.url;
                let root = snapshot.root;

                do {
                    data = Object.assign(data, root.data);
                    params = Object.assign(params, root.params);
                    query = Object.assign(query, root.queryParams);
                    segments = segments.concat(root.url.map(segment => segment.path));
                    root = root.firstChild;
                } while (root);

                this.section.next(data.section ? data.section : null);

                let route: NavigationRoute = {
                    data,
                    params,
                    query,
                    segments,
                    url
                };

                this.route.next(route);
                this.setMetaData();
            }

            if (event instanceof Scroll) {
                this.viewportScroller.scrollToPosition([scroll.x, scroll.y]);
                if (event.anchor) {
                    this.scrollToAnchor(event.anchor, 5);
                }
            }
        });
    }

    /**
     * Scroll the page to a given anchor
     * @param elementId String without hash
     * @param retries Number of retries to attempt
     * @param retryInterval Time between retries
     */
    public scrollToAnchor(elementId: string, retries: number = 0, retryInterval: number = 10): void {
        if (this.document.getElementById(elementId)) {
            this.viewportScroller.scrollToAnchor(elementId);
        } else if (retries > 0) {
            setTimeout(() => {
                this.scrollToAnchor(elementId, retries - 1);
            }, retryInterval);
        }        
    }
}
