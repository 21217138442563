<div class="contain-config" [ngClass]="{ 'read-only': readOnly }">
    <div class="too-small">
        <p>The solid surface configuration tool is not currently available for this device.</p>
        <p>The minimum requirement is a tablet, laptop or desktop device with minimum resolution of 1024x500.</p>
    </div>

    <ng-template #summary>
        <div class="summary">
            <table>
                <tr>
                    <td colspan="6">
                        <h5>Style Information</h5>
                    </td>
                </tr>
                <tr>
                    <th>Layout:</th>
                    <th>Style:</th>
                    <th>Material:</th>
                    <th>Thickness:</th>
                    <th>Edging:</th>
                    <th>Extras:</th>
                </tr>
                <tr>
                    <td>
                        <p>{{layoutName(worktopConfig?.layout)}}</p>
                    </td>
                    <td>
                        <p>{{worktop?.sub_cat}}</p>
                    </td>
                    <td>
                        <p>{{worktop?.cat}}</p>
                    </td>
                    <td>
                        <p>{{worktop?.thickness}}mm</p>
                    </td>
                    <td>
                        <p>{{worktopConfig?.profileOption?.product_name}}</p>
                    </td>
                    <td>
                        <p>Island: {{(worktopConfig?.addIsland) ? 'Yes' : 'No'}}</p>
                        <p>Extra Piece: {{(worktopConfig?.addExtra) ? 'Yes' : 'No'}}</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <h5>Dimensions</h5>
                    </td>
                </tr>
                <tr>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>Extra</th>
                    <th>Island</th>
                </tr>
                <tr>
                    <td>
                        <p *ngIf="hasA">{{lengthWithGap(SolidSurfaceConfigPieces.A)}} x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.A].depth}}mm</p>
                        <p *ngIf="!hasA">-</p>
                    </td>
                    <td>
                        <p *ngIf="hasB">{{lengthWithGap(SolidSurfaceConfigPieces.B)}} x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.B].depth}}mm</p>
                        <p *ngIf="!hasB">-</p>
                    </td>
                    <td>
                        <p *ngIf="hasC">{{lengthWithGap(SolidSurfaceConfigPieces.C)}} x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.C].depth}}mm</p>
                        <p *ngIf="!hasC">-</p>
                    </td>
                    <td>
                        <p *ngIf="hasD">{{lengthWithGap(SolidSurfaceConfigPieces.D)}} x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.D].depth}}mm</p>
                        <p *ngIf="!hasD">-</p>
                    </td>
                    <td>
                        <p *ngIf="hasE">{{lengthWithGap(SolidSurfaceConfigPieces.E)}} x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.E].depth}}mm</p>
                        <p *ngIf="!hasE">-</p>
                    </td>
                    <td>
                        <p *ngIf="hasI">{{lengthWithGap(SolidSurfaceConfigPieces.I)}} x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.I].depth}}mm</p>
                        <p *ngIf="!hasI">-</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <h5>Shapings & Edges</h5>
                    </td>
                </tr>
                <tr>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>Extra</th>
                    <th>Island</th>
                </tr>
                <tr>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.A, SolidSurfaceConfigShapings.PROFILES)">{{shaping}}</p>
                    </td>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.B, SolidSurfaceConfigShapings.PROFILES)">{{shaping}}</p>
                    </td>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.C, SolidSurfaceConfigShapings.PROFILES)">{{shaping}}</p>
                    </td>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.D, SolidSurfaceConfigShapings.PROFILES)">{{shaping}}</p>
                    </td>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.E, SolidSurfaceConfigShapings.PROFILES)">{{shaping}}</p>
                    </td>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.I, SolidSurfaceConfigShapings.PROFILES)">{{shaping}}</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <h5>Upstands</h5>
                    </td>
                </tr>
                <tr>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>Extra</th>
                    <th>Island</th>
                </tr>
                <tr>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.A, SolidSurfaceConfigShapings.UPSTANDS)">{{shaping}}</p>
                    </td>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.B, SolidSurfaceConfigShapings.UPSTANDS)">{{shaping}}</p>
                    </td>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.C, SolidSurfaceConfigShapings.UPSTANDS)">{{shaping}}</p>
                    </td>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.D, SolidSurfaceConfigShapings.UPSTANDS)">{{shaping}}</p>
                    </td>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.E, SolidSurfaceConfigShapings.UPSTANDS)">{{shaping}}</p>
                    </td>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.I, SolidSurfaceConfigShapings.UPSTANDS)">{{shaping}}</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <h5>Downturns</h5>
                    </td>
                </tr>
                <tr>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>Extra</th>
                    <th>Island</th>
                </tr>
                <tr>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.A, SolidSurfaceConfigShapings.DOWNTURNS)">{{shaping}}</p>
                    </td>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.B, SolidSurfaceConfigShapings.DOWNTURNS)">{{shaping}}</p>
                    </td>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.C, SolidSurfaceConfigShapings.DOWNTURNS)">{{shaping}}</p>
                    </td>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.D, SolidSurfaceConfigShapings.DOWNTURNS)">{{shaping}}</p>
                    </td>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.E, SolidSurfaceConfigShapings.DOWNTURNS)">{{shaping}}</p>
                    </td>
                    <td>
                        <p *ngFor="let shaping of shapings(SolidSurfaceConfigPieces.I, SolidSurfaceConfigShapings.DOWNTURNS)">{{shaping}}</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <h5>Cutouts</h5>
                    </td>
                </tr>
                <tr>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>Extra</th>
                    <th>Island</th>
                </tr>
                <tr>
                    <td>
                        <ng-container *ngIf="hasA && worktopConfig?.pieces[SolidSurfaceConfigPieces.A].cutouts.length">
                            <p *ngFor="let cutout of worktopConfig?.pieces[SolidSurfaceConfigPieces.A].cutouts">{{cutout.product_name}}</p>
                        </ng-container>
                        <ng-container *ngIf="hasA && worktopConfig?.pieces[SolidSurfaceConfigPieces.A].gaps.length">
                            <p *ngFor="let gap of worktopConfig.pieces[SolidSurfaceConfigPieces.A].gaps">{{ gap }}mm Range cooker</p>
                        </ng-container>
                        <p *ngIf="!hasA || (!worktopConfig?.pieces[SolidSurfaceConfigPieces.A].cutouts.length && !worktopConfig?.pieces[SolidSurfaceConfigPieces.A].gaps.length)">-</p>
                    </td>
                    <td>
                        <ng-container *ngIf="hasB && worktopConfig?.pieces[SolidSurfaceConfigPieces.B].cutouts.length">
                            <p *ngFor="let cutout of worktopConfig?.pieces[SolidSurfaceConfigPieces.B].cutouts">{{cutout.product_name}}</p>
                        </ng-container>
                        <ng-container *ngIf="hasB && worktopConfig?.pieces[SolidSurfaceConfigPieces.B].gaps.length">
                            <p *ngFor="let gap of worktopConfig.pieces[SolidSurfaceConfigPieces.B].gaps">{{ gap }}mm Range cooker</p>
                        </ng-container>
                        <p *ngIf="!hasB || (!worktopConfig?.pieces[SolidSurfaceConfigPieces.B].cutouts.length && !worktopConfig?.pieces[SolidSurfaceConfigPieces.B].gaps.length)">-</p>
                    </td>
                    <td>
                        <ng-container *ngIf="hasC && worktopConfig?.pieces[SolidSurfaceConfigPieces.C].cutouts.length">
                            <p *ngFor="let cutout of worktopConfig?.pieces[SolidSurfaceConfigPieces.C].cutouts">{{cutout.product_name}}</p>
                        </ng-container>
                        <ng-container *ngIf="hasC && worktopConfig?.pieces[SolidSurfaceConfigPieces.C].gaps.length">
                            <p *ngFor="let gap of worktopConfig.pieces[SolidSurfaceConfigPieces.C].gaps">{{ gap }}mm Range cooker</p>
                        </ng-container>
                        <p *ngIf="!hasC || (!worktopConfig?.pieces[SolidSurfaceConfigPieces.C].cutouts.length && !worktopConfig?.pieces[SolidSurfaceConfigPieces.C].gaps.length)">-</p>
                    </td>
                    <td>
                        <ng-container *ngIf="hasD && worktopConfig?.pieces[SolidSurfaceConfigPieces.D].cutouts.length">
                            <p *ngFor="let cutout of worktopConfig?.pieces[SolidSurfaceConfigPieces.D].cutouts">{{cutout.product_name}}</p>
                        </ng-container>
                        <ng-container *ngIf="hasD && worktopConfig?.pieces[SolidSurfaceConfigPieces.D].gaps.length">
                            <p *ngFor="let gap of worktopConfig.pieces[SolidSurfaceConfigPieces.D].gaps">{{ gap }}mm Range cooker</p>
                        </ng-container>
                        <p *ngIf="!hasD || (!worktopConfig?.pieces[SolidSurfaceConfigPieces.D].cutouts.length && !worktopConfig?.pieces[SolidSurfaceConfigPieces.D].gaps.length)">-</p>
                    </td>
                    <td>
                        <ng-container *ngIf="hasE && worktopConfig?.pieces[SolidSurfaceConfigPieces.E].cutouts.length">
                            <p *ngFor="let cutout of worktopConfig?.pieces[SolidSurfaceConfigPieces.E].cutouts">{{cutout.product_name}}</p>
                        </ng-container>
                        <ng-container *ngIf="hasE && worktopConfig?.pieces[SolidSurfaceConfigPieces.E].gaps.length">
                            <p *ngFor="let gap of worktopConfig.pieces[SolidSurfaceConfigPieces.E].gaps">{{ gap }}mm Range cooker</p>
                        </ng-container>
                        <p *ngIf="!hasE || (!worktopConfig?.pieces[SolidSurfaceConfigPieces.E].cutouts.length && !worktopConfig?.pieces[SolidSurfaceConfigPieces.E].gaps.length)">-</p>
                    </td>
                    <td>
                        <ng-container *ngIf="hasI && worktopConfig?.pieces[SolidSurfaceConfigPieces.I].cutouts.length">
                            <p *ngFor="let cutout of worktopConfig?.pieces[SolidSurfaceConfigPieces.I].cutouts">{{cutout.product_name}}</p>
                        </ng-container>
                        <ng-container *ngIf="hasI && worktopConfig?.pieces[SolidSurfaceConfigPieces.I].gaps.length">
                            <p *ngFor="let gap of worktopConfig.pieces[SolidSurfaceConfigPieces.I].gaps">{{ gap }}mm Range cooker</p>
                        </ng-container>
                        <p *ngIf="!hasI || (!worktopConfig?.pieces[SolidSurfaceConfigPieces.I].cutouts.length && !worktopConfig?.pieces[SolidSurfaceConfigPieces.I].gaps.length)">-</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <h5>Splashbacks & Cills</h5>
                    </td>
                </tr>
                <tr>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>Extra</th>
                    <th>Island</th>
                </tr>
                <tr>
                    <td>
                        <ng-container *ngIf="hasA && worktopConfig?.pieces[SolidSurfaceConfigPieces.A].splashback.length">
                            <p><strong>Splashback</strong></p>
                            <p>{{worktopConfig?.pieces[SolidSurfaceConfigPieces.A].splashback.length}}mm x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.A].splashback.height}}mm</p>
                        </ng-container>
                        <ng-container *ngIf="hasA && worktopConfig?.pieces[SolidSurfaceConfigPieces.A].cill.length">
                            <p><strong>Cill</strong></p>
                            <p>{{worktopConfig?.pieces[SolidSurfaceConfigPieces.A].cill.length}}mm x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.A].cill.height}}mm</p>
                        </ng-container>
                        <p *ngIf="!hasA || (!worktopConfig?.pieces[SolidSurfaceConfigPieces.A].splashback.length && !worktopConfig?.pieces[SolidSurfaceConfigPieces.A].cill.length)">-</p>
                    </td>
                    <td>
                        <ng-container *ngIf="hasB && worktopConfig?.pieces[SolidSurfaceConfigPieces.B].splashback.length">
                            <p><strong>Splashback</strong></p>
                            <p>{{worktopConfig?.pieces[SolidSurfaceConfigPieces.B].splashback.length}}mm x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.B].splashback.height}}mm</p>
                        </ng-container>
                        <ng-container *ngIf="hasB && worktopConfig?.pieces[SolidSurfaceConfigPieces.B].cill.length">
                            <p><strong>Cill</strong></p>
                            <p>{{worktopConfig?.pieces[SolidSurfaceConfigPieces.B].cill.length}}mm x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.B].cill.height}}mm</p>
                        </ng-container>
                        <p *ngIf="!hasB || (!worktopConfig?.pieces[SolidSurfaceConfigPieces.B].splashback.length && !worktopConfig?.pieces[SolidSurfaceConfigPieces.B].cill.length)">-</p>
                    </td>
                    <td>
                        <ng-container *ngIf="hasC && worktopConfig?.pieces[SolidSurfaceConfigPieces.C].splashback.length">
                            <p><strong>Splashback</strong></p>
                            <p>{{worktopConfig?.pieces[SolidSurfaceConfigPieces.C].splashback.length}}mm x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.C].splashback.height}}mm</p>
                        </ng-container>
                        <ng-container *ngIf="hasC && worktopConfig?.pieces[SolidSurfaceConfigPieces.C].cill.length">
                            <p><strong>Cill</strong></p>
                            <p>{{worktopConfig?.pieces[SolidSurfaceConfigPieces.C].cill.length}}mm x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.C].cill.height}}mm</p>
                        </ng-container>
                        <p *ngIf="!hasC || (!worktopConfig?.pieces[SolidSurfaceConfigPieces.C].splashback.length && !worktopConfig?.pieces[SolidSurfaceConfigPieces.C].cill.length)">-</p>
                    </td>
                    <td>
                        <ng-container *ngIf="hasD && worktopConfig?.pieces[SolidSurfaceConfigPieces.D].splashback.length">
                            <p><strong>Splashback</strong></p>
                            <p>{{worktopConfig?.pieces[SolidSurfaceConfigPieces.D].splashback.length}}mm x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.D].splashback.height}}mm</p>
                        </ng-container>
                        <ng-container *ngIf="hasD && worktopConfig?.pieces[SolidSurfaceConfigPieces.D].cill.length">
                            <p><strong>Cill</strong></p>
                            <p>{{worktopConfig?.pieces[SolidSurfaceConfigPieces.D].cill.length}}mm x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.D].cill.height}}mm</p>
                        </ng-container>
                        <p *ngIf="!hasD || (!worktopConfig?.pieces[SolidSurfaceConfigPieces.D].splashback.length && !worktopConfig?.pieces[SolidSurfaceConfigPieces.D].cill.length)">-</p>
                    </td>
                    <td>
                        <ng-container *ngIf="hasE && worktopConfig?.pieces[SolidSurfaceConfigPieces.E].splashback.length">
                            <p><strong>Splashback</strong></p>
                            <p>{{worktopConfig?.pieces[SolidSurfaceConfigPieces.E].splashback.length}}mm x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.E].splashback.height}}mm</p>
                        </ng-container>
                        <ng-container *ngIf="hasE && worktopConfig?.pieces[SolidSurfaceConfigPieces.E].cill.length">
                            <p><strong>Cill</strong></p>
                            <p>{{worktopConfig?.pieces[SolidSurfaceConfigPieces.E].cill.length}}mm x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.E].cill.height}}mm</p>
                        </ng-container>
                        <p *ngIf="!hasE || (!worktopConfig?.pieces[SolidSurfaceConfigPieces.E].splashback.length && !worktopConfig?.pieces[SolidSurfaceConfigPieces.E].cill.length)">-</p>
                    </td>
                    <td>
                        <ng-container *ngIf="hasI && worktopConfig?.pieces[SolidSurfaceConfigPieces.I].splashback.length">
                            <p><strong>Splashback</strong></p>
                            <p>{{worktopConfig?.pieces[SolidSurfaceConfigPieces.I].splashback.length}}mm x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.I].splashback.height}}mm</p>
                        </ng-container>
                        <ng-container *ngIf="hasI && worktopConfig?.pieces[SolidSurfaceConfigPieces.I].cill.length">
                            <p><strong>Cill</strong></p>
                            <p>{{worktopConfig?.pieces[SolidSurfaceConfigPieces.I].cill.length}}mm x {{worktopConfig?.pieces[SolidSurfaceConfigPieces.I].cill.height}}mm</p>
                        </ng-container>
                        <p *ngIf="!hasI || (!worktopConfig?.pieces[SolidSurfaceConfigPieces.I].splashback.length && !worktopConfig?.pieces[SolidSurfaceConfigPieces.I].cill.length)">-</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <h5>Costs</h5>
                    </td>
                </tr>
                <tr>
                    <td colspan="5"><p class="costs"><strong>Worktops</strong></p></td>
                    <td><p class="costs" [innerHTML]="prices.sqrMtrCost | currencyFormat:false:true:false:true"></p></td>
                </tr>
                <tr>
                    <td colspan="5"><p class="costs"><strong>Edge Profiling</strong></p></td>
                    <td><p class="costs" [innerHTML]="prices.profilesCost | currencyFormat:false:true:false:true"></p></td>
                </tr>
                <tr>
                    <td colspan="5"><p class="costs"><strong>Corner Shaping</strong></p></td>
                    <td><p class="costs" [innerHTML]="prices.cornersCost | currencyFormat:false:true:false:true"></p></td>
                </tr>
                <tr>
                    <td colspan="5"><p class="costs"><strong>Cut Outs</strong></p></td>
                    <td><p class="costs" [innerHTML]="prices.cutoutsCost | currencyFormat:false:true:false:true"></p></td>
                </tr>
                <tr *ngIf="prices.downturnsCost > 0">
                    <td colspan="5"><p class="costs"><strong>Downturns</strong></p></td>
                    <td><p class="costs" [innerHTML]="prices.downturnsCost | currencyFormat:false:true:false:true"></p></td>
                </tr>
                <tr *ngIf="prices.upstandsCost > 0">
                    <td colspan="5"><p class="costs"><strong>Upstands</strong></p></td>
                    <td><p class="costs" [innerHTML]="prices.upstandsCost | currencyFormat:false:true:false:true"></p></td>
                </tr>
                <tr *ngIf="prices.splashbacksCost > 0">
                    <td colspan="5"><p class="costs"><strong>Splashbacks</strong></p></td>
                    <td><p class="costs" [innerHTML]="prices.splashbacksCost | currencyFormat:false:true:false:true"></p></td>
                </tr>
                <tr *ngIf="prices.cillsCost > 0">
                    <td colspan="5"><p class="costs"><strong>Cills</strong></p></td>
                    <td><p class="costs" [innerHTML]="prices.cillsCost | currencyFormat:false:true:false:true"></p></td>
                </tr>
                <tr>
                    <td colspan="5"><p class="costs"><strong>Laser Templating & Installation</strong></p></td>
                    <td><p class="costs" [innerHTML]="prices.installCost | currencyFormat:false:true:false:true"></p></td>
                </tr>
                <tr>
                    <td colspan="5"><p class="costs"><strong>Total</strong></p></td>
                    <td><p class="costs" [innerHTML]="prices.total | currencyFormat:true:true"></p></td>
                </tr>
            </table>
        </div>
    </ng-template>

    <div class="big-enough" *ngIf="worktopConfig && !readOnly">
        <div class="left-panel">
            <mat-vertical-stepper class="tabs" linear #stepper animationDuration="0" [selectedIndex]="stepIndex" (selectionChange)="gotoTab($event)">
                <mat-step [stepControl]="layoutFormGroup">
                    <ng-template matStepLabel>Choose Layout</ng-template>

                    <div class="layouts">
                        <button mat-flat-button color="primary" class="button-layout" [ngClass]="{ selected: (worktopConfig.layout === SolidSurfaceConfigLayout.SINGLE) }" (click)="setLayout(SolidSurfaceConfigLayout.SINGLE)">
                            <span>{{layoutName(SolidSurfaceConfigLayout.SINGLE)}} Worktop</span>
                            <img src="https://static.diy-kitchens.com/assets/images/graniteworktops/single-layout-icon.png" alt="Granite Worktops Single Layout"/>
                        </button>
                        <button mat-flat-button color="primary" class="button-layout" [ngClass]="{ selected: (worktopConfig.layout === SolidSurfaceConfigLayout.GALLEY) }" (click)="setLayout(SolidSurfaceConfigLayout.GALLEY)">
                            <span>{{layoutName(SolidSurfaceConfigLayout.GALLEY)}} Worktop</span>
                            <img src="https://static.diy-kitchens.com/assets/images/graniteworktops/galley-layout-icon.png" alt="Granite Worktops Galley Layout"/>
                        </button>
                        <button mat-flat-button color="primary" class="button-layout" [ngClass]="{ selected: (worktopConfig.layout === SolidSurfaceConfigLayout.L_SHAPED) }" (click)="setLayout(SolidSurfaceConfigLayout.L_SHAPED)">
                            <span>{{layoutName(SolidSurfaceConfigLayout.L_SHAPED)}} Worktop</span>
                            <img src="https://static.diy-kitchens.com/assets/images/graniteworktops/lshaped-layout-icon.png" alt="Granite Worktops L-Shaped Layout"/>
                        </button>
                        <button mat-flat-button color="primary" class="button-layout" [ngClass]="{ selected: (worktopConfig.layout === SolidSurfaceConfigLayout.U_SHAPED) }" (click)="setLayout(SolidSurfaceConfigLayout.U_SHAPED)">
                            <span>{{layoutName(SolidSurfaceConfigLayout.U_SHAPED)}} Worktop</span>
                            <img src="https://static.diy-kitchens.com/assets/images/graniteworktops/ushaped-layout-icon.png" alt="Granite Worktops U-Shaped Layout"/>
                        </button>
                        <button mat-flat-button color="primary" class="button-layout" [ngClass]="{ selected: (worktopConfig.layout === SolidSurfaceConfigLayout.G_SHAPED) }" (click)="setLayout(SolidSurfaceConfigLayout.G_SHAPED)">
                            <span>{{layoutName(SolidSurfaceConfigLayout.G_SHAPED)}} Worktop</span>
                            <img src="https://static.diy-kitchens.com/assets/images/graniteworktops/gshaped-layout-icon.png" alt="Granite Worktops G-Shaped Layout"/>
                        </button>
                        <button mat-flat-button color="primary" class="button-layout layout-island" [ngClass]="{ selected: (worktopConfig.layout === SolidSurfaceConfigLayout.ISLAND) }" (click)="setLayout(SolidSurfaceConfigLayout.ISLAND)">
                            <span>{{layoutName(SolidSurfaceConfigLayout.ISLAND)}}</span>
                            <img src="https://static.diy-kitchens.com/assets/images/graniteworktops/single-layout-icon.png" alt="Granite Worktops Single Layout Icon" />
                        </button>
                    </div>
                </mat-step>
                <mat-step [stepControl]="dimensionsFormGroup">
                    <ng-template matStepLabel>Configure Dimensions</ng-template>

                    <div *ngIf="hasA">
                        <p>Piece A</p>
                        <mat-form-field appearance="outline" [class.invalid]="!worktopConfig.pieces[SolidSurfaceConfigPieces.A].isValidLength">
                            <mat-label>Length</mat-label>
                            <input matInput placeholder="Length" [(ngModel)]="worktopConfig.pieces[SolidSurfaceConfigPieces.A].length" (ngModelChange)="update('piece a - length')" />
                        </mat-form-field>

                        <mat-form-field appearance="outline" [class.invalid]="!worktopConfig.pieces[SolidSurfaceConfigPieces.A].isValidDepth">
                            <mat-label>Depth</mat-label>
                            <input matInput placeholder="Depth" [(ngModel)]="worktopConfig.pieces[SolidSurfaceConfigPieces.A].depth" (ngModelChange)="update('piece a - depth')" />
                        </mat-form-field>
                    </div>
                    <div *ngIf="hasB">
                        <p>Piece B</p>
                        <mat-form-field appearance="outline" [class.invalid]="!worktopConfig.pieces[SolidSurfaceConfigPieces.B].isValidLength">
                            <mat-label>Length</mat-label>
                            <input matInput placeholder="Length" [(ngModel)]="worktopConfig.pieces[SolidSurfaceConfigPieces.B].length" (ngModelChange)="update('piece b - length')" />
                        </mat-form-field>

                        <mat-form-field appearance="outline" [class.invalid]="!worktopConfig.pieces[SolidSurfaceConfigPieces.B].isValidDepth">
                            <mat-label>Depth</mat-label>
                            <input matInput placeholder="Depth" [(ngModel)]="worktopConfig.pieces[SolidSurfaceConfigPieces.B].depth" (ngModelChange)="update('piece b - depth')" />
                        </mat-form-field>
                    </div>
                    <div *ngIf="hasC">
                        <p>Piece C</p>
                        <mat-form-field appearance="outline" [class.invalid]="!worktopConfig.pieces[SolidSurfaceConfigPieces.C].isValidLength">
                            <mat-label>Length</mat-label>
                            <input matInput placeholder="Length" [(ngModel)]="worktopConfig.pieces[SolidSurfaceConfigPieces.C].length" (ngModelChange)="update('piece c - length')" />
                        </mat-form-field>

                        <mat-form-field appearance="outline" [class.invalid]="!worktopConfig.pieces[SolidSurfaceConfigPieces.C].isValidDepth">
                            <mat-label>Depth</mat-label>
                            <input matInput placeholder="Depth" [(ngModel)]="worktopConfig.pieces[SolidSurfaceConfigPieces.C].depth" (ngModelChange)="update('piece c - depth')" />
                        </mat-form-field>
                    </div>
                    <div *ngIf="hasD">
                        <p>Piece D</p>
                        <mat-form-field appearance="outline" [class.invalid]="!worktopConfig.pieces[SolidSurfaceConfigPieces.D].isValidLength">
                            <mat-label>Length</mat-label>
                            <input matInput placeholder="Length" [(ngModel)]="worktopConfig.pieces[SolidSurfaceConfigPieces.D].length" (ngModelChange)="update('piece d - length')" />
                        </mat-form-field>

                        <mat-form-field appearance="outline" [class.invalid]="!worktopConfig.pieces[SolidSurfaceConfigPieces.D].isValidDepth">
                            <mat-label>Depth</mat-label>
                            <input matInput placeholder="Depth" [(ngModel)]="worktopConfig.pieces[SolidSurfaceConfigPieces.D].depth" (ngModelChange)="update('piece d - depth')" />
                        </mat-form-field>
                    </div>
                    <div *ngIf="hasI">
                        <p>Island Piece</p>
                        <mat-form-field appearance="outline" [class.invalid]="!worktopConfig.pieces[SolidSurfaceConfigPieces.I].isValidLength">
                            <mat-label>Length</mat-label>
                            <input matInput placeholder="Length" [(ngModel)]="worktopConfig.pieces[SolidSurfaceConfigPieces.I].length" (ngModelChange)="update('piece i - length')" />
                        </mat-form-field>

                        <mat-form-field appearance="outline" [class.invalid]="!worktopConfig.pieces[SolidSurfaceConfigPieces.I].isValidDepth">
                            <mat-label>Depth</mat-label>
                            <input matInput placeholder="Depth" [(ngModel)]="worktopConfig.pieces[SolidSurfaceConfigPieces.I].depth" (ngModelChange)="update('piece i - depth')" />
                        </mat-form-field>
                    </div>
                    <div *ngIf="hasE">
                        <p>Extra Piece</p>
                        <mat-form-field appearance="outline" [class.invalid]="!worktopConfig.pieces[SolidSurfaceConfigPieces.E].isValidLength">
                            <mat-label>Length</mat-label>
                            <input matInput placeholder="Length" [(ngModel)]="worktopConfig.pieces[SolidSurfaceConfigPieces.E].length" (ngModelChange)="update('piece e - length')" />
                        </mat-form-field>

                        <mat-form-field appearance="outline" [class.invalid]="!worktopConfig.pieces[SolidSurfaceConfigPieces.E].isValidDepth">
                            <mat-label>Depth</mat-label>
                            <input matInput placeholder="Depth" [(ngModel)]="worktopConfig.pieces[SolidSurfaceConfigPieces.E].depth" (ngModelChange)="update('piece e - depth')" />
                        </mat-form-field>
                    </div>
                </mat-step>
                <mat-step [stepControl]="edgesShapesUpstandsFormGroup">
                    <ng-template matStepLabel>Edges, Shapes & Upstands</ng-template>

                    <div class="sub-section-header" [ngClass]="{ selected: (sub3 === SolidSurfaceConfigProfiles.PROFILED) }" (click)="t3sub(SolidSurfaceConfigProfiles.PROFILED)">
                        <p>Profiled edges</p>
                        <mat-icon *ngIf="sub3 !== SolidSurfaceConfigProfiles.PROFILED">arrow_drop_down</mat-icon>
                        <mat-icon *ngIf="sub3 === SolidSurfaceConfigProfiles.PROFILED">arrow_drop_up</mat-icon>
                    </div>
                    <div class="sub-section" *ngIf="worktopConfig.profileOption" [ngClass]="sub3 === SolidSurfaceConfigProfiles.PROFILED ? 'open' : ''">
                        <p>Tick all worktop edges that face into your kitchen.</p>
                        <p class="center">
                            Profile style: <strong>{{ worktopConfig.profileOption.product_name }}</strong><br />

                            <img src="https://static.diy-kitchens.com/worktops/icons/{{worktopConfig.profileOption.img}}" class="profile-img" [alt]="worktopConfig.profileOption.product_name"/>
                            <button mat-flat-button color="primary" (click)="setProfileOption()">
                                Change profile style
                            </button>
                        </p>
                    </div>

                    <div class="sub-section-header" [ngClass]="{ selected: (sub3 === SolidSurfaceConfigProfiles.UPSTAND) }" (click)="t3sub(SolidSurfaceConfigProfiles.UPSTAND)">
                        <p>Upstands</p>
                        <mat-icon *ngIf="sub3 !== SolidSurfaceConfigProfiles.UPSTAND">arrow_drop_down</mat-icon>
                        <mat-icon *ngIf="sub3 === SolidSurfaceConfigProfiles.UPSTAND">arrow_drop_up</mat-icon>
                    </div>
                    <div class="sub-section" [ngClass]="sub3 === SolidSurfaceConfigProfiles.UPSTAND ? 'open' : ''">
                        <p>Add optional upstands to edges that are against a wall.</p>
                        <p>
                            Upstand height:
                        </p>
                        <div class="radios">
                            <div class="radio" (click)="setUpstandHeight(75)">
                                <mat-icon *ngIf="worktopConfig.upstandHeight === 75">radio_button_checked</mat-icon>
                                <mat-icon *ngIf="worktopConfig.upstandHeight !== 75">radio_button_unchecked</mat-icon>
                                75mm
                            </div>
                            <div class="radio" (click)="setUpstandHeight(100)">
                                <mat-icon *ngIf="worktopConfig.upstandHeight === 100">radio_button_checked</mat-icon>
                                <mat-icon *ngIf="worktopConfig.upstandHeight !== 100">radio_button_unchecked</mat-icon>
                                100mm
                            </div>
                            <div class="radio" (click)="setUpstandHeight(150)">
                                <mat-icon *ngIf="worktopConfig.upstandHeight === 150">radio_button_checked</mat-icon>
                                <mat-icon *ngIf="worktopConfig.upstandHeight !== 150">radio_button_unchecked</mat-icon>
                                150mm
                            </div>
                            <div class="radio" (click)="setUpstandHeight(200)">
                                <mat-icon *ngIf="worktopConfig.upstandHeight === 200">radio_button_checked</mat-icon>
                                <mat-icon *ngIf="worktopConfig.upstandHeight !== 200">radio_button_unchecked</mat-icon>
                                200mm
                            </div>
                        </div>
                    </div>

                    <div class="sub-section-header" [ngClass]="{ selected: (sub3 === SolidSurfaceConfigProfiles.DOWNTURN) }" (click)="t3sub(SolidSurfaceConfigProfiles.DOWNTURN)">
                        <p>Downturns</p>
                        <mat-icon *ngIf="sub3 !== SolidSurfaceConfigProfiles.DOWNTURN">arrow_drop_down</mat-icon>
                        <mat-icon *ngIf="sub3 === SolidSurfaceConfigProfiles.DOWNTURN">arrow_drop_up</mat-icon>
                    </div>
                    <div class="sub-section" [ngClass]="sub3 === SolidSurfaceConfigProfiles.DOWNTURN ? 'open' : ''">
                        <p>Add optional downturns by clicking on the desired edges.</p>
                    </div>

                    <div class="sub-section-header" [ngClass]="{ selected: (sub3 === SolidSurfaceConfigProfiles.CORNER) }" (click)="t3sub(SolidSurfaceConfigProfiles.CORNER)">
                        <p>Corner shapes</p>
                        <mat-icon *ngIf="sub3 !== SolidSurfaceConfigProfiles.CORNER">arrow_drop_down</mat-icon>
                        <mat-icon *ngIf="sub3 === SolidSurfaceConfigProfiles.CORNER">arrow_drop_up</mat-icon>
                    </div>
                    <div class="sub-section" [ngClass]="sub3 === SolidSurfaceConfigProfiles.CORNER ? 'open' : ''">
                        <p>Change corner shape options.</p>
                        <p class="note">Only corners that are profiled can be shaped.</p>
                    </div>
                </mat-step>
                <mat-step [stepControl]="appliancesSinksFormGroup">
                    <ng-template matStepLabel>Appliances & Sinks</ng-template>

                    <div *ngIf="hasA" class="options">
                        <div class="option" (click)="addCutout(SolidSurfaceConfigPieces.A)">
                            <mat-icon class="cutout-add">add_circle_outline</mat-icon>
                            <h5>Piece A</h5>
                        </div>
                        <div class="option" *ngFor="let opt of worktopConfig.pieces[SolidSurfaceConfigPieces.A].cutouts" (click)="removeCutout(SolidSurfaceConfigPieces.A, opt)">
                            <mat-icon class="cutout-remove">remove_circle_outline</mat-icon>
                            <p>{{ cutoutTitle(opt) }}</p>
                        </div>
                        <div class="option" *ngFor="let gap of worktopConfig.pieces[SolidSurfaceConfigPieces.A].gaps" (click)="removeGap(SolidSurfaceConfigPieces.A, gap)">
                            <mat-icon class="cutout-remove">remove_circle_outline</mat-icon>
                            <p>{{ gap }}mm Range cooker</p>
                        </div>
                    </div>
                    <div *ngIf="hasB" class="options">
                        <div class="option" (click)="addCutout(SolidSurfaceConfigPieces.B)">
                            <mat-icon class="cutout-add">add_circle_outline</mat-icon>
                            <h5>Piece B</h5>
                        </div>
                        <div class="option" *ngFor="let opt of worktopConfig.pieces[SolidSurfaceConfigPieces.B].cutouts" (click)="removeCutout(SolidSurfaceConfigPieces.B, opt)">
                            <mat-icon class="cutout-remove">remove_circle_outline</mat-icon>
                            <p>{{ cutoutTitle(opt) }}</p>
                        </div>
                        <div class="option" *ngFor="let gap of worktopConfig.pieces[SolidSurfaceConfigPieces.B].gaps" (click)="removeGap(SolidSurfaceConfigPieces.B, gap)">
                            <mat-icon class="cutout-remove">remove_circle_outline</mat-icon>
                            <p>{{ gap }}mm Range cooker</p>
                        </div>
                    </div>
                    <div *ngIf="hasC" class="options">
                        <div class="option" (click)="addCutout(SolidSurfaceConfigPieces.C)">
                            <mat-icon class="cutout-add">add_circle_outline</mat-icon>
                            <h5>Piece C</h5>
                        </div>
                        <div class="option" *ngFor="let opt of worktopConfig.pieces[SolidSurfaceConfigPieces.C].cutouts" (click)="removeCutout(SolidSurfaceConfigPieces.C, opt)">
                            <mat-icon class="cutout-remove">remove_circle_outline</mat-icon>
                            <p>{{ cutoutTitle(opt) }}</p>
                        </div>
                        <div class="option" *ngFor="let gap of worktopConfig.pieces[SolidSurfaceConfigPieces.C].gaps" (click)="removeGap(SolidSurfaceConfigPieces.C, gap)">
                            <mat-icon class="cutout-remove">remove_circle_outline</mat-icon>
                            <p>{{ gap }}mm Range cooker</p>
                        </div>
                    </div>
                    <div *ngIf="hasD" class="options">
                        <div class="option" (click)="addCutout(SolidSurfaceConfigPieces.D)">
                            <mat-icon class="cutout-add">add_circle_outline</mat-icon>
                            <h5>Piece D</h5>
                        </div>
                        <div class="option" *ngFor="let opt of worktopConfig.pieces[SolidSurfaceConfigPieces.D].cutouts" (click)="removeCutout(SolidSurfaceConfigPieces.D, opt)">
                            <mat-icon class="cutout-remove">remove_circle_outline</mat-icon>
                            <p>{{ cutoutTitle(opt) }}</p>
                        </div>
                        <div class="option" *ngFor="let gap of worktopConfig.pieces[SolidSurfaceConfigPieces.D].gaps" (click)="removeGap(SolidSurfaceConfigPieces.D, gap)">
                            <mat-icon class="cutout-remove">remove_circle_outline</mat-icon>
                            <p>{{ gap }}mm Range cooker</p>
                        </div>
                    </div>
                    <div *ngIf="hasI" class="options">
                        <div class="option" (click)="addCutout(SolidSurfaceConfigPieces.I)">
                            <mat-icon class="cutout-add">add_circle_outline</mat-icon>
                            <h5>Island</h5>
                        </div>
                        <div class="option" *ngFor="let opt of worktopConfig.pieces[SolidSurfaceConfigPieces.I].cutouts" (click)="removeCutout(SolidSurfaceConfigPieces.I, opt)">
                            <mat-icon class="cutout-remove">remove_circle_outline</mat-icon>
                            <p>{{ cutoutTitle(opt) }}</p>
                        </div>
                        <div class="option" *ngFor="let gap of worktopConfig.pieces[SolidSurfaceConfigPieces.I].gaps" (click)="removeGap(SolidSurfaceConfigPieces.I, gap)">
                            <mat-icon class="cutout-remove">remove_circle_outline</mat-icon>
                            <p>{{ gap }}mm Range cooker</p>
                        </div>
                    </div>
                    <div *ngIf="hasE" class="options">
                        <div class="option" (click)="addCutout(SolidSurfaceConfigPieces.E)">
                            <mat-icon class="cutout-add">add_circle_outline</mat-icon>
                            <h5>Extra Piece</h5>
                        </div>
                        <div class="option" *ngFor="let opt of worktopConfig.pieces[SolidSurfaceConfigPieces.E].cutouts" (click)="removeCutout(SolidSurfaceConfigPieces.E, opt)">
                            <mat-icon class="cutout-remove">remove_circle_outline</mat-icon>
                            <p>{{ cutoutTitle(opt) }}</p>
                        </div>
                        <div class="option" *ngFor="let gap of worktopConfig.pieces[SolidSurfaceConfigPieces.E].gaps" (click)="removeGap(SolidSurfaceConfigPieces.E, gap)">
                            <mat-icon class="cutout-remove">remove_circle_outline</mat-icon>
                            <p>{{ gap }}mm Range cooker</p>
                        </div>
                    </div>
                </mat-step>
                <mat-step [stepControl]="splashbacksCillsFormGroup">
                    <ng-template matStepLabel>Splashbacks & Cills</ng-template>

                    <div *ngIf="hasA" class="options">
                        <div class="option" (click)="addSplashbackCill(SolidSurfaceConfigPieces.A)">
                            <mat-icon class="splashback-cill-add" *ngIf="!hasSplashbackCill(SolidSurfaceConfigPieces.A)">add_circle_outline</mat-icon>
                            <mat-icon class="splashback-cill-edit" *ngIf="hasSplashbackCill(SolidSurfaceConfigPieces.A)">edit</mat-icon>
                            <h5>Piece A</h5>
                        </div>
                        <div class="option" *ngIf="worktopConfig.pieces[SolidSurfaceConfigPieces.A].splashback.length">
                            <mat-icon class="splashback-cill-remove" (click)="removeSplashback(SolidSurfaceConfigPieces.A)">remove_circle_outline</mat-icon>
                            <div class="option-text">
                                <h6>Splashback</h6>
                                <p>{{ worktopConfig.pieces[SolidSurfaceConfigPieces.A].splashback.length }}mm x {{ worktopConfig.pieces[SolidSurfaceConfigPieces.A].splashback.height }}mm</p>
                            </div>
                        </div>
                        <div class="option" *ngIf="worktopConfig.pieces[SolidSurfaceConfigPieces.A].cill.length">
                            <mat-icon class="splashback-cill-remove" (click)="removeCill(SolidSurfaceConfigPieces.A)">remove_circle_outline</mat-icon>
                            <div class="option-text">
                                <h6>Cill</h6>
                                <p>{{ worktopConfig.pieces[SolidSurfaceConfigPieces.A].cill.length }}mm x {{ worktopConfig.pieces[SolidSurfaceConfigPieces.A].cill.height }}mm</p>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="hasB" class="options">
                        <div class="option" (click)="addSplashbackCill(SolidSurfaceConfigPieces.B)">
                            <mat-icon class="splashback-cill-add" *ngIf="!hasSplashbackCill(SolidSurfaceConfigPieces.B)">add_circle_outline</mat-icon>
                            <mat-icon class="splashback-cill-edit" *ngIf="hasSplashbackCill(SolidSurfaceConfigPieces.B)">edit</mat-icon>
                            <h5>Piece B</h5>
                        </div>
                        <div class="option" *ngIf="worktopConfig.pieces[SolidSurfaceConfigPieces.B].splashback.length">
                            <mat-icon class="splashback-cill-remove" (click)="removeSplashback(SolidSurfaceConfigPieces.B)">remove_circle_outline</mat-icon>
                            <div class="option-text">
                                <h6>Splashback</h6>
                                <p>{{ worktopConfig.pieces[SolidSurfaceConfigPieces.B].splashback.length }}mm x {{ worktopConfig.pieces[SolidSurfaceConfigPieces.B].splashback.height }}mm</p>
                            </div>
                        </div>
                        <div class="option" *ngIf="worktopConfig.pieces[SolidSurfaceConfigPieces.B].cill.length">
                            <mat-icon class="splashback-cill-remove" (click)="removeCill(SolidSurfaceConfigPieces.B)">remove_circle_outline</mat-icon>
                            <div class="option-text">
                                <h6>Cill</h6>
                                <p>{{ worktopConfig.pieces[SolidSurfaceConfigPieces.B].cill.length }}mm x {{ worktopConfig.pieces[SolidSurfaceConfigPieces.B].cill.height }}mm</p>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="hasC" class="options">
                        <div class="option" (click)="addSplashbackCill(SolidSurfaceConfigPieces.C)">
                            <mat-icon class="splashback-cill-add" *ngIf="!hasSplashbackCill(SolidSurfaceConfigPieces.C)">add_circle_outline</mat-icon>
                            <mat-icon class="splashback-cill-edit" *ngIf="hasSplashbackCill(SolidSurfaceConfigPieces.C)">edit</mat-icon>
                            <h5>Piece C</h5>
                        </div>
                        <div class="option" *ngIf="worktopConfig.pieces[SolidSurfaceConfigPieces.C].splashback.length">
                            <mat-icon class="splashback-cill-remove" (click)="removeSplashback(SolidSurfaceConfigPieces.C)">remove_circle_outline</mat-icon>
                            <div class="option-text">
                                <h6>Splashback</h6>
                                <p>{{ worktopConfig.pieces[SolidSurfaceConfigPieces.C].splashback.length }}mm x {{ worktopConfig.pieces[SolidSurfaceConfigPieces.C].splashback.height }}mm</p>
                            </div>
                        </div>
                        <div class="option" *ngIf="worktopConfig.pieces[SolidSurfaceConfigPieces.C].cill.length">
                            <mat-icon class="splashback-cill-remove" (click)="removeCill(SolidSurfaceConfigPieces.C)">remove_circle_outline</mat-icon>
                            <div class="option-text">
                                <h6>Cill</h6>
                                <p>{{ worktopConfig.pieces[SolidSurfaceConfigPieces.C].cill.length }}mm x {{ worktopConfig.pieces[SolidSurfaceConfigPieces.C].cill.height }}mm</p>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="hasD" class="options">
                        <div class="option" (click)="addSplashbackCill(SolidSurfaceConfigPieces.D)">
                            <mat-icon class="splashback-cill-add" *ngIf="!hasSplashbackCill(SolidSurfaceConfigPieces.D)">add_circle_outline</mat-icon>
                            <mat-icon class="splashback-cill-edit" *ngIf="hasSplashbackCill(SolidSurfaceConfigPieces.D)">edit</mat-icon>
                            <h5>Piece D</h5>
                        </div>
                        <div class="option" *ngIf="worktopConfig.pieces[SolidSurfaceConfigPieces.D].splashback.length">
                            <mat-icon class="splashback-cill-remove" (click)="removeSplashback(SolidSurfaceConfigPieces.D)">remove_circle_outline</mat-icon>
                            <div class="option-text">
                                <h6>Splashback</h6>
                                <p>{{ worktopConfig.pieces[SolidSurfaceConfigPieces.D].splashback.length }}mm x {{ worktopConfig.pieces[SolidSurfaceConfigPieces.D].splashback.height }}mm</p>
                            </div>
                        </div>
                        <div class="option" *ngIf="worktopConfig.pieces[SolidSurfaceConfigPieces.D].cill.length">
                            <mat-icon class="splashback-cill-remove" (click)="removeCill(SolidSurfaceConfigPieces.D)">remove_circle_outline</mat-icon>
                            <div class="option-text">
                                <h6>Cill</h6>
                                <p>{{ worktopConfig.pieces[SolidSurfaceConfigPieces.D].cill.length }}mm x {{ worktopConfig.pieces[SolidSurfaceConfigPieces.D].cill.height }}mm</p>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="hasI" class="options">
                        <div class="option" (click)="addSplashbackCill(SolidSurfaceConfigPieces.I)">
                            <mat-icon class="splashback-cill-add" *ngIf="!hasSplashbackCill(SolidSurfaceConfigPieces.I)">add_circle_outline</mat-icon>
                            <mat-icon class="splashback-cill-edit" *ngIf="hasSplashbackCill(SolidSurfaceConfigPieces.I)">edit</mat-icon>
                            <h5>Island</h5>
                        </div>
                        <div class="option" *ngIf="worktopConfig.pieces[SolidSurfaceConfigPieces.I].splashback.length">
                            <mat-icon class="splashback-cill-remove" (click)="removeSplashback(SolidSurfaceConfigPieces.I)">remove_circle_outline</mat-icon>
                            <div class="option-text">
                                <h6>Splashback</h6>
                                <p>{{ worktopConfig.pieces[SolidSurfaceConfigPieces.I].splashback.length }}mm x {{ worktopConfig.pieces[SolidSurfaceConfigPieces.I].splashback.height }}mm</p>
                            </div>
                        </div>
                        <div class="option" *ngIf="worktopConfig.pieces[SolidSurfaceConfigPieces.I].cill.length">
                            <mat-icon class="splashback-cill-remove" (click)="removeCill(SolidSurfaceConfigPieces.I)">remove_circle_outline</mat-icon>
                            <div class="option-text">
                                <h6>Cill</h6>
                                <p>{{ worktopConfig.pieces[SolidSurfaceConfigPieces.I].cill.length }}mm x {{ worktopConfig.pieces[SolidSurfaceConfigPieces.I].cill.height }}mm</p>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="hasE" class="options">
                        <div class="option" (click)="addSplashbackCill(SolidSurfaceConfigPieces.E)">
                            <mat-icon class="splashback-cill-add" *ngIf="!hasSplashbackCill(SolidSurfaceConfigPieces.E)">add_circle_outline</mat-icon>
                            <mat-icon class="splashback-cill-edit" *ngIf="hasSplashbackCill(SolidSurfaceConfigPieces.E)">edit</mat-icon>
                            <h5>Extra Piece</h5>
                        </div>
                        <div class="option" *ngIf="worktopConfig.pieces[SolidSurfaceConfigPieces.E].splashback.length">
                            <mat-icon class="splashback-cill-remove" (click)="removeSplashback(SolidSurfaceConfigPieces.E)">remove_circle_outline</mat-icon>
                            <div class="option-text">
                                <h6>Splashback</h6>
                                <p>{{ worktopConfig.pieces[SolidSurfaceConfigPieces.E].splashback.length }}mm x {{ worktopConfig.pieces[SolidSurfaceConfigPieces.E].splashback.height }}mm</p>
                            </div>
                        </div>
                        <div class="option" *ngIf="worktopConfig.pieces[SolidSurfaceConfigPieces.E].cill.length">
                            <mat-icon class="splashback-cill-remove" (click)="removeCill(SolidSurfaceConfigPieces.E)">remove_circle_outline</mat-icon>
                            <div class="option-text">
                                <h6>Cill</h6>
                                <p>{{ worktopConfig.pieces[SolidSurfaceConfigPieces.E].cill.length }}mm x {{ worktopConfig.pieces[SolidSurfaceConfigPieces.E].cill.height }}mm</p>
                            </div>
                        </div>
                    </div>
                </mat-step>
                <mat-step [stepControl]="styleFormGroup">
                    <ng-template matStepLabel>Confirm Style</ng-template>

                    <div class="change-style">
                        <h5 class="title">{{ worktop.sub_cat }} <span>({{ worktop.thickness }}mm)</span></h5>
                        <img [src]="worktop.media.image" width="100%" [alt]="worktop.sub_cat" />
                        <p>Price for this configuration,</p>
                        <p>including laser templating</p>
                        <p>and fitting fee:</p>
                        <p class="price" [innerHTML]="prices.total | currencyFormat:true:true"></p>

                        <button mat-flat-button color="primary" (click)="changeStyle()">Change style</button>
                    </div>
                </mat-step>
                <mat-step [stepControl]="quotationFormGroup">
                    <ng-template matStepLabel>Quotation Price</ng-template>

                    <div class="quotation">
                        <h5 class="title">{{ worktop.sub_cat }} <span>({{ worktop.thickness }}mm)</span></h5>
                        <img [src]="worktop.media.image" width="100%" [alt]="worktop.sub_cat"/>

                        <div class="quotation-prices">
                            <div class="quotation-price">
                                <p>Worktops</p>
                                <p [innerHTML]="prices.sqrMtrCost | currencyFormat:false:true:false:true"></p>
                            </div>
                            <div class="quotation-price">
                                <p>Edge profiling</p>
                                <p [innerHTML]="prices.profilesCost | currencyFormat:false:true:false:true"></p>
                            </div>
                            <div class="quotation-price">
                                <p>Corner shaping</p>
                                <p [innerHTML]="prices.cornersCost | currencyFormat:false:true:false:true"></p>
                            </div>
                            <div class="quotation-price">
                                <p>Cut outs</p>
                                <p [innerHTML]="prices.cutoutsCost | currencyFormat:false:true:false:true"></p>
                            </div>
                            <div class="quotation-price" *ngIf="prices.downturnsCost > 0">
                                <p>Downturns</p>
                                <p [innerHTML]="prices.downturnsCost | currencyFormat:false:true:false:true"></p>
                            </div>
                            <div class="quotation-price" *ngIf="prices.upstandsCost > 0">
                                <p>Upstands</p>
                                <p [innerHTML]="prices.upstandsCost | currencyFormat:false:true:false:true"></p>
                            </div>
                            <div class="quotation-price" *ngIf="prices.splashbacksCost > 0">
                                <p>Splashbacks</p>
                                <p [innerHTML]="prices.splashbacksCost | currencyFormat:false:true:false:true"></p>
                            </div>
                            <div class="quotation-price" *ngIf="prices.cillsCost > 0">
                                <p>Cills</p>
                                <p [innerHTML]="prices.cillsCost | currencyFormat:false:true:false:true"></p>
                            </div>
                            <div class="quotation-price">
                                <p>Laser templating & installation</p>
                                <p [innerHTML]="prices.installCost | currencyFormat:false:true:false:true"></p>
                            </div>
                            <div class="quotation-price">
                                <p>Total</p>
                                <p [innerHTML]="prices.total | currencyFormat:true:true"></p>
                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-vertical-stepper>
        </div>

        <div class="right-panel">
            <div class="header" [ngSwitch]="stepper.selectedIndex">
                <div class="header-title" *ngSwitchCase="0">
                    <h4>Step 1 - Choose main worktop layout</h4>
                    <p>Select one of the layouts that represents your main worktop layout to begin your estimate.</p>
                </div>
                <div class="header-title" *ngSwitchCase="1">
                    <h4>Step 2 - Configure dimensions</h4>
                    <p>Enter your required worktop lengths and depths.</p>
                </div>
                <div class="header-title" *ngSwitchCase="2">
                    <h4>Step 3 - Configure edges, corners & upstands</h4>
                    <div>Choose edging & corner profiles, add upstands & downturns.</div>
                </div>
                <div class="header-title" *ngSwitchCase="3">
                    <h4>Step 4 - Configure appliance and sink locations</h4>
                    <div>Position your appliances to add the required cut outs & grooves.</div>
                </div>
                <div class="header-title" *ngSwitchCase="4">
                    <h4>Step 5 - Configure splashbacks, cills</h4>
                    <div>Add splashback & window cill pieces.</div>
                </div>
                <div class="header-title" *ngSwitchCase="5">
                    <h4>Step 6 - Confirm Style</h4>
                    <div>Confirm or change your worktop product selection.</div>
                </div>
                <div class="header-title" *ngSwitchCase="6">
                    <h4>Step 7 - Your worktop quotation</h4>
                    <div>Note: You can make changes by re-visiting any of the steps above.</div>
                </div>

                <!-- <div class="help">
                    <p>Need help or advice? Email us at: solidsurfaces&#64;diy-kitchens.com</p>
                </div> -->
            </div>

            <div class="canvas">
                <canvas id="worktopCanvas" width="760px" height="500px"></canvas>
            </div>

            <div class="popout-summary" [ngClass]="{ 'show-popout-summary': showWorktopSummary }" *ngIf="stepper.selectedIndex === 6">
                <ng-container *ngTemplateOutlet="summary"></ng-container>
            </div>

            <div class="footer" [ngSwitch]="stepper.selectedIndex">
                <div class="actions">
                    <div class="action-buttons">
                        <ng-container *ngSwitchCase="0">
                            <button mat-flat-button color="primary" *ngIf="worktopConfig.layout !== SolidSurfaceConfigLayout.ISLAND" (click)="toggleIsland()">
                                <ng-container *ngIf="!worktopConfig.addIsland">Add an Island</ng-container>
                                <ng-container *ngIf="worktopConfig.addIsland">Remove Island</ng-container>
                            </button>
                        </ng-container>
                        <button mat-flat-button color="primary" *ngSwitchCase="0" (click)="toggleExtra()">
                            <ng-container *ngIf="!worktopConfig.addExtra">Add Extra Piece</ng-container>
                            <ng-container *ngIf="worktopConfig.addExtra">Remove Extra Piece</ng-container>
                        </button>
                        <button mat-flat-button color="primary" *ngSwitchCase="0" (click)="stepper.next()">
                            Configure Dimensions
                            <mat-icon>arrow_right_alt</mat-icon>
                        </button>
                        <button mat-stroked-button color="secondary" *ngSwitchCase="6" (click)="viewQuote()">
                            <ng-container *ngIf="!showWorktopSummary">View Worktop Summary</ng-container>
                            <ng-container *ngIf="showWorktopSummary">Close Worktop Summary</ng-container>
                        </button>
                    </div>
                    <button mat-flat-button color="primary" *ngSwitchCase="1" (click)="stepper.next()" [disabled]="!validDims">
                        Configure Edgings & Upstands
                        <mat-icon>arrow_right_alt</mat-icon>
                    </button>
                    <button mat-flat-button color="primary" *ngSwitchCase="2" (click)="stepper.next()">
                        Add Cut Outs for Appliances & Sinks
                        <mat-icon>arrow_right_alt</mat-icon>
                    </button>
                    <button mat-flat-button color="primary" *ngSwitchCase="3" (click)="stepper.next()">
                        Add Splashbacks & Cills
                        <mat-icon>arrow_right_alt</mat-icon>
                    </button>
                    <button mat-flat-button color="primary" *ngSwitchCase="4" (click)="stepper.next()">
                        Confirm Style
                        <mat-icon>arrow_right_alt</mat-icon>
                    </button>
                    <button mat-flat-button color="primary" *ngSwitchCase="5" (click)="stepper.next()">
                        View Quotation
                        <mat-icon>arrow_right_alt</mat-icon>
                    </button>
                    <button mat-flat-button color="primary" *ngSwitchCase="6" (click)="addToBasket()">
                        <ng-container *ngIf="worktopConfigUuid">Update This Configuration in Your Basket</ng-container>
                        <ng-container *ngIf="!worktopConfigUuid">Add This Configuration to Your Basket</ng-container>
                    </button>
                </div>
                <div class="notes">
                    <p class="note" *ngSwitchCase="1">
                        If you are having a range or freestanding cooker, include its width in your dimensions. Cooker cutouts are automatically deducted from the price.
                    </p>
                    <ng-container *ngSwitchCase="2">
                        <p class="note" *ngIf="sub3 === SolidSurfaceConfigProfiles.PROFILED">
                            For aesthetic and safety reasons, it is compulsary to profile all edges that face into the room.
                        </p>
                    </ng-container>
                    <p class="note" *ngSwitchCase="3">
                        Click on a worktop piece to add cut outs for appliances, sinks, etc.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="big-enough" *ngIf="worktopConfig && readOnly">
        <div class="right-panel">
            <div class="canvas">
                <canvas id="worktopCanvas" width="760px" height="500px"></canvas>
            </div>

            <ng-container *ngTemplateOutlet="summary"></ng-container>
        </div>
    </div>
</div>