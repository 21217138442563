import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMedia } from '@app/services/catalogue/models/product.models/common';

@Component({
    selector: 'component-product-image-gallery-dialog',
    templateUrl: './product-image-gallery.dialog.component.html',
    styleUrls: ['./product-image-gallery.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ProductImageGalleryDialogComponent {
    public focussedImage: IMedia;
    public images: IMedia[];
    public selectedImage: IMedia;

    constructor(
        private dialogRef: MatDialogRef<ProductImageGalleryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.focussedImage = this.data.focussedImage;
        this.images = this.data.images;
        this.selectedImage = this.data.focussedImage;
    }

    public arrowLeftClick(): void {
        let next = this.images.indexOf(this.focussedImage) -1;
        if (next < 0) {
            next = this.images.length -1;
        }
        this.focussedImage = this.images[next];
        this.selectedImage = this.images[next];
    }

    public arrowRightClick(): void {
        let next = this.images.indexOf(this.focussedImage) +1;
        if (next >= this.images.length) {
            next = 0;
        }
        this.focussedImage = this.images[next];
        this.selectedImage = this.images[next];
    }

    public thumbnailClick(image: IMedia): void {
        this.focussedImage = image;
        this.selectedImage = image;
    }
}
