@import '/src/theme/variables';

.mat-mdc-dialog-container {
    component-dialog-iframe {
        .mat-mdc-dialog-content {
            position: relative;
            display: flex;
            padding: 0;
            font-size: 14px;
            color: rgba(0, 18, 36, 0.9);
            font-weight: 400;
            line-height: 20px;

            .close {
                position: absolute;
                top: 15px;
                right: 15px;
                cursor: pointer;
            }

            iframe {
                border: none;
                width: 100%;
                height: 100%;
            }
        }
    }
}