@import '/src/theme/variables';

component-option-bar {
    .option-container {
        display: flex;
        gap: 5%;
        margin-top: 15px;
        padding: 10px 20px;
        border-top: 1px solid color('copy-border');

        .filters,
        .toggles {
            display: flex;
            gap: 10px;
            align-items: center;
            max-width: 50%;

            button {
                border: 1px solid color('brand-dark');
                border-radius: 20px;
                min-width: 100px;

                &.mdc-button.mat-mdc-button-base.mat-mdc-unelevated-button {
                    height: 0;
                    padding: 18px;
                }

                &.selected {
                    border: 2px solid color('brand-primary');
                }
            }
        }

        .toggles {
            border-left: 1px solid color('copy-border');
            margin: 0 0 0 auto;
            padding-left: 5%;
            display: flex;

            .active-range {
                display: flex;
                align-items: center;
                justify-self: flex-end;
                margin: 0 0 0 auto;
                gap: 10px;

                img {
                    width: 30px;
                }

                .range-text {
                    display: flex;
                    flex-direction: column;

                    span {
                        font-size: 0.9rem;
                    }
                }

                button {
                    // margin-left: -20px;
                    padding: 0;
                    border: none;
                }
            }

            button {
                border-radius: 0;

                .mdc-button__label {
                    text-transform: capitalize;
                    font-size: 0.7rem;
                }

                .status {
                    margin-left: 5px;
                    font-size: 0.8rem;
                }
            }
        }

        .wrapped {
            display: none;

            button {
                display: flex;
                flex-direction: row-reverse;
                background-color: color('brand-dark');

                .mdc-button__label {
                    text-transform: capitalize;
                    margin-right: 10px;
                }
            }
        }
    }

    .side-menu {
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        background-color: color('brand-dark');
        width: 20vw;
        min-width: 300px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 2px;

        .side-menu-header {
            display: flex;
            width: 100%;
            align-items: center;

            h3 {
                margin: 0 0 0 20px;
                color: color('brand-lightgrey');
            }

            button {
                margin: 0 10px 0 auto;
                color: color('brand-lightgrey');
            }
        }

        .side-menu-body {
            overflow-y: auto;

            mat-expansion-panel {
                background-color: color('brand-dark');
                color: color('white');

                mat-expansion-panel-header {
                    background-color: color('brand-dark');
                    color: color('white');

                    span:first-of-type {
                        height: 100%;
                        align-items: center;
                    }

                    .mat-expansion-panel-header-description,
                    .mat-expansion-indicator::after {
                        color: color('white');
                    }

                    &.mat-expanded:focus {
                        background-color: color('brand-dark');
                    }
                }

                .expansion-content {
                    &.box-select {
                        display: grid;
                        grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
                        gap: 10px;

                        .opt {
                            border: 1px solid color('white');
                            text-align: center;
                            padding: 5px;

                            &.selected {
                                background-color: color('brand-primary');
                            }

                            &.disabled {
                                opacity: 0.4;
                            }
                        }
                    }

                    &:not(.box-select) .opt {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        margin-bottom: 5px;

                        &.disabled {
                            opacity: 0.4;
                        }
                    }

                    .opt:hover {
                        cursor: pointer;
                    }
                }
            }

            mat-radio-group {
                display: flex;
                flex-direction: column;

                .mat-mdc-radio-button {
                    .mdc-form-field {
                        width: 100%;
                    }

                    label {
                        margin-left: 20px;
                        color: color('white');
                    }

                    label:hover {
                        cursor: pointer;
                    }

                    .mdc-radio {
                        margin: 0 10px 0 auto;
                        color: color('white');
                        --mdc-radio-disabled-selected-icon-color: color('white');
                        --mdc-radio-disabled-unselected-icon-color: color('white');
                        --mdc-radio-unselected-focus-icon-color: color('white');
                        --mdc-radio-unselected-hover-icon-color: #009CDE;
                        --mdc-radio-unselected-icon-color: color('white');
                        --mdc-radio-unselected-pressed-icon-color: color('white');
                        --mdc-radio-selected-focus-icon-color: #009CDE;
                        --mdc-radio-selected-hover-icon-color: #009CDE;
                        --mdc-radio-selected-icon-color: #009CDE;
                        --mdc-radio-selected-pressed-icon-color: color('white');
                        --mat-mdc-radio-ripple-color: color('white');
                        --mat-mdc-radio-checked-ripple-color: color('white');
                    }
                }
            }
        }

        .side-menu-body::-webkit-scrollbar {
            width: 0px;
        }

        .side-menu-footer {
            display: flex;
            margin: auto 20px 20px 20px;
            justify-content: space-between;

            button {
                flex-direction: row-reverse;
                gap: 10px;
                margin: 5px;
                border: 1px solid color('brand-lightgrey');

                span {
                    color: color('brand-lightgrey');
                }

                mat-icon {
                    color: color('brand-lightgrey');
                }

                &.results-btn {
                    margin-left: auto;
                }
            }
        }

        &.animate-left {
            animation: slide-in-left 0.5s;
        }

        @keyframes slide-in-left {
            from {
                transform: translateX(-100%);
            }

            to {
                transform: translateX(0);
            }
        }
    }

    @media only screen and (max-width: 992px) {
        .option-container {
            .toggles {
                .active-range {
                    display: none;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .side-menu {
            width: 100vw;
        }

        .option-container {
            flex-direction: column;

            .filters {
                display: none;
            }

            .toggles {
                width: 100%;
                max-width: 100%;
                justify-content: center;
                border: none;
                padding: 15px 0;
                margin: 0;

                .active-range {
                    margin: 0;
                }
            }

            .wrapped {
                width: 100%;
                display: flex;
                justify-content: space-evenly;

                button {
                    min-width: 40%;
                }
            }
        }
    }

    @media only screen and (max-width: 480px) {
        .option-container {
            .toggles {
                .active-range {
                    display: none;
                }
            }
        }
    }
}