import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';

import { LoginDialogComponent } from './login.dialog.component';

import { LoginComponentModule } from '@app/components/login/login.component.module';

@NgModule({
    imports: [
        DesignModule,

        LoginComponentModule
    ],
    declarations: [
        LoginDialogComponent
    ]
})
export class LoginDialogModule { }
