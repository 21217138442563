import { NgModule } from '@angular/core';

import { WorkerService } from './worker.service';

@NgModule({
    providers: [
        WorkerService
    ]
})
export class WorkerServiceModule { }
