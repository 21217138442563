<section class="filters content-contained" *ngIf="items">
    <ng-container *ngIf="subCategoryData">
        <component-gallery [galleryItems]="galleryItems" [filter]="galleryFilter" [category]="category" [subCategory]="subCategory"></component-gallery>
        <hr class="narrow" />
    </ng-container>
</section>
<!-- <header class="content-contained content-header-background" [ngClass]="headerCssClass"> -->
<header class="content-contained">
    <div class="header-blurb">
        <h1>{{subCategoryDisplay}} {{categoryDisplay}}</h1>
        <ng-container *ngIf="(category === 'base' && !subCategory) || (category === 'base' && subCategory === 'all')">
            <p>With over 500 kitchen base unit options available, we offer the most comprehensive range of
                <a routerLink="/kitchen-units" title="Kitchen Units">kitchen units</a> you'll find anywhere to help build your dream
                <a routerLink="/kitchens" title="Build your dream kitchen">kitchen</a>.
            </p>
            <p>
                Base Units are supplied 720mm high (+150mm Legs).<br />
                Want to know about our <a routerLink="/about-diy-kitchens/our-partners" fragment="Innova" title="Innova">Innova Kitchen Range</a>?
            </p>
            <p>
                Our base units are also available dry assembled which means they are delivered rigid, but not glued.
                This is especially useful if your kitchen layout has an awkward section.
            </p>
        </ng-container>
        <ng-container *ngIf="category === 'base' && subCategory === 'highline'">
            <p>Highline means that the unit has a door that spans from the bottom to the top of the unit and has no drawer. We have over 25 different single & double highline base units available in 12 widths, so you will be able design your kitchen and make use of all the space, without any compromises.</p>
        </ng-container>
        <ng-container *ngIf="category === 'base' && subCategory === 'drawerline'">
            <p>Drawerline base units (single & double) have a drawer at the top of each unit and a smaller door below it, to allow you to access the cupboard. We have 26 different drawerline units in 9 widths, ranging from 300mm to 1200mm. So, you'll be able to create the perfect kitchen, with the units that you need, exactly where you want them!</p>
        </ng-container>
        <ng-container *ngIf="category === 'base' && subCategory === 'pan-and-multi-drawer'">
            <p>If your kitchen design requires some pan or multi drawer base units then you're in luck! We have a selection of 41 different drawer units, ranging from those units with 1 drawer, right up to units featuring 5. So, if you have more cutlery than a professional kitchen, then you'll find these units extremely useful!</p>
        </ng-container>
        <ng-container *ngIf="category === 'base' && subCategory === 'sink'">
            <p>When planning the sink area in your kitchen, it's very important to think about the type of sink base unit that you are going to have. This is because the plumbing & pipework, can take up a considerable amount of space, inside the unit. If you are planning on having a hot water tap too then these also require space inside the unit and things could start to get a little cramped, if the unit is too small.</p>
        </ng-container>
        <ng-container *ngIf="category === 'base' && subCategory === 'pull-out-storage'">
            <p>Pull out storage base units in a kitchen, are very practical and allow easy access to items such as tea, coffee and spice jars. Not only are the units a joy to have but with unit widths as small as 100mm wide, you could make full use of any available space, rather than having to use a filler. If you are thinking about incorporating some pull-out storage in your kitchen then <a href='https://advice.diy-kitchens.com/customer-questions/150mm-pullout-units/' target="_blank" title="Advice">this article</a> goes into more detail.</p>
        </ng-container>
        <ng-container *ngIf="category === 'base' && subCategory === 'pull-out-waste-units'">
            <p>If you want to fully streamline your workflow in the kitchen then a pull out bin is essential! Place your chopping board on the worktop above it, prepare all your food and then all the peelings and packaging can be scraped straight off the worktop, straight into the bin below. A pull out bin 'is' amazing. Don't plan your kitchen without one!</p>
        </ng-container>
        <ng-container *ngIf="category === 'base' && subCategory === 'curved-and-s-shaped'">
            <p>If you are looking to soften the end of a kitchen run, so that it matches the curves of the worktop above it, then a curved base unit will help you do that and make your kitchen look even more stunning in the process! Curved corner base units are also available, if you want to extend to the look even further.</p>
        </ng-container>
        <ng-container *ngIf="category === 'base' && subCategory === 'slimline'">
            <p>Reduced depth (slimline) base units are very versatile and can be used in many different ways. They are ideal if you have limited space or if you needed to <a href='https://advice.diy-kitchens.com/customer-questions/use-stowaway-unit/' target="_blank" title="Advice">navigate around an obstruction</a> in the kitchen and still wanted to use full depth units next to it.</p>
        </ng-container>
        <ng-container *ngIf="category === 'base' && subCategory === 'open'">
            <p>Open base units can have many different functions, like filling in a gap in a kitchen run, creating a display feature or even using them as a bookcase, to keep all your recipes neatly stored and easily accessible. With many different open base units available, you may want to spend a little time here to consider which unit is right for you and what it is going to be used for.</p>
        </ng-container>
        <ng-container *ngIf="category === 'base' && subCategory === 'wine-rack'">
            <p>If you are looking to declutter your kitchen, especially getting rid of bottles off the worktops then a wine rack base unit is an ideal investment! Not only can the wine rack hold bottles of red wine (white wine really needs to be in the wine cooler), but you can also put all your squash and fizzy pop bottles in there too. Conveniently stored and easy to access.</p>
        </ng-container>
        <ng-container *ngIf="category === 'base' && subCategory === 'end-quadrant'">
            <p>End quadrant base units are the perfect unit to use, if you want to end your kitchen run a little differently and to make a feature of it. You could use it to store objet d'art, family photos & books.</p>
        </ng-container>
        <ng-container *ngIf="category === 'base' && subCategory === 'peninsular'">
            <p>Peninsular base units are a great idea to allow easy access to the unit from both sides. You can also use these units under a breakfast bar, if you wanted the convenient storage below and the space above to relax.</p>
        </ng-container>
        <ng-container *ngIf="category === 'base' && subCategory === 'reduced-height'">
            <p>Reduced height base units are great little additions to your kitchen plan, if you are looking to get creative and put them to good use by making something like a seating area above them or a space for a TV to sit.</p>
        </ng-container>
        <ng-container *ngIf="category === 'base' && subCategory === '900mm-high'">
            <p>If you are creating a breakfast bar but need one side of it to be a little higher, then our 900mm high base units, are just the answer. Available in 9 different widths, we'll have the perfect unit for you that will fit into your design.</p>
        </ng-container>
        <ng-container *ngIf="category === 'base' && subCategory === '1250mm-high'">
            <p>If you require a little more height from a base unit but do not need a tall unit then a compromise could be the mid height, 1250mm high base units. They are not too high and you can even install integrated appliances into them, such as ovens, fridges and compact appliances like wine coolers & drinks machines.</p>
        </ng-container>
        <ng-container *ngIf="category === 'base' && subCategory === 'angled'">
            <p>If space is a little tight at the end of your kitchen run, such as where a door opens then you could choose to install an angled base unit, to give you that little bit more space to get into the kitchen.</p>
        </ng-container>
        <ng-container *ngIf="category === 'base' && subCategory === 'feature-unit'">
            <p>Feature units offer a unique blend of form and function to elevate the aesthetic appeal of your kitchen. Whether you opt for a wicker basket base unit for a rustic touch or an S-shape pull-out pan drawer for sleek modernity, these units stand out while providing practical storage solutions. They serve as a focal point in your kitchen, marrying style and utility in a way that's sure to capture attention.</p>
        </ng-container>
        
        <ng-container *ngIf="(category === 'corner-base' && !subCategory) || (category === 'corner-base' && subCategory === 'all')">
            <p>With over 100 corner base unit options available we offer the most comprehensive range of units you'll find anywhere.</p>
            <p>We offer a selection of corner unit mechanisms by Kessebohmer to maximise the potential storage space in your <a routerLink="/kitchens" title="Build your dream kitchen">kitchen</a>.</p>
            <p>
                Our corner base units are also available dry assembled which means they are delivered rigid, but not glued.
                This is especially useful if your kitchen layout has an awkward section.
            </p>
        </ng-container>
        <ng-container *ngIf="category === 'corner-base' && subCategory === 'standard'">
            <p>A corner base unit allows you to return units onto an adjacent kitchen run and carry on the run of units seamlessly. A standard corner unit does require a corner post (70mm x 70mm), which is supplied, and you need to leave 130mm at the end of the unit for the service void. This gap also allows the units to line up at right angles, once the 70mm x 70mm corner post is in place. </p>
        </ng-container>
        <ng-container *ngIf="category === 'corner-base' && subCategory === 'sink'">
            <p>If you are looking to maximise usable worktop space as much as possible then you have the option to place your sink, inside a corner sink unit. The draining board can then get positioned into the corner, so that you can utilise every bit of space. The smallest corner sink unit that we make is 900mm wide, which gives plenty of space inside the unit for the <a href="https://advice.diy-kitchens.com/customer-questions/difference-between-sink-waste-plumbing-kit/" target="_blank" title="plumbing and pipework">plumbing and pipework</a>.</p>
        </ng-container>
        <ng-container *ngIf="category === 'corner-base' && subCategory === 'pull-out'">
            <p>In a standard corner base unit, it can be a little difficult sometimes, getting to items that have been pushed to the back of the unit. But, with a pull out corner unit, everything comes towards you!</p>
        </ng-container>
        <ng-container *ngIf="category === 'corner-base' && subCategory === 'l-shaped'">
            <p>An L-shaped corner base unit, if you have the space, is a great addition in any kitchen design. The doors open up to grace you with masses of storage space, as the entire corner is utilisable. There is no need for a corner post with this unit because the doors create the 90 degree return.</p>
        </ng-container>
        <ng-container *ngIf="category === 'corner-base' && subCategory === 'diagonal'">
            <p>If you really want to max out the available storage space in a corner then a diagonal corner base unit is the answer! With just the one 1 door, positioned at an angle, you get more storage space at the front of the unit, that you would lose with an l-shaped unit.</p>
        </ng-container>
        <ng-container *ngIf="category === 'corner-base' && subCategory === 'internal-curved'">
            <p>If you've invested in a nice quartz worktop with curves, and you want to mirror the smooth look on your units then an internal curved, corner base unit can do just that. Not only will it look fantastic but the unit itself, offers plenty of storage space and it's even available with a carousel!</p>
        </ng-container>

        <ng-container *ngIf="(category === 'wall' && !subCategory) || (category === 'wall' && subCategory === 'all')">
            <p>With over 100 wall unit options available across three different heights we offer the most comprehensive range of wall units you'll find anywhere.</p>
            <p>720mm Wall Units are designed for use with 1970mm Tall Units.</p>
            <p>900mm Wall Units are designed for use with 2150mm Tall Units.</p>
        </ng-container>
        <ng-container *ngIf="category === 'wall' && subCategory === 'standard'">
            <p>We have 33 standard, single & double wall units available (575mm, 720mm & 900mm high), ranging from 150mm to 1000mm in width. No matter the length of your kitchen, you'll be able to fill in the space perfectly, to maximise your wall cupboard storage. For the smaller kitchens where space is a premium, you could always choose the 900mm tall wall units instead, to give you that little bit extra storage space.</p>
        </ng-container>
        <ng-container *ngIf="category === 'wall' && subCategory === 'glazed'">
            <p>If you want to add a touch of class to your wall units then why not upgrade them a little and choose the glazed wall units instead? These units are perfect if you want to showcase your fancy dinning set or place some unit lighting inside and use them as display cases, for pictures or ornaments. The choice, is yours! Our glazed wall units are either 720mm or 900mm high and are available 4 different widths, ranging from 400mm to 1000mm wide. All the glazed doors in our units have transparent perspex and not glass.</p>
        </ng-container>
        <ng-container *ngIf="category === 'wall' && subCategory === 'corner'">
            <p>A corner wall unit is essential in your kitchen design when you are returning units onto an adjacent wall. We have 27 different corner wall units available, to help you do this. The units also come in 3 different heights, so you'll have lots of flexibility and options, in the planning stage.</p>
        </ng-container>
        <ng-container *ngIf="category === 'wall' && subCategory === 'curved-and-s-shaped'">
            <p>If you are looking to soften the end of a kitchen run, so that it matches the curves of your worktop below it, then a curved wall unit will help you do that and make your kitchen look even more stunning in the process!</p>
        </ng-container>
        <ng-container *ngIf="category === 'wall' && subCategory === 'open'">
            <p>Open wall units can help break up the flow of doors in your kitchen and add a feature, to store items such as plates, photos & books. We have many different coloured units to choose from, so you can choose a colour as close to your door style as possible or contrast a little with a wood effect unit.</p>
        </ng-container>
        <ng-container *ngIf="category === 'wall' && subCategory === 'microwave'">
            <p>If you are looking to keep your work surface as tidy as possible then placing your microwave into a wall unit, would be an excellent idea! We have 2 different heights (720mm & 900mm) of microwave wall units to choose from and you have the added bonus of a cupboard above the microwave too!</p>
        </ng-container>
        <ng-container *ngIf="category === 'wall' && subCategory === 'angled'">
            <p>If space is a little tight at the end of your kitchen run, such as where a door opens then you could choose to install an angled wall unit. This will give you a little more room and you won't be presented with the flat side of a unit, when you enter the kitchen.</p>
        </ng-container>
        <ng-container *ngIf="category === 'wall' && subCategory === 'peninsular'">
            <p>If you are looking to mirror the peninsular base units hat open from both ends with some matching wall units then we have 6 different peninsular wall units, to help you achieve this look.</p>
        </ng-container>
        <ng-container *ngIf="category === 'wall' && subCategory === 'end-quadrant'">
            <p>If you want to make a display feature, at the end of your run of wall units, then one of our 5 end quadrant wall units, will help you create something special.</p>
        </ng-container>
        <ng-container *ngIf="category === 'wall' && subCategory === 'wine-rack'">
            <p>A wine rack wall unit included in your kitchen design, can help to free up some valuable worktop space and create a very useful area to store bottles in at the same time. The bottles will be in an easy to reach location for you but well out of the way of the kids!</p>
        </ng-container>
        <ng-container *ngIf="category === 'wall' && subCategory === 'bi-fold'">
            <p>A bi-fold wall unit would look great in any kitchen, especially if space is tight and there is no room for doors to open outwards. The bi-fold doors open upwards, with the help of the sturdy door lifting system and the recommended clearance needed above a horizontal bi-fold wall unit, is 160mm.</p>
        </ng-container>
        <ng-container *ngIf="category === 'wall' && subCategory === 'top-boxes'">
            <p>When designing your kitchen, one unit people tend to forget about is the top box. A top box can be an interesting alternative to the more traditional wall units and can create a nice feature in your kitchen. We have over 20 top boxes to choose from in 6 different widths and 3 heights. You can see how some of our customers have used top boxes in their kitchens <a href="https://advice.diy-kitchens.com/customer-questions/top-box-kitchen-unit/" target="_blank" title="Top boxes">here</a>.</p>
        </ng-container>
        <ng-container *ngIf="category === 'wall' && subCategory === 'boiler-housing'">
            <p>If your boiler happens to be in your kitchen then why not integrate it too with one of our boiler housing wall units. The end effect will look great and it's way, way cheaper than relocating the boiler! This article here, talks about how to plan around a boiler and other obstacles.</p>
        </ng-container>
        <ng-container *ngIf="category === 'wall' && subCategory === 'canopy'">
            <p>Cooker canopies aren't just functional, they're a statement piece in the modern kitchen. Serving to effectively vent out unwanted fumes and odors, these canopies also elevate the overall design and feel of your space. Whether you're seeking a sleek contemporary look or a timeless traditional touch, our range of cooker canopies promises both performance and aesthetic appeal. Make it the crowning jewel of your kitchen and cook in style and comfort.</p>
        </ng-container>
        <ng-container *ngIf="category === 'wall' && subCategory === 'reduced-depth-unit'">
            <p>Reduced depth (slimline) wall units are very versatile and can be used in many different ways. They are ideal if you have limited space or if you needed to navigate around an obstruction in the kitchen and still wanted to use full depth units next to it.</p>
        </ng-container>

        <ng-container *ngIf="(category === 'corner-wall' && !subCategory) || (category === 'corner-wall' && subCategory === 'all')">
            <p>With over 100 wall unit options available across three different heights we offer the most comprehensive range of wall units you'll find anywhere.</p>
            <p>720mm Wall Units are designed for use with 1970mm Tall Units.</p>
            <p>900mm Wall Units are designed for use with 2150mm Tall Units.</p>
        </ng-container>
        <ng-container *ngIf="category === 'corner-wall' && subCategory === 'standard-corner'">
            <p>We have 12 standard corner wall units available in 4 different widths, to help you return the units onto an adjacent wall, to continue the run seamlessly. Our standard corner wall units also come in 3 different heights, 575mm, 720mm & 900mm. So, no matter which tall units you choose, we'll have wall units to match.</p>
        </ng-container>
        <ng-container *ngIf="category === 'corner-wall' && subCategory === 'l-shape'">
            <p>An L-shape corner wall unit, utilises all the available space and is available in 3 different heights. If you wanted to use this unit as more of a display area then there is an open version available as well.</p>
        </ng-container>
        <ng-container *ngIf="category === 'corner-wall' && subCategory === 'internal-curve'">
            <p>Our internal curved wall unit looks great in any style. The l-shape unit itself, offers loads of storage space, fully utilises the corner and adds a smooth, sleek finish to any kitchen.</p>
        </ng-container>
        <ng-container *ngIf="category === 'corner-wall' && subCategory === 'angled'">
            <p>Our corner wall angled units are available in 3 different heights and offer the most internal storage space, out of all the corner wall units that we provide. This unit is also available as an open unit, if you want to get creative and think of different uses for it.</p>
        </ng-container>

        <ng-container *ngIf="(category === 'appliance-housings' && !subCategory) || (category === 'appliance-housings' && subCategory === 'all')">
            <p>With over 70 appliance housing unit variants in our range, you can easily fit in appliances with various drawer and door options.</p>
            <p>
                To guarantee you have the correct housing for your appliance, find the appliance in our appliances section.
                Available housings for each appliance are listed in the appliance details page.
            </p>
        </ng-container>
        <ng-container *ngIf="category === 'appliance-housings' && subCategory === 'microwave'">
            <p>If you are looking to integrate your oven or microwave under your kitchen worktop, then we have 2 units below that can do just that. Moving the microwave from your worktop, can free up some valuable space and the microwave base unit, also comes with a pull out drawer for some very convenient additional storage.</p>
        </ng-container>
        <ng-container *ngIf="category === 'appliance-housings' && subCategory === 'single-oven'">
            <p>Integrating a single oven into a tall single oven housing is an excellent idea! The oven becomes more accessible, without having to bend down as much and the unit offers vasts amount of storage space both above and below the single oven.</p>
        </ng-container>
        <ng-container *ngIf="category === 'appliance-housings' && subCategory === 'single-oven-&-microwave'">
            <p>To make your single oven & microwave easily accessible, there is no better unit than a single oven & microwave housing. All your cooking appliances will be located in one place, there will be more space on your worktop and you'll get some additional storage space from the cupboards, above and below the appliances.</p>
        </ng-container>
        <ng-container *ngIf="category === 'appliance-housings' && subCategory === 'double-oven'">
            <p>A double oven can increase your cooking capacity tremendously and we have 4 different double oven housings to choose from below. If you required some very handy storage below your oven for baking trays etc., then the double oven housing with the 2 pull out pan drawers, is an absolute must!</p>
        </ng-container>
        <ng-container *ngIf="category === 'appliance-housings' && subCategory === 'double-oven-&-microwave'">
            <p>A double oven and microwave housing, offers the perfect location for your cooking appliances and really makes a feature of them in your kitchen. The perfectly positioned pan drawer below the oven also gives some additional storage for baking trays, right where you need them most.</p>        
        </ng-container>
        <ng-container *ngIf="category === 'appliance-housings' && subCategory === 'refrigeration'">
            <p>If you are looking to fully integrate your fridge freezer, so that it is completely out of sight then we have a choice of 20 different fridge freezer housings, that can do just that. The first thing you need to decide on is the type of fridge freezer that you want to integrate, then choose a unit with the door combination that matches.</p>
        </ng-container>
        <ng-container *ngIf="category === 'appliance-housings' && subCategory === 'compact-appliance'">
            <p>If you are looking to install a compact appliance into your kitchen, like a coffee machine, microwave, warming drawer or even a compact 46cm tall single oven then our compact appliance housings, were made with these appliances in mind.</p>
        </ng-container>
        <ng-container *ngIf="category === 'appliance-housings' && subCategory === 'x2-compact-appliance'">
            <p>If you are considering installing a few compact appliances into your kitchen, like a coffee machine, microwave, warming drawer or even a compact 46cm tall single oven then our compact appliance housings, were made with multiple compact appliances in mind.</p>        
        </ng-container>

        <ng-container *ngIf="(category === 'tall' && !subCategory) || (category === 'tall' && subCategory === 'all')">
            <p>With over 100 Tall Unit options available. We offer a selection of Tall Units with factory fitted Kessebohmer storage solutions to maximise the potential storage space in your kitchen.</p>
            <p class="bold">Heights available:</p>
            <p>1970mm tall units are designed for use with 720mm wall units.</p>
            <p>2150mm tall units are designed for use with 900mm wall units.</p>
        </ng-container>
        <ng-container *ngIf="category === 'tall' && subCategory === 'broom'">
            <p>If you need space in your kitchen to hide away the larger objects that are used occasionally then a tall broom cupboard can offer you the space required. With over {{diy.ranges}} units available in 6 different widths, you'll be spoilt for choice!</p>
        </ng-container>
        <ng-container *ngIf="category === 'tall' && subCategory === 'curved-unit'">
            <p>If you are looking to soften the end of a kitchen run, so that it matches the curves of the worktop, then a curved unit will help you do that and make your kitchen look even more stunning in the process! </p>
        </ng-container>
        <ng-container *ngIf="category === 'tall' && subCategory === 'larder'">
            <p>If you are looking for a tall larder unit with more shelves than you could possibly ever use then this unit is for you! We have over 70 tall larder units available in 3 different widths, so no matter how big or small you need the unit to be, we've got you covered. Not sure which tall larder unit you need? This article explains the different types, in more detail.</p>
        </ng-container>
        <ng-container *ngIf="category === 'tall' && subCategory === 'pull-out-larder'">
            <p>A tall pull out larder unit is a great addition to any kitchen, to add some practical storage where the contents are transported out of the unit in seconds and showcased on the multiple shelves. The only hard decision to make here is, which one of the 21 tall larder units are you going to choose? Not sure which tall larder unit you need? This article explains the different types, in more detail.</p>
        </ng-container>
        <ng-container *ngIf="category === 'tall' && subCategory === 'internal-drawer'">
            <p>Drawers in a kitchen are always really handy for storing cutlery and other large items, such as pans & plates. But, with our tall internal drawer units, you get even more practical drawers but nicely hidden away until you need them. If you want to be super organised then add one of these units into your kitchen plan today! Not sure which tall larder unit you need? <a href="https://advice.diy-kitchens.com/customer-questions/choose-larder-unit/" target="_blank" title="Internal drawers">This article</a> explains the different types, in more detail.</p>
        </ng-container>
        <ng-container *ngIf="category === 'tall' && subCategory === 'angled'">
            <p>If space is a little tight at the end of your kitchen run, such as where a door opens then you could choose to fit a tall angled unit instead.  This will  give you a little more room and you won't be presented with the flat side of a unit, when you enter the kitchen. Not sure which tall unit you need? <a href="https://advice.diy-kitchens.com/customer-questions/choose-larder-unit/" target="_blank" title="Tall Angled units">This article</a> explains the different types, in more detail.</p>
        </ng-container>
        <ng-container *ngIf="category === 'tall' && subCategory === 'wine-rack'">
            <p>If you have either a 150mm or 300mm space to fill in your kitchen and you're struggling for ideas, then a tall wine rack unit might just be the answer! It can store all your wine, squash bottles & fizzy pop in an easily accessible area, whilst freeing up some valuable worktop space.</p>
        </ng-container>
        <ng-container *ngIf="category === 'tall' && subCategory === 'tandem-larder-units'">
            <p>A tall tandem larder unit is one of the most easily accessible larders we provide, as when you open the door, shelving comes towards you from inside the unit, as well as the shelving that is attached to the door. Picking items out of a unit, does not get any easier than this! Not sure which tall larder unit you need? <a href="https://advice.diy-kitchens.com/customer-questions/choose-larder-unit/" target="_blank" title="Tall Lader Unit">This article</a> explains the different types, in more detail.</p>
        </ng-container>
        <ng-container *ngIf="category === 'tall' && subCategory === 'quadrant'">
            <p>If you want to make a display feature at the end of your run of units, then one of our tall end quadrant units with open shelving, will help you create a feature in your kitchen that you can adorn to your liking!</p>
        </ng-container>

        <ng-container *ngIf="(category === 'tall-corner' && !subCategory) || (category === 'tall-corner' && subCategory === 'all')">
            <p>With over 100 Tall Unit options available. We offer a selection of Tall Units with factory fitted Kessebohmer storage solutions to maximise the potential storage space in your kitchen.</p>
            <p class="bold">Heights available:</p>
            <p>1970mm tall units are designed for use with 720mm wall units.</p>
            <p>2150mm tall units are designed for use with 900mm wall units.</p>
        </ng-container>
        <ng-container *ngIf="category === 'tall-corner' && subCategory === 'walk-in-larder'">
            <p>If you are looking at maximising storage space as much as possible in your kitchen and want all the items in there to be easily accessible, then our walk in larders are the perfect solution!</p>
        </ng-container>
        <ng-container *ngIf="category === 'tall-corner' && subCategory === 'diagonal'">
            <p>When planning your new kitchen, storage space is very important. If you don't quite have enough space for a walk in larder then our diagonal corner units are the next best thing and still give masses of space, to store your everyday kitchen items in.</p>
        </ng-container>
        <ng-container *ngIf="category === 'tall-corner' && subCategory === 'tall-corner'">
            <p>If you require some additional tall storage in your kitchen and space is limited, then our tall corner larder unit is definitely worth looking at. With it's multiple shelves and different door configurations, you could integrate this unit into your kitchen very easily.</p>
        </ng-container>
        <ng-container *ngIf="category === 'tall-corner' && subCategory === 'reduced-depth-tall-corner'">
            <p>If you have a small obstruction, behind where a normal 560mm deep tall unit would go then there is always the option of using a reduced depth (300mm deep) unit, to get around any obstacles. Just use a tall end panel on the exposed end to cover the gap then any other full depth units, can be positioned right next to it.</p>
        </ng-container>

        <ng-container *ngIf="(category === 'dresser' && !subCategory) || (category === 'dresser' && subCategory === 'all')">
            <p>We offer a comprehensive range of dresser units across two heights, with several glazed, tambour and curved unit options.</p>
            <p>1205mm High Dresser Units are designed for use with 1970mm (+150mm Legs) Tall Units & 720mm High Wall Units.</p>
            <p>1390mm High Dresser Units are designed for use with 2150mm (+150mm Legs) Tall Units & 900mm High Wall Units.</p>
        </ng-container>
        <ng-container *ngIf="category === 'dresser' && subCategory === 'open'">
            <p>An open worktop dresser unit does take up a little worktop space but it creates a really nice area for storing or displaying items that you want to be an integral part of the kitchen. We have 4 different open dresser units to choose from in widths of 500mm or 600mm. All the dresser units come with a compact drawer underneath them as well, which gives a little more storage. Not sure which worktop dresser unit is right for you? <a href="https://advice.diy-kitchens.com/customer-questions/worktop-dresser-unit/" target="_blank" title="Worktop Dresser Unit">This article</a> will explain all the types available with some examples installed in some of our customers' kitchens.</p>
        </ng-container>
        <ng-container *ngIf="category === 'dresser' && subCategory === 'tambour'">
            <p>If you want to add something a little different into your kitchen design then a tambour dresser unit is that unit! You could choose to have this unit as an open unit but if you have items like toasters and kettles that you want to store out of sight, then you can simply close the door on this unit, by rolling the door down. Not sure which worktop dresser unit is right for you? <a href="https://advice.diy-kitchens.com/customer-questions/worktop-dresser-unit/" target="_blank" title="Tambour Dresser Unit">This article</a> will explain all the types available with some examples installed in some of our customers' kitchens.</p>
        </ng-container>
        <ng-container *ngIf="category === 'dresser' && subCategory === 'standard'">
            <p>If you require a more traditional worktop dresser unit then we have both standard and glazed options available. Both these dresser units have a door on the front and if you are thinking of having unit lighting in them then a glazed door would look great and would really make the unit stand out.</p>
            <p>Not sure which worktop dresser unit is right for you? <a href="https://advice.diy-kitchens.com/customer-questions/worktop-dresser-unit/" target="_blank" title="Which Dresser Unit?">This article</a> will explain all the types available with some examples installed in some of our customers' kitchens. </p>
            <p class="bold">All the glazed doors in our units have transparent perspex and not glass.</p>
        </ng-container>
        <ng-container *ngIf="category === 'dresser' && subCategory === 'mantles'">
            <p>If you are planning on having a Range cooker in your kitchen then why not choose one of our ready built mantles? They really do look amazing! If you want to see some of our mantles installed in our customers' kitchens then please <a href="https://advice.diy-kitchens.com/customer-questions/line-up-kitchen-mantle/" target="_blank" title="Ready Built Mantles">click here</a></p>
        </ng-container>
        <ng-container *ngIf="category === 'dresser' && subCategory === 'mantles-canopies'">
            <p>If you really want to add a wow factor to your kitchen then why not install a cooker canopy to house your extractor. Or, if you are planning on having a Range cooker in your kitchen then why not choose one of our ready built mantles? They really do look amazing! If you want to see some of our mantles installed in our customers' kitchens then please <a href="https://advice.diy-kitchens.com/customer-questions/line-up-kitchen-mantle/" target="_blank" title="Mantle Canopies">click here</a>.</p>
        </ng-container>

        <ng-container *ngIf="(category === 'accessories' && !subCategory) || (category === 'accessories' && subCategory === 'all')">
                <p>Panels are used to match the style of any exposed sides of your units to the style of your kitchen doors.</p>
                <p>A choice of post and column styles can be used to further enhance the decorative look of your kitchen.</p>
                <p>Note, not all post styles are available in every kitchen style.</p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'decorative-items'">
            <p>If you are looking to add something extra to your kitchen like a <a href="https://advice.diy-kitchens.com/customer-questions/how-to-construct-a-plate-rack/" target="_blank" title="Plate Rack">plate rack</a>, <a href="https://advice.diy-kitchens.com/customer-questions/what-is-a-pilaster/" target="_blank" title="Pilaster">pilaster</a> or a <a href="https://advice.diy-kitchens.com/customer-questions/tongue-groove-end-panels-radius-feature-ends/" target="_blank" title="Radius Feature End Post">radius feature end post</a> then this is the page for you! If you want to know more about these items and see some examples in our customers' kitchens then you can always have a look our on <a href="https://advice.diy-kitchens.com/" target="_blank" title="Advice">advice site here</a>.</p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'appliance-doors'">
            <p>Many of your integrated appliances, do not require a unit, just the space left between 2 units and an appliance door. All the appliance doors that we sell can be found below. If you are not sure which appliance needs a unit or a door then <a href="https://advice.diy-kitchens.com/customer-questions/kitchen-appliances-to-be-placed-in-a-unit/" target="_blank" title="Integrated Appliances">this article</a> will be able to advise you.</p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'pilaster'">
            <p>A pilaster is a rectangular architectural element that resembles a column, featuring a base, shaft, and capital. Unlike a full column that is freestanding and often structural, a pilaster is decorative and projects only slightly from the wall, serving primarily an aesthetic purpose.</p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'column'">
            <p>A decorative column is an architectural element that imitates the structure and details of a traditional column but serves primarily for aesthetic purposes rather than as a structural support.</p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'flying-shelf'">
            <p>A flying-shelf is a shelving unit that appears to be floating or suspended without visible supports like brackets or legs.</p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'corner-posts'">
            <p>If you have units that return (meet at a right angle), then you are going to need a corner post. Corner posts are supplied with our corner base units but additional ones are available below, if you need them, as well as corner posts for wall & tall units. If you want to read some more on corner posts and how they are used in a kitchen then <a href="https://advice.diy-kitchens.com/customer-questions/what-are-kitchen-corner-posts-and-why-are-they-needed/" target="_blank" title="Corner Posts"></a>this article will explain more.</p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'end-panels'">
            <p>At then end of a kitchen run, you're either going to need an end panel, to finish off an exposed end or a filler panel, if there is a small gap between your last unit and a wall. This article on <a href="https://advice.diy-kitchens.com/customer-questions/end-a-kitchen-run/" target="_blank" title="End Panels">'how to end a kitchen run'</a> will show you the main ways in which you can do this. </p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'kitchen-bottle-rack'">
            <p>If you are looking to declutter your kitchen, especially getting rid of bottles off the worktops then a wine rack base unit is an ideal investment! Not only can the wine rack hold bottles of red wine (white wine really needs to be in the wine cooler), but you can also put all your squash and fizzy pop bottles in there too. Conveniently stored and easy to access.</p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'kitchen-cooker-canopy'">
            <p>Cooker canopies aren't just functional, they're a statement piece in the modern kitchen. Serving to effectively vent out unwanted fumes and odors, these canopies also elevate the overall design and feel of your space. Whether you're seeking a sleek contemporary look or a timeless traditional touch, our range of cooker canopies promises both performance and aesthetic appeal. Make it the crowning jewel of your kitchen and cook in style and comfort.</p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'kitchen-cornice-&-pelmet'">
            <p>A cornice is a decorative molding typically placed at the top of walls or cabinets, used to provide a neat finish and add architectural detail.</p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'kitchen-end-panel'">
            <p>An end panel, often referred to as a cabinet side or side panel, is a finished piece of material that is used to cover the exposed side of a cabinet.</p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'kitchen-fly-over-shelf'">
            <p>A fly-over shelf is a type of shelving that extends over and across other furniture pieces, appliances, or areas, typically without legs or vertical supports on its outer edge. It appears to "fly" above its surroundings, providing additional storage or display space without occupying additional floor space.</p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'kitchen-mantle'">
            <p>If you are planning on having a Range cooker in your kitchen then why not choose one of our ready built mantles? They really do look amazing! If you want to see some of our mantles installed in our customers' kitchens then please <a href="https://advice.diy-kitchens.com/customer-questions/line-up-kitchen-mantle/" target="_blank" title="Kitchen Mantle">click here</a></p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'kitchen-pilaster'">
            <p>A finished piece of material that is used to cover the exposed side of a cabinet.</p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'kitchen-platerack'">
            <p>A plate rack is a storage solution designed specifically for holding plates in an upright position, usually allowing for easy access and display.</p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'kitchen-plinth'">
            <p>A plinth, also known as a toe kick or kickboard, is a horizontal panel positioned at the base of cabinets, cupboards, or other furniture pieces.</p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'cornice-pelmet-plinths'">
            <p>The 3 key elements for finishing off your kitchen are the cornice, pelmet and <a href="https://advice.diy-kitchens.com/customer-questions/plinths/" target="_blank" title="Plinth">Plinth</a> is a must and can be bought in unit & door material, as well as <a href="https://advice.diy-kitchens.com/customer-questions/plinths/#mirrored" target="_blank" title="Mirrored Plinth">mirrored plinth</a> too. Cornice & pelmet are used above and below your wall units to help frame them, but this is optional, depending on your kitchen design. If you're not sure what pelmet, plinth & cornice are, then <a href="https://advice.diy-kitchens.com/customer-questions/what-is-cornice-pelmet-plinth/" target="_blank" title="Cornice, Pelmets, and Plinths">this article</a> will explain some more.</p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'mantles-canopies'">
            <p>If you really want to add a wow factor to your kitchen then why not install a cooker canopy to house your extractor. Or, if you are planning on having a Range cooker in your kitchen then why not choose one of our ready built mantles? They really do look amazing! If you want to see some of our mantles installed in our customers' kitchens then please <a href="https://advice.diy-kitchens.com/customer-questions/line-up-kitchen-mantle/" target="_blank" title="Mantle Canopies">click here</a>.</p>
        </ng-container>
        <ng-container *ngIf="category === 'accessories' && subCategory === 'carcase-material'">
            <p>If you are wanting to get a little creative and make something yourself, that matches the look and feel of the units then we can provide plinths, fillers, panels & shelves in carcase material.</p>
        </ng-container>
        <ng-container *ngIf="(category === 'mid-height') || (category === 'mid-height' && subCategory === 'all')">
            <p>With 20 mid height unit options available, it's easy for you to create raised areas or stunning islands in your dream <a routerLink="/kitchens">kitchen</a>.</p>
            <p>Mid height units are supplied 1250mm high (+150mm Legs).</p>
            <p>
                Our mid height units are also available dry assembled which means they are delivered rigid, but not glued.
                This is especially useful if your kitchen layout has an awkward section.
            </p>
        </ng-container>
    </div>
    <div class="range-selector">
        <div *ngIf="activeRange" class="active-range">
            <div class="cabinet-image">
                <div class="carcase-image">
                    <img [src]="carcaseImage()" [alt]="activeRange?.carcaseColour"/>
                </div>

                <div *ngIf="activeRange?.rangeDetail?.isTrueHandleless" class="profile-image">
                    <img src="https://static.diy-kitchens.com/carcases/true_handleless_unit_profile_silver_3q_500px.png" [alt]="activeRange?.carcaseColour + ' Profile'"/>
                </div>

                <div [ngClass]="{'door-image': !activeRange?.rangeDetail?.isTrueHandleless, 'th-door-image': activeRange?.rangeDetail?.isTrueHandleless}">
                    <img [src]="unitDoorImage()" [alt]="activeRange?.rangeColour + ' Door'"/>
                </div>
            </div>

            <!-- <img src="{{getRangeDoorImage()}}" [alt]="activeRange.range.name + ' ' + activeRange.rangeColour"/> -->
            <div class="range-details">
                <div class="range-text">
                    <span class="range-name">{{ activeRange.range.name }} {{ activeRange.rangeColour }}</span>
                    <span class="unit-name">{{ activeRange.carcaseColour }} Unit</span>
                </div>
                <button mat-stroked-button class="skinny" color="secondary" (click)="selectRange()">Edit Style</button>
            </div>
        </div>
    </div>
</header>
<section class="filters content-contained" *ngIf="items">
    <component-option-bar [filters]="filterFields" (filterChange)="onFilterChange()" (sortChange)="onSortChange($event)" (softCloseChange)="onSoftCloseChange($event)" [showActiveRange]="false"></component-option-bar>
</section>
<section class="lightgrey">
    <div class="products content-contained" *ngIf="items">
        <component-product-listing-column *ngFor="let entry of filteredItems" [activeRange]="activeRange" [type]="type"
            [products]="products" [product]="entry">
        </component-product-listing-column>
    </div>
    <div *ngIf="items && !hasResults" class="no-results content-contained">
        <h3>No items were found that match your filters...</h3>
        <p>Try removing a filter <span *ngIf="activeRange">or changing the range style.</span></p>
        <h4 *ngIf="activeRange">Currently selected range</h4>
        <div *ngIf="activeRange" class="active-range">
            <img src="{{getRangeDoorImage()}}" [alt]="activeRange.range.name + ' ' + activeRange.rangeColour"/>
            <div class="range-text">
                <span class="range-name">{{ activeRange.range.name }} {{ activeRange.rangeColour }}</span>
                <span class="unit-name">{{ activeRange.carcaseColour }} unit</span>
            </div>
            <button mat-flat-button color="primary" (click)="selectRange()">Edit Style</button>
        </div>
    </div>
</section>
