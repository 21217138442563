import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'component-dialog-iframe',
    templateUrl: './dialog-iframe.component.html',
    styleUrls: ['./dialog-iframe.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DialogIFrameComponent {
    public url: SafeUrl;

    public closeTop: string = '15px';
    public closeRight: string = '15px';

    constructor(
        private domSanatizer: DomSanitizer,
        private dialogRef: MatDialogRef<DialogIFrameComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data && data.url) {
            this.url = this.domSanatizer.bypassSecurityTrustResourceUrl(data.url);
        }

        if (data && data.closePosition) {
            if (data.closePosition.top) {
                this.closeTop = data.closePosition.top;
            }
            
            if (data.closePosition.right) {
                this.closeRight = data.closePosition.right;
            }
        }
    }

    public close() {
        this.dialogRef.close(null);
    }
}
