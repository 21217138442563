import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Config } from '@app/config';
import { StringHelper, URLHelper } from '@app/utilities/helpers';

import { CatalogueService } from '@app/services/catalogue';
import { ActiveRange, ProductType } from '@app/services/catalogue/models';
import { IMedia, IProductSpecification } from '@app/services/catalogue/models/product.models/common';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';

@Component({
    selector: 'page-handle-detail',
    templateUrl: './handles-detail.page.html',
    styleUrls: ['./handles-detail.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class HandleDetailPage implements OnInit, OnDestroy {
    public ProductType = ProductType;
    public item: any;
    public qty: number = 1;
    public type: ProductType = ProductType.HANDLES;
    public activeRange: ActiveRange;
    private routeSubscription: Subscription;
    private activeRangeSubscription: Subscription;
    private routeHistory: RouteHistory[] = [{ title: 'Handles', route: '/handles' }];
    public productImages: IMedia[] = [];

    constructor(
        private config: Config,
        private urlHelper: URLHelper,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                this.getCatalogueData(route.params.details || null);
            }
        );

        this.activeRangeSubscription = this.catalogueService.activeRange$
            .subscribe((rangeInfo: ActiveRange) => {
                this.activeRange = rangeInfo;

            });
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }

        if (this.activeRangeSubscription) {
            this.activeRangeSubscription.unsubscribe();
            this.activeRangeSubscription = null;
        }
    }

    private getCatalogueData(details: string) {
        this.catalogueService.getHandleByDetails(details)
            .then((handle: any) => {
                let routeHistory = this.routeHistory.slice();

                if (handle) {
                    this.item = handle;
                    this.item.qty = 1;
                    this.item._cost = this.item.price || 0;

                    this.productImages = this.item.extended_details?.images?.map(image => ({
                        image: `${this.config.api.endpoints.cdn}/assets/images/products/handles/cimageurl/${image}`
                    }));

                    if (!this.item.extended_details?.images?.length && this.item.media) {
                        this.productImages = [this.item.media];
                    }

                    const removeDuplicates = (arr: IProductSpecification[]): IProductSpecification[] => {
                        return arr.filter((obj, index) => {
                            return !arr.some((otherObj, otherIndex) => {
                                return otherIndex < index && obj.name === otherObj.name && obj.value === otherObj.value;
                            });
                        });
                    }

                    if (this.item.extended_details?.specifications) {
                        this.item.extended_details.specifications = removeDuplicates(this.item.extended_details.specifications);
                    }

                    this.item.extended_details?.specifications?.forEach((specification => {
                        specification.name = StringHelper.titleCase(specification.name.replace(/_/g, ' '));
                    }));

                    const category = StringHelper.spaceToDash(this.item.cat);
                    routeHistory.push({
                        title: this.item.cat,
                        route: `/handles/${category}`
                    });

                    routeHistory.push({
                        title: this.item.product_code,
                        route: this.urlHelper.fullRoute(this.item)
                    });

                    this.navigationService.setNavigation({
                        title: this.item?.title,
                        metaTags: [{ name: 'no-meta', content: 'no metadata' }]
                    });

                    const metaDescription = `The ${this.item.desc} from the DIY Kitchens ${this.item.cat} range`;
                    this.navigationService.setMetaTags([
                        { property: 'og:description', content: metaDescription },
                        { name: 'Description', content: metaDescription },
                        { property: 'og:locale', content: 'en_GB' },
                        { property: 'og:type', content: 'website' },
                        { property: 'og:site_name', content: 'DIY Kitchens' },
                        { property: 'og:url', content: this.item?.title },
                        { property: 'og:image', content: this.item?.media?.image },
                        { property: 'og:image:secure_url', content: this.item?.media?.image },
                        { property: 'og:image:width', content: '600' },
                        { property: 'og:image:height', content: '400' }
                    ]);
                }

                this.navigationService.setRouteHistory(routeHistory);
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
