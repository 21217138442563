@import '/src/theme/variables';

component-page-not-found {
    header {
        &.content-contained {
            p {
                &:last-of-type {
                    padding: 0;
                }
            }
        }
    }

    .row {
        justify-content: space-evenly;
        margin: 25px 0;

        ul {
            list-style-type: none;
            padding-inline-start: 0;

            li {
                padding: 5px 0;
            }
        }
    }
}