<header class="content-header-corner content-header-background">
    <header class="content-contained">
        <h1>DUROPAL LAMINATE WORKTOPS
        </h1>
        <p>Hardwearing Duropal laminate worktops are the perfect alternative to granite and quartz worktops - at a fraction
            of the price.Manufactured using Melamine phenolic resin,the hardest of all synthetically produced organic
            materials, Duropal's easy-to-clean and care for worktops deliver high levels of scratch and wear resistance to
            both heat and chemicals. This comprehensive Duropal kitchen worktop collection gives you a multitude of durable
            choices, ranging from worktops with a modern look and feel, to those with a traditional appeal - all designed to
            complement your kitchen units perfectly.</p>
    </header>
</header>

<section class="lightgrey">
    <div class="contained flex-space-between main-content-row">
        <div>
            <div class="note-info-container">
                <h5>Please note:</h5>
                <p>
                    Due to our competitive pricing structure for Laminate worktops, any item in this section can only be
                    ordered as part of a full kitchen order.
                </p>
            </div>
        </div>
        <div class="products">
            <div class="doors-listing-top-banner">
                <div class="doors-listing-item">
                    <img src="https://static.diy-kitchens.com/assets/images/banner/listings-banner-trustpilot.jpg"
                        alt="See how we compare">
                </div>
                <div class="doors-listing-item">
                    <img src="https://static.diy-kitchens.com/assets/images/banner/offer-banner_real-customers_oct2020.jpg"
                        alt="View real customer kitchens">
                </div>
            </div>
            <div class="alert-info-section">
                <p><strong>Important note: </strong>All gloss worktops will scratch and will show scratches more easily.
                    Additional care should be taken accordingly in both usage and installation.</p>
            </div>
            <div class="categories">
                <div class="item-container" *ngFor="let item of items">
                    <div class="item">
                        <div class="image">
                            <img src="{{item.image}}" [alt]="item.name"/>
                        </div>
                        <div class="details">
                            <div class="flex-space-between">
                                <div class="product-brand-cnt">
                                    <img src="https://static.diy-kitchens.com/assets/images/laminateworktops/duropal-logo.svg"
                                        height="24" alt="Duropal Logo">
                                </div>
                                <div class="price">
                                    <p *ngIf="item.price">
                                        <span class="common-price-span">from </span>
                                        <span class="common-price-span" [innerHTML]="item.price | currencyFormat:true:true"></span>
                                    </p>
                                    <p class="common-small" *ngIf="!item.price">Unavailable</p>
                                </div>
                            </div>

                            <span class="common-item-title">{{ item.name }}</span>
                            <div class="description" *ngIf="item.isStandard && item.isSquareEdge">{{ item.name }} is available in both Quadra & Square Edge profiles.</div>
                            <div class="description" *ngIf="item.isStandard && !item.isSquareEdge">{{ item.name }} is only available in the Quadra profile.</div>
                            <div class="description" *ngIf="!item.isStandard && item.isSquareEdge">{{ item.name }} is only available in the Square Edge profile.</div>
                            <div class="actions">
                                <a [routerLink]="edgeProfileRoute(item)" class="chunky">
                                    <button mat-flat-button color="primary" class="chunky" *ngIf="item.isStandard && item.isSquareEdge">
                                        Choose Style
                                    </button>
                                    <button mat-flat-button color="primary" class="chunky" *ngIf="!item.isStandard || !item.isSquareEdge">
                                        View Style
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
