@import '/src/theme/variables';

page-sinks-and-taps-listing {
    padding-top: 12px;

    .products {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 150px 0 15px;
        max-width: calc(100% - 280px);
        min-height: 100vh;
    }
    
    .banners {
        margin: 15px 0 0 40px;

        .panel {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            gap: 20px;
        }

        @media only screen and (max-width: 1000px) {
            margin-left: 0;
        }

        @media only screen and (max-width: 768px) {
            .panel {
                flex-direction: column;
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .products {
            padding-top: 60px;
            margin-left: 0;
            max-width: 100%;
        }
    }

    .no-results {
        padding: 20px;
    }
}
