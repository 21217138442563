@import '/src/theme/variables';

component-product-image-gallery {

    .image-gallery-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;

        .focussed-image {
            overflow: hidden;
            width: 100%;
            text-align: center;

            img {
                max-width: 100%;
                max-height: 40vh;
                width: auto;
                height: auto;
                cursor: pointer;
            }

        }

        .thumbnails {
            margin-top: 5px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;

            img {
                border: 1px solid color('brand-dark');
                max-width: 15%;
                max-height: 100px;
                width: auto;
                height: auto;
                cursor: pointer;
            }

            .selected {
                border: 2px solid color('brand-primary');
            }

        }
    }
}