<header class="content-contained">
    <h1>Privacy Policy</h1>
    <p>DIY Kitchens.com is totally committed to protecting the privacy of our site visitors and customers. The Diy-Kitchens.com team members are customers themselves of other Internet sites and fully appreciate and respect the importance of privacy on the Internet. We will not disclose information about our customers to third parties except where it is part of providing a service to you - e.g. arranging for a product to be sent to you, carrying out credit and other security checks and for the purposes of customer research and profiling or where we have your express permission to do so.</p>
</header>

<hr class="narrow" />

<section class="content-contained">
    <h3>What information do we collect?</h3>
    <p>We collect information from you when you place an order.</p>
    <p>When ordering or registering on our site, as appropriate, you may be asked to enter your: name, e-mail address,
        phone number or credit card information.</p>
    <h3>How do we protect your information?</h3>
    <p>We implement a variety of security measures to maintain the safety of your personal information when you place an
        order or enter, submit, or access your personal information.</p>
    <p>We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket
        Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by
        those authorised with special access rights to such systems, and are required to keep the information
        confidential.</p>
    <p>After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not
        be kept on file for more than 30 days.</p>
    <h3>Do we use cookies?</h3>
    <p>Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through
        your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser
        and capture and remember certain information. We use cookies to help us remember and process the items in your
        shopping cart. Click <a routerLink="/cookie-policy" title="Cookie Policy">here</a> to read more about the types of cookies that we use.</p>
    <h3>Google Display Advertising</h3>
    <p>We use Google's Display Advertising feature that allows us to remarket to visitors of this website by the use of
        cookies. Visitors can opt out of Google Display Advertising by using the <a
            href="https://www.google.com/settings/ads/onweb/?hl=en_GB&amp;sig=ACi0TCg4VHd0m21adYaAg9yNDxJFQTiTSdorWliAwloVbVUTgmchctwp2GJgAjNVdX5eTnvU_liK7Nw1FGvAO2MinLUps2ts4sc__SSyVF7FCU6gglVus727677iacDuZaJDGl9s2vkWKy9gmbFnJTyVHgeNeMVjqG3WCh_5C10Gqr73KbdwD0__6mf5ax3UV_6tHffg9HzX"
            title="Ads Preferences Manager">Ads Preferences Manager here</a>. </p>
    <h3>Google Advertising Features</h3>
    <p>We use Google Advertising Features to monitor visits to our website, collect demographic data and interest data
        and how our visitors find us and use our website in order for us to make improvements that will benefit you.
        Google stores all this data anonymously. You can prevent Google from storing this data <a
            href="https://tools.google.com/dlpage/gaoptout/" title="Opt Out" target="_blank">here</a>.</p>
    <h3>Google Analytics</h3>
    <p>User visitor data is passed to Google Analytics, this includes the pages you visit, your language preference,
        your location, device and Internet browser details, and so on. This data is shared with Google Analytics and is
        kept for up to 26 months after you last visited the website. You can opt out of Google Analytics <a
            href="https://tools.google.com/dlpage/gaoptout?hl=en"
            title="Google Analytics">here</a>. </p>
    <h3>Promotional Email Campaign Subscriptions</h3>
    <p>When you subscribe to our emails in the subscription box on our website, our email client will collect and manage
        the following information when you receive an email from us. Email opens &amp; time opened, links clicked,
        unsubscribes, the email client/web browser you used, operating system and the device you used to read the email.
        By signing up to our emails, you agree to this information being recorded by our email client, to allow us to
        keep our emails relevant and readable on all devices. </p>
    <h3>Do we disclose any information to outside parties?</h3>
    <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This
        does not include trusted third parties who assist us in operating our website, conducting our business, or
        servicing you, so long as those parties agree to keep this information confidential. We may also release your
        information when we believe release is appropriate to comply with the law, enforce our site policies, or protect
        ours or others rights, property, or safety. However, non-personally identifiable visitor information may be
        provided to other parties for marketing, advertising, or other uses.</p>
    <h3>Online privacy policy only</h3>
    <p>This online privacy policy applies only to information collected through our website and not to information
        collected offline.</p>
    <h3>Terms and conditions</h3>
    <p>Please also visit our Terms and Conditions section establishing the use, disclaimers, and limitations of
        liability governing the use of our website at:<br /><a routerLink="/terms-and-conditions"
            title="Terms &amp; Conditions">Terms &amp; Conditions</a></p>
    <h3>Your consent</h3>
    <p>By using our site, you consent to our web site privacy policy.</p>
    <h3>Changes to our privacy policy</h3>
    <p>If we decide to change our privacy policy, we will post those changes on this page. This policy replaces all
        other versions of our privacy policy and is correct as of 9th May 2018.</p>
    <h3>Contacting us</h3>
    <p>If there are any questions regarding this privacy policy you may contact us using the information below.</p>
    <p>Diy-Kitchens.com,<br />
        Unit 26a Langthwaite Grange Industrial Estate,<br /> South Kirkby,<br />
        Pontefract,<br /> West Yorkshire,<br /> WF9 3NR,<br />
        United Kingdom<br /><br />
        <strong>e: </strong> <a class="black" href="mailto:sales@diy-kitchens.com?subject=Questions" title="Email Sales">sales&#64;diy-kitchens.com</a><br />
        <strong>t: </strong> <a class="black" href="tel:01977 608418" title="Phone After Sales">01977 608418</a> (For after sales and enquiries - orders can not be place over the phone.)</p>
    <h3>Copyright Notice</h3>
    <p>This website (DIY Kitchens.com) including its source code and design is subject to the protection of the
        copyright laws of the United Kingdom and other countries. Copyright of this site belongs to DIY Kitchens Ltd and
        may not be used, sold, licensed, transferred, copied or reproduced in whole or in part in any manner or form or
        in or on any media to any person other than in accordance with the terms of the Owner's agreement or otherwise
        without the prior written consent of the Owner.</p>
    <h3>Requesting information</h3>
    <p>You have the right to request from us, the personal information that we have about you. If you wish to get a copy
        of this information, please write in to the address below with your request. We will also request that you
        verify your identity with 2 forms of ID such as a passport, birth certificate or driving license etc., before we
        disclose this information to you.</p>
    <p>DIY Kitchens,<br />Unit 26 Lidgate Crescent,<br />Langthwaite Business Park,<br />South Kirkby,<br />West
        Yorkshire,<br />WF9 3NR.</p>
    <h3>Links to other websites</h3>
    <p>On occasions, this site or our advice site may link off to other 3rd party websites to assist you. DIY Kitchens
        is not responsible or liable for the privacy or security of these sites. The decision to visit any 3rd party
        sites, through external links, is entirely at your own discretion and responsibility.</p>
</section>

<component-latest-offers></component-latest-offers>
