import { NgModule } from '@angular/core';

import { MonekService } from './monek.service';

@NgModule({
    providers: [
        MonekService
    ]
})
export class MonekServiceModule { }
