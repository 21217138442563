import { Component, Inject, Signal, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { StringHelper } from '@app/utilities/helpers';

import { Config } from '@app/config';

import { BasketService } from '@app/services/basket';
import { DialogService } from '@app/services/dialog';
import { IAccessory, isAccessory } from '@app/services/catalogue/models/product.models';
import { BasketItem, IBasketUpdateItem } from '@app/services/basket/models';
import { ActiveRange, CabinetColour, ProductType } from '@app/services/catalogue/models';

import { CostCalculator } from '@app/services/catalogue/cost-calculator';

@Component({
    selector: 'component-dimensions-input-calculator-dialog',
    templateUrl: './dimensions-input-calculator.dialog.component.html',
    styleUrls: ['./dimensions-input-calculator.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DimensionsInputCalculatorDialogComponent {
    public dimensionsInputForm: FormGroup;

    public item: IAccessory | BasketItem;
    public product: IAccessory;
    public carcaseColours: CabinetColour[] = [];
    public activeRange: Signal<ActiveRange>;
    public qty: number = 1;
    public price: number = 0;
    public type = ProductType.ACCESSORIES;

    public minimumSize = {
        width: 100,
        height: 100
    }

    public maximumSize = {
        width: 2020,
        height: 2770
    }

    public isAccessory = isAccessory;

    private costCalculator = new CostCalculator();
    private baseCost: number = 0;

    constructor(
        private formBuilder: FormBuilder,
        private basketService: BasketService,
        private dialogService: DialogService,
        private dialogRef: MatDialogRef<DimensionsInputCalculatorDialogComponent>,
        private config: Config,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data) {
            if (data.item) {
                if (isAccessory(data.item)) {
                    this.item = data.item as IAccessory;
                    this.product = data.item;
                } else {
                    this.item = data.item as BasketItem;
                    if (data.product && isAccessory(data.product)) {
                        this.product = data.product;
                    }
                }
                this.baseCost = this.product?.price || 0;
            }

            if (data.carcaseColours && Array.isArray(data.carcaseColours)) {
                this.carcaseColours = data.carcaseColours;
            }

            if (data.activeRange) {
                this.activeRange = data.activeRange;
            }
        }

        this.dimensionsInputForm = this.formBuilder.group({
            carcaseColour: new FormControl(this.getInitialColour()),
            width: new FormControl(this.item?.width !== '0' ? this.item.width : this.minimumSize.width, [Validators.min(this.minimumSize.width), Validators.max(this.maximumSize.width)]),
            height: new FormControl(this.item?.height !== '0' ? this.item.height : this.minimumSize.height, [Validators.min(this.minimumSize.height), Validators.max(this.maximumSize.height)]),
            qty: new FormControl(1)
        });

        this.calculateCost();
    }

    public close() {
        this.dialogRef.close();
    }

    public getValidationErrorMessage(formControlName: string): string {
        const control = this.dimensionsInputForm.get(formControlName);
        let errorString = '';
        if (control && control?.touched) {
            if (control?.errors) {
                if (control.errors.required) {
                    errorString += 'This field is required. ';
                }
                if (control.errors.min) {
                    errorString += `${StringHelper.titleCase(formControlName)} minimum value is ${this.minimumSize[formControlName]}`;
                }
                if (control.errors.max) {
                    errorString += `${StringHelper.titleCase(formControlName)} maximum value is ${this.maximumSize[formControlName]}`;
                }
            }
        }

        return errorString;
    }

    public getDescription(): string {
        if (!isAccessory(this.item) && this.item.description) {
            return this.item.description;
        } else {
            return this.product?.desc || '';
        }
    }   

    public getExtendedDetail(): string[] {
        if (isAccessory(this.product) && 
            this.product.extended_details && 
            Array.isArray(this.product.extended_details.details)) {
                return this.product.extended_details.details;
        }
        return [];
    }

    public calculateCost(): void {
        if (this.dimensionsInputForm.valid) {
            const width = this.dimensionsInputForm.get('width').value;
            const height = this.dimensionsInputForm.get('height').value;
            this.item.width = width;
            this.item.height = height;
            this.price = this.costCalculator.getCarcaseMaterialCost(this.baseCost, width, height);
            if (isAccessory(this.item)) {
                this.item._cost = this.price;
            }
        }
    }

    public updateQty() {
        this.qty = this.dimensionsInputForm.get('qty').value || 1;
    }

    public getUpdateParams(): IBasketUpdateItem {
        return {
            cost: this.price || (this.item as BasketItem).cost,
            carcaseColour: this.dimensionsInputForm.get('carcaseColour').value || (this.item as BasketItem).carcaseColour,
            width: this.dimensionsInputForm.get('width').value || (this.item as BasketItem).width,
            height: this.dimensionsInputForm.get('height').value || (this.item as BasketItem).height,
        }
    }

    public updateBasketItem() {
        if (!isAccessory(this.item) && this.dimensionsInputForm.valid && this.price) {
            this.basketService.updateItem(this.item.id, {
                cost: this.price,
                carcaseColour: this.dimensionsInputForm.get('carcaseColour').value,
                width: this.dimensionsInputForm.get('width').value,
                height: this.dimensionsInputForm.get('height').value
            })
                .then(() => this.close())
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        }
    }

    public onStatusReceived(status: 'success' | 'failure') {
        if (status === 'success') {
            if (this.config.isBrowser) {
                setTimeout(() => this.close(), 500);
            } else {
                this.close();
            }
        }
    }

    public onCarcaseColourChange() {
        if (isAccessory(this.item)) {
            this.item.carcaseColour = this.dimensionsInputForm.get('carcaseColour').value;
        }
    }

    private getInitialColour(): string | null {
        if (this.item?.carcaseColour) {
            const matchedColour = this.carcaseColours.find((colour) => colour.colour === this.item.carcaseColour);
            if (matchedColour) {
                return this.item.carcaseColour;
            }
        }
        if (this.activeRange && this.activeRange()?.carcaseColour) {
            return this.activeRange()?.carcaseColour;
        }
        return null;
    }
}
