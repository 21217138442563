<header>
    <h2>Careers at DIY Kitchens</h2>
    <p>DIY Kitchens is one of the UK's leading kitchen manufacturers and online retailers, a family owned, rapidly
        expanding business with three state of the art manufacturing sites in South Kirkby and Sherburn in Elmet.</p>
</header>
<section class="intro">
    <p>
        We're really proud to produce stunning kitchens that our customers love, and customer demand has never been
        higher. As a result we're recruiting for the role listed in our "Current Vacancies" section below.
    </p>
    <p>
        These are all full time, permanent contracts, great opportunities for both men & women who are real team
        players with an eye for detail and a positive can do attitude. Successful applicants will have plenty of
        scope to push for promotion, developing both their career and their earning potential.
    </p>
    <p class="bold">
        If you are interested in any of these vacancies then please send your CV and covering letter to
        <a href="mailto:careers@diy-kitchens.com" title="Email your CV and covering letter">careers&#64;diy-kitchens.com</a>
    </p>
</section>
<h4>Current vacancies</h4>
<p>
    All the available job vacancies within DIY Kitchens are listed below.
</p>

<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Kitchen Showroom Advisors
            </mat-panel-title>
            <mat-panel-description>
                South Kirkby
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div>

            <h3>Kitchen Showroom Advisors</h3>
            <table>
                <tbody>
                    <tr>
                        <td><strong>Position Type:</strong></td>
                        <td>Permanent</td>
                    </tr>
                    <tr>
                        <td><strong>Location:</strong></td>
                        <td>South Kirkby</td>
                    </tr>
                    <tr>
                        <td><strong>Hours:</strong></td>
                        <td>Upto 40 hours per week</td>
                    </tr>
                    <tr>
                        <td><strong>Salary:</strong></td>
                        <td>Salary Competitive</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            <h4>Job Specification</h4>
            <p>We are a family run business that has been manufacturing high specification kitchens and kitchen units
                since
                1982. Our history lies in supplying premium high street retailers throughout the UK. We are proud to say
                many of the staff that joined us back then are still with us today!<br /><br />Every weekend, our showroom
                is
                full of customers excitedly shopping for their brand-new kitchen. As our showroom is so busy we are
                expanding our team and are looking for part time weekend and full time Customer Showroom Advisors.
                You'll be
                responsible for welcoming and engaging all our potential customers, whilst delivering an exceptional
                service
                that we are passionate and proud of.<br /><br />You'll show our customers around the showroom and our
                kitchen
                ranges and introduce our planning package.<br /><br /><b>Main responsibilities:</b></p>
            <ul>
                <li>To welcome and approach customers as they walk in to the showroom and take control in a friendly
                    way.
                </li>
                <li>Introduce the Company to every customer, explaining who we are and what sets us apart from our
                    competitors.</li>
                <li>To introduce our new planning/design tool to all potential customers.</li>
                <li>Create quotes and give comparisons against our competitors.</li>
                <li>Explain why DIY Kitchens is best in class and why it sets us apart from other companies.</li>
            </ul>
            <p><b>Relevant skills:</b></p>
            <p><b>Mandatory:</b></p>
            <ul>
                <li>Strong interpersonal and sociable skills, an effective communicator with an eye for detail.</li>
                <li>Smart appearance with a can-do attitude.</li>
            </ul>
            <p><b>Essential:</b></p>
            <ul>
                <li>Previous sales or planning/design experience.</li>
            </ul>
            <p><b>Desirable:</b></p>
            <ul>
                <li>Previous customer facing work experience within a similar environment.</li>
            </ul>
            <p><b>Some benefits of working with us:</b></p>
            <ul>
                <li>Fantastic pay - with real potential to develop and grow with the business.</li>
                <li>Free parking.</li>
                <li>Free drinks.</li>
                <li>Pension scheme.</li>
            </ul>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Production Operatives
            </mat-panel-title>
            <mat-panel-description>
                South Kirkby
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div>
            <h3>Production Operatives</h3>
            <table>
                <tbody>
                    <tr>
                        <td><strong>Position Type:</strong></td>
                        <td>Permanent</td>
                    </tr>
                    <tr>
                        <td><strong>Location:</strong></td>
                        <td>South Kirkby</td>
                    </tr>
                    <tr>
                        <td><strong>Hours:</strong></td>
                        <td>Monday – Friday 8.00am - 4.30pm, (a flexible approach to working hours is required, as
                            occasional overtime/weekend work maybe required).</td>
                    </tr>
                    <tr>
                        <td><strong>Salary:</strong></td>
                        <td>Competitive</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            <h4>Job Specification</h4>
            <p>We are looking for various 'Production operatives' to join our teams. The role will include:<br /></p>
            <ul>
                <li>Picking, using a storage location system.</li>
                <li>Wrapping and assembly (using hand held tools).</li>
                <li>Assembling units (using hand tools).</li>
                <li>Other Warehouse duties will include goods in, picking, working part of a two-man team lifting
                    Kitchen
                    doors.</li>
                <li>Keeping a clean and tidy work space.</li>
                <li>Ensure a flexible and common sense approach to Health &amp; Safety notifying Team Managers and/or
                    other
                    staff members of any type of hazard which cannot be dealt with by the post holder area.</li>
            </ul>

            <p><b>What you will need:</b></p>

            <ul>
                <li>A proven ability to communicate within a small team.</li>
                <li>To prioritise and manage own workload often working to tight deadlines.</li>
                <li>Work on own initiative as well as part of a team.</li>
                <li>Basic numeracy and literacy skills.</li>
                <li>Work quickly and accurately.</li>
                <li>Work under pressure in a variety of different roles/departments.</li>
                <li>Some heavy lifting will be required.</li>
            </ul>

            <p><b>Desirable:</b><br /></p>
            <ul>
                <li>Experience of working within a Warehouse in a similar role.</li>
                <li>Experienced in previous roles which have been physically challenging.</li>
            </ul>

            <p>This is full time permanent role, immediate start for the right candidates, dependant on notice period.
            </p>
            <p>Working hours are Monday - Friday 8.00am - 4.30pm, (a flexible approach to working hours is required, as
                occasional overtime/weekend work maybe required).</p>

        </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Reach Truck Driver
            </mat-panel-title>
            <mat-panel-description>
                South Kirkby
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div>
            <h3>Reach Truck Driver</h3>
            <table>
                <tbody>
                    <tr>
                        <td><strong>Position Type:</strong></td>
                        <td>Permanent</td>
                    </tr>
                    <tr>
                        <td><strong>Location:</strong></td>
                        <td>South Kirkby</td>
                    </tr>
                    <tr>
                        <td><strong>Hours:</strong></td>
                        <td>Working hours are Monday to Friday 8.00am - 4.30pm, (a flexible approach to working hours is
                            required, as occasional overtime/weekend work maybe required).</td>
                    </tr>
                    <tr>
                        <td><strong>Salary:</strong></td>
                        <td>Competitive</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            <h4>Job Specification</h4>
            <p>We are looking for a Reach Truck Driver to join our team. The role will include:</p>

            <ul>
                <li>Driving a Reach truck.</li>
                <li>Loading and unloading pallets.</li>
                <li>Other Warehouse duties will include goods in, put away and picking orders.</li>
                <li>Keeping a clean and tidy work space.</li>
            </ul>

            <p><b>What you will need:</b></p>

            <ul>
                <li>A Reach truck Licence (is essential).</li>
                <li>A proven ability to communicate within a small team.</li>
                <li>To prioritise and manage own workload often working to tight deadlines.</li>
                <li>Work on own initiative as well as part of a team.</li>
                <li>Basic numeracy and literacy skills.</li>
                <li>Work quickly and accurately.</li>
                <li>Work under pressure in a variety of different roles/departments.</li>
                <li>Some heavy lifting will be required.</li>
            </ul>

            <p><b>Desirable:</b></p>

            <ul>
                <li>Experienced in previous roles which have been physically challenging.</li>
                <li>This is full time permanent role, immediate start for the right candidates, dependant on notice
                    period.
                </li>
            </ul>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Hand Sprayer
            </mat-panel-title>
            <mat-panel-description>
                South Kirkby
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div>

            <h3>Hand Sprayer</h3>
            <table>
                <tbody>
                    <tr>
                        <td><strong>Position Type:</strong></td>
                        <td>Permanent</td>
                    </tr>
                    <tr>
                        <td><strong>Location:</strong></td>
                        <td>South Kirkby</td>
                    </tr>
                    <tr>
                        <td><strong>Hours:</strong></td>
                        <td>Working a shift pattern of 06:00 -14:00 and 14:00 - 22:00 the following week. Immediate
                            start
                            for the right individual.</td>
                    </tr>
                    <tr>
                        <td><strong>Salary:</strong></td>
                        <td>Competitive</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            <h2>Job Specification</h2>
            <p>We are looking for an experience furniture Hand Sprayer to join our team. The main purpose of the role is
                to
                spray cabinets to the highest quality whilst adhering to all Health &amp; safety regulations.</p>

            <p><b>Main Responsibilities:</b></p>

            <ul>
                <li>To deliver very good quality products to set timescales.</li>
                <li>Experienced and skilled in the spraying of paint/lacquers.</li>
                <li>Able to apply both first and finishing coats of paint.</li>
                <li>The ability to maintain quality whilst under pressure.</li>
                <li>Fully support the Production Management team.</li>
                <li>Ability to follow safety procedures when using flammable/hazardous materials</li>
                <li>Adhere to procedures regarding the use and care of equipment and materials.</li>
                <li>Additional duties as and when requested by your Manager.</li>
            </ul>

            <p><b>About You:</b></p>

            <ul>
                <li>A self-starter, team player who works well in a fast pace working environment.</li>
                <li>Excellent time keeper with flexibility to work overtime when required.</li>
                <li>Experience in woodwork (desired).</li>
            </ul>
            <div><br /></div>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Class 2 Drivers
            </mat-panel-title>
            <mat-panel-description>
                South Kirkby
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div>
            <h3>Class 2 Drivers</h3>
            <table>
                <tbody>
                    <tr>
                        <td><strong>Position Type:</strong></td>
                        <td>Permanent</td>
                    </tr>
                    <tr>
                        <td><strong>Location:</strong></td>
                        <td>South Kirkby</td>
                    </tr>
                    <tr>
                        <td><strong>Hours:</strong></td>
                        <td>40 hours per week</td>
                    </tr>
                    <tr>
                        <td><strong>Salary:</strong></td>
                        <td>Excellent</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            <h4>Job Specification</h4>
            <p>We are looking to recruit experienced Drivers!</p>
            <p>If you’re an experienced, careful and efficient commercial driver with a passion for delivering, not only
                important loads but also exceptional customer service, then this could be right up your street.</p>
            <p><b>So what will you actually be doing?</b></p>
            <ul>
                <li>You’ll be responsible for the safe, efficient and economic driving of a range of our vehicles on
                    various
                    business delivery, you’ll be able to accurately ensure all deliveries are signed for. Extremely
                    customer
                    focussed, you’ll also take care to ensure your load arrives on time and in good condition.</li>
                <li>You’ll be responsible for the safety and legal compliance of your vehicle, making checks to ensure
                    weight and load distribution complies with axle and gross weight guidance and that you loads are
                    secure.
                    You’ll also be responsible for maintaining a clean and tidy vehicle.</li>
                <li>A good communicator, you’ll proactively keep the Traffic Office informed of any delays, accidents,
                    breakdowns or discrepancies while at all times adhering to the Ultima quality standards and
                    operational
                    procedures.</li>
            </ul>
            <p><b>Who’s right for the job?</b></p>
            <ul>
                <li>With at least 2 years’ HGV Class 1 driving experience and a licence with no more than 6 penalty
                    points,
                    you will have also successfully completed the 35 hours periodic CPC training. Training in the use of
                    digital tachographs would also be useful, but not essential.</li>
                <li>With a positive, flexible approach and great team work you’ll be able to communicate effectively
                    with
                    the Traffic Office, including identifying and alerting them to any potential business opportunities.
                </li>
                <li>Diligent, organised and with a good eye for detail you’ll accurately manage all your documentation,
                    ensuring it is completed and submitted correctly and on time. You’ll be also be thorough in your
                    safety
                    and compliance checks for both your vehicle and your loads.</li>
            </ul>
            <p><br /></p>

        </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Production Operatives
            </mat-panel-title>
            <mat-panel-description>
                Sherburn-in-Elmet
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div>
            <h3>Production Operatives</h3>
            <table>
                <tbody>
                    <tr>
                        <td><strong>Position Type:</strong></td>
                        <td>Permanent</td>
                    </tr>
                    <tr>
                        <td><strong>Location:</strong></td>
                        <td>Sherburn-in-Elmet</td>
                    </tr>
                    <tr>
                        <td><strong>Hours:</strong></td>
                        <td>40 hours per week</td>
                    </tr>
                    <tr>
                        <td><strong>Salary:</strong></td>
                        <td>Excellent</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            <h4>Job Specification</h4>
            <p><br /><b>We are looking for various 'Production operatives' to join our teams. The role will include:</b>
            </p>
            <ul>
                <li>Picking, using a storage location system</li>
                <li>Wrapping and assembly (using hand held tools)</li>
                <li>Assembling units (using hand tools)</li>
                <li>Other Warehouse duties will include goods in, picking, working part of a two-man team lifting
                    Kitchen
                    doors</li>
                <li>Keeping a clean and tidy work space</li>
                <li>Ensure a flexible and common sense approach to Health &amp; Safety notifying Team Managers and/or
                    other
                    staff members of any type of hazard which cannot be dealt with by the post holder area.</li>
            </ul>
            <p><b>What you will need:</b></p>
            <ul>
                <li>A proven ability to communicate within a small team</li>
                <li>To prioritise and manage own workload often working to tight deadlines</li>
                <li>Work on own initiative as well as part of a team</li>
                <li>Basic numeracy and literacy skills</li>
                <li>Work quickly and accurately</li>
                <li>Work under pressure in a variety of different roles/departments</li>
                <li>Some heavy lifting will be require</li>
            </ul>
            <p><b>Desirable:</b><br /></p>
            <ul>
                <li>Experience of working within Manufacturing or a similar role<br /></li>
                <li>Experienced in previous roles which have been physically challenging</li>
            </ul>
            <p>This is full time permanent role, immediate start for the right candidates, dependant on notice
                period.<br />Working hours are Monday - Friday 8.00am - 4.30pm, (a flexible approach to working hours is
                required, as occasional overtime/weekend work maybe required).<br /></p>

        </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Delivery Driver/Drivers Mate
            </mat-panel-title>
            <mat-panel-description>
                South Kirky & Sherburn-in-Elmet
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div>
            <h3>Delivery Driver/Drivers Mate</h3>
            <table>
                <tbody>
                    <tr>
                        <td><strong>Position Type:</strong></td>
                        <td>Permanent</td>
                    </tr>
                    <tr>
                        <td><strong>Location:</strong></td>
                        <td>South Kirkby &amp; Sherburn-in-Elmet</td>
                    </tr>
                    <tr>
                        <td><strong>Hours:</strong></td>
                        <td>40 hours per week</td>
                    </tr>
                    <tr>
                        <td><strong>Salary:</strong></td>
                        <td>Competitive</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            <h4>Job Specification</h4>
            <p>We are looking to recruit an additional Drivers/Drivers mates as we are expanding our team. If you’re an
                experienced, careful and efficient Driver with a passion for delivering, not only important loads but
                also
                exceptional service, then this could be right up your street</p>
            <p><b>So what will you actually be doing?</b></p>
            <ul>
                <li>This role will include direct customer contact and some manual handling as the main purpose of the
                    role
                    is to deliver kitchen units to our customers across the UK</li>
                <li>You’ll be responsible for the safe, efficient and economic driving of our vehicles on various
                    business
                    delivery and collection tasks<br />You’ll be able to accurately ensure all deliveries are signed for
                    as
                    well as carefully checking goods you collect and recording any damages or shortages</li>
                <li>Delivering world class customer service to our customers, you’ll also take care to ensure your
                    load arrive on time and in good condition</li>
                <li>You’ll be responsible for the safety and legal compliance of your vehicle, making checks to ensure
                    that
                    your loads are secure</li>
                <li>You’ll also be responsible for maintaining a clean and tidy vehicle</li>
                <li>Some heavy lifting will be required</li>
                <li>A good communicator, you’ll proactively keep the office and Customers informed of any delays,
                    accidents,
                    breakdowns or discrepancies while always adhering to the quality standards and operational
                    procedures.
                </li>
            </ul>
            <p><b>Who’s right for the job?</b></p>
            <ul>
                <li>You will need at least 1 years’ driving experience (previous experience advantageous) with a licence
                    with no more than 6 penalty points</li>
                <li>A positive attitude, flexible approach with excellent communication skills</li>
                <li>You will be diligent, organised and with a good eye for detail you’ll accurately manage all your
                    documentation, ensuring it is completed and submitted correctly and on time.</li>
                <li>You’ll be also be thorough in your safety and compliance checks for both your vehicle and your loads
                </li>
            </ul>
            <p>Some overnight working and occasional weekend working will be required during busy times.</p>
            <p>For the right individual training, will be provided, via an experienced driver who will assess driving
                and
                communication between the driver and customers.<br /><br /></p>
        </div>
    </mat-expansion-panel>
</mat-accordion>