import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { DoorsRouting } from './doors.routing';
import { DoorsPage } from './doors.page';
import { DoorsListingPage } from './listing/doors-listing/doors-listing.page';
import { DoorsDetailPage } from './detail/doors-detail/doors-detail.page';

@NgModule({
    imports: [
        SharedModule,
        DoorsRouting
    ],
    declarations: [
        DoorsPage,
        DoorsListingPage,
        DoorsDetailPage
    ]
})
export class DoorsPageModule { }
