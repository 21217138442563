@import '/src/theme/variables';

page-wood-worktops-page {
    .products {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: auto;
        padding: 15px 0 15px 15px;
        max-width: 100%;
        height: fit-content;
    }

    .doors-listing-top-banner {
        display: flex;
        align-items: flex-start;
        gap: 30px;
        padding: 15px 0 30px 0;
        margin: 0;
        position: relative;

        @include responsive-breakpoint-down(md) {
            flex-direction: column;
            gap: 20px;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: -15px;
            right: -15px;
            height: 1px;
            background: rgba(0, 0, 0, .1);
        }

        .doors-listing-item {
            flex: 0 0 calc(50% - 15px);
            max-width: calc(50% - 15px);

            @include responsive-breakpoint-down(md) {
                flex: 1;
                max-width: inherit;
            }

            img {
                max-width: 100%;
                height: auto;
                object-fit: contain;
                object-position: center center;
            }
        }
    }

    .content-header-background {
        background-position-x: 100% !important;
        background-repeat: no-repeat;
        background-size: 50% !important;
        background-image: url(https://static.diy-kitchens.com/categories/worktops/category-images_page-header/category-image_page-header_worktops_wood.png);

        @include responsive-breakpoint-down(xl) {
            padding: 0 30px;
        }
    }

    .categories {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 5px;

        .item-container {
            flex: 0 0 100%;
            width: 100%;

            .item {
                display: flex;
                align-self: flex-start;
                gap: 30px;
                background: transparent;
                box-shadow: none;
                margin-bottom: 2.5rem;

                @include responsive-breakpoint-between(md, lg) {
                    margin-bottom: 1rem;
                }

                @include responsive-breakpoint-down(md) {
                    gap: 20px;
                    margin: 0.75rem 0;
                }

                .product-code {
                    font-size: clamp(0.75rem, 1vw + 0.75rem, 0.75rem);
                    font-weight: 700;
                    letter-spacing: 0.33em;
                    line-height: clamp(1.333rem, 1vw + 1.333rem, 1.333rem);
                    color: #b0b7c1;
                    text-transform: uppercase;
                    margin-bottom: 2.5rem;

                    @include responsive-breakpoint-down(md) {
                        margin-bottom: 2rem;
                    }
                }

                .price {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    @include responsive-breakpoint-down(md) {
                        justify-content: flex-start;
                    }
                }
            }

            .image {
                flex: 0 0 30%;
                max-width: 30%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                padding: 20px 0;
                height: 288px;

                @include responsive-breakpoint-between(md, xl) {
                    height: 184px;
                }

                @media screen and (min-width: 768px) and (max-width: 1000px) {
                    width: 184px;
                    flex: inherit;
                }

                @include responsive-breakpoint-down(md) {
                    height: 112px;
                    padding: 10px 0;
                }

                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                img {
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .details {
                flex: 1;
                padding: 20px 10px 20px 0;

                @include responsive-breakpoint-between(md, lg) {
                    padding: 20px 0;
                }

                @include responsive-breakpoint-down(md) {
                    padding: 10px 0;
                }

                h6 {
                    a {
                        color: color('black');
                        font-family: 'Open Sans';
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 2.08px;
                        text-align: left;
                    }
                }

                p {
                    color: color('black');
                    font-family: 'Open Sans';
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 32px;
                    letter-spacing: 0.48px;
                    text-align: left;
                }

                .actions {
                    display: flex;
                    gap: 30px;
                    justify-content: flex-end;

                    >button {
                        flex: 0 1 50%;
                    }
                }
            }

            .bottom-content {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                justify-content: flex-end;

                .price {
                    h5 {
                        margin-top: 0;
                        color: color('wisteria');
                    }

                    .cabinet-only {
                        margin-bottom: 0;
                    }
                }

                .actions {
                    text-align: right;

                    .chunky {
                        width: initial;

                        &:last-child {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .products {
            margin-top: 60px;
            margin-left: 0;
            max-width: 100%;
            padding: 15px 0;

            .item {
                flex-wrap: nowrap;
                margin: 15px 0;
                max-width: 100%;
            }
        }
    }

    @media only screen and (max-width: 800px) {
        .products {
            .item {
                flex-wrap: wrap;
                margin: 15px 0;

                .left {
                    border: 0;
                    width: 100%;

                    .image {
                        width: 100%;
                    }
                }

                .details {
                    .price {
                        .currency {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
    }

    .no-results {
        padding: 20px;
    }

    .btn-blue {
        font-size: 12px;
        min-height: 50px;
        font-weight: 600;
        letter-spacing: 0.1429em;
        background: color('brand-primary');
        padding: 1rem 1.5rem;
        color: color('white');
        border: 1px solid color('brand-primary');
        text-align: center;
        border-radius: .25rem;
        text-transform: uppercase;
        display: block;
        margin-bottom: 15px;

        &:not(:disabled) {
            color: color('white');
        }

        &:hover {
            background: #00a9f1;
            border-color: #00a9f1;
        }
    }

    .btn-hollow {
        font-size: 12px;
        min-height: 50px;
        font-weight: 600;
        letter-spacing: 0.1429em;
        background: transparent;
        padding: 1rem 1.5rem;
        color: #343a40;
        border: 1px solid #343a40;
        text-align: center;
        border-radius: .25rem;
        text-transform: uppercase;
        display: block;
        margin-bottom: 15px;

        &:not(:disabled) {
            border-color: #343a40;
        }

        &:hover {
            background: #343a40;
            color: color('white');
        }
    }
}
