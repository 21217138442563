import { NgModule } from '@angular/core';

import { RemedialsService } from './remedials.service';

@NgModule({
    providers: [
        RemedialsService
    ]
})
export class RemedialsServiceModule { }
