<div *ngIf="paymentHistoryLoaded">
    <div class="how-to-pay" *ngIf="paymentDetails.oustandingAmount > 0">
        <h3>How would you like to pay?</h3>
        <mat-tab-group [selectedIndex]="selected.value" (selectedTabChange)="tabChange($event)">
            <mat-tab *ngIf="paymentDetails.oustandingAmount < maxChargeableAmount" label="Single Card Payment"></mat-tab>
            <mat-tab label="Bank Transfer (BACS)"></mat-tab>
            <mat-tab label="Multiple Payments"></mat-tab>
        </mat-tab-group>
    </div>
</div>

<div *ngIf="paymentHistoryLoaded">
    <!-- <div *ngIf="paymentDetails.oustandingAmount > 0" class="payment-options">
        <ng-container *ngIf="selectPaymentMode">
            <div>
                How do you want to pay?
            </div>

            <div class="payment-option" [ngClass]="{ 'payment-selected': (prePaymentMode === 1) }" (click)="prePaymentMode = 1">
                <div class="payment_header">
                    Pay in full by card
                </div>
                <div class="split-me">
                    <div>
                        Pay the order in full ({{ paymentDetails.oustandingAmount | currencyFormat }}) on
                        the banking gateway
                    </div>
                    <div>
                        <mat-icon>credit_card</mat-icon>
                    </div>
                </div>
            </div>
            <div class="payment-option" [ngClass]="{ 'payment-selected': (prePaymentMode === 2) }" (click)="prePaymentMode = 2">
                <div class="payment_header">
                    Split Payment by card
                </div>
                <div class="split-me">
                    <div>
                        Select the amount that you want to pay with your card and split it over multiple payments
                    </div>
                    <div>
                        <mat-icon>payments</mat-icon>
                    </div>
                </div>
            </div>
            <div class="payment-option" [ngClass]="{ 'payment-selected': (prePaymentMode === 3) }" (click)="prePaymentMode = 3">
                <div class="payment_header">
                    Pay by bank transfer
                </div>
                <div class="split-me">
                    <div>
                        Our bank details so that you can pay via bank transfer
                    </div>
                    <div>
                        <mat-icon>corporate_fare</mat-icon>
                    </div>
                </div>
            </div>
            
            <button mat-flat-button color="primary" type="submit" (click)="goToPaymentOption(prePaymentMode)" [disabled]="!prePaymentMode">
                Continue to payment
            </button>
        </ng-container>

    </div> -->
    <ng-container *ngIf="paymentDetails.oustandingAmount < 0">
        <!-- DO NOT WANT TO AUTO REFUND -->
        <!-- <H3>Process Refund</H3>
        Due to the changes on your order, the new total for your order is less than what we currently have placed on
        your payments.
        <br />
        <br />
        <div *ngIf="refundableHistory.length > 1">
            Please fill in below where we you would prefer the refund to be allocated to:
            <br />
            <br />
            <div [ngClass]="{ unallocated: (refundLeftToAllocate !== 0), allocated: (refundLeftToAllocate === 0) }">
                Amount left to allocate: {{ refundLeftToAllocate | currencyFormat}}
            </div>

            <div [formGroup]="refundForm" class="refund-options">
                <ng-container *ngFor="let payment of refundableHistory">
                    <div class="payment">
                        <div>
                            <p>{{ payment.paymentReference}} ({{ payment.ccbrand }})</p>
                            <p>Charged {{ getChargedAmount(payment) | currencyFormat }}</p>
                            <p>{{ payment.txndate_processed }}</p>
                            <ng-container *ngIf="payment.txntype === 'sale' && payment.refundedAmount">
                                <br />
                                Already Refunded: {{ payment.refundedAmount | currencyFormat }}
                            </ng-container>
                        </div>
                        <div>
                            <mat-form-field appearance="outline">
                                <input matInput formControlName="paymentRefundControl-{{payment.id}}"
                                    (keydown)="ensureNumeric($event)" (keyup)="recalculateRefundLeftToAllocate(payment)">
                            </mat-form-field>
                        </div>

                        <button mat-flat-button color="primary" (click)="allocateMax(payment)">Allocate Max</button>
                    </div>
                </ng-container>
            </div>
        </div>

        <div *ngIf="refundableHistory.length === 1" class="single-payment-refund-notice">
            We will allocate the refund against the payment that you have made.
        </div>

        <div *ngIf="refundableAmount < startingRefundLeftToAllocate">
            As we do not have sufficient card payment records to fully allocate refunds to, our customer support will contact you to confirm how we can proceed with your refund.
        </div>

        <div class="actions">
            <button mat-flat-button class="chunky" color="primary" *ngIf="refundableHistory.length"
                (click)="refundBasketAndCreateOrder()"
                [disabled]="refundLeftToAllocate !== 0 && (startingRefundLeftToAllocate - refundableAmount !== refundLeftToAllocate)">
                Proceed with refund
            </button>
        </div> -->
    </ng-container>

    <ng-container *ngIf="paymentDetails.oustandingAmount === 0">
        No additional payment is required for your order
        <br />
        <br />

        <button mat-flat-button class="chunky" color="primary" (click)="createOrderNoPayment()"
            *ngIf="!order">Procceed and create
            order</button>
    </ng-container>
</div>
<ng-container *ngIf="!paymentHistoryLoaded">
    Please wait while we load up the payment processing for your order. If this page takes too long to load, please
    contact customer support.
</ng-container>

<div class="payment-options" *ngIf="paymentDetails.oustandingAmount > 0">
    <div *ngIf="paymentMode === 3" class="split-payment">
        <p class="pay-header" *ngIf="!splitPaymentMode">Pay with a single card payment.</p>
        <p class="pay-header" *ngIf="splitPaymentMode">Split your payments between multiple debit/credit cards and/or a
            bank transfer.</p>
        <div *ngIf="!splitPaymentMode">Amount to pay: <strong>{{ paymentDetails.oustandingAmount | currencyFormat }}</strong></div>
        <!-- Dont uncomment this section <div class="payment-info">
            <div>
                As we do not keep card details, please enter a reference (minimum 6 characters) that can be used to identify your payment for any future possible refunds
            </div>
            <br />
            <mat-form-field>
                <mat-label>Payment Reference </mat-label>
                <input matInput [formControl]="paymentReferenceControl"  placeholder="Payment Reference"/>
            </mat-form-field>
        </div> -->
        <div *ngIf="splitPaymentMode">
            <div>Enter the amount of your card payment below (minimum <strong>£200</strong>).</div>
            <div *ngIf="paymentDetails.oustandingAmount > maxChargeableAmount" >Maximum of {{maxChargeableAmount | currencyFormat}}</div>

            <br />
            <mat-form-field>
                <mat-label>Amount to be charged:</mat-label>
                <input matInput [formControl]="paymentAmountControl" placeholder="Amount to be charged"
                    (keydown)="ensureNumeric($event)" (keyup)="ensureMax($event)" />
            </mat-form-field>
        </div>
        <div class="payment-info">

            <button mat-flat-button color="primary" class="chunk payment-button" (click)="payInFull()" *ngIf="!splitPaymentMode">
                Proceed to payment
            </button>

            <button mat-flat-button color="primary" class="chunk payment-button" (click)="proceedToSplitPayment()"
                *ngIf="splitPaymentMode" [disabled]="paymentAmountControl.value < 4">
                Proceed to payment
            </button>
            <div *ngIf="splitPaymentMode">
                Pay <strong>{{ paymentAmountControl.value | currencyFormat }}</strong> now, leaving <strong>{{ paymentDetails.oustandingAmount -
                paymentAmountControl.value | currencyFormat}}</strong> outstanding
            </div>
        </div>
    </div>

    <div *ngIf="paymentMode === 2">
        <p class="pay-header">Pay with a bank transfer</p>
        <div>
            Placing your order will generate an order number, which you must use as a reference on your bank transfer.
        </div>
        <br />
        <div>
            Full details of where to send your payment will be confirmed once you have placed your order.
        </div>

        <br />
        <div class="payment-info">
            <button mat-flat-button color="primary" class="chunk payment-button" (click)="proceedWithBACS()">
                <span *ngIf="!order || !order.orderNumber">Place Order</span>
                <span *ngIf="order && order.orderNumber">View BACS Details</span>
            </button>
        </div>
    </div>
</div>

<ng-container *ngIf="paymentStarted">
    <ng-container *ngIf="!error && paymentMode !== 3">
        Please wait while we load up the payment processing for your order. If this page takes too long to load, please
        contact customer support.
    </ng-container>

    <ng-container *ngIf="error === BasketError.BASKET_LOCKED">
        <p><strong>Please note:</strong> The cutoff period for the order has already passed. If you need something
            changed on your order, please contact customer support.</p>
    </ng-container>

    <ng-container *ngIf="error === BasketError.NO_BASKET_ITEMS">
        <p><strong>Please note:</strong> There are no basket items for this order.</p>
    </ng-container>

    <ng-container *ngIf="error === BasketError.MINIMUM_COST_NOT_MET">
        <p><strong>Please note:</strong> A minimum order value of {{ minOrderValue | currencyFormat }} inc. VAT is
            required to checkout this order. This cost excludes the delivery fee.</p>
    </ng-container>

    <ng-container *ngIf="error === BasketError.NO_UNIT_IN_BASKET">
        <p><strong>Please note:</strong> We don't accept door, appliance, worktop, sink, tap or handle only orders.</p>
    </ng-container>

    <ng-container *ngIf="error === BasketError.CONTAINS_OUT_OF_RANGE_ITEM">
        <p><strong>Please note:</strong> Some of your items are unavailable in specific range</p>
    </ng-container>

    <ng-container *ngIf="error === BasketError.BASKET_NOT_FOUND">
        <p><strong>Please note:</strong> Error occured while attempting to retrieve your basket. Please contact customer
            support</p>
    </ng-container>
</ng-container>
