import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BasketPage } from './basket.page';
import { LinkBasketPage } from './link-basket/link-basket.page';

import { PageNotFoundComponent } from '@app/shared/page-not-found.component';
import { UuidGuard } from '@app/utilities/guards';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: BasketPage,
                data: { vatToggle: true }
            },
            {
                path: ':uuid',
                component: LinkBasketPage,
                canActivate: [UuidGuard],
                data: { vatToggle: true }
            },
            { path: '**', component: PageNotFoundComponent }
        ])
    ],
    exports: [RouterModule]
})
export class BasketRouting { }
