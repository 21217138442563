import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';

import { AboutPrivacyPolicyRouting } from './privacy-policy.routing';
import { AboutPrivacyPolicyPage } from './privacy-policy.page';

@NgModule({
    imports: [
        SharedModule,
        AboutPrivacyPolicyRouting
    ],
    declarations: [
        AboutPrivacyPolicyPage
    ]
})
export class AboutPrivacyPolicyPageModule { }
