<div *ngIf="product" class="product" [promotion]="'product-listing-column'" [request]="'listing-overlay-image'" [productCode]="product.item._code" [item]="product.item">
    <div class="image">
        <img [ngClass]="{ 
            flip: (
                (product.item._hingeSide === Handing.LH && product.item.handingType !== HandingType.DRAWER) || 
                (product.item._hingeSide === Handing.RH && product.item.handingType === HandingType.DRAWER)
            ),
            gray: (isUnit && product.item?.categoryLink !== 'accessories') }"
            [imageSrc]="product.item.media.image" [altSrcs]="[product.item.media.altImage || null]" 
            [alt]="product.item._description" />
        
    </div>
    <!-- <ng-container *ngIf="product.item._description && product.item._description.length > 55; else unabridged">
        <div class="title" [matTooltip]="product.item._description">{{product.item._description.slice(0,50)}}...</div>
    </ng-container> -->
    <!-- <ng-template #unabridged> -->
        <div class="title">{{product.item._description}}</div>
    <!-- </ng-template> -->
    <span *ngIf="!isUnit" class="desc">{{product.item._details}}</span>
    <p class="small">{{product.item._code}}<span *ngIf="product.item.diy_code"> / {{product.item.diy_code}}</span></p>
    <div class="selected-size">
        <span>H {{product.item.height}}</span>
        <span>W {{product.item.width}}</span>
        <span>D {{product.item.depth}}</span>
    </div>
    <a [routerLink]="routeLink" class="link-style" title="More Details">More Details</a>
    <div *ngIf="product.item.width || (product.widths && product.widths.length)" class="width-container">
        <mat-form-field *ngIf="product.widths && product.widths.length > 1" class="simple-select">
            <mat-label>Select width</mat-label>
            <mat-select [value]="product.item.width">
                <mat-option *ngFor="let itemWidth of product.widths" [value]="itemWidth.width"
                (click)="selectWidth(product, itemWidth)"
                >{{itemWidth.width}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="!product.widths || product.widths.length === 1"><label>Width: </label>{{product.item.width}}mm</div>
    </div>
    <div class="config" *ngIf="product.item.softCloseCost > 0 || product.item.handed">
        <ng-container *ngIf="product.item.handed">
            <button mat-stroked-button class="left-hand" [class.selected]="product.item._hingeSide === Handing.LH" (click)="setHinge(product, Handing.LH)">
                Left {{hingeWording(product)}}
            </button>
            <button mat-stroked-button class="right-hand" [class.selected]="product.item._hingeSide !== Handing.LH" (click)="setHinge(product, Handing.RH)">
                Right {{hingeWording(product)}}
            </button>
        </ng-container>
        <button mat-stroked-button *ngIf="product.item.softCloseCost > 0" class="right" [class.selected]="product.item.softClose"
            (click)="toggleSoft(product)">
            Soft Closing
        </button>
    </div>
    <div class="config soft-close" *ngIf="product.item.hingecost">
        <label>Soft Close:</label>
        <div class="options">
            <div class="option">
                <mat-icon *ngIf="product.item.softClose" class="selected">check_box</mat-icon>
                <mat-icon *ngIf="!product.item.softClose" (click)="toggleSoft(product)">check_box_outline_blank</mat-icon>
                <span class="label">Yes</span>
            </div>
            <div class="option">
                <mat-icon *ngIf="!product.item.softClose" class="selected">check_box</mat-icon>
                <mat-icon *ngIf="product.item.softClose" (click)="toggleSoft(product)">check_box_outline_blank</mat-icon>
                <span class="label">No</span>
            </div>
        </div>
    </div>
    <div class="config dry-assembled" *ngIf="product.item.width > 600 && product.item.depth > 600 && !product.item.dryAssembledAlways">
        <label>Dry Assembly: 
            <mat-icon matTooltip="
            Due to the dimensions of this unit, it may prove difficult to fit through the entrance of your property safely. 
            To prevent damage, we recommend that you order this unit as 'Dry Assembled' as this can then be dismantled on site to fit through narrow entrances." 
            matTooltipPosition="above">
                info
            </mat-icon>
        </label>
        <div class="options">
            <div class="option">
                <mat-icon *ngIf="product.item.dryAssembled" class="selected">check_box</mat-icon>
                <mat-icon *ngIf="!product.item.dryAssembled" (click)="toggleDryAssembled(product)">check_box_outline_blank</mat-icon>
                <span class="label">Yes</span>
            </div>
            <div class="option">
                <mat-icon *ngIf="!product.item.dryAssembled" class="selected">check_box</mat-icon>
                <mat-icon *ngIf="product.item.dryAssembled" (click)="toggleDryAssembled(product)">check_box_outline_blank</mat-icon>
                <span class="label">No</span>
            </div>
        </div>
    </div>
    <div class="dims">
        <div *ngIf="product.widths && variants(product).length > 1">
            <mat-expansion-panel>
            <mat-expansion-panel-header>Variants:</mat-expansion-panel-header>
            <div class="variants">
                <button mat-stroked-button *ngFor="let variant of variants(product)"
                    (click)="selectVariant(product, variant)"
                    [class.selected]="variant.code === product.item._code" [title]="variant.code">
                    {{variant.variant}}
                </button>
            </div>
            </mat-expansion-panel>
        </div>
        <div *ngIf="product.item.hole_centre">
            <label>Hole centre:</label>
            <div>{{product.item.hole_centre}}mm</div>
        </div>
        <div *ngIf="product.item._finish">
            <label>Finish:</label>
            <div>{{product.item._finish | sentenceCase}}</div>
        </div>
    </div>
    <div class="align-bottom">
        <!-- <div class="drawer-upgrade" *ngIf="product.item.panDrawerQty > 0 || product.item.drawerBoxQty > 0">
            <button mat-stroked-button color="secondary" class="skinny" (click)="drawerUpgrade()">Drawer Configuration</button>
        </div> -->
        <div class="price" [promotion]="'product-listing-column'" [request]="isUnit ? 'unit-price' : 'door-price'" [productCode]="product.item.unit_code" [item]="product.item" [softClose]="product.item.softClose">
            <strong *ngIf="product.item._cost"
                [innerHTML]="product.item._cost + ((product.item.softClose && product.item.hingecost) ? product.item.originalHingecost || product.item.hingecost : 0)  | currencyFormat:true:true"></strong>
            <p *ngIf="isUnit && !activeRange">Cabinet only price</p>
            <p *ngIf="isUnit && !product.item._cost">Unavailable in this range</p>
            <p *ngIf="!isUnit && !product.item._cost">Currently unavailable</p>
            <div class="warranty-logo" *ngIf="product.item && product.item.brand && product.item.warranty && warranties[product.item.brand] && warranties[product.item.brand][product.item.warranty]">
                <a [href]="warranties[product.item.brand][product.item.warranty].url" target="_blank" title="Warranty">
                    <img [src]="warranties[product.item.brand][product.item.warranty].image" width="100%" [alt]="product.item.brand + ' Warranty'"/>
                </a>
            </div>
        </div>
        <div class="brand-logo" *ngIf="product.item.media.brandImage">
            <img src="{{product.item.media.brandImage}}" alt="Brand logo"/>
        </div>
        <div class="offers" *ngIf="product.item && product.item.brand && offers[product.item.brand]">
            <ng-container *ngFor="let offer of offers[product.item.brand]">
                <a class="offer" *ngIf="offer.fragment !== 'cdaoffer1' || product.item.overlay" [routerLink]="offer.route" [fragment]="offer.fragment">
                    <p [style.backgroundColor]="offer.colour">
                        {{offer.title}}
                        <mat-icon>arrow_forward</mat-icon>
                    </p>
                </a>
            </ng-container>
        </div>

        <div class="actions">
            <div *ngIf="!activeRange" class="select-style">
                <button mat-flat-button color="primary" class="chunky" (click)="selectRange()">
                    Choose Door Style
                </button>
                <a routerLink="/kitchens/allstyles" title="Discover your Kitchen">
                    <button mat-flat-button class="chunky" color="primary">
                        Discover your Kitchen
                    </button>
                </a>
            </div>
            <div *ngIf="activeRange" class="basket-actions">
                <div *ngIf="!showQuantity">
                    <mat-form-field class="quantity">
                        <input matInput placeholder="Qty" [(ngModel)]="qtyInput" (keyup)="validateQty()" (blur)="validateQty(true)" />
                    </mat-form-field>
                </div>
                <component-basket-add-button [activeRange]="activeRange" [type]="type" [item]="product.item" [qty]="qty"></component-basket-add-button>
            </div>
        </div>
    </div>
</div>
