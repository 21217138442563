import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { NavigationService } from '@app/services/navigation';
import { RouteHistory } from '@app/services/navigation/models';

import { StickyTabsPricePromise } from './models';
import { IStickyTab } from '@app/components/sticky-tabs/models';

@Component({
    selector: 'page-about-price-promise',
    templateUrl: './price-promise.page.html',
    styleUrls: ['./price-promise.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AboutPricePromisePage implements OnInit {
    public StickyTabs = StickyTabsPricePromise;
    public tabs: IStickyTab<StickyTabsPricePromise>[] = [
        { title: 'Our Promise', id: StickyTabsPricePromise.PROMISE },
        { title: 'Kitchen Units', id: StickyTabsPricePromise.DOORS },
        { title: 'Sinks & Taps', id: StickyTabsPricePromise.SINKS },
        { title: 'Appliances', id: StickyTabsPricePromise.APPLIANCES },
        { title: 'Worktops', id: StickyTabsPricePromise.WORKTOPS },
        { title: 'Storage Solutions', id: StickyTabsPricePromise.STORAGE },
        // { title: 'Accessories', id: StickyTabsPricePromise.ACCESSORIES },
    ];

    private routeHistory: RouteHistory[] = [
        { title: 'About Diy Kitchens', route: '/about-diy-kitchens' },
        { title: 'Price Promise', route: '/about-diy-kitchens/price-promise' }
    ];

    constructor(
        private navigationService: NavigationService
    ) {    }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: 'Price Promise',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'description', content: 'We are a family run business that has been manufacturing and supplying high specification kitchens to both trade and retail customers for nearly 30 years.'},
            ],
            routeHistory: this.routeHistory
        });
    }
}
