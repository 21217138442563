<div mat-dialog-title>
    <h2>Create Quote</h2>
    <div class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </div>
</div>
<mat-dialog-content>
    <div class="quote-section">
        <div *ngIf="!createQuote">
            <component-customer-lookup (customerFoundEvent)="customerFound($event)"></component-customer-lookup>
        </div>
        <form class="registration-form" [formGroup]="quoteFormGroup" (keydown.enter)="submitQuote()" *ngIf="createQuote" class="quote">
            <div class="quote-email">
                <div>
                    {{customer.email}} 
                </div>
                <button mat-raised-button color="primary" (click)="changeCustomer()">
                    <mat-icon>search</mat-icon> Change
                </button>
            </div>
            <mat-form-field>
                <mat-label>Competitor</mat-label>
                <input matInput formControlName="competitor" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Competitor Price</mat-label>
                <input matInput formControlName="competitorPrice" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Lead Information</mat-label>
                <textarea matInput formControlName="leadInformation" cdkTextareaAutosize cdkAutosizeMinRows="6"
                    cdkAutosizeMaxRows="4"></textarea>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Quote Details (optional)</mat-label>
                <textarea matInput formControlName="quoteDetails" cdkTextareaAutosize cdkAutosizeMinRows="6"
                    cdkAutosizeMaxRows="6"></textarea>
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="submitQuote()">
                <mat-icon>request_quote</mat-icon> Create Quote
            </button>
        </form>
    </div>
</mat-dialog-content>