@import '/src/theme/variables';

.mat-mdc-dialog-container {
    component-youtube-video-dialog {
        display: block;
        height: 100%;

        .mat-mdc-dialog-content {
            display: flex;
            margin: 0 auto;
            padding: 0;
            width: 100%;
            height: 100%;

            iframe {
                flex: 0 0 100%;
                border: 0;

                .embed-responsive-item {
                    margin: 0 auto;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}