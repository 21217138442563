import { Component, ViewEncapsulation, Input, OnChanges, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { Subscription } from 'rxjs';

import { BasketService } from '@app/services/basket';
import { Basket, BasketItem, BasketItemHanding } from '@app/services/basket/models';
import { ICabinet } from '@app/services/catalogue/models/product.models';
import { HandingType } from '@app/services/catalogue/models';
import { CheckoutService } from '@app/services/checkout';
import { DialogService } from '@app/services/dialog';
import { CatalogueService } from '@app/services/catalogue';

@Component({
    selector: 'component-order-summary',
    templateUrl: './order-summary.component.html',
    styleUrls: ['./order-summary.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class OrderSummaryComponent implements AfterViewInit, OnChanges, OnDestroy {
    @Input() step: MatStep;
    @Input() stepSelected: any;
    @Input() stepper: MatStepper;
    @Input() formGroup: FormGroup;
    @Input() order: any;

    private basket: Basket;
    public items: any = [];
    public deliveryCost: number;
    public deliverySurCharge: number;
    public orderCost: number;
    public Math = Math;

    public sections: any = [];

    private paymentHistory: any = [];

    public costs: any = {};

    public Handing = BasketItemHanding;

    public showButton: boolean = true;
    public loading: boolean = true;

    private basketSubscription: Subscription = null;

    constructor(
        private basketService: BasketService,
        private checkoutService: CheckoutService,
        private dialogService: DialogService,
        private catalogueService: CatalogueService
    ) { }

    ngAfterViewInit() {
        if (!this.stepper) {
            this.showButton = false;
        }

        if (!this.order) {
            this.basketSubscription = this.basketService.basket$.subscribe((basket) => {
                if (basket) {
                    this.basket = basket;

                    this.loadOrderSummary();
                }
            });
        }
    }

    ngOnChanges(): void {
        if (this.stepSelected === this.step) {
            this.loadOrderSummary();
        }
    }

    ngOnDestroy() {
        if (this.basketSubscription) {
            this.basketSubscription.unsubscribe();
            this.basketSubscription = null;
        }
    }

    public nextStep() {
        if (this.stepper) {
            this.stepper.next();
        }
    }

    private loadOrderSummary() {
        if (this.basket || this.order) {
            this.loading = true;

            if (this.basket) {
                this.items = this.basketService.clone<BasketItem[]>(this.basket.items);
                this.deliveryCost = this.basket.deliveryCost;
                this.deliverySurCharge = this.basket.deliverySurcharge || 0;
                this.orderCost = this.basket.cost;
                this.paymentHistory = this.basket.paymentHistory;

                this.costs = this.checkoutService.calculateOrderCost(
                    this.orderCost,
                    this.deliveryCost,
                    this.deliverySurCharge,
                    this.paymentHistory
                );

                this.basketService.createBasketSections(this.items, false)
                    .then((sections) => {
                        this.sections = sections;

                        setTimeout(() => {
                            this.loading = false;

                            if (this.formGroup) {
                                this.formGroup.setValue({ complete: true });
                            }
                        }, 100);
                    })
                    .catch((error) => {
                        this.dialogService.error(this.constructor.name, error);

                        setTimeout(() => {
                            this.loading = false;
                        }, 100);
                    });
            } else if (this.order) {
                this.items = this.order.items;
                this.deliveryCost = this.order.deliveryCost;
                this.deliverySurCharge = this.order.deliverySurcharge || 0;
                this.orderCost = this.order.productTotal;
                this.paymentHistory = this.order.paymentHistory;

                this.costs = this.checkoutService.calculateOrderCost(
                    this.orderCost,
                    this.deliveryCost,
                    this.deliverySurCharge,
                    this.paymentHistory
                );

                if (this.items && this.items.length) {
                    this.items.forEach((item) => {
                        item.code = item.productCode;
                    });
                }

                this.basketService.createBasketSections(this.items, false)
                    .then((sections) => {
                        this.sections = sections;

                        setTimeout(() => {
                            this.loading = false;

                            if (this.formGroup) {
                                this.formGroup.setValue({ complete: true });
                            }
                        }, 100);
                    })
                    .catch((error) => {
                        this.dialogService.error(this.constructor.name, error);

                        setTimeout(() => {
                            this.loading = false;
                        }, 100);
                    });
            } else {
                setTimeout(() => {
                    this.loading = false;
                }, 100);
            }
        }
    }

    public hingeWording(item: ICabinet): string {
        return this.catalogueService.getHingeWording(item);
    }
}
