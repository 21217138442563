<div class="product" *ngIf="product">
    <div class="left" [promotion]="'product-listing'" [request]="'listing-overlay-image'" [brand]="product.item.brand" [productCode]="product.item._code" [item]="product.item" [type]="type">
        <div class="image">
            <img [ngClass]="{ flip: (product.options?.hingeSide === Handing.LH),
                gray: (isUnit && product.item?.categoryLink !== 'accessories') }"
                [src]="product.item.media.image"
                [imageSrc]="product.item.media.image"
                [altSrcs]="[product.item.media.altImage || null]"
                [alt]="product.item._description"/>
            <!-- <img class="offer-overlay" *ngIf="product.item.overlay" [src]="'https://static.diy-kitchens.com/assets/images/offers/' + product.item.overlay" alt="Offer"/> -->
        </div>
    </div>
    <div class="details">
        <!-- <div class="price" [promotion]="'appliance-detail'" [request]="'product-price'" [brand]="item.brand" [productCode]="item.code" [item]="item">
            <p [innerHTML]="item.cost | currencyFormat:true:true"></p>
        </div> -->
        <div class="price">
            <div class="price" [promotion]="'product-listing'" [request]="'product-price'" [brand]="product.item.brand" [productCode]="product.item.code" [item]="product.item" [type]="type">
                <strong *ngIf="product.item._cost"
                    [innerHTML]="product.item._cost + ((product.options?.softClose) ? product.item.softCloseCost || 0 : 0) | currencyFormat:true:true"></strong>
                <p *ngIf="isUnit && !activeRange">Cabinet only price</p>
                <p *ngIf="isUnit && !product.item._cost">Unavailable in this range</p>
                <p *ngIf="!isUnit && !product.item._cost">Currently unavailable</p>
            </div>
            <div class="warranty-logo" *ngIf="product.item && product.item.brand && product.item.warranty && warranties[product.item.brand] && warranties[product.item.brand][product.item.warranty]">
                <a [href]="warranties[product.item.brand][product.item.warranty].url" target="_blank" title="Warranty">
                    <img [src]="warranties[product.item.brand][product.item.warranty].image" width="100%" [alt]="product.item.brand + ' Warranty'"/>
                </a>
            </div>
        </div>
        <div class="brand-logo" *ngIf="product.item.media.brandImage">
            <img src="{{product.item.media.brandImage}}" alt="Brand logo"/>
        </div>
        <div class="title">{{product.item._description}}</div>
        <p class="small">{{product.item._code}}</p>
        <div class="offers" *ngIf="product.item && product.item.brand">
            <div class="offers" [promotion]="'product-listing'" [request]="'offer-button'" [brand]="product.item.brand" [productCode]="product.item.code" [type]="type">
            </div>
        </div>
        <p class="desc">{{product.item._details}}</p>

        <div class="dims">
            <div class="flex" *ngIf="product.item.height && !isCarcaseMaterial(product.item)">
                <label>Height:</label>
                <div>{{product.item.height}}mm</div>
            </div>
            <div class="flex" *ngIf="product.item.depth">
                <label>Depth:</label>
                <div>{{product.item.depth}}mm</div>
            </div>
            <div class="flex" *ngIf="(product.item.width || (product.widths && product.widths.length)) && !isCarcaseMaterial(product.item)">
                <label>Width:</label>
                <div *ngIf="!product.widths || product.widths.length === 1">{{product.item.width}}mm</div>
                <div *ngIf="product.widths && product.widths.length > 1" class="widths">
                    <button mat-stroked-button *ngFor="let itemWidth of product.widths"
                        [class.selected]="itemWidth.width === product.item.width" (click)="selectWidth(product, itemWidth)">
                        {{itemWidth.width}}
                    </button>
                </div>
            </div>
            <div class="flex" *ngIf="product.widths && variants(product).length > 1">
                <label>Variants:</label>
                <div class="variants">
                    <button mat-stroked-button class="chunky" *ngFor="let variant of variants(product)"
                        (click)="selectVariant(product, variant)"
                        [class.selected]="variant.code === product.item._code" [title]="variant.code">
                        {{variant.variant}}
                    </button>
                </div>
            </div>
            <div class="flex" *ngIf="product.item.hole_centre">
                <label>Hole centre:</label>
                <div>{{product.item.hole_centre}}mm</div>
            </div>
            <div class="flex" *ngIf="product.item._finish">
                <label>Finish:</label>
                <div>{{product.item._finish | sentenceCase}}</div>
            </div>
        </div>

        <div class="config" *ngIf="product.item.softCloseCost > 0 || product.item.handed">
            <button *ngIf="product.item.handed" class="left-hand" [class.selected]="product.options.hingeSide === Handing.LH"
                (click)="setHinge(product, Handing.LH)">
                HINGED LEFT
            </button>
            <button *ngIf="product.item.handed" class="right-hand"
                [class.selected]="product.options.hingeSide !== Handing.LH" (click)="setHinge(product, Handing.RH)">
                HINGED RIGHT
            </button>
            <button *ngIf="product.item.softCloseCost > 0" class="right" [class.selected]="product.options.softClose"
                (click)="toggleSoft(product)">
                SOFT CLOSING
            </button>
        </div>

        <div class="actions">
            <ng-container *ngIf="!isUnit || activeRange">
                <a [routerLink]="routeLink" title="Details">
                    <button mat-stroked-button color="secondary" class="chunky">
                        Details
                    </button>
                </a>
            </ng-container>
            <ng-container *ngIf="isUnit && !activeRange">
                <button mat-flat-button color="primary" class="chunky" (click)="selectRange()">
                    Choose Door Style
                </button>

                <a routerLink="/kitchens/allstyles" title="Discover your Kitchen">
                    <button mat-flat-button class="chunky" color="primary">
                        Discover your Kitchen
                    </button>
                </a>
            </ng-container>
            @if (isCarcaseMaterial(product.item)) {
                <button mat-flat-button color="primary" (click)="openDimensionsInputDialog()" [disabled]="!this.product.item.price">Choose Size</button>
            } @else {
                <ng-container *ngIf="showQuantity; else onlyAddButton">
                    <div class="qty-and-add-button">
                        <mat-form-field class="quantity">
                            <input matInput type="number" min="1" placeholder="Qty" [(ngModel)]="qty" />
                        </mat-form-field>
                        <component-basket-add-button [activeRange]="activeRange" [type]="type" [item]="product.item" [qty]="qty"></component-basket-add-button>
                    </div>
                </ng-container>
                <ng-template #onlyAddButton>
                    <component-basket-add-button [activeRange]="activeRange" [type]="type" [item]="product.item" [qty]="qty"></component-basket-add-button>
                </ng-template>
            }
        </div>
    </div>
</div>
