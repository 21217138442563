import { NgModule } from '@angular/core';
import { RouterModule, Routes, RouteReuseStrategy } from '@angular/router';

import { AuthGuard, CanDeactivateGuard } from './utilities/guards';
import { ApiDataResolver } from './utilities/resolvers';
import { CustomRouteReuseStrategy, SelectivePreloadingStrategy } from './utilities/strategies';

import { AboutPageModule } from '@app/pages/about/about.page.module';
import { AboutCookiePolicyPageModule } from '@app/pages/about/content/cookie-policy/cookie-policy.page.module';
import { AboutDeliveryInformationPageModule } from '@app/pages/about/content/delivery-information/delivery-information.page.module';
import { AboutKitchenSalesPageModule } from '@app/pages/about/content/kitchen-sales/kitchen-sales.page.module';
import { AboutPrivacyPolicyPageModule } from '@app/pages/about/content/privacy-policy/privacy-policy.page.module';
import { AboutShopOnlinePageModule } from '@app/pages/about/content/shop-online/shop-online.page.module';
import { AboutTermsConditionsPageModule } from '@app/pages/about/content/terms-conditions/terms-conditions.page.module';
import { AboutUnitSpecificationsPageModule } from '@app/pages/about/content/unit-specifications/unit-specifications.page.module';
import { AccessoriesPageModule } from '@app/pages/accessories/accessories.page.module';
import { AccountPageModule } from '@app/pages/account/account.page.module';
import { AppliancesPageModule } from '@app/pages/appliances/appliances.page.module';
import { BasketPageModule } from '@app/pages/basket/basket.page.module';
import { CheckoutPageModule } from '@app/pages/checkout/checkout.page.module';
import { DoorsPageModule } from '@app/pages/doors/doors.page.module';
import { HandlesPageModule } from '@app/pages/handles/handles.page.module';
import { HomePageModule } from '@app/pages/home/home.page.module';
import { KitchensPageModule } from '@app/pages/kitchens/kitchens.page.module';
import { LaminateWorktopsPageModule } from '@app/pages/worktops/laminate-worktops/laminate-worktops.page.module';
import { PlanOnlinePageModule } from '@app/pages/plan-online/plan-online.page.module';
import { ReviewsPageModule } from '@app/pages/customer-reviews/reviews.page.module';
import { SamplesPageModule } from '@app/pages/samples/samples.page.module';
import { SinksAndTapsPageModule } from '@app/pages/sinks-and-taps/sinks-and-taps.page.module';
import { SolidSurfacesPageModule } from '@app/pages/worktops/solid-surfaces/solid-surfaces.page.module';
import { SupportPageModule } from '@app/pages/support/support.page.module';
import { UnitsPageModule } from '@app/pages/units/units.page.module';
import { WoodWorktopsPageModule } from '@app/pages/worktops/wood-worktops/wood-worktops.page.module';
import { WorktopsPageModule } from '@app/pages/worktops/worktops.page.module';

import { PageNotFoundComponent } from '@app/shared/page-not-found.component';

const appRoutes: Routes = [
    {
        path: '',
        loadChildren: () => HomePageModule,
        pathMatch: 'full',
        data: { showBreadcrumb: false }
    },
    {
        path: 'plan-online',
        loadChildren: () => PlanOnlinePageModule,
        pathMatch: 'full',
        data: { section: 'plan' }
    },
    {
        path: 'about-diy-kitchens',
        loadChildren: () => AboutPageModule,
        data: { section: 'about' }
    },
    {
        path: 'kitchen-sales',
        loadChildren: () => AboutKitchenSalesPageModule,
        data: { section: 'about' }
    },
    {
        path: 'terms-and-conditions',
        loadChildren: () => AboutTermsConditionsPageModule,
        data: { section: 'about' }
    },
    {
        path: 'privacy-policy',
        loadChildren: () => AboutPrivacyPolicyPageModule,
        data: { section: 'about' }
    },
    {
        path: 'cookie-policy',
        loadChildren: () => AboutCookiePolicyPageModule,
        data: { section: 'about' }
    },
    {
        path: 'delivery-information',
        loadChildren: () => AboutDeliveryInformationPageModule,
        data: { section: 'about' }
    },
    {
        path: 'shop-online-and-stay-safe',
        loadChildren: () => AboutShopOnlinePageModule,
        data: { section: 'about' }
    },
    {
        path: 'specification',
        loadChildren: () => AboutUnitSpecificationsPageModule,
        data: { section: 'about' }
    },
    {
        path: 'support',
        loadChildren: () => SupportPageModule
    },
    {
        path: 'account',
        loadChildren: () => AccountPageModule
    },
    {
        path: 'myorder/default.asp',
        loadChildren: () => CheckoutPageModule
    },
    {
        path: 'customer-reviews',
        loadChildren: () => ReviewsPageModule
    },
    {
        path: 'kitchens',
        loadChildren: () => KitchensPageModule,
        resolve: { apiData: ApiDataResolver },
        data: { section: 'kitchens' }
    },
    {
        path: 'kitchen-units',
        loadChildren: () => UnitsPageModule,
        resolve: { apiData: ApiDataResolver },
        data: { section: 'buy', subsection: 'units' }
    },
    {
        path: 'kitchen-doors',
        loadChildren: () => DoorsPageModule,
        resolve: { apiData: ApiDataResolver },
        data: { section: 'buy', subsection: 'doors' }
    },
    {
        path: 'appliances',
        loadChildren: () => AppliancesPageModule,
        resolve: { apiData: ApiDataResolver },
        data: { section: 'buy', subsection: 'appliances' }
    },
    {
        path: 'sinks-and-taps',
        loadChildren: () => SinksAndTapsPageModule,
        resolve: { apiData: ApiDataResolver },
        data: { section: 'buy', subsection: 'sinks' }
    },
    {
        path: 'samples',
        loadChildren: () => SamplesPageModule,
        resolve: { apiData: ApiDataResolver },
        data: { section: 'samples' }
    },
    {
        path: 'worktops',
        loadChildren: () => WorktopsPageModule,
        resolve: { apiData: ApiDataResolver },
        data: { section: 'buy', subsection: 'worktops' }
    },
    {
        path: 'laminate-worktops',
        loadChildren: () => LaminateWorktopsPageModule,
        resolve: { apiData: ApiDataResolver },
        data: { section: 'laminate-worktops' }
    },
    {
        path: 'wood-worktops',
        loadChildren: () => WoodWorktopsPageModule,
        resolve: { apiData: ApiDataResolver },
        data: { section: 'wood-worktops' }
    },
    {
        path: 'solid-surfaces',
        loadChildren: () => SolidSurfacesPageModule,
        resolve: { apiData: ApiDataResolver },
        data: { section: 'solid-surfaces' }
    },
    {
        path: 'accessories',
        loadChildren: () => AccessoriesPageModule,
        resolve: { apiData: ApiDataResolver },
        data: { section: 'buy', subsection: 'accessories' }
    },
    {
        path: 'handles',
        loadChildren: () => HandlesPageModule,
        resolve: { apiData: ApiDataResolver },
        data: { section: 'buy', subsection: 'handles' }
    },
    {
        path: 'basket',
        loadChildren: () => BasketPageModule,
        data: { section: 'buy', subsection: 'basket' }
    },
    {
        path: 'checkout',
        loadChildren: () => CheckoutPageModule,
        data: { section: 'buy', subsection: 'checkout' }
    },
    { path: '**', component: PageNotFoundComponent }
];
@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: SelectivePreloadingStrategy,
            scrollPositionRestoration: 'disabled',
            anchorScrolling: 'enabled',
            initialNavigation: 'enabledBlocking',
            canceledNavigationResolution: 'replace',
            paramsInheritanceStrategy: 'always',
            urlUpdateStrategy: 'eager',
        })
    ],
    exports: [RouterModule],
    providers: [
        AuthGuard,
        CanDeactivateGuard,
        SelectivePreloadingStrategy,
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy
        }
    ]
})
export class AppRouting { }
