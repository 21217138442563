import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'component-media-player-dialog',
    templateUrl: './media-player.dialog.component.html',
    styleUrls: ['./media-player.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class MediaPlayerDialogComponent {
    public video: SafeUrl;
    public imageStyle;
    public unsupported: boolean;

    constructor(
        private sanitizer: DomSanitizer,
        private dialogRef: MatDialogRef<MediaPlayerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        if (data.mediaURL.match(/\.mp4/i)) {
            this.video = this.sanitizer.bypassSecurityTrustResourceUrl(data.mediaURL);
        } else if (data.mediaURL.match(/\.png/i) || data.mediaURL.match(/\.jpg/i) || data.mediaURL.match(/\.gif/i)) {
            this.imageStyle = {
                'background-image': 'url(' + data.mediaURL + ')'
            };
        } else {
            this.unsupported = true;
        }
    }

    public closeDialog() {
        this.dialogRef.close();
    }
}
