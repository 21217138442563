<section *ngIf="loading" class="loader">
    <mat-spinner></mat-spinner>
</section>

<div class="contain-content">
    <div class="panel panel-info">
        <div *ngIf="problemOrder">
            <p>We encountered a problem getting the order details. Please try again later.</p>
            <p>If the problem persists, please contact customer support.</p>

            <div class="buttons">
                <button mat-raised-button routerLink="/account/orders">BACK</button>
            </div>
        </div>

        <div *ngIf="order">
            <div class="order-title-order-number">
                <div>
                    <label>Production ref :</label> {{ order.orderNumber }}
                </div>
                <div>
                    <label>Order :</label> {{order.diyId}}<br>
                </div>
            </div>

            <section *ngIf="!order.isDispatched">
                <label>Expected dispatch date: </label>{{order.deliveryDetail}}
            </section>
            <section *ngIf="order.isDispatched && hasReportableItems">
                <div class="buttons reports" *ngIf="!showDamages && !showReturns && !showMissings">
                    <button mat-raised-button (click)="openReturns()">
                        <mat-icon>local_shipping</mat-icon> Return items
                    </button>
                    <button mat-raised-button (click)="openDamages()">
                        <mat-icon>report</mat-icon> Report damages or incorrect items
                    </button>
                    <button mat-raised-button (click)="openMissings()">
                        <mat-icon>rule</mat-icon> Report missing items
                    </button>
                    <!-- & Order extras -->
                </div>
            </section>

            <ng-container *ngIf="showReturns">
                <div class="report-header">
                    <h5>
                        <mat-icon>report</mat-icon> RETURN ITEMS
                    </h5>
                    <p>Please tick each item you wish to return and add a reason why.
                    </p>
                </div>
                <div class="report-footer">
                    <div class="buttons">
                        <div *ngIf="!showSummary" class="mini-summary">
                            {{miniSummary()}}
                        </div>
                        <div>
                            <button mat-raised-button (click)="cancel()" class="go-left">
                                <mat-icon>cancel</mat-icon> CANCEL
                            </button>
                            <button mat-raised-button *ngIf="!showSummary" (click)="gotoSummary()"
                                [disabled]="countReturns()<1 || highlightNeedy()">
                                PROCEED TO SUMMARY <mat-icon>arrow_forward</mat-icon>
                            </button>
                            <button mat-raised-button *ngIf="showSummary" (click)="submitReturns()">
                                SUBMIT RETURNS REQUEST <mat-icon>arrow_forward</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="showDamages">
                <div class="report-header">
                    <h5>
                        <mat-icon>report</mat-icon> REPORT ISSUES
                        <!-- & ORDER EXTRAS-->
                    </h5>
                    <p>Please mark all damaged or missing items.
                        <!-- You can also add extra items below. -->
                    </p>
                </div>
                <div class="report-footer">
                    <div class="buttons">
                        <div *ngIf="!showSummary" class="mini-summary">
                            {{miniSummary()}}
                        </div>

                        <div class="error" *ngIf="totalAttachmentSize > attachmentSizeLimit">Please reduce the size of your attached images. Max {{attachmentSizeLimit / 1024 / 1024}}MB.</div>
                        <!-- <button mat-raised-button (click)="addExtras()" class="go-center"
                            *ngIf="!showSummary">
                            <mat-icon>shopping_basket</mat-icon> ADD EXTRA ITEMS
                        </button>
                        -->
                        <div>
                            <button mat-raised-button (click)="cancel()" class="go-left">
                                <mat-icon>cancel</mat-icon> CANCEL
                            </button>
                            <button mat-raised-button *ngIf="!showSummary" (click)="gotoSummary()"
                                [disabled]="showDamagesProceed()">
                                PROCEED<span class="non-mobile"> TO SUMMARY </span>
                                <mat-icon>arrow_forward</mat-icon>
                            </button>

                            <button mat-raised-button *ngIf="showSummary" (click)="submitDamages()">
                                {{checkoutText}} <mat-icon>arrow_forward</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="showMissings">
                <div class="report-header">
                    <h5>
                        <mat-icon>report</mat-icon> REPORT MISSING ITEMS
                    </h5>
                    <p>Please select all missing items.
                    </p>
                </div>
                <div class="report-footer">
                    <div class="buttons">
                        <div *ngIf="!showSummary" class="mini-summary">
                            {{miniSummary()}}
                        </div>
                        <div>
                            <button mat-raised-button (click)="cancel()" class="go-left">
                                <mat-icon>cancel</mat-icon> CANCEL
                            </button>
                            <button mat-raised-button *ngIf="!showSummary" (click)="gotoSummary()"
                                [disabled]="(countDamages()+newProducts.length)<1 || highlightNeedy()">
                                PROCEED<span class="non-mobile"> TO SUMMARY </span>
                                <mat-icon>arrow_forward</mat-icon>
                            </button>

                            <button mat-raised-button *ngIf="showSummary" (click)="submitDamages()">
                                {{checkoutText}} <mat-icon>arrow_forward</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="new-issue">
                <div *ngIf="showReturns">
                    <strong>Note: As per our terms and conditions, the following items are non returnable and may be
                        excluded from the list below.</strong>
                    <ul>
                        <li>Kitchens Units as these are made bespoke to your order</li>
                        <li>Bespoke painted doors</li>
                        <li>Bespoke painted cornices, plinths, pelmet & decorative items</li>
                        <li>Colour matched doors, cornices, plinths, pelmet & decorative items</li>
                        <li>Any items made as a special request</li>
                        <li>Any other painted items maybe rejected at our discretion</li>
                    </ul>
                </div>
                <table class="order-items-table">
                    <tr class="headers" *ngIf="orderItemsInContext().length>0">
                        <td>Item #</td>
                        <td>Product code</td>
                        <td>Description</td>
                        <td>Qty</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <ng-container *ngFor="let orderItem of orderItemsInContext()">
                        <tr class="item-border" *ngIf="orderItem.show"
                            [ngClass]="{ fade: ((highlightNeedy() && !needsDetails(orderItem)) ), displaynone: (showReturns && !itemReturnable(orderItem)) }">
                            <td
                                [ngClass]="{ 'order-item-selected': orderItem.selected, 'is-component': orderItem.isComponent }">
                                <div class="order-item-rank">
                                    <label class="mobile">Item # </label>{{ orderItem.rank }}
                                </div>
                            </td>
                            <td
                                [ngClass]="{ 'order-item-selected': orderItem.selected, 'is-component': orderItem.isComponent }">
                                <div class="order-item-code">
                                    <label class="mobile">Product code: </label>{{ orderItem.code }}
                                    <br>
                                    <button *ngIf="orderItem.hasComponents && !showSummary"
                                        (click)="setShown(orderItem)" mat-raised-button>
                                        <!-- <span *ngIf="!orderItem.showComponents">
                                            <mat-icon>expand_more</mat-icon> SHOW COMPONENTS
                                        </span>
                                        <span *ngIf="orderItem.showComponents">
                                            <mat-icon>expand_less</mat-icon> HIDE COMPONENTS
                                        </span> -->
                                    </button>
                                </div>
                            </td>
                            <td class="order-item-sub-details">
                                <span>{{ orderItem.description }}</span>
                                <span *ngIf="orderItem.handing"> ({{ orderItem.handing }})</span>

                                <div *ngIf="!orderItem.isCabinet && (orderItem.range || orderItem.colour)"
                                    class="colour-section">
                                    {{ orderItem.range }} - {{ orderItem.colour }}
                                </div>
                                <div *ngIf="orderItem.isCabinet" class="colour-section">
                                    Cabinet: {{orderItem.carcaseColour}}
                                </div>
                                <ng-container *ngIf="!orderItem.requestDetail && !orderItem.remedialDetail">
                                    <div *ngIf="(showReturns || showDamages) && showTextEntry(orderItem)">
                                        <div class="file-inputs">
                                            <input type="file" accept="image/*" [id]="'file-'+orderItem.rank"
                                                (change)="fileUpload($event,orderItem)" />
                                        </div>
                                        <textarea [placeholder]="textPlaceholder()" [(ngModel)]="orderItem.issueText"></textarea>

                                        <div class="problem"
                                            *ngIf="(orderItem.isDamaged || orderItem.isIncorrect) && !orderItem.issueText">
                                            Please describe the issue above.
                                        </div>

                                        <div class="problem"
                                            *ngIf="showReturns && orderItem.reqReturn && !orderItem.issueText">
                                            Please include a reason for the return.
                                        </div>
                                        <div class="files" *ngFor="let file of orderItem.itemAttachments">
                                            <div>
                                                <img [src]="file.binaryContent">
                                                <br>
                                                {{file.name}}
                                                <ng-container *ngIf="file.size">({{displayFileSize(file.size)}}MB)</ng-container>
                                            </div>
                                            <button mat-raised-button *ngIf="!showSummary"
                                                (click)="clearDamageFile(orderItem,file)">
                                                <mat-icon>remove_circle</mat-icon> REMOVE IMAGE
                                            </button>
                                        </div>
                                        <div class="upload-button"
                                            *ngIf="(orderItem.isDamaged || orderItem.isIncorrect) && !showSummary">
                                            <button mat-raised-button (click)="getMediaFile(orderItem)">
                                                <mat-icon>attach_file</mat-icon>

                                                Upload / <mat-icon>camera_alt</mat-icon> Take photo
                                            </button>
                                        </div>
                                        <div class="problem"
                                            *ngIf="(orderItem.isDamaged || orderItem.isIncorrect) && !orderItem.itemAttachments && orderItem.issueText">
                                            Please upload or take a photo of the damage to this item.
                                        </div>
                                    </div>
                                </ng-container>

                                <div *ngIf="orderItem.requestDetail" class="remedial-detail">
                                    <mat-icon>question_answer</mat-icon><br>
                                    {{orderItem.requestDetail}}
                                </div>

                            </td>

                            <td *ngIf="orderItem.isMissing && orderItem.qty>1">
                                <mat-form-field appearance="fill">
                                    <mat-label>Quantity Missing</mat-label>
                                    <mat-select [(ngModel)]="orderItem.qtyMissing">
                                        <mat-option *ngFor="let i of qtyDropdown(orderItem)" value="{{i}}">{{i}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="problem" *ngIf="orderItem.qtyMissing===undefined">
                                    Please enter the quantity missing
                                </div>
                            </td>
                            <td *ngIf="!(orderItem.isMissing && orderItem.qty>1)">
                                <label class="mobile">Quantity: </label>{{orderItem.qty}}
                            </td>

                            <td *ngIf="showSummary && orderItem.isMissing">
                                <mat-checkbox [ngModel]="true"> ITEM MISSING</mat-checkbox>
                            </td>
                            <td *ngIf="!showSummary">
                                <div *ngIf="orderItem.remedialDetail" class="remedial-detail">
                                    <mat-icon>published_with_changes</mat-icon>
                                    <br>Replacement scheduled<br>Ref: {{orderItem.remedialDetail}}
                                </div>
                                <div *ngIf="showDamages && !(orderItem.remedialDetail || orderItem.requestDetail)"
                                    class="line-buttons">
                                    <mat-checkbox [(ngModel)]="orderItem.isDamaged"
                                        (change)="isDamageChanged(orderItem)">
                                        DAMAGED ITEM</mat-checkbox><br>
                                    <mat-checkbox [(ngModel)]="orderItem.isIncorrect"
                                        (change)="isIncorrectChanged(orderItem)">
                                        INCORRECT ITEM</mat-checkbox><br>
                                </div>
                                <div *ngIf="showMissings && !(orderItem.remedialDetail || orderItem.requestDetail)"
                                    class="line-buttons">
                                    <mat-checkbox [(ngModel)]="orderItem.isMissing"
                                        (change)="isMissingChanged(orderItem)">
                                        MISSING ITEM</mat-checkbox>
                                </div>
                                <div *ngIf="showReturns" class="buttons">
                                    <mat-checkbox [(ngModel)]="orderItem.reqReturn">RETURN ITEM</mat-checkbox>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                    <tr class="headers" *ngIf="newProducts.length>0">
                        <td>Extra Items</td>
                        <td>Product code</td>
                        <td>Description</td>
                        <td>Qty</td>
                        <td class="align-right">Cost</td>
                        <td></td>
                    </tr>
                    <ng-container *ngFor="let newItem of newProducts">
                        <tr class="item-border" [ngClass]="{ fade: highlightNeedy()}">
                            <td class="order-item-rank">

                            </td>
                            <td class="order-item-code">
                                <label class="mobile">Product code: </label>{{ newItem.code }}
                            </td>
                            <td>
                                <div class="order-item-sub-details">
                                    <span>{{ newItem.description }}</span>
                                    <span *ngIf="newItem.handing"> ({{ newItem.handing }})</span>

                                    <div *ngIf="!newItem.isCabinet && (newItem.range || newItem.colour)"
                                        class="colour-section">
                                        {{ newItem.range }} - {{ newItem.colour }}
                                    </div>
                                    <div *ngIf="newItem.isCabinet" class="colour-section">
                                        Cabinet: {{newItem.carcaseColour}}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <label class="mobile">Quantity: </label>{{newItem.qty}}
                            </td>
                            <td class="align-right">
                                £{{newItem.cost*newItem.qty | number:'1.2-2'}}
                            </td>
                            <td *ngIf="!showSummary" class="align-right">
                                <button mat-raised-button (click)="removeItem(newItem)">
                                    <mat-icon>delete</mat-icon> Delete
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="newProducts.length>0">
                        <tr>
                            <td colspan="4" class="align-right">Total:</td>
                            <th class="align-right">£{{totalNewProducts() | number:'1.2-2'}}</th>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="4" class="align-right">VAT:</td>
                            <th class="align-right">£{{totalNewProducts()*.2 | number:'1.2-2'}}</th>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="4" class="align-right">Total to pay:</td>
                            <th class="align-right">£{{totalNewProducts()*1.2 | number:'1.2-2'}}</th>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="6" class="align-right">
                                <strong>Note:</strong> Payment for extra items will only be taken if your request is
                                approved.
                            </td>
                        </tr>
                    </ng-container>
                </table>
                <div *ngIf="showSummary && newProducts.length>0">
                    <h2 class="addresses-header">
                        Address details <button mat-button>
                            <mat-icon>edit</mat-icon> Edit
                        </button></h2>
                    <div class="addresses" *ngIf="checkoutCustomer">
                        <div>
                            <strong>Billing Address</strong><br><br>
                            <p>{{checkoutCustomer.forename}} {{checkoutCustomer.surname}}</p>
                            <p *ngIf="checkoutCustomer.billingLine1">
                                {{checkoutCustomer.billingLine1}}</p>
                            <p *ngIf="checkoutCustomer.billingLine2">
                                {{checkoutCustomer.billingLine2}}</p>
                            <p *ngIf="checkoutCustomer.billingTown">
                                {{checkoutCustomer.billingTown}}</p>
                            <p *ngIf="checkoutCustomer.billingCounty">
                                {{checkoutCustomer.billingCounty}}</p>
                            <p *ngIf="checkoutCustomer.billingPostcode">
                                {{checkoutCustomer.billingPostcode}}</p>
                            <br>
                            <p *ngIf="checkoutCustomer.contactHome">
                                Home telephone: {{checkoutCustomer.contactHome}}</p>
                            <p *ngIf="checkoutCustomer.contactMobile">
                                Mobile telephone: {{checkoutCustomer.contactMobile}}</p>
                        </div>
                        <div>
                            <strong>Delivery Address</strong><br><br>
                            <p *ngIf="checkoutCustomer.deliveryLine1">
                                {{checkoutCustomer.deliveryLine1}}</p>
                            <p *ngIf="checkoutCustomer.deliveryLine2">
                                {{checkoutCustomer.deliveryLine2}}</p>
                            <p *ngIf="checkoutCustomer.deliveryTown">
                                {{checkoutCustomer.deliveryTown}}</p>
                            <p *ngIf="checkoutCustomer.deliveryCounty">
                                {{checkoutCustomer.deliveryCounty}}</p>
                            <p *ngIf="checkoutCustomer.deliveryPostcode">
                                {{checkoutCustomer.deliveryPostcode}}</p>
                            <br>
                            <p *ngIf="checkoutCustomer.contactDelivery">
                                Delivery telephone: {{checkoutCustomer.contactDelivery}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section *ngIf="showBBuilder">
    <div class="iframe-backdrop">
        <div class="contain-content">
            <button mat-button (click)="closeExtras()">
                <mat-icon>cancel</mat-icon> CANCEL
            </button>
            <iframe [src]="bbuilderURL" width=100% height=800px></iframe>
        </div>
    </div>
</section>
