import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'component-solid-surface-config-profile-options-dialog',
    templateUrl: './profile-options.dialog.component.html',
    styleUrls: ['./profile-options.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SolidSurfaceConfigProfileOptionsDialogComponent {
    public option: any;
    public options: any;

    constructor(
        private dialogRef: MatDialogRef<SolidSurfaceConfigProfileOptionsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.option = this.data.option;
        this.options = this.data.options;
    }

    public select(opt) {
        this.dialogRef.close({
            option: opt
        });
    }
}
