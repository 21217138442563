import { Component, ViewEncapsulation, Input } from '@angular/core';

import { URLHelper } from '@app/utilities/helpers';

import { BasketService } from '@app/services/basket';
import { BasketItemHanding } from '@app/services/basket/models';
import { CatalogueService } from '@app/services/catalogue';
import { ActiveRange, HandingType, ListingProduct, ProductType } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';

@Component({
    selector: 'component-product-listing-column',
    templateUrl: './product-listing-column.component.html',
    styleUrls: ['./product-listing-column.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ProductListingColumnComponent {
    @Input() activeRange: ActiveRange;
    @Input() type: ProductType;
    @Input() route: string;
    @Input() products: any = [];
    @Input() product: ListingProduct;
    @Input() showQuantity: boolean = false;

    get isUnit(): boolean {
        return this.type === ProductType.CABINETS;
    }

    get routeLink(): string {
        return this.urlHelper.fullRoute(this.product.item);
    }

    public HandingType = HandingType;

    public qtyInput: string = '1';
    public qty: number = 1;

    public warranties: any = {
        CDA: {
            '5-year': {
                url: 'https://www.cda.co.uk/warranty/',
                image: 'https://static.diy-kitchens.com/assets/images/products/appliances/warranties/cda/cda-5-year-warranty.png'
            }
        },
        Zanussi: {
            '1-year': {
                url: 'https://www.zanussi.co.uk/support/warranties/',
                image: 'https://static.diy-kitchens.com/assets/images/products/appliances/warranties/zanussi/zanussi-1-year-warranty.png'
            }
        },
        AEG: {
            '5-year': {
                url: 'https://www.aeg.co.uk/support/warranties/',
                image: 'https://static.diy-kitchens.com/assets/images/products/appliances/warranties/aeg/aeg-5-year-warranty.png'
            }
        }
    }

    public offers: any = {
        CDA: [
            // {
            //     route: '/kitchen-sales',
            //     fragment: 'cdaoffer1',
            //     colour: '#E84339',
            //     title: 'Up to £150 cashback'
            // },
            {
                route: '/kitchen-sales',
                fragment: 'cdaoffer5',
                colour: '#3F6E6B',
                title: 'Extended Warranty'
            },
            // {
            //     route: '/kitchen-sales',
            //     fragment: 'cdaoffer2',
            //     colour: '#222222',
            //     title: '10% Off 3 or more'
            // }
        ],
        AEG: [
            {
                route: '/kitchen-sales',
                fragment: 'aegoffer1',
                colour: '#21201F',
                title: 'Up to £1000 cashback'
            },
            {
                route: '/kitchen-sales',
                fragment: 'aegoffer2',
                colour: '#7444E3',
                title: 'Free dishwasher'
            }
        ]
    }

    public Handing = BasketItemHanding;

    constructor(
        private urlHelper: URLHelper,
        private basketService: BasketService,
        private catalogueService: CatalogueService,
        private dialogService: DialogService
    ) { }

    public validateQty(blur: boolean = false) {
        if (this.qtyInput !== '') {
            if (this.qtyInput === '0') {
                this.qtyInput = '1';
            } else {
                this.qtyInput = this.qtyInput.replace(/[^0-9]/g, '');

                if (this.qtyInput.length > 3) {
                    this.qtyInput = '999';
                }
            }

            this.qty = parseInt(this.qtyInput, 10);
        } else {
            if (blur) {
                this.qtyInput = '1';
            }

            this.qty = 1;
        }
    }

    public selectWidth(entry, width) {
        let newItem = this.products.find((product) => product._code === width.variants[0].code);
        if (newItem) {
            entry.item = newItem;
        }
    }

    public setHinge(product, side) {
        product.item._hingeSide = side
    }

    public toggleSoft(product) {
        product.item.softClose = !product.item.softClose;
    }

    public toggleDryAssembled(product) {
        product.item.dryAssembled = !product.item.dryAssembled;
    }

    public variants(product) {
        if (product.widths && product.widths.length) {
            let selectedWidth = product.widths.find((itemWidth) => itemWidth.width === product.item.width);

            return (selectedWidth) ? selectedWidth.variants : [];
        } else {
            return [];
        }
    }

    public selectVariant(entry, variant) {
        let newItem = this.products.find((product) => product._code === variant.code);
        if (newItem) {
            entry.item = newItem;
        }
    }

    public drawerUpgrade() {
        this.basketService.drawerUpgradeDialog()
            .then(() => {
                // this.basketService.calculateCosts(this.basket);
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public selectRange() {
        this.catalogueService.openRangeSelector();
    }

    public hingeWording(product): string {
        return this.catalogueService.getHingeWording(product.item);
    }
}
