<div mat-dialog-title cdkFocusRegionstart>
    <h2>Drawer Upgrades</h2>
    <div class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </div>
</div>
<mat-dialog-content>
    <p>You can select to upgrade your drawers from the options below. Once the upgrade is selected it is applied to your entire cart and any new items added will automatically be upgraded.</p>
    <p class="small">If you decide you no longer require these upgrades you can easily remove it from your shopping cart.</p>

    <hr />

    <ng-container *ngIf="drawers && !showPanDrawerUpgrades && !showDrawerBoxUpgrades">
        <div class="upgrade-options">
            <div class="upgrade-group">
                <h3>Pan Drawers</h3>
                <p>You currently have {{drawers.panDrawers.qty}} pan drawers in your shopping cart.</p>

                <div class="upgrade-option selected">
                    <ng-container *ngIf="panDrawerUpgrade === PanDrawerUpgrade.NONE">
                        <ng-container *ngTemplateOutlet="upgradePanDrawerStandard"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="panDrawerUpgrade === PanDrawerUpgrade.HIGH_SIDES">
                        <ng-container *ngTemplateOutlet="upgradePanDrawerHighSided"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="panDrawerUpgrade === PanDrawerUpgrade.FROSTED_SIDES">
                        <ng-container *ngTemplateOutlet="upgradePanDrawerGlass"></ng-container>
                    </ng-container>

                    <div class="upgrade-button">
                        <button mat-flat-button color="primary" *ngIf="panDrawerUpgrade === PanDrawerUpgrade.NONE" (click)="showPanDrawerUpgrades = true; showDrawerBoxUpgrades = false">Upgrade Pan Drawers</button>
                        <button mat-stroked-button color="secondary" *ngIf="panDrawerUpgrade !== PanDrawerUpgrade.NONE" (click)="showPanDrawerUpgrades = true; showDrawerBoxUpgrades = false">Change Pan Drawers</button>
                    </div>
                </div>
            </div>

            <div class="upgrade-group">
                <h3>Drawer Boxes</h3>
                <p>You currently have {{drawers.drawerBoxes.qty}} drawer boxes in your shopping cart.</p>

                <div class="upgrade-option selected">
                    <ng-container *ngIf="drawerBoxUpgrade === DrawerBoxUpgrade.NONE">
                        <ng-container *ngTemplateOutlet="upgradeDrawerBoxStandard"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="drawerBoxUpgrade === DrawerBoxUpgrade.DOVETAILS">
                        <ng-container *ngTemplateOutlet="upgradeDrawerBoxDovetail"></ng-container>
                    </ng-container>

                    <div class="upgrade-button">
                        <button mat-flat-button color="primary" *ngIf="drawerBoxUpgrade === DrawerBoxUpgrade.NONE" (click)="showPanDrawerUpgrades = false; showDrawerBoxUpgrades = true">Upgrade Drawer Boxes</button>
                        <button mat-stroked-button color="secondary" *ngIf="drawerBoxUpgrade !== DrawerBoxUpgrade.NONE" (click)="showPanDrawerUpgrades = false; showDrawerBoxUpgrades = true">Change Drawer Boxes</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="upgrade-continue">
            <button mat-stroked-button color="secondary" (click)="close()" *ngIf="panDrawerUpgrade === PanDrawerUpgrade.NONE && drawerBoxUpgrade === DrawerBoxUpgrade.NONE">No Upgrades</button>
            <button mat-flat-button color="primary" (click)="close()" *ngIf="panDrawerUpgrade !== PanDrawerUpgrade.NONE || drawerBoxUpgrade !== DrawerBoxUpgrade.NONE">Keep Upgrades</button>
        </div>
    </ng-container>
    <ng-container *ngIf="drawers && showPanDrawerUpgrades">
        <div class="header-block">
            <div class="back">
                <button mat-stroked-button color="secondary" class="skinny" (click)="showPanDrawerUpgrades = false">
                    <mat-icon>chevron_left</mat-icon> Back
                </button>
            </div>

            <h3>Pan Drawers</h3>
            <p>You currently have {{drawers.panDrawers.qty}} pan drawers in your shopping cart.</p>
        </div>

        <div class="upgrade-options">
            <div class="upgrade-option" [ngClass]="{ selected: panDrawerUpgrade === PanDrawerUpgrade.NONE }">
                <ng-container *ngTemplateOutlet="upgradePanDrawerStandard"></ng-container>
            </div>
            <div class="upgrade-option" [ngClass]="{ selected: panDrawerUpgrade === PanDrawerUpgrade.HIGH_SIDES }">
                <ng-container *ngTemplateOutlet="upgradePanDrawerHighSided"></ng-container>
            </div>
            <div class="upgrade-option" [ngClass]="{ selected: panDrawerUpgrade === PanDrawerUpgrade.FROSTED_SIDES }">
                <ng-container *ngTemplateOutlet="upgradePanDrawerGlass"></ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="drawers && showDrawerBoxUpgrades">
        <div class="header-block">
            <div class="back">
                <button mat-stroked-button color="secondary" class="skinny" (click)="showDrawerBoxUpgrades = false">
                    <mat-icon>chevron_left</mat-icon> Back
                </button>
            </div>

            <h3>Drawer Boxes</h3>
            <p>You currently have {{drawers.drawerBoxes.qty}} drawer boxes in your shopping cart.</p>
        </div>

        <div class="upgrade-options">
            <div class="upgrade-option" [ngClass]="{ selected: drawerBoxUpgrade === DrawerBoxUpgrade.NONE }">
                <ng-template *ngTemplateOutlet="upgradeDrawerBoxStandard"></ng-template>
            </div>
            <div class="upgrade-option" [ngClass]="{ selected: drawerBoxUpgrade === DrawerBoxUpgrade.DOVETAILS }">
                <ng-template *ngTemplateOutlet="upgradeDrawerBoxDovetail"></ng-template>
            </div>
        </div>
    </ng-container>

    <ng-template #upgradePanDrawerStandard>
        <h4>Standard</h4>

        <p *ngIf="!showPanDrawerUpgrades || panDrawerUpgrade === PanDrawerUpgrade.NONE">We will fit Standard Gallery Rail sides as standard.</p>
        <p *ngIf="showPanDrawerUpgrades && panDrawerUpgrade !== PanDrawerUpgrade.NONE">Downgrade to Standard Gallery Rail sides.</p>

        <img cdn="images/misc/pandrawer-standard.jpg" width="100%" alt="Standard Pan Drawer" />

        <div class="upgrade-button" *ngIf="showPanDrawerUpgrades">
            <button mat-flat-button color="primary" [disabled]="upgrading" (click)="upgradePanDrawers(PanDrawerUpgrade.NONE)">No upgrade thanks</button>
        </div>
    </ng-template>
    <ng-template #upgradePanDrawerHighSided>
        <h4>High Sided</h4>

        <p *ngIf="!showPanDrawerUpgrades || panDrawerUpgrade === PanDrawerUpgrade.HIGH_SIDES">We will fit High Sided sides for only <span [innerHTML]="drawers.panDrawers.costs[PanDrawerUpgrade.HIGH_SIDES] | currencyFormat:true:true"></span> per drawer.</p>
        <p *ngIf="showPanDrawerUpgrades && panDrawerUpgrade !== PanDrawerUpgrade.HIGH_SIDES">Upgrade now to High Sided sides for only <span [innerHTML]="drawers.panDrawers.costs[PanDrawerUpgrade.HIGH_SIDES] | currencyFormat:true:true"></span> per drawer.</p>

        <img cdn="images/misc/pandrawer-high_sided.jpg" width="100%" alt="High-Sided Pan Drawer" />

        <div class="upgrade-button" *ngIf="showPanDrawerUpgrades">
            <button mat-flat-button color="primary" [disabled]="upgrading" (click)="upgradePanDrawers(PanDrawerUpgrade.HIGH_SIDES)">
                <span *ngIf="panDrawerUpgrade === PanDrawerUpgrade.HIGH_SIDES">Keep Upgrade</span>
                <span *ngIf="panDrawerUpgrade !== PanDrawerUpgrade.HIGH_SIDES">Upgrade to High Sided</span>
            </button>
        </div>
    </ng-template>
    <ng-template #upgradePanDrawerGlass>
        <h4>Glass (Offer)</h4>

        <p *ngIf="!showPanDrawerUpgrades || panDrawerUpgrade === PanDrawerUpgrade.FROSTED_SIDES">We will fit Glass sides for only <span [innerHTML]="drawers.panDrawers.costs[PanDrawerUpgrade.FROSTED_SIDES] | currencyFormat:true:true"></span> per drawer.</p>
        <p *ngIf="showPanDrawerUpgrades && panDrawerUpgrade !== PanDrawerUpgrade.FROSTED_SIDES">Upgrade now to Glass sides for only <span [innerHTML]="drawers.panDrawers.costs[PanDrawerUpgrade.FROSTED_SIDES] | currencyFormat:true:true"></span> per drawer.</p>

        <img cdn="images/misc/pandrawer-glass.jpg" width="100%" alt="Frosted Glass Sided Pan Drawer" />

        <div class="upgrade-button" *ngIf="showPanDrawerUpgrades">
            <button mat-flat-button color="primary" [disabled]="upgrading" (click)="upgradePanDrawers(PanDrawerUpgrade.FROSTED_SIDES)">
                <span *ngIf="panDrawerUpgrade === PanDrawerUpgrade.FROSTED_SIDES">Keep Upgrade</span>
                <span *ngIf="panDrawerUpgrade !== PanDrawerUpgrade.FROSTED_SIDES">Upgrade to Frosted Glass</span>
            </button>
        </div>
    </ng-template>
    <ng-template #upgradeDrawerBoxStandard>
        <h4>Standard</h4>

        <p *ngIf="!showDrawerBoxUpgrades || drawerBoxUpgrade === DrawerBoxUpgrade.NONE">We will fit Standard drawer boxes.</p>
        <p *ngIf="showDrawerBoxUpgrades && drawerBoxUpgrade !== DrawerBoxUpgrade.NONE">Downgrade to Standard drawer boxes.</p>

        <img cdn="images/misc/drawer_box-standard.jpg" width="100%" alt="Standard Drawer Box" />

        <div class="upgrade-button" *ngIf="showDrawerBoxUpgrades">
            <button mat-flat-button color="primary" [disabled]="upgrading" (click)="upgradeDrawerBoxes(DrawerBoxUpgrade.NONE)">No upgrade thanks</button>
        </div>
    </ng-template>
    <ng-template #upgradeDrawerBoxDovetail>
        <h4>Dovetail</h4>

        <p *ngIf="!showDrawerBoxUpgrades || drawerBoxUpgrade === DrawerBoxUpgrade.DOVETAILS">We will fit Dovetails for only <span [innerHTML]="drawers.drawerBoxes.costs[DrawerBoxUpgrade.DOVETAILS] | currencyFormat:true:true"></span> per drawer.</p>
        <p *ngIf="showDrawerBoxUpgrades && drawerBoxUpgrade !== DrawerBoxUpgrade.DOVETAILS">Upgrade now to Dovetails for only <span [innerHTML]="drawers.drawerBoxes.costs[DrawerBoxUpgrade.DOVETAILS] | currencyFormat:true:true"></span> per drawer.</p>

        <img cdn="images/misc/drawer_box-dovetail.jpg" width="100%" alt="Dovetail Drawer Box" />

        <div class="upgrade-button" *ngIf="showDrawerBoxUpgrades">
            <button mat-flat-button color="primary" [disabled]="upgrading" (click)="upgradeDrawerBoxes(DrawerBoxUpgrade.DOVETAILS)">
                <span *ngIf="drawerBoxUpgrade === DrawerBoxUpgrade.DOVETAILS">Keep Upgrade</span>
                <span *ngIf="drawerBoxUpgrade !== DrawerBoxUpgrade.DOVETAILS">Upgrade to Dovetails</span>
            </button>
        </div>
    </ng-template>
</mat-dialog-content>
