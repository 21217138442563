@import '/src/theme/variables';

page-kitchens-range-detail {
    
    h1 {
        margin-bottom: 2rem;
        font-size: 3.157rem;
        font-weight: 300;
        line-height: 4.209rem;
    }

    hr {
        background-color: color('light-grey');
        height: 1px;
        border: none;
    }

    .range-image {
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 500px;

        h4 {
            position: absolute;
            display: block;
            bottom: 20px;
            padding: 25px 60px;
            background: rgba(255, 255, 255, 0.8);
            color: color('black');
            font-size: 2rem;
            text-transform: uppercase;
        }
    }

    .range-colours {
        padding: 20px 0;

        a {
            .swatch {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 6px;
                padding: 10px;
                border: 1px solid color('gray-chateau');
                border-radius: 100px;
                min-width: 40px;
                min-height: 40px;
                text-align: center;

                .selected-colour {
                    width: 38px;
                    height: 38px;
                    color: #fff;
                    font-size: 2.4rem;
                }
            }

            .swatch-innova-alabaster,
            .swatch-innova-vanilla,
            .swatch-innova-white {
                .selected-colour {
                    color: color('black');
                }
            }
        }
    }

    .specs {
        padding: 30px;

        th {
            text-align: left;
            padding: 15px;
        }
    }

    .unit-colours {
        align-items: center;
        justify-content: center;

        .swatch {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px;
            border: 1px solid color('gray-chateau');
            border-radius: 100px;
            min-width: 40px;
            min-height: 40px;
            text-align: center;
            cursor: pointer;

            & + .swatch {
                margin-left: 5px;
            }

            .selected-colour {
                color: color('white');
                font-size: 1.5rem;
            }
        }

        .swatch-carcase-alabaster,
        .swatch-carcase-vanilla,
        .swatch-carcase-white {
            .selected-colour {
                color: color('black');
            }
        }
    }

    .carcase-image {
        margin: 30px 60px 60px 0;
    }

    @media only screen and (min-device-width: 315px) and (max-device-width: 576px) {
        .range-image {
            position: relative;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 350px;
        }
    }

    .range-details {
        display: flex;
        margin-bottom: 60px;
    }

    @media only screen and (max-width: 1023px) {
        .range-details {
            flex-wrap: wrap;
        }
    }
}
