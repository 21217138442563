import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { DoorsPage } from './doors.page';

import { PageNotFoundComponent } from '@app/shared/page-not-found.component';
import { DoorsListingPage } from './listing/doors-listing/doors-listing.page';
import { DoorsDetailPage } from './detail/doors-detail/doors-detail.page';
import { ProductCategoryGuard } from '@app/utilities/guards/product-category.guard';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: DoorsPage,
                pathMatch: 'full',
                data: { vatToggle: true }
            },
            {
                path: ':style/doors-only-ordering',
                component: DoorsDetailPage,
                pathMatch: 'full',
                data: { vatToggle: true }
            },
            {
                path: ':rangeType',
                component: DoorsListingPage,
                canActivate: [ProductCategoryGuard],
                pathMatch: 'full',
                data: { vatToggle: true, reuse: true }
            },
            {
                path: ':rangeType/:colour',
                component: DoorsListingPage,
                data: { vatToggle: true, reuse: true }
            },
            { path: '**', component: PageNotFoundComponent }
        ])
    ],
    exports: [RouterModule]
})
export class DoorsRouting { }
