<header class="content-contained">
    <h1>{{categoryDisplay}} Worktop Samples</h1>
    <p *ngIf="!category">
        If you are not quite sure which worktop to choose for your new kitchen then just have a look at the 200+ worktop samples that we have available below.
        Our samples include quartz, granite, Dekton & solid wood, so we will definitely have something for you, you just need to decide which one!
    </p>
    <p *ngIf="category && category === 'quartz'">
        Before buying a stunning solid surface like a quartz worktop, you may want to see how it will look with your chosen door & unit samples,
        to make sure that you are 100% certain before making your final decision.
    </p>
    <p *ngIf="category && category === 'granite'">
        Buying a solid surface worktop like granite is a very big decision, so you may want to see a sample of the worktop in the light of day,
        before deciding on the one that is going to adorn your new kitchen.
    </p>
    <p *ngIf="category && category === 'dekton'">
        Our newest range of Dekton worktops have a great portfolio of features and look great with their ultra-thin 20mm thick profile.
        It is worth ordering a Dekton worktop sample, as the look & feel of them will definitely convince you that this is the worktop for your kitchen!
    </p>
    <p *ngIf="category && category === 'solid-wood'">
        A solid wood worktop looks elegant in any kitchen and we highly recommend you order a sample first,
        to help you decide on the one that is right for you. We have 4 different solid wood worktop samples to choose from, ranging from iroko, beech, oak & walnut.
    </p>
    <p *ngIf="category">All {{categoryDisplay.toLowerCase()}} surface samples measure approximately {{sampleSize}}.</p>
</header>

<hr class="narrow" />

<section class="content-contained">
    <div class="flex-space-between">
        <div class="left">
            <component-filters [items]="items" [filters]="filterFields" [sectionCats]="sectionCats"
                [section]="category || 'allstyles'" [sectionURL]="'samples/solid-surface-worktop-samples'"
                (filterChange)="updateItems($event)" [showSort]="false">
            </component-filters>
            <div class="tip">
                <h4>Useful tip:</h4>
                <p>
                    We recommend ordering multiple worktop samples to make an important decision as easy as possible and give you
                    the confidence that you are buying the correct material or colour to complement your new kitchen.
                </p>
            </div>
        </div>
        <div class="products" *ngIf="items">
            <div class="banners">
                <div class="panel">
                    <div class="panel-left">
                        <a routerLink="/about-diy-kitchens/price-and-quality-comparison" title="Price & Quality Comparison">
                            <img src="https://static.diy-kitchens.com/assets/images/banner/listings-banner-trustpilot.jpg" width="100%" alt="Trustpilot Best rated national kitchen retailer."/>
                        </a>
                    </div>
                    <div class="panel-right">
                        <a routerLink="/customer-reviews/shaker" class="video-outer" title="Real Customer Kitchens">
                            <img src="https://static.diy-kitchens.com/assets/images/banner/offer-banner_real-customers_oct2020.jpg" width="100%" alt="Real customer kitchen videos. Take a look."/>
                        </a>
                    </div>
                </div>
                <br />
                <hr class="narrow" />
            </div>
            <div class="door" *ngFor="let entry of filteredItems">
                <div class="image"><img [cdn]="entry.image" [alt]="entry.sub_cat + ' ' + entry.cat"></div>
                <div class="title">{{entry.sub_cat}}<br />{{entry.cat}}</div>
                <div class="price" [innerHTML]="entry.cost | currencyFormat:true:true"></div>
                <div class="actions">
                    <component-basket-add-button [type]="type" [item]="entry"></component-basket-add-button>
                </div>
            </div>
            <div *ngIf="noResults" class="no-results">
                <em class="common-em">No samples were found that match your filters.</em>
                Try removing a filter.
            </div>
        </div>
    </div>
</section>
