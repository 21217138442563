
<div class="gallery-container" role="region" aria-label="Image Gallery">
    <div class="focussed-image-container" role="region" aria-label="Focused Image">
        <button class="arrow-control left-arrow" (click)="arrowLeftClick()" *ngIf="this.images.length >1"
                aria-label="Previous Image"><
        </button>
        <img src="{{ this.focussedImage.image }}" alt="Product Image"/>
        <button class="arrow-control right-arrow" (click)="arrowRightClick()" *ngIf="this.images.length >1"
                aria-label="Next Image">>
        </button>
    </div>
    <ng-container *ngIf="images.length > 1">
        <div class="thumbnails" role="region" aria-label="Image Thumbnails">
            <img *ngFor="let image of images" src="{{image.image}}" [ngClass]="{'selected': image === selectedImage}"
            (click)="thumbnailClick(image)"
            alt="Product Image" [attr.aria-current]="image === selectedImage ? 'true' : 'false'"/>
        </div>
    </ng-container>
</div>

