import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { StringHelper, URLHelper } from '@app/utilities/helpers';

import { BasketService } from '@app/services/basket';
import { BasketItem } from '@app/services/basket/models';
import { CatalogueService } from '@app/services/catalogue';
import { ActiveRange, ProductType, Layouts } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute } from '@app/services/navigation/models';

import { KitchenLayoutsDialogComponent } from '@app/shared/dialogs/kitchen-layouts';

import { CostCalculator } from '@app/services/catalogue/cost-calculator';

@Component({
    selector: 'page-solid-surfaces',
    templateUrl: './solid-surfaces.page.html',
    styleUrls: ['./solid-surfaces.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SolidSurfacesPage implements OnInit, OnDestroy {
    public loaded: boolean = false;
    private allProducts: any; // All products of this type (ie All Units or Appliances)
    public category: string; // Category to display ie (Base units or Dishwashers)
    public sectionCats: any[] = []; // A list of all categories for this type (ie different unit types or appliance types)
    public items: any; // Products in this category - gets sent to the filters component
    public filteredItems: any[] = []; // Filtered products to show as results
    public noResults: boolean;

    public type: ProductType = ProductType.WORKTOPS;
    public logoURL: string = 'https://static.diy-kitchens.com/assets/images/brands/';
    public orderSampleText = 'ORDER SAMPLE';
    public filterFields = [
        {
            title: 'Brand',
            field: 'brand'
        }
    ];
    public qty = 1;
    public buttonIcon = '';

    private routeSubscription: Subscription;
    private activeBasketSubscription: Subscription;
    public activeRange: ActiveRange;
    private activeRangeSubscription;

    private costCalculator: CostCalculator = new CostCalculator();

    constructor(
        private navigationService: NavigationService,
        private catalogueService: CatalogueService,
        private basketService: BasketService,
        private dialogService: DialogService,
        private urlHelper: URLHelper
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                let category = this.getCategoryFromURL(route.url);
                this.category = category;
                let categoryDisplay = (this.category) ? StringHelper.titleCase(StringHelper.dashToSpace(this.category)) : null;

                this.navigationService.setNavigation({
                    title: `${categoryDisplay} Worktops At Trade Prices`,
                    metaTags: [
                        { name: 'no-meta', content: 'no metadata' }
                    ]
                });

                this.navigationService.setRouteHistory([
                    { title: 'Worktops', route: '/worktops' },
                    { title: category, route: `/solid-surfaces/${StringHelper.spaceToDash(category)}-worktops` }
                ]);

                this.catalogueService.getWorktops()
                    .then((products: any) => {
                        this.allProducts = products;
                        this.initProducts();
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));

                let metaImage = 'https://static.diy-kitchens.com/assets/images/seo/samples/samples/solid-surface-worktop-samples-quartz.jpg';
                if (this.category === 'granite') {
                    metaImage = 'https://static.diy-kitchens.com/assets/images/seo/samples/samples/solid-surface-worktop-samples-granite.jpg';
                } else if (this.category === 'dekton') {
                    metaImage = 'https://static.diy-kitchens.com/assets/images/seo/samples/samples/solid-surface-worktop-samples-dekton.jpg';
                }

                this.navigationService.setMetaTags([
                    { property: 'og:image', content: metaImage },
                    { property: 'og:description', content: `See our range of ${categoryDisplay} Worktops available at trade prices.` },
                    { name: 'Description', content: `See our range of ${categoryDisplay} Worktops available at trade prices.` },
                ]);
            }
        );

        this.activeRangeSubscription = this.catalogueService.activeRange$.subscribe((activeRange) => {
            this.activeRange = activeRange;
        });
    }

    ngOnChanges() {
        this.initProducts();
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }

        if (this.activeBasketSubscription) {
            this.activeBasketSubscription.unsubscribe();
            this.activeBasketSubscription = null;
        }

        if (this.activeRangeSubscription) {
            this.activeRangeSubscription.unsubscribe();
            this.activeRangeSubscription = null;
        }
    }

    public itemCost(item) {
        return item.price || 0;
    }

    private initProducts() {
        let category = this.category;
        let products = this.allProducts;

        if (products) {
            this.sectionCats = [];
            this.items = [];

            let addedWorktops: any = {};

            products.forEach((worktop) => {
                if (this.itemCost(worktop) > 0) {
                    if (
                        worktop.cat === 'Granite' ||
                        worktop.cat === 'Dekton' ||
                        worktop.cat === 'Quartz' ||
                        worktop.cat === 'Ceramic'
                    ) {
                        if (!addedWorktops[worktop.sub_cat]) {
                            addedWorktops[worktop.sub_cat] = worktop;
                        } else if (addedWorktops[worktop.sub_cat].thickness > worktop.thickness) {
                            addedWorktops[worktop.sub_cat] = worktop;
                        }
                    }
                }
            });

            Object.values<any>(addedWorktops).forEach((item) => {
                this.items.push({
                    item: item,
                    widths: [{
                        width: item.width,
                        code: item.code,
                        cost: item._cost
                    }]
                });

                let catExists = this.sectionCats.filter((opt) => opt.name === item.cat);
                if (catExists.length === 0) {
                    let cat = {
                        name: item.cat,
                        sub_cats: {}
                    };
                    cat.sub_cats[item.sub_cat] = 1;
                    this.sectionCats.push(cat);
                } else {
                    catExists[0].sub_cats[item.sub_cat] = 1;
                }
            });

            this.sectionCats.map((cat) => {
                cat.count = Object.keys(cat.sub_cats).length;
                delete cat.sub_cats;
            });

            this.items = this.items.filter((entry) => StringHelper.spaceToDash(entry.item.cat) === category);
            if (!(category.match(/granite/i) || category.match(/dekton/i) || category.match(/quartz/i) || category.match(/ceramic/i))) {
                let subcats = [];
                this.items.sort((a, b) => a.item.price - b.item.price);
                this.items.map((entry) => {
                    let worktop = entry.item;
                    if (Number(worktop.depth) > 300) {
                        let exists = subcats.filter(product => product.item.sub_cat === worktop.sub_cat);
                        if (!exists[0]) {
                            subcats.push(entry);
                        }
                    }
                });

                this.items = subcats;
            }

            this.loaded = true;
        }
    }

    public updateItems(filtered) {
        this.filteredItems = filtered;
        this.noResults = filtered.length === 0;
    }

    public addToBasket(item: any) {
        if (item.cat.match(/laminate/i)) {
            return;
        }

        const sample = {
            code: `${(StringHelper.spaceToDash(item.sub_cat || '')).toUpperCase()}-SMP`,
            category: item.cat,
            desc: 'Worktop Sample - ' + item.cat + ' ' + item.sub_cat,
            cat: item.cat,
            sub_cat: item.sub_cat,
            cost: item.cat.toLowerCase() === 'solid wood' ? this.costCalculator.worktopWoodSampleCost : this.costCalculator.worktopSampleCost,
            _cost: item.cat.toLowerCase() === 'solid wood' ? this.costCalculator.worktopWoodSampleCost : this.costCalculator.worktopSampleCost,
            image: `worktops/thumbs/${(item.thumb_img || '').toLowerCase()}`
        };

        const basketItem = new BasketItem(sample, this.catalogueService.activeRange, ProductType.SAMPLE_WORKTOP, this.qty);

        this.basketService.addItem(basketItem, ProductType.SAMPLE_WORKTOP)
            .then(() => {
                item.orderSampleText = this.qty + ' Item Added';
                item.buttonIcon = 'done';
                setTimeout(() => {
                    item.orderSampleText = null;
                    item.buttonIcon = null;
                }, 2000);
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public itemRoute(item) {
        return '/worktops/' + item.cat.toLowerCase().replace(/ /g, '-') + '/' + item.sub_cat.toLowerCase().replace(/ /g, '-');
    }

    public configRoute(item) {
        return this.urlHelper.configRoute(item)
    }

    private getCategoryFromURL(url: string) {
        var caterogyFound = url.indexOf('quartz');
        if (caterogyFound > 0) {
            return 'quartz'
        }

        caterogyFound = url.indexOf('granite');
        if (caterogyFound > 0) {
            return 'granite'
        }

        caterogyFound = url.indexOf('dekton');
        if (caterogyFound > 0) {
            return 'dekton'
        }

        caterogyFound = url.indexOf('ceramic');
        if (caterogyFound > 0) {
            return 'ceramic'
        }

        return '';
    }

    //TODO; get from API
    public getCategoryDescription() {
        if (this.category.toUpperCase() === 'GRANITE') {
            return 'Granite is a natural luxury material that never falls out of fashion and it\'s suitable for all kitchen layouts. If you want a unique look for your kitchen then granite will help you achieve that. <br/> With over 29 different Granite worktops options available, there will be the perfect solid surface for you. Use our clever six step granite calculation system, to get a price in all our granite styles for your kitchen design and requirements.';
        } else if (this.category.toUpperCase() === 'QUARTZ') {
            return 'With over 100 different Quartz worktops options available there will be the perfect solid surface for you. Use our clever six step system to get a price in all styles for your design and requirements. Quartz worktops, like their granite cousins, give a refined and polished look to any new kitchen. Quartz has a very sophisticated appearance and consist of no less than 90% inorganic materials, mainly quartz and silicone. <br/> Quartz worktops have the feel and weight of natural stone and due to their colour consistency, they are better than any other natural stone. Before the worktop is ready to install in your kitchen, there are a few processes that it needs to go through first.';
        } else if (this.category.toUpperCase() === 'DEKTON') {
            return 'Dekton worktops are one of the newest ultra compact solid surface worktops with an excellent portfolio of features that will make it a keen contender as the solid surface worktop of choice in the years to come. A Dekton worktop will look amazing in your kitchen but did you know that you can also use it outside too? And, you\'ll be blown away by the price! Dekton Features zero water absorption and high UV resistance (for outdoor use). Dekton is also ice & thawing resistance as well as being fireproof with a high resistance to fire and heat. Abrasion & stain resistance are other major benefits of Dekton and constant contact with water will not damage the surface in any way. Dekton can be bought in thickness of 8mm, 12mm & 20mm and the largest size available is 1440mm x 3200mm.';
        } else {
            return 'Ceramic worktops are an increasingly popular choice for modern kitchens due to their combination of aesthetic appeal, durability, and versatility. Crafted from a blend of natural materials such as clay, sand, and quartz, ceramic worktops are subjected to extremely high temperatures during manufacturing. This process creates a dense, non-porous surface that is resistant to stains, scratches, and heat. Available in a wide range of colours, patterns, and finishes, ceramic worktops can mimic the appearance of natural stone, concrete, or even wood, making them a stylish option for a variety of interior design schemes.';
        }
    }

    public getHeaderClassName() {
        return `worktops-${this.category.toLowerCase()}-header-bg`;
    }

    public openShapeDialog() {
        const layoutId = Layouts.L_SHAPED;
        const colorObj = this.activeRange.rangeDetail.guidePrices[this.activeRange.rangeColour];
        const layoutData = colorObj[layoutId];

        this.dialogService.custom(KitchenLayoutsDialogComponent, {
            maxWidth: '100%',
            data: {
                style: `${this.activeRange.rangeDetail.name} ${this.activeRange.rangeColour}`,
                layoutId: layoutId,
                layoutData: layoutData
            }
        })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
