import * as Interfaces from '@app/services/catalogue/models/product.models';

// To-do: Move these interfaces to a central location
import { FrontalTitleDict, IUnitComponentDetail } from '../../pages/units/detail/models';
import { ActiveRange, ProductType } from './models';

// interfaces to aid with development, these should move to Cabinet/Basket Models
export interface CabinetCostItem {
    code: string;
    hingeCost?: number;
    runnerCost?: number;
    softCloseCost?: number;
}

export interface CostRange {
    id: string;
    cabinets: any;
}

export class CostCalculator {
    // Todo: move this to the db. If you update here also update the API
    public worktopSampleCost: number = 4.84;
    public worktopWoodSampleCost: number = 3.63;

    public getBaseCost(pricing, colourCode) {
        if (pricing.prices && pricing.prices[colourCode]) {
            return pricing.prices[colourCode];
        }
        let baseCost;
        if (typeof pricing.colours === 'number') {
            baseCost = pricing.colours;
        } else if (pricing && pricing.colours) {
            baseCost = pricing.colours[colourCode];
        }

        if (typeof baseCost === 'number') {
            return baseCost;
        }

        return 0; // If no price then cabinet is not available in current range, return 0 as we still want to display the item
    }

    public getOptionsCost(item: CabinetCostItem, options): number {
        let optionsCost = 0;
        if (options) {
            if (options.softCloseHinges) {
                optionsCost += item.hingeCost;
            }
            if (options.softCloseRunners) {
                optionsCost += item.runnerCost;
            }
            if (options.fullSoftClose) {
                optionsCost += item.softCloseCost;
            }
        }

        return optionsCost;
    }

    // private isRangeItem(basketItem: BasketItem, range: CostRange) {
    //     return basketItem.range && range && range.id === basketItem.range.id;
    // }

    /**
     * Get the price of a unit for a specific Range and Colour
     */
    public getUnitPriceRangeAndColour(range, rangeColour, unit_code: string) {
        if (range.cabinets) {
            const unitPrices = range.cabinets.find((cab) => cab.unit_code === unit_code);

            if (unitPrices) {
                return unitPrices.prices[rangeColour];
            }
        }

        return 0;
    }

    public getCabinetFrontalsFromConfig(
        item: Interfaces.ICabinet,
        activeRange: ActiveRange,
        frontals: Interfaces.IDoorForUnit
    ): IUnitComponentDetail[] {
        const frontalConfigStr: string =
            activeRange?.rangeDetail?.inframe === 'Yes' || item.inframe_only ? item.if_frontal_config : item.frontal_config;
        let cabinetComponents: IUnitComponentDetail[] = [];

        if (typeof frontalConfigStr === 'string' && frontalConfigStr.length) {
            let frontalConfig = frontalConfigStr.split('');
            while (frontalConfig.length) {
                let frontalTitle: string = '';
                let frontalCode = frontalConfig.shift();
                if (frontalCode === 'I' && frontalConfig.length) {
                    frontalCode = frontalConfig.shift();
                    if (activeRange?.rangeDetail?.inframe === 'Yes') {
                        frontalTitle = 'Inframe ';
                    }
                }
                frontalTitle += FrontalTitleDict[frontalCode];
                if (frontalTitle !== 'undefined') {
                    cabinetComponents.push(<IUnitComponentDetail>{
                        title: frontalTitle,
                        qty: 1,
                        editColour: true
                    });
                }
            }

            // Match against doors listed on cabinet & find frontal cost
            const doorKey = activeRange?.rangeDetail?.inframe === 'Yes' || item.inframe_only ? 'if_door_' : 'door_';
            for (let i = 0; i < cabinetComponents.length; i++) {
                if (item[`${doorKey}${i + 1}_qty`]) {
                    cabinetComponents[i].description = item[`${doorKey}${i + 1}`];
                    cabinetComponents[i].qty = item[`${doorKey}${i + 1}_qty`];
                    if (frontals) {
                        const matchingFrontal = Object.values<any>(frontals).find(
                            (front) =>
                                (front.size_tag || '').replace(/[^0-9]/g, '').toLowerCase() ===
                                item[`${doorKey}${i + 1}`].replace(/[^0-9]/g, '').toLowerCase()
                        );
                        if (matchingFrontal) {
                            cabinetComponents[i]._cost = matchingFrontal._cost ?? 0;
                            cabinetComponents[i].salePrice = matchingFrontal.salePrice ?? 0;
                            cabinetComponents[i].description = matchingFrontal._description;
                            cabinetComponents[i].door = matchingFrontal;
                            if (matchingFrontal?.media?.image) {
                                cabinetComponents[i].image = matchingFrontal.media.image;
                            }
                        }
                    }
                }
            }

            // In-frame units sometimes have door & drawer frontal sets, combine the descriptions together.
            if (frontals && Object.keys(frontals).length && Object.keys(frontals).length < cabinetComponents.length) {
                const combinedSets = cabinetComponents.filter((component) => !component._cost);
                cabinetComponents = cabinetComponents.filter((component) => component._cost);
                combinedSets.forEach(
                    (combinedFrontal) => (cabinetComponents[cabinetComponents.length - 1].title += ` & ${combinedFrontal.title}`)
                );
            }
        } else if (frontals && item.childCodeUsed) {
            // Mostly for Mantles & Canopies
            const frontalKeys = Object.keys(frontals);
            frontalKeys.forEach((key) => {
                const frontal = frontals[key];

                const cabComponent: IUnitComponentDetail = {
                    title: frontal.subcat || frontal.desc,
                    description: frontal._description || frontal.desc,
                    qty: 1,
                    editColour: true,
                    _cost: frontal.price || frontal._cost,
                    salePrice: frontal.salePrice || 0,
                    door: frontal
                }

                if (frontal?.media?.image) {
                    cabComponent.image = frontal.media.image;
                }

                cabinetComponents.push(cabComponent);
            });
            
        }

        return cabinetComponents;
    }

    /**
     *
     * @param item A kitchen unit
     * @param activeRange The activeRange object
     * @param frontals The frontals object from the Catalogue Service
     * @param carcaseDiscounts A number array of discount percentages to be applied
     * @param doorDiscounts A number array of discount percentages to be applied
     * @returns The total sale price of the cabinet and all associated frontals
     */
    public calculateCabinetSalePrice(
        item: Interfaces.ICabinet,
        activeRange: ActiveRange,
        frontals: Interfaces.IDoorForUnit,
        carcaseDiscounts: number[] = [],
        doorDiscounts: number[] = []
    ): number {
        let carcasePrice = item.cab_price;
        const cabDoors = this.getCabinetFrontalsFromConfig(item, activeRange, frontals);
        let doorTotal = 0;
        cabDoors.forEach((door) => {
            doorTotal += this.calculateDoorSalePrice(door.door, doorDiscounts, door.qty);
        });
        carcaseDiscounts.forEach((carcaseDiscount) => {
            carcasePrice = carcasePrice * (1 - carcaseDiscount / 100);
        });
        carcasePrice = this.roundDownPrice(carcasePrice);
        doorTotal = this.roundDownPrice(doorTotal);
        return carcasePrice + doorTotal;
    }

    /**
     * Utility method to round down a price, deliberately mathematically incorrect
     * @param price
     * @returns price rounded down to 2 decimal places
     */
    private roundDownPrice(price: number): number {
        // let [whole, decimals = ''] = price.toString().split('.');
        // decimals = decimals.padEnd(2, '0').slice(0, 2);
        // const roundedPrice = parseFloat(`${whole}.${decimals}`);

        // return roundedPrice;

        /**
         * Updated logic after discussion which reversed an earlier decision
         */
        return Math.round(price * 100) / 100;
    }

    /**
     * Calculates the sale price by applying each percentage discount in turn.
     * @param item Type cabinet
     * @param carcaseDiscounts An array of numbers representing percentage discounts to be applied consecutively.
     * @param carcaseDiscounts An array of numbers representing percentage discounts to be applied consecutively.
     * @returns The calculated sale price of the cabinet
     */
    public calculateCabinetOnlySalePrice(item: Interfaces.ICabinet, carcaseDiscounts: number[] = [], qty: number = 1): number {
        let carcasePrice = item.cab_price;
        return this.calculateItemSalePrice(carcasePrice, carcaseDiscounts, qty);
    }

    /**
     * Calculates the sale price by applying each percentage discount in turn.
     * @param item Type door, or anything considered a frontal
     * @param doorDiscounts An array of numbers representing percentage discounts to be applied consecutively.
     * @param qty Optional quantity
     * @returns The calculated sale price of the door
     */
    public calculateDoorSalePrice(item: Interfaces.IDoor, doorDiscounts: number[] = [], qty: number = 1): number {
        let doorPrice = item?._cost || item?.price || 0;
        return this.calculateItemSalePrice(doorPrice, doorDiscounts, qty);
    }

    public calculateProductSalePrice(cost: number, discounts: number[] = [], qty: number = 1): number {
        return this.calculateItemSalePrice(cost, discounts, qty);
    }

    /**
     * Utility method to calculate sale price by applying each percentage discount in turn.
     * @param cost The item cost
     * @param discounts An array of numbers representing percentage discounts to be applied consecutively.
     * @param qty Optional quantity
     * @returns The calculated sale price
     */
    private calculateItemSalePrice(cost: number, discounts: number[] = [], qty: number = 1): number {
        let price = cost;
        discounts.forEach((discount) => {
            if (typeof discount === 'string') {
                discount = parseFloat(discount);
            }
            price = price * (1 - discount / 100);
        });
        return this.roundDownPrice(price) * qty;
    }

    public getItemSalePrice(
        item: Interfaces.ICabinet,
        type: ProductType,
        activeRange: ActiveRange,
        frontals: Interfaces.IDoorForUnit
    ): number | null {
        if (type !== ProductType.CABINETS) {
            return null;
        }
        const cabPrice = item.cab_salePrice ? item.cab_salePrice : item.cab_price;
        const cabDoors = this.getCabinetFrontalsFromConfig(item, activeRange, frontals);
        let totalCost: number = cabPrice;
        cabDoors.forEach((door) => {
            let doorCost = door.salePrice ? door.salePrice : door._cost;
            doorCost = doorCost * door.qty;
            totalCost += doorCost;
        });

        totalCost = Math.floor(totalCost * 100) / 100; // Round down to the nearest penny
        return totalCost;
    }

    public calculateSalePrice(originalCost: number, discountPercentage: number) {
        const salePrice = originalCost * (1 - discountPercentage / 100);
        return Math.floor(salePrice * 100) / 100; // Round down to the nearest penny
    }

    // TODO - double check the functions below are used
    public getPricing(range: CostRange, item) {
        if (range.cabinets) {
            return range.cabinets.find((cab) => cab.unit_code === item.unit_code);
        }

        return false;
    }

    public cabinetBaseCost(item: CabinetCostItem, range: CostRange, colourCode: string) {
        const pricing = this.getPricing(range, item);

        return this.getBaseCost(pricing, colourCode);
    }

    public getUnitCost(item: CabinetCostItem, range: CostRange) {
        const pricing = this.getPricing(range, item);

        return pricing && pricing.cost;
    }

    public getColourCost(item: CabinetCostItem, range: CostRange, colourCode: string) {
        const pricing = this.getPricing(range, item);

        return pricing ? this.getBaseCost(pricing, colourCode) - this.getUnitCost(item, range) : null;
    }

    public cabinetTotalCost(item: CabinetCostItem, range: CostRange, colourCode: string, options: any) {
        const pricing = this.getPricing(range, item);

        if (!pricing) {
            throw new Error(`No pricing found in range ${range.id} for item code ${item.code}`);
        }

        return this.getBaseCost(pricing, colourCode) + this.getOptionsCost(item, options);
    }

    // private isPricesItem(basketItem: BasketItem) {
    //     // return basketItem.type === 'worktops' && basketItem.options && basketItem.options.price;
    //     return basketItem.type === 'worktops' && basketItem.options && basketItem.cost;
    // }

    // public totalCostFromBasket(item: CabinetCostItem, basketItem: BasketItem, range: CostRange) {
    //     if (this.isRangeItem(basketItem, range)) {
    //         return this.cabinetTotalCost(item, range, basketItem.colour.code, basketItem.options);
    //     }
    //     if (this.isPricesItem(basketItem)) {
    //         // return basketItem.options.prices.total;
    //         return basketItem.cost;
    //     }

    //     return this.simpleItemTotal(item);
    // }

    public simpleItemTotal(item) {
        return item.cost || 0.0;
    }

    /**
     * Calculates the carcase material price based on the price per square m and dimensions
     * @param pricePerM 
     * @param width in mm
     * @param height in mm
     */
    public getCarcaseMaterialCost(pricePerM: number, width: number, height: number): number {
        const minimumValue = 10;    // Minimum order value
        const calculatedPrice = this.roundDownPrice(width * height * pricePerM / 1000000);

        return minimumValue > calculatedPrice ? minimumValue : calculatedPrice;
    }
}
