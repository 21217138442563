<header class="content-contained">
    <h1>History</h1>
    <p>DIY Kitchens is a family run business and the UK's largest online kitchen retailer. We have been manufacturing high specification <strong>kitchens</strong> and <strong>kitchen units</strong> since 1982 and our history lies in supplying premium, high street retailers throughout the UK, however DIY Kitchens now focuses on supplying you with the same high specification kitchens, at unbelievably low prices.</p>
</header>

<hr class="narrow" />

<section class="content-contained history" *ngIf="history && history.length">
    <div class="history-item" *ngFor="let item of history">
        <div class="history-item-content" [ngStyle]="{ 'background-color': item.backgroundColour }">
            <h4>{{item.year}}</h4>
            <h2>{{item.title}}</h2>
            <p>{{item.description}}</p>
        </div>
        <div class="history-item-image">
            <ng-container *ngIf="!item.isVideo">
                <img [src]="item.assetUrl" width="100%" [alt]="item.assetText"/>
            </ng-container>
            <ng-container *ngIf="item.isVideo">
                <video autoplay="" muted="" loop="" width="100%">
                    <source [src]="item.assetUrl" />
                </video>
            </ng-container>
            <div class="history-item-image-container" *ngIf="item.assetText">
                <div class="history-item-image-description">
                    <p>{{item.assetText}}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<component-latest-offers></component-latest-offers>