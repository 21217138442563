<mat-dialog-content>
    <div class="header">
        <h5>Add Note</h5>
        <div class="close" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="note-input">
        <mat-form-field appearance="outline">
            <mat-label>Note:</mat-label>
            <input matInput [(ngModel)]="note">
        </mat-form-field>
    </div>
    <div class="warning">
        <mat-icon>warning</mat-icon>
        <ng-container *ngIf="itemGroup === ProductType.CABINETS; else universalWarning">
            <p>This note applies only to this unit and any doors attached to it.</p>
        </ng-container>
        <ng-template #universalWarning>
            <p>This note applies only to this item.</p>
        </ng-template>
    </div>
    <div class="modal-action">
        <button mat-flat-button color="primary" class="chunky" (click)="addNote()">Add Note</button>
    </div>
</mat-dialog-content>
