import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Config } from '@app/config';

@Component({
    selector: 'component-directions-dialog',
    templateUrl: './directions.dialog.component.html',
    styleUrls: ['./directions.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DirectionsDialogComponent {
    public postcode: string;

    constructor(
        private dialogRef: MatDialogRef<DirectionsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private config: Config
    ) { }

    public getDirections() {
        if (this.config.isBrowser && window) {
            const googleMaps = 'https://www.google.com/maps';
            let diyKitchens = 'DIY+Kitchens,+26A+Lidgate+Cres,+South+Kirkby,+Pontefract+WF9+3NR';
            if (this.data && this.data.address) {
                diyKitchens = this.data.address;
            }
            const postcode = this.postcode.replace(/\s/g, '+');

            const url = `${googleMaps}?saddr=${postcode}&daddr=${diyKitchens}`;

            window.open(url, '_blank');
        }
    }

    public close() {
        this.dialogRef.close();
    }
}
