import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from '@app/shared/page-not-found.component';
import { WorktopConfigPage } from '../config/config.page';
import { SolidSurfacesPage } from './solid-surfaces.page';

const routes: Routes = [];

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: SolidSurfacesPage,
                pathMatch: 'full'
            },
            {
                path: 'quartz-worktops',
                component: SolidSurfacesPage,
                data: { vatToggle: true }
            },
            {
                path: 'granite-worktops',
                component: SolidSurfacesPage,
                data: { vatToggle: true }
            }, {
                path: 'dekton-worktops',
                component: SolidSurfacesPage,
                data: { vatToggle: true }
            },
            {
                path: 'ceramic-worktops',
                component: SolidSurfacesPage,
                data: { vatToggle: true }
            },
            {
                path: 'config/:details',
                component: WorktopConfigPage,
                data: { reuse: true, vatToggle: true }
            },
            {
                path: 'config/edit/:worktopConfigUuid',
                component: WorktopConfigPage,
                data: { reuse: true, vatToggle: true }
            },
            {
                path: 'config/viewer/:worktopConfigUuid',
                component: WorktopConfigPage,
                data: { reuse: true, vatToggle: true }
            },
            { path: '**', component: PageNotFoundComponent }
        ])
    ],
    exports: [RouterModule]
})
export class SolidSurfacesRouting { }
