<section class="contained" *ngIf="item">
    <div class="product-details">
        <div class="left">
            <div class="image" [promotion]="'handles-detail'" [request]="'product-overlay-image'" [brand]="item.brand" [productCode]="item.product_code" [item]="item" [type]="ProductType.HANDLES">
                <component-product-image-gallery [images]="productImages"></component-product-image-gallery>
            </div>
        </div>
        <div class="details">
            <img *ngIf="item.brand" [src]="item.brandLogo + '_logo.gif'" class="brand-logo" [alt]="item.brand + ' logo'"/>
            <h1 class="title">
                {{item.desc}}
            </h1>
            <p class="code">{{item.product_code}}</p>
            <p>{{item.detail}}</p>
            <ng-container *ngIf="this.item.extended_details?.details.length">
                <p *ngFor="let description of item.extended_details.details"
                [ngClass]="{'sub-title': description === description.toUpperCase()}"
                >{{description}}</p>
            </ng-container>
            <table>
                <tbody>
                    <tr *ngIf="item.width > 0">
                        <th>Material:</th>
                        <td>{{item.sub_cat}}</td>
                    </tr>
                    <tr *ngIf="item.width > 0">
                        <th>Handle length:</th>
                        <td>{{item.width}}mm</td>
                    </tr>
                    <tr *ngIf="item.height > 0">
                        <th>Height:</th>
                        <td>{{item.height}}mm</td>
                    </tr>
                    <tr *ngIf="item.depth > 0">
                        <th>Depth:</th>
                        <td>{{item.depth}}mm</td>
                    </tr>
                    <tr *ngIf="item.hole_centre > 0">
                        <th>Screw holes distance:</th>
                        <td>{{item.hole_centre}}mm</td>
                    </tr>
                </tbody>
            </table>

            <div class="price" [promotion]="'handles-detail'" [request]="'product-price'" [brand]="item.brand" [productCode]="item.product_code" [item]="item" [type]="ProductType.HANDLES">
                <strong *ngIf="item.price" [innerHTML]="item.price | currencyFormat:true:true"></strong>
                <p class="small" *ngIf="!item.price">Currently unavailable</p>
            </div>
            <div class="flex-space-between">
                <div class="common-quantity">
                    <label>Quantity:</label>
                    <span>
                        <input type="number" min="1" placeholder="Qty" [(ngModel)]="item.qty">
                    </span>
                </div>
                <component-basket-add-button [activeRange]="activeRange" [type]="type" [item]="item" [qty]="item.qty"></component-basket-add-button>
            </div>
        </div>
    </div>
</section>
