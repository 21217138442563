import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { StringHelper, URLHelper } from '@app/utilities/helpers';

import { BasketService } from '@app/services/basket';
import { CatalogueService } from '@app/services/catalogue';
import { ProductType } from '@app/services/catalogue/models';
import { NavigationService } from '@app/services/navigation';
import { DialogService } from '@app/services/dialog';

@Component({
    selector: 'page-wood-worktops-page',
    templateUrl: './wood-worktops.page.html',
    styleUrls: ['./wood-worktops.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class WoodWorktopsPage implements OnInit {
    public loaded: boolean = false;
    private allProducts: any; // All products of this type (ie All Units or Appliances)
    public category: string; // Category to display ie (Base units or Dishwashers)
    public sectionCats: any[] = []; // A list of all categories for this type (ie different unit types or appliance types)
    public items: any; // Products in this category - gets sent to the filters component
    public filteredItems: any[] = []; // Filtered products to show as results
    public noResults: boolean;

    public type: ProductType = ProductType.WORKTOPS;
    public logoURL: string = 'https://static.diy-kitchens.com/assets/images/brands/';

    public filterFields = [{
        title: 'Brand',
        field: 'brand'
    }];

    constructor(
        private urlHelper: URLHelper,
        private basketService: BasketService,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.category = 'solid-wood';
        this.navigationService.setNavigation({
            title: 'Wood Worktops',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' }
            ]
        });

        this.navigationService.setRouteHistory([
            { title: 'Worktops', route: '/worktops' },
            { title: 'Solid Wood', route: '/wood-worktops' }
        ]);

        this.navigationService.setMetaTags([
            { property: 'og:image', content: 'https://static.diy-kitchens.com/assets/images/seo/wood-worktops/wood-worktops.jpg' },
            { property: 'og:description', content: 'See our range of Wood Worktops available at trade prices.' },
            { name: 'Description', content: 'See our range of Wood Worktops available at trade prices.' },
        ]);

        this.catalogueService.getWorktops()
            .then((products: any) => {
                this.allProducts = products;
                this.initProducts();
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    ngOnChanges() {
        this.initProducts();
    }

    public itemCost(item) {
        return item.price || 0;
    }

    private initProducts() {
        let category = this.category;
        let products = this.allProducts;

        if (products) {
            this.sectionCats = [];
            this.items = [];

            products.forEach((item) => {
                if (this.itemCost(item) > 0) {
                    this.items.push({
                        item: item,
                        widths: [{
                            width: item.width,
                            code: item.code,
                            cost: item.price || item._cost
                        }]
                    });

                    const catExists = this.sectionCats.find((opt) => opt.name === item.cat);
                    if (!catExists) {
                        let cat = {
                            name: item.cat,
                            sub_cats: {}
                        };
                        cat.sub_cats[item.sub_cat] = 1;
                        this.sectionCats.push(cat);
                    } else {
                        catExists.sub_cats[item.sub_cat] = 1;
                    }
                }
            });

            this.sectionCats.forEach((cat) => {
                cat.count = Object.keys(cat.sub_cats).length;
                delete cat.sub_cats;
            });


            this.items = this.items.filter((entry) => StringHelper.spaceToDash(entry.item.cat) === category);

            this.items.sort((a, b) => a.item.price - b.item.price);
            this.filteredItems = this.items;

            this.loaded = true;
        }
    }

    public addToBasket(item) {
        this.basketService.addItem(item, this.type);
    }

    public configRoute(item) {
        return this.urlHelper.configRoute(item);
    }

    public solidSurface(item) {
        return !item.cat.match(/laminate/i) && !item.cat.match(/Solid/i);
    }
}
