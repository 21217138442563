import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { FormValidators } from '@app/utilities/validators';

import { AuthService, AuthCustomerService } from '@app/services/auth';

@Component({
    selector: 'component-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class RegisterComponent {
    public authenticating: boolean = false;
    public errorMessage: string;

    public registrationFormGroup: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private authCustomerService: AuthCustomerService
    ) {
        this.registrationFormGroup = this.formBuilder.group({
            email: new FormControl('', [Validators.required, FormValidators.email]),
            firstName: new FormControl('', [Validators.required]),
            lastName: new FormControl('', [Validators.required]),
            contactNumber: new FormControl('', [Validators.required, FormValidators.phoneNumber]),
            password: new FormControl('', [Validators.required]),
            passwordConfirm: new FormControl('', [Validators.required]),
        });
    }

    public authenticate() {
        this.errorMessage = null;

        if (!this.authenticating) {
            if (this.registrationFormGroup.valid) {
                this.authenticating = true;

                if (this.registrationFormGroup.controls.password.value === this.registrationFormGroup.controls.passwordConfirm.value) {
                    this.authCustomerService.register({
                        // TODO: get custom form group interface working
                        email: this.registrationFormGroup.controls.email['cleanValue'],
                        firstName: this.registrationFormGroup.controls.firstName.value,
                        lastName: this.registrationFormGroup.controls.lastName.value,
                        password: this.registrationFormGroup.controls.password.value,
                        // TODO: get custom form group interface working
                        contactNumber: this.registrationFormGroup.controls.contactNumber['cleanValue']
                    })
                        .then(() => {
                            this.authenticating = false;
                        })
                        .catch((error) => {
                            this.errorMessage = (error && error.body && error.body.message) ? error.body.message : 'Something went wrong.';
                            this.authenticating = false;
                        });
                } else {
                    this.errorMessage = 'Passwords do not match';
                    this.authenticating = false;
                }
            } else {
                this.errorMessage = 'Please enter all required fields';
            }
        }
    }

    public loginDialog() {
        this.authService.login();
    }
}
