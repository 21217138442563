<section class="gallery-container" *ngIf="galleryItems.length">
    <button class="arrow-control left-arrow" [ngClass]="{ 'hidden': showLeftArrow === false }" (click)="scrollLeft()" aria-controls="gallery"><</button>
    <div id="gallery" class="gallery" [scrollLeft]="scrollPosition"
        [ngClass]="{'centre-content': scrollWidth <= clientWidth}" (touchstart)="onTouchStart($event)"
        (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd($event)">
        <ng-container *ngFor="let galleryItem of galleryItems">
            <a class="gallery-item" *ngIf="!exceptions.includes(galleryItem.url)"
                [ngClass]="{ 'selected': isSelected(galleryItem) }" (click)="galleryClick(galleryItem)"
                [matTooltip]="galleryItem.name" [routerLink]="subCategoryUrl(galleryItem)" [state]="{ scroll: 'none' }">
                <img [src]="galleryItem.image.url" [alt]="galleryItem.name" />
                <div class="category-label">
                    <div class="category-label-text">
                        <span>{{ galleryItem.name }}</span>
                    </div>
                </div>
            </a>
        </ng-container>
    </div>
    <button class="arrow-control right-arrow" [ngClass]="{ 'hidden': showRightArrow === false }" (click)="scrollRight()" aria-controls="gallery">></button>
</section>