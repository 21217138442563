import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Config } from '@app/config';
import { StringHelper, URLHelper } from '@app/utilities/helpers';

import { FetchApi } from '@app/services/api';
import { BasketItemHanding } from '@app/services/basket/models';
import { CatalogueService } from '@app/services/catalogue';
import { CostCalculator } from '@app/services/catalogue/cost-calculator';
import { ActiveRange, HandingType, ProductType } from '@app/services/catalogue/models';
import * as Interfaces from '@app/services/catalogue/models/product.models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';

import { IUnitComponentDetail, IUnitEntry, IUnitVariantDetail, IUnitWidthDetail } from './models';

@Component({
    selector: 'page-unit-detail',
    templateUrl: './unit-detail.page.html',
    styleUrls: ['./unit-detail.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class UnitDetailPage implements OnInit, OnDestroy {
    private routeHistory: RouteHistory[] = [{ title: 'Units', route: '/kitchen-units' }];

    public HandingType = HandingType;
    public type: ProductType = ProductType.CABINETS;
    public ProductType = ProductType;
    public qty: number = 1;

    private unitLink: string;
    private unitCode: string;
    private category: string;
    private categoryDisplay: string = '';
    private subCategory: string;
    private subCategoryDisplay: string = '';

    public activeRange: ActiveRange;
    public loaded: boolean = false;

    private catalogueUnits: Interfaces.ICabinet[];
    public unit: Interfaces.ICabinet;
    public entry: IUnitEntry;
    public variants: IUnitVariantDetail[];
    public featureTags: string[];

    private activeRangeSubscription: Subscription;
    private activeRangeLoadedSubscription: Subscription;
    private routeSubscription: Subscription;
    private costCalculator: CostCalculator = new CostCalculator();

    public Handing = BasketItemHanding;

    constructor(
        private config: Config,
        private urlHelper: URLHelper,
        private fetchApi: FetchApi,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit(): void {
        this.navigationService.setNavigation({
            title: 'Kitchen Units',
            metaTags: [{ name: 'no-meta', content: 'no metadata' }],
            routeHistory: this.routeHistory,
        });

        this.routeSubscription = this.navigationService.route.subscribe((route: NavigationRoute) => {
            this.unitLink = route.params.details || null;
            this.unitCode = route.params.code || null;
            this.category = route.params.category || null;
            this.categoryDisplay = (this.category) ? StringHelper.titleCase(StringHelper.dashToSpace(this.category)) : null;
            this.subCategory = route.params.subCategory || null;
            this.subCategoryDisplay = (this.subCategory) ? this.catalogueService.getCategoryTitle(this.subCategory) : null;

            if (this.category === 'accessories') {
                this.type = ProductType.RANGE_PRODUCTS;
            }

            if (!this.activeRangeLoadedSubscription) {
                this.activeRangeLoadedSubscription = this.catalogueService.activeRangeLoaded$.subscribe((loaded) => {
                    if (loaded) {
                        this.activeRangeSubscription = this.catalogueService.activeRange$.subscribe((activeRange) => {
                            this.activeRange = activeRange || null;

                            this.getCatalogueData();
                        });
                    }
                });
            } else {
                this.getCatalogueData();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }

        if (this.activeRangeSubscription) {
            this.activeRangeSubscription.unsubscribe();
            this.activeRangeSubscription = null;
        }

        if (this.activeRangeLoadedSubscription) {
            this.activeRangeLoadedSubscription.unsubscribe();
            this.activeRangeLoadedSubscription = null;
        }
    }

    public additionalItemDescription(item: Interfaces.ICabinet): string | null {
        return item?.desc_additional ?? null;
    }

    private getCatalogueData(): void {
        this.catalogueService.getUnitsAndDoors()
            .then((units: any) => {
                if (this.activeRange || this.type === ProductType.RANGE_PRODUCTS) {
                    const getRangeExtras = (rangeId: string, rangeColour: string) => {
                        this.catalogueService.getRangeDetails(rangeId)
                            .then((extras) => {
                                const products = extras.products.filter(
                                    (product) => product.colour === rangeColour && !product.desc.match(/sample/i)
                                );
                                this.catalogueUnits = [...units, ...products];
                                this.updateItem();
                            })
                            .catch((error) => this.dialogService.error(this.constructor.name, error));
                    };

                    if (this.type === ProductType.RANGE_PRODUCTS) {
                        this.urlHelper.getRangeProductByURL(this.unitLink).then((product) => {
                            this.catalogueUnits = units;
                            if (product) {
                                this.catalogueUnits = [...units, product];
                            }
                            this.updateItem();
                        });
                    } else {
                        getRangeExtras(this.activeRange.range.id, this.activeRange.rangeColour);
                    }
                } else {
                    this.catalogueUnits = units;
                    this.updateItem();
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    // private getItemSalePrice(item: Interfaces.ICabinet): number | null {
    //     if (this.type !== ProductType.CABINETS) {
    //         return null;
    //     }
    //     const cabPrice = item.cab_salePrice ? item.cab_salePrice : item.cab_price;
    //     const cabDoors = this.getCabinetFrontalsFromConfig(item);
    //     let totalCost: number = cabPrice;
    //     cabDoors.forEach((door) => {
    //         let doorCost = door.salePrice ? door.salePrice : door._cost;
    //         doorCost = doorCost * door.qty;
    //         totalCost += doorCost;
    //     });

    //     totalCost = Math.floor(totalCost * 100) / 100; // Round down to the nearest penny
    //     return totalCost;
    // }

    public getItemCost(item: Interfaces.ICabinet | Interfaces.IRangeProduct): number {
        let itemCost: number;
        if (!this.activeRange && 'cab_price' in item) {
            return item.cab_price;
        }

        if (this.type === ProductType.RANGE_PRODUCTS) {
            return (<Interfaces.IRangeProduct>item).price;
        }

        if (this.activeRange) {
            const pricing = this.costCalculator.getPricing(this.activeRange.rangeDetail, item);
            if (pricing && this.activeRange.rangeColour) {
                itemCost = this.costCalculator.getBaseCost(pricing, this.activeRange.rangeColour);
            }
        }

        return itemCost ?? (<Interfaces.ICabinet>item)?.cost ?? 0;
    }

    private getVariantDetails(item: Interfaces.ICabinet): IUnitVariantDetail {
        return {
            code: item.unit_code,
            link: this.urlHelper.routeEnd(item),
            variant: item.variant || item.unit_code, // TODO replace variant name with data from API
        };
    }

    // private getCabinetFrontalsFromConfig(item: Interfaces.ICabinet): IUnitComponentDetail[] {
    //     const frontalConfigStr: string = (this.activeRange?.rangeDetail?.inframe === 'Yes' || item.inframe_only) ?
    //                                 item.if_frontal_config :
    //                                 item.frontal_config;
    //     const frontals = (this.activeRange) ?
    //                     this.catalogueService.getDoorForUnit(this.unit.unit_code, this.activeRange?.rangeColour || 'Alabaster') :
    //                     null;
    //     let cabinetComponents: IUnitComponentDetail[] = [];

    //     if (typeof frontalConfigStr === 'string' && frontalConfigStr.length) {
    //         let frontalConfig = frontalConfigStr.split('');
    //         while (frontalConfig.length) {
    //             let frontalTitle: string =  '';
    //             let frontalCode = frontalConfig.shift();
    //             if (frontalCode === 'I' && frontalConfig.length) {
    //                 frontalCode = frontalConfig.shift();
    //                 if (this.activeRange?.rangeDetail?.inframe === 'Yes') {
    //                     frontalTitle = 'Inframe ';
    //                 }
    //             }
    //             frontalTitle += FrontalTitleDict[frontalCode];
    //             if (frontalTitle !== 'undefined') {
    //                 cabinetComponents.push(<IUnitComponentDetail>{
    //                     title: frontalTitle,
    //                     qty: 1,
    //                     editColour: true
    //                 });
    //             }
    //         }
    //         // Match against doors listed on cabinet & find frontal cost
    //         const doorKey = (this.activeRange?.rangeDetail?.inframe === 'Yes' || item.inframe_only) ?
    //                         'if_door_' : 'door_'
    //         for (let i = 0; i < cabinetComponents.length; i++) {
    //             if (item[`${doorKey}${i +1}_qty`]) {
    //                 cabinetComponents[i].description = item[`${doorKey}${i +1}`];
    //                 cabinetComponents[i].qty = item[`${doorKey}${i +1}_qty`];
    //                 if (frontals) {
    //                     const matchingFrontal = Object.values<any>(frontals).find(front =>
    //                         (front.size_tag || '').replace(/[^0-9]/g, '').toLowerCase() === item[`${doorKey}${i +1}`].replace(/[^0-9]/g, '').toLowerCase()
    //                     );
    //                     if (matchingFrontal) {
    //                         cabinetComponents[i]._cost = matchingFrontal._cost ?? 0;
    //                         cabinetComponents[i].salePrice = matchingFrontal.salePrice ?? 0;
    //                         cabinetComponents[i].description = matchingFrontal._description;
    //                         if (matchingFrontal?.media?.image) {
    //                             cabinetComponents[i].image = matchingFrontal.media.image;
    //                         }
    //                     }
    //                 }
    //             }
    //         }

    //         // In-frame units sometimes have door & drawer frontal sets, combine the descriptions together.
    //         if (frontals && Object.keys(frontals).length && Object.keys(frontals).length < cabinetComponents.length) {
    //             const combinedSets = cabinetComponents.filter(component => !component._cost);
    //             cabinetComponents = cabinetComponents.filter(component => component._cost);
    //             combinedSets.forEach((combinedFrontal) => cabinetComponents[cabinetComponents.length -1].title += ` & ${combinedFrontal.title}`);
    //         }
    //     }

    //     return cabinetComponents;
    // }

    private getCabinetFrontalsFromConfig(item: Interfaces.ICabinet): IUnitComponentDetail[] {
        const frontals = this.activeRange
            ? this.catalogueService.getDoorForUnit(this.unit.unit_code, this.activeRange?.rangeColour || 'Alabaster')
            : null;
        if (frontals) {
            let doorStyle = item?.subcat === 'Drawerline' || item?.desc.match(/drawerline/i) ? 'Drawerline' : 'Highline';
            // Switch drawerline image to slab if other frontals (i.e drawers)
            doorStyle = (doorStyle === 'Drawerline' && Object.keys(frontals).length > 1) ? 'Highline' : doorStyle;
            Object.keys(frontals).forEach((key) => {
                if (frontals[key]?.subcat === 'Kitchen Door' && frontals[key].media) {
                    frontals[key].media.image = this.config.api.endpoints.cdn + '/' + this.cleanImageLink(this.catalogueService.getUnitAccessoryImage(
                        frontals[key].code,
                        frontals[key].size_tag,
                        frontals[key].desc,
                        frontals[key].height,
                        this.activeRange?.range?.name.toLowerCase() || 'clayton',
                        (this.activeRange) ? `${this.activeRange?.range?.name}-${this.activeRange?.colour}`.toLowerCase() : 'clayton-alabaster',
                        doorStyle
                    ));
                }
            });
        }

        let cabinetFrontals = this.costCalculator.getCabinetFrontalsFromConfig(item, this.activeRange, frontals);

        if (frontals) {
            const doorKey = (this.activeRange?.rangeDetail?.inframe === 'Yes' || item.inframe_only) ? 'if_door_' : 'door_';
            for (let i = 1; i <= 5; i++) {
                const door = (item[`${doorKey}${i}`] || '').toUpperCase();
                if (door && door.match(/cornerpost/i) && frontals[door]) {
                    let doorDetails = {
                        description: frontals[door].desc,
                        door: frontals[door],
                        editColour: false,
                        image: this.cleanImageLink(frontals[door].media?.image) || null,
                        qty: item[`${doorKey}${i}_qty`] || 1,
                        salePrice: frontals[door].salePrice || 0,
                        title: 'Corner Post',
                        _cost: frontals[door]._cost
                    }

                    cabinetFrontals.push(doorDetails);
                }
            }
        }

        return cabinetFrontals;
    }

    private cleanImageLink(url: string): string {
        if (typeof url === 'string') {
            return url.replace(' ', '-');
        }

        return url;
    }

    private getCabinetComponents(item: Interfaces.ICabinet): IUnitComponentDetail[] {
        let cabinetComponents: IUnitComponentDetail[] = this.getCabinetFrontalsFromConfig(item);
        let components = Object.entries(item)
            .filter(
                ([key, value]) =>
                    key.startsWith('component') &&
                    !key.includes('qty') &&
                    value &&
                    !value.includes('frontal') &&
                    !value.includes('shelf') &&
                    !value.includes('filler')
            )
            .map(
                ([key, value]): IUnitComponentDetail => ({
                    title: value.includes('drw') ? 'Drawer' : value.includes('shelf') ? 'Shelf' : value.includes('hinge') ? 'Hinges' : null,
                    description: this.cleanComponentDescription(value),
                    qty: item[key + '_qty'],
                    editColour: false,
                })
            );

        return [...cabinetComponents, ...components];
    }

    private cleanComponentDescription(value: string) {
        let cleanValue = (value || '').toLowerCase()
            .replace(/drw/g, 'drawer')
            .replace(/dwr/g, 'drawer')
            .replace(/std/g, 'standard')
            .replace(/\sint\s/g, ' internal ');

        return cleanValue;
    }

    private getEntry(item: Interfaces.ICabinet): IUnitEntry {
        const entry: IUnitEntry = {
            item: item,
            widths: [
                {
                    width: item.width,
                    code: item.unit_code,
                    variants: [this.getVariantDetails(item)],
                    cost: this.getItemCost(item),
                },
            ],
            doors: [],
            drawers: [],
            fillers: [],
            components: this.getCabinetComponents(item),
        };

        return entry;
    }

    public getRangeDoorImage(): string {
        return 'https://static.diy-kitchens.com/doors/' + this.activeRange?.colourDetail?.door_image;
    }

    public getRangeCarcaseImage(): string {
        return `https://static.diy-kitchens.com/carcases/Unit_${(this.activeRange?.carcaseColour || '').replace(/\s/g, '_')}.png`;
    }

    public getRangeCarcaseSwatch(): string {
        const carcaseSwatch = StringHelper.kebabCase(`swatch carcase ${this.activeRange?.carcaseColour}`);

        return `swatch ${carcaseSwatch}`;
    }

    public hingeWording(item: Interfaces.ICabinet): string {
        return this.catalogueService.getHingeWording(item);
    }

    public setHinge(entry: IUnitEntry, side: string): void {
        entry.item._hingeSide = side;
    }

    public selectRange(): void {
        this.catalogueService.openRangeSelector();
    }

    public sortWidths(widths: IUnitWidthDetail[]): IUnitWidthDetail[] {
        return widths.sort((a, b) => {
            if (a.width !== b.width) {
                return a.width - b.width;
            }
            return a.cost - b.cost;
        });
    }

    public toggleSoft(item): void {
        item.softClose = !item.softClose;
        if (item.softClose) {
            this.featureTags = this.featureTags.concat(['blum hinges']);
        } else {
            this.featureTags = this.featureTags.filter((tag) => tag !== 'blum hinges');
        }
    }

    public toggleDryAssembled(item) {
        item.dryAssembled = !item.dryAssembled;
    }

    private updateItem(): void {
        if (this.catalogueUnits && (this.unitLink || this.unitCode)) {
            this.unit = this.catalogueUnits.find((unit) => {
                if (this.unitLink) {
                    if (this.type === ProductType.RANGE_PRODUCTS) {
                        return this.unitLink === this.urlHelper.routeEnd(unit);
                    }
                    return this.unitLink === this.urlHelper.routeEnd(unit);
                } else {
                    return unit.unit_code.toLowerCase() === this.unitCode.toLowerCase();
                }
            });

            if (this.unit) {
                this.unit._cost = this.getItemCost(this.unit);
                if (this.unit.promotions) {
                    //this.unit.salePrice = this.getItemSalePrice(this.unit);
                }

                this.unit.softClose = this.config.settings.softClose;
                this.entry = this.getEntry(this.unit);

                this.catalogueUnits
                    .filter((item) => {
                        if (item.unit_code === this.unit.unit_code || item.height !== this.unit.height || item.depth !== this.unit.depth) {
                            return false;
                        }

                        if (item.type) {
                            return item.type === this.unit.type;
                        }

                        return item.category === this.unit.category && item.desc === this.unit.desc && item.subcat === this.unit.subcat;
                    })
                    .map((item) => {
                        item._cost = this.getItemCost(item);
                        if (item._cost > 0) {
                            const category = item.categoryLink || 'misc';
                            const subCategory = item.subCategoryLink || 'misc';
                            const code = item.unit_code.toLowerCase();
                            const title = StringHelper.spaceToDash(`${item.width}mm ${item.desc}`).replace(/_/g, '-');
                            const existingWidth = this.entry.widths.find((width) => width.width === item.width);
                            if (existingWidth) {
                                existingWidth.variants.push(this.getVariantDetails(item));
                            } else {
                                this.entry.widths.push({
                                    width: item.width,
                                    code: item.unit_code,
                                    cost: item._cost,
                                    variants: [this.getVariantDetails(item)],
                                    link: this.urlHelper.fullRoute(item),
                                });
                            }
                        }
                    });

                if (this.entry) {
                    const category = this.unit.categoryLink || 'misc';
                    const categoryDisplay = StringHelper.titleCase(StringHelper.dashToSpace(category));
                    const subCategory = this.unit.subCategoryLink || 'misc';
                    const subCategoryDisplay = StringHelper.titleCase(StringHelper.dashToSpace(subCategory));
                    const code = this.unit.unit_code;
                    const title = `${this.unit.width}mm ${this.unit.desc}`;

                    const routeHistory = this.routeHistory.slice();

                    this.entry.category = category;
                    this.entry.subCategory = subCategory;

                    routeHistory.push({
                        title: categoryDisplay,
                        route: `/kitchen-units/${category}`,
                    });
                    routeHistory.push({
                        title: subCategoryDisplay,
                        route: `/kitchen-units/${category}/${subCategory}`,
                    });
                    routeHistory.push({
                        title: this.unit.unit_code,
                        route: `/kitchen-units/${category}/${subCategory}/${this.unitLink}`,
                    });

                    this.navigationService.setTitle(`${title} - ${code} | DIY Kitchens`);
                    this.navigationService.setRouteHistory(routeHistory);

                    if (this.entry.widths && this.entry.widths.length) {
                        this.variants = this.entry.widths.find((itemWidth) => itemWidth.width === this.entry.item.width).variants;
                    }

                    this.featureTags = [];
                    if (this.entry.item.doors) {
                        this.featureTags.push('blum hinges');
                    }

                    if (this.entry.item.drawers) {
                        this.featureTags.push('soft close drawer');
                    }

                    const technicalImage = `${this.config.api.endpoints.cdn}/assets/images/products/carcases/extraimage1/${this.unit.unit_code}.jpg`;
                    this.fetchApi.head(technicalImage, {
                        // mode: 'no-cors',
                        cache: 'no-cache',
                        headers: { 'Content-Type': 'image/jpeg' }
                    })
                        .then(() => {
                            this.entry.item.technicalImageLink = `assets/images/products/carcases/extraimage1/${this.unit.unit_code}.jpg`;
                        })
                        .catch((error) => (this.entry.item.technicalImageLink = null));

                    this.updateMetaData();
                }

                this.loaded = true;
            } else {
                this.loaded = true;
            }
        }
    }

    public variantLink(entry, variant): string {
        return `/kitchen-units/${entry.category}/${entry.subCategory}/${variant.link}`;
    }

    private updateMetaData(): void {
        const metaDescription = `The ${this.entry.item.unit_code} ${this.entry.item.desc} is the ${this.entry.item.detail} from the DIY Kitchens ${this.subCategoryDisplay?.toLowerCase()} ${this.categoryDisplay?.toLowerCase()} range.`;
        this.navigationService.setMetaTags([
            { property: 'og:description', content: metaDescription },
            { name: 'Description', content: metaDescription },
            { property: 'og:locale', content: 'en_GB' },
            { property: 'og:type', content: 'website' },
            { property: 'og:site_name', content: 'DIY Kitchens' },
            { property: 'og:url', content: `${this.entry.item.width}mm ${this.unit.desc}` },
            { property: 'og:image', content: this.entry.item.media.image },
            { property: 'og:image:secure_url', content: this.entry.item.media.image },
            { property: 'og:image:width', content: '600' },
            { property: 'og:image:height', content: '400' }
        ]);
    }
}
