import { Injectable } from '@angular/core';

import { Config } from '@app/config';
import { DateHelper, NumberHelper } from '@app/utilities/helpers';

import { HttpApi } from '@app/services/api';
import { HttpApiOptions, HttpApiResponse } from '@app/services/api/models';
import { AuthCustomerService } from '@app/services/auth';
import { IBasketDeliveryType } from '@app/services/basket/models';
import { DialogService } from '@app/services/dialog';
import { StorageService } from '@app/services/storage';

import { Customer, DeliveryType } from './models';

@Injectable()
export class CheckoutService {
    public customer: Customer;

    private deliveryTypeCacheKey: string = 'DeliveryTypeCache';

    constructor(
        private config: Config,
        private httpApi: HttpApi,
        private authCustomerService: AuthCustomerService,
        private dialogService: DialogService,
        private storageService: StorageService
    ) { }

    public calculateOrderCost(cost, deliveryCharge, deliverySurcharge, paymentHistory) {
        const netTotal = cost + (deliveryCharge || 0) + (deliverySurcharge || 0);
        const vat = netTotal * 0.2;
        const grandTotal = netTotal + vat;

        let charged = 0;
        let refunded = 0;
        let outstandingAmount = grandTotal;

        if (paymentHistory) {
            paymentHistory.forEach((payment) => {
                if (payment.txntype !== 'preauth') {
                    charged += payment.chargeTotal - (payment.refundedAmount || 0);
                    refunded += payment.refundedAmount;
                } else {
                    charged += payment.chargeTotal;
                }
            });
            outstandingAmount = outstandingAmount - charged;
        }

        return {
            cost: NumberHelper.toPoundsAndPence(cost),
            netTotal: NumberHelper.toPoundsAndPence(netTotal),
            vat: NumberHelper.toPoundsAndPence(vat),
            grandTotal: NumberHelper.toPoundsAndPence(grandTotal),
            charged: NumberHelper.toPoundsAndPence(charged),
            refunded: NumberHelper.toPoundsAndPence(refunded),
            outstandingAmount: NumberHelper.toPoundsAndPence(outstandingAmount),
        };
    }

    public getOrderPaymentHistory(orderNumber): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/order/${orderNumber}/payment-history`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public getDeliveryTypes(variant): Promise<IBasketDeliveryType[]> {
        return new Promise((resolve, reject) => {
            let key = `${this.deliveryTypeCacheKey}-${variant}`;
            this.storageService.get(key)
                .then((cachedDeliveryType) => {
                    let now = DateHelper.now();

                    if (cachedDeliveryType && cachedDeliveryType.cacheTill > now) {
                        resolve(cachedDeliveryType.data);
                    } else {
                        const url = `${this.config.api.endpoints.diy}/basket/delivery/types/${variant}`;
                        const options: HttpApiOptions = {
                            authorization: {
                                ApplicationId: this.config.app.id,
                                UserToken: this.authCustomerService.universalToken
                            }
                        };

                        this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                            next: (response) => {
                                if (response.success) {
                                    this.storageService.set(key,
                                        {
                                            cacheTill: DateHelper.format(DateHelper.moment().add(15, 'minutes')),
                                            data: response.body
                                        })
                                        .then(() => resolve(response.body || []))
                                        .catch((error) => this.dialogService.error(this.constructor.name, error));

                                    resolve(response.body);
                                } else {
                                    reject(response);
                                }
                            },
                            error: (error) => reject(error)
                        });
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getAddressSurcharge(postcode: string, level: string): Promise<number> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/diy/delivery/charges/${postcode}/${level}`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public createPaymentHash(uuid: string, refundsToDo, paymentReference: string, amount: number): Promise<number> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/diy/basket/${uuid}/create-hash`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            const params = {
                url: this.config.api.endpoints.diy,
                paymentReference: paymentReference,
                amount: amount,
                refundsToDo: refundsToDo
            };

            this.httpApi.post<HttpApiResponse<any>>(url, params, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public createPaymentHashForOrder(orderId: number, refundsToDo, paymentReference: string, amount: number): Promise<number> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/diy/order/${orderId}/create-hash`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            const params = {
                url: this.config.api.endpoints.diy,
                paymentReference: paymentReference,
                amount: amount,
                refundsToDo: refundsToDo
            };

            this.httpApi.post<HttpApiResponse<any>>(url, params, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public deliveryTypeName(type: DeliveryType) {
        let result = '';

        switch (type) {
            case DeliveryType.STANDARD:
            case DeliveryType.PANEL_AND_DOOR_LARGE:
                result = 'Standard';
                break;
            case DeliveryType.COLLECTION:
                result = 'Collection';
                break;
            case DeliveryType.SAMPLE_DELIVERY:
                result = 'Nationwide Carrier';
                break;
            case DeliveryType.PANEL_AND_DOOR:
                result = 'Nationwide Carrier';
                break;
            case DeliveryType.HANDLE:
                result = 'Nationwide Carrier';
                break;
            case DeliveryType.SINK_AND_TAPS:
                result = 'Nationwide Carrier';
                break;
            case DeliveryType.APPLIANCE:
                result = 'FOC Appliance';
                break;
            case DeliveryType.ACCESSORIES:
                result = 'Nationwide Carrier';
                break;
            case DeliveryType.SMALL_ACCESSORIES:
                result = 'Nationwide Carrier';
                break;
            case DeliveryType.SOLID_WOOD:
                result = 'Standard';
                break;
            case DeliveryType.LAMINATES:
                result = 'Standard';
                break;
            case DeliveryType.SMALL_ORDER_MISC:
                result = 'Standard';
                break;
            case DeliveryType.SMALL_ORDER_UNITS:
                result = 'Standard';
                break;
        }
    
        return result;
    }
}
