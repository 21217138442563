import { Injectable } from '@angular/core';

import { Config } from '@app/config';

import { HttpApi } from '@app/services/api';
import { HttpApiOptions, HttpApiResponse } from '@app/services/api/models';
import { AuthCustomerService, AuthStaffService } from '@app/services/auth';
import { CustomerUserDetails } from '@app/services/auth/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';

// import { MessagingService } from '@app/services/messaging';
import { MessageGroup, MessageType } from '@app/services/messaging/models';

@Injectable()
export class MessagingService {
    constructor(
        protected config: Config,
        protected httpApi: HttpApi,
        protected dialogService: DialogService,
        protected navigationService: NavigationService,
        protected authStaffService: AuthStaffService,
        protected authCustomerService: AuthCustomerService
    ) {
        // super(config, httpApi, navigationService, authStaffService, authCustomerService);
    }

    public createTicket(title: string, message: string, groupIds?: MessageGroup[], files?: File[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const customerDetails: CustomerUserDetails = this.authCustomerService.details;
            if (customerDetails && customerDetails.id) {
                const url = `${this.config.api.endpoints.messaging}/message`;
                const options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id,
                        UserToken: this.authCustomerService.universalToken
                    }
                };

                const attachments = (files || []).map((file: any) => {
                    return {
                        name: file.name,
                        type: file.type,
                        binaryContent: file.binaryContent
                    };
                });

                let payload: any = {
                    title: title,
                    message: message,
                    priorityId: 2, // 1 | 2 | 3 | 4 (Low, Normal, High, Urgent) (DB: Messaging -> priorities)
                    customers: [this.authCustomerService.details.id],
                    typeId: MessageType.GENERAL,
                    options: {
                        statusId: 1, //  1 = Open, 2 = Closed
                        groups: groupIds,
                    },
                    attachments: attachments, // list of url attachments - generally to S3
                };

                this.httpApi.post<HttpApiResponse<any>>(url, payload, options).subscribe({
                    next: (response) => {
                        if (response.success) {
                            resolve();
                        } else {
                            reject(response);
                        }
                    },
                    error: (error) => {
                        reject(error);
                    }
                });
            } else {
                this.dialogService.notice('Authentication', 'There was an issue with your login details, log out and try again.', true);
            }
        });
    }
}
