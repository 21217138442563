import { Component, ViewEncapsulation, ViewChild, OnInit, OnDestroy, NgZone } from '@angular/core';

import { Config } from '@app/config';

import { CatalogueService } from '@app/services/catalogue';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';

@Component({
    selector: 'page-home',
    templateUrl: './home.page.html',
    styleUrls: ['./home.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class HomePage implements OnInit, OnDestroy {
    @ViewChild('videoBanner') set video(v) {
        this.videoBannerElement = v.nativeElement;
        if (this.config.isBrowser) {
            setTimeout(() => {
                if (this.videoBannerElement) {
                    this.videoBannerElement.muted = true;
                    const playPromise = this.videoBannerElement.play();
                    if (playPromise) {
                        playPromise
                            .then(() => { })
                            .catch((error) => {
                                // Silently fail for mobile devices and show a play button
                                this.videoBannerPlaying = false;
                            });
                    }
                }
            }, 100);
        }
    }

    @ViewChild('videoShowroom') set videoShowroom(v) {
        this.videoShowroomElement = v.nativeElement;
        if (this.config.isBrowser) {
            setTimeout(() => {
                if (this.videoShowroomElement) {
                    this.videoShowroomElement.muted = true;
                    const playPromise = this.videoShowroomElement.play();
                    if (playPromise) {
                        playPromise
                            .then(() => { })
                            .catch((error) => {
                                // Silently fail for mobile devices and show a play button
                                this.videoShowroomPlaying = false;
                            });
                    }
                }
            }, 100);
        }
    }

    public diy = this.config.diy;

    public videoBannerElement: HTMLVideoElement;
    public videoBannerPlaying = true;
    public videoShowroomElement: HTMLVideoElement;
    public videoShowroomPlaying = true;

    public numRanges = 0;
    public styles: any = [
        {
            name: 'High Gloss',
            link: 'high-gloss',
            count: 0,
            price: 0,
            image: 'https://static.diy-kitchens.com/assets/images/home/kb-highgloss.jpg',
            colour: '#F6EFE4'
        },
        {
            name: 'Shaker',
            link: 'shaker',
            count: 0,
            price: 0,
            image: 'https://static.diy-kitchens.com/assets/images/home/kb-shaker.jpg',
            colour: '#CAC3C8'
        },
        {
            name: 'Solid Wood',
            link: 'solid-wood',
            count: 0,
            price: 0,
            image: 'https://static.diy-kitchens.com/assets/images/home/kb-painted.jpg',
            colour: '#E0D5CB'
        },
        {
            name: 'Handleless',
            link: 'handleless',
            count: 0,
            price: 0,
            image: 'https://static.diy-kitchens.com/assets/images/home/kb-handleless.jpg',
            colour: '#D1D2D1'
        },
        {
            name: 'Inframe',
            link: 'inframe',
            count: 0,
            price: 0,
            image: 'https://static.diy-kitchens.com/assets/images/home/kb-inframe.jpg',
            colour: '#CBD5D9'
        },
        {
            name: 'Bespoke Painted',
            link: 'bespoke-painted',
            count: 0,
            price: 0,
            image: 'https://static.diy-kitchens.com/assets/images/home/kb-bespoke.jpg',
            colour: '#C4E9F8'
        },
        {
            name: 'Modern',
            link: 'modern',
            count: 0,
            price: 0,
            image: 'https://static.diy-kitchens.com/assets/images/home/kb-modern.jpg',
            colour: '#F6EFE4'
        },
        {
            name: 'Traditional',
            link: 'traditional',
            count: 0,
            price: 0,
            image: 'https://static.diy-kitchens.com/assets/images/home/kb-traditional.jpg',
            colour: '#F6EFE4'
        },
        {
            name: 'Slab Door',
            link: 'slab-door',
            count: 0,
            price: 0,
            image: 'https://static.diy-kitchens.com/assets/images/home/kb-slabdoor.jpg',
            colour: '#F6EFE4'
        }
    ];
    public finishes: any[] = [];
    public colours: any[] = [];
    public slideClicked;
    public currentSlide = 0;
    public slideHalt = false;
    public sliderInt: any;
    public totalStyles: number;
    public minPrice: number;

    public slides = [
        {
            image: 'https://static.diy-kitchens.com/assets/images/homepage/slider/homepage-linwood-slide.jpg',
            mobileImage: 'https://static.diy-kitchens.com/assets/images/homepage/slider/mobile/simply_shaker.jpg',
            linkUrl: '/kitchens/shaker'
        },
        {
            image: 'https://static.diy-kitchens.com/assets/images/homepage/slider/free-samples-homepage-slide-v2.jpg',
            mobileImage: 'https://static.diy-kitchens.com/assets/images/homepage/slider/mobile/free_samples.jpg',
            linkUrl: '/samples'
        },
        {
            image: 'https://static.diy-kitchens.com/assets/images/homepage/slider/homepage-rigidflatpack-slide.jpg',
            mobileImage: 'https://static.diy-kitchens.com/assets/images/homepage/slider/mobile/rigid_flat_pack.jpg',
            linkUrl: '/about-diy-kitchens'
        },
        {
            image: 'https://static.diy-kitchens.com/assets/images/homepage/slider/homepage-showroom-slide.jpg',
            mobileImage: 'https://static.diy-kitchens.com/assets/images/homepage/slider/mobile/showroom.jpg',
            linkUrl: '/about-diy-kitchens/showroom'
        },
        {
            image: 'https://static.diy-kitchens.com/assets/images/homepage/slider/Cento-Style_Launch.jpg',
            mobileImage: 'https://static.diy-kitchens.com/assets/images/homepage/slider/mobile/cento.jpg',
            linkUrl: '/kitchens/modern/cento/graphite'
        }
    ];

    constructor(
        private config: Config,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private ngZone: NgZone
    ) { }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: 'DIY Kitchens | Discount Kitchens, Doors & Cheap Kitchen Units',
            metaTags: [{ name: 'no metadata', content: 'no metadata' },
            { name: 'description', content: 'DIY Kitchens stock a huge range of discount kitchens, kitchen units, doors &amp; accessories at trade prices.' }]
        });

        this.navigationService.setMetaTags([
            { property: 'og:image', content: 'https://advice.diy-kitchens.com/wp-content/uploads/2022/09/social-logo_diy-kitchens.png' },
            { property: 'og:description', content: 'DIY Kitchens stock a huge range of discount kitchens, kitchen units, doors & accessories at trade prices.' }
        ]);

        this.getKitchenFinishes();

        this.catalogueService.getRanges()
            .then((ranges: any) => {
                this.totalStyles = 0;
                this.minPrice = 9999999999;

                ranges.map((range) => {
                    this.numRanges += range.colours.length;
                    // Build style swatches
                    range.filters.forEach((style) => {
                        const existingStyle = this.styles.find(existing => existing.name === style);
                        if (existingStyle) {
                            existingStyle.count += range.colours.length;
                            if (range.fromPrice < existingStyle.price || existingStyle.price === 0) {
                                existingStyle.price = range.fromPrice;
                            }
                        }
                    });

                    // Build Finishes Swatches
                    const incFinish = (finishName, increment) => {
                        const finish = this.finishes.find(existing => existing.name === finishName);
                        if (finish) {
                            finish.count += increment;
                            if (range.fromPrice < finish.price || finish.price === 0) {
                                finish.price = range.fromPrice;
                            }
                        }
                    };

                    switch (range.door_material) {
                        case 'Acrylic Faced MDF':
                        case 'Laquered MDF':
                            incFinish('High Gloss', range.colours.length);
                            incFinish('Smooth', range.colours.length);
                            break;
                        case 'Painted MDF':
                            incFinish('Painted', range.colours.length);
                            incFinish('Matt', range.colours.length);
                            incFinish('Smooth', range.colours.length);
                            break;
                        case 'Melamine MFC':
                        case 'Foil Wrapped MDF':
                            incFinish('Wood effect', range.colours.length);
                            break;
                        case 'Solid Timber':
                            const timber = range.colours.filter(colour => colour.type === 'Wood');
                            incFinish('Wood', timber.length);
                            incFinish('Painted', range.colours.length - timber.length);
                            incFinish('Matt', range.colours.length - timber.length);
                            break;
                    }

                    // Build Colour swatches
                    range.colours.forEach((colour) => {
                        if (!colour.availability) {
                            this.totalStyles++;
                            const displayName = colour.name.match(/bespoke/i) ? 'Bespoke Painted' : colour.name.replace(/ gloss/i, '');

                            const existingColour = this.colours.find(existing => existing.name === displayName);
                            if (existingColour) {
                                existingColour.count++;
                            } else {
                                this.colours.push({
                                    name: displayName,
                                    count: 1,
                                    css_class: colour.css_class,
                                    ref: colour.colour.replace(/ /g, '-').toLowerCase()
                                });
                            }
                        }
                    });
                });

                this.styles.forEach(style => {
                    if (style.price > 0 && style.price < this.minPrice) {
                        this.minPrice = style.price;
                    }
                });
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));

        if (this.config.isBrowser) {
            this.sliderInt = setInterval(() => {
                if (!this.slideClicked && !this.slideHalt) {
                    this.advanceSlider();
                } else {
                    this.slideClicked = null;
                }
            }, 10000);
        }
    }

    ngOnDestroy() {
        if (this.config.isBrowser) {
            clearInterval(this.sliderInt);
        }
    }

    public slideStyle(num) {
        if (this.config.isBrowser && window && window.innerWidth <= 875) {
            return {
                'background-image': 'url("' + this.slides[num].mobileImage + '")'
            };
        }

        return {
            'background-image': 'url("' + this.slides[num].image + '")'
        };
    }

    public slideLink(num) {
        if (this.slides[num - 1].linkUrl) {
            this.navigationService.navigate([this.slides[num].linkUrl]);
        }
    }

    public slidePos(num) {
        if (num === this.currentSlide) {
            return 'focus';
        }

        return num < this.currentSlide ? 'left' : 'right';
    }

    public slideTo(slide) {
        this.slideClicked = true;
        this.currentSlide = slide;
    }

    public advanceSlider() {
        this.currentSlide++;
        if (this.currentSlide >= this.slides.length) {
            this.currentSlide = 0;
        }
    }

    public slideLeft() {
        this.slideClicked = true;
        this.currentSlide--;
        if (this.currentSlide < 0) {
            this.currentSlide = this.slides.length - 1;
        }
    }

    public slideRight() {
        this.slideClicked = true;
        this.advanceSlider();
    }

    public slidehover(state) {
        this.slideHalt = state;
    }

    public getKitchenFinishes() {
        this.catalogueService.getKitchenFinishes()
            .then((data) => {
                this.finishes = data;
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public playVideoBanner(): void {
        this.videoBannerPlaying = true;
        this.videoBannerElement.play();
    }
}
