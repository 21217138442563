import { NgModule } from '@angular/core';

import { FilterService } from './filter.service';

@NgModule({
    providers: [
        FilterService
    ]
})
export class FilterServiceModule { }
