import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { LaminateWorktopsRouting } from './laminate-worktops.routing';
import { LaminateWorktopsPage } from './laminate-worktops.page';
import { EdgeProfilesPage } from './content/edge-profiles/edge-profiles.page';
import { AllTypesPage } from './content/all-types/all-types.page';


@NgModule({
    imports: [
        SharedModule,
        LaminateWorktopsRouting
    ],
    declarations: [
        LaminateWorktopsPage,
        EdgeProfilesPage,
        AllTypesPage
    ]
})
export class LaminateWorktopsPageModule { }
