@import '/src/theme/variables';

page-contact {
    h3 {
        margin: 0 0 30px;
    }

    .dept-blurb {
        margin-bottom: 20px;
        max-width: 800px;
        line-height: 1.6rem;
    }

    .next-step {
        display: flex;
        justify-content: center;
    }

    .edit-text {
        clear: both;
    }

    .edit {
        float: right;
        width: fit-content;

        button {
            margin-left: 20px;
        }
    }

    .raise-ticket {
        margin: 0 20px;

        .fields {
            margin: 20px 0;

            label {
                width: 300px;
            }

            input {
                margin: 10px 0;
                padding: 10px;
                width: 300px;
            }
        }

        textarea {
            padding: 10px;
            width: calc(100% - 20px);
            height: 200px;

        }
    }

    .file-inputs {
        display: none;
    }

    .files {
        display: flex;
        align-items: center;

        img {
            max-width: 200px;
            max-height: 200px;

            &.add {
                border: 0;
                width: 80px;
                height: 80px;
                opacity: 0.4;
            }
        }

        .thumb {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 15px;
            border: 1px solid #CCC;
            border-radius: 5px;
            font-size: 0.8rem;
        }

        div {
            text-align: center;
        }
    }

    .right-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            margin: 20px;
        }
    }

    .red {
        color: color('bright-red');
    }

    @media screen and (max-width: 1024px) {
        .page {
            padding: 10px;
            width: calc(100vw - 20px);
        }

        .right-buttons {
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .mat-icon {
        vertical-align: bottom;
    }

    .too-big {
        margin-right: 30px;
        width: 100px;
        height: 100px;
        font-size: 100px;
        vertical-align: middle;
    }
}
