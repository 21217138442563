@import '/src/theme/variables';

component-product-category {
    .contained {
        padding: 15px 0;

        &.categories {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
            gap: 15px;
        }
    }

    .category-link {
        cursor: pointer;
        display: block;
    }

    .category {
        padding: 15px;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
        background-color: color('white');
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(100% - 30px);

        img {
            max-width: 100%;
        }

        .count {
            font-weight: 600;
            display: flex;
            justify-items: flex-start;
            flex: 1;
            flex-direction: row;
            align-items: flex-end;
        }

        button {
            width: calc(100% - 40px);
            max-width: 400px;
            padding: 5px 0;
            text-transform: uppercase;
            margin-top: auto;
        }

        h5 {
            margin-top: 0.25rem;
        }

        @media (max-width: 768px) {
            h5 {
                margin: 0.8rem;
                font-size: 1.5rem;
                line-height: 1.333rem;
            }
        }

    }
}
