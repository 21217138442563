@import '/src/theme/variables';

.dialog-showroom-tour {
    .mdc-dialog {
        .mdc-dialog__content {
            padding-bottom: 0;
        }
    }
}

page-about-showroom {
    .horizontal-scroller {
        display: flex;
        overflow-x: auto;

        .horizontal-scroller-block {
            flex: 0 0 calc(30% - 30px);
            padding: 0 15px;

            h3 {
                margin-top: 1.333rem;
            }
        }

        @media only screen and (max-width: 768px) {
            .horizontal-scroller-block {
                flex: 0 0 calc(50% - 50px);
                padding: 0 15px;
            }
        }
    }

    .showroom-tour {
        position: relative;
        cursor: pointer;

        .showroom-icon {
            position: absolute;
            top: calc(50% - 90px);
            left: calc(50% - 90px);
        }
    }

    .showroom-address {
        text-align: left;
    }

    .panel {
        margin-bottom: 25px;
    }

    .actions {
        margin-top: 56px;

        .mdc-button+.mdc-button {
            margin-left: 25px;
        }
    }
}