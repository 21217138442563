import { BehaviorSubject } from 'rxjs';

import { Stream, StreamModel, StreamType } from './models';

export class RemoteDataStream<T> {
    /** Stream that emits whenever the data has been modified. */
    public dataChange$: BehaviorSubject<StreamModel<T>> = new BehaviorSubject<StreamModel<T>>(null);

    get data(): T[] {
        return (this.dataChange$.value.data || []).slice();
    }

    private stream: typeof Stream;

    constructor(stream?: typeof Stream) {
        if (stream) {
            this.stream = stream;
        } else {
            this.stream = () => new Promise<any>((resolve, reject) => resolve(null));
        }
    }

    public loader(stream: typeof Stream) {
        this.stream = stream;
    }

    public load(resetIndex: boolean = false): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.stream<T>(resetIndex).then(
                (response) => {
                    if (response) {
                        this.dataChange$.next({
                            data: response.data,
                            length: response.length,
                            pageIndex: response.pageIndex || null,
                            pageSize: response.pageSize || null,
                            type: StreamType.REMOTE
                        });
                    }

                    resolve();
                }
            ).catch((error) => reject(error));
        });
    }

    public close() {
        this.dataChange$.complete();
    }
}
