@import '/src/theme/variables';

page-about-history {
    .history-item {
        display: grid;
        gap: 30px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-bottom: 24px;

        &:nth-child(even) {
            .history-item-content {
                order: 1;
            }
        }

        .history-item-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 24px;
        }

        .history-item-image {
            position: relative;

            .history-item-image-container {
                position: absolute;
                left: 20px;
                right: 20px;
                bottom: 20px;
                display: flex;

                .history-item-image-description {
                    margin: 0 auto;
                    padding: 1rem;
                    border-radius: 5px;
                    background-color: color('white');

                    p {
                        margin: 0;
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }

    @media (max-width: 992px) {
        .history-item {
            grid-template-columns: repeat(1, minmax(0, 1fr));
            gap: 0;

            &:nth-child(even) {
                .history-item-content {
                    order: 0;
                }
            }
        }
    }
}