<mat-dialog-content>
    <div class="container-fluid options">
        <div class="option" *ngFor="let opt of options" [ngClass]="{ selected: (opt.code === option.code) }" (click)="select(opt)">
            <img [src]="'https://static.diy-kitchens.com/worktops/icons/' + opt.img" [alt]="opt.product_name"/>
            <div class="details">
                <em>{{ opt.product_name }}</em>
                <p><span [innerHTML]="opt.price | currencyFormat"></span> per Metre</p>
            </div>
        </div>
    </div>
</mat-dialog-content>