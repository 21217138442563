@import '/src/theme/variables';

page-plan-online {
    section {
        padding-bottom: 30px;
    }

    .ipad {
        margin-left: 60px;
        width: 600px;
        height: 700px;
        flex: 0 0 auto;
        overflow: hidden;

        img {
            width: 800px;
        }
    }

    @media only screen and (max-width: $mobile-width) {
        .text-right {
        }

        .ipad {
            margin-left: 20px;

            img {
                width: 400px;
            }
        }
    }
}
