<header class="content-header-corner content-header-background" [ngClass]="getHeaderClassName()">
    <header class="content-contained">
        <h1>{{category}} WORKTOPS</h1>
        <p [innerHtml]="getCategoryDescription()"></p>
    </header>
</header>
<section class="lightgrey">
    <div class="contained flex-space-between main-content-row">
        <div>
            <component-filters [items]="items" [filters]="filterFields" [sectionCats]="sectionCats" [section]="category"
                [sectionURL]="'solid-surfaces'" (filterChange)="updateItems($event)" [showSort]="false"></component-filters>

            <div class="video-block">
                <iframe width="100%" height="190" src="https://www.youtube.com/embed/UkHlDkuN-pY?rel=0" frameborder="0"
                    class="w-100" allowfullscreen=""></iframe>
            </div>
            <div class="left-grn-wtops-img">
                <img src="https://static.diy-kitchens.com/assets/images/graniteworktops/elite-showroom-cosentino.jpg"
                    alt="Elite Showroom Conentino" title="Elite Showroom Cosentino">
            </div>

        </div>
        <div class="products">
            <div class="doors-listing-top-banner">
                <div class="doors-listing-item">
                    <img src="https://static.diy-kitchens.com/assets/images/banner/listings-banner-trustpilot.jpg"
                        alt="See how we compare">
                </div>
                <div class="doors-listing-item">
                    <img src="https://static.diy-kitchens.com/assets/images/banner/offer-banner_real-customers_oct2020.jpg"
                        alt="View real customer kitchens">
                </div>
            </div>
            <div class="alert-info-section">
                <p><strong>Important note: </strong>Images for representative purposes. We recommend ordering a sample
                    before purchasing.</p>
                <p><strong>Installation note: </strong>All Solid surfaces are professionally templated prior to
                    manufacture so the information supplied on the calculator is not definitive. However if the
                    information is vastly incorrect extra charges may have to be applied. After the template process is
                    complete the delivery and installation team will be back to fit the work tops within 7 to 10 working
                    days. Please note all quotes done on the calculator will include the template and installation as we
                    feel this is imperative to achieve a stunning fit & finish.</p>
                <p><strong>Quality accreditations: </strong>Please <a href="https://static.diy-kitchens.com/images/documents/Natural_Stone_Surfaces_Quality_Accreditation.pdf" target="_blank" title="Quality Accreditations">click here</a> to view quality accreditations for our
                    range of solid surface worktops supplied by Natural Stone Surfaces.</p>
            </div>
            <div class="categories">
                <div class="item-container" *ngFor="let entry of filteredItems">
                    <div class="item">
                        <div class="image">
                            <img src="{{entry.item.media.image}}" [alt]="entry.item.sub_cat"/>
                        </div>
                        <div class="details">
                            <div class="flex-space-between">
                                <div class="product-brand-cnt">
                                    <img src="https://static.diy-kitchens.com/assets/images/brands/innova_logo.gif" *ngIf="entry.item.brand === 'Innova'" height="24" alt="Innova Logo" />
                                    <img src="https://static.diy-kitchens.com/assets/images/brands/silestone_logo.gif" *ngIf="entry.item.brand === 'Silestone'" height="24" alt="Silestone Logo" />
                                    <img src="https://static.diy-kitchens.com/assets/images/brands/dekton_logo.gif" *ngIf="entry.item.brand === 'Dekton'" height="24" alt="Dekton Logo" />
                                </div>
                                <div class="price">
                                    <p *ngIf="itemCost(entry.item)">
                                        <span class="common-price-span">from </span>
                                        <span class="common-price-span" [innerHTML]="itemCost(entry.item) | currencyFormat:true:true"></span>
                                        <span class="unit-text">per Sqr Metre</span>
                                    </p>
                                    <p class="common-small" *ngIf="!itemCost(entry.item)">Unavailable</p>
                                </div>
                            </div>
                            <span class="common-item-title">{{ entry.item.sub_cat }}</span>
                            <div class="description">
                                <!-- Example L-Shaped layout price <a (click)="openShapeDialog()">What's included?</a> -->
                            </div>
                            <div class="actions">
                                <button mat-stroked-button color="secondary"
                                    (click)="addToBasket(entry.item)">
                                    {{ entry.item.orderSampleText || 'Order Sample' }}
                                    <mat-icon class="basket-add-success" *ngIf="entry.item.buttonIcon">{{ entry.item.buttonIcon }}</mat-icon>
                                </button>

                                <button mat-flat-button color="primary" [routerLink]="configRoute(entry.item)">
                                    SELECT STYLE
                                </button>
                                <!-- <button mat-stroked-button class="chunky btn-hollow" *ngIf="!solidSurface(entry.item)"
                                    [routerLink]="itemRoute(entry.item)">
                                    Product options
                                </button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
