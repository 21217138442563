<section class="raise-ticket">
    <section *ngIf="!submitSuccess && !submitFailed">
        <h3>{{deptMsg}}</h3>

        <div class="dept-blurb" *ngIf="deptBlurb" [innerHTML]="deptBlurb"></div>
        <section *ngIf="ticket.fields.length>0" class="fields">
            <strong>Required information:</strong>
            <div *ngFor="let field of ticket.fields">
                <label>{{field.name}}</label>: <input [(ngModel)]="field.value" />
            </div>
        </section>
        <textarea [(ngModel)]="ticket.message" placeholder="Type your message here"></textarea>

        <div class="file-inputs">
            <input type="file" accept="image/*,application/pdf" #fileInput (change)="fileUpload($event)" />
        </div>
        <section class="files">
            <div *ngFor="let file of files" class="thumb">
                <div>
                    <img [src]="file.binaryContent" />
                    <br />
                    {{file.name}}
                </div>
                <button mat-button (click)="cancelFile(file); fileInput.value = ''">
                    <mat-icon>remove_circle</mat-icon> REMOVE IMAGE
                </button>
            </div>
            <div class="thumb" (click)="addFile(fileInput)">
                <button mat-flat-button color="secondary">
                    <mat-icon>attach_file</mat-icon>
                    ADD IMAGE OR FILE
                </button>
            </div>
        </section>

        <div class="right-buttons">
            <div *ngIf="disableTicket()" class="red">Minimum message size: {{minimumChars}} characters.</div>
            <button mat-raised-button (click)="raiseTicket()" [disabled]="disableTicket()">
                <mat-icon>send</mat-icon> SEND MESSAGE
            </button>
        </div>
    </section>

    <section *ngIf="submitSuccess">
        <h3>
            <mat-icon>done_outline</mat-icon> Message sent
        </h3>
        <p>Thank you for your enquiry, our team will get back to you shortly.</p>
        <div class="next-step">
            <button mat-raised-button routerLink="/support">
                <mat-icon>home</mat-icon> SUPPORT HOME
            </button>
            <button mat-raised-button routerLink="/support/messaging">
                <mat-icon>question_answer</mat-icon> VIEW ALL YOUR MESSAGES
            </button>
        </div>
    </section>

    <section *ngIf="submitFailed">
        <h3>
            <mat-icon>warning</mat-icon> Message Failed
        </h3>
        <p>There was a problem sending your message.</p>
        <p>&nbsp;</p>
        <ul *ngIf="files.length>0">
            <mat-icon class="too-big">photo_size_select_large</mat-icon>
            <strong>Please ensure any attachments
                you are sending have a file size less than 20Mb.</strong>
        </ul>
        <p>&nbsp;</p>
        <p>If this problem persists, please email your query to us at <a href="mailto:{{supportEmailAddress}}"><strong>{{supportEmailAddress}}</strong></a></p>
        <p>&nbsp;</p>
        <div class="next-step">
            <button mat-raised-button (click)="submitFailed=false">
                <mat-icon>check</mat-icon> GOT IT
            </button>
        </div>
    </section>
</section>
