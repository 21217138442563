export class StringUtility {
    public lowercase(value: string) {
        return (value === null) ? value : value.toLowerCase();
    }

    public uppercase(value: string) {
        return (value === null) ? value : value.toUpperCase();
    }

    public sentenceCase(value: string) {
        return ((value || '') + '')
            .trim()
            .toLowerCase()
            .replace(/^(\b\w)|\. (\b\w)/g, (l) => l.toUpperCase());
    }

    public titleCase(value: string) {
        return ((value || '') + '')
            .trim()
            .toLowerCase()
            .replace(/\b\w/g, (l) => l.toUpperCase());
    }

    public camelCase(value: string) {
        return (value || '').replace(/[^a-z ]/ig, '').replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
            if (+match === 0) {
                return '';
            }

            return index === 0 ? match.toLowerCase() : match.toUpperCase();
        });
    }

    public pascalCase(value: string) {
        return (value || '').replace(/[^a-z ]/ig, '').replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
            if (+match === 0) {
                return '';
            }

            return match.toUpperCase();
        });
    }

    public snakeCase(value: string) {
        return (value || '').match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map(x => x.toLowerCase())
            .join('_');
    }

    public kebabCase(value: string) {
        return (value || '').match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map(x => x.toLowerCase())
            .join('-');
    }

    public spaceToDash(value: string) {
        return (value || '').toLowerCase()
            .replace(/ /g, '-');
    }

    public spaceToUnderscore(value: string) {
        return (value || '').toLowerCase()
            .replace(/ /g, '_');
    }

    public dashToSpace(value: string) {
        return (value || '').toLowerCase()
            .replace(/-/g, ' ');
    }

    public clean(value: string) {
        if (typeof value === 'string') {
            if (value.trim() === '') {
                return null;
            } else {
                return value.trim();
            }
        }

        return value;
    }

    public cleanUrl_(value: string) { // Kept for legacy testing only
        return (value || '').replace(/[^a-z0-9\-\/]/gi, '')
            .replace(/---/g, '-')
            .replace(/--/g, '-');
    }

    public cleanUrl(value: string) {
        return (value || '')
            // Remove any character that is not a letter, number, space, forward slash, or hyphen; also, explicitly remove underscores and commas
            .replace(/[^a-z0-9 \/,-]|_|,/gi, '')
            // Replace one or more consecutive spaces, forward slashes, or hyphens with a single hyphen
            .replace(/[ \/,-]+/g, '-')
            // Replace multiple hyphens with a single hyphen
            .replace(/-+/g, '-');
    }

    public toBoolean = (value: any) => {
        switch ((value + '').toLowerCase()?.trim()) {
            case 'true':
            case 'yes':
            case 'y':
            case '1':
                return true;

            case 'false':
            case 'no':
            case 'n':
            case '0':
            case 'null':
            case 'undefined':
            default:
                return false;
        }
    }

    /**
     * Temporary method to attempt to clean mixed html markup from strings. It's here only as long as needed
     * by the catalogue service for processing the descriptions from product-details.json
     * @param html string containing dirty html markup
     * @returns An array of cleansed strings
     */
    public extractSentencesFromHtml(html: string): string[] {
        const replaceEntities = (str: string): string => {
            type HTMLEntityMap = {
                [key: string]: string;
            };

            const htmlEntities: HTMLEntityMap = {
                '&amp;': '&',
                '&lt;': '<',
                '&gt;': '>',
                '&quot;': '\'',
                '&#39;': '\'',
                '&pound;': '£'
            };

            for (const entity in htmlEntities) {
                str = str.replace(new RegExp(entity, 'g'), htmlEntities[entity]);
            }
            return str;
        }

        // Remove content inside anchor tags
        const noAnchors = html.replace(/<a[^>]*>.*?<\/a>/gi, '');

        // Uppercase entire headings
        const uppercasedHeadings = noAnchors.replace(/(<h[1-6][^>]*>.*?<\/h[1-6]>)/gi, match => match.toUpperCase());

        // Replace <br> and <br /> tags encapsulated within li tags
        const innerBrReplaced = uppercasedHeadings.replace(/(?<=<li[^>]*>.*?)<br\s*\/?>(?=.*?<\/li>)/g, ' ');

        // Replace standalone <br>, <br />, </li>, </p>, </h1>, etc., with unique identifiers
        const replacements = innerBrReplaced
            .replace(/<br\s*\/?>/gi, ' BR_TAG ')
            .replace(/<\/li>/gi, ' LI_END_TAG ')
            .replace(/<\/p>/gi, ' P_END_TAG ')
            .replace(/<\/(h1|h2|h3|h4|h5|h6)>/gi, ' H_END_TAG ')
            .replace(/<\/div>/gi, ' DIV_END_TAG ')
            .replace(/<\/blockquote>/gi, ' BLOCKQUOTE_END_TAG ')
            .replace(/<\/caption>/gi, ' CAPTION_END_TAG ')
            .replace(/<\/(dt|dd)>/gi, ' DL_END_TAG ')
            .trim();

        // Remove other HTML tags
        const strippedHtml = replacements.replace(/<[^>]+>/g, ' ').trim();

        // Split into sentences
        const splitPattern = /BR_TAG|LI_END_TAG|P_END_TAG|H_END_TAG|DIV_END_TAG|BLOCKQUOTE_END_TAG|CAPTION_END_TAG|DL_END_TAG|\s{3,}/;
        const sentences = strippedHtml.split(splitPattern)
            .map(sentence => sentence.trim().replace(/\s{2}/g, ' '));

        // Remove unwanted characters from the start of the sentences but keep '('
        const refinedSentences = sentences.map(sentence => sentence.replace(/^[^\w(]+/, ''));

        // Filter out any empty strings or irrelevant sentences
        const fullSentences = refinedSentences.filter(sentence => sentence.length > 1);

        // Append full stop to the sentences that meet the criteria
        const cleanSentences = fullSentences.map(sentence => {
            const lastChar = sentence[sentence.length - 1];
            if (!/[A-Z0-9\)\]\.\!\?\,\;\:\-]$/g.test(sentence)) {
                return sentence + '.';
            }
            return sentence;
        }).map(replaceEntities);

        return cleanSentences;
    }
}

export const StringHelper = new StringUtility();
