export enum ProductType {
    CABINETS = 'Cabinets',
    SINK_AND_TAPS = 'Sinks & Taps',
    APPLIANCES = 'Appliances',
    DOORS = 'Doors',
    SAMPLE = 'Sample',
    SAMPLE_DOORS = 'Door Samples',
    SAMPLE_CARCASE = 'Carcase Samples',
    SAMPLE_WORKTOP = 'Worktop Samples',
    HANDLES = 'Handles',
    ACCESSORIES = 'Accessories',
    WORKTOPS = 'Worktops',
    RANGE_PRODUCTS = 'Range Accessories',
    PANELS = 'Panels',
    DRAWER_BOXES = 'Drawer Boxes',
    DELIVERY = 'Delivery',
    SURCHARGES = 'Surcharges',
    DISCOUNTS = 'Discounts',
    EXTERNAL_CURVED_CORNICE = 'External Curved Cornice',
    CORNICE_PELMET = 'Cornice Pelmet',
    OPEN_FRAME_PROFILE = 'Open Frame Profile',
    PLINTHS = 'Plinths'
}
