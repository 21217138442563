@import '/src/theme/variables';

page-about-cookie-policy {
    table {
        border: 1px solid color('white', 0.1);
        border-collapse: collapse;

        tr {
            border-bottom: 1px solid color('white', 0.1);
        }

        td {
            padding: 10px 30px;
            vertical-align: top;

            &:first-child {
                font-weight: 600;
            }
        }

        @media only screen and (max-width: 800px) {
            td {
                display: block;
                padding: 10px;
            }
        }

    }
}