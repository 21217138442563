<mat-dialog-content>
    <button mat-icon-button class="close-dialog" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>

    <section *ngIf="video">
        <video autoplay>
            <source [src]="video" />
        </video>
    </section>

    <section *ngIf="imageStyle" class="image" [ngStyle]="imageStyle"></section>

    <section *ngIf="unsupported">
        <h4>Unsupported media type.</h4>
    </section>
</mat-dialog-content>