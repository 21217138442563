import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';

import { Config } from '@app/config';

import { DateHelper } from '@app/utilities/helpers';
import { TableDataModel, RemoteDataStream } from '@app/utilities/table-data';

import { BasketService } from '@app/services/basket';
import { CheckoutService } from '@app/services/checkout';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { OrdersService } from '@app/services/orders';
import { IOrder } from '@app/services/orders/models';
import { Subscription } from 'rxjs';

@Component({
    selector: 'page-orders',
    templateUrl: './orders.page.html',
    styleUrls: ['./orders.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class OrdersPage implements OnInit, OnDestroy {
    public ordersLoaded: boolean = false;

    public tableData: TableDataModel<any>;
    public displayColumns: string[] = [
        'order',
        'payment',
        'customer',
        'style',
        'status',
        'actions'
    ];

    public reportIssue = false;

    private stream: RemoteDataStream<any>;
    private cutoff: string;

    private routeSubscription: Subscription;

    constructor(
        private config: Config,
        private basketService: BasketService,
        private checkoutService: CheckoutService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private ordersService: OrdersService,
    ) {
        navigationService.setNavigation({
            title: 'DIY Kitchens - Your Orders',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' }
            ],
            routeHistory: [
                { title: 'Support', route: '/support' },
                { title: 'Your orders', route: '/account/orders' }
            ]
        });
    }

    ngOnInit() {
        this.cutoff = DateHelper.moment().startOf('isoWeek').subtract(2, 'weeks').format('YYYY-MM-DD');

        this.tableData = new TableDataModel<IOrder[]>({
            remoteDataStream: new RemoteDataStream()
        });
        this.stream = <RemoteDataStream<IOrder[]>>this.tableData.stream;

        this.routeSubscription = this.navigationService.route.subscribe((route) => {
            if (route && Array.isArray(route.segments)) {
                if (route.segments.includes('report-issue')) {
                    this.reportIssue = true;
                }
            }
        });

        this.stream.loader(this.loadOrdersForStream());
        this.stream.load(true)
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    ngOnDestroy(): void {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }

    public openPlan(planId: number) {
        window.open(`${this.config.api.endpoints.plannerUI}/basket/checkout/?pid=${planId}`, '_new');
    }

    public openOrder(order: IOrder, viewInvoice: boolean = false) {
        if (viewInvoice) {
            if (this.config.isBrowser) {
                window.open(`/account/invoice/${order.orderNumber}`, '_blank');
            }
        } else {
            this.navigationService.navigate([
                'account',
                'order',
                order.orderNumber
            ]);
        }
    }

    public showPrint(order: IOrder) {
        if (order.type !== 1) {
            return true;
        }

        return false;
    }

    public showEdit(order: IOrder) {
        if (
            order.type === 2 &&
            !order.planId &&
            (!order.weekCommencing || order.cutoffDate > DateHelper.now())
        ) {
            return true;
        }

        return false;
    }

    public showPayment(order: IOrder) {
        if (
            order.type === 2 &&
            !order.planId &&
            order.outstandingAmount > 0
        ) {
            return true;
        }

        return false;
    }

    public showCancel(order: IOrder) {
        if (
            order.type === 2 &&
            !order.planId &&
            !order.removedAt
        ) {
            return true;
        }

        return false;
    }

    public showRemedial(order: IOrder) {
        if (order.inMCS && order.weekCommencing <= this.cutoff) {
            return true;
        }

        return false;
    }

    public deliveryTypeName(order) {
        if (order.type === 2 && !order.planId) {
            return this.checkoutService.deliveryTypeName(order.deliveryType);
        }

        return order.deliveryType || '';
    }

    public editOrder(order: IOrder) {
        if (order.type === 3) {
            const url = `https://www.diy-kitchens.com/myorder/default.asp?autoorder=${order.diyId}&autoemail=${order.email}`;
            window.open(url, '_blank');
        } else if (order.type === 2 && !order.planId && !order.removedAt) {
            this.ordersService.reopenBasketForOrder(order.orderNumber)
                .then((basketUuid) => {
                    if (basketUuid) {
                        this.basketService.switch(basketUuid)
                            .then(() => this.navigationService.navigate(['basket']))
                            .catch((error) => this.dialogService.error(this.constructor.name, error));
                    }
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        }
    }

    public editOrderDetails(order: IOrder) {
        if (order.type === 2 && !order.planId && !order.removedAt) {
            this.navigationService.navigate([
                'checkout',
                'order',
                'address',
                order.orderNumber
            ]);
        }
    }

    public makePayment(order: IOrder) {
        if (order.type === 2 && !order.planId && !order.removedAt) {
            this.navigationService.navigate([
                'checkout',
                'order',
                order.orderNumber,
                'payment'
            ]);
        }
    }

    public cancelOrder(order: IOrder) {
        if (order.type === 2 && !order.planId && !order.removedAt) {
            this.dialogService.notice(
                'Cancel Order',
                'Please contact customer support if you would like to cancel your order.',
                true
            );
        }
    }

    public createRemedial(order: IOrder, action: string = null) {
        if (order.inMCS) {
            this.navigationService.navigate([
                'account',
                'order',
                order.orderNumber,
                action
            ]);
        }
    }

    private loadOrdersForStream() {
        return (resetIndex: boolean = false) => {
            return new Promise<any>((resolve, reject) => {
                this.ordersService.getOrders()
                    .then((orders) => {
                        this.ordersLoaded = true;

                        resolve({ data: orders, length: orders.length });
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            });
        };
    }
}
