import { Component, ViewEncapsulation, Input, OnInit, OnChanges } from '@angular/core';
import { IMedia } from '@app/services/catalogue/models/product.models/common';
import { DialogService } from '@app/services/dialog';
import { ProductImageGalleryDialogComponent } from './dialog/product-image-gallery.dialog.component';
import { Config } from '@app/config';

@Component({
    selector: 'component-product-image-gallery',
    templateUrl: './product-image-gallery.component.html',
    styleUrls: ['./product-image-gallery.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ProductImageGalleryComponent implements OnInit, OnChanges {
    @Input() images: IMedia[] = [];

    public focussedImage: IMedia;
    public selectedImage: IMedia;

    constructor(
        private dialogService: DialogService,
        private config: Config
    ) { }

    ngOnInit(): void {
        if (!Array.isArray(this.images)) {
            this.images = [];
        }

        if (this.images?.length) {
            this.focussedImage = this.images[0];
            this.selectedImage = this.images[0];
        } else {
            this.focussedImage = {
                image: `${this.config.api.endpoints.cdn}/assets/images/products/misc/cimageurl/noimage.jpg`
            };
        }
    }

    ngOnChanges(): void {
        if (this.images?.length) {
            this.focussedImage = this.images[0];
            this.selectedImage = this.images[0];
        }
    }

    public focusImageClick(image: IMedia): void {
        this.dialogService.custom(ProductImageGalleryDialogComponent, {
            width: '80%',
            maxWidth: '1000px',
            minWidth: '390px',
            data: {
                focussedImage: image,
                images: this.images
            }
        })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public thumbnailClick(image: IMedia): void {
        this.focussedImage = image;
        this.selectedImage = image;
    }
}
