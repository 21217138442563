import { NgModule, NO_ERRORS_SCHEMA, APP_ID } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule, HAMMER_GESTURE_CONFIG, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, UrlSerializer } from '@angular/router';
import { Location } from '@angular/common';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule, } from 'ngx-google-analytics';

import { Config } from '@app/config';
import { ServicesModule } from '@app/services';
import { SharedModule } from '@app/shared';
import { CustomHammerGestureConfig } from '@app/utilities/general/hammer.gestures';
import { CustomUrlSerializerStrategy } from '@app/utilities/strategies';

import { AppRouting } from './app.routing';
import { AppComponent } from './app.component';

const globalRippleConfig: RippleGlobalOptions = {
    disabled: true,
};

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRouting,
        ServicesModule,
        SharedModule,
        NgxGoogleAnalyticsModule.forRoot(new Config(null).tracking.gtag.id),
        NgxGoogleAnalyticsRouterModule
    ],
    declarations: [AppComponent],
    providers: [
        Config,
        { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerGestureConfig },
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
        // { provide: UrlSerializer, useClass: CustomUrlSerializerStrategy, deps: [Location] },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
        { provide: APP_ID, useValue: 'serverApp' },
        provideClientHydration()
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
    // Diagnostic only: inspect router configuration
    constructor(router: Router) {
        // console.log('Routes: ', JSON.stringify(router.config, null, 2));
    }
}
