<header class="content-header-corner content-header-background content-header-kitchens-all-styles">
    <header class="content-contained">
        <h1>Customer Reviews</h1>
        <p>Browse DIY Kitchens' kitchens, designed and installed in real homes by real people throughout the UK. All
            ordered online through our website. Read and watch their thoughts and experiences and also take a look at
            some of the photos they have sent us.</p>
    </header>
</header>

<hr class="narrow" />
<section class="content-contained">
    <div class="review-container">
        <div class="filters" *ngIf="filters">
            <div class="filter filter-range-type">
                <h4>Kitchen Style</h4>
                <div class="filter-options">
                    <div class="filter-option" [ngClass]="{ selected: filterOption.id === filters.rangeType.selected }" *ngFor="let filterOption of filters.rangeType.options">
                        <a [routerLink]="filterOption.link" [title]="filterOption.name">
                            <img class="filter-image" [src]="filterOption.image" [alt]="filterOption.name"/>
                            <span>{{filterOption.name}}</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="filter filter-colour">
                <h4>Kitchen Colour</h4>
                <div class="filter-options">
                    <div class="filter-option" [ngClass]="{ selected: filterOption.id === filters.colour.selected }" *ngFor="let filterOption of filters.colour.options">
                        <a [routerLink]="filterOption.link" [matTooltip]="filterOption.name" [title]="filterOption.name">
                            <div class="swatch" [ngClass]="'swatch-' + filterOption.image"></div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="filter filter-finish">
                <h4>Kitchen Finish</h4>
                <div class="filter-options">
                    <div class="filter-option" [ngClass]="{ selected: filterOption.id === filters.finish.selected }" *ngFor="let filterOption of filters.finish.options">
                        <a [routerLink]="filterOption.link" [matTooltip]="filterOption.name" [title]="filterOption.name">
                            <img class="filter-image" [src]="filterOption.image" [alt]="filterOption.name"/>
                            <span>{{filterOption.name}}</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="filter filter-review-type">
                <h4>Review Type</h4>
                <div class="filter-options">
                    <div class="filter-option" [ngClass]="{ selected: filterOption.id === filters.reviewType.selected }" *ngFor="let filterOption of filters.reviewType.options">
                        <a [routerLink]="filterOption.link" [matTooltip]="filterOption.name" [title]="filterOption.name">
                            <mat-icon class="filter-image">{{filterOption.image}}</mat-icon>
                            <span>{{filterOption.name}}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="paginatedResults && paginatedResults.length">
            <div class="reviews">
                <div class="review" *ngFor="let review of paginatedResults">
                    <div class="review-header">
                        <div class="header-left">
                            <h2>{{review.kitchen_style}}</h2>
                        </div>
                    </div>
                    <div class="video-container" *ngIf="review.videoURL" id="test">
                        <iframe [src]="review.videoURL" frameborder="0" allowfullscreen=""></iframe>
                    </div>
                    <div *ngIf="!review.videoURL" class="images">
                        <component-product-image-gallery [images]="review.images"></component-product-image-gallery>
                    </div>
                    <div class="review-information">
                        <h3>{{review.customer_name}}</h3>
                        <p *ngFor="let para of review.paras">{{para}}</p>
                    </div>
                </div>
                <mat-paginator [length]="filteredResults.length" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
                    (page)="updatePage($event)">
                </mat-paginator>
            </div>
        </ng-container>
        <ng-container *ngIf="loaded && (!paginatedResults || !paginatedResults.length)" #noResults>
            <div class="reviews">
                <div class="reviews">
                    <div class="review">
                        <div class="review-header">
                            <div class="header-left">
                                <h2>No Results</h2>
                            </div>
                        </div>
                        <div class="review-information">
                            <p>Your selected filters returned no results. Try changing or removing a filter to see more reviews.</p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</section>
