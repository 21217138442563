@import '/src/theme/variables';

page-about-careers {
    .mat-expansion-panel-header {
        font-weight: 600;
    }

    .mat-expansion-panel-header-title {
        width: 50%;
    }

    .mat-expansion-panel-header-description {
        width: 40%;
    }

    .intro {
        padding: 30px 0;
    }

    table {
        td:first-child {
            width: 150px;
        }
    }
}
