import { Component, ViewEncapsulation, HostListener, ViewChild, ElementRef } from '@angular/core';

import { Config } from '@app/config';
import { AuthCustomerService } from '@app/services/auth';
import { NavigationService } from '@app/services/navigation';

@Component({
    selector: 'page-messaging',
    templateUrl: './messaging.page.html',
    styleUrls: ['./messaging.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class MessagingPage {
    @ViewChild('messagingApp', { static: true }) messagingApp: ElementRef;

    public messagingURL: string = this.config.api.endpoints.messagingUI;

    constructor(
        private config: Config,
        private authCustomerService: AuthCustomerService,
        private navigationService: NavigationService
    ) {
        this.navigationService.setNavigation({
            title: 'DIY Kitchens - Your Messages',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' }
            ],
            routeHistory: [
                { title: 'Support', route: '/support' },
                { title: 'Your messages', route: '/support/messaging' }
            ]
        });
    }

    @HostListener('window:message', ['$event']) onMessage(event) {
        if (
            this.config.trusted.messaging.indexOf(event.origin) !== -1 ||
            event.origin.match(/^https:\/\/[a-z0-9-_\.]+\.diy-kitchens.com/i)
        ) {
            let data = event.data;

            if (data && data.isReady) {
                event.source.postMessage({
                    navigate: 'planner',
                    token: this.authCustomerService.applicationToken
                }, '*');
            }
        }
    };
}
