import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { Config } from '@app/config';

import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { RouteHistory } from '@app/services/navigation/models';

import { YouTubeVideoDialogComponent } from '@app/dialogs/youtube-video';

@Component({
    selector: 'page-about',
    templateUrl: './about.page.html',
    styleUrls: ['./about.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AboutPage implements OnInit {
    public diy = this.config.diy;

    private routeHistory: RouteHistory[] = [
        { title: 'About DIY Kitchens', route: '/about-diy-kitchens' }
    ];

    constructor(
        private config: Config,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: 'About DIY Kitchens',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'description', content: 'We are a family run business that has been manufacturing and supplying high specification kitchens to both trade and retail customers for nearly 30 years.' }
            ],
            routeHistory: this.routeHistory
        });
    }

    public showVideo(url: string) {
        this.dialogService.custom(
            YouTubeVideoDialogComponent,
            {
                width: '65%',
                height: '65%',
                data: {
                    url: url
                }
            }
        )
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
