// If these change, update on API as well
export enum DeliveryType {
    STANDARD = 'standard',
    COLLECTION = 'collection',
    SAMPLE_DELIVERY = 'sample delivery',
    PANEL_AND_DOOR = 'panel and door delivery',
    PANEL_AND_DOOR_LARGE = 'panel and door large delivery',
    HANDLE = 'handle delivery',
    SINK_AND_TAPS = 'sink and taps delivery',
    APPLIANCE = 'appliance delivery',
    ACCESSORIES = 'accessories delivery',
    SMALL_ACCESSORIES = 'accessories small delivery',
    SOLID_WOOD = 'solid wood delivery',
    LAMINATES = 'laminates delivery',
    SMALL_ORDER_MISC = 'misc delivery',
    SMALL_ORDER_UNITS = 'standard small',
}
