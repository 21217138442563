import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { PipesModule } from '@app/pipes';

import { DrawerUpgradeDialogModule } from './drawer-upgrade/drawer-upgrade.dialog.module';

@NgModule({
    imports: [
        DesignModule,
        DirectivesModule,
        PipesModule,

        DrawerUpgradeDialogModule
    ]
})
export class BasketServiceDialogModule { }
