// Update enum on API
export enum ErrorsGeneral {
    SQL_ERROR = 500,
    NO_BASKET_ITEMS = 3500,
    NO_UNIT_IN_BASKET = 3501,
    BASKET_LOCKED = 2009,
    BASKET_NOT_FOUND = 2404,
    BASKET_ITEM_NOT_FOUND = 3404,
    BASKET_ALREADY_SYNCED = 2600,
    MINIMUM_COST_NOT_MET = 2002,
    CUSTOMER_DETAILS_NOT_FOUND = 4404,
    ORDER_NOT_FOUND = 6404,
    CONTAINS_OUT_OF_RANGE_ITEM = 3702,
    NOT_AUTHORISED = 1500
}
