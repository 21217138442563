component-delivery-access {
    .delivering-order {
        border: 4px solid #ccc;
        padding: 20px;
        margin: 15px 0;

        .delivering-order-title {
            font-size: 40px;
            font-weight: bold;
            margin: 30px auto;
            max-width: 700px;
            line-height: 42px;
            text-align: center;
            color: rgba(0, 18, 36, 0.9);
        }

        .delivering-order-content {
            margin: 30px auto 40px;
            max-width: 930px;

            .error-message {
                font-weight: 600;
            }
        }

        .delivering-order-body-cnt {
            display: flex;
            justify-content: space-between;
            margin: 30px auto 40px;
            max-width: 930px;
            border: 4px solid #25E676;
            padding: 20px;

            &.border-0 {
                border: none;
            }

            h4 {
                font-size: clamp(1rem, 1vw + 1rem, 1rem);
                font-style: normal;
                font-weight: 700;
                letter-spacing: 0.05rem;
                line-height: 1.618rem;
                margin-bottom: 1rem;
                color: rgba(0, 18, 36, 0.9);
                text-transform: uppercase;
                margin-top: 0;
            }

            p {
                font-size: clamp(1rem, 1vw + 1rem, 1rem);
                font-style: normal;
                font-weight: 400;
                line-height: 1.618rem;
                margin-bottom: 0;
            }

            .del-order-info-content-left {
                width: 600px;
            }

            .del-order-info-content-right {
                width: 318px;
            }


            .del-order-info-list {
                list-style-type: none;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                row-gap: 15px;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    column-gap: 20px;

                    .mat-icon {
                        flex-shrink: 0;
                        font-size: 30px;
                        width: 30px;
                        height: 30px;
                        color: #25E676;
                    }
                }
            }
        }

        .acc-steps-img {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: auto;
            }
        }

        .delivering-order-img {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin-bottom: 40px;

            .del-order-img-inner {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 720px;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }

        }

        .delivering-order-action {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 40px;
            margin: 10px 0 30px;
        }

        @mixin btn-delivering {
            display: inline-block;
            padding: 0 20px;
            border-radius: 3px;
            font-size: 14px;
            font-weight: 700;
            min-width: 90px;
        }

        .btn-success {
            @include btn-delivering;
            background-color: rgb(37, 228, 112);
            color: #000;
        }

        .btn-danger {
            @include btn-delivering;
            background-color: rgb(252, 31, 73);
            color: #ffffff;
        }
    }

    @media only screen and (max-width: 768px) {
        .delivering-order {
            .delivering-order-body-cnt {
                flex-direction: column;

                .del-order-info-content-left {
                    width: 100%;
                }
                .del-order-info-content-right {
                    width: 100%;
                }
            }
            .delivering-order-action {
                flex-direction: column;
                gap: 20px;
            }
        }
    }
}