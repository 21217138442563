<button mat-flat-button class="chunky basket-add-button" color="primary" *ngIf="!isUnit || activeRange"
    [ngClass]="{ 'basket-adding': addingToBasket, 'basket-success': (addedToBasket === true), 'basket-failure': (addedToBasket === false) }"
    [disabled]="(!item._cost && !allowFree) || addingToBasket || disabled" (click)="addToBasket(item)">
    <span class="label" *ngIf="!addingToBasket && addedToBasket === null">
        @if (customLabel) {
            {{customLabel}}
        } @else {
            Add To Basket
        }
    </span>
    <span class="label" *ngIf="addingToBasket && addedToBasket === null">Adding...</span>
    <span class="label" *ngIf="!addingToBasket && addedToBasket === true">Added!</span>
    <span class="label" *ngIf="!addingToBasket && addedToBasket === false">Error</span>
    <mat-icon class="basket-add-success">done</mat-icon>
    <mat-icon class="basket-add-failure">close</mat-icon>
    <mat-spinner class="basket-spinner" diameter="25" color="accent"></mat-spinner>
</button>
