import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';
import { DirectivesModule } from '@app/directives';
import { PipesModule } from '@app/pipes';

import { BreadcrumbsComponentModule } from '@app/components/breadcrumbs/breadcrumbs.component.module'
import { HeaderSearchComponentModule } from '@app/components/header-search/header-search.component.module'
import { MegaMenuComponentModule } from '@app/components/mega-menu/mega-menu.component.module'

import { HeaderComponent } from './header.component';
import { MobileMenuComponentModule } from "./mobile-menu/mobile-menu.component.module";

@NgModule({
    imports: [
        DesignModule,
        DirectivesModule,
        PipesModule,
        BreadcrumbsComponentModule,
        HeaderSearchComponentModule,
        MegaMenuComponentModule,
        MobileMenuComponentModule
    ],
    exports: [
        HeaderComponent
    ],
    declarations: [
        HeaderComponent
    ]
})
export class HeaderComponentModule { }
