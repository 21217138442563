<div mat-dialog-title>
    <div class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </div>
</div>
<mat-dialog-content>
    <div [ngClass]="{ panel: videoLink }">
        <div class="panel-left">
            <h3>{{title}}</h3>
            <p [innerHTML]="description | sanitizeHtml"></p>
        </div>
        <div class="panel-right" *ngIf="videoLink">
            <div class="panel-frame">
                <iframe [src]="getVideoLink() | safe:'resourceUrl'" allowfullscreen=""></iframe>
            </div>
        </div>
    </div>

    <button *ngIf="this.references" mat-button color="secondary" (click)="viewReferenceData()">View reference data</button>
</mat-dialog-content>
