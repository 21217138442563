<!-- Question-1 (start) -->
<div class="delivering-order" *ngIf="choice === DeliveryAccess.ACCESS_VEHICLE_SIZE">
    <h2 class="delivering-order-title">Can a vehicle this size fit down your street and park easily?</h2>
    <div class="delivering-order-body-cnt">
        <div class="del-order-info-content-left">
            <h4>We can deliver safely if you can answer yes to the following</h4>
            <ul class="del-order-info-list">
                <li>
                    <mat-icon>check_circle_outline</mat-icon>
                    <p>My road is more than 9.9ft (3m) wide.</p>
                </li>
                <li>
                    <mat-icon>check_circle_outline</mat-icon>
                    <p> My road is well tarmacked.</p>
                </li>
                <li>
                    <mat-icon>check_circle_outline</mat-icon>
                    <p> A vehicle 9.9ft (3m) wide X 40ft (12.2m) long can park easily.</p>
                </li>
            </ul>
        </div>
        <div class="del-order-info-content-right">
            <h4>Don't worry, if you answer no we could still deliver</h4>
            <p class="error-message big">
                If your road is not suitable for our large vehicles we will add a £100 access surcharge to change to a smaller vehicle.
                Please answer the question honestly, otherwise we may need to refuse offloading of your delivery and then you could be charged a redelivery and holding fee.
            </p>
        </div>
    </div>
    <div class="delivering-order-img">
        <div class="del-order-img-inner">
            <img src="https://static.diy-kitchens.com/assets/images/general/vehicle_access.jpg" alt="Delivery vehicle is up to 40ft (12.2m) in length, 12ft (3.7m) tall, and up to 9.9ft (3m) wide."/>
        </div>
    </div>
    <div class="delivering-order-action">
        <button class="btn-success" mat-stroked-button (click)="answer(true)">
            <mat-icon>done</mat-icon>Yes, the vehicle will fit down my street
        </button>
        <button class="btn-danger" mat-stroked-button (click)="answer(false)">
            <mat-icon>clear</mat-icon>No, the vehicle will not fit down my street
        </button>
    </div>
</div>
<!-- Question-1 (end) -->

<!-- Question-2 (start) -->
<div class="delivering-order" *ngIf="choice === DeliveryAccess.ACCESS_WITHIN_20M">
    <h2 class="delivering-order-title">Can the delivery vehicle get within 20m walking distance of your property?</h2>
    <div class="delivering-order-content">
        <p class="text-center">
            Our units are large and can be awkward for our drivers to carry (typically over 300kg of units). This means
            that our team must be able to park the delivery vehicle within 20m of your property.
        </p>
        <br />
        <p class="error-message big text-center">
            If your property is further than 20m we can offer curbside delivery. You will need to arrange to move the items from the curbside into their final location.
            Please answer the question honestly, otherwise we may need to refuse offloading of your delivery and then you could be charged a redelivery and holding fee.
        </p>
    </div>
    <div class="delivering-order-img">
        <div class="del-order-img-inner">
            <img src="https://static.diy-kitchens.com/assets/images/general/20m-access.png" alt="Distance from delivery vehicle to door under 20m."/>
        </div>
    </div>
    <div class="delivering-order-action">
        <button class="btn-success" mat-stroked-button (click)="answer(true)">
            <mat-icon>done</mat-icon>Yes, the walking distance is less than 20m
        </button>
        <button class="btn-danger" mat-stroked-button (click)="answer(false)">
            <mat-icon>clear</mat-icon>No, please deliver to the curbside
        </button>
    </div>
</div>
<!-- Question-2 (end) -->

<!-- Question-3 (start) -->
<div class="delivering-order" *ngIf="choice === DeliveryAccess.ACCESS_IS_SAFE">
    <h2 class="delivering-order-title">Can our delivery team access your property safely?</h2>
    <div class="delivering-order-content">
        <p class="text-center">
            Our units are large and can be awkward for our drivers to carry. This is why we have limits on the amount steps
            they can use and obstructions they can manoeuvre around. As a guide, below is a list of what is considered safe
            access into your property.
        </p>
        <br />
        <p class="error-message big text-center">
            Please answer the question honestly, otherwise we may need to refuse offloading of your delivery and then you could be charged a redelivery and holding fee.
        </p>
    </div>
    <div class="delivering-order-body-cnt">
        <div class="del-order-info-content-left">
            <h4>We can deliver safely if you can<br />answer yes to the following</h4>
            <ul class="del-order-info-list">
                <li>
                    <mat-icon>check_circle_outline</mat-icon>
                    <p> There are 6 straight steps or less into property</p>
                </li>
                <li>
                    <mat-icon>check_circle_outline</mat-icon>
                    <p>The route is free of obstructions, including overgrown foliage</p>
                </li>
                <li>
                    <mat-icon>check_circle_outline</mat-icon>
                    <p> The property is not high-rise or below ground level</p>
                </li>
                <li>
                    <mat-icon>check_circle_outline</mat-icon>
                    <p>There is even ground with no trip hazards</p>
                </li>
            </ul>
        </div>
        <div class="del-order-info-content-right">
            <div class="acc-steps-img">
                <img src="https://static.diy-kitchens.com/assets/images/general/checkout-access-steps.svg" alt="Up to six steps with no turns" />
            </div>
        </div>
    </div>
    <div class="delivering-order-action">
        <button class="btn-success" mat-stroked-button (click)="answer(true)">
            <mat-icon>done</mat-icon>Yes, the delivery team will have safe access
        </button>
        <button class="btn-danger" mat-stroked-button (click)="answer(false)">
            <mat-icon>clear</mat-icon>No, please deliver to the curbside
        </button>
    </div>
</div>
<!-- Question-3 (end) -->