<div class="loader" *ngIf="loading">
    <mat-spinner></mat-spinner>

    <div class="loader-message">Loading Information...</div>
</div>

<form [formGroup]="detailsFormGroup">
    <div class="customer-details">
        <div class="customer-detail">
            <h4>Details</h4>

            <div class="blank-space"></div>

            <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput placeholder="First Name" formControlName="firstName" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput placeholder="Last Name" formControlName="lastName" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Phone Number</mat-label>
                <input matInput placeholder="Phone Number" formControlName="landline" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Mobile Number</mat-label>
                <input matInput placeholder="Mobile Number" formControlName="tel" />
            </mat-form-field>
        </div>
        <div class="customer-address">
            <h4>Billing Details</h4>

            <div class="blank-space"></div>

            <mat-form-field appearance="outline">
                <mat-label>Line 1</mat-label>
                <input matInput placeholder="Line 1" name="address-line1" formControlName="billingLine1" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Line 2</mat-label>
                <input matInput placeholder="Line 2" name="address-line2" formControlName="billingLine2" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Town</mat-label>
                <input matInput placeholder="Town" formControlName="billingTown" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>County</mat-label>
                <input matInput placeholder="County" formControlName="billingCounty" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Postcode</mat-label>
                <input matInput placeholder="Postcode" formControlName="billingPostcode" />
            </mat-form-field>
        </div>
        <div class="customer-address">
            <h4>Delivery Details</h4>

            <div class="blank-space">
                <mat-slide-toggle formControlName="deliverToBilling">
                    Deliver to the billing address
                </mat-slide-toggle>
            </div>

            <ng-container *ngIf="!deliverToBilling">
                <!-- <mat-form-field appearance="outline">
                    <mat-label>Delivery Contact Name</mat-label>
                    <input matInput placeholder="Contact Name" name="delivery-contact-name" formControlName="deliveryContactName" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Delivery Contact Mobile</mat-label>
                    <input matInput placeholder="Contact Mobile" name="delivery-contact-number" formControlName="deliveryContactNumber" />
                </mat-form-field> -->
                <mat-form-field appearance="outline">
                    <mat-label>Line 1</mat-label>
                    <input matInput placeholder="Line 1" name="address-line1" formControlName="deliveryLine1" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Line 2</mat-label>
                    <input matInput placeholder="Line 2" name="address-line2" formControlName="deliveryLine2" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Town</mat-label>
                    <input matInput placeholder="Town" formControlName="deliveryTown" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>County</mat-label>
                    <input matInput placeholder="County" formControlName="deliveryCounty" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Postcode</mat-label>
                    <input matInput placeholder="Postcode" formControlName="deliveryPostcode" />
                </mat-form-field>
            </ng-container>
        </div>
    </div>

    <p class="error-message" *ngIf="error">An error occured while trying to save your details. Please try again or contact customer support.</p>

    <button mat-flat-button color="primary" class="button-spinner" [disabled]="updating || detailsFormGroup.invalid" (click)="saveAddress()">
        <mat-icon>arrow_forward</mat-icon>Continue to next step
        <mat-spinner *ngIf="updating" diameter="25" color="accent"></mat-spinner>
    </button>
</form>
