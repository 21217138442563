import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';

import { DirectionsDialogComponent } from './directions.dialog.component';

@NgModule({
    imports: [
        DesignModule
    ],
    declarations: [
        DirectionsDialogComponent
    ]
})
export class DirectionsDialogModule { }
