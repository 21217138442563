import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';
import { PipesModule } from '@app/pipes';

import { FooterComponent } from './footer.component';

@NgModule({
    imports: [
        DesignModule,
        PipesModule
    ],
    exports: [
        FooterComponent
    ],
    declarations: [
        FooterComponent
    ]
})
export class FooterComponentModule { }
