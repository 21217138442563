import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { KitchensPage } from './kitchens.page';
import { KitchensListingsPage } from './range-listing/range-listing.page';
import { RangeDetailPage } from './range-detail/range-detail.page';

import { PageNotFoundComponent } from '@app/shared/page-not-found.component';
import { ProductCategoryGuard } from '@app/utilities/guards/product-category.guard';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: KitchensPage,
                pathMatch: 'full',
                data: { vatToggle: true }
            },
            {
                path: ':style/details',
                component: RangeDetailPage,
                pathMatch: 'full'
            },
            {
                path: ':rangeType',
                component: KitchensListingsPage,
                pathMatch: 'full',
                canActivate: [ProductCategoryGuard],
                data: { vatToggle: true, reuse: true }
            },
            {
                path: ':rangeType/:colour',
                component: KitchensListingsPage,
                data: { vatToggle: true, reuse: true }
            },
            {
                path: ':rangeType/:colour/:finish',
                component: KitchensListingsPage,
                data: { vatToggle: true, reuse: true }
            },
            {
                path: ':rangeType/:colour/:finish/:material',
                component: KitchensListingsPage,
                data: { vatToggle: true, reuse: true }
            },
            {
                path: ':rangeType/:colour/:finish/:material/:layout',
                component: KitchensListingsPage,
                data: { vatToggle: true, reuse: true }
            },
            {
                path: ':rangeType/:colour/:finish/:material/:layout/:sortBy',
                component: KitchensListingsPage,
                data: { vatToggle: true, reuse: true }
            },
            { path: '**', component: PageNotFoundComponent }
        ])
    ],
    exports: [RouterModule]
})
export class KitchensRouting { }
