import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';

import { MediaPlayerDialogComponent } from './media-player.dialog.component';

@NgModule({
    imports: [
        DesignModule
    ],
    declarations: [
        MediaPlayerDialogComponent
    ]
})
export class MediaPlayerDialogModule { }
