import { Component, ViewEncapsulation, Signal } from '@angular/core';
import { Subscription } from 'rxjs';

import { StringHelper } from '@app/utilities/helpers';
import { SignalHelper } from '@app/utilities/helpers';

import { CatalogueService } from '@app/services/catalogue';
import { ProductType } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';

@Component({
    selector: 'page-accessory-listing',
    templateUrl: './accessory-listing.page.html',
    styleUrls: ['./accessory-listing.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AccessoryListingPage {
    public type = ProductType.ACCESSORIES;
    public route = '/accessories/accessory/';

    public category: string; // Category to display ie (Base units or Dishwashers)
    public categoryDisplay = SignalHelper.signal<string>(undefined); // Category to display ie (Base units or Dishwashers)
    private subCategory: string;
    private subCategoryDisplay = SignalHelper.signal<string>(undefined);
    public sectionCats: any[] = []; // A list of all categories for this type (ie different unit types or appliance types)
    public headerCssClass: string;

    public products: any; // All products of this type (ie All Units or Appliances)
    public items: any; // Products in this category - gets sent to the filters component
    public filteredItems = SignalHelper.signal<any[]>([]); // Filtered products to show as results
    public noResults: boolean;

    public filterFields: any = [
        {
            title: 'Brand',
            field: 'brand'
        },
        {
            title: 'Sub Category',
            field: 'sub_cat'
        }
    ];

    private routeHistory: RouteHistory[] = [
        { title: 'Accessories', route: '/accessories' }
    ];

    private routeSignal: Signal<NavigationRoute>;

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { 
        this.routeSignal = SignalHelper.toSignalWithEffect<NavigationRoute>(this.navigationService.route, (route) => {
            this.init(route|| null);
        }, true);
    }

    private init(route: NavigationRoute) {
        this.setDisplayStrings(route);
        this.updateNavigationHistory();

        if (this.subCategory) {
            let filterField = this.filterFields.find((filter) => filter.field === 'sub_cat');
            if (filterField) {
                filterField.preselected = this.subCategory
            }
        }

        this.catalogueService.getAccessories()
            .then((products: any) => {
                this.products = products;
                this.initProducts();
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    private updateNavigationHistory(): void {
        let routeHistory = this.routeHistory.slice();
        routeHistory.push({
            title: this.categoryDisplay(),
            route: `/accessories/${this.category}`
        });

        if (this.subCategory) {
            routeHistory.push({
                title: this.subCategoryDisplay(),
                route: `/accessories/${this.category}/${this.subCategory}`
            });
        }
        
        const metaDescription = `See our range of ${this?.categoryDisplay()?.toLowerCase()} accessories.`
        this.navigationService.setNavigation({
            title: `${this.categoryDisplay()}`,
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'description', content: metaDescription},
            ],
            routeHistory: routeHistory
        });
    }

    private setDisplayStrings(route: NavigationRoute): void {
        this.category = route.params.category || null;
        this.categoryDisplay.set(
            (this.category) ? StringHelper.titleCase(StringHelper.dashToSpace(this.category)) : null
        );

        this.subCategory = route.params.subCategory || null;
        this.subCategoryDisplay.set(
            (this.subCategory) ? StringHelper.titleCase(StringHelper.dashToSpace(this.subCategory)) : null
        );
        this.headerCssClass = `content-header-accessories-${this.category}`;      
    }

    public updateItems(filtered) {
        this.filteredItems.set(filtered);
        this.noResults = filtered.length === 0;
    }

    private initProducts() {
        if (this.products) {
            this.sectionCats = [];
            this.items = [];

            this.products.map((product) => {
                product._code = product.product_code;
                product._description = product.desc;
                product._details = product.detail;
                product._cost = this.cost(product);

                if (product._cost > 0) {
                    this.items.push({
                        item: product
                    });

                    const catExists = this.sectionCats.find((opt) => opt.name.toLowerCase() === product.cat.toLowerCase());

                    if (!catExists) {
                        this.sectionCats.push({
                            name: product.cat,
                            count: 1
                        });
                    } else {
                        catExists.count++;
                    }
                }
            });
            this.items = this.items.filter(entry => entry.item.cat.toLowerCase().replace(/ /g, '-') === this.category.toLowerCase());

        }
    }

    private cost(item) {
        return item.price || 0;
    }
}
