import { SolidSurfaceConfigCornerRadius, SolidSurfaceConfigLayout, SolidSurfaceConfigTabs } from './';

export interface ISolidSurfaceConfig {
    tab: ISolidSurfaceConfigTabs;
    maxTab: ISolidSurfaceConfigTabs;
    layout: SolidSurfaceConfigLayout;
    addIsland: boolean;
    addExtra: boolean;
    pieces: {
        a: ISolidSurfaceConfigPieceA;
        b: ISolidSurfaceConfigPieceB;
        c: ISolidSurfaceConfigPieceC;
        d: ISolidSurfaceConfigPieceD;
        e: ISolidSurfaceConfigPieceE;
        i: ISolidSurfaceConfigPieceI;
    },
    upstandHeight: number;
    prices: any;
    profileOption?: any;
}

type ISolidSurfaceConfigTabs = SolidSurfaceConfigTabs;

export type ISolidSurfaceConfigPieces = ISolidSurfaceConfigPieceA |
    ISolidSurfaceConfigPieceB |
    ISolidSurfaceConfigPieceC |
    ISolidSurfaceConfigPieceD |
    ISolidSurfaceConfigPieceE |
    ISolidSurfaceConfigPieceI;

export interface ISolidSurfaceConfigPiece {
    length: number;
    depth: number;
    isValidLength: boolean;
    isValidDepth: boolean;
    splashback: ISolidSurfaceConfigItem;
    cill: ISolidSurfaceConfigItem;
    cutouts: any[];
    gaps: any[];
}

export interface ISolidSurfaceConfigItem {
    length: number;
    height: number;
}

export interface ISolidSurfaceConfigShaping {
    length?: number;
    height?: number;
    type?: SolidSurfaceConfigCornerRadius;
}

export interface ISolidSurfaceConfigShapings {
    nw?: boolean | SolidSurfaceConfigCornerRadius;
    n?: boolean | SolidSurfaceConfigCornerRadius;
    ne?: boolean | SolidSurfaceConfigCornerRadius;
    e?: boolean | SolidSurfaceConfigCornerRadius;
    se?: boolean | SolidSurfaceConfigCornerRadius;
    s?: boolean | SolidSurfaceConfigCornerRadius;
    sw?: boolean | SolidSurfaceConfigCornerRadius;
    w?: boolean | SolidSurfaceConfigCornerRadius;
}

export enum SolidSurfaceConfigShapings {
    PROFILES = 'profiles',
    UPSTANDS = 'upstands',
    DOWNTURNS = 'downturns',
    CORNERS = 'corners'
}

export enum SolidSurfaceConfigPieceEdges {
    NW = 'nw',
    N = 'n',
    NE = 'ne',
    E = 'e',
    SE = 'se',
    S = 's',
    SW = 'sw',
    W = 'w'
}

export type ISolidSurfaceConfigPieceEdges = SolidSurfaceConfigPieceEdges |
    SolidSurfaceConfigPieceAEdges |
    SolidSurfaceConfigPieceBEdges |
    SolidSurfaceConfigPieceCEdges |
    SolidSurfaceConfigPieceDEdges |
    SolidSurfaceConfigPieceEEdges |
    SolidSurfaceConfigPieceIEdges;

export enum SolidSurfaceConfigPieceProfiles {
    N = 'n',
    E = 'e',
    S = 's',
    W = 'w'
}

export type ISolidSurfaceConfigPieceProfiles = SolidSurfaceConfigPieceProfiles |
    SolidSurfaceConfigPieceAProfiles |
    SolidSurfaceConfigPieceBProfiles |
    SolidSurfaceConfigPieceCProfiles |
    SolidSurfaceConfigPieceDProfiles |
    SolidSurfaceConfigPieceEProfiles |
    SolidSurfaceConfigPieceIProfiles;

export enum SolidSurfaceConfigPieceCorners {
    NW = 'nw',
    NE = 'ne',
    SE = 'se',
    SW = 'sw',
}

export type ISolidSurfaceConfigPieceCorners = SolidSurfaceConfigPieceCorners |
    SolidSurfaceConfigPieceACorner |
    SolidSurfaceConfigPieceBCorner |
    SolidSurfaceConfigPieceCCorner |
    SolidSurfaceConfigPieceDCorner |
    SolidSurfaceConfigPieceECorner |
    SolidSurfaceConfigPieceICorner;

// Piece A
export interface ISolidSurfaceConfigPieceA extends ISolidSurfaceConfigPiece {
    edges: ISolidSurfaceConfigPieceAEdges;
    profiles: ISolidSurfaceConfigPieceAProfiles;
    upstands: ISolidSurfaceConfigPieceAProfiles;
    downturns: ISolidSurfaceConfigPieceAProfiles;
    corners: ISolidSurfaceConfigPieceACorner;
}

interface ISolidSurfaceConfigPieceAEdges {
    e: boolean;
}

export enum SolidSurfaceConfigPieceAEdges {
    E = 'e'
}

interface ISolidSurfaceConfigPieceAProfiles {
    n: boolean;
    s: boolean;
    w: boolean;
}

export enum SolidSurfaceConfigPieceAProfiles {
    N = 'n',
    S = 's',
    W = 'w'
}

interface ISolidSurfaceConfigPieceACorner {
    ne: SolidSurfaceConfigCornerRadius;
    nw: SolidSurfaceConfigCornerRadius;
    se: SolidSurfaceConfigCornerRadius;
    sw: SolidSurfaceConfigCornerRadius;
}

export enum SolidSurfaceConfigPieceACorner {
    NE = 'ne',
    NW = 'nw',
    SE = 'se',
    SW = 'sw'
}

// Piece B
export interface ISolidSurfaceConfigPieceB extends ISolidSurfaceConfigPiece {
    edges: ISolidSurfaceConfigPieceBEdges;
    profiles: ISolidSurfaceConfigPieceBProfiles;
    upstands: ISolidSurfaceConfigPieceBProfiles;
    downturns: ISolidSurfaceConfigPieceBProfiles;
    corners: ISolidSurfaceConfigPieceBCorner;
}

interface ISolidSurfaceConfigPieceBEdges {
    s: boolean;
}

export enum SolidSurfaceConfigPieceBEdges {
    S = 's'
}

interface ISolidSurfaceConfigPieceBProfiles {
    n: boolean;
    e?: boolean;
}

export enum SolidSurfaceConfigPieceBProfiles {
    N = 'n',
    E = 'e'
}

interface ISolidSurfaceConfigPieceBCorner {
    ne: SolidSurfaceConfigCornerRadius;
    se: SolidSurfaceConfigCornerRadius;
    sw: SolidSurfaceConfigCornerRadius;
}

export enum SolidSurfaceConfigPieceBCorner {
    NE = 'ne',
    SE = 'se',
    SW = 'sw'
}

// Piece C
export interface ISolidSurfaceConfigPieceC extends ISolidSurfaceConfigPiece {
    edges: ISolidSurfaceConfigPieceCEdges;
    profiles: ISolidSurfaceConfigPieceCProfiles;
    upstands: ISolidSurfaceConfigPieceCProfiles;
    downturns: ISolidSurfaceConfigPieceCProfiles;
    corners: ISolidSurfaceConfigPieceCCorner;
}

interface ISolidSurfaceConfigPieceCEdges {
    w: boolean;
}

export enum SolidSurfaceConfigPieceCEdges {
    W = 'w'
}

interface ISolidSurfaceConfigPieceCProfiles {
    n: boolean;
    e: boolean;
    s?: boolean;
}

export enum SolidSurfaceConfigPieceCProfiles {
    N = 'n',
    E = 'e',
    S = 's'
}

interface ISolidSurfaceConfigPieceCCorner {
    ne: SolidSurfaceConfigCornerRadius;
    nw: SolidSurfaceConfigCornerRadius;
    se: SolidSurfaceConfigCornerRadius;
    sw: SolidSurfaceConfigCornerRadius;
}

export enum SolidSurfaceConfigPieceCCorner {
    NE = 'ne',
    NW = 'nw',
    SE = 'se',
    SW = 'sw'
}

// Piece D
export interface ISolidSurfaceConfigPieceD extends ISolidSurfaceConfigPiece {
    edges: ISolidSurfaceConfigPieceDEdges;
    profiles: ISolidSurfaceConfigPieceDProfiles;
    upstands: ISolidSurfaceConfigPieceDProfiles;
    downturns: ISolidSurfaceConfigPieceDProfiles;
    corners: ISolidSurfaceConfigPieceDCorner;
}

interface ISolidSurfaceConfigPieceDEdges {
    n: boolean;
}

export enum SolidSurfaceConfigPieceDEdges {
    N = 'n'
}

interface ISolidSurfaceConfigPieceDProfiles {
    e: boolean;
    s: boolean;
    w: boolean;
}

export enum SolidSurfaceConfigPieceDProfiles {
    E = 'e',
    S = 's',
    W = 'w'
}

interface ISolidSurfaceConfigPieceDCorner {
    nw: SolidSurfaceConfigCornerRadius;
    se: SolidSurfaceConfigCornerRadius;
    sw: SolidSurfaceConfigCornerRadius;
}

export enum SolidSurfaceConfigPieceDCorner {
    NW = 'nw',
    SE = 'se',
    SW = 'sw'
}

// Piece E
export interface ISolidSurfaceConfigPieceE extends ISolidSurfaceConfigPiece {
    edges: ISolidSurfaceConfigPieceEEdges;
    profiles: ISolidSurfaceConfigPieceEProfiles;
    upstands: ISolidSurfaceConfigPieceEProfiles;
    downturns: ISolidSurfaceConfigPieceEProfiles;
    corners: ISolidSurfaceConfigPieceECorner;
}

interface ISolidSurfaceConfigPieceEEdges {
    n: boolean;
}

export enum SolidSurfaceConfigPieceEEdges {
    N = 'n'
}

interface ISolidSurfaceConfigPieceEProfiles {
    e: boolean;
    s: boolean;
    w: boolean;
}

export enum SolidSurfaceConfigPieceEProfiles {
    E = 'e',
    S = 's',
    W = 'w'
}

interface ISolidSurfaceConfigPieceECorner {
    ne: SolidSurfaceConfigCornerRadius;
    nw: SolidSurfaceConfigCornerRadius;
    se: SolidSurfaceConfigCornerRadius;
    sw: SolidSurfaceConfigCornerRadius;
}

export enum SolidSurfaceConfigPieceECorner {
    NE = 'ne',
    NW = 'nw',
    SE = 'se',
    SW = 'sw'
}

// Piece I
export interface ISolidSurfaceConfigPieceI extends ISolidSurfaceConfigPiece {
    edges: ISolidSurfaceConfigPieceIEdges;
    profiles: ISolidSurfaceConfigPieceIProfiles;
    upstands: ISolidSurfaceConfigPieceIProfiles;
    downturns: ISolidSurfaceConfigPieceIProfiles;
    corners: ISolidSurfaceConfigPieceICorner;
}

interface ISolidSurfaceConfigPieceIEdges {
    n: boolean;
    e: boolean;
    s: boolean;
    w: boolean;
}

export enum SolidSurfaceConfigPieceIEdges {
    N = 'n',
    E = 'e',
    S = 's',
    W = 'w'
}

interface ISolidSurfaceConfigPieceIProfiles {
    e: boolean;
    s: boolean;
    w: boolean;
}

export enum SolidSurfaceConfigPieceIProfiles {
    E = 'e',
    S = 's',
    W = 'w'
}

interface ISolidSurfaceConfigPieceICorner {
    ne: SolidSurfaceConfigCornerRadius;
    nw: SolidSurfaceConfigCornerRadius;
    se: SolidSurfaceConfigCornerRadius;
    sw: SolidSurfaceConfigCornerRadius;
}

export enum SolidSurfaceConfigPieceICorner {
    NE = 'ne',
    NW = 'nw',
    SE = 'se',
    SW = 'sw'
}