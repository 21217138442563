import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService } from '@app/services/auth';
import { BasketService } from '@app/services/basket';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute } from '@app/services/navigation/models';

@Component({
    selector: 'page-basket-recovery',
    templateUrl: './basket-recovery.page.html',
    styleUrls: ['./basket-recovery.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class BasketRecoveryPage implements OnInit, OnDestroy {
    public shopperId: string;
    public recovering: boolean = false;

    private routeSubscription: Subscription;

    constructor(
        private authService: AuthService,
        private basketService: BasketService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                if (route && route.query && route.query.shopperid) {
                    let shopperId = route.query.shopperid;
                    if (shopperId.length > 19) {
                        shopperId = shopperId.replace(/[^A-Za-z0-9]/g, '');

                        this.shopperId = shopperId;
                    }
                }
            }
        );

        this.navigationService.setNavigation({
            title: 'Basket Recovery',
            metaTags: [{ name: 'no-meta', content: 'no metadata' }],
            routeHistory: [
                { title: 'Support', route: '/support' },
                { title: 'Basket Recovery', route: '/support/basket-recovery' }
            ]
        });
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }

    public recover() {
        if (!this.recovering) {
            this.recovering = true;
            this.authService.loginPrompt()
                .then(() => {
                    if (this.shopperId && this.shopperId.length >= 19) {
                        this.shopperId = this.shopperId.replace(/[^A-Za-z0-9]/g, '');

                        this.basketService.recover(this.shopperId)
                            .then((basketUuid) => {
                                if (basketUuid) {
                                    this.basketService.switch(basketUuid)
                                        .then(() => {
                                            this.basketService.validateBasket()
                                                .then(() => {
                                                    this.navigationService.navigate(['/basket']);
                                                    this.recovering = false;
                                                })
                                                .catch((error) => {
                                                    this.recovering = false;
                                                    this.dialogService.error(this.constructor.name, error);
                                                });
                                        })
                                        .catch((error) => {
                                            this.recovering = false;
                                            this.dialogService.error(this.constructor.name, error);
                                        });
                                } else {
                                    this.recovering = false;
                                    this.dialogService.error(this.constructor.name, 'Basket Not Found');
                                }
                            })
                            .catch((error) => {
                                this.recovering = false;
                                this.dialogService.error(this.constructor.name, error);
                            });
                    }
                })
                .catch((error) => {
                    this.recovering = false;
                    this.dialogService.error(this.constructor.name, error);
                });
        }
    }
}
