<header class="content-contained">
    <h1>Our Credit Rating</h1>
    <p>We have kept our prices as stable as possible throughout the recession years. In a recent price comparison experiment we have come out cheaper than the shed such as Wickes, B&Q etc..., but with a higher specification, fully built rigid colour co-ordinated to suit the door kitchen units.</p>
</header>

<hr class="narrow" />

<component-sticky-tabs [tabs]="tabs" type="scroller"></component-sticky-tabs>

<section class="content-contained" [id]="StickyTabs.CONFIDENCE">
    <h3 class="playfair">Buy online from Diy-Kitchens.com with confidence!</h3>
    <p>As you may have read in the news, Moben/Kitchens direct have gone into administration. This has left approx 450 customers who paid by cash deposit out of pocket.</p>
    <p>This comes on top of other high profile companies like MFI doing the same. In the recent 'recession' years we can understand customers concerns/wariness of who they are ordering a kitchen with.</p>
    <p>Throughout this period of recession www.diy-kitchens.com have enjoyed record sales in our 27 year history. Below is our current Credit rating information through online credit check company <a href="https://www.creditsafe.com" target="_blank" title="Creditsafe"><strong>www.creditsafe.com</strong></a>, Company no. - 1918871.</p>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.RATING">
    <h3 class="playfair">Credit Rating & Limit - Information</h3>
    <h4>The Credit Rating for DIY Kitchens (1-100) is: <span class="rating">82</span></h4>
    <p>Rating - Key Financials Filed / Established Rating - Newly Incorporated</p>

    <table cellspacing="2" cellpadding="2" class="table-striped">
        <thead>
            <tr>
                <th width="12%">RATING</th>
                <th width="32%">DESCRIPTION</th>
                <th>RISK</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>71-100</td>
                <td>Very Good Credit Worthiness </td>
                <td>LOW RISK</td>
            </tr>
            <tr>
                <td>51-70</td>
                <td>Good Credit Worthiness </td>
                <td>LOW RISK</td>
            </tr>
            <tr>
                <td>30-50</td>
                <td>Credit Worthy </td>
                <td>MODERATE RISK</td>
            </tr>
            <tr>
                <td>21-29</td>
                <td>Credit Against Collateral </td>
                <td>CAUTION - HIGH RISK</td>
            </tr>
            <tr>
                <td>1-20</td>
                <td>Caution - Credit at your discretion </td>
                <td>CAUTION - HIGH RISK</td>
            </tr>
        </tbody>
    </table>

    <p>Online ordering has allowed us to sell a <a routerLink="/specification/unit-specifications" title="Unit Specifications"><strong>very high specification product</strong></a> direct to the general public at trade prices  that we have supplied to top end kitchen showrooms with for the past 27 years.</p>
    <p>We have kept our prices as stable as possible throughout the 'recession' years. In a recent price comparison experiment we have come out cheaper than the 'sheds' such as Wickes, B&amp;Q etc..., but with a higher specification, fully built 'rigid' colour co-ordinated to suit the door kitchen units.</p>
    <p>We feel the product is excellent value for money and with a good customer service and quick delivery. www.diy-kitchens.com are a safe and  excellent value for money option when selecting the right company to purchase your kitchen from.</p>
    <p><a routerLink="/customer-reviews" title="Customer Reviews"><strong>Customer Feedback</strong></a> has played a massive part in our recent success and we have used a third party company Trustpilot to collate feedback for us. All of our customers were given a link to leave feedback and all feedback is reported (Good or Bad)</p>
    <p>Follow the link to see our reviews: <a href="https://www.trustpilot.co.uk/review/www.diy-kitchens.com" target="_blank" title="Trustpiolot"><strong>https://www.trustpilot.co.uk/review/www.diy-kitchens.com</strong></a></p>
    <p>Any negative feedback is taken very seriously and we try and turn them into a positive by improving in those areas as a company.</p>
    <p>Finally orders placed via credit card are fully insured, the customers that have lost there money with Moben all paid cash or cheque, the same happened when MFI went into administration. All customers that paid via credit card got there money back from the credit card company.</p>
    <p>The information below has been taken from online advice website www.uk.creditcards.com.</p>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.SAFEST">
    <h3 class="playfair">Credit cards hailed as safest way to buy goods</h3>
    <p>By UK CreditCards.com</p>
    <p>Legal protections mean consumers should have few concerns about using their credit cards abroad, announced payments association Apacs.</p>
    <p>New analysis from Apacs showed that credit cards provide "probably one of the safest ways" to buy goods. It also stated that cardholders benefitted from an "extra layer of protection" from Section 75 of the Consumer Credit Act.</p>
    <p>This legislation states that providers are obliged to reimburse customers who suffer from a breach of contract while buying goods and services with their credit card. Breakages, design faults and failure to deliver are all counted in this protection, which applies to goods worth between £100 and £30,000.</p>
</section>

<component-latest-offers></component-latest-offers>
