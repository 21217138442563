@import '/src/theme/variables';

component-kitchen-layouts-dialog {
    .kitchen-layout-dialog-content {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 50px);
        max-width: 1140px;

        .kitchen-layout-header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid color('copy-border');

            h5 {
                margin: 0;
                font-size: 0.9rem;
            }

            .close {
                cursor: pointer;

                .mat-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.05rem;
                    opacity: 0.8;
                    fill: #000;
                    text-shadow: 0 1px 0 #fff;
                }
            }
        }

        .kitchen-layout-content {
            display: flex;
            overflow: hidden;

            .content-left {
                flex: 0 0 calc(40% - 30px);
                padding: 15px;
                overflow-y: scroll;
                width: 100%;
                max-width: calc(40% - 30px);

                p {
                    color: #000;
                    font-size: 0.875rem;
                    font-family: font('bitter');
                    font-weight: 400;
                }

                ol {
                    padding-left: 24px;

                    li {
                        color: #000;
                        font-size: 0.75rem;
                        font-weight: 400;
                        line-height: 24px;

                        &::marker {
                            color: #000;
                            font-size: 11px;
                            font-weight: 700;
                        }
                    }
                }
            }

            .content-right {
                flex: 0 0 calc(60% - 15px);
                padding: 0 0 0 15px;
                width: 100%;
                max-width: calc(60% - 15px);

                img {
                    max-width: 100%;
                    height: calc(100% - 85px);
                    width: 100%;
                    object-fit: contain;
                    object-position: top center;
                }

                .price-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 20px 15px;
                    background: color('brand-warn');
                    color: color('white');

                    .layout-name {
                        padding-right: 30px;
                        color: color('white');
                        font-size: 14px;
                        font-weight: 400;
                    }

                    .layout-price {
                        color: color('white');
                        font-size: 24px;
                        font-weight: 700;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}