import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { StringHelper } from '@app/utilities/helpers';

import { BasketService } from '@app/services/basket';
import { CatalogueService } from '@app/services/catalogue';
import { ActiveRange, ProductType } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute } from '@app/services/navigation/models';

@Component({
    selector: 'page-doors-detail',
    templateUrl: './doors-detail.page.html',
    styleUrls: ['./doors-detail.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DoorsDetailPage implements OnInit, OnDestroy {
    public type: ProductType = ProductType.DOORS;

    public style: string;
    public rangeColour;
    public rangeDescription: string;
    public rangeImage: string;

    public products: any; // All products of this type (ie All Units or Appliances)
    public items: any; // Products in this category - gets sent to the filters component
    public noResults: boolean;

    private range;

    public activeRange: ActiveRange;

    private routeSubscription: Subscription;
    private activeRangeSubscription: Subscription;

    constructor(
        private basketService: BasketService,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                let style = route.params.style || null;
                this.style = style;

                this.navigationService.setNavigation({
                    title: `${StringHelper.titleCase(StringHelper.dashToSpace(this.style))} Kitchen Doors`,
                    metaTags: [
                        { name: 'no-meta', content: 'no metadata' }
                    ]
                });

                let routeHistory = [{ title: 'Kitchen Doors', route: '/kitchen-doors' }];
                this.navigationService.setRouteHistory(routeHistory);

                if (this.style) {
                    this.catalogueService.getCatalogue()
                        .then((catalogue) => {
                            if (catalogue && catalogue.rangeColours && catalogue.rangeColours[this.style]) {
                                this.rangeColour = catalogue.rangeColours[this.style];

                                routeHistory.push({
                                    title: `${this.rangeColour.rangeName} ${this.rangeColour.colour}`,
                                    route: '/kitchen-doors/' + this.style
                                });
                                this.navigationService.setRouteHistory(routeHistory);

                                this.catalogueService.getRangeDetails(this.rangeColour.rangeId)
                                    .then((rangeExtras) => {
                                        if (rangeExtras) {
                                            this.range = catalogue.ranges.find((range) => range.range_id === this.rangeColour.rangeId);
                                            if (this.range) {
                                                this.rangeDescription = this.range.range_detailed_description;
                                                const metaDescription = `The DIY Kitchens ${this.rangeColour.rangeName} ${this.rangeColour.colour} kitchen doors range.`
                                                this.navigationService.setMetaTags([
                                                    { property: 'og:image', content: `https://static.diy-kitchens.com/assets/images/seo/samples/samples/door-samples.jpg` },
                                                    { name: 'description', content: metaDescription},
                                                    { property: 'og:description', content: metaDescription}
                                                ]);
                                            }

                                            this.products = rangeExtras.products.filter((product) => {
                                                return product.colour === this.rangeColour.colour && !product.desc.match(/sample/i);
                                            });

                                            this.products.sort((a, b) => {
                                                const aProductCode = a.size_tag.replace('APPDOOR', '').match(/^(\d+)x(\d+)(\w+)?$/i);
                                                const bProductCode = b.size_tag.replace('APPDOOR', '').match(/^(\d+)x(\d+)(\w+)?$/i);

                                                let [aCode, aHeight, aWidth, aSuffix] = (aProductCode) ? aProductCode : [null];
                                                let [bCode, bHeight, bWidth, bSuffix] = (bProductCode) ? bProductCode : [null];

                                                if (aHeight) {
                                                    aHeight = parseInt(aHeight, 10);
                                                }

                                                if (aWidth) {
                                                    aWidth = parseInt(aWidth, 10);
                                                }

                                                if (bHeight) {
                                                    bHeight = parseInt(bHeight, 10);
                                                }

                                                if (bWidth) {
                                                    bWidth = parseInt(bWidth, 10);
                                                }

                                                if (aCode) {
                                                    if (!bCode) {
                                                        return -1;
                                                    } else if (aHeight < bHeight) {
                                                        return -1;
                                                    } else if (aHeight > bHeight) {
                                                        return 1;
                                                    } else if (aWidth < bWidth) {
                                                        return -1;
                                                    } else if (aWidth > bWidth) {
                                                        return 1;
                                                    }

                                                    return 0;
                                                } else {
                                                    if (a.size_tag < b.size_tag) {
                                                        return -1;
                                                    } else if (a.size_tag > b.size_tag) {
                                                        return 1;
                                                    }

                                                    return 0;
                                                }
                                            });

                                            this.initDoors();
                                        } else {
                                            this.noResults = true;
                                        }
                                    })
                                    .catch((error) => this.dialogService.error(this.constructor.name, error));

                            } else {
                                this.noResults = true;
                            }
                        })
                        .catch((error) => {
                            this.noResults = true;
                            this.dialogService.error(this.constructor.name, error);
                        });
                }
            }
        );

        this.activeRangeSubscription = this.catalogueService.activeRange$
            .subscribe((rangeInfo: ActiveRange) => {
                this.activeRange = rangeInfo;
            });
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }

        if (this.activeRangeSubscription) {
            this.activeRangeSubscription.unsubscribe();
            this.activeRangeSubscription = null;
        }
    }

    public cost(item, entry?) {
        return item.price + ((entry?._softClose) ? item.softCloseCost : 0);
    }

    public addToBasket(item: any) {
        let basketQueries = [];

        basketQueries.push(this.basketService.addItem(item, this.type));
    }

    public setRangeImage() {
        if (this.range && this.rangeColour) {
            const rangeId = (this.range.range_id === 'Luca') ? 'luca_matt' : this.range.range_id;
            const colourId = StringHelper.spaceToUnderscore(this.rangeColour.colour).replace(/_gloss/, '');

            this.rangeImage = `https://static.diy-kitchens.com/ranges/${rangeId}_${colourId}_900px.jpg`;
        } else {
            this.rangeImage = null;
        }
    }

    public selectWidth(entry, itemWidth) {
        entry.item = itemWidth.item;
    }

    private initDoors() {
        if (this.products) {
            this.setRangeImage();

            let items = [];

            this.products.map((product) => {
                product._cost = this.cost(product);
                product.qty = 1;
                if (product._cost > 0) {
                    let addItem = true;

                    if (product.code.match(/^\d+x\d+\w+?$/i)) {
                        const [productCode, height, width, suffix] = product.code.match(/^(\d+)x(\d+)(\w+)?$/i);

                        let existing = items.find((p) => {
                            if (p.item.code.match(/^\d+x\d+\w+?$/i)) {
                                const [pCode, pHeight, pWidth, pSuffix] = p.item.code.match(/^(\d+)x(\d+)(\w+)?$/i);
                                if (height === pHeight && suffix === pSuffix) {
                                    return true;
                                }
                            }

                            return false;
                        });

                        if (existing) {
                            if (existing.widths.length === 0) {
                                existing.widths.push({
                                    item: existing.item,
                                    value: parseInt(existing.item.width, 10)
                                });
                            }

                            existing.widths.push({
                                item: product,
                                value: parseInt(width, 10)
                            });

                            addItem = false;
                        }
                    }

                    if (addItem) {
                        items.push({
                            item: product,
                            widths: []
                        });
                    }
                }
            });

            items.forEach((item) => {
                if (item.widths.length) {
                    item.widths.sort((a, b) => {
                        if (a.value < b.value) {
                            return -1;
                        } else if (a.value > b.value) {
                            return 1;
                        }

                        return 0;
                    });
                }
            });

            this.items = items;
        }
    }
}
