@import '/src/theme/variables';

component-customer-lookup {
    .email-lookup {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;

        .mat-mdc-form-field {
            min-width: 360px;
        }

        .mat-icon {
            cursor: pointer;
        }
    }
}