import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';

import { Config } from '@app/config';

import { AuthCustomerService, AuthService, AuthStaffService } from '@app/services/auth';
import { UserType } from '@app/services/auth/models';

@Injectable()
export class AuthGuard {
    constructor(
        private config: Config,
        private authService: AuthService,
        private authCustomerService: AuthCustomerService,
        private authStaffService: AuthStaffService
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const url: string = state.url;
        const claims: string[] = (route.data && route.data.AuthClaims) ? route.data.AuthClaims : null;

        return this.checkLogin(url, claims);
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.canActivate(route, state);
    }

    public canLoad(route: Route): Promise<boolean> {
        const url = `/${route.path}`;

        return this.checkLogin(url);
    }

    public checkLogin(url: string, claims?: string[]): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.authService.ready.subscribe({
                next: (ready: boolean) => {
                    if (ready) {
                        if (this.config.auth.type === UserType.STAFF) {
                            if (this.authService.isAuthenticated) {
                                if (claims) {
                                    if (this.authStaffService.hasAccess(claims)) {
                                        resolve(true);
                                    } else {
                                        resolve(false);
                                    }
                                } else {
                                    resolve(true);
                                }
                            } else {
                                this.authService.loggedOut(url);
                                resolve(false);
                            }
                        }

                        if (this.config.auth.type === UserType.CUSTOMER) {
                            if (this.authService.isAuthenticated) {
                                resolve(true);
                            } else {
                                this.authService.loggedOut(url);
                                resolve(false);
                            }
                        }
                    }
                },
                error: (error) => {
                    this.authService.loggedOut(url);
                    resolve(false);
                }
            });
        });
    }
}
