import { Component, ViewEncapsulation, OnInit, OnDestroy, Inject, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';

import { StringHelper } from '@app/utilities/helpers';

import { CatalogueService } from '@app/services/catalogue';
import { ListingProduct, ProductType } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute } from '@app/services/navigation/models';

@Component({
    selector: 'page-handles-listing',
    templateUrl: './handles-listing.page.html',
    styleUrls: ['./handles-listing.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class HandlesListingPage implements OnInit, OnDestroy {
    public type: ProductType = ProductType.HANDLES;
    public route: string = '/handles/handle/';

    public category: string;
    public categoryDisplay: string;
    public sectionCats: any[] = [];
    public headerCssClass: string;

    public products: any; // All products of this type (ie All Units or Appliances)
    public items: ListingProduct[]; // Products in this category - gets sent to the filters component
    public filteredItems: any[] = []; // Filtered products to show as results
    public noResults: boolean;

    public filterFields = [{
        title: 'Brand',
        field: 'brand'
    }, {
        title: 'Finish',
        field: 'sub_cat'
    }, {
        title: 'Width',
        field: 'width',
        token: 'mm'
    }, {
        title: 'Height',
        field: 'height',
        token: 'mm'
    }, {
        title: 'Depth',
        field: 'depth',
        token: 'mm'
    }];

    private routeSubscription: Subscription;

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                this.category = route.params.category || null;
                this.categoryDisplay = (this.category) ? StringHelper.titleCase(StringHelper.dashToSpace(this.category)) : null;

                this.headerCssClass = `content-header-handles-${this.category}`;
                
                const metaCatagoryImages = {
                    "bar-handles": 'https://static.diy-kitchens.com/assets/images/products/handles/cimageurl/GCOB096BN.jpg?',
                    "bow-and-bridge-handles": 'https://static.diy-kitchens.com/assets/images/products/handles/cimageurl/IN-M-BHBN174.jpg',
                    "cup-and-pull-handles": 'https://static.diy-kitchens.com/assets/images/products/handles/cimageurl/IN-M-CHPC76.jpg',
                    knobs: 'https://static.diy-kitchens.com/assets/images/products/handles/cimageurl/IN-T-KHUO44.jpg',
                    "pull-out": 'https://static.diy-kitchens.com/assets/images/products/misc/cimageurl/IN-FOBS.jpg',
                    "quooker-boiling-water": 'https://static.diy-kitchens.com/assets/images/products/misc/cimageurl/3NRCHR.jpg',
                    "profile-handles": 'https://static.diy-kitchens.com/assets/images/products/handles/cimageurl/IN-M-CPBSG50_2.jpg?t=1742936915207',
                };
                const metaDescription = `See our range of ${this.categoryDisplay.toLowerCase()} available at trade prices.`;
                this.navigationService.setNavigation({
                    title: this.categoryDisplay,
                    metaTags: [
                        { name: 'no-meta', content: 'no metadata' },
                        { name: 'Description', content: `See our range of ${this.category} available at trade prices.` },
                        { property: 'og:locale', content: 'en_GB' },
                        { property: 'og:type', content: 'website' },
                        { property: 'og:site_name', content: 'DIY Kitchens' },
                        { property: 'og:url', content: 'Sinks & Taps - DIY Kitchens ' },
                        { property: 'og:title', content: 'Sinks & Taps - DIY Kitchens ' },
                        { property: 'og:description', content: `See our range of ${metaDescription} available at trade prices.` },
                        { property: 'og:image', content: metaCatagoryImages[this.category] || metaCatagoryImages['bar-handles'] },
                        { property: 'og:image:secure_url', content: metaCatagoryImages[this.category] || metaCatagoryImages['bar-handles'] },
                        { property: 'og:image:width', content: '600' },
                        { property: 'og:image:height', content: '400' }
                    ],
                    routeHistory: [
                        { title: 'Handles', route: '/handles' },
                        { title: this.categoryDisplay, route: `/handles/${this.category}` }
                    ]
                });

                this.catalogueService.getHandles()
                    .then((products: any) => {
                        this.products = products;
                        this.initProducts();
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            }
        );
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }

    public updateItems(filtered) {
        this.filteredItems = filtered;
        this.noResults = filtered.length === 0;
    }

    private initProducts() {
        if (this.products) {
            this.sectionCats = [];
            this.items = [];
            this.products.map((product) => {
                product._code = product.product_code;
                product._description = product.desc;
                product._details = product.info;
                product._finish = product.sub_cat || null;
                product._cost = this.cost(product);

                if (product._cost > 0) {
                    this.items.push({
                        item: product
                    });

                    let catExists = this.sectionCats.find((opt) => opt.name === product.cat);
                    if (!catExists) {
                        this.sectionCats.push({
                            name: product.cat,
                            count: 1
                        });
                    } else {
                        catExists.count++;
                    }
                }
            });

            this.items = this.items.filter((entry) => {
                return entry.item.cat && entry.item.cat.toLowerCase().replace(/ /g, '-') === this.category;
            });
        }
    }

    private cost(item) {
        return item.price || 0;
    }
}
