<section class="dark content-header-background handles-header-bg">
    <div class="contained">
        <h1>Handles</h1>
        <div class="sub-head">
            Make your kitchen unique and choose the handles that you feel will really complement your
            new kitchen units. From sleek & simple bar handles to traditional T handles, we'll have something that suits
            your requirements.
        </div>
    </div>
</section>
<component-product-category *ngIf="loaded" type="handles" [categories]="categories"></component-product-category>