import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { AboutService } from '@app/services/about';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { RouteHistory } from '@app/services/navigation/models';

@Component({
    selector: 'page-about-history',
    templateUrl: './history.page.html',
    styleUrls: ['./history.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AboutHistoryPage implements OnInit {
    public history = [];

    private routeHistory: RouteHistory[] = [
        { title: 'About Diy Kitchens', route: '/about-diy-kitchens' },
        { title: 'History', route: '/about-diy-kitchens/history' }
    ];

    constructor(
        private aboutService: AboutService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: `History`,
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'description', content: 'We are a family run business that has been manufacturing and supplying high specification kitchens to both trade and retail customers for nearly 30 years.' }
            ],
            routeHistory: this.routeHistory
        });

        this.aboutService.history()
            .then((history) => {
                this.history = history || [];
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
