{
  "app": {
    "id": "diykitchens",
    "name": "DIY Kitchens",
    "icon": "insert_photo"
  },
  "diy": {
    "ranges": 60,
    "carcases": 4
  },
  "api": {
    "cache": 1800000,
    "endpoints": {
      "cdn": "https://static.diy-kitchens.com",
      "universal": "https://universal-api.diy-kitchens.com",
      "articles": "https://articles.ultimafurniture.co.uk",
      "liveDiy": "https://api.diy-kitchens.com",
      "diy": "https://new-api.diy-kitchens.com",
      "admin": "https://staging-customer-admin-api.ultimafurniture.co.uk",
      "orders": "https://l9afvot6g2.execute-api.eu-west-1.amazonaws.com/dev",
      "catalogue": {
        "url": "https://static.diy-kitchens.com/cats/new/dev/diy_kitchens_nonrange_compressed.json",
        "inflate": true
      },
      "catalogueRange": {
        "url": "https://static.diy-kitchens.com/cats/new/dev/diy_range_{0}_compressed.json",
        "inflate": true
      },
      "productDetails": {
        "url": "https://static.diy-kitchens.com/cats/new/dev/diy_product-details_compressed.json",
        "inflate": true
      },
      "customerReviews": {
        "url": "https://static.diy-kitchens.com/cats/new/dev/diy-reviews_compressed.json",
        "inflate": true
      },
      "legacyImageCatalogue": {
        "url": "https://static.diy-kitchens.com/cats/new/prod/diy_kitchens_legacy_image_catalogue.json",
        "inflate": true
      },
      "searchTermCatalogue": {
        "url": "https://static.diy-kitchens.com/cats/new/prod/diy_kitchens_search_terms.json",
        "inflate": true
      },
      "payment": "https://1farbt89j5.execute-api.eu-west-1.amazonaws.com/dev",
      "reviews": "https://static.diy-kitchens.com/misc/reviews.json",
      "comparison": "https://static.diy-kitchens.com/misc/comparison.json",
      "support": "https://staging-support.diy-kitchens.com",
      "planner": "https://planner.diy-kitchens.com",
      "messagingUI": "https://messaging.diy-kitchens.com",
      "messaging": "https://api.diy-kitchens.com",
      "plannerUI": "https://planner.diy-kitchens.com",
      "universalUI": "https://universal.diy-kitchens.com"
    },
    "timeout": 30000
  },
  "auth": {
    "authorization": "Universal",
    "login": "/account",
    "logout": "/",
    "returnUrl": "/",
    "redirect": "foreground",
    "storage": "user",
    "type": "customer",
    "timeout": 3600000
  },
  "storage": {
    "name": "diy-kitchens",
    "storeName": "diy-kitchens-storage",
    "universal": "universal",
    "worktopConfig": "worktop-config"
  },
  "pipes": {
    "currency": {
      "unit": "£",
      "separator": ","
    }
  },
  "payments": {
    "vatRate": 1.2,
    "minPayment": 0.3,
    "minOrderValue": 750
  },
  "trusted": {
    "messaging": [
      "http://localhost:4200",
      "http://localhost:4400"
    ],
    "support": [
      "https://support.diy-kitchens.com",
      "https://staging-support.diy-kitchens.com"
    ]
  },
  "tracking": {
    "gtag": {
      "id": "G-SX0Y04ZCL1",
      "code": "UA-9765766-1"
    }
  }
}
