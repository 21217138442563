@import '/src/theme/variables';

component-customer-details {
    .customer-details {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        margin: 15px 0;

        .mat-mdc-form-field {
            margin-bottom: 15px;
            width: 100%;
        }

        .customer-address {
            display: flex;
            flex-direction: column;
            padding-left: 20px;
            margin-left: 20px;
            border-left: 1px solid color('copy-border');

            p {
                margin: 0;
            }

            .change-address {
                margin-bottom: 15px;
            }
        }
    }

    .blank-space {
        height: 40px;
    }

    .chunky {
        & + .chunky {
            margin-top: 15px;
        }
    }

    @media only screen and (max-width: 768px) {
        .customer-details {
            grid-template-columns: 1fr;
        }
    }
}