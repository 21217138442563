import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { PipesModule } from '@app/shared/pipes';

import { DrawerUpgradeDialogComponent } from './drawer-upgrade.dialog.component';

@NgModule({
    imports: [
        SharedModule,
        PipesModule
    ],
    declarations: [
        DrawerUpgradeDialogComponent
    ]
})
export class DrawerUpgradeDialogModule { }
