@import '/src/theme/variables';

component-breadcrumbs {
    z-index: 9;
    
    section {
        padding: 15px 0;
    }

    ._breadcrumbs {
        display: flex;
        overflow: hidden;
        margin-right: 115px;
        white-space: nowrap;

        @include responsive-breakpoint-down(xl) {
            margin-right: 0;
            flex-wrap: wrap;
        }

        ._breadcrumb {
            margin-bottom: 0;
            padding: 0;
            background: none;
            font-size: 0.75rem;
            font-weight: 600;
            line-height: 1.313rem;
            letter-spacing: 0.13rem;
            text-transform: uppercase;

            &:not(:last-child) {
                display: flex;
                align-items: center;
                font-weight: 300;

                &::after {
                    margin: 0 8px;
                    color: #000000;
                    content: '|';
                    opacity: 0.8;
                }

                a {
                    color: #444;
                    font-weight: 400;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }
            }

            a {
                padding: 0;
                color: #000;

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
    }

    @media only screen and (max-width: $mobile-width) {
        section {
            display: none;

            .contained {
                margin-left: 0 !important;
            }
        }
    }
}