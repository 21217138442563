import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { BasketService } from '@app/services/basket';
import { Basket, BasketItem } from '@app/services/basket/models';
import { CatalogueService } from '@app/services/catalogue';
import { ProductType } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute } from '@app/services/navigation/models';

@Component({
    selector: 'page-door-sample',
    templateUrl: './door-sample.page.html',
    styleUrls: ['./door-sample.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DoorSamplePage implements OnInit, OnDestroy {
    public header: string = '';

    public allProducts: any; // All products of this type (ie All Units or Appliances)
    public category: string; // Category to display ie (Base units or Dishwashers)
    public categoryDisplay: string;
    public sectionCats: any[] = []; // A list of all categories for this type (ie different unit types or appliance types)
    public items: any; // Products in this category - gets sent to the filters component
    public filteredItems: any[] = []; // Filtered products to show as results
    public noResults: boolean;
    public type: ProductType = ProductType.SAMPLE_DOORS;
    public samplePrice: number;
    private allowedFree: number = 0;
    public isFree: boolean = false;
    public itemRoute: string = '/kitchen-units/unit/';

    public filterFields = [];

    private routeSubscription: Subscription;

    private activeBasketSubscription: Subscription;

    constructor(
        private basketService: BasketService,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
    ) { }

    ngOnInit() {
        this.allowedFree = 3;

        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                const ucFirst = s => s[0] ? s[0].toUpperCase() + s.substr(1) : '';
                let category = route.params.category || '';
                if (category.match(/all/i)) {
                    category = '';
                    this.categoryDisplay = '';
                } else {
                    this.categoryDisplay = ucFirst(category.replace(/-/g, ' '));
                }
                this.category = category;

                this.navigationService.setNavigation({
                    title: 'Kitchen door samples',
                    metaTags: [
                        { name: 'no-meta', content: 'no metadata' },
                        { property: 'og:description', content: `See our range of door samples available to help you choose between the different finishes available.` },
                        { name: 'Description', content: `See our range of door samples available to help you choose between the different finishes available.` },
                    ]
                });

                this.navigationService.setRouteHistory([
                    { title: 'Samples', route: '/samples' },
                    { title: (this.categoryDisplay || 'Kitchen') + ' Door Samples', route: '/samples/door-samples' }
                ]);

                this.catalogueService.getSampleDoors()
                    .then((sampleDoors) => {
                        this.catalogueService.getRanges()
                            .then((ranges: any) => {
                                let totalDoors = 0;
                                ranges.forEach(range => totalDoors += range.colours
                                    .filter(col => !col.availability && !col.colour.match(/bespoke/i)).length);

                                this.sectionCats = [{
                                    name: 'All styles',
                                    count: sampleDoors.length,
                                    tick: !this.category
                                }];

                                this.allProducts = sampleDoors;
                                ranges.map(range => {
                                    range.colours = range.colours.filter(col => !col.availability && !col.colour.match(/bespoke/i));

                                    range.filters.map(style => {
                                        let catExists = this.sectionCats.filter((opt) => opt.name === style);
                                        if (catExists.length === 0) {
                                            if (!style.match(/bespoke/i)) {
                                                this.sectionCats.push({
                                                    name: style,
                                                    count: range.colours.length
                                                });
                                            }
                                        } else {
                                            catExists[0].count += range.colours.length;
                                        }
                                    });
                                });

                                if (this.category.length > 0) {
                                    this.items = this.allProducts.filter(
                                        (entry) => entry.filters.filter((style) => style.toLowerCase().replace(/ /g, '-') === this.category).length > 0);
                                } else {
                                    this.items = this.allProducts;
                                }
                            })
                            .catch((error) => this.dialogService.error(this.constructor.name, error));
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));

            });

        this.activeBasketSubscription = this.basketService.basket$.subscribe(
            (basket: Basket) => {
                if (basket) {
                    const numSampleDoorsInBasket = basket.items.filter((item: BasketItem) => item.group === ProductType.SAMPLE_DOORS).length;
                    this.isFree = (numSampleDoorsInBasket < this.allowedFree);
                }
            }
        );
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
        if (this.activeBasketSubscription) {
            this.activeBasketSubscription.unsubscribe();
            this.activeBasketSubscription = null;
        }
    }

    public itemCost(item) {
        return this.isFree ? 0 : item.cost;
    }

    public updateItems(filtered) {
        this.filteredItems = filtered;
        this.noResults = filtered.length === 0;
    }
}
