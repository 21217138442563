import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, throwError } from 'rxjs';

import { Config } from '@app/config';
import { HttpApiAuthorization, HttpApiHeaders, HttpApiOptions } from './models';

@Injectable()
export class HttpApi {
    public activeRequests$: BehaviorSubject<number> = new BehaviorSubject(0);
    private activeRequests = 0;
    private requestQueue = new Map<string, Observable<any>>();

    constructor(
        private httpClient: HttpClient,
        private config: Config
    ) { }

    /**
     * HEAD Request - Used to check file existences
     * @param {string} url
     * @param {HttpApiOptions} options
     * @returns {Observable<any>}
     */
    public head<T>(url: string, options?: HttpApiOptions): Observable<T> {
        return this.generateRequest<T>('HEAD', url, null, options);
    }

    /**
    * Get Request
    * @param {string} url
    * @param {HttpApiOptions} options
    * @returns {Observable<any>}
    */
    public get<T>(url: string, options?: HttpApiOptions): Observable<T> {
        return this.generateRequest<T>('GET', url, null, options);
    }

    /**
     * Post Request
     * @param {string} url
     * @param {Object} data
     * @param {HttpApiOptions} options
     * @returns {Observable<any>}
     */
    public post<T>(url: string, data: object, options?: HttpApiOptions): Observable<T> {
        return this.generateRequest<T>('POST', url, data, options);
    }

    /**
     * Put Request
     * @param {string} url
     * @param {Object} data
     * @param {HttpApiOptions} options
     * @returns {Observable<any>}
     */
    public put<T>(url: string, data: object, options?: HttpApiOptions): Observable<T> {
        return this.generateRequest<T>('PUT', url, data, options);
    }

    /**
     * Patch Request
     * @param {string} url
     * @param {Object} data
     * @param {HttpApiOptions} options
     * @returns {Observable<any>}
     */
    public patch<T>(url: string, data: object, options?: HttpApiOptions): Observable<T> {
        return this.generateRequest<T>('PATCH', url, data, options);
    }

    /**
     * Delete Request
     * @param {string} url
     * @param {HttpApiOptions} options
     * @returns {Observable<any>}
     */
    public del<T>(url: string, options?: HttpApiOptions): Observable<T> {
        return this.generateRequest<T>('DELETE', url, null, options);
    }

    /**
     * Generate Request
     * @param {string} method
     * @param {string} url
     * @param {Object} data
     * @param {HttpApiOptions} options
     * @returns {Observable<any>}
     */
    private generateRequest<T>(method: string, url: string, data?: object, options?: HttpApiOptions): Observable<T> {
        const key = `${method}:${url}:${JSON.stringify(data)}`;
        if (this.requestQueue.has(key)) {
            return this.requestQueue.get(key);
        }

        const params: any = {};

        if (data) {
            params.body = data;
        }

        if (options && (options.headers || options.authorization)) {
            params.headers = this.generateHeaders(options.headers, options.authorization);
        }

        if (options && options.responseType) {
            params.responseType = options.responseType;
        }

        const request: Subject<T> = new Subject<T>();

        this.activeRequestsAdd();

        this.requestQueue.set(key, request);

        this.httpClient.request<T>(method, url, params).subscribe(
            (response: any) => request.next(response),
            (error: any) => {
                request.error(this.handleError(error));
                this.activeRequestsRemove();
                this.requestQueue.delete(key);
            },
            () => {
                request.complete();
                this.activeRequestsRemove();
                this.requestQueue.delete(key);
            }
        );

        return request;
    }

    /**
     * Generate Headers
     * @param {HttpApiHeaders} headers
     * @param {HttpApiAuthorization} authorization
     * @returns {HttpHeaders}
     */
    private generateHeaders(headers?: HttpApiHeaders, authorization?: HttpApiAuthorization): HttpHeaders {
        headers = Object.assign({ 'Content-Type': 'application/json' }, headers || {});

        if (headers['Content-Type'] === null) {
            delete headers['Content-Type'];
        }

        const httpHheaders = this.generateAuthorizationHeaders(headers, authorization);

        return httpHheaders;
    }

    /**
     * Generate Authorization Headers
     * @param {HttpHeaders} headers
     * @param {HttpApiAuthorization} authorization
     * @returns {HttpHeaders}
     */
    private generateAuthorizationHeaders(headers?: HttpApiHeaders, authorization?: HttpApiAuthorization): HttpHeaders {
        if (!headers) {
            headers = {};
        }

        if (authorization) {
            const header: string = this.config.auth.authorization;
            const authorizationString: string = Object.keys(authorization).map((key) => {
                return `${key}=${authorization[key]}`;
            }).join(',');
            const authorizationHeader = `${header} ${btoa(authorizationString)}`;

            headers['Authorization'] = authorizationHeader;
        }

        return new HttpHeaders(headers);
    }

    /**
     * Active Requests Add
     */
    private activeRequestsAdd(): void {
        this.activeRequests++;

        this.activeRequests$.next(this.activeRequests);
    }

    /**
     * Active Requests Remove
     */
    private activeRequestsRemove(): void {
        if (this.activeRequests > 0) {
            this.activeRequests--;
        }

        this.activeRequests$.next(this.activeRequests);
    }

    /**
     * Handle Error
     * @param {any} response
     * @returns {Observable.throw}
     */
    private handleError(response: any): any {
        let errMsg = '';

        try {
            if (typeof response === 'string') {
                errMsg = response;
            } else {
                const error = (response && response.json) ? response.json() : {};
                errMsg = (error.explanation) ? error.explanation :
                    (error.message) ? error.message :
                        (error.error) ? error.error :
                            (response.status) ? `${response.status} - ${response.statusText}` : response.message || 'Server error';
            }
        } catch (error) {
            console.error('HTTP Api', 'Handle Error', error);
        }

        return throwError(errMsg);
    }
}
