import { Injectable } from '@angular/core';

import { Config } from '@app/config';

import { HttpApi } from '@app/services/api';
import { HttpApiOptions, HttpApiResponse } from '@app/services/api/models';

@Injectable()
export class AboutService {
    constructor(
        protected config: Config,
        protected httpApi: HttpApi,
    ) { }

    public history(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/about/company-history`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                }
            };

            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public comparison(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/about/comparison`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                }
            };

            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public unitSpecifications(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/about/specifications`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                }
            };

            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }
}
