import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

import { NavigationService } from '@app/services/navigation';
import { DialogService } from '@app/services/dialog';
import { MarketingService } from '@app/services/marketing';
import { RouteHistory } from '@app/services/navigation/models';
import { Promotion } from '@app/services/marketing/models/promotion.model';
import { PromotionType } from '@app/services/marketing/models';

@Component({
    selector: 'page-about-kitchen-sales',
    templateUrl: './kitchen-sales.page.html',
    styleUrls: ['./kitchen-sales.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AboutKitchenSalesPage implements OnInit {
    public brand: string;

    public promotions: Promotion[] = [];

    private routeHistory: RouteHistory[] = [
        { title: 'Kitchen Sales & Offers', route: '/kitchen-sales' }
    ];

    constructor(
        private navigationService: NavigationService,
        private viewportScroller: ViewportScroller,
        private marketingService: MarketingService,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: 'Kitchen Sales & Offers - Don\'t buy a kitchen until you\'ve seen our unbeatable prices!',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'description', content: 'Throughout the year we have some fantastic kitchen sales & offers available to our customers. Take a look below to see what sales & offers we have available for you at the moment.'},
                { property: 'og:description', content: 'See our range of Kitchen Sales' },
            ],
            routeHistory: this.routeHistory
        });

        this.marketingService.getActiveFullPromotions()
            .then((fullPromotions) => {
                if (Array.isArray(fullPromotions)) {
                    this.promotions = fullPromotions;
                    this.promotions.sort((a: Promotion, b: Promotion) => {
                        const promotionOrder = {
                            [PromotionType.BASIC]: 1,
                            [PromotionType.PRODUCT_DISCOUNT]: 2,
                            [PromotionType.BRAND_EXTERNAL]: 3,
                            [PromotionType.FREEITEM_BRAND]: 4,
                            [PromotionType.BRAND]: 5,
                            [PromotionType.FREEITEM]: 6,
                            [PromotionType.INFORMATION]: 7
                        };
                        const aValue = promotionOrder[a.type] || 100;
                        const bValue = promotionOrder[b.type] || 100;
                        if (aValue < bValue) {
                            return -1;
                        } else if (aValue > bValue) {
                            return 1;
                        }
                        return 0;
                    });
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public getPromotionId(promotion: Promotion): string {
        return this.marketingService.getPromotionUrlFragment(promotion);
    }

    public getSimpleDateString(date: string): string {
        const jsDate = new Date(date);

        return `${jsDate.getDate()}/${jsDate.getMonth() + 1}/${jsDate.getFullYear()}`;
    }

    public scrollToAnchor(elementId) {
        this.viewportScroller.scrollToAnchor(elementId);        
    }
}
