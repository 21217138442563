import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HandlesPage } from './handles.page';
import { HandlesListingPage } from './listing/handles-listing.page';
import { HandleDetailPage } from './detail/handles-detail.page';

import { PageNotFoundComponent } from '@app/shared/page-not-found.component';
import { ProductCategoryGuard } from '@app/utilities/guards/product-category.guard';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: HandlesPage,
                pathMatch: 'full'
            },
            {
                path: ':category',
                component: HandlesListingPage,
                canActivate: [ProductCategoryGuard],
                data: { vatToggle: true }
            },
            {
                path: ':category/:details',
                component: HandleDetailPage,
                canActivate: [ProductCategoryGuard],
                data: { vatToggle: true }
            },
            { path: '**', component: PageNotFoundComponent }
        ])
    ],
    exports: [RouterModule]
})
export class HandlesRouting { }
