import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AboutPrivacyPolicyPage } from './privacy-policy.page';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: AboutPrivacyPolicyPage
            },
            {
                path: '**',
                redirectTo: ''
            }
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class AboutPrivacyPolicyRouting { }
