@import '/src/theme/variables';

page-door-sample {

    .left {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .tip {
            width: 250px;
            padding: 12px 20px;
            background-color: #e2e3e5;
            color: #383d41;
            border-radius: 5px;
        }
    }

    #filters-component {
        padding: 0;

        section {
            &:first-of-type {
                display: none;
            }
        }

        .filters {
            margin-top: 15px;
        }
    }

    .banner {
        padding: 15px;
    }

    .products {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 15px;
        padding-top: 0;
        max-width: calc(100% - 280px);

        .door {
            width: 200px;
            display: flex;
            flex: 0 0 auto;
            flex-direction: column;
            margin: 15px 0 0 10px;
            box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
            background-color: color('white');
            padding: 15px;
            text-align: center;

            img {
                width: 140px;
                margin: 15px auto;
            }

            .title {
                margin-bottom: 5px;
                font-size: 16px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 0.03em;
            }

            .currency-format {
                .currency {
                    font-size: 1.6rem;
                    font-weight: 600;
                }
            }

            .actions {
                margin: 0 auto;

                button {
                    margin-top: 15px;
                }
            }
        }
    }

    .no-results {
        padding: 20px;
    }

    @media only screen and (max-width: 1000px) {
        .products {
            max-width: 70%;
        }
    }

    @media only screen and (max-width: 768px) {
        .products {
            margin-left: 0;
            max-width: 100%;
        }
        .tip {
            display: none;
        }
    }
}
