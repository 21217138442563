<div class="contain-content">
    <div class="contained">
        <section class="head">
            <header>
                <h3>
                    How can we help?
                </h3>
                <p>At DIY Kitchens we are dedicated to providing you with the best possible customer service. We have an experienced, friendly and enthusiastic team who understand that you will have questions about ordering your new kitchen. Do not hesitate to contact us with any queries as we are always happy to help.</p>
                <p>Online support is available 7 days a week from 8am to 6pm.</p>
                <ng-container *ngIf="showXmasShutdown">
                    <p class="danger"><strong>Please note</strong> that our production facilities are on annual shutdown from Wednesday 20 December 2023 until Wednesday 3 January 2024.</p>
                    <p class="danger">Therefore, any remedial or additional items reported to us after 11 December 2023, will not be delivered until w/c 8 January 2024.</p>
                    <p class="danger">We apologise in advance for any inconvenience this may cause.</p>
                </ng-container>
            </header>
            <div class="phones">
                <h3>Phone support</h3>
                <a href="tel:01977608418">01977 608418</a>
                <table>
                    <tbody>
                        <tr class="btop">
                            <td rowspan="2">Sales & Support</td>
                            <td>Mon-Sat</td>
                            <td>8am to 5.30pm</td>
                        </tr>
                        <tr>

                            <td>Sun</td>
                            <td>9am to 5pm</td>
                        </tr>
                        <tr class="btop">
                            <td rowspan="2">Accounts & Deliveries</td>
                            <td>Mon-Fri</td>
                            <td>9am to 5pm</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>

        <ng-container *ngIf="showXmasShutdown">
            <h3>Christmas opening times</h3>
            <section class="xmas-wrapper">
                <table class="xmas">
                    <tbody>
                        <tr>
                            <th>21<sup>ST</sup><br>DEC</th>
                            <th>22<sup>ND</sup><br>DEC</th>
                            <th>23<sup>RD</sup><br>DEC</th>
                            <th>24<sup>TH</sup><br>DEC</th>
                            <th>25<sup>TH</sup><br>DEC</th>
                            <th>26<sup>TH</sup><br>DEC</th>
                            <th>27<sup>TH</sup><br>DEC</th>
                            <th>28<sup>TH</sup><br>DEC</th>
                            <th>29<sup>TH</sup><br>DEC</th>
                            <th>30<sup>TH</sup><br>DEC</th>
                            <th>31<sup>ST</sup><br>DEC</th>
                            <th>1<sup>ST</sup><br>JAN</th>
                            <th>2<sup>ND</sup><br>JAN</th>
                        </tr>
                        <tr>
                            <td colspan="6" class="closed">PHONES &amp; ONLINE<br>SUPPORT CLOSED</td>
                            <td colspan="2">PHONES &amp; ONLINE<br>SUPPORT OPEN</td>
                            <td colspan="4" class="closed">PHONES &amp; ONLINE<br>SUPPORT CLOSED</td>
                            <td rowspan="2">OPEN AS<br>USUAL</td>
                        </tr>
                        <tr>
                            <td colspan="6" class="closed">SHOWROOM CLOSED</td>
                            <td colspan="4">SHOWROOM OPEN</td>
                            <td colspan="2" class="closed">SHOWROOM CLOSED</td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </ng-container>

        <section class="support-options">
            <p *ngIf="!isAuthenticated" class="bold">Please <a class="link-button" (click)="login()">sign in</a> in to access more options.</p>

            <a class="tile" routerLink="messaging">
                <mat-icon>question_answer</mat-icon>
                <span>
                    View your messages<br>
                    <em>VIEW MESSAGES AND EMAILS</em>
                </span>
            </a>
            <a class="tile" [class.disabled]="!isAuthenticated" (click)="openContact('planning')">
                <mat-icon>handyman</mat-icon>
                <span>
                    Planning<br>
                    <em>PLAN QUESTIONS</em>
                </span>
            </a>
            <a class="tile" [class.disabled]="!isAuthenticated" (click)="openContact('quote')">
                <mat-icon>design_services</mat-icon>
                <span>
                    Quote query<br>
                    <em>QUOTATION REQUESTS</em>
                </span>
            </a>
            <a class="tile" [class.disabled]="!isAuthenticated" (click)="openContact()">
                <mat-icon>settings_suggest</mat-icon>
                <span>
                    Technical / pre-sales query<br>
                    <em>GENERAL PRE-SALES QUESTIONS</em>
                </span>
            </a>
            <a class="tile" [class.disabled]="!isAuthenticated" (click)="reportIssue()">
                <mat-icon>summarize</mat-icon>
                <span>
                    Report an issue
                    <em>DAMAGED, INCORRECT OR MISSING ITEMS</em>
                </span>
            </a>
            <a class="tile" [class.disabled]="!isAuthenticated" (click)="openContact('aftersales')">
                <mat-icon>chat</mat-icon>
                <span>
                    After sales message<br>
                    <em>GENERAL AFTER SALES QUESTIONS</em>
                </span>
            </a>
            <a class="tile" [class.disabled]="!isAuthenticated" (click)="openContact('delivery')">
                <mat-icon>local_shipping</mat-icon>
                <span>
                    Delivery query<br>
                    <em>DELIVERY OPTION QUESTIONS</em>
                </span>
            </a>
            <a class="tile" [class.disabled]="!isAuthenticated" (click)="openBaskets()">
                <mat-icon>shopping_cart</mat-icon>
                <span>
                    View / edit your basket<br>
                    <em>BASKET DETAILS | AMENDMENTS</em>
                </span>
            </a>
            <a class="tile" [class.disabled]="!isAuthenticated" (click)="openOrders()">
                <mat-icon>edit</mat-icon>
                <span>
                    View / edit your order<br>
                    <em>ORDER DETAILS | INVOICE | AMENDMENTS</em>
                </span>
            </a>
            <a class="tile" [class.disabled]="!isAuthenticated" (click)="openOrders()">
                <mat-icon>assignment_return</mat-icon>
                <span>
                    Return an item<br>
                    <em>REQUEST A RETURNS NUMBER</em>
                </span>
            </a>

            <a class="tile" href="https://www.diy-kitchens.com/about-diy-kitchens/showroom/" target="_top">
                <mat-icon>local_activity</mat-icon>
                <span>
                    Visit our showroom<br>
                    <em>SHOWROOM TOUR | HOW TO FIND US</em>
                </span>
            </a>
            <a class="tile small" [class.disabled]="!isAuthenticated" (click)="openContact('feedback')">
                <mat-icon>reviews</mat-icon>
                <span>
                    Send us feedback<br>
                    <em>LET US KNOW HOW WE DID | <br /> SUBMIT YOUR FITTED KITCHEN PHOTOS</em>
                </span>
            </a>
        </section>
    </div>
</div>
