<header class="content-header-corner content-header-background">
    <header class="content-contained">
        <h1>WOOD WORKTOPS
        </h1>
        <p>Beautiful solid timbers help you create the perfect natural worktop, bringing genuine warmth and character to
            whatever kitchen style you create.
            <br />
            Whether you are buying a new worktop for your new kitchen or just replacing an old warn one, we have a vast
            range below that will suit any kitchen and fit perfectly with your kitchen units.
        </p>
    </header>
</header>
<section class="lightgrey">
    <div class="contained flex-space-between">
        <div class="products">
            <div class="doors-listing-top-banner">
                <div class="doors-listing-item">
                    <img src="https://static.diy-kitchens.com/assets/images/banner/listings-banner-trustpilot.jpg"
                        alt="See how we compare">
                </div>
                <div class="doors-listing-item">
                    <img src="https://static.diy-kitchens.com/assets/images/banner/offer-banner_real-customers_oct2020.jpg"
                        alt="View real customer kitchens">
                </div>
            </div>

            <div class="categories">
                <div class="item-container" *ngFor="let entry of filteredItems">
                    <div class="item">
                        <div class="image">
                            <img src="{{entry.item.media.image}}" [alt]="entry.item.desc"/>
                        </div>
                        <div class="details">
                            <div class="price">
                                <p>
                                    <span class="common-price-span">from </span>
                                    <span class="common-price-span" [innerHTML]="entry.item.price | currencyFormat:true:true"></span>
                                </p>
                            </div>

                            <span class="common-item-title">{{ entry.item.desc }}</span>

                            <div class="product-code">{{ entry.item.product_code }}</div>

                            <div class="actions">
                                <component-basket-add-button [type]="type" [item]="entry.item" [allowFree]="true"
                                    [overridePrice]="entry.item.price">
                                </component-basket-add-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
