@import '/src/theme/variables';

component-solid-surface-config-cutout-options-dialog {
    h5 {
        margin: 0;
        margin-bottom: 25px;
        text-align: center;
        text-transform: uppercase;
    }

    .options {
        display: flex;
        gap: 30px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .option {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 20px;
            border: 1px solid color('copy-border');
            border-radius: 10px;
            width: 100%;
            min-width: 240px;
            max-width: 300px;
            cursor: pointer;

            &.selected {
                border-color: color('diy-blue');
                color: color('diy-blue');
            }

            .details {
                flex-grow: 1;
                padding-left: 25px;
            }

            p {
                margin: 0;
            }

            img {
                height: 100px;
            }
        }
    }

    .ranges {
        display: flex;
        gap: 15px;
        flex-direction: column;
    }

    .actions {
        display: flex;
        justify-content: center;
        margin-top: 25px;
    }
}