import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { CatalogueService } from '@app/services/catalogue';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';

@Component({
    selector: 'page-doors',
    templateUrl: './doors.page.html',
    styleUrls: ['./doors.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DoorsPage implements OnInit {
    public styles: any[] = [];

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) {
        this.navigationService.setNavigation({
            title: 'Kitchens Doors',
            metaTags: [
                { name: 'Kitchen Doors', content: 'Kitchen Doors page' }, 
                { name: 'Description', content: 'See our range of kitchen doors.' }
            ],
            routeHistory: [{ title: 'Kitchen Doors', route: '/kitchen-doors' }]
        });
    }

    ngOnInit() {
        this.catalogueService.getKitchenStyles()
            .then((styles) => {
                this.styles = styles.slice().filter((style) => {
                    if (style.name !== 'Inframe') {
                        return true;
                    }

                    return false;
                });

                if (this.styles && this.styles.length) {
                    this.styles.forEach((style) => {
                        style.url = style.url.replace('kitchens', 'kitchen-doors');
                    });
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
