@import '/src/theme/variables';

component-notes-dialog { // sass-lint:disable-line no-empty-rulesets

    max-width: 100vw;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        h5 {
            margin: 0;
        }

        .close {
            margin-top: 10px;
            margin-right: 10px;
            cursor: pointer;
        }
    }

    mat-label {
        font-size: 14px;
    }

    .mat-mdc-text-field-wrapper {
        max-width: 90vw;
        width: 450px;
    }

    .warning {
        display: flex;
        margin-top: 10px;
        width: 100%;
        grid-gap: 5px;
        color: #dc3545;
    }

}