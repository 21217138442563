import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { NavigationService } from '@app/services/navigation';
import { RouteHistory } from '@app/services/navigation/models';

@Component({
    selector: 'page-about-which',
    templateUrl: './which.page.html',
    styleUrls: ['./which.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AboutWhichPage implements OnInit {
    public brand: string;

    private routeHistory: RouteHistory[] = [
        { title: 'About Diy Kitchens', route: '/about-diy-kitchens' },
        { title: 'Which? Best Buy', route: '/about-diy-kitchens/which-best-buy' }
    ];

    constructor(
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: 'Which? Best Buy - Kitchen Units',
            metaTags: [{ name: 'no-meta', content: 'no metadata' }],
            routeHistory: this.routeHistory
        });
    }
}
