import { Component, ViewEncapsulation, Input, OnInit, OnDestroy, HostListener } from '@angular/core';

import { Config } from '@app/config';
import { MarketingService } from '@app/services/marketing';
import { BannerLocation, IBanner } from '@app/services/marketing/models';
import { NavigationService } from '@app/services/navigation';
import { BasketService } from '@app/services/basket';
import { DialogService } from '@app/services/dialog';
import { Subscription } from 'rxjs';
import { Basket } from '@app/services/basket/models';
import { DateHelper } from '@app/utilities/helpers';

@Component({
    selector: 'component-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class BannerComponent implements OnInit, OnDestroy {
    @Input() locationId: BannerLocation;

    public banner: IBanner;
    public currentImageUrl;
    public style: any = {};
    public editingBasket: boolean = false;

    public basketSubscription: Subscription;
    public basketCutOffSubscription: Subscription;
    public basket: Basket;
    public now = DateHelper.now();
    public cutoffTimer = '';

    constructor(
        private config: Config,
        private dialogService: DialogService,
        private marketingService: MarketingService,
        private navigationService: NavigationService,
        private basketService: BasketService
    ) {}

    ngOnInit() {
        this.basketSubscription = this.basketService.basket$.subscribe((basket) => {
            if (basket) {
                this.basket = basket;
            }

            if (basket?.orderNumber) {
                this.editingBasket = true;
            } else {
                this.editingBasket = false;
            }
        });

        this.basketCutOffSubscription = this.basketService.cutoffTimer$.subscribe((timer) => (this.cutoffTimer = timer));

        this.loadBanner();
        this.updateImageUrl();
    }

    ngOnDestroy(): void {
        if (this.basketSubscription) {
            this.basketSubscription.unsubscribe();
            this.basketSubscription = null;
        }

        if (this.basketCutOffSubscription) {
            this.basketCutOffSubscription.unsubscribe();
            this.basketCutOffSubscription = null;
        }
    }

    @HostListener('window:resize', [])
    onResize() {
        this.updateImageUrl();
    }

    private loadBanner() {
        this.marketingService.getBannerForLocation(this.locationId)
            .then((response) => {
                if (response && response.length) {
                    this.banner = response.pop();
                    this.updateImageUrl();

                    if (this.banner.backgroundColour) {
                        this.style.background = this.banner.backgroundColour;
                    }

                    if (this.banner.routerLink && this.banner.routerLink.length) {
                        this.style.cursor = 'pointer';
                    }
                } else {
                    this.banner = null;
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    private updateImageUrl() {

        if (this.config.isBrowser && this.banner) {
            const isMobile = window.matchMedia('(max-width: 768px)').matches;
            this.currentImageUrl = isMobile
                ? this.banner.mobileImageUrl
                : this.banner.imageUrl;
        }
    }

    public handleClick() {
        if (this.banner?.routerLink?.length) {
            if (this.config.isBrowser && this.banner.routerLink.includes('http')) {
                window.open(this.banner.routerLink);
            } else {
                this.navigationService.navigate([this.banner.routerLink]);
            }
        }
    }
}
