import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'component-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class LoaderComponent {
    constructor() { }
}
