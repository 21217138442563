import { NgModule } from '@angular/core';

import { CategoryListingService } from './category-listing.service';

@NgModule({
    providers: [
        CategoryListingService
    ]
})
export class CategoryListingServiceModule { }
