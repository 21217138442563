import { Component, ViewEncapsulation, Inject, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';

import { Config } from '@app/config';
import { MergeUniquePipe } from '@app/pipes';
import { StringHelper } from '@app/utilities/helpers';

import { CatalogueService } from '@app/services/catalogue';
import { ActiveRange } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';

@Component({
    selector: 'component-range-selector-dialog',
    templateUrl: './range-selector.dialog.component.html',
    styleUrls: ['./range-selector.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class RangeSelectorDialogComponent implements OnInit, OnDestroy {
    @ViewChild('doorsPanel', { static: false }) doorsPanel: ElementRef;

    public ranges: any;
    public styles: any[] = [];
    public selectedStyle = '';
    public doors: any = [];

    public range: any = {};
    public rangeColour: string = null;
    public colour: string = null;
    public bespokeColoursList: any;
    public bespokeColour: string = null;
    public carcaseColours: string[];
    public carcaseColour: string = null;
    public isTrueHandleless: boolean = false;

    public toggleStyleSelection: boolean = true;
    public filteredBespokeColours: any[] = [];
    public sampleColourSelected: boolean = false;
    public searchBespokeText: string = null;
    public searchStyleText: string = null;

    public activeRange: ActiveRange;

    private activeRangeSubscription: Subscription;
    private mergeUnique = new MergeUniquePipe();

    constructor(
        private config: Config,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private dialogRef: MatDialogRef<RangeSelectorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.activeRange = data.activeRange;
        this.ranges = data.ranges.filter((range) => !range?.isTrueHandleless);
        this.carcaseColours = (data.carcaseColours || []).map((carcaseColour) => {
            if (carcaseColour && carcaseColour.colour) {
                return carcaseColour.colour;
            }

            return '';
        });
    }

    ngOnInit() {
        this.setDefaults(this.activeRange);

        this.styles = this.mergeUnique.transform(this.ranges.map(range => range.filters));
        this.catalogueService.sortStyles(this.styles);

        this.filterStyle(this.styles[0]);
        this.getBespokeColours();
    }

    ngOnDestroy() {
        if (this.activeRangeSubscription) {
            this.activeRangeSubscription.unsubscribe();
            this.activeRangeSubscription = null;
        }
    }

    private setDefaults(activeRange: ActiveRange) {
        if (activeRange && activeRange.range) {
            this.range = activeRange.range;
            this.rangeColour = activeRange.rangeColour;
            this.colour = activeRange.colour;
            this.bespokeColour = activeRange.bespokeColour;
            this.carcaseColour = activeRange.carcaseColour;
            this.isTrueHandleless = activeRange.rangeDetail.isTrueHandleless;
        } else {
            if (this.ranges && this.ranges.length) {
                this.range = this.ranges.slice().shift();
                this.rangeColour = this.range.colours.slice().shift().name;
                this.colour = null;
                this.bespokeColour = null;
                this.carcaseColour = this.carcaseColours.slice().shift();
            }
        }

        if (this.rangeColour === 'Bespoke') {
            this.searchBespokeText = this.bespokeColour || this.colour;
        }
    }

    public filterStyle(style) {
        this.selectedStyle = style;
        this.doors = [];
        this.ranges.map((range) => {
            if (range.filters.indexOf(style) !== -1) {
                range.colours.forEach((colour) => {
                    if (!style.match(/bespoke/i) || colour.name.match(/bespoke/i)) {
                        this.doors.push({
                            range: range,
                            colour: colour
                        });
                    }
                });
            }
        });

        this.doors.sort((a, b) => {
            if (a.range.name < b.range.name) {
                return -1;
            } else if (a.range.name > b.range.name) {
                return 1;
            } else if (a.colour.name < b.colour.name) {
                return -1;
            } else if (a.colour.name > b.colour.name) {
                return 1;
            }

            return 0;
        });

        this.scrollDoors();
    }

    private scrollDoors() {
        if (this.doorsPanel) {
            this.doorsPanel.nativeElement.scrollTo(0, 0);
        }
    }

    public doorImage(door) {
        return `${this.config.api.endpoints.cdn}/doors/${door.colour.door_image}`;
    }

    public carcaseImage(carcaseColour: string) {
        if (carcaseColour) {
            if (this.isTrueHandleless) {
                return `${this.config.api.endpoints.cdn}/carcases/true_handleless_${carcaseColour.replace(/\s/g, '_').toLowerCase()}_500px.jpg`;
            }

            return `${this.config.api.endpoints.cdn}/carcases/Unit_${((carcaseColour || '').replace(/\s/g, '_'))}.png`;
        } else {
            return 'https://static.diy-kitchens.com/3q_doors_units/unit_default_500px.png';
        }
    }

    public unitDoorImage() {
        const filise = (str) => str.toLowerCase().replace(/ /g, '_').replace(/_painted_colours/, '');

        if (this.range && this.range.name && this.rangeColour) {
            return `${this.config.api.endpoints.cdn}/3q_doors_units/` +
                filise(this.range.name) + '_' +
                filise(this.rangeColour).replace('_gloss', '') + '_3q_500px.png';
        } else {
            console.warn('missing deets', this.range, this.rangeColour);
        }

        return '';
    }

    public selectCarcaseColour(carcaseColour) {
        this.carcaseColour = carcaseColour;
    }

    public selectDoor(door) {
        this.range = door.range;
        this.rangeColour = door.colour.name;
        this.isTrueHandleless = door.range.isTrueHandleless;
        this.colour = null;
        this.bespokeColour = null;
        this.carcaseColours.forEach((carcaseColour) => {
            if (carcaseColour.match(door.colour.def_carcase)) {
                this.carcaseColour = carcaseColour;
            }
        });

        if (!this.carcaseColour) {
            this.carcaseColour = this.carcaseColours[0];
        }
    }

    public saveSelection() {
        this.toggleStyleSelection = true;
        this.dialogRef.close({
            range: this.range,
            rangeColour: this.rangeColour,
            colour: this.colour,
            bespokeColour: this.bespokeColour,
            carcaseColour: this.carcaseColour
        });
    }

    public close() {
        this.toggleStyleSelection = true;
        this.dialogRef.close(null);
    }

    public onSwitchSelect(toogle: boolean) {
        this.toggleStyleSelection = toogle;
    }

    public getBespokeColours() {
        this.catalogueService.getBespokeColours()
            .then((response) => {
                this.bespokeColoursList = response;
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public filterBespokeColours() {
        const filterValue = this.searchBespokeText.toLowerCase();
        this.filteredBespokeColours = this.bespokeColoursList
            .filter((colour) => colour.code.toLowerCase().includes(filterValue));
    }

    public searchStyle() {
        this.selectedStyle = '';
        this.doors = [];
        this.ranges.map((range) => {
            const regexp = new RegExp(this.searchStyleText.replace(/\s/g, '.'), 'i');
            let rangeMatch = false;

            if (range && range.name && range.name.match(regexp)) {
                rangeMatch = true;
            }

            if (range && range.colours) {
                range.colours.forEach((rangeColour) => {
                    if (rangeMatch) {
                        this.doors.push({
                            range: range,
                            colour: rangeColour
                        });
                    } else {
                        const style = `${range.name} ${rangeColour.name}`;
                        if (style.match(regexp) || rangeColour.name.match(regexp)) {
                            this.doors.push({
                                range: range,
                                colour: rangeColour
                            });
                        }
                    }
                });
            }
        });

        this.doors.sort((a, b) => {
            if (a.range.name < b.range.name) {
                return -1;
            } else if (a.range.name > b.range.name) {
                return 1;
            } else if (a.colour.name < b.colour.name) {
                return -1;
            } else if (a.colour.name > b.colour.name) {
                return 1;
            }

            return 0;
        });

        this.scrollDoors();
    }

    public clearSearchStyle() {
        this.filterStyle(this.styles[0]);
        this.searchStyleText = null;
    }

    public onBespokeColourSelected(event: MatAutocompleteSelectedEvent): void {
        const selectedName = event.option.value;
        const selectedColour = this.filteredBespokeColours.find((bespokeColour) => bespokeColour.code === selectedName);

        this.colour = selectedColour.code;
        this.bespokeColour = null;
    }

    public confirmColour() {
        if (this.searchBespokeText) {
            const foundBespoke = this.bespokeColoursList.find((bespokeColour) => bespokeColour.code === this.searchBespokeText);

            if (foundBespoke) {
                this.colour = foundBespoke.code;
                this.bespokeColour = null;
            } else {
                this.colour = 'Special Paint Colour';
                this.bespokeColour = StringHelper.titleCase(this.searchBespokeText)
            }
        } else {
            this.colour = null;
            this.bespokeColour = null;
        }
    }

    public isSelectionValid(): boolean {
        if (this.rangeColour === 'Bespoke') {
            if (!this.sampleColourSelected && this.colour && this.colour.length && !this.bespokeColour) {
                return true;
            } else if (this.sampleColourSelected && this.bespokeColour && this.bespokeColour.length) {
                return true;
            } else {
                return false;
            }
        }

        return true;
    }
}
