import { Component, ViewEncapsulation, Input, AfterViewInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Moment } from 'moment';
import { Subscription } from 'rxjs';

import { DateHelper } from '@app/utilities/helpers';

import { BasketService } from '@app/services/basket';
import { IBasketDeliveryType } from '@app/services/basket/models';
import { CheckoutService } from '@app/services/checkout';
import { DeliveryType } from '@app/services/checkout/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { OrderService } from '@app/services/order';

@Component({
    selector: 'component-delivery-options',
    templateUrl: './delivery-options.component.html',
    styleUrls: ['./delivery-options.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DeliveryOptionsComponent implements AfterViewInit, OnDestroy {
    @Input() stepper: MatStepper;
    @Input() formGroup: FormGroup;
    @Input() order: any;
    @Output() setAccessSurchargeCheck = new EventEmitter<boolean>();

    public deliveryTypes: IBasketDeliveryType[] = [];

    public selectedIndex: number = null;
    public selectedDates: Moment[] = [];
    public selectDateLater: boolean[] = [];
    public deliveryNote: string;

    public updatingSurcharge: boolean = false;

    public weekCommencing: string;

    private postcode: string;

    public selectedDelivery: {
        date: string;
        pickDeliveryLater: boolean;
        level: DeliveryType;
        cost: number;
    };

    public loading: boolean = true;
    private basketSubscription: Subscription = null;

    constructor(
        private basketService: BasketService,
        private checkoutService: CheckoutService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private orderService: OrderService
    ) { }

    ngAfterViewInit() {
        if (!this.order) {
            this.basketSubscription = this.basketService.basket$.subscribe((basket) => {
                if (basket) {
                    if (basket.deliveryLevel) {
                        this.selectedDelivery = {
                            date: basket.deliveryDate,
                            pickDeliveryLater: basket.pickDeliveryLater || false,
                            level: basket.deliveryLevel,
                            cost: basket.deliveryCost
                        };
                    }

                    if (basket.notes && basket.notes.length) {
                        let deliveryNote = basket.notes.find((note) => note.isDeliveryNote);
                        if (deliveryNote) {
                            this.deliveryNote = deliveryNote.note;
                        }
                    }

                    Promise.all([
                        this.leadtime(),
                        this.getPostcode()
                    ])
                        .then(() => {
                            this.getDeliveryTypes()
                                .then(() => {
                                    this.loading = false;
                                })
                                .catch((error) => {
                                    this.loading = false;
                                    this.dialogService.error(this.constructor.name, error);
                                });
                        })
                        .catch((error) => {
                            this.loading = false;
                            this.dialogService.error(this.constructor.name, error);
                        });
                }
            });
        } else {
            if (this.order.deliveryType) {
                this.selectedDelivery = {
                    date: this.order.deliveryDate,
                    pickDeliveryLater: this.order.pickDeliveryLater || false,
                    level: this.order.deliveryType,
                    cost: this.order.deliveryCost
                };
            }

            if (this.order.notes && this.order.notes.length) {
                let deliveryNote = this.order.notes.find((note) => note.isDeliveryNote);
                if (deliveryNote) {
                    this.deliveryNote = deliveryNote.note;
                }
            }

            Promise.all([
                this.leadtime(),
                this.getPostcode(),
            ])
                .then(() => {
                    this.getDeliveryTypes()
                        .then(() => {
                            this.loading = false;
                        })
                        .catch((error) => {
                            this.loading = false;
                            this.dialogService.error(this.constructor.name, error);
                        });
                })
                .catch((error) => {
                    this.loading = false;
                    this.dialogService.error(this.constructor.name, error);
                });
        }
    }

    ngOnDestroy() {
        if (this.basketSubscription) {
            this.basketSubscription.unsubscribe();
            this.basketSubscription = null;
        }
    }

    public weekCommencingFilter = (date) => {
        if (date && this.weekCommencing && DateHelper.format(date, true) >= this.weekCommencing) {
            const day = date.day();

            return day === 1;
        } else {
            return false;
        }
    }

    public anyDayFilter = (date) => {
        if (date && this.weekCommencing && DateHelper.format(date, true) >= this.weekCommencing) {
            const day = date.day();

            return day !== 0 && day !== 6;
        } else {
            return false;
        }
    }

    public isDeliveryDateValid(idx: number) {
        let validDeliveryDate = false;
        
        let deliveryType = this.deliveryTypes[idx];

        if (!deliveryType) {
            return false;
        }

        if (deliveryType.deliveryOptions === 'none') {
            return true;
        }

        if (this.selectDateLater[idx]) {
            validDeliveryDate = true;
        } else {
            if (
                this.selectedDates[idx] &&
                this.weekCommencing &&
                DateHelper.format(this.selectedDates[idx], true) >= this.weekCommencing
            ) {
                validDeliveryDate = true;
            }
        }

        return validDeliveryDate;
    }

    public selectDeliveryOption(idx: number) {
        this.updateDeliverySurcharge(idx, true)
            .then(() => {
                this.stepper.next();
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    private getPostcode(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.order) {
                this.postcode = this.order.deliveryPostcode;
                resolve();
            } else {
                this.basketService.addresses()
                    .then((response) => {
                        if (response && response.delivery) {
                            this.postcode = response.delivery.postcode;
                        }

                        resolve();
                    })
                    .catch((error) => reject(error));
            }
        });
    }

    private getDeliveryTypes(): Promise<void> {
        return new Promise((resolve, reject) => {
            let deliveryVariant = this.basketService.deliveryVariation(this.order || null);

            this.checkoutService.getDeliveryTypes(deliveryVariant)
                .then((deliveryTypes) => {
                    this.deliveryTypes = deliveryTypes;

                    this.deliveryTypes.forEach((deliveryType) => {
                        if (deliveryType.leadTimeInDays) {
                            let today = DateHelper.moment();
                            let firstAvailableDate = DateHelper.addBusinessDays(today, deliveryType.leadTimeInDays);
                            this.weekCommencing = DateHelper.format(firstAvailableDate.startOf('isoWeek'), true);
                        }
                    });

                    if (this.selectedDelivery) {
                        const idx = this.deliveryTypes
                            .map((deliveryType) => deliveryType.level)
                            .indexOf(this.selectedDelivery.level);

                        if (this.selectedDelivery.date) {
                            this.selectedDates[idx] = DateHelper.moment(this.selectedDelivery.date);

                            if (
                                this.selectedDates[idx] &&
                                this.weekCommencing &&
                                DateHelper.format(this.selectedDates[idx], true) < this.weekCommencing
                            ) {
                                this.selectedDates[idx] = DateHelper.moment(this.weekCommencing);
                            }

                        } else {
                            this.selectedDates[idx] = DateHelper.moment(this.weekCommencing);
                        }

                        if (this.selectedDelivery.pickDeliveryLater) {
                            this.selectDateLater[idx] = true;
                        } else {
                            this.selectDateLater[idx] = false;
                        }

                        this.selectedIndex = idx;

                        this.formGroup.setValue({ level: this.selectedDelivery.level, complete: this.isDeliveryDateValid(idx) });
                    }

                    resolve();
                })
                .catch((error) => reject(error));
        });
    }

    private updateDeliverySurcharge(idx: number, fromUserInput: boolean = false): Promise<void> {
        return new Promise((resolve, reject) => {
            this.updatingSurcharge = true;

            const deliveryType = this.deliveryTypes[idx];
            let deliveryDate = (this.selectDateLater[idx]) ? null : this.selectedDates[idx].format('YYYY-MM-DD');
            if (deliveryDate && this.weekCommencing && deliveryDate < this.weekCommencing) {
                deliveryDate = this.weekCommencing;
            }

            if (!this.selectedDelivery) {
                this.selectedDelivery = {
                    date: deliveryDate,
                    pickDeliveryLater: this.selectDateLater[idx] || false,
                    level: deliveryType.level,
                    cost: deliveryType.cost
                };
            } else {
                this.selectedDelivery.date = deliveryDate;
                this.selectedDelivery.pickDeliveryLater = this.selectDateLater[idx] || false;
                this.selectedDelivery.level = deliveryType.level;
                this.selectedDelivery.cost = deliveryType.cost;
            }

            if (this.order && !this.order.pickDeliveryLater && !this.selectedDelivery.pickDeliveryLater) {
                this.selectedDelivery.date = this.order.deliveryDate;
            }

            this.getDeliverySurcharge(this.postcode, deliveryType.level)
                .then((response) => {
                    let standardDelivery = false;
                    if ([DeliveryType.STANDARD, DeliveryType.SMALL_ORDER_UNITS].indexOf(deliveryType.level) !== -1) {
                        standardDelivery = true;
                    }
                    let accessSurchargeCheckRequired = standardDelivery;
                    this.setAccessSurchargeCheck.emit(accessSurchargeCheckRequired);
                    let deliveryNote;

                    if (standardDelivery) {
                        deliveryNote = this.deliveryNote;
                    }
                    if (this.order) {
                        this.orderService.setOrderDelivery(
                            this.order.id,
                            deliveryType.level,
                            deliveryType.cost,
                            deliveryDate,
                            response,
                            this.selectDateLater[idx] || false,
                            deliveryNote
                        )
                            .then(() => {
                                this.formGroup.setValue({ level: deliveryType.level, complete: true });
                                this.updatingSurcharge = false;
                                if (fromUserInput && (!standardDelivery)) {
                                    this.navigationService.navigate([`/checkout/order/${this.order.orderNumber}/delivery`]);
                                }
                                resolve();
                            })
                            .catch((error) => {
                                this.updatingSurcharge = false;
                                reject(error);
                            });
                    } else {
                        this.basketService.setDelivery(
                            deliveryType.level,
                            deliveryType.cost,
                            deliveryDate,
                            response,
                            this.selectDateLater[idx] || false,
                            deliveryNote
                        )
                            .then(() => {
                                this.formGroup.setValue({ level: deliveryType.level, complete: true });
                                this.updatingSurcharge = false;
                                resolve();
                            })
                            .catch((error) => {
                                this.updatingSurcharge = false;
                                reject(error);
                            });
                    }
                })
                .catch((error) => {
                    this.updatingSurcharge = false;
                    reject(error);
                });
        });
    }

    private getDeliverySurcharge(postcode: string, level: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.checkoutService.getAddressSurcharge(postcode, level)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    private leadtime(): Promise<void> {
        return new Promise((resolve, reject) => {
            let items;

            if (this.order) {
                items = this.order.items;
            } else {
                const basketItems = this.basketService.basketItems;
                if (basketItems && basketItems.length) {
                    items = basketItems;
                }
            }

            let styles = [];
            let carcaseColours = [];

            if (items && items.length) {
                items.forEach((item) => {
                    if (item.carcaseColour && carcaseColours.indexOf(item.carcaseColour) === -1) {
                        carcaseColours.push(item.carcaseColour);
                    }

                    if (item.range && item.rangeColour) {
                        const style = `${item.range} ${item.rangeColour}`;
                        if (styles.indexOf(style) === -1) {
                            styles.push(style);
                        }
                    }
                });
            }

            if (styles.length || carcaseColours.length) {
                this.basketService.leadtime((styles.length) ? styles : null, (carcaseColours.length) ? carcaseColours : null)
                    .then((weekCommencing) => {
                        if (weekCommencing) {
                            this.weekCommencing = weekCommencing;
                        } else {
                            this.weekCommencing = DateHelper.format(
                                this.defaultDeliveryDate(),
                                true
                            );
                        }

                        resolve();
                    })
                    .catch((error) => {
                        this.weekCommencing = DateHelper.format(
                            this.defaultDeliveryDate(),
                            true
                        );

                        resolve();
                        this.dialogService.error(this.constructor.name, error);
                    });
            } else {
                resolve();
            }
        });
    }

    private defaultDeliveryDate() {
        return DateHelper.moment().startOf('isoWeek').add(4, 'weeks');
    }
}
