<div class="contain-content">
    <div class="contained">
        <component-loader *ngIf="!loaded"></component-loader>

        <ng-container *ngIf="loaded">
            <div class="header">
                <h1 class="small">YOUR BASKETS</h1>

                <div class="actions">
                    <button mat-stroked-button color="secondary" (click)="recover()">
                        <span>Recover Basket</span>
                        <mat-icon>history</mat-icon>
                    </button>
                    <button mat-stroked-button color="secondary" (click)="create()">
                        <span>New Basket</span>
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>

            <table class="baskets-table" mat-table [dataSource]="tableData.source" matSort *ngIf="loaded" multiTemplateDataRows>
                <ng-container matColumnDef="basket">
                    <th mat-header-cell *matHeaderCellDef>Basket</th>
                    <td mat-cell *matCellDef="let row">
                        <div class="basket-container basket-details-column">
                            <div class="basket-details-container">
                                <div class="basket-details">
                                    <div class="basket-detail">
                                        <h3>{{row.name}}</h3>
                                        <h4 *ngIf="row.id">Ref: {{row.id}}</h4>
                                    </div>
                                    <div class="basket-detail">
                                        <p class="basket-date" *ngIf="row.createdAt">Created:</p>
                                        <p class="basket-date" *ngIf="row.createdAt" [matTooltip]="row.createdAt"><strong>{{row.createdAt | date:"dd/MM/YYYY"}} <span>{{row.createdAt | date:"HH:mma"}}</span></strong></p>
                                        <p class="basket-date" *ngIf="row.modifiedAt">Modified:</p>
                                        <p class="basket-date" *ngIf="row.modifiedAt" [matTooltip]="row.modifiedAt"><strong>{{row.modifiedAt | date:"dd/MM/YYYY"}} <span>{{row.modifiedAt | date:"HH:mma"}}</span></strong></p>
                                    </div>
                                </div>
                            </div>
                            <div class="basket-actions-container">
                                <button mat-stroked-button class="secondary" (click)="view(row)">
                                    <mat-icon>description</mat-icon> View
                                </button>
                                <button mat-stroked-button class="secondary" *ngIf="row.planId" (click)="openPlan(row.planId)">
                                    <mat-icon>build</mat-icon> Plan
                                </button>
                            </div>
                        </div>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="cost">
                    <th mat-header-cell *matHeaderCellDef>Cost</th>
                    <td mat-cell *matCellDef="let row">
                        <div class="basket-container">
                            <div class="basket-details-container">
                                <h4 *ngIf="row.cost" [innerHTML]="row.cost | currencyFormat:true:true"></h4>
                            </div>
                            <div class="basket-actions-container"></div>
                        </div>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="style">
                    <th mat-header-cell *matHeaderCellDef>Info</th>
                    <td mat-cell *matCellDef="let row" class="row-style">
                        <div class="basket-container">
                            <div class="basket-details-container">
                                <h4>Items</h4>
                                <p>{{row.itemQty}}</p>

                                <!-- <div class="styles" *ngFor="let style of row.styles">
                                    <h4>{{style.range}}</h4>
                                    <div class="style-colour" *ngFor="let colour of style.colours">
                                        <div class="swatch" [ngClass]="'swatch-' + colour.swatch"></div>
                                        <p>{{colour.rangeColour}}</p>
                                    </div>
                                </div>
                                <div class="style-colour" *ngIf="row.stylesMissing && row.stylesMissing.length">
                                    <p *ngFor="let style of row.stylesMissing">{{style}}</p>
                                </div> -->
                                <div class="styles" *ngFor="let style of row.styles">
                                    <h4>{{style.rangeName}}</h4>
                                    <div class="style-colour" *ngFor="let colour of style.colours">
                                        <div class="swatch" [ngClass]="'swatch-' + colour.swatch"></div>
                                        <p>{{colour.name}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="basket-actions-container"></div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="header-actions"></th>
                    <td mat-cell *matCellDef="let row" class="row-actions">
                        <div class="actions">
                            <div class="action">
                                <button mat-stroked-button class="action-button skinny" color="secondary" *ngIf="row.id" (click)="rename(row)">
                                    <mat-icon>edit</mat-icon> Rename
                                </button>
                            </div>
                            <div class="action">
                                <button mat-stroked-button class="action-button skinny" color="primary" *ngIf="row.id" (click)="duplicate(row)">
                                    <mat-icon>content_copy</mat-icon> Duplicate
                                </button>
                            </div>
                            <div class="action">
                                <button mat-stroked-button class="action-button skinny" color="warn" *ngIf="row.id" (click)="remove(row)">
                                    <mat-icon>delete</mat-icon> Remove
                                </button>
                            </div>
                        </div>
                    </td>
                </ng-container>
        
                <thead>
                    <tr mat-header-row class="baskets-table-header" *matHeaderRowDef="displayColumns"></tr>
                </thead>
                <tbody>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;" class="baskets-table-row" [ngClass]="{ 'removed': row.removedAt, 'exported': row.mcsState, 'added': !row.removedAt }"></tr>
                </tbody>
            </table>
        </ng-container>
    </div>
</div>
