import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';
import { PipesModule } from '@app/pipes';

import { ArticlesComponent } from './articles.component';
import { ArticleComponent } from './article/article.component';

@NgModule({
    imports: [
        DesignModule,
        PipesModule
    ],
    exports: [
        ArticlesComponent,
        ArticleComponent
    ],
    declarations: [
        ArticlesComponent,
        ArticleComponent
    ]
})
export class ArticlesComponentModule { }
