import { Component, ViewEncapsulation, Input, OnInit, OnChanges } from '@angular/core';

import { Config } from '@app/config';

import { CatalogueService } from '@app/services/catalogue';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';

@Component({
    selector: 'component-range-actions',
    templateUrl: './range-actions.component.html',
    styleUrls: ['./range-actions.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class RangeActionsComponent implements OnInit, OnChanges {
    @Input() range: any;
    @Input() selectedColour: any;
    @Input() selectedCabinet: any;
    @Input() orientation: string; // row || column
    private useDefaultCabs: boolean = false;

    constructor(
        private config: Config,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        if (!this.selectedCabinet) {
            this.useDefaultCabs = true;
            this.getDefaultCab();
        }
    }

    ngOnChanges() {
        if (this.useDefaultCabs) {
            this.getDefaultCab();
        }
    }

    private getDefaultCab() {
        this.catalogueService.getCabinetColours()
            .then((cabinetColours: any) => {
                if (cabinetColours) {
                    this.selectedCabinet = cabinetColours.find(col => col.colour === this.selectedColour.def_carcase) || cabinetColours[0];
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public selectRange(dest) {
        this.catalogueService.updateActiveRange(this.range, this.selectedColour.colour);
        let destinationMap = {
            'units': 'kitchen-units/base',
            'doors': 'kitchen-doors',
            'samples': 'samples'
        };
        this.navigationService.navigate([destinationMap[dest]]);
    }

    public plannerLink() {
        if (this.config.isBrowser) {
            window.open(`${this.config.api.endpoints.planner}/range/${this.range.name}:${this.selectedColour.name}`, '_blank');
        }
    }
}
