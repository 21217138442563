@import '/src/theme/variables';

component-loader {
    display: block;

    .loader {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: color('black', 0.1);
        z-index: 1000;

        .loader-message {
            margin-top: 50px;
        }
    }
}