@import '/src/theme/variables';

page-about-kitchen-sales {
    section {
        &.content-contained {
            display: flex;
            flex-direction: column
        }
    }

    button {
        &.button-link {
            color: color('white');
            font-size: 1rem;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .offer-banner {
        display: flex;
        flex-direction: row;
        padding: 15px;
        overflow: hidden;

        & + .offer-banner {
            margin-top: 25px;
        }

        &.offer-sale {
            background: #E84339;
            color: #333;
        }

        &.offer-competitor {
            background: #9C815F;
        }

        &.offer-competitor-worktop {
            background: #71455C;
        }

        .offer-image {
            min-width: calc(50% - 25px);
            margin-right: 25px;
        }

        .offer-details {
            display: flex;
            flex-direction: column;
            min-width: 50%;

            .offer-detail {
                display: flex;
                flex-direction: column;
                overflow: hidden;
                height: 100%;

                p {
                    margin: 0;
                    font-family: font('bitter');

                    &.small {
                        font-size: 1.5rem;
                        font-weight: 400;
                    }

                    &.large {
                        font-size: 3.9rem;
                        font-weight: 700;
                        line-height: 3.9rem;
                    }
                }

                .button-wrapper {
                    display: flex;
                    flex-direction: row;
                    margin-top: auto;
                    gap: 15px;

                    a {
                        width: 100%;

                        .mdc-button,
                        & + .mdc-button {
                            margin-top: 15px;
                            width: 100%;
                            text-align: center;
                        }

                        & + .mdc-button {
                            margin-left: 15px;
                        }
                    }
                }
            }
        }

        .offer-terms {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;

            p {
                margin: 0;
                color: color('white');
                font-size: 0.625rem;
            }

            .button-link {
                &:last-child {
                    margin-left: auto;
                }
            }
        }
    }

    @media only screen and (max-width: 992px) {
        .offer-banner {
            .offer-details {
                .offer-detail {
                    p {
                        &.large {
                            font-size: 3rem;
                            line-height: 3rem;
                        }
                    }

                    .button-wrapper {
                        flex-direction: column;

                        a {
                            & + .mdc-button {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .offer-banner {
            flex-direction: column;

            .offer-image {
                margin: 0;
            }
        }
    }

    @media only screen and (max-width: 480px) {
        .offer-banner {
            .offer-details {
                .offer-detail {
                    p {
                        &.small {
                            font-size: 1.2rem;
                        }

                        &.large {
                            font-size: 2rem;
                            line-height: 2rem;
                        }
                    }
                }
            }
        }
    }
}
