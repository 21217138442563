@import '/src/theme/variables';

page-about {
    .how-kitchens-made {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .how-kitchens-made-video {
            position: relative;
            flex-basis: 40%;

            &:hover {
                cursor: pointer;
            }

            .mat-icon {
                position: absolute;
                left: calc(50% - 40px);
                top: calc(50% - 40px);
                width: 80px;
                height: 80px;
                color: color('white');
                font-size: 80px;
                opacity: 0.8;
            }
        }

        .how-kitchens-made-content {
            flex-basis: 50%;
            margin-left: 8%;
        }
    }

    .about-diy-topics {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 30px;
        margin: 56px 0;

        .about-diy-topic {
            display: flex;
            flex-direction: column;
            text-align: center;

            h3 {
                margin: 20px 0;
                color: color('opaque-black');
                font-size: 1.75rem;
                font-family: font('playfair');
                font-weight: 700;
                line-height: 1.75rem;
            }

            .about-diy-topic-content {
                flex-grow: 1;
            }

            .mdc-button {
                margin: 1.333rem 0;
                min-height: 50px;
                font-weight: 600;
            }
        }
    }

    @media (max-width: 992px) {
        .content-contained {
            max-width: 720px;
        }

        .how-kitchens-made {
            flex-direction: column;

            .how-kitchens-made-content {
                margin-top: 56px;
                margin-left: 0;
            }
        }

        .about-diy-topics {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}