<div class="view-basket-history">
    <div class="basket-quick-action-cnt basket-history-header" id="section-to-hide-print">
        <h5>
            {{ cutOffDate? 'Order History' : 'Basket History'}}
        </h5>
    </div>
    <div class="basket-history-list">

        <div class="basket-history-item" *ngFor="let basketItem of basketHistoryList">
            <div class="basket-quick-action-cnt" id="section-to-hide-print">
                <mat-icon [ngClass]="basketItem.actionIconCssName">{{basketItem.actionIcon}}</mat-icon><span
                    [ngClass]="basketItem.actionLabelCss"> {{basketItem.actionTitle}}</span>
                <span class="date"> {{basketItem.modifiedAt | date: 'dd/MM/yyyy HH:mm:ss'}} </span>
            </div>
            <div class="basket-quick-action-cnt" id="section-to-hide-print">
                <span class="basket-history-detail">{{getBasketHistoryItemTitle(basketItem)}}</span>
            </div>
            <div class="basket-quick-action-cnt basket-history-lbl-item" id="section-to-hide-print">
                <span>{{basketItem.changedItem}}</span>
                <span>Quantity : {{basketItem.itemQty}}</span>
            </div>
        </div>

    </div>
</div>