import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SamplesPage } from './samples.page';
import { DoorSamplePage } from './door-sample/door-sample.page';
import { CarcaseSamplePage } from './carcase-sample/carcase-sample.page';
import { WorktopSamplePage } from './worktop-sample/worktop-sample.page';

import { PageNotFoundComponent } from '@app/shared/page-not-found.component';
import { ProductCategoryGuard } from '@app/utilities/guards/product-category.guard';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: SamplesPage,
                pathMatch: 'full'
            },
            {
                path: 'door-samples',
                component: DoorSamplePage,
                data: { vatToggle: true }
            },
            {
                path: 'door-samples/:category',
                component: DoorSamplePage,
                data: { vatToggle: true }
            },
            {
                path: 'carcase-samples',
                component: CarcaseSamplePage
            },
            {
                path: 'solid-surface-worktop-samples',
                component: WorktopSamplePage,
                data: { vatToggle: true }
            },
            {
                path: 'solid-surface-worktop-samples/:category',
                component: WorktopSamplePage,
                canActivate: [ProductCategoryGuard],
                data: { vatToggle: true }
            },
            { path: '**', component: PageNotFoundComponent }
        ])
    ],
    exports: [RouterModule]
})
export class SamplesRouting { }
