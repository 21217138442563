@import '/src/theme/variables';

component-header-search {

    .container {
        .scroller {
            overflow-y: auto;
        }
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 16px 30px 0;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0.21em;
        text-transform: uppercase;

        .close {
            cursor: pointer;
        }
    }

    .section-cards {
        .mdc-card {
            max-width: 300px;
            height: auto;
            cursor: auto;

            .mat-mdc-card-title {
                padding-bottom: 15px;
                font-weight: 600;
            }

            .mat-mdc-card-content {
                flex-grow: 1;

                p {
                    margin-top: 10px;
                }
            }
        }
    }

    .tabs {
        display: flex;
        max-width: 100%;
        flex-wrap: wrap;
        margin-top: 15px;
        margin-bottom: 5px;
        border-top: 0.5px solid color('black', 0.06);
        border-bottom: 0.5px solid color('black', 0.06);

        a {
            display: block;
            margin: 0 40px 0 0;
            padding: 15px 0;
            font-size: 14px;
            letter-spacing: 0.11em;
            text-transform: uppercase;

            &.selected {
                padding-bottom: 7px;
                border-bottom: 1px solid color('black');
                font-weight: bold;
            }
        }
    }

    .results {
        margin-bottom: 30px;
        padding: 0 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid color('copy-border');

        .prods {
            display: flex;
            gap: 15px;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;

        }

        .item {
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
            margin: 3px 0;
            width: calc((100vw - 130px) / 4);
            min-width: 260px;
            max-width: 210px;
            text-align: center;

            .details {
                flex: 0 0 auto;
                margin: 15px 0;
                height: 45px;
                color: color('black');
                font-family: 'Open Sans';
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
                letter-spacing: 0.03px;
            }

            .code {
                margin: 10px 0 15px;
                color: color('cadet-blue');
                font-family: 'Open Sans';
                font-size: 12px;
                font-weight: 100;
                line-height: 19px;
                letter-spacing: 0.17em;
                text-transform: uppercase;
            }

            .price {
                margin-top: 0;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.13em;

                .cabinet-only {
                    margin-bottom: 0;
                }
            }

            button {
                text-transform: uppercase;
                font-size: 0.75rem;
                font-weight: 600;
            }
        }

        .image {
            position: relative;
            display: flex;
            flex-basis: 275px;
            align-items: center;

            a {
                display: block;
                width: 100%;
            }

            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
                max-height: 275px;
            }
        }

        .actions {
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: space-between;
            margin-top: 15px;
            width: 100%;
        }
    }

    .noresult {
        padding: 20px;

        strong {
            margin: 20px 0;
            font-size: 1.1em;
            font-weight: bold;
        }

        a {
            font-weight: bold;
        }

        .advice {
            margin-top: 60px;
        }
    }
}
