@import '/src/theme/variables';

component-latest-offers {
    .latest-offers {
        width: 100%;
        padding: 40px 0;
        border-top: 1px solid rgb(238, 241, 245);

        strong {
            font-size: 14px;
            letter-spacing: 1.5px
        }

        .section-intro-block {
            text-align: center;
            margin-bottom: 56px;

            h4 {
                font-family: font('opensans');
                font-size: clamp(1rem, 1vw + 1rem, 1rem);
                font-style: normal;
                font-weight: 700;
                letter-spacing: 0.05rem;
                line-height: clamp(1rem, 1vw + 1rem, 1.333rem);
                margin-bottom: 1rem;
                color: rgba(0, 18, 36, 0.9);
                text-transform: uppercase;
            }

            p {
                font-family: font('opensans');
                font-size: clamp(1rem, 1vw + 1rem, 1rem);
                font-style: normal;
                font-weight: 300;
                letter-spacing: 0rem;
                line-height: clamp(1.618rem, 1vw + 1.618rem, 1.618rem);
                margin-bottom: 1.333rem;
                color: rgba(0, 18, 36, 0.9);
            }
        }

        .sign-up {
            margin-bottom: 20px;
            padding: 15px 30px;
            border: none;
            border-radius: 4px;
            background-color: #009CDE;
            width: 60%;
            height: 52px;
            color: #FFF;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            cursor: pointer;

            &:hover {
                background: #30A8DD;
                color: #FFF;
                text-decoration: none
            }
        }
    }

    @media (max-width: 480px) {
        .latest-offers {
            padding: 40px 0;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            width: 100%;

            &::after {
                content: '';
            }
        }
    }
}