<header class="content-contained">
    <h1>{{categoryDisplay}} Door Samples</h1>
    <p>
        At DIY Kitchens, we want to supply you with your dream kitchen, that's why we offer a door samples service. Order
        a sample or two of the door style that you are interested in, so that you can see the quality of our doors for
        yourself!
    </p>
    <p>
        <strong>Important notice</strong>: Due to increased demand for sample doors our delivery leadtime has
        temporarily changed to 5-7 days.
    </p>
    <p>
        Simply order your sample doors, decide on your favourite then return the doors to us for a refund on the door
        cost and then proceed with ordering your new kitchen.
    </p>
</header>

<hr class="narrow" />

<section class="content-contained">
    <div class="flex-space-between">
        <div class="left">
            <component-filters [items]="items" [filters]="filterFields" [sectionCats]="sectionCats"
                [section]="category || 'allstyles'" [sectionURL]="'samples/door-samples'"
                (filterChange)="updateItems($event)" [showSort]="false">
            </component-filters>
            <div class="tip">
                <h4>Useful tip:</h4>
                <p>
                Why not order some carcase colour swatches too, these are FREE and allow you to check the colour of your cabinet against your sample door.
                All door samples come packaged in durable folders with more information about the range you are interested in.
                You can return the samples with our drivers on delivery of your new kitchen, or post them back to us either way we'll give you a refund on the cost of the doors!
                </p>
            </div>
        </div>
        <div class="products" *ngIf="items">
            <div class="banner">
                <img src="https://static.diy-kitchens.com/assets/images/samples/free-sample_listings.jpg" width="100%" alt="Free samples. Get 3 free door samples straight to your home. £6 delivery charge. Must be delivered to same address."/>
            </div>

            <div class="door" *ngFor="let entry of filteredItems">
                <div class="image"><img [cdn]="entry.item.image" [alt]="entry.item.style"></div>
                <div class="title">{{entry.item.style}}</div>
                <div class="price" [innerHTML]="itemCost(entry.item) ? (itemCost(entry.item) | currencyFormat:true:true) : 'FREE'"></div>
                <div class="actions">
                    <component-basket-add-button [type]="type" [item]="entry.item" [allowFree]="true" [overridePrice]="itemCost(entry.item)">
                    </component-basket-add-button>
                    <button mat-stroked-button class="chunky" [routerLink]="entry.item.styleURL">
                        VIEW THIS STYLE
                    </button>
                </div>
            </div>
            <div *ngIf="noResults" class="no-results">
                <em class="common-em">No samples were found that match your filters.</em>
                Try removing a filter.
            </div>
        </div>
    </div>
</section>
