import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { SamplesRouting } from './samples.routing';
import { SamplesPage } from './samples.page';
import { DoorSamplePage } from './door-sample/door-sample.page';
import { CarcaseSamplePage } from './carcase-sample/carcase-sample.page';
import { WorktopSamplePage } from './worktop-sample/worktop-sample.page';

@NgModule({
    imports: [
        SharedModule,
        SamplesRouting
    ],
    declarations: [
        SamplesPage,
        DoorSamplePage,
        CarcaseSamplePage,
        WorktopSamplePage
    ]
})
export class SamplesPageModule { }
