@import '/src/theme/variables';

component-solid-surface-config {
    .contain-config {
        margin: 0 auto;
        background-color: color('white');
        width: 1024px;
        color: color('black');
    }

    .too-small {
        display: none;
        padding: 30px;
    }

    .canvas {
        margin-top: 25px;
    }

    .big-enough {
        display: flex;
        width: 100%;

        .left-panel {
            width: calc(100% - 760px);

            .tabs {
                width: 100%;

                .layouts {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .button-layout {
                        padding: 5px 10px;
                        width: 100%;
                        height: auto;

                        &.selected {
                            background-color: color('link-hover');
                        }

                        &.layout-island {
                            img {
                                transform: rotate(90deg) translateX(20px);
                            }
                        }

                        .mdc-button__label {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            overflow: hidden;
                            width: 100%;
                        }

                        img {
                            margin-left: 5px;
                            width: 50px;
                        }
                    }
                }

                .options {
                    h5 {
                        margin: 0;
                        font-size: 1rem;
                    }

                    p {
                        margin: 0;
                        font-size: 0.75rem;
                        font-weight: 400;
                    }

                    .splashback-cill-add,
                    .cutout-add {
                        color: color('green');
                    }

                    .splashback-cill-remove,
                    .cutout-remove {
                        color: color('brand-warn');
                    }

                    .splashback-cill-edit {
                        color: color('outrageous-orange');
                    }
                }
            }
        }

        .right-panel {
            padding-left: 15px;
            border-left: 1px solid color('copy-border');

            .header {
                display: flex;
                justify-content: space-between;

                .help {
                    display: flex;
                    flex-shrink: 0;
                    flex-grow: 0;
                    align-items: center;
                    justify-content: center;
                    padding: 15px;
                    border: 1px solid color('copy-border');
                    width: 200px;
                    height: 110px;

                    p {
                        margin: 0;
                        font-size: 0.75rem;
                    }
                }
            }

            .actions {
                display: flex;
                justify-content: flex-end;
                width: 100%;

                .action-buttons {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                }

                .expand-left {
                    margin-left: auto;
                }
            }

            .footer {
                .notes {
                    margin-top: 15px;

                    p {
                        margin: 0;
                        font-size: 0.75rem;
                    }
                }
            }
        }

        .popout-summary {
            display: none;

            &.show-popout-summary {
                display: block;
            }
        }

        .summary {
            h5 {
                font-weight: 400;
            }

            table {
                margin-bottom: 50px;
                width: 100%;

                th {
                    text-align: left;
                }

                td {
                    vertical-align: top;

                    p {
                        margin: 0;
                        font-size: 0.875rem;
                        font-weight: 400;
                    }
                }
            }

            .costs {
                text-align: right;
            }
        }

        .mat-mdc-form-field {
            width: 100%;

            & + .mat-mdc-form-field {
                margin-top: 15px;
            }

            &.invalid {
                .mdc-text-field--outlined:not(.mdc-text-field--disabled) {

                    .mdc-notched-outline__leading,
                    .mdc-notched-outline__notch,
                    .mdc-notched-outline__trailing {
                        border-color: color('bright-red');
                    }
                }
            }
        }

        .sub-section-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;
            cursor: pointer;

            &:not(:first-child) {
                border-top: 1px solid color('copy-border');
            }

            p {
                margin: 0;
                font-weight: 400;
            }
        }

        .sub-section {
            display: none;

            &.open {
                display: block;
            }

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        .radios {
            .radio {
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }

        .options {
            &+.options {
                margin-top: 15px;
            }

            .option {
                display: flex;
                align-items: center;
                gap: 5px;

                .mat-icon {
                    flex-shrink: 0;
                    cursor: pointer;
                }

                h6 {
                    margin: 0;
                    font-size: 0.875rem;
                }
            }
        }

        .quotation,
        .change-style {
            .title {
                font-weight: normal;

                span {
                    font-size: 0.75rem;
                }
            }

            img {
                margin-bottom: 15px;
            }

            p {
                margin: 0;
            }

            .price {
                margin-top: 10px;
                margin-bottom: 25px;
                font-weight: 400;
            }
        }

        .quotation {
            .quotation-prices {
                display: flex;
                gap: 5px;
                flex-direction: column;

                .quotation-price {
                    display: flex;
                    justify-content: space-between;

                    &:last-child {
                        border-top: 1px solid color('copy-border');

                        p {
                            font-weight: 600;

                            &:last-child {
                                font-size: 1rem;
                            }
                        }
                    }

                    p {
                        &:first-child {
                            font-size: 0.75rem;
                        }

                        &:last-child {
                            font-size: 0.875rem;
                        }
                    }
                }
            }
        }

        .mdc-button {
            flex-shrink: 0;
        }
    }

    .read-only {
        .big-enough {
            .right-panel {
                margin: 0 auto;
                padding: 0;
                border: 0;
            }
        }
    }

    @media (max-width: 1083px) {
        .big-enough {
            display: none;
        }

        .too-small {
            display: block;
        }

        .contain-config {
            width: auto;
        }
    }
}