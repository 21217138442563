import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { WorktopsPage } from './worktops.page';

import { PageNotFoundComponent } from '@app/shared/page-not-found.component';


@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: WorktopsPage,
                pathMatch: 'full'
            },
            { path: '**', component: PageNotFoundComponent }
        ])
    ],
    exports: [RouterModule]
})
export class WorktopsRouting { }
