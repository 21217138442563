import { Component, ViewEncapsulation, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Subscription } from 'rxjs';

import { AuthService } from '@app/services/auth';
import { DialogService } from '@app/services/dialog';
import { IBasketDeliveryAccess } from '@app/services/basket/models';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';
import { OrderService } from '@app/services/order';

@Component({
    selector: 'component-page-change-order-address',
    templateUrl: './change-order-address.page.html',
    styleUrls: ['./change-order-address.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ChangeOrderAddressPage implements OnInit, OnDestroy {
    @ViewChild(MatStepper) stepper: MatStepper;

    public order: any = {};
    public steps: string[] = ['summary', 'payment'];
    public orderNumber: string = null;
    public loaded: boolean = false;
    public accessSurchargeCheckRequired: boolean = false;

    private routeHistory: RouteHistory[] = [];
    private routeSubscription: Subscription;

    public paymentDetailsFetched: boolean = false;
    public outstandingAmount = null;
    public weekCommencing: string;
    public delivery: string;
    public userCancelled: boolean = false;
    private lastStep: number = 0;
    public selectedDelivery: any = null;
    public selectedAccess: IBasketDeliveryAccess;

    public detailsFormGroup: FormGroup = this.formBuilder.group({
        complete: new FormControl(false, [Validators.requiredTrue])
    });
    public deliveryFormGroup: FormGroup = this.formBuilder.group({
        level: new FormControl(null, [Validators.required]),
        complete: new FormControl(false, [Validators.requiredTrue])
    });

    public accessFormGroup: FormGroup = this.formBuilder.group({
        complete: new FormControl(false, [Validators.requiredTrue])
    });

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private orderService: OrderService
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                if (route.params && route.params.orderNumber) {
                    this.orderNumber = route.params.orderNumber;
                }

                this.routeHistory = [{ title: 'Change Order Address', route: `/checkout/order/address/${this.orderNumber}` }];
                this.navigationService.setNavigation({
                    title: 'Kitchens - Your account',
                    metaTags: [{ name: 'no-meta', content: 'no metadata' }],
                    routeHistory: this.routeHistory
                });
            }
        );

        this.authService.authentication.subscribe(
            (isAuthed) => {
                if (isAuthed) {
                    this.orderService.getOrderDetails(this.orderNumber)
                        .then((order) => {
                            if (order) {
                                this.order = order;
                                this.selectedDelivery = {
                                    date: order.deliveryDate,
                                    pickDeliveryLater: order.pickDeliveryLater || false,
                                    level: order.deliveryType,
                                    cost: order.deliveryCost
                                };
                                this.loaded = true;
                            } else {
                                this.dialogService.error(this.constructor.name, 'Failed to load Order');
                            }
                        })
                        .catch((error) => this.dialogService.error(this.constructor.name, error));
                }
            }
        );
    }

    public stepperUpdate(event) {
        if (this.lastStep < event.selectedIndex) {
            this.lastStep = event.selectedIndex;
        } else if (this.lastStep > event.selectedIndex) {
            this.stepper.steps.forEach((step, idx) => {
                if (idx > event.selectedIndex) {
                    step.reset();
                }
            });
        }
    }

    public goToPaymentOptions() {
        this.navigationService.navigate([`/checkout/order/${this.order.orderNumber}`]);
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }

    public setAccessSurchargeCheck(check) {
        this.accessSurchargeCheckRequired = check;
    }
}
