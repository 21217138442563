import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { StringHelper } from '@app/utilities/helpers';

import { BasketService } from '@app/services/basket';
import { CatalogueService } from '@app/services/catalogue';
import { ProductType } from '@app/services/catalogue/models';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';
import { DialogService } from '@app/services/dialog';
import { Config } from '@app/config';

@Component({
    selector: 'page-all-types',
    templateUrl: './all-types.page.html',
    styleUrls: ['./all-types.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AllTypesPage implements OnInit, OnDestroy {
    public ProductType = ProductType;
    public sections: any = {
        'Square Edge Laminate': {
            'Standard': {
                title: 'Standard',
                description: 'To be used with standard units edged on 3 sides.',
                image: 'https://static.diy-kitchens.com/assets/images/laminateworktops/square-edge-a.jpg',
                rank: 1
            },
            'Radius Base': {
                title: 'Radius Base',
                description: 'To be used with curved base units edged on 3 sides.',
                image: 'https://static.diy-kitchens.com/assets/images/laminateworktops/square-edge-b.jpg',
                rank: 2
            },
            'Peninsular': {
                title: 'Peninsular',
                description: 'To be used with peninsular layouts edged on 3 sides.',
                image: 'https://static.diy-kitchens.com/assets/images/laminateworktops/square-edge-c.jpg',
                rank: 3
            },
            'S Shape': {
                title: 'S-Shaped',
                description: 'To be used with the 1000mm s-shaped unit.',
                image: 'https://static.diy-kitchens.com/assets/images/laminateworktops/square-edge-d.jpg',
                rank: 4
            },
            'Curved Island': {
                title: 'Curved Island',
                description: 'Finished on all edges & curved on all 4 corners.',
                image: 'https://static.diy-kitchens.com/assets/images/laminateworktops/square-edge-e.jpg',
                rank: 5
            },
            'Breakfast Bar': {
                title: 'Breakfast Bar',
                description: 'Finished on all edges, to create a breakfast bar or island layout.',
                image: 'https://static.diy-kitchens.com/assets/images/laminateworktops/square-edge-f.jpg',
                rank: 6
            },
            'Curved Breakfast Bar': {
                title: 'Breakfast Bar',
                description: 'Finished on all edges, to create a breakfast bar or island layout.',
                image: 'https://static.diy-kitchens.com/assets/images/laminateworktops/square-edge-f.jpg',
                rank: 7
            },
            'Internal Curve': {
                title: 'Internal Curve',
                description: 'To be used with the 900 x 900mm internal curved base unit.',
                image: 'https://static.diy-kitchens.com/assets/images/laminateworktops/square-edge-g.jpg',
                rank: 8
            },
            'Gable Downturn': {
                title: 'Gable / Downturn',
                description: 'Finished on both sides & edged on 2 long edges.',
                image: 'https://static.diy-kitchens.com/assets/images/laminateworktops/square-edge-h.jpg',
                rank: 9
            },
            'Upstand': {
                title: 'Upstands',
                description: 'An ideal cost-efficient and hygienic alternative to tiling.',
                image: 'https://static.diy-kitchens.com/assets/images/laminateworktops/square-edge-i.jpg',
                rank: 10
            },
            'Back Panel': {
                title: 'Back Panels',
                description: 'Extend the decor behind your hob for a linear look.',
                image: 'https://static.diy-kitchens.com/assets/images/laminateworktops/square-edge-j.jpg',
                rank: 11
            },
            'Edging Strip': {
                title: 'Edging Strip',
                description: 'Ideal to finish off exposed edges. (Laminate strip, not 2mm edging)',
                image: 'https://static.diy-kitchens.com/assets/images/laminateworktops/square-edge-k.jpg',
                rank: 12
            }
        },
        'Standard Laminate': {
            'Standard': {
                title: 'Standard',
                description: 'To be used with standard units finished on 1 x long length',
                image: 'https://static.diy-kitchens.com/assets/images/laminateworktops/standard-a.jpg',
                rank: 1
            },
            'Breakfast Bar': {
                title: 'Breakfast Bars',
                description: 'Double postformed, to create a breakfast bar or island unit.',
                image: 'https://static.diy-kitchens.com/assets/images/laminateworktops/standard-b.jpg',
                rank: 2
            },
            'Upstand': {
                title: 'Upstands',
                description: 'An ideal cost-efficient and hygienic alternative to tiling.',
                image: 'https://static.diy-kitchens.com/assets/images/laminateworktops/standard-c.jpg',
                rank: 3
            },
            'Back Panel': {
                title: 'Back Panels',
                description: 'Extend the decor behind your hob for a linear look.',
                image: 'https://static.diy-kitchens.com/assets/images/laminateworktops/standard-d.jpg',
                rank: 4
            },
            'Edging Strip': {
                title: 'Edging Strip',
                description: 'Ideal to finish off exposed edges. (Laminate strip, not 2mm edging)',
                image: 'https://static.diy-kitchens.com/assets/images/laminateworktops/square-edge-k.jpg',
                rank: 5
            }
        }
    };
    public products: any = [];

    public edgeProfileLink: string;
    public edgeProfile: string;
    public worktopLink: string;
    public worktopName: string;

    public worktopImage: string = '';
    public type = ProductType.WORKTOPS;
    public qty: number = 1;

    private routeSubscription: Subscription;

    private routeHistory: RouteHistory[] = [
        { title: 'Worktops', route: '/worktops' },
        { title: 'Duropal Laminate Worktops', route: '/laminate-worktops' }
    ];

    constructor(
        private basketService: BasketService,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private config: Config
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                if (route.params.section && this.config.isBrowser) {
                    window.setTimeout(() => {
                        this.navigationService.scrollToAnchor(route.params.section);
                    }, 100);
                }
                this.edgeProfileLink = route.params.edgeProfile;
                let edgeProfile = StringHelper.titleCase(StringHelper.dashToSpace(this.edgeProfileLink));
                this.edgeProfile = (edgeProfile === 'Standard') ? 'Standard Laminate' : edgeProfile;

                this.worktopLink = route.params.worktopLink;
                this.worktopName = StringHelper.titleCase(StringHelper.dashToSpace(route.params.worktopLink));

                let routeHistory = this.routeHistory.slice();
                routeHistory.push({
                    title: this.edgeProfile,
                    route: `/laminate-worktops/${this.worktopLink}/edge-profiles`
                });
                routeHistory.push({
                    title: this.worktopName,
                    route: `/laminate-worktops/options/${this.edgeProfileLink}/${this.worktopLink}/all-types`
                });

                this.navigationService.setNavigation({
                    title: `${this.worktopName} ${this.edgeProfile} Worktop`,
                    metaTags: [{ name: 'no-meta', content: 'no metadata' }],
                    routeHistory: routeHistory
                });

                this.getCatalogueData();
            }
        );
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }

    public addToBasket(product) {
        this.basketService.addItem(product, ProductType.WORKTOPS);
    }

    public getAnchor(anchorText): string {
        return StringHelper.spaceToDash(anchorText).toLowerCase();
    }

    private getCatalogueData() {
        this.catalogueService.getWorktopSubCategory(this.edgeProfile, this.worktopName)
            .then((worktops: any) => {
                if (worktops && worktops.length > 0) {
                    worktops.sort((a, b) => a.price - b.price);
                    this.worktopImage = worktops[0].media.image;

                    let products: any = {};

                    worktops.forEach((worktop) => {
                        worktop.cost = worktop.price;
                        worktop._cost = worktop.price;
                        worktop.cat_img = this.worktopImage;

                        const group = worktop.cat_type;
                        if (!this.sections[this.edgeProfile][group]) {
                            this.dialogService.error(this.constructor.name, 'Missing Section', group);
                        }

                        if (!products[group]) {
                            products[group] = {
                                name: group,
                                items: []
                            };
                        }

                        products[group].items.push(worktop);
                    });

                    products = Object.values(products);
                    products.sort((a, b) => {
                        const aRank = this.sections[this.edgeProfile][a.name]?.rank || 99;
                        const bRank = this.sections[this.edgeProfile][b.name]?.rank || 99;

                        if (aRank < bRank) {
                            return -1;
                        } else if (aRank > bRank) {
                            return 1;
                        }

                        return 0;
                    });

                    this.products = products;
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
