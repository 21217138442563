@import '/src/theme/variables';

component-create-customer {
    .mat-mdc-form-field-focus-overlay,
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: transparent;
    }

    .customer-registration-container {
        .customer-registration-form {            
            width: 100%;
            max-width: 595px;
            min-width: 300px;
        }

        .customer-registration-terms {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 30px;
            max-width: 420px;
            min-width: 300px;
            margin-top: 15px;
        }

        h3 {
            margin-top: 0;
        }

        .login-button {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .mdc-button {
                margin-left: 15px;
            }
        }

        .login-button-left {
            display: none;
        }

        .login-button-right {
            margin-left: auto;
        }

        .details {
            display: flex;
            margin: auto;

            .mat-mdc-form-field {
                flex-basis: 50%;
                margin-bottom: 10px;

                &:first-child {
                    padding-right: 10px;
                }

                + .mat-mdc-form-field {
                    padding-left: 10px;
                }
            }
        }

        .marketing-consent {
            flex-grow: 1;
            font-size: 0.75rem;
        }

        .actions {
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            margin-bottom: 15px;
            width: 100%;
            max-width: 420px;
        }

        p {
            margin-bottom: 15px;
        }

        .registration-form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .mat-mdc-form-field {
            width: 100%;
        }

        .mat-mdc-raised-button {
            margin-bottom: 20px;
            width: 90%;
        }

        input {
            height: 35px;
            line-height: 35px;

            &:-webkit-autofill { // sass-lint:disable-line no-vendor-prefixes
                background-color: transparent !important; // sass-lint:disable-line no-important
                -webkit-box-shadow: 0 0 0 1000px color('white') inset; // sass-lint:disable-line no-vendor-prefixes
            }
        }
    }

    @media (max-width: 992px) {
        .customer-registration-container {
            padding: 15px;

            .details {
                flex-direction: column;

                .mat-mdc-form-field {
                    &:first-child,
                    + .mat-mdc-form-field {
                        padding: 0;
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        .customer-registration-container {
            .registration-form {
                flex-direction: column;
            }

            .login-button-left {
                display: flex;
            }

            .login-button-right {
                display: none;
            }

            .customer-registration-form {
                min-width: initial;
            }

            .customer-registration-terms {
                padding: 0;
                max-width: 100%;
                min-width: initial
            }
        }
    }
}
