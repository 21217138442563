import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { StringHelper } from '@app/utilities/helpers';

import { CatalogueService } from '@app/services/catalogue';
import { ListingProduct, ProductType } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';

@Component({
    selector: 'page-sinks-and-taps-listing',
    templateUrl: './sinks-and-taps-listing.page.html',
    styleUrls: ['./sinks-and-taps-listing.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SinksAndTapsListingPage implements OnInit, OnDestroy {
    public type: ProductType = ProductType.SINK_AND_TAPS;
    public route: string = '/sinks-and-taps/sinks-and-taps/';

    public category: string; // Category to display ie (Base units or Dishwashers)
    public categoryDisplay: string;
    public subCategory: string;
    public subCategoryDisplay: string;
    public title: string;
    public sectionCats: any[] = []; // A list of all categories for this type (ie different unit types or appliance types)
    public headerCssClass: string;

    public products: any; // All products of this type (ie All Units or Appliances)
    public items: ListingProduct[]; // Products in this category - gets sent to the filters component
    public filteredItems: any[] = []; // Filtered products to show as results
    public noResults: boolean;

    public filterFields: any = [
        {
            title: 'Sub Category',  
            field: 'subCategory',
            rankField: 'subCatRank'
        },
        {
            title: 'Type',
            field: 'type'
        },
        {
            title: 'Finish',
            field: 'finish'
        },
        {
            title: 'Width',
            field: 'width',
            token: 'mm'
        },
        {
            title: 'Height',
            field: 'height',
            token: 'mm'
        },
        {
            title: 'Depth',
            field: 'depth',
            token: 'mm'
        }
    ];

    private routeHistory: RouteHistory[] = [
        { title: 'Sinks & Taps', route: '/sinks-and-taps' }
    ];

    private routeSubscription: Subscription;

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                this.category = route.params.category || null;
                this.categoryDisplay = (this.category) ? StringHelper.titleCase(StringHelper.dashToSpace(this.category)) : null;
                this.subCategory = route.params.subCategory || null;
                this.subCategoryDisplay = (this.subCategory) ? StringHelper.titleCase(StringHelper.dashToSpace(this.subCategory)) : null;
                if (this.categoryDisplay === 'Taps' && this?.subCategoryDisplay === 'Accessory') {

                }
                this.headerCssClass = (this.subCategory) ? `content-header-${this.category}-${this.subCategory}` : `content-header-${this.category}`;

                let routeHistory = this.routeHistory.slice();
                routeHistory.push({
                    title: this.categoryDisplay,
                    route: `/sinks-and-taps/${this.category}`
                });

                if (this.subCategory) {
                    routeHistory.push({
                        title: this.subCategoryDisplay,
                        route: `/sinks-and-taps/${this.category}/${this.subCategory}`
                    });

                    let filterField = this.filterFields.find((filter) => filter.field === 'subCategory');
                    if (filterField) {
                        filterField.preselected = this.subCategory
                    }
                }

                this.title = this.categoryDisplay;

                if (this.subCategoryDisplay) {
                    this.title = `${this.subCategoryDisplay} ${this.categoryDisplay}`;

                    if (this.title === 'Accessory Taps') {
                        this.title = 'Taps Accessories';
                    }
                }
                
                const metaCategory = (this.subCategoryDisplay) ? `${this.subCategoryDisplay} ${this.categoryDisplay}` : this.categoryDisplay;
                const metaCatagoryImages = {
                    inset: 'https://static.diy-kitchens.com/assets/images/products/sinks/cimageurl/UNO965.jpg',
                    belfast: 'https://static.diy-kitchens.com/assets/images/products/sinks/cimageurl/BAK710WH-1.jpg',
                    undermount: 'https://static.diy-kitchens.com/assets/images/products/sinks/cimageurl/IN-LAG400.jpg',
                    mixer: 'https://static.diy-kitchens.com/assets/images/products/misc/cimageurl/IN-TIBS.jpg',
                    "pull-out": 'https://static.diy-kitchens.com/assets/images/products/misc/cimageurl/IN-FOBS.jpg',
                    "quooker-boiling-water": 'https://static.diy-kitchens.com/assets/images/products/misc/cimageurl/3NRCHR.jpg',
                    spout: 'https://static.diy-kitchens.com/assets/images/products/misc/cimageurl/IN-SDC.jpg',
                    accessory: 'https://static.diy-kitchens.com/assets/images/products/misc/cimageurl/CWF.jpg'
                };

                this.navigationService.setNavigation({
                    title: `${this.title} | Sinks & Taps`,
                    metaTags: [
                        { name: 'no-meta', content: 'no metadata' },
                        { name: 'Description', content: `See our range of ${metaCategory} available at trade prices.` },
                        { property: 'og:locale', content: 'en_GB' },
                        { property: 'og:type', content: 'website' },
                        { property: 'og:site_name', content: 'DIY Kitchens' },
                        { property: 'og:url', content: 'Sinks & Taps - DIY Kitchens ' },
                        { property: 'og:title', content: 'Sinks & Taps - DIY Kitchens ' },
                        { property: 'og:description', content: `See our range of ${metaCategory} available at trade prices.` },
                        { property: 'og:image', content: metaCatagoryImages[this.subCategory] || metaCatagoryImages.inset },
                        { property: 'og:image:secure_url', content: metaCatagoryImages[this.subCategory] || metaCatagoryImages.inset },
                        { property: 'og:image:width', content: '600' },
                        { property: 'og:image:height', content: '400' }
                    ],
                    routeHistory: routeHistory
                });

                this.catalogueService.getSinksAndTaps()
                    .then((products: any) => {
                        this.products = products;
                        this.initProducts();
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            }
        );
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }

    public updateItems(filtered) {
        this.filteredItems = filtered;
        this.noResults = filtered.length === 0;
    }

    private initProducts() {
        if (this.products) {
            this.sectionCats = [];
            this.items = [];

            this.products.map((product) => {
                product._code = product.code;
                product._description = product.desc;
                product._details = product.info;
                product._finish = product.finish || null;
                product._cost = this.cost(product);

                if (product._cost > 0) {
                    this.items.push({
                        item: product
                    });

                    let catExists = this.sectionCats.find((opt) => opt.name === product.category);
                    if (!catExists) {
                        this.sectionCats.push({
                            name: product.category,
                            count: 1
                        });
                    } else {
                        catExists.count++;
                    }
                }
            });

            this.items = this.items.filter((entry) => {
                return entry.item.category.toLowerCase().replace(/ /g, '-') === this.category;
            });
        }
    }

    private cost(item) {
        return item.price || 0;
    }
}
