import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { StringHelper } from '@app/utilities/helpers';

import { NavigationService } from '@app/services/navigation';
import { NavigationRoute } from '@app/services/navigation/models';

@Component({
    selector: 'page-edge-profiles',
    templateUrl: './edge-profiles.page.html',
    styleUrls: ['./edge-profiles.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class EdgeProfilesPage implements OnInit, OnDestroy {
    private worktopLink: any;

    private routeSubscription: Subscription;

    constructor(
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                this.worktopLink = route.params.worktopLink || null;

                this.navigationService.setNavigation({
                    title: StringHelper.titleCase(StringHelper.dashToSpace(this.worktopLink)),
                    metaTags: [
                        { name: 'no-meta', content: 'no metadata' }
                    ]
                });

                this.navigationService.setRouteHistory([
                    { title: 'Worktops', route: '/worktops' },
                    { title: 'Duropal Laminate Worktops', route: '/laminate-worktops' },
                    { title: 'Edge Profiles', route: `/laminate-worktops/${this.worktopLink}/edge-profiles` }
                ]);
            }
        );
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }

    public allTypeRoute(isCurvedEdge: boolean) {
        if (isCurvedEdge) {
            return `/laminate-worktops/options/standard/${this.worktopLink}/all-types`;
        } else {
            return `/laminate-worktops/options/square-edge-laminate/${this.worktopLink}/all-types`;
        }
    }
}
