import { Directive, Input, HostListener } from '@angular/core';

import { DialogService } from '@app/services/dialog';

import { YouTubeVideoDialogComponent } from '@app/shared/dialogs/youtube-video';
import { MediaPlayerDialogComponent } from '@app/shared/dialogs/media-player';

@Directive({
    selector: '[media-thumb]',
    standalone: false
})
export class MediaThumbDirective {
    @Input('media-thumb') mediaURL: string;

    constructor(
        private dialogService: DialogService
    ) { }

    @HostListener('click') onClick() {
        if (this.mediaURL.match(/youtube/i)) {
            this.dialogService.custom(YouTubeVideoDialogComponent, {
                width: '80%',
                height: '54%',
                data: { url: this.mediaURL }
            });
        } else {
            this.dialogService.custom(MediaPlayerDialogComponent, {
                width: '80%',
                height: '80%',
                data: { mediaURL: this.mediaURL }
            });
        }
    }
}
