import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';

import { AboutCookiePolicyRouting } from './cookie-policy.routing';
import { AboutCookiePolicyPage } from './cookie-policy.page';

@NgModule({
    imports: [
        SharedModule,
        AboutCookiePolicyRouting
    ],
    declarations: [
        AboutCookiePolicyPage
    ]
})
export class AboutCookiePolicyPageModule { }
