@import '/src/theme/variables';

page-handle-detail {
    .product-details .details .title {
        margin-top: 0;
        margin-bottom: 5px;
        font-size: 21px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.1em;
        text-align: left;
    }

    .product-details .left .image {
        position: relative;
    }

    .product-details .details {

        .sub-title {
            font-weight: bold;
        }

        p {
            margin: 0;
            
        }

        .price {
            > * {
                text-align: right;
            }

            strong {
                font-size: 1.6rem;
                font-weight: 600;
            }
        }
    }
}
