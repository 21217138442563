@import '/src/theme/variables';

page-doors {
    section.content-contained {
        padding-bottom: 0;
    }

    .kitchen-blocks {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 30px;

        .kitchen {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 56px;

            .kitchen-image {
                overflow: hidden;
                object-fit: cover;
                flex: 0 0 50%;
            }

            h2 {
                font-size: 22px;
                font-family: font('opensans');
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: -1.6px;
            }

            p {
                &.large {
                    margin-top: 20px;
                    min-height: 0;
                    font-size: 1.125rem;
                }

                &.small {
                    flex-grow: 1;
                    font-size: 0.875rem;
                    font-weight: 100;
                    text-align: center;
                }
            }
        }

        .trustpilot {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #000032;
            width: 100%;
            height: 100%;
            color: color('white');
            text-align: center;
    
            h2 {
                color: color('white');
                font-size: 4.125rem;
                font-weight: bold;
                font-family: font('bitter');
                line-height: 4.125rem;
                text-transform: capitalize;
                letter-spacing: normal;
            }

            .rating {
                font-size: 3rem;
            }
    
            .trustpilot-green {
                color: #00B67A;
                font-size: 4.875rem;
                font-weight: bold;
                font-family: font('bitter');
                line-height: 4.875rem;
                letter-spacing: normal;
            }
        }

        @media (max-width: 992px) {
            display: block;

            .kitchen {
                margin-bottom: 56px;
            }
        }
    }
}