import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withFetch } from '@angular/common/http';

import { TimeoutInterceptor } from '@app/utilities/interceptors';
import { HttpApi } from './http.api';
import { FetchApi } from './fetch.api';

@NgModule({ imports: [], providers: [
        HttpApi,
        FetchApi,
        [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
        provideHttpClient(withInterceptorsFromDi(), withFetch())
    ] })
export class ApiModule { }
