import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';

import { DirectivesModule } from '@app/directives';
import { PipesModule } from '@app/pipes';

import { BasketComponentModule } from '@app/shared/components/basket/basket.component.module';

import { ProductCategoryComponent } from './category/product-category.component';
import { ProductImageGalleryComponent } from './image-gallery/product-image-gallery.component';
import { ProductImageGalleryDialogComponent } from './image-gallery/dialog/product-image-gallery.dialog.component';
import { ProductListingComponent } from './listing/product-listing.component';
import { ProductListingColumnComponent } from './listing-column/product-listing-column.component';

@NgModule({
    imports: [
        DesignModule,
        PipesModule,
        DirectivesModule,

        BasketComponentModule
    ],
    exports: [
        ProductCategoryComponent,
        ProductListingComponent,
        ProductListingColumnComponent,
        ProductImageGalleryComponent,
        ProductImageGalleryDialogComponent
    ],
    declarations: [
        ProductCategoryComponent,
        ProductListingComponent,
        ProductListingColumnComponent,
        ProductImageGalleryComponent,
        ProductImageGalleryDialogComponent
    ]
})
export class ProductComponentModule { }
