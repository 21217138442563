@import '/src/theme/variables';

page-about-which {
    section {
        &.content-contained {
            h2 {
                font-family: font('opensans');
            }

            .panel {
                .panel-left,
                .panel-right {
                    flex: auto;
                }
            }
        }
    }

    .which-logo {
        float: right;
        margin-left: 20px;
        margin-bottom: 20px;
        max-width: 30%;
    }

    em {
        font-weight: 400;
    }

    .button-wrapper {
        display: flex;
        margin-bottom: 56px;

        a {
            &+a {
                .mdc-button {
                    margin-left: 30px;
                }
            }
        }
    }
}