import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';

import { CustomerLookupComponent } from './customer-lookup.component';
import { CreateCustomerComponentModule } from '@app/shared/components/create-customer/create-customer.component.module';

@NgModule({
    imports: [
        DesignModule,
        CreateCustomerComponentModule
    ],
    exports: [
        CustomerLookupComponent
    ],
    declarations: [
        CustomerLookupComponent
    ]
})
export class CustomerLookupComponentModule { }
