<div class="cookie-consent" *ngIf="showCookieConsent">
    <div class="cookie-content">
        <p>This website uses cookies to ensure you get the best possible experience. <a routerLink="/cookie-policy" title="Cookie Policy">Learn more</a></p>

        <button mat-stroked-button color="secondary" (click)="markAsRead()">
            <span>Got It</span>
        </button>
    </div>
</div>

<div class="footer-container" id="footer" *ngIf="true">
    <div class="brands">
        <div class="logos">
            <a routerLink="/about-diy-kitchens/our-partners" fragment="blum" title="Blum" class="blum">
                <img src="https://static.diy-kitchens.com/assets/images/partners/logos-coloured/A_Logos_Blum.svg"
                    alt="Blum" height="26" width="67" loading="lazy"/>
            </a>
            <a routerLink="/about-diy-kitchens/our-partners" fragment="Kessebohmer" title="Kessebohmer" class="kessebohmer">
                <img src="https://static.diy-kitchens.com/assets/images/partners/logos-coloured/A_Logos_Kessebohmer.svg"
                    alt="Kessebohmer" height="14" width="97" loading="lazy"/>
            </a>
            <a routerLink="/about-diy-kitchens/our-partners" fragment="AEG" title="AEG" class="aeg">
                <img src="https://static.diy-kitchens.com/assets/images/partners/logos-coloured/A_Logos_AEG.svg"
                    alt="AEG" height="14" width="43" loading="lazy" />
            </a>
            <!-- <a routerLink="/about-diy-kitchens/our-partners" fragment="Zanussi" title="Zanussi" class="zanussi">
                <img src="https://static.diy-kitchens.com/assets/images/partners/logos-coloured/A_Logos_Zanussi.svg"
                    alt="Zanussi" height="26" width="115" loading="lazy"/>
            </a> -->
            <a routerLink="/about-diy-kitchens/our-partners" fragment="Dekton" title="Dekton" class="dekton">
                <img src="https://static.diy-kitchens.com/assets/images/partners/logos-coloured/A_Logos_Dekton.svg"
                    alt="Dekton" height="15" width="96" loading="lazy"/>
            </a>
            <a routerLink="/about-diy-kitchens/our-partners" fragment="cda" title="CDA" class="cda">
                <img src="https://static.diy-kitchens.com/assets/images/partners/logos-coloured/A_Logos_CDA.svg"
                    alt="CDA" height="16" width="54" loading="lazy"/>
            </a>
            <a routerLink="/about-diy-kitchens/our-partners" fragment="Innova" title="Innova" class="innova">
                <img src="https://static.diy-kitchens.com/assets/images/partners/logos-coloured/A_Logos_Innova.svg"
                    alt="Innova" height="16" width="75" loading="lazy" />
            </a>
            <!-- <a routerLink="/about-diy-kitchens/our-partners" fragment="beko" title="Beko" class="beko">
                <img src="https://static.diy-kitchens.com/assets/images/partners/logos-coloured/A_Logos_Beko.svg"
                    alt="Beko" height="24" width="42" loading="lazy"/>
            </a> -->
            <a routerLink="/about-diy-kitchens/our-partners" fragment="Silestone" title="Silestone" class="silestone">
                <img src="https://static.diy-kitchens.com/assets/images/partners/logos-coloured/A_Logos_Silestone.svg"
                    alt="Silestone" height="13" width="96" loading="lazy"/>
            </a>
            <a routerLink="/about-diy-kitchens/our-partners" fragment="Duropal" title="Duropal" class="duropal">
                <img src="https://static.diy-kitchens.com/assets/images/partners/logos-coloured/A_Logos_Duropal.svg"
                    alt="Duropal" height="17" width="86" loading="lazy"/>
            </a>
        </div>
    </div>

    <div class="links-container">
        <div class="links">
            <strong>Can We Help?</strong>
            <a routerLink="/delivery-information" title="Delivery Information">Delivery Information</a>
            <a routerLink="/support" title="7 days a waeek after sales support">After Sales Support</a>
            <a routerLink="/support" title="Pre Sales Support">Pre Sales Support</a>
            <a routerLink="/kitchens" title="See our range of kitchens">Our Kitchen Range</a>
        </div>
        <div class="links">
            <a routerLink="/about-diy-kitchens" title="About Us"><strong>About Us</strong></a>
            <a routerLink="/about-diy-kitchens/history" title="Our History">Our History</a>
            <a routerLink="/about-diy-kitchens/price-and-quality-comparison" title="Price & Quality Comparison">Price & Quality Comparison</a>
            <a routerLink="/about-diy-kitchens/showroom" title="Kitchen Showroom">Kitchen Showroom</a>
            <ng-container *ngIf="plannerURL">
                <a href="{{plannerURL}}" target="_blank" title="Online Kitchen Planner">Online Kitchen Planner</a>
            </ng-container>
            <a routerLink="/kitchen-sales" title="Kitchen Sales & Offers">Kitchen Sales & Offers</a>
            <a href="https://jobs.diy-kitchens.com/" target="_blank" title="Jobs at DIY Kitchens">Jobs at DIY Kitchens</a>
        </div>
        <div class="links">
            <strong>DIY Kitchens</strong>
            <a href="https://www.facebook.com/diykitchens1" rel="noreferrer" target="_blank" title="Find us on Facebook">Find us on Facebook</a>
            <a href="https://www.twitter.com/DIY_Kitchens" rel="noreferrer" target="_blank" title="Follow us on Twitter">Follow us on Twitter</a>
            <a href="https://www.youtube.com/user/diywebmaster/featured" rel="noreferrer" target="_blank" title="View our YouTube Channel">View our YourTube Channel</a>
            <a href="https://www.pinterest.co.uk/diykitchens/real-customer-kitchens/" rel="noreferrer" target="_blank" title="Follow us on Pinterest">Follow us on Pinterest</a>
            <a href="https://www.instagram.com/diykitchens/" rel="noreferrer" target="_blank" title="Follow us on Instagram">Follow us on Instagram</a>
        </div>
        <div class="links">
            <strong>Help & Support</strong>
            <a routerLink="/terms-and-conditions" title="Terms & Conditions">Terms &amp; Conditions</a>
            <a routerLink="/privacy-policy" title="Privacy Policy">Privacy Policy</a>
            <a routerLink="/cookie-policy" title="Cookie Policy">Cookie Policy</a>
            <a href="https://advice.diy-kitchens.com/customer-questions/" target="_blank" title="FAQs">FAQs</a>
            <a routerLink="/shop-online-and-stay-safe" title="Shop Safe">Shop Online & Stay Safe</a>
        </div>
    </div>
    <div class="overview">
        <div class="point">
            <div class="achievement-logo energy">
                <img src="https://static.diy-kitchens.com/assets/images/footer/better-for-our-planet-logo.svg" alt="Better Planet Logo" loading="lazy"/>
            </div>
            <p>HALF OF OUR ENERGY IS NOW CERTIFIED AS RENEWABLE</p>
        </div>
        <!-- <div class="point">
            <a routerLink="/about-diy-kitchens/which-best-buy">
                <div class="achievement-logo which">
                    <img src="https://static.diy-kitchens.com/assets/images/homepage/icon_logobanner_which2023.svg" alt="Which Best Buy Logo" loading="lazy"/>
                </div>
                <p>BEST BUY IN LAB TESTS FOR INNOVA UNITS</p>
            </a>
        </div> -->
        <div class="point">
            <div class="achievement-logo ombudsman">
                <img src="https://static.diy-kitchens.com/assets/images/footer/furniture-ombudsman-logo-v2.svg" alt="Furniture & Home Improvement Ombudsman Logo" loading="lazy"/>
            </div>
            <p>MEMBER OF THE FURNITURE OMBUDSMAN</p>
        </div>
        <div class="point">
            <div class="achievement-logo trustpilot">
                <img src="https://static.diy-kitchens.com/assets/images/footer/trustpilot-logo.svg" alt="Trustpilot Logo" loading="lazy"/>
            </div>
            <p>RATED EXCELLENT BY OUR CUSTOMERS</p>
        </div>
        <div class="point">
            <div class="achievement-logo britain">
                <img src="https://static.diy-kitchens.com/assets/images/footer/made-in-britain-logo.svg" alt="Made in Britain" loading="lazy"/>
            </div>
            <p>FAMILY MADE IN BRITAIN FOR OVER 30 YEAR</p>
        </div>
    </div>
    <div class="flex copyright-container">
        <div class="copyright small">
            &#x24B8; Copyright 2003 - 2020 . DIY Kitchens. All rights reserved. Diy Kitchens is a trading name of
            Ultima Furniture Systems Ltd, a company registered in England and Wales under Company Registration No:
            01918871, VAT Registration No: 436502956. Registered office: Ultima Furniture Systems Limited, Unit 26
            Lidgate Crescent, Langthwaite business park, South Kirkby , West Yorkshire,
            <a class="small"
                href="https://maps.google.co.uk/maps?q=wf93nr&hl=en&ll=53.588345,-1.305805&spn=0.0114,0.033023&sll=53.741446,-1.686054&sspn=0.726922,2.113495&t=h&hnear=South+Kirkby+WF9+3NR,+United+Kingdom&z=16&layer=c&cbll=53.588345,-1.305805&panoid=ekl3KJlHFoaCzvED2drN3Q&cbp=12,124.15,,0,13.8"
                target="_blank" rel="noreferrer">WF9 3NR</a>.
        </div>
        <div class="flexible-friends" alt="We except these payment methods" width="100%">
        </div>
    </div>
</div>
