<mat-dialog-content>
    <div class="container-fluid">
        <ng-container *ngIf="!type">
            <h5>Select a cut out type to add</h5>
            <div class="options">
                <button mat-raised-button color="primary" (click)="cutout('hobs')">Hob cut out</button>
                <button mat-raised-button color="primary" (click)="cutout('range')">Range cooker</button>
                <button mat-raised-button color="primary" (click)="cutout('sink')">Sink cut out</button>
                <button mat-raised-button color="primary" (click)="cutout('misc')">Miscellaneous</button>
            </div>
        </ng-container>

        <div *ngIf="type === 'range'" class="ranges">
            <button mat-stroked-button color="secondary" (click)="addRange(600)">
                <em>600mm Freestanding cooker</em>
            </button>
            <button mat-stroked-button color="secondary" (click)="addRange(900)">
                <em>900mm Range cooker</em>
            </button>
            <button mat-stroked-button color="secondary" (click)="addRange(1000)">
                <em>1000mm Range cooker</em>
            </button>
            <button mat-stroked-button color="secondary" (click)="addRange(1100)">
                <em>1100mm Range cooker</em>
            </button>
            <button mat-stroked-button color="secondary" (click)="addRange(1200)">
                <em>1200mm Range cooker</em>
            </button>
        </div>

        <ng-container *ngIf="type === 'hobs'">
            <h5>Select your hob cut out type</h5>
            <div class="options">
                <div class="option" *ngFor="let opt of hobs" (click)="add(opt, 'hobextras')">
                    <img [src]="'https://static.diy-kitchens.com/worktops/icons/' + opt.img" [alt]="opt.product_name"/>
                    <div class="details">
                        <em>{{ opt.product_name }}</em>
                        <p><span [innerHTML]="opt.price | currencyFormat"></span></p>
                    </div>
                </div>
            </div>
            <div class="actions">
                <button mat-stroked-button color="secondary" (click)="add()">
                    <mat-icon>not_interested</mat-icon>
                    <em>Cancel</em>
                </button>
            </div>
        </ng-container>

        <ng-container *ngIf="type === 'hobextras'">
            <h5>Do you want to add hob bars?</h5>
            <div class="options">
                <div class="option" *ngFor="let opt of hobExtras" (click)="add(opt)">
                    <img [src]="'https://static.diy-kitchens.com/worktops/icons/' + opt.img" [alt]="opt.product_name"/>
                    <div class="details">
                        <em>{{ opt.product_name }}</em>
                        <p><span [innerHTML]="opt.price | currencyFormat"></span></p>
                    </div>
                </div>
            </div>
            <div class="actions">
                <button mat-stroked-button color="secondary" (click)="add()">
                    <mat-icon>not_interested</mat-icon>
                    <em>No hob bars</em>
                </button>
            </div>
        </ng-container>

        <ng-container *ngIf="type === 'sink'">
            <h5>Select your sink cut out type</h5>
            <div class="options">
                <div class="option" *ngFor="let opt of sinks" (click)="add(opt, 'drainers')">
                    <img [src]="'https://static.diy-kitchens.com/worktops/icons/' + opt.img" [alt]="opt.product_name"/>
                    <div class="details">
                        <em>{{ opt.product_name }}</em>
                        <p><span [innerHTML]="opt.price | currencyFormat"></span></p>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="type === 'drainers'">
            <h5>Do you want to add drainer grooves?</h5>
            <div class="options">
                <div class="option" *ngFor="let opt of drainers" (click)="add(opt, 'sinkextras')">
                    <img [src]="'https://static.diy-kitchens.com/worktops/icons/' + opt.img" [alt]="opt.product_name"/>
                    <div class="details">
                        <em>{{ opt.product_name }}</em>
                        <p><span [innerHTML]="opt.price | currencyFormat"></span></p>
                    </div>
                </div>
            </div>
            <div class="actions">
                <button mat-stroked-button color="secondary" (click)="add()">
                    <mat-icon>not_interested</mat-icon>
                    <em>No drainer grooves</em>
                </button>
            </div>
        </ng-container>

        <ng-container *ngIf="type === 'sinkextras'">
            <h5>Do you want to add tap holes?</h5>
            <div class="options">
                <div class="option" *ngFor="let opt of sinkExtras" (click)="add(opt)">
                    <img [src]="'https://static.diy-kitchens.com/worktops/icons/' + opt.img" [alt]="opt.product_name"/>
                    <div class="details">
                        <em>{{ opt.product_name }}</em>
                        <p><span [innerHTML]="opt.price | currencyFormat"></span></p>
                    </div>
                </div>
            </div>
            <div class="actions">
                <button mat-stroked-button color="secondary" (click)="add()">
                    <mat-icon>not_interested</mat-icon>
                    <em>No tap holes</em>
                </button>
            </div>
        </ng-container>

        <ng-container *ngIf="type === 'misc'">
            <h5>Select your misc cut out type</h5>
            <div class="options">
                <div class="option" *ngFor="let opt of misc" (click)="add(opt)">
                    <img [src]="'https://static.diy-kitchens.com/worktops/icons/' + opt.img" [alt]="opt.product_name"/>
                    <div class="details">
                        <em>{{ opt.product_name }}</em>
                        <p><span [innerHTML]="opt.price | currencyFormat"></span></p>
                    </div>
                </div>
            </div>
            <div class="actions">
                <button mat-stroked-button color="secondary" (click)="add()">
                    <mat-icon>not_interested</mat-icon>
                    <em>Cancel</em>
                </button>
            </div>
        </ng-container>
    </div>
</mat-dialog-content>