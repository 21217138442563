import { Directive, ElementRef, Input, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Config } from '@app/config';

@Directive({
    selector: '[imageSrc]',
    standalone: false
})
export class ImageDirective implements OnInit, OnChanges {
    @Input('imageSrc') url: string;
    @Input('altSrcs') altSrcs: string[];

    private images: string[] = [];
    private testedImages: string[] = [];
    private noImage = `${this.config.api.endpoints.cdn}/assets/images/products/misc/cimageurl/noimage.jpg`;

    private loading: boolean = false;
    private loaded: boolean = false;

    constructor(
        private config: Config,
        private element: ElementRef,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit() {
        this.populateImages();
        this.setImg();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('url' in changes && !changes?.url.firstChange) {
            this.loaded = false;
            this.loading = false;

            this.populateImages();
            this.setImg();        
        }
    }

    private populateImages() {
        this.images = [];

        if (this.url && typeof this.url === 'string') {
            this.images.push(this.url);
        }

        if (this.altSrcs && this.altSrcs instanceof Array && this.altSrcs.length) {
            this.altSrcs.forEach((url) => {
                if (url) {
                    this.images.push(url);
                }
            });
        }

        this.images.push(this.noImage);
    }

    private setImg(hasError: boolean = false): void {
        if (hasError || (!this.loading && !this.loaded) && this.images.length) {
            this.loading = true;

            const url = this.images.shift();
            if (this.config.isBrowser && url) {
                this.testedImages.push(url);
                const img = this.element.nativeElement;
                // img.crossOrigin = 'anonymous';
                img.onload = () => {
                    if (img.nodeName === 'IMG') {
                        if (img.src !== url) {
                            img.src = url;
                        }
                    } else {
                        img.style.backgroundImage = `url('${url}')`;
                    }
                };

                img.onerror = () => {
                    if (url === this.noImage) {
                        this.loaded = true;
                        this.loading = false;
                    } else {
                        this.setImg(true);
                    }
                };

                img.src = url;
            }
        }
    }
}
