<ng-container *ngIf="!editingBasket">
    <div [ngClass]="'banner-container-' + locationId" [ngStyle]="style">
        <img *ngIf="currentImageUrl" [cdn]="currentImageUrl" (click)="handleClick()" [alt]="banner.name" />
    </div>
</ng-container>
<ng-container *ngIf="editingBasket">
    <a routerLink="/basket">
        <div [ngClass]="'banner-container-' + locationId" class="editing-order">
            You are editing an order - click here to return to your basket to confirm or discard changes <br />
            <div class="cut-off-timer" *ngIf="cutoffTimer">
                <span><span class="cut-off-message">Time remaining to edit order: </span>{{cutoffTimer}}</span>
            </div>
        </div>
    </a>
</ng-container>
