import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { Config } from '@app/config';
import { DateHelper, StringHelper } from '@app/utilities/helpers';

import { AuthCustomerService, AuthService } from '@app/services/auth';
import { BasketService } from '@app/services/basket';
import { BasketItem, BasketItemHanding, BasketNote, BasketNoteAuthor, DrawerBoxUpgrade, DrawerBoxUpgradeCost, IBasketDeliveryType, IBasketUpdateItem, PanDrawerUpgrade, PanDrawerUpgradeCost } from '@app/services/basket/models';
import { BasketError } from '@app/services/basket/models';
import { Basket } from '@app/services/basket/models/basket.model';
import { CatalogueService } from '@app/services/catalogue';
import { ProductType, HandingType } from '@app/services/catalogue/models';
import { CustomerService } from '@app/services/customer';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { RouteHistory } from '@app/services/navigation/models';
import { CheckoutService } from '@app/services/checkout';
import { DeliveryType } from '@app/services/checkout/models';

import { NotesDialogComponent } from '@app/shared/dialogs/notes';
import { ChangeCabinetOptionsDialogComponent } from '@app/shared/dialogs/change-cabinet-options';
import { ChangeStyleDialogComponent } from '@app/shared/dialogs/change-style';

import { MatDrawer } from '@angular/material/sidenav';
import { IAccessory, ICabinet } from '@app/services/catalogue/models/product.models';

import { TransferBasketDialogComponent } from '@app/shared/dialogs/transfer-basket';
import { IWarning } from './models';
import { DimensionsInputCalculatorDialogComponent } from '@app/shared/dialogs/dimensions-input-calculator';

@Component({
    selector: 'page-basket',
    templateUrl: './basket.page.html',
    styleUrls: ['./basket.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class BasketPage implements OnInit, OnDestroy {
    @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;

    public globalHeadingTags;
    public items: BasketItem[];
    public basket: Basket;
    public sections: any = [];
    private basketSubscription: Subscription;
    private authSubscription: Subscription;
    private catalogueSubscription: Subscription;
    public orderCosts: any = {};
    public deliveries: any[] = [];

    public editingBasketName: boolean = false;
    public checkOutBasket: boolean = false;
    public validated: boolean = false;
    public busy: boolean = true;

    public Math = Math;

    public deliveryTypes: IBasketDeliveryType[] = [];
    private normalDeliveryTypes: IBasketDeliveryType[];
    public selectedDeliveryType: IBasketDeliveryType;
    public selectedDeliveryLevel: DeliveryType;
    private deliveryVariant;

    public totalCost: any;

    public now = DateHelper.now();
    public basketError;
    public BasketError = BasketError;
    private customerHasPreviousOrders = false;
    public minOrderValue = this.config.payments.minOrderValue;

    private carcaseColours: any;
    public loadBasketHistory: boolean = false;

    public rangeNames = {};

    public deliveriesLoaded: boolean = false;
    public deliveriesLoading: boolean = false;

    public Handing = BasketItemHanding;

    public costs: any = {};
    public reachedCutOff: boolean = false;

    public BasketNoteAuthor = BasketNoteAuthor;

    public PanDrawerUpgrade = PanDrawerUpgrade;
    public panDrawerUpgrade: PanDrawerUpgrade;
    public PanDrawerUpgradeCost = PanDrawerUpgradeCost;
    public DrawerBoxUpgrade = DrawerBoxUpgrade;
    public drawerBoxUpgrade: DrawerBoxUpgrade;
    public DrawerBoxUpgradeCost = DrawerBoxUpgradeCost;

    public editableItemTypes: string[] = [
        ProductType.CABINETS,
        ProductType.APPLIANCES,
        ProductType.SINK_AND_TAPS,
        ProductType.HANDLES,
        ProductType.ACCESSORIES
    ];
    public showIndividualPrices: string[] = [
        ProductType.SAMPLE_DOORS,
        ProductType.SAMPLE_WORKTOP,
        ProductType.DOORS,
        ProductType.CABINETS,
        ProductType.APPLIANCES,
        ProductType.SINK_AND_TAPS,
        ProductType.HANDLES,
        ProductType.ACCESSORIES,
        ProductType.WORKTOPS
    ];

    public HandingType = HandingType;
    public ProductType = ProductType;

    public isCarcaseMaterial = this.catalogueService.isCarcaseMaterial;
    public isCarcaseColoured = this.catalogueService.isCarcaseColoured;

    private basketErrors = {
        minimumOrderAmountNotReached: false,
        availability: {
            style: true,
            discontinued: true
        },
        noUnitInOrder: false,
    };

    public basketWarnings: IWarning[] = [
        {
            id: 'unit-heights',
            image: 'https://static.diy-kitchens.com/images/misc/warnings-unit_heights.png',
            title: 'Mismatching Cabinet Heights',
            label: 'Please be advised that your basket contains units with mismatching heights. If this is intentional dismiss this warning before proceeding to checkout.',
            shouldDisplay: () => this.basketContainsMismatchingCabinetHeights(),
            isDismissed: false
        },
        {
            id: 'mixed-ranges',
            image: 'https://static.diy-kitchens.com/images/misc/warnings-mixed_ranges.png',
            title: 'Mixed Ranges',
            label: 'Please be advised that your basket contains kitchen cabinets from more than 1 range. If this is intentional please dismiss this warning.',
            shouldDisplay: () => this.basketContainsMultipleRanges(),
            isDismissed: false
        }
    ];

    constructor(
        private config: Config,
        private authService: AuthService,
        private authCustomerService: AuthCustomerService,
        private basketService: BasketService,
        private catalogueService: CatalogueService,
        private checkoutService: CheckoutService,
        private customerService: CustomerService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.catalogueSubscription = this.catalogueService.activeRangeLoaded$.subscribe(
            (activeRange) => {
                if (activeRange) {
                    this.catalogueService.getRanges()
                        .then((ranges) => {
                            if (ranges) {
                                ranges.forEach((range) => {
                                    this.rangeNames[range.range_id] = range.name;
                                });
                            }

                            if (!this.authSubscription) {
                                this.authSubscription = this.authService.authentication.subscribe(
                                    (isAuthed) => {
                                        if (isAuthed) {
                                            this.confirmCustomerHasPreviousOrders();
                                        }
                                    }
                                );
                            }

                            this.getcarcaseColours();

                            if (!this.basketSubscription) {
                                this.basketSubscription = this.basketService.basket$.subscribe({
                                    next: (basket: Basket) => {
                                        if (basket) {
                                            this.panDrawerUpgrade = basket.panDrawerUpgrade;
                                            this.drawerBoxUpgrade = basket.drawerBoxUpgrade;

                                            if (!this.validated) {
                                                this.validated = true;
                                                this.basketService.validateBasket()
                                                    .catch((error) => this.dialogService.error(this.constructor.name, error));
                                            } else {
                                                this.setBasket(basket);
                                            }
                                        }
                                    },
                                    error: (error) => this.dialogService.error(this.constructor.name, error)
                                });
                            }
                        })
                        .catch((error) => {
                            this.dialogService.error(this.constructor.name, error);
                        });
                }
            }
        );
    }

    ngOnDestroy() {
        if (this.catalogueSubscription) {
            this.catalogueSubscription.unsubscribe();
            this.catalogueSubscription = null;
        }

        if (this.basketSubscription) {
            this.basketSubscription.unsubscribe();
            this.basketSubscription = null;
        }

        if (this.authSubscription) {
            this.authSubscription.unsubscribe();
            this.authSubscription = null;
        }
    }

    public login() {
        this.authService.login();
    }

    public isStaff() {
        return this.authCustomerService.isStaff;
    }

    private setBasket(basket) {
        this.busy = false;
        if (basket.cutOffDate && basket.cutOffDate < DateHelper.now()) {
            this.reachedCutOff = true;
        } else {
            this.reachedCutOff = false;
        }

        this.basket = basket;

        if (this.basket.orderNumber) {
            let routeHistory: RouteHistory[] = [{ title: 'Order', route: '/basket' }];

            this.navigationService.setNavigation({
                title: 'Your order',
                metaTags: [{ name: 'no-meta', content: 'no metadata' }],
                routeHistory: routeHistory
            });
        } else {
            let routeHistory: RouteHistory[] = [{ title: 'Basket', route: '/basket' }];

            this.navigationService.setNavigation({
                title: 'Your basket',
                metaTags: [{ name: 'no-meta', content: 'no metadata' }],
                routeHistory: routeHistory
            });
        }

        if (basket.orderNumber && basket.id) {
            this.basketService.paymentDetailsFromApi(basket.uuid)
                .then((paymentHistory) => {
                    this.basket.paymentHistory = paymentHistory;

                    this.costs = this.checkoutService.calculateOrderCost(basket.cost, basket.deliveryCost, basket.deliverySurcharge, paymentHistory);
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        }

        this.getDeliveryType();

        this.basketService.createBasketSections(basket.items)
            .then((sections) => {
                this.sections = sections;
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));

        if (this.reachedCutOff) {
            this.basketLockedPrompt();
        }

        this.setBasketErrors();
    }

    public setBasketErrors() {
        // this.basketErrors.minimumOrderAmountNotReached = !this.basketService.isSampleOnlyBasket(this.basket) &&
        //     ((this.basket.cost * this.config.payments.vatRate) < this.config.payments.minOrderValue) &&
        //     !this.customerHasPreviousOrders;

        if (!this.basket) {
            return;
        }

        this.basketErrors.availability = this.basketService.hasUnavailableItems();
        this.basketErrors.noUnitInOrder = this.noUnitInOrder();

        this.basketErrors.minimumOrderAmountNotReached = !this.basketErrors.noUnitInOrder &&
            ((this.basket.cost * this.config.payments.vatRate) < this.config.payments.minOrderValue) &&
            !this.customerHasPreviousOrders;
    }

    public checkBasketForErrors(error?: BasketError) {
        if (!error) {
            return !this.basketErrors.availability.style ||
                !this.basketErrors.availability.discontinued ||
                !(!this.basket.cutOffDate ? true : this.basket.cutOffDate > DateHelper.now()) ||
                this.basketErrors.minimumOrderAmountNotReached ||
                this.activeBasketWarnings();
        } else {
            if (!this.basketErrors.availability.style && error === BasketError.CONTAINS_OUT_OF_RANGE_ITEM) {
                return true;
            } else if (!this.basketErrors.availability.discontinued && error === BasketError.CONTAINS_UNAVAILABLE_ITEM) {
                return true;
            } else if (!(!this.basket.cutOffDate ? true : this.basket.cutOffDate > DateHelper.now()) && error === BasketError.CUTOFF_DATE_REACHED) {
                return true;
            } else if (this.basketErrors.minimumOrderAmountNotReached &&
                error === BasketError.MINIMUM_ORDER_AMOUNT_NOT_REACHED
            ) {
                return true;
            } else if (error === BasketError.UNACKNOWELDGED_WARNINGS) {
                return this.activeBasketWarnings();
            } else {
                return false;
            }
        }
    }

    public activeBasketWarnings(): boolean {
        return this.basketWarnings.some(warning =>
            warning.shouldDisplay() && (!warning.isDismissed || warning.isAnimating)
        );
    }

    public dismissWarning(warning: IWarning): void {
        warning.isDismissed = true;
        warning.isAnimating = true;
        if (this.config.isBrowser) {
            setTimeout(() => {
                warning.isAnimating = false;
                warning.shouldDisplay = () => false;
            }, 500);
        }
    }

    private basketContainsMismatchingCabinetHeights(): boolean {
        if (this.basket && Array.isArray(this.basket?.items) && this.basket.items.length) {
            const baseUnits = this.basket.items.filter((basketItem) =>
                basketItem.group === ProductType.CABINETS &&
                basketItem.category === 'Base Units' &&
                basketItem.subCategory !== 'Sink Unit'
            );
            if (Array.isArray(baseUnits) && baseUnits.length) {
                const baseUnitHeight: number = baseUnits[0].height;
                const differentHeightUnits = baseUnits.some((baseUnit) => baseUnit.height !== baseUnitHeight);
                if (differentHeightUnits) {
                    return true;
                }
            }
            const wallUnits = this.basket.items.filter((basketItem) =>
                basketItem.group === ProductType.CABINETS &&
                basketItem.category === 'Wall Units' &&
                basketItem.subCategory !== 'Top Boxes'
            );
            if (Array.isArray(wallUnits) && wallUnits.length) {
                const wallUnitHeight: number = wallUnits[0].height;
                const differentHeightUnits = wallUnits.some((wallUnit) => wallUnit.height !== wallUnitHeight);
                if (differentHeightUnits) {
                    return true;
                }
            }
        }
        return false;
    }

    public basketContainsMultipleRanges(): boolean {
        if (this.basket && Array.isArray(this.basket?.items) && this.basket.items.length) {
            const cabinets = this.basket.items.filter((basketItem) =>
                basketItem.group === ProductType.CABINETS
            );
            if (Array.isArray(cabinets) && cabinets.length) {
                const rangeId: string = cabinets[0].rangeId;
                const differentRanges = cabinets.some((cabinet) => cabinet.rangeId !== rangeId);
                if (differentRanges) {
                    return true;
                }
            }
        }
        return false;
    }

    private confirmCustomerHasPreviousOrders() {
        if (this.isLoggedIn()) {
            this.customerService.customerHasPreviousOrders()
                .then((response) => {
                    if (response) {
                        this.customerHasPreviousOrders = true;
                    }
                    this.setBasketErrors();
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        }
    }

    private getDeliveryType() {
        if (this.deliveriesLoading) {
            return;
        }

        let queries = [];
        this.deliveriesLoading = true;
        if (!this.deliveryVariant) {
            let deliveryVariant = this.basketService.deliveryVariation();
            if (deliveryVariant != this.deliveryVariant) {
                queries.push(this.checkoutService.getDeliveryTypes(deliveryVariant));
            }
        }

        Promise.all(queries)
            .then(([normalDeliveryTypes]) => {
                if (normalDeliveryTypes) {
                    this.normalDeliveryTypes = normalDeliveryTypes;

                    this.deliveriesLoaded = true;
                    this.deliveriesLoading = false;

                    this.fixDeliveryTypeBasedOnBasket();
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    private fixDeliveryTypeBasedOnBasket() {
        this.deliveryTypes = this.normalDeliveryTypes;

        let deliveryType: IBasketDeliveryType;
        let deliveryChanged = false;

        if (!this.basket.deliveryLevel) {
            this.selectedDeliveryLevel = this.deliveryTypes.slice().shift().level;
            deliveryChanged = true;
        } else {
            this.selectedDeliveryLevel = this.basket.deliveryLevel;
        }

        deliveryType = this.deliveryTypes.find((option) => option.level === this.selectedDeliveryLevel);

        if (!deliveryType) {
            this.selectedDeliveryLevel = this.deliveryTypes[0].level;
            deliveryType = this.deliveryTypes.find((option) => option.level === this.selectedDeliveryLevel);
            deliveryChanged = true;
        }

        if (deliveryChanged) {
            this.setDeliveryLevel(this.selectedDeliveryLevel, deliveryType.cost);
        }

        this.totalCost = this.basket.cost + ((deliveryType) ? deliveryType.cost : 0);
    }

    public setDeliveryLevel(level: DeliveryType, cost: number) {
        if (this.basket.items && this.basket.items.length) {
            this.basketService.setDeliveryLevel(level, cost);
        }
    }

    public selectDeliveryOption(event: any) {
        const deliveryType = this.deliveryTypes.find((deliveryType) => deliveryType.level === event.value);
        this.totalCost = this.basket.cost + ((deliveryType) ? deliveryType.cost : 0);

        this.setDeliveryLevel(deliveryType.level, deliveryType.cost);
    }

    public selectedDeliveryCost() {
        return this.basket.deliveryCost || null;
    }

    private getDeliveryCost() {
        const deliveryType = this.deliveryTypes.find((option) => option.level === this.selectedDeliveryLevel);
        if (!deliveryType) {
            this.selectedDeliveryType = (this.deliveryTypes && this.deliveryTypes.length) ? this.deliveryTypes.slice().shift() : null;
        } else {
            this.selectedDeliveryType = deliveryType;
        }

        return (this.selectedDeliveryType) ? this.selectedDeliveryType.cost : 0;
    }

    public itemPrice(item: BasketItem) {
        if (StringHelper.toBoolean(item.softClose)) {
            return (item.cost || item.cab_price || 0) - (item?._cabinet?.hingecost || item.hingeCost);
        } else {
            return (item.cost || item.cab_price || 0);
        }
    }

    public editBasketName(name?: string) {
        if (typeof name === 'undefined') {
            this.editingBasketName = true;
        } else if (name === null) {
            this.editingBasketName = false;
        } else if (name.length > 3) {
            this.basketService.rename(name);
            this.editingBasketName = false;
        }
    }

    public isLoggedIn() {
        return this.authService.isAuthenticated;
    }

    public checkoutMessage(): string {
        return this.basket.orderNumber ? 'Confirm Order Changes' : 'Checkout Order';
    }

    public proceedToCheckout() {
        if (!this.busy) {
            this.busy = true;
            let deliveryNote = this.basket.notes.find((note) => note.isDeliveryNote);
            let deliveryType: IBasketDeliveryType = null;

            if (!this.selectedDeliveryLevel) {
                // TODO: not sure why we are setting this here when it is handled in the checkout?
                let standardDelivery = false;
                if ([DeliveryType.STANDARD, DeliveryType.SMALL_ORDER_UNITS].indexOf(deliveryType.level) !== -1) {
                    standardDelivery = true;
                }

                deliveryType = this.deliveryTypes.find((e) => e.level === this.selectedDeliveryLevel);
                this.basketService.setDelivery(
                    deliveryType ? deliveryType.level : null,
                    deliveryType ? deliveryType.cost : null,
                    this.basket.deliveryDate ? DateHelper.moment(this.basket.deliveryDate).format('YYYY-MM-DD') : null,
                    this.basket.deliverySurcharge,
                    false,
                    standardDelivery && deliveryNote ? deliveryNote.note : null
                )
                    .then(() => {
                        this.busy = false;
                        this.checkOutBasket = true;
                        if (this.basket.orderNumber) {
                            this.navigationService.navigate([`/checkout/order/${this.basket.orderNumber}`]);
                        } else {
                            this.navigationService.navigate(['/checkout']);
                        }
                    })
                    .catch((error) => {
                        this.busy = false;
                        this.dialogService.error(this.constructor.name, error);
                    });
            } else {
                if (this.basket.orderNumber) {
                    /** Remove soft deleted items */
                    const itemsToBeRemoved = this.basket.items.filter((item) => item.orderItemToBeDeleted);
                    const removalPromises = itemsToBeRemoved.map((item) => {
                        return this.basketService.removeItem(item.id);
                    }) || [];

                    Promise.all(removalPromises)
                        .then(() => {
                            this.basketService.convertToOrder(this.basket.uuid)
                                .then((response) => {
                                    this.busy = false;
                                    this.navigationService.navigate([`/checkout/order/${response.orderNumber || this.basket.orderNumber}`]);
                                })
                                .catch((error) => {
                                    this.busy = false;
                                    this.dialogService.error(this.constructor.name, error);
                                });
                        })
                        .catch((error) => this.dialogService.error(this.constructor.name, error))
                } else {
                    this.busy = false;
                    this.navigationService.navigate(['/checkout']);
                }
            }
        }
    }

    public cancelOrderChanges(): void {
        this.basketService.cancelOrderEdit(this.basket.uuid, this.basket.orderNumber)
            .catch((error) => this.dialogService.error(this.constructor.name, error))
        this.basketService.clearAll(false, true);
        this.navigationService.navigate(['/account/orders']);
    }

    public viewBaskets() {
        if (this.isLoggedIn()) {
            this.navigationService.navigate(['/account/baskets']);
        } else {
            this.authService.loginPrompt()
                .then(() => this.viewBaskets())
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        }
    }

    public noUnitInOrder() {
        let units = this.basket.items.filter((item) => {
            return item.group === ProductType.CABINETS;
        })
        return !(units && units.length);
    }

    public drawerUpgrade() {
        this.basketService.drawerUpgradeDialog()
            .then(() => {
                // this.basketService.calculateCosts(this.basket);
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public isDrawerUpgrade(item) {
        if (item.code === 'PDSIDES' || item.code === 'PDUPGRADEGLASS') {
            return true;
        }

        return false;
    }

    public canEditItem(item) {
        return !this.isSubItem(item) && item.group !== ProductType.DRAWER_BOXES && item.group !== ProductType.DELIVERY;
    }

    public canRemoveItem(item) {
        return item.group !== ProductType.SURCHARGES &&
            item.group !== ProductType.DISCOUNTS;
    }

    public canDuplicateItem(item) {
        return item.code !== 'SOLIDSURFACEWORKTOP' &&
            item.group !== ProductType.SURCHARGES &&
            item.group !== ProductType.DISCOUNTS;
    }

    public canShowIndividualPrices(item) {
        if (item.rankParent === '0' || item.rankParent === 0) {
            return true;
        }

        return this.showIndividualPrices.indexOf(item.group) !== -1;
    }

    public canShowDetail(type, item) {
        switch (type) {
            case 'cabinet':
            case 'hingeSide':
            case 'softClose':
            case 'dryAssembled':
                return item.group === ProductType.CABINETS;
        }

        return false;
    }

    public isSubItem(item) {
        let rank = (item.rank) ? item.rank.toString().split('.') : [];

        return rank.length > 1;
    }

    public toYesNo(value) {
        return value === 1 || value === true ? 'Yes' : 'No';
    }

    public removeItem(item: BasketItem) {
        this.dialogService.confirm(
            'Remove item?',
            `Are you sure you wish to remove '${item.description}'?`,
            { confirm: 'Remove' }
        )
            .then((response) => {
                if (response) {
                    if (this.basket.orderNumber && item.id) {
                        const basketItem = this.basket.items.find((basketItem) => basketItem.id === item.id);
                        if (basketItem) {
                            basketItem.orderItemToBeDeleted = true;
                        }
                        item.orderItemToBeDeleted = true;
                        this.getDeliveryType();
                        this.basketService.localUpdateBasket(this.basket);
                    } else {
                        this.basketService.removeItem(item.id)
                            .then(() => this.getDeliveryType())
                            .catch((error) => this.dialogService.error(this.constructor.name, error))
                    }
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public undoRemovedOrderItem(item: BasketItem): void {
        const basketItem = this.basket.items.find((basketItem) => basketItem.id === item.id);
        if (basketItem) {
            basketItem.orderItemToBeDeleted = false;
        }
        item.orderItemToBeDeleted = false;
        this.getDeliveryType();
        this.basketService.localUpdateBasket(this.basket);
    }

    public duplicateItem(item: BasketItem) {
        this.basketService.duplicateItem(item.id)
            .then(() => this.getDeliveryType())
            .catch((error) => this.dialogService.error(this.constructor.name, error))
    }

    public addNote(item: BasketItem) {
        this.dialogService.custom(NotesDialogComponent, {
            maxWidth: '500px',
            data: {
                basketId: this.basket.id,
                basketItemId: item.id,
                itemGroup: item.group
            }
        })
            .then(() => { })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public deleteNote(note: BasketNote): void {
        this.dialogService.prompt(
            'Remove Note',
            'Are you sure you want to remove this note?'
        )
            .then((response) => {
                if (response) {
                    if (!this.isStaffNote(note)) {
                        this.basketService.deleteBasketItemNote(note)
                            .then(() => { })
                            .catch((error) => this.dialogService.error(this.constructor.name, error));
                    }
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public isStaffNote(note: BasketNote): Boolean {
        if (note?.createdBy) {
            let [userType, userId] = note.createdBy.split(':');
            return userType === BasketNoteAuthor.STAFF;
        }
        return false;
    }

    public changeCabinetOptions(item: BasketItem) {
        if (this.basketService.hasPassedCutOffDate()) {
            return;
        }
        this.dialogService.custom(ChangeCabinetOptionsDialogComponent, {
            panelClass: 'component-change-style-dialog',
            maxWidth: '500px',
            data: {
                basketId: this.basket.id,
                item: item,
                carcaseColours: this.carcaseColours
            }
        })
            .then((response) => {

            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public changeStyle(section) {
        if (this.basketService.hasPassedCutOffDate()) {
            return;
        }

        let itemIds = [];
        section.subSections.forEach(sub => {
            sub.items.forEach(item => {
                itemIds.push(item.id);
            });
        });

        this.dialogService.custom(ChangeStyleDialogComponent, {
            width: '90%',
            data: {
                itemIds: itemIds
            }
        })
            .then(() => this.fixDeliveryTypeBasedOnBasket())
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public changeCarcaseMaterial(item: BasketItem): void {
        Promise.all([
            this.catalogueService.getAccessory(item.code),
            this.catalogueService.getCabinetColours()
        ])
            .then(([accessory, carcaseColours]) => {
                this.dialogService.custom(DimensionsInputCalculatorDialogComponent, {
                    maxWidth: '500px',
                    data: {
                        item: item,
                        carcaseColours: carcaseColours,
                        product: accessory
                    }
                })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public getOrderValue(includeDelivery = false): number {
        if (includeDelivery) {
            return this.basket.cost + this.getDeliveryCost();
        } else {
            return this.basket.cost;
        }
    }

    public printBasket() {
        if (this.config.isBrowser) {
            window.print();
        }
    }

    private getcarcaseColours() {
        this.catalogueService
            .getCabinetColours()
            .then((carcaseColours: any) => {
                this.carcaseColours = carcaseColours;
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public getItemNotAvailableInStyleMessage(item: any) {
        return item.notAvailableInStyle ? ' (Unavailable in Style)' : '';
    }

    public getItemNotAvailableMessage(item: any) {
        return item.notAvailable ? ' (Discontinued)' : '';
    }

    public showBasketHistory() {
        this.loadBasketHistory = !this.loadBasketHistory;
        this.drawer.toggle();
    }

    public hingeWording(item: ICabinet): string {
        this.catalogueService.setHandingType(item);

        return this.catalogueService.getHingeWording(item);
    }

    public allowQtyEdit(item: BasketItem) {
        if (
            [
                ProductType.ACCESSORIES,
                ProductType.APPLIANCES,
                ProductType.HANDLES,
                ProductType.SINK_AND_TAPS,
                ProductType.WORKTOPS
            ].indexOf(item.group) !== -1 &&
            item.code !== 'SOLIDSURFACEWORKTOP' &&
            !this.reachedCutOff &&
            !item.isPromotion
        ) {
            return true;
        } else {
            return false;
        }
    }

    public updateQty(event, item: BasketItem) {
        if (event !== null) {
            const qty = parseInt(event, 10);

            if (qty > 0) {
                item.qty = qty;
            } else {
                this.removeItem(item);
            }
        } else {
            if (
                !this.basketService.hasPassedCutOffDate() &&
                !item._cabinet &&
                !item.group.includes('sample')
            ) {
                const params: IBasketUpdateItem = {
                    qty: item.qty
                };

                this.basketService.updateItem(item.id, params)
                    .then(() => { })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            }
        }
    }

    public basketLockedPrompt(): void {
        this.dialogService.prompt(
            'Your Order is now Locked',
            'Please note that this order is now locked as your order has passed the cut-off date for amendments and is now being processed, therefore, no changes or additions can be made to this order. If you require additional items, please create a new basket.',
            {
                data: {
                    buttons: [
                        { label: 'cancel', action: false, type: 'stroked', colour: 'secondary' },
                        { label: 'New Basket', action: true }
                    ]
                },
                minWidth: '380px'
            }).then((response) => {
                if (response) {
                    this.basketService.clearAll(true);
                }
            });
    }

    public createQuote() {
        this.dialogService.custom(TransferBasketDialogComponent, {
            width: '100%',
            maxWidth: '800px',
            maxHeight: '600px',
            panelClass: 'component-transfer-basket-dialog'
        })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public basketHasAppliances(): boolean {
        let hasAppliances = false;
        if (this.basket && this.basket.items) {
            hasAppliances = !!this.basket.items.find((basketItem) => basketItem.group === ProductType.APPLIANCES);
        }

        return hasAppliances;
    }
}
