import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';
import { PipesModule } from '@app/pipes';

import { LatestOffersComponent } from './latest-offers.component';

@NgModule({
    imports: [
        DesignModule,
        PipesModule
    ],
    exports: [
        LatestOffersComponent
    ],
    declarations: [
        LatestOffersComponent
    ]
})
export class LatestOffersComponentModule { }
