<header class="content-contained">
    <h1>Kitchen Sales &amp; Offers</h1>
    <p>Throughout the year we have some fantastic <em><strong>kitchen sales &amp; offers</strong></em> available to our customers. Take a look below to see what sales &amp; offers we have available for you at the moment.</p>
    <ng-container *ngIf="!this.promotions.length">
        <p>Although we don't have any active offers at the moment, please take a look at the <a routerLink="/kitchen-units" title="View Kitchen Units">vast number of units</a> that we have to offer. The prices of our units are clearly shown on our website and you may even find that they are cheaper than any quote you already have with discounts already included!</p>
    </ng-container>
    <p>It only takes a few minutes to look, and you could <strong>save yourself £100s, if not £1000s!</strong> Not sure which kitchen to go for yet? Then take a look at our magnificent <a routerLink="/kitchens/allstyles">kitchen range here.</a></p>
</header>

<hr class="narrow" />

<section class="content-contained">
    <div *ngFor="let promotion of this.promotions" class="offer-banner offer-sale" [id]="this.getPromotionId(promotion)"
    [style]="'background-color: ' + promotion.saleCard.backgroundColour">
        <div class="offer-image">
            <img [src]="promotion.saleCard.image.imageData" width="100%" [alt]="promotion.name"/>
        </div>
        <div class="offer-details">
            <div class="offer-detail">
                <p *ngFor="let text of promotion.saleCard.textList" [class]="text.cssClass" [style]="'color: ' + text.colour">
                    {{ text.text }}
                </p>
                <div class="button-wrapper">
                    <ng-container *ngFor="let button of promotion.saleCard.buttons">
                        <ng-container *ngIf="button.routerLink.length">
                            <a *ngIf="button.routerLink[0] === '/'" [routerLink]="button.routerLink">
                                <button mat-flat-button color="primary" [title]="button.text">{{ button.text }}</button>
                            </a>
                            <a *ngIf="button.routerLink[0] !== '/'" [href]="button.routerLink" target="_blank">
                                <button mat-flat-button color="primary" [title]="button.text">{{ button.text }}</button>
                            </a>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="offer-terms">
                <p>Offer Ends &#64; Midnight on {{this.getSimpleDateString(promotion.endDate)}}</p>
                <button class="button-link" (click)="this.scrollToAnchor(this.getPromotionId(promotion) + '-terms')" title="Read terms">Read terms</button>
            </div>
        </div>
    </div>

    <div class="offer-banner offer-competitor">
        <div class="offer-image">
            <img src="https://static.diy-kitchens.com/assets/images/kitchen-sales/kitchen-comparison-prices.jpg" width="100%" alt="Beautiful kitchen"/>
        </div>
        <div class="offer-details">
            <div class="offer-detail">
                <p class="small">UP TO</p>
                <p class="large">50% OFF</p>
                <p class="small">COMPETITOR</p>
                <p class="small">KITCHEN PRICES</p>

                <div class="button-wrapper">
                    <a routerLink="/about-diy-kitchens/price-and-quality-comparison" title="Price & Quality Comparison">
                        <button mat-flat-button color="primary" title="View comparison prices">View comparison prices</button>
                    </a>
                </div>
            </div>
            <div class="offer-terms">
                <button class="button-link" (click)="scrollToAnchor('price')" title="Read terms">Read terms</button>
            </div>
        </div>
    </div>

    <div class="offer-banner offer-competitor-worktop">
        <div class="offer-image">
            <img src="https://static.diy-kitchens.com/assets/images/kitchen-sales/worktop-prices.jpg" width="100%" alt="Beautiful kitchen"/>
        </div>
        <div class="offer-details">
            <div class="offer-detail">
                <p class="small">UP TO</p>
                <p class="large">30% OFF</p>
                <p class="small">COMPETITOR</p>
                <p class="small">WORKTOP PRICES</p>

                <div class="button-wrapper">
                    <a routerLink="/worktops" title="Worktops">
                        <button mat-flat-button color="primary" title="View worktops">View worktops</button>
                    </a>
                </div>
            </div>
            <div class="offer-terms">
                <button class="button-link" (click)="scrollToAnchor('worktop')" title="Read terms">Read terms</button>
            </div>
        </div>
    </div>
</section>

<section class="content-contained lightgrey">
    <h3>Terms &amp; Conditions</h3>

    <ng-container *ngFor="let promotion of this.promotions">
        <a [name]="this.getPromotionId(promotion) + '-terms'" [id]="this.getPromotionId(promotion) + '-terms'" title="Terms & Conditions"></a>
        <p>
            <strong>{{ promotion.terms.title }}</strong><br />
            {{ promotion.terms.body }}
            <ng-container *ngIf="promotion.terms.link.routerLink.length">
                <a target="_blank" [href]="promotion.terms.link.routerLink"> {{ promotion.terms.link.text }}</a>.
            </ng-container>
        </p>
    </ng-container>

    <a name="worktop" id="worktop"></a>
    <p>
        <strong>Up to 30% Off Competitor Worktop Prices</strong><br />
        To take advantage of our already fantastic pricing, you need to buy a worktop as part of a complete kitchen order.
    </p>

    <a name="price" id="price"></a>
    <p>
        <strong>Up to 50% Off Competitor Kitchen Prices</strong><br />
        We have based these comparisons on one of our most popular styles Linwood Oak (Shaker Oak).
    </p>

    <p>**Please note that we have a minimum order value of £750 for NEW kitchen purchases.</p>
</section>

<component-latest-offers></component-latest-offers>
