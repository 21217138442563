import { Component, ViewEncapsulation } from '@angular/core';

import { DateHelper } from '@app/utilities/helpers';

import { AuthService } from '@app/services/auth';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';

@Component({
    selector: 'page-support',
    templateUrl: './support.page.html',
    styleUrls: ['./support.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SupportPage {
    public showXmasShutdown: boolean = false;

    public get isAuthenticated(): boolean {
        return this.authService.isAuthenticated;
    }

    constructor(
        private authService: AuthService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
    ) {
        this.navigationService.setNavigation({
            title: 'DIY Kitchens - Support Portal',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' }
            ],
            routeHistory: [{ title: 'Support', route: '/support' }]
        });

        const currentDate = DateHelper.moment();
        if (currentDate.format('MM') === '12') {
            this.showXmasShutdown = true;
        } else if (currentDate.format('MM') === '01' && currentDate.format('DD') < '03') {
            this.showXmasShutdown = true;
        }
    }

    public login() {
        this.authService.loginPrompt()
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public openContact(type?: string) {
        this.authService.loginPrompt()
            .then(() => {
                let navigateTo = ['support', 'contact'];

                if (type) {
                    navigateTo.push(type);
                }

                this.navigationService.navigate(navigateTo);
            })
            .catch((error) => {
                if (error) {
                    this.dialogService.error(this.constructor.name, error);
                }
            });
    }

    public openOrders() {
        this.authService.loginPrompt()
            .then(() => this.navigationService.navigate(['/account/orders']))
            .catch((error) => {
                if (error) {
                    this.dialogService.error(this.constructor.name, error);
                }
            });
    }

    public reportIssue() {
        this.authService.loginPrompt()
            .then(() => this.navigationService.navigate(['/account/orders/report-issue']))
            .catch((error) => {
                if (error) {
                    this.dialogService.error(this.constructor.name, error);
                }
            });
    }

    public openBaskets() {
        this.authService.loginPrompt()
            .then(() => this.navigationService.navigate(['/account/baskets']))
            .catch((error) => {
                if (error) {
                    this.dialogService.error(this.constructor.name, error);
                }
            });
    }
}
