import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthGuard, CanDeactivateGuard } from '@app/utilities/guards';

import { AccountPage } from './account.page';
import { BasketsPage } from './content/baskets/baskets.page';
import { OrderPage } from './content/order/order.page';
import { OrdersPage } from './content/orders/orders.page';
import { PlaceholderPage } from './content/placeholder/placeholder.page';
import { RemedialPage } from './content/remedial/remedial.page';
import { ResetPasswordPage } from './content/reset-password/reset-password.page';

import { PageNotFoundComponent } from '@app/shared/page-not-found.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: AccountPage,
                data: { reuse: true }
            },
            {
                path: 'reset-password',
                component: ResetPasswordPage,
                pathMatch: 'full'
            },
            {
                path: 'reset-password/:resetToken',
                component: ResetPasswordPage,
                pathMatch: 'full'
            },
            {
                path: 'baskets',
                component: BasketsPage,
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: { AuthGuard: 'customer', vatToggle: true }
            },
            {
                path: 'orders',
                component: OrdersPage,
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: { AuthGuard: 'customer' }
            },
            {
                path: 'orders/report-issue',
                component: OrdersPage,
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: { AuthGuard: 'customer' }
            },
            {
                path: 'order/:orderNumber',
                component: OrderPage,
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: { AuthGuard: 'customer' }
            },
            {
                path: 'order/:orderNumber/:action',
                component: RemedialPage,
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: { AuthGuard: 'customer' }
            },
            {
                path: 'invoice/:orderNumber',
                component: OrderPage,
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: { AuthGuard: 'customer' }
            },
            {
                path: 'placeholder',
                component: PlaceholderPage,
                pathMatch: 'full'
            },
            { path: '**', component: PageNotFoundComponent }
        ])
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AuthGuard,
        CanDeactivateGuard
    ]
})
export class AccountRouting { }
