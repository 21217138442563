<header class="content-contained">
    <h1>Retrieve Basket</h1>
    <p>If you have already created a shopping basket and have a basket recovery code, please enter it below to retrieve your basket on this device.</p>
</header>

<hr class="narrow" />

<section class="content-contained">
    <h3>Enter basket recovery code</h3>

    <mat-form-field class="shopper-id" appearance="outline">
        <input matInput [(ngModel)]="shopperId" (keyup.enter)="recover()" />
    </mat-form-field>

    <p class="small">Your basket recovery code will be either 19 or 27 characters long. Please include any hyphens or hash symbols that may appear within your basket recovery code. If you are unsure of your basket recovery code please send us a support ticket.</p>
    <p class="small">Recovering your basket can take a few minutes depending on how large your basket is.</p>

    <div class="actions">
        <button mat-flat-button color="primary" class="button-spinner" [disabled]="recovering" (click)="recover()">
            Retrieve Basket <mat-spinner *ngIf="recovering" diameter="25" color="accent"></mat-spinner>
        </button>
        <button mat-stroked-button color="secondary" routerLink="/support">Contact Support</button>
    </div>
</section>