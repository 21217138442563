import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SupportPage } from './support.page';
import { BasketRecoveryPage } from './content/basket-recovery/basket-recovery.page';
import { ContactPage } from './content/contact/contact.page';
import { MessagingPage } from './content/messaging/messaging.page';

import { PageNotFoundComponent } from '@app/shared/page-not-found.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: SupportPage,
                data: { reuse: true }
            },
            {
                path: 'resurrect.asp',
                component: BasketRecoveryPage,
                pathMatch: 'full'
            },
            {
                path: 'basket-recovery',
                component: BasketRecoveryPage,
                pathMatch: 'full'
            },
            {
                path: 'messaging',
                component: MessagingPage,
                pathMatch: 'full'
            },
            {
                path: 'baskets',
                component: SupportPage,
                data: { reuse: true }
            },
            {
                path: 'contact',
                component: ContactPage,
                pathMatch: 'full'
            },
            {
                path: 'contact/delivery',
                component: ContactPage,
            },
            {
                path: 'contact/planning',
                component: ContactPage,
            },
            {
                path: 'contact/quote',
                component: ContactPage,
            },
            {
                path: 'contact/feedback',
                component: ContactPage,
            },
            {
                path: 'contact/customerkitchens',
                component: ContactPage,
            },
            {
                path: 'contact/aftersales',
                component: ContactPage,
            },
            { path: '**', component: PageNotFoundComponent }
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class SupportRouting { }
