export interface BasketNote {
    id: number;
    note: string;
    basketId: number;
    basketItemId: number;
    orderId: number;
    orderItemId: number;
    isInternal: boolean;
    typeId: number;
    createdAt: string;
    createdBy: string;
}

export enum BasketNoteAuthor {
   STAFF = '1',
   CUSTOMER = '2'
}