<div class="container" #resultsContainer>
    <div class="title">
        <span (click)="focusSearch()">{{ searchText }}</span>
        <mat-icon class="close" (click)="close()">close</mat-icon>
    </div>
    <div class="tabs">
        <!-- TODO: this should be a <button> instead of an <a> tag -->
        <a *ngIf="unitHits.length > 0" [ngClass]="type === ProductType.CABINETS ? 'selected' : ''"
            (click)="setTab(ProductType.CABINETS)">
            {{ unitHits.length }} Units
        </a>
        <!-- TODO: this should be a <button> instead of an <a> tag -->
        <a *ngIf="applianceHits.length > 0" [ngClass]="type === ProductType.APPLIANCES ? 'selected' : ''"
            (click)="setTab(ProductType.APPLIANCES)">
            {{ applianceHits.length }} Appliances
        </a>
        <!-- TODO: this should be a <button> instead of an <a> tag -->
        <a *ngIf="sinksTapsHits.length > 0" [ngClass]="type === ProductType.SINK_AND_TAPS ? 'selected' : ''"
            (click)="setTab(ProductType.SINK_AND_TAPS)">
            {{ sinksTapsHits.length }} Sinks & Taps
        </a>
        <!-- TODO: this should be a <button> instead of an <a> tag -->
        <a *ngIf="worktopHits.length > 0" [ngClass]="type === ProductType.WORKTOPS ? 'selected' : ''"
            (click)="setTab(ProductType.WORKTOPS)">
            {{ worktopHitsGrouped.length }} Worktops
        </a>
        <!-- TODO: this should be a <button> instead of an <a> tag -->
        <a *ngIf="accessoryHits.length > 0" [ngClass]="type === ProductType.ACCESSORIES ? 'selected' : ''"
            (click)="setTab(ProductType.ACCESSORIES)">
            {{ accessoryHits.length }} Accessories
        </a>
        <!-- TODO: this should be a <button> instead of an <a> tag -->
        <a *ngIf="handleHits.length > 0" [ngClass]="type === ProductType.HANDLES ? 'selected' : ''"
            (click)="setTab(ProductType.HANDLES)">
            {{ handleHits.length }} Handles
        </a>
        <!-- TODO: this should be a <button> instead of an <a> tag -->
        <a *ngIf="doorHits.length > 0" [ngClass]="type === ProductType.DOORS ? 'selected' : ''"
            (click)="setTab(ProductType.DOORS)">
            {{ doorHits.length }} Doors
        </a>
        <!-- TODO: this should be a <button> instead of an <a> tag -->
        <a *ngIf="panelHits.length > 0" [ngClass]="type === ProductType.RANGE_PRODUCTS ? 'selected' : ''"
            (click)="setTab(ProductType.RANGE_PRODUCTS)">
            {{ panelHits.length }} Range Accessories
        </a>
    
        <ng-container *ngFor="let category of categoryHits">
            <!-- TODO: this should be a <button> instead of an <a> tag -->
            <a *ngIf="category.subCategories.length > 0" [ngClass]="type === category.title + ' Categories' ? 'selected' : ''"
                (click)="setTab(category.title + ' Categories')">
                {{category.title}} Categories
            </a>
        </ng-container>
        <!-- TODO: this should be a <button> instead of an <a> tag -->
        <a *ngIf="keywords && keywords.length > 0" [ngClass]="type === 'Keywords' ? 'selected' : ''"
            (click)="setTab('Keywords')">
            {{ keywords.length }} Advice
        </a>

    </div>

    <div id="scroller" class="scroller">
        <div *ngIf="searchText.length < 3" class="noresult">
            <h3>Search too short</h3>
        </div>
        <div *ngIf="searchText.length > 2" class="results">
            <div class="section-cards" *ngIf="keywords && keywords.length && type === 'Keywords'">
                <mat-card *ngFor="let keyword of keywords">
                    <mat-card-header>
                        <mat-card-title>{{keyword.name}}</mat-card-title>
                    </mat-card-header>
                    <img mat-card-image *ngIf="keyword.image" [src]="keyword.image" [alt]="keyword.name"/>
                    <mat-card-content>
                        <p [innerHTML]="keyword.content"></p>
                    </mat-card-content>
                    <mat-card-actions *ngIf="keyword.url">
                        <a [routerLink]="keyword.url" *ngIf="!externalUrl(keyword.url)">
                            <button mat-button>View</button>
                        </a>
                        <a [href]="keyword.url" *ngIf="externalUrl(keyword.url)" target="_blank">
                            <button mat-button>View</button>
                        </a>
                    </mat-card-actions>
                </mat-card>
            </div>

            <div *ngIf="(keywords && keywords.length < 1) && worktopHits.length < 1 && unitHits.length < 1 && applianceHits.length < 1 && 
            sinksTapsHits.length < 1 && accessoryHits.length < 1 && handleHits.length < 1 && doorHits.length < 1 && categoryHits.length < 1 && panelHits.length < 1"
                class="noresult">
                <p><strong>No product search results</strong></p>
                <p>Please add more detail or try a different search term.</p>
                <p class="advice">
                    Alternatively, for more general kitchen advice, visit our <a href="https://advice.diy-kitchens.com" target="_blank"
                        rel="noreferrer" title="Kitchen Advice">kitchen advice and help centre</a>. Where you will find frequently asked
                    questions, kitchen tips & ideas to help you make the best decisions when buying a new kitchen.
                </p>
            </div>

            <div *ngIf="unitHits.length > 0 && type === ProductType.CABINETS" class="prods">
                <div *ngFor="let item of unitHits" class="item">
                    <div class="image" [promotion]="'search-component'" [request]="'listing-overlay-image'" [productCode]="item.unit_code" [type]="ProductType.CABINETS">
                        <img src="{{ item.media.image }}" [alt]="item.desc"/>
                    </div>
                    <div class="details">{{ item.desc }}</div>
                    <div class="code">{{ item.unit_code }}</div>
                    <div class="small cabinet-only" *ngIf="!activeRange">Cabinet only price</div>
                    <div class="price" *ngIf="item.currentPrice" [promotion]="'search-component'" [request]="'unit-price'" [productCode]="item.unit_code" [item]="item" [softClose]="item.softClose">{{ item.currentPrice | currencyFormat }}</div>
                    <div class="small" *ngIf="activeRange && !item.currentPrice">Unavailable in this range</div>
                    <div class="actions">
                        <button mat-raised-button class="chunky" color="primary"
                            [routerLink]="itemLink(item, ProductType.CABINETS)" (click)="close()">DETAILS</button>
                    </div>
                </div>
            </div>

            <div *ngIf="applianceHits.length > 0 && type === ProductType.APPLIANCES" class="prods">
                <div *ngFor="let app of applianceHits" class="item">
                    <div class="image" [promotion]="'search-component'" [request]="'listing-overlay-image'" [productCode]="app.code" [brand]="app.brand" [item]="app" [type]="ProductType.APPLIANCES">
                        <img src="{{ app.media.image }}" [alt]="app.description"/>
                    </div>
                    <div class="details">{{ app.description }}</div>
                    <div class="code">{{ app.code }}</div>
                    <div class="price" *ngIf="app.cost" [promotion]="'search-component'" [request]="'product-price'" [productCode]="app.code" [item]="app" [type]="ProductType.APPLIANCES">{{ app.cost | currencyFormat }}</div>
                    <div class="actions">
                        <button mat-raised-button class="chunky" color="primary"
                            [routerLink]="itemLink(app, ProductType.APPLIANCES)" (click)="close()">DETAILS</button>
                    </div>
                </div>
            </div>

            <div *ngIf="sinksTapsHits.length > 0 && type === ProductType.SINK_AND_TAPS" class="prods">
                <div *ngFor="let prod of sinksTapsHits" class="item">
                    <div class="image" [promotion]="'search-component'" [request]="'listing-overlay-image'" [productCode]="prod.code" [brand]="prod.brand" [item]="prod" [type]="ProductType.SINK_AND_TAPS">
                        <img src="{{ prod.media.image }}" [alt]="prod.desc"/>
                    </div>
                    <div class="details">{{ prod.desc }}</div>
                    <div class="code">{{ prod.code }}</div>
                    <div class="price" *ngIf="prod.price" [promotion]="'search-component'" [request]="'product-price'" [productCode]="prod.code" [brand]="prod.brand" [item]="prod" [type]="ProductType.SINK_AND_TAPS">{{ prod.price | currencyFormat }}</div>
                    <div class="actions">
                        <button mat-raised-button class="chunky" color="primary" [routerLink]="itemLink(prod, ProductType.SINK_AND_TAPS)"
                            (click)="close()">DETAILS</button>
                    </div>
                </div>
            </div>

            <div *ngIf="worktopHits.length > 0 && type === ProductType.WORKTOPS" class="prods">
                <div *ngFor="let container of worktopHitsGrouped" class="item">
                    <div class="image" [promotion]="'search-component'" [request]="'listing-overlay-image'" [productCode]="container.products[0].code" [brand]="container.products[0].brand" [item]="container.products[0]" [type]="ProductType.WORKTOPS">
                        <img src="{{ container.media.image }}" [alt]="container.products[0].cat + ' ' + container.products[0].sub_cat + ' worktop'" />
                    </div>
                    <div class="details">
                         {{ container.description }}
                    </div>
                    <div class="code">{{ container.code }}</div>
                    <div class="price" *ngIf="container.fromPrice" [promotion]="'search-component'" [request]="'product-price'" [productCode]="container.products[0].code" [brand]="container.products[0].brand" [item]="container.products[0]" [type]="ProductType.WORKTOPS">From {{ container.fromPrice | currencyFormat }}</div>
                    <div class="actions">
                        <ng-container *ngFor="let button of container.buttons">
                            <button *ngIf="button.text !== 'Order Sample'" mat-raised-button class="chunky" color="primary"
                                [routerLink]="button.url" (click)="close()">
                                {{ button.text }}
                            </button>
                            <!-- <button *ngIf="button.text === 'Order Sample'" mat-raised-button class="chunky" color="primary"
                               (click)="addToBasket(container.products[0])">
                                {{ button.text }}
                            </button> -->
                            <component-basket-add-button *ngIf="button.text === 'Order Sample'" [customLabel]="button.text" [type]="type" [item]="container.products[0]"></component-basket-add-button>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div *ngIf="accessoryHits.length > 0 && type === ProductType.ACCESSORIES" class="prods">
                <div *ngFor="let acc of accessoryHits" class="item">
                    <div class="image" [promotion]="'search-component'" [request]="'listing-overlay-image'" [productCode]="acc.product_code" [item]="acc" [type]="ProductType.ACCESSORIES">
                        <img src="{{ acc.media.image }}" [alt]="acc.desc"/>
                    </div>
                    <div class="details">{{ acc.desc }}</div>
                    <div class="code">{{ acc.product_code }}</div>
                    <div class="price" *ngIf="acc.price" [promotion]="'search-component'" [request]="'product-price'" [productCode]="acc.product_code" [item]="acc" [type]="ProductType.ACCESSORIES">{{ acc.price | currencyFormat }}</div>
                    <div class="actions">
                        <button mat-raised-button class="chunky" color="primary"
                            [routerLink]="itemLink(acc, ProductType.ACCESSORIES)" (click)="close()">DETAILS</button>
                    </div>
                </div>
            </div>

            <div *ngIf="handleHits.length > 0 && type === ProductType.HANDLES" class="prods">
                <div *ngFor="let handle of handleHits" class="item">
                    <div class="image" [promotion]="'search-component'" [request]="'listing-overlay-image'" [productCode]="handle.product_code" [item]="handle" [type]="ProductType.HANDLES">
                        <img src="{{ handle.media.image }}" [alt]="handle.desc"/>
                    </div>
                    <div class="details">{{ handle.desc }}</div>
                    <div class="code">{{ handle.product_code }}</div>
                    <div class="price" *ngIf="handle.price" [promotion]="'search-component'" [request]="'product-price'" [productCode]="handle.product_code" [item]="handle" [type]="ProductType.HANDLES">{{ handle.price | currencyFormat }}</div>
                    <div class="actions">
                        <button mat-raised-button class="chunky" color="primary"
                            [routerLink]="itemLink(handle, ProductType.HANDLES)" (click)="close()">DETAILS</button>
                    </div>
                </div>
            </div>

            <div *ngIf="doorHits.length > 0 && type === ProductType.DOORS" class="prods">
                <div *ngFor="let door of doorHits" class="item">
                    <div class="image">
                        <img src="{{ door.media.image }}" [alt]="door.desc"/>
                    </div>
                    <div class="details">{{ door.desc }}</div>
                    <div class="code">{{ door.unit_code }}</div>
                    <div class="price" *ngIf="door.price">{{ door.price | currencyFormat }}</div>
                    <div class="actions">
                        <button mat-raised-button class="chunky" color="primary"
                            [routerLink]="itemLink(door, ProductType.DOORS)" (click)="close()">DETAILS</button>
                    </div>
                </div>
            </div>

            <div *ngIf="panelHits.length > 0 && type === ProductType.RANGE_PRODUCTS" class="prods">
                <div *ngFor="let panel of panelHits" class="item">
                    <div class="image">
                        <img src="{{ panel.media.image }}" [alt]="panel.desc"/>
                    </div>
                    <div class="details">{{ panel.desc }}</div>
                    <div class="code">{{ panel.code }}</div>
                    <div class="price" *ngIf="panel.price">{{ panel.price | currencyFormat }}</div>
                    <div class="actions">
                        <button mat-raised-button class="chunky" color="primary"
                            [routerLink]="itemLink(panel, ProductType.RANGE_PRODUCTS)" (click)="close()">DETAILS</button>
                    </div>
                </div>
            </div>

            <ng-container *ngFor="let category of categoryHits">
                <div *ngIf="category.subCategories.length > 0 && type === category.title + ' Categories'" class="prods">
                    <ng-container *ngFor="let subCategory of category.subCategories">
                        <div *ngFor="let item of subCategory.items" class="item">
                            <div class="image">
                                <img src="{{ item.image.url }}" [alt]="item.name"/>
                            </div>
                            <div class="details">{{ getCategoryItemName(item.name, category.title) }}</div>
                            <div class="actions">
                                <button mat-raised-button class="chunky" color="primary"
                                    [routerLink]="item.url" (click)="close()">
                                    View Products
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

        </div>
    </div>
</div>
