<div mat-dialog-title>
    <h2>Enter your postcode</h2>
    <div class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </div>
</div>
<mat-dialog-content>
    <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="postcode" />
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="getDirections()">Get directions</button>
</mat-dialog-actions>
