import { Injectable } from '@angular/core';

import { Config } from '@app/config';

import { HttpApi } from '@app/services/api';
import { HttpApiOptions, HttpApiResponse } from '@app/services/api/models';
import { AuthCustomerService } from '@app/services/auth';
import { IOrder, IProductSelectorItem } from './models';

@Injectable()
export class OrdersService {
    private _carcaseColours: string[] = null;

    constructor(
        private config: Config,
        private httpApi: HttpApi,
        private authCustomerService: AuthCustomerService,
    ) { }

    // public getOrders(): Promise<any[]> {
    //     return new Promise((resolve, reject) => {
    //         if (this.authCustomerService.isAuthenticated) {
    //             let url = `${this.config.api.endpoints.diy}/customer/details/orders`;
    //             let options: HttpApiOptions = {
    //                 authorization: {
    //                     UserToken: this.authCustomerService.universalToken
    //                 }
    //             };

    //             this.httpApi.get<HttpApiResponse<any[]>>(url, options).subscribe({
    //                 next: (response) => {
    //                     if (response && response.success) {
    //                         resolve(response.body || []);
    //                     } else {
    //                         resolve([]);
    //                     }
    //                 },
    //                 error: (error) => reject(error)
    //             });
    //         } else {
    //             resolve([]);
    //         }
    //     });
    // }

    private processOrders(orders) {
        if (orders?.order) {
            orders = [orders.order];
        }
        orders.sort((a, b) => {
            let aD = new Date(a.expectedDeliveryDate || a.deliveryDate || a.weekCommencingDate).getTime();
            let bD = new Date(b.expectedDeliveryDate || b.deliveryDate || b.weekCommencingDate).getTime();

            return bD - aD;
        });

        let today = new Date().getTime();
        orders.forEach(order => {
            if (order.isRemoved) {
                order.isDispatched = false;
                order.deliveryDetail = 'Order cancelled';
                order.status = 'CANCELLED';
            } else {
                let delDate;
                if (order.expectedDeliveryDate) {
                    delDate = new Date(order.expectedDeliveryDate || order.deliveryDate);
                    order.deliveryDetail = delDate.toDateString();
                } else {
                    delDate = new Date(order.weekCommencingDate || order.deliveryDate);
                    order.deliveryDetail = 'From ' + delDate.toDateString();
                }
                if (order.status) {
                    order.isDispatched = order.status.match(/in production/i) ? (delDate.getTime() <= today) : false;
                } else {
                    order.isDispatched = delDate.getTime() <= today;
                }
            }
        });

        return orders;
    }

    public getMCSOrders(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            if (this.authCustomerService.isAuthenticated) {
                let url = `${this.config.api.endpoints.diy}/orders/customerv2`;
                let options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id,
                        UserToken: this.authCustomerService.universalToken
                    }
                };

                this.httpApi.get<HttpApiResponse<any[]>>(url, options).subscribe({
                    next: (response) => {
                        if (response && response.success) {
                            // resolve(this.processOrders(response.body || []));
                            resolve(response.body || []);
                        } else {
                            reject(response);
                        }
                    },
                    error: (error) => reject(error)
                });
            } else {
                resolve([]);
            }
        });
    }

    public getMCSOrder(orderNumber): Promise<any[]> {
        return new Promise((resolve, reject) => {
            if (this.authCustomerService.isAuthenticated) {
                let url = `${this.config.api.endpoints.liveDiy}/pit/order/${orderNumber}`;
                let options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id,
                        UserToken: this.authCustomerService.universalToken,
                        // TODO remove admin tokens below
                        ApplicationToken: this.authCustomerService.applicationToken,
                        UniversalToken: this.authCustomerService.universalToken
                    }
                };

                this.httpApi.get<HttpApiResponse<any[]>>(url, options).subscribe({
                    next: (response) => {
                        if (response && response.success) {
                            resolve(this.processOrders(response.body || []));
                        } else {
                            reject(response);
                        }
                    },
                    error: (error) => reject(error)
                });
            } else {
                resolve([]);
            }
        });
    }

    public getOrderAddresses(orderId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = `${this.config.api.endpoints.diy}/order/${orderId}/addresses`;
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public setOrderDelivery(orderId, level, cost, deliveryDate, surCharge): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = `${this.config.api.endpoints.diy}/order/${orderId}/delivery`;
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            let params = {
                level,
                cost,
                orderId,
                deliveryDate,
                surCharge
            };

            this.httpApi.post<HttpApiResponse<any>>(url, params, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public saveOrderAddresses(billingAddress, deliveryAddress, orderId, surcharge) {
        return new Promise((resolve, reject) => {
            let url = `${this.config.api.endpoints.diy}/order/${orderId}/addresses`;
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.post<HttpApiResponse<any>>(url, {
                billingAddress,
                deliveryAddress,
                orderId,
                surcharge
            }, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public setOrderDeliveryAccess(orderId, deliveryAccess) {
        return new Promise((resolve, reject) => {
            let url = `${this.config.api.endpoints.diy}/order/${orderId}/delivery-access`;
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.post<HttpApiResponse<any>>(url, deliveryAccess, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public getPayIntentForOrder(orderId): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = `${this.config.api.endpoints.diy}/order/${orderId}/payment`;
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public getDiyBaskets(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            if (this.authCustomerService.isAuthenticated) {
                let url = `${this.config.api.endpoints.diy}/diy/baskets/support`;
                let options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id,
                        UserToken: this.authCustomerService.universalToken
                    }
                };

                this.httpApi.get<HttpApiResponse<any[]>>(url, options).subscribe({
                    next: (response) => {
                        if (response && response.success) {
                            resolve(response.body);
                        } else {
                            reject(response);
                        }
                    },
                    error: (error) => reject(error)
                });
            } else {
                resolve([]);
            }
        });
    }

    public getDIYOrderDetails(orderNumber): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = `${this.config.api.endpoints.diy}/order/${orderNumber}/details`;

            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public exportedOrderDetails(orderNumber, diyId, isLegacy): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = `${this.config.api.endpoints.diy}/order/${orderNumber}/details/exported/${diyId}/${isLegacy}`;

            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public reopenBasketForOrder(orderNumber: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.authCustomerService.isAuthenticated) {
                let url = `${this.config.api.endpoints.diy}/order/${orderNumber}/re-open`;
                let options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id,
                        UserToken: this.authCustomerService.universalToken
                    }
                };

                this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                    next: (response) => {
                        if (response && response.success) {
                            resolve(response.body.message);
                        } else {
                            reject(response);
                        }
                    },
                    error: (error) => reject(error)
                });
            } else {
                resolve([]);
            }
        });
    }

    public getLegacyDiyOrder(diyId): Promise<any[]> {
        return new Promise((resolve, reject) => {
            if (this.authCustomerService.isAuthenticated) {
                let url = `${this.config.api.endpoints.liveDiy}/order/legacy/${diyId}`;
                let options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id,
                        UserToken: this.authCustomerService.universalToken
                    }
                };

                this.httpApi.get<HttpApiResponse<any[]>>(url, options).subscribe({
                    next: (response) => {
                        if (response && response.success) {
                            // resolve(this.processOrders(response.body || []));
                            resolve(response.body || []);
                        } else {
                            reject(response);
                        }
                    },
                    error: (error) => reject(error)
                });
            } else {
                resolve([]);
            }
        });
    }

    public getPaymentDetails(orderNumber = null): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = `${this.config.api.endpoints.diy}/diy/payment-details`;

            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken
                }
            };

            let params = {
                orderNumber: orderNumber
            };

            this.httpApi.post<HttpApiResponse<any>>(url, params, options).subscribe(
                (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                (error) => reject(error)
            );
        });
    }

    public lookupCarcaseColours(): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            if (this._carcaseColours && this._carcaseColours.length) {
                resolve(this._carcaseColours);
            } else {
                const url = `${this.config.api.endpoints.diy}/diy/carcase-colours`;
                const options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id
                    }
                };

                this.httpApi.get<HttpApiResponse<string[]>>(url, options).subscribe({
                    next: (response) => {
                        if (response.success) {
                            this._carcaseColours = response.body;
                            resolve(this._carcaseColours);
                        } else {
                            reject(response);
                        }
                    },
                    error: (error) => reject(error)
                });
            }
        });
    }

    public legacyUpdateItemConfig(changeRequest: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/legacy/update-item`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.post<HttpApiResponse<any>>(url, changeRequest, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public legacyDeleteItem(orderId: number, basketItemId: number, itemId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/legacy/delete-item/${orderId}/${itemId}`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.del<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public legacyAddItem(orderId: number, basketItems: IProductSelectorItem[]): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/legacy/add-item`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };
            const params = {
                orderId: orderId,
                basketItems: basketItems
            };

            this.httpApi.post<HttpApiResponse<any>>(url, params, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public getOrders(): Promise<IOrder[]> {
        return new Promise<IOrder[]>((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/orders`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken,
                    UniversalToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.get<HttpApiResponse<IOrder[]>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body || []);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public orderDetails(orderNumber: string): Promise<IOrder[]> {
        return new Promise<any[]>((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/order/${orderNumber}`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken,
                    UniversalToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.get<HttpApiResponse<any[]>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body || null);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }
}
