@import '/src/theme/variables';

page-about-delivery-information {
    section {
        &.content-contained {
            h2 {
                font-size: clamp(1.777rem, 1vw + 1.777rem, 2.369rem);
                font-family: font('opensans');
                font-weight: 600;
                line-height: clamp(1.777rem, 1vw + 1.777rem, 2.369rem);
                letter-spacing: normal;
                text-align: center;
            }
        }

        &.inactive {
            display: none;
        }
    }

    .delivery-information {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 30px;
        margin-top: 56px;

        .delivery-information-block {
            h3 {
                margin-top: 20px;
            }

            .video-button {
                cursor: pointer;
            }
        }
    }

    .pricing-section {
        display: flex;
        justify-content: center;
        margin-top: 56px;

        .pricing-block {
            display: flex;
            flex-basis: 33%;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: space-around;
            justify-content: space-around;
            margin: 0 15px;
            font-weight: 700;

            ol,
            ul {
                padding-left: 48px;
                padding-right: 48px;
                border-top: 1px solid #666;
            }

            ol>li,
            ul>li {
                margin: 16px 0;
                font-size: 12px;
                font-weight: 400;
                line-height: 1.3rem;
            }

            .price {
                margin-top: 1.5rem;
                padding-top: 1rem;
                font-weight: normal;
                text-align: center;
            }

            .variant {
                margin-top: 0.5rem;
                margin-bottom: 1.333rem;
                padding: 0px 10px;
                font-size: 1.75rem;
                font-weight: 600;
                line-height: 1.75rem;
                text-align: center;
            }

            .options {
                flex-grow: 1;
                padding: 20px 40px;
                border-top: 1px solid #666;
                font-size: 1rem;
                font-weight: 700;
                line-height: 1.5rem;
                letter-spacing: normal;

                p {
                    margin: 0;
                    font-weight: normal;
                }
            }

            &.delivery-standard {
                background-color: #69F0AE;
            }

            &.doors {
                background-color: #42C0FB;
                color: #FFF;
            }

            &.appliances {
                background-color: #31B94D;
                color: #FFF;
            }

            &.worktops {
                background-color: #A68064;
                color: #FFF;
            }
        }
    }

    .pricing-section-notes {
        margin-top: 25px;
        margin-bottom: 25px;
        text-align: center;
    }

    .factory-fitted {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 30px;
        align-items: center;
        margin-top: 56px;
    }

    .section-header {
        margin: 0 auto;
        max-width: 66%;
        text-align: center;
    }

    .legend {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 20px;

        .block {
            display: flex;
            align-items: center;
            justify-content: center;

            .green {
                background-color: #CFC;
                width: 50px;
                height: 50px;
            }

            .red {
                background-color: #F66;
                width: 50px;
                height: 50px;
            }

            p {
                margin: 0;
                margin-left: 15px;
            }
        }
    }

    .map-view {
        overflow: visible;
        margin: 30px auto;

        td,
        th {
            position: relative;
            margin: 2px;
            height: 60px;
            font-size: 0.8rem;
            text-align: center;

            i {
                display: block;
                font-style: normal;
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.3rem
            }
        }

        td {
            border: 1px solid color('light-grey');
            background: #CFC;
            width: 80px;
            cursor: help;

            &.red {
                border-color: #000;
                background: #F66;
            }
        }

        th {
            text-align: left;
            font-size: 1.3rem;
        }
    }

    .popup-div {
        position: absolute;
        overflow: visible;
        padding: 15px;
        border: 1px solid #999;
        border-radius: 10px;
        background: color('white');
        box-shadow: 4px 4px 10px #666;
        width: fit-content;
        height: fit-content;
        z-index: 999;

        div {
            font-size: 1.4rem;
            line-height: 2rem;
        }

        p {
            margin: 5px 0;

            i {
                display: inline-block;
                font-weight: 600;
            }
        }
    }

    .areas {
        a {
            color: color('brand-primary');
        }
    }

    .mobile-view {
        display: none;

        .row {
            display: flex;
            flex-direction: row;
            background-color: #CFC;
            border: 1px solid color('light-grey');
            margin: 15px 0;

            &.red {
                background-color: #F66;

                .row {
                    background-color: #F66;
                }
            }

            .row {
                flex-wrap: wrap;
                border: none;
                margin: 0;

                div {
                    padding: 10px 15px 10px;
                    line-height: 1.5rem;
                }

                div:first-child {
                    width: 100%;
                }
            }
        }

        .pc {
            font-size: 3rem;
            line-height: 3.2rem;
            padding: 30px;
            width: 100px;
            text-align: center;
        }
    }

    @media only screen and (max-width: 1119px) {
        .map-view {
            display: none;
        }

        .mobile-view {
            display: block;
        }
    }

    @media (max-width: 992px) {
        .delivery-information {
            grid-template-columns: repeat(1, minmax(0, 1fr));
            margin-left: auto;
            margin-right: auto;
            max-width: 760px;
        }

        .section-header {
            max-width: 760px;
        }

        .pricing-section {
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
            max-width: 760px;

            .pricing-block {
                flex-basis: fit-content;

                &+.pricing-block {
                    margin-top: 30px;
                }
            }
        }

        .factory-fitted {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        section {
            &.inactive {
                display: inline;
            }
        }

        section::after {
            content: '';
            display: block;
            margin-top: 20px;
            border-bottom: 1px solid color('copy-border');
        }
    }
}