/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { inflate } from 'pako';

import { Config } from '@app/config';
import { DateHelper, StringHelper } from '@app/utilities/helpers';

import { HttpApi } from '@app/services/api';
import { HttpApiHeaders, HttpApiOptions, HttpApiResponse } from '@app/services/api/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { StorageService } from '@app/services/storage';

import { RangeSelectorDialogComponent } from '@app/dialogs/range-selector';

import {
    HandingType,
    ProductType,
    ActiveRange,
    CabinetColour,
    Catalogue,
    Layouts,
    IImageOptions,
    ImageTypes,
    ISearchTermCatalogue,
    ISearchTerm
} from '@app/services/catalogue/models';
import { IProductDetailsMap } from './models/product.models/common';
import {
    AllProductsUnion,
    IAccessory,
    IAppliance,
    ICabinet,
    IHandle,
    ISinkAndTaps,
    IWorktop,
    isRangeProduct,
} from './models/product.models';
import { isCarcaseColoured, isCarcaseMaterial, isCarcaseShelf, fixCode, setHandingType } from './catalogue.utils';

@Injectable()
export class CatalogueService {
    public activeRange$: BehaviorSubject<ActiveRange> = new BehaviorSubject(null);
    public activeRangeLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public activeRange: ActiveRange = {
        range: null,
        rangeColour: null,
        colour: null,
        bespokeColour: null,
        carcaseColour: null,
    };

    public missingSizeTags = {};

    private catalogues: any = {};
    public catalogue: Catalogue;
    public legacyImageCatalogue: any;
    public searchTermCatalogue: ISearchTermCatalogue;
    public productExtendedDetails: IProductDetailsMap;
    private range;
    private panels;
    private reviews;
    private bespokeColours = [];

    constructor(
        private config: Config,
        private httpApi: HttpApi,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private storageService: StorageService
    ) {
        this.broadcastActiveRange();
    }

    public broadcastActiveRange() {
        this.storageService
            .get('active-range')
            .then((activeRange: ActiveRange) => {
                if (activeRange && activeRange.rangeColour && activeRange.colour && typeof activeRange.colour === 'string') {
                    this.activeRange = activeRange;

                    this.getRangeById(activeRange.range.id)
                        .then((rangeDetail) => {
                            if (rangeDetail) {
                                const withDetails: any = Object.assign({}, this.activeRange);
                                withDetails.rangeDetail = rangeDetail;
                                withDetails.colourDetail = rangeDetail.colours.find((colour) => {
                                    return colour.name === this.activeRange.rangeColour;
                                });

                                this.getRangeDetails(activeRange.range.id)
                                    .then((rangeExtras) => {
                                        if (rangeExtras) {
                                            if (rangeExtras.cabinets && rangeExtras.cabinets.length) {
                                                rangeExtras.cabinets.forEach((cabinet) => {
                                                    cabinet.unit_code = this.fixCode(cabinet.unit_code);

                                                    Object.keys(cabinet.prices).forEach((colour) => {
                                                        const name = (colour || '').replace(/gloss/gi, '').trim();
                                                        if (!cabinet.prices[name]) {
                                                            cabinet.prices[name] = cabinet.prices[colour];
                                                        }
                                                    });
                                                });
                                            }

                                            withDetails.rangeDetail.cabinets = rangeExtras.cabinets;
                                            withDetails.rangeDetail.products = rangeExtras.products;
                                            this.activeRange = withDetails;
                                            this.activeRange$.next(withDetails);

                                            this.updatePanels();
                                        }

                                        this.activeRangeLoaded$.next(true);
                                    })
                                    .catch((error) => {
                                        this.activeRangeLoaded$.next(true);
                                        this.dialogService.error(this.constructor.name, error);
                                    });
                            } else {
                                this.activeRangeLoaded$.next(true);
                            }
                        })
                        .catch((error) => {
                            this.activeRangeLoaded$.next(true);
                            this.dialogService.error(this.constructor.name, error);
                        });
                } else {
                    this.activeRangeLoaded$.next(true);
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public updateActiveRange(range, rangeColour: string, colour: string = null, bespokeColour: string = null, carcaseColour: string = null) {
        if (colour !== null && typeof colour !== 'string') {
            // TODO: remove this
            this.dialogService.notice(
                'Active Range Error',
                'Please let a dev know that the active range is incorrect, with a screenshot of this page including the URL',
                true
            );
        }

        if (!carcaseColour) {
            this.activeRange = {
                range: {
                    id: range.range_id || range.id,
                    name: range.name,
                    isInframe: StringHelper.toBoolean(range.inframe),
                },
                rangeColour: rangeColour,
                colour: colour || rangeColour,
                bespokeColour: bespokeColour,
                carcaseColour: range && range.colours ? range.colours.slice().shift().def_carcase : 'Alabaster',
            };

            this.storageService
                .set('active-range', this.activeRange)
                .then(() => this.broadcastActiveRange())
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        } else {
            this.activeRange = {
                range: {
                    id: range.range_id || range.id,
                    name: range.name,
                    isInframe: StringHelper.toBoolean(range.inframe),
                },
                rangeColour: rangeColour,
                colour: colour || rangeColour,
                bespokeColour: bespokeColour,
                carcaseColour: carcaseColour,
            };

            this.storageService
                .set('active-range', this.activeRange)
                .then(() => this.broadcastActiveRange())
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        }
    }

    public getRanges(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => {
                    resolve(catalogue.ranges);
                })
                .catch((error) => reject(error));
        });
    }

    public get<T>(url?: string): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            const endpoint = url ? url : this.config.api.endpoints.catalogue.url;
            const options: HttpApiOptions = {
                responseType: 'text'
            };

            if (this.catalogues[endpoint]) {
                if (this.catalogues[endpoint].data) {
                    resolve(this.catalogues[endpoint].data);
                } else {
                    this.catalogues[endpoint].subject.pipe(first()).subscribe(() => {
                        resolve(this.catalogues[endpoint].data);
                    });
                }
            } else {
                this.catalogues[endpoint] = {
                    data: null,
                    subject: new Subject<void>(),
                };

                const endpointUrl = `${endpoint}?t=${DateHelper.now()}`;
                this.httpApi.get<any>(endpointUrl, options).subscribe({
                    next: (response: any) => {
                        const inflated = inflate(response, { to: 'string' });
                        const data = JSON.parse(inflated);
                        const catalogueDataPromise: Promise<Catalogue> = url
                            ? new Promise((resolve) => resolve(data))
                            : this.processAPIData(data);

                        catalogueDataPromise
                            .then((catalogueData) => {
                                this.catalogues[endpoint].data = catalogueData;
                                this.catalogues[endpoint].subject.next();

                                resolve(this.catalogues[endpoint].data);
                            })
                            .catch((error) => reject(error));
                    },
                    error: (error) => {
                        reject(error);
                    },
                });
            }
        });
    }

    /**
     * Set's the product's subCategoryName and subCategoryLink field, overriding whatever has come from the catalogue
     * @param product IAccessory, IAppliance, ICabinet, IDoor, IDrawerBox, IHandle, IRangeProduct, ISinksandTaps, IWorktop
     * @returns product
     */
    public setSubCategory(product: AllProductsUnion): AllProductsUnion {
        if (isRangeProduct(product)) {
            const sizeTag = product.size_tag || '';
            product.material = product.material || '';
            product.subCategoryName = product.subCategoryName || '';
            product.subCategoryLink = product.subCategoryLink || '';
            product.code = product.code || '';
            product.desc = product.desc || '';

            if (product.material === 'Carcase') {
                product.subCategoryName = 'Plinths, Fillers, Panels & Shelves (Carcase Material)';
            } else if (product.code.match(/^appdoor/i) || sizeTag.match(/^appdoor/i)) {
                product.subCategoryName = 'Appliance Doors';
            } else if (product.subCategoryName === 'Kitchen Corner Post') {
                product.subCategoryName = 'Corner Posts';
            } else if (
                product.subCategoryName === 'Radius Feature End' ||
                product.subCategoryName === 'Kitchen Radius Feature End' ||
                product.subCategoryName === 'Kitchen Corner Block' ||
                product.subCategoryName === 'Kitchen End Posts' ||
                product.subCategoryName.match(/pilaster/i) ||
                product.subCategoryName === 'Column' ||
                product.subCategoryName === 'Flying Shelf' ||
                product.subCategoryName === 'Kitchen Bottle Rack' ||
                product.subCategoryName === 'Kitchen Fly Over Shelf' ||
                product.subCategoryName === 'Kitchen Platerack'
            ) {
                product.subCategoryName = 'Decorative Items';
            } else if (product.subCategoryName === 'Kitchen Cooker Canopy' || product.subCategoryName === 'Kitchen Mantle') {
                product.subCategoryName = 'Mantles & Canopies';
            }

            product.subCategoryLink = StringHelper.spaceToDash(product.subCategoryName);

            if (product.subCategoryName === 'Kitchen Cornice & Pelmet' || product.subCategoryName === 'Kitchen Plinth') {
                product.subCategoryName = 'Cornice, Pelmet & Plinths';
                product.subCategoryLink = 'cornice-pelmet-plinths';
            } else if (product.subCategoryName === 'Plinths, Fillers, Panels & Shelves (Carcase Material)') {
                product.subCategoryLink = 'carcase-material';
            } else if (product.code.match(/^endpanel/i) || sizeTag.match(/^endpanel/i) || product.desc.match(/filler panel/i)) {
                product.subCategoryName = 'End Panels & Fillers';
                product.subCategoryLink = 'end-panels';
            }
        }
        return product;
    }

    public getRange<T>(rangeId: string, _headers?: HttpApiHeaders): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            const url = this.config.api.endpoints.catalogueRange.url.replace('{0}', rangeId);

            this.get<T>(url)
                .then((response: any) => {
                    if (response && response.products && response.products.length) {
                        this.getRangeById(rangeId)
                            .then((range) => {
                                response.products.forEach((product) => {
                                    if (!product.code) {
                                        product.code = product.eq_code;
                                    }

                                    if (!product.unit_code) {
                                        product.unit_code = product.code || product.eq_code;
                                    }

                                    const rangeLink = StringHelper.spaceToDash(range.name);
                                    const style = StringHelper.spaceToDash(`${rangeLink} ${product.colour}`);

                                    if (!product.media) {
                                        let image = this.getUnitAccessoryImage(
                                            product.code,
                                            product.size_tag,
                                            product.desc,
                                            product.height,
                                            rangeLink,
                                            style
                                        );
                                        product.media = {
                                            image: `${this.config.api.endpoints.cdn}/${image}`
                                        };

                                        if (product.code && product.diy_code && product.code !== product.diy_code) {
                                            product.media.altImage = `${this.config.api.endpoints.cdn}/${image.replace(product.code, product.diy_code)}`;
                                        }

                                        if (product.image && typeof product.image === 'string' && product.image.startsWith('/assets/')) {
                                            product.media.image = `${this.config.api.endpoints.cdn}${product.image}`;
                                        }
                                    }

                                    product._cost = product.price;
                                    product.categoryLink = 'accessories';

                                    product.subcat = product.sub_cat;
                                    product._sub_cat = product.sub_cat;

                                    product.subCategoryName = product.subcat;

                                    product = this.setSubCategory(product);
                                });
                                resolve(response);
                            })
                            .catch((error) => reject(error));
                    } else {
                        resolve(response);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getUnitAccessoryImage(code, sizeTag, description, height, rangeLink, style, doorStyle = 'Highline') {
        let image = null;
        code = code || '';
        description = description || '';
        const colour = style.split('-').slice(1).join('-') || 'alabaster';

        if (
            description.match(/plinth/i) ||
            description.match(/cornice/i) ||
            description.match(/pelmet/i) ||
            description.match(/corner post/i)
        ) {
        } else if (code.match(/canopy/i)) {
            image = 'assets/images/products/range_specific/master/cooker_canopy.jpg';
        } else if (description.match(/curved radius left/i)) {
            image = 'assets/images/products/range_specific/master/CBL.jpg'
        } else if (description.match(/curved radius right/i)) {
            image = 'assets/images/products/range_specific/master/CBR.jpg'
        } else if (description.match(/door/i) || description.match(/drawer/i) || code.match(/^\d{3,4}X\d{3}/i)) {
            if (description.match(/fluted glass/i)) {
                image = `assets/images/products/misc/cimageurl/BLFLU715497.jpg`;
            } else if (description.match(/internal curve/i)) {
                image = `assets/images/products/doors/${rangeLink}/${style.replace(' ', '-')}-intcurveddoor.jpg`;
            } else if (description.match(/curve/i)) {
                image = `assets/images/products/doors/${rangeLink}/${style.replace(' ', '-')}-curveddoor.jpg`;
            } else if (description.match(/glazed/i)) {
                let tagSize: string = sizeTag || code;
                if (tagSize.match(/^\d{3,4}X\d{3}G$/i)) {
                    tagSize += 'l';
                }

                if (rangeLink.match(/bramley/i)) {
                    image = `assets/images/products/doors/${rangeLink.replace(/true-handleless/i, 'th')}/${rangeLink.replace(/true-handleless/i, 'th')}-${colour.replace('gloss-', '')}-${tagSize.toLowerCase()}.jpg`;
                } else {
                    image = `assets/images/products/doors/${rangeLink.replace(/true-handleless/i, 'th')}/${rangeLink.replace(/true-handleless/i, 'th')}-${colour.replace('gloss-', '')}-glazed-door.jpg`;
                }
            } else if (height <= 175 && !description.match(/sample/i)) {
                image = `assets/images/products/doors/${rangeLink}/${style.replace(' ', '-')}-drawer.jpg`;
            } else if (height <= 355 && !description.match(/sample/i)) {
                image = `assets/images/products/doors/${rangeLink}/${style.replace(' ', '-')}-pandrawer.jpg`;
            } else if (description.match(/drawerline effect|drawer line set/i)) {
                image = `assets/images/products/doors/${rangeLink}/${style.replace(' ', '-')}-doordrawer.jpg`;
            } else if (code.match(/^\d{3,4}X\d{3}$/i) && rangeLink.match(/bramley/i)) {
                let tagSize = sizeTag || code;
                if (tagSize.match(/715cds/i)) {
                    tagSize = '715x313-pair';
                }
                image = `assets/images/products/doors/${rangeLink.replace(/true-handleless/i, 'th')}/${rangeLink.replace(/true-handleless/i, 'th')}-${colour.replace('gloss-', '')}-${tagSize.toLowerCase()}.jpg`;
            } else if (description.match(/door/i) || description.match(/corner wall set/i) || code.match(/^\d{3,4}X\d{3}/i)) {
                if (rangeLink && style) {
                    if (doorStyle === 'Drawerline' || style.match(/true-handleless/i)) {
                        image = `doors/${style.replace(/true-handleless/i, 'th').replace(/-| /g, '_')}_straight_on_500px.jpg`;
                    } else {
                        image = `assets/images/products/doors/${rangeLink.replace(/true-handleless/i, 'th').replace(' ', '-')}/${rangeLink.replace(/true-handleless/i, 'th').replace(' ', '-')}-${colour.replace('gloss-', '').replace(' ', '-')}-door.jpg`;
                    }
                }
            }
        }

        return image || `assets/images/products/range_specific/master/${code}.jpg`;
    }

    public getCatalogue(refresh?: boolean): Promise<Catalogue> {
        return new Promise<Catalogue>((resolve, reject) => {
            if (this.catalogue && !refresh) {
                resolve(this.catalogue);
            } else {
                this.get<Catalogue>()
                    .then((catalogue: Catalogue) => {
                        this.catalogue = catalogue;
                        resolve(this.catalogue);
                    })
                    .catch((error) => {
                        console.error('getCatalogue');
                        reject(error);
                    });
            }
        });
    }

    /**
     * Temporary for the legacy basket migration. To be removed later!
     */
    public getLegacyImageCatalogue(refresh?: boolean): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (this.legacyImageCatalogue && !refresh) {
                resolve(this.legacyImageCatalogue);
            } else {
                this.get<any>(this.config.api.endpoints.legacyImageCatalogue.url)
                    .then((catalogue: any) => {
                        this.legacyImageCatalogue = catalogue;
                        resolve(this.legacyImageCatalogue);
                    })
                    .catch((error) => reject(error));
            }
        });
    }

    /**
     * Retrieves the SearchTermsCatalogue used by the search component
     */
    public getSearchTermsCatalogue(refresh?: boolean): Promise<ISearchTermCatalogue> {
        return new Promise<ISearchTermCatalogue>((resolve, reject) => {
            if (this.searchTermCatalogue && !refresh) {
                resolve(this.searchTermCatalogue);
            } else {
                this.get<any>(this.config.api.endpoints.searchTermCatalogue.url)
                    .then((catalogue: ISearchTermCatalogue) => {
                        this.searchTermCatalogue = catalogue;
                        resolve(this.searchTermCatalogue);
                    })
                    .catch((error) => reject(error));
            }
        });
    }

    public getProductExtendedDetails(refresh?: boolean): Promise<IProductDetailsMap> {
        return new Promise<IProductDetailsMap>((resolve, reject) => {
            if (this.productExtendedDetails && !refresh) {
                resolve(this.productExtendedDetails);
            } else {
                this.get<IProductDetailsMap>(this.config.api.endpoints.productDetails.url)
                    .then((productExtendedDetails: IProductDetailsMap) => {
                        this.productExtendedDetails = productExtendedDetails;
                        resolve(this.productExtendedDetails);
                    })
                    .catch((error) => {
                        console.error('getProductExtendedDetails');
                        reject(error);
                    });
            }
        });
    }

    /**
     * Given the category link it returns a formatted string that can be used as a title.
     * Example getCategoryTitle('cornice-pelmet-plinths') -> 'Cornice, Pelmet and Plinths'.
     * Intended to be temporary and that this information will eventually come from the API / Catalogue data
     * @param categoryLink or subCategoryLink in its raw form from the product data.
     * @returns The title for the categoryLink formatted as a title
     */
    public getCategoryTitle(categoryLink: string): string {
        switch (categoryLink) {
            case 'cornice-pelmet-plinths':
                return 'Cornice, Pelmet and Plinths';
            case 'corner-posts':
                return 'Corner Post';
            case 'standard-corner':
                return 'Standard';
            case 'appliance-doors':
                return 'Appliance Door';
            case 'end-panels':
                return 'End Panel';
            case 'mantles-canopies':
                return 'Mantles and Canopies';
            case 'l-shaped':
                return 'L-Shaped';
            case 'l-shape':
                return 'L-Shape';
            case 'curved-and-s-shaped':
                return 'Curved and S-Shaped';
            case 'bi-fold':
                return 'Bi-Fold';
            case 'microwave':
                return 'Oven / Microwave';
            case 'single-oven-&-microwave':
                return 'Single Oven and Microwave';
            case 'double-oven-&-microwave':
                return 'Double Oven and Microwave';
            case 'compact-appliance':
                return 'Compact';
            case 'x2-compact-appliance':
                return 'X2 Compact';
            case 'reduced-depth-tall-corner':
                return 'Reduced Depth';
            case '50-50-fridge-freezers':
                return '50:50 Fridge Freezers';
            case '70-30-fridge-freezers':
                return '70:30 Fridge Freezers';
        }
        return StringHelper.titleCase(StringHelper.dashToSpace(categoryLink));
    }

    public getCategories(type: string) {
        return new Promise<any>((resolve, _reject) => {
            // TODO Move this data to ProdCMS
            const categoryData = {
                'kitchen-units': {
                    base: {
                        name: 'Base',
                        desc: 'Industry leading choice of over 500 practical and creative units',
                        imageCss: '',
                        image: 'categories/units/base_unit.jpg',
                        rank: 1,
                    },
                    'corner-base': {
                        name: 'Corner Base',
                        desc: 'A variety of corner unit types and options to choose from.',
                        image: 'categories/units/corner_base_unit.jpg',
                        rank: 2,
                    },
                    wall: {
                        name: 'Wall',
                        desc: 'With over 100 practical and creative wall units available, finding your perfect combination is simple.',
                        image: 'categories/units/wall_unit.jpg',
                        rank: 3,
                    },
                    'corner-wall': {
                        name: 'Corner Wall',
                        desc: 'With over 100 practical and creative wall units available, finding your perfect combination is simple.',
                        image: 'categories/units/corner_wall_unit.jpg',
                        rank: 4,
                    },
                    'appliance-housings': {
                        name: 'Appliance Housings',
                        desc: 'Units specifically design to house and support your appliances.',
                        image: 'categories/units/appliance_housings.jpg',
                        rank: 5,
                    },
                    tall: {
                        name: 'Tall',
                        desc: 'Create a perfect bridge between base & wall with innovative storage.',
                        image: 'categories/units/tall_unit.jpg',
                        rank: 6,
                    },
                    'tall-corner': {
                        name: 'Tall Corner',
                        desc: 'Full height corner units',
                        image: 'categories/units/tall-corner_walk_in_larder.jpg',
                        rank: 7,
                    },
                    dresser: {
                        name: 'Worktop Dresser',
                        desc: 'Worktop standing units, popular in traditional kitchen styles.',
                        image: 'categories/units/mantle_unit.jpg',
                        rank: 8,
                    },
                    accessories: {
                        name: 'Panels & Accessories',
                        desc: 'Add some extra decorative features to your kitchen',
                        image: 'categories/units/plinth_panels.jpg',
                        rank: 9,
                    },
                },
                appliances: {
                    cooking: {
                        name: 'Cooking',
                        desc: 'Ovens, Hobs, Extractors, Range cookers, Microwaves other compact appliances.',
                        image: 'appliances/appliance_choice/top_double_ovens.jpg',
                        rank: 1,
                    },
                    dishwashers: {
                        name: 'Dishwashers',
                        desc: 'Freestanding, fully and semi integrated dishwashers.',
                        image: 'appliances/appliance_choice/600_integrated_dishwasher.jpg',
                        rank: 4,
                    },
                    laundry: {
                        name: 'Laundry',
                        desc: 'Washing machines and Dryers, built in and stand alone.',
                        image: 'appliances/appliance_choice/washer_integrated.jpg',
                        rank: 3,
                    },
                    refrigeration: {
                        name: 'Refrigeration',
                        desc: 'Fridges, freezers and American Fridges, inbuilt and stand alone options.',
                        image: 'appliances/appliance_choice/top_american.jpg',
                        rank: 2,
                    },
                },
                'sinks-and-taps': {
                    sinks: {
                        name: 'Sinks',
                        desc: 'Ceramic, Composite, Stainless steel - Undermount, Inset and Belfast sinks.',
                        image: 'categories/sinks-and-taps/category_sinks_1000px.jpg',
                        rank: 1,
                    },
                    taps: {
                        name: 'Taps',
                        desc: 'Black, Brushed Steel, Brass, Chrome, Copper, Gold, Gunmetal, Nickel, Stainless Steel and more taps.',
                        image: 'categories/sinks-and-taps/category_taps_1000px.jpg',
                        rank: 2,
                    },
                },
                worktops: {
                    dekton: {
                        name: 'Dekton',
                        desc: 'Revolutionary material, designed to create the perfect kitchen worktop.',
                        image: 'worktops/dekton_category.jpg',
                        rank: 1,
                    },
                    ceramic: {
                        name: 'Ceramic',
                        desc: 'Revolutionary material, designed to create the perfect kitchen worktop.',
                        image: 'worktops/ceramic_category.jpg',
                        rank: 6,
                    },
                    granite: {
                        name: 'Granite',
                        desc: 'Granite is a resilient natural stone that brings luxury to any home.',
                        image: 'worktops/granite_category.jpg',
                        rank: 3,
                    },
                    quartz: {
                        name: 'Quartz',
                        desc: 'The look and feel of natural stone, yet available in a huge range of styles.',
                        image: 'worktops/quartz_category.jpg',
                        rank: 2,
                    },
                    laminate: {
                        name: 'Laminate',
                        desc: 'Hardwearing laminate worktops are the perfect cost-effective choice.',
                        image: 'worktops/laminate_category.jpg',
                        rank: 5,
                    },
                    'square-edge-laminate': {
                        name: 'Square Edge Laminate',
                        desc: 'Square edging allows you to encorprate corners and shapes with laminate worktops.',
                        image: 'worktops/laminate_category.jpg',
                        rank: 5,
                    },
                    'standard-laminate': {
                        name: 'Laminate',
                        desc: 'Hardwearing laminate worktops are the perfect cost-effective choice.',
                        image: 'worktops/laminate_category.jpg',
                        rank: 5,
                    },
                    'solid-wood': {
                        name: 'Solid wood',
                        desc: 'Beautiful solid timbers help to create genuine warmth and character.',
                        image: 'worktops/wood_category.jpg',
                        rank: 4,
                    },
                },
                handles: {
                    'bar-handles': {
                        name: 'Bar Handles',
                        desc: 'Modern bar handles have clean lines whilst being practical.',
                        image: 'categories/handles/category_handles_bar_1000px.jpg',
                        rank: 1,
                    },
                    'boss-bar-handles': {
                        name: 'Boss Bar Handles',
                        desc: 'Modern bar handles have clean lines whilst being practical.',
                        image: 'categories/handles/category_handles_bar_1000px.jpg',
                        rank: 1,
                    },
                    'd-handle': {
                        name: '',
                    },
                    'drop-handle': {
                        name: '',
                    },
                    'bow-and-bridge-handles': {
                        name: 'Bow Handles',
                        desc: 'Smooth bow handles give a less obtrusive feel.',
                        image: 'categories/handles/category_handles_bow_1000px.jpg',
                        rank: 2,
                    },
                    'cup-and-pull-handles': {
                        name: 'Cup Handles',
                        desc: 'Cup handles work great on drawer fronts.',
                        image: 'categories/handles/category_handles_cup_1000px.jpg',
                        rank: 3,
                    },
                    'profile-handles': {
                        name: 'Profile Handles',
                        desc: 'For a more concealed appearance.',
                        image: 'categories/handles/category_handles_profile_1000px.jpg',
                        rank: 6,
                    },
                    knobs: {
                        name: 'Knob Handles',
                        desc: 'A classical round or square knob handle.',
                        image: 'categories/handles/category_handles_knob_1000px.jpg',
                        rank: 5,
                    },
                    't-handle': {
                        name: '',
                    },
                },
                accessories: {
                    components: {
                        name: 'Components',
                        desc: 'A wide selection of components for your kitchen such as legs, plinth moisture barriers, & hinges.',
                        image: 'assets/images/menu/category_accessories_components.jpg',
                        rank: 1,
                    },
                    'cutlery-inserts': {
                        name: 'Cutlery Inserts',
                        desc: 'A selection of solid wood and plastic cutlery tray inserts available in a multitude of sizes',
                        image: 'categories/accessories/category_cutlery-inserts_1000px.jpg',
                        rank: 2,
                    },
                    'drawer-boxes': {
                        name: 'Drawer Boxes',
                        desc: 'We have a choice of over 20 Blum drawer boxes.',
                        image: 'assets/images/menu/category_accessories_drawer-boxes.jpg',
                        rank: 3,
                    },
                    'glass-shelves': {
                        name: 'Glass Shelves',
                        desc: 'Create even more of a wow factor with some glass shelves to adorn your wall units.',
                        image: 'assets/images/menu/category_accessories_glass-shelves.jpg',
                        rank: 4,
                    },
                    lighting: {
                        name: 'Lighting',
                        desc: 'Lighting options to make your kitchen shine at night.',
                        image: 'categories/accessories/category_lighting_1000px.jpg',
                        rank: 5,
                    },
                    'waste-systems': {
                        name: 'Waste Systems',
                        desc: 'Integrate a waste bin inside a unit, so that it is completely out of sight!',
                        image: 'assets/images/menu/category_accessories_waste-systems.jpg',
                        rank: 6,
                    },
                    worktops: {
                        name: 'Worktops',
                        desc: 'Keep worktops in top condition with Danish Oil, Hotrods and other accessories.',
                        image: 'assets/images/menu/category_worktops_solid-wood.jpg',
                        rank: 7,
                    },
                    // sinks: {
                    //     name: 'Sink Accessories',
                    //     desc: 'A selection of accessories from plumbing packs to colanders for your sink.',
                    //     image: 'categories/sinks-and-taps/category_sinks_1000px.jpg',
                    //     rank: 8,
                    // },
                    'unit-accessories': {
                        name: 'Unit Accessories',
                        desc: 'A wide choice of unit accessories to help finish your kitchen off nicely.',
                        image: 'assets/images/menu/category_accessories_unit_accessories.jpg',
                        rank: 9,
                    }
                },
                samples: {
                    'door-samples': {
                        name: 'FREE Door Samples',
                        descriptionHTML:
                            '<p>Choosing the right doors is probably the most important decision you will make.  That’s why you can choose up to 3 door samples, absolutely FREE*</p><p class="small subtext">(*excludes bespoke painted samples. £6 delivery fee applies)</p>',
                        image: 'assets/images/samples/kitchen-doors-block.jpg',
                        rank: 1,
                    },
                    'carcase-samples': {
                        name: 'FREE Cabinet Colour Samples',
                        desc: 'DIY Kitchen cabinets are available in ' + this.config.diy.carcases + ' colours, allowing you to match or contrast with the door colour. Samples of these colours are available for you to order, FREE of charge',
                        image: 'assets/images/samples/kitchen-units-block.jpg',
                        rank: 2,
                    },
                    'solid-surface-worktop-samples/quartz': {
                        name: 'Quartz Worktop Samples',
                        desc: 'Quartz worktops provide a refined and polished look to any kitchen, with a sophisticated appearance.',
                        image: 'assets/images/worktops/quartz-worktops-block.jpg',
                        rank: 3,
                    },
                    'solid-surface-worktop-samples/granite': {
                        name: 'Granite Worktop Samples',
                        desc: 'Granite worktops are the ultimate in kitchen work surfaces and give a high quality look and feel to a kitchen. Granite is quarried straight from the mountainside where it has laid for millions of years!',
                        image: 'assets/images/worktops/granite-worktops-block.jpg',
                        rank: 4,
                    },
                    'solid-surface-worktop-samples/dekton': {
                        name: 'Dekton Worktop Samples',
                        desc: 'Dekton worktops are one of the newest ultra compact solid surface worktops with an excellent portfolio of features that will make it a keen contender as the solid surface worktop of choice in the years to come.',
                        image: 'assets/images/worktops/dekton_worktop.jpg',
                        rank: 5,
                    },
                    'solid-surface-worktop-samples/solid-wood': {
                        name: 'Solid Timber Worktop Samples',
                        desc: 'You can’t beat the natural look and characteristics of solid timber – a worktop that ages well and lasts for years.',
                        image: 'assets/images/samples/solidwood-samples-block.jpg',
                        rank: 6,
                    },
                    'solid-surface-worktop-samples/laminate': {
                        name: 'Laminate Worktop Samples',
                        desc: 'You can’t beat the natural look and characteristics of solid timber – a worktop that ages well and lasts for years.',
                        image: 'assets/images/worktops/dekton_worktop.jpg',
                        rank: 7,
                    },
                },
            };

            resolve(categoryData[type]);
        });
    }

    public getHingeWording(unit: ICabinet): string {
        switch (unit.handingType) {
            case HandingType.PANEL:
                return 'Blank Panel';
            case HandingType.DRAWER:
                return 'Working Drawer';
            case HandingType.QUADRANT:
                return 'Quadrant';
            default:
                return 'Door Hinge';
        }
    }

    /**
     * processAPIData processing moved to catalogue.utils in the API!
     */
    private processAPIData(data): Promise<Catalogue> {
        return new Promise((resolve, reject) => {
            this.bespokeColours = (data['wallColours'] || []).map((bespokeColour) => {
                switch (bespokeColour.source) {
                    case 'Dulux':
                        bespokeColour.supplierCode = 'DLX';
                        break;
                    case 'Farrow & Ball':
                        bespokeColour.supplierCode = 'F&B';
                        break;
                    case 'Fired Earth':
                        bespokeColour.supplierCode = 'FE';
                        break;
                    case 'Howdens':
                        bespokeColour.supplierCode = 'HOW';
                        break;
                    case 'Little Greene':
                        bespokeColour.supplierCode = 'LG';
                        break;
                    case 'RAL':
                        bespokeColour.supplierCode = 'RAL';
                        break;
                    default:
                        console.warn('Missing Bespoke Colour: ', bespokeColour);
                        break;
                }

                bespokeColour.code = `${bespokeColour.supplierCode} - ${StringHelper.titleCase(bespokeColour.name)}`;

                return bespokeColour;
            });

            resolve(data);

        });
    }

    public getLayoutName(id?: Layouts) {
        switch (id) {
            case Layouts.STRAIGHT_RUN:
                return 'Straight Run';
            case Layouts.L_SHAPED:
                return 'L-Shaped';
            case Layouts.GALLEY:
                return 'Galley';
            case Layouts.U_SHAPED:
                return 'U-Shape';
            case Layouts.L_SHAPED_ISLAND:
                return 'L-Shaped with Island';
            case Layouts.U_SHAPED_ISLAND:
                return 'U-Shape with Island';
            default:
                return 'L-Shaped';
        }
    }

    public getLayoutImage(id?: Layouts) {
        switch (id) {
            case Layouts.STRAIGHT_RUN:
                return 'https://static.diy-kitchens.com/assets/images/kitchens/straight-run-layout.jpg';
            case Layouts.L_SHAPED:
                return 'https://static.diy-kitchens.com/assets/images/kitchens/lshaped-layout.jpg';
            case Layouts.GALLEY:
                return 'https://static.diy-kitchens.com/assets/images/kitchens/galley-layout.jpg';
            case Layouts.U_SHAPED:
                return 'https://static.diy-kitchens.com/assets/images/kitchens/ushaped-layout.jpg';
            case Layouts.L_SHAPED_ISLAND:
                return 'https://static.diy-kitchens.com/assets/images/kitchens/lshaped-island-layout.jpg';
            case Layouts.U_SHAPED_ISLAND:
                return 'https://static.diy-kitchens.com/assets/images/kitchens/ushaped-island-layout.jpg';
            default:
                return 'https://static.diy-kitchens.com/assets/images/kitchens/lshaped-layout.jpg';
        }
    }

    public getRangeByName(name: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => {
                    resolve(
                        catalogue.ranges.find((range) => range?.name?.toLowerCase()?.replace(/ /g, '-') === name?.toLowerCase()?.replace(/ /g, '-')) || undefined
                    );
                }
                )
                .catch((error) => reject(error));
        });
    }

    public getRangeByStyle(style: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => {
                    resolve(
                        catalogue.ranges.find((range) => {
                            return (
                                range.colours
                                    .map((colour) => {
                                        return `${range.name} ${colour.name}`.toLowerCase().replace(/ /g, '-');
                                    })
                                    .indexOf(style) !== -1
                            );
                        })
                    );
                })
                .catch((error) => reject(error));
        });
    }

    public getRangeById(rangeId?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            rangeId = rangeId || 'range001';

            this.getCatalogue()
                .then((catalogue) => {
                    const range = catalogue.ranges.find((r) => r.range_id === rangeId);
                    resolve(range);
                })
                .catch((error) => reject(error));
        });
    }

    public getRangeDetails(rangeId?: string, refresh?: boolean): Promise<any> {
        rangeId = rangeId || 'range001';

        return new Promise((resolve, reject) => {
            this.storageService
                .get('range')
                .then((range) => {
                    if (range && range.range_id === rangeId && !refresh) {
                        this.range = range;
                        resolve(range);
                    } else {
                        this.range = null;
                        const headers = {
                            'Content-Type': null,
                            // 'ultima-inflate': this.config.api.endpoints.catalogue.inflate ? 'yes' : null
                        };

                        this.getRange(rangeId, headers)
                            .then((liveRange) => {
                                this.range = liveRange;
                                resolve(liveRange);
                            })
                            .catch((error) => this.dialogService.error(this.constructor.name, error));
                    }
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        });
    }

    public getKitchenStyles(): Promise<any[]> {
        return new Promise<any[]>((resolve, reject) => {
            const filise = (str) => str.toLowerCase().replace(/ /g, '_');
            const styles = [];
            this.getRanges()
                .then((ranges: any) => {
                    ranges.map((range) => {
                        range.filters.map((style) => {
                            if (style === 'in frame') {
                                style = 'Inframe';
                            }

                            if (style === 'Slab door') {
                                style = 'Slab Door';
                            }

                            const catExists = styles.filter((opt) => opt.name === style);
                            const price = Object.keys(range.guidePrices)
                                .map((colour) => range.guidePrices[colour][Layouts.L_SHAPED].price)
                                .sort()[0];

                            if (catExists.length === 0) {
                                let title = '';
                                let description = '';

                                switch (style) {
                                    case 'High Gloss':
                                        title = 'Clean, contemporary & vibrant';
                                        description =
                                            "The clean lines and contemporary styling of high gloss kitchens create a bold statement in any home. The signature flat design and reflective surface can look great in a variety of finishes, from cool whites to vibrant reds, we'll have a colour for you.";
                                        break;
                                    case 'Shaker':
                                        title = 'Simplicity & versatility  make for a beautiful kitchen';
                                        description =
                                            'The beauty of a shaker style kitchen is in its simplicity. The characteristic features of a shaker style door are a square framed design with an inset flat centre panel. The versatility of the Shaker design means it can look great in wood-grain, matt and even gloss coloured finishes.';
                                        break;
                                    case 'Painted':
                                        title = 'Add a touch of luxury to your kitchen';
                                        description =
                                            "Our painted kitchen ranges are available in a variety of door styles, from shaker, traditional &amp; modern. Painted kitchens are also available from our 'extensive' pallet of colours, which allows you to customise the look of your kitchen, so that it looks exactly how you want it.";
                                        break;
                                    case 'Handleless':
                                        title = 'Minimalistic yet still achieves an aesthetic impact';
                                        description =
                                            "The combination of a flat slab door with an integrated handle profile, creates a clean contemporary linear look. This style is all about the door design, it's minimalistic yet still achieves an aesthetic impact with different colour options depending on style.";
                                        break;
                                    case 'Bespoke Painted':
                                        title = 'Your kitchen painted in any colour you can imagine';
                                        description =
                                            "Our bespoke painted option is available across most of our painted door styles. As we manufacture and paint our own doors, we are able to offer our 'Bespoke Paint' service, which allows you to create a truly personalised and unique kitchen.";
                                        break;
                                    case 'Modern':
                                        title = 'Clean lines and contemporary styling';
                                        description =
                                            'The clean simple lines of a modern kitchen help to create a calming uncluttered feel to a room. The flat, slab style features of a modern door make it an excellent pallet for our variety of finishes, whether simple block colours or exotic wood-grains.';
                                        break;
                                    case 'Traditional':
                                        title = 'Celebrate the purpose of a kitchen as a place to cook';
                                        description =
                                            'A traditional kitchen celebrates the main purpose of a kitchen, and that is as a place to cook. Functional accessories put practical pieces on display and intricate detailing and raised centre panels, beautiful pilasters, feature end panels and over hob mantels really do compliment the look.';
                                        break;
                                    case 'Inframe':
                                        title = 'Classically created and instantly recognisable by their design';
                                        description =
                                            'A classically created in-frame kitchen is instantly recognisable by its design. The appearance of a door enveloped by a frame instantly conjures images of traditional time-served craftsmanship.';
                                        break;
                                    case 'Slab Door':
                                        title = 'Simple, stylish and sophisticated';
                                        description =
                                            'A collection of matt and gloss slab doors in a wide variety of colours and textures.  Mix and match to create something truly unique to reflect your personality and style.';
                                        break;
                                    case 'Solid Wood':
                                        title = 'Elegant, individual and naturally characterful';
                                        description =
                                            'The charm of a solid wood kitchen is timeless, whether designed in a natural oak finish or when painted in a range of fashionable and on-trend colours.  From simple shakers to more traditional raised panel or in-frame doors, our solid wood range has it all.';
                                        break;
                                }

                                styles.push({
                                    name: style,
                                    count: 1,
                                    price: price,
                                    title: title,
                                    description: description,
                                    url: '/kitchens/' + style.toLowerCase().replace(/ /g, '-'),
                                    backgroundImage: 'styles/feature-images/kitchen-style_' + filise(style) + '.jpg',
                                });
                            } else {
                                catExists[0].count++;
                                if (price < catExists[0].price) {
                                    catExists[0].price = price;
                                }
                            }
                        });
                    });
                    resolve(styles);
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        });
    }

    public sortStyles(styles: string[]): void {
        if (styles && styles.length) {
            const ranks = {
                Shaker: 1,
                'High Gloss': 2,
                Handleless: 3,
                'Bespoke Painted': 4,
                Inframe: 5,
                Modern: 6,
                Traditional: 7,
                'Solid Wood': 8,
                'Slab Door': 9,
            };

            styles.sort((a, b) => {
                const aRank = ranks[a] || 999;
                const bRank = ranks[b] || 999;

                if (aRank < bRank) {
                    return -1;
                } else if (aRank > bRank) {
                    return 1;
                }

                return 0;
            });
        }
    }

    public getProductCategories() {
        return new Promise((resolve, reject) => {
            console.warn('call to productCategories needs sorting');
            resolve({});
        });
    }

    public getApplianceTree() {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => resolve(catalogue.appliance_tree))
                .catch((error) => reject(error));
        });
    }

    public getAppliances(): Promise<IAppliance[]> {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => {
                    if (catalogue.productAppliances) {
                        resolve(catalogue.productAppliances);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getGenericAppliances() {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => resolve(catalogue.flat_appliances))
                .catch((error) => reject(error));
        });
    }

    public getAppliance(code: string) {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => {
                    const appliance: IAppliance = catalogue.productAppliances.find((app) => app.code === code);
                    // Get extended details from product-data.json
                    if (appliance) {
                        this.getProductExtendedDetails()
                            .then((extendedDetails: IProductDetailsMap) => {
                                if (extendedDetails[appliance.code]) {
                                    appliance.extended_details = extendedDetails[appliance.code];
                                    appliance.extended_details.details = appliance.extended_details.details
                                        .map((detail) => StringHelper.extractSentencesFromHtml(detail))
                                        .flat();
                                }
                                resolve(appliance);
                            })
                            .catch((error) => reject(error));
                    } else {
                        resolve(appliance);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getApplianceByDetails(details: string) {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => {
                    const appliance: IAppliance = catalogue.productAppliances.find((item) => {
                        const itemDetails = StringHelper.cleanUrl(StringHelper.spaceToDash(`${item.description} ${item.code}`));

                        return itemDetails === details;
                    });

                    // Get extended details from product-data.json
                    if (appliance) {
                        this.getProductExtendedDetails()
                            .then((extendedDetails: IProductDetailsMap) => {
                                if (extendedDetails[appliance.code]) {
                                    appliance.extended_details = extendedDetails[appliance.code];
                                    appliance.extended_details.details = appliance.extended_details.details
                                        .map((detail) => StringHelper.extractSentencesFromHtml(detail))
                                        .flat();
                                }
                                resolve(appliance);
                            })
                            .catch((error) => reject(error));
                    } else {
                        resolve(appliance);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getApplianceHousingsByAperture(generic_id: string): Promise<any[]> {
        return new Promise<any[]>((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => {
                    const generic = catalogue.flat_appliances.find((gen) => gen.appliance_id === generic_id);
                    if (generic) {
                        if (generic.aperture.match(/siz/i) || generic.aperture.match(/sink/i)) {
                            const apertures = generic.aperture.split(',').map((apt) => apt.replace(/^.*siz/i, 'siz').trim());
                            const aperture_ids = catalogue.apertureMatrix
                                .filter(
                                    (a) =>
                                        apertures.indexOf(a.app1) !== -1 || apertures.indexOf(a.app3) !== -1 || apertures.indexOf(a.app3) !== -1
                                )
                                .map((matrix) => matrix.aperture_id);
                            // TODO: wtf?
                            resolve(
                                catalogue.cabinetsFlat
                                    .filter((unit) => aperture_ids.indexOf(unit.aperture) !== -1)
                                    .sort((a, b) => {
                                        // Sort order:
                                        // - Put wall units at the end of the list
                                        // - Otherwise sort on height, shortest first
                                        // ie. Base units -> Mid height -> Tall -> Wall
                                        const aWall = !(a.leg_needed && a.leg_needed.match(/y/i)),
                                            bWall = !(b.leg_needed && b.leg_needed.match(/y/i));
                                        if (aWall !== bWall) {
                                            return aWall ? 1 : -1;
                                        }
                                        if (a.height !== b.height) {
                                            return a.height - b.height;
                                        }

                                        // In event that heights are same, then sort on category rank
                                        return a.catRank - b.catRank;
                                    })
                            );
                        }
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getAccessories(): Promise<IAccessory[]> {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => resolve(catalogue.extras))
                .catch((error) => reject(error));
        });
    }

    public getAccessory(code: string) {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => {
                    const accessory = catalogue.extras.find((acc) => acc.product_code === code);
                    // Get extended details from product-data.json
                    if (accessory) {
                        this.getProductExtendedDetails()
                            .then((extendedDetails: IProductDetailsMap) => {
                                if (extendedDetails[accessory.product_code]) {
                                    accessory.extended_details = extendedDetails[accessory.product_code];
                                    accessory.extended_details.details = accessory.extended_details.details
                                        .map((detail) => StringHelper.extractSentencesFromHtml(detail))
                                        .flat();
                                }
                                resolve(accessory);
                            })
                            .catch((error) => reject(error));
                    } else {
                        resolve(accessory);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getAccessoryByDetails(details: string) {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => {
                    const accessory = catalogue.extras.find((item) => {
                        const itemDetails = StringHelper.cleanUrl(StringHelper.spaceToDash(`${item.product_code} ${item.desc}`));

                        return itemDetails === details;
                    });

                    // Get extended details from product-data.json
                    if (accessory) {
                        this.getProductExtendedDetails()
                            .then((extendedDetails: IProductDetailsMap) => {
                                if (extendedDetails[accessory.product_code]) {
                                    accessory.extended_details = extendedDetails[accessory.product_code];
                                    accessory.extended_details.details = accessory.extended_details.details
                                        .map((detail) => StringHelper.extractSentencesFromHtml(detail))
                                        .flat();
                                }
                                resolve(accessory);
                            })
                            .catch((error) => reject(error));
                    } else {
                        resolve(accessory);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getHandles(): Promise<IHandle[]> {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => resolve(catalogue.handles))
                .catch((error) => reject(error));
        });
    }

    public getHandle(code: string) {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => resolve(catalogue.handles.find((handle) => handle.product_code === code)))
                .catch((error) => reject(error));
        });
    }

    public getHandleByDetails(details: string) {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => {
                    const handle = catalogue.handles.find((item) => {
                        const itemDetails = StringHelper.cleanUrl(StringHelper.spaceToDash(`${item.product_code} ${item.desc}`));

                        return itemDetails === details;
                    });

                    // Get extended details from product-data.json
                    if (handle) {
                        this.getProductExtendedDetails()
                            .then((extendedDetails: IProductDetailsMap) => {
                                if (extendedDetails[handle.product_code]) {
                                    handle.extended_details = extendedDetails[handle.product_code];
                                    handle.extended_details.details = handle.extended_details.details
                                        .map((detail) => StringHelper.extractSentencesFromHtml(detail))
                                        .flat();
                                }
                                resolve(handle);
                            })
                            .catch((error) => reject(error));
                    } else {
                        resolve(handle);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getWorktops(): Promise<IWorktop[]> {
        return new Promise<any[]>((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => resolve(catalogue.worktops))
                .catch((error) => reject(error));
        });
    }

    public getWorktop(code: string) {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => resolve(catalogue.worktops.find((worktop) => worktop.code === code)))
                .catch((error) => reject(error));
        });
    }

    public getWorktopSubCategory(edgeProfile: string, worktopName: string) {
        return new Promise((resolve, reject) => {
            this.getWorktops()
                .then((worktops) => {
                    if (worktops) {
                        const products = worktops.filter((worktop) => {
                            return (
                                worktop.cat.toLowerCase() === edgeProfile.toLowerCase() &&
                                worktop.sub_cat.toLowerCase() === worktopName.toLowerCase()
                            );
                        });

                        resolve(products);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getGraniteOptions() {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => resolve(catalogue.graniteOptions))
                .catch((error) => reject(error));
        });
    }

    public getApertureMatrix() {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => resolve(catalogue.apertureMatrix))
                .catch((error) => reject(error));
        });
    }

    public getUnitsAndDoors(): Promise<ICabinet[]> {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => resolve(catalogue.cabinetsFlat))
                .catch((error) => reject(error));
        });
    }

    public getCabinetsFlat() {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => {
                    let flatCabinets = JSON.parse(JSON.stringify(catalogue.cabinetsFlat));
                    if (this.activeRange && this.activeRange?.rangeDetail?.isTrueHandleless) {
                        flatCabinets = catalogue.cabinetsFlat.filter((cab) => cab?.isTrueHandleless);
                    } else {
                        flatCabinets = catalogue.cabinetsFlat.filter((cab) => !cab?.isTrueHandleless);
                    }
                    resolve(flatCabinets);
                })
                .catch((error) => reject(error));
        });
    }

    public getUnitAndDoor(code: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => resolve(catalogue.cabinetsFlat.find((cab) => cab.unit_code === code)))
                .catch((error) => reject(error));
        });
    }

    public getImageForItem(item, doorStyle = 'Highline') {
        return new Promise((resolve, reject) => {
            const cdn = this.config.api.endpoints.cdn;
            const noImage = `${cdn}/assets/images/products/misc/cimageurl/noimage.jpg`;
            switch (item.group) {
                case ProductType.SAMPLE_CARCASE:
                case ProductType.SAMPLE:
                    const image = item.cat_img || item.categoryImage || noImage;

                    resolve({
                        code: item.code,
                        image: `${cdn}/${image}`,
                    });
                    break;
                case ProductType.CABINETS:
                    resolve({
                        code: item.code,
                        image: `${cdn}/units_cat/${this.fixCode(item.code)}.jpg`,
                    });
                    break;
                case ProductType.PANELS:
                case ProductType.RANGE_PRODUCTS:
                case ProductType.EXTERNAL_CURVED_CORNICE:
                case ProductType.CORNICE_PELMET:
                case ProductType.PLINTHS:
                case ProductType.DOORS:
                case ProductType.SAMPLE_DOORS:
                    // Special case for cornerposts
                    if (item.code && item.code.match(/cornerpost/)) {
                        resolve({
                            code: 'Corner post',
                            image: `${cdn}/assets/images/products/misc/cimageurl/cp30.jpg`,
                        });
                    } else {
                        this.getRangeByName(item.range?.replace(/ matt| gloss/i, ''))
                            .then((range) => {
                                if (range) {
                                    const rangeLink = StringHelper.spaceToDash(range.name);
                                    const rangeColour = item.rangeColour && item.rangeColour !== 'Bespoke' ? item.rangeColour : 'Alabaster';
                                    const style = StringHelper.spaceToDash(`${rangeLink} ${rangeColour.replace(/\s?gloss\s?/gi, '')}`);
                                    const image = this.getUnitAccessoryImage(
                                        item.code,
                                        item.size_tag,
                                        item.description,
                                        item.height,
                                        rangeLink,
                                        style,
                                        doorStyle
                                    );

                                    resolve({
                                        code: item.code,
                                        image: `${cdn}/${image}`,
                                    });
                                } else {
                                    resolve({
                                        code: item.code,
                                        image: noImage,
                                    });
                                }
                            })
                            .catch((error) => reject(error));
                    }
                    break;
                case ProductType.APPLIANCES:
                    resolve({
                        code: item.code,
                        image: `${cdn}/assets/images/products/appliances/large/${item.cat_img || item.categoryImage || item.code + '.jpg'}`,
                    });
                    break;
                case ProductType.SINK_AND_TAPS:
                    if (item.category.match(/sink/i)) {
                        resolve({
                            code: item.code,
                            image: `${cdn}/assets/images/products/sinks/cimageurl/${item.cat_img || item.categoryImage || item.code + '.jpg'}`,
                        });
                    } else {
                        resolve({
                            code: item.code,
                            image: `${cdn}/assets/images/products/misc/cimageurl/${item.cat_img || item.categoryImage || item.code + '.jpg'}`,
                        });
                    }
                    break;
                case ProductType.HANDLES:
                    resolve({
                        code: item.code,
                        image: `${cdn}/assets/images/products/handles/cimageurl/${item.cat_img || item.categoryImage || item.code + '.jpg'}`,
                    });
                    break;
                case ProductType.ACCESSORIES:
                    let imageURL = `${cdn}/assets/images/products/misc/cimageurl/${item.cat_img || item.categoryImage}`;
                    if (this.isCarcaseMaterial(item)) {
                        imageURL = `${cdn}/assets/images/general/grain.jpg`;
                    } else if (this.isCarcaseShelf(item)) {
                        const catImg = item?.categoryImage || '1_110.jpg';
                        imageURL = `${cdn}/assets/images/products/carcase_accessories/cimageurl/${catImg}`;
                    }

                    if (imageURL.match(/null/i) || item.category?.match(/regular doors|corner posts|kitchen frontal/i)) {
                        if (item.category?.match(/drawer boxes/i) && item.subCategory.match(/blum/i)) {
                            imageURL = `${cdn}/assets/images/products/misc/cimageurl/blumdrawer3.jpg`;
                        } else if (item.code.match(/CAREKIT/i)) {
                            const colour = StringHelper.spaceToDash(item.description.split(' - ')[1] || '');
                            if (colour) {
                                imageURL = `${cdn}/assets/images/products/misc/cimageurl/carekits_${colour}.jpg`;
                            } else {
                                imageURL = noImage;
                            }

                        } else if (!item.size_tag && !item.height &&
                            !item.description.match(/door/i) &&
                            !item.description.match(/drawer/i) &&
                            !item.code.match(/^\d{3,4}X\d{3}/i)
                        ) {
                            imageURL = `${cdn}/assets/images/products/misc/cimageurl/${item.code.replace(/\//g, '-')}.jpg`;
                        } else {
                            imageURL = cdn + '/' + this.getUnitAccessoryImage(
                                item.code,
                                item.size_tag,
                                item.description,
                                item.height,
                                this.activeRange?.range?.name.toLowerCase() || 'clayton',
                                (this.activeRange) ? `${this.activeRange?.range?.name}-${this.activeRange?.colour}`.toLowerCase() : 'clayton-alabaster',
                                doorStyle
                            );
                        }
                    }

                    resolve({
                        code: item.code,
                        image: imageURL
                    });
                    break;
                case ProductType.SAMPLE_WORKTOP:
                case ProductType.WORKTOPS:
                    const category = item.category
                        ? item.category.match(/laminate/i)
                            ? 'laminate'
                            : StringHelper.spaceToUnderscore(item.category)
                        : null;
                    const colour = item.colour ? StringHelper.spaceToUnderscore(item?.colour?.toLowerCase()) : null;
                    const worktopImage = `${cdn}/worktops/thumbs/${category}/${(item.cat_img || item.categoryImage?.toLowerCase()?.replace(/-/g, '_')?.replace('_thumb', '') || (colour + '.jpg')).split('/').pop()}`;
                    resolve({
                        code: item.code,
                        image: worktopImage.match(/null/i) ? noImage : worktopImage
                    });
                    break;
                default:
                    resolve({
                        code: item.code,
                        image: noImage,
                    });
                    break;
            }
        });
    }

    /**
     * Returns the generic cabinet colours for all the ranges.
     * Cabinet colours are the same across all ranges
     */
    public getCabinetColours(): Promise<CabinetColour[]> {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => resolve(catalogue.cabinetColours.filter((colour) =>
                    !colour.colour.match(/Alabaster/i) &&
                    !colour.colour.match(/Cashmere/i) &&
                    !colour.colour.match(/Heron Grey/i)
                )))
                .catch((error) => reject(error));
        });
    }

    public getSinksAndTaps(): Promise<ISinkAndTaps[]> {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => resolve(catalogue.sinkstaps))
                .catch((error) => reject(error));
        });
    }

    public getSinkOrTap(code: string) {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => resolve(catalogue.sinkstaps.find((product) => product.code === code)))
                .catch((error) => reject(error));
        });
    }

    public getSinkOrTapByDescription(description: string) {
        return new Promise((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => {
                    const sinkOrTap = catalogue.sinkstaps.find((item) => {
                        const itemDetails = StringHelper.cleanUrl(StringHelper.spaceToDash(item.desc));

                        return itemDetails === description;
                    });

                    if (sinkOrTap) {
                        this.getProductExtendedDetails()
                            .then((extendedDetails: IProductDetailsMap) => {
                                if (extendedDetails[sinkOrTap.code]) {
                                    sinkOrTap.extended_details = extendedDetails[sinkOrTap.code];
                                    sinkOrTap.extended_details.details = sinkOrTap.extended_details.details
                                        .map((detail) => StringHelper.extractSentencesFromHtml(detail))
                                        .flat();
                                }
                                resolve(sinkOrTap);
                            })
                            .catch((error) => reject(error));
                    } else {
                        resolve(sinkOrTap);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getItemByTypeAndCode(type: string, code: string): Promise<any> {
        switch (type) {
            case 'cabinets':
                return this.getUnitAndDoor(code);
            case 'appliances':
                return this.getAppliance(code);
            case 'accessories':
                return this.getAccessory(code);
            case 'worktops':
                return this.getWorktop(code);
        }

        return Promise.resolve(null);
    }

    public findClosestPanel(width, height, colour) {
        if (!this.panels) {
            this.updatePanels();
        }

        let colourRE = new RegExp('^' + colour.replace(/gloss/i, '').trim() + '$', 'i');
        if (colour.match(/bespoke/i)) {
            colourRE = new RegExp('bespoke', 'i');
        }

        const panel = this.panels.filter((prod) => width <= prod.width && height <= prod.height && prod.colour.match(colourRE)).shift();

        if (panel) {
            return panel;
        }

        return null;
    }

    private updatePanels() {
        // console.time('updatePanels()');
        const products = this.activeRange && this.activeRange.rangeDetail ? this.activeRange.rangeDetail.products : null;

        this.panels = products
            .filter((product) => {
                if (
                    (product.category_specific || product.cat_spec).match(/panel/i) &&
                    !product.desc.match(/carcase/i) &&
                    !product.desc.match(/tongue/i) &&
                    !product.desc.match(/T&G/i)
                ) {
                    const prod = JSON.parse(JSON.stringify(product));
                    prod.colour = prod.colour.replace(/gloss/i, '').trim();

                    return prod;
                }
            })
            .sort((a, b) => {
                if (a.height === b.height) {
                    return a.width - b.width;
                }

                return a.height - b.height;
            });
        // console.timeEnd('updatePanels()');
    }

    public openRangeSelector() {
        Promise.all([this.getCabinetColours(), this.getRanges()])
            .then(([carcaseColours, ranges]) => {
                let showPopup = true;

                if (this.config.isBrowser) {
                    if (document.body.clientWidth < 993) {
                        showPopup = false;
                    }
                }

                if (showPopup) {
                    this.dialogService
                        .custom(RangeSelectorDialogComponent, {
                            maxWidth: '100%',
                            panelClass: 'range-selector-dialog',
                            data: {
                                carcaseColours,
                                ranges,
                                activeRange: this.activeRange,
                            },
                        })
                        .then((response) => {
                            if (response) {
                                this.updateActiveRange(
                                    response.range,
                                    response.rangeColour,
                                    response.colour,
                                    response.bespokeColour,
                                    response.carcaseColour
                                );
                            }
                        })
                        .catch((error) => this.dialogService.error(this.constructor.name, error));
                } else {
                    this.navigationService.navigate(['/kitchens/allstyles'])
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public getReviews(): Promise<any[]> {
        return new Promise<any[]>((resolve, reject) => {
            if (this.reviews) {
                resolve(this.reviews);
            } else {
                const options: HttpApiOptions = {};

                const url = this.config.api.endpoints.customerReviews.url;

                this.get(url)
                    .then((response: any) => {
                        this.reviews = response;
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            }
        });
    }

    public getKitchensHeader(rangeType: string) {
        return new Promise<any>((resolve, reject) => {
            // TODO Move this data to API
            const headers = {
                allstyles: {
                    name: 'All Styles',
                    description:
                        "With over 100 different kitchen ranges on offer there's sure to be something that suits you and your lifestyle. We have a strong 30 year history of manufacturing high quality kitchen units at our state of the art factory here in West Yorkshire combined with a wealth of experience gained selling kitchens online over the last ten years.",
                    imageClass: 'content-header-kitchens-all-styles',
                },
                'slab-door': {
                    name: 'Slab Door',
                    description:
                        'Simple but stylish.  The ultra-modern appearance of a slab door style kitchen provides a minimalist backdrop and clean lines.   Whether you are looking for a high gloss or matt finish, in a plain colour or woodgrain effect, our range of slab doors covers the full spectrum.  If you’re looking for something unique, then Carrera Bespoke is the door for you, with the option to have this door painted in any colour you choose.',
                    imageClass: 'content-header-kitchens-modern',
                },
                modern: {
                    name: 'Modern',
                    description:
                        'The clean simple lines of a modern kitchen help to create a calming uncluttered feel to a room. The flat, slab style features of a modern door make it an excellent pallet for our variety of finishes, whether simple block colours or exotic wood-grains. The whole look can be transformed through clever mixing and matching of the finishes and textures and by introducing minimalist or bold handles to the mix. Modern kitchens can look fantastic in most types of houses and tend to be manufactured from some of the most hardwearing materials available to us.',
                    imageClass: 'content-header-kitchens-modern',
                },
                'high-gloss': {
                    name: 'High Gloss',
                    description:
                        'The clean lines and contemporary styling of a high gloss finish create a bold statement in any kitchen. The signature flat design and reflective surface can look great in a variety of finishes, from cool whites to vibrant reds. High gloss doors are easy to care for and extremely durable.',
                    imageClass: 'content-header-kitchens-high-gloss',
                },
                handleless: {
                    name: 'Handleless',
                    description:
                        "The combination of a flat slab door with an integrated handle profile creates a clean contemporary linear look. This style is all about the door design, it's minimalistic yet still achieves an aesthetic impact. Select white and natural tones for a more subdued look or bold colours for maximum effect. This style of door is finished in highly durable lacquers and looks great in any type of property.",
                    imageClass: 'content-header-kitchens-handleless',
                },
                'solid-wood': {
                    name: 'Solid Wood',
                    description:
                        'Solid wood kitchens are a timeless and enduring choice for those seeking both aesthetic appeal and durability in their kitchen design.  One of their hallmark characteristics is their authenticity and unique grain patterns, which adds a touch of rustic charm or modern sophistication, depending on the chosen wood type and finish. Choose from natural oak or a range of painted finishes and for those who want something totally unique, our bespoke painting service allows you to select any colour.',
                    imageClass: 'content-header-kitchens-traditional',
                },
                traditional: {
                    name: 'Traditional',
                    description:
                        'A traditional kitchen range celebrates the main purpose of a kitchen as a place to cook. Functional accessories put practical pieces on display such as crockery, vegetable baskets and wine racks. Intricate detailing and raised centre panels are a particular characteristic of a traditional style kitchen range and beautiful pilasters, feature end panels and over hob mantels compliment the look. Natural timber and painted finishes combine perfectly to complete the look. A traditional kitchen will bring a touch of elegance to any type of home, whether large or small, old or new and the durability of the materials used will ensure the kitchen is enjoyed for many years.',
                    imageClass: 'content-header-kitchens-traditional',
                },
                'bespoke-painted': {
                    name: 'Bespoke Painted',
                    description:
                        "Our bespoke painted option is available across most of our painted door styles. As we manufacture and paint our own doors, we are able to offer our 'Bespoke Paint' service which allows you to create a truly personalised and unique kitchen. If you've a favourite colour in mind, send us a sample of the colour or quote us a paint reference and we'll match your kitchen to it.",
                    imageClass: 'content-header-kitchens-bespoke-painted',
                },
                inframe: {
                    name: 'Inframe',
                    description:
                        'A classically created in-frame kitchen is instantly recognisable by its design. The appearance of a door enveloped by a frame instantly conjures images of traditional time-served craftsmanship. Manufactured using natural materials and finished in lacquer or paint, our in-frame kitchen ranges feature square framed doors with classic inset flat centre panels or more traditional detailed raised centre panels. An in-frame kitchen is both a practical and stylish option for the more discerning customer.',
                    imageClass: 'content-header-kitchens-in-frame',
                },
                shaker: {
                    name: 'Shaker',
                    description:
                        'The beauty of a shaker style kitchen is in its simplicity. The characteristic features of this style of door are a square framed design with an inset flat centre panel. The versatility of the Shaker design means it can look great in wood-grain, matt and even gloss coloured finishes. It is suitable for both a traditional and modern setting and tends to be both a versatile and hardwearing choice for family homes.',
                    imageClass: 'content-header-kitchens-shaker',
                },
            };

            const defaultHeader = {
                name: 'All Styles',
                description:
                    "With over 100 different kitchen ranges on offer there's sure to be something that suits you and your lifestyle. We have a strong 30 year history of manufacturing high quality kitchen units at our state of the art factory here in West Yorkshire combined with a wealth of experience gained selling kitchens online over the last ten years.",
                imageClass: 'content-header-kitchens-all-styles',
            }

            if (!headers[rangeType]) {
                this.dialogService.error(this.constructor.name, `No range found for ${rangeType} in catalogueService.getKitchenHeader()`);
            }

            resolve(headers[rangeType] || defaultHeader);
        });
    }

    public getStylesList() {
        return new Promise<any>((resolve, reject) => {
            this.getCatalogue()
                .then((catalogue) => {
                    let rangeList = [];
                    catalogue.ranges.forEach((range) => {
                        range.colours.forEach((colour) => {
                            rangeList.push({
                                range: range.name,
                                rangeId: range.range_id,
                                rangeColour: colour.name,
                                style: `${range.name} ${colour.name}`,
                                carcaseColour: colour.def_carcase,
                                title: range.name + '-' + colour.name,
                                doorImage: colour.door_image,
                                isInframe: StringHelper.toBoolean(range.inframe),
                            });
                        });
                    });

                    resolve(rangeList);
                })
                .catch((error) => reject(error));
        });
    }

    public getKitchenFinishes() {
        return new Promise<any>((resolve, reject) => {
            // TODO Move this data to API
            const finishes = [
                {
                    name: 'Wood',
                    description: 'Achieve a natural look with solid timber doors.',
                    count: 0,
                    price: 0,
                    image: 'door_finishes/door-finish_solid-oak.png',
                    colour: '#EBDBD6',
                },
                {
                    name: 'Painted',
                    description: 'Create your look with our painted wood doors in a variety of styles.',
                    count: 0,
                    price: 0,
                    image: 'door_finishes/door-finish_painted.png',
                    colour: '#ECECEC',
                },
                {
                    name: 'Smooth',
                    description: 'A smooth door surface gives a modern style in a multitude of colour options.',
                    count: 0,
                    price: 0,
                    image: 'door_finishes/door-finish_smooth-painted.png',
                    colour: '#E8DFEA',
                },
                {
                    name: 'Matt',
                    description: 'Our matt finish colour options provide an understated elegance.',
                    count: 0,
                    price: 0,
                    image: 'door_finishes/door-finish_matt.png',
                    colour: '#EEF1F5',
                },
                {
                    name: 'High Gloss',
                    description: 'For an ultra-modern look, choose from a variety of gloss finishes.',
                    count: 0,
                    price: 0,
                    image: 'door_finishes/door-finish_gloss.png',
                    colour: '#CDE7FC',
                },
                {
                    name: 'Wood effect',
                    description: 'All the appearance of wood, with high durabilty and low pricing.',
                    material: 'Melamine MFC',
                    count: 0,
                    price: 0,
                    image: 'door_finishes/door-finish_wood-effect.png',
                    colour: '#F2E5E5',
                },
            ];
            resolve(finishes);
        });
    }

    public getDoorForUnit(unitCode = 'P2D1-6', doorColour = 'Alabaster') {
        const masterUnits = this.catalogue.cabinetsFlat.filter((cab) => cab.unit_code === unitCode);
        let masterUnit;
        if (masterUnits && masterUnits.length) {
            masterUnit = masterUnits.pop();
        } else {
            return {};
        }
        let range = JSON.parse(JSON.stringify(this.activeRange.rangeDetail));
        let rangeColour = range.colours.find((colour) => colour.name === doorColour) || range.colours[0];
        let missingFrontals = [];
        let doors = {};
        const sizeTagMatch = (a, b) => a.toLowerCase() === b.toLowerCase();

        const addFrontals = (idx) => {
            const findProduct = (size_tag, qty) => {
                if (qty > 0) {
                    let prods = range.products.filter((prod) => {
                        return (
                            (prod.diy_prefix === rangeColour.diy_prefix || prod.if_suffix === rangeColour.diy_prefix) &&
                            (sizeTagMatch(prod.size_tag, size_tag) || sizeTagMatch(prod.alt_size_tag, size_tag))
                        );
                    });
                    if (prods.length === 1) {
                        doors[size_tag] = prods[0];
                    } else if (prods.length === 0) {
                        missingFrontals.push(size_tag);
                    } else {
                        prods.sort((a, b) => {
                            return sizeTagMatch(a.size_tag, size_tag) ? -1 : 0;
                        });
                        doors[size_tag] = prods[0];
                    }
                }
            };

            if (this.isPositive(range.inframe)) {
                let frontal = (masterUnit['if_door_' + idx] || '').toUpperCase().trim();
                if (frontal.length > 2) {
                    // Some entries are zeros, so ignore those
                    findProduct(frontal, masterUnit['if_door_' + idx + '_qty']);
                }
            } else {
                let frontal = (masterUnit['door_' + idx] || '').toUpperCase().trim();
                if (frontal.length > 2) {
                    // Some entries are zeros, so ignore those
                    findProduct(frontal, masterUnit['door_' + idx + '_qty']);
                }
            }
        };

        addFrontals(1);
        addFrontals(2);
        addFrontals(3);
        addFrontals(4);
        addFrontals(5);

        return doors;
    }

    public isPositive(yesNo) {
        if (!yesNo) {
            return false;
        }
        if (yesNo === true) {
            return true;
        }
        if (typeof yesNo === 'string') {
            switch (yesNo.toLowerCase().trim()) {
                case '1':
                case 'y':
                case 'yes':
                    return true;
                default:
                    return false;
            }
        } else if (typeof yesNo === 'number') {
            return yesNo > 0;
        }
        return false;
    }

    public search(term: string): Promise<ISearchTerm[]> {
        return new Promise((resolve, reject) => {
            this.getSearchTermsCatalogue()
                .then((searchTermCatalogue) => {
                    const results: ISearchTerm[] = [];
                    const searchItems = Object.keys(searchTermCatalogue);
                    searchItems.forEach((key) => {
                        if (searchTermCatalogue[key].keywords.toLowerCase().includes(term.toLowerCase())) {
                            results.push({ name: key, ...searchTermCatalogue[key] })
                        }
                    });
                    resolve(results);
                })
                .catch((error) => reject(error));
        });
    }

    public getBespokeColours(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            resolve(this.bespokeColours);
        });
    }

    public image(options: IImageOptions) {
        switch (options.type) {
            case ImageTypes.STYLES:
                const colour = StringHelper.spaceToUnderscore(options.colour)
                    .replace(/_gloss/g, '')
                    .replace(/gloss_/g, '');

                return `ranges/${options.rangeId}_${colour}_900px.jpg`;
            default:
                return 'assets/images/products/misc/cimageurl/noimage.jpg';
        }
    }

    public unsetActiveRange() {
        this.activeRange = {
            range: null,
            rangeColour: null,
            colour: null,
            bespokeColour: null,
            carcaseColour: null,
        };

        this.storageService
            .remove('active-range')
            .then(() => {
                // this.broadcastActiveRange();
                this.activeRange$.next(null);
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public getSampleDoors(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getRanges()
                .then((ranges: any) => {
                    let totalDoors = 0;
                    ranges.forEach(
                        (range) => (totalDoors += range.colours.filter((col) => !col.availability && !col.colour.match(/bespoke/i)).length)
                    );

                    let allProducts = [];
                    ranges.map((range) => {
                        range.colours = range.colours.filter((col) => !col.availability && !col.colour.match(/bespoke/i));

                        allProducts = allProducts.concat(
                            range.colours.map((col) => {
                                const style = `${range.name} ${col.name}`.toLowerCase().replace(/ /g, '-');

                                return {
                                    item: {
                                        desc: `Door Sample: ${range.name} ${col.name}`,
                                        style: `${range.name} ${col.name}`,
                                        cost: col.samplePrice || 0,
                                        code: col.sampleCode || '',
                                        rangeColour: col.name,
                                        range: range || null,
                                        image: `doors/${col.door_image}`,
                                        styleURL: `/kitchens/${style}/details/`,
                                    },
                                    filters: range.filters,
                                };
                            })
                        );
                    });

                    resolve(allProducts);
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        });
    }

    public isCarcaseMaterial = isCarcaseMaterial;
    public isCarcaseColoured = isCarcaseColoured;
    public isCarcaseShelf = isCarcaseShelf;
    private fixCode = fixCode;
    public setHandingType = setHandingType;
}
