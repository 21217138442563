import { Injectable } from '@angular/core';
import { Config } from '@app/config';

import { IWorkerCommand, IWorkerResponse } from '@app/utilities/workers/models';

import { test } from '@app/services/catalogue';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {
    public catalogue: Catalogue;
    
    constructor(
        private config: Config
    ) {
        this.catalogue = new Catalogue(this.config);
    }
}

class WorkerQueue {
    protected workerPool: IPoolWorker[] = [];
    protected busyWorkerPool: IPoolWorker[] = [];
    protected WORKER_MAX = 4;
    protected queue: IWorkerCommand[] = [];
    protected isBrowser: Boolean;
    protected promises: IStoredPromise[] = [];
    protected taskCount: number = 1;
    protected workerBusy: Boolean = false;

    constructor(
    ) {}

    protected initialiseWorkers(): void {
        this.workerPool.forEach((poolWorker) => {
            if (this.isBrowser && poolWorker.worker) {            
                poolWorker.worker.onmessage = (event) => this.handleResult(event);
                poolWorker.worker.onerror = (error) => this.handleError(error);
            }
        });
    }

    /**
     * Finds the stored promise for the result from the worker and resolves it.
     */
    protected handleResult(event: MessageEvent): void {
        const response = event.data ? event.data as IWorkerResponse : {} as IWorkerResponse;
        let success = response.success || false;
        let payload = response.payload || null;
        let workerId = response.workerId || null;
        let promise: IStoredPromise;

        if (response.taskId) {
            promise = this.promises.find((storedPromise) => storedPromise.taskId === event.data.taskId);
        }

        if (promise) {
            if (success) {
                promise.resolve(payload);
            } else {
                promise.reject(payload)
            }

            this.promises = this.promises.filter((storedPromise) => storedPromise.taskId !== promise.taskId);
        }

        this.taskFinished(workerId);
    }
    
    protected handleError(error: ErrorEvent): void {
        console.error(error);
        this.taskFinished();
    }

    /**
     * Creates the worker task and adds it to the queue of tasks for the worker pool
     * @param fallback For environments that do not support web-workers, such as SSR, it's vital to be able to run the command in the main thread
     */
    protected startTask(workerCommand: string, fallback: (params) => Promise<any>, params: any): Promise<any> {
        // Temporary whilst I look into worker problem with JIT compiler!
        return new Promise((resolve) => {
            return resolve(fallback(params));
        });

        if (!this.isBrowser) {
            return new Promise((resolve) => {
                return resolve(fallback(params));
            });
        }

        let resolveFn: (value: any) => void;
        let rejectFn: (reason?: any) => void;
        const taskPromise = new Promise((resolve, reject) => {
            resolveFn = resolve;
            rejectFn = reject;
        });

        this.promises.push({ taskId: this.taskCount, promise: taskPromise, resolve: resolveFn, reject: rejectFn });

        const workerTask: IWorkerCommand = {
            taskId: this.taskCount,
            command: workerCommand,
            args: params,
        }

        this.addTaskToQueue(workerTask);

        this.taskCount ++;
        return taskPromise;
    }

    protected addTaskToQueue(workerTask: IWorkerCommand): void {
        this.queue.push(workerTask);
        this.nextTaskFromQueue();
    }

    protected nextTaskFromQueue(): void {
        if (this.queue.length && this.workerPool.length) {
            const poolWorker = this.workerPool.shift();
            const workerTask = this.queue.shift();
            workerTask.workerId = poolWorker.workerId;
            this.busyWorkerPool.push(poolWorker);
            poolWorker.worker.postMessage(workerTask);
        }
    }

    protected taskFinished(workerId?: number): void {
        if (workerId) {
            const poolWorker = this.busyWorkerPool.find((worker) => worker.workerId === workerId);
            if (poolWorker) {
                this.workerPool.push(poolWorker);
                this.busyWorkerPool = this.busyWorkerPool.filter((poolWorker) => poolWorker.workerId !== workerId);
            }
        }
        this.nextTaskFromQueue();
    }
}

class Catalogue extends WorkerQueue{
    constructor(
        private config: Config
    ) {
        super();
        this.isBrowser = this.config.isBrowser && typeof Worker !== 'undefined';
        this.isBrowser = false; // Temporary whilst I look into the issue with the worker and JIT compiler!
        if (this.isBrowser) {
            for (let i = 1; i <= this.WORKER_MAX; i++) {
                this.workerPool.push(
                    {
                        workerId: i,
                        worker: new Worker(new URL('../../utilities/workers/catalogue.worker', import.meta.url), { type: 'module' })
                    } as IPoolWorker
                )
            }

            this.initialiseWorkers();
        }
    }

    public test(): Promise<any> {
        return this.startTask('Test', test, {});
    }
}

interface IStoredPromise {
    taskId: number;
    promise: Promise<any>;
    resolve: (value: any) => void;
    reject: (reason?: any) => void;
}

interface IPoolWorker {
    workerId: number;
    worker: Worker;
}
