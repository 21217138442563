export enum StreamType {
    LOCAL = 1,
    REMOTE = 2
}

export interface StreamModel<T> {
    data: T[];
    length: number;
    pageIndex?: number;
    pageSize?: number;
    type?: StreamType;
}

export function Stream<T>(resetIndex: boolean): Promise<StreamModel<T>> { return null; }
