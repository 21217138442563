import { NgModule } from '@angular/core';

import { CurrencyFormatPipe } from './currency-format.pipe';
import { MergeUniquePipe } from './merge-unique.pipe';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { SentenceCasePipe } from './sentence-case.pipe';
import { SafePipe } from './safe.pipe';

@NgModule({
    exports: [
        CurrencyFormatPipe,
        MergeUniquePipe,
        SanitizeHtmlPipe,
        SentenceCasePipe,
        SafePipe,
    ],
    declarations: [
        CurrencyFormatPipe,
        MergeUniquePipe,
        SanitizeHtmlPipe,
        SentenceCasePipe,
        SafePipe
    ]
})
export class PipesModule { }
