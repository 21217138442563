import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Config } from '@app/config';
import { RemoteDataStream, TableDataModel } from '@app/utilities/table-data';
import { StringHelper } from '@app/utilities/helpers';

import { BasketService } from '@app/services/basket';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { OrdersService } from '@app/services/orders';

@Component({
    selector: 'page-baskets',
    templateUrl: './baskets.page.html',
    styleUrls: ['./baskets.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class BasketsPage implements OnInit {
    public loaded: boolean = false;

    public tableData: TableDataModel<any>;
    public displayColumns: string[] = [
        'basket',
        'cost',
        'style',
        'actions'
    ];

    private baskets: any = [];
    private stream: RemoteDataStream<any>;

    constructor(
        private config: Config,
        private basketService: BasketService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private ordersService: OrdersService
    ) {
        navigationService.setNavigation({
            title: 'DIY Kitchens  Your Baskets',
            metaTags: [
                { name: 'nometa', content: 'no metadata' }
            ],
            routeHistory: [
                { title: 'Support', route: '/support' },
                { title: 'Your baskets', route: '/account/baskets' }
            ]
        });
    }

    ngOnInit() {
        this.tableData = new TableDataModel<any[]>({
            remoteDataStream: new RemoteDataStream()
        });
        this.stream = <RemoteDataStream<any[]>>this.tableData.stream;

        this.stream.loader(this.loadBasketsForStream());
        this.stream.load(true)
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    private loadBasketsForStream() {
        return (resetIndex: boolean = false) => {
            return new Promise<any>((resolve, reject) => {
                if (this.baskets && this.baskets.length) {
                    resolve({ data: this.baskets || [], length: (this.baskets || []).length });
                } else {
                    this.ordersService.getDiyBaskets()
                        .then((baskets: any) => {
                            this.loaded = true;
                            if (baskets && baskets.length) {
                                baskets.forEach((basket) => {
                                    let ranges = {};
                                    let styles = [];

                                    basket.itemQty = 0;
                                    basket.items.forEach((item) => {
                                        if (item.rangeId && item.range && item.rangeColour) {
                                            if (!ranges[item.rangeId]) {
                                                ranges[item.rangeId] = {
                                                    rangeId: item.rangeId,
                                                    rangeName: item.range,
                                                    isInframe: item.isInframe,
                                                    isTrueHandleless: null,
                                                    colours: []
                                                };
                                            }

                                            const style = `${item.range} ${item.rangeColour}`;
                                            if (styles.indexOf(style) === -1) {
                                                styles.push(style);
                                                const swatch = `innova-${StringHelper.kebabCase(item.rangeColour)}`
                                                ranges[item.rangeId].colours.push({
                                                    style: style,
                                                    styleGroup: style,
                                                    name: item.rangeColour,
                                                    swatch: swatch
                                                });
                                            }
                                        }

                                        if (item.qty) {
                                            basket.itemQty += item.qty;
                                        }
                                    });

                                    basket.styles = Object.values(ranges);
                                });
                            }

                            this.baskets = baskets || [];

                            resolve({ data: baskets || [], length: (baskets || []).length });
                        })
                        .catch((error) => this.dialogService.error(this.constructor.name, error));
                }
            });
        };
    }

    public openPlan(planId: number) {
        window.open(`${this.config.api.endpoints.plannerUI}/basket/checkout/?pid=${planId}`, '_new');
    }

    public view(basket) {
        this.basketService.switch(basket.uuid)
            .then(() => {
                this.navigationService.navigate(['/basket']);
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public recover() {
        this.navigationService.navigate(['/support/basket-recovery']);
    }

    public create() {
        this.dialogService.input(
            'New Basket',
            'Enter New Basket Name',
            {
                data: {
                    buttons: [
                        { label: 'Cancel', colour: 'secondary', action: false },
                        { label: 'Create', action: true }
                    ]
                }
            }
        )
            .then((response) => {
                if (response.action) {
                    this.basketService.clearAll(true, true, response.input)
                        .then(() => {
                            this.navigationService.navigate(['/basket']);
                        })
                        .catch((error) => this.dialogService.error(this.constructor.name, error));
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error.message));
    }

    public rename(basket) {
        this.dialogService.input(
            'Rename Basket',
            'Enter New Basket Name',
            {
                data: {
                    defaultValue: basket.name,
                    buttons: [
                        { label: 'Cancel', colour: 'secondary', action: false },
                        { label: 'Rename', action: true }
                    ]
                }
            }
        )
            .then((response) => {
                if (response.action) {
                    if (response.input && response.input.length > 6) {
                        this.basketService.rename(response.input, basket.uuid)
                            .then(() => {
                                basket.name = response.input;
                                this.stream.load(true)
                                    .catch((error) => this.dialogService.error(this.constructor.name, error));
                            })
                            .catch((error) => this.dialogService.error(this.constructor.name, error));
                    } else {
                        this.dialogService.error(this.constructor.name, 'Please make sure you enter more than 6 characters.');
                    }
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error.message));
    }

    public remove(basket): Promise<void> {
        return new Promise((resolve, reject) => {
            this.dialogService.prompt(
                'Delete basket?',
                `Are you sure you wish to delete basket '${basket.name}'? (This is irreversible)`,
                {
                    data: {
                        buttons: [
                            { label: 'Cancel', colour: 'secondary', action: false },
                            { label: 'Delete basket', colour: 'warn', action: true }
                        ]
                    }
                }
            )
                .then((response) => {
                    if (response) {
                        this.basketService.remove(basket.uuid)
                        this.baskets = this.baskets.filter((basketEntry) => basketEntry.uuid !== basket.uuid);
                        this.stream.load(true)
                            .catch((error) => this.dialogService.error(this.constructor.name, error));

                        resolve();
                    } else {
                        reject();
                    }
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error.message));
        });
    }

    public duplicate(basket): Promise<void> {
        return new Promise((resolve, reject) => {
            this.dialogService.prompt(
                'Duplicate basket?',
                `Are you sure you wish to duplicate the basket '${basket.name}'?`,
                {
                    data: {
                        buttons: [
                            { label: 'Cancel', colour: 'secondary', action: false },
                            { label: 'Duplicate basket', action: true }
                        ]
                    },
                }
            )
                .then((response) => {
                    if (response) {
                        this.basketService.duplicateBasket(basket.uuid)
                            .then((response) => {
                                this.basketService.switch(response);
                                this.navigationService.navigate(['/basket']);
                            })
                            .catch((error) => this.dialogService.error(this.constructor.name, error));
                        resolve();
                    } else {
                        resolve();
                    }
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error.message));
        });
    }
}

