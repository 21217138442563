@import '/src/theme/variables';

component-mega-menu {

    .hidden {
        display: none;
    }

    .mega-menu-backdrop {
        position: fixed;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        z-index: 999;

        &.full-screen {
            top: 0;
        }
    }

    .dropdown-menu {
        position: absolute;
        left: 0;
        background-color: color('white');
        width: 100%;
        z-index: 1000;

        .dropdown-grid-wrapper {
            display: flex;
            padding: 25px 0 0 15px;

            .tab-list {
                flex: 0 0 25%;
                padding-right: 15px;
                border-right: 1px solid color('copy-border');

                .tab {
                    padding: 0 16px;
                    color: color('black');
                    font-weight: 600;
                    line-height: 48px;
                    text-transform: uppercase;
                    cursor: pointer;

                    &.active {
                        background-color: color('selection');
                        color: color('white');
                    }
                }
            }

            .grid-template-5 {
                padding-right: 15px;
            }

            .dropdown-block {
                margin-bottom: 25px;
                padding: 0 15px;

                .dropdown-block-content {
                    a {
                        &:hover {
                            opacity: 0.8;
                        }
                    }

                    h3 {
                        margin: 0;
                        padding-top: 16px;
                        color: black;
                        font-size: 14px;
                        line-height: 28px;
                        text-transform: uppercase;
                    }

                    img {
                        width: 100%;
                    }
                }

                .dropdown-swatch-content {
                    a {
                        display: flex;
                        align-items: center;

                        &:hover {
                            opacity: 0.8;
                        }

                        h3 {
                            margin: 0;
                            font-size: 0.875rem;
                            font-weight: 400;
                        }

                        .swatch {
                            flex-shrink: 0;
                            margin: 0 15px;
                            width: 48px;
                            height: 48px;
                        }
                    }
                }
            }

            .additional-items {
                margin-top: 26px;
            }
        }
    }

    .sub-links {
        padding: 20px 10px;
        border-top: 1px solid color('copy-border');

        .bottom-links {
            align-self: center;
            justify-self: center;
            padding: 16px 10px;
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }

            &.planner-link {
                background-color: color('yellow');
            }

            a {
                display: flex;
                align-items: center;

                span {
                    margin-left: 8px;
                    font-size: 0.875rem;
                    font-weight: 400;
                }
            }
        }
    }
}