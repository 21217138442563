<header class="content-contained content-header-background">
    <h1>Oops that page is missing</h1>
    <p>Sorry, we can't find that page. It may be an old link or possibly been moved. Hopefully one of the links below will get you back on track.</p>
    <div class="row">
        <div class="column">
            <p class="bold">Products</p>
            <ul>
                <li><a routerLink="/kitchens" title="Kitchens" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'Kitchens',  '404-Click']);">Kitchens</a></li>
                <li><a routerLink="/kitchen-units/base" title="Units" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'Units',  '404-Click']);">Units</a></li>
                <li><a routerLink="/kitchen-doors" title="Doors" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'Doors',  '404-Click']);">Doors</a></li>
                <li><a routerLink="/appliances" title="Appliances" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'Appliances',  '404-Click']);">Appliances</a></li>
                <li><a routerLink="/sinks-and-taps" title="Sinks &amp; Taps" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'SinksTaps',  '404-Click']);">Sinks &amp; Taps</a></li>
                <li><a routerLink="/worktops" title="Worktops" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'Worktops',  '404-Click']);">Worktops</a></li>
                <li><a routerLink="/samples" title="Samples" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'Samples',  '404-Click']);">Samples</a></li>
                <li><a routerLink="/accessories" title="Accessories" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'Accessories',  '404-Click']);">Accessories</a></li>
            </ul>
        </div>
        <div class="column">
            <p class="bold">Support</p>
            <ul>
                <li><a routerLink="/support" title="Speak With Us" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'SpeakWithUs',  '404-Click']);">Speak with us</a></li>
                <li><a routerLink="/about-diy-kitchens/showroom" title="Directions to showroom" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'DirectionsToShowroom',  '404-Click']);">Directions to showroom</a></li>
                <li><a href="https://advice.diy-kitchens.com" target="_blank" title="Advice" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'Advice',  '404-Click']);">Advice</a></li>
                <li><a href="https://advice.diy-kitchens.com/customer-questions" target="_blank" title="Frequently asked questions" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'FrequentlyAskedQuestions',  '404-Click']);">FAQ's</a></li>
            </ul>
        </div>
        <div class="column">
            <p class="bold">About us</p>
            <ul>
                <li><a routerLink="/about-diy-kitchens/history" title="Our History" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'OurHistory',  '404-Click']);">Our history</a></li>
                <li><a routerLink="/about-diy-kitchens/showroom" title="Online Showroom Tour" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'OnlineShowroomTour',  '404-Click']);">Online showroom tour</a></li>
                <li><a routerLink="/about-diy-kitchens/price-and-quality-comparison" title="Competitor Comparison" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'CompetitorComparison',  '404-Click']);">Competitor comparison</a></li>
            </ul>
        </div>
        <div class="column">
            <p class="bold">Terms</p>
            <ul>
                <li><a routerLink="/terms-and-conditions" title="Terms of Site Use" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'TermsOfSiteUse',  '404-Click']);">Terms of site use</a></li>
                <li><a routerLink="/privacy-policy" title="Privacy Policy" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'PrivacyPolicy',  '404-Click']);">Privacy policy</a></li>
                <li><a routerLink="/cookie-policy" title="Cookie Preferences" onclick="_gaq.push(['_trackEvent', '404ErrorPage', 'CookiePreferences',  '404-Click']);">Cookie preferences</a></li>
            </ul>
        </div>
    </div>
</header>