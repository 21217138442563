import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

import { BasketService } from '@app/services/basket';
import { DialogService } from '@app/services/dialog';

@Component({
    selector: 'component-transfer-basket-dialog',
    templateUrl: './transfer-basket.dialog.component.html',
    styleUrls: ['./transfer-basket.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class TransferBasketDialogComponent {
    public createQuote: boolean = false;
    public quoteFormGroup: FormGroup;
    public customer;

    constructor(
        private formBuilder: FormBuilder,
        private basketService: BasketService,
        private dialogService: DialogService,
        private dialogRef: MatDialogRef<TransferBasketDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.quoteFormGroup = this.formBuilder.group({
            competitor: new FormControl(''),
            competitorPrice: new FormControl(''),
            leadInformation: new FormControl(''),
            quoteDetails: new FormControl('')
        });
    }

    public customerFound(customer) {
        this.createQuote = true;
        this.customer = customer;
    }

    public changeCustomer() {
        this.createQuote = false;
        this.customer = null;
    }

    public submitQuote() {
        this.dialogService.prompt(
            'Confirm Quote',
            'Are you sure you want to send this quote?',
            {
                data: {
                    buttons: [
                        { label: 'cancel', action: false, type: 'stroked', colour: 'secondary' },
                        { label: 'Send Quote', action: true }
                    ]
                },
                minWidth: '380px'
            })
            .then((response) => {
                if (response) {
                    const customerId = this.customer.id;
                    const competitor = this.quoteFormGroup.controls.competitor.value;
                    const competitorPrice = this.quoteFormGroup.controls.competitorPrice.value;
                    const leadInformation = this.quoteFormGroup.controls.leadInformation.value;
                    const quoteDetails = this.quoteFormGroup.controls.quoteDetails.value;

                    this.basketService.createQuote(
                        customerId,
                        competitor,
                        competitorPrice,
                        leadInformation,
                        quoteDetails
                    )
                        .then(() => {
                            this.dialogService.snackbar('Quote has been sent', 5000);
                            this.dialogRef.close(null);
                        })
                        .catch((error) => this.dialogService.error(this.constructor.name, error));
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public close() {
        this.dialogRef.close(null);
    }
}
