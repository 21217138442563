import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

import { Config } from '@app/config';

import { BasketService } from '@app/services/basket';
import { DialogService } from '@app/services/dialog';

import { BasketItem, IBasketUpdateItem } from '@app/services/basket/models';

@Component({
    selector: 'component-basket-update-button',
    templateUrl: './basket-update-button.component.html',
    styleUrls: ['./basket-update-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class BasketUpdateButtonComponent {
    @Input() item: BasketItem;
    @Input() updates: IBasketUpdateItem;
    @Input() disabled: boolean = false;
    @Output() status = new EventEmitter<'success' | 'failure'>();

    public updatingBasket: boolean = false;
    public updatedBasket: boolean = null;

    constructor(
        private config: Config,
        private basketService: BasketService,
        private dialogService: DialogService
    ) { }

    public updateBasketItem(item: BasketItem, updates: IBasketUpdateItem) {
        if (typeof item === 'object' && Object.hasOwn(item, 'code')) {
            this.updatingBasket = true;
            this.basketService.updateItem(this.item.id, updates)
                .then(() => {
                    this.updatingBasket = false;
                    this.updatedBasket = true;

                    if (this.config.isBrowser) {
                        setTimeout(() => this.updatedBasket = null, 2000);
                    } else {
                        this.updatedBasket = null;
                    }
                    this.status.emit('success');
                })
                .catch((error) => {
                    this.updatingBasket = false;
                    this.updatedBasket = false;

                    if (this.config.isBrowser) {
                        setTimeout(() => this.updatedBasket = null, 2000);
                    } else {
                        this.updatedBasket = null;
                    }
                    this.status.emit('failure');
                    this.dialogService.error(this.constructor.name, error)
                });
        } else {
            this.updatingBasket = false;
            this.updatedBasket = false;

            if (this.config.isBrowser) {
                setTimeout(() => this.updatedBasket = null, 2000);
            } else {
                this.updatedBasket = null;
            }
            this.status.emit('failure');
            this.dialogService.error(this.constructor.name, `Invalid basket item for update: ${JSON.stringify(item)}`);
        }
    }
}
