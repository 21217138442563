<header class="content-contained">
    <h1>Shop Online & Stay Safe</h1>
    <p>This short guide will give you advice on <em><strong>buying from kitchen companies</strong></em> on the Internet
        in the UK and how to research the legitimacy of the ones that you've never heard of. You can either watch the
        video or read the information below.</p>
</header>

<hr class="narrow" />

<section class="content-contained">
    <div class="panel">
        <div class="panel-left">
            <div class="video" (click)="showVideo('https://www.youtube.com/embed/DFEHcsquHR4?rel=0')">
                <mat-icon>play_circle_filled</mat-icon>
                <img src="https://img.youtube.com/vi/DFEHcsquHR4/maxresdefault.jpg" width="100%"
                    alt="Shop online & stay safe. Read out 3 minute educational guide before you buy online.">
            </div>
        </div>
        <div class="panel-right">
            <h3 class="playfair">What to watch out for</h3>
            <ul>
                <li><strong><em>Poor quality</em></strong> kitchen units or none at all</li>
                <li><strong><em>No customer service</em></strong> from the company that sold the kitchen</li>
                <li><strong><em>No way</em></strong> of obtaining missing parts or to<em><strong> chase
                            issues</strong></em> after delivery</li>
                <li>Paid by cash and were <em><strong>unable to claim</strong></em> any form of
                    <em><strong>refund</strong></em></li>
            </ul>
            <p>Please don't let this happen to you!</p>
        </div>
    </div>
</section>

<hr class="narrow" />

<section class="content-contained">
    <h3>Basic Trust Factors</h3>
    <p>When buying from an online UK company, the following basic information should <strong>ALWAYS</strong> be
        available somewhere on the website.</p>
    <ul>
        <li>A UK phone number</li>
        <li>A company registration number</li>
        <li>A VAT registration number</li>
        <li>A registered office address (This is a<em><strong> legal requirement</strong></em> in the UK -<em><strong>
                    Companies Act 2006</strong></em>) The registered office address is not always the business address.
        </li>
    </ul>
    <p>You can read more on this by following the link below</p>
    <p><a href="https://www.starting-business.org.uk/index.php?q=registered-office-laws" title="Starting Businesses"
            target="_blank" rel="nofollow"
            class="fodiyblue">https://www.starting-business.org.uk/index.php?q=registered-office-laws</a></p>
    <h3 class="mt-5">Companies House</h3>
    <p>All UK businesses should have a Company Registration number like the one shown at the bottom of our website
        (<em><strong>Our Company Registration No: 01918871</strong></em>). Once you have this number you can visit
        companies house on the link below and check that the company exists and they are who they claim to be and not
        dissolved.</p>
    <p><a href="https://www.companieshouse.gov.uk/" title="Companies House" target="_blank" rel="nofollow"
            class="fodiyblue">https://www.companieshouse.gov.uk/</a></p>
    <p>If a company claim to have been around for x amount of years, look at the <em><strong>date of
                incorporation</strong></em>. This is the date in which the company was legally formed. The status of the
        company should also be <em><strong>Active</strong></em>.</p>
    <h3 class="mt-5">Registered Address</h3>
    <p>The registered office address is not always the business address, it can sometimes be an address of an
        accountant. </p>
    <p>If there is manufacturing part to the business that creates the kitchen units, then there is no reason to hide
        this address from the website.</p>
    <p>If you place the <em><strong>postcode</strong></em> of the<em><strong> registered address</strong></em> into <a
            href="https://maps.google.co.uk/maps?q=wf93nr&amp;hl=en&amp;ll=53.588345,-1.305805&amp;spn=0.0114,0.033023&amp;sll=53.741446,-1.686054&amp;sspn=0.726922,2.113495&amp;t=h&amp;hnear=South+Kirkby+WF9+3NR,+United+Kingdom&amp;z=16&amp;layer=c&amp;cbll=53.588345,-1.305805&amp;panoid=ekl3KJlHFoaCzvED2drN3Q&amp;cbp=12,124.15,,0,13.8"
            title="Google Maps" target="_blank" rel="nofollow" class="fodiyblue">Google Maps</a>, you'll be able to see
        where the registered address is located. </p>
    <p><strong><em>If you're not happy</em></strong> with what you see then go and visit the company, if possible. </p>
    <p>Our postcode is<em><strong> WF9 3NR</strong></em> and you can see us on Google Maps <a
            href="https://maps.google.co.uk/maps?q=wf93nr&amp;hl=en&amp;ll=53.588345,-1.305805&amp;spn=0.0114,0.033023&amp;sll=53.741446,-1.686054&amp;sspn=0.726922,2.113495&amp;t=h&amp;hnear=South+Kirkby+WF9+3NR,+United+Kingdom&amp;z=16&amp;layer=c&amp;cbll=53.588345,-1.305805&amp;panoid=ekl3KJlHFoaCzvED2drN3Q&amp;cbp=12,124.15,,0,13.8"
            title="Google Maps" target="_blank" rel="nofollow" class="fodiyblue">here</a>.</p>
    <h3 class="mt-5">Copycats</h3>
    <p>Sometimes you will see copycat websites where the URL is virtually the same as our domain name below.</p>
    <p>Our URL/Domain Name is - <a routerLink="/" title="DIY Kitchens" class="fodiyblue"
            aria-label="www.diy-kitchens.com">www.diy-kitchens.com</a></p>
    <p>The domain name above &nbsp;is the only <em><strong>DIY Kitchens </strong></em>website that belongs to DIY
        Kitchens. We are not affiliated with any other online kitchen manufacturer that has virtually identical domain
        names.</p>
    <p>From time to time we also see websites that have stolen our content and sometimes the entire design. To the
        untrained eye, you may be thinking that you are buying from us when you are not. If in doubt, watch the whole of
        the video above. It could save you from making a big mistake!</p>
    <h3 class="mt-5">Pay By Credit Card</h3>
    <p>If you <em><strong>use</strong></em> your <em><strong>credit card</strong></em> to make your online purchase then
        you have extra protection if something was to go drastically wrong. Pay by cash and you could be left high and
        dry</p>
    <p>If you pay by credit card, under<em><strong> Section 75 of the Consumer Credit Act,</strong></em> your
        <em><strong>credit card company is jointly liable</strong></em> if something goes wrong. </p>
    <p>Section 75 is also useful if a retailer has gone out of business or you are totally unable to contact the seller
        of the goods.</p>
    <p>In a worst case scenario situation, you can put a claim in with your credit card company to help resolve any
        issues that you are having. </p>
    <p>Section 75 covers <em><strong>purchases ranging</strong></em> from<strong> £100</strong> to
        <strong>£30,000</strong>.</p>
    <p>You can read more on this by following the link below.</p>
    <p><a href="https://www.which.co.uk/consumer-rights/problem/can-i-claim-on-my-credit-card-when-something-goes-wrong/"
            title="Consumer Credit Act" target="_blank" class="fodiyblue"
            rel="nofollow">https://www.which.co.uk/consumer-rights/problem/can-i-claim-on-my-credit-card-when-something-goes-wrong/</a>
    </p>
    <p><strong>REMEMBER</strong> - If in doubt, check them out. Don't throw your hard earned cash away!</p>
</section>

<hr class="narrow" />

<section class="content-contained">
    <div class="panel">
        <div class="panel-left">
            <img src="https://static.diy-kitchens.com/assets/images/kitchens/ushaped-layout.jpg" width="100%"
                alt="Beautiful kitchen" />
        </div>
        <div class="panel-right">
            <h3 class="playfair">You Get What You Pay For</h3>
            <p>The saying, "<strong>you get what you pay for</strong>" should not be forgotten. At DIY Kitchens we can
                provide great quality kitchen units at a much discounted rate than the prices that you would pay on the
                highstreet. We run to tight profit margins and the prices we are offer are some of the best in the
                industry for the quality that you pay for.</p>
            <p>This is the example kitchen the the following prices are based on.</p>
            <p>We can provide a <em><strong>13 unit shaker kitchen </strong></em>complete with doors, cornice and pelmet
                for <strong>£2,493</strong>. We have no middle men, high street stores and can pass the savings directly
                on to you.</p>
            <p>The<em> <strong>cheapest</strong></em> kitchen we could find from a<em><strong> reputable</strong></em>
                outlet on the <em><strong>high street</strong></em> was <strong><em>£3,438</em></strong>.</p>
            <p>If you are buying from <em><strong>another online kitchen company</strong></em> that are only charging
                you ~<em><strong>£1000</strong></em>, you may want to <em><strong>ask</strong></em> them a few
                <em><strong>questions</strong></em> regarding exactly what it is that you are getting.</p>
            <p>Also, please ensure that your quotes are what we call "Apples for Apples" i.e. you are not comparing a 7
                unit kitchen price with a 13 unit price.</p>
        </div>
    </div>
</section>

<component-latest-offers></component-latest-offers>