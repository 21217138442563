import { NgModule } from '@angular/core';

import { StickyDirective } from './sticky.directive';

@NgModule({
    exports: [
        StickyDirective
    ],
    declarations: [
        StickyDirective
    ]
})
export class StickyDirectiveModule { }
