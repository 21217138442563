import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { StringHelper } from '@app/utilities/helpers';

import { CatalogueService } from '@app/services/catalogue';
import { ProductType } from '@app/services/catalogue/models';
import { IAppliance } from '@app/services/catalogue/models/product.models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { MarketingService } from '@app/services/marketing';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';
import { BannerLocation, IBanner } from '@app/services/marketing/models';

@Component({
    selector: 'page-appliance-listing',
    templateUrl: './appliance-listing.page.html',
    styleUrls: ['./appliance-listing.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ApplianceListingPage implements OnInit, OnDestroy {
    public type: ProductType = ProductType.APPLIANCES;
    public route = '/appliances/appliance/';

    public category: string; // Category to display ie (Base units or Dishwashers)
    public categoryDisplay: string;
    public subCategory: string;
    public subCategoryDisplay: string;
    public sectionCats: any[] = []; // A list of all categories for this type (ie different unit types or appliance types)
    public headerCssClass: string;

    public products: IAppliance[]; // All products of this type (ie All Units or Appliances)
    public items: any; // Products in this category - gets sent to the filters component
    public filteredItems: any[] = []; // Filtered products to show as results
    public noResults: boolean;

    public filterFields: any = [
        {
            title: 'Sub category',
            field: 'subcat',
            rankField: 'subCatRank'
        },
        {
            title: 'Brand',
            field: 'brand'
        },
        {
            title: 'Type',
            field: 'specific_category'
        },
        {
            title: 'Width',
            field: 'width',
            token: 'mm'
        },
        {
            title: 'Height',
            field: 'height',
            token: 'mm'
        },
        {
            title: 'Depth',
            field: 'depth',
            token: 'mm'
        }
    ];

    private routeHistory: RouteHistory[] = [
        { title: 'Appliances', route: '/appliances' }
    ];

    private locationId: BannerLocation = BannerLocation.APPLIANCE_LISTING;
    public promotionBanners: IBanner[];

    private routeSubscription: Subscription;

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private marketingService: MarketingService
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                this.category = route.params.category || null;
                this.categoryDisplay = (this.category) ? StringHelper.titleCase(StringHelper.dashToSpace(this.category)) : null;

                this.subCategory = route.params.subCategory || null;

                if (this.category === 'dishwashers' && route.params.subCategory && route.params.subCategory === '450') {
                    this.subCategory = 'slimline-integrated';
                }

                this.subCategoryDisplay = (this.subCategory) ? this.catalogueService.getCategoryTitle(this.subCategory) : null;
                this.headerCssClass = (this.subCategory) ? `content-header-appliances-${this.category}-${this.subCategory}` : `content-header-appliances-${this.category}`;

                let routeHistory = this.routeHistory.slice();
                routeHistory.push({
                    title: this.categoryDisplay,
                    route: `/appliances/${this.category}`
                });

                if (this.subCategory) {
                    routeHistory.push({
                        title: this.subCategoryDisplay,
                        route: `/appliances/${this.category}/${this.subCategory}`
                    });

                    let filterField = this.filterFields.find((filter) => filter.field === 'subcat');
                    if (filterField) {
                        filterField.preselected = this.subCategory
                    }
                }

                this.navigationService.setNavigation({
                    title: `${this.categoryDisplay} | Appliances`,
                    metaTags: [
                        { name: 'no-meta', content: 'no metadata' },
                        { name: 'description', content: `See our range of ${this.subCategoryDisplay?.toLowerCase() || this.categoryDisplay?.toLowerCase() || 'appliances'} available at trade prices.`},
                        { property: 'og:description', content: `See our range of ${this.subCategoryDisplay?.toLowerCase() || this.categoryDisplay?.toLowerCase() || 'appliances'} available at trade prices.`},
                    ],
                    routeHistory: routeHistory
                });

                this.catalogueService.getAppliances()
                    .then((products: IAppliance[]) => {
                        this.products = products;
                        this.initProducts();
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
                
                this.marketingService.getBannerForLocation(this.locationId)
                    .then((banners) => {
                        this.promotionBanners = banners;
                        this.promotionBanners.sort((a, b) => {
                            if (a.name > b.name) {
                                return 1;
                            } else if (a.name < b.name) {
                                return -1;
                            }

                            return 0;
                        });
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
                
            }
        );
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }

    public updateItems(filtered) {
        this.filteredItems = filtered;
        this.noResults = filtered.length === 0;
    }

    public getPromotionUrlFragment(promotion: IBanner): string {
        return this.marketingService.getPromotionUrlFragment(promotion);
    }

    private initProducts() {
        if (this.products) {
            this.sectionCats = [];
            this.items = [];
            this.products.map((product) => {
                product._code = product.code;
                product._description = product.description;
                product._details = product.info;
                product._cost = this.cost(product);

                if (product._cost > 0) {
                    this.items.push({
                        item: product
                    });

                    const catExists = this.sectionCats.find((opt) => opt.name === product.category);
                    if (!catExists) {
                        this.sectionCats.push({
                            name: product.category || '',
                            count: 1
                        });
                    } else {
                        catExists.count++;
                    }
                }

                if (product.overlay) {
                    product.overlay = product.overlay.replace(/\.jpg$/, '.png');
                }
            });

            this.items = this.items.filter((entry) => {
                return entry.item.category && entry.item.category.toLowerCase().replace(/ /g, '-') === this.category;
            });
        }
    }

    private cost(item) {
        return item.cost || 0;
    }
}
