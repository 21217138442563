@import '/src/theme/variables';

page-accessory-detail {
    
    .product-details .details .title {
        margin-top: 0;
        margin-bottom: 5px;
        font-size: 21px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.1em;
        text-align: left;
    }

    .product-details .left .image {
        position: relative;
    }

    .sub-title {
        font-weight: bold;
    }

    .description-block {
        padding: 1.5rem 0;
        border-top: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
        margin-bottom: 2rem;
    }

    .details {
        .colour-swatches {
            display: flex;
            gap: 10px;

            .swatch {
                width: 45px;
                height: 45px;
                border: 1px solid #000;
                cursor: pointer;

                &.selected {
                    border: 2px solid color('brand-primary');
                }
            }
        }

        .price {
            > * {
                text-align: right;
            }

            strong {
                font-size: 1.6rem;
                font-weight: 600;
            }
        }
    }

    .desc-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 0 1.875rem;

        @include responsive-breakpoint-down(lg) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        .desc-grid-item {
            &:last-child {
                padding-top: 26px;
            }

            .item-specification {
                margin: 3rem 0 0;

                @include responsive-breakpoint-down(md) {
                    margin: 2.5rem 0 0;
                }

                h3 {
                    margin-bottom: 2rem;

                    @include responsive-breakpoint-down(md) {
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        h3 {
            font-family: font('opensans');
            font-size: 16px;
            font-weight: 600;
            letter-spacing: -0.05rem;
            line-height: clamp(1.333rem, 1vw + 1.333rem, 1.777rem);
            color: rgba(0, 18, 36, 0.9);
            text-transform: uppercase;
            margin: 0 0 10px;

            &.desc-sec-title {
                margin-bottom: 1rem;
            }
        }

        p {
            font-family: font('opensans');
            font-size: 16px;
            font-weight: 300;
            color: rgba(0, 18, 36, 0.9);
            line-height: clamp(1.618rem, 1vw + 1.618rem, 1.618rem);
            margin-bottom: 1.333rem;
            letter-spacing: 0;

            strong {
                font-weight: 500;
                color: #878787;
            }
        }

        .spec-grid {
            display: flex;
            flex-direction: column;
            gap: 20px 0;

            .spec-grid-item {
                display: grid;
                grid-template-columns: 235px 235px;
                gap: 0 10px;

                @include responsive-breakpoint-between(md, lg) {
                    grid-template-columns: 235px 1fr;
                }

                @include responsive-breakpoint-down(md) {
                    grid-template-columns: 0.7fr 1fr;
                }

                label {
                    font-family: font('opensans');
                    font-size: 15px;
                    font-weight: 700;
                    color: rgba(0, 18, 36, 0.9);
                    line-height: 1.618rem;
                    letter-spacing: 0;
                    padding-right: 20px;

                    @include responsive-breakpoint-down(md) {
                        line-height: normal;
                    }
                }

                span {
                    font-family: font('opensans');
                    font-size: 15px;
                    font-weight: 400;
                    color: rgba(0, 18, 36, 0.9);
                    line-height: 1.618rem;
                    letter-spacing: 0;
                }
            }
        }
    }
}
