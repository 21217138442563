import { NgModule } from '@angular/core';

import { MessagingService } from './messaging.service';

@NgModule({
    providers: [
        MessagingService
    ]
})
export class MessagingServiceModule { }
