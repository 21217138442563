import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { DateHelper } from '@app/utilities/helpers';

import { BasketService } from '@app/services/basket';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { RouteHistory } from '@app/services/navigation/models';

import { StickyTabsDeliveryInformation } from './models';
import { IStickyTab } from '@app/components/sticky-tabs/models';

import { YouTubeVideoDialogComponent } from '@app/dialogs/youtube-video';

@Component({
    selector: 'page-about-delivery-information',
    templateUrl: './delivery-information.page.html',
    styleUrls: ['./delivery-information.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AboutDeliveryInformationPage implements OnInit {
    public StickyTabs = StickyTabsDeliveryInformation;
    public tabs: IStickyTab<StickyTabsDeliveryInformation>[] = [
        { title: 'Kitchens', id: StickyTabsDeliveryInformation.KITCHENS },
        { title: 'Doors & Accessories', id: StickyTabsDeliveryInformation.DOORS },
        { title: 'Sinks & Taps', id: StickyTabsDeliveryInformation.SINKS },
        { title: 'Appliances', id: StickyTabsDeliveryInformation.APPLIANCES },
        { title: 'Worktops', id: StickyTabsDeliveryInformation.WORKTOPS }
    ];
    public activeTab: StickyTabsDeliveryInformation = StickyTabsDeliveryInformation.KITCHENS;

    public weekCommencing: string = DateHelper.moment().startOf('isoWeek').add(4, 'weeks').format(DateHelper.formatDate);

    private routeHistory: RouteHistory[] = [
        { title: 'About Diy Kitchens', route: '/about-diy-kitchens' },
        { title: 'Deliveries', route: '/delivery-information' }
    ];

    constructor(
        private basketService: BasketService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: 'Delivery Information',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'description', content: 'We are a family run business that has been manufacturing and supplying high specification kitchens to both trade and retail customers for nearly 30 years.' }
            ],
            routeHistory: this.routeHistory
        });

        this.basketService.leadtime([], null)
            .then((weekCommencing) => {
                this.weekCommencing = weekCommencing;
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public setActiveTab(activeTab: StickyTabsDeliveryInformation) {
        this.activeTab = activeTab;
    }

    public showVideo(url: string) {
        this.dialogService.custom(
            YouTubeVideoDialogComponent,
            {
                width: '65%',
                height: '65%',
                data: {
                    url: url
                }
            }
        )
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
