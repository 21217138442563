@import '/src/theme/variables';

page-home {
    .video-banner {
        padding-top: 0;
        margin: 0 auto;

        .video {
            display: flex;

            .video-with-control {
                video {
                    width: 67vw;
                    height: 33vw;
                    z-index: 1;
                    object-fit: cover;
                }

                .play-button {
                    display: none;
                    position: absolute;
                    left: calc(33vw - 40px);
                    top: calc(16.5vw - 40px);
                    color: color('white');
                    opacity: 0.9;
                    cursor: pointer;

                    mat-icon {
                        width: 80px;
                        height: 80px;
                        font-size: 80px;
                    }

                    &.show {
                        display: block;
                    }
                }
            }

            .num1 {
                display: flex;
                justify-content: center;
                background-color: #011844;
                background-image: url('https://static.diy-kitchens.com/assets/images/homepage/planner-promo.jpg');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: right;
                width: 34vw;
                height: 33vw;

                .planner-promo-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-top: 2vw;

                    span {
                        font-size: 1rem;
                        font-weight: 300;
                        color: #ffffff;
                        text-align: center;
                    }

                    .heading {
                        font-size: 1.8rem;
                        line-height: 1.8rem;
                        font-weight: 600;
                        letter-spacing: -0.05rem;
                        color: #ffffff;
                        text-align: center;
                        margin: 2vw 0;
                    }

                    a {
                        display: inline-block;
                        padding: 15px;
                        background: rgb(78, 168, 237);
                        color: rgb(255, 255, 255);
                        border-radius: 4px;
                        font-size: 12px;
                        font-weight: bold;
                        letter-spacing: 2px;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            @media only screen and (max-width:768px) {
                flex-direction: column;

                video {
                    width: 100vw;
                    min-height: 200px;
                }

                .video-with-control {
                    video {
                        width: 100vw;
                        min-height: 200px;
                    }

                    .play-button {
                        left: calc(50vw - 30px);
                        top: 70px;

                        mat-icon {
                            width: 60px;
                            height: 60px;
                            font-size: 60px;
                        }
                    }
                }

                .num1 {
                    width: 100vw;
                    height: 28vw;
                    min-height: 200px;
                    background-size: cover;

                    .planner-promo-content {
                        span {
                            background-color: #011844;
                            border-radius: 5px;
                            padding: 10px;
                            font-size: 0.85rem;
                        }

                        h3 {
                            background-color: #011844;
                            border-radius: 5px;
                            padding: 10px;
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }

        .info-banner {
            z-index: 200;
            margin-top: -100px;
            position: relative;

            .contained {
                display: flex;
                width: 100vw;

                .panel {
                    margin: 0 15px;
                    width: 360px;
                    padding: 1.5rem;
                    background: #FFF;
                    color: #222;
                    border-radius: 3px;
                    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-around;

                    .heading {
                        margin-top: 0;
                        margin-bottom: 20px;
                        font-size: 1.75rem;
                        font-weight: 600;
                        letter-spacing: normal;
                        line-height: 1.75rem;
                    }

                    .content {
                        font-family: font('opensans');
                        font-size: clamp(1rem, 1vw + 1rem, 1rem);
                        font-style: normal;
                        font-weight: 300;
                        letter-spacing: 0rem;
                        line-height: clamp(1.618rem, 1vw + 1.618rem, 1.618rem);
                        color: rgba(0, 18, 36, 0.9);
                    }

                    .bold {
                        font-family: font('opensans');
                        font-size: clamp(1rem, 1vw + 1rem, 1rem);
                        font-style: normal;
                        font-weight: 300;
                        letter-spacing: 0rem;
                        margin-bottom: 0;
                        line-height: clamp(1.618rem, 1vw + 1.618rem, 1.618rem);
                        color: rgba(0, 18, 36, 0.9);
                    }
                }
            }

            @media only screen and (max-width:1300px) {
                display: none;
            }
        }

        .round-alls {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 1vw;
            left: 1vw;
            z-index: 99;

            img {
                margin-bottom: 1vw;
                height: 15vw;
                min-height: 90px;

                @media only screen and (min-width:1301px) {
                    max-height: 11vw;
                }
            }
        }
    }

    .feature-banner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 60px;
        padding: 20px 25px;
        border-radius: 4px;
        background: color('feature-banner-blue');
        width: 90%;
        color: color('white');

        a {
            &:hover {
                text-decoration: none;
            }
        }

        div {
            display: flex;
            align-items: center;
        }

        img {
            margin-right: 20px;
            width: 50px;
            height: 50px;
        }

        p {
            margin-bottom: 0;
            color: color('white');
            line-height: 22px;
        }

        @media (max-width: 992px) {
            display: none;
        }
    }

    .true-handleless {
        margin-top: 60px;
        text-align: center;

        & + .true-handleless {
            margin-top: 30px;
        }

        &.tri-usp {
            .tri-image {
                overflow: visible;
            }
        }

        h1 {
            font-size: 42px;
            font-family: font('playfair');
            margin: 0;
            font-weight: 900;
            color: rgba(0, 18, 36, 0.9);
            letter-spacing: -0.1rem;
        }

        h5 {
            font-family: font('opensans');
            font-size: clamp(1rem, 1vw + 1rem, 1rem);
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0.05rem;
            line-height: clamp(1rem, 1vw + 1rem, 1.333rem);
            margin-bottom: 1rem;
            color: rgba(0, 18, 36, 0.9);
            text-transform: uppercase;
        }

        p {
            strong {
                font-weight: 400;
            }
        }
    }

    .welcome {
        margin-top: 60px;
        text-align: center;

        h1 {
            font-size: 42px;
            font-family: font('playfair');
            margin: 0;
            font-weight: 900;
            color: rgba(0, 18, 36, 0.9);
            letter-spacing: -0.1rem;
        }

        p {
            max-width: 78%;
            margin: 15px auto;
            font-weight: 300;
            letter-spacing: 0rem;
        }

        a {
            font-weight: regular;
            color: color('brand-primary');
        }
    }

    .tri-usp {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 30px;

        .tri-block {
            display: flex;
            flex-direction: column;
        }

        .tri-image {
            flex: 0 0 55%;
            position: relative;
            overflow: hidden;

            .mat-icon {
                position: absolute;
                left: calc(50% - 40px);
                top: calc(50% - 40px);
                width: 80px;
                height: 80px;
                color: color('white');
                font-size: 80px;
                opacity: 0.9;
            }
        }

        .tri-content {
            flex: 0.8;
            text-align: center;
            margin-bottom: 5px;

            h2 {
                margin: 10px 0;
                font-size: 1.8rem;
                font-weight: 700;
                line-height: 3.157rem;
            }
        }

        .tri-button {
            flex-grow: 0;
            margin: 20px;
            text-align: center;
            margin-top: 5px;

            .mdc-button {
                padding: 16px;
                height: 52px;
                font-size: 0.75rem;
                font-weight: 600;
                text-transform: uppercase;
            }
        }

        @media (max-width: 992px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media (max-width: 768px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    .mat-icon {
        vertical-align: bottom;
    }

    .brand-primary {
        color: color('brand-primary');
    }

    .showroom {
        .contained {
            display: flex;
            flex-direction: column;
            align-items: center;

            video {
                margin-bottom: 25px;
            }
        }
    }

    .style {
        margin-top: 56px;

        .style-h4 {
            font-family: font('playfair');
            font-size: clamp(1.777rem, 1vw + 1.777rem, 3.157rem);
            font-style: normal;
            font-weight: 900;
            letter-spacing: -0.1rem;
            line-height: clamp(1.777rem, 1vw + 2.369rem, 4.209rem);
            margin-bottom: 1.777rem;
            color: rgba(0, 18, 36, 0.9);

            @media only screen and (max-width: 1023px) {
                font-size: 30px;
                line-height: 40px;
            }
        }

        .style-h5 {
            font-family: font('opensans');
            font-size: clamp(1rem, 1vw + 1rem, 1rem);
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0.05rem;
            line-height: clamp(1rem, 1vw + 1rem, 1.333rem);
            margin-bottom: 1rem;
            color: rgba(0, 18, 36, 0.9);
            text-transform: uppercase;
        }

        .styles-grid {
            display: grid;
            gap: 35px 30px;
            grid-template-columns: repeat(3, minmax(0, 1fr));

            @media (max-width: 992px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @media (max-width: 768px) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }

        .style-type {
            margin-top: 30px;
            text-align: center;

            h4 {
                margin: 15px 0 0;
                font-size: 28px;
            }

            p {
                margin-bottom: 20px;
                font-size: 14px;
                font-weight: 600;
            }

            a {
                display: block;
                margin: 0 auto;
                margin-bottom: 20px;
                padding: 1rem;
                border: 1px solid #343a40;
                border-radius: 0.25rem;
                background-color: #fff;
                width: fit-content;
                color: #343a40;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 0.1429em;

                &:hover {
                    color: #fff;
                    background-color: #343a40;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }

        .tab-container {
            padding: 15px;
        }

        .kitchen-price {
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
        }

        .kitchen-finish {
            display: flex;
            flex-direction: column;
            margin: 15px;
            width: 100%;
            max-width: 300px;
            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);

            .finishes-content {
                flex-grow: 1;
                padding: 20px;
            }

            img {
                display: block;
                margin-left: auto;
                width: 100%;
                max-width: 300px;
            }
        }

        .kitchen-finish-title,
        .colour-title {
            font-family: font('playfair');
            font-size: 32px;
            font-weight: 600;
            line-height: 50px;
        }

        .colour-swatch {
            padding: 10px;
            width: 200px;
            height: 200px;
            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
        }
    }

    .about {
        display: flex;
        padding-bottom: 60px;

        .img {
            img {
                width: 40vw;
                max-width: 520px;
            }
        }

        .text {
            padding: 0 30px 0 60px;

            h4 {
                font-family: font('playfair');
                font-size: 42px;
                line-height: 60px;

                @media only screen and (max-width: 800px) {
                    font-size: 35px;
                }
            }

            a {
                font-weight: 600;
            }

            button {
                margin-top: 40px;
                padding: 15px 100px;
                letter-spacing: 2px;
                background: color('diy-blue');
                color: color('white');
                border: 1px solid #999;
                border-radius: 4px;
            }
        }
    }

    @media only screen and (max-width: 800px) {
        .style {
            .kitchen-finish {
                margin: 15px 0;
                width: 100%;
                max-width: initial;

                .finishes-content {
                    padding: 10px;

                    p {
                        margin-bottom: 0.5rem;
                        line-height: 22px;
                    }
                }
            }

            .kitchen-finish-title {
                font-size: 32px;
                padding: 10px 0;
            }

            .colour-title {
                font-size: 22px;
                padding: 0;
            }

            .colour-swatch {
                padding: 10px;
                width: 200px;
                height: 200px;
            }
        }

        .about {
            flex-wrap: wrap;

            .img {
                img {
                    width: 100%;
                    max-width: 100vw;
                }
            }

            .text {
                padding: 0;
            }
        }
    }

    .three-decades-section {
        padding: 50px;
        background-color: #F8F9FB;
        margin-bottom: 30px;
        margin-top: 50px;

        h4 {
            font-family: font('playfair') !important;
            font-size: 2.36rem !important;
            font-style: normal;
            font-weight: 900;
            letter-spacing: -0.1rem;
            line-height: clamp(1.777rem, 1vw + 2.369rem, 4.209rem) !important;
            margin-bottom: 1.777rem;
            color: rgba(0, 18, 36, 0.9);
        }

        p {
            font-family: 'Open Sans';
            font-size: 0.9rem;
            font-style: normal;
            font-weight: 300;
            letter-spacing: 0rem;
            line-height: 1.518rem;
            margin-bottom: 1.333rem;
            color: rgba(0, 18, 36, 0.9);
        }

        .text {
            padding-left: calc(8.3333% + 1rem);

            a {
                color: #007BFB;
                font-weight: 300;

                &:hover {
                    color: #0056b3;
                    text-decoration: underline;
                }
            }

            button {
                font-family: 'Open Sans';
                font-size: 12px;
                min-height: 52px;
                font-weight: 600;
                letter-spacing: 0.1429em;
                background: #009cde;
                border-color: #009cde;
                padding: 15px 88px;

                &:hover {
                    background: #00a9f1;
                    border-color: #00a9f1;
                }
            }
        }
    }
}