import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { CheckoutRouting } from './checkout.routing';
import { CheckoutPage } from './checkout.page';
import { CheckoutOrderPage } from './checkout-order/checkout-order.page';
import { ChangeOrderAddressPage } from './change-order-address/change-order-address.page';
import { LloydPaymentConfirmPage } from './lloyd-payment-confirm/lloyd-payment-confirm.page';

import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { DeliveryAccessComponent } from './delivery-access/delivery-access.component';
import { DeliveryOptionsComponent } from './delivery-options/delivery-options.component';
import { TermsComponent } from './terms/terms.component';


@NgModule({
    imports: [
        SharedModule,
        CheckoutRouting
    ],
    declarations: [
        CheckoutPage,
        CheckoutOrderPage,
        LloydPaymentConfirmPage,
        ChangeOrderAddressPage,

        CustomerDetailsComponent,
        DeliveryAccessComponent,
        DeliveryOptionsComponent,
        TermsComponent
    ]
})
export class CheckoutPageModule { }
