import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { UnitsPage } from './units.page';
import { UnitListingPage } from './listing/unit-listing.page';
import { UnitDetailPage } from './detail/unit-detail.page';

import { ProductCategoryGuard } from '@app/utilities/guards/product-category.guard';

import { PageNotFoundComponent } from '@app/shared/page-not-found.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: UnitsPage,
                pathMatch: 'full'
            },
            {
                path: 'unit/:code',
                component: UnitDetailPage,
                data: { vatToggle: true }
            },
            {
                path: ':category',
                component: UnitListingPage,
                canActivate: [ProductCategoryGuard],
                data: { reuse: true, vatToggle: true }
            },
            {
                path: ':category/:subCategory',
                component: UnitListingPage,
                canActivate: [ProductCategoryGuard],
                data: { reuse: true, vatToggle: true }
            },
            {
                path: ':category/:subCategory/:details',
                component: UnitDetailPage,
                data: { vatToggle: true }
            },
            { path: '**', component: PageNotFoundComponent }
        ])
    ],
    exports: [RouterModule]
})
export class UnitsRouting { }
