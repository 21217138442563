import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { HandlesRouting } from './handles.routing';
import { HandlesPage } from './handles.page';
import { HandlesListingPage } from './listing/handles-listing.page';
import { HandleDetailPage } from './detail/handles-detail.page';

@NgModule({
    imports: [
        SharedModule,
        HandlesRouting
    ],
    declarations: [
        HandlesPage,
        HandlesListingPage,
        HandleDetailPage
    ]
})
export class HandlesPageModule { }
