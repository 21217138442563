import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';

import { AboutTermsConditionsRouting } from './terms-conditions.routing';
import { AboutTermsConditionsPage } from './terms-conditions.page';

@NgModule({
    imports: [
        SharedModule,
        AboutTermsConditionsRouting
    ],
    declarations: [
        AboutTermsConditionsPage
    ]
})
export class AboutTermsConditionsPageModule { }
