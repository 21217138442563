<section class="dark content-header-background accessories-header-bg">
    <div class="contained">
        <h1>Accessories</h1>
        <div class="sub-head">
            You can put your personal stamp on the kitchen and really make it your own with hand-picked
            touches of individuality. There’s everything you need to help flex your creative muscles, from cool lighting
            and innovative accessories, to must-have components, essential tools and much more.
        </div>
    </div>
</section>

<component-product-category *ngIf="loaded" type="accessories" [categories]="categories"></component-product-category>