import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { Config } from '@app/config';

import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { RouteHistory } from '@app/services/navigation/models';

import { DirectionsDialogComponent } from './dialogs/directions';

@Component({
    selector: 'page-about-showroom',
    templateUrl: './showroom.page.html',
    styleUrls: ['./showroom.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AboutShowroomPage implements OnInit {
    public diy = this.config.diy;

    private routeHistory: RouteHistory[] = [
        { title: 'About Diy Kitchens', route: '/about-diy-kitchens' },
        { title: 'Showroom', route: '/about-diy-kitchens/showroom' }
    ];

    constructor(
        private config: Config,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: 'Kitchen Showroom - Visit our showroom to find your new kitchen',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'description', content: 'We are a family run business that has been manufacturing and supplying high specification kitchens to both trade and retail customers for nearly 30 years.'},
            ],
            routeHistory: this.routeHistory
        });
    }

    public openShowroomTour() {
        this.dialogService.html(
            'SHOWROOM 3D TOUR',
            '<iframe src="https://my.matterport.com/show/?m=XiBDw3Gne9m" frameborder="0" scrolling="no" style="width:100%;height:540px"></iframe>',
            // '<iframe src="https://live.tourdash.com/embed/d8489052ab04427bb77bd80f2b5e08b0?pano=CAoSLEFGMVFpcE5yTmJidHdOMGVBNExxUkptdnoyTF9MZDNLcXVNYnpheEJiQ0Vo&amp;head=-113.60554805614447&amp;pitch=12.07949760814617&amp;zoom=0" frameborder="0" scrolling="no" style="width:100%;height:540px"></iframe>',
            {
                minWidth: '80%',
                minHeight: '540px',
                panelClass: 'dialog-showroom-tour',
                data: {
                    showCloseButton: true,
                    buttons: []
                }
            }
        )
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public directions(address: string) {
        this.dialogService.custom(DirectionsDialogComponent, {
            data: {
                address: address
            }
        })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
