@import '/src/theme/variables';

page-doors-detail {
    header {
        display: flex;

        .range-image {
            margin-left: 56px;
            margin-bottom: 15px;
        }
    }

    .banners {
        .narrow {
            margin: 15px 0;
        }
    }

    .content-contained {
        padding-top: 0;
    }

    .products {
        margin-top: 56px;
    }

    .item {
        display: grid;
        grid-template-columns: 1fr 4fr 1fr;
        grid-template-areas:
            'image   header   price'
            'image   size     basket'
            'image   size     basket'
            'image   size     basket'
            'image   size     basket'
            'image   size     basket';

        & + .item {
            margin-top: 25px;
            padding-top: 25px;
            border-top: 1px solid color('copy-border');
        }
    }

    .item-image {
        display: inline-block;
        position: relative;
        grid-area: image;
    }

    .item-header {
        grid-area: header;
        margin: 5px 15px;

        h4 {
            margin: 0;
        }

        p {
            &.small {
                font-size: 12px;
                line-height: 19px;
                letter-spacing: 3px;
                text-align: left;
                text-transform: uppercase;
            }
        }
    }

    .price {
        grid-area: price;
        margin: 0;
        padding-top: 5px;
        min-width: 180px;
        text-align: right;
    }

    .sizing {
        grid-area: size;
        margin: 0 15px;

        label {
            flex: 0 0 auto;
            width: 110px;
            color: #687685;
            font-weight: 400;
        }

        span {
            font-weight: 600;
        }

        .sizing-height {
            display: flex;
            margin-bottom: 10px;
        }

        .sizing-width {
            display: flex;

            .width-buttons {
                display: grid;
                width: 100%;
                grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
            }

            .mdc-button {
                margin: 5px;
                padding: 5px 10px;
                min-width: 50px;
                font-weight: 600;
                letter-spacing: 0.05rem;

                &.selected {
                    border-bottom: 4px solid color('brand-primary');
                    color: inherit;
                }
            }
        }
    }

    .add-to-basket {
        grid-area: basket;
        margin-left: auto;
        min-width: 130px;
    }

    .no-results,
    .no-range {
        padding: 20px;

        em {
            display: block;
            margin-bottom: 30px;
            font-size: 2em;
            line-height: 1em;
            font-style: normal;
            font-weight: bold;
        }
    }

    @media only screen and (max-width: 992px) {
        .banners {
            .panel {
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        header {
            flex-direction: column;

            .range-image {
                margin-left: 10%;
                margin-right: 10%;
            }
        }

        .item {
            grid-template-columns: 1fr 1fr 2fr 2fr;
            grid-template-areas:
                'image   image   header   header'
                'image   image   size     size'
                'image   image   price    basket';
        }

        .sizing {
            margin-right: 0;
        }

        .item-header {
            margin-right: 0;
        }

        .price {
            margin: 5px 15px 0 15px;
            text-align: left;
        }

        .add-to-basket {
            margin: 5px 0 0 auto;
        }
    }

    @media only screen and (max-width: 480px) {
        .item {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-areas:
                'image   header  header   header'
                'size    size    size     size'
                'price   price   basket   basket';
        }

        .price {
            margin-left: 0;
            padding-left: 5px;
        }

        .sizing {
            padding: 5px 5px;
            margin-left: 0;
        }
    }
}
