import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { StringHelper } from '@app/utilities/helpers';

import { CatalogueService } from '@app/services/catalogue';
import { ProductType } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';

@Component({
    selector: 'page-carcase-sample',
    templateUrl: './carcase-sample.page.html',
    styleUrls: ['./carcase-sample.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CarcaseSamplePage implements OnInit {
    public carcases: any; // Products in this category - gets sent to the filters component
    public type: ProductType = ProductType.SAMPLE_CARCASE;

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: 'Kitchen carcase samples',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { property: 'og:description', content: `See our range of carcase samples available to help you choose between the different finishes available.` },
                { name: 'Description', content: `See our range of carcase samples available to help you choose between the different finishes available.` },
            ]
        });

        this.navigationService.setRouteHistory([
            { title: 'Samples', route: '/samples' },
            { title: 'Carcase Samples', route: '/samples/carcase-samples' }
        ]);

        this.catalogueService.getCabinetColours()
            .then((cabinetColours: any) => {
                cabinetColours.forEach((cab) => cab.image = 'carcases/' + cab.img_2d);
                this.carcases = cabinetColours.map((cab) => {
                    return {
                        colour: cab.colour,
                        image: cab.image,
                        cssClass: cab.css_class,
                        code: cab.sample_code || `SMP-${(StringHelper.spaceToDash(cab.colour || '')).toUpperCase()}`,
                        cost: 0,
                        description: 'Carcase Sample - ' + cab.colour
                    };
                });
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
