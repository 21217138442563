import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';
import { PipesModule } from '@app/pipes';

import { RangeActionsComponent } from './range-actions.component';

@NgModule({
    imports: [
        DesignModule,
        PipesModule
    ],
    exports: [
        RangeActionsComponent
    ],
    declarations: [
        RangeActionsComponent
    ]
})
export class RangeActionsComponentModule { }
