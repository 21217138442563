<div class="customer-registration-container">
    <form class="registration-form" [formGroup]="registrationFormGroup" (keydown.enter)="authenticate()">
        <div class="customer-registration-form">
            <p class="login-button login-button-left">Already Registered? <button mat-flat-button color="primary" (click)="loginDialog()">Login</button></p>

            <h3>Register and Checkout</h3>

            <div class="details">
                <mat-form-field>
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="firstName" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="lastName" />
                </mat-form-field>
            </div>

            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Contact Number</mat-label>
                <input matInput formControlName="contactNumber" />
            </mat-form-field>
            <div class="error-message" *ngIf="registrationFormGroup.controls.contactNumber?.errors?.phoneNumberValid === false">
                Please enter a valid UK contact number. i.e. 07XX XXX XXXX
            </div>

            <div class="details">
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="password" type="password" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Confirm Password</mat-label>
                    <input matInput formControlName="passwordConfirm" type="password" />
                </mat-form-field>
            </div>
        </div>

        <div class="customer-registration-terms">
            <p class="login-button login-button-right">Already Registered? <button mat-flat-button color="primary" (click)="loginDialog()">Login</button></p>

            <p class="marketing-consent">
                By providing your contact details to DIY Kitchens, you will be indicating to us your consent for us to contact you by email and SMS to let you know about your order delivery or offers/promotions, which may be of interest to you, unless you indicate an objection to receiving such messages. You can also unsubscribe from marketing email communications from within our emails.
            </p>

            <p *ngIf="errorMessage" class="error-message">{{errorMessage}}</p>

            <button mat-raised-button type="submit" color="primary" [disabled]="authenticating" (click)="authenticate()">
                Next
            </button>
        </div>
    </form>
</div>