import { AbstractControl } from '@angular/forms';

import { EmailValidator } from './email.validator';
import { PhoneNumberValidator } from './phonenumber.validator';
import { PostcodeValidator } from './postcode.validator';

class Validators {
    public email = this.validator(EmailValidator);
    public phoneNumber = this.validator(PhoneNumberValidator);
    public postcode = this.validator(PostcodeValidator);

    private validator(Validator) {
        return (control: AbstractControl) => {
            return new Validator().validate(control);
        };
    }
}

export const FormValidators = new Validators();
