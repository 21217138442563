import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'component-login-dialog',
    templateUrl: './login.dialog.component.html',
    styleUrls: ['./login.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class LoginDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<LoginDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    public loginComplete(isAuthed) {
        this.dialogRef.close(isAuthed);
    }

    public close() {
        this.dialogRef.close();
    }
}
