<div class="mobile-menu">
  <div class="menu-header">
    <h3>Menu</h3>
    <button mat-icon-button (click)="closeMenu()" aria-label="Close menu">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-accordion>
    <!-- Kitchens -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title class="section-title">Kitchens</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="menu-links">
        <a href="/kitchens/high-gloss" routerLink="/kitchens/high-gloss" (click)="closeMenu()" title="High Gloss Kitchens">High Gloss Kitchens</a>
        <a href="/kitchens/shaker" routerLink="/kitchens/shaker" (click)="closeMenu()" title="Shaker Kitchens">Shaker Kitchens</a>
        <a href="/kitchens/allstyles/allcolours/painted/allmaterial/straight-shaped/low-high" routerLink="/kitchens/allstyles/allcolours/painted/allmaterial/straight-shaped/low-high" (click)="closeMenu()" title="Painted Kitchens">Painted Kitchens</a>
        <a href="/kitchens/handleless" routerLink="/kitchens/handleless" (click)="closeMenu()" title="Handleless Kitchens">Handleless Kitchens</a>
        <a href="/kitchens/bespoke-painted" routerLink="/kitchens/bespoke-painted" (click)="closeMenu()" title="Bespoke Painted Kitchens">Bespoke Painted Kitchens</a>
        <a href="/kitchens/inframe" routerLink="/kitchens/inframe" (click)="closeMenu()" title="In-Frame Kitchens">In-Frame Kitchens</a>
        <a href="/kitchens/modern" routerLink="/kitchens/modern" (click)="closeMenu()" title="Modern Kitchens">Modern Kitchens</a>
        <a href="/kitchens/traditional" routerLink="/kitchens/traditional" (click)="closeMenu()" title="Traditional Kitchens">Traditional Kitchens</a>
      </div>
    </mat-expansion-panel>

    <!-- Units -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title class="section-title">Units</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="menu-links">
        <a href="/kitchen-units/base" routerLink="/kitchen-units/base" (click)="closeMenu()" title="Base Units">Base Units</a>
        <a href="/kitchen-units/wall" routerLink="/kitchen-units/wall" (click)="closeMenu()" title="Wall Units">Wall Units</a>
        <a href="/kitchen-units/corner-base" routerLink="/kitchen-units/corner-base" (click)="closeMenu()" title="Corner Base Units">Corner Base Units</a>
        <a href="/kitchen-units/tall" routerLink="/kitchen-units/tall" (click)="closeMenu()" title="Tall Units">Tall Units</a>
        <a href="/kitchen-units/dresser" routerLink="/kitchen-units/dresser" (click)="closeMenu()" title="Worktop Dresser Units">Worktop Dresser Units</a>
        <a href="/kitchen-units/accessories/appliance-doors" routerLink="/kitchen-units/accessories/appliance-doors" (click)="closeMenu()" title="Appliance Doors">Appliance Doors</a>
        <a href="/kitchen-units/accessories/cornice-pelmet-plinths" routerLink="/kitchen-units/accessories/cornice-pelmet-plinths" (click)="closeMenu()" title="Cornice, Pelmet & Plinths">Cornice, Pelmet & Plinths</a>
        <a href="/kitchen-units/accessories/end-panels" routerLink="/kitchen-units/accessories/end-panels" (click)="closeMenu()" title="End & Back Panels">End & Back Panels</a>
        <a href="/kitchen-units/accessories/corner-posts" routerLink="/kitchen-units/accessories/corner-posts" (click)="closeMenu()" title="Corner Posts">Corner Posts</a>
        <a href="/kitchen-units/dresser/mantles-canopies" routerLink="/kitchen-units/dresser/mantles-canopies" (click)="closeMenu()" title="Mantles & Canopies">Mantles & Canopies</a>
        <a href="/kitchen-units/accessories/decorative-items" routerLink="/kitchen-units/accessories/decorative-items" (click)="closeMenu()" title="Decorative Items">Decorative Items</a>
        <a href="/kitchen-units/accessories/end-panels" routerLink="/kitchen-units/accessories/end-panels" (click)="closeMenu()" title="Filler Panels">Filler Panels</a>
        <a href="/kitchen-units/accessories/carcase-material" routerLink="/kitchen-units/accessories/carcase-material" (click)="closeMenu()" title="Plinths, Fillers, Panels & Shelves">Plinths, Fillers, Panels & Shelves</a>
      </div>
    </mat-expansion-panel>

    <!-- Doors -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title class="section-title">Doors</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="menu-links">
        <a href="/kitchen-doors/high-gloss" routerLink="/kitchen-doors/high-gloss" (click)="closeMenu()" title="High Gloss Kitchen Doors">High Gloss Kitchen Doors</a>
        <a href="/kitchen-doors/shaker" routerLink="/kitchen-doors/shaker" (click)="closeMenu()" title="Shaker Kitchen Doors">Shaker Kitchen Doors</a>
        <a href="/kitchen-doors/traditional" routerLink="/kitchen-doors/traditional" (click)="closeMenu()" title="Painted Kitchen Doors">Painted Kitchen Doors</a>
        <a href="/kitchen-doors/handleless" routerLink="/kitchen-doors/handleless" (click)="closeMenu()" title="Handleless Kitchen Doors">Handleless Kitchen Doors</a>
        <a href="/kitchen-doors/bespoke-painted" routerLink="/kitchen-doors/bespoke-painted" (click)="closeMenu()" title="Bespoke Painted Kitchen Doors">Bespoke Painted Kitchen Doors</a>
        <a href="/kitchen-doors/modern" routerLink="/kitchen-doors/modern" (click)="closeMenu()" title="Modern Kitchen Doors">Modern Kitchen Doors</a>
        <a href="/kitchen-doors/traditional" routerLink="/kitchen-doors/traditional" (click)="closeMenu()" title="Traditional Kitchen Doors">Traditional Kitchen Doors</a>
      </div>
    </mat-expansion-panel>

    <!-- Appliances -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="section-title">Appliances</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="menu-links">
          <strong>Cooking</strong>
          <a href="/appliances/cooking/single-ovens" 
             routerLink="/appliances/cooking/single-ovens" 
             (click)="closeMenu()" 
             title="Single Ovens">Single Ovens</a>
          <a href="/appliances/cooking/double-ovens" 
             routerLink="/appliances/cooking/double-ovens" 
             (click)="closeMenu()" 
             title="Double Ovens">Double Ovens</a>
          <a href="/appliances/cooking/compact-ovens" 
             routerLink="/appliances/cooking/compact-ovens" 
             (click)="closeMenu()" 
             title="Compact Ovens">Compact Ovens</a>
          <a href="/appliances/cooking/microwaves" 
             routerLink="/appliances/cooking/microwaves" 
             (click)="closeMenu()" 
             title="Microwaves">Microwaves</a>
          <a href="/appliances/cooking/warming-drawers" 
             routerLink="/appliances/cooking/warming-drawers" 
             (click)="closeMenu()" 
             title="Warming Drawers">Warming Drawers</a>
          <a href="/appliances/cooking/hobs" 
             routerLink="/appliances/cooking/hobs" 
             (click)="closeMenu()" 
             title="Hobs">Hobs</a>
          <a href="/appliances/cooking/extractors" 
             routerLink="/appliances/cooking/extractors" 
             (click)="closeMenu()" 
             title="Hoods/Extractors">Hoods/Extractors</a>
      
          <strong>Refrigeration</strong>
          <a href="/appliances/refrigeration/american-fridge-freezer" 
             routerLink="/appliances/refrigeration/american-fridge-freezer" 
             (click)="closeMenu()">American Fridge Freezers</a>
          <a href="/appliances/refrigeration/50-50-fridge-freezers" 
             routerLink="/appliances/refrigeration/50-50-fridge-freezers" 
             (click)="closeMenu()">50:50 Fridge Freezers</a>
          <a href="/appliances/refrigeration/70-30-fridge-freezers" 
             routerLink="/appliances/refrigeration/70-30-fridge-freezers" 
             (click)="closeMenu()">70:30 Fridge Freezers</a>
          <a href="/appliances/refrigeration/fridges" 
             routerLink="/appliances/refrigeration/fridges" 
             (click)="closeMenu()">Fridges</a>
          <a href="/appliances/refrigeration/freezers" 
             routerLink="/appliances/refrigeration/freezers" 
             (click)="closeMenu()">Freezers</a>
          <a href="/appliances/refrigeration/fridge-freezers" 
             routerLink="/appliances/refrigeration/fridge-freezers" 
             (click)="closeMenu()">Fridge Freezers</a>
          <a href="/appliances/refrigeration/wine-coolers" 
             routerLink="/appliances/refrigeration/wine-coolers" 
             (click)="closeMenu()" 
             title="Wine Coolers">Wine Coolers</a>
      
          <strong>Laundry</strong>
          <a href="/appliances/laundry/washing-machines" 
             routerLink="/appliances/laundry/washing-machines" 
             (click)="closeMenu()" 
             title="Washing Machines">Washing Machines</a>
          <a href="/appliances/laundry/washer-dryers" 
             routerLink="/appliances/laundry/washer-dryers" 
             (click)="closeMenu()" 
             title="Washer Dryers">Washer Dryers</a>
          <a href="/appliances/laundry/tumble-dryer" 
             routerLink="/appliances/laundry/tumble-dryer" 
             (click)="closeMenu()" 
             title="Tumble Dryers">Tumble Dryers</a>
      
          <strong>Dishwashers</strong>
          <a href="/appliances/dishwashers" 
             routerLink="/appliances/dishwashers" 
             (click)="closeMenu()" 
             title="Dishwashers">Dishwashers</a>
          <a href="/appliances/dishwashers/slimline-integrated" 
             routerLink="/appliances/dishwashers/slimline-integrated" 
             (click)="closeMenu()" 
             title="Slim Dishwashers">Slim Dishwashers</a>
        </div>
    </mat-expansion-panel>

    <!-- Sinks & Taps -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="section-title">Sinks & Taps</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="menu-links">
          <a href="/sinks-and-taps/sinks" 
             routerLink="/sinks-and-taps/sinks" 
             (click)="closeMenu()" 
             title="Sinks">Sinks</a>
          <a href="/sinks-and-taps/taps" 
             routerLink="/sinks-and-taps/taps" 
             (click)="closeMenu()" 
             title="Taps">Taps</a>
          <!-- <a href="/accessories/sinks" 
             routerLink="/accessories/sinks" 
             (click)="closeMenu()" 
             title="Sink Accessories">Sink Accessories</a> -->
        </div>
    </mat-expansion-panel>

    <!-- Worktops -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="section-title">Worktops</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="menu-links">
          <a href="/laminate-worktops" 
             routerLink="/laminate-worktops" 
             (click)="closeMenu()" 
             title="Laminate Worktops">Laminate Worktops</a>
          <a href="/wood-worktops" 
             routerLink="/wood-worktops" 
             (click)="closeMenu()" 
             title="Solid Wood Worktops">Solid Wood Worktops</a>
          <a href="/solid-surfaces/granite-worktops" 
             routerLink="/solid-surfaces/granite-worktops" 
             (click)="closeMenu()" 
             title="Granite Worktops">Granite Worktops</a>
          <a href="/solid-surfaces/quartz-worktops" 
             routerLink="/solid-surfaces/quartz-worktops" 
             (click)="closeMenu()" 
             title="Quartz Worktops">Quartz Worktops</a>
          <a href="/solid-surfaces/dekton-worktops" 
             routerLink="/solid-surfaces/dekton-worktops" 
             (click)="closeMenu()" 
             title="Dekton Worktops">Dekton Worktops</a>
        </div>
    </mat-expansion-panel>

    <!-- Handles -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="section-title">Handles</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="menu-links">
          <a href="/handles/bar-handles" 
             routerLink="/handles/bar-handles" 
             (click)="closeMenu()" 
             title="Bar kitchen handles">Bar kitchen handles</a>
          <a href="/handles/bow-and-bridge-handles" 
             routerLink="/handles/bow-and-bridge-handles" 
             (click)="closeMenu()" 
             title="Bow / bridge kitchen handles">Bow / bridge kitchen handles</a>
          <a href="/handles/cup-and-pull-handles" 
             routerLink="/handles/cup-and-pull-handles" 
             (click)="closeMenu()" 
             title="Cup / pull kitchen handles">Cup / pull kitchen handles</a>
          <a href="/handles/profile-handles" 
             routerLink="/handles/profile-handles" 
             (click)="closeMenu()" 
             title="Profile kitchen handles">Profile kitchen handles</a>
          <a href="/handles/knobs" 
             routerLink="/handles/knobs" 
             (click)="closeMenu()" 
             title="Rounded kitchen handles">Rounded kitchen handles</a>
          <a href="/handles/knobs" 
             routerLink="/handles/knobs" 
             (click)="closeMenu()" 
             title="Square kitchen handles">Square kitchen handles</a>
        </div>
    </mat-expansion-panel>
      
    <!-- Samples -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="section-title">Samples</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="menu-links">
          <a href="/samples/door-samples" 
             routerLink="/samples/door-samples" 
             (click)="closeMenu()" 
             title="Door samples">Door Samples</a>
          <a href="/samples/carcase-samples" 
             routerLink="/samples/carcase-samples" 
             (click)="closeMenu()" 
             title="Carcase samples">Carcase Samples</a>
          <a href="/samples/solid-surface-worktop-samples/quartz" 
             routerLink="/samples/solid-surface-worktop-samples/quartz" 
             (click)="closeMenu()" 
             title="Quartz Worktop Samples">Quartz Worktop Samples</a>
          <a href="/samples/solid-surface-worktop-samples/granite" 
             routerLink="/samples/solid-surface-worktop-samples/granite" 
             (click)="closeMenu()" 
             title="Granite Worktop Samples">Granite Worktop Samples</a>
          <a href="/samples/solid-surface-worktop-samples/solid-wood" 
             routerLink="/samples/solid-surface-worktop-samples/solid-wood" 
             (click)="closeMenu()" 
             title="Wood Worktop Samples">Wood Worktop Samples</a>
        </div>
    </mat-expansion-panel>
      
    <!-- Accessories -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="section-title">Accessories</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="menu-links">
          <a href="/accessories/lighting" 
             routerLink="/accessories/lighting" 
             (click)="closeMenu()" 
             title="Lighting">Lighting</a>
          <a href="/accessories/waste-systems" 
             routerLink="/accessories/waste-systems" 
             (click)="closeMenu()" 
             title="Waste Systems">Waste Systems</a>
          <a href="/accessories/cutlery-inserts" 
             routerLink="/accessories/cutlery-inserts" 
             (click)="closeMenu()" 
             title="Cutlery Inserts">Cutlery Inserts</a>
          <a href="/accessories/drawer-boxes" 
             routerLink="/accessories/drawer-boxes" 
             (click)="closeMenu()" 
             title="Drawer Boxes">Drawer Boxes</a>
          <a href="/accessories/support-legs" 
             routerLink="/accessories/support-legs" 
             (click)="closeMenu()" 
             title="Support Legs">Support Legs</a>
          <a href="/accessories/glass-shelves" 
             routerLink="/accessories/glass-shelves" 
             (click)="closeMenu()" 
             title="Shelving Systems">Shelving Systems</a>
          <a href="/accessories/waste-systems" 
             routerLink="/accessories/waste-systems" 
             (click)="closeMenu()" 
             title="Pullouts">Pullouts</a>
        </div>
    </mat-expansion-panel>
      
    <!-- Help & Support -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="section-title">Help & Support</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="menu-links">
          <strong>Aftersales</strong>
          <a href="/account/orders" 
             routerLink="/account/orders" 
             (click)="closeMenu()" 
             title="Amend your order">Amend your order</a>
          <a href="/account/orders" 
             routerLink="/account/orders" 
             (click)="closeMenu()" 
             title="View your orders">View your orders</a>
          <a href="/account/orders" 
             routerLink="/account/orders" 
             (click)="closeMenu()" 
             title="Submit aftersales issue">Have a aftersales issue?</a>
          <a href="/account/orders" 
             routerLink="/account/orders" 
             (click)="closeMenu()" 
             title="View aftersales tickets">View aftersales tickets</a>
      
          <strong>Presales</strong>
          <a href="/account" 
             routerLink="/account" 
             (click)="closeMenu()" 
             title="Have a question">Have a pre-sales question?</a>
          <a href="/account/orders/?ps=true" 
             routerLink="/account/orders/?ps=true" 
             (click)="closeMenu()" 
             title="View pre-sales tickets">View pre-sales tickets</a>
      
          <strong>Website tools</strong>
          <a href="/account" 
             routerLink="/account" 
             (click)="closeMenu()" 
             title="Retrieve basket">Retrieve basket</a>
          <a href="/basket" 
             routerLink="/basket" 
             (click)="closeMenu()" 
             title="Create new basket">Create new basket</a>
        </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
