@import '/src/theme/variables';

component-solid-surface-config-splashback-cill-dialog {
    h4 {
        text-align: center;
    }

    h5 {
        margin: 0;
        margin-bottom: 25px;
        text-align: center;
        text-transform: uppercase;
    }

    .error-message {
        margin-top: 15px;
        margin-bottom: 0;
    }

    .mat-mdc-form-field {
        width: 100%;

        & + .mat-mdc-form-field {
            margin-top: 15px;
        }
    }

    .options {
        display: flex;
        grid-gap: 25px;
        margin-bottom: 25px;
    }
}