import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { WoodWorktopsRouting } from './wood-worktops.routing';
import { WoodWorktopsPage } from './wood-worktops.page';


@NgModule({
    imports: [
        SharedModule,
        WoodWorktopsRouting
    ],
    declarations: [
        WoodWorktopsPage
    ]
})
export class WoodWorktopsPageModule { }
