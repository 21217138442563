@import '/src/theme/variables';

.range-selector-dialog {
    width: 80vw;
    height: 85vh;
}

component-range-selector-dialog {
    .mat-mdc-dialog-content {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }

    header {
        display: flex;
        margin-bottom: 20px;

        .toggle-selector {
            display: flex;
            flex-grow: 1;
            gap: 30px;
        }
    
        .close {
            margin-left: 25px;
            cursor: pointer;
        }
    }

    .modal-content-cover {
        display: flex;
        overflow: hidden;
    }

    .search {
        margin-bottom: 15px;
        margin-top: 5px;
        font-size: 0.75rem;

        .search-form-field {
            width: 200px;
        }

        .mat-form-field-wrapper {
            padding: 0;
        }

        .mat-icon {
            cursor: pointer;
        }
    }

    .unit-overview {
        display: flex;
        flex-direction: column;
        padding: 0 0 0 20px;
        overflow-y: auto;
        min-width: 250px;
        max-width: 250px;

        .save-selection {
            width: 100%;
            text-transform: uppercase;
        }
    }

    .styles {
        .style {
            padding: 0.5rem;
            border: 1px solid rgba(0, 0, 0, 0.125);
            border-bottom: none;
            color: #495057;
            font-size: 15px;
            text-align: left;
            line-height: 1.618rem;
            cursor: pointer;

            &:first-child {
                border-top-left-radius: 0.25rem;
                border-top-right-radius: 0.25rem
            }

            &:last-child {
                border-bottom-left-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            }

            &:hover {
                background: #f8f9fa;
                color: #495057;
            }

            &.selected {
                border-color: color('brand-primary');
                background: color('brand-primary');
                color: #ffffff;
            }
        }
    }

    .doors {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        justify-content: flex-start;
        overflow-y: auto;
        margin-left: 15px;
        column-gap: 4px;

        .door {
            padding: 10px;
            max-width: 122px;
            cursor: pointer;

            img {
                display: block;
                border: 1px solid color('copy-border');
                max-width: 100%;
            }

            .title {
                margin-top: 10px;
                margin-bottom: 0;
                font-weight: bold;
                font-size: 14px;
                color: #007bff;

                .small {
                    color: #878787;
                }
            }
        }
    }

    .cabinet-colours {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        justify-content: flex-start;
        overflow-y: auto;
        margin-left: 0;
        padding: 0 7px;
        gap: 12px;

        .cabinet-colour {
            padding: 10px;
            max-width: 160px;
            cursor: pointer;

            .title {
                margin-top: 10px;
                margin-bottom: 0;
                font-weight: bold;
                font-size: 14px;
                color: #007bff;

                .small {
                    color: #878787;
                }
            }

            img {
                display: block;
                max-width: 100%;
            }

            p {
                margin-top: 10px;
                font-weight: 500;
            }
        }
    }

    .cabinet-image {
        position: relative;
        margin-top: 0;
        width: 220px;

        img {
            display: block;
            width: 100%;
        }

        .carcase-image {
            padding: 3px;
        }

        .door-image {
            position: absolute;
            top: 3px;
        }

        .th-door-image {
            position: absolute;
            top: 0px;
            left: 3px;
        }

        .profile-image {
            position: absolute;
            top: 2px;
            left: 3px;
        }
    }

    .cabinet-details {
        margin-bottom: 30px;

        p {
            margin-bottom: 0;
        }

        .title {
            margin-top: 10px;
            margin-bottom: 10px;
            font-weight: 500;
        }
    }

    .no-range {
        padding: 300px 50px;
        font-weight: 600;
        text-align: center;
    }
}

@media (max-width: 1024px) {
    .range-selector-dialog {
        width: 90vw;
    }

    component-range-selector-dialog {
        .cabinet-image {
            width: 180px;
        }
    }
}

@media (max-width: 768px) {
    .range-selector-dialog {
        width: 100%;
    }
}
