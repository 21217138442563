import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';
import { DirectivesModule } from '@app/directives';
import { PipesModule } from '@app/pipes';

import { BasketComponentModule } from '../basket/basket.component.module';

import { HeaderSearchComponent } from './header-search.component';

@NgModule({
    imports: [
        DesignModule,
        DirectivesModule,
        PipesModule,
        BasketComponentModule
    ],
    exports: [
        HeaderSearchComponent
    ],
    declarations: [
        HeaderSearchComponent
    ]
})
export class HeaderSearchComponentModule { }
