@import '/src/theme/variables';

page-sinks-and-taps {
    h1 {
        margin-top: 0;
        margin-bottom: 2rem;
        font-size: 3.157rem;
        font-weight: 300;
        line-height: 4.209rem;
    }
}
