@import '/src/theme/variables';

component-directions-dialog {
    .mat-mdc-dialog-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            margin: 0;
            font-size: 2.375rem;
            font-weight: 600;
        }

        .close {
            margin-left: 20px;
            cursor: pointer;
        }
    }

    .mat-mdc-dialog-content {
        .mat-mdc-form-field {
            width: 100%;

            .mat-mdc-form-field-infix {
                padding: 18px;
                width: 100%;
                font-size: 1rem;
            }
        }
    }

    .mat-mdc-dialog-actions {
        padding: 0 25px 25px;

        .mdc-button {
            padding: 26px;
            width: 100%;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
}