@import '/src/theme/variables';

.component-login-dialog {
    width: calc(100% - 20px);
    height: calc(100% - 20px);

    .mat-dialog-container {
        padding: 0;
    }
}

component-login-dialog {
    display: flex;
    flex-direction: column;
    height: 100%;

    .close {
        position: absolute;
        top: 15px;
        right: 15px;

        .mat-icon {
            cursor: pointer;
        }
    }

    component-login {
        flex-grow: 1;

        .customer-login-container {
            height: 100%;

            .customer-login-dialog {
                height: 100%;
            }

            .customer-login-panel {
                display: flex;
                flex-direction: column;
                height: 100%;
                
                .actions {
                    margin-top: auto;
                }
            }
        }
    }
}