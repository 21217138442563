@import '/src/theme/variables';

component-comparison-details-dialog {
    .close {
        display: flex;
        justify-content: flex-end;
        margin-top: -25px;

        .mat-icon {
            cursor: pointer;
        }
    }

    h3 {
        margin: 0;
        margin-bottom: 15px;
        color: color('black');
        font-weight: 600;
        line-height: 1.8rem;
    }

    p {
        color: color('black');
        font-weight: 300;
    }

    .panel {
        display: flex;

        .panel-left {
            flex: 0 0 calc(50% - 15px);
            padding-right: 15px;
        }

        .panel-right {
            flex: 0 0 calc(50% - 15px);
            padding-left: 15px;
        }

        .panel-frame {
            height: 100%;

            iframe {
                width: 100%;
                height: 68%;
                border: 0;
            }
        }
    }

    .mdc-button {
        padding: 26px;
        background-color: color('copy-border');
        width: 100%;
        font-weight: 300;
        text-transform: uppercase;
    }
}