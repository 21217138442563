export enum DeliveryVariant {
    ALL = -1,
    DEFAULT = 1,
    UNIT = 2,
    SAMPLE = 3,
    DOOR = 4,
    HANDLE = 5,
    LAMINATES = 6,
    SINK_AND_TAPS = 7,
    APPLIANCES = 8,
    SOLID_SURFACE_WORKTOP = 9,
    ACCESSORIES = 10,
    SMALL_ORDER_UNITS = 11,
    DOOR_LARGE_ORDER = 12,
    WOOD_WORKTOP = 13,
    SMALL_ACCESSORIES = 14
}
