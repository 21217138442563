import { Component, ViewEncapsulation, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute } from '@app/services/navigation/models';
import { AuthService } from '@app/services/auth';
import { BasketService } from '@app/services/basket';
import { ErrorsGeneral } from '@app/services/api/models';

@Component({
    selector: 'component-page-link-basket',
    templateUrl: './link-basket.page.html',
    styleUrls: ['./link-basket.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class LinkBasketPage implements OnInit, OnDestroy {
    private uuid: string;
    public error: string;
    public loggedIn: boolean = false;
    public showLogin: boolean = false;
    private routeSubscription: Subscription;

    constructor(
        private navigationService: NavigationService,
        private basketService: BasketService,
        private authService: AuthService,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                if (route.params && route.params.uuid) {
                    this.uuid = route.params.uuid;
                }

                // this.routeHistory = [{ title: 'Change Order Address', route: `/checkout/order/address/${this.orderNumber}` }];
                // this.navigationService.setNavigation({
                //     title: 'Kitchens - Your account',
                //     metaTags: [{ name: 'no-meta', content: 'no metadata' }],
                //     routeHistory: this.routeHistory
                // });
            }
        );

        this.authService.authentication.subscribe(
            (isAuthed) => {
                if (isAuthed !== null) {
                    if (!isAuthed) {
                        this.loggedIn = false;
                        // this.login();
                    } else {
                        this.loggedIn = true;
                        // this.loadBasket();
                        // this.load();
                    }
                    this.loadBasket();
                }

                // if (isAuthed) {
                //     // Link Basket
                //         // If yes
                //             // Go to basket
                //         // If no
                //             // Show error

                //     // this.orderService.getOrderDetails(this.orderNumber)
                //     //     .then((order) => {
                //     //         if (order) {
                //     //             this.order = order;
                //     //             this.selectedDelivery = {
                //     //                 date: order.deliveryDate,
                //     //                 pickDeliveryLater: order.pickDeliveryLater || false,
                //     //                 level: order.deliveryType,
                //     //                 cost: order.deliveryCost
                //     //             };
                //     //             this.loaded = true;
                //     //         } else {
                //     //             this.dialogService.error(this.constructor.name, 'Failed to load Order');
                //     //         }
                //     //     })
                //     //     .catch((error) => this.dialogService.error(this.constructor.name, error));
                // } else {
                //     // Force Login
                //     this.authService.login();
                // }
            }
        );
    }

    public login() {
        this.authService.login()
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public loadBasket() {
        this.basketService.linkBasket(this.uuid)
            .then((response) => {
                this.basketService.switch(response)
                    .then(() => {
                        this.navigationService.navigate(['/basket']);
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            })
            .catch((error) => {
                if (error.body && error.body.code) {
                    let code = error.body.code;
                    if (code === ErrorsGeneral.BASKET_NOT_FOUND) {
                        this.dialogService.error(this.constructor.name, error.message);
                    } else if (code === ErrorsGeneral.NOT_AUTHORISED && !this.loggedIn) {
                        this.login();
                        this.showLogin = true;5
                    } else if (code === ErrorsGeneral.NOT_AUTHORISED && this.loggedIn) {
                        this.showLogin = false;
                        this.error = 'An error has occured trying to retrieve your basket. Please contact customer support';
                    } else {
                        this.dialogService.error(this.constructor.name, error);
                    }
                } else {
                    this.dialogService.error(this.constructor.name, error);
                }
            });
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }
}
