import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

@Injectable()
export class CustomRouteReuseStrategy extends RouteReuseStrategy {
    public shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void { }

    public shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return null;
    }

    public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return (future.routeConfig === curr.routeConfig) || (future.data.reuse && curr.data.reuse);
    }
}
