import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DateHelper } from '@app/utilities/helpers';
import { DialogTypes } from './models';

@Component({
    selector: 'component-dialog-input',
    templateUrl: './dialog-input.component.html',
    styleUrls: ['./dialog-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DialogInputComponent {
    public title: string;
    public subTitle: string;
    public content: string;
    public inputLabel: string;
    public optionsLabel: string;
    public html: boolean;

    public inputRequired: boolean = false;

    public inputControl: FormControl;
    public dateInputControl: FormControl;
    public textAreaControl: FormControl;
    public inputForm: FormGroup;
    public numericMode: boolean = false;
    public minimum: number;
    public maximum: number;
    public inputMaxLength: number;

    public selectOptions = [];
    public selectedOption;
    public selectPlaceHolcer = 'Select Option';

    public inputType: string = 'text';

    public multiMode: string[] = [];
    public dialogTypes = DialogTypes;

    public buttons: any = [
        { label: 'cancel', action: false },
        { label: 'okay', action: true }
    ];

    constructor(
        private dialogRef: MatDialogRef<DialogInputComponent>,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.title = data.title;
        this.subTitle = data.subTitle || null;
        this.content = data.content;
        this.inputLabel = data.inputLabel || null;
        this.optionsLabel = data.optionsLabel || null;
        this.html = data.isHtml || false;

        if (data.buttons || data.buttons === null) {
            this.buttons = data.buttons;
        }

        if (data.inputRequired) {
            this.inputRequired = data.inputRequired;
        }

        if (data.numericMode) {
            this.numericMode = data.numericMode;
            if (data.minimum) {
                this.minimum = data.minimum;
            }
            if (data.maximum) {
                this.maximum = data.maximum;
            }
        }

        if (data.multiMode) {
            this.multiMode = data.multiMode;
            this.inputType = 'None';
        }

        if (data.inputType) {
            this.inputType = data.inputType;
        }

        if (data.selectedOption) {
            this.selectedOption = data.selectedOption;
        }

        if (data.selectOptions && data.selectOptions.length) {
            this.selectOptions = data.selectOptions;
        }

        let defaultValue = data.defaultValue || '';

        let validators = [];
        if (this.inputRequired) {
            validators.push(Validators.required);
        }

        if (this.minimum) {
            validators.push(Validators.min(this.minimum));
        }

        if (this.maximum) {
            validators.push(Validators.max(this.maximum));
        }

        if (this.multiMode.length > 0) {
            if (this.multiMode.indexOf(DialogTypes.TEXT) !== -1) {
                this.inputControl = new FormControl('', validators);
                this.inputControl.setValue(defaultValue);
            }

            if (this.multiMode.indexOf(DialogTypes.TEXTAREA) !== -1) {
                this.textAreaControl = new FormControl('', validators);
                this.textAreaControl.setValue(defaultValue);
            }

            if (this.multiMode.indexOf(DialogTypes.DATE) !== -1) {
                this.dateInputControl = new FormControl('', validators);
                this.dateInputControl.setValue(defaultValue);
            }
        } else {
            switch (this.inputType) {
                case 'text':
                    this.inputControl = new FormControl('', validators);
                    this.inputControl.setValue(defaultValue);
                    break;
                case 'textArea':
                    this.textAreaControl = new FormControl('', validators);
                    this.textAreaControl.setValue(defaultValue);
                    break;
                case 'date':
                    this.dateInputControl = new FormControl('', validators);
                    this.dateInputControl.setValue(DateHelper.moment(defaultValue));
                    break;
            }
        }

        if (data.inputMaxLength) {
            this.inputMaxLength = data.inputMaxLength;
        }

        this.inputForm = formBuilder.group({
            inputControl: this.inputControl,
            dateInputControl: this.dateInputControl,
            textAreaControl: this.textAreaControl,
        });
    }

    public optionalFormat(event) {
        if (this.numericMode) {
            if ([46, 8, 9, 27, 13, 110, 190].indexOf(event.keyCode) !== -1 ||
                // Allow: Ctrl+A
                (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
                // Allow: Ctrl+C
                (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
                // Allow: Ctrl+V
                (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
                // Allow: Ctrl+X
                (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
                // Allow: home, end, left, right
                (event.keyCode >= 35 && event.keyCode <= 39)) {
                // let it happen, don't do anything
                return;
            }
            // Ensure that it is a number and stop the keypress
            if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
                event.preventDefault();

                return;
            }
        }
    }

    public action(action: any): void {
        if (!action) {
            this.closeDialog(action, null, null);

            return;
        }

        if (!this.isFormValid()) {
            return;
        }

        let input = '';
        let multiResponse = {
            text: '',
            textArea: '',
            options: '',
            date: ''
        };

        if (this.multiMode.length > 0) {
            if (this.multiMode.indexOf(DialogTypes.TEXT) !== -1) {
                multiResponse.text = this.inputControl.value;
            }

            if (this.multiMode.indexOf(DialogTypes.TEXTAREA) !== -1) {
                multiResponse.textArea = this.textAreaControl.value;
            }

            if (this.multiMode.indexOf(DialogTypes.OPTIONS) !== -1) {
                multiResponse.options = this.selectedOption;
            }

            if (this.multiMode.indexOf(DialogTypes.DATE) !== -1) {
                multiResponse.date = this.dateInputControl.value.format('YYYY-MM-DD');
            }
        } else {
            switch (this.inputType) {
                case 'text':
                    input = this.inputControl.value;
                    break;
                case 'textArea':
                    input = this.textAreaControl.value;
                    break;
                case 'options':
                    input = this.selectedOption;
                    break;
                case 'date':
                    input = this.dateInputControl.value;
                    if (input && typeof input !== 'string') {
                        input = DateHelper.format(this.dateInputControl.value, true);
                    }
                    break;
            }
        }

        this.closeDialog(action, input, multiResponse);
    }

    private isFormValid() {
        if (this.multiMode.length > 0) {
            let valid = true;
            if (this.multiMode.indexOf(DialogTypes.TEXT) !== -1) {
                valid = valid && this.inputForm.status === 'VALID';
            }

            if (this.multiMode.indexOf(DialogTypes.TEXTAREA) !== -1) {
                valid = valid && this.textAreaControl.status === 'VALID';
            }

            if (this.multiMode.indexOf(DialogTypes.DATE) !== -1) {
                valid = valid && this.dateInputControl.status === 'VALID';
            }

            return valid;
        } else {
            switch (this.inputType) {
                case 'text':
                    return this.inputForm.status === 'VALID';
                case 'textArea':
                    return this.textAreaControl.status === 'VALID';
                case 'date':
                    return this.dateInputControl.status === 'VALID';
                default:
                    return true;
            }
        }
    }

    public close() {
        this.dialogRef.close(null);
    }

    private closeDialog(action, input, multiResponse) {
        this.dialogRef.close({
            action,
            input,
            multiResponse
        });
    }
}
