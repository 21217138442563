import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogButton } from '../../models';

@Component({
    selector: 'component-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DialogComponent {
    public title: string;
    public subTitle: string;
    public content: string;
    public html: boolean;
    public buttons: DialogButton[] = [{ label: 'cancel', action: false }, { label: 'okay', action: true }];
    public showCloseButton: boolean = false;

    constructor(private dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.title = data.title;
        this.subTitle = data.subTitle || null;
        this.content = data.content;
        this.html = data.isHtml || false;

        if (data.buttons || data.buttons === null) {
            this.buttons = data.buttons;
        }

        if (data && data.showCloseButton) {
            this.showCloseButton = true;
        }
    }

    public action(value: any): void {
        this.dialogRef.close(value);
    }

    public close() {
        this.dialogRef.close(null);
    }
}
