export enum MegaMenuCategories {
    KITCHENS = 'kitchens',
    UNITS = 'units',
    DOORS = 'doors',
    APPLIANCES = 'appliances',
    SINKS_AND_TAPS = 'sinks-and-taps',
    WORKTOPS = 'worktops',
    HANDLES = 'handles',
    SAMPLES = 'samples',
    ACCESSORIES = 'accessories'
}

export enum MegaMenuTemplates {
    CATEGORIES = 'categories',
    ITEMS = 'items',
    IMAGE = 'image',
    SWATCH = 'swatch',
    LINK = 'link'
}

export interface IMegaMenu {
    [x: string]: IMegaMenuCategory;
}

export interface IMegaMenuCategory {
    title: string;
    subCategories?: IMegaMenuSubCategory[];
    items?: IMegaMenuItem[];
    template: MegaMenuTemplates;
    viewAll?: string;
}

export interface IMegaMenuSubCategory {
    id?: number;
    title?: string;
    name: string;
    url?: string;
    items: IMegaMenuItem[];
    additionalItems?: IMegaMenuItem[];
    template: MegaMenuTemplates;
}

export interface IMegaMenuItem {
    name: string;
    colour?: string;
    url?: string;
    image?: IMegaMenuImage;
    anchor?: IMegaMenuAnchor;
}

export interface IMegaMenuImage {
    url: string;
    logo?: string;
}

export interface IMegaMenuAnchor {
    url: string;
    title: string;
}
