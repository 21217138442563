import { Injectable } from '@angular/core';

import { CatalogueService } from '@app/services/catalogue';
import { Layouts } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';

@Injectable()
export class FilterService {

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService
    ) { }


    public initFilters(products: any[], filters: any[], sectionCats: any[], section: string, sectionURL: string): any[] {
        let activeFilters: any[];
        if (products && products[0]) {
            let catFilter = { title: 'Category', opts: [] };
            if (sectionCats) {
                sectionCats.forEach((cat) => {
                    const routeEnd = cat.name.toLowerCase().replace(/ /g, '-').replace('all-styles', 'allstyles');
                    let routeSuffix = '';
                    if (
                        cat.name === 'Granite' ||
                        cat.name === 'Dekton' ||
                        cat.name === 'Quartz'
                    ) {
                        routeSuffix = '-worktops';
                    }
                    catFilter.opts.push({
                        title: cat.title,
                        name: cat.name,
                        count: cat.count,
                        tick: section === routeEnd,
                        route: `/${sectionURL}/${routeEnd}${routeSuffix}`
                    });
                });
            }

            filters.forEach((filter) => {
                filter.opts = [];
            });

            let subCatFilter = filters.find((filter) => filter.field === 'subCategory');
            if (subCatFilter) {
                subCatFilter.opts.push({
                    name: '',
                    value: 'all',
                    count: 1,
                    rank: 1,
                    tick: (subCatFilter.preselected) ? (subCatFilter.preselected === subCatFilter.subCategoryLink) : false
                })
            }

            filters.unshift(catFilter);
            const addFilterOption = (filter, value, rank, cssClass, product) => {
                if (value || filter.field === 'subCategory') {
                    let token = filter.token || '';
                    let exists = (filter.field === 'subCategory')
                        ? filter.opts.filter(opt => opt.value === product.subCategoryLink)
                        : filter.opts.filter(opt => opt.name === value + token);
                    if (exists.length > 0) {
                        exists[0].count++;
                    } else {
                        if (filter.field === 'subCategory') {
                            filter.opts.push({
                                name: product.subCategoryName,
                                value: product.subCategoryLink,
                                count: 1,
                                rank: rank,
                                css_class: cssClass,
                                tick: filter.preselected ? filter.preselected === product.subCategoryLink : false
                            });
                        } else {
                            filter.opts.push({
                                name: value + token,
                                value: value,
                                count: 1,
                                rank: rank,
                                css_class: cssClass,
                                tick: filter.preselected ? filter.preselected === value.toLowerCase().replace(/ /g, '-') : false
                            });
                        }
                    }
                }
            };

            products.forEach((entry) => {
                let product = entry.item || entry;
                filters.forEach((filter) => {
                    if (filter.title === 'Width' && entry.widths) {
                        entry.widths.forEach((width) => {
                            addFilterOption(filter, width.width, null, null, null);
                        });
                    } else {
                        if (filter.field) {
                            if (Array.isArray(product[filter.field])) {
                                product[filter.field].forEach(elm => addFilterOption(filter, elm, null, null, null));
                            } else {
                                const value = (product[filter.field] && product[filter.field] !== '0') ? product[filter.field] : null;
                                addFilterOption(filter, value, filter.rankField ? product[filter.rankField] : null, null, product);
                            }
                        } else if (filter.objectArrayField) {
                            product[filter.objectArrayField.field].forEach((obj) => {
                                addFilterOption(filter, obj[filter.objectArrayField.subField],
                                    filter.objectArrayField.rankField ? obj[filter.objectArrayField.rankField] : null, obj[filter.objectArrayField.cssField], null);
                            });
                        }
                    }
                });
            });

            activeFilters = filters.filter((filter) => filter.opts.length > 1);
            activeFilters.forEach((filter) => {
                if (filter.preselected) {
                    filter.initOpen = true;
                }
                if (filter.opts[0].value !== undefined) {
                    filter.opts.sort((a, b) => {
                        let aValue = (a.rank !== undefined && a.rank !== null) ? a.rank : a.value;
                        let bValue = (b.rank !== undefined && b.rank !== null) ? b.rank : b.value;
                        if (typeof aValue === 'number') {
                            return aValue - bValue;
                        }
                        if (aValue !== null) {
                            return aValue.localeCompare(bValue);
                        }

                        return a.value.localeCompare(b.value);
                    });
                }
            });

            if (!activeFilters.find(filter => filter.initOpen)) {
                activeFilters[0].initOpen = true;
            }
        }

        return activeFilters || filters;
    }

    public updateOrder(filteredResults: any[], sortOrder: string): any[] {
        const rangePrice = (range) => {
            if (range.guidePrices[range.selectedColour.name]) {
                return range.guidePrices[range.selectedColour.name][Layouts.L_SHAPED].price;
            }

            return 0;
        };
        switch (sortOrder) {
            default: // popularity
                filteredResults.sort((a, b) => {
                    let itemA = a.item || a;
                    let itemB = b.item || b;
                    // Push 'unavailable' (items with no cost) to the bottom of the results
                    if (itemA._cost === undefined && itemB._cost === undefined) {
                        return 0;
                    }
                    if (itemA._cost === undefined) {
                        return 1;
                    }
                    if (itemB._cost === undefined) {
                        return -1;
                    }
                    // TODO - replace category ranks with real popularity data
                    if (a.maxPopularity !== undefined) {
                        return b.maxPopularity - a.maxPopularity;
                    }
                    if (itemA.rank !== itemB.rank) {
                        return itemA.rank - itemB.rank;
                    }
                    if (itemA.catRank !== itemB.catRank) {
                        return itemA.catRank - itemB.catRank;
                    }
                    if (itemA.subCatRank !== itemB.subCatRank) {
                        return itemA.subCatRank - itemB.subCatRank;
                    }
                    if (itemA.specificCategoryRank !== itemB.specificCategoryRank) {
                        return itemA.specificCategoryRank - itemB.specificCategoryRank;
                    }
                
                    return itemA._cost - itemB._cost;
                });
                
                break;
            case 'low-high':
                if ((filteredResults[0].item || filteredResults[0]).guidePrices) { // Ranges
                    filteredResults.sort((a, b) => {
                        let priceA = rangePrice(a.item || a);
                        let priceB = rangePrice(b.item || b);

                        return priceA - priceB;
                    });
                } else {
                    filteredResults.sort((a, b) => {
                        let itemA = a.item || a;
                        let itemB = b.item || b;
                        if (itemA.cost) {
                            return itemA.cost - itemB.cost;
                        } else if (itemA._cost) {
                            return itemA._cost - itemB._cost;
                        }

                        return itemA.price - itemB.price;
                    });
                }
                break;
            case 'high-low':
                if ((filteredResults[0].item || filteredResults[0]).guidePrices) { // Ranges
                    filteredResults.sort((a, b) => {
                        let priceA = rangePrice(a.item || a);
                        let priceB = rangePrice(b.item || b);

                        return priceB - priceA;
                    });
                } else {
                    filteredResults.sort((a, b) => {
                        let itemA = a.item || a;
                        let itemB = b.item || b;
                        if (itemA.cost) {
                            return itemB.cost - itemA.cost;
                        } else if (itemA._cost) {
                            return itemB._cost - itemA._cost;
                        }

                        return itemB.price - itemA.price;
                    });
                }
                break;
            case 'a-z':
                filteredResults.sort((a, b) => {
                    let itemA = a.item || a;
                    let itemB = b.item || b;
                    if (itemA.title) {
                        return itemA.title.localeCompare(itemB.title);
                    } else if (itemA.name) {
                        return itemA.name.localeCompare(itemB.name);
                    } else if (itemA.desc) {
                        return itemA.desc.localeCompare(itemB.desc);
                    }
                });
                break;
            case 'z-a':
                filteredResults.sort((a, b) => {
                    let itemA = a.item || a;
                    let itemB = b.item || b;

                    if (itemA.title) {
                        return itemB.title.localeCompare(itemA.title);
                    } else if (itemA.name) {
                        return itemB.name.localeCompare(itemA.name);
                    } else if (itemA.desc) {
                        return itemB.desc.localeCompare(itemA.desc);
                    }
                });
                break;
        }
        return filteredResults;
    }

    public filterItems(activeFilters: any[], items: any[], sectionURL: string, sortOrder: string) {
        let filteredResults: any[];

        filteredResults = items.filter((entry) => {
            let item = entry.item || entry;
            let include = true;
            let filterCount = 0;

            activeFilters.forEach((filter) => {
                if (filter.title !== 'Category') {
                    let tested = false;
                    let passed = false;

                    filter.opts.forEach((option) => {
                        if (option.tick) {
                            filterCount++;
                            tested = true;

                            if (filter.title === 'Width' && sectionURL === 'kitchen-units') {
                                let matchingWidths = entry.widths.filter((width) => option.value === width.width);
                                if (matchingWidths.length > 0) {
                                    passed = true;
                                    // Also select the filtered width
                                    this.catalogueService.getUnitAndDoor(matchingWidths[0].variants[0].code)
                                        .then((unit) => {
                                            if (unit) {
                                                entry.item = unit;
                                            }
                                        })
                                        .catch((error) => this.dialogService.error(this.constructor.name, error));
                                }
                            } else if (filter.field === 'subCategory' && option.value === 'all') {
                                passed = true;
                            } else {
                                if (filter.field) {
                                    const filterField = (filter.field === 'subCategory') ? 'subCategoryLink' : filter.field;
                                    if (Array.isArray(item[filterField])) {
                                        if (item[filterField].indexOf(option.value) !== -1) {
                                            passed = true;
                                        }
                                    } else if (item[filterField] === option.value) {
                                        passed = true;
                                    }
                                } else if (filter.objectArrayField) {
                                    let match = item[filter.objectArrayField.field]
                                        .filter(obj => obj[filter.objectArrayField.subField] === option.value);
                                    if (match.length > 0) {
                                        passed = true;
                                    }
                                }
                            }
                        }
                    });

                    if (tested && !passed) {
                        include = false;
                    }
                }
            });

            if (entry?.item?.hideInListings) {
                include = false;
            }

            return include;
        });

        let filteredCount = filteredResults.length;
        if (filteredCount) {
            filteredResults = this.updateOrder(filteredResults, sortOrder);
        }
        if (activeFilters && activeFilters.length) {
            let totalFilters = 0;
            activeFilters.forEach((filter) => {
                if (
                    filter.title !== 'Category' &&
                    filter.title !== 'Width' &&
                    filter.title !== 'Height' &&
                    filter.title !== 'Depth'
                ) {
                    if (filter.opts && filter.opts.length) {
                        totalFilters++;
                    }
                }
            });
        }
        return filteredResults;
    }

    public limitFilters(activeFilters: any[], items: any[], sectionURL: string): any[] {
        let activeFiltersCopy = JSON.parse(JSON.stringify(activeFilters));
        let itemsCopy = JSON.parse(JSON.stringify(items));

        // Reset disabled flag
        activeFiltersCopy.forEach((filter) => {
            filter.opts?.forEach(option => {
                delete option['disabled'];
            });
        });

        // Dry-run each filter on a copy, marking those that yield no results with a disabled flag
        activeFiltersCopy.forEach((filter) => {
            if (Object.hasOwn(filter, 'opts') && filter.title !== 'Category') {
                let tickedOptions = filter.opts.filter(option => option.tick).map(option => {
                    option.tick = false;
                    return option.name;
                });
                filter.opts.forEach((option) => {
                    option.tick = true;
                    option['disabled'] = !!!this.filterItems(activeFiltersCopy, itemsCopy, sectionURL, 'popularity').length;
                    option.tick = false;
                });
                let tickedOptionsSet = new Set(tickedOptions);
                filter.opts.forEach(option => {
                    if (tickedOptionsSet.has(option.name)) {
                        option.tick = true;
                    }
                });
            }
        });

        return activeFiltersCopy;
    }

}
