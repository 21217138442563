import { TableDataOptions, MatPaginator, MatSort } from './models';

import { TableDataSource } from './table-data.source';

import { LocalDataStream } from './local-data.stream';
import { RemoteDataStream } from './remote-data.stream';

export class TableDataModel<T> {
    public source: TableDataSource<T>;

    get length() {
        if (this.source && this.source.paginator) {
            return this.source.paginator.length;
        } else {
            return this.params.length;
        }
    }

    set length(length: number) {
        this.params.length = length;

        if (this.source && this.source.paginator) {
            this.source.paginator.length = length;
        }
    }

    get pageIndex() {
        if (this.source && this.source.paginator) {
            return this.source.paginator.pageIndex;
        } else {
            return this.params.pageIndex;
        }
    }

    set pageIndex(pageIndex: number) {
        this.params.pageIndex = pageIndex;

        if (this.source && this.source.paginator) {
            this.source.paginator.pageIndex = pageIndex;
        }
    }

    get pageSize() {
        if (this.source && this.source.paginator) {
            return this.source.paginator.pageSize;
        } else {
            return this.params.pageSize;
        }
    }

    set pageSize(pageSize: number) {
        this.params.pageSize = pageSize;

        if (this.source && this.source.paginator) {
            this.source.paginator.pageSize = pageSize;
        }
    }

    get pageSizeOptions() {
        if (this.source && this.source.paginator) {
            return this.source.paginator.pageSizeOptions;
        } else {
            return this.params.pageSizeOptions;
        }
    }

    set pageSizeOptions(pageSizeOptions: number[]) {
        this.params.pageSizeOptions = pageSizeOptions;

        if (this.source && this.source.paginator) {
            this.source.paginator.pageSizeOptions = pageSizeOptions;
        }
    }

    get sortColumn() {
        if (this.source && this.source.sorter && this.source.sorter.active) {
            return this.source.sorter.active;
        } else {
            return null;
        }
    }

    get sortDirection() {
        if (this.source && this.source.sorter && this.source.sorter.direction) {
            return this.source.sorter.direction;
        } else {
            return null;
        }
    }

    get stream(): LocalDataStream<T> | RemoteDataStream<T> {
        return this.source.stream;
    }

    private sortBy: Function;
    private params = {
        length: 0,
        pageIndex: 0,
        pageSize: 25,
        pageSizeOptions: [25, 50, 100, 500]
    };

    constructor(options: TableDataOptions<T> = {}) {
        this.sortBy = options.sortBy || (
            (type: string, a: any, b: any) => {
                let propertyA = a[type];
                let propertyB = b[type];
                // TODO: Issue here when Col DEF does not match the object key
                // console.log(propertyA, propertyB);

                return [(a[type] || ''), (b[type] || '')];
            }
        );

        this.source = this.create(options.matPaginator, options.matSort, options.remoteDataStream);

        this.pageIndex = (options.pageIndex) ? options.pageIndex : this.params.pageIndex;
        this.pageSize = (options.pageSize) ? options.pageSize : this.params.pageSize;
        this.pageSizeOptions = (options.pageSizeOptions) ? options.pageSizeOptions : this.params.pageSizeOptions;
    }

    public setPaginator(matPaginator: MatPaginator) {
        if (matPaginator) {
            this.source.paginator = matPaginator;

            this.source.paginator.length = this.params.length;
            this.source.paginator.pageIndex = this.params.pageIndex;
            this.source.paginator.pageSize = this.params.pageSize;
            this.source.paginator.pageSizeOptions = this.params.pageSizeOptions;
        }
    }

    public setSorter(matSort: MatSort) {
        if (matSort) {
            this.source.sorter = matSort;
        }
    }

    private create(matPaginator?: MatPaginator, matSort?: MatSort, dataStream?: RemoteDataStream<T>): TableDataSource<T> {
        let source: TableDataSource<T> = new TableDataSource<T>();
        source.paginator = matPaginator;
        source.sorter = matSort;
        source.sortBy = (sorter: MatSort, a: T, b: T) => {
            let propertyA: boolean | number | string;
            let propertyB: boolean | number | string;

            [propertyA, propertyB] = this.sortBy(sorter.active, a, b);

            return (propertyA < propertyB ? -1 : 1) * (sorter.direction === 'asc' ? 1 : -1);
        };
        source.stream = (dataStream) ? dataStream : new LocalDataStream<T>();

        return source;
    }
}
