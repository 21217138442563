import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { AboutService } from '@app/services/about';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { RouteHistory } from '@app/services/navigation/models';

import { StickyTabsUnitSpecifications } from './models';
import { IStickyTab } from '@app/components/sticky-tabs/models';

@Component({
    selector: 'page-about-unit-specifications',
    templateUrl: './unit-specifications.page.html',
    styleUrls: ['./unit-specifications.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AboutUnitSpecificationsPage implements OnInit {
    public StickyTabs = StickyTabsUnitSpecifications;
    public tabs: IStickyTab<StickyTabsUnitSpecifications>[] = [
        { title: 'Units', id: StickyTabsUnitSpecifications.UNITS },
        { title: 'Drawer options', id: StickyTabsUnitSpecifications.DRAWER },
        { title: 'Wirework storage solutions', id: StickyTabsUnitSpecifications.WIREWORK },
        { title: 'Colour match', id: StickyTabsUnitSpecifications.COLOUR }
    ];

    public doors = [];
    public units;
    public unitSelected = 'base';
    public unitMenuOpen: boolean = false;

    private routeHistory: RouteHistory[] = [
        { title: 'About Diy Kitchens', route: '/about-diy-kitchens' },
        { title: 'Unit specification', route: '/unit-specifications' }
    ];

    constructor(
        private aboutService: AboutService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: 'Unit Specification',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'description', content: 'We are a family run business that has been manufacturing and supplying high specification kitchens to both trade and retail customers for nearly 30 years.'},
            ],
            routeHistory: this.routeHistory
        });

        this.aboutService.unitSpecifications()
            .then((response) => {
                if (response && response.doors) {
                    this.doors = response.doors;
                }

                if (response && response.units) {
                    this.units = response.units;
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public selectUnitType(unitType) {
        this.unitSelected = unitType;

        this.unitMenuOpen = false;
    }
}
