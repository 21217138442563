<div *ngIf="isVisible" class="dropdown-menu">
    <div class="dropdown-menu-inner" @fade>
        <div class="dropdown-grid-wrapper">
            <div *ngIf="menuCategory.template === MegaMenuTemplates.CATEGORIES" class="tab-list" @fade>
                <ng-container *ngFor="let subCategory of menuCategory.subCategories">
                    <ng-container *ngIf="subCategory.template === MegaMenuTemplates.LINK">
                        <div class="tab">
                            <a (click)="close()" [routerLink]="subCategory.url">{{ subCategory.name }}</a>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="subCategory.template !== MegaMenuTemplates.LINK">
                        <div (click)="selectSubCategory(subCategory.id)"
                            [ngClass]="{ 'active': subCategory.id === subCategoryId }" class="tab">
                                {{ subCategory.title || subCategory.name }}
                        </div>
                    </ng-container>
                </ng-container>
                <div *ngIf="menuCategory.viewAll" class="tab">
                    <a (click)="close()" [routerLink]="menuCategory.viewAll">See All {{ menuCategory.title }}</a>
                </div>
            </div>

            <div class="menu-items">
                <div [ngClass]="{ 'grid-template-4': menuCategory.template === MegaMenuTemplates.CATEGORIES, 'grid-template-5': menuCategory.template === MegaMenuTemplates.ITEMS }">
                    <div @fade *ngFor="let item of items" class="dropdown-block">
                        <ng-template [ngIf]="template === MegaMenuTemplates.IMAGE" [ngIfElse]="swatchLayout">
                            <div class="dropdown-block-content">
                                <a (click)="close()" [routerLink]="item.url" [title]="item.name">
                                    <img [src]="item.image.url" [alt]="item.name"/>
                                    <h3>{{ item.name }}</h3>
                                </a>
                            </div>
                        </ng-template>
                        <ng-template #swatchLayout>
                            <div class="dropdown-swatch-content">
                                <a (click)="close()" [routerLink]="item.anchor.url" [title]="item.name">
                                    <div class="swatch swatch-{{ item.colour }}"></div>
                                    <h3>{{ item.name }}</h3>
                                </a>
                            </div>
                        </ng-template>
                    </div>
                </div>

                <ng-container *ngIf="additionalItems">
                    <div class="additional-items" [ngClass]="{ 'grid-template-4': menuCategory.template === MegaMenuTemplates.CATEGORIES, 'grid-template-5': menuCategory.template === MegaMenuTemplates.ITEMS }">
                        <div @fade *ngFor="let item of additionalItems" class="dropdown-block">
                            <div class="dropdown-block-content">
                                <a (click)="close()" [routerLink]="item.url" [title]="item.name">
                                    <img [src]="item.image.url" [alt]="item.name" loading="lazy"/>
                                    <h3>{{ item.name }}</h3>
                                </a>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="sub-links grid-template-4">
            <div class="bottom-links">
                <a (click)="close()" routerLink="/about-diy-kitchens" title="About Us">
                    <img src="https://static.diy-kitchens.com/assets/images/menu/icon_made-in-britain.svg" height="28" alt="Made in Britain" loading="lazy"/>
                    <span>How our kitchens are made</span>
                </a>
            </div>
            <div class="bottom-links planner-link">
                <a href="https://planner.diy-kitchens.com" target="_blank" title="Planner">
                    <img src="https://static.diy-kitchens.com/assets/images/menu/icon_new_white.svg" height="34" alt="New icon" loading="lazy"/>
                    <span>Try our new kitchen planner</span>
                </a>
            </div>
            <div class="bottom-links">
                <a (click)="close()" routerLink="/customer-reviews" title="See real customer reviews">
                    <img src="https://static.diy-kitchens.com/assets/images/menu/icon_film.svg" height="16" alt="Video icon" loading="lazy"/>
                    <span>What our customers think</span>
                </a>
            </div>
            <div class="bottom-links">
                <a (click)="close()" routerLink="/about-diy-kitchens/showroom" title="Visit our showroom">
                    <img src="https://static.diy-kitchens.com/assets/images/menu/icon_virtual-tour.svg" height="28" alt="Tour icon" loading="lazy"/>
                    <span>Virtual showroom tour</span>
                </a>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isVisible" class="mega-menu-backdrop" [ngClass]="{ 'full-screen': scrollLimitReached }" (click)="close()"></div>
<!-- Build a hidden div full of the mega-menu links for SEO purposes, not strictly a part of the mega-menu! -->
<div class="hidden">
    <div *ngFor="let category of categories">
        <ul *ngIf="category.items && category.items.length">
            <h2>{{category.title}}</h2>
            <li *ngFor="let item of category.items">
                <a [href]="item.url" [title]="item.name">
                    <img [src]="item.image.url" [alt]="item.name" width="100px" loading="lazy"/><br />
                    {{item.name}}
                </a>
            </li>
        </ul>
        <ng-container *ngIf="category.subCategories && category.subCategories.length"></ng-container>
        <ul *ngFor="let subCategory of category.subCategories">
            <h2>{{subCategory.name}}</h2>
            <ng-container *ngIf="subCategory.template !== MegaMenuTemplates.SWATCH">
                <li *ngFor="let item of subCategory.items">
                    <a [href]="item.url" [title]="item.name">
                        <img [src]="item.image.url" [alt]="item.name" width="100px" loading="lazy"/><br />
                        {{item.name}}
                    </a>
                </li>
            </ng-container>
            <ng-container *ngIf="subCategory.template === MegaMenuTemplates.SWATCH">
                <li *ngFor="let item of subCategory.items">
                    <a [href]="item.anchor.url" [title]="item.name">
                        {{item.anchor.title}}
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
</div>
<!-- End hidden div -->
