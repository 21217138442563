import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { NavigationService } from '@app/services/navigation';
import { RouteHistory } from '@app/services/navigation/models';

import { StickyTabsOurPartners } from './models';
import { IStickyTab } from '@app/components/sticky-tabs/models';

@Component({
    selector: 'page-about-our-partners',
    templateUrl: './our-partners.page.html',
    styleUrls: ['./our-partners.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AboutOurPartnersPage implements OnInit {
    public StickyTabs = StickyTabsOurPartners;
    public tabs: IStickyTab<StickyTabsOurPartners>[] = [
        { title: 'Innova', id: StickyTabsOurPartners.INNOVA },
        { title: 'Egger', id: StickyTabsOurPartners.EGGER },
        { title: 'Blum', id: StickyTabsOurPartners.BLUM },
        { title: 'Kessebohmer', id: StickyTabsOurPartners.KESSEBOHMER },
        { title: 'Duropal', id: StickyTabsOurPartners.DUROPAL }
    ];

    private routeHistory: RouteHistory[] = [
        { title: 'About Diy Kitchens', route: '/about-diy-kitchens' },
        { title: 'Our Partners', route: '/about-diy-kitchens/our-partners' }
    ];

    constructor(
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: 'Our Partners',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'description', content: 'We are a family run business that has been manufacturing and supplying high specification kitchens to both trade and retail customers for nearly 30 years.'},
            ],
            routeHistory: this.routeHistory
        });
    }
}
