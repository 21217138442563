import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { StringHelper, URLHelper } from '@app/utilities/helpers';

@Component({
    selector: 'component-solid-surface-config-change-style-dialog',
    templateUrl: './change-style.dialog.component.html',
    styleUrls: ['./change-style.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SolidSurfaceConfigChangeStyleDialogComponent {
    public worktop: any;
    public category: any;
    public categories: any;
    public options;

    private worktops: any;

    constructor(
        private urlHelper: URLHelper,
        private dialogRef: MatDialogRef<SolidSurfaceConfigChangeStyleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.worktop = this.data.worktop;
        this.worktops = this.data.worktops;

        let categories = {};
        this.worktops.forEach((worktop) => {
            worktop.route = this.urlHelper.configRoute(worktop);
            worktop.code = StringHelper.spaceToDash(worktop.product_code);

            if (!worktop.cat.match(/laminate/i)) {
                categories[worktop.cat] = true;
            }
        });

        this.categories = Object.keys(categories);

        this.selectCat(this.worktop.cat);
    }

    public close() {
        this.dialogRef.close(null);
    }

    public setStyle(option) {
        this.dialogRef.close({
            worktop: option
        });
    }

    public selectCat(category) {
        this.category = category;
        const options = this.worktops.filter((worktop) => worktop.cat === this.category);
        options.sort((a, b) => {
            if (a.sub_cat < b.sub_cat) {
                return -1;
            } else if (a.sub_cat > b.sub_cat) {
                return 1;
            }

            return 0;
        });
        this.options = options;
    }
}
