
<section class="content-contained" *ngIf="loaded">
    <mat-vertical-stepper [linear]="true" animationDuration="0" #stepper (selectionChange)="stepperUpdate($event)">
        <mat-step *ngIf="!authService.isAuthenticated">
            <ng-template matStepLabel>LOGIN OR REGISTRATION</ng-template>
            <component-register></component-register>
        </mat-step>

        <mat-step *ngIf="!isSampleOrder" [stepControl]="termsFormGroup">
            <ng-template matStepLabel>TERMS & CONDITIONS</ng-template>
            <component-terms *ngIf="authService.isAuthenticated" [stepper]="stepper" [formGroup]="termsFormGroup"></component-terms>
        </mat-step>

        <mat-step [stepControl]="detailsFormGroup">
            <ng-template matStepLabel>DELIVERY & BILLING ADDRESS</ng-template>
            <component-customer-details *ngIf="termsFormGroup.valid" [stepper]="stepper" [formGroup]="detailsFormGroup"></component-customer-details>
        </mat-step>

        <mat-step [stepControl]="deliveryFormGroup">
            <ng-template matStepLabel>CHOOSE DELIVERY TYPE & DATE</ng-template>
            <component-delivery-options *ngIf="detailsFormGroup.valid" [stepper]="stepper" [formGroup]="deliveryFormGroup"
            (setAccessSurchargeCheck)="setAccessSurchargeCheck($event)"></component-delivery-options>
        </mat-step>

        <mat-step #accessStep [stepControl]="accessFormGroup" *ngIf="!isSampleOrder && accessSurchargeCheckRequired">
            <ng-template matStepLabel>DELIVERING YOUR ORDER</ng-template>
            <component-delivery-access *ngIf="deliveryFormGroup.valid" [step]="accessStep" [stepSelected]="stepper.selected" [stepper]="stepper" [formGroup]="accessFormGroup"></component-delivery-access>
        </mat-step>

        <mat-step #summaryStep>
            <ng-template matStepLabel>CONFIRM WHAT'S IN YOUR SHOPPING CART</ng-template>
            <ng-template matStepContent>
                <component-order-summary *ngIf="isSampleOrder || accessFormGroup.valid || !accessSurchargeCheckRequired" [step]="summaryStep" [stepSelected]="stepper.selected" [stepper]="stepper" [formGroup]="summaryFormGroup"></component-order-summary>
            </ng-template>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>PAYMENT</ng-template>
            <!-- <component-lloyd-payment *ngIf="summaryFormGroup.valid" [stepper]="stepper" [basket]="basket"></component-lloyd-payment> -->
            <!-- <component-monek-payment *ngIf="summaryFormGroup.valid" [stepper]="stepper" [basket]="basket"></component-monek-payment> -->
            <component-monek-legacy-payment *ngIf="summaryFormGroup.valid" [stepper]="stepper" [basket]="basket"></component-monek-legacy-payment>
        </mat-step>
    </mat-vertical-stepper>
</section>