import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BasketService } from '@app/services/basket';
import { DialogService } from '@app/services/dialog';

import { ProductType } from '@app/services/catalogue/models';

@Component({
    selector: 'component-notes-dialog',
    templateUrl: './notes.dialog.component.html',
    styleUrls: ['./notes.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class NotesDialogComponent {
    constructor(
        private basketService: BasketService,
        private dialogService: DialogService,
        private dialogRef: MatDialogRef<NotesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data && data.basketItemId && data.itemGroup) {
            this.basketItemId = data.basketItemId;
            this.itemGroup = data.itemGroup;
        }
    }

    public note = '';
    private basketItemId: number;
    public itemGroup: ProductType;
    public saving = false;
    public ProductType = ProductType;

    public addNote() {
        this.saving = true;
        this.basketService.addNote(this.basketItemId, this.note)
            .then(() => this.close(true))
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public close(addedNote = false) {
        this.dialogRef.close(addedNote);
    }
}
