import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Config } from '@app/config';
import { StringHelper } from '@app/utilities/helpers';

import { BasketService } from '@app/services/basket';
import { CatalogueService } from '@app/services/catalogue';
import { Layouts, ImageTypes } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { MarketingService } from '@app/services/marketing';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';

import { KitchenLayoutsDialogComponent } from '@app/shared/dialogs/kitchen-layouts';

import { IPromotion, PromotionTypes } from '@app/services/marketing/models';

@Component({
    selector: 'page-kitchens-styles',
    templateUrl: './range-listing.page.html',
    styleUrls: ['./range-listing.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class KitchensListingsPage implements OnInit, OnDestroy {
    public header: any;

    public weekCommencing: string;
    public styles: any;

    public selectedRangeType: string;
    public selectedColour: string;
    public selectedFinish: string;
    public selectedMaterial: string;
    public selectedLayout: Layouts;
    public selectedSortBy: string;
    public filters;
    public filteredRanges: any;

    private ranges;

    private routeHistory: RouteHistory[] = [
        { title: 'Kitchens', route: '/kitchens' }
    ];

    private route: Subscription;

    constructor(
        private config: Config,
        private basketService: BasketService,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private marketingService: MarketingService
    ) { }

    ngOnInit() {
        this.route = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                this.selectedRangeType = (route.params.rangeType) ? route.params.rangeType.toLowerCase() : 'allstyles';
                this.selectedColour = (route.params.colour) ? route.params.colour.toLowerCase() :
                    (this.selectedRangeType === 'bespoke-painted') ? 'bespoke' : 'allcolours';
                this.selectedFinish = (route.params.finish) ? route.params.finish.toLowerCase() : 'allfinishes';
                this.selectedMaterial = (route.params.material) ? route.params.material.toLowerCase() : 'allmaterial';
                this.selectedLayout = (route.params.layout) ? route.params.layout.toLowerCase() : Layouts.L_SHAPED;
                this.selectedSortBy = (route.params.sortBy) ? route.params.sortBy.toLowerCase() : 'popularity';

                const rangeType = (this.selectedRangeType === 'allstyles') ? '' :
                    StringHelper.titleCase(StringHelper.dashToSpace(this.selectedRangeType)) + ' ';

                let routeHistory = this.routeHistory.slice();

                if (rangeType === '') {
                    routeHistory.push({
                        title: 'All Styles',
                        route: `/kitchens/${this.selectedRangeType}/`
                    });
                } else if (rangeType !== '') {
                    routeHistory.push({
                        title: rangeType,
                        route: `/kitchens/${this.selectedRangeType}/`
                    });
                }

                this.navigationService.setNavigation({
                    title: `${rangeType} Kitchens & Kitchen Units At Trade Prices`,
                    metaTags: [{ name: 'no-meta', content: 'no metadata' }],
                    canonical: `/kitchens/${this.selectedRangeType}`,
                    routeHistory: routeHistory
                });

                this.catalogueService.getRanges()
                    .then((ranges: any) => {
                        this.ranges = JSON.parse(JSON.stringify(ranges));

                        this.setFilters();
                        this.filterRanges();
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));

                // TODO; Get the kitchens styles header data from API.
                this.catalogueService.getKitchensHeader(this.selectedRangeType)
                    .then((header) => {
                        this.header = header;
                        const metaDescription = `See our range of ${this.header.name} Kitchens & kitchen units. All of our ${this.header.name} Kitchens & kitchen units are available at trade prices.`
                        this.navigationService.setMetaTags([
                            { property: 'og:image', content: 'https://static.diy-kitchens.com/assets/images/seo/kitchen-sales/kitchen-sales.jpg' },
                            { name: 'Description', content: metaDescription },
                            { property: 'og:description', content: metaDescription }
                        ]);
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            }
        );
    }

    ngOnDestroy() {
        if (this.route) {
            this.route.unsubscribe();
            this.route = null;
        }
    }

    public filterSort(sortBy: any) {
        return this.getFullUrl('sortBy', sortBy);
    }

    private setFilters() {
        let filters = {
            results: 0,
            sortBy: this.selectedSortBy,
            rangeType: {
                selected: this.selectedRangeType,
                options: [{
                    id: 'allstyles',
                    name: 'All Styles',
                    link: '/kitchens/allstyles/',
                    image: 'https://static.diy-kitchens.com/assets/images/kitchens/highgloss-icon.jpg'
                }]
            },
            colour: {
                selected: this.selectedColour,
                options: [{
                    id: 'allcolours',
                    name: 'All Colours',
                    link: this.getFullUrl('colour', 'allcolours'),
                    image: 'all'
                }]
            },
            finish: {
                selected: this.selectedFinish,
                options: []
            },
            material: {
                selected: this.selectedMaterial,
                options: []
            },
            layout: {
                selected: this.selectedLayout,
                options: []
            },
        };

        if (this.ranges && this.ranges.length) {
            let rangeTypes = [];
            let colours = [];
            let colourClass = {};
            let finishes = [];
            let materials = [];

            this.ranges.forEach((range) => {
                if (this.selectedRangeType === 'allstyles') {
                    range.displayRange = true;
                } else if (range.filters.map((filter) => StringHelper.spaceToDash(filter)).indexOf(this.selectedRangeType) !== -1) {
                    range.displayRange = true;
                } else {
                    range.displayRange = false;
                }

                if (range.filters && range.filters.length) {
                    range.filters.forEach((rangeType) => {
                        if (rangeTypes.indexOf(rangeType) === -1) {
                            rangeTypes.push(rangeType);
                        }
                    });
                }

                if (range.displayRange) {
                    if (range.colours && range.colours.length) {
                        range.colours.forEach((colour) => {
                            colourClass[colour.name] = colour.css_class;

                            if (colours.indexOf(colour.name) === -1) {
                                colours.push(colour.name);
                            }
                        });
                    }

                    if (range.finishes && range.finishes.length) {
                        range.finishes.forEach((finish, idx) => {
                            if (finish === 'Wood' || finish === 'Wood effect') {
                                range.finishes[idx] = 'Wood Effect';
                                finish = range.finishes[idx];
                            }

                            if (finishes.indexOf(finish) === -1) {
                                finishes.push(finish);
                            }
                        });
                    }

                    if (range.door_material) {
                        if (materials.indexOf(range.door_material) === -1) {
                            materials.push(range.door_material);
                        }
                    }
                }
            });

            filters.rangeType.options = filters.rangeType.options.concat(rangeTypes.map((rangeType) => {
                const id = StringHelper.spaceToDash(rangeType);

                return {
                    id: id,
                    name: StringHelper.titleCase(rangeType),
                    link: `/kitchens/${id}/`,
                    image: `https://static.diy-kitchens.com/assets/images/kitchens/${id}-icon.jpg`
                };
            }));

            filters.colour.options = filters.colour.options.concat(colours.sort().map((colour) => {
                const id = StringHelper.spaceToDash(colour);

                return {
                    id: id,
                    name: colour,
                    link: this.getFullUrl('colour', id),
                    image: colourClass[colour]
                };
            }));

            filters.finish.options = finishes.map((finish) => {
                const id = StringHelper.spaceToDash(finish);

                return {
                    id: id,
                    name: finish,
                    link: this.getFullUrl('finish', id),
                    image: `https://static.diy-kitchens.com/door_finishes/door-finish_${id}.png`
                };
            });

            filters.material.options = materials.map((material) => {
                const id = StringHelper.spaceToDash(material);

                return {
                    id: id,
                    name: material,
                    link: this.getFullUrl('material', id),
                    image: `https://static.diy-kitchens.com/door_finishes/door-finish_${id}.png`
                };
            });

            let layouts = [
                Layouts.STRAIGHT_RUN,
                Layouts.L_SHAPED,
                Layouts.GALLEY,
                Layouts.U_SHAPED,
                Layouts.L_SHAPED_ISLAND,
                Layouts.U_SHAPED_ISLAND
            ];

            layouts.forEach((layout) => {
                const layoutImage = layout.replace(/-/g, '');

                filters.layout.options.push({
                    id: layout,
                    name: this.catalogueService.getLayoutName(layout),
                    link: this.getFullUrl('layout', layout),
                    image: `https://static.diy-kitchens.com/assets/images/kitchens/${layoutImage}-icon.jpg`
                });
            });

            // range.guidePrices.<colour>.<shape>.
            // cornice: {name: '18 x 3000 x 50mm - Cornice/Pelmet', lengths: 4, price: 104.8}
            // items: [{
            //     price: 249.9
            //     unit_code: 'P2D1-6'
            // }]
            // plinth: {name: '150 x 3000 x 18mm - Plinth', lengths: 3, price: 94.65}
            // price: 1711.2
        }

        // {
        //     id: null,
        //     name: '',
        //     link: '',
        //     image: ''
        // }

        this.filters = filters;
    }

    public howItsMade(range) {
        let videoLink = '';

        switch (range.name) {
            case 'Luca':
                videoLink = 'luca_matt';
                break;
            case 'Norton':
                if (range.selectedColour && range.selectedColour.colour === 'Oak') {
                    videoLink = 'norton_oak';
                } else {
                    videoLink = 'norton_painted';
                }
                break;
            default:
                videoLink = StringHelper.spaceToUnderscore(range.name);
                break;
        }

        return `https://static.diy-kitchens.com/assets/videos/how-its-made/how-its-made_${videoLink}.mp4`;
    }

    public rangeId(rangeName: string) {
        return (rangeName || '').toLowerCase().replace(/\s/g, '-');
    }

    private filterRanges() {
        let filteredRanges = [];

        this.ranges.forEach((range) => {
            range.colours = range.colours.filter((colour) => {
                if (!colour.availability) {
                    const colourId = StringHelper.spaceToDash(colour.name);
                    if (colourId === this.selectedColour) {
                        range.selectedColour = colour;
                    }

                    return true;
                }

                return false;
            });

            if (!range.selectedColour) {
                range.selectedColour = range.colours[0];
            }

            range.selectedKitchenLayout = this.catalogueService.getLayoutName(this.selectedLayout);

            if (this.selectedColour !== 'allcolours') {
                if (range.displayRange) {
                    if (range && range.colours && !range.colours.find((colour) => {
                        return StringHelper.spaceToDash(colour.name) === this.selectedColour;
                    })) {
                        range.displayRange = false;
                    }
                }
            }

            if (this.selectedFinish !== 'allfinishes') {
                if (range.displayRange) {
                    if (range && range.finishes && !range.finishes.find((finish) => {
                        return StringHelper.spaceToDash(finish).toLowerCase() === this.selectedFinish;
                    })) {
                        range.displayRange = false;
                    }
                }
            }

            if (this.selectedMaterial !== 'allmaterial') {
                if (range.displayRange) {
                    if (StringHelper.spaceToDash(range.door_material).toLowerCase() !== this.selectedMaterial) {
                        range.displayRange = false;
                    }
                }
            }

            if (range.displayRange) {
                filteredRanges.push(range);
            }
        });

        this.filters.results = filteredRanges.length;

        filteredRanges.sort((a, b) => {
            switch (this.selectedSortBy) {
                case 'popularity':
                    if (a.rank > b.rank) {
                        return 1;
                    } else if (a.rank < b.rank) {
                        return -1;
                    }
                    break;
                case 'low-high':
                    if (a.fromPrice > b.fromPrice) {
                        return 1;
                    } else if (a.fromPrice < b.fromPrice) {
                        return -1;
                    }
                    break;
                case 'high-low':
                    if (a.fromPrice > b.fromPrice) {
                        return -1;
                    } else if (a.fromPrice < b.fromPrice) {
                        return 1;
                    }
                    break;
                case 'a-z':
                    if (a.name > b.name) {
                        return 1;
                    } else if (a.name < b.name) {
                        return -1;
                    }
                    break;
                case 'z-a':
                    if (a.name > b.name) {
                        return -1;
                    } else if (a.name < b.name) {
                        return 1;
                    }
                    break;
            }

            return 0;
        });

        let styles = [];
        filteredRanges.forEach((range) => {
            (range.colours || []).forEach((rangeColour) => {
                styles.push(`${range.name} ${rangeColour.name}`);
            })
        });

        this.basketService.leadtime(styles, null, true)
            .then((response) => {
                this.styles = response.styles || {};
                this.weekCommencing = response.weekCommencing;
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));

        this.filteredRanges = filteredRanges;
    }

    public rangeWeekCommencing(range) {
        const colour = (range.selectedColour) ?
            range.selectedColour.name : (range.colours && range.colours.length) ?
                range.colours[0].name : '';

        const style = `${range.name} ${colour}`;

        if (this.styles && this.styles[style]) {
            return this.styles[style];
        } else {
            return null;
        }
    }

    public getRangePrice(range): number {
        const selectedColour = range.selectedColour.colour;
        const selectedLayout = this.selectedLayout;
        if (range?.guidePrices && range.guidePrices[selectedColour] && range.guidePrices[selectedColour][selectedLayout]) {
            return range.guidePrices[selectedColour][selectedLayout]?.price || range.fromPrice;
        }

        return range.fromPrice;
    }

    private getFullUrl(param, value) {
        const url = [
            'kitchens',
            this.selectedRangeType,
            (param === 'colour') ? value : this.selectedColour,
            (param === 'finish') ? value : this.selectedFinish,
            (param === 'material') ? value : this.selectedMaterial,
            (param === 'layout') ? value : this.selectedLayout,
            (param === 'sortBy') ? value : this.selectedSortBy
        ].join('/');

        return `/${url}/`;
    }

    public selectRange(range) {
        this.catalogueService.updateActiveRange(range, range.selectedColour.colour);
    }

    public details(range) {
        const style = StringHelper.spaceToDash(`${range.name} ${range.selectedColour.name}`);

        return `/kitchens/${style}/details/`;
    }

    public switchRangeColour(range, colour) {
        range.selectedColour = colour;
    }

    public getRangeImage(range) {
        return this.catalogueService.image({
            rangeId: range.range_id,
            colour: range.selectedColour.name,
            type: ImageTypes.STYLES
        });
    }

    public getRangeDoorImage(range) {
        return 'https://static.diy-kitchens.com/doors/' + range.selectedColour.door_image;
    }

    public plannerLink(ranage: any) {
        return `${this.config.api.endpoints.planner}/range/${ranage.name}:${ranage.selectedColour.name}`;
    }

    public openShapeDialog(range: any) {
        const colorObj = range.guidePrices[range.selectedColour.colour];
        const layoutData = colorObj[this.selectedLayout];

        this.dialogService.custom(KitchenLayoutsDialogComponent, {
            maxWidth: '100%',
            data: {
                style: `${range.name} ${range.selectedColour.name}`,
                layoutId: this.selectedLayout,
                layoutData: layoutData
            }
        })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
