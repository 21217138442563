import { NgModule } from '@angular/core';

import { DesignModule } from '@app/design';
import { DialogsModule } from '@app/dialogs';
import { DirectivesModule } from '@app/directives';
import { PipesModule } from '@app/pipes';

import { ArticlesComponentModule } from './articles/articles.component.module';
import { BannerComponentModule } from './banner/banner.component.module';
import { BasketComponentModule } from './basket/basket.component.module';
import { BreadcrumbsComponentModule } from './breadcrumbs/breadcrumbs.component.module';
import { CreateCustomerComponentModule } from './create-customer/create-customer.component.module'
import { CustomerLookupComponentModule } from './customer-lookup/customer-lookup.component.module'
import { FiltersComponentModule } from './filters/filters.component.module'
import { FooterComponentModule } from './footer/footer.component.module'
import { HeaderComponentModule } from './header/header.component.module'
import { HeaderSearchComponentModule } from './header-search/header-search.component.module'
import { LatestOffersComponentModule } from './latest-offers/latest-offers.component.module'
import { LoaderComponentModule } from './loader/loader.component.module'
import { LoginComponentModule } from './login/login.component.module'
import { MegaMenuComponentModule } from './mega-menu/mega-menu.component.module'
import { MonekLegacyPaymentComponentModule } from './monek-legacy-payment/monek-legacy-payment.component.module'
import { OrderSummaryComponentModule } from './order-summary/order-summary.component.module'
import { ProductComponentModule } from './product/product.component.module'
import { RangeActionsComponentModule } from './range-actions/range-actions.component.module'
import { RegisterComponentModule } from './register/register.component.module';
import { SolidSurfaceConfigComponentModule } from './solid-surface-config/solid-surface-config.component.module';
import { StickyTabsComponentModule } from './sticky-tabs/sticky-tabs.component.module';

@NgModule({
    imports: [
        DesignModule,
        DialogsModule,
        DirectivesModule,
        PipesModule,

        ArticlesComponentModule,
        BannerComponentModule,
        BasketComponentModule,
        BreadcrumbsComponentModule,
        CreateCustomerComponentModule,
        CustomerLookupComponentModule,
        FiltersComponentModule,
        FooterComponentModule,
        HeaderComponentModule,
        HeaderSearchComponentModule,
        LatestOffersComponentModule,
        LoaderComponentModule,
        LoginComponentModule,
        MegaMenuComponentModule,
        MonekLegacyPaymentComponentModule,
        OrderSummaryComponentModule,
        ProductComponentModule,
        RangeActionsComponentModule,
        RegisterComponentModule,
        SolidSurfaceConfigComponentModule,
        StickyTabsComponentModule
    ],
    exports: [
        ArticlesComponentModule,
        BannerComponentModule,
        BasketComponentModule,
        BreadcrumbsComponentModule,
        CreateCustomerComponentModule,
        CustomerLookupComponentModule,
        FiltersComponentModule,
        FooterComponentModule,
        HeaderComponentModule,
        HeaderSearchComponentModule,
        LatestOffersComponentModule,
        LoaderComponentModule,
        LoginComponentModule,
        MegaMenuComponentModule,
        MonekLegacyPaymentComponentModule,
        OrderSummaryComponentModule,
        ProductComponentModule,
        RangeActionsComponentModule,
        RegisterComponentModule,
        SolidSurfaceConfigComponentModule,
        StickyTabsComponentModule
    ]
})
export class ComponentsModule { }
