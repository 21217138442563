import { NgModule } from '@angular/core';

import { CheckoutService } from './checkout.service';

@NgModule({
    providers: [
        CheckoutService
    ]
})
export class CheckoutServiceModule { }
