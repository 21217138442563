import { NgModule } from '@angular/core';

import { CustomTransferStateService } from './transfer-state.service';

@NgModule({
    providers: [
        CustomTransferStateService
    ]
})
export class TransferStateServiceModule { }
