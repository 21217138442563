import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChange, SimpleChanges } from '@angular/core';
import { MarketingService } from '@app/services/marketing';
import { DialogService } from '@app/services/dialog';
import { IPromotionListItem, Promotion } from '@app/services/marketing/models';
import { CurrencyFormatPipe } from '@app/pipes';
import { Config } from '@app/config';
import { IPromotionSelectionKeys } from '@app/services/marketing/models/mock-promotion-rules';
import { isCabinet, isDoor } from '@app/services/catalogue/models/product.models';
import { CatalogueService } from '@app/services/catalogue';
import { ActiveRange, ProductType } from '@app/services/catalogue/models';
import { Subscription } from 'rxjs';
import { IPromotionButton } from '@app/services/marketing/models/promotion.model';
import { Router } from '@angular/router';

@Directive({
    selector: '[promotion]',
    standalone: false
})
export class PromotionDirective implements OnChanges, OnInit, OnDestroy {
    /**
     * The page slug to uniquely identify the page, e.g. unit-detail
     * This means that a promotion can be limited to which pages it will display
     */
    @Input('promotion') page: string;
    /**
     * The request, e.g. listing-overlay-image, unit-price, etc.
     */
    @Input() request: 'unit-price' | 'cab-price' | 'door-price' | 'product-price' | 'hingecost-price' | 'listing-overlay-image' | 'product-overlay-image' | 'offer-button' | 'warranty-button';
    @Input() brand: string;
    @Input() productCode: string;
    @Input() item: any; // Temporarily any type. To-do: define the types, AllProductsUnion?
    @Input() type: ProductType;
    @Input() qty: number;
    @Input() softClose: boolean; // For units, explore if there's another way!
    /**
     * Range name in sentence case, as it appears in activeRange.range.name object.
     * Used for overriding the activeRange, usually in cases of range listing
     */
    @Input() rangeName: string;
    /**
     * Colour name in sentence case, as it appears in activeRange.rangeColour object.
     * Used for overriding the activeRange colour, usually in cases of colour listing
     */
    @Input() colourName: string;

    private activeRangeSubsciption: Subscription;
    private activeRange: ActiveRange;
    private includeVatSubscription: Subscription;
    private includeVat: boolean;

    /**
     * Stores only the header data of each active promotions
     * Not all scenarios require pulling the full promotion details and so this becomes more efficient.
     * @type IPromotionListItem[]
     */
    private applicablePromotionHeaders: IPromotionListItem[];

    private imageOverlayCSSClass = 'promotion-directive-offer-overlay';
    private appliedPromotions: IPromotionListItem[] = [];

    /**
     * Fine-tuned css for different pages
     */
    private imageCSSMapByPage = {
        'product-listing-column': 'promotion-directive-product-listing-offer-overlay',
        'product-listing': 'promotion-directive-product-listing-offer-overlay',
        'search-component': 'promotion-directive-product-listing-offer-overlay',
        'unit-detail': 'promotion-directive-unit-detail-offer-overlay',
        'appliance-detail': 'promotion-directive-unit-detail-offer-overlay',
        'sinks-and-taps-detail': 'promotion-directive-unit-detail-offer-overlay',
        'door-detail': 'promotion-directive-door-detail-offer-overlay'
    };

    /**
     * Used to efficiently get only the promotion headers, rather than full promotion details for certain requests.
     */
    private requestType: 'image' | 'price' | 'button';
    private changes: SimpleChanges;
    private unitPrice: number;

    private selectionKeys: IPromotionSelectionKeys;

    constructor(
        private el: ElementRef,
        private marketingService: MarketingService,
        private dialogService: DialogService,
        private renderer: Renderer2,
        private currencyFormatPipe: CurrencyFormatPipe,
        private config: Config,
        private catalogueService: CatalogueService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.activeRangeSubsciption = this.catalogueService.activeRange$.subscribe((range) => {
            const firstChange = this.activeRange ? false : true;
            const changes: SimpleChanges = {
                ActiveRange: new SimpleChange(this.activeRange, range, firstChange)
            };
            this.activeRange = range;
            this.changes = changes;
            this.manageChanges(); // ngOnChanges doesn't natively get called for includeVat
        });
        this.includeVatSubscription = this.config.includeVatChanges.subscribe((includeVat) => {
            const firstChange = this.includeVat === undefined ? true : false;
            const changes: SimpleChanges = {
                includeVat: new SimpleChange(this.includeVat, includeVat, firstChange)
            };
            this.includeVat = includeVat;
            this.changes = changes;
            this.manageChanges(); // ngOnChanges doesn't natively get called for includeVat
        });

        this.selectionKeys = {
            page: this.page ?? null,
            range: this.rangeName ?? this.activeRange?.range?.name ?? null,
            colour: this.colourName ?? this.activeRange?.rangeColour ?? null,
            brand: this.brand ?? null,
            productCode: this.productCode ?? null,
            productType: this.type || this.item?._group  || this.item?.group || null
        };

        this.requestType = this.request.includes('price') 
            ? 'price' 
            : this.request.includes('button')
            ? 'button'
            : 'image';           

        this.marketingService.getApplicablePromotionHeaders(this.selectionKeys)
            .then((activePromotions) => {
                if (Array.isArray(activePromotions)) {
                    this.applicablePromotionHeaders = activePromotions;
                    // if (this.brand) {
                        // this.marketingService.filterPromotionHeadersByBrand(activePromotions, this.brand)
                        //     .then((applicableHeadersByBrand) => {
                        //         if (Array.isArray(applicableHeadersByBrand)) {
                        //             this.applicablePromotionHeaders = applicableHeadersByBrand;
                        //             if (this.selectionKeys.productCode && this.selectionKeys.productCode === 'SL100SS') {
                        //                 console.log('Results:', JSON.parse(JSON.stringify(this.applicablePromotionHeaders)));
                        //             }
                        //         }
                        //         this.processTypeRequest();
                        //     })
                        //     .catch((error) => this.dialogService.error(this.constructor.name, error));
                    // } else {
                    //     this.processTypeRequest();
                    // }
                    this.processTypeRequest();
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    ngOnDestroy(): void {
        if (this.includeVatSubscription) {
            this.includeVatSubscription.unsubscribe();
        }
        if (this.activeRangeSubsciption) {
            this.activeRangeSubsciption.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.changes = changes;
        this.manageChanges();
    }

    private manageChanges(): void {
        if (this.changes && typeof this.changes === 'object') {
            // Ignore first changes as these are triggered by other elements loading
            let changed = false;
            const keys = Object.keys(this.changes);
            keys.forEach((key) => {
                if (!this.changes[key].isFirstChange()) {
                    changed = true;
                }
            });

            if (changed) {
                this.selectionKeys = {
                    page: this.page ?? null,
                    range: this.rangeName ?? this.activeRange?.range?.name ?? null,
                    colour: this.colourName ?? this.activeRange?.rangeColour ?? null,
                    brand: this.brand ?? null,
                    productCode: this.productCode ?? null,
                    productType: this.type || this.item?._group  || this.item?.group || null
                };

                const refreshApplicablePromotions =
                    ('ActiveRange' in this.changes && !this.changes['ActiveRange'].isFirstChange()) ||
                    ('colourName' in this.changes && !this.changes['colourName'].isFirstChange()) ||
                    ('rangeName' in this.changes && !this.changes['rangeName'].isFirstChange()) ||
                    ('productCode' in this.changes && !this.changes['productCode'].isFirstChange())
                        ? true
                        : false;

                if (refreshApplicablePromotions) {
                    this.marketingService
                        .getApplicablePromotionHeaders(this.selectionKeys)
                        .then((activePromotions) => {
                            if (Array.isArray(activePromotions)) {
                                this.applicablePromotionHeaders = activePromotions;
                                this.processTypeRequest();
                            }
                        })
                        .catch((error) => this.dialogService.error(this.constructor.name, error));
                } else {
                    this.processTypeRequest();
                }
            }
        }
    }

    /**
     * Selects what to add to or remove from the DOM based on the [request] Input
     */
    private processTypeRequest(): void {
        if (Array.isArray(this.applicablePromotionHeaders)) {
            // Check if any of the promotions changed
            const removalCheck = this.appliedPromotions.some(
                (promotion) => !this.applicablePromotionHeaders.find((promoHeader) => promotion.id === promoHeader.id)
            );

            const additionCheck = this.applicablePromotionHeaders.some(
                (promoHeader) => !this.appliedPromotions.find((promotion) => promotion.id === promoHeader.id)
            );

            if (removalCheck || additionCheck) {
                this.changes['promotions'] = new SimpleChange(this.appliedPromotions, this.applicablePromotionHeaders, false);
            }

            if (this.requestType === 'price') {
                if (this.request === 'unit-price') {
                    this.processUnitPrice();
                } else if (this.request === 'cab-price') {
                    this.processCabinetPrice();
                } else if (this.request === 'door-price') {
                    this.processDoorPrice();
                } else if (this.request === 'product-price') {
                    this.processProductPrice();
                } else if (this.request === 'hingecost-price') {
                    this.processHingeCostPrice();
                }
            } else if (this.requestType === 'image') {
                if (this.request === 'listing-overlay-image') {
                    this.processListingOverlayImage();
                } else if (this.request === 'product-overlay-image') {
                    this.processProductOverlayImage();
                }
            } else if (this.requestType === 'button') {
                if (this.request === 'offer-button') {
                    this.processProductOfferButton();
                } else if (this.request === 'warranty-button') {
                    this.processProductWarrantyButton();
                }
            }
        }
    }

    /**
     * Processes all promotions that have a carcaseDiscount and/or doorDiscount and applies the discount price to the unit as a whole
     */
    private processUnitPrice(): void {
        if (this.item && isCabinet(this.item)) {
            let tag = 'h2';
            if (this.page.includes('listing') || this.page.includes('search')) {
                tag = 'p';
            }
            // Check to see if the ActiveRange or ApplicablePromotions have changed to prompt a full recalculation
            if ('ActiveRange' in this.changes || 'promotions' in this.changes || 'productCode') {
                // Full calculation

                let getFullPromotionPromises: Promise<Promotion>[] = [];
                this.applicablePromotionHeaders.forEach((promotionHeader) => {
                    getFullPromotionPromises.push(this.marketingService.getPromotion(promotionHeader.id));
                });

                Promise.all(getFullPromotionPromises)
                    .then((fullPromotions) => {
                        const carcaseDiscounts = fullPromotions.flatMap((promotion) =>
                            promotion.conditionalFields.filter((field) => field.name === 'carcaseDiscount').map((field) => field.value)
                        );

                        const doorDiscounts = fullPromotions.flatMap((promotion) =>
                            promotion.conditionalFields.filter((field) => field.name === 'doorsDiscount').map((field) => field.value)
                        );

                        if (carcaseDiscounts.length || doorDiscounts.length) {
                            this.unitPrice = this.marketingService.getCabinetSalePrice(
                                this.item,
                                this.activeRange,
                                carcaseDiscounts,
                                doorDiscounts
                            );

                            if (this.item.originalHingecost) {
                                this.item.hingecost = this.item.originalHingecost;
                                this.item.originalHingecost = null;
                            }
                            if (this.item.hingecost) {
                                this.item.originalHingecost = this.item.hingecost;
                                this.item.hingecost = (this.item.hingecost) 
                                        ? this.marketingService.getCabinetHingeCostSalePrice(this.item.hingecost, carcaseDiscounts)
                                        : this.item.hingecost;
                            }
                            this.unitPrice += this.item.softClose && this.item.hingecost ? this.item.hingecost : 0;

                            if (this.unitPrice < (this.item?._cost || this.item?.currentPrice)) {
                                this.addSalePrice(this.unitPrice, tag);
                            } else {
                                this.removeSalePrice();
                            }
                        } else {
                            this.removeSalePrice();
                        }
                        // Record applied promotions
                        this.appliedPromotions = JSON.parse(JSON.stringify(this.applicablePromotionHeaders));
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            } else {
                // Simple recalculation
                if ('softClose' in this.changes && this.unitPrice) {
                    const softCloseChange = this.changes['softClose'];
                    if (softCloseChange.previousValue !== softCloseChange.currentValue && this.item.hingecost) {
                        this.unitPrice += this.softClose ? this.item.hingecost : -this.item.hingecost;
                        this.addSalePrice(this.unitPrice, tag);
                    }
                }
                if ('includeVat' in this.changes && this.unitPrice) {
                    this.addSalePrice(this.unitPrice, tag);
                }
            }
        }
    }

    /**
     * Adds/removes a cabinet only sale price to the element
     */
    private processCabinetPrice(): void {
        if (this.item && isCabinet(this.item)) {
            let getFullPromotionPromises: Promise<Promotion>[] = [];
            this.applicablePromotionHeaders.forEach((promotionHeader) => {
                getFullPromotionPromises.push(this.marketingService.getPromotion(promotionHeader.id));
            });
            Promise.all(getFullPromotionPromises)
                .then((fullPromotions) => {
                    const carcaseDiscounts = fullPromotions.flatMap((promotion) =>
                        promotion.conditionalFields.filter((field) => field.name === 'carcaseDiscount').map((field) => field.value)
                    );

                    const cabOnlyPrice = this.marketingService.getCabinetOnlySalePrice(this.item, carcaseDiscounts);

                    if (cabOnlyPrice < this.item.cab_price) {
                        this.addSalePrice(cabOnlyPrice, 'p');
                    } else {
                        this.removeSalePrice();
                    }
                    // Record applied promotions
                    this.appliedPromotions = JSON.parse(JSON.stringify(this.applicablePromotionHeaders));
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        }
    }

    /**
     * Adds or removes a door only sale price to the element
     */
    private processDoorPrice(): void {
        if (this.item && isDoor(this.item)) {
            let getFullPromotionPromises: Promise<Promotion>[] = [];
            this.applicablePromotionHeaders.forEach((promotionHeader) => {
                getFullPromotionPromises.push(this.marketingService.getPromotion(promotionHeader.id));
            });
            Promise.all(getFullPromotionPromises)
                .then((fullPromotions) => {
                    const doorDiscounts = fullPromotions.flatMap((promotion) =>
                        promotion.conditionalFields.filter((field) => field.name === 'doorsDiscount').map((field) => field.value)
                    );

                    const qty = this.qty ?? 1;
                    const doorPrice = doorDiscounts.length
                        ? this.marketingService.getDoorSalePrice(this.item, doorDiscounts, qty)
                        : this.item._cost * qty;

                    if (doorPrice < this.item._cost * qty) {
                        this.addSalePrice(doorPrice, 'p');
                    } else {
                        this.removeSalePrice();
                    }
                    // Record applied promotions
                    this.appliedPromotions = JSON.parse(JSON.stringify(this.applicablePromotionHeaders));
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        }
    }

    /**
     * Adds or removes a hinge cost sale price to the element.
     */
    private processHingeCostPrice(): void {
        if (this.item && isCabinet(this.item)) {
            let getFullPromotionPromises: Promise<Promotion>[] = [];
            this.applicablePromotionHeaders.forEach((promotionHeader) => {
                getFullPromotionPromises.push(this.marketingService.getPromotion(promotionHeader.id));
            });
            Promise.all(getFullPromotionPromises)
                .then((fullPromotions) => {
                    const carcaseDiscounts = fullPromotions.flatMap((promotion) =>
                        promotion.conditionalFields.filter((field) => field.name === 'carcaseDiscount').map((field) => field.value)
                    );

                    if (this.item.originalHingecost) {
                        this.item.hingecost = this.item.originalHingecost;
                        this.item.originalHingecost = null;
                    }
                    if (this.item.hingecost) {
                        this.item.originalHingecost = this.item.hingecost;
                        this.item.hingecost = (this.item.hingecost) 
                                ? this.marketingService.getCabinetHingeCostSalePrice(this.item.hingecost, carcaseDiscounts)
                                : this.item.hingecost;
                    }

                    if (this.item.originalHingecost && this.item.hingecost) {
                        if (this.item.hingecost < this.item.originalHingecost) {
                            this.addSalePrice(this.item.hingecost, 'p');
                        } else {
                            this.removeSalePrice();
                        }
                    }
                    
                    // Record applied promotions
                    this.appliedPromotions = JSON.parse(JSON.stringify(this.applicablePromotionHeaders));
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        }        
    }

    private processProductPrice(): void {
        if (!this.item || typeof this.item !== 'object') {
            return;
        }

        const discountFields = ['brandDiscount', 'productDiscount'];
        const cost = this.item['price'] || this.item['cost'] || this.item['_cost'] || 0;
        const productDiscounts = this.applicablePromotionHeaders.flatMap((promotion) =>
            promotion.conditionalFields.filter((field) => discountFields.includes(field.name)).map((field) => field.value)
        );
        const salePrice = this.marketingService.getProductSalePrice(this.item, productDiscounts);
        if (salePrice < cost) {
            this.addSalePrice(salePrice, 'p');
        } else {
            this.removeSalePrice();
        }
        // Record applied promotions
        this.appliedPromotions = JSON.parse(JSON.stringify(this.applicablePromotionHeaders));
    }

    private processProductOfferButton(): void {
        this.getPromotionButtons()
            .then((buttons) => {
                if (Array.isArray(buttons)) {
                    buttons.forEach((button) => this.addButton(button));
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    private processProductWarrantyButton(): void {
        this.getPromotionButtons()
            .then((buttons) => {
                if (Array.isArray(buttons)) {
                    const warrantyButtons = buttons.filter((button) => button.title.match(/warranty/i));
                    warrantyButtons.forEach((button) => this.addButton(button));
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    private getPromotionButtons(): Promise<IPromotionButton[] | void> {
        return new Promise((resolve, reject) => {
            if (this.applicablePromotionHeaders.length) {
                const fullPromotionPromises: Promise<Promotion>[] = this.applicablePromotionHeaders.map((header) => this.marketingService.getPromotion(header.id));
                Promise.all(fullPromotionPromises)
                    .then((fullPromotions) => {
                        if (Array.isArray(fullPromotions)) {
                            const buttons: IPromotionButton[] = fullPromotions.map((promotion) => {
                                return {
                                    route: '/kitchen-sales',
                                    fragment: this.marketingService.getPromotionUrlFragment(promotion),
                                    colour: promotion.saleCard.backgroundColour,
                                    title: promotion.terms.title
                                };
                            });
                            resolve(buttons);
                        }
                        resolve();
                    })
                    .catch((error) => reject(error));
            } else {
                resolve();
            }
        });
    }

    /**
     * Utility function to add/remove an image to the DOM. It will select the first match.
     * First checks that the element is a DIV tag
     */
    private processListingOverlayImage(): void {
        const promotion = this.applicablePromotionHeaders.find((promotion) => promotion.listingOverlayImageURL);
        if (promotion && this.el.nativeElement.tagName === 'DIV') {
            this.addImage(promotion.listingOverlayImageURL);
        } else {
            this.removeImages();
        }
        // Record applied promotions
        this.appliedPromotions = JSON.parse(JSON.stringify(this.applicablePromotionHeaders));
    }

    /**
     * Utility function to add an image to the DOM. It will select the first match.
     * First checks that the element is a DIV tag
     */
    private processProductOverlayImage(): void {
        const promotion = this.applicablePromotionHeaders.find((promotion) => promotion.productOverlayImageURL);
        if (promotion && this.el.nativeElement.tagName === 'DIV') {
            this.addImage(promotion.productOverlayImageURL);
        } else {
            this.removeImages();
        }
        // Record applied promotions
        this.appliedPromotions = JSON.parse(JSON.stringify(this.applicablePromotionHeaders));
    }

    private addButton(buttonDetail: IPromotionButton): void {
        const cssClass = 'promotion-directive-offer-button';
        const id = `${cssClass}-${buttonDetail.fragment}`;

        // Check to see the button isn't already there!
        const insertedButton = this.el.nativeElement.querySelector(`#${id}`);
        if (!insertedButton) {
            const button = this.renderer.createElement('a');
            const text = this.renderer.createElement('p');
            const icon = this.renderer.createElement('mat-icon');
            
            this.renderer.setAttribute(button, 'id', id);
            this.renderer.setAttribute(button, 'href', `${buttonDetail.route}#${buttonDetail.fragment}`);

            this.renderer.addClass(button, cssClass);
            this.renderer.setStyle(text, 'backgroundColor', buttonDetail.colour);
            
            const matIconClasses = ['mat-icon', 'notranslate', 'material-icons', 'mat-ligature-font', 'mat-icon-no-color'];
            matIconClasses.forEach((iconClass) => this.renderer.addClass(icon, iconClass));
            this.renderer.setAttribute(icon, 'role', 'img');
            this.renderer.setAttribute(icon, 'aria-hidden', 'true');
            this.renderer.setAttribute(icon, 'data-mat-icon-type', 'font');
            icon.innerHTML = 'arrow_forward';

            this.renderer.listen(button, 'click', () => {
                this.router.navigate([buttonDetail.route], { fragment: buttonDetail.fragment });
            });

            this.renderer.appendChild(text, icon);
            this.renderer.appendChild(button, text);
            this.renderer.appendChild(this.el.nativeElement, button);

            text.innerHTML = this.removeBrandNamesFromString(buttonDetail.title) + text.innerHTML;
        }
    }

    private removeBrandNamesFromString(str: string): string {
        const brandPattern = 'AEG|CDA|Zanussi';
        const regex = new RegExp(`\\b(${brandPattern})\\b`, 'g');
        return str.replace(regex, ' ');
    }

    /**
     * Utility method to add an image to the element on the DOM
     * @param imageURL
     */
    private addImage(imageURL: string): void {
        // Apply page specific css class, or default if none specified
        let cssClass = this.imageCSSMapByPage[this.page];
        cssClass = cssClass ? cssClass : this.imageOverlayCSSClass;
        imageURL = `${this.config.api.endpoints.cdn}/${imageURL}`;
        // Check to see the image isn't already there!
        const insertedImage = this.el.nativeElement.querySelector(`.${cssClass}`);
        if (!insertedImage || insertedImage.src !== imageURL) {
            const img = this.renderer.createElement('img');
            this.renderer.setAttribute(img, 'src', imageURL);
            this.renderer.addClass(img, cssClass);
            this.renderer.appendChild(this.el.nativeElement, img);
        }
    }

    /**
     * Utility method to remove all images added to the element from the DOM
     */
    private removeImages(): void {
        const selector = 'img[class^="promotion-directive-"], img[class*=" promotion-directive-"]';
        const insertedImages = this.el.nativeElement.querySelectorAll(selector);
        insertedImages.forEach((image) => {
            this.renderer.removeChild(this.el.nativeElement, image);
        });
    }

    /**
     * Utility method to insert the sale price onto the DOM. It first removes any instances of sale-price and marks any currency elements with strike through
     * @param salePrice The price to insert into the DOM
     * @param tag Target element tag, e.g. 'h2' or 'p'
     * @param cssClass A specific class to add to the price, defaults to the sale-price class
     */
    private addSalePrice(salePrice: number | string, tag: string, cssClass: string = 'promotion-directive-sale-price'): void {
        this.removeSalePrice();
        // Add strike-through to existing price
        const childElements = this.el.nativeElement.children;
        if (childElements) {
            const childKeys = Object.keys(childElements);
            childKeys.forEach((key) => {
                let childEl = childElements[key];
                if (childElements[key].children && childElements[key].querySelectorAll('.currency-format')) {
                    this.renderer.addClass(childEl, 'promotion-directive-strike-through');
                }
            });
        }

        salePrice = this.currencyFormatPipe.transform(salePrice, true, true);
        const salePriceTag = this.renderer.createElement(tag);
        this.renderer.addClass(salePriceTag, cssClass);
        this.renderer.appendChild(this.el.nativeElement, salePriceTag);
        this.el.nativeElement.lastChild.innerHTML = salePrice as string;
    }

    /**
     * Utility method to remove instances of sale-price from the element
     */
    private removeSalePrice(): void {
        const insertedSalePriceTags = this.el.nativeElement.querySelectorAll('.promotion-directive-sale-price');

        insertedSalePriceTags.forEach((salePriceTag) => {
            this.renderer.removeChild(this.el.nativeElement, salePriceTag);
        });

        const childElements = this.el.nativeElement.children;
        if (childElements) {
            const childKeys = Object.keys(childElements);
            childKeys.forEach((key) => {
                let childEl = childElements[key];
                if (childElements[key].children && childElements[key].querySelectorAll('.currency-format')) {
                    this.renderer.removeClass(childEl, 'promotion-directive-strike-through');
                }
            });
        }
    }
}
