@import '/src/theme/variables';

page-edge-profiles {

    .edge-profile-heading-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        max-width: 820px;
        margin: 40px auto;

        @include responsive-breakpoint-down(md) {
            margin: 24px auto;
        }

        h2 {
            font-family: 'Open Sans';
            font-size: clamp(1.777rem, 1vw + 1.777rem, 1.777rem);
            font-weight: 900;
            line-height: clamp(1.777rem, 1vw + 1.777rem, 1.777rem);
            margin-bottom: 30px;
            color: rgba(0, 18, 36, 0.9);
            text-transform: uppercase;

            @include responsive-breakpoint-between(lg, xl) {
                font-size: 1.625rem;
            }

            @include responsive-breakpoint-between(md, lg) {
                font-size: 1.625rem;
            }

            @include responsive-breakpoint-down(md) {
                font-size: 1.3rem;
                margin-bottom: 10px;
            }
        }

        p {
            font-family: 'Open Sans';
            font-size: 18px;
            color: #878787;
            font-weight: 300;
            line-height: clamp(1.618rem, 1vw + 1.618rem, 1.618rem);

            @include responsive-breakpoint-down(md) {
                font-size: 16px;
            }
        }
    }

    .choose-edge-profile-row {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1.875rem;
        width: 100%;
        max-width: 940px;
        margin: 0 auto;

        @include responsive-breakpoint-down(md) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        .choose-edge-profile-column {
            .edge-profile-img {
                width: 100%;
                height: auto;

                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    object-position: center center;
                }
            }

            .edge-profile-block-content {
                padding: 40px 0;
                border-bottom: 1px solid #CCC;

                @include responsive-breakpoint-down(md) {
                    padding: 24px 0;
                }

                h3 {
                    font-family: 'Open Sans';
                    font-size: clamp(1.777rem, 1vw + 1.777rem, 2.369rem);
                    font-weight: 600;
                    letter-spacing: -0.1rem;
                    line-height: clamp(1.777rem, 1vw + 1.777rem, 2.369rem);
                    margin-bottom: 1.333rem;
                    margin-top: 0;
                    color: rgba(0, 18, 36, 0.9);
                    text-transform: uppercase;

                    @include responsive-breakpoint-between(lg, xl) {
                        font-size: 2rem;
                    }

                    @include responsive-breakpoint-between(md, lg) {
                        font-size: 1.8rem;
                    }

                    @include responsive-breakpoint-down(md) {
                        font-size: 1.5rem;
                    }
                }

                p {
                    font-family: 'Open Sans';
                    font-size: clamp(1rem, 1vw + 1rem, 1rem);
                    font-weight: 300;
                    line-height: clamp(1.618rem, 1vw + 1.618rem, 1.618rem);
                    margin-bottom: 0;
                    color: rgba(0, 18, 36, 0.9);
                }

                .price {
                    margin-bottom: 24px;
                    font-family: 'Open Sans';
                    font-weight: 400;
                    font-size: 14px;
                    color: rgba(255, 16, 83, 1);
                    text-align: left;
                    letter-spacing: 0.04em;
                    line-height: 14px;

                    strong {
                        font-weight: bold;
                    }
                }

                ul {
                    list-style-type: none;
                    margin: 20px 0 0 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    li {
                        p {
                            position: relative;
                            display: flex;
                            align-items: center;

                            &::before {
                                content: '';
                                font-size: 26px;
                                font-weight: 500;
                                vertical-align: middle;
                                margin-top: -4px;
                                margin-right: 25px;
                            }
                        }
                    }

                    &.pros-list {
                        li {
                            p {
                                &::before {
                                    content: '\002B';
                                }
                            }
                        }
                    }

                    &.cons-list {
                        li {
                            p {
                                &::before {
                                    content: '\2212';
                                }
                            }
                        }
                    }
                }
            }

            .edge-profile-action-block {
                padding: 40px 0;

                @include responsive-breakpoint-down(md) {
                    padding: 24px 0;
                }
            }
        }
    }

    .btn-blue {
        font-size: 12px;
        min-height: 50px;
        font-weight: 600;
        letter-spacing: 0.1429em;
        background: color('brand-primary');
        padding: 1rem 1.5rem;
        color: color('white');
        border: 1px solid color('brand-primary');
        text-align: center;
        border-radius: .25rem;
        text-transform: uppercase;
        display: block;
        width: 100%;
        margin-bottom: 15px;

        &:not(:disabled) {
            color: color('white');
        }

        &:hover {
            background: #00a9f1;
            border-color: #00a9f1;
        }
    }

    .btn-hollow {
        font-size: 12px;
        min-height: 50px;
        font-weight: 600;
        letter-spacing: 0.1429em;
        background: transparent;
        padding: 1rem 1.5rem;
        color: #343a40;
        border: 1px solid #343a40;
        text-align: center;
        border-radius: .25rem;
        text-transform: uppercase;
        display: block;
        width: 100%;
        margin-bottom: 15px;

        &:not(:disabled) {
            border-color: #343a40;
        }

        &:hover {
            background: #343a40;
            color: color('white');
        }
    }
}