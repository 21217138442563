import { Injectable } from '@angular/core';

import { Config } from '@app/config';

import { HttpApi } from '@app/services/api';
import { HttpApiOptions, HttpApiResponse } from '@app/services/api/models';
import { AuthCustomerService } from '@app/services/auth';
import { DialogService } from '@app/services/dialog';

@Injectable()
export class CustomerService {
    constructor(
        private config: Config,
        private httpApi: HttpApi,
        private authCustomerService: AuthCustomerService,
        private dialogService: DialogService
    ) { }

    public details(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.universal}/customer`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken
                }
            };

            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public update(firstName: string, lastName: string, landline: string, tel: string) {
        return new Promise<any>((resolve, reject) => {
            // const url = `${this.config.api.endpoints.universal}/customer`;
            // const options: HttpApiOptions = {
            //     authorization: {
            //         UserToken: this.authCustomerService.applicationToken
            //     }
            // };
            // const params = {
            //     firstName,
            //     lastName,
            //     landline,
            //     tel
            // };

            // TODO: this should update the customer details on the order?
            resolve(null);
            // this.httpApi.put<HttpApiResponse<any>>(url, params, options).subscribe({
            //     next: (response) => {
            //         if (response && response.success) {
            //             resolve(response.body);
            //         } else {
            //             reject(response);
            //         }
            //     },
            //     error: (error) => this.dialogService.error(this.constructor.name, error)
            // });
        });
    }

    public customerHasPreviousOrders(): Promise<number> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/diy/customer/details/has-previous-orders`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken
                }
            };

            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public addresses(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.universal}/customer/address`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken
                }
            };

            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public saveAddress(address: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.universal}/customer/address`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken
                }
            };

            this.httpApi.post<HttpApiResponse<any>>(url, { address }, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public customerSearch(email: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.universal}/customer/${email}/search`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken
                }
            };

            this.httpApi.get<any>(url, options).subscribe({
                next: (response) => {
                    if (response && response.success) {
                        resolve((response.body || []).map((customer) => {
                            return {
                                id: customer.id,
                                firstName: customer.first_name,
                                lastName: customer.last_name,
                                email: customer.email,
                                createDate: customer.create_date,
                                lastLogin: customer.last_login
                            };
                        }));
                    } else {
                        resolve([]);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }
}
