@import '/src/theme/variables';

component-dimensions-input-calculator-dialog {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h5 {
            margin: 0;
        }

        .close {
            margin-top: 10px;
            margin-right: 10px;
            cursor: pointer;
        }
    }

    table {
        margin-bottom: 25px;
        width: 100%;

        tr {
            height: 80px;

            td {
                &:first-child {
                    padding-right: 15px;
                }
            }
        }
    }

    .price-container {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        margin-bottom: 20px;

        button {
            max-width: 50%;
        }

        .price {
            strong {
                font-size: 1.6rem;
                font-weight: 600;
            }
        }
    }

    mat-dialog-actions {
        display: unset !important;

        .actions {
            display: flex;
            padding: var(--mat-dialog-content-padding, 20px 24px);
    
            component-basket-update-button {
                width: 100%;
            }
        }
    }

}
