
<mat-dialog-content class="change-style-dialog-content">
    <div class="header">
		<h5>{{item.rank}} {{item.code}}</h5>
		<div class="close" (click)="close()">
			<mat-icon>close</mat-icon>
		</div>
	</div>

    <p>{{item.description}} - {{item.width}}mm Width {{item.height}}mm Height {{item.depth}}mm Depth</p>

    <form [formGroup]="changeOptionsForm">
        <table>
            <tr>
                <td>Carcase Colour:</td>
                <td>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="carcaseColour" required>
                            <mat-option *ngFor="let carcaseColour of carcaseColours" [value]="carcaseColour.colour">{{carcaseColour.colour}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </tr>
            <tr *ngIf="isHanded">
                <td>{{hingeWording(item)}}:</td>
                <td>
                    <mat-radio-group formControlName="handing" required>
                        <mat-radio-button value="RH">Right</mat-radio-button>
                        <mat-radio-button value="LH">Left</mat-radio-button>
                    </mat-radio-group>
                </td>
            </tr>
            <tr *ngIf="item.group === ProductType.CABINETS && needSoftClose">
                <td>Soft Close:</td>
                <td>
                    <mat-radio-group formControlName="softClose" required>
                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                        <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                </td>
            </tr>
            <tr *ngIf="item.group === ProductType.CABINETS && item.width > 600 && item.depth > 600 && !item.dryAssembledAlways">
                <td>Dry Assmbled:</td>
                <td>
                    <mat-radio-group formControlName="isDryAssembled" required>
                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                        <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                </td>
            </tr>
        </table>

        <div class="modal-action">
            <button mat-flat-button color="primary" class="chunky" (click)="updateItem()">Update</button>
        </div>
    </form>
</mat-dialog-content>