<header class="content-header-corner content-header-background" [ngClass]="headerCssClass">
    <header class="content-contained">
        <h1 *ngIf="!subCategory">{{categoryDisplay}}</h1>
        <h1 *ngIf="subCategory">{{subCategoryDisplay}}</h1>
        <ng-container *ngIf="category === 'cooking' && !subCategory">
            <p>The cooking category is a culinary haven, offering everything from single and double ovens for perfect meals, to versatile hobs for any dish. Keep your kitchen fresh with stylish extractors and add convenience with integrated microwaves. Need a bit more capacity? Compact ovens have got you covered. For timely meals or budding bakers, our warming drawers are a must. Explore a blend of top brands and functions that elevate your cooking experience!</p>
        </ng-container>
        <ng-container *ngIf="category === 'cooking' && subCategory === 'single-ovens'">
            <p>A built-in single oven is a delight for any new kitchen. The oven fits perfectly into one of our integrated oven housing units, whether it be under your worktop or more conveniently positioned inside a tall oven unit. We have many top named brands to choose from, in a choice of stainless steel or black finishes. So, why not make cooking a joy and invest in a single oven for your new kitchen today!</p>
        </ng-container>
        <ng-container *ngIf="category === 'cooking' && subCategory === 'double-ovens'">
            <p>A double oven is a great upgrade and addition to any kitchen. With its additional cooking capacity, family mealtimes could involve a few additional friends (proving there are no more than 6 of you in the house), to enjoy your cooking and company. We have many top name brands to choose from, such as AEG. You can also choose to have a built-in double oven, to go inside a tall oven unit or a built under double oven, that fits perfectly under your worktop. The choice is yours!</p>
        </ng-container>
        <ng-container *ngIf="category === 'cooking' && subCategory === 'hobs'">
            <p>A hob is an essential item in every kitchen and there are a few considerations that you need to make before choosing one. As well as the good old fashion gas & electric hobs, induction hobs are also increasingly popular and use less energy too. We have many branded hobs to choose from in a choice of stainless steel and black. If you need a little extra cooking capacity, then you can opt for one of the 5 ringer burners instead.</p>
        </ng-container>
        <ng-container *ngIf="category === 'cooking' && subCategory === 'extractors'">
            <p>Extraction in your kitchen is very important, to remove excess steam & odours and to eliminate condensation. Now, choosing an extractor could take a little more time, as there loads of styles to choose from, with the most popular being the chimney extractor. When planning your kitchen, it is very important to consider the extraction method, as some recirculate the air back into in the room and some require an outside wall, to vent the steam outside.</p>
        </ng-container>
        <ng-container *ngIf="category === 'cooking' && subCategory === 'compact-ovens'">
            <p>A compact oven is an excellent addition to your kitchen, if you find that your single oven, just isn't quite big enough for Sunday lunches. A compact oven can be situated anywhere in your kitchen, ideally in a tall appliance unit, such as our compact housing units here. We have many top name brands to choose from and our compact ovens are available in a stainless steel finish. So, if you need a little more space when cooking, then a compact oven is a must, and some can even steam your food!</p>
        </ng-container>
        <ng-container *ngIf="category === 'cooking' && subCategory === 'microwaves'">
            <p>A microwave oven is an appliance that many of us could not live without! What's great about our built-in microwave ovens is that they no longer have to sit on your worktop, as you can place them in any of our kitchen units, even wall units! As built-in microwaves can vary in height, each of our integrated microwave units are supplied with a filler panel, for you to cover any remaining gap in the unit, for a fully integrated look. So, free up some valuable worktop space today, with a built-in microwave, you won't regret it!</p>
        </ng-container>
        <ng-container *ngIf="category === 'cooking' && subCategory === 'warming-drawers'">
            <p>If you often find yourself doing the cooking but there is nobody around to eat it, then a built-in warming drawer is an ideal appliance for your kitchen! The warming drawer will keep your food and plates warm for as long as you need it to and without drying the food out. If you're an avid baker then the warming drawer is also ideal for letting dough rise too. The warming drawers integrate perfectly under an oven and we have many units available to help you plan this appliance into your kitchen</p>
        </ng-container>
        <ng-container *ngIf="category === 'refrigeration'">
            <p>Whether you choose a freestanding, integrated or American fridge freezer in your kitchen, refrigeration is a must but there are many different appliances to help you do this. If you are wanting to hide your fridge away then you are going to need to buy a built in/integrated fridge and these are a lot different from freestanding appliances, as the kitchen unit doors are attached to the appliance.</p>
        </ng-container>
        <ng-container *ngIf="category === 'refrigeration' && subCategory === 'fridges'">

        </ng-container>
        <ng-container *ngIf="category === 'refrigeration' && subCategory === 'freezers'">

        </ng-container>
        <ng-container *ngIf="category === 'refrigeration' && subCategory === '50-50-fridge-freezers'">

        </ng-container>
        <ng-container *ngIf="category === 'refrigeration' && subCategory === '70-30-fridge-freezers'">

        </ng-container>
        <ng-container *ngIf="category === 'refrigeration' && subCategory === 'fridge-freezers'">

        </ng-container>
        <ng-container *ngIf="category === 'refrigeration' && subCategory === 'wine-coolers'">

        </ng-container>
        <ng-container *ngIf="category === 'dishwashers'">
            <p>Without a doubt, a dishwasher saves you time and allows you to get all your daily dishes & cutlery, washed in one go. Not only is washing made easier but any glassware that has seen better days, comes out gleaming like new, perfect for those dinner parties! Our choice of built-in slimline (450mm wide) or standard width (600mm) dishwashers, give you the option of fitting a dishwasher into space in your kitchen run and fully integrating it. The only additional piece you need, is an appliance door to attach to it.</p>
        </ng-container>
        <ng-container *ngIf="category === 'laundry' && !subCategory">
            <p>Whether you're placing your washer or dryer in your kitchen or utility room, the main consideration to make is choosing between a built-in or freestanding appliance. Built-in washers & dryers integrate seamlessly into your kitchen, offering a sleek & uniform look. Freestanding washers & dryers on the other hand, provide extra capacity and flexibility, though they are typically deeper than a standard 600mm deep worktop. Make the smart choice for your home today!</p>
        </ng-container>
        <ng-container *ngIf="category === 'laundry' && subCategory === 'washing-machines'">
            <p>Whether you are putting your washing machine in your kitchen or utility room, the main consideration you need to make is which type of appliance you are going to buy, built-in or freestanding. Built-in washing machines integrate seamlessly into your kitchen but can be a little smaller, whereas freestanding washers, can give you little extra capacity but they are usually deeper than a standard 600mm deep worktop.</p>
        </ng-container>
        <ng-container *ngIf="category === 'laundry' && subCategory === 'washer-dryers'">
            <p>Why buy 2 appliances when you can have one! Whether you are putting your washer dryer in your kitchen or utility room, the main consideration you need to make is which type of appliance you are going to buy, built-in or freestanding. Built-in washer dryers integrate seamlessly into your kitchen but can be a little smaller, whereas freestanding washer dryers, can give you little extra capacity but they are usually deeper than a standard 600mm deep worktop.</p>
        </ng-container>
        <ng-container *ngIf="category === 'laundry' && subCategory === 'tumble-dryer'">
            <p>A tumble dryer is a great appliance to have, especially in Winter when drying clothes is made a lot harder. So, rather than covering all your radiators in garments and quickly hiding them before visitors pop round, hide a tumble dryer in your kitchen or utility room instead. Not only will you get your clothes dried quicker, but you’ll also get really soft towels at the same time!</p>
        </ng-container>
    </header>
</header>
<section class="lightgrey">
    <div class="contained flex-space-between">
        <div>
            <component-filters [items]="items" [filters]="filterFields" [sectionCats]="sectionCats" [section]="category"
                [sectionURL]="'appliances'" (filterChange)="updateItems($event)" sortOrder="low-high"></component-filters>
        </div>
        <div class="products" *ngIf="items">
            <div class="small-banners">
                @for (banner of promotionBanners; track banner?.bannerId) {
                    <a class="small-banner" routerLink="/kitchen-sales" [fragment]="getPromotionUrlFragment(banner)" [title]="banner.name">
                        <img [src]="'https://static.diy-kitchens.com/' + banner.imageUrl" [alt]="banner.name" />
                    </a>
                }
            </div>

            <component-product-listing *ngFor="let entry of filteredItems"
                [type]="type" [route]="route" [products]="products" [product]="entry">
            </component-product-listing>

            <div *ngIf="noResults" class="no-results">
                <em class="common-em">No items were found that match your filters...</em>
                Try removing a filter.
            </div>
        </div>
    </div>
</section>
