@import '/src/theme/variables';

page-carcase-sample {
    .left {
        display: flex;
        flex-direction: column;
        gap: 20px;
        min-width: 250px;
        max-width: 30%;
        padding-top: 20px;

        .tip {
            padding: 12px 20px;
            background-color: #e2e3e5;
            color: #383d41;
            border-radius: 5px;
        }
    }

    .products {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 15px;
        padding-top: 0;

        .carcase {
            width: 200px;
            display: flex;
            flex: 0 0 auto;
            flex-direction: column;
            margin: 15px 0 0 10px;
            box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
            background-color: color('white');
            padding: 15px;
            text-align: center;

            .image {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;

                .swatch {
                    width: 150px;
                    height: 150px;
                }
            }

            .title {
                margin-bottom: 5px;
                font-size: 16px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 0.03em;
            }

            .actions {
                width: fit-content;
                margin: 15px auto 0 auto;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .products {
            margin-left: 0;
            max-width: 100%;
        }
        .left {
            display: none;
        }
    }
}