@import '/src/theme/variables';

page-placeholder {
    background-color: color('white');

    .loader {
        position: relative;
        margin-top: 100px;
        margin-bottom: 100px;
        background-color: color('white');
        z-index: 1;
    }
}
