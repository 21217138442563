@import '/src/theme/variables';

component-banner {
    .banner-container-header {
        width: 100%;
        max-height: 150px;
        text-align: center;
    
        img {
            height: 50px;

            @include responsive-breakpoint-down(xl){
                height: auto;
                max-width: 100%;
                max-height: 100px;
            }
        }
    }

    .editing-order {
        padding: 10px;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: 600;
        background-color: #EF5350;
        color: color('white');
        cursor: pointer;

        .cut-off-timer {
            padding: 10px;
            font-size: 14px;
        }

        @include responsive-breakpoint-down(xl){
            .cut-off-timer {
                padding: 5px;
                font-size: 12px;

                .cut-off-message {
                    display: none;
                }
            }

            padding: 10px;
            font-size: 12px;
        }
    }
}
