@import '/src/theme/variables';

page-basket-recovery {
    .shopper-id {
        width: 100%;
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 25px;
        width: 100%;

        .mdc-button {
            width: 100%;

            &:first-of-type {
                margin-right: 15px;
            }

            &:last-of-type {
                margin-left: 15px;
            }
        }
    }
}
