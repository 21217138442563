import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { WorktopsRouting } from './worktops.routing';
import { WorktopsPage } from './worktops.page';
import { WorktopConfigPage } from './config/config.page';

@NgModule({
    imports: [
        SharedModule,
        WorktopsRouting
    ],
    declarations: [
        WorktopsPage,
        WorktopConfigPage
    ]
})
export class WorktopsPageModule { }
