import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';

import { ComparisonDetailsDialogComponent } from './details.dialog.component';

@NgModule({
    imports: [
        DesignModule,
        PipesModule
    ],
    declarations: [
        ComparisonDetailsDialogComponent
    ]
})
export class ComparisonDetailsDialogModule { }
