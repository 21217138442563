@import '/src/theme/variables';

component-footer {
    .cookie-consent {
        position: fixed;
        bottom: 0;
        padding: 10px 20px;
        background-color: color('brand-primary');
        width: calc(100% - 40px);
        color: color('white');
        z-index: 1000;

        .cookie-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 auto;
            max-width: 1024px;

            p {
                margin: 0;
            }

            .mdc-button {
                border-color: color('white');
                color: color('white');
            }
        }
    }

    .brands {
        border-top: 1px solid #CCC;
        border-bottom: 1px solid #888;
        background: #FFF;

        .logos {
            display: flex;
            justify-content: space-between;
            margin: 0.5rem auto;
            padding: 0 1rem;
            max-width: 100%;
            text-align: center;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 20px;
                border-radius: 0.25rem;
                cursor: pointer;

                img {
                    max-width: 100%;
                    max-height: 16px;
                }

                &:hover {
                    img {
                        filter: brightness(0) invert(1);
                    }
                }

                &.blum {
                    &:hover {
                        background-color: rgba(236, 102, 7, 1);
                    }
                }

                &.kessebohmer {
                    &:hover {
                        background-color: rgba(0, 161, 119, 1);
                    }
                }

                &.aeg {
                    &:hover {
                        background-color: rgba(207, 20, 48, 1);
                    }
                }

                &.zanussi {
                    &:hover {
                        background-color: rgba(0, 0, 0, 1);
                    }
                }

                &.dekton {
                    &:hover {
                        background-color: rgba(59, 110, 143, 1);
                    }
                }

                &.cda {
                    &:hover {
                        background-color: rgba(168, 11, 13, 1);
                    }
                }

                &.innova {
                    &:hover {
                        background-color: rgba(64, 68, 78, 1);
                    }
                }

                &.beko {
                    &:hover {
                        background-color: rgba(0, 131, 190, 1);
                    }
                }

                &.silestone {
                    &:hover {
                        background-color: rgba(0, 0, 0, 1);
                    }
                }

                &.duropal {
                    &:hover {
                        background-color: rgba(244, 158, 0, 1);
                    }
                }
            }
        }

        @media (max-width: 1200px) {
            .logos {
                img {
                    margin: 15px 25px;
                }
            }
        }

        @media (max-width: 1023px) {
            display: none;
        }
    }

    .footer-container {
        z-index: 9;
        position: absolute;
        width: 100%;
        margin-bottom: 0;
        background-color: #001224;
        background-image: url(https://static.diy-kitchens.com/assets/images/footer/footer-bg.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        color: color('white');

        .links-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            margin: 0 auto;
            padding: 40px 0;
            border-bottom: 2px solid color('white', 0.3);
            max-width: 1300px;
            line-height: 2rem;

            .links {
                padding: 10px;
                width: 20%;

                strong {
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-bottom: 1rem;
                    display: block;
                    color: color('white');
                }

                a {
                    display: block;
                    color: rgba(255, 255, 255, 0.8);
                    font-family: font('opensans');
                    cursor: pointer;
                    margin-bottom: 0.5rem;

                    strong {
                        &:hover {
                            color: color('white');
                            text-decoration: underline;
                        }
                    }

                    &:hover {
                        color: color('white');
                    }
                }
            }
        }

        .overview {
            display: flex;
            justify-content: space-evenly;
            margin: auto;
            padding: 25px 15px;
            max-width: 1300px;

            .point {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 15px;
                width: 100%;
                min-width: 120px;
                text-align: center;

                .achievement-logo {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 15px;
                    width: 100%;
                    height: 75px;

                    img {
                        width: 100%;
                        max-width: 200px;
                        max-height: 100%;
                    }
                }

                p {
                    margin: auto;
                    max-width: 75%;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 1rem;
                    color: color('white');
                    letter-spacing: 0.04rem;
                }
            }
        }

        @media (max-width: 768px) {
            .links-container {
                text-align: center;

                .links {
                    width: 50%;
                }
            }

            .overview {
                flex-wrap: wrap;
            }
        }

        @media (max-width: 480px) {
            .links-container {
                .links {
                    width: 100%;

                    h5 {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }

    .copyright-container {
        max-width: 1300px;
        margin: 15px auto;
        padding-top: 16px;
        justify-content: space-between;
        border-top: 2px solid color('white', 0.3);

        .copyright {
            padding: 20px;
            font-size: 12px;
            line-height: 21px;
        }

        .small {
            font-family: font('opensans');
            font-size: clamp(0.75rem, 1vw + 0.75rem, 0.75rem);
            font-style: normal;
            color: rgba(255, 255, 255, 0.4);
            font-weight: 300;
            letter-spacing: 0rem;
            line-height: clamp(1.333rem, 1vw + 1.333rem, 1.333rem);
            margin-bottom: 1.333rem;
        }

        .flexible-friends {
            flex: 0 0 auto;
            background-image: url(https://static.diy-kitchens.com/assets/images/homepage/payment-cards.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 288px;
            height: 40px;
            margin: 20px;
        }
    }
}