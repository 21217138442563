@import '/src/theme/variables';

page-samples {
    .content-header-worktops {
        margin-top: 56px;
    }

    .samples {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 30px;

        .sample {
            display: flex;
            flex-direction: column;
            text-align: center;

            h3 {
                margin: 20px 0;
                color: color('opaque-black');
                font-size: 1.75rem;
                font-family: font('playfair');
                font-weight: 700;
                line-height: 1.75rem;
            }

            .sample-content {
                flex-grow: 1;
            }

            .mdc-button {
                margin: 1.333rem 0;
                min-height: 50px;
                font-weight: 600;
            }
        }
    }

    @media (max-width: 992px) {
        .content-contained {
            max-width: 720px;
        }
    }

    @media (max-width: 480px) {
        .samples {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}