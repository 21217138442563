import { DateHelper } from '@app/utilities/helpers';

import { BasketItem, DrawerBoxUpgrade, IBasketAddresses, PanDrawerUpgrade } from './';
import { DeliveryType } from '@app/services/checkout/models';

export class Basket {
    // DB Values
    public id?: number;
    public uuid?: string;
    public name: string;
    public orderId?: number;
    public lastPaymentId?: number;
    public orderNumber?: string;
    public createdAt: string;
    public orderPlaced?: string;
    public cutOffDate?: string;
    public weekCommencing?: string;
    public deliveryDate?: string;
    public customerId: number;
    public basketStatusId: number;
    public modifiedAt: string;
    public cost: number;
    public deliveryLevel?: DeliveryType;
    public deliveryCost?: number;
    public deliverySurcharge?: number;
    public acceptedTerms: boolean;
    public deliveryClearAccess: boolean;
    public deliveryParkEasily: boolean;
    public accessVehicleSize?: boolean;
    public accessWithin20m: boolean;
    public accessIsSafe: boolean;
    public panDrawerUpgrade?: PanDrawerUpgrade;
    public drawerBoxUpgrade?: DrawerBoxUpgrade;
    public discountPercentage: number;
    public promotionPercentage?: number;
    public pickDeliveryLater: boolean;
    public items: BasketItem[];

    // Additional Params Not Saved in the DB
    public orderDate: string;
    public isRemedial?: boolean;
    public orderStatus: number;
    public outstanding?: number;
    public totalCharged?: number;
    public totalRefunded?: number;
    public cutoffDateFormatted?: string;
    public paymentHistory?: any[];
    public addresses?: IBasketAddresses;

    public notes?: any[];

    public get canEdit() {
        return !this.cutOffDate ? true : this.cutOffDate > DateHelper.now()
    }

    constructor(name?: string) {
        let createdAt = DateHelper.now();

        this.name = name || `New Basket - ${DateHelper.now(true)}`;
        this.items = [];
        this.cost = 0;
        this.modifiedAt = createdAt;
    }
}
