@import '/src/theme/variables';

component-terms {
    hr {
        &.narrow {
            margin-bottom: 15px;
        }
    }

    .button-full {
        margin-bottom: 20px;
    }

    .terms {
        display: flex;
        align-items: flex-start;
        gap: 40px;
        margin: 15px 0;
        padding: 20px;
        border: 4px solid color('copy-border');

        .terms-column {
            flex: 1 1 50%;
        }

        .thanks-banner {
            background: #EBEBEB;
            margin-top: 20px;
            margin-bottom: 20px;

            h3 {
                margin: 0;
                padding: 10px;
                background: #999999;
                color: #FFFFFF;
                text-align: center;
            }

            p {
                margin: 0;
                padding: 15px;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .terms {
            flex-direction: column;
        }
    }

}