<section>
    <div class="contained">
        <div class="edge-profile-page-content">
            <div class="edge-profile-heading-block">
                <h2>Choose edge profile</h2>
                <p>
                    Square edged laminate worktops are a bespoke made to order product, You cannot cancel an
                    order for these once you process and place your order with us. Also due to the difference in
                    edge profile between Quadra profile & Square edged we recommend NOT combining both.
                </p>
            </div>
            <div class="choose-edge-profile-row">
                <div class="choose-edge-profile-column">
                    <div class="edge-profile-img">
                        <img src="https://static.diy-kitchens.com/assets/images/laminateworktops/worktop_round_edge.jpg" alt="Worktop round edge"/>
                    </div>
                    <div class="edge-profile-block-content">
                        <h3>Quadra edge profile</h3>
                        <div class="price">From <strong [innerHTML]="75.47 | currencyFormat:true:true"></strong></div>
                        <p>This style of shaped laminate edging allows you to have standard straight lengths of worktop
                            in your kitchen.</p>
                    </div>
                    <div class="edge-profile-block-content">
                        <p>Pros</p>
                        <ul class="pros-list">
                            <li>
                                <p>Lower price.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="edge-profile-block-content">
                        <p>Cons</p>
                        <ul class="cons-list">
                            <li>
                                <p>Curved shapes not available.</p>
                            </li>
                            <li>
                                <p> Downturns not available.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="edge-profile-action-block">
                        <button mat-stroked-button class="btn-blue" [routerLink]="allTypeRoute(true)">
                            Choose
                        </button>
                    </div>
                </div>
                <div class="choose-edge-profile-column">
                    <div class="edge-profile-img">
                        <img src="https://static.diy-kitchens.com/assets/images/laminateworktops/worktop_square_edge.jpg" alt="Worktop square edge"/>
                    </div>
                    <div class="edge-profile-block-content">
                        <h3>Square edge profile</h3>
                        <div class="price">From <strong [innerHTML]="151.20 | currencyFormat:true:true"></strong></div>
                        <p>This style allows you to have the internal, end and s-shape curved worktops, more usually
                            associated with solid wood or granite, in your kitchen.</p>
                    </div>
                    <div class="edge-profile-block-content">
                        <p>Pros</p>
                        <ul class="pros-list">
                            <li>
                                <p>Curved shape options for more flexibility.</p>
                            </li>
                            <li>
                                <p>Downturns available to finish the look.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="edge-profile-block-content">
                        <p>Cons</p>
                        <ul class="cons-list">
                            <li>
                                <p>Higher price.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="edge-profile-action-block">
                        <button mat-stroked-button class="btn-blue" [routerLink]="allTypeRoute(false)">
                            Choose
                        </button>
                        <button mat-stroked-button class="btn-hollow">
                            More Info
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>