import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { AppliancesRouting } from './appliances.routing';
import { AppliancesPage } from './appliances.page';
import { ApplianceListingPage } from './listing/appliance-listing.page';
import { ApplianceDetailPage } from './detail/appliance-detail.page';

@NgModule({
    imports: [
        SharedModule,
        AppliancesRouting,
    ],
    declarations: [
        AppliancesPage,
        ApplianceListingPage,
        ApplianceDetailPage
    ]
})
export class AppliancesPageModule { }
