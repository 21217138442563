<header class="content-header-corner content-header-background" *ngIf="header" [ngClass]="header.imageClass">
    <header class="content-contained">
        <h1>{{header.name}} Kitchens</h1>
        <p>{{header.description}}</p>
    </header>
</header>

<hr class="narrow" />

<section class="content-contained">
    <div class="kitchen-container">
        <div class="filters" *ngIf="filters">
            <div class="filter filter-range-type">
                <h4>Kitchen Style</h4>
                <div class="filter-options">
                    <div class="filter-option" [ngClass]="{ selected: filterOption.id === filters.rangeType.selected }" *ngFor="let filterOption of filters.rangeType.options">
                        <a [routerLink]="filterOption.link" [title]="filterOption.name">
                            <img class="filter-image" [src]="filterOption.image" [alt]="filterOption.name"/>
                            <span>{{filterOption.name}}</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="filter filter-colour">
                <h4>Kitchen Colour</h4>
                <div class="filter-options">
                    <div class="filter-option" [ngClass]="{ selected: filterOption.id === filters.colour.selected }" *ngFor="let filterOption of filters.colour.options">
                        <a [routerLink]="filterOption.link" [matTooltip]="filterOption.name" [title]="filterOption.name">
                            <div class="swatch" [ngClass]="'swatch-' + filterOption.image"></div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="filter filter-finish">
                <h4>Kitchen Finish</h4>
                <div class="filter-options">
                    <div class="filter-option" [ngClass]="{ selected: filterOption.id === filters.finish.selected }" *ngFor="let filterOption of filters.finish.options">
                        <a [routerLink]="filterOption.link" [matTooltip]="filterOption.name" [title]="filterOption.name">
                            <img class="filter-image" [src]="filterOption.image" [alt]="filterOption.name"/>
                            <span>{{filterOption.name}}</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="filter filter-material">
                <h4>Kitchen Material</h4>
                <div class="filter-options">
                    <div class="filter-option" [ngClass]="{ selected: filterOption.id === filters.material.selected }" *ngFor="let filterOption of filters.material.options">
                        <a [routerLink]="filterOption.link" [title]="filterOption.name">
                            <span>{{filterOption.name}}</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="filter filter-layout">
                <h4>Kitchen Layout</h4>
                <p>Select a kitchen layout to see prices in that shaping.</p>
                <div class="filter-options">
                    <div class="filter-option" [ngClass]="{ selected: filterOption.id === filters.layout.selected }" *ngFor="let filterOption of filters.layout.options">
                        <a [routerLink]="filterOption.link" [matTooltip]="filterOption.name" [title]="filterOption.name">
                            <img class="filter-image" [src]="filterOption.image" [alt]="filterOption.name"/>
                        </a>
                    </div>
                </div>
            </div>

            <div class="week-commencing-container">
                <div class="week-comencing-block">
                    <h2>Deliveries</h2>
                    <p>Order your fully rigid kitchen today and have it delivered to your home from week commencing:</p>
                    <span>{{weekCommencing | date: 'dd/MM/YYYY'}}</span>
                    <p class="text-small">Please note that delivery dates vary by kitchen range. Please check on the individual kitchen range pages for further details.</p>
                </div>
                <hr />
                <div class="week-comencing-block">
                    <h3>Have a delivery date in mind?</h3>
                    <p>To help you plan for your new kitchen, you can now select a later delivery date as far in advance as you like.</p>
                    <p>Simply select your preferred delivery date at checkout.</p>
                </div>
            </div>
        </div>
        <div *ngIf="filteredRanges" class="kitchens">
            <div class="banners">
                <div class="panel">
                    <div class="panel-left">
                        <a routerLink="/about-diy-kitchens/price-and-quality-comparison" title="Price & Quality Comparison">
                            <img src="https://static.diy-kitchens.com/assets/images/banner/listings-banner-trustpilot.jpg" width="100%" alt="Trustpilot Best rated national kitchen retailer."/>
                        </a>
                    </div>
                    <div class="panel-right">
                        <a routerLink="/customer-reviews/shaker" class="video-outer" title="Real Customer Kitchens">
                            <img src="https://static.diy-kitchens.com/assets/images/banner/offer-banner_real-customers_oct2020.jpg" width="100%" alt="Real customer kitchen videos. Take a look."/>
                        </a>
                    </div>
                </div>

                <hr class="narrow" />

                <div class="banner">
                    <img src="https://static.diy-kitchens.com/assets/images/samples/free-sample_listings.jpg" width="100%" alt="Free samples. Get 3 free door samples straight to your home. £6 delivery charge. Must be delivered to same address."/>
                </div>
            </div>

            <div class="filters-sort-by">
                <h5>Sort Styles by:</h5>

                <div class="checkboxes">
                        <a [routerLink]="filterSort('popularity')">
                            <mat-icon *ngIf="selectedSortBy === 'popularity'">check_box</mat-icon>
                            <mat-icon *ngIf="selectedSortBy !== 'popularity'">check_box_outline_blank</mat-icon>
                            <p>Popularity</p>
                        </a>
                        <a [routerLink]="filterSort('low-high')">
                            <mat-icon *ngIf="selectedSortBy === 'low-high'">check_box</mat-icon>
                            <mat-icon *ngIf="selectedSortBy !== 'low-high'">check_box_outline_blank</mat-icon>
                            <p>Price (Low to high)</p>
                        </a>
                        <a [routerLink]="filterSort('high-low')">
                            <mat-icon *ngIf="selectedSortBy === 'high-low'">check_box</mat-icon>
                            <mat-icon *ngIf="selectedSortBy !== 'high-low'">check_box_outline_blank</mat-icon>
                            <p>Price (High to low)</p>
                        </a>
                        <a [routerLink]="filterSort('a-z')">
                            <mat-icon *ngIf="selectedSortBy === 'a-z'">check_box</mat-icon>
                            <mat-icon *ngIf="selectedSortBy !== 'a-z'">check_box_outline_blank</mat-icon>
                            <p>Title (a-z)</p>
                        </a>
                        <a [routerLink]="filterSort('z-a')">
                            <mat-icon *ngIf="selectedSortBy === 'z-a'">check_box</mat-icon>
                            <mat-icon *ngIf="selectedSortBy !== 'z-a'">check_box_outline_blank</mat-icon>
                            <p>Title (z-a)</p>
                        </a>
                </div>
            </div>

            <div class="kitchen" *ngFor="let range of filteredRanges" [id]="rangeId(range.name)">
                <div class="kitchen-header">
                    <div class="header-left">
                        <h2>{{range.name}}</h2>
                        <h3>{{range.selectedColour.name}}</h3>
                        <p>From <span [innerHTML]="getRangePrice(range) | currencyFormat:true:true"></span>
                            <button class="button-link" (click)="openShapeDialog(range)">{{range.selectedKitchenLayout}} price includes</button>
                        </p>
                    </div>
                    <div class="header-right">
                        <div class="how-its-made">
                            <button class="button-link" [media-thumb]="howItsMade(range)">
                                <div class="how-its-made-image">
                                    <mat-icon>play_circle_filled</mat-icon>
                                    <img src="https://static.diy-kitchens.com/assets/images/home/uk.jpg" width="100%" alt="How its made"/>
                                </div>
                                <p>How it's Made</p>
                            </button>
                        </div>
                        <div class="kitchen-door">
                            <a routerLink="/kitchen-doors" (click)="selectRange(range)" [title]="range.name">
                                <img [src]="getRangeDoorImage(range)" class="kitchendoor" width="100%" [alt]="range.name + ' ' + range.selectedColour.name" />
                            </a>
                        </div>
                    </div>
                </div>

                <div class="range-image" [promotion]="'range-listing'" [request]="'product-overlay-image'" [rangeName]="range.name" [colourName]="range.selectedColour.name">
                    <img [cdn]="getRangeImage(range)" width="100%" [alt]="range.name + ' ' + range.selectedColour.name" />
                </div>

                <div class="common-swatches">
                    <button *ngFor="let colour of range.colours" (click)="switchRangeColour(range, colour)"
                        class="button-link"
                        [ngClass]="{ active: range.selectedColour.name === colour.name }"
                        [matTooltip]="colour.name" matTooltipPosition="above" matTooltipClass="tooltip">
                        <div class="swatch swatch-{{colour.css_class}}">
                            <mat-icon class="selected-colour">done</mat-icon>
                        </div>
                    </button>
                </div>

                <div class="kitchen-information">
                    <p>Kitchen style: <strong>{{range.filters.join(', ')}}</strong> </p>
                    <p [innerHTML]="range.range_detailed_description"></p>
                    <p *ngIf="range.isTrueHandleless">
                        Designing and ordering a True Handleless kitchen is more complicated than a standard kitchen due to the number of components required to achieve the handleless look.
                        <strong>We therefore strongly recommend that you create a plan using our FREE online planner rather than creating a basket.</strong>
                        This will allow our planning team to check your design to ensure you have all the required components.
                    </p>
                    <p *ngIf="range.isTrueHandleless" class="warning"><strong>Ordering True Handleless units without a plan is likely to lead to delays in your installation if the correct components are not ordered.</strong></p>
                    <p class="bold">{{range.door_material}}, {{range.door_thickness}}mm thick doors</p>
                    <p *ngIf="rangeWeekCommencing(range)">Delivered to your home on week commencing <span>{{rangeWeekCommencing(range) | date: 'dd/MM/YYYY'}}</span></p>
                    <p class="small"><strong>Please note</strong> that all images and colours are for illustrative purposes only and we strongly recommend ordering a <a class="button-link" routerLink="/samples" title="Samples">sample</a> of both the door and the colour before placing your order.</p>
                    <p class="small"><strong>Please also note</strong> that images and colours will look different based upon your monitor/device settings.</p>
                </div>
                <div class="actions">
                    <div class="action">
                        <a [routerLink]="details(range)">
                            <button mat-stroked-button class="detailButton">
                                Details
                            </button>
                        </a>
                    </div>
                    <div class="action">
                        <a routerLink="/samples/door-samples">
                            <button mat-stroked-button class="detailButton" (click)="selectRange(range)">
                                Free Sample
                            </button>
                        </a>
                    </div>
                    <div class="action">
                        <a [href]="plannerLink(range)" target="_blank">
                            <button mat-flat-button color="accent">
                                Start Planning
                            </button>
                        </a>
                    </div>
                    <div class="action">
                        <a routerLink="/kitchen-units">
                            <button mat-flat-button *ngIf="!range?.isTrueHandleless" (click)="selectRange(range)" class="chunky" color="primary">
                                Buy Units
                            </button>
                        </a>
                    </div>
                </div>
            </div>

            <div *ngIf="filteredRanges && !filteredRanges.length" class="no-results">
                <em>No Styles were found that match your filters.</em>
                Try removing a filter.
            </div>
        </div>
    </div>
</section>

<component-latest-offers></component-latest-offers>
