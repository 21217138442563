<header class="content-contained">
    <h1>Carcase Samples</h1>
    <p>At diy-kitchens we want to supply your dream kitchen, that's why we offer a samples service. Order a sample or
        two of the carcase colour you are interested in so you can see the quality for yourself.
    </p>
</header>

<hr class="narrow" />

<section class="content-contained">
    <div class="contained flex-space-between">
        <div class="left">
            <div class="tip">
                <h4>Useful tip:</h4>
                <p>
                    Why not order some door samples too, these allow you to check the colour of your cabinet against a sample door.
                    We can arrange collection of the samples or you can return them with our drivers on delivery of your new kitchen.
                </p>
            </div>
        </div>
        <div class="products" *ngIf="carcases">
            <div class="carcase" *ngFor="let carcase of carcases">
                <div class="image">
                    <div class="swatch swatch-{{carcase.cssClass}}"></div>
                </div>
                <div class="title">{{carcase.colour}}</div>
                <div class="price">Carcase samples are<br />free of charge</div>
                <div class="actions">
                    <component-basket-add-button [type]="type" [item]="carcase" [allowFree]="true"></component-basket-add-button>
                </div>
            </div>
        </div>
    </div>
</section>