import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Config } from '@app/config';
import { StorageService } from '@app/services/storage';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
    constructor(private storageService: StorageService, private config: Config) { }

    private buildAuthToken(token): string {
        const header: string = this.config.auth.authorization;
        const authorizationString = `UserToken=${token}`;

        return `${header} ${btoa(authorizationString)}`;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.storageService.get(this.config.auth.storage)).pipe(
            switchMap(r => {
                const token = r ? r.token : null;
                if (token) {
                    const authToken = this.buildAuthToken(token);
                    const requestClone = request.clone({ setHeaders: { Authorization: `${authToken}` } });

                    return next.handle(requestClone);
                } else {
                    return next.handle(request);
                }
            })
        );
    }
}
