<section class="contained" *ngIf="item">
    <div class="product-details">
        <div class="left">
            <div class="image" [promotion]="'sinks-and-taps-detail'" [request]="'product-overlay-image'" [brand]="item.brand" [productCode]="item.code" [item]="item" [type]="ProductType.SINK_AND_TAPS">
                <component-product-image-gallery [images]="productImages"></component-product-image-gallery>
            </div>
        </div>
        <div class="details">
            <img class="brand" src="{{ item.media.brandImage }}" [alt]="item.brand + 'logo'" />
            <p><label>Product code: </label> <label class="code">{{ item.code }}</label> </p>
            <h1 class="title">{{ item.desc }}</h1>
            <p class="description">{{ item.detail }}</p>
            <ng-container *ngIf="this.item.extended_details?.details.length">
                <p *ngFor="let description of item.extended_details.details"
                    [ngClass]="{'sub-title': description === description.toUpperCase()}">{{description}}</p>
            </ng-container>
            <table>
                <tr *ngIf="item.width > 0">
                    <th>Finish:</th>
                    <td>{{item.finish}}</td>
                </tr>
                <tr *ngIf="item.width > 0">
                    <th>Width:</th>
                    <td>{{item.width}}mm</td>
                </tr>
                <tr *ngIf="item.height > 0">
                    <th>Height:</th>
                    <td>{{item.height}}mm</td>
                </tr>
                <tr *ngIf="item.depth > 0">
                    <th>Depth:</th>
                    <td>{{item.depth}}mm</td>
                </tr>
            </table>

            <div class="price" [promotion]="'sinks-and-taps-detail'" [request]="'product-price'" [brand]="item.brand" [productCode]="item.code" [item]="item" [type]="ProductType.SINK_AND_TAPS">
                <p *ngIf="subCategoryName === 'Undermount'">Please be aware that under mount sinks are not compatible for use with laminate worktops. Under mount sinks should only be installed with solid timber, granite, marble or other solid surface worktop materials.</p>
                <p *ngIf="subCategoryName === 'Belfast'">Please be aware that belfast sinks are not compatible for use with laminate worktops. Belfast sinks should only be installed with solid timber, granite, marble or other solid surface worktop materials.</p>
                <strong *ngIf="item.price" [innerHTML]="item.price | currencyFormat:true:true"></strong>
                <p class="small" *ngIf="!item.price">Currently unavailable</p>
            </div>
            <div class="flex-space-between">
                <div class="common-quantity">
                    <label>Quantity:</label>
                    <span>
                        <input type="number" min="1" placeholder="Qty" [(ngModel)]="item.qty">
                    </span>
                </div>
                <component-basket-add-button [activeRange]="activeRange" [type]="type" [item]="item"
                    [qty]="item.qty"></component-basket-add-button>
            </div>
        </div>
    </div>

    <div class="description-block" *ngIf="item">
        <div class="desc-grid">
            <div class="desc-grid-item">
                <h3 class="desc-sec-title">Description</h3>
                <p>{{item.desc}}</p>
                <hr>
                <div class="item-specification">
                    <h3>Specification</h3>
                    <ng-container *ngIf="item.extended_details?.specifications?.length; else showDimensions">
                        <div class="desc-grid-item" *ngFor="let spec of item.extended_details.specifications">
                            <ng-container *ngIf="spec.name !== 'Key Feature' && spec.name !== 'Feature'">
                                <label>{{spec.name}}</label>
                                <span>{{spec.value}}</span>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-template #showDimensions>
                        <div class="desc-grid-item">
                            <label>Bowl Depth:</label>
                            <span>{{item.depth}}mm</span>
                        </div>
                        <div class="desc-grid-item">
                            <label>Dimensions:</label>
                            <span>{{item.height}}mm X {{item.width}}mm </span>
                        </div>
                        <div class="desc-grid-item">
                            <label>Depth</label>
                            <span>{{item.depth}}mm</span>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div class="desc-grid-item">
                <h4>FOUND A LOWER PRICE? WE'LL MATCH IT!</h4>
                <p><strong>If you find any like for like products cheaper elsewhere we will match it!</strong></p>
                <p>
                    Price matching will hopefully be beneficial to both yourself and to us in the case of kitchen units
                    for example they will be delivered all in one delivery and we will be able to check that you have
                    the
                    correct unit / appliance door configurations.
                </p>
                <p><strong>Please note:</strong> This is at our discretion dependent on retailer.</p>
                <button mat-flat-button class="common-btn-hollow" routerLink="/about-diy-kitchens/price-promise">OUR PRICE
                    PROMISE</button>
            </div>
        </div>
    </div>
</section>

<section *ngIf="housings.length > 0" class="housings">
    <div class="contained">
        <h4>Compatible sink housing units</h4>
        <div class="products">
            <div class="item" *ngFor="let entry of housings">
                <div class="left">
                    <div class="image" [promotion]="'sinks-and-taps-detail'" [request]="'listing-overlay-image'" [productCode]="entry.item.unit_code" [type]="ProductType.CABINETS">
                        <img [ngClass]="{ 
                            flip: (
                                (entry.item._hingeSide === Handing.LH && entry.item.handingType !== HandingType.DRAWER) || 
                                (entry.item._hingeSide === Handing.RH && entry.item.handingType === HandingType.DRAWER)
                            )}" src="{{entry.item.media.image}}" [alt]="entry.item.desc" />
                    </div>
                </div>
                <div class="details">
                    <div class="price" [promotion]="'sinks-and-taps-detail'" [request]="'unit-price'" [productCode]="entry.item.unit_code" [item]="entry.item" [softClose]="entry._softClose">
                        <strong *ngIf="itemCost(entry.item)"
                            [innerHTML]="itemCost(entry.item) + ((entry._softClose) ? entry.item.softCloseCost : 0) | currencyFormat:true:true"></strong>
                        <p *ngIf="!activeRange">Cabinet only price</p>
                        <p *ngIf="!itemCost(entry.item)">Unavailable in this range</p>
                    </div>
                    <div class="title">{{entry.item.desc}}</div>
                    <p class="small">{{entry.item.unit_code}}</p>
                    <p class="desc">{{entry.item.detail}}</p>

                    <div class="dims">
                        <div class="flex">
                            <label>Height:</label>
                            <div>{{entry.item.height}}mm</div>
                        </div>
                        <div class="flex">
                            <label>Depth:</label>
                            <div>{{entry.item.depth}}mm</div>
                        </div>
                        <div class="flex">
                            <label>Width:</label>
                            <div *ngIf="entry.widths.length === 1">{{entry.item.width}}mm</div>
                            <div *ngIf="entry.widths.length>1" class="widths">
                                <button mat-stroked-button *ngFor="let width of sortWidths(entry.widths)"
                                    (click)="selectWidth(entry, width)"
                                    [class.selected]="width.width === entry.item.width">
                                    {{width.width}}
                                </button>
                            </div>

                        </div>
                        <div class="flex" *ngIf="variants(entry).length > 1">
                            <label>Variants:</label>
                            <div class="variants">
                                <button mat-stroked-button class="chunky" *ngFor="let variant of variants(entry)"
                                    (click)="selectVariant(entry, variant)"
                                    [class.selected]="variant.code === entry.item.unit_code" title="{{variant.code}}">
                                    {{variant.variant}}
                                </button>
                            </div>

                        </div>
                    </div>

                    <div class="config" *ngIf="(entry.item.softCloseCost > 0) || entry.item.handed">
                        <label>{{hingeWording(entry.item)}} Side: </label>
                        <button *ngIf="entry.item.handed" class="left-hand"
                            [class.selected]="entry.item._hingeSide === Handing.LH"
                            (click)="setHinge(entry.item, Handing.LH)">
                            LEFT
                        </button>
                        <button *ngIf="entry.item.handed" class="right-hand"
                            [class.selected]="entry.item._hingeSide !== Handing.LH"
                            (click)="setHinge(entry.item, Handing.RH)">
                            RIGHT
                        </button>
                        <button *ngIf="entry.item.softCloseCost > 0" class="right" [class.selected]="entry._softClose"
                            (click)="toggleSoft(entry)">
                            SOFT CLOSING
                        </button>
                    </div>

                    <div class="actions">
                        <button mat-stroked-button class="chunky"
                            [routerLink]="'/kitchen-units/unit/' + entry.item.unit_code">
                            DETAILS
                        </button>
                        <button mat-flat-button class="chunky" color="primary" *ngIf="activeRange"
                            (click)="addUnitToBasket(entry)" [disabled]="!itemCost(entry.item)">
                            ADD TO BASKET
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="noResults" class="no-results">
                <em>No items were found that match your filters...</em>
                Try removing a filter.
            </div>
        </div>
    </div>
</section>
