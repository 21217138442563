import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BasketService } from '@app/services/basket';
import { DrawerBoxUpgrade, IDrawerCostAndQty, PanDrawerUpgrade } from '@app/services/basket/models';
import { DialogService } from '@app/services/dialog';

@Component({
    selector: 'component-drawer-upgrade-dialog',
    templateUrl: './drawer-upgrade.dialog.component.html',
    styleUrls: ['./drawer-upgrade.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DrawerUpgradeDialogComponent {
    public showPanDrawerUpgrades: boolean = false;
    public showDrawerBoxUpgrades: boolean = false;

    public PanDrawerUpgrade = PanDrawerUpgrade;
    public panDrawerUpgrade: PanDrawerUpgrade;
    public DrawerBoxUpgrade = DrawerBoxUpgrade;
    public drawerBoxUpgrade: DrawerBoxUpgrade;

    public drawers: IDrawerCostAndQty;

    public upgrading: boolean = false;

    constructor(
        private basketService: BasketService,
        private dialogService: DialogService,
        private dialogRef: MatDialogRef<DrawerUpgradeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        let panDrawerQty = 0;
        let drawerBoxQty = 0;

        let drawerUpgrades = this.basketService.getDrawerUpgrades();
        this.panDrawerUpgrade = drawerUpgrades.panDrawer;
        this.drawerBoxUpgrade = drawerUpgrades.drawerBox;

        if (data && data.item && data.item.panDrawerQty) {
            panDrawerQty = (data.item.panDrawerQty * (data.item.qty || 1));
        }

        if (data && data.item && data.item.drawerBoxQty) {
            drawerBoxQty = (data.item.drawerBoxQty * (data.item.qty || 1));
        }

        this.basketService.getDrawerCostAndQty(panDrawerQty, drawerBoxQty)
            .then((drawers) => this.drawers = drawers)
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public upgradePanDrawers(option: PanDrawerUpgrade) {
        if (this.panDrawerUpgrade !== option) {
            this.panDrawerUpgrade = option;
            this.upgrade();
        } else {
            this.showPanDrawerUpgrades = false;
            this.showDrawerBoxUpgrades = false;
        }
    }

    public upgradeDrawerBoxes(option: DrawerBoxUpgrade) {
        if (this.drawerBoxUpgrade !== option) {
            this.drawerBoxUpgrade = option;
            this.upgrade();
        } else {
            this.showPanDrawerUpgrades = false;
            this.showDrawerBoxUpgrades = false;
        }
    }

    private upgrade() {
        this.upgrading = true;
        this.basketService.setDrawerUpgrade(this.panDrawerUpgrade, this.drawerBoxUpgrade)
            .then(() => {
                this.upgrading = false;
                this.showPanDrawerUpgrades = false;
                this.showDrawerBoxUpgrades = false;
            })
            .catch((error) => {
                this.dialogService.error(this.constructor.name, error);

                this.upgrading = false;
                this.showPanDrawerUpgrades = false;
                this.showDrawerBoxUpgrades = false;
            });
    }

    public close() {
        this.dialogRef.close(null);
    }
}
