import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { Config } from '@app/config';

import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { StorageService } from '@app/services/storage';

import { UserDetails } from './models';

import { LoginDialogComponent } from '@app/services/auth/dialogs/login';

@Injectable()
export class AuthService {
    public ready: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public details: BehaviorSubject<UserDetails> = new BehaviorSubject<UserDetails>(null);
    public authentication: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    public get isAuthenticated(): boolean {
        return (this.authentication.getValue()) ? true : false;
    }

    private returnUrl: string;

    constructor(
        private config: Config,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private storageService: StorageService
    ) {
        this.loadUser()
            .then(() => {
                this.loaded.next(true);
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public loggedIn() {
        let url = null;

        if (this.returnUrl) {
            url = this.returnUrl;
            this.returnUrl = null;
        } else if (this.navigationService.route.value.query.returnUrl) {
            url = this.navigationService.route.value.query.returnUrl;
        } else if (this.navigationService.route.value.url.match(/^\/account\/reset-password/i)) {
            url = '/';
        }

        if (url) {
            this.navigationService.navigate([url]);
        }
    }

    public loggedOut(returnUrl?: string) {
        let navigationExtras: NavigationExtras;

        if (returnUrl && this.config.auth.redirect === 'foreground') {
            navigationExtras = {
                queryParams: { returnUrl }
            };
        } else {
            this.returnUrl = returnUrl || null;
        }

        let route = this.config.auth.login || this.config.auth.logout;

        if (route) {
            this.navigationService.navigate([route], navigationExtras);
        }
    }

    public activate(ignore: boolean = false, applicationToken: string = null) {
        if (!this.isAuthenticated) {
            this.authentication.next(true);

            if (this.config.isBrowser && this.navigationService.iframeSrc) {
                this.navigationService.post({
                    token: applicationToken,
                    parentUrl: window.location.origin
                }, '*');
            }

            if (!ignore) {
                this.loggedIn();
            }
        }
    }

    public deactivate() {
        if (this.authentication.value !== false) {
            this.authentication.next(false);
        }

        if (this.config.isBrowser && this.navigationService.iframeSrc) {
            this.navigationService.post({
                action: 'logout',
                parentUrl: window.location.origin
            }, '*');
        }

        if (this.isAuthenticated) {
            this.loggedOut();
        }
    }

    public loadUser(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.storageService.get(this.config.auth.storage)
                .then((data: UserDetails) => {
                    this.details.next(data);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public saveUser(details: UserDetails): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.storageService.set(this.config.auth.storage, details)
                .then(() => {
                    this.details.next(details);
                    resolve();
                })
                .catch((error) => reject(error));
        });
    }

    public removeUser(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.storageService.remove(this.config.auth.storage)
                .then(() => {
                    this.details.next(null);
                    resolve();
                })
                .catch((error) => reject(error));
        });
    }

    public login(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.dialogService.custom(LoginDialogComponent, {
                panelClass: 'component-login-dialog',
                maxWidth: '460px',
                maxHeight: '600px',
                autoFocus: false
            })
                .then((isAuthed) => resolve(isAuthed))
                .catch((error) => reject(error));
        });
    }

    public loginPrompt(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.isAuthenticated) {
                resolve();
            } else {
                this.dialogService.prompt('Login', 'Please login or register to access and manage your account.', {
                    data: {
                        buttons: [
                            { label: 'cancel', action: false, type: 'stroked', colour: 'secondary' },
                            { label: 'login / register', action: true }
                        ]
                    }
                })
                    .then((response) => {
                        if (response) {
                            this.login()
                                .then((isAuthed) => {
                                    if (isAuthed) {
                                        resolve();
                                    } else {
                                        reject();
                                    }
                                })
                                .catch((error) => reject(error));
                        } else {
                            reject();
                        }
                    })
                    .catch((error) => {
                        reject();
                        this.dialogService.error(this.constructor.name, error);
                    });
            }
        });
    }
}
