import { Component, ViewEncapsulation, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Subscription } from 'rxjs';

import { BasketService } from '@app/services/basket';
import { DialogService } from '@app/services/dialog';

@Component({
    selector: 'component-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class TermsComponent implements AfterViewInit, OnDestroy {
    @Input() stepper: MatStepper;
    @Input() formGroup: FormGroup;

    public loading: boolean = true;
    private basketSubscription: Subscription = null;

    constructor(
        private basketService: BasketService,
        private dialogService: DialogService
    ) { }

    ngAfterViewInit() {
        this.basketSubscription = this.basketService.basket$.subscribe((basket) => {
            if (basket) {
                if (basket.acceptedTerms) {
                    this.formGroup.controls.accepted.setValue(true);
                }

                setTimeout(() => {
                    this.loading = false;
                }, 100);
            }
        });
    }

    ngOnDestroy() {
        if (this.basketSubscription) {
            this.basketSubscription.unsubscribe();
            this.basketSubscription = null;
        }
    }

    public accept() {
        this.basketService.acceptTerms()
            .then(() => {
                this.formGroup.controls.accepted.setValue(true);

                this.stepper.next();
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
