import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { MergeUniquePipe } from '@app/pipes';

import { CatalogueService } from '@app/services/catalogue';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';

@Component({
    selector: 'page-sinks-and-taps',
    templateUrl: './sinks-and-taps.page.html',
    styleUrls: ['./sinks-and-taps.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SinksAndTapsPage implements OnInit {
    public loaded: boolean = false;

    public tags: string[] = ['diy kitchens - website', 'sinks & taps'];
    public categories = [];
    public items: any;

    private mergeUnique = new MergeUniquePipe();

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) {
        this.navigationService.setNavigation({
            title: 'Sinks & Taps',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'Description', content: `See our range of Sinks And Taps available at trade prices.` },
                { property: 'og:locale', content: 'en_GB' },
                { property: 'og:type', content: 'website' },
                { property: 'og:site_name', content: 'DIY Kitchens' },
                { property: 'og:url', content: 'Sinks & Taps - DIY Kitchens ' },
                { property: 'og:title', content: 'Sinks & Taps - DIY Kitchens ' },
                { property: 'og:description', content: `See our range of Sinks And Taps available at trade prices.` },
                { property: 'og:image', content: 'https://static.diy-kitchens.com/assets/images/seo/sinks-and-taps/sinks-and-taps/sinks-inset.jpg' },
                { property: 'og:image:secure_url', content: 'https://static.diy-kitchens.com/assets/images/seo/sinks-and-taps/sinks-and-taps/sinks-inset.jpg' },
                { property: 'og:image:width', content: '600' },
                { property: 'og:image:height', content: '400' }
            ],
            routeHistory: [{ title: 'Sinks & Taps', route: '/sinks-and-taps' }]
        });
    }

    ngOnInit() {
        this.catalogueService.getSinksAndTaps()
            .then((sinkstaps: any) => {
                if (sinkstaps) {
                    let categories = this.mergeUnique.transform(sinkstaps.map(product => product.category));

                    this.categories = categories.map(category => {
                        this.items = sinkstaps.filter(product => product.category === category);
                        let itemCount = this.items.length;

                        return {
                            name: category,
                            route: category.toLowerCase().replace(/ /g, '-'),
                            tag: category.toLowerCase().replace(/ /g, ' '),
                            itemCount: itemCount,
                            countName: 'products'
                        };
                    });

                    this.loaded = true;
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
