import { NgModule } from '@angular/core';

import { OrderService } from './order.service';

@NgModule({
    providers: [
        OrderService
    ]
})
export class OrderServiceModule { }
