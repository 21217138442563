import { NgModule } from '@angular/core';

import { CurrencyFormatPipe } from '@app/pipes';

import { AuthGuardDirective } from '@app/services/auth/directives/auth-guard.directive';

import { CdnDirectiveModule } from './cdn/cdn.directive.module';
import { PromotionDirectiveModule } from './promotion/promotion.directive.module';
import { StickyDirectiveModule } from './sticky/sticky.directive.module';

import { BasketImageDirective } from './basket-image.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { ImageDirective } from './image.directive';
import { MapPopupDirective } from './map-popup.directive';
import { MatVerticalStepperScrollerDirective } from './mat-vertical-stepper.directive';
import { MediaThumbDirective } from './media-thumb.directive';

@NgModule({
    imports: [
        CdnDirectiveModule,
        PromotionDirectiveModule,
        StickyDirectiveModule
    ],
    exports: [
        AuthGuardDirective,
        CdnDirectiveModule,
        PromotionDirectiveModule,
        StickyDirectiveModule,

        BasketImageDirective,
        ClickOutsideDirective,
        ImageDirective,
        MapPopupDirective,
        MatVerticalStepperScrollerDirective,
        MediaThumbDirective
    ],
    declarations: [
        AuthGuardDirective,

        BasketImageDirective,
        ClickOutsideDirective,
        ImageDirective,
        MapPopupDirective,
        MatVerticalStepperScrollerDirective,
        MediaThumbDirective
    ],
    providers: [CurrencyFormatPipe]
})
export class DirectivesModule { }
