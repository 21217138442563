<section class="dark">
    <div class="contained flex-space-between wrap-under-800">
        <div>
            <h2>Plan with ease online</h2>
            <p>Design your kitchen online in the comfort of your own home - perhaps whilst sitting in your existing
                kitchen.
            </p>
            <p>With our online kitchen planning tool you can try out different layouts, choose from our extensive range
                of
                door
                styles and colours, try various worktop options and appliances.</p>
            <p>As you plan in a 2D overhead view, you can easily switch to a 3D view of the kitchen. So getting the
                exact look
                you want is easier than ever.</p>
            <p>All the time you're planning, the product list and costs are being built up into a product basket, so you
                can
                clearly see how your style choices match your budget.</p>
            <p>You'll find helpful advice, guidance videos and tips as you go. The planning tool has a lot of kitchen
                knowledge
                built in, so it ensures you have everything you need for your kitchen.</p>
            <p>For example, the tool will automatically calculate how much plinth, cornice & pelmet you need - add
                corner
                posts
                where required and generally assist you at every step.</p>
            <p>And, of course, our team of kitchen experts are only a click away if you need them.</p>
            <p class="bold">For total peace of mind, when you're ready to progress to the ordering stage, our team of
                kitchen
                design experts will thoroughly check your plan before you recieve your comprehensive product and
                installation
                quotation.</p>
            <p class="text-right">
                <a mat-flat-button color="primary" href="https://planner.diy-kitchens.com/plan/range/" target="_blank" title="Start Planning">START PLANNING...</a>
            </p>
        </div>
        <div class="ipad"><img src="/assets/images/plan2d-ipad.png" alt="Plan online"></div>
    </div>
</section>
