import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AboutUnitSpecificationsPage } from './unit-specifications.page';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'unit-specifications',
                component: AboutUnitSpecificationsPage
            },
            {
                path: '**',
                redirectTo: 'unit-specifications'
            }
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class AboutUnitSpecificationsRouting { }
