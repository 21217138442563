@import '/src/theme/variables';

page-reviews {
    .review-container {
        display: flex;
    }

    .reviews {
        display: flex;
        flex-direction: column;
        margin-left: 30px;

        .review {
            
            h2 {
                margin: 0 0 1.3rem 0;
                font-size: 1.3rem;
                font-weight: 700;
                line-height: 1.777rem;
                letter-spacing: 0.125rem;
                font-family: font('opensans');
            }

            h3 {
                margin: 1rem 0 0 0;
                font-size: 1.2rem;
                font-weight: 600;
                letter-spacing: 0.05rem;
                font-family: font('opensans');
            }


            &:not(:first-child) {
                margin-top: 56px;
            }

            .review-header {
                display: flex;

                .header-left {
                    flex-grow: 1;
                }
            }

            .review-information {
                p {
                    color: inherit;
                    font-size: 1.1rem;
                    font-weight: inherit;
                }
            }

            .video-container {
                position: relative;
                width: 100%;
                padding-bottom: 56.25%; /* for 16:9 ratio */
                overflow: hidden;
            }
            
            .video-container iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            
        }
    }

    .filters {
        flex: 0 0 290px;

        .filter {
            margin-bottom: 25px;
            padding-bottom: 25px;
            border-bottom: 1px solid color('copy-border');

            &.filter-range-type {
                .filter-image {
                    display: block;
                    width: 36px;
                    height: 50px;
                }
            }

            &.filter-colour {
                .filter-options {
                    grid-template-columns: repeat(4, minmax(0, 1fr));

                    .filter-option {
                        padding: 0;
                        border: 0;

                        a {
                            justify-content: center;
                        }
                    }
                }
            }

            &.filter-finish {
                .filter-image {
                    display: block;
                    width: 36px;
                    height: 50px;
                }
            }

            &.filter-review-type {
                .filter-image {
                    display: block;
                    width: 43px;
                    height: 50px;
                    font-size: 43px;
                    line-height: 50px;
                    color: darkgray;
                }

                a:hover {
                    text-decoration: none;
                }
                
                a:hover span {
                    text-decoration: underline;
                }
                
            }

            .filter-options {
                display: grid;
                gap: 15px;
                grid-template-columns: repeat(2, minmax(0, 1fr));

                .filter-option {
                    padding: 5px;
                    border: 1px solid color('copy-border');

                    &.selected {
                        border-color: color('link');

                        a {
                            color: color('brand-primary');

                            .swatch {
                                border-color: color('brand-primary');
                            }
                        }
                    }

                    a {
                        display: flex;
                        align-items: center;
                        font-size: 0.75rem;
                        font-weight: 400;

                        .filter-image {
                            margin-right: 5px;
                        }

                        .swatch {
                            border: 1px solid color('copy-border');
                            width: 50px;
                            height: 50px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .content-header-background::before {
            background-image: none !important;
        }

        .content-header-background {
            p {
                padding-right: 0 !important;
            }
        }

        .review-container {
            flex-direction: column;
        }

        .reviews {
            margin-left: 0
        }

        .filters {
            .filter {
                &.filter-range-type {
                    .filter-options {
                        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
                    }
                }

                &.filter-colour {
                    .filter-options {
                        grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
                    }
                }

                &.filter-finish {
                    .filter-options {
                        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
                    }
                }
            }
        }
    }
}