import { Directive, HostListener } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Config } from '@app/config';

@Directive({
    selector: 'mat-vertical-stepper',
    standalone: false
})
export class MatVerticalStepperScrollerDirective {

    constructor(
        private stepper: MatStepper,
        private config: Config
        ) { }

    @HostListener('selectionChange', ['$event'])
    selectionChanged(selection: StepperSelectionEvent) {
        let stepElement;
        const stepId = this.stepper._getStepLabelId(selection.selectedIndex);
        if (this.config.isBrowser) {
            stepElement = document.getElementById(stepId);
        }
        if (stepElement) {
            setTimeout(() => {
                stepElement.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'auto' });
            }, 1);
        }
    }
}
