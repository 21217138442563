<section class="option-container">
    <ng-container *ngIf="filters && !!filters.length">
        <div class="filters">
            <button mat-flat-button (click)="toggleFilter()">All filters</button>
            <ng-container *ngFor="let filter of filters">
                <ng-container *ngIf="filtersOnBar.includes(filter.title)">
                    <button mat-flat-button *ngIf="getTickedFilter(filter.title).length; else fullTitle"
                    (click)="toggleFilter(filter.title)" class="selected" #abbreviatedTitle>
                        {{filter.title[0]}} {{getTickedFilter(filter.title)[0].name + (getTickedFilter(filter.title).length > 1 ? '...' : '')}}
                    </button>
                    <ng-template #fullTitle>
                        <button mat-flat-button (click)="toggleFilter(filter.title)">{{filter.title}}</button>
                    </ng-template>
                </ng-container>
            </ng-container>
            <button *ngIf="showSort" mat-flat-button (click)="toggleFilter('sort')">Sort by {{sortOrder}}</button>
        </div>
        <div class="wrapped">
            <button mat-flat-button color="primary" (click)="toggleFilter()">
                <span>Filter</span><mat-icon>filter_alt</mat-icon>
            </button>
            <button *ngIf="showSort" mat-flat-button color="primary" (click)="toggleFilter('sort')">Sort by {{sortOrder}}</button>
        </div>
    </ng-container>
    <ng-container *ngIf="showToggles">
        <div class="toggles">
            <div *ngIf="showActiveRange && activeRange" class="active-range">
                <img src="{{getRangeDoorImage()}}" [alt]="activeRange.range.name + ' ' + activeRange.rangeColour"/>
                <div class="range-text">
                    <span class="range-name">{{ activeRange.range.name }} {{ activeRange.rangeColour }}</span>
                    <span class="unit-name">{{ activeRange.carcaseColour }} unit</span>
                </div>
                <button mat-flat-button color="secondary" (click)="selectRange()">Edit Style</button>
            </div>
            <button mat-flat-button [ngClass]="{'selected' : config.settings.softClose}" (click)="clickSoftClose()">
                Soft close
                <span class="status">{{(config.settings.softClose) ? 'ON' : 'OFF'}}</span>
            </button>
        </div>
    </ng-container>

</section>
<section class="side-menu animate-left" *ngIf="sideMenuVisible" (clickOutside)="clickOutsider()">
    <div class="side-menu-header">
        <h3 *ngIf="!sideMenuSortVisible">Filters</h3>
        <h3 *ngIf="sideMenuSortVisible">Sort</h3>
        <button mat-icon-button (click)="toggleFilter()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="side-menu-body">
        <ng-container *ngIf="!sideMenuSortVisible; else sortOptionBlock" #filterOptionBlock>
            <ng-container *ngFor="let filter of filters">
                <mat-expansion-panel *ngIf="filter.title !== 'Category' && filter.title !== 'Sub Category'" [expanded]="filter.expanded" (expandedChange)="expansionPanelChange($event, filter)">
                    <mat-expansion-panel-header *ngIf="filter.token; else withoutToken">{{filter.title + ' ('+ filter.token + ')'}}</mat-expansion-panel-header>
                    <ng-template #withoutToken>
                        <mat-expansion-panel-header>{{filter.title}}</mat-expansion-panel-header>
                    </ng-template>
                    <div class="expansion-content" [ngClass]="{'box-select': ['Width', 'Height', 'Depth'].includes(filter.title)}">
                        <ng-container *ngFor="let opt of filter.opts">
                            <ng-container *ngIf="['Width', 'Height', 'Depth'].includes(filter.title); else tickBox">
                                <div [ngClass]="{'selected' : opt.tick, 'disabled' : opt.disabled}" class="opt" (click)="clickOption(opt)">
                                    <span>{{opt.name.substring(0, opt.name.length -2)}}</span>
                                </div>
                            </ng-container>
                            <ng-template #tickBox>
                                <div class="opt" (click)="clickOption(opt, filter, false)" [ngClass]="{'disabled' : opt.disabled}">
                                    <mat-icon class="selected" *ngIf="opt.tick">check_box</mat-icon>
                                    <mat-icon *ngIf="!opt.tick">check_box_outline_blank</mat-icon>
                                    <span class="title">{{opt.title || opt.name}}</span>
                                </div>
                            </ng-template>
                        </ng-container>
                    </div>
                </mat-expansion-panel>
            </ng-container>
        </ng-container>
        <ng-template #sortOptionBlock>
            <mat-radio-group labelPosition="before" [(ngModel)]="sortOrder" (change)="sortChanged()">
                <mat-radio-button value="popularity">Popularity</mat-radio-button>
                <mat-radio-button value="high-low">Price High to Low</mat-radio-button>
                <mat-radio-button value="low-high">Price Low to High</mat-radio-button>
                <mat-radio-button value="a-z">A to Z</mat-radio-button>
                <mat-radio-button value="z-a">Z to A</mat-radio-button>
            </mat-radio-group>
        </ng-template>
    </div>
    <div class="side-menu-footer">
        <button *ngIf="!sideMenuSortVisible" mat-flat-button color="primary" (click)="clearFilters()"><span>Clear filters</span><mat-icon>close</mat-icon></button>
        <button mat-flat-button color="primary" class="results-btn" (click)="toggleFilter()"><span>View results</span></button>
    </div>
</section>
