@import '/src/theme/variables';

component-filters {
    section {
        padding: 15px 0;
    }

    .count-sort {
        position: absolute;
        width: 1300px;
        height: 65px;
        z-index: 200;
        max-width: calc(100% - 50px);

        .count-sort-content {
            padding: 0 20px;
            height: 65px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 600;
            background-color: white;

            .mat-icon {
                vertical-align: middle;
            }
        }

        .mat-form-field-infix {
            padding: 0;
        }

        .sort-by-dropdown {
            position: absolute;
            top: 5px;
            right: 0px;
            font-weight: 600;
            font-size: 14px;
            padding-right: 5px;
        }
    }

    .width-dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        margin-top: 60px;
        margin-left: 330px;
        width: calc(100% - 390px);
        max-width: calc(1300px - 390px);
        font-weight: 600;
    }

    .active-range {
        display: flex;
        align-items: center;
        height: 100%;

        .door-style {
            margin-right: 10px;
            height: calc(100% - 15px);
        }
    }

    .door-style-display {
        display: flex;
        flex-direction: column;

        mat-label {
            color: color('black', 0.6);
            font-size: 0.75rem;
            line-height: normal;
        }
    }

    a {
        cursor: pointer;
    }

    @media only screen and (max-width: 1360px) {
        .count-sort {
            .count-sort-content {
                width: calc(100vw - 100px);
            }
        }
    }

    .fixed {
        .count-sort {
            width: 1300px;
            position: fixed;
            top: 0;
        }
    }

    button {
        background-color: color('white');
    }

    i {
        vertical-align: middle;
        font-size: 16px;
        // margin: -1px 0 0 10px;
    }

    .mat-expansion-panel-header {
        text-transform: uppercase;
        font-weight: 600;
    }

    .opt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;

        .mat-icon {
            flex-shrink: 0;
        }

        .title {
            flex-grow: 1;
            margin-left: 5px;
        }

        .count {
            float: right;
            color: color('brand-primary');
        }
    }

    section {
        @media only screen and (min-width: 1000px) {
            &.fixed {
                .filters {
                    position: fixed;
                    top: 0px;
                    max-height: calc(100vh - 90px);
                    overflow: auto;
                    padding-bottom: 30px;
                    padding-right: 5px;

                    &::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                        background-color: #F5F5F5;
                        border-radius: 10px;
                    }

                    &::-webkit-scrollbar {
                        width: 6px;
                        border-radius: 10px;
                        background-color: #F5F5F5;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background-color: #000000;
                    }
                }

                .items {
                    margin-left: 278px;
                }
            }
        }
    }

    .resets {
        padding: 10px;

        .pill-button {
            display: flex;
            align-items: center;
            margin: 6px;
            padding: 3px 12px;
            border: none;
            border-radius: 20px;
            background-color: color('brand-primary');
            color: color('white');
            font-size: 12px;
            white-space: nowrap;
            cursor: pointer;

            .close-icon {
                color: color('white');
                transform: scale(0.7);
            }
        }
    }

    .filter-mobile-header {
        display: none;
    }

    @media only screen and (max-width: 1000px) {
        .count-sort {
            display: none;

            .count-sort-content {
                display: none;
            }
        }

        .filter-mobile-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            border-bottom: 1px solid color('borderThin');
            padding: 0 15px;
            width: calc(100% - 30px);
            background-color: #FFF;
            z-index: 9;
            left: 0;
            margin-top: -45px;
            border-top: 1px solid #f1f1f1;

            .mat-form-field-infix {
                width: 120px;
            }

            .mat-mdc-form-field-infix {
                width: 120px;
                min-height: 30px;
            }

            button {
                cursor: pointer;
                border: none;
                padding: 15px 10px;
                font-size: 14px;
                display: flex;
                align-items: center;
                gap: 5px;
            }

            .filters-with-size {
                margin-top: 40px;
            }
        }

        .filters {
            display: none;
        }

        section {
            &.fixed {
                .filter-mobile-header {
                    position: fixed;
                    top: 0px;
                    left: 0;
                    margin-top: 0;
                }
            }
        }

        .refine {
            display: flex;
        }

        .results {
            display: none;

            button {
                background-color: color('brand-primary');
                color: color('white');
                margin-right: 10px;
                word-break: break-word;
                display: inline;
                padding: 8px 10px;
                font-size: 12px;
            }
        }

        .refine,
        .results {
            justify-content: flex-end;

            em {
                color: color('diy-selected');
                font-weight: 600;
                font-style: normal;
                display: block;
                justify-self: flex-start;
                padding: 15px;
            }
        }

        .show-on-mobile {
            .refine {

                //order: 3;
                button {
                    max-width: 104px;
                }
            }

            .results {
                display: flex;
            }

            .filter-mobile-header {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 999999;
                padding: 0;
                margin-top: 0;
                border-top: none;
                border-bottom-color: #dfdfdf;
                box-sizing: border-box;
                width: 100%;
            }

            .filters,
            .filters-with-size {
                z-index: 99999;
                display: block;
                position: fixed;
                top: 62px;
                left: 0;
                width: 100%;
                height: calc(100vh - 62px);
                overflow: auto;
                background-color: color('white');
                padding: 20px;
                margin: 0;
                box-sizing: border-box;

                .no-show-desktop {
                    display: block;
                }
            }

            &.fixed {
                .filter-mobile-header {
                    position: fixed;
                    top: 0px;
                    left: 0;
                }
            }
        }

        .width-dropdown {
            display: none;
        }
    }

    .kitchen-quick-option-block {
        border-bottom: 1px solid rgba(0, 18, 36, 0.7);
        padding-bottom: 34px;
        margin-top: 0;
        margin-bottom: 26px;

        .kitchen-quick-option-block-title {
            font-size: clamp(1rem, 1vw + 1rem, 1rem);
            font-weight: 700;
            letter-spacing: 0.05rem;
            line-height: clamp(1rem, 1vw + 1rem, 1.333rem);
            margin-bottom: 1rem;
            color: rgba(0, 18, 36, 0.9);
            text-transform: uppercase;

            .kitchen-quick-option-small-text {
                font-size: 12px;
                font-weight: normal;
                display: block;
                text-transform: initial;
                margin-top: 12px;
            }
        }

        .kitchen-quick-option-block-content {
            display: flex;
            align-items: center;
            column-gap: 20px;
            row-gap: 10px;
            flex-wrap: wrap;

            .kitchen-quick-option-item {
                width: calc(50% - 10px);

                .kitchen-quick-option-link {
                    display: flex;
                    align-items: center;
                    border: 1px solid #D7D7D7;
                    border-radius: 2px;
                    padding: 0.5rem;
                    cursor: pointer;
                    background: #ffffff;

                    img {
                        flex-shrink: 0;
                    }

                    span {
                        font-size: 12px;
                        color: #007bff;
                        font-weight: 400;
                    }

                    &:hover,
                    &.active {
                        border-color: #35BBF6;

                        span {
                            color: #35BBF6;
                        }
                    }
                }
            }
        }

        &.kitchen-style-option {
            .kitchen-quick-option-link {
                img {
                    height: 50px;
                    margin-right: 10px;
                }
            }
        }

        &.kitchen-brand-option {
            .kitchen-quick-option-link {
                justify-content: center;

                span {
                    font-size: 14px !important;
                    text-align: center;
                }
            }
        }

        &.kitchen-layout-option {
            .kitchen-quick-option-link {
                justify-content: center;
            }
        }

        &.kitchen-color-option {
            .kitchen-quick-option-block-content {
                column-gap: 30px;

                .kitchen-quick-option-item {
                    width: calc(25% - 23px);
                    height: 52px;
                    cursor: pointer;

                    .kitchen-quick-option-link {
                        padding: 0;

                        img {
                            max-width: 100%;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .mat-expansion-panel-header {
        background-color: color('brand-primary');
        color: white;
    }

    .mat-expansion-panel-header:hover {
        background-color: color('brand-primary') !important;
        color: white;
    }

    // .mat-header{
    //     background-color: color('brand-primary');
    //     color: white;
    // }
    // .mat-header :hover{
    //     background-color: color('brand-primary');
    //     color: white;
    // }
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: color('brand-primary');
        color: color('white') !important;
        height: 50px;

        @media screen and (max-width: 767px) {
            top: 9px;
        }
    }

    .mat-expansion-panel-header.mat-expanded {
        height: 64px;
        background-color: color('brand-primary') !important;
    }

    .filters {
        margin-top: 80px;
        width: 290px;
    }

    .filters-with-size {
        margin-top: 65px;
        width: 290px;

        .no-show-desktop {
            display: none;
        }

        @media screen and (max-width: 1000px) {
            display: none;
        }

        @include responsive-breakpoint-down(lg) {
            margin-top: 0;
        }
    }

    .show-on-mobile {
        .refine {
            button {
                @media screen and (min-width: 768px) and (max-width: 1000px) {
                    max-width: inherit;
                }
            }
        }

        .filters,
        .filters-with-size {
            @media screen and (min-width: 768px) and (max-width: 1000px) {
                top: 55px;
                height: calc(100vh - 55px);
            }
        }
    }
}