<header class="content-contained">
    <div class="panel" *ngIf="rangeColour">
        <h1>{{rangeColour.rangeName}} {{rangeColour.colour}} - Kitchen Doors</h1>
        <p *ngIf="rangeDescription" [innerHTML]="rangeDescription"></p>
    </div>

    <div class="range-image" *ngIf="rangeImage">
        <img [src]="rangeImage" width="100%" [alt]="rangeColour.rangeName + ' ' + rangeColour.colour" />
    </div>

    <div *ngIf="noResults" class="no-results">
        <em>No doors were found that match your filters.</em>
        Try removing a filter.
    </div>
</header>

<section class="content-contained">
    <div class="banners">
        <div class="panel">
            <div class="panel-left">
                <a routerLink="/about-diy-kitchens/price-and-quality-comparison" title="Price & Quality Comparison">
                    <img src="https://static.diy-kitchens.com/assets/images/banner/listings-banner-trustpilot.jpg" width="100%" alt="Trustpilot Best rated national kitchen retailer."/>
                </a>
            </div>
            <div class="panel-right">
                <a routerLink="/customer-reviews/shaker" class="video-outer" title="Customer Reviews">
                    <img src="https://static.diy-kitchens.com/assets/images/banner/offer-banner_real-customers_oct2020.jpg" width="100%" alt="Real customer kitchen videos. Take a look."/>
                </a>
            </div>
        </div>

        <hr class="narrow" />

        <div class="banner">
            <img src="https://static.diy-kitchens.com/assets/images/samples/free-sample_listings.jpg" width="100%" alt="Free samples. Get 3 free door samples straight to your home. £6 delivery charge. Must be delivered to same address."/>
        </div>
    </div>

    <div class="products" *ngIf="items">
        <div class="item" *ngFor="let entry of items">
            <div class="item-image" [promotion]="'door-detail'" [request]="'listing-overlay-image'" [rangeName]="rangeColour.rangeName" [colourName]="rangeColour.colour">
                <img [src]="entry.item.media.image" width="100%" [alt]="entry.item.desc + ' image'" />
            </div>
            <div class="item-header">
                <h4>{{rangeColour.rangeName}} {{rangeColour.colour}} {{entry.item.desc}}</h4>
                <p class="small">{{entry.item.code}}</p>
            </div>
            <div class="price" [promotion]="'door-detail'" [request]="'door-price'" [productCode]="entry.item.code" [item]="entry.item">
                <h5 [innerHTML]="cost(entry.item, entry) | currencyFormat:true:true"></h5>
            </div>
            <div class="sizing">
                <div class="sizing-height" *ngIf="entry.item.height">
                    <label>Height:</label>
                    <span>{{entry.item.height}}mm</span>
                </div>
                <div class="sizing-width" *ngIf="entry.item.width && !entry.widths.length">
                    <label>Width:</label>
                    <span>{{entry.item.width}}mm</span>
                </div>
                <div class="sizing-width" *ngIf="entry.widths.length">
                    <label>Width:</label>
                    <div class="width-buttons">
                        <ng-container *ngFor="let itemWidth of entry.widths">
                            <button mat-stroked-button color="primary" *ngIf="itemWidth.value === entry.item.width" class="selected" (click)="selectWidth(entry, itemWidth)">
                                {{itemWidth.value}}
                            </button>
                            <button mat-stroked-button color="secondary" *ngIf="itemWidth.value !== entry.item.width" (click)="selectWidth(entry, itemWidth)">
                                {{itemWidth.value}}
                            </button>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="add-to-basket">
                <mat-form-field class="quantity">
                    <input matInput type="number" min="1" placeholder="Qty" [(ngModel)]="entry.item.qty" />
                </mat-form-field>
                <component-basket-add-button [activeRange]="activeRange" [type]="type" [item]="entry.item" [qty]="entry.item.qty"></component-basket-add-button>
            </div>
        </div>

    </div>
</section>
