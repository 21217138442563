// Linked to API - so change there too
export enum MessageType {
    MANUFACTURING = 1,
    FEEDBACK = 2,
    QUOTES = 3,
    SAMPLES = 4,
    PRESALES = 5,
    DAMAGED = 6,
    MISSING = 7,
    INCORRECT = 8,
    RETURNS = 9,
    GENERAL = 10,
    PLANNER_QUOTE = 11,
    PLANNER_QUERY = 12,
    PLANNER_CHECK = 13,
    PLANNER_WORKTOP = 14,
    PLANNER_CONFIRMATION = 15,
    DELIVERY = 16,
    HR = 17,
    INTERNAL_SUPPORT = 18,
    RETURN = 19,
    CALLBACK = 20,
    NO_REPLY = 21
}
