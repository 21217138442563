<header class="content-header-corner content-header-background" [ngClass]="headerCssClass">
    <header class="content-contained">
        <h1>{{categoryDisplay}}</h1>
        <ng-container *ngIf="category === 'bar-handles'">
            <p>Our modern Innova bar handles have very clean & elegant lines and are a very practical handle to use in a new or more traditional kitchen. The bar handles below are available in various widths, to a maximum of 437mm, all you need to do now, is decide on the finish!</p>
        </ng-container>
        <ng-container *ngIf="category === 'bow-and-bridge-handles'">
            <p>Our Innova bow or bridge handles, as they are also called, give a nice smooth look and finish to any kitchen unit door. Available in 3 different finishes with various different widths up to 232mm in size, these handles are ideal for modern kitchens, as well as the more traditional shaker kitchens too.</p>
        </ng-container>
        <ng-container *ngIf="category === 'knobs'">
            <p>Our Innova knobs are perfectly suited to doors and drawer fronts. We have classic round knobs, square knobs and sleek traditional knobs to choose from with a choice of 10 different finishes. Whether you have a traditional shaker style door or modern high gloss door, there is a handle here for you!</p>
        </ng-container>
        <ng-container *ngIf="category === 'profile-handles'">
            <p>Our Innova profile handles integrate virtually seamlessly with your doors and drawers, to give a stylish and contemporary look to your kitchen. With a choice of stainless steel or black profile handles in 2 different widths, you can make your kitchen unit doors look sleek and ultra-modern.</p>
        </ng-container>
        <ng-container *ngIf="category === 'cup-and-pull-handles'">
            <p>Our Innova cup & pull handles match perfectly with our drawer fronts to create something visually appealing and practical at the same time. With a choice of 18 different finishes with some very stylish designs, there is a cup handle here to suit any kitchen design.</p>
        </ng-container>
    </header>
</header>
<section class="lightgrey">
    <div class="contained flex-space-between">
        <div>
            <component-filters [items]="items" [filters]="filterFields" [sectionCats]="sectionCats" [section]="category"
                [sectionURL]="'handles'" (filterChange)="updateItems($event)" sortOrder="low-high"></component-filters>
        </div>
        <div class="products" *ngIf="items">
            <div class="banners">
                <div class="panel">
                    <div class="panel-left">
                        <img src="https://static.diy-kitchens.com/assets/images/banner/Listings-Banner_Hendel-And-Hendel.jpg" width="100%" alt="Hendel & Hendel. Statement handles created to be tactile & aesthetic."/>
                    </div>
                    <div class="panel-right">
                        <a routerLink="/customer-reviews/shaker" title="Customer Reviews">
                            <img src="https://static.diy-kitchens.com/assets/images/banner/offer-banner_real-customers_oct2020.jpg" width="100%" alt="Real customer kitchen videos. Take a look."/>
                        </a>
                    </div>
                </div>
            </div>
            <component-product-listing *ngFor="let entry of filteredItems" [type]="type" [route]="route"
                [products]="products" [product]="entry" [showQuantity]="true">
            </component-product-listing>

            <div *ngIf="noResults" class="no-results">
                <em class="common-em">No items were found that match your filters...</em>
                Try removing a filter.
            </div>
        </div>
    </div>
</section>
