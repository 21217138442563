@import '/src/theme/variables';

page-sinks-and-taps-detail {
    .product-details {
        .details {
            .title {
                margin-top: 0;
                margin-bottom: 5px;
                font-size: 21px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 0.1em;
                text-align: left;
            }

            .price {
                > * {
                    text-align: right;
                }
            }
        }

        .image {
            position: relative;
        }
    }

    .housings {
        padding: 30px 0;
        background-color: color('brand-lightgrey');
        text-align: center;
    }

    .products {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .item {
            display: flex;
            flex: 1 1 auto;
            flex-direction: row;
            margin: 15px 0 0 40px;
            box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
            background-color: color('white');

            .left {
                flex: 0 0 auto;
                position: relative;
                padding: 30px;
                border-right: 0.5px solid color('brand_lightgrey');
                width: 380px;

                .image {
                    display: flex;
                    position: relative;
                    flex-basis: 275px;
                    align-items: flex-end;

                    img:not(.promotion-directive-unit-detail-offer-overlay) {
                        display: block;
                        margin: 0 auto;
                        margin-bottom: 32px;
                        max-width: 100%;
                        max-height: 300px;
                        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0*/
                        filter: grayscale(100%);

                        &.flip {
                            transform: scaleX(-1);
                        }
                    }
                }
            }

            @media only screen and (max-width: 1200px) {
                .left {
                    width: 280px;
                }
            }

            .details {
                padding: 30px;
                width: 100%;

                .price {
                    float: right;
                    margin-left: 40px;
                    text-align: right;
                    font-size: 1.2rem;

                    strong {
                        font-size: 1.2rem;
                        font-weight: 600;
                    }

                    p {
                        font-size: 12px;
                        line-height: 19px;
                        text-transform: uppercase;
                    }
                }

                .title {
                    margin-bottom: 5px;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 21px;
                    letter-spacing: 0.03em;
                    text-align: left;
                }

                p {
                    color: color('black');
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 32px;
                    letter-spacing: 0.48px;
                    text-align: left;
                }

                .small {
                    font-size: 12px;
                    line-height: 19px;
                    letter-spacing: 3px;
                    text-align: left;
                    text-transform: uppercase;
                }

                .desc {
                    line-height: 26px;
                }

                .dims {
                    font-weight: 600;
                    line-height: 1.5rem;

                    label {
                        flex: 0 0 auto;
                        margin: 0;
                        width: 80px;
                        color: color('pale-sky');
                        font-weight: 400;
                    }
                }

                .config {
                    display: flex;
                    flex-direction: row;
                    justify-items: flex-start;
                    margin-top: 30px;

                    button {
                        margin: 0 10px 0 0;
                        padding: 8px 15px;
                        border: 1px solid rgba(0, 0, 0, 0.12);
                        border-radius: 20px;
                        background-color: color('white');
                        color: color('black');
                        font-size: 0.8rem;
                        letter-spacing: 0.05rem;
                        cursor: pointer;

                        &.left-hand {
                            margin: -5px -1px 0px 10px;
                            border-radius: 20px 0 0 20px;
                        }

                        &.right-hand {
                            margin: -5px 0 0 0;
                            border-radius: 0 20px 20px 0;
                        }

                        &.right {
                            margin-right: 0;
                            margin-left: auto;
                        }
                    }
                }

                .widths,
                .variants {
                    button {
                        margin: 5px 5px 5px 0;
                        padding: 5px 10px;
                        min-width: 48px;
                        font-weight: 600;
                        letter-spacing: 0.05rem
                    }
                }

                .variants {
                    button {
                        text-align: left;
                    }
                }
            }

            .actions {
                margin-top: 30px;
                text-align: right;

                button {
                    margin: 0 10px;
                    padding: 10px 20px;
                    letter-spacing: 0.05rem;
                }

                button:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .products {
            margin-left: 0;
            max-width: 100%;

            .item {
                flex-wrap: nowrap;
                margin: 15px 0;
                max-width: 100%;

                .details {
                    width: auto;
                    min-width: 300px;
                }
            }
        }
    }

    @media only screen and (max-width: 800px) {
        .products {
            .item {
                flex-wrap: wrap;
                justify-content: center;
                margin: 15px 0;

                .left {
                    padding: 20px 20px 0;
                    border: 0;
                    text-align: center;

                    .image {
                        img {
                            margin-bottom: 0;
                        }
                    }
                }

                .details {
                    width: 100%;

                    .price {
                        strong {
                            font-size: 1.2rem;
                        }
                    }
                }

                .actions {
                    button {
                        padding: 10px 20px;
                    }
                }
            }
        }
    }

    .description-block {
        padding: 1.5rem 0;
        border-top: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
        margin-bottom: 2rem;
    }

    .desc-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 0 1.875rem;

        @include responsive-breakpoint-down(lg) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        .desc-grid-item {
            label {
                margin-right: 10px;
                font-family: font('opensans');
                font-size: 15px;
                font-weight: 700;
                color: rgba(0, 18, 36, 0.9);
                line-height: 1.618rem;
                letter-spacing: 0;
                white-space: normal;
                word-wrap: break-word;
                width: 50%;

                @include responsive-breakpoint-down(md) {
                    line-height: normal;
                }
            }

            span {
                font-family: font('opensans');
                font-size: 15px;
                font-weight: 400;
                color: rgba(0, 18, 36, 0.9);
                line-height: 1.618rem;
                letter-spacing: 0;
                white-space: normal;
                word-wrap: break-word;
                width: 50%;
            }

            .item-specification {
                margin: 3rem 0 0;

                @include responsive-breakpoint-down(md) {
                    margin: 2.5rem 0 0;
                }

                h3 {
                    margin-bottom: 2rem;

                    @include responsive-breakpoint-down(md) {
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        h3 {
            font-family: font('opensans');
            font-size: 16px;
            font-weight: 600;
            letter-spacing: -0.05rem;
            line-height: clamp(1.333rem, 1vw + 1.333rem, 1.777rem);
            color: rgba(0, 18, 36, 0.9);
            text-transform: uppercase;
            margin: 0 0 10px;

            &.desc-sec-title {
                margin-bottom: 1rem;
            }
        }

        p {
            font-family: font('opensans');
            font-size: 16px;
            font-weight: 300;
            color: rgba(0, 18, 36, 0.9);
            line-height: clamp(1.618rem, 1vw + 1.618rem, 1.618rem);
            margin-bottom: 1.333rem;
            letter-spacing: 0;

            strong {
                font-weight: 500;
                color: #878787;
            }
        }
    }
}
