<header class="content-contained">
    <h1>Showroom</h1>
    <p>Our fantastic, new showroom is now open. This state-of-the-art facility is the largest kitchen showroom in the UK and is the perfect place for you to view 60 displays, designed to give you all the inspiration you need to design your perfect kitchen.</p>
</header>

<hr class="narrow" />

<section class="content-contained">
    <div class="centered-block">
        <h2 class="playfair">What to expect</h2>
        <p>There really is no better way to appreciate the qualities of our kitchens than seeing them yourself in our 25,000 sq ft, two-storey, purpose-built showroom. There's no need to make an appointment and our expert team are on hand to offer you free advice and answer any technical queries*. We also provide free refreshments and free Wifi to help make your visit more convenient, and we're dog friendly too!</p>

        <h3>New Oxford Showroom Now Open</h3>
        <p>We are delighted to announce that our NEW Oxford showroom, also featuring over 60 displays, is now open and can be located at:</p>
        <p class="showroom-address">
            <strong>DIY Kitchens Showroom</strong><br />
            <strong>9 Witan Park, Avenue Two</strong><br />
            <strong>Witney, Oxford</strong><br />
            <strong>OX28 4FH</strong>
        </p>
        <p><strong>Open from 9am - 7pm Monday - Sunday</strong></p>

        <p>
            <i>
                <small>*Please note that we do not offer a design service within our showroom, however our knowledgeable staff will answer any of your technical questions.
                    Please <a href="https://planner.diy-kitchens.com" target="_blank" title="Start your plan">click here</a> for advice about planning and designing your new kitchen.
                </small>
            </i>
        </p>
    </div>

    <div class="horizontal-scroller">
        <div class="horizontal-scroller-block">
            <img src="https://static.diy-kitchens.com/assets/images/showroom/new_showroom.jpg" width="100%" alt="Kitchen Showroom"/>
            <h3>UK's largest showroom</h3>
            <p>Keep enough time free to explore the UK's largest kitchen showrooms, located in South Kirkby & Oxford - we guarantee you won't be disappointed.</p>
        </div>

        <div class="horizontal-scroller-block">
            <img src="https://static.diy-kitchens.com/assets/images/showroom/60_kitchens.jpg" width="100%" alt="Beautiful modern kitchen"/>
            <h3>{{diy.ranges}} beautiful kitchens</h3>
            <p>From the latest in modern kitchen design, to traditional cosiness, we've got a kitchen to suit your style.</p>
        </div>
        <div class="horizontal-scroller-block">
            <img src="https://static.diy-kitchens.com/assets/images/showroom/free_expert_advice.jpg" width="100%" alt="Expert advice"/>
            <h3>Free expert advice</h3>
            <p>No one knows kitchens like our showroom team. They're on hand to answer any of your questions and to help guide you, as you create your dream kitchen.</p>
        </div>
        <div class="horizontal-scroller-block">
            <img src="https://static.diy-kitchens.com/assets/images/showroom/Free-Refreshments.jpg" width="100%" alt="Free refreshments"/>
            <h3>Free refreshments</h3>
            <p>Enjoy the relaxed atmosphere of our Cafe area, with free hot and cold drinks.</p>
        </div>
        <div class="horizontal-scroller-block">
            <img src="https://static.diy-kitchens.com/assets/images/showroom/Free-Wifi.jpg" width="100%" alt="Free wifi"/>
            <h3>Free WIFI</h3>
            <p>Whether you want to check the latest sports scores or check our prices against other retailers, our free WIFI is available to all visitors.</p>
        </div>
    </div>
</section>

<hr class="narrow" />

<section class="content-contained">
    <div class="centered-block">
        <h2 class="playfair">Try our 3D tour</h2>
        <p>If you don't have the time to visit us in person, you can still view our stunning new showroom using our 3D tour below.</p>
    </div>

    <div class="showroom-tour" (click)="openShowroomTour()">
        <!-- <img class="showroom-icon" src="https://static.diy-kitchens.com/assets/images/showroom/icon_3d-tour.svg" alt="3D Tour"/> -->
        <!-- <img src="https://static.diy-kitchens.com/assets/images/showroom/showroom_3d-tour.jpg" width="100%" alt="3D Showroom Tour"/> -->
        <img src="https://static.diy-kitchens.com/assets/images/showroom/tour_placeholder.jpg" width="100%" alt="3D Showroom Tour"/>
    </div>
</section>

<hr class="narrow" />

<section class="content-contained">
    <div class="centered-block">
        <h2 class="playfair">How to find us</h2>
        <p>Our purpose built kitchen showrooms are located in the heart of West Yorkshire, just 5 miles from the A1 and in Witney, Oxford. Whilst our showrooms are only in two locations, we will deliver your beautiful new kitchen to anywhere in mainland UK.</p>
    </div>

    <div class="panel">
        <div class="panel-left">
            <h4>West Yorkshire Showroom</h4>
            <p>
                25 Lidgate Crescent,<br />
                Langthwaite Grange Business Park,<br />
                South Kirkby, West Yorkshire,<br />
                WF9 3NR
            </p>
            <button mat-flat-button color="primary" (click)="directions('DIY+Kitchens,+26A+Lidgate+Cres,+South+Kirkby,+Pontefract+WF9+3NR')">Directions</button>
        </div>
        <div class="panel-right">
            <iframe src="https://maps.google.co.uk/maps?q=diy+kitchens&amp;hl=en&amp;sll=53.741446,-1.686054&amp;sspn=0.761035,2.113495&amp;hq=diy+kitchens&amp;t=m&amp;ie=UTF8&amp;hnear=&amp;cid=4887510453065662843&amp;ll=53.722717,-1.403503&amp;spn=0.365656,0.95993&amp;z=10&amp;iwloc=A&amp;output=embed" width="100%" height="318px" frameborder="0" scrolling="no"></iframe>
        </div>
    </div>

    <div class="panel">
        <div class="panel-left">
            <h4>Oxford Showroom</h4>
            <p>
                9 Witan Park,<br />
                Avenue Two,<br />
                Witney, Oxford,<br />
                OX28 4FH
            </p>
            <button mat-flat-button color="primary" (click)="directions('DIY+Kitchens,Unit+9,+Witan+Park,+Ave+Two,+Witney,+OX28+4FH')">Directions</button>
        </div>
        <div class="panel-right">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2468.6569686220173!2d-1.4862630219637054!3d51.7758779718758!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876cd49de56918f%3A0xb6b00702b3db54df!2sDIY%20Kitchens%20Showroom!5e0!3m2!1sen!2suk!4v1733830533570!5m2!1sen!2suk" width="100%" height="318px" frameborder="0" scrolling="no"></iframe>
        </div>
    </div>

    <div class="showroom-contact">
        <h4>Telephone</h4>
        <p>01977 608 418</p>

        <h4>Opening hours</h4>
        <p>Monday to Sunday : 9am to 7pm</p>

        <h4>Open as usual over the bank holiday weekend</h4>

        <div class="actions">
            <a mat-flat-button color="primary" routerLink="/support" title="Support">Talk to us</a>
        </div>
    </div>
</section>

<component-latest-offers></component-latest-offers>
