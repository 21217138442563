<div class="loader" *ngIf="loading">
    <mat-spinner></mat-spinner>

    <div class="loader-message">Loading Information...</div>
</div>

<div class="selected-delivery-option" *ngIf="!loading">
    <div class="selected-delivery-header">
        <div class="selected-delivery-title">
            Delivery details:
            <span *ngIf="selectedDelivery">
                ({{ selectedDelivery.level }} - {{ selectedDelivery.cost ? (selectedDelivery.cost | currencyFormat) : 'Free' }})
            </span>
        </div>
    </div>
    <div class="selected-delivery-option-content">
        <mat-accordion *ngIf="deliveryTypes && deliveryTypes.length">
            <mat-expansion-panel class="checkout-delivery-panel" *ngFor="let deliveryType of deliveryTypes; let i = index;" [expanded]="selectedIndex === i">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="delivery-name">{{ deliveryType.name }}</span>
                    </mat-panel-title>
                    <mat-panel-description>
                        <span class="delivery-title">{{ deliveryType.title }}</span>
                        <span class="delivery-cost">{{ deliveryType.cost ? (deliveryType.cost | currencyFormat) : 'Free'}}</span>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="delivery-panel">
                    <div class="panel-left">
                        <p class="text-lg">{{ deliveryType.description }}</p>

                        <ng-container *ngIf="deliveryType.altCostWording">
                            <div class="delivery-cost-alt" *ngIf="!deliveryType.cost">{{ deliveryType.altCostWording }}</div>
                        </ng-container>
                        <ng-container *ngIf="!deliveryType.altCostWording">
                            <h1 class="delivery-cost" *ngIf="deliveryType.cost">
                                {{deliveryType.cost | currencyFormat}} <span class="delivery-cost-vat">+ VAT</span>
                            </h1>
                            <h1 class="delivery-cost" *ngIf="!deliveryType.cost">Free</h1>
                        </ng-container>

                        <div class="select-del" *ngIf="deliveryType.deliveryOptions === 'week' || deliveryType.deliveryOptions === 'day'">
                            <p class="select-del-label" *ngIf="deliveryType.deliveryOptions === 'week'">
                                Select a {{deliveryType.level === 'collection' ? 'collection' : 'delivery'}} week
                            </p>
                            <p class="select-del-label" *ngIf="deliveryType.deliveryOptions === 'day'">
                                Select a {{deliveryType.level === 'collection' ? 'collection' : 'delivery'}} day
                            </p>

                            <mat-form-field *ngIf="!selectDateLater[i] && weekCommencing" (click)="picker.open()">
                                <input matInput [(ngModel)]="selectedDates[i]" [matDatepicker]="picker"
                                    [matDatepickerFilter]="deliveryType.deliveryOptions === 'week' ? weekCommencingFilter : anyDayFilter"
                                    placeholder="Choose a date" disabled />
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker disabled="false"></mat-datepicker>
                            </mat-form-field>

                            <div class="delivery-later">
                                <mat-checkbox [(ngModel)]="selectDateLater[i]">
                                    Choose {{deliveryType.level === 'collection' ? 'collection' : 'delivery'}} week at a later time
                                </mat-checkbox>
                                <p class="error-message" *ngIf="selectDateLater[i]">Please note that if you have not specified a delivery week, your kitchen will not go into production until this has been confirmed.</p>
                            </div>
                        </div>
                        <div class="select-del" *ngIf="deliveryType.deliveryOptions !== 'week' && deliveryType.deliveryOptions !== 'day' && deliveryType.deliveryOptions !== 'none'">
                            <p class="select-del-label">
                                {{ deliveryType.deliveryOptions }}
                            </p>
                        </div>
                        <!-- <div *ngIf="deliveryType.level === 'standard'">
                            <h3>Delivery Note</h3>
                            <mat-form-field class="delivery-note">
                                <textarea matInput matTextareaAutosize matAutosizeMinRows="6" matAutosizeMaxRows="6" [(ngModel)]="deliveryNote"
                                placeholder="Note" required class='note-text-area'></textarea>
                            </mat-form-field>
                        </div> -->
                        <div>
                            <button mat-flat-button color="primary" class="btn-confirm-delivery"
                                [disabled]="!isDeliveryDateValid(i)" (click)="selectDeliveryOption(i)">
                                CONFIRM DELIVERY TYPE
                                <mat-spinner *ngIf="updatingSurcharge" diameter="25" color="accent"></mat-spinner>
                            </button>
                        </div>
                    </div>
                    <div class="panel-right">
                        <h3>GOOD TO KNOW</h3>

                        <ul class="del-rule-list" *ngIf="deliveryType.points && deliveryType.points.length">
                            <li *ngFor="let point of deliveryType.points">
                                {{ point }}
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
