import { Injectable, StateKey, TransferState, makeStateKey } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CustomTransferStateService extends TransferState {
    constructor() {
        super();
    }

    get<T>(key: StateKey<T>, defaultValue: T): T {
        return super.get<T>(key, defaultValue);
    }
    /**
     * Set the value corresponding to a key.
     */
    set<T>(key: StateKey<T>, value: T): void {
        // Deliberately exclude .json files, such as catalogue.json as these bulk out the prerender.
        const valAsString = JSON.stringify(value);
        if (!valAsString.includes('.json')) {
            super.set<T>(key, value);
        }
    }
    /**
     * Remove a key from the store.
     */
    remove<T>(key: StateKey<T>): void {
        super.remove<T>(key);
    }
    /**
     * Test whether a key exists in the store.
     */
    hasKey<T>(key: StateKey<T>): boolean {
        return super.hasKey<T>(key);
    }
    /**
     * Indicates whether the state is empty.
     */
    get isEmpty(): boolean {
        return super.isEmpty;
    }
    /**
     * Register a callback to provide the value for a key when `toJson` is called.
     */
    onSerialize<T>(key: StateKey<T>, callback: () => T): void {
        super.onSerialize<T>(key, callback);
    }
    /**
     * Serialize the current state of the store to JSON.
     */
    toJson(): string {
        return super.toJson();
    }
}
