import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Output, EventEmitter } from '@angular/core';
import { FormValidators } from '@app/utilities/validators';

import { AuthService, AuthCustomerService } from '@app/services/auth';
import { CustomerRegistrationNoPassword } from '@app/services/auth/models';

@Component({
    selector: 'component-create-customer',
    templateUrl: './create-customer.component.html',
    styleUrls: ['./create-customer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CreateCustomerComponent {
    @Output() customerCreatedEvent = new EventEmitter<any>();

    public authenticating: boolean = false;
    public errorMessage: string;

    public registrationFormGroup: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private authCustomerService: AuthCustomerService
    ) {
        this.registrationFormGroup = this.formBuilder.group({
            email: new FormControl('', [Validators.required, FormValidators.email]),
            firstName: new FormControl('', [Validators.required]),
            lastName: new FormControl('', [Validators.required]),
            contactNumber: new FormControl('', [FormValidators.phoneNumber])
        });
    }

    public authenticate() {
        this.errorMessage = null;

        if (!this.authenticating) {
            if (this.registrationFormGroup.valid) {
                this.authenticating = true;

                let params: CustomerRegistrationNoPassword = {
                    // TODO: get custom form group interface working
                    email: this.registrationFormGroup.controls.email['cleanValue'],
                    firstName: this.registrationFormGroup.controls.firstName.value,
                    lastName: this.registrationFormGroup.controls.lastName.value,
                    // TODO: get custom form group interface working
                    contactNumber: this.registrationFormGroup.controls.contactNumber['cleanValue']
                };
                this.authCustomerService.registerWithNoPassword(params)
                    .then((customerId) => {
                        this.customerCreatedEvent.emit({
                            id: customerId,
                            email: params.email
                        });
                        this.authenticating = false;
                    })
                    .catch((error) => {
                        this.errorMessage = (error && error.body && error.body.message) ? error.body.message : 'Something went wrong.';
                        this.authenticating = false;
                    });

            } else {
                this.errorMessage = 'Please enter all required fields';
            }
        }
    }

    public loginDialog() {
        this.authService.login();
    }
}
