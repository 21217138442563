import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild, ElementRef, NgZone, Renderer2, Signal, AfterViewChecked } from '@angular/core';
import { Subscription } from 'rxjs';

import { Config } from '@app/config';
import { SignalHelper, StringHelper } from '@app/utilities/helpers';

import { AuthCustomerService, AuthService } from '@app/services/auth';
import { BasketService } from '@app/services/basket';
import { CatalogueService } from '@app/services/catalogue';
import { ActiveRange } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute } from '@app/services/navigation/models';

import { IMegaMenu, IMegaMenuItem, IMegaMenuSubCategory, MegaMenuCategories, MegaMenuTemplates } from '@app/shared/components/mega-menu/models';
import { mockData } from '../mega-menu/mock-data';

@Component({
    selector: 'component-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewChecked {
    @ViewChild('searchInputMobile') searchInputMobile: ElementRef;

    public get isAuthenticated(): boolean {
        return (this.viewLoaded) ? this.authService.isAuthenticated : false;
    }

    public get universalUser() {
        return this.authCustomerService.universalUser;
    }

    private activeRangeSubscription;

    public activeRange: Signal<ActiveRange>;
    public activeRangeDesc: string;
    public activeRangeLoaded = false;
    public menu = [];
    public _showMenu = false;
    public subMenu = '';

    public includeVat: boolean = false;

    public section: string = null;
    public subsection: string = null;
    public showBreadcrumb = false;
    public showVatToggle = false;

    public balance = 0;
    public cutoffTimer = '';

    public searchText = '';
    public showMobileSearchBanner: boolean = false;

    public MegaMenuCategories = MegaMenuCategories;
    public megaMenuCategory: MegaMenuCategories = null;
    public megaMenuMockData: IMegaMenu = mockData;
    public mobileMenuLevel: 'category' | 'subCategory' | 'end' = 'category';
    public selectedCategory: string;
    public selectedSubCategory: string;
    public MegaMenuTemplates = MegaMenuTemplates;

    private viewLoaded: boolean = false;

    private routeSubscription: Subscription;

    constructor(
        public config: Config,
        private authService: AuthService,
        private authCustomerService: AuthCustomerService,
        private basketService: BasketService,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private ngZone: NgZone,
        private renderer: Renderer2,
    ) {
        this.activeRange = SignalHelper.toSignal<ActiveRange>(this.catalogueService.activeRange$, { initialValue: null });

        this.basketService.balance$.subscribe((cost) => (this.balance = cost));
    }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                // Set section to highlight button
                this.section = route.data.section;
                this.subsection = route.data.subsection;
                this.showBreadcrumb = (route.data.showBreadcrumb === false) ? false : true;
                this.showVatToggle = route.data.vatToggle ? true : false;

                if (this.section === 'about') {
                    this.setSubMenu('about');
                } else if (this.section === 'buy') {
                    this.setSubMenu('buy');
                } else {
                    this.setSubMenu('');
                }

                this.closeSearch();
            }
        );
    }

    ngAfterViewChecked() {
        this.viewLoaded = true;
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }

        if (this.activeRangeSubscription) {
            this.activeRangeSubscription.unsubscribe();
            this.activeRangeSubscription = null;
        }
    }

    public selectRange() {
        this.catalogueService.openRangeSelector();
    }

    public showMenu() {
        this.mobileMenuLevel = 'category';
        this._showMenu = true;
        // this.renderer.addClass(document.body, 'no-scroll');
    }

    public hideMenu() {
        this._showMenu = false;
        // this.renderer.removeClass(document.body, 'no-scroll');
        this.closeSearch();
    }

    public toggleMenu(event: Event) {
        event.preventDefault();
        if (this._showMenu) {
            this.hideMenu();
        } else {
            this.showMenu();
        }
        this.closeSearch();
    }

    public toggleVat() {
        this.config.settings.includeVat = !this.config.settings.includeVat;
        this.config.saveSettings();
    }

    public closeSearch() {
        this.searchText = '';
    }

    public mainMenu() {
        this.subMenu = '';
    }

    public buyMenu(event: Event) {
        event.preventDefault();
        this.subMenu = 'buy';
    }

    public aboutMenu(event: Event) {
        event.preventDefault();
        this.subMenu = 'about';
    }

    public setSubMenu(text: string) {
        this.subMenu = text;
    }

    public login(event: Event) {
        event.preventDefault();
        this.hideMenu();

        this.authService.login();
    }

    public carcaseSwatch() {
        return 'swatch-' + StringHelper.spaceToDash(this.activeRange()?.carcaseColour || '');
    }

    public logout(event: Event) {
        event.preventDefault();
        this.hideMenu();

        this.authCustomerService.logout()
            .then(() => {
                this.basketService.clearAll();
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public openMegaMenu(megaMenuCategory: MegaMenuCategories, event) {
        if (event) {
            event.preventDefault();
        }

        if (this.megaMenuCategory === megaMenuCategory) {
            this.megaMenuCategory = null;
        } else {
            this.megaMenuCategory = megaMenuCategory;
        }
    }

    public closeMegaMenu() {
        this.megaMenuCategory = null;
    }

    public searchToggle(): void {
        this.showMobileSearchBanner = !this.showMobileSearchBanner;
        if (this.showMobileSearchBanner) {
            setTimeout(() => this.searchInputMobile.nativeElement.focus(), 0);
            this.hideMenu();
        } else {
            this.closeSearch();
        }
    }

    public getMobileCategoryKeys(): string[] {
        return this.megaMenuMockData ? Object.keys(this.megaMenuMockData) : [];
    }

    public getMobileSubCategories(): IMegaMenuSubCategory[] {
        if (this.megaMenuMockData &&
            this.megaMenuMockData[this.selectedCategory] &&
            Array.isArray(this.megaMenuMockData[this.selectedCategory].subCategories)
        ) {
            return this.megaMenuMockData[this.selectedCategory].subCategories;
        }

        return [];
    }

    public getMobileSubCategoryItems(): IMegaMenuItem[] {
        if (this.megaMenuMockData &&
            this.selectedCategory &&
            this.megaMenuMockData[this.selectedCategory]) {
            if (this.megaMenuMockData[this.selectedCategory].subCategories &&
                this.selectedSubCategory) {
                const subCategory = this.megaMenuMockData[this.selectedCategory].subCategories.find((subCat) => subCat.name === this.selectedSubCategory);
                if (subCategory) {
                    return subCategory.items;
                }
            } else if (this.megaMenuMockData[this.selectedCategory].items) {
                return this.megaMenuMockData[this.selectedCategory].items;
            }
        }

        return [];
    }

    public nextMenuLevel(key: string): void {
        if (this.mobileMenuLevel === 'category') {
            this.selectedCategory = key;
            if (this.megaMenuMockData &&
                this.megaMenuMockData[key] &&
                !Array.isArray(this.megaMenuMockData[key].subCategories)
            ) {
                this.mobileMenuLevel = 'end';
                this.selectedSubCategory = this.selectedCategory;
                return;
            }
        } else if (this.mobileMenuLevel === 'subCategory') {
            this.selectedSubCategory = key;
        }

        this.mobileMenuLevel = (this.mobileMenuLevel === 'category')
            ? this.mobileMenuLevel = 'subCategory'
            : (this.mobileMenuLevel === 'subCategory')
                ? this.mobileMenuLevel = 'end'
                : this.mobileMenuLevel = 'category';
    }

    public previousMenuLevel(): void {
        this.mobileMenuLevel = (this.mobileMenuLevel === 'end')
            ? this.mobileMenuLevel = 'subCategory'
            : (this.mobileMenuLevel === 'subCategory')
                ? this.mobileMenuLevel = 'category'
                : this.mobileMenuLevel = 'category';

        if (this.mobileMenuLevel === 'subCategory' &&
            this.megaMenuMockData &&
            !Array.isArray(this.megaMenuMockData[this.selectedCategory].subCategories)
        ) {
            this.mobileMenuLevel = 'category';
        }
    }
}
