function toPoundsAndPence(num: number) {
    return Math.round(num * 100) / 100;
}

function toPoundsAndPenceFloored(num: number) {
    return Math.floor(num * 100) / 100;
}

export const NumberHelper = {
    toPoundsAndPence,
    toPoundsAndPenceFloored
};
