<section class="contained" *ngIf="item">
    <div class="product-details">
        <div class="left">
            <div class="image" [promotion]="'accessories-detail'" [request]="'product-overlay-image'" [brand]="item.brand" [productCode]="item.code" [item]="item" [type]="ProductType.ACCESSORIES">
                <component-product-image-gallery [images]="productImages"></component-product-image-gallery>
            </div>
        </div>
        <div class="details">
            <img *ngIf="item.brand" [src]="item?.media?.brandImage || (item.brandLogo + '_logo.gif')" class="brand-logo" [alt]="item.brand + 'logo'"/>
            <h1 class="title">
                {{item.desc}}
            </h1>
            <p class="code">{{item.product_code}}</p>
            <p>{{item.detail}}</p>
            <ng-container *ngIf="this.item.extended_details?.details.length">
                <p *ngFor="let description of item.extended_details.details"
                [ngClass]="{'sub-title': description === description.toUpperCase()}"
                >{{description}}</p>
            </ng-container>
            <table>
                <tbody>
                    @if (item.width > 0) {
                        <tr>
                            <th>Width:</th>
                            <td>{{item.width}}mm</td>
                        </tr>
                    }
                    @if (item.height > 0) {
                        <tr>
                            <th>Depth:</th>
                            <td>{{item.height}}mm</td>
                        </tr>
                    }
                    @if (item.depth > 0) {
                        <tr>
                            <th>Thickness:</th>
                            <td>{{item.depth}}mm</td>
                        </tr>
                    }
                    @if (isCarcaseColoured() && item.carcaseColour) {
                        <tr>
                            <th>Colour:</th>
                            <td>{{ item.carcaseColour }}</td>
                        </tr>
                    }
                </tbody>
            </table>

            @if (isCarcaseColoured() && this.carcaseColours) {
                <div class="colour-swatches">
                    @for (colour of this.carcaseColours; track colour) {
                        <div [class]="'swatch swatch-' + colour.css_class" [ngClass]="{'selected': item.carcaseColour === colour.colour}" 
                        [matTooltip]="colour.colour" (click)="onColourSwatchClick(colour)"></div>
                    }
                </div>
            }

            <div class="price" [promotion]="'accessories-detail'" [request]="'product-price'" [brand]="item.brand" [productCode]="item.code" [item]="item" [type]="ProductType.ACCESSORIES">
                @if (this.item?.calculatedPrice) {
                    <strong *ngIf="item.price" [innerHTML]="item.price | currencyFormat:true:true"></strong><span> per m&#178;</span>
                } @else {
                    <strong *ngIf="item.price" [innerHTML]="item.price | currencyFormat:true:true"></strong>
                    <p class="small" *ngIf="!item.price">Currently unavailable</p>
                }
            </div>
            <div class="flex-space-between">
                @if (this.item?.calculatedPrice) {
                    <div class="common-quantity"></div>
                    <button mat-flat-button color="primary" (click)="openDimensionsInputDialog()" [disabled]="!this.item.price">Choose Size</button>
                } @else {
                    <div class="common-quantity">
                        <label>Quantity:</label>
                        <span>
                            <input type="number" min="1" placeholder="Qty" [(ngModel)]="item.qty">
                        </span>
                    </div>
                    <component-basket-add-button [activeRange]="activeRange()" [type]="type" [item]="item"
                        [qty]="item.qty" [disabled]="isCarcaseColoured() && !item.carcaseColour">
                    </component-basket-add-button>
                }
            </div>
        </div>
    </div>

    <div class="description-block" *ngIf="item && item.extended_details?.specifications?.length">
        <div class="desc-grid">
            <div class="desc-grid-item">
                <h3 class="desc-sec-title">Description</h3>
                <p>{{item.detail}}</p>
                <div class="item-specification">
                    <h3>Specification</h3>
                    <div class="spec-grid">
                        <div *ngFor="let spec of item.extended_details.specifications" class="spec-grid-item">
                            <label>{{spec.name}}</label>
                            <span>{{spec.value}}</span>
                        </div>
                        <div class="spec-grid-item" *ngIf="item.height > 0">
                            <label>Height:</label>
                            <span>{{item.height}}</span>
                        </div>
                        <div class="spec-grid-item" *ngIf="item.width > 0">
                            <label>Width:</label>
                            <span>{{item.width}}</span>
                        </div>
                        <div class="spec-grid-item" *ngIf="item.depth > 0">
                            <label>Depth:</label>
                            <span>{{item.depth}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="desc-grid-item">
                <h3>FOUND A LOWER PRICE? WE'LL MATCH IT!</h3>
                <p>If you find any like for like products cheaper elsewhere we will match it!</p>
                <p>Price matching will hopefully be beneficial to both yourself and to us in the case of appliances for
                    example they will be delivered all in one delivery and we will be able to check that you have the
                    correct unit / appliance door configurations.</p>
                <p>
                    <strong>Please note:</strong>
                    This is at our discretion dependent on retailer.
                </p>
                <button mat-stroked-button class="common-btn-hollow" color="primary"
                    routerLink="/about-diy-kitchens/price-promise">
                    Our price promise
                </button>
            </div>
        </div>

    </div>
</section>
