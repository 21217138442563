import { NgModule } from '@angular/core';

import { PromotionDirective } from './promotion.directive';

@NgModule({
    exports: [
        PromotionDirective
    ],
    declarations: [
        PromotionDirective
    ]
})
export class PromotionDirectiveModule { }
