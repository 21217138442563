import { Pipe, PipeTransform } from '@angular/core';

import { Config } from '@app/config';

@Pipe({
    name: 'currencyFormat',
    pure: false,
    standalone: false
})
export class CurrencyFormatPipe implements PipeTransform {
    constructor(private config: Config) { }

    transform(
        currency: string | number,
        showVat = false,
        includeVat = false,
        onlyVat = false,
        globalSettings = false,
        unit: string = this.config.pipes.currency.unit,
        separator: string = this.config.pipes.currency.separator
    ): string {
        let vatString = null;
        let valueNumber: number = (currency) ? parseFloat((currency + '')) : 0.00;

        if (!showVat && includeVat) {
            if (globalSettings) {
                if (this.config.settings.includeVat) {
                    valueNumber = valueNumber * this.config.payments.vatRate;
                } else {
                    valueNumber = valueNumber;
                }
            } else {
                valueNumber = valueNumber * this.config.payments.vatRate;
            }
        } else if (!showVat && onlyVat) {
            valueNumber = (valueNumber * this.config.payments.vatRate) - valueNumber;
        } else if (includeVat && this.config.settings.includeVat) {
            valueNumber = valueNumber * this.config.payments.vatRate;
        }

        const value = valueNumber.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, separator);

        if (showVat) {
            vatString = (includeVat && this.config.settings.includeVat) ? 'Inc. VAT' : 'Ex. VAT';

            // eslint-disable-next-line max-len
            return `<span class="currency-format"><span class="currency">${unit}${value}</span><span class="currency-vat">${vatString}</span></span>`;
        } else {
            return `${unit}${value}`;
        }
    }
}
