import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { StringHelper } from '@app/utilities/helpers';

import { CatalogueService } from '@app/services/catalogue';
import { ProductType } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute } from '@app/services/navigation/models';
import { CostCalculator } from '@app/services/catalogue/cost-calculator';

@Component({
    selector: 'page-worktop-sample',
    templateUrl: './worktop-sample.page.html',
    styleUrls: ['./worktop-sample.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class WorktopSamplePage implements OnInit, OnDestroy {
    public header: string = '';

    public allProducts: any; // All products of this type (ie All Units or Appliances)
    public category: string; // Category to display ie (Base units or Dishwashers)
    public categoryDisplay: string;
    public sectionCats: any[] = []; // A list of all categories for this type (ie different unit types or appliance types)
    public items: any; // Products in this category - gets sent to the filters component
    public filteredItems: any[] = []; // Filtered products to show as results
    public noResults: boolean;
    public type: ProductType = ProductType.SAMPLE_WORKTOP;

    public filterFields = [];

    private routeSubscription: Subscription;

    private sampleSizes = {
        'quartz': '40mm x 40mm x 10mm',
        'granite': '50mm x 40mm x 30mm',
        'dekton': '40mm x 40mm x 10mm',
        'solid-wood': '148mm x 200mm x 39mm',
    };
    public sampleSize: string = '';

    private costCalculator: CostCalculator = new CostCalculator();

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                const ucFirst = s => s[0] ? s[0].toUpperCase() + s.substr(1) : '';
                let category = route.params.category || '';
                if (category.match(/all/i)) {
                    category = null;
                    this.categoryDisplay = '';
                } else {
                    this.categoryDisplay = ucFirst(category.replace(/-/g, ' '));
                }
                this.category = category;
                this.sampleSize = this.sampleSizes[category];

                this.navigationService.setNavigation({
                    title: 'Kitchen worktop samples',
                    metaTags: [
                        { name: 'no-meta', content: 'no metadata' },
                        { property: 'og:description', content: `See our range of ${this.categoryDisplay || 'Kitchen'} Worktop samples available.` },
                        { name: 'Description', content: `See our range of ${this.categoryDisplay || 'Kitchen'} Worktop samples available.` },
                    ]
                });
                this.navigationService.setRouteHistory([
                    { title: 'Samples', route: '/samples' },
                    { title: (this.categoryDisplay || 'Kitchen') + ' Worktop Samples', route: '/samples/solid-surface-worktop-samples' }
                ]);

                this.allProducts = [];
                this.sectionCats = [];
                this.catalogueService.getWorktops()
                    .then((worktops: any) => {
                        worktops.filter(top => !top.cat.match(/laminate/i))
                            .forEach(top => {
                                let ucCat = ucFirst(top.cat);
                                let exists = this.allProducts.find(wt => wt.cat === ucCat && wt.sub_cat === top.sub_cat);
                                if (!exists) {
                                    this.allProducts.push({
                                        code: `${(StringHelper.spaceToDash(top.sub_cat || '')).toUpperCase()}-SMP`,
                                        category: top.cat,
                                        desc: 'Worktop Sample - ' + ucCat + ' ' + top.sub_cat,
                                        cat: ucCat,
                                        sub_cat: top.sub_cat,
                                        cost: top.cat.toLowerCase() === 'solid wood' ? this.costCalculator.worktopWoodSampleCost : this.costCalculator.worktopSampleCost,
                                        _cost: top.cat.toLowerCase() === 'solid wood' ? this.costCalculator.worktopWoodSampleCost : this.costCalculator.worktopSampleCost,
                                        image: 'worktops/thumbs/' + (top.thumb_img || '').toLowerCase()
                                    });
                                    let catExists = this.sectionCats.filter((opt) => opt.name === ucCat);
                                    if (catExists.length === 0) {
                                        this.sectionCats.push({
                                            name: ucCat,
                                            count: 1
                                        });
                                    } else {
                                        catExists[0].count++;
                                    }
                                }
                            });
                        this.sectionCats.sort((a, b) => b.count - a.count);
                        this.sectionCats.unshift({
                            name: 'All Styles',
                            count: this.allProducts.length
                        });
                        if (this.category) {
                            this.items = this.allProducts.filter((entry) => entry.cat.toLowerCase().replace(/ /g, '-') === this.category);
                        } else {
                            this.items = this.allProducts;
                        }
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            });
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }

    public updateItems(filtered) {
        this.filteredItems = filtered;
        this.noResults = filtered.length === 0;
    }
}
