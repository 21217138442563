@import '/src/theme/variables';

page-about-shop-online {
    .video {
        position: relative;
        cursor: pointer;

        &:hover {
            .mat-icon {
                color: #0056b3;
            }
        }
        
        .mat-icon {
            position: absolute;
            top: calc(50% - 40px);
            left: calc(50% - 40px);
            width: 80px;
            height: 80px;
            color: #007bff;
            font-size: 80px;
        }
    }
}