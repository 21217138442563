import { BehaviorSubject } from 'rxjs';

import { StreamModel, StreamType } from './models';

export class LocalDataStream<T> {
    /** Stream that emits whenever the data has been modified. */
    public dataChange$: BehaviorSubject<StreamModel<T>> = new BehaviorSubject<StreamModel<T>>(null);

    get data(): T[] {
        return (this.dataChange$.value.data || []).slice();
    }

    private streamData: T[];

    constructor() { }

    public load(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            resolve();
        });
    }

    public setData(data: T[]) {
        this.streamData = data;

        this.clearFilter();
    }

    public filterData(filter: Function) {
        let filteredData = this.data.filter((item) => filter(item));

        this.dataChange$.next({
            data: filteredData.slice(),
            length: filteredData.length,
            type: StreamType.LOCAL
        });
    }

    public clearFilter() {
        if (this.streamData) {
            this.dataChange$.next({
                data: this.data.slice(),
                length: this.data.length,
                type: StreamType.LOCAL
            });
        }
    }

    public close() {
        this.dataChange$.complete();
    }
}
