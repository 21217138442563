import { Injectable } from '@angular/core';

import { Config } from '@app/config';

import { HttpApi } from '@app/services/api';
import { HttpApiOptions, HttpApiResponse } from '@app/services/api/models';
import { AuthStaffService } from '@app/services/auth';

import { Article, ArticleResponse } from './models';

@Injectable()
export class ArticlesService {
    constructor(
        protected config: Config,
        protected httpApi: HttpApi,
        protected authStaffService: AuthStaffService
    ) { }

    public list(): Promise<Article[]> {
        return new Promise<Article[]>((resolve, reject) => {
            const url = `${this.config.api.endpoints.articles}/mysql/articles/list`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    ApplicationToken: this.authStaffService.applicationToken,
                    UniversalToken: this.authStaffService.universalToken
                }
            };

            this.httpApi.get<HttpApiResponse<Article[]>>(url, options).subscribe(
                (response) => {
                    if (response.success) {
                        resolve(response.body || []);
                    } else {
                        resolve([]);
                    }
                },
                (error) => reject(error)
            );
        });
    }

    public byTags(tags: string[]): Promise<Article[]> {
        return new Promise<Article[]>((resolve, reject) => {
            let url = `${this.config.api.endpoints.articles}/mysql/articles/by-tags/`;
            url += encodeURI(tags.join(','));
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                }
            };

            this.httpApi.get<HttpApiResponse<Article[]>>(url, options).subscribe(
                (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        resolve(null);
                    }
                },
                (error) => reject(error)
            );
        });
    }

    public byId(id: string): Promise<Article> {
        return new Promise<Article>((resolve, reject) => {
            let url = `${this.config.api.endpoints.articles}/mysql/article/${id}`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                }
            };

            this.httpApi.get<HttpApiResponse<Article>>(url, options).subscribe(
                (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        resolve(null);
                    }
                },
                (error) => reject(error)
            );
        });
    }

    public create(article: Article): Promise<ArticleResponse> {
        return new Promise<ArticleResponse>((resolve, reject) => {
            let url = `${this.config.api.endpoints.articles}/mysql/article`;
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    ApplicationToken: this.authStaffService.applicationToken,
                    UniversalToken: this.authStaffService.universalToken
                }
            };

            this.httpApi.post<HttpApiResponse<ArticleResponse>>(url, article, options).subscribe(
                (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        resolve(null);
                    }
                },
                (error) => reject(error)
            );
        });
    }

    public update(article: Article): Promise<ArticleResponse> {
        return new Promise<ArticleResponse>((resolve, reject) => {
            let url = `${this.config.api.endpoints.articles}/mysql/article/${article.id}`;
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    ApplicationToken: this.authStaffService.applicationToken,
                    UniversalToken: this.authStaffService.universalToken
                }
            };

            this.httpApi.put<HttpApiResponse<ArticleResponse>>(url, article, options).subscribe(
                (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        resolve(null);
                    }
                },
                (error) => reject(error)
            );
        });
    }

    public listTags(): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            let url = `${this.config.api.endpoints.articles}/tags`;
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    ApplicationToken: this.authStaffService.applicationToken,
                    UniversalToken: this.authStaffService.universalToken
                }
            };

            this.httpApi.get<HttpApiResponse<string[]>>(url, options).subscribe(
                (response) => {
                    if (response.success) {
                        resolve(response.body || []);
                    } else {
                        resolve([]);
                    }
                },
                (error) => reject(error)
            );
        });
    }
}
