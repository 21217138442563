<div class="latest-offers">
    <div class="flex-center">
        <div class="flex-item">
            <div class="section-intro-block">
                <h4>Get the latest offers</h4>
                <p>Register for our Newsletter and keep up to date with the latest offers, new products and kitchen
                    trends </p>
            </div>
            <div class="text-center">
                <button class="sign-up brand-primary" mat-flat-button color="primary" (click)="openSignUp()">
                    Signup now
                </button>
            </div>
        </div>
    </div>
</div>