<header class="content-contained">
    <h1>Cookie Policy</h1>
    <p>Our website uses small files called cookies that are placed on your computer, laptop or tablet, in this instance we are referring to (first party functional cookies), to help make your experience of using DIY Kitchens, as pleasant as possible. Our cookies do things like remember your shopping cart ID and the items in your shopping cart and are essential for placing orders on our website.</p>
</header>

<hr class="narrow" />

<section class="content-contained">
    <p>Our website also uses third party cookies to enhance your experience on our site. These cookies include services such as Twitter, Facebook & Google amongst others. These are explained in more detail below.</p>

    <p>Cookies are safe to use and are used on virtually all websites on the internet, in some form or other. If you disable cookies then you may not be able to use all the features on this website or place your order with us. By using our website and accepting the cookie consent message when you first visit our website, you agree to accept the cookies that we use.</p>

    <p>Should you wish to disable cookies in your Internet browser, then the links below will show you how to do this.</p>

    <ul>
        <li>
            <a href="https://support.google.com/mobile/bin/answer.py?hl=en&amp;answer=169022" rel="noreferrer" target="_blank" title="Android">Android</a>
        </li>
        <li>
            <a href="https://support.microsoft.com/kb/196955" rel="noreferrer" target="_blank" title="Internet Explorer">Internet Explorer</a>
        </li>
        <li>
            <a href="https://support.mozilla.org/en-US/kb/Enabling%20and%20disabling%20cookies" rel="noreferrer" target="_blank" title="Firefox">Firefox</a>
        </li>
        <li>
            <a href="https://support.google.com/chrome/bin/answer.py?hl=en-GB&amp;answer=95647" rel="noreferrer" target="_blank" title="Google Chrome">Google Chrome</a>
        </li>
        <li>
            <a href="https://www.opera.com/browser/tutorials/security/privacy" rel="noreferrer" target="_blank" title="Opera">Opera</a>
        </li>
        <li class="foopensans">
            <a href="https://support.apple.com/kb/HT1677" rel="noreferrer" target="_blank" title="Safari iOS">Safari iOS</a>
        </li>
    </ul>

    <p>You can read more about cookies
        <a href="https://www.aboutcookies.org/how-to-control-cookies" rel="noreferrer" target="_blank" title="Read about cookies">here</a>.
    </p>

    <p>All the cookies that are used on DIY Kitchens are documented below.</p>

    <h4>Third Party Cookies</h4>

    <table>
        <tr>
            <td>YouTube</td>
            <td>IDE<br />CONSENT<br />PREF<br />VISITOR_INFO1_LIVE<br />YSC</td>
            <td>
                <p>We embed some YouTube videos on our website and YouTube sets some cookies when a video is clicked. <br />No
                    personally-identifiable information is stored.</p>
            </td>
        </tr>
        <tr>
            <td>Google Analytics</td>
            <td>_utma<br />_utmb<br />_utmc<br />_utmt<br />_utmv<br />_utmz</td>
            <td>
                <p>We use Google Analytics
                    to view visits to our site and the pages that our customers
                    visit. We use this information to help improve our website and give users the right information at the
                    right
                    time. No personal details such as credit card details etc are stored here.</p>
                <p>To stop Google Analytics from tracking you across the web, you can download a
                    browser add-on from the link <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" title="Google Analytics">here</a>
                </p>
            </td>
        </tr>
        <tr>
            <td>Facebook</td>
            <td>fr</td>
            <td>We use Facebook to promote DIY Kitchens through Facebook Ads and use Facebook social media plugins to
                allow you to share content.</td>
        </tr>
        <tr>
            <td>Twitter</td>
            <td></td>
            <td>We use Twitter social
                media plugins to allow you to share content.</td>
        </tr>
        <tr>
            <td>Addthis</td>
            <td>loc<br />mus<br />uid<br />uvc<br />xtc</td>
            <td>
                <p>We use the social media sharing
                    applet Addthis to allow you to share our content on
                    various social media platforms, accessible from within the Addthis sharing button.</p>
            </td>
        </tr>
    </table>
</section>

<component-latest-offers></component-latest-offers>
