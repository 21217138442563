import { Component, ViewEncapsulation } from '@angular/core';

import { NavigationService } from '@app/services/navigation';
import { RouteHistory } from '@app/services/navigation/models';

@Component({
    selector: 'page-plan-online',
    templateUrl: './plan-online.page.html',
    styleUrls: ['./plan-online.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PlanOnlinePage {
    public api: any = {};
    public cdnPath: any = '';
    private routeHistory: RouteHistory[] = [{ title: 'Plan online', route: '/plan-online' }];

    constructor(private navigationService: NavigationService) {
        navigationService.setNavigation({
            title: 'Plan your kitchen online',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { property: 'og:description', content: `Use the DIY Kitchens online planner to create your perfect kitchen.` },
                { name: 'Description', content: `Use the DIY Kitchens online planner to create your perfect kitchen.` },
            ],
            routeHistory: this.routeHistory
        });
    }
}
