import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AuthService } from '@app/services/auth';
import { DialogService } from '@app/services/dialog';

@Component({
    selector: 'component-basket-add-register-first-dialog',
    templateUrl: './register-first.dialog.component.html',
    styleUrls: ['./register-first.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class BasketAddRegisterFirstDialogComponent {
    constructor(
        private authService: AuthService,
        private dialogService: DialogService,
        private dialogRef: MatDialogRef<BasketAddRegisterFirstDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    public login() {
        this.authService.login()
            .then((isAuthed) => {
                if (isAuthed) {
                    this.dialogRef.close(false);
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public saveLater() {
        this.dialogRef.close(true);
    }
}
