import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';
import { PipesModule } from '@app/shared/pipes';

import { ChangeStyleDialogComponent } from './change-style.dialog.component';

@NgModule({
    imports: [
        DesignModule,
        PipesModule
    ],
    declarations: [
        ChangeStyleDialogComponent
    ]
})
export class ChangeStyleDialogModule { }
