@import '/src/theme/variables';

page-unit-listing {
    header {
        display: flex;

        .header-blurb {
            max-width: 800px;
        }
    }

    .content-header-background {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 220px;
    }

    section {
        &.filters {
            padding: 0;

            hr {
                margin-top: 10px;
            }
        }
    }

    .products {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        padding-top: 20px;
        padding-bottom: 20px;
    }

    h3,
    h4 {
        margin: 0;
        padding: 0;
    }

    .no-results {
        background-color: color('white');
        width: 90%;
        padding: 20px;
        margin-top: 0;
    }

    .range-selector {
        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
        align-items: flex-end;
        margin-left: auto;
        padding-left: 15px;
        border-left: 1px solid color('copy-border');

        .active-range {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            width: 200px;

            .cabinet-image {
                position: relative;
                margin-top: 0;
                width: 85px;

                img {
                    display: block;
                    width: 100%;
                }

                .door-image {
                    position: absolute;
                    top: 0px;
                    left: -1px;
                    width: 88px;
                }

                .th-door-image {
                    position: absolute;
                    top: -1px;
                    left: 0px;
                    width: 85px;
                }

                .profile-image {
                    position: absolute;
                    top: 2px;
                    left: 3px;
                }
            }

            .range-details {
                text-align: center;

                .range-text {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 15px;

                    .range-name,
                    .unit-name {
                        font-weight: 400;
                        font-size: 0.875rem;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        .content-header-background {
            min-height: unset;
        }
    }

    @media only screen and (max-width: 760px) {
        header {
            flex-direction: column;
        }

        .range-selector {
            margin: auto;
            border-left: none;
        }
    }
}