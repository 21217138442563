import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { Config } from '@app/config';

@Directive({
    selector: '[map-popup]',
    standalone: false
})
export class MapPopupDirective {
    @Input('map-popup') data: any[];

    private popupDiv;

    constructor(
        private element: ElementRef,
        private config: Config
    ) { }

    private togglePopUp() {
        if (this.config.isBrowser && !this.popupDiv) {
            let html = `<h5>${this.data[0]}</h5>`;
            if (this.data[1] > 0) {
                html += `<span><i>Standard:</i> £${this.data[1]}</span>`;
            }

            // if (this.data[2] > 0) {
            //     html += `<span><i>Gold:</i> £${this.data[2]}</span>`;
            // }

            // if (this.data[3] > 0) {
            //     html += `<span><i>Platinum:</i> £${this.data[3]}</span>`;
            // }

            this.popupDiv = document.createElement('div');
            this.popupDiv.className = 'popup-div';
            this.popupDiv.innerHTML = html;
            this.element.nativeElement.appendChild(this.popupDiv);
        } else {
            this.element.nativeElement.removeChild(this.popupDiv);
            this.popupDiv = null;
        }
    }

    @HostListener('click') onClick() {
        this.togglePopUp();
    }

    @HostListener('mouseenter') onMouseIn() {
        this.togglePopUp();
    }

    @HostListener('mouseleave') onMouseOut() {
        this.togglePopUp();
    }
}
