import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';

import { RegisterComponent } from './register.component';

@NgModule({
    imports: [
        DesignModule
    ],
    exports: [
        RegisterComponent
    ],
    declarations: [
        RegisterComponent
    ]
})
export class RegisterComponentModule { }
