@import '/src/theme/variables';

component-basket-update-button {
    .mat-mdc-unelevated-button {
        .mdc-button__label {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .basket-update-button {
        text-align: center;

        &.basket-updating {
            .label {
                color: color('mako');
            }

            .basket-spinner {
                display: block;
            }
        }

        &.basket-success {
            background-color: color('success');

            .basket-add-success {
                display: inline-block;
            }
        }

        &.basket-failure {
            background-color: color('warning');

            .basket-add-failure {
                display: inline-block;
            }
        }

        .mat-button-wrapper {
            display: flex;
            align-items: center;
        }

        .basket-update-success,
        .basket-update-failure {
            display: none;
        }

        .basket-spinner {
            display: none;
            margin-left: 10px;
        }
    }
}
