import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';

import { AccountRouting } from './account.routing';
import { AccountPage } from './account.page';

import { BasketsPage } from './content/baskets/baskets.page';
import { OrderPage } from './content/order/order.page';
import { OrdersPage } from './content/orders/orders.page';
import { PlaceholderPage } from './content/placeholder/placeholder.page';
import { RemedialPage } from './content/remedial/remedial.page';
import { ResetPasswordPage } from './content/reset-password/reset-password.page';

@NgModule({
    imports: [
        SharedModule,
        AccountRouting
    ],
    declarations: [
        AccountPage,
        BasketsPage,
        OrderPage,
        OrdersPage,
        PlaceholderPage,
        RemedialPage,
        ResetPasswordPage
    ]
})
export class AccountPageModule { }
