import { NgModule } from '@angular/core';

import { CatalogueService } from './catalogue.service';

@NgModule({
    providers: [
        CatalogueService
    ]
})
export class CatalogueServiceModule { }
