import { Injectable, Signal } from '@angular/core';
import { StorageService } from '@app/services/storage';

import { Config } from '@app/config';
import { DateHelper } from '@app/utilities/helpers';

import { HttpApi } from '@app/services/api';
import { HttpApiOptions, HttpApiResponse } from '@app/services/api/models';
import { AuthCustomerService } from '@app/services/auth';
import { DialogService } from '@app/services/dialog';
import { CostCalculator } from '../catalogue/cost-calculator';

import { BannerLocation, IBanner, IPromotionListItem, Promotion } from './models';
import { IPromotionSelectionKeys } from './models/mock-promotion-rules';
import { AllProductsUnion, ICabinet, IDoor } from '../catalogue/models/product.models';
import { ActiveRange, ProductType } from '../catalogue/models';
import { CatalogueService } from '../catalogue';
import { BasketItem } from '../basket/models';
import { IPromotionBasketAction } from './models/promotion.model';

@Injectable()
export class MarketingService {
    private promotionCacheTimeInMinutes: number = 30;
    private promotionCacheKey = 'active-promotions';

    private costCalculator: CostCalculator = new CostCalculator();

    protected debug = false;

    constructor(
        private config: Config,
        private httpApi: HttpApi,
        private authCustomerService: AuthCustomerService,
        private dialogService: DialogService,
        private storageService: StorageService,
        private catalogueService: CatalogueService
    ) { }

    /**
     * An optimised query to return only the promotion headers from the promotions table for the active promotions. Results will first be returned from the local cache.
     * @param ignoreCache false by default
     * @returns IPromotionListItem[] of active promotion headers
     */
    public getActivePromotionHeaders(ignoreCache = false): Promise<IPromotionListItem[]> {
        return new Promise((resolve, reject) => {
            ignoreCache = this.debug ? true : ignoreCache;

            let now = DateHelper.now();
            this.storageService.get(this.promotionCacheKey)
                .then((cachedPromotions) => {
                    if (!ignoreCache && cachedPromotions && cachedPromotions.promotionsCacheTill > now) {
                        resolve(cachedPromotions.promotions);
                    } else {
                        const url = `${this.config.api.endpoints.diy}/diy/marketing/active-promotions`;
                        this.getAPIData<IPromotionListItem[]>(url)
                            .then((promotions) => {
                                this.storageService.set(this.promotionCacheKey, {
                                    promotionsCacheTill: DateHelper.format(
                                        DateHelper.moment().add(this.promotionCacheTimeInMinutes, 'minutes')
                                    ),
                                    promotions: promotions
                                })
                                    .then(() => resolve(promotions || []))
                                    .catch((error) => {
                                        console.error(error);
                                        this.dialogService.error(this.constructor.name, error);
                                    });
                            })
                            .catch((error) => {
                                console.error('getActivePromotionHeaders');
                                reject(error);
                            });
                    }
                })
                .catch((error) => {
                    console.error('getActivePromotionHeaders');
                    reject(error);
                });
        });
    }

    /**
     * @param ignoreCache false by default
     * @returns An array of fully instantiated Promotion class
     * @todo Needs fixing. Testing revealed bug with multiple promotions sharing the same SaleCard image, colour and button (text did change)
     */
    // public getActiveFullPromotions(ignoreCache = false): Promise<Promotion[]> {
    //     return new Promise((resolve, reject) => {
    //         let now = DateHelper.now();
    //         const cacheKey = `${this.promotionCacheKey}-all`;
    //         this.storageService
    //             .get(cacheKey)
    //             .then((cachedPromotionData) => {
    //                 if (!ignoreCache && cachedPromotionData && cachedPromotionData.promotionsCacheTill > now) {
    //                     const fullPromotions = cachedPromotionData.promotion.map((promotion) => new Promotion(promotion));
    //                     resolve(fullPromotions);
    //                 } else {
    //                     const url = `${this.config.api.endpoints.diy}/diy/marketing/all-promotions`;
    //                     this.getAPIData<Promotion[]>(url)
    //                         .then((promotionData) => {
    //                             const fullPromotions = promotionData.map((promotion) => new Promotion(promotion));
    //                             this.storageService
    //                                 .set(cacheKey, {
    //                                     promotionsCacheTill: DateHelper.format(
    //                                         DateHelper.moment().add(this.promotionCacheTimeInMinutes, 'minutes')
    //                                     ),
    //                                     promotion: promotionData
    //                                 })
    //                                 .then(() => resolve(fullPromotions))
    //                                 .catch((error) => this.dialogService.error(this.constructor.name, error));
    //                         })
    //                         .catch((error) => reject(error));
    //                 }
    //             })
    //             .catch((error) => reject(error));
    //     });
    // }

    /**
     * @param ignoreCache false by default
     * @returns An array of fully instantiated Promotion class
     * @deprecated Once the other version has been fixed
     */
    public getActiveFullPromotions(ignoreCache = false): Promise<Promotion[]> {
        return new Promise((resolve, reject) => {
            this.getActivePromotionHeaders(ignoreCache)
                .then((promotionHeaders) => {
                    const promotionPromises = promotionHeaders.map((header) =>
                        this.getPromotion(header.id, ignoreCache)
                    );

                    return Promise.all(promotionPromises);
                })
                .then((promotions) => {
                    resolve(promotions);
                })
                .catch((error) => {
                    console.error('getActiveFullPromotions');
                    reject(error);
                });
        });
    }

    /**
     * Filters the active promotions to those that apply to a given page, range, colour, etc. The selectionKeys are applied in a logical AND
     * and so the more keys provided the higher the constraints, fewer keys therefore results in fewer constraits. Partial matches are not returned.
     * @param selectionKeys Slugs that identify page, range, colour, etc.
     * @returns
     */
    public getApplicablePromotionHeaders(selectionKeys: IPromotionSelectionKeys): Promise<IPromotionListItem[]> {
        return new Promise((resolve, reject) => {
            const queries = [];
            const keys = [];

            if (selectionKeys.page) {
                queries.push(this.getPromotionHeadersByPage(selectionKeys.page));
                keys.push('page');
            }
            if (selectionKeys.range) {
                queries.push(this.getPromotionHeadersByRange(selectionKeys.range));
                keys.push('range');
            }
            if (selectionKeys.colour) {
                queries.push(this.getPromotionHeadersByKitchenColour(selectionKeys.colour));
                keys.push('colour');
            }
            if (selectionKeys.brand) {
                queries.push(this.getPromotionHeadersByBrand(selectionKeys.brand));
                keys.push('brand');
            }
            if (selectionKeys.productCode) {
                queries.push(this.getPromotionHeadersByProductCode(selectionKeys.productCode));
                keys.push('productCode');
            }
            if (selectionKeys.productType) {
                queries.push(this.getPromotionHeadersByProductType(selectionKeys.productType));
                keys.push('productType');
            }

            Promise.all(queries)
                .then((results) => {
                    const resolvedPromotions = this.promotionHeadersReturnedByAllKeys(results, keys);
                    this.removeApplicablePromotionExceptions(selectionKeys, resolvedPromotions)
                        .then((promotionsWithoutExceptions) => {
                            resolve(promotionsWithoutExceptions);
                        })
                })
                .catch((error) => reject(error));
        });
    }

    /**
     * Fetches the full promotions for the provided promotion headers and then filters them based on the 'brandName' conditional field
     * @param headers Applicable promotion headers, i.e. the output of getApplicablePromotionHeaders()
     * @param brand 
     * @returns promotion headers of the promotions that match the brand in their conditional field
     * @deprecated No longer necessary since the headers now contain the promotion 'rules'??
     */
    public filterPromotionHeadersByBrand(headers: IPromotionListItem[], brand: string): Promise<IPromotionListItem[]> {
        return new Promise((resolve, reject) => {
            let filteredHeaders: IPromotionListItem[] = [];
            if (!Array.isArray(headers) || !headers.length) {
                return resolve([]);
            }
            const fullPromotionPromises: Promise<Promotion>[] = headers.map((header) => this.getPromotion(header.id));
            Promise.all(fullPromotionPromises)
                .then((fullPromotions) => {
                    if (!Array.isArray(fullPromotions)) {
                        return reject(`getPromotion() returned invalid response(s) for filterPromotionHeadersByBrand() ${brand}`);
                    }
                    filteredHeaders = fullPromotions.map((promotion) => {
                        const brandField = promotion.conditionalFields.find((field) => field.name === 'brandName');
                        if (brandField && brandField?.value === brand) {
                            return headers.find((header) => header.id === promotion.id);
                        }
                        return null;
                    }).filter(header => header !== null);
                    resolve(filteredHeaders);
                })
                .catch((error) => reject(error))
        });
    }

    /**
     * Given a list of promotion headers and a list of keys, it will return promotion headers that match all keys.
     * e.g. If the keys 'range', 'colour' are provided the results will match both 'range' and 'colour'. Partial matches are not returned.
     * @param promotionHeaders
     * @param keys from the inputs of the promotion directive
     * @returns
     */
    private promotionHeadersReturnedByAllKeys(promotionHeaders: IPromotionListItem[][], keys: string[]): IPromotionListItem[] {
        // Filter only promotions that are returned by all queries, to filter out partial matches
        const idCounts = promotionHeaders.flat().reduce((acc, { id }) => {
            acc[id] = (acc[id] || 0) + 1;
            return acc;
        }, {});
        const filteredPromotions = promotionHeaders.map((innerArray) => innerArray.filter(({ id }) => idCounts[id] === promotionHeaders.length));

        // Remove duplicates and ensure all key conditions have been matched
        const combinedResults = filteredPromotions.flat();
        const uniquePromotions = Array.from(new Map(combinedResults.map((promotion) => [promotion.id, promotion])).values());

        // Check if all results are present based on the keys of the provided fields
        const allResultsPresent = filteredPromotions.every((result, index) => keys[index] && result.length > 0);

        const allKeyMatches = allResultsPresent ? uniquePromotions : [];

        return allKeyMatches;
    }

    /**
     * Some promotions are too complex to allow for the scope of fully editable rules. This method is the home of all hardcoded rules
     * for promotions.
     * @param selectionKeys Slugs that identify page, range, colour, etc.
     * @param applicablePromotionHeaders Applicable promotion headers, i.e. the output of getApplicablePromotionHeaders()
     * @returns 
     */
    private removeApplicablePromotionExceptions(selectionKeys: IPromotionSelectionKeys, applicablePromotionHeaders: IPromotionListItem[]): Promise<IPromotionListItem[]> {
        return new Promise((resolve, reject) => {
            // if (selectionKeys.brand && selectionKeys.brand === 'CDA') {
            //     this.exceptionCDAFreeDishwasher(selectionKeys, applicablePromotionHeaders)
            //         .then((processedHeaders) => resolve(processedHeaders))
            //         .catch((error) => reject(error));
            // } else {
            //     return resolve(applicablePromotionHeaders);
            // }
            return resolve(applicablePromotionHeaders);
        });
    }

    /**
     * CDA Product Exclusion of ovens in SL100 or SL200 range for Free Dishwasher promotion
     * @param selectionKeys Slugs that identify page, range, colour, etc.
     * @param applicablePromotionHeaders Applicable promotion headers, i.e. the output of getApplicablePromotionHeaders()
     * @returns 
     */
    private exceptionCDAFreeDishwasher(selectionKeys: IPromotionSelectionKeys, applicablePromotionHeaders: IPromotionListItem[]): Promise<IPromotionListItem[]> {
        return new Promise((resolve, reject) => {
            if (!selectionKeys.productCode || !selectionKeys.productCode.match(/^(SL100|SL200)/)) {
                return resolve(applicablePromotionHeaders);
            }
            this.filterPromotionHeadersByBrand(applicablePromotionHeaders, 'CDA')
                .then((headers) => {
                    if (!Array.isArray(headers) || !headers.length) {
                        return resolve(applicablePromotionHeaders);
                    }
                    const fullPromotionPromises: Promise<Promotion>[] = headers.map((header) => this.getPromotion(header.id));
                    Promise.all(fullPromotionPromises)
                        .then((fullPromotions) => {
                            if (!Array.isArray(fullPromotions)) {
                                return reject(`getPromotion() returned invalid response(s) for exceptionCDAFreeDishwasher()`);
                            }
                            const exceptionPromotion = fullPromotions.find((promotion) =>
                                promotion.name.match(/dishwasher/i) &&
                                promotion.terms.body.match(/SL100/i) && promotion.terms.body.match(/SL200/i)
                            );
                            if (exceptionPromotion) {
                                return resolve(applicablePromotionHeaders.filter((header) => header.id !== exceptionPromotion.id));
                            }

                            return resolve(applicablePromotionHeaders);
                        })
                        .catch((error) => reject(error))
                })
                .catch((error) => reject(error));
        });
    }

    /**
     * Utility method to retrieve the carcase discounts for a given cabinet.
     * Not efficient for use in the Promotion directive, intended for use by methods exposed to basket service.
     * @param item
     * @param activeRange
     * @returns An array of discount percentages that apply to the given cabinet.
     */
    private getCarcaseDiscounts(item: ICabinet, activeRange: ActiveRange): Promise<number[]> {
        return new Promise((resolve, reject) => {
            const selectionKeys: IPromotionSelectionKeys = {
                range: activeRange?.range?.name ?? null,
                colour: activeRange?.rangeColour ?? null,
                productCode: item.unit_code ?? null
            };
            this.getApplicablePromotionHeaders(selectionKeys)
                .then((promotionHeaders) => {
                    if (Array.isArray(promotionHeaders)) {
                        let getFullPromotionPromises: Promise<Promotion>[] = [];
                        promotionHeaders.forEach((promotionHeader) => {
                            getFullPromotionPromises.push(this.getPromotion(promotionHeader.id));
                        });
                        Promise.all(getFullPromotionPromises)
                            .then((fullPromotions) => {
                                const carcaseDiscounts = fullPromotions.flatMap((promotion) =>
                                    promotion.conditionalFields.filter((field) => field.name === 'carcaseDiscount').map((field) => field.value)
                                );

                                resolve(carcaseDiscounts);
                            })
                            .catch((error) => this.dialogService.error(this.constructor.name, error));
                    } else {
                        resolve([]);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    /**
     * Utility method to retrieve the door discounts for a given door.
     * Not efficient for use in the Promotion directive, intended for use by methods exposed to basket service.
     * @param item
     * @param activeRange
     * @returns An array of discount percentages that apply to the given door.
     */
    private getDoorDiscounts(item: IDoor, activeRange: ActiveRange): Promise<number[]> {
        return new Promise((resolve, reject) => {
            const selectionKeys: IPromotionSelectionKeys = {
                range: activeRange?.range?.name ?? null,
                colour: activeRange?.rangeColour ?? null
            };
            this.getApplicablePromotionHeaders(selectionKeys)
                .then((promotionHeaders) => {
                    if (Array.isArray(promotionHeaders)) {
                        let getFullPromotionPromises: Promise<Promotion>[] = [];
                        promotionHeaders.forEach((promotionHeader) => {
                            getFullPromotionPromises.push(this.getPromotion(promotionHeader.id));
                        });
                        Promise.all(getFullPromotionPromises)
                            .then((fullPromotions) => {
                                const doorDiscounts = fullPromotions.flatMap((promotion) =>
                                    promotion.conditionalFields.filter((field) => field.name === 'doorsDiscount').map((field) => field.value)
                                );

                                resolve(doorDiscounts);
                            })
                            .catch((error) => this.dialogService.error(this.constructor.name, error));
                    } else {
                        resolve([]);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    /**
     * Gets the door only sale price by applying promotions based on item and active range.
     * Not efficient for use in directives, intended for use by the basket service.
     * @param item
     * @param activeRange
     * @returns The sale price of the door
     */
    public getDoorOnlySalePrice(item: IDoor, activeRange: ActiveRange): Promise<number> {
        return new Promise((resolve, reject) => {
            this.getDoorDiscounts(item, activeRange)
                .then((doorDiscounts) => {
                    resolve(this.getDoorSalePrice(item, doorDiscounts));
                })
                .catch((error) => reject(error));
        });
    }

    /**
     * Gets the cabinet only sale price by applying promotions based on item and active range.
     * Not efficient for use in directives, intended for use by the basket service.
     * @param item
     * @param activeRange
     * @returns The sale price of the carcase
     */
    public getCarcaseSalePrice(item: ICabinet, activeRange: ActiveRange): Promise<number> {
        return new Promise((resolve, reject) => {
            this.getCarcaseDiscounts(item, activeRange)
                .then((carcaseDiscounts) => {
                    resolve(this.getCabinetOnlySalePrice(item, carcaseDiscounts));
                })
                .catch((error) => reject(error));
        });
    }

    /**
     * Gets the hingecost sale price by applying promotions based on item and active range.
     * Not efficient for use in directives, intended for use by the basket service.
     * @param item
     * @param activeRange
     * @returns The sale price of the carcase
     */
    public getHingeCostSalePrice(item: BasketItem, activeRange: ActiveRange): Promise<number> {
        return new Promise((resolve, reject) => {
            if (item.hingeCost) {
                this.getCarcaseDiscounts(item as unknown as ICabinet, activeRange)
                    .then((carcaseDiscounts) => {
                        resolve(this.costCalculator.calculateProductSalePrice(item.hingeCost, carcaseDiscounts));
                    })
                    .catch((error) => reject(error));
            } else {
                resolve(item.hingeCost);
            }
        });
    }

    /**
     * Uses the CostCalculator to perform the sale price calculation. Supports promotion stacking and will return the price after all discounts have been applied.
     * @param item A kitchen unit
     * @param activeRange The activeRange object
     * @param carcaseDiscounts A number array of discount percentages to be applied
     * @param doorDiscounts A number array of discount percentages to be applied
     * @returns The total sale price of the cabinet and all associated frontals
     */
    public getCabinetSalePrice(item: ICabinet, activeRange: ActiveRange, carcaseDiscounts: number[] = [], doorDiscounts: number[] = []): number {
        const frontals = activeRange ? this.catalogueService.getDoorForUnit(item.unit_code, activeRange.rangeColour || 'Alabaster') : null;
        if (frontals) {
            return this.costCalculator.calculateCabinetSalePrice(item, activeRange, frontals, carcaseDiscounts, doorDiscounts);
        }
        return this.costCalculator.calculateCabinetOnlySalePrice(item, carcaseDiscounts);
    }

    public getCabinetHingeCostSalePrice(hingeCost: number, carcaseDiscounts: number[] = []): number {
        return this.costCalculator.calculateProductSalePrice(hingeCost, carcaseDiscounts);
    }

    /**
     * Uses the CostCalculator to perform the sale price calculation. Supports promotion stacking and will return the price after all discounts have been applied.
     * @param item Kitchen cabinet
     * @param carcaseDiscounts A number array of discount percentages to be applied
     * @returns The sale price of the cabinet without doors, etc. Kinned to the cab_price attribtute
     */
    public getCabinetOnlySalePrice(item: ICabinet, carcaseDiscounts: number[] = []): number {
        return this.costCalculator.calculateCabinetOnlySalePrice(item, carcaseDiscounts);
    }

    /**
     * Uses the CostCalculator to perform the sale price calculation. Supports promotion stacking and will return the price after all discounts have been applied.
     * @param item A door or frontal (plinths, cornices, etc.)
     * @param doorDiscounts A number array of discount percentages to be applied
     * @param qty
     * @returns The sale price of a door
     */
    public getDoorSalePrice(item: IDoor, doorDiscounts: number[] = [], qty: number = 1): number {
        return this.costCalculator.calculateDoorSalePrice(item, doorDiscounts, qty);
    }

    /**
     * Uses the CostCalculator to perform the sale price calculation. Supports promotion stacking and will return the price after all discounts have been applied.
     * @param item Any product (except cabinet & door)
     * @param productDiscounts A number array of discount percentages to be applied
     * @param qty 
     * @returns 
     */
    public getProductSalePrice(item: AllProductsUnion, productDiscounts: number[] = [], qty: number = 1): number {
        if (typeof item === 'object') {
            const cost = item['price'] || item['cost'] || item['_cost'] || 0;
            const salePrice = this.costCalculator.calculateProductSalePrice(cost, productDiscounts, qty);
            return salePrice < cost ? salePrice : cost;
        }
        return 0;
    }

    /**
     * Fetches the full promotion details for a given promotion. Results will first be returned from the local cache.
     * N.B. The endpoint will only return details if the promotion is currently active
     * @param id The unique identifier for the promotion (PK of promotions table)
     * @param ignoreCache
     * @returns A fully instantiated Promotion object with all promotion details
     */
    public getPromotion(id: number, ignoreCache = false): Promise<Promotion> {
        return new Promise((resolve, reject) => {
            ignoreCache = this.debug ? true : ignoreCache;

            let now = DateHelper.now();
            const cacheKey = `${this.promotionCacheKey}-${id}`;

            this.storageService
                .get(cacheKey)
                .then((cachedPromotion) => {
                    if (!ignoreCache && cachedPromotion && cachedPromotion.promotionsCacheTill > now) {
                        resolve(new Promotion(cachedPromotion.promotion));
                    } else {
                        const url = `${this.config.api.endpoints.diy}/diy/marketing/promotion/${id}`;
                        this.getAPIData<Promotion>(url)
                            .then((promotionData) => {
                                const promotion = new Promotion(promotionData);
                                this.storageService
                                    .set(cacheKey, {
                                        promotionsCacheTill: DateHelper.format(
                                            DateHelper.moment().add(this.promotionCacheTimeInMinutes, 'minutes')
                                        ),
                                        promotion: promotionData
                                    })
                                    .then(() => resolve(promotion))
                                    .catch((error) => this.dialogService.error(this.constructor.name, error));
                            })
                            .catch((error) => reject(error));
                    }
                })
                .catch((error) => reject(error));
        });
    }

    /**
     * Fetches the applicable promotions for a given page slug
     * @param pageSlug String identifier of the page, e.g. 'range-detail'
     * @returns An array of applicable promotions
     */
    public getPromotionHeadersByPage(pageSlug: string): Promise<IPromotionListItem[]> {
        return this.getPromotionHeadersByKey(pageSlug, 'applicablePages');
    }

    /**
     * Fetches the applicable promotions for a given kitchen range Name
     * @param rangeName String identifier of the range - this should be range.name
     * e.g. 'Altino'
     * @returns An array of applicable promotions
     */
    public getPromotionHeadersByRange(rangeName: string): Promise<IPromotionListItem[]> {
        return this.getPromotionHeadersByKey(rangeName, 'applicableRanges');
    }

    /**
     * Fetches the applicable promotions for a given kitchen colour Name
     * @param colourName String identifier of the colour - this should be colour.name
     * e.g. 'Alabaster'
     * @returns An array of applicable promotions
     */
    public getPromotionHeadersByKitchenColour(colourName: string): Promise<IPromotionListItem[]> {
        return this.getPromotionHeadersByKey(colourName, 'applicableColours');
    }

    /**
     * Fetches the applicable promotions for a given brand name
     * @param brandName String identifier of the brand
     * e.g. 'AEG'
     * @returns An array of applicable promotions
     */
    public getPromotionHeadersByBrand(brandName: string): Promise<IPromotionListItem[]> {
        return this.getPromotionHeadersByKey(brandName, 'applicableBrands');
    }

    /**
     * Fetches the applicable promotions for a given productCode
     * @param productCode String identifier of the product
     * e.g. 'FD6'
     * @returns An array of applicable promotions
     */
    public getPromotionHeadersByProductCode(productCode: string): Promise<IPromotionListItem[]> {
        return this.getPromotionHeadersByKey(productCode, 'applicableProductCodes');
    }

    /**
     * Fetches the applicable promotions for a given productType
     * @param productType from the ProductType enum
     * e.g. 'Range Accessories'
     * @returns An array of applicable promotions
     */
    public getPromotionHeadersByProductType(productType: ProductType): Promise<IPromotionListItem[]> {
        return this.getPromotionHeadersByKey(productType, 'applicableProductTypes');
    }

    /**
     * Utility function to return applicable promotions given a slug and PromotionRules Key.
     * @param slug String member of IPromotionApplicables values
     * @param key String key of IPromotionApplicables
     * @returns An array of applicable promotions
     */
    private getPromotionHeadersByKey(slug: string, key: string): Promise<IPromotionListItem[]> {
        return new Promise((resolve, reject) => {
            this.getActivePromotionHeaders()
                .then((activePromotions) => {
                    if (Array.isArray(activePromotions)) {
                        let applicablePromotions = [];
                        activePromotions.forEach((promotion) => {
                            const promotionRules = promotion.rules[key];

                            if (key === 'applicableBrands') {
                                if (promotionRules.find((rule) => slug.match(rule)) || promotionRules.includes('all')) {
                                    applicablePromotions.push(promotion);
                                }
                            } else {
                                if (promotionRules.includes(slug) || promotionRules.includes('all')) {
                                    applicablePromotions.push(promotion);
                                }
                            }
                        });
                        resolve(applicablePromotions);
                    } else {
                        reject(activePromotions);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    /**
     * Formats the promotion name into a safe url fragment
     * @param promotion Instantiated Promotion class
     * @returns An id string suitable for use in urls
     */
    public getPromotionUrlFragment(promotion: Promotion | IBanner): string {
        return promotion?.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .replace(/[^a-z0-9\-_:.]/g, '');
    }

    /**
     * Hardcoded exception for the CDA offer whose rules are too complex for the promotion editor.
     * @param basketItems 
     * @param promotions 
     * @param descriptionAppend 
     * @returns 
     */
    private checkBasketQualifyingFreeItemsExceptionCDA(basketItems: BasketItem[], promotions: Promotion[], descriptionAppend: string): IPromotionBasketAction[] {
        console.log('Items:', basketItems);
        let response: IPromotionBasketAction[] = [];
        let freeItemCDAPromotions = promotions.filter((promotion) =>
            promotion.conditionalFields.some((field) => field.name === 'freeProducts') &&
            promotion.conditionalFields.some((field) => field.name === 'brandName' && field.value === 'CDA')
        );

        let cdaItems: BasketItem[] = JSON.parse(JSON.stringify(basketItems));
        cdaItems = cdaItems.filter((item) => item.supplier === 'CDA');

        if (!freeItemCDAPromotions.length) {
            return [];
        }

        const qualifies = cdaItems.some((item) => item.subCategory.match(/Oven/i) && !item.code.match(/^(SL100|SL200)/)) &&
            cdaItems.some((item) => item.subCategory === 'Hobs') &&
            cdaItems.some((item) => item.subCategory === 'Extractors') &&
            (
                cdaItems.some((item) => item.subCategory.match(/Fridge Freezers|Fridges & Freezers/i)) ||
                (
                    cdaItems.some((item) => item.subCategory === 'Fridges') &&
                    cdaItems.some((item) => item.subCategory === 'Freezers')
                )
            );

        const freeItem = freeItemCDAPromotions[0].conditionalFields.find((field) => field.name === 'freeProducts');
        let basketAction: IPromotionBasketAction = {
            action: 'nothing',
            productCode: freeItem.value,
            group: ProductType.APPLIANCES
        }

        let freeItemIsPresentInBasket = false;
        const matchingItems = basketItems.filter((item) => item.code === basketAction.productCode);
        const itemIsPresentInBasket = matchingItems.length > 0;
        if (matchingItems.some((item) => item.description.includes(descriptionAppend))) {
            freeItemIsPresentInBasket = true;
        }

        if (qualifies && !freeItemIsPresentInBasket) {
            basketAction.action = 'add';
            basketAction.value = 0.001;
            if (itemIsPresentInBasket) {
                response.push({
                    action: 'remove',
                    productCode: freeItem.value,
                    group: ProductType.APPLIANCES
                })
            }
        } else if (!qualifies) {
            if (freeItemIsPresentInBasket) {
                basketAction.action = 'remove';
            }
        }

        response.push(basketAction);

        return response;
        /*
        Rules:
            1. 1 x CDA Single Oven, excluding .match(/^(SL100|SL200)/)
            2. 1 x CDA Hob
            3. 1 x CDA Hood
            4. 1 x CDA fridgefreezer (or 1 x fridge & 1 x freezer )
        */
    }

    /**
     * Checks the 'Free Item' promotions for the basket to see if any 'free item' should be added or removed
     * @param basketItems 
     * @param descriptionAppend 
     * @returns 
     */
    public checkBasketQualifyingFreeItems(basketItems: BasketItem[], descriptionAppend: string): Promise<IPromotionBasketAction[]> {
        return new Promise((resolve, reject) => {
            let response: IPromotionBasketAction[] = [];
            this.getActiveFullPromotions()
                .then((promotions) => {
                    let freeItemPromotions = promotions.filter((promotion) => promotion.conditionalFields.some((field) => field.name === 'freeProducts'));
                    // Exclude CDA freeItem promotion as it has rules which are too complex for the editor
                    // freeItemPromotions = freeItemPromotions.filter((promotion) => !promotion.conditionalFields.some((field) => field.name === 'brandName' && field.value === 'CDA'));
                    // const cdaException = this.checkBasketQualifyingFreeItemsExceptionCDA(basketItems, promotions, descriptionAppend);
                    const cdaException = [];

                    freeItemPromotions.forEach((promotion) => {
                        let basketAction: IPromotionBasketAction = {
                            action: 'nothing',
                            productCode: '',
                            group: ProductType.APPLIANCES
                        };

                        const promotionBrand = promotion.conditionalFields.find((field) => field.name === 'brandName');
                        if (!promotionBrand) {
                            response.push(basketAction);
                            return;
                        }

                        let items = basketItems.filter((item) => item?.supplier?.match(promotionBrand.value));
                        if (!items.length) {
                            response.push(basketAction);
                            return;
                        }

                        const minRequiredField = promotion.conditionalFields.find((field) => field.name === 'minRequired');
                        const freeItem = promotion.conditionalFields.find((field) => field.name === 'freeProducts');

                        if (!minRequiredField || !freeItem) {
                            response.push(basketAction);
                            return;
                        }

                        basketAction.productCode = freeItem.value;
                        const totalItems = items.reduce((total, item) =>
                            (item.code !== basketAction.productCode || !item.description.includes(descriptionAppend))
                                ? total + (item.qty || 1)
                                : total
                            , 0);

                        const matchingItems = basketItems.filter((item) => item.code === basketAction.productCode);
                        const itemPresentInBasketAndUpdateNeeded = matchingItems.length > 0 && totalItems > parseInt(minRequiredField.value, 10);;
                        const freeItemIsPresentInBasket = matchingItems.some((item) => item.description.includes(descriptionAppend));

                        if (totalItems >= parseInt(minRequiredField.value, 10) && !freeItemIsPresentInBasket) {
                            // Basket qualifies for free item
                            basketAction.action = 'add';
                            basketAction.value = 0.001;
                            if (itemPresentInBasketAndUpdateNeeded) {
                                response.push({
                                    action: 'remove',
                                    productCode: freeItem.value,
                                    group: ProductType.APPLIANCES
                                });
                            }
                        } else if (totalItems < parseInt(minRequiredField.value, 10) && freeItemIsPresentInBasket) {
                            // Basket does not qualify for free item
                            basketAction.action = 'remove';
                        }

                        response.push(basketAction);
                    });


                    resolve([...response, ...cdaException]);
                })
                .catch((error) => reject(error));
        });
    }

    /**
     * Gets all banners of active promotions from the DIY API
     * @returns all banners of active promotions
     */
    public getBanners(ignoreCache = false): Promise<IBanner[]> {
        return new Promise((resolve, reject) => {
            ignoreCache = this.debug ? true : ignoreCache;

            let now = DateHelper.now();
            this.storageService
                .get(`${this.promotionCacheKey}-banners`)
                .then((cachedBanners) => {
                    if (!ignoreCache && cachedBanners && cachedBanners.promotionsCacheTill > now) {
                        resolve(cachedBanners.banners);
                    } else {
                        const url = `${this.config.api.endpoints.diy}/diy/marketing/active-banners`;
                        this.getAPIData<IBanner[]>(url)
                            .then((bannerResponse) => {
                                this.storageService
                                    .set(`${this.promotionCacheKey}-banners`, {
                                        promotionsCacheTill: DateHelper.format(
                                            DateHelper.moment().add(this.promotionCacheTimeInMinutes, 'minutes')
                                        ),
                                        banners: bannerResponse
                                    })
                                    .then(() => {
                                        resolve(bannerResponse || [])
                                    })
                                    .catch((error) => this.dialogService.error(this.constructor.name, error));
                            })
                            .catch((error) => reject(error));
                    }
                })
                .catch((error) => reject(error));
        });
    }

    /**
     * Returns an array of banners that match the locationId for active promotions.
     */
    public getBannerForLocation(locationId: BannerLocation, ignoreCache = false): Promise<IBanner[]> {
        return new Promise((resolve, reject) => {
            this.getBanners(ignoreCache)
                .then((banners) => {
                    resolve(banners.filter((banner) => banner.location === locationId))
                })
                .catch((error) => reject(error));
        });
    }

    /**
     * Utility method to fetch data using the httpApi.get() method from a given url
     * @param url
     * @returns
     */
    private getAPIData<T>(url: string): Promise<T> {
        return new Promise((resolve, reject) => {
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken
                }
            };

            this.httpApi.get<HttpApiResponse<T>>(url, options).subscribe({
                next: (response) => {
                    if (response && response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public cookieConsent(markAsRead: boolean = false): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const storageKey = 'cookie-consent';

            if (markAsRead) {
                this.storageService.set(storageKey, true)
                    .then(() => resolve(true))
                    .catch((error) => reject(error));
            } else {
                this.storageService.get(storageKey)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            }
        });
    }
}
