import { NgModule } from '@angular/core';

import { CdnDirective } from './cdn.directive';

@NgModule({
    exports: [
        CdnDirective
    ],
    declarations: [
        CdnDirective
    ]
})
export class CdnDirectiveModule { }
