import { Injectable } from '@angular/core';

import { Config } from '@app/config';

import { HttpApi } from '@app/services/api';
import { HttpApiOptions, HttpApiResponse } from '@app/services/api/models';
import { AuthCustomerService } from '@app/services/auth';
import { NumberHelper } from '@app/utilities/helpers';

import {
    IBasketAddress
} from '@app/services/basket/models';

@Injectable()
export class OrderService {
    constructor(private config: Config, private httpApi: HttpApi, private authCustomerService: AuthCustomerService) {}

    public getOrders(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            if (this.authCustomerService.isAuthenticated) {
                let url = `${this.config.api.endpoints.diy}/diy/customer/details/orders`;
                let options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id,
                        UserToken: this.authCustomerService.applicationToken,
                    },
                };

                this.httpApi.get<HttpApiResponse<any[]>>(url, options).subscribe(
                    (response) => {
                        if (response && response.success) {
                            resolve(response.body || []);
                        } else {
                            resolve([]);
                        }
                    },
                    (error) => reject(error)
                );
            } else {
                resolve([]);
            }
        });
    }

    public setOrderDelivery(orderId, level, cost, deliveryDate, surCharge, selectLater, deliveryNote): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = `${this.config.api.endpoints.diy}/order/${orderId}/delivery`;
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken,
                },
            };

            let params = {
                level,
                cost,
                orderId,
                deliveryDate,
                deliverySurcharge: surCharge,
                selectLater,
                deliveryNote,
            };

            this.httpApi.post<HttpApiResponse<any>>(url, params, options).subscribe(
                (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                (error) => reject(error)
            );
        });
    }

    public setOrderDeliveryAccess(orderId, deliveryAccess) {
        return new Promise((resolve, reject) => {
            let url = `${this.config.api.endpoints.diy}/order/${orderId}/delivery-access`;
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken,
                },
            };

            this.httpApi.post<HttpApiResponse<any>>(url, deliveryAccess, options).subscribe(
                (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                (error) => reject(error)
            );
        });
    }

    public getOrderDetails(orderNumber): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = `${this.config.api.endpoints.diy}/order/${orderNumber}/details`;

            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken,
                },
            };

            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe(
                (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                (error) => reject(error)
            );
        });
    }

    public markPayWithBacs(orderNumber): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = `${this.config.api.endpoints.diy}/diy/order/${orderNumber}/pay-with-bacs`;

            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken,
                },
            };

            this.httpApi.post<HttpApiResponse<any>>(url, null, options).subscribe(
                (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                (error) => reject(error)
            );
        });
    }

    public getPaymentDetails(paymentId, orderNumber = null): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = `${this.config.api.endpoints.diy}/diy/payment-details`;

            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken,
                },
            };

            let params = {
                paymentId: paymentId,
                orderNumber: orderNumber,
            };

            this.httpApi.post<HttpApiResponse<any>>(url, params, options).subscribe(
                (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                (error) => reject(error)
            );
        });
    }

    // Keep the same output as basketService's one
    public paymentDetails(order) {
        let paymentDetails = {
            oustandingAmount: NumberHelper.toPoundsAndPence(order.orderTotal),
            totalPaid: 0,
            preauthAmount: 0,
            preauthRefunded: 0,
            saleAmount: 0,
            saleRefunded: 0,
        };

        if (order.preAuths && order.preAuths.length) {
            order.preAuths.forEach((preAuth) => {
                paymentDetails.preauthAmount += preAuth.transAmount;
                paymentDetails.oustandingAmount -= preAuth.transAmount;
            });
        }

        if (order.paymentHistory && order.paymentHistory.length) {
            order.paymentHistory.forEach((payment) => {
                switch (payment.txntype) {
                    case 'preauth':
                        paymentDetails.preauthAmount += payment.chargeTotal;
                        paymentDetails.preauthRefunded += payment.refundedAmount || 0;
                        paymentDetails.oustandingAmount -= payment.chargeTotal;

                        break;
                    case 'sale':
                        paymentDetails.saleAmount += payment.chargeTotal;
                        paymentDetails.saleRefunded += payment.refundedAmount || 0;
                        paymentDetails.oustandingAmount = paymentDetails.oustandingAmount - payment.chargeTotal + (payment.refundedAmount || 0);

                        break;
                }

                if (payment.isManualPayment && !['preauth', 'sale'].includes(payment.txntype)) {
                    paymentDetails.saleAmount += payment.chargeTotal || 0;
                    paymentDetails.saleRefunded += payment.refundedAmount || 0;
                    paymentDetails.oustandingAmount = paymentDetails.oustandingAmount - payment.chargeTotal + (payment.refundedAmount || 0);
                }
            });
        }

        paymentDetails = {
            oustandingAmount: NumberHelper.toPoundsAndPence(paymentDetails.oustandingAmount),
            totalPaid: NumberHelper.toPoundsAndPence(paymentDetails.totalPaid),
            preauthAmount: NumberHelper.toPoundsAndPence(paymentDetails.preauthAmount),
            preauthRefunded: NumberHelper.toPoundsAndPence(paymentDetails.preauthRefunded),
            saleAmount: NumberHelper.toPoundsAndPence(paymentDetails.saleAmount),
            saleRefunded: NumberHelper.toPoundsAndPence(paymentDetails.saleRefunded),
        };

        return paymentDetails;
    }

    public saveOrderAddresses(billingAddress: IBasketAddress, deliveryAddress: IBasketAddress, orderNumber: string) {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/order/${orderNumber}/addresses`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken,
                },
            };
            const params = {
                billingAddress,
                deliveryAddress,
                orderNumber,
            };

            this.httpApi.post<HttpApiResponse<any>>(url, params, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error),
            });
        });
    }

    public updateOrderStatus(orderNumber: string) {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/order/update-status/${orderNumber}`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken,
                },
            };

            this.httpApi.put<HttpApiResponse<any>>(url, {}, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error),
            });
        });
    }
}
