<div mat-dialog-title class="header">
    <h5>Change Style - {{currentRange}} - {{currentRangeColour}}<ng-container *ngIf="currentRangeColour === 'Bespoke'"> - {{currentBespokeColour || currentColour}}</ng-container></h5>
    <div class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </div>
</div>

<mat-dialog-content>
    <div class="style-options" *ngIf="rangeOptions">
        <mat-form-field appearance="outline">
            <mat-label>Range</mat-label>
            <mat-select [(ngModel)]="selectedRange" (selectionChange)="resetSelected()">
                <mat-option *ngFor="let range of rangeOptions" [value]="range">
                    {{range.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="selectedRange">
            <mat-label>Colour</mat-label>
            <mat-select [(ngModel)]="selectedRangeColour" (selectionChange)="resetSelectedColour()">
                <mat-option *ngFor="let rangeColour of selectedRange.rangeColours" [value]="rangeColour">
                    {{rangeColour}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="selectedRange">
            <mat-label>Carcase Colour</mat-label>
            <mat-select [(ngModel)]="selectedCarcaseColour" (selectionChange)="resetSelectedColour()">
                <mat-option *ngFor="let carcaseColour of selectedRange.rangeCarcaseColours" [value]="carcaseColour">
                    {{carcaseColour}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="autocomplete" appearance="outline" *ngIf="selectedRangeColour === 'Bespoke'">
            <mat-label class="mat-label">Bespoke Colour</mat-label>
            <input matInput [(ngModel)]="searchText" name="searchText" (ngModelChange)="filterBespokeColours()" [matAutocomplete]="auto" (keyup)="confirmColour()">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onBespokeColourSelected($event)">
                <mat-option *ngFor="let bespokeColour of filteredBespokeColours" [value]="bespokeColour.code">
                    <span>{{ bespokeColour.code }}</span>
                    <small *ngIf="bespokeColour.source"> ({{ bespokeColour.source }})</small>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <ng-container *ngIf="!compareBasketItems">
        <table class="item-list">
            <thead>
                <tr>
                    <th>
                        <mat-checkbox [checked]="allChecked" [indeterminate]="someChecked()" (change)="setAll($event.checked)"></mat-checkbox>
                    </th>
                    <th>Rank</th>
                    <th>Code</th>
                    <th>Item</th>
                    <th>QTY</th>
                    <th>Current Price</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of basketItems">
                    <td class="checkbox">
                        <ng-container *ngIf="item.checked === true || item.checked === false">
                            <mat-checkbox [(ngModel)]="item.checked" (ngModelChange)="updateAllChecked()"></mat-checkbox>
                        </ng-container>
                    </td>
                    <td>{{ item.rank }}</td>
                    <td>{{ item.code }}</td>
                    <td>{{ item.description }} <span *ngIf="item?.rankParent === '0' && item.carcaseColour"> - {{item.carcaseColour}}</span></td>
                    <td>{{ item.qty }}</td>
                    <td>
                        <span>{{ item.cost | currencyFormat }}</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"></td>
                    <td><strong>Total</strong></td>
                    <td><strong>{{oldTotal | currencyFormat}}</strong></td>
                </tr>
            </tbody>
        </table>

        <div class="actions">
            <button mat-flat-button color="primary" class="chunky" [disabled]="!isSelectionValid() || checkingAvailability" (click)="checkAvailability()">
                Check Availability
            </button>
        </div>
    </ng-container>

    <ng-container *ngIf="compareBasketItems">
        <table class="item-list">
            <thead>
                <ng-container *ngIf="basketItemsUnavailable.length">
                    <tr>
                        <th>Rank</th>
                        <th>Code</th>
                        <th>Item</th>
                        <th>QTY</th>
                        <th>Old Cost</th>
                        <th>New Cost</th>
                    </tr>
                </ng-container>
                <ng-container *ngIf="!basketItemsUnavailable.length">
                    <tr>
                        <th></th>
                        <th>Old Cost</th>
                        <th>New Cost</th>
                    </tr>
                </ng-container>
            </thead>
            <tbody>
                <ng-container *ngIf="basketItemsUnavailable.length">
                    <tr *ngFor="let item of basketItemsUnavailable">
                        <td>{{item.rank}}</td>
                        <td>{{item.code}}</td>
                        <td>{{ item.description }} <span *ngIf="item?.rankParent === '0' && item.carcaseColour"> - {{item.carcaseColour}}</span></td>
                        <td>{{item.qty}}</td>
                        <td colspan="2">
                            <span class="danger">Item <strong>NOT</strong> Available in Style</span>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="!basketItemsUnavailable.length">
                    <tr>
                        <td colspan="3">All items available in new style</td>
                    </tr>
                </ng-container>
                <tr>
                    <td *ngIf="basketItemsUnavailable.length" colspan="3"></td>
                    <td><strong>Total</strong></td>
                    <td><strong>{{oldTotal | currencyFormat}}</strong></td>
                    <td>
                        <div class="new-total">
                            <strong>{{newTotal | currencyFormat}}</strong>
                            <mat-icon class="success" *ngIf="oldTotal > newTotal">arrow_drop_down</mat-icon>
                            <mat-icon class="danger" *ngIf="oldTotal < newTotal">arrow_drop_up</mat-icon>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="unavailable-notice" *ngIf="basketItemsUnavailable.length">
            <p class="error-message">The above items are not available in the selected style. They will be <strong>REMOVED</strong> from your basket if you confirm the change. You can look for an alternative and add that to your basket as a replacement.</p>
        </div>

        <div class="actions">
            <button mat-stroked-button color="secondary" class="chunky" [disabled]="checkingAvailability" (click)="close()">
                Cancel Changes
            </button>

            <button mat-flat-button color="primary" class="chunky" [disabled]="checkingAvailability" (click)="applyChanges()">
                Apply Changes
            </button>
        </div>
    </ng-container>
</mat-dialog-content>
