@import '/src/theme/variables';

page-about-easy-online-ordering {
    .button-wrapper {
        display: flex;
        margin-bottom: 56px;

        &:last-of-type {
            margin-bottom: 0;
        }

        .mdc-button {
            width: 280px;
        }

        a {
            & + a {
                .mdc-button {
                    margin-left: 30px;
                }
            }
        }
    }
}