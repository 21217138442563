@import '/src/theme/variables';

component-media-player-dialog {
    display: flex;
    height: 100%;

    section {
        height: 100%;
    }

    .mat-mdc-dialog-content {
        max-height: 100%;
    }

    .close-dialog {
        position: absolute;
        top: 15px;
        right: 15px;
        background: #FFF;
        color: #000;
        border-radius: 30px;
        box-shadow: 0px 0px 5px color('black');
        z-index: 100;
    }

    iframe,
    video,
    .image {
        width: 100%;
        height: 100%;
        outline: none;
    }

    .image {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }
}