import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Article } from '@app/services/articles/models';
import { CatalogueService } from '@app/services/catalogue';
import { ActiveRange, ImageTypes } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { MarketingService } from '@app/services/marketing';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';

import { StringHelper } from '@app/utilities/helpers';
import { IPromotion, PromotionTypes } from '@app/services/marketing/models';

@Component({
    selector: 'page-kitchens-range-detail',
    templateUrl: './range-detail.page.html',
    styleUrls: ['./range-detail.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class RangeDetailPage implements OnInit, OnDestroy {
    public globalHeadingTags;
    public range: any;
    public selectedColour: any;
    public selectedCabinet: any;
    public specs: Article[];
    public specsTags = ['diy kitchens - website', 'ranges', 'specs'];
    public featuresTags = ['diy kitchens - website', 'ranges', 'features'];
    public cabinetColours: any;
    public activeRange: ActiveRange;

    public hasBespoke = false;
    public hasGlazed = false;
    public hasCurves = false;
    public hasPlaterack = false;

    private routeSubscription: Subscription;
    private routeHistory: RouteHistory[] = [{ title: 'Kitchens', route: '/kitchens' }];

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private marketingService: MarketingService
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                const style = route.params.style || null;
                const routeHistory = this.routeHistory.slice();

                routeHistory.push({
                    title: StringHelper.titleCase(StringHelper.dashToSpace(style)),
                    route: `/kitchens/${style}/details/`
                });

                if (style) {
                    this.loadRange(style);

                    this.navigationService.setNavigation({
                        title: StringHelper.titleCase(StringHelper.dashToSpace(style)),
                        metaTags: [
                            { name: 'no-meta', content: 'no metadata' }
                        ],
                        routeHistory: routeHistory
                    });
                }
            }
        );
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }

    public styleUrl(range, colour) {
        const style = `${range} ${colour}`.toLowerCase().replace(/ /g, '-');

        return `/kitchens/${style}/details/`;
    }

    private loadRange(style: string) {
        this.catalogueService.getRangeByStyle(style)
            .then((range: any) => {
                if (range) {
                    this.range = range;

                    this.selectedColour = range.colours.find((colour) => {
                        return `${range.name} ${colour.name}`.toLowerCase().replace(/ /g, '-') === style;
                    }) || range.colours[0];

                    this.specsTags.push(range.name);
                    this.featuresTags.push(range.name);

                    this.hasBespoke = !!range.colours.find(col => col.colour.match(/bespoke/i));
                    const features = [range.feature1, range.feature2, range.feature3];
                    features.forEach((feature) => {
                        if (feature) {
                            if (feature.match(/glazed/i)) {
                                this.hasGlazed = true;
                            }

                            if (feature.match(/curve/i)) {
                                this.hasCurves = true;
                            }

                            if (feature.match(/plate/i)) {
                                this.hasPlaterack = true;
                            }
                        }
                    });

                    this.navigationService.setMetaTags([
                        { property: 'og:image', content: this.getRangeImage() },
                        { property: 'og:description', content: `The DIY Kitchens ${range.name} kitchen - ${range.range_detailed_description}` },
                        { name: 'Description', content: `The DIY Kitchens ${range.name} kitchen - ${range.range_detailed_description}` },
                    ]);

                    this.catalogueService
                        .getCabinetColours()
                        .then((cabinetColours: any) => {
                            if (cabinetColours) {
                                this.cabinetColours = cabinetColours.filter(cab => !cab.discontinued);
                                this.selectedCabinet = cabinetColours.find(col => col.colour === this.selectedColour.def_carcase) || cabinetColours[0];
                            }
                        })
                        .catch((error) => this.dialogService.error(this.constructor.name, error));
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public selectCabinetColour(cabinetColour) {
        this.selectedCabinet = cabinetColour;
    }

    public getRangeImage() {
        return this.catalogueService.image({
            rangeId: this.range.range_id,
            colour: this.selectedColour.name,
            type: ImageTypes.STYLES
        });
    }
}
