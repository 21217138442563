import { Injectable } from '@angular/core';
import localForage from 'localforage';

import { Config } from '@app/config';

@Injectable()
export class StorageService {
    constructor(private config: Config) {
        const localForageConfig: LocalForageOptions = {
            name: config.storage.name,
            storeName: config.storage.storeName
        };
        if (this.config.isBrowser) {
            localForage.config(localForageConfig);
        }
    }

    public get(key): Promise<any> {
        if (this.config.isBrowser) {
            return localForage.getItem(key);
        } else {
            return new Promise((resolve, reject) => resolve(null));
        }
    }

    public set(key, data): Promise<void> {
        if (this.config.isBrowser) {
            return localForage.setItem(key, data);
        } else {
            return new Promise((resolve, reject) => resolve(null));
        }
    }

    public remove(key): Promise<void> {
        if (this.config.isBrowser) {
            return localForage.removeItem(key);
        } else {
            return new Promise((resolve, reject) => resolve(null));
        }
    }

    public getCookie(name: string) {
        if (this.config.isBrowser) {
            let cookie = null;

            try {
                cookie = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || null;
            } catch (e) {
                console.error('Cookie Fetch: ', name);
            }

            return cookie;
        } else {
            return null;
        }
    }
}
