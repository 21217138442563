@import '/src/theme/variables';

page-support {
    .head {
        display: flex;
        justify-content: space-between;
        clear: both;

        h3 {
            margin: 0 0 10px;
            margin-right: auto;
        }

        .phones {
            margin-left: 60px;
            border-collapse: collapse;
            min-width: 440px;

            .btop {
                td {
                    padding-top: 10px;
                    border-top: 1px solid #AAA;
                }
            }
        }

        a {
            display: block;
            margin: 0 0 10px;
            font-size: 38px;
            line-height: 38px;
        }

        td {
            padding-right: 20px;
            vertical-align: top;
        }
    }

    header {
        max-width: 850px;

        p {
            margin-top: 30px;
        }
    }

    .support-options {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: flex-start;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
        max-width: 1300px;

        .disabled {
            opacity: 0.4;
            filter: grayscale(1);
        }
    }

    .tile {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        margin: 12px 24px 12px 0;
        padding: 24px;
        box-shadow: 0 5px 5px 0 rgb(0 0 0 / 5%);
        border-radius: 5px;
        background-color: #FFF;
        background-repeat: no-repeat;
        background-position: 30px center;
        background-size: 66px;
        width: calc((1300px - 280px)/3);
        min-width: 330px;
        min-height: 50px;
        max-height: 280px;
        font-weight: 600;
        line-height: 1.5rem;
        letter-spacing: 0;

        &.small {
            padding: 12px 24px;
        }

        .mat-icon {
            width: 64px;
            height: 48px;
            color: rgb(52, 156, 236);
            font-size: 46px;
        }

        em {
            color: #999;
            font-size: 12px;
            font-style: normal;
        }
    }

    @media only screen and (max-width: 1300px) {
        .tile {
            width: calc((100vw - 276px)/3);
        }
    }

    @media only screen and (max-width: 900px) {
        .head {
            flex-wrap: wrap;

            .phones {
                margin: 30px 0 0;
            }
        }

        .tile {
            width: calc((100vw - 204px)/2);
        }
    }

    @media only screen and (max-width: 600px) {
        .tile {
            margin-right: 0;
            // padding: 100px 5px 15px;
            background-size: 55px;
            width: calc(100vw - 105px);
            font-size: 0.9rem;
        }
    }

    .tile,
    ::after,
    ::before {
        box-sizing: initial;
    }

    .my-order {
        background-image: url('https://static.diy-kitchens.com/assets/images/support/icon_dashboard_orders.svg');
    }

    .delivery {
        background-image: url('https://static.diy-kitchens.com/assets/images/support/icon_dashboard_deliveries.svg');
    }

    .returns {
        background-image: url('https://static.diy-kitchens.com/assets/images/support/icon_dashboard_returns.svg');
    }

    .general {
        background-image: url('https://static.diy-kitchens.com/assets/images/support/icon_dashboard_general-question.svg');
    }

    .showroom {
        background-image: url('https://static.diy-kitchens.com/assets/images/support/icon_dashboard_showroom.svg');
    }

    .delivery-small {
        background-image: url('https://static.diy-kitchens.com/assets/images/support/icon_dashboard_samples.svg');
    }

    .quote {
        background-image: url('https://static.diy-kitchens.com/assets/images/support/icon_dashboard_quotation.svg');
    }

    .xmas-wrapper {
        overflow-x: auto;
        width: 100%;
    }

    .xmas {
        border: 1px solid #999;
        border-collapse: collapse;
        width: 100%;
        min-width: 1190px;
        font-size: 14px;

        td {
            padding: 15px 5px;
            border: 1px solid #999;
            background: #9F9;
            text-align: center;
        }

        th {
            padding: 5px 15px;
            border: 1px solid #999;
            background-color: #FFF;
        }

        .closed {
            background: #666;
            color: #FFF;
        }

        sup {
            font-size: 0.5rem;
        }
    }
}
