import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { AuthService } from '@app/services/auth';
import { DialogService } from '@app/services/dialog';
import { MessagingService } from '@app/services/messaging';
import { MessageGroup } from '@app/services/messaging/models';
import { NavigationService } from '@app/services/navigation';

@Component({
    selector: 'page-contact',
    templateUrl: './contact.page.html',
    styleUrls: ['./contact.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ContactPage implements OnInit {
    public supportEmailAddress: string = 'support@diy-kitchens.com';

    public isAuthenticated: boolean = false;
    public messageGroup: MessageGroup;
    public deptMsg = 'us';
    public deptBlurb = '';
    public minimumChars = 20;

    public files: any[] = [];
    public submitSuccess = false;
    public submitFailed = false;
    public ticket = {
        message: '',
        fields: []
    };

    constructor(
        private authService: AuthService,
        private dialogService: DialogService,
        private messagingService: MessagingService,
        private navigationService: NavigationService,
    ) {
        navigationService.setNavigation({
            title: 'DIY Kitchens - New Message',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' }
            ]
        });
    }

    ngOnInit() {
        const segs = this.navigationService.getRouteSegments('contact');
        const type = segs[0] || 'general';

        let breadcrumb = 'Technical / pre-sales query';
        let route = '/support/contact/' + type;

        switch (type) {
            case 'planning':
                this.messageGroup = MessageGroup.PLANNER;
                this.deptMsg = 'Message our planning team';
                this.deptBlurb = 'Do you have questions or queries about your own design you have created on our planner? '
                    + 'Our dedicated team will be able to access your account and assist you with anything you need.';
                breadcrumb = 'Planning query';
                break;
            case 'quote':
                this.messageGroup = MessageGroup.QUOTES;
                this.deptMsg = 'Message our quotations team';
                this.deptBlurb = 'Whether you have a quotation from another kitchen company or a kitchen plan of your own, '
                    + 'by completing the enquiry form below we can provide you with a quotation for a comparable kitchen style, '
                    + 'or a kitchen style of your choice, from our range of over 100 styles, in the quickest possible time.';
                breadcrumb = 'Quotation';
                break;
            case 'feedback':
                this.messageGroup = MessageGroup.FEEDBACK;
                this.deptMsg = 'Send us your feedback';
                this.deptBlurb = 'We pride ourselves on customer service and are always looking for ways to improve. Please tell '
                    + 'us about your experience dealing with us, your feedback is greatly appreciated.'
                    + '<br><br>Share photos of your newly fitted kitchen in the real customer kitchens section of our web site. '
                    + 'Simply attach your photos using the button below - why not include a message to inspire future customers.';
                breadcrumb = 'Send your feedback';
                break;
            case 'customerkitchens':
                this.messageGroup = MessageGroup.FEEDBACK;
                this.deptMsg = 'Want to show off your newly fitted kitchen?';
                this.deptBlurb = 'Share photos of your newly fitted kitchen in the real customer kitchens section of our web site. '
                    + 'Simply attach your photos using the button below - why not include a message to inspire future customers.';
                breadcrumb = 'Customer kitchens';
                break;
            case 'delivery':
                this.messageGroup = MessageGroup.DELIVERIES;
                this.deptMsg = 'Message our deliveries team';
                this.deptBlurb = 'You can find up to date delivery information on <strong><a href="/orders">your orders page</a></strong>'
                    + '.<br>For general delivery enquiries please send us a message below. ';
                breadcrumb = 'Delivery query';
                break;
            case 'aftersales':
                this.messageGroup = MessageGroup.AFTERSALES;
                this.deptMsg = 'Message our after sales team';
                this.deptBlurb = 'To report damaged or missing items, or to return an item please use the options on the order via '
                    + ' <strong><a href="/orders">your orders page</a></strong>.<br><br>'
                    + 'For general after sales enquiries please send us a message below. ';
                breadcrumb = 'After sales message';
                break;
            default:
                this.deptMsg = 'Message us';
                this.messageGroup = MessageGroup.PRESALES;
                route = '/support/contact';
                break;
        }

        this.navigationService.setRouteHistory([{ title: 'Support', route: '/support' }, { title: breadcrumb, route: route }]);
    }

    public disableTicket() {
        return this.ticket.message.length < this.minimumChars;
    }

    public addFile(input: HTMLElement) {
        input.click();
    }

    public fileUpload(e) {
        const newFiles: File[] = Array.from(e.target.files);
        newFiles.forEach((file: File) => {
            let reader = new FileReader();
            reader.onload = ((fileToBeUploaded: any) => {
                return (ev) => {
                    fileToBeUploaded.binaryContent = ev.target.result;
                    this.files.push(file);
                };
            })(file);

            reader.readAsDataURL(file);
        });
    }

    public cancelFile(file) {
        this.files = this.files.filter((f) => f !== file);
    }

    public raiseTicket() {
        this.authService.loginPrompt()
            .then(() => {
                this.submitSuccess = false;
                this.submitFailed = false;

                const title = this.ticket.message.substring(0, 100);

                let message = '';

                if (this.ticket.fields.length > 0) {
                    message += '<table>';
                    this.ticket.fields.forEach(f => message += '<tr><td>' + f.name + '</td><td><b>' + f.value + '</b></td></tr>');
                    message += '</table><p>&nbsp;</p>';
                }

                message += '<p>' + this.ticket.message + '</p>';

                this.messagingService.createTicket(title, message, [this.messageGroup], this.files)
                    .then(() => {
                        this.submitSuccess = true;
                    })
                    .catch((error) => {
                        this.submitFailed = true;
                        this.dialogService.error(this.constructor.name, error);
                    });
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
