import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { mockData } from '@app/shared/components/mega-menu/mock-data';
import { StringHelper } from '../helpers';
import { IMegaMenuItem, IMegaMenuSubCategory } from '@app/shared/components/mega-menu/models';

@Injectable({
    providedIn: 'root'
})
export class ProductCategoryGuard implements CanActivate {
    constructor(private router: Router) { }

    private categoryOverrideSections = ['kitchens', 'doors'];

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const megaMenuCategory = this.getMegaMenuCategory(route);
        if (!megaMenuCategory && !this.isValidNonMegaMenuRoute(route)) {
            this.router.navigate([this.getSectionUrl(route)]);
            return false;
        }

        if (this.shouldValidateSubCategory(route)) {
            const megaMenuSubCategory = this.getMegaMenuSubCategory(this.getRouteUrl(route), megaMenuCategory);
            if (!megaMenuSubCategory && !this.isValidNonMegaMenuRoute(route)) {
                this.router.navigate([this.getFallbackRoute(route)]);
                return false;
            }
        }

        return true;
    }

    /**
     * @returns The category string from the route
     */
    private getCategoryString(route: ActivatedRouteSnapshot): string {
        const categoryMap = {
            'accessories': 'panels-&-accessories'
        };

        let category: string;

        if (Array.isArray(route.url) && route.url.length) {
            category = route.url[0].path.toLowerCase();
        }

        // Override for sections of MegaMenu that have a 'styles' subcategory that is not part of the URL route
        if (this.categoryOverrideSections.includes(this.getMegaMenuSectionFromRoute(route))) {
            category = `styles`;
        }

        return categoryMap[category] || category;
    }

    /**
     * @returns the matching category from the MegaMenu (MegaMenuSubCategory) if found
     */
    private getMegaMenuCategory(route: ActivatedRouteSnapshot): IMegaMenuSubCategory {
        const categoryName = this.getCategoryString(route);
        const megaMenuSection = this.getMegaMenuSectionFromRoute(route);

        // Some MegaMenu Data (handles etc.) has :category set in items, rather than subCateogies and so we need to deal with that.
        if (!mockData[megaMenuSection]?.subCategories) {
            if (mockData[megaMenuSection].items) {
                if (mockData[megaMenuSection].items.find((subCategory) => subCategory.url === this.getCategoryUrl(route))) {
                    return mockData[megaMenuSection] as IMegaMenuSubCategory;
                }
            }
            return undefined;
        }

        return mockData[megaMenuSection]?.subCategories.find((category) => StringHelper.spaceToDash(category.name.toLowerCase()) === categoryName);
    }

    /**
     * @returns the matching subcategory (MegaMenuItem) if found in category
     */
    private getMegaMenuSubCategory(subCategoryUrl: string, category: IMegaMenuSubCategory): IMegaMenuItem {
        return category?.items.find((subCategory) => subCategory.url === subCategoryUrl);
    }

    /**
     * @returns the derived MegaMenu subsection from the route data
     */
    private getMegaMenuSectionFromRoute(route: ActivatedRouteSnapshot): 'kitchens' | 'units' | 'doors' | 'appliances' | 'sinks-and-taps' | 'worktops' | 'handles' | 'samples' | 'accessories' {
        const subSectionMap = {
            'sinks': 'sinks-and-taps',
            'taps': 'sinks-and-taps'
        }
        let subSection = route.data?.subsection || route.data?.section;
        subSection = subSectionMap[subSection] || subSection;

        return subSection;
    }

    /**
     * @returns the parent path e.g. /kitchen-units
     */
    private getSectionUrl(route: ActivatedRouteSnapshot): string {
        return `/${route.parent.routeConfig.path}`;
    }

    /**
     * @returns the url of the parent category e.g. /kitchen-units/base
     */
    private getCategoryUrl(route: ActivatedRouteSnapshot): string {
        // Override for sections of MegaMenu that have a 'styles' subcategory that is not part of the URL route
        if (this.categoryOverrideSections.includes(this.getMegaMenuSectionFromRoute(route))) {
            return this.getSectionUrl(route);
        }

        if (Array.isArray(route.url) && route.url.length) {
            return `${this.getSectionUrl(route)}/${route.url[0]}`;
        }

        return `${this.getSectionUrl(route)}`;
    }

    /**
     * @returns the relative url from the root e.g /kitchen-units/base/highline
     */
    private getRouteUrl(route: ActivatedRouteSnapshot): string {
        return `${this.getSectionUrl(route)}/${route.url.join('/')}`;
    }

    /**
     * @returns the last segment of the url
     */
    private getLastUrlSegment(route: ActivatedRouteSnapshot): string {
        if (Array.isArray(route.url) && route.url.length) {
            return route.url.pop().path;
        }
        return undefined;
    }

    /**
     * @returns whether the route is valid, despite not being present in the MegaMenu e.g. '/kitchens/allstyles'
     */
    private isValidNonMegaMenuRoute(route: ActivatedRouteSnapshot): boolean {
        const subCategoryException = [
            'allstyles', 'accessory', 'dekton', 'corner-posts', 'gables', 'regular-panels',
            'regular-plinths', 'ribbed-glazed-doors', 'special-panels', 'true-handleless-components',
            'solid-wood', 'granite', 'quartz'
        ];
        return subCategoryException.includes(this.getLastUrlSegment(route));
    }

    /**
     * @returns whether the route has a 'subCategory' that needs validating or not
     */
    private shouldValidateSubCategory(route: ActivatedRouteSnapshot): boolean {
        const validate = route.params.subCategory ||
            ((this.categoryOverrideSections.includes(this.getMegaMenuSectionFromRoute(route)) && route.url.length));
        return validate;
    }

    /**
     * @returns the parent of the route for fallback purposes. e.g /kitchen-units for the route /kitchen-units/base
     */
    private getFallbackRoute(route: ActivatedRouteSnapshot): string {
        if (this.categoryOverrideSections.includes(this.getMegaMenuSectionFromRoute(route))) {
            return this.getSectionUrl(route);
        } else {
            return this.getCategoryUrl(route);
        }
    }
}
