import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Config } from '@app/config';
import { URLHelper } from '@app/utilities/helpers';

import { BasketService } from '@app/services/basket';
import { CatalogueService } from '@app/services/catalogue';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute } from '@app/services/navigation/models';
import { StorageService } from '@app/services/storage';

@Component({
    selector: 'page-worktop-config',
    templateUrl: './config.page.html',
    styleUrls: ['./config.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class WorktopConfigPage implements OnInit, OnDestroy {
    public worktop: any;
    public worktopConfigUuid: string;
    public worktopConfig: any;
    public readOnly: boolean = false;

    public error: boolean = false;

    private routeSubscription: Subscription;

    constructor(
        private config: Config,
        private urlHelper: URLHelper,
        private basketService: BasketService,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private storageService: StorageService,
    ) {
        navigationService.setNavigation({
            title: 'Solid Surface Configuration',
            metaTags: [{ name: 'no-meta', content: 'no metadata' }]
        });
    }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe((route: NavigationRoute) => {
            this.error = false;

            if (route.params.details) {
                this.worktopConfigUuid = null;
                this.readOnly = false;
                this.catalogueService.getWorktops()
                    .then((worktops) => {
                        this.worktop = worktops.find((worktop) => {
                            return this.urlHelper.configRouteEnd(worktop) === route.params.details;
                        });

                        if (this.worktop) {
                            this.setRouteHistory();

                            this.storageService.get(this.config.storage.worktopConfig)
                                .then((data: any) => {
                                    if (data) {
                                        this.worktopConfig = data;
                                    } else {
                                        this.worktopConfig = null;
                                    }
                                })
                                .catch((error) => {
                                    this.worktopConfig = null;
                                });
                        }
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            } else if (route.params.worktopConfigUuid) {
                this.worktopConfigUuid = route.params.worktopConfigUuid;
                this.readOnly = (route.segments.indexOf('viewer') !== -1) ? true : false;
                this.basketService.worktopConfig(route.params.worktopConfigUuid)
                    .then((worktopConfig) => {
                        if (worktopConfig) {
                            this.storageService.set(this.config.storage.worktopConfig, worktopConfig.config)
                                .then(() => {
                                    this.catalogueService.getWorktops()
                                        .then((worktops) => {
                                            this.worktop = worktops.find((worktop) => {
                                                return worktop.sub_cat === worktopConfig.subCategory && worktop.thickness === worktopConfig.thickness;
                                            });

                                            if (this.worktop) {
                                                this.setRouteHistory();
                                                this.worktopConfig = worktopConfig.config;
                                            }
                                        })
                                        .catch((error) => this.dialogService.error(this.constructor.name, error));
                                })
                                .catch((error) => this.dialogService.error(this.constructor.name, error));
                        } else {
                            this.error = true;
                        }
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            }
        });
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }

    public saveConfig(config) {
        if (!this.readOnly) {
            this.storageService.set(this.config.storage.worktopConfig, config);
        }
    }

    private setRouteHistory() {
        this.navigationService.setRouteHistory([
            { title: 'Worktops', route: '/worktops' },
            {
                title: this.worktop.cat,
                route: this.urlHelper.fullRoute(this.worktop)
            },
            {
                title: `Cost Calculator: ${this.worktop.sub_cat}`,
                route: this.urlHelper.configRoute(this.worktop)
            }
        ]);
    }
}
