
<mat-dialog-content class="dimensions-input-calculator-dialog-content">
    <div class="header">
		<h5>
            @if(!isAccessory(item)) {
                {{item.rank}}
            }
           {{item.code}}
        </h5>
		<div class="close" (click)="close()">
			<mat-icon>close</mat-icon>
		</div>
	</div>

    <p>
        {{ getDescription() }}
    </p>
    
    @for (detail of getExtendedDetail(); track detail) {
        <p>{{detail}}</p>
    }

    <form [formGroup]="dimensionsInputForm">
        <table>
            <tr>
                <td>Height (mm):</td>
                <td>
                    <mat-form-field appearance="outline">
                            <input
                                matInput
                                type="number"
                                formControlName="height"
                                [matTooltip]="getValidationErrorMessage('height')"
                                matTooltipPosition="above"
                                matTooltipClass="tooltip" 
                                (change)="calculateCost()"/>
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>Width (mm):</td>
                <td>
                    <mat-form-field appearance="outline">
                            <input
                                matInput
                                type="number"
                                formControlName="width"
                                [matTooltip]="getValidationErrorMessage('width')"
                                matTooltipPosition="above"
                                matTooltipClass="tooltip" 
                                (change)="calculateCost()" />
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>Carcase Colour:</td>
                <td>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="carcaseColour" required [matTooltip]="getValidationErrorMessage('carcaseColour')" (selectionChange)="onCarcaseColourChange()">
                            @for (carcaseColour of carcaseColours; track carcaseColour) {
                                <mat-option [value]="carcaseColour.colour">{{carcaseColour.colour}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </td>
            </tr>
        </table>

        <div class="price-container">
            <button mat-flat-button color="primary" class="chunky" (click)="calculateCost()">Calculate</button>
            @if (dimensionsInputForm.valid && price) {
                <div class="price">
                    <strong [innerHTML]="price | currencyFormat:true:true"></strong>
                </div>
            }
        </div>
        
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <form [formGroup]="dimensionsInputForm">
        <div class="actions">
            @if (isAccessory(item)) {
                <div class="common-quantity">
                    <label>Quantity:</label>
                    <span>
                        <input type="number" min="1" placeholder="Qty" formControlName="qty" (change)="updateQty()">
                    </span>
                </div>
            
                <component-basket-add-button [activeRange]="activeRange()" [type]="type" [item]="item"
                [qty]="qty" [disabled]="!(dimensionsInputForm.valid && price)" (status)="onStatusReceived($event)"></component-basket-add-button>
            } @else {
                <component-basket-update-button [item]="item" [updates]="getUpdateParams()" [disabled]="!(dimensionsInputForm.valid && price)"
                (status)="onStatusReceived($event)"></component-basket-update-button>
            }
        </div>
    </form>
</mat-dialog-actions>    
