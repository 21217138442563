import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';

import { PlanOnlineRouting } from './plan-online.routing';
import { PlanOnlinePage } from './plan-online.page';

@NgModule({
    imports: [
        SharedModule,
        PlanOnlineRouting
    ],
    declarations: [
        PlanOnlinePage
    ]
})
export class PlanOnlinePageModule { }
