@import '/src/theme/variables';

component-change-style-dialog {
    margin: 0;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 15px;

        &::before {
            content: initial;
        }

        h5 {
            margin: 0;
        }

        .close {
            cursor: pointer;
        }
    }

    .style-options {
        display: flex;
        padding-top: 15px;

        .mat-mdc-form-field {
            & + .mat-mdc-form-field {
                margin-left: 15px;
            }
        }

        .autocomplete {
            .mat-mdc-form-field-infix {
                display: flex;
            }
        }
    }

    .item-list {
        margin: 15px 0;
        border-collapse: collapse;
        width: 100%;

        tr {

            th,
            td {
                padding: 10px;
                border: 1px solid #CCCCCC;
                background-color: #E1E1E1;
                color: #000000;
                font-size: 0.75rem;
                font-weight: 700;
            }

            td {
                background-color: #ffffff;
                font-weight: 400;
                word-break: break-all;

                &.checkbox {
                    padding: 0;
                    text-align: center;
                }
            }
        }

        .new-total {
            display: flex;
            align-items: center;
        }
    }

    .actions {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
    }
}