<div class="universal-login" *ngIf="state === 1">
    <iframe class="application-token-iframe" [src]="universalTokenLocation | safe:'resourceUrl'"></iframe>
</div>

<div class="customer-login-container">
    <div class="customer-login-dialog" [ngClass]="{ expand: (state === 5) }">
        <div class="customer-login-panel" *ngIf="state >= 4">
            <h3 *ngIf="state === 4 || state === 8 || state === 9">Login</h3>
            <h3 *ngIf="state === 5">Register</h3>
            <h3 *ngIf="state === 6">Reset Password</h3>
            <!-- <p class="small" *ngIf="state === 4 && loginType === 1">To view your account, see previous correspondence or to contact us, please enter your email address and password below.</p> -->
            <!-- <p class="small" *ngIf="state === 4 && loginType === 2">To amend your order, view previous orders or submit/view any aftersales issues please enter your order number and email address below.</p> -->

            <div class="switch-login-type" *ngIf="state === 4 || state === 8 || state === 9">
                <button mat-stroked-button color="secondary" *ngIf="loginType === 1" (click)="switchType(2)">Login with Order Number</button>
                <button mat-stroked-button color="secondary" *ngIf="loginType === 2" (click)="switchType(1)">Login with Password</button>
            </div>

            <form *ngIf="state <= 9" class="login-form" [formGroup]="loginForm" (keydown.enter)="authenticate()">
                <div class="details" *ngIf="state === 5">
                    <mat-form-field>
                        <input matInput #focusName [formControl]="firstName" placeholder="First Name" />
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput [formControl]="lastName" placeholder="Last Name" />
                    </mat-form-field>
                </div>

                <mat-form-field *ngIf="state <= 6">
                    <input matInput #focusEmail [formControl]="email" placeholder="Email" />
                </mat-form-field>

                <mat-form-field *ngIf="state === 5">
                    <input matInput [formControl]="contactNumber" placeholder="Contact Number" />
                </mat-form-field>

                <mat-form-field *ngIf="state === 4 && loginType === 2">
                    <input matInput [formControl]="orderNumber" placeholder="Order Number" />
                </mat-form-field>

                <p class="bold" *ngIf="state === 9">Set a new password on your account.</p>

                <div class="details" *ngIf="state === 5 || state === 8 || state === 9">
                    <mat-form-field *ngIf="state === 5 || state === 8 || state === 9">
                        <input matInput #focusPassword [formControl]="password" type="password" placeholder="Password" />
                    </mat-form-field>
                    <mat-form-field *ngIf="state === 5 || state === 9">
                        <input matInput [formControl]="passwordConfirm" type="password" placeholder="Confirm Password" />
                    </mat-form-field>
                </div>

                <p class="marketing-consent" *ngIf="state === 5">
                    By providing your contact details to DIY Kitchens, you will be indicating to us your consent for us to contact you by email and SMS to let you know about your order delivery or offers/promotions, which may be of interest to you, unless you indicate an objection to receiving such messages. You can also unsubscribe from marketing email communications from within our emails.
                </p>

                <p *ngIf="errorMessage" class="error-message">{{errorMessage}}</p>
                <p *ngIf="noticeMessage" class="notice-message">{{noticeMessage}}</p>

                <button mat-raised-button type="submit" class="submit-button" color="primary" [disabled]="authenticating || disablePasswordReset" (click)="authenticate()">
                    <span *ngIf="state === 4 && loginType === 1">Next</span>
                    <span *ngIf="state === 5">Register</span>
                    <span *ngIf="state === 6">Reset Password</span>
                    <span *ngIf="state === 8 || state === 9 || (state === 4 && loginType === 2)">Login</span>
                </button>
            </form>

            <div class="actions" *ngIf="state <= 9">
                <button mat-stroked-button color="secondary" *ngIf="state > 4 && state < 8" (click)="login()">Login</button>
                <button mat-stroked-button color="secondary" *ngIf="state <= 9 && state !== 6" (click)="forgotPassword()">Forgot password?</button>
                <button mat-stroked-button color="secondary" *ngIf="state !== 5" (click)="register()">Register</button>
            </div>

            <p class="small" *ngIf="state === 4 || state === 8 || state === 9">If you have a planner account you can use the same account to login.</p>

            <p *ngIf="state === 10">Success</p>

            <mat-divider></mat-divider>

            <div class="footer-text">DIY Kitchens.</div>
        </div>

        <div *ngIf="state <= 3">
            <p *ngIf="state === 0">Customer Login</p>
            <p *ngIf="state === 1">Validating Application</p>
            <p *ngIf="state === 2">Fetching Previous Session</p>
            <p *ngIf="state === 3">Previous Session Found</p>
        </div>
    </div>
</div>