<header class="content-contained">
    <h1>Why buy DIY Kitchens?</h1>
    <p>There are so many reasons why buying a kitchen from us makes perfect sense. You get British made to order custom units with quality components and fittings at extremely affordable prices. Buy a kitchen from DIY Kitchens and you'll never look back! To help you decide whether buying a DIY Kitchens is right for you, we've picked our top ten reasons to help you decide!</p>
</header>

<hr class="narrow" />

<component-sticky-tabs [tabs]="tabs" type="scroller"></component-sticky-tabs>

<section class="content-contained" [id]="StickyTabs.SELECTION">
    <h3 class="playfair">A Vast Selection of Quality Doors</h3>
    <p>DIY Kitchens offer a collection of kitchens to buy in Modern, Traditional, Painted, In-Frame and
        Handleless styles.</p>
    <p>DIY Kitchens source only the best quality kitchen doors, panels and decorative accessories using the
        UK's leading distributors. These products are manufactured by Europe's largest and most successful
        kitchen product manufactures. Based in Italy and Germany our suppliers are all experts in their
        specific methods of manufacture.</p>
    <p>Many of our competitors offer products of an inferior quality, sourced and manufactured in the Far
        East. The quality of products manufactured in these countries can be of a much lower quality and are
        also on a much longer lead-time to those produced in Europe. Buy a kitchen from DIY Kitchens and get
        it delivered in 2-3 weeks.</p>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.TRANSPARENT">
    <h3 class="playfair">Transparent Pricing Policy</h3>
    <p>Unlike many of our competitors<em><strong> we do not give you an inflated price</strong></em> when
        you buy a kitchen then offer 50% off for 10 months of the year and 'Free' appliances for the other
        two months! Our pricing policy is simple;<em><strong> we offer high quality products at reasonable
                prices</strong></em> because we work on lower margins. The prices you see are what you pay.
        We also make our prices transparent, so you can see the cost of your cabinets and doors separately.
    </p>
    <p>DIY Kitchens charge the same price for any of our {{diy.carcases}} different fully colour co-ordinate cabinet
        colours. With one click of the 'Change Kitchen Style' button in the shopping cart, you can compare
        the cost of your kitchen in any of our different kitchen designs. By organising the design and
        fitting aspects of the kitchen yourself you can save up to 75% over buying the same kitchen from a
        high street retailer.</p>
    <p>Unlike some of our competitors that claim to offer 'free' delivery we do not build delivery charges
        into our cabinet or door prices.</p>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.COORDINATED">
    <h3 class="playfair">Fully Colour Co-ordinated Cabinets</h3>
    <p>DIY Kitchens offer {{diy.carcases}} different cabinet colours. These are all fully colour coordinated both inside
        and out. We endeavour to provide you with the best possible carcase colour match to all of the door
        ranges that we offer. This has several benefits:-</p>
    <ul>
        <li>With cabinets it is not always necessary to have clad on panels, you can make the decision
            whether you require these or not depending on your budget and the space available within your
            kitchen.</li>
        <li>When all doors and drawers are closed you have no obvious white lines between the frontals.
            (This is especially visible on kitchens of a darker or stronger colour with a white or cream
            cabinet).</li>
        <li>Glass units and accessory units are all made from a co-ordinating cabinet material.</li>
    </ul>
    <p>You will notice that most of our competitors offer white cabinets, or a restricted choice of cabinet
        colours and door styles.</p>
    <p>DIY Kitchens cabinets are edged with 2mm durable ABS edging around the front edge. Many of our
        competitors use a melamine edging material that is more susceptible to chipping and moisture ingress
        over time, our ABS edging is more resistant in both of these instances.</p>
    <p>We are confident that with our vast array of door designs and cabinet colours we have the right
        kitchen for you.</p>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.WIDEST">
    <h3 class="playfair">Widest Range of Units Available</h3>
    <p>DIY Kitchens offer the widest possible selection of units available. This ensures even the most
        awkward kitchen layout shape or size can be planned successfully. Our aim is to provide you with
        your dream kitchen and not to force you to make compromises. There are over 1500 different unit and
        door options available to you to choose from!</p>
    <p>Most of our competitors will offer a limited number of unit choices and sizes that may not suit your
        particular room dimensions.</p>
    <p>All of our units are also available dry assembled; this can be specified in the 'extra information'
        box when a unit is added to the shopping cart. This allows certain units within a specific order to
        be delivered rigid, but not glued. This is especially useful if your kitchen layout has an awkward
        boxed section, or you are fitting a unit around a boiler.</p>
    <p>Within the 'Special instructions' box you can also request minor amendments or Size / Dimension
        changes to some units allowing you to create a kitchen that will work perfectly with the space you
        have. We do include a small surcharge for this service, but it can make a big difference to the
        overall look of your fitted kitchen.</p>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.INNOVATIVE">
    <h3 class="playfair">Innovative Quality Components</h3>
    <p>We work with the UKs largest and most forward thinking kitchen component distributors. We aim to be
        first to market with innovative kitchen components. Historically these have included some of the
        following. Curves etc.</p>
    <p>DIY Kitchens are in partnership with recognised market leading premium brands, Neff, Franke, Bosch,
        Elica to name a few. We not only take pride in manufacturing quality products but also supplying
        them.</p>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.RIGID">
    <h3 class="playfair">Rigid Assembly</h3>
    <p>DIY Kitchens supply <em><strong>rigid kitchen cabinets</strong></em>, using a combination of
        traditional techniques and modern manufacturing methods. Our cabinets are assembled from components
        that have glue and dowels automatically inserted into them before being clamped at high pressure
        into a special squaring clamp.</p>
    <p>Because we manufacture your cabinets specifically for you, <em><strong>we only drill them for hinges
                on the side that you require</strong></em>. Each cabinet is made to your exact
        specification. Unlike many of our competitors who sell cabinets with multi drilled unsightly holes.
    </p>
    <p>We <em><strong>manufacture all of our own cabinets </strong></em>so we can guarantee the quality
        through every step of the manufacturing process.</p>
    <p>Many of our competitors purchase flat pack cabinets or cabinet panels from a third party and cannot
        directly control the quality of these products.</p>
    <p><strong><em>Rigidity is a key quality feature</em></strong> of any DIY Kitchens kitchen. All of our
        carcase ends, shelves and base panels are made from 18mm Melamine Faced Chipboard (MFC). All base
        and wall units are supplied with 8mm solid back panels, providing stability, durability and
        reliability. Our drawerbox bases are made from 16mm MFC.</p>
    <p>Our cabinets are Rigid, not 'Fully Assembled' or 'Pre Assembled' like many of our competitors. Some
        companies use these terms to describe flat pack cabinets that they assemble before sending out to
        you. Flat Pack cabinets are susceptible to movement over time as they are held together with Cams,
        Dowels and Screws. They also have unsightly fixings visible on the outside of the cabinet, this is
        particularly visible under wall units and top boxes.</p>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.BLUM">
    <h3 class="playfair">Blum Hinges & Drawers</h3>
    <p>A quality kitchen cabinet needs to be equipped with high specification components. Every DIY Kitchens
        cabinet is fitted with Blum Clip Top Metal Hinges with 3-way adjustment and soft close. We use Blum
        Tandembox A design, twin walled metal sided drawer system with 16mm solid base and metal back
        incorporating full extension runners with integrated soft close dampers.</p>
    <p>DIY Kitchens are the only manufacturer in the county that supply the Blum Tandembox in a grey
        A-Design profile. We have this manufactured by Blum Austria especially for you as we believe it
        offers the best balance of soft close system and aesthetic appeal.</p>
    <p>We also offer a wide variety of accessories, including both metal or Intivo glass pan drawer sides on
        your deep drawers to stop things falling out. In fact, we were the first company in the UK to launch
        Intivo Glass pan drawer sides too!</p>
    <p>For a full range of metal and plastic cutlery inserts visit the Drawer / Pan Drawer accessories
        section of the website.</p>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.KESSEBOHMER">
    <h3 class="playfair">Kessebohmer Wirework</h3>
    <p>At DIY Kitchens we use Kessebohmer wirework; they are the market leaders in clever storage solutions.
    </p>
    <p>Kessebohmer products are manufactured to exacting standards and have won many European awards for
        design and innovation. When you use Kessebohmer products you can be sure your kitchen storage
        solutions will provide a lifetime of trouble-free service.</p>
    <p>Kessebohmer Features &amp; Benefits:-</p>
    <ul>
        <li>DIY Kitchens units are supplied with Kessebohmer storage solutions pre fitted. This will save
            you valuable time and money when installing.</li>
        <li>Kessebohmer wirework is full extension allowing you greater access to your storage.</li>
        <li>Kessebohmer pull-out units are fitted with 'softstop' soft close action to ensure a lifetime of
            smooth running.</li>
        <li>Kessebohmer wirework is chrome finished, many of our competitors offer dull grey powder coated
            versions.</li>
        <li>Kessebohmer wirework offers greater load bearing capacity than typical storage systems.</li>
    </ul>
    <p>Kessebohmer wirework is available with a selection of different basket options:-</p>
    <ul>
        <li>Classic Chrome tubular wire base and gallery rails.</li>
        <li>Arena Classic - Solid bases in gloss white or non slip grey with chrome tubular gallery rails.
        </li>
        <li>Arena Style - Solid bases in gloss white or non-slip grey with chrome flat bar gallery rails.
        </li>
    </ul>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.PAINTED">
    <h3 class="playfair">Painted Kitchens</h3>
    <p>The manufacturing side of DIY Kitchens has been painting kitchens for over 15 years, over this time
        we have gained a wealth of experience in this field. We offer kitchens painted to match any colour
        reference. All painted kitchen orders include a sample door painted to your desired finish.</p>
    <p>Many of our competitors offer painted kitchens in 2 or 3 colours or even 10-15 colours, but these are
        usually on a much longer lead-time. With DIY Kitchens you can choose from an infinite number of
        painted finishes and we only increase our lead-time to 3 weeks instead of 2!</p>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.DELIVERY">
    <h3 class="playfair">Delivery</h3>
    <p>We aim to deliver your kitchen promptly. Our usual lead-times are 2 weeks (3 weeks if we are painting
        your kitchen a bespoke colour) from point of order to delivery. Our next available delivery date is
        always displayed at the top of the website. In the check out phase of placing your order you can
        specify the earliest possible date for delivery or request a delivery as far into future as you
        require.</p>
    <p>We own our own fleet of delivery vehicles and can act as a '1 stop shop' if you choose to purchase
        worktops, appliances sinks, taps etc all in one go. This has the benefit of you only taking one
        delivery for everything you need for your new kitchen and we can also check that the appliances that
        you order will work with the units and housings on your order.</p>
</section>

<component-latest-offers></component-latest-offers>