@import '/src/theme/variables';

page-doors-listing {
    .kitchen-container {
        display: flex;
    }

    .kitchens {
        display: flex;
        flex-direction: column;
        margin-left: 30px;

        .banners {
            .narrow {
                margin: 15px 0;
            }
        }

        .kitchen {
            margin-top: 56px;

            .kitchen-header {
                display: flex;

                .header-left {
                    flex-grow: 1;

                    h2 {
                        margin-bottom: 5px;
                        font-size: 2.375rem;
                        font-family: font('opensans');
                        font-weight: 600;
                        text-transform: uppercase;
                        letter-spacing: -1.6px;
                    }

                    h3 {
                        color: color('black', 0.4);
                        font-size: 0.875rem;
                        font-weight: 700;
                        text-transform: uppercase;
                    }

                    p {
                        color: color('black', 0.4);
                        font-size: 1.375rem;
                        font-weight: 700;
                    }

                    .button-link {
                        margin-left: 10px;
                        color: color('black', 0.4);
                        font-weight: 600;
                        text-decoration: underline;
                    }
                }

                .header-right {
                    display: flex;

                    .kitchen-door {
                        margin-left: 30px;
                        margin-bottom: 15px;
                        width: 85px;
                    }
                }
            }

            .actions {
                display: flex;
                flex-wrap: wrap;

                .action {
                    margin: 5px;
                }

                button {
                    width: 200px;
                    padding: 5px 30px;
                    text-transform: uppercase;
                    min-height: 50px !important;
                    font-weight: 600 !important;
                }

                .detailButton {
                    font-family: 'Open Sans';
                    font-size: 12px;
                    min-height: 50px;
                    font-weight: 600;
                    letter-spacing: 0.1429em;
                    padding: 1rem;
                    border-radius: 0.25rem;
                    border: 1px solid;
                    color: #343a40;
                    border-color: #343a40;
                    background-color: #fff;
                    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                }

                .detailButton:hover {
                    color: #fff;
                    background-color: #343a40;
                    text-decoration: none;
                    cursor: pointer;
                }
            }

            .range-image {
                display: inline-block;
                position: relative;
                width: 100%;
            }
        }
    }

    .filters {
        flex: 0 0 290px;

        .filter {
            margin-bottom: 25px;
            padding-bottom: 25px;
            border-bottom: 1px solid color('copy-border');

            &.filter-range-type {
                .filter-image {
                    display: block;
                    width: 36px;
                    height: 50px;
                }
            }

            &.filter-colour {
                .filter-options {
                    grid-template-columns: repeat(4, minmax(0, 1fr));

                    .filter-option {
                        padding: 0;
                        border: 0;

                        a {
                            justify-content: center;
                        }
                    }
                }
            }

            .filter-options {
                display: grid;
                gap: 15px;
                grid-template-columns: repeat(2, minmax(0, 1fr));

                .filter-option {
                    padding: 5px;
                    border: 1px solid color('copy-border');

                    &.selected {
                        border-color: color('link');

                        a {
                            color: color('brand-primary');

                            .swatch {
                                border-color: color('brand-primary');
                            }
                        }
                    }

                    a {
                        display: flex;
                        align-items: center;
                        font-size: 0.75rem;
                        font-weight: 400;

                        .filter-image {
                            margin-right: 5px;
                        }

                        .swatch {
                            border: 1px solid color('copy-border');
                            width: 50px;
                            height: 50px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .content-header-background::before {
            background-image: none !important;
        }

        .content-header-background {
            p {
                padding-right: 0 !important;
            }
        }

        .kitchen-container {
            flex-direction: column;
        }

        .kitchens {
            margin-left: 0;

            .banners {
                .panel {
                    flex-direction: row;
                    justify-content: space-between;
                }
            }
        }

        .filters {
            .filter {
                &.filter-range-type {
                    .filter-options {
                        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
                    }
                }

                &.filter-colour {
                    .filter-options {
                        grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
                    }
                }
            }
        }
    }
}
