@import '/src/theme/variables';

component-order-summary {
    width: 100%;

    .section {
        h2 {
            margin: 0;
            padding: 0 20px;
            background-color: color('menu-dark');
            color: color('white');
            font-family: font('opensans');
            font-size: 1rem;
        }

        .sub-section {
            h3 {
                margin: 0;
                padding: 10px 20px;
                background-color: color('brand-primary');
                color: color('white');
                font-size: 1rem;
                text-transform: uppercase;
            }

            .sub-section-item {
                &+.sub-section-item {
                    border-top: 1px solid color('copy-border');
                }

                .sub-section-item-parent,
                .sub-section-item-child {
                    display: flex;
                }

                .sub-section-item-child {
                    .item-rank {
                        margin-left: 50px;
                    }
                }

                .item-rank {
                    p {
                        padding: 12px 5px;
                        background-color: color('brand-secondary');
                        width: 40px;
                        font-weight: 600;
                        text-align: center;
                    }
                }

                .item-details {
                    flex-grow: 1;

                    .item-description {
                        margin-bottom: 0;
                        padding: 5px;
                        padding-bottom: 0;
                        font-weight: 600;
                    }

                    .item-code {
                        margin-bottom: 0;
                        padding: 5px;
                        padding-top: 0;
                        font-size: 0.875rem;
                        font-weight: 400;
                    }

                    .item-note {
                        font-style: italic;
                        margin-bottom: 0;
                        padding: 5px;
                        padding-top: 0;
                        font-size: 0.875rem;
                        font-weight: 400;
                    }

                    .item-dimensions {
                        display: flex;
                        padding: 5px;

                        p {
                            margin: 0;
                            font-size: 0.875rem;
                            font-weight: 600;

                            &+p {
                                margin-left: 10px;
                            }

                            span {
                                font-weight: normal;
                            }
                        }
                    }
                }

                .item-options {
                    width: 100%;
                    max-width: 200px;

                    table {
                        padding: 5px;
                        width: 100%;

                        td {
                            width: 50%;
                            font-size: 0.875rem;

                            &+td {
                                font-weight: 600;
                            }
                        }
                    }
                }

                .item-quantity {
                    width: 100%;
                    max-width: 100px;
                    padding: 5px;

                    p {
                        margin: 0;
                        font-size: 0.875rem;
                        font-weight: 600;

                        span {
                            font-weight: normal;
                        }
                    }
                }

                .item-cost {
                    padding-right: 10px;
                    width: 100%;
                    max-width: 100px;
                    text-align: right;

                    p {
                        margin: 0;
                        padding: 5px;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .actions {
        margin-top: 15px;
        text-align: right;
    }

    .order-summary-totals {
        margin-left: auto;
        margin-bottom: 50px;
        text-align: right;

        h4 {
            margin: 0;
            font-size: 0.875rem;
        }

        p {
            margin: 0;
            margin-left: 15px;
            font-weight: 400;
        }
    }
}