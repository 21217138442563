
<mat-dialog-content>
    <header>
        <div class="toggle-selector">
            <button mat-stroked-button color="secondary" class="chunky-dark" (click)="onSwitchSelect(true)" [ngClass]="{ selected: toggleStyleSelection }">Select door style</button>
            <button mat-stroked-button color="secondary" class="chunky-dark"  (click)="onSwitchSelect(false)" [ngClass]="{ selected: !toggleStyleSelection }">Select unit colour</button>
        </div>

        <div class="close" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    </header>

    <div class="modal-content-cover">
        <ng-container *ngIf="toggleStyleSelection">
            <div class="styles" *ngIf="styles">
                <div class="search">
                    <mat-form-field class="search-form-field" appearance="outline">
                        <mat-label>Search Range / Colour...</mat-label>
                        <input matInput [(ngModel)]="searchStyleText" (keyup)="searchStyle()">
                        <mat-icon matSuffix color="warn" *ngIf="searchStyleText" (click)="clearSearchStyle()">close</mat-icon>
                    </mat-form-field>
                </div>

                <div class="style" *ngFor="let style of styles" (click)="filterStyle(style)" [ngClass]="{ 'selected': (style === selectedStyle) }">
                    {{ style }}
                </div>
            </div>

            <div class="doors" #doorsPanel>
                <ng-container *ngIf="doors && doors.length">
                    <div class="door" *ngFor="let door of doors" (click)="selectDoor(door)">
                        <img [src]="doorImage(door)" [alt]="door.range.name"/>
                        <p class="title">{{ door.range.name }}</p>
                        <p class="small">{{ door.colour.name }}</p>
                    </div>
                </ng-container>
                <ng-container *ngIf="!doors || !doors.length">
                    <p>We couldn't find any results for your search...</p>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="!toggleStyleSelection">
            <div class="cabinet-colours" *ngIf="carcaseColours">
                <div class="cabinet-colour" *ngFor="let carcaseColour of carcaseColours" (click)="selectCarcaseColour(carcaseColour)">
                    <img [src]="carcaseImage(carcaseColour)" [alt]="carcaseColour"/>
                    <p class="title"> {{ carcaseColour }}</p>
                </div>
            </div>
        </ng-container>

        <div class="unit-overview">
            <div class="cabinet-image">
                <div class="carcase-image">
                    <img [src]="carcaseImage(carcaseColour)" [alt]="carcaseColour"/>
                </div>

                <div *ngIf="isTrueHandleless" class="profile-image">
                    <img src="https://static.diy-kitchens.com/carcases/true_handleless_unit_profile_silver_3q_500px.png" [alt]="carcaseColour + ' Profile'"/>
                </div>

                <div [ngClass]="{'door-image': !isTrueHandleless, 'th-door-image': isTrueHandleless}">
                    <img [src]="unitDoorImage()" [alt]="carcaseColour + ' Door'"/>
                </div>
            </div>

            <div class="cabinet-details">
                <p class="title">{{!range ? 'Not Selected' : range?.name + ' ' + (rangeColour || '') }}</p>
                <p class="small">{{!carcaseColour ? 'Please choose a door style' : (carcaseColour || '') }} (Carcase)</p>

            </div>

            <div *ngIf="rangeColour === 'Bespoke'">
                <p class="small error">Type in a Bespoke Colour of your choice, or type a unique name and check the box below if you are going to send us a sample to colour match.</p>
                <mat-form-field appearance="outline">
                    <mat-label class="mat-label">Select bespoke colour </mat-label>
                    <input matInput [(ngModel)]="searchBespokeText" name="searchBespokeText" (ngModelChange)="filterBespokeColours()" [matAutocomplete]="auto" (blur)="confirmColour()">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onBespokeColourSelected($event)">
                        <mat-option *ngFor="let bespokeColour of filteredBespokeColours" [value]="bespokeColour.code">
                            <span>{{ bespokeColour.code }}</span>
                            <small *ngIf="bespokeColour.source"> ({{ bespokeColour.source }})</small>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-checkbox [(ngModel)]="sampleColourSelected" *ngIf="rangeColour === 'Bespoke'">
                    Supply Colour Sample
                </mat-checkbox>

                <p class="error-message" *ngIf="sampleColourSelected && !isSelectionValid()">Please enter a unique name for your bespoke colour above</p>
            </div>

            <p class="small"><strong>Please note</strong> that all images and colours are for illustrative purposes only and we strongly recommend ordering a <a class="button-link" routerLink="/samples" title="Sample">sample</a> of both the door and the carcase before placing your order.</p>
            <p class="small"><strong>Please also note</strong> that images and colours can look different based upon your monitor/device settings.</p>

            <button mat-flat-button color="primary" (click)="saveSelection()" [disabled]="!isSelectionValid()" >Confirm style selection</button>
        </div>
    </div>
</mat-dialog-content>
