import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from '@app/shared/page-not-found.component';
import { WoodWorktopsPage } from './wood-worktops.page';

const routes: Routes = [];

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: WoodWorktopsPage,
                pathMatch: 'full'
            },
            { path: '**', component: PageNotFoundComponent }
        ])
    ],
    exports: [RouterModule]
})
export class WoodWorktopsRouting { }
