import { Component, ViewEncapsulation, Input, Output, EventEmitter, OnInit, } from '@angular/core';
import { CheckoutService } from '@app/services/checkout/checkout.service';
import { DialogService } from '@app/services/dialog';
import { Config } from '@app/config';
import { BasketApiErrors } from './models';
import { AuthService } from '@app/services/auth';
import { NavigationService } from '@app/services/navigation';
import { BasketService } from '@app/services/basket';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Basket } from '@app/services/basket/models/basket.model';
import { OrderService } from '@app/services/order';
import { NumberHelper } from '@app/utilities/helpers';
import { CatalogueService } from '@app/services/catalogue';
import { MonekService } from '@app/services/monek';
import { StorageService } from '@app/services/storage';
import { DateHelper } from '@app/utilities/helpers';

@Component({
    selector: 'component-monek-legacy-payment',
    templateUrl: './monek-legacy-payment.component.html',
    styleUrls: ['./monek-legacy-payment.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class MonekLegacyPaymentComponent implements OnInit {
     //TODO: Bug that buttons are not disabled on click and busy
     @Input() basket: Basket = null;
     @Input() order: any = null;
     @Input() orderNumber: string = null;
     @Input() stepper: any;
     public selected = new FormControl(0);
 
     public maxChargeableAmount: number = 20000;
 
     public paymentStarted: boolean = false;
     public prePaymentMode: number = 0;
     public paymentMode: number = 0;
     public refundLeftToAllocate: number = 0;
     public startingRefundLeftToAllocate: number = 0;
     public splitPaymentMode: boolean = false;
     public paymentHistoryLoaded: boolean = false;
     public paymentHistory = [];
     public refundableHistory = [];
     public refundableAmount = 0;
 
     public splitPaymentForm: FormGroup;
     public refundForm: FormGroup;
 
     public paymentAmountControl: FormControl = new FormControl('', [
         Validators.required
     ]);
 
     public paymentReferenceControl: FormControl = new FormControl('', [
         Validators.required
     ]);
 
     private errorMessages = {
         [BasketApiErrors.BASKET_LOCKED]: '<p><strong>Please note:</strong> The cutoff period for the order has already passed. If you need something changed on your order, please contact customer support.</p>',
         [BasketApiErrors.BASKET_NOT_FOUND]: '<p><strong>Please note:</strong> Error occured while attempting to retrieve your basket. Please contact customer support</p>',
         [BasketApiErrors.CONTAINS_OUT_OF_RANGE_ITEM]: '<p><strong>Please note:</strong> Some of your items are unavailable in specific range</p>',
         [BasketApiErrors.MINIMUM_COST_NOT_MET]: '<p><strong>Please note:</strong> A minimum order value of {{ minOrderValue | currencyFormat }} inc. VAT is required to checkout this order. This cost excludes the delivery fee.</p>',
         [BasketApiErrors.NO_BASKET_ITEMS]: '<p><strong>Please note:</strong> There are no basket items for this order.</p>',
         [BasketApiErrors.NO_UNIT_IN_BASKET]: '<p><strong>Please note:</strong> We don\'t accept door, appliance, worktop, sink, tap or handle only orders.</p>'
     }
 
     public error: BasketApiErrors;
     public BasketError = BasketApiErrors;
     public minOrderValue = this.config.payments.minOrderValue;
     public paymentDetails = {
         oustandingAmount: 0,
         totalPaid: 0,
         preauthAmount: 0,
         preauthRefunded: 0,
         saleAmount: 0,
         saleRefunded: 0
     };

     private paymentCacheKey = 'payment-gateway-initiated';
     private paymentCacheMinutes = 30;
 
     constructor(
         private monekService: MonekService,
         private dialogService: DialogService,
         private authService: AuthService,
         private basketService: BasketService,
         private orderService: OrderService,
         private catalogeService: CatalogueService,
         private config: Config,
         private navigationService: NavigationService,
         private formBuilder: FormBuilder,
         private storageService: StorageService
     ) {
         this.refundForm = this.formBuilder.group({});
     }
 
     ngOnInit(): void {
         if (this.order) {
             this.paymentDetails = this.orderService.paymentDetails(this.order);
             this.paymentHistory = this.order.paymentHistory;
         } else {
             this.paymentDetails = this.basketService.paymentDetails(this.basket);
             this.paymentHistory = this.basket.paymentHistory;
         }
 
         this.refundLeftToAllocate = Math.abs(this.paymentDetails.oustandingAmount);
         this.startingRefundLeftToAllocate = Math.abs(this.paymentDetails.oustandingAmount);
 
         let paymentAmountValidator = [];
         paymentAmountValidator.push(Validators.max(this.paymentDetails.oustandingAmount > this.maxChargeableAmount ? this.maxChargeableAmount : this.paymentDetails.oustandingAmount));
         paymentAmountValidator.push(Validators.min(1));
         paymentAmountValidator.push(Validators.required);
 
         let paymentReferenceValidator = [];
         paymentReferenceValidator.push(Validators.max(45));
         paymentReferenceValidator.push(Validators.min(6));
         paymentReferenceValidator.push(Validators.required);
 
         this.paymentAmountControl = new FormControl('0', paymentAmountValidator);
         this.paymentReferenceControl = new FormControl('', paymentReferenceValidator);
         this.splitPaymentForm = this.formBuilder.group({
             paymentAmount: this.paymentAmountControl,
             paymentReference: this.paymentReferenceControl
         });
 
         if (this.paymentHistory && this.paymentHistory.length) {
             this.paymentHistory.forEach((payment) => {
                 if (payment.status === 'APPROVED' && (payment.txntype === 'preauth' || payment.txntype === 'sale')) {
                     if (payment.chargeTotal !== payment.refundedAmount) {
                         this.refundForm.addControl(
                             `paymentRefundControl-${payment.id}`,
                             new FormControl(
                                 0, [Validators.required, Validators.max(payment.chargeTotal)]
                             )
                         );
                         this.refundableHistory.push(payment);
                     }
 
                     if (payment.txntype === 'preauth') {
                         this.refundableAmount = this.refundableAmount + (payment.chargeTotal || 0);
                     } else if (payment.txntype === 'sale') {
                         this.refundableAmount = this.refundableAmount + (payment.chargeTotal || 0) - (payment.refundedAmount || 0);
                     }
                 }
             });
 
             if (this.paymentDetails.oustandingAmount < 0 && this.refundableHistory && this.refundableHistory.length === 1) {
                 this.allocateMax(this.refundableHistory[0]);
             }
         }
         this.paymentHistoryLoaded = true;
         if (this.paymentDetails.oustandingAmount > this.maxChargeableAmount) {
             this.goToPaymentOption(3);
             this.selected.setValue(2);
         } else {
             this.goToPaymentOption(1);
         }
     }
 
     public tabChange(event) {
         let paymentMode = event.index;
         if (this.paymentDetails.oustandingAmount > this.maxChargeableAmount) {
             paymentMode++;
         }
         this.goToPaymentOption(paymentMode + 1);
     }
 
     public goToPaymentOption(paymentMode) {
         this.paymentMode = paymentMode;
 
         switch (paymentMode) {
             case 1:
                 if (this.refundableHistory && this.refundableHistory.length) {
                     this.paymentAmountControl.setValue(this.paymentDetails.oustandingAmount);
                     this.splitPaymentMode = false;
                     this.paymentMode = 3;
                 } else {
                     this.paymentReferenceControl.setValue('Main');
                     this.paymentMode = 3;
                     this.splitPaymentMode = false;
                     // this.payInFull();
                 }
                 break;
             case 2:
                 // this.paymentStarted = true;
                 // if (this.basket) {
                 // this.basketService.basketToOrder(this.basket.id)
                 //     .then((response) => {
                 //         this.navigationService.navigate([`/checkout/order/${response}`]);
                 //     })
                 //     .catch((error) => this.dialogService.error(this.constructor.name, error))
                 // }
                 break;
             case 3:
                 this.splitPaymentMode = true;
                 this.paymentReferenceControl.setValue('Split Card');
                 break;
         }
     }
 
     public proceedWithBACS() {
         if (!this.order) {
             this.basketService.convertToOrder(this.basket.uuid, true)
                 .then((response) => {
                    //  this.catalogeService.unsetActiveRange();
                     this.basketService.clearAll(true)
                         .then(() => {
                             this.navigationService.navigate([`/checkout/order/${response.orderNumber}/bacs`]);
                         })
                         .catch((error) => this.dialogService.error(this.constructor.name, error));
                 })
                 .catch((error) => {
                     this.handleError(error)
                         .then(() => this.dialogService.error(this.constructor.name, error))
                         .catch((error) => this.dialogService.error(this.constructor.name, error));
                 });
         } else {
             this.orderService.markPayWithBacs(this.order.orderNumber)
                 .then(() => {
                    //  this.catalogeService.unsetActiveRange();
                     this.basketService.clearAll(true)
                         .then(() => {
                             this.navigationService.navigate([`/checkout/order/${this.order.orderNumber}/bacs`]);
                         })
                         .catch((error) => this.dialogService.error(this.constructor.name, error));
                 })
                 .catch((error) => {
                     this.handleError(error)
                         .then(() => this.dialogService.error(this.constructor.name, error))
                         .catch((error) => this.dialogService.error(this.constructor.name, error));
                 });
         }
     }
 
     public refundBasketAndCreateOrder() {
         let refunds = this.getRefundsToDo();
         this.getMonekFormInputs(this.paymentReferenceControl.value, refunds, null)
             .then((response) => {
                //  this.catalogeService.unsetActiveRange();
                 this.basketService.clearAll(true)
                     .then(() => {
                         if (response && response.redirectToOrder) {
                             this.navigationService.navigate([`/checkout/order/${response.orderNumber}/refund`]);
                         } else if (response.formInputs) {
                             this.launchPaymentForm(response.host, response.formInputs);
                         }
                     })
                     .catch((error) => this.dialogService.error(this.constructor.name, error));
             })
             .catch((error) => this.dialogService.error(this.constructor.name, error));
     }
 
     private getRefundsToDo() {
         let refundsToDo = [];
         Object.keys(this.refundForm.controls).forEach((key) => {
             let idSplit = key.split('-');
             let refundValue = parseFloat(this.refundForm.controls[key].value);
             if (refundValue && idSplit.length === 2) {
                 refundsToDo.push({
                     amount: refundValue,
                     id: idSplit[1]
                 });
             }
         });
         return refundsToDo;
     }
 
     public createOrderNoPayment() {
         this.basketService.convertToOrder(this.basket.uuid)
             .then((response) => {
                //  this.catalogeService.unsetActiveRange();
                 this.basketService.clearAll(true)
                     .then(() => {
                         this.navigationService.navigate([`/checkout/order/${response.orderNumber}/no-payment`]);
                     })
                     .catch((error) => this.dialogService.error(this.constructor.name, error));
             })
             .catch((error) => {
                 this.handleError(error)
                     .then(() => this.dialogService.error(this.constructor.name, error))
                     .catch((error) => this.dialogService.error(this.constructor.name, error));
             });
     }
 
     public ensurePositiveNumber(number) {
         return Math.abs(number);
     }
 
     public payInFull() {
         this.paymentStarted = true;
         this.getMonekFormInputs(this.paymentReferenceControl.value, [], null)
             .then((response) => {
                //  this.catalogeService.unsetActiveRange();
                 this.basketService.clearAll(true)
                     .then(() => {
                         if (response && response.redirectToOrder) {
                             this.navigationService.navigate([`/checkout/order/${response.orderNumber}/pay-in-full`]);
                         } else if (response.formInputs) {
                            this.storageService.set(this.paymentCacheKey, {
                                orderNumber: response.formInputs.orderid,
                                redirect: `/checkout/order/${response.formInputs.orderid}`,
                                expiry: DateHelper.format(DateHelper.moment().add(this.paymentCacheMinutes, 'minutes'))
                            });
                             this.launchPaymentForm(response.host, response.formInputs);
                         }
                     })
                     .catch((error) => this.dialogService.error(this.constructor.name, error));
             })
             .catch((error) => this.dialogService.error(this.constructor.name, error));
     }
 
     public proceedToSplitPayment() {
         if (this.paymentAmountControl.status !== 'VALID' ||
             this.paymentReferenceControl.status !== 'VALID') {
             this.splitPaymentForm.markAllAsTouched();
             return;
         }
 
         this.paymentStarted = true;
 
         this.getMonekFormInputs(this.paymentReferenceControl.value, [], this.paymentAmountControl.value)
             .then((response) => {
                //  this.catalogeService.unsetActiveRange();
                 this.basketService.clearAll(true)
                     .then(() => {
                         if (response && response.redirectToOrder) {
                             this.navigationService.navigate([`/checkout/order/${response.orderNumber}/split-payment`]);
                         } else if (response.formInputs) {
                            this.storageService.set(this.paymentCacheKey, {
                                orderNumber: response.formInputs.orderid,
                                redirect: `/checkout/order/${response.formInputs.orderid}`,
                                expiry: DateHelper.format(DateHelper.moment().add(this.paymentCacheMinutes, 'minutes'))
                            });
                             this.launchPaymentForm(response.host, response.formInputs);
                         }
                     })
                     .catch((error) => this.dialogService.error(this.constructor.name, error));
             })
             .catch((error) => this.dialogService.error(this.constructor.name, error));
     }
 
     public ensureNumeric(event) {
         if ([46, 8, 9, 27, 13, 110, 190].indexOf(event.keyCode) !== -1 ||
             // Allow: Ctrl+A
             (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
             // Allow: Ctrl+C
             (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
             // Allow: Ctrl+V
             (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
             // Allow: Ctrl+X
             (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
             // Allow: home, end, left, right
             (event.keyCode >= 35 && event.keyCode <= 39)) {
             // let it happen, don't do anything
 
             return;
         }
 
         // Ensure that it is a number and stop the keypress
         if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
             event.preventDefault();
             return;
         }
     }
 
     public ensureMax(event) {
         let maxAmount = this.paymentDetails.oustandingAmount > this.maxChargeableAmount ? this.maxChargeableAmount : this.paymentDetails.oustandingAmount;
         if (parseFloat(this.paymentAmountControl.value) > maxAmount) {
             this.paymentAmountControl.setValue(maxAmount);
         }
     }
 
     public allocateMax(payment) {
         let formControl = this.refundForm.controls[`paymentRefundControl-${payment.id}`];
         formControl.setValue(payment.chargeTotal);
 
         this.recalculateRefundLeftToAllocate(payment);
     }
 
     public getChargedAmount(payment) {
         switch (payment.txntype) {
             case 'preauth':
                 return payment.chargeTotal;
             case 'sale':
                 return payment.chargeTotal - (payment.refundAmount || 0);
         }
     }
 
     public recalculateRefundLeftToAllocate(payment) {
         let formControlKey = `paymentRefundControl-${payment.id}`;
         let formControl = this.refundForm.controls[formControlKey];
         let max = this.getChargedAmount(payment);
         // let value: any = parseFloat(formControl.value);
         let value: any = formControl.value;
         let valueForced = false;
 
         if (isNaN(value)) {
             value = 0;
         }
 
         // value = Math.round(value * 100) / 100;
 
         if (value > max) {
             value = max;
             valueForced = true;
         }
 
         let outstanding = Math.abs(this.paymentDetails.oustandingAmount);
 
         Object.keys(this.refundForm.controls).forEach((key) => {
             if (key !== formControlKey) {
                 outstanding = outstanding - NumberHelper.toPoundsAndPence(parseFloat(this.refundForm.controls[key].value));
             }
         })
 
         if ((outstanding - value) < 0) {
             value = outstanding;
             valueForced = true;
         }
 
         // if (!valueForced && formControl.value.toString().endsWith('.')) {
         //     value = `${value}.`;
         // }
 
         if (!valueForced && formControl.value.toString().match(/\.\d{2}/)) {
             value = NumberHelper.toPoundsAndPenceFloored(value);
         }
 
         this.refundForm.controls[formControlKey].setValue(NumberHelper.toPoundsAndPence(value));
 
         this.refundLeftToAllocate = outstanding - value;
     }
 
     private getMonekFormInputs(paymentReference, refundsToDo, amount): Promise<any> {
         if (this.order) {
             return new Promise((resolve, reject) => {
                 this.monekService.goToMonek(this.order.orderNumber, refundsToDo, paymentReference, amount)
                     .then((response) => resolve(response))
                     .catch((error) => {
                         this.handleError(error)
                             .then(() => reject(error))
                             .catch((error) => this.dialogService.error(this.constructor.name, error));
                     });
             });
         } else {
             return new Promise((resolve, reject) => {
                 this.monekService.createOrderAndGoToMonek(this.basket.uuid, refundsToDo, paymentReference, amount)
                     .then((response) => resolve(response))
                     .catch((error) => {
                         this.handleError(error)
                             .then(() => reject(error))
                             .catch((error) => this.dialogService.error(this.constructor.name, error));
                     });
             });
         }
     }
 
     private launchPaymentForm(host, formInputs) {
         let form = document.createElement('form');
         form.setAttribute('method', 'post');
         form.setAttribute('action', host);
         form.setAttribute('style', 'display:none');
 
         Object.keys(formInputs).forEach((key) => {
             let input = document.createElement('input');
             input.setAttribute('type', 'hidden');
             input.setAttribute('name', key);
             input.setAttribute('value', formInputs[key]);
             form.appendChild(input);
         });
 
         let submitButton = document.createElement('button');
         submitButton.setAttribute('type', 'submit');
         form.appendChild(submitButton);
 
         document.body.appendChild(form);
         submitButton.click();
     }
 
     private handleError(error): Promise<void> {
         return new Promise((resolve, reject) => {
             this.error = error.body?.message;
             let beforeConversion = error.body?.beforeConversion;
             let orderId = error.body?.orderId;
             let orderNumber = error.body?.orderNumber;
 
             if (error === 'Invalid Token') {
                 this.authService.login()
                     .then((isAuthed) => {
                         if (isAuthed) {
                             this.stepper.previous();
                             resolve();
                         } else {
                             // TODO: Not Sure?
                         }
                     })
                     .catch((error) => this.dialogService.error(this.constructor.name, error));
             }
 
             if (beforeConversion || !this.error) {
                 let defaultError = '<p><strong>Please note:</strong> An error has occured. Please try again. If this error re-occurs, please contact customer support and quote error code 6001</p>';
                 this.dialogService.html(
                     'Error occured',
                     this.errorMessages[this.error] ? this.errorMessages[this.error] : (this.error || defaultError),
                     {
                         minWidth: '80%',
                         minHeight: '540px',
                         data: {
                             showCloseButton: false,
                             buttons: [{ label: 'Retry', action: true }]
                         }
                     }
                 )
                     .then(() => {
                         this.stepper.previous();
                         // this.stepBackFromPayment.emit();
                     })
                     .catch((error) => this.dialogService.error(this.constructor.name, error));
             } else {
                 if (orderNumber) {
                     let displayError = `<p><strong>Please note:</strong> ${this.error} <br/><br/> Dont worry, as we have recieved your order and will get in contact soon.</p>`
                     this.dialogService.html(
                         'Error occured',
                         displayError,
                         {
                             minWidth: '80%',
                             minHeight: '540px',
                             data: {
                                 showCloseButton: false,
                                 buttons: [{ label: 'Retry Payment', action: true }, { label: 'Wait for Support', action: false }]
                             }
                         }
                     )
                         .then((actionResponse) => {
                             this.basketService.clearAll()
                                 .then(() => {
                                     this.basketService.load();
                                 })
                                 .catch((error) => this.dialogService.error(this.constructor.name, error));
 
                             if (actionResponse) {
                                 this.navigationService.navigate([`/checkout/order/${orderNumber}/error`]);
                             } else {
                                 this.navigationService.navigate([`/`]);
                             }
                         })
                         .catch((error) => this.dialogService.error(this.constructor.name, error));
                 } else {
                     // Serious error occured here. Not known if basket has been order converted.
                     let displayError = `<p><strong>Please note:</strong> ${this.error}</p>`
                     this.dialogService.html(
                         'Error occured',
                         displayError,
                         {
                             minWidth: '80%',
                             minHeight: '540px',
                             data: {
                                 showCloseButton: false,
                                 buttons: [{ label: 'Retry', action: true }]
                             }
                         }
                     )
                         .then(() => {
                             this.stepper.previous();
                             // this.stepBackFromPayment.emit();
                         })
                         .catch((error) => this.dialogService.error(this.constructor.name, error));
                 }
             }
 
             resolve();
         });
     }
 }
 