import { AbstractControl, ValidationErrors } from '@angular/forms';

import { FormValidator } from './models';

export class PhoneNumberValidator implements FormValidator {
    private phoneNumberRegExp = new RegExp(/^(07|01|02)\d{9}$/);

    public validate(control: AbstractControl): ValidationErrors | null {
        if (control.value) {
            const cleanValue = control.value.replace(/\s/g, '')
                .replace(/^\+/, '')
                .replace(/^44/, '0');

            // TODO: get custom form group interface working
            control['cleanValue'] = cleanValue;

            if (cleanValue.match(this.phoneNumberRegExp)) {
                return null;
            } else {
                return { phoneNumberValid: false };
            }
        } else {
            return null;
        }
    }
}
