<div class="loader" *ngIf="loading">
    <mat-spinner></mat-spinner>

    <div class="loader-message">Loading Information...</div>
</div>

<ng-container *ngIf="!loading">
    <ng-template #itemTemplate let-item="item">
        <div class="item-rank">
            <p>{{item.rank}}</p>
        </div>
        <div class="item-details">
            <p class="item-description">{{item.description}}</p>
            <p class="item-code"><span>Code:</span> {{item.code}}</p>
            <p class="item-note" *ngFor="let note of item.notes">{{note.note}}</p>
            <div class="item-dimensions">
                <p *ngIf="item.height"><span>H:</span> {{item.height}}</p>
                <p *ngIf="item.width"><span>W:</span> {{item.width}}</p>
                <p *ngIf="item.depth"><span>D:</span> {{item.depth}}</p>
            </div>
        </div>
        <div class="item-options">
            <table>
                <tr *ngIf="item.group === 'Cabinets'">
                    <td>Cabinet:</td>
                    <td>{{ item.carcaseColour }}</td>
                </tr>
                <tr *ngIf="item.group === 'Cabinets' && item.handed">
                    <td>{{hingeWording(item)}} Side</td>
                    <td>{{item.handing == Handing.RH ? 'Right' : 'Left' }}</td>
                </tr>
                <tr *ngIf="item.group === 'Cabinets' && item.hingeCost">
                    <td>Soft Close:</td>
                    <td>{{ item.softClose ? 'Yes' : 'No' }}</td>
                </tr>
                <tr *ngIf="item.group === 'Cabinets' && item.width > 600 && item.depth > 600 && !item.dryAssembledAlways">
                    <td>Dry Assembled:</td>
                    <td>{{ item.isDryAssembled ? 'Yes' : 'No' }}</td>
                </tr>
            </table>
        </div>
        <div class="item-quantity">
            <p><span>Quantity:</span> {{ item.qty }}</p>
        </div>
        <div class="item-cost">
            <p *ngIf="(item.salePrice || item.cost || item.normalPrice) > 0" [innerHTML]="(item.salePrice || item.cost || item.normalPrice) * item.qty | currencyFormat"></p>
        </div>
    </ng-template>

    <div class="section" *ngFor="let section of sections">
        <h2>{{section.title}}</h2>

        <div class="sub-section" *ngFor="let subSection of section.subSections">
            <h3>{{subSection.category}}</h3>

            <ng-container *ngFor="let item of subSection.items">
                <div class="sub-section-item" *ngIf="item.rankParent === '0' || item.rankParent === 0">
                    <div class="sub-section-item-parent">
                        <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item }"></ng-container>
                    </div>

                    <ng-container *ngIf="item._children && item._children.length">
                        <div class="sub-section-item-child" *ngFor="let childItem of item._children">
                            <ng-container *ngTemplateOutlet="itemTemplate; context: { item: childItem }"></ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
    <table class="order-summary-totals">
        <tbody>
            <tr>
                <td>
                    <h4>Sub-total (ex VAT):</h4>
                </td>
                <td>
                    <p>{{ orderCost | currencyFormat }}</p>
                </td>
            </tr>
            <tr *ngIf="deliveryCost > 0">
                <td>
                    <h4>Delivery Charge (ex VAT):</h4>
                </td>
                <td>
                    <p>{{ deliveryCost | currencyFormat }}</p>
                </td>
            </tr>
            <tr *ngIf="deliverySurCharge > 0">
                <td>
                    <h4>Delivery Sur-Charge (ex VAT):</h4>
                </td>
                <td>
                    <p>{{ deliverySurCharge | currencyFormat }}</p>
                </td>
            </tr>
            <tr>
                <td>
                    <h4>Net-total (ex VAT):</h4>
                </td>
                <td>
                    <p>{{ costs.netTotal | currencyFormat }}</p>
                </td>
            </tr>
            <tr>
                <td>
                    <h4>VAT:</h4>
                </td>
                <td>
                    <p>{{ costs.vat | currencyFormat }}</p>
                </td>
            </tr>
            <tr>
                <td>
                    <h4>Grand-total (inc VAT):</h4>
                </td>
                <td>
                    <p>{{ costs.grandTotal | currencyFormat }}</p>
                </td>
            </tr>
            <tr *ngIf="costs.charged">
                <td>
                    <h4>Already Paid:</h4>
                </td>
                <td>
                    <p>{{ costs.charged | currencyFormat }}</p>
                </td>
            </tr>
            <tr *ngIf="costs.charged && costs.outstandingAmount > 0">
                <td>
                    <h4>Outstanding Amount:</h4>
                </td>
                <td>
                    <p>{{ costs.outstandingAmount | currencyFormat }}</p>
                </td>
            </tr>
            <tr *ngIf="costs.charged && costs.outstandingAmount < 0">
                <td>
                    <h4>To Refund:</h4>
                </td>
                <td>
                    <p>{{ Math.abs(costs.outstandingAmount) | currencyFormat }}</p>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="actions" *ngIf="showButton">
        <button mat-flat-button color="primary" (click)="nextStep()">
            <mat-icon>arrow_forward</mat-icon>CONTINUE TO PAYMENT
        </button>
    </div>
</ng-container>
