import { ValidatorFn, Validators } from '@angular/forms';
import { PromotionStatus } from './promotion-status.enum';
import { PromotionType } from './promotion-type.enum';
import { ProductType } from '@app/services/catalogue/models';
import { BasketItem } from '@app/services/basket/models';
import { IPromotionApplicables } from './promotion-type-rules.model';

/**
 * Promotion class and associated interfaces and classes.
 * A Promotion can be intantiated with either no arguments, in which case all values are set to defaults, or with an object of either
 * IPromotion or Partial<IPromotion>, in this case the values are first set to their defaults and then overwritten with any values provided
 * in the argument. Attributes of the Promotion class that are common to all promotion types are present as named fields. However, there are
 * also 'conditionalFields' which hold the attributes to a given type of promotion.
 *
 * Conditional Fields.
 * The constructor of the Promotion class has a hard-coded object 'conditionalPromotionFields' which holds the array of field objects for each
 * type of promotion. The correct subset of fields is added to the Promotion by the constructor.
 */

export interface IPromotion {
    id?: number;
    name: string;
    description: string;
    startDate: string;
    endDate: string;
    type: PromotionType;
    status: PromotionStatus;
    banners?: IPromotionBanner[];
    productOverlayImage: IPromotionMedia;
    listingOverlayImage: IPromotionMedia;
    conditionalFields: IPromotionField[];
    saleCard: IPromotionSaleCard;
    terms: IPromotionTerms;
    rules: IPromotionApplicables;
}

export class Promotion implements IPromotion {
    id?: number;
    name: string;
    description: string;
    startDate: string;
    endDate: string;
    type: PromotionType;
    status: PromotionStatus;
    banners?: IPromotionBanner[];
    productOverlayImage: IPromotionMedia;
    listingOverlayImage: IPromotionMedia;
    conditionalFields: IPromotionField[];
    saleCard: IPromotionSaleCard;
    terms: IPromotionTerms;
    rules: IPromotionApplicables;

    conditionalPromotionFields: {};

    constructor(init?: Partial<IPromotion>) {
        this.conditionalPromotionFields = {
            [PromotionType.BASIC]: [
                {
                    name: 'carcaseDiscount',
                    label: 'Carcase Discount %',
                    value: 0,
                    type: 'number',
                    validators: [Validators.required, Validators.min(0), Validators.max(100)],
                },
                {
                    name: 'doorsDiscount',
                    label: 'Doors Discount %',
                    value: 0,
                    type: 'number',
                    validators: [Validators.required, Validators.min(0), Validators.max(100)],
                },
                {
                    name: 'applicableRanges',
                    label: 'Ranges',
                    value: [],
                    type: 'simpleSelection'
                },
                {
                    name: 'applicableColours',
                    label: 'Colours',
                    value: [],
                    type: 'simpleSelection'
                },
                {
                    name: 'applicableProductCodes',
                    label: 'Product Codes',
                    value: [],
                    type: 'simpleSelection'
                }
            ],
            [PromotionType.PRODUCT_DISCOUNT]: [
                {
                    name: 'productDiscount',
                    label: 'Product Discount %',
                    value: 0,
                    type: 'number',
                    validators: [Validators.required, Validators.min(0), Validators.max(100)]
                },
                {
                    name: 'applicableProductCodes',
                    label: 'Product Codes',
                    value: [{ isPlaceholder: true }],
                    type: 'simpleSelection'
                },
                {
                    name: 'applicableProductTypes',
                    label: 'Product Types',
                    value: [{ isPlaceholder: true }],
                    type: 'simpleSelection'
                }
            ],
            [PromotionType.BRAND]: [
                {
                    name: 'brandName',
                    label: 'Brand Name',
                    value: 'AEG',
                    type: 'select',
                    validators: [Validators.required],
                },
                {
                    name: 'brandDiscount',
                    label: 'Brand Discount %',
                    value: 0,
                    type: 'number',
                    validators: [Validators.required, Validators.min(0), Validators.max(100)],
                },
                {
                    name: 'requiredProducts',
                    label: 'Brand Required Products',
                    value: [{ isPlaceholder: true }],
                    type: 'productSelection',
                    validators: [Validators.required, Validators.minLength(2)],
                },
            ],
            [PromotionType.FREEITEM]: [
                {
                    name: 'brandName',
                    label: 'Brand Name',
                    value: 'AEG',
                    type: 'select',
                    validators: [Validators.required],
                },
                {
                    name: 'requiredProducts',
                    label: 'Required Products',
                    value: [{ isPlaceholder: true }],
                    type: 'productSelection',
                    validators: [Validators.required, Validators.minLength(2)],
                },
                {
                    name: 'freeProducts',
                    label: 'Free Products',
                    value: [{ isPlaceholder: true }],
                    type: 'productSelection',
                    validators: [Validators.required, Validators.minLength(1), Validators.maxLength(1)],
                },
            ],
            [PromotionType.FREEITEM_BRAND]: [
                {
                    name: 'brandName',
                    label: 'Brand Name',
                    value: 'AEG',
                    type: 'select',
                    validators: [Validators.required],
                },
                {
                    name: 'minRequired',
                    label: 'Minimum of Brand Required',
                    value: 0,
                    type: 'number',
                    validators: [Validators.required, Validators.min(0), Validators.max(100)],
                },
                {
                    name: 'freeProducts',
                    label: 'Free Products',
                    value: [{ isPlaceholder: true }],
                    type: 'productSelection',
                    validators: [Validators.required, Validators.minLength(1), Validators.maxLength(1)],
                },
            ],
            [PromotionType.BRAND_EXTERNAL]: [
                {
                    name: 'brandName',
                    label: 'Brand Name',
                    value: 'AEG',
                    type: 'select',
                    validators: [Validators.required],
                },
            ],
            [PromotionType.INFORMATION]: [],
        };

        Object.assign(this, {
            name: '',
            description: '',
            startDate: '',
            endDate: '',
            type: PromotionType.BASIC,
            status: PromotionStatus.NOT_ACTIVE,
            banners: [new PromotionBanner()],
            productOverlayImage: new PromotionMedia(),
            listingOverlayImage: new PromotionMedia(),
            conditionalFields: [],
            saleCard: new PromotionSaleCard(),
            terms: new PromotionTerms(),
            rules: {},
            ...init,
        });

        let conditionalFieldsForType: IPromotionField[] = this.conditionalPromotionFields[this.type];
        // Copy data into conditional fields
        this.conditionalFields.forEach((copyFrom) => {
            let targetField = conditionalFieldsForType.find((copyTo) => copyTo.name === copyFrom.name);
            if (targetField) {
                targetField.value = copyFrom.value || targetField.value;
            }
        });
        this.conditionalFields = conditionalFieldsForType;

        if (init?.saleCard) {
            this.saleCard = new PromotionSaleCard(init.saleCard);
        }

        if (Array.isArray(init?.banners)) {
            init.banners.forEach((banner, idx) => {
                if (banner.image) {
                    this.banners[idx].image = new PromotionMedia(banner.image);
                }
            });
        }

        if (init?.productOverlayImage) {
            this.productOverlayImage = new PromotionMedia(init.productOverlayImage);
        }

        if (init?.listingOverlayImage) {
            this.listingOverlayImage = new PromotionMedia(init.listingOverlayImage);
        }

        if (init?.terms) {
            this.terms = new PromotionTerms(init.terms);
        }
    }
}

export interface IPromotionSaleCard {
    image: IPromotionMedia;
    textList: IPromotionText[];
    buttons: IPromotionRouterLink[];
    backgroundColour: string;
}

export class PromotionSaleCard implements IPromotionSaleCard {
    image: IPromotionMedia;
    textList: IPromotionText[];
    buttons: IPromotionRouterLink[];
    backgroundColour: string;

    constructor(init?: Partial<IPromotionSaleCard>) {
        const maxImageSize = 400000;
        Object.assign(this, {
            image: new PromotionMedia({ maxBytes: maxImageSize }),
            textList: [{ isPlaceholder: true }],
            buttons: [new PromotionRouterLink(), new PromotionRouterLink()],
            backgroundColour: '#009CDE',
            ...init,
        });

        if (init?.image) {
            this.image = new PromotionMedia({ ...init.image, maxBytes: maxImageSize });
        }
    }
}

export interface IPromotionText {
    text: string;
    cssClass: '' | 'small' | 'large';
    colour: string;
    isPlaceholder?: boolean;
}

export interface IPromotionRouterLink {
    text: string;
    routerLink: string;
}

export class PromotionRouterLink implements IPromotionRouterLink {
    text: string;
    routerLink: string;

    constructor(init?: Partial<IPromotionRouterLink>) {
        Object.assign(this, {
            text: '',
            routerLink: '',
            ...init,
        });
    }
}

export interface IPromotionTerms {
    title: string;
    body: string;
    link?: IPromotionRouterLink;
}

export class PromotionTerms implements IPromotionTerms {
    title: string;
    body: string;
    link?: IPromotionRouterLink;

    constructor(init?: Partial<IPromotionTerms>) {
        Object.assign(this, {
            title: '',
            body: '',
            link: new PromotionRouterLink(),
            ...init,
        });

        if (init?.link) {
            this.link = new PromotionRouterLink(init.link);
        }
    }
}

export interface IPromotionField {
    name: string;
    label: string;
    value: any;
    type: 'text' | 'number' | 'productSelection' | 'simpleSelection';
    validators: ValidatorFn[];
}

export class PromotionBanner implements IPromotionBanner {
    image: IPromotionMedia;
    mobileImage: IPromotionMedia;
    backgroundColour: string;
    routerLink: string;

    constructor(init?: Partial<IPromotionBanner>) {
        Object.assign(this, {
            image: new PromotionMedia(),
            mobileImage: new PromotionMedia(),
            backgroundColour: '#009CDE',
            routerLink: '',
            ...init,
        });

        if (init?.image) {
            this.image = new PromotionMedia(init.image);
        }

        if (init?.mobileImage) {
            this.mobileImage = new PromotionMedia(init.mobileImage);
        }
    }
}

export interface IPromotionBanner {
    image: IPromotionMedia;
    mobileImage: IPromotionMedia;
    backgroundColour: string;
    routerLink: string;
}

export interface IPromotionMedia {
    name: string;
    imageData: string | ArrayBuffer;
    maxBytes: number;
}

export class PromotionMedia implements IPromotionMedia {
    name: string;
    imageData: string | ArrayBuffer;
    maxBytes: number;

    constructor(init?: Partial<IPromotionMedia>) {
        Object.assign(this, {
            name: '',
            imageData: '',
            maxBytes: 100000,
            ...init,
        });
    }
}

export interface IPromotionListItem {
    id: number;
    name: string;
    startDate: string;
    endDate: string;
    type: PromotionType;
    status: PromotionStatus;
    productOverlayImageURL: string;
    listingOverlayImageURL: string;
    conditionalFields: IPromotionField[];
    rules?: IPromotionApplicables;
}

export interface IPromotionButton {
    route: string;
    fragment: string;
    colour: string;
    title: string;
}

export interface IPromotionBasketAction {
    action: 'add' | 'remove' | 'nothing';
    productCode: string;
    group: ProductType;
    value?: number;
}

export interface IPromotionBasketItems {
    addItems: BasketItem[],
    removeItems: BasketItem[]
}
