import { ActiveRange, ProductType } from '@app/services/catalogue/models';
import { StringHelper } from '@app/utilities/helpers';
import { BasketItemHanding } from './item-handing.enum.model';
import { isCarcaseColoured } from '@app/services/catalogue';
export class BasketItem {
    // DB Values
    public id?: number;
    public basketId?: number;
    public code: string;
    public description: string;
    public rangeId: string;
    public range: string;
    public rangeColour: string;
    public colour: string;
    public carcaseColour?: string;
    public otherColour?: string;
    public bespokeColour?: string;
    public material?: string;
    public drawerBox?: string;
    public legBoxType?: string;
    public height?: number;
    public isCustomHeight?: boolean;
    public width?: number;
    public isCustomWidth?: boolean;
    public depth?: number;
    public isCustomDepth?: boolean;
    public isSpecial?: boolean;
    public isInframe?: boolean;
    public isDryAssembled?: boolean;
    public isCarcaseOnly?: boolean;
    public isFlatPacked?: boolean;
    public isLooseDoor?: boolean;
    public noDoorDrilling?: boolean;
    public noDoorRequired?: boolean;
    public isPromotion?: boolean;
    public notAvailableInStyle?: boolean;
    public notAvailable?: boolean;
    public handing?: string;
    public panDrawerQty?: number;
    public drawerBoxQty?: number;
    public qty: number;
    public rank: string;
    public rankParent: string;
    public supplier: string;
    public group: ProductType;
    public category: string;
    public subCategory: string;
    public cost: number;
    public salePrice?: number;
    public campaignId?: number;
    public discount?: number;
    public discountBy?: string;
    public softClose: boolean;
    public hingeCost: number;
    public cabinetCost?: string;
    public discountPercentage?: number;
    public promotionPercentage?: number;
    public categoryImage?: string;
    public worktopConfigUuid?: string;

    // Additional Params Not Saved in the DB
    public orderId?: number;
    public origCost?: number;
    public notes?: any[];
    public cab_price?: number;
    public doors?: any[];
    public ifDoors?: any[];
    public cat_img?: string;
    public worktopConfig?: any;
    public currentImage?: string;
    public _cabinet?: any;
    public _sectionGroup?: string;
    public _sectionCategory?: string;
    public _children?: any;
    public updatingBespoke?: boolean;
    public checked?: boolean;
    /** Flag to delete this order item from an order being edited */
    public orderItemToBeDeleted?: boolean = false;

    constructor(item, activeRange: ActiveRange, type, qty = 1) {
        let drawerBox = null;
        if (item.drawerBox) {
            drawerBox = item.drawerBox.intivo ?
                'Intivo' :
                item.drawerBox.wood_blum ?
                    'Blum' :
                    null;
        }

        let doorIndex = 1;
        let loopingForDoors = true;
        const doors = [];
        while (loopingForDoors) {
            let door = item[`door_${doorIndex}`];
            if (door) {
                doors.push({
                    code: door,
                    qty: item[`door_${doorIndex}_qty`]
                });
                doorIndex++;
            } else {
                loopingForDoors = false;
            }
        }

        const ifDoors = [];
        doorIndex = 1;
        loopingForDoors = true;
        while (loopingForDoors) {
            let door = item[`if_door_${doorIndex}`];
            if (door) {
                ifDoors.push({
                    code: door,
                    qty: item[`if_door_${doorIndex}_qty`]
                });
                doorIndex++;
            } else {
                loopingForDoors = false;
            }
        }

        this.group = type;
        this.category = item.category;
        this.subCategory = item.subcat || item.subCategory;

        if (this.group === ProductType.DOORS ||
            this.group === ProductType.CABINETS ||
            this.group === ProductType.RANGE_PRODUCTS ||
            this.group === ProductType.PANELS
        ) {
            this.range = activeRange?.range.name || item.range || null;
            this.rangeId = activeRange?.range.id || item.rangeId || null;
            this.bespokeColour = activeRange?.bespokeColour || item.bespokeColour || null;
        }

        if (this.group === ProductType.SAMPLE_DOORS) {
            this.range = item.range.name;
            this.rangeId = item.range.range_id;
            this.colour = null;
            this.carcaseColour = null;
            this.rangeColour = item.rangeColour || null;
        } else {
            this.colour = activeRange?.colour ? activeRange.colour : item.colour || null;
            this.rangeColour = activeRange?.rangeColour ? activeRange.rangeColour : item.rangeColour || null;

            if (item.keepCarcaseColour) {
                this.carcaseColour = item.carcaseColour || activeRange?.carcaseColour || null;

            } else {
                this.carcaseColour = activeRange?.carcaseColour || item.carcaseColour || null;
            }
        }

        if (isCarcaseColoured(item) && item.carcaseColour) {
            this.carcaseColour = item.carcaseColour;
        }

        this.code = item.unit_code || item.code || item.product_code;
        this.description = item.desc || item.description;
        this.material = activeRange?.rangeDetail?.door_material || item.material || null;

        this.isInframe = StringHelper.toBoolean(activeRange?.rangeDetail?.inframe.toLowerCase());
        this.drawerBox = drawerBox;
        this.otherColour = null; // Used for appliances. (Not a unit / door)
        this.height = item.height;
        this.isCustomHeight = false;
        this.width = item.width;
        this.isCustomWidth = false;
        this.depth = item.depth;
        this.isCustomDepth = false;
        this.isSpecial = false; // if any of the above falses.... are true
        this.isDryAssembled = item.dryAssembled || false;
        this.qty = qty || 1;
        this.handing = item._hingeSide || null;
        this.rank = item.rank || '1';
        this.rankParent = '0';

        this.cost = item.cab_price || item._cost || item.cost || item.price; // REPLACE from the backend
        this.cab_price = item.cab_price;
        this.softClose = item.softClose || false;
        this.hingeCost = item.hingecost;
        this.doors = doors;
        this.ifDoors = ifDoors;
        this.cat_img = item.cat_img || item.image;
        this.panDrawerQty = item.panDrawerQty || 0;
        this.drawerBoxQty = item.drawerBoxQty || 0;
        this.supplier = item.brand || null;

        if (item.salePrice && item.salePrice < this.cost) {
            this.promotionPercentage = parseFloat((1 - item.salePrice / this.cost).toFixed(2)) * 100;
            this.salePrice = item.salePrice;
        }
        if (item.worktopConfig) {
            this.worktopConfig = item.worktopConfig;
        }
    }
}
