<section class="dark">
    <div class="contained flex-space-between wrap-under-800">
        <div class="flex-item">
            <h1>Appliances</h1>
            <p>
                A kitchen is only complete when it contains the perfect appliances. So now could be the ideal time to
                browse our vast range of top-branded ovens, fridge-freezers, dishwashers and more.
            </p>
        </div>
    </div>
    <div class="contained flex-space-between wrap-under-800 intro">
        <div class="flex-item">
            <img cdn="assets/images/appliances/brand-aeg.jpg" alt="AEG Brand Logo"/>
        </div>
        <div class="flex-item">
            <h4>TRUSTED BRANDS</h4>
            <p>We’ve carefully selected the top range from the best appliance brands, offering the best prices available. Many of these come with specially extended warranties and are guaranteed to fit our kitchen units perfectly.</p>
            <p>
                <button mat-flat-button color="primary" routerLink="/appliances/cooking/single-ovens">
                    SHOP ALL BRANDS
                </button>
            </p>
        </div>
    </div>
</section>

<component-product-category *ngIf="loaded" type="appliances" [categories]="categories"></component-product-category>