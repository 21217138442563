import { Component, ViewEncapsulation, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

import { CustomerService } from '@app/services/customer';
import { DialogService } from '@app/services/dialog';

@Component({
    selector: 'component-customer-lookup',
    templateUrl: './customer-lookup.component.html',
    styleUrls: ['./customer-lookup.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CustomerLookupComponent {
    @ViewChild('emailSearch') emailSearch: ElementRef;
    @Output() customerFoundEvent = new EventEmitter<any>();

    public email: string;

    public customers: any[];

    public searchText: string;
    public searching: boolean = false;

    public createNewCustomer: boolean = false;
    public createQuote: boolean = false;

    public errorMessage: string;

    constructor(
        private customerService: CustomerService,
        private dialogService: DialogService
    ) { }

    public selectCustomer(event) {
        if (event && event.option && event.option.value) {
            const customer = this.customers.find((customer) => customer.email === event.option.value);
            this.lookupCustomer(customer);
        }
    }

    public customerCreated(customer) {
        this.createNewCustomer = false;
        this.createQuote = true;
        this.customerFoundEvent.emit(customer);
    }

    private lookupCustomer(customer) {
        if (customer) {
            if (customer.id === -1) {
                this.createNewCustomer = true;
            } else {
                this.customerFoundEvent.emit(customer);
            }
        }
    }

    public searchCustomer() {
        if (this.email.length > 3) {
            this.searchText = this.email;

            this.searching = true;

            this.customerService.customerSearch(this.email)
                .then((response) => {
                    this.searching = false;
                    this.customers = response;
                    this.customers.unshift({
                        id: -1,
                        email: 'Create New Customer'
                    });

                    if (this.emailSearch) {
                        setTimeout(() => {
                            this.emailSearch.nativeElement.focus();
                        }, 50);
                    }
                })
                .catch((error) => {
                    this.searching = false;
                    this.errorMessage = error;

                    this.dialogService.error(this.constructor.name, error);
                });
        } else {
            this.errorMessage = 'Please enter a valid email address.';
        }
    }

    public clearSearch() {
        this.email = null;
        this.searchText = null;

        this.customers = [];
    }
}
