import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';
import { DirectivesModule } from '@app/directives';
import { PipesModule } from '@app/pipes';

import { BannerComponent } from './banner.component';

@NgModule({
    imports: [
        DesignModule,
        DirectivesModule,
        PipesModule
    ],
    exports: [
        BannerComponent
    ],
    declarations: [
        BannerComponent
    ]
})
export class BannerComponentModule { }
