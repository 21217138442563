import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Config } from '@app/config';
import { StringHelper } from '@app/utilities/helpers';

import { BasketService } from '@app/services/basket';
import { BasketItemHanding } from '@app/services/basket/models';
import { CatalogueService } from '@app/services/catalogue';
import { CostCalculator } from '@app/services/catalogue/cost-calculator';
import { ActiveRange, HandingType, ProductType } from '@app/services/catalogue/models';
import { ICabinet, isCabinet } from '@app/services/catalogue/models/product.models';
import { IMedia, IProductSpecification } from '@app/services/catalogue/models/product.models/common';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';

@Component({
    selector: 'page-sinks-and-taps-detail',
    templateUrl: './sinks-and-taps-detail.page.html',
    styleUrls: ['./sinks-and-taps-detail.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SinksAndTapsDetailPage implements OnInit, OnDestroy {
    public ProductType = ProductType;
    public item: any;
    public type: ProductType = ProductType.SINK_AND_TAPS;
    public logoURL: string = 'https://static.diy-kitchens.com/assets/images/brands/';
    public housings: any[] = [];
    public units: any[] = [];

    public noResults: boolean = false;

    public addingToBasket: boolean = false;
    public addedToBasket: boolean = null;

    private routeSubscription: Subscription;
    private routeHistory: RouteHistory[] = [{ title: 'Sinks & Taps', route: '/sinks-and-taps' }];
    private activeRangeSubscription: Subscription;
    public activeRange: ActiveRange;
    private costCalculator: CostCalculator = new CostCalculator();

    public Handing = BasketItemHanding;
    public HandingType = HandingType;
    public productImages: IMedia[] = [];

    public subCategoryName: string;

    constructor(
        private config: Config,
        private basketService: BasketService,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                this.getCatalogueData(route.params.description || null);
            }
        );

        this.activeRangeSubscription = this.catalogueService.activeRange$.subscribe(
            (rangeInfo: ActiveRange) => {
                this.activeRange = rangeInfo;
            }
        );
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }

        if (this.activeRangeSubscription) {
            this.activeRangeSubscription.unsubscribe();
            this.activeRangeSubscription = null;
        }
    }

    private getCatalogueData(description: string) {
        this.catalogueService.getSinkOrTapByDescription(description)
            .then((sinkstaps: any) => {
                let routeHistory = this.routeHistory.slice();

                if (sinkstaps) {
                    this.item = sinkstaps;
                    this.item.qty = 1;
                    this.item._cost = this.item.price || 0;
                    
                    this.productImages = this.item.extended_details?.images?.map(image => ({
                        image: `${this.config.api.endpoints.cdn}/assets/images/products/sinks-taps/${this.item.category.toLowerCase()}/large/${image}`
                    }));

                    if (!this.item.extended_details?.images?.length && this.item.media) {
                        this.productImages = [this.item.media];
                    }

                    const removeDuplicates = (arr: IProductSpecification[]): IProductSpecification[] => {
                        return arr.filter((obj, index) => {
                            return !arr.some((otherObj, otherIndex) => {
                                return otherIndex < index && obj.name === otherObj.name && obj.value === otherObj.value;
                            });
                        });
                    }

                    if (this.item.extended_details?.specifications) {
                        this.item.extended_details.specifications = removeDuplicates(this.item.extended_details.specifications);
                    }

                    this.item.extended_details?.specifications?.forEach((specification => {
                        specification.name = StringHelper.titleCase(specification.name.replace(/_/g, ' '));
                    }));

                    const categoryLink = StringHelper.spaceToDash(this.item.category);
                    this.subCategoryName = this.item.subCategoryName;
                    routeHistory.push({
                        title: this.item.category,
                        route: `/sinks-and-taps/${categoryLink}`
                    });

                    routeHistory.push({
                        title: this.item.subCategoryName,
                        route: `/sinks-and-taps/${categoryLink}/${this.item.subCategoryLink}`
                    });

                    routeHistory.push({
                        title: this.item.code,
                        route: `/sinks-and-taps/${categoryLink}/${this.item.subCategoryLink}/${description}`
                    });

                    this.navigationService.setNavigation({
                        title: this.item?.title,
                        metaTags: [{ name: 'no-meta', content: 'no metadata' }]
                    });

                    this.catalogueService.getApplianceHousingsByAperture(sinkstaps.genid)
                    .then((units) => {
                        this.units = units;
                        if (units.length > 0) {
                            this.initHousings(units);
                        }
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
                }

                this.navigationService.setRouteHistory(routeHistory);

                const metaDescription = `${this.item?.desc || this.item?.description} from the DIY Kitchens ${this.item?.subCategoryName || ''} ${this.item?.category} range.`;

                this.navigationService.setMetaTags([
                    { property: 'og:description', content: metaDescription },
                    { name: 'Description', content: metaDescription },
                    { property: 'og:locale', content: 'en_GB' },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:site_name', content: 'DIY Kitchens' },
                    { property: 'og:url', content: this.item?.title },
                    { property: 'og:image', content: this.item?.media?.image },
                    { property: 'og:image:secure_url', content: this.item?.media?.image },
                    { property: 'og:image:width', content: '600' },
                    { property: 'og:image:height', content: '400' }
                ]);
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public addToBasket() {
        this.addingToBasket = true;
        this.basketService.addItem(this.item, this.type, this.item.qty)
            .then(() => {
                this.addingToBasket = false;
                this.addedToBasket = true;

                if (this.config.isBrowser) {
                    setTimeout(() => this.addedToBasket = null, 2000);
                } else {
                    this.addedToBasket = null;
                }
            })
            .catch(() => {
                this.addingToBasket = false;
                this.addedToBasket = false;

                if (this.config.isBrowser) {
                    setTimeout(() => this.addedToBasket = null, 2000);
                } else {
                    this.addedToBasket = null;
                }
            });
    }

    public itemCost(item) {
        if (this.activeRange) {
            item._cost = this.costCalculator.getUnitPriceRangeAndColour(
                this.activeRange.rangeDetail, this.activeRange.rangeColour, item.unit_code);
        } else {
            item._cost = item.cab_price || 0;
        }

        return item._cost;
    }

    public sortWidths(widths) {
        return widths.sort((a, b) => {
            if (a.width !== b.width) {
                return a.width - b.width;
            }

            return a.cost - b.cost;
        });
    }

    private initHousings(units) {
        let category = 'Appliance housings';
        if (units) {
            this.housings = [];
            units.map((item) => {
                if (this.itemCost(item) > 0) {
                    if (item.promotion) {
                        item.salePrice = this.getItemSalePrice(item);
                    }
                    let exists = this.housings.filter((entry) => {
                        if (item.height !== entry.item.height || item.depth !== entry.item.depth) {
                            return false;
                        }
                        if (item.type) {
                            return item.type === entry.item.type;
                        }

                        return (item.category === entry.item.category
                            && item.desc === entry.item.desc
                            && item.subcat === entry.item.subcat);
                    });
                    if (exists && exists[0]) {
                        if (exists[0].maxPopularity < item.popularity) {
                            exists[0].maxPopularity = item.popularity;
                            exists[0].item = item;
                        } else if (exists[0].maxPopularity === item.popularity) {
                            if (item.width === 600) {
                                exists[0].item = item;
                            }
                        }
                        let existingWidth = exists[0].widths.find(width => width.width === item.width);
                        if (existingWidth) {
                            existingWidth.variants.push({
                                code: item.unit_code,
                                variant: item.variant || item.unit_code // TODO replace variant name with data from API
                            });
                        } else {
                            exists[0].widths.push({
                                width: item.width,
                                variants: [{
                                    code: item.unit_code,
                                    variant: item.variant || item.unit_code // TODO replace variant name with data from API
                                }],
                                cost: item._cost
                            });

                        }
                    } else {
                        this.housings.push({
                            item: item,
                            maxPopularity: item.popularity,
                            widths: [{
                                width: item.width,
                                variants: [{
                                    code: item.unit_code,
                                    variant: item.variant || item.unit_code // TODO replace variant name with data from API
                                }],
                                cost: item._cost
                            }]
                        });
                    }
                }
            });

        }
    }

    public setHinge(item, side) {
        item._hingeSide = side;
    }

    public hingeWording(item: ICabinet): string {
        return this.catalogueService.getHingeWording(item);
    }


    public toggleSoft(item) {
        item._softClose = !item._softClose;
    }
    public variants(entry) {
        let selectedWidth = entry.widths.find(width => width.width === entry.item.width);

        return selectedWidth.variants;
    }
    public selectWidth(entry, width) {
        let newItem = this.units.find(cab => cab.unit_code === width.variants[0].code);
        if (newItem) {
            entry.item = newItem;
        }
    }
    public selectVariant(entry, variant) {
        let newItem = this.units.find(cab => cab.unit_code === variant.code);
        if (newItem) {
            entry.item = newItem;
        }
    }

    public addUnitToBasket(entry) {
        this.basketService.addItem(entry.item, ProductType.CABINETS)
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    private getItemSalePrice(item: ICabinet): number | null {
        if (item.promotion && item._cost && isCabinet(item)) {
            const cabPrice = (item.cab_salePrice) ? item.cab_salePrice : item.cab_price;
            const frontals = (this.activeRange) ? 
                        this.catalogueService.getDoorForUnit(item.unit_code, this.activeRange?.rangeColour || 'Alabaster') :
                        null;
            let totalCost: number = cabPrice;
            if (frontals) {
                Object.values(frontals).forEach((door: any) => {
                    let doorCost = (door.salePrice) ? door.salePrice : door._cost;
                    totalCost += doorCost;
                });
            }
            return totalCost;
        }
        return null;
    }
}
