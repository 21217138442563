import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { Config } from '@app/config';

import { NavigationService } from '@app/services/navigation';
import { RouteHistory } from '@app/services/navigation/models';

import { StickyTabsWhyDiyKitchens } from './models';
import { IStickyTab } from '@app/components/sticky-tabs/models';

@Component({
    selector: 'page-about-why-diy-kitchens',
    templateUrl: './why-diy-kitchens.page.html',
    styleUrls: ['./why-diy-kitchens.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AboutWhyDiyKitchensPage implements OnInit {
    public diy = this.config.diy;

    public StickyTabs = StickyTabsWhyDiyKitchens;
    public tabs: IStickyTab<StickyTabsWhyDiyKitchens>[] = [
        { title: 'Selection', id: StickyTabsWhyDiyKitchens.SELECTION },
        { title: 'Transparent', id: StickyTabsWhyDiyKitchens.TRANSPARENT },
        { title: 'Co-ordinated', id: StickyTabsWhyDiyKitchens.COORDINATED },
        { title: 'Widest', id: StickyTabsWhyDiyKitchens.WIDEST },
        { title: 'Innovative', id: StickyTabsWhyDiyKitchens.INNOVATIVE },
        { title: 'Rigid', id: StickyTabsWhyDiyKitchens.RIGID },
        { title: 'Blum', id: StickyTabsWhyDiyKitchens.BLUM },
        { title: 'Kessebohmer', id: StickyTabsWhyDiyKitchens.KESSEBOHMER },
        { title: 'Painted', id: StickyTabsWhyDiyKitchens.PAINTED },
        { title: 'Delivery', id: StickyTabsWhyDiyKitchens.DELIVERY }
    ];

    private routeHistory: RouteHistory[] = [
        { title: 'About Diy Kitchens', route: '/about-diy-kitchens' },
        { title: 'Why DIY Kitchens?', route: '/about-diy-kitchens/why-diy-kitchens' },
    ];

    constructor(
        private config: Config,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: 'Why DIY Kitchens? 10 Reasons to buy a kitchen from us',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'description', content: 'We are a family run business that has been manufacturing and supplying high specification kitchens to both trade and retail customers for nearly 30 years.'},
            ],
            routeHistory: this.routeHistory
        });
    }
}
