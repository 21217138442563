import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CatalogueService } from '@app/services/catalogue';
import { DialogService } from '@app/services/dialog';

@Injectable()
export class CategoryListingService {
    public parentCategory$ = new BehaviorSubject<string>('Loading category');
    currentParentCategory = this.parentCategory$.asObservable();

    public categoryListSection$ = new BehaviorSubject<string>('Loading category');
    currentCategorySection = this.categoryListSection$.asObservable();

    public appCats$ = new BehaviorSubject<string[]>(['Loading category']);
    currentCats = this.appCats$.asObservable();

    public applianceCategories = [];
    public parentCats = [];
    public selectedCategory = [];
    public items: any;
    public catItems: any;
    public catSubItems: any;
    public currentParentSection: any;
    public activeFilter = false;
    public clearFilter = false;
    public listItems: any;
    public listingTags = [];
    public checked = [];
    public itemCount: any;
    public selectedFilters = [];
    public activeFilters = false;

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService
    ) { }

    changeParentCategorySection(category: string) {
        this.parentCategory$.next(category);
        this.getCatalogueData(category);
    }

    changeCategorySection(category: string) {
        this.categoryListSection$.next(category);
    }

    private getCatalogueData(category: string) {
        this.catalogueService.getProductCategories()
            .then((productCategories: any) => {
                if (productCategories) {
                    const appCats = Object.keys(productCategories)
                        .filter(key => productCategories[key][this.parentCategory$.value])
                        .map(key => productCategories[key][this.parentCategory$.value]);

                    console.warn('Initial appCats: ' + appCats);

                    this.appCats$ = appCats[0].map(applCategories => {
                        return {
                            name: applCategories.name,
                            route: '/' + this.parentCategory$.value + '/' + applCategories.route,
                            article: null,
                            tag: applCategories.tag,
                            filters: applCategories.filters,
                            countName: this.parentCategory$.value
                        };
                    });
                    console.warn('Current appCats: ' + this.appCats$);
                    this.selectedCategory = Object.keys(appCats[0])
                        .filter(key => appCats[0][key].name.toLowerCase().replace(/ /g, '-') === this.categoryListSection$.value)
                        .map(key => appCats[0][key]);

                    console.warn('Current selectedCategory: ' + this.categoryListSection$.value);

                    this.selectedFilters = [];

                    this.activeFilters = false;
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
