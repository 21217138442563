@import '/src/theme/variables';

.component-transfer-basket-dialog {
    height: 100%;

    .mat-mdc-dialog-container {
        margin: auto;
        width: 90%;
        height: 90%;
    }

    @media (max-width: 480px) {
        .mat-mdc-dialog-container {
            width: 100%;
            height: 100%;
        }
    }
}

component-transfer-basket-dialog {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    .mat-mdc-dialog-title {
        &::before {
            height: auto;
        }

        h2 {
            font-size: 38px;
            font-weight: 600;
            line-height: 38px;
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }

    .mat-mdc-dialog-content {
        max-height: 100%;
    }

    .quote {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    
    .quote-section {
        height: 100%;
    }

    .quote-email {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
