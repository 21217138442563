import { Injectable } from '@angular/core';

import { Config } from '@app/config';
import { HttpApi } from '@app/services/api';
import { HttpApiOptions, HttpApiResponse } from '@app/services/api/models';

import { AuthCustomerService } from '@app/services/auth';

@Injectable()
export class PaymentsService {
    constructor(
        private config: Config,
        private httpApi: HttpApi,
        private authCustomerService: AuthCustomerService
    ) { }

    public createHashAndLaunchCardnet(orderNumber: string, subtotal: number, shipping: number) {
        return new Promise((resolve, reject) => {
            // TODO - upgrade to new api and user validation
            const url = `${this.config.api.endpoints.planner}/createHash`;

            const vattax = (subtotal + shipping) * 0.2;
            const chargeTotal = subtotal + shipping + vattax;

            const userId = (this.authCustomerService.details && this.authCustomerService.details.id) ?
                this.authCustomerService.details.id.toString() : '';

            const to2DecimalPlacesStr = (f) => '' + (Math.floor(f * 100) / 100);

            let payload = [{
                param: 'storename',
                value: '2220540912'
            }, {
                param: 'txntype',
                value: 'preauth'
            }, {
                param: 'timezone',
                value: 'Europe/London'
            }, {
                param: 'hash_algorithm',
                value: 'HMACSHA256'
            }, {
                param: 'subtotal',
                value: to2DecimalPlacesStr(subtotal)
            }, {
                param: 'shipping',
                value: to2DecimalPlacesStr(shipping)
            }, {
                param: 'vattax',
                value: to2DecimalPlacesStr(vattax)
            }, {
                param: 'chargetotal',
                value: to2DecimalPlacesStr(chargeTotal)
            }, {
                param: 'checkoutoption',
                value: 'combinedpage'
            }, {
                param: 'currency',
                value: '826'
            }, {
                param: 'customerid',
                value: userId
            }, {
                param: 'oid',
                value: orderNumber // remedial request number
            }, {
                param: 'hideOrderDetails',
                value: 'true'
            }, {
                param: 'responseSuccessURL',
                value: 'http://localhost:8463/paymenttxn'
                // value: 'https://planner-api.diy-kitchens.com/paymenttxn'
            }, {
                param: 'responseFailURL',
                value: 'http://localhost:8463/paymenttxn'
                // value: 'https://planner-api.diy-kitchens.com/paymenttxn'
            }];

            this.httpApi.post<any>(url, { payload: payload }).subscribe({
                next: (response) => {
                    if (response) {
                        payload.push({
                            param: 'txndatetime',
                            value: response.txndatetime
                        });
                        payload.push({
                            param: 'hashExtended',
                            value: response.hashExtended
                        });
                        let oid = payload.find(pair => pair.param === 'oid');
                        if (oid) {
                            oid.value = response.oid; // Set oid to the orderNumber and paymentId, ie. D123456/987
                        }

                        let form = document.createElement('form');
                        form.setAttribute('method', 'post');
                        form.setAttribute('action', 'https://test.ipg-online.com/connect/gateway/processing');
                        form.setAttribute('style', 'display:none');

                        payload.forEach(pair => {
                            let input = document.createElement('input');
                            input.setAttribute('type', 'hidden');
                            input.setAttribute('name', pair.param);
                            input.setAttribute('value', pair.value);
                            form.appendChild(input);
                        });

                        let submitButton = document.createElement('button');
                        submitButton.setAttribute('type', 'submit');
                        form.appendChild(submitButton);

                        document.body.appendChild(form);
                        submitButton.click();
                    } else {
                        // TODO show failure message to customer
                        console.error('Failed to createHash');
                    }
                },
                error: (error) => reject(error)
            });
        });
    }
}
