import { Component, Input, ViewEncapsulation, OnChanges } from '@angular/core';

import { BasketService } from '@app/services/basket';
import { BasketHistoryAction } from '@app/services/basket/models/basket-history-action.model';
import { DialogService } from '@app/services/dialog';

@Component({
    selector: 'component-basket-history',
    templateUrl: './basket-history.component.html',
    styleUrls: ['./basket-history.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class BasketHistoryComponent implements OnChanges {
    @Input() basketId;
    @Input() cutOffDate;
    @Input() refreshBasketHistory;

    basketHistoryList: any;

    constructor(
        private basketService: BasketService,
        private dialogService: DialogService
    ) { }

    ngOnChanges(changes: any) {
        if (this.refreshBasketHistory) {
            this.getBasketHistory(this.basketId);
        }
    }

    public getBasketHistory(basketId) {
        this.basketService.getBasketHistory(basketId)
            .then((response) => {
                var data = response;
                data.forEach((history) => {

                    history.actionIcon = 'add_circle';
                    history.actionIconCssName = 'success-icon';
                    history.actionLabelCss = 'success-label';

                    switch (history.actionId) {
                        case BasketHistoryAction.ADD:
                            history.actionTitle = 'CUSTOMER ADDED';
                            break;
                        case BasketHistoryAction.EDIT:
                            history.actionTitle = 'CUSTOMER CHANGED';
                            break;
                        case BasketHistoryAction.REMOVE:
                            history.actionTitle = 'CUSTOMER REMOVED';
                            history.actionIcon = 'remove_circle';
                            history.actionIconCssName = 'danger-icon';
                            history.actionLabelCss = 'danger-label';
                            break;
                        case BasketHistoryAction.SOFT_CLOSE:
                            history.actionTitle = 'CUSTOMER CHANGED';
                            history.changedItem = 'Soft Close to ' + history.value ? 'Yes' : 'No'
                            break;
                        case BasketHistoryAction.QTY:
                            history.actionTitle = 'CUSTOMER CHANGED';
                            history.changedItem = 'Qty to ' + history.value;
                            break;
                        case BasketHistoryAction.STYLE:
                            history.actionTitle = 'CUSTOMER CHANGED';
                            history.changedItem = 'Style to ' + history.value;
                            break;
                        case BasketHistoryAction.HANDING:
                            history.actionTitle = 'CUSTOMER CHANGED';
                            history.changedItem = 'Hading to ' + history.value;
                            break;
                        case BasketHistoryAction.CARCASE_COLOUR:
                            history.actionTitle = 'CUSTOMER CHANGED';
                            history.changedItem = 'Carcase colour to ' + history.value;
                            break;
                        default:
                            history.actionTitle = '',
                                history.actionIcon = '',
                                history.actionIconCssName = '',
                                history.actionLabelCss = ''
                            break;
                    }
                });
                this.basketHistoryList = data;
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public getBasketHistoryItemTitle(basketHistoryItem: any) {
        return (basketHistoryItem.range ? basketHistoryItem.range : '') + ' ' + (basketHistoryItem.rangeColour ? basketHistoryItem.rangeColour : '') + ' ' + basketHistoryItem.code;
    }
}
