import {
    Component, ViewEncapsulation, ViewChild, ElementRef,
    HostListener, Input, Output, EventEmitter
} from '@angular/core';

import { IStickyTab, IStickyTabsTypes, StickyTabsTypes } from './models';
import { Config } from '@app/config';

@Component({
    selector: 'component-sticky-tabs',
    templateUrl: './sticky-tabs.component.html',
    styleUrls: ['./sticky-tabs.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class StickyTabsComponent {
    @ViewChild('tabContainer', { static: false }) tabContainer: ElementRef;

    @Input() tabs: IStickyTab<any>[] = [];
    @Input() type: IStickyTabsTypes = StickyTabsTypes.TABS;
    @Input() activeTab: any;
    @Output() activeTabChange: EventEmitter<any> = new EventEmitter<any>();

    public tabsSticky: boolean = false;
    private tabsStickyPos: number = 0;
    private tabsStickyHeight: number = 0;

    constructor(private config: Config) { }

    public switchTab(tab: any, scrollTo: boolean = true) {
        this.activeTab = tab;

        this.activeTabChange.emit(tab);

        if (this.config.isBrowser && window && scrollTo) {
            if (this.type === StickyTabsTypes.TABS) {
                window.scrollTo(0, this.tabsStickyPos);
            } else {
                const element = document.getElementById(tab);
                if (element) {
                    if (!this.tabsStickyHeight) {
                        this.tabsStickyHeight = this.tabContainer.nativeElement.getBoundingClientRect().height;
                    }

                    const elementPos = element.getBoundingClientRect();
                    window.scrollTo(0, elementPos.top - this.tabsStickyHeight + window.scrollY + 1);
                }
            }
        }
    }

    @HostListener('document:scroll', ['$event'])
    public onViewportScroll() {
        if (this.config.isBrowser && window && this.tabContainer) {
            const scrollY = window.scrollY;
            const tabContainerPos = this.tabContainer.nativeElement.getBoundingClientRect();

            if (!this.tabsSticky && (scrollY < this.tabsStickyPos || this.tabsStickyPos === 0)) {
                this.tabsStickyPos = (scrollY + tabContainerPos.top);
                this.tabsStickyHeight = tabContainerPos.height;

                if (this.type === StickyTabsTypes.SCROLLER && this.tabs && this.tabs.length) {
                    this.tabs.forEach((tab) => {
                        const element = document.getElementById(tab.id);

                        if (element) {
                            tab.y = scrollY + element.getBoundingClientRect().top;
                        }
                    });
                }
            }

            this.tabsSticky = (
                this.tabsStickyPos &&
                scrollY > this.tabsStickyPos &&
                tabContainerPos.top <= 0
            ) ? true : false;

            if (this.type === StickyTabsTypes.SCROLLER && this.tabs && this.tabs.length) {
                let activeTab = null;
                this.tabs.forEach((tab) => {
                    if (tab.y && tab.y < (scrollY + this.tabsStickyHeight + 56)) {
                        if (!activeTab) {
                            activeTab = tab;
                        } else if (activeTab.y < tab.y) {
                            activeTab = tab;
                        }
                    }
                });

                if (activeTab && this.tabsSticky) {
                    this.switchTab(activeTab.id, false);
                } else {
                    this.switchTab(null, false);
                }
            }
        }
    }
}
