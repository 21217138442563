import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AboutServiceModule } from './about/about.module';
import { ApiModule } from './api/api.module';
import { ArticlesServiceModule } from './articles/articles.module';
import { AuthServiceModule } from './auth/auth.module';
import { BasketServiceModule } from './basket/basket.module';
import { CatalogueServiceModule } from './catalogue/catalogue.module';
import { CategoryListingServiceModule } from './category-listing/category-listing.module';
import { CheckoutServiceModule } from './checkout/checkout.module';
import { CustomerServiceModule } from './customer/customer.module';
import { DialogServiceModule } from './dialog/dialog.module';
import { FilterServiceModule } from './filter/filter.module';
import { MarketingServiceModule } from './marketing/marketing.module';
import { MessagingServiceModule } from './messaging/messaging.module';
import { MonekServiceModule } from './monek/monek.module';
import { NavigationServiceModule } from './navigation/navigation.module';
import { OrderServiceModule } from './order/order.module';
import { OrdersServiceModule } from './orders/orders.module';
import { PaymentsServiceModule } from './payments/payments.module';
import { RemedialsServiceModule } from './remedials/remedials.module';
import { StorageServiceModule } from './storage/storage.module';
import {
    CacheLocalInterceptor,
    CompressionInterceptor,
} from './storage/interceptors';
import { TransferStateServiceModule } from './transfer-state/transfer-state.module';
import { WorkerServiceModule } from './worker/worker.module';

@NgModule({
    imports: [
        AboutServiceModule,
        ApiModule,
        ArticlesServiceModule,
        AuthServiceModule,
        BasketServiceModule,
        CatalogueServiceModule,
        CategoryListingServiceModule,
        CheckoutServiceModule,
        CustomerServiceModule,
        DialogServiceModule,
        FilterServiceModule,
        MarketingServiceModule,
        MessagingServiceModule,
        MonekServiceModule,
        NavigationServiceModule,
        OrderServiceModule,
        OrdersServiceModule,
        PaymentsServiceModule,
        RemedialsServiceModule,
        StorageServiceModule,
        TransferStateServiceModule,
        WorkerServiceModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: CacheLocalInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CompressionInterceptor, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: InflateInterceptor, multi: true }
    ]
})
export class ServicesModule { }
