import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
    protected defaultTimeout = 30000;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const value = Number(req.headers.get('timeout')) || this.defaultTimeout;
        const headers = req.headers.delete('timeout');
        const handledReq = req.clone({ headers });

        return next.handle(handledReq).pipe(
            timeout(value)
        );
    }
}
