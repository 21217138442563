@import '/src/theme/variables';

page-about-price-promise {
    header {
        .panel {
            display: flex;
            align-items: flex-start;
            img {
                margin-left: 15px;
                width: 25%;
            }
        }

    }

    @media (max-width: 992px) {
        .panel-right {
            margin-top: 32px;
        }
    }
}