<mat-dialog-content>
    <h4>Save your basket</h4>
    <p class="text-wrap">Your basket has been saved temporarily onto your device. To ensure you can retrieve your basket at any time and from any device, login or register.</p>

    <p class="error-message"><strong>Please note:</strong> we have a minimum order value of £750.00 (ex VAT) for kitchen orders.</p>

    <div class="button-wrapper">
        <button mat-stroked-button color="secondary" class="chunky" (click)="saveLater()">Save Basket Later</button>
        <button mat-flat-button color="primary" class="chunky" (click)="login()">Login / Register</button>
    </div>
</mat-dialog-content>