import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';
import { PipesModule } from '@app/shared/pipes';

import { RangeSelectorDialogComponent } from './range-selector.dialog.component';

@NgModule({
    imports: [
        DesignModule,
        PipesModule
    ],
    declarations: [
        RangeSelectorDialogComponent
    ]
})
export class RangeSelectorDialogModule { }
