@import '/src/theme/variables';

page-reset-password {
    header {
        &.content-contained {
            h1 {
                margin-top: 25px;
            }
        }
    }

    .error-message,
    .notice-message {
        margin-top: 15px;
        text-align: center;
    }

    .mat-mdc-form-field-focus-overlay,
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: transparent;
    }

    .mat-mdc-form-field {
        margin-bottom: 10px;
        width: 100%;
    }

    .mdc-button {
        margin-top: 25px;
    }

    .reset-form {
        max-width: 420px;
    }
}