@import '/src/theme/variables';

component-gallery {
    font-size: 0.95rem;

    .gallery-container {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 100%;

        .gallery {
            display: flex;
            grid-gap: 15px;
            overflow-x: scroll;
            margin: 0 0 0 -50px;
            padding-left: 20px;
            padding-right: 20px;
            width: 100%;

            &.centre-content {
                justify-content: center;
            }

            .gallery-item {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 5px;
                width: 150px;
                height: 150px;

                img {
                    border-radius: 8px;
                }

                .category-label {
                    position: absolute;
                    bottom: 7px;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    width: calc(100% - 10px);

                    .category-label-text {
                        display: flex;
                        align-items: flex-end;
                        padding: 5px;
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                        background-image: linear-gradient(transparent, color('black', 0.8));
                        width: 100%;
                        height: 35px;
                        text-align: center;
                    }

                    span {
                        width: 100%;
                        color: color('white');
                        font-size: 0.875rem;
                        font-weight: 600;
                        text-align: center;
                        text-transform: uppercase;
                    }
                }

                &.selected {
                    border-radius: 12px;
                    box-shadow: inset 0 0 0 2px color(brand-primary);
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .gallery::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }

        .arrow-control {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: color('brand-dark');
            border-radius: 5px;
            color: color('white');
            font-size: 1.6rem;
            height: 50px;
            width: 50px;
            z-index: 1;

            &.hidden {
                visibility: hidden;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .right-arrow {
            margin-left: -50px;
        }
    }

    @media only screen and (max-width: 768px) {
        .gallery-container {
            .gallery {
                gap: 5px;
                margin-left: -50px;
                padding-left: 70px;
                padding-right: 70px;

                .gallery-item {
                    width: 110px;
                    height: 110px;

                    .category-label {
                        bottom: 5px;

                        span {
                            font-size: 0.75rem;
                        }
                    }
                }
            }

            .arrow-control {
                border: none;
                font-size: 1rem;
                height: 25px;
                padding: 0;
                width: 25px;
                z-index: 1;
            }
        }
    }

    @media only screen and (max-width: 480px) {
        .gallery-container {
            .gallery {
                padding-left: 50px;
                padding-right: 50px;
            }
        }
    }
}