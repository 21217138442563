import { Component, ViewEncapsulation, Input, Output, OnInit, OnChanges, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';

import { Config } from '@app/config';
import { NumberHelper, StringHelper } from '@app/utilities/helpers';

import { BasketService } from '@app/services/basket';
import { BasketItem } from '@app/services/basket/models';
import { CatalogueService } from '@app/services/catalogue';
import { ProductType } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';

import { SolidSurfaceConfigChangeStyleDialogComponent } from './dialogs/change-style';
import { SolidSurfaceConfigCornerOptionsDialogComponent } from './dialogs/corner-options';
import { SolidSurfaceConfigCutoutOptionsDialogComponent } from './dialogs/cutout-options';
import { SolidSurfaceConfigProfileOptionsDialogComponent } from './dialogs/profile-options';
import { SolidSurfaceConfigSplashbackCillDialogComponent } from './dialogs/splashback-cill';

import {
    SolidSurfaceConfigLayout,
    SolidSurfaceConfigPieces,
    SolidSurfaceConfigProfiles,
    ISolidSurfaceConfig,
    ISolidSurfaceConfigPieceCorners,
    SolidSurfaceConfigPieceACorner,
    SolidSurfaceConfigPieceBCorner,
    SolidSurfaceConfigPieceCCorner,
    SolidSurfaceConfigPieceDCorner,
    SolidSurfaceConfigPieceECorner,
    SolidSurfaceConfigPieceICorner,
    ISolidSurfaceConfigPieceEdges,
    SolidSurfaceConfigPieceEdges,
    SolidSurfaceConfigPieceAEdges,
    SolidSurfaceConfigPieceBEdges,
    SolidSurfaceConfigPieceCEdges,
    SolidSurfaceConfigPieceDEdges,
    SolidSurfaceConfigPieceCorners,
    SolidSurfaceConfigCornerRadius,
    SolidSurfaceConfigTabs,
    ISolidSurfaceConfigPieceProfiles,
    SolidSurfaceConfigPieceAProfiles,
    SolidSurfaceConfigPieceBProfiles,
    SolidSurfaceConfigPieceCProfiles,
    SolidSurfaceConfigPieceDProfiles,
    SolidSurfaceConfigPieceEProfiles,
    SolidSurfaceConfigPieceIProfiles,
    SolidSurfaceConfigPieceProfiles,
    ISolidSurfaceConfigShapings,
    SolidSurfaceConfigShapings,
    ISolidSurfaceConfigShaping,
    ISolidSurfaceConfigPiece,
    ISolidSurfaceConfigPieceA,
    ISolidSurfaceConfigPieces,
    SolidSurfaceConfigOrientation
} from './models';

@Component({
    selector: 'component-solid-surface-config',
    templateUrl: './solid-surface-config.component.html',
    styleUrls: ['./solid-surface-config.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SolidSurfaceConfigComponent implements OnInit, OnChanges {
    @Input() readOnly: boolean = false;
    @Input() worktop: any;
    @Input() worktopConfigUuid: string = null;
    @Input() worktopConfig: ISolidSurfaceConfig = null;
    @Output() saveEmitter = new EventEmitter<any>();
    @ViewChild(MatStepper) stepper: MatStepper;

    public SolidSurfaceConfigPieces = SolidSurfaceConfigPieces;
    public SolidSurfaceConfigLayout = SolidSurfaceConfigLayout;
    public SolidSurfaceConfigProfiles = SolidSurfaceConfigProfiles;
    public SolidSurfaceConfigShapings = SolidSurfaceConfigShapings;

    public showWorktopSummary: boolean = false;

    public prices: any = {};
    public worktops: any;
    private canvasContext;
    public hasA: boolean;
    public hasB: boolean;
    public hasC: boolean;
    public hasD: boolean;
    public hasE: boolean;
    public hasI: boolean;
    public validDims: boolean;
    public sub3: SolidSurfaceConfigProfiles = SolidSurfaceConfigProfiles.PROFILED;
    public profileOptions = [];
    public cornerOpts: any;
    public graniteOptions: any = [];

    public stepIndex: number = 0;
    public layoutFormGroup: FormGroup;
    public dimensionsFormGroup: FormGroup;
    public edgesShapesUpstandsFormGroup: FormGroup;
    public appliancesSinksFormGroup: FormGroup;
    public splashbacksCillsFormGroup: FormGroup;
    public styleFormGroup: FormGroup;
    public quotationFormGroup: FormGroup;

    // 'angle-end'
    // 'breakfast-curve'
    // 'breakfront'
    // 'double-angled'
    // 'double-curve'
    // 'double-downturn'
    // 'full-angled'
    // 'full-curve'
    // 'horseshoe'
    // 'internal-curve'
    // 'large-curve'
    // 'leafed'
    // 'medium-curve'
    // 'onepointfive-recessed'
    // 'single-downturn'
    // 'single-recessed'
    // 'small-curve'
    // 'sshaped'
    // 'straight'
    // 'twin-angle-end'
    // 'twin-large-curve'
    private renderImages = {
        'extra-bowl': `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-extra-bowl.png`,
        'hob-bars': `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-hob-bars.png`,
        'hob': `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-hob.png`,
        'inset-sink': `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-inset-sink.png`,
        'large-circular-p': `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-large-circular-p.png`,
        // `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-large-circular-up.png`,
        'large-tap': `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-large-tap.png`,
        'popup-socket': `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-popup-socket.png`,
        // `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-range-cooker-100.png`,
        // `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-range-cooker-110.png`,
        // `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-range-cooker-120.png`,
        'range-cooker': `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-range-cooker-60.png`,
        // `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-range-cooker-90.png`,
        // `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-rebated-round.png`,
        'rebated-square': `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-rebated-square.png`,
        'small-tap': `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-small-tap.png`,
        'three-notch': `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-three-notch.png`,
        'two-notch': `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-two-notch.png`,
        'undermount': `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-undermount.png`,
        'belfast': `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-belfast.png`,
        // `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-doubleundermount.png`,
        // `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-downdraft.png`,
        '5straight': `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-drainer.png`,
        'fluted': `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-drainer.png`,
        'electrical-socket': `${this.config.api.endpoints.cdn}/assets/images/graniteworktops/icons/section-a-cutout-electrical-socket.png`,
    }

    private clickables: object[] = [];
    private canvasOffset: any = {};
    private startingConfig: ISolidSurfaceConfig = {
        tab: 1,
        maxTab: 1,
        layout: SolidSurfaceConfigLayout.SINGLE,
        addIsland: false,
        addExtra: false,
        pieces: {
            a: {
                length: 0,
                depth: 600,
                isValidLength: false,
                isValidDepth: true,
                edges: {
                    e: true
                },
                profiles: {
                    n: false,
                    s: false,
                    w: false
                },
                upstands: {
                    n: false,
                    s: false,
                    w: false
                },
                downturns: {
                    n: false,
                    s: false,
                    w: false
                },
                splashback: {
                    length: null,
                    height: null
                },
                cill: {
                    length: null,
                    height: null
                },
                corners: {
                    ne: null,
                    nw: null,
                    se: null,
                    sw: null
                },
                cutouts: [],
                gaps: []
            },
            b: {
                length: 0,
                depth: 600,
                isValidLength: false,
                isValidDepth: true,
                edges: {
                    s: true
                },
                profiles: {
                    n: false,
                    e: false
                },
                upstands: {
                    n: false,
                    e: false
                },
                downturns: {
                    n: false,
                    e: false
                },
                splashback: {
                    length: null,
                    height: null
                },
                cill: {
                    length: null,
                    height: null
                },
                corners: {
                    ne: null,
                    se: null,
                    sw: null
                },
                cutouts: [],
                gaps: []
            },
            c: {
                length: 0,
                depth: 600,
                isValidLength: false,
                isValidDepth: true,
                edges: {
                    w: true
                },
                profiles: {
                    n: false,
                    e: false,
                    s: false
                },
                upstands: {
                    n: false,
                    e: false,
                    s: false
                },
                downturns: {
                    n: false,
                    e: false,
                    s: false
                },
                splashback: {
                    length: null,
                    height: null
                },
                cill: {
                    length: null,
                    height: null
                },
                corners: {
                    ne: null,
                    nw: null,
                    se: null,
                    sw: null
                },
                cutouts: [],
                gaps: []
            },
            d: {
                length: 0,
                depth: 600,
                isValidLength: false,
                isValidDepth: true,
                edges: {
                    n: true
                },
                profiles: {
                    e: false,
                    s: false,
                    w: false
                },
                upstands: {
                    e: false,
                    s: false,
                    w: false
                },
                downturns: {
                    e: false,
                    s: false,
                    w: false
                },
                splashback: {
                    length: null,
                    height: null
                },
                cill: {
                    length: null,
                    height: null
                },
                corners: {
                    nw: null,
                    se: null,
                    sw: null
                },
                cutouts: [],
                gaps: []
            },
            e: {
                length: 0,
                depth: 600,
                isValidLength: false,
                isValidDepth: true,
                edges: {
                    n: true
                },
                profiles: {
                    e: false,
                    s: false,
                    w: false
                },
                upstands: {
                    e: false,
                    s: false,
                    w: false
                },
                downturns: {
                    e: false,
                    s: false,
                    w: false
                },
                splashback: {
                    length: null,
                    height: null
                },
                cill: {
                    length: null,
                    height: null
                },
                corners: {
                    ne: null,
                    nw: null,
                    se: null,
                    sw: null
                },
                cutouts: [],
                gaps: []
            },
            i: {
                length: 0,
                depth: 900,
                isValidLength: false,
                isValidDepth: true,
                edges: {
                    n: true,
                    e: true,
                    s: true,
                    w: true
                },
                profiles: {
                    e: false,
                    s: false,
                    w: false
                },
                upstands: {
                    e: false,
                    s: false,
                    w: false
                },
                downturns: {
                    e: false,
                    s: false,
                    w: false
                },
                splashback: {
                    length: null,
                    height: null
                },
                cill: {
                    length: null,
                    height: null
                },
                corners: {
                    ne: null,
                    nw: null,
                    se: null,
                    sw: null
                },
                cutouts: [],
                gaps: []
            }
        },
        upstandHeight: 100,
        prices: {}
    };

    private logCanvas: boolean = false;

    constructor(
        private config: Config,
        private basketService: BasketService,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private formBuilder: FormBuilder,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        if (!this.worktopConfig) {
            this.worktopConfig = JSON.parse(JSON.stringify(this.startingConfig));
        }

        this.layoutFormGroup = this.formBuilder.group({
            layoutControl: [this.worktopConfig.layout, Validators.required]
        });
        this.dimensionsFormGroup = this.formBuilder.group({
            dimensionsControl: [this.validDims, Validators.requiredTrue]
        });
        this.edgesShapesUpstandsFormGroup = this.formBuilder.group({});
        this.appliancesSinksFormGroup = this.formBuilder.group({});
        this.splashbacksCillsFormGroup = this.formBuilder.group({});
        this.styleFormGroup = this.formBuilder.group({});
        this.quotationFormGroup = this.formBuilder.group({});

        this.catalogueService.getGraniteOptions()
            .then((graniteOptions: any) => {
                this.graniteOptions = graniteOptions.filter((option) => {
                    return option.isActive !== false && option.isActive !== 0;
                });
                this.profileOptions = graniteOptions.filter((option) => option.type === 'edges');

                this.cornerOpts = {};
                graniteOptions.forEach((option) => {
                    if (option.type === 'shapings') {
                        this.cornerOpts[option.code] = option;
                    }
                });

                this.setupCanvas();
                this.update('init');
                // this.renderCanvas();
            })
            .catch((error) => console.warn(error));
    }

    ngOnChanges(changes: {}) {
        if (this.cornerOpts) {
            this.update('update');
        }
    }

    private combineMouseTouch(event, rect) {
        if (event.touches !== undefined && event.touches[0] !== undefined) {
            return {
                x: event.touches[0].pageX - rect.left,
                y: event.touches[0].pageY - rect.top
            };
        }

        // When on mobile safari, the coordinates information is inside the targetTouches object
        if (event.targetTouches !== undefined) {
            if (event.targetTouches[0] !== undefined) {
                event = event.targetTouches[0];
            }
        }

        if (event.pageX !== undefined && event.pageY !== undefined) {
            return {
                x: event.pageX - rect.left,
                y: event.pageY - rect.top
            };
        }

        if (this.config.isBrowser) {
            let element = !document.compatMode || document.compatMode === 'CSS1Compat' ? document.documentElement : document.body;

            return {
                x: event.clientX + element.scrollLeft - rect.left,
                y: event.clientY + element.scrollTop - rect.top
            };
        }

        return {
            x: 0,
            y: 0
        }
    }

    private setupCanvas() {
        if (this.config.isBrowser) {
            const canvasObj = <HTMLCanvasElement>document.getElementById('worktopCanvas');
            if (canvasObj) {
                this.canvasContext = canvasObj.getContext('2d');
            }

            // Canvas event handlers
            const eventStart = (event) => {
                const canvasRect = canvasObj.getBoundingClientRect();

                let mouseEvent = this.combineMouseTouch(event, canvasRect);
                mouseEvent.x -= window.scrollX; // Adjust for the page being scrolled across
                mouseEvent.y -= window.scrollY; // Adjust for the page being scrolled down
                mouseEvent.x = (mouseEvent.x - this.canvasOffset.origin.x) / this.canvasOffset.scale;
                mouseEvent.y = (mouseEvent.y - this.canvasOffset.origin.y) / this.canvasOffset.scale;

                this.clickables.forEach((clickable: any) => {
                    if (
                        clickable.x < mouseEvent.x && clickable.x + clickable.width > mouseEvent.x &&
                        clickable.y < mouseEvent.y && clickable.y + clickable.depth > mouseEvent.y
                    ) {
                        clickable.fn();
                    }
                });
            };

            // Add event listener to catch mouse clicks, etc.
            canvasObj.addEventListener('touchstart', eventStart, false);
            canvasObj.addEventListener('mousedown', eventStart, false);
        }
    }

    private moveTo(ctx, x, y, reference?: string) {
        if (reference) {
            this.log(reference);
        }

        this.log('Move To', x, y);
        ctx.moveTo(x, y);
    }

    private lineTo(ctx, x, y, reference?: string) {
        if (reference) {
            this.log(reference);
        }

        this.log('Line To', x, y);
        ctx.lineTo(x, y);
    }

    private arcTo(ctx, x1, y1, x2, y2, radius, reference?: string) {
        if (reference) {
            this.log(reference);
        }

        this.log('Arc To', x1, y1, x2, y2, radius);
        ctx.arcTo(x1, y1, x2, y2, radius);
    }

    private log(...values) {
        if (this.logCanvas) {
            console.log.apply(this, values);
        }
    }

    private renderCanvas() {
        let ctx: CanvasRenderingContext2D = this.canvasContext;
        if (ctx) {
            // Reference Fields
            const pieceA = this.worktopConfig.pieces[SolidSurfaceConfigPieces.A];
            const pieceB = this.worktopConfig.pieces[SolidSurfaceConfigPieces.B];
            const pieceC = this.worktopConfig.pieces[SolidSurfaceConfigPieces.C];
            const pieceD = this.worktopConfig.pieces[SolidSurfaceConfigPieces.D];
            const pieceE = this.worktopConfig.pieces[SolidSurfaceConfigPieces.E];
            const pieceI = this.worktopConfig.pieces[SolidSurfaceConfigPieces.I];

            const isGShaped = this.worktopConfig.layout === SolidSurfaceConfigLayout.G_SHAPED;
            const extraGap = 450;
            const canvasWidth = 760;
            const canvasHeight = 500;
            // Allows 30 pixels outer boarder, plus 30 pixels for outer dim measurements
            const canvasVerticalPadding = 30 + 15 + 15;
            const canvasHorizontalPadding = 30 + 30 + 15 + 15;

            const defaultLengthAC = 3000;
            const defaultLengthA = 3000;
            const defaultLengthB = 3000;
            const defaultLengthC = 3000;
            const defaultLengthD = 1600;
            const defaultLengthE = 1600;
            const defaultLengthI = 1800;

            let leftLength = pieceA.length || defaultLengthA;
            let topLength = pieceA.depth + (this.hasB ? pieceB.length || defaultLengthB : this.worktopConfig.layout === SolidSurfaceConfigLayout.GALLEY ? defaultLengthB : 0) + (this.hasC ? pieceC.depth : 0);
            let rightLength = (pieceC.length || ((isGShaped) ? defaultLengthC - 600 : defaultLengthC)) + ((this.hasD) ? pieceD.depth : 0);

            if (this.worktopConfig.layout === SolidSurfaceConfigLayout.ISLAND) {
                leftLength = rightLength = pieceI.depth;
                topLength = pieceI.length || defaultLengthI;
            }

            const maxWidth = Math.max((this.hasE) ? pieceE.length || defaultLengthE : 0, topLength);
            const maxDepth = Math.max(leftLength, rightLength) + (this.hasE ? extraGap + pieceE.depth : 0);

            const scale = 1 / Math.max(maxWidth / (canvasWidth - canvasHorizontalPadding), maxDepth / (canvasHeight - canvasVerticalPadding));
            const lineWidth = Math.floor(1 / scale) / 2;

            this.canvasOffset = {
                scale: scale,
                origin: {
                    x: (canvasWidth - maxWidth * scale) / 2,
                    y: (canvasHeight - maxDepth * scale) / 2
                }
            };

            // Clear canvas and set up
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);
            ctx.beginPath();
            ctx.save();
            ctx.translate(this.canvasOffset.origin.x, this.canvasOffset.origin.y);
            ctx.scale(scale, scale);

            ctx.lineWidth = lineWidth;
            ctx.font = 14 * Math.floor(1 / scale) + 'px \'Open Sans\', sans-serif';
            ctx.lineCap = 'square';
            ctx.textBaseline = 'top';
            ctx.fillStyle = '#FFF';
            ctx.strokeStyle = '#333';

            const text = (text, x, y) => {
                ctx.save();
                ctx.fillStyle = '#005CB8';
                ctx.fillText(text, x, y);
                ctx.restore();
            };

            // Render the runs
            let radius = 0; // corner radius
            let isAngled = false; // is angled corner
            let isInternal = false;

            const cornerRadius = (piece: SolidSurfaceConfigPieces, location: ISolidSurfaceConfigPieceCorners) => {
                switch (this.worktopConfig.pieces[piece].corners[location]) {
                    case SolidSurfaceConfigCornerRadius.SOFT:
                        return 40;
                    case SolidSurfaceConfigCornerRadius.RADIUS:
                        return 100;
                    case SolidSurfaceConfigCornerRadius.CURVED:
                        return 300;
                    case SolidSurfaceConfigCornerRadius.INTERNAL:
                        return 300;
                    case SolidSurfaceConfigCornerRadius.ANGLED:
                        return 200;
                    default:
                        return 0;
                }
            };

            const cornerPrep = (piece: SolidSurfaceConfigPieces, location: ISolidSurfaceConfigPieceCorners) => {
                isAngled = this.worktopConfig.pieces[piece].corners[location] === SolidSurfaceConfigCornerRadius.ANGLED;
                isInternal = this.worktopConfig.pieces[piece].corners[location] === SolidSurfaceConfigCornerRadius.INTERNAL;

                return cornerRadius(piece, location);
            };

            const setEdgeStyle = (piece: SolidSurfaceConfigPieces, edge: ISolidSurfaceConfigPieceEdges, noStroke: boolean = false) => {
                if (!noStroke) {
                    ctx.stroke();
                }

                ctx.beginPath();
                const setProfiled = () => {
                    ctx.lineWidth = lineWidth * 5;
                    ctx.strokeStyle = '#C00';
                    if (
                        this.worktopConfig.tab === SolidSurfaceConfigTabs.SHAPINGS &&
                        this.sub3 !== SolidSurfaceConfigProfiles.PROFILED &&
                        this.sub3 !== SolidSurfaceConfigProfiles.CORNER
                    ) {
                        ctx.globalAlpha = 0.4;
                    } else {
                        ctx.globalAlpha = 1;
                    }
                };

                const setUpstand = () => {
                    ctx.lineWidth = lineWidth * 5;
                    ctx.strokeStyle = '#00C';
                    if (
                        this.worktopConfig.tab === SolidSurfaceConfigTabs.SHAPINGS &&
                        this.sub3 !== SolidSurfaceConfigProfiles.UPSTAND
                    ) {
                        ctx.globalAlpha = 0.4;
                    } else {
                        ctx.globalAlpha = 1;
                    }
                };

                const setDefault = () => {
                    ctx.lineWidth = lineWidth;
                    ctx.strokeStyle = '#333';
                    ctx.globalAlpha = 1;
                };

                const checkLocation = (piece: SolidSurfaceConfigPieces, edge: ISolidSurfaceConfigPieceProfiles) => {
                    if (this.worktopConfig.pieces[piece].profiles[edge]) {
                        return setProfiled();
                    }

                    if (this.worktopConfig.pieces[piece].upstands[edge]) {
                        return setUpstand();
                    }

                    setDefault();
                };

                if (this.worktopConfig.pieces[piece].edges[edge]) {
                    // if mandatory profiled
                    return setProfiled();
                }

                switch (piece) {
                    case SolidSurfaceConfigPieces.A:
                        switch (edge) {
                            case SolidSurfaceConfigPieceEdges.N:
                                return checkLocation(SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.N);
                            case SolidSurfaceConfigPieceEdges.S:
                                return checkLocation(SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.S);
                            case SolidSurfaceConfigPieceEdges.W:
                                return checkLocation(SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.W);
                            default:
                                return setDefault();
                        }
                    case SolidSurfaceConfigPieces.B:
                        switch (edge) {
                            case SolidSurfaceConfigPieceEdges.N:
                                return checkLocation(SolidSurfaceConfigPieces.B, SolidSurfaceConfigPieceBProfiles.N);
                            case SolidSurfaceConfigPieceEdges.E:
                                if (this.hasC) {
                                    return setDefault();
                                }

                                return checkLocation(SolidSurfaceConfigPieces.B, SolidSurfaceConfigPieceBProfiles.E);
                            default:
                                return setDefault();
                        }
                    case SolidSurfaceConfigPieces.C:
                        switch (edge) {
                            case SolidSurfaceConfigPieceEdges.N:
                                return checkLocation(SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.N);
                            case SolidSurfaceConfigPieceEdges.E:
                                return checkLocation(SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.E);
                            case SolidSurfaceConfigPieceEdges.S:
                                if (this.hasD) {
                                    return setDefault();
                                }

                                return checkLocation(SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.S);
                            default:
                                return setDefault();
                        }
                    case SolidSurfaceConfigPieces.D:
                        switch (edge) {
                            case SolidSurfaceConfigPieceEdges.E:
                                return checkLocation(SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDProfiles.E);
                            case SolidSurfaceConfigPieceEdges.S:
                                return checkLocation(SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDProfiles.S);
                            case SolidSurfaceConfigPieceEdges.W:
                                return checkLocation(SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDProfiles.W);
                            default:
                                return setDefault();
                        }
                    case SolidSurfaceConfigPieces.E:
                        switch (edge) {
                            case SolidSurfaceConfigPieceEdges.E:
                                return checkLocation(SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.E);
                            case SolidSurfaceConfigPieceEdges.S:
                                return checkLocation(SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.S);
                            case SolidSurfaceConfigPieceEdges.W:
                                return checkLocation(SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.W);
                            default:
                                return setDefault();
                        }
                    case SolidSurfaceConfigPieces.I:
                        switch (edge) {
                            case SolidSurfaceConfigPieceEdges.E:
                                return checkLocation(SolidSurfaceConfigPieces.I, SolidSurfaceConfigPieceIProfiles.E);
                            case SolidSurfaceConfigPieceEdges.S:
                                return checkLocation(SolidSurfaceConfigPieces.I, SolidSurfaceConfigPieceIProfiles.S);
                            case SolidSurfaceConfigPieceEdges.W:
                                return checkLocation(SolidSurfaceConfigPieces.I, SolidSurfaceConfigPieceIProfiles.W);
                            default:
                                return setDefault();
                        }
                }
            };

            const renderPiece = (piece: SolidSurfaceConfigPieces, x: number, y: number, w: number, h: number) => {
                ctx.save();
                ctx.beginPath();

                // Pass 1 - Fill
                this.log('Pass 1');

                radius = cornerPrep(piece, SolidSurfaceConfigPieceCorners.NW);
                this.moveTo(ctx, x, y + radius);

                if (isAngled) {
                    this.lineTo(ctx, x + radius, y);
                } else {
                    this.arcTo(ctx, x, y, x + radius, y, radius);
                }

                radius = cornerPrep(piece, SolidSurfaceConfigPieceCorners.NE);
                this.lineTo(ctx, x + w - radius, y);

                if (isAngled) {
                    this.lineTo(ctx, x + w, y + radius);
                } else {
                    this.arcTo(ctx, x + w, y, x + w, y + radius, radius);
                }

                radius = cornerPrep(piece, SolidSurfaceConfigPieceCorners.SE);
                this.lineTo(ctx, x + w, y + h - radius);

                if (isInternal) {
                    this.lineTo(ctx, x + w, y + h + radius);
                    this.arcTo(ctx, x + w, y + h, x + w - radius, y + h, radius);
                } else {
                    if (isAngled) {
                        this.lineTo(ctx, x + w - radius, y + h);
                    } else {
                        this.arcTo(ctx, x + w, y + h, x + w - radius, y + h, radius);
                    }
                }

                radius = cornerPrep(piece, SolidSurfaceConfigPieceCorners.SW);
                this.lineTo(ctx, x + radius, y + h);

                if (isInternal) {
                    if (piece === SolidSurfaceConfigPieces.B) {
                        this.arcTo(ctx, x, y + h, x, y + h + radius, radius);
                    } else {
                        this.lineTo(ctx, x - radius, y + h);
                        this.arcTo(ctx, x, y + h, x, y + h - radius, radius);
                    }
                } else {
                    if (isAngled) {
                        this.lineTo(ctx, x, y + h - radius);
                    } else {
                        this.arcTo(ctx, x, y + h, x, y + h - radius, radius);
                    }
                }

                ctx.closePath();
                ctx.fill();
                ctx.stroke();

                // Pass 2 - Edges and Style
                this.log('Pass 2');

                setEdgeStyle(piece, SolidSurfaceConfigPieceEdges.N, true);
                radius = cornerPrep(piece, SolidSurfaceConfigPieceCorners.NW);
                const initialRadius = radius;

                this.moveTo(ctx, x, y + radius);

                if (isAngled) {
                    this.lineTo(ctx, x + radius, y);
                } else {
                    this.arcTo(ctx, x, y, x + radius, y, radius);
                }

                radius = cornerPrep(piece, SolidSurfaceConfigPieceCorners.NE);
                if (piece === SolidSurfaceConfigPieces.D) {
                    this.lineTo(ctx, x + w - pieceC.depth - cornerRadius(SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCorners.SW), y);
                    setEdgeStyle(piece, SolidSurfaceConfigPieceEdges.E);
                    this.moveTo(ctx, x + w, y);
                } else {
                    this.lineTo(ctx, x + w - radius, y);
                    if (piece === SolidSurfaceConfigPieces.A && this.hasB) {
                        setEdgeStyle(piece, SolidSurfaceConfigPieceEdges.E);
                        this.moveTo(ctx, x + w, y + pieceB.depth + cornerRadius(SolidSurfaceConfigPieces.B, SolidSurfaceConfigPieceCorners.SW));
                    } else {
                        if (isAngled) {
                            this.lineTo(ctx, x + w, y + radius);
                        } else {
                            this.arcTo(ctx, x + w, y, x + w, y + radius, radius);
                        }

                        setEdgeStyle(piece, SolidSurfaceConfigPieceEdges.E);
                        this.moveTo(ctx, x + w, y + radius);
                    }
                }

                radius = cornerPrep(piece, SolidSurfaceConfigPieceCorners.SE);
                if (piece === SolidSurfaceConfigPieces.B && this.hasC) {
                    this.moveTo(ctx, x + w, y + h - radius);
                } else {
                    this.lineTo(ctx, x + w, y + h - radius);
                }

                if (isInternal) {
                    setEdgeStyle(piece, SolidSurfaceConfigPieceEdges.S);
                    this.lineTo(ctx, x + w, y + h + radius);
                    this.arcTo(ctx, x + w, y + h, x + w - radius, y + h, radius);
                } else {
                    if (isAngled) {
                        this.lineTo(ctx, x + w - radius, y + h);
                    } else {
                        this.arcTo(ctx, x + w, y + h, x + w - radius, y + h, radius);
                    }
                }

                setEdgeStyle(piece, SolidSurfaceConfigPieceEdges.S);
                this.moveTo(ctx, x + w - radius, y + h);
                radius = cornerPrep(piece, SolidSurfaceConfigPieceCorners.SW);

                if (piece === SolidSurfaceConfigPieces.C && this.hasD) {
                    this.moveTo(ctx, x + radius, y + h);
                } else {
                    this.lineTo(ctx, x + radius, y + h, '2');
                }

                this.lineTo(ctx, x + radius, y + h, '3');
                if (isInternal) {
                    if (piece === SolidSurfaceConfigPieces.B) {
                        this.arcTo(ctx, x, y + h, x, y + h + radius, radius);
                    } else {
                        setEdgeStyle(piece, SolidSurfaceConfigPieceEdges.W);
                        this.lineTo(ctx, x - radius, y + h);
                        this.arcTo(ctx, x, y + h, x, y + h - radius, radius);
                    }
                } else {
                    if (isAngled) {
                        this.lineTo(ctx, x, y + h - radius);
                    } else {
                        this.arcTo(ctx, x, y + h, x, y + h - radius, radius);
                    }
                }

                setEdgeStyle(piece, SolidSurfaceConfigPieceEdges.W);
                if (piece !== SolidSurfaceConfigPieces.B) {
                    if (piece === SolidSurfaceConfigPieces.C && this.hasB) {
                        ctx.beginPath();
                        this.moveTo(ctx, x, y + h - radius);
                        this.lineTo(ctx, x, y + pieceB.depth + cornerRadius(SolidSurfaceConfigPieces.B, SolidSurfaceConfigPieceCorners.SE));
                        ctx.stroke();
                    } else {
                        ctx.beginPath();
                        this.moveTo(ctx, x, y + h - radius);
                        this.lineTo(ctx, x, y + initialRadius);
                        ctx.stroke();
                    }
                }

                ctx.restore();
            };

            const renderDownturn = (piece: SolidSurfaceConfigPieces, edge: ISolidSurfaceConfigPieceProfiles, x: number, y: number, w: number) => {
                const downturnDepth = this.worktopConfig.layout === SolidSurfaceConfigLayout.ISLAND ? 60 : 160;
                const downturn = this.worktopConfig.pieces[piece].downturns[edge];

                if (downturn) {
                    switch (edge) {
                        case SolidSurfaceConfigPieceProfiles.N:
                            this.moveTo(ctx, x, y);
                            this.lineTo(ctx, x + w, y);
                            this.lineTo(ctx, x + w - downturnDepth, -downturnDepth);
                            this.lineTo(ctx, x + downturnDepth, -downturnDepth);
                            this.lineTo(ctx, x, y);
                            break;
                        case SolidSurfaceConfigPieceProfiles.E:
                            this.moveTo(ctx, x, y);
                            this.lineTo(ctx, x, y + w);
                            this.lineTo(ctx, x + downturnDepth, y + w - downturnDepth);
                            this.lineTo(ctx, x + downturnDepth, y + downturnDepth);
                            this.lineTo(ctx, x, y);
                            break;
                        case SolidSurfaceConfigPieceProfiles.S:
                            this.moveTo(ctx, x, y);
                            this.lineTo(ctx, x + w, y);
                            this.lineTo(ctx, x + w - downturnDepth, y + downturnDepth);
                            this.lineTo(ctx, x + downturnDepth, y + downturnDepth);
                            this.lineTo(ctx, x, y);
                            break;
                        case SolidSurfaceConfigPieceProfiles.W:
                            this.moveTo(ctx, x, y);
                            this.lineTo(ctx, x, y + w);
                            this.lineTo(ctx, x - downturnDepth, y + w - downturnDepth);
                            this.lineTo(ctx, x - downturnDepth, y + downturnDepth);
                            this.lineTo(ctx, x, y);
                            break;
                    }
                }
            };

            // Render Piece A
            if (this.hasA) {
                this.log('Render A');
                renderPiece(SolidSurfaceConfigPieces.A, 0, 0, pieceA.depth, pieceA.length || defaultLengthAC);
                text('A', 50, 50);
            }

            // Render Piece B
            const pieceBX = pieceA.depth;
            if (this.hasB) {
                this.log('Render B');
                renderPiece(SolidSurfaceConfigPieces.B, pieceBX, 0, pieceB.length || defaultLengthB, pieceB.depth);
                text('B', pieceA.depth + 50, 50);
            }

            // Render Piece C
            const pieceCX = pieceA.depth + (pieceB.length || defaultLengthB);
            if (this.hasC) {
                this.log('Render C');
                renderPiece(SolidSurfaceConfigPieces.C, pieceCX, 0, pieceC.depth, pieceC.length || (isGShaped ? defaultLengthAC - 600 : defaultLengthAC));
                text('C', pieceA.depth + (pieceB.length || defaultLengthB) + 50, 50);
            }

            // Render Piece D
            const pieceDX = pieceA.depth + (pieceB.length || defaultLengthB) + pieceC.depth - (pieceD.length || defaultLengthD);
            const pieceDY = pieceC.length || (isGShaped ? defaultLengthAC - 600 : defaultLengthAC);
            if (this.hasD) {
                this.log('Render D');
                renderPiece(SolidSurfaceConfigPieces.D, pieceDX, pieceDY, pieceD.length || defaultLengthD, pieceD.depth);
                text('D', pieceDX + 50, pieceDY + 50);
            }

            // Render Piece E
            const pieceEY = Math.max(leftLength, rightLength) + extraGap;
            if (this.hasE) {
                this.log('Render E');
                renderPiece(SolidSurfaceConfigPieces.E, 0, pieceEY, pieceE.length || defaultLengthE, pieceE.depth);
                text('Extra', 50, Math.max(leftLength, rightLength) + extraGap + 50);
            }

            // Render Island
            const pieceIX = (this.worktopConfig.layout === SolidSurfaceConfigLayout.ISLAND) ? 0 : pieceA.depth + 600;
            const pieceIY = (this.worktopConfig.layout === SolidSurfaceConfigLayout.ISLAND) ? 0 : pieceB.depth + 450;
            if (this.worktopConfig.layout !== SolidSurfaceConfigLayout.ISLAND && this.hasI) {
                this.log('Render I');
                renderPiece(SolidSurfaceConfigPieces.I, pieceIX, pieceIY, pieceI.length || defaultLengthI, pieceI.depth);
                text('Island', pieceIX + 50, pieceB.depth + 500);
            } else if (this.worktopConfig.layout === SolidSurfaceConfigLayout.ISLAND) {
                this.log('Render I');
                renderPiece(SolidSurfaceConfigPieces.I, 0, 0, pieceI.length || defaultLengthI, pieceI.depth);
                text('Island', 50, 50);
            }

            // Render Downturns
            if (this.worktopConfig.tab > SolidSurfaceConfigTabs.DIMENSIONS) {
                ctx.save();
                if (
                    this.worktopConfig.tab === SolidSurfaceConfigTabs.SHAPINGS &&
                    this.sub3 !== SolidSurfaceConfigProfiles.DOWNTURN
                ) {
                    ctx.globalAlpha = 0.25;
                }

                ctx.lineWidth = ctx.lineWidth * 5;
                ctx.strokeStyle = '#C00';
                ctx.beginPath();

                if (this.hasA) {
                    renderDownturn(SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.N, 0, 0, pieceA.depth);
                    renderDownturn(SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.S, 0, pieceA.length, pieceA.depth);
                    renderDownturn(SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.W, 0, 0, pieceA.length);
                }

                if (this.hasB) {
                    renderDownturn(SolidSurfaceConfigPieces.B, SolidSurfaceConfigPieceBProfiles.N, pieceBX, 0, pieceB.length);
                    renderDownturn(SolidSurfaceConfigPieces.B, SolidSurfaceConfigPieceBProfiles.E, (pieceBX + pieceB.length), 0, pieceB.depth);
                }

                if (this.hasC) {
                    renderDownturn(SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.N, pieceCX, 0, pieceC.depth);
                    renderDownturn(SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.E, topLength, 0, pieceC.length);
                    renderDownturn(SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.S, pieceCX, pieceC.length, pieceC.depth);
                }

                if (this.hasD) {
                    renderDownturn(SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDProfiles.E, topLength, pieceC.length, pieceD.depth);
                    renderDownturn(SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDProfiles.S, pieceDX, (pieceDY + pieceD.depth), pieceD.length);
                    renderDownturn(SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDProfiles.W, pieceDX, pieceDY, pieceA.depth);
                }

                if (this.hasE) {
                    renderDownturn(SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.E, pieceE.length, pieceEY, pieceE.depth);
                    renderDownturn(SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.S, 0, (pieceEY + pieceE.depth), pieceE.length);
                    renderDownturn(SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.W, 0, pieceEY, pieceE.depth);
                }

                if (this.hasI) {
                    renderDownturn(SolidSurfaceConfigPieces.I, SolidSurfaceConfigPieceIProfiles.E, (pieceIX + pieceI.length), pieceIY, pieceI.depth);
                    renderDownturn(SolidSurfaceConfigPieces.I, SolidSurfaceConfigPieceIProfiles.S, pieceIX, (pieceIY + pieceI.depth), pieceI.length);
                    renderDownturn(SolidSurfaceConfigPieces.I, SolidSurfaceConfigPieceIProfiles.W, pieceIX, pieceIY, pieceI.depth);
                }

                ctx.stroke();
                ctx.restore();
            }

            // Render Outer Wall Dimensions
            if (
                this.worktopConfig.tab === SolidSurfaceConfigTabs.DIMENSIONS ||
                this.worktopConfig.tab === SolidSurfaceConfigTabs.SUMMARY
            ) {
                if (this.worktopConfig.layout !== SolidSurfaceConfigLayout.ISLAND) {
                    ctx.save();
                    ctx.strokeStyle = '#000';
                    ctx.fillStyle = '#000';
                    ctx.textAlign = 'center';

                    // Left Hand Wall Dimensions
                    this.moveTo(ctx, -15 / scale, 0);
                    this.lineTo(ctx, -5 / scale, 0);
                    this.moveTo(ctx, -10 / scale, 0);
                    this.lineTo(ctx, -10 / scale, leftLength);
                    this.moveTo(ctx, -15 / scale, leftLength);
                    this.lineTo(ctx, -5 / scale, leftLength);
                    ctx.save();
                    ctx.translate(-30 / scale, leftLength / 2);
                    ctx.rotate(-Math.PI / 2);
                    ctx.fillText((pieceA.length ? leftLength : '? ') + 'mm', 0, 0);
                    ctx.restore();

                    // Top Wall Dimensions
                    if (this.hasB) {
                        this.moveTo(ctx, 0, -15 / scale);
                        this.lineTo(ctx, 0, -5 / scale);
                        this.moveTo(ctx, 0, -10 / scale);
                        this.lineTo(ctx, topLength, -10 / scale);
                        this.moveTo(ctx, 0, -10 / scale);
                        this.lineTo(ctx, topLength, -10 / scale);
                        this.moveTo(ctx, topLength, -15 / scale);
                        this.lineTo(ctx, topLength, -5 / scale);
                        ctx.save();
                        ctx.translate(topLength / 2, -30 / scale);
                        ctx.fillText((pieceB.length ? topLength : '? ') + 'mm', 0, 0);
                        ctx.restore();
                    }

                    // Right Hand Wall Dimensions
                    if (this.hasC) {
                        this.moveTo(ctx, topLength + 15 / scale, 0);
                        this.lineTo(ctx, topLength + 5 / scale, 0);
                        this.moveTo(ctx, topLength + 10 / scale, 0);
                        this.lineTo(ctx, topLength + 10 / scale, rightLength);
                        this.moveTo(ctx, topLength + 15 / scale, rightLength);
                        this.lineTo(ctx, topLength + 5 / scale, rightLength);
                        ctx.save();
                        ctx.translate(topLength + 30 / scale, rightLength / 2);
                        ctx.rotate(Math.PI / 2);
                        ctx.fillText((pieceC.length ? rightLength : '? ') + 'mm', 0, 0);
                        ctx.restore();
                    }

                    // Bottom Wall Dimensions
                    if (this.hasD) {
                        this.moveTo(ctx, pieceDX, pieceDY + pieceD.depth + 15 / scale);
                        this.lineTo(ctx, pieceDX, pieceDY + pieceD.depth + 5 / scale);
                        this.moveTo(ctx, pieceDX, pieceDY + pieceD.depth + 10 / scale);
                        this.lineTo(ctx, pieceDX + (pieceD.length || defaultLengthD), pieceDY + pieceD.depth + 10 / scale);
                        this.moveTo(ctx, pieceDX, pieceDY + pieceD.depth + 10 / scale);
                        this.lineTo(ctx, pieceDX + (pieceD.length || defaultLengthD), pieceDY + pieceD.depth + 10 / scale);
                        this.moveTo(ctx, pieceDX + (pieceD.length || defaultLengthD), pieceDY + pieceD.depth + 15 / scale);
                        this.lineTo(ctx, pieceDX + (pieceD.length || defaultLengthD), pieceDY + pieceD.depth + 5 / scale);
                        ctx.save();
                        ctx.translate(pieceDX + (pieceD.length || defaultLengthD) / 2, pieceDY + pieceD.depth + 12 / scale);
                        ctx.fillText((pieceD.length ? pieceD.length : '? ') + 'mm', 0, 0);
                        ctx.restore();
                    }

                    ctx.stroke();
                    ctx.restore();
                }
            }

            this.clickables = [];

            const renderTickbox = (x: number, y: number, radius: number, on: boolean, colour: string) => {
                ctx.strokeStyle = (on) ? colour : '#000';
                ctx.beginPath();
                ctx.fillRect(x - radius, y - radius, radius * 2, radius * 2);
                ctx.strokeRect(x - radius, y - radius, radius * 2, radius * 2);

                if (on) {
                    ctx.save();
                    ctx.fillStyle = colour;
                    ctx.textBaseline = 'middle';
                    ctx.textAlign = 'center';
                    ctx.fillText('✓', x, y);
                    ctx.restore();
                }
            };

            const renderConfig = (x: number, y: number, radius: number) => {
                ctx.beginPath();
                ctx.fillRect(x - radius, y - radius, radius * 2, radius * 2);
                ctx.strokeRect(x - radius, y - radius, radius * 2, radius * 2);
                ctx.save();
                ctx.fillStyle = ctx.strokeStyle;
                ctx.textBaseline = 'middle';
                ctx.textAlign = 'center';
                ctx.fillText('☰', x, y);
                ctx.restore();
            };

            const clearCorners = (piece: SolidSurfaceConfigPieces, edge: ISolidSurfaceConfigPieceProfiles) => {
                const mapKey: string = (piece === SolidSurfaceConfigPieces.E || piece === SolidSurfaceConfigPieces.I) ? `${piece}${edge}` : edge;
                const map: { [x: string]: { piece: SolidSurfaceConfigPieces, corner: SolidSurfaceConfigPieceCorners }[] } = {
                    n: [
                        { piece: SolidSurfaceConfigPieces.A, corner: SolidSurfaceConfigPieceCorners.NW },
                        { piece: SolidSurfaceConfigPieces.C, corner: SolidSurfaceConfigPieceCorners.NE }
                    ],
                    w: [
                        { piece: SolidSurfaceConfigPieces.A, corner: SolidSurfaceConfigPieceCorners.NW },
                        { piece: SolidSurfaceConfigPieces.A, corner: SolidSurfaceConfigPieceCorners.SW }
                    ],
                    e: [
                        { piece: SolidSurfaceConfigPieces.C, corner: SolidSurfaceConfigPieceCorners.NE },
                        { piece: SolidSurfaceConfigPieces.C, corner: SolidSurfaceConfigPieceCorners.SE },
                        { piece: SolidSurfaceConfigPieces.D, corner: SolidSurfaceConfigPieceCorners.SE }
                    ],
                    se: [
                        { piece: SolidSurfaceConfigPieces.C, corner: SolidSurfaceConfigPieceCorners.SE },
                        { piece: SolidSurfaceConfigPieces.C, corner: SolidSurfaceConfigPieceCorners.SW },
                        { piece: SolidSurfaceConfigPieces.D, corner: SolidSurfaceConfigPieceCorners.SE },
                        { piece: SolidSurfaceConfigPieces.D, corner: SolidSurfaceConfigPieceCorners.SW }
                    ],
                    sw: [
                        { piece: SolidSurfaceConfigPieces.A, corner: SolidSurfaceConfigPieceCorners.SE },
                        { piece: SolidSurfaceConfigPieces.A, corner: SolidSurfaceConfigPieceCorners.SW }
                    ],
                    ee: [
                        { piece: SolidSurfaceConfigPieces.E, corner: SolidSurfaceConfigPieceCorners.NE },
                        { piece: SolidSurfaceConfigPieces.E, corner: SolidSurfaceConfigPieceCorners.SE }
                    ],
                    es: [
                        { piece: SolidSurfaceConfigPieces.E, corner: SolidSurfaceConfigPieceCorners.SE },
                        { piece: SolidSurfaceConfigPieces.E, corner: SolidSurfaceConfigPieceCorners.SW }
                    ],
                    ew: [
                        { piece: SolidSurfaceConfigPieces.E, corner: SolidSurfaceConfigPieceCorners.NW },
                        { piece: SolidSurfaceConfigPieces.E, corner: SolidSurfaceConfigPieceCorners.SW }
                    ],
                    ie: [
                        { piece: SolidSurfaceConfigPieces.I, corner: SolidSurfaceConfigPieceCorners.NE },
                        { piece: SolidSurfaceConfigPieces.I, corner: SolidSurfaceConfigPieceCorners.SE }
                    ],
                    is: [
                        { piece: SolidSurfaceConfigPieces.I, corner: SolidSurfaceConfigPieceCorners.SE },
                        { piece: SolidSurfaceConfigPieces.I, corner: SolidSurfaceConfigPieceCorners.SW }
                    ],
                    iw: [
                        { piece: SolidSurfaceConfigPieces.I, corner: SolidSurfaceConfigPieceCorners.NW },
                        { piece: SolidSurfaceConfigPieces.I, corner: SolidSurfaceConfigPieceCorners.SW }
                    ]
                };

                if (map[mapKey]) {
                    map[mapKey].forEach((location) => {
                        this.worktopConfig.pieces[location.piece].corners[location.corner] = null;
                    });
                }
            };

            // Render Profiled Edge Markers
            if (this.worktopConfig.tab === SolidSurfaceConfigTabs.SHAPINGS && this.sub3 === SolidSurfaceConfigProfiles.PROFILED) {
                const profileSpot = (x: number, y: number, configPiece: SolidSurfaceConfigPieces | SolidSurfaceConfigPieces[], edge: ISolidSurfaceConfigPieceProfiles) => {
                    const radius: number = 12 / scale;
                    const pieces: SolidSurfaceConfigPieces[] = (configPiece instanceof Array) ? configPiece : [configPiece];

                    renderTickbox(x, y, radius, this.worktopConfig.pieces[pieces[0]].profiles[edge], '#C00');
                    this.clickables.push({
                        x: x - radius,
                        y: y - radius,
                        width: radius * 2,
                        depth: radius * 2,
                        fn: () => {
                            pieces.forEach((piece) => {
                                this.worktopConfig.pieces[piece].upstands[edge] = false;
                                this.worktopConfig.pieces[piece].downturns[edge] = false;
                                this.worktopConfig.pieces[piece].profiles[edge] = !this.worktopConfig.pieces[piece].profiles[edge];
                                if (!this.worktopConfig.pieces[piece].profiles[edge]) {
                                    clearCorners(piece, edge);
                                }
                            });
                            this.update('profiled edge markers');
                        }
                    });
                };

                ctx.save();
                ctx.fillStyle = '#EFEFEF';
                ctx.lineWidth = 3 * ctx.lineWidth;

                // North Edge
                if (this.hasB) {
                    let profiledEdgeNorth = [SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieces.B];
                    if (this.hasC) {
                        profiledEdgeNorth.push(SolidSurfaceConfigPieces.C);
                    }

                    profileSpot(topLength / 2, 0, profiledEdgeNorth, SolidSurfaceConfigPieceProfiles.N);
                } else {
                    if (this.hasA) {
                        profileSpot(pieceA.depth / 2, 0, SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.N);
                    }

                    if (this.hasC) {
                        profileSpot(pieceCX + pieceC.depth / 2, 0, SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.N);
                    }
                }

                // East Edge
                if (this.hasD) {
                    profileSpot(topLength, (pieceC.length + pieceD.depth) / 2, [SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieces.D], SolidSurfaceConfigPieceProfiles.E);
                } else if (this.hasC) {
                    profileSpot(topLength, pieceC.length / 2, SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.E);
                } else if (this.hasB) {
                    profileSpot(topLength, pieceB.depth / 2, SolidSurfaceConfigPieces.B, SolidSurfaceConfigPieceBProfiles.E);
                }

                // South Edge
                if (this.worktopConfig.layout !== SolidSurfaceConfigLayout.ISLAND) {
                    profileSpot(pieceA.depth / 2, pieceA.length, SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.S);
                }

                if (this.hasD) {
                    profileSpot(pieceDX + pieceD.length / 2, rightLength, SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDProfiles.S);
                } else if (this.hasC) {
                    profileSpot(pieceCX + pieceC.depth / 2, rightLength, SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.S);
                }

                // West Edge
                if (this.worktopConfig.layout !== SolidSurfaceConfigLayout.ISLAND) {
                    profileSpot(0, pieceA.length / 2, SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.W);
                }

                if (this.hasD) {
                    profileSpot(pieceDX, rightLength - pieceD.depth / 2, SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDProfiles.W);
                }

                // Extra Edges
                if (this.hasE) {
                    profileSpot(pieceE.length, pieceEY + pieceE.depth / 2, SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.E);
                    profileSpot(pieceE.length / 2, pieceEY + pieceE.depth, SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.S);
                    profileSpot(0, pieceEY + pieceE.depth / 2, SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.W);
                }

                ctx.restore();
            }

            // Render Upstand Edge Markers
            if (this.worktopConfig.tab === SolidSurfaceConfigTabs.SHAPINGS && this.sub3 === SolidSurfaceConfigProfiles.UPSTAND) {
                const upstandSpot = (x, y, configPiece: SolidSurfaceConfigPieces | SolidSurfaceConfigPieces[], edge: ISolidSurfaceConfigPieceProfiles) => {
                    const radius = 10 / scale;
                    const pieces: SolidSurfaceConfigPieces[] = (configPiece instanceof Array) ? configPiece : [configPiece];

                    renderTickbox(x, y, radius, this.worktopConfig.pieces[pieces[0]].upstands[edge], '#00C');
                    this.clickables.push({
                        x: x - radius,
                        y: y - radius,
                        width: radius * 2,
                        depth: radius * 2,
                        fn: () => {
                            pieces.forEach((piece) => {
                                this.worktopConfig.pieces[piece].profiles[edge] = false;
                                this.worktopConfig.pieces[piece].downturns[edge] = false;
                                this.worktopConfig.pieces[piece].upstands[edge] = !this.worktopConfig.pieces[piece].upstands[edge];
                                if (this.worktopConfig.pieces[piece].upstands[edge]) {
                                    clearCorners(piece, edge);
                                }
                            });

                            this.update('upstand edge markers');
                        }
                    });
                };

                ctx.save();
                ctx.fillStyle = '#EFEFEF';
                ctx.lineWidth = 3 * ctx.lineWidth;

                // North Edge
                if (this.hasB) {
                    let profiledEdgeNorth = [SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieces.B];
                    if (this.hasC) {
                        profiledEdgeNorth.push(SolidSurfaceConfigPieces.C);
                    }

                    upstandSpot(topLength / 2, 0, profiledEdgeNorth, SolidSurfaceConfigPieceProfiles.N);
                } else {
                    if (this.hasA) {
                        upstandSpot(pieceA.depth / 2, 0, SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.N);
                    }

                    if (this.hasC) {
                        upstandSpot(pieceCX + pieceC.depth / 2, 0, SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.N);
                    }
                }

                // East Edge
                if (this.hasD) {
                    upstandSpot(topLength, (pieceC.length + pieceD.depth) / 2, [SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieces.D], SolidSurfaceConfigPieceProfiles.E);
                } else if (this.hasC) {
                    upstandSpot(topLength, pieceC.length / 2, SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.E);
                } else if (this.hasB) {
                    upstandSpot(topLength, pieceB.depth / 2, SolidSurfaceConfigPieces.B, SolidSurfaceConfigPieceBProfiles.E);
                }

                // South Edge
                if (this.worktopConfig.layout !== SolidSurfaceConfigLayout.ISLAND) {
                    upstandSpot(pieceA.depth / 2, pieceA.length, SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.S);
                }

                if (this.hasD) {
                    upstandSpot(pieceDX + pieceD.length / 2, rightLength, SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDProfiles.S);
                } else if (this.hasC) {
                    upstandSpot(pieceCX + pieceC.depth / 2, rightLength, SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.S);
                }

                // West Edge
                if (this.worktopConfig.layout !== SolidSurfaceConfigLayout.ISLAND) {
                    upstandSpot(0, pieceA.length / 2, SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.W);
                }

                if (this.hasD) {
                    upstandSpot(pieceDX, rightLength - pieceD.depth / 2, SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDProfiles.W);
                }

                // Extra Edges
                if (this.hasE) {
                    upstandSpot(pieceE.length, pieceEY + pieceE.depth / 2, SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.E);
                    upstandSpot(pieceE.length / 2, pieceEY + pieceE.depth, SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.S);
                    upstandSpot(0, pieceEY + pieceE.depth / 2, SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.W);
                }

                ctx.restore();
            }

            // Render Downturn Edge Markers
            if (this.worktopConfig.tab === SolidSurfaceConfigTabs.SHAPINGS && this.sub3 === SolidSurfaceConfigProfiles.DOWNTURN) {
                const downturnSpot = (x: number, y: number, configPiece: SolidSurfaceConfigPieces | SolidSurfaceConfigPieces[], edge: ISolidSurfaceConfigPieceProfiles) => {
                    const radius = 10 / scale;
                    const pieces: SolidSurfaceConfigPieces[] = (configPiece instanceof Array) ? configPiece : [configPiece];

                    renderTickbox(x, y, radius, this.worktopConfig.pieces[pieces[0]].downturns[edge], '#C00');
                    this.clickables.push({
                        x: x - radius,
                        y: y - radius,
                        width: radius * 2,
                        depth: radius * 2,
                        fn: () => {
                            pieces.forEach((piece) => {
                                this.worktopConfig.pieces[piece].profiles[edge] = false;
                                this.worktopConfig.pieces[piece].upstands[edge] = false;
                                this.worktopConfig.pieces[piece].downturns[edge] = !this.worktopConfig.pieces[piece].downturns[edge];
                                if (this.worktopConfig.pieces[piece].downturns[edge]) {
                                    clearCorners(piece, edge);
                                }
                            });

                            this.update('downturn edge markers');
                        }
                    });
                };

                ctx.save();
                ctx.fillStyle = '#EFEFEF';
                ctx.lineWidth = 3 * ctx.lineWidth;

                // North Edge
                // if (this.hasB) {
                //     let profiledEdgeNorth = [SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieces.B];
                //     if (this.hasC) {
                //         profiledEdgeNorth.push(SolidSurfaceConfigPieces.C);
                //     }

                //     downturnSpot(topLength / 2, 0, profiledEdgeNorth, SolidSurfaceConfigPieceProfiles.N);
                // } else {
                //     if (this.hasA) {
                //         downturnSpot(pieceA.depth / 2, 0, SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.N);
                //     }

                //     if (this.hasC) {
                //         downturnSpot(pieceCX + pieceC.depth / 2, 0, SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.N);
                //     }
                // }

                // East Edge
                // if (this.hasD) {
                //     downturnSpot(topLength, (pieceC.length + pieceD.depth) / 2, [SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieces.D], SolidSurfaceConfigPieceProfiles.E);
                // } else if (this.hasC) {
                //     downturnSpot(topLength, pieceC.length / 2, SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.E);
                // } else if (this.hasB) {
                //     downturnSpot(topLength, pieceB.depth / 2, SolidSurfaceConfigPieces.B, SolidSurfaceConfigPieceBProfiles.E);
                // }

                // South Edge
                if (this.worktopConfig.layout !== SolidSurfaceConfigLayout.ISLAND) {
                    downturnSpot(pieceA.depth / 2, pieceA.length, SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.S);
                }

                if (this.hasD) {
                    downturnSpot(pieceDX + pieceD.length / 2, rightLength, SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDProfiles.S);
                } else if (this.hasC) {
                    downturnSpot(pieceCX + pieceC.depth / 2, rightLength, SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.S);
                }

                // West Edge
                // if (this.worktopConfig.layout !== SolidSurfaceConfigLayout.ISLAND) {
                //     downturnSpot(0, pieceA.length / 2, SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.W);
                // }

                if (this.hasD) {
                    downturnSpot(pieceDX, rightLength - pieceD.depth / 2, SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDProfiles.W);
                }

                // Extra Edges
                if (this.hasE) {
                    downturnSpot(pieceE.length, pieceEY + pieceE.depth / 2, SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.E);
                    downturnSpot(pieceE.length / 2, pieceEY + pieceE.depth, SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.S);
                    downturnSpot(0, pieceEY + pieceE.depth / 2, SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.W);
                }

                // Island Edges
                if (this.hasI) {
                    downturnSpot(pieceIX, pieceIY + pieceI.depth / 2, SolidSurfaceConfigPieces.I, SolidSurfaceConfigPieceIProfiles.W);
                    downturnSpot(pieceIX + pieceI.length / 2, pieceIY + pieceI.depth, SolidSurfaceConfigPieces.I, SolidSurfaceConfigPieceIProfiles.S);
                    downturnSpot(pieceIX + pieceI.length, pieceIY + pieceI.depth / 2, SolidSurfaceConfigPieces.I, SolidSurfaceConfigPieceIProfiles.E);
                }

                ctx.restore();
            }

            // Render Corner Markers
            if (this.worktopConfig.tab === SolidSurfaceConfigTabs.SHAPINGS && this.sub3 === SolidSurfaceConfigProfiles.CORNER) {
                const cornerOffset = Math.min(100, 12 / scale); // 12 / scale; // corner offset
                const cornerSpot = (
                    x: number,
                    y: number,
                    piece: SolidSurfaceConfigPieces,
                    corner: ISolidSurfaceConfigPieceCorners,
                    isInternal: boolean = false
                ) => {
                    const radius = cornerOffset;
                    renderConfig(x, y, radius);
                    this.clickables.push({
                        x: x - radius,
                        y: y - radius,
                        width: radius * 2,
                        depth: radius * 2,
                        fn: () => {
                            this.dialogService.custom(SolidSurfaceConfigCornerOptionsDialogComponent, {
                                panelClass: 'solid-surface-config-corner-options-dialog',
                                data: {
                                    isInternal: isInternal,
                                    option: this.worktopConfig.pieces[piece].corners[corner],
                                    cornerOpts: this.cornerOpts
                                }
                            })
                                .then((response) => {
                                    if (response) {
                                        this.worktopConfig.pieces[piece].corners[corner] = response.option;
                                        this.update('corner markers');
                                    }
                                })
                                .catch((error) => this.dialogService.error(this.constructor.name, error));
                        }
                    });
                };

                const hasDownturn = (piece: SolidSurfaceConfigPieces, edge: ISolidSurfaceConfigPieceProfiles) => {
                    return this.worktopConfig.pieces[piece].downturns[edge];
                };

                const profiled = (piece: SolidSurfaceConfigPieces, edge: ISolidSurfaceConfigPieceProfiles) => {
                    return this.worktopConfig.pieces[piece].profiles[edge] && !this.worktopConfig.pieces[piece].downturns[edge];
                };

                ctx.save();
                ctx.fillStyle = '#EFEFEF';
                ctx.lineWidth = 3 * ctx.lineWidth;

                // Piece A - Corners
                if (this.hasA) {
                    if (profiled(SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.N)) {
                        if (!this.hasB) {
                            cornerSpot(pieceA.depth + cornerOffset, -cornerOffset, SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceACorner.NE);
                        }

                        // if (profiled(SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.W)) {
                        //     cornerSpot(-cornerOffset, -cornerOffset, SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceACorner.NW);
                        // }
                    }

                    if (profiled(SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.S)) {
                        cornerSpot(pieceA.depth + cornerOffset, pieceA.length + cornerOffset, SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceACorner.SE);

                        // if (profiled(SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceAProfiles.W)) {
                        //     cornerSpot(-cornerOffset, pieceA.length + cornerOffset, SolidSurfaceConfigPieces.A, SolidSurfaceConfigPieceACorner.SW);
                        // }
                    }
                }

                // Piece B - Corners
                if (this.hasB) {
                    cornerSpot(pieceA.depth - cornerOffset, pieceB.depth - cornerOffset, SolidSurfaceConfigPieces.B, SolidSurfaceConfigPieceBCorner.SW, true);

                    if (!this.hasC) {
                        if (profiled(SolidSurfaceConfigPieces.B, SolidSurfaceConfigPieceBProfiles.E)) {
                            cornerSpot(topLength + cornerOffset, pieceB.depth + cornerOffset, SolidSurfaceConfigPieces.B, SolidSurfaceConfigPieceBCorner.SE);

                            // if (profiled(SolidSurfaceConfigPieces.B, SolidSurfaceConfigPieceBProfiles.N)) {
                            //     cornerSpot(topLength + cornerOffset, -cornerOffset, SolidSurfaceConfigPieces.B, SolidSurfaceConfigPieceBCorner.NE);
                            // }
                        }
                    }
                }

                // Piece C - Corners
                if (this.hasC) {
                    if (this.hasB) {
                        cornerSpot(pieceCX + cornerOffset, pieceB.depth - cornerOffset, SolidSurfaceConfigPieces.B, SolidSurfaceConfigPieceBCorner.SE, true);
                    }

                    if (profiled(SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.N)) {
                        if (!this.hasB) {
                            cornerSpot(pieceCX - cornerOffset, -cornerOffset, SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCCorner.NW);
                        }

                        // if (profiled(SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.E)) {
                        //     cornerSpot(topLength + cornerOffset, -cornerOffset, SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCCorner.NE);
                        // }
                    }

                    if (!this.hasD) {
                        if (profiled(SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.S)) {
                            cornerSpot(pieceCX - cornerOffset, pieceC.length + cornerOffset, SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCCorner.SW);

                            if (profiled(SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCProfiles.E)) {
                                cornerSpot(topLength + cornerOffset, pieceC.length + cornerOffset, SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCCorner.SE);
                            }
                        }
                    } else {
                        cornerSpot(pieceCX + cornerOffset, pieceC.length + cornerOffset, SolidSurfaceConfigPieces.C, SolidSurfaceConfigPieceCCorner.SW, true);
                    }
                }

                // Piece D - Corners
                if (this.hasD) {
                    if (profiled(SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDProfiles.W)) {
                        cornerSpot(pieceDX - cornerOffset, pieceDY - cornerOffset, SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDCorner.NW);
                    }

                    if (profiled(SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDProfiles.S)) {
                        // if (profiled(SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDProfiles.E)) {
                        //     cornerSpot(pieceDX + pieceD.length + cornerOffset, pieceDY + pieceD.depth + cornerOffset, SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDCorner.SE);
                        // }

                        if (profiled(SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDProfiles.W)) {
                            cornerSpot(pieceDX - cornerOffset, pieceDY + pieceD.depth + cornerOffset, SolidSurfaceConfigPieces.D, SolidSurfaceConfigPieceDCorner.SW);
                        }
                    }
                }

                // Piece E - Corners
                if (this.hasE) {
                    if (profiled(SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.E)) {
                        cornerSpot(pieceE.length + cornerOffset, pieceEY - cornerOffset, SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceECorner.NE);
                    }

                    if (profiled(SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.W)) {
                        cornerSpot(-cornerOffset, pieceEY - cornerOffset, SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceECorner.NW);
                    }

                    if (profiled(SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.S)) {
                        if (profiled(SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.E)) {
                            cornerSpot(pieceE.length + cornerOffset, pieceEY + pieceE.depth + cornerOffset, SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceECorner.SE);
                        }

                        if (profiled(SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceEProfiles.W)) {
                            cornerSpot(-cornerOffset, pieceEY + pieceE.depth + cornerOffset, SolidSurfaceConfigPieces.E, SolidSurfaceConfigPieceECorner.SW);
                        }
                    }
                }

                // Piece I - Corners
                if (this.hasI) {
                    if (!hasDownturn(SolidSurfaceConfigPieces.I, SolidSurfaceConfigPieceIProfiles.E)) {
                        cornerSpot(pieceIX + pieceI.length + cornerOffset, pieceIY - cornerOffset, SolidSurfaceConfigPieces.I, SolidSurfaceConfigPieceICorner.NE);

                        if (!hasDownturn(SolidSurfaceConfigPieces.I, SolidSurfaceConfigPieceIProfiles.S)) {
                            cornerSpot(pieceIX + pieceI.length + cornerOffset, pieceIY + pieceI.depth + cornerOffset, SolidSurfaceConfigPieces.I, SolidSurfaceConfigPieceICorner.SE);
                        }
                    }

                    if (!hasDownturn(SolidSurfaceConfigPieces.I, SolidSurfaceConfigPieceIProfiles.W)) {
                        cornerSpot(pieceIX - cornerOffset, pieceIY - cornerOffset, SolidSurfaceConfigPieces.I, SolidSurfaceConfigPieceICorner.NW);

                        if (!hasDownturn(SolidSurfaceConfigPieces.I, SolidSurfaceConfigPieceIProfiles.S)) {
                            cornerSpot(pieceIX - cornerOffset, pieceIY + pieceI.depth + cornerOffset, SolidSurfaceConfigPieces.I, SolidSurfaceConfigPieceICorner.SW);
                        }
                    }
                }

                ctx.restore();
            }

            // Render Cutouts Markers
            if (this.worktopConfig.tab === SolidSurfaceConfigTabs.APPLIANCES) {
                const cutoutSpot = (x: number, y: number, w: number, d: number, piece: SolidSurfaceConfigPieces) => {
                    ctx.save();
                    ctx.strokeStyle = '#00E676';
                    ctx.lineWidth = 2.2 / scale;
                    ctx.translate(x + w / 2, y + d / 2);
                    ctx.beginPath();
                    ctx.arc(0, 0, 9 / scale, 0, Math.PI * 2);
                    this.moveTo(ctx, 0 - 4 / scale, 0);
                    this.lineTo(ctx, 0 + 4 / scale, 0);
                    this.moveTo(ctx, 0, 0 - 4 / scale);
                    this.lineTo(ctx, 0, 0 + 4 / scale);
                    ctx.stroke();
                    ctx.restore();
                    this.clickables.push({
                        x: x,
                        y: y,
                        width: w,
                        depth: d,
                        fn: () => this.addCutout(piece)
                    });
                };

                if (this.hasA) {
                    cutoutSpot(0, 0, pieceA.depth, pieceA.length, SolidSurfaceConfigPieces.A);
                }

                if (this.hasB) {
                    cutoutSpot(pieceBX, 0, pieceB.length, pieceB.depth, SolidSurfaceConfigPieces.B);
                }

                if (this.hasC) {
                    cutoutSpot(pieceCX, 0, pieceC.depth, pieceC.length, SolidSurfaceConfigPieces.C);
                }

                if (this.hasD) {
                    cutoutSpot(pieceDX, pieceDY, pieceD.length, pieceD.depth, SolidSurfaceConfigPieces.D);
                }

                if (this.hasI) {
                    cutoutSpot(pieceIX, pieceIY, pieceI.length, pieceI.depth, SolidSurfaceConfigPieces.I);
                }

                if (this.hasE) {
                    cutoutSpot(0, pieceEY, pieceE.length, pieceE.depth, SolidSurfaceConfigPieces.E);
                }

                ctx.restore();
            }

            // Render Splashbacks and Cills Markers
            if (this.worktopConfig.tab === SolidSurfaceConfigTabs.SPLASHBACKS) {
                const splashbackSpot = (x: number, y: number, w: number, d: number, piece: SolidSurfaceConfigPieces) => {
                    ctx.save();
                    ctx.strokeStyle = '#00E676';
                    ctx.lineWidth = 2.2 / scale;
                    ctx.translate(x + w / 2, y + d / 2);
                    ctx.beginPath();
                    ctx.arc(0, 0, 9 / scale, 0, Math.PI * 2);
                    this.moveTo(ctx, 0 - 4 / scale, 0);
                    this.lineTo(ctx, 0 + 4 / scale, 0);
                    this.moveTo(ctx, 0, 0 - 4 / scale);
                    this.lineTo(ctx, 0, 0 + 4 / scale);
                    ctx.stroke();
                    ctx.restore();
                    this.clickables.push({
                        x: x,
                        y: y,
                        width: w,
                        depth: d,
                        fn: () => this.addSplashbackCill(piece)
                    });
                };

                if (this.hasA) {
                    splashbackSpot(0, 0, pieceA.depth, pieceA.length, SolidSurfaceConfigPieces.A);
                }

                if (this.hasB) {
                    splashbackSpot(pieceBX, 0, pieceB.length, pieceB.depth, SolidSurfaceConfigPieces.B);
                }

                if (this.hasC) {
                    splashbackSpot(pieceCX, 0, pieceC.depth, pieceC.length, SolidSurfaceConfigPieces.C);
                }

                if (this.hasD) {
                    splashbackSpot(pieceDX, pieceDY, pieceD.length, pieceD.depth, SolidSurfaceConfigPieces.D);
                }

                if (this.hasI) {
                    splashbackSpot(pieceIX, pieceIY, pieceI.length, pieceI.depth, SolidSurfaceConfigPieces.I);
                }

                if (this.hasE) {
                    splashbackSpot(0, pieceEY, pieceE.length, pieceE.depth, SolidSurfaceConfigPieces.E);
                }

                ctx.restore();
            }

            let awaitRestore = 0;
            let awaitRestorePending = true;
            if (this.worktopConfig.tab === SolidSurfaceConfigTabs.SUMMARY) {
                const imageSpot = (
                    x: number, y: number, w: number, d: number,
                    orientation: SolidSurfaceConfigOrientation,
                    images: string[]
                ) => {
                    if (images.length) {
                        let left = 0;
                        let right = 0;
                        let showImage = true;

                        images.forEach((image) => {
                            if (image && this.renderImages[image]) {
                                awaitRestore++;

                                const img = new Image();
                                img.onload = () => {
                                    awaitRestore--;
                                    const imgWidth = img.width / scale;
                                    const imgHeight = img.height / scale;

                                    ctx.save();

                                    if (orientation === SolidSurfaceConfigOrientation.HORIZONTAL) {
                                        if (left === 0) {
                                            ctx.translate(
                                                x + (w / 2) + (imgHeight / 2),
                                                y + (d / 2) - (imgWidth / 2)
                                            );

                                            left = (imgHeight / 2);
                                            right = (imgHeight / 2);
                                        } else {
                                            if (left === right || left < right) {
                                                if (((w / 2) - left) > imgHeight) {
                                                    ctx.translate(
                                                        x + (w / 2) - left,
                                                        y + (d / 2) - (imgWidth / 2)
                                                    );

                                                    left += imgHeight;
                                                } else {
                                                    showImage = false;
                                                }
                                            } else {
                                                if (((w / 2) - right) > imgHeight) {
                                                    right += imgHeight;
                                                    ctx.translate(
                                                        x + (w / 2) + right,
                                                        y + (d / 2) - (imgWidth / 2)
                                                    );
                                                } else {
                                                    showImage = false;
                                                }
                                            }
                                        }

                                        ctx.rotate(Math.PI / 2);
                                    } else {
                                        if (left === 0) {
                                            ctx.translate(
                                                x + (w / 2) - (imgWidth / 2),
                                                y + (d / 2) - (imgHeight / 2)
                                            );
                                            left = (imgHeight / 2);
                                            right = (imgHeight / 2);
                                        } else {
                                            if (left === right || left < right) {
                                                if (((d / 2) - left) > imgHeight) {
                                                    left += imgHeight;

                                                    ctx.translate(
                                                        x + (w / 2) - (imgWidth / 2),
                                                        y + (d / 2) - left
                                                    );
                                                } else {
                                                    showImage = false;
                                                }
                                            } else {
                                                if (((d / 2) - right) > imgHeight) {
                                                    ctx.translate(
                                                        x + (w / 2) - (imgWidth / 2),
                                                        y + (d / 2) + right
                                                    );

                                                    right += imgHeight;
                                                } else {
                                                    showImage = false;
                                                }
                                            }
                                        }
                                    }

                                    if (showImage) {
                                        ctx.drawImage(img, 0, 0, imgWidth, imgHeight);
                                    }

                                    if (orientation === SolidSurfaceConfigOrientation.HORIZONTAL) {
                                        ctx.rotate(-Math.PI / 2);
                                    }

                                    ctx.restore();

                                    if (!awaitRestorePending && !awaitRestore) {
                                        ctx.restore();
                                    }
                                };
                                img.src = this.renderImages[image];
                            }
                        });
                    }
                };

                if (this.hasA) {
                    const images = this.pieceImages(SolidSurfaceConfigPieces.A);
                    if (images && images.length) {
                        // imageSpot(0, 0, pieceA.depth, pieceA.length, SolidSurfaceConfigOrientation.VERTICAL, images);
                    }
                }

                if (this.hasB) {
                    const images = this.pieceImages(SolidSurfaceConfigPieces.B);
                    if (images && images.length) {
                        // imageSpot(pieceBX, 0, pieceB.length, pieceB.depth, SolidSurfaceConfigOrientation.HORIZONTAL, images);
                    }
                }

                if (this.hasC) {
                    const images = this.pieceImages(SolidSurfaceConfigPieces.C);
                    if (images && images.length) {
                        // imageSpot(pieceCX, 0, pieceC.depth, pieceC.length, SolidSurfaceConfigOrientation.VERTICAL, images);
                    }
                }

                if (this.hasD) {
                    const images = this.pieceImages(SolidSurfaceConfigPieces.D);
                    if (images && images.length) {
                        // imageSpot(pieceDX, pieceDY, pieceD.length, pieceD.depth, SolidSurfaceConfigOrientation.HORIZONTAL, images);
                    }
                }

                if (this.hasI) {
                    const images = this.pieceImages(SolidSurfaceConfigPieces.I);
                    if (images && images.length) {
                        imageSpot(pieceIX, pieceIY, pieceI.length, pieceI.depth, SolidSurfaceConfigOrientation.HORIZONTAL, images);
                    }
                }

                if (this.hasE) {
                    const images = this.pieceImages(SolidSurfaceConfigPieces.E);
                    if (images && images.length) {
                        // imageSpot(0, pieceEY, pieceE.length, pieceE.depth, SolidSurfaceConfigOrientation.HORIZONTAL, images);
                    }
                }
            }

            if (!awaitRestore) {
                ctx.restore();
            } else {
                awaitRestorePending = false;
            }
        }
    }

    public addCutout(piece: SolidSurfaceConfigPieces) {
        this.dialogService.custom(SolidSurfaceConfigCutoutOptionsDialogComponent, {
            panelClass: 'solid-surface-config-cutout-options-dialog',
            data: {
                graniteOptions: this.graniteOptions,
                piece: this.worktopConfig.pieces[piece]
            }
        })
            .then((response) => {
                if (response) {
                    if (response.options) {
                        if (!this.worktopConfig.pieces[piece].cutouts) {
                            this.worktopConfig.pieces[piece].cutouts = [];
                        }

                        response.options.forEach((option) => {
                            this.worktopConfig.pieces[piece].cutouts.push(option);
                        });
                    }

                    if (response.gap) {
                        if (!this.worktopConfig.pieces[piece].gaps) {
                            this.worktopConfig.pieces[piece].gaps = [];
                        }

                        const gapLength = this.worktopConfig.pieces[piece].gaps.reduce((gap, a) => gap + a, 0);
                        if ((this.worktopConfig.pieces[piece].length - gapLength) <= response.gap) {
                            this.dialogService.notice(
                                `${response.gap}mm Range Cooker`,
                                'There is not enough worktop length to add this item',
                                true
                            );
                        } else {
                            this.worktopConfig.pieces[piece].gaps.push(response.gap);
                        }
                    }

                    this.update('add cutout');
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public removeCutout(piece: SolidSurfaceConfigPieces, option) {
        this.worktopConfig.pieces[piece].cutouts = this.worktopConfig.pieces[piece].cutouts.filter((opt) => opt.code !== option.code);
        this.update('remove cutout');
    }

    public removeGap(piece: SolidSurfaceConfigPieces, gap) {
        for (let i = this.worktopConfig.pieces[piece].length; i--;) {
            if (this.worktopConfig.pieces[piece].gaps[i] === gap) {
                this.worktopConfig.pieces[piece].gaps.splice(i, 1);
            }
        }

        this.update('remove gap');
    }

    public hasSplashbackCill(piece: SolidSurfaceConfigPieces) {
        return !!(
            this.worktopConfig.pieces[piece].splashback.length ||
            this.worktopConfig.pieces[piece].splashback.height ||
            this.worktopConfig.pieces[piece].cill.length ||
            this.worktopConfig.pieces[piece].cill.height
        );
    }

    public addSplashbackCill(piece: SolidSurfaceConfigPieces) {
        this.dialogService.custom(SolidSurfaceConfigSplashbackCillDialogComponent, {
            panelClass: 'solid-surface-config-splashback-cill-dialog',
            data: {
                piece: this.worktopConfig.pieces[piece]
            }
        })
            .then((response) => {
                if (response) {
                    this.update('add splashback / cill');
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public removeSplashback(piece: SolidSurfaceConfigPieces) {
        this.worktopConfig.pieces[piece].splashback.length = null;
        this.worktopConfig.pieces[piece].splashback.height = null;
    }

    public removeCill(piece: SolidSurfaceConfigPieces) {
        this.worktopConfig.pieces[piece].cill.length = null;
        this.worktopConfig.pieces[piece].cill.height = null;
    }

    public gotoTab(event) {
        const tabNum = event.selectedIndex + 1;
        if (tabNum !== this.worktopConfig.tab) {
            if (tabNum > this.worktopConfig.maxTab) {
                this.worktopConfig.maxTab = tabNum;
            }

            this.worktopConfig.tab = tabNum;
            this.sub3 = SolidSurfaceConfigProfiles.PROFILED;

            this.update('goto tab');
        }
    }

    public toggleIsland() {
        this.worktopConfig.addIsland = !this.worktopConfig.addIsland;
        this.update('toggle island');
    }

    public toggleExtra() {
        this.worktopConfig.addExtra = !this.worktopConfig.addExtra;
        this.update('toggle extra');
    }

    public t3sub(subNum: SolidSurfaceConfigProfiles) {
        if (this.sub3 === subNum) {
            this.sub3 = null;
        } else {
            this.sub3 = subNum;
        }
        this.update('t3 sub');
    }

    public cutoutTitle(opt) {
        switch (opt.type) {
            case 'hobs':
                return opt.product_name + ' Hob Cut Out';
            case 'sinks':
                return opt.product_name + ' Sink Cut Out';
            default:
                return opt.product_name;
        }
    }

    public setProfileOption() {
        this.dialogService.custom(SolidSurfaceConfigProfileOptionsDialogComponent, {
            panelClass: 'solid-surface-config-profile-options-dialog',
            data: {
                options: this.profileOptions,
                option: this.worktopConfig.profileOption
            }
        })
            .then((response) => {
                if (response) {
                    this.worktopConfig.profileOption = response.option;
                    this.save();
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public toggleConfig(config, value) {
        config.toggle = value;
    }

    public isValidDims() {
        this.worktopConfig.pieces[SolidSurfaceConfigPieces.A].length = Math.floor(this.worktopConfig.pieces[SolidSurfaceConfigPieces.A].length) || 0;
        this.worktopConfig.pieces[SolidSurfaceConfigPieces.A].depth = Math.floor(this.worktopConfig.pieces[SolidSurfaceConfigPieces.A].depth) || 0;
        this.worktopConfig.pieces[SolidSurfaceConfigPieces.B].length = Math.floor(this.worktopConfig.pieces[SolidSurfaceConfigPieces.B].length) || 0;
        this.worktopConfig.pieces[SolidSurfaceConfigPieces.B].depth = Math.floor(this.worktopConfig.pieces[SolidSurfaceConfigPieces.B].depth) || 0;
        this.worktopConfig.pieces[SolidSurfaceConfigPieces.C].length = Math.floor(this.worktopConfig.pieces[SolidSurfaceConfigPieces.C].length) || 0;
        this.worktopConfig.pieces[SolidSurfaceConfigPieces.C].depth = Math.floor(this.worktopConfig.pieces[SolidSurfaceConfigPieces.C].depth) || 0;
        this.worktopConfig.pieces[SolidSurfaceConfigPieces.D].length = Math.floor(this.worktopConfig.pieces[SolidSurfaceConfigPieces.D].length) || 0;
        this.worktopConfig.pieces[SolidSurfaceConfigPieces.D].depth = Math.floor(this.worktopConfig.pieces[SolidSurfaceConfigPieces.D].depth) || 0;
        this.worktopConfig.pieces[SolidSurfaceConfigPieces.I].length = Math.floor(this.worktopConfig.pieces[SolidSurfaceConfigPieces.I].length) || 0;
        this.worktopConfig.pieces[SolidSurfaceConfigPieces.I].depth = Math.floor(this.worktopConfig.pieces[SolidSurfaceConfigPieces.I].depth) || 0;
        this.worktopConfig.pieces[SolidSurfaceConfigPieces.E].length = Math.floor(this.worktopConfig.pieces[SolidSurfaceConfigPieces.E].length) || 0;
        this.worktopConfig.pieces[SolidSurfaceConfigPieces.E].depth = Math.floor(this.worktopConfig.pieces[SolidSurfaceConfigPieces.E].depth) || 0;

        this.validDims = true;

        if (this.hasA) {
            if (this.worktopConfig.pieces[SolidSurfaceConfigPieces.A].length < 350) {
                this.validDims = false;
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.A].isValidLength = false;
            } else {
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.A].isValidLength = true;
            }

            if (this.worktopConfig.pieces[SolidSurfaceConfigPieces.A].depth < 350) {
                this.validDims = false;
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.A].isValidDepth = false;
            } else {
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.A].isValidDepth = true;
            }
        }

        if (this.hasB) {
            if (this.worktopConfig.pieces[SolidSurfaceConfigPieces.B].length < 350) {
                this.validDims = false;
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.B].isValidLength = false;
            } else {
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.B].isValidLength = true;
            }

            if (this.worktopConfig.pieces[SolidSurfaceConfigPieces.B].depth < 350) {
                this.validDims = false;
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.B].isValidDepth = false;
            } else {
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.B].isValidDepth = true;
            }
        }

        if (this.hasC) {
            if (this.worktopConfig.pieces[SolidSurfaceConfigPieces.C].length < 350) {
                this.validDims = false;
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.C].isValidLength = false;
            } else {
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.C].isValidLength = true;
            }

            if (this.worktopConfig.pieces[SolidSurfaceConfigPieces.C].depth < 350) {
                this.validDims = false;
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.C].isValidDepth = false;
            } else {
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.C].isValidDepth = true;
            }
        }

        if (this.hasD) {
            if (this.worktopConfig.pieces[SolidSurfaceConfigPieces.D].length < 350) {
                this.validDims = false;
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.D].isValidLength = false;
            } else {
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.D].isValidLength = true;
            }

            if (this.worktopConfig.pieces[SolidSurfaceConfigPieces.D].depth < 350) {
                this.validDims = false;
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.D].isValidDepth = false;
            } else {
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.D].isValidDepth = true;
            }
        }

        if (this.hasI) {
            if (this.worktopConfig.pieces[SolidSurfaceConfigPieces.I].length < 350) {
                this.validDims = false;
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.I].isValidLength = false;
            } else {
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.I].isValidLength = true;
            }

            if (this.worktopConfig.pieces[SolidSurfaceConfigPieces.I].depth < 350) {
                this.validDims = false;
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.I].isValidDepth = false;
            } else {
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.I].isValidDepth = true;
            }
        }

        if (this.hasE) {
            if (this.worktopConfig.pieces[SolidSurfaceConfigPieces.E].length < 350) {
                this.validDims = false;
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.E].isValidLength = false;
            } else {
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.E].isValidLength = true;
            }

            if (this.worktopConfig.pieces[SolidSurfaceConfigPieces.E].depth < 350) {
                this.validDims = false;
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.E].isValidDepth = false;
            } else {
                this.worktopConfig.pieces[SolidSurfaceConfigPieces.E].isValidDepth = true;
            }
        }

        this.dimensionsFormGroup.controls.dimensionsControl.setValue(this.validDims);
    }

    public viewQuote() {
        this.showWorktopSummary = !this.showWorktopSummary;
    }

    public lengthWithGap(piece: SolidSurfaceConfigPieces) {
        if (this.worktopConfig && this.worktopConfig.pieces[piece]) {
            return this.worktopConfig.pieces[piece].length - this.worktopConfig.pieces[piece].gaps.reduce((gap, a) => gap + a, 0);
        }

        return 0;
    }

    private pieceImages(piece: SolidSurfaceConfigPieces): string[] {
        let images: string[] = [];

        if (this.worktopConfig.pieces[piece].cutouts.length) {
            this.worktopConfig.pieces[piece].cutouts.forEach((cutout) => {
                if (this.renderImages[cutout.product_ref]) {
                    images.push(cutout.product_ref);
                }
            });
        }

        if (this.worktopConfig.pieces[piece].gaps.length) {
            this.worktopConfig.pieces[piece].gaps.forEach(() => {
                images.push('range-cooker');
            });
        }

        return images;
    }

    private priceForWorktop(worktop) {
        const installCost = 650;
        const downturnHeight = 900;
        const upstandHeight = this.worktopConfig.upstandHeight;
        const profilesPrice = parseFloat(this.worktopConfig.profileOption.price);

        const pieceA = this.worktopConfig.pieces[SolidSurfaceConfigPieces.A];
        const pieceB = this.worktopConfig.pieces[SolidSurfaceConfigPieces.B];
        const pieceC = this.worktopConfig.pieces[SolidSurfaceConfigPieces.C];
        const pieceD = this.worktopConfig.pieces[SolidSurfaceConfigPieces.D];
        const pieceI = this.worktopConfig.pieces[SolidSurfaceConfigPieces.I];
        const pieceE = this.worktopConfig.pieces[SolidSurfaceConfigPieces.E];

        let total = 0;
        let sqrMtrCost = 0;

        let topLength = 0;
        let leftLength = 0;
        let rightLength = 0;

        let cutoutsCost = 0;
        let profilesCost = 0;
        let upstandsCost = 0;
        let splashbacksCost = 0;
        let cillsCost = 0;
        let downturnsCost = 0;
        let cornersCost = 0;

        const piecePrice = (piece: ISolidSurfaceConfigPieces) => {
            let gapLength = 0;
            if (piece.gaps) {
                piece.gaps.forEach((gap) => {
                    gapLength += Math.floor(gap);
                });
            }

            sqrMtrCost += this.sqrMtrPrice(piece.length - gapLength, piece.depth, worktop.price, true);
            piece.cutouts.forEach((opt) => {
                cutoutsCost += parseFloat(opt.price);
            });
        };

        const profilePrice = (length: number) => {
            if (length && length > 0) {
                return (length / 1000) * profilesPrice;
            }

            return 0;
        };

        const splashbackPrice = (piece: ISolidSurfaceConfigPieces) => {
            if (piece.splashback && piece.splashback.length && piece.splashback.height) {
                return this.sqrMtrPrice(piece.splashback.length, piece.splashback.height, worktop.price);
            }

            return 0;
        };

        const cillPrice = (piece: ISolidSurfaceConfigPieces) => {
            if (piece.cill && piece.cill.length && piece.cill.height) {
                return this.sqrMtrPrice(piece.cill.length, piece.cill.height, worktop.price);
            }

            return 0;
        };

        const cornerPrice = (corner: SolidSurfaceConfigCornerRadius) => {
            switch (corner) {
                case SolidSurfaceConfigCornerRadius.SOFT:
                    return parseFloat(this.cornerOpts.RRR13.price);
                case SolidSurfaceConfigCornerRadius.INTERNAL:
                    return parseFloat(this.cornerOpts.RIC63.price);
                case SolidSurfaceConfigCornerRadius.ANGLED:
                    return parseFloat(this.cornerOpts.RREU3.price);
                case SolidSurfaceConfigCornerRadius.RADIUS:
                    return parseFloat(this.cornerOpts.RRR53.price);
                case SolidSurfaceConfigCornerRadius.CURVED:
                    return parseFloat(this.cornerOpts.RRR63.price);
                default:
                    return 0;
            }
        };

        if (this.hasA) {
            topLength = pieceA.depth;
            leftLength = pieceA.length;
            piecePrice(pieceA);

            // Profile Cost
            if (pieceA.profiles[SolidSurfaceConfigPieceProfiles.N]) {
                profilesCost += profilePrice(pieceA.depth);
            }

            if (pieceA.edges[SolidSurfaceConfigPieceProfiles.E]) {
                if (this.hasB) {
                    profilesCost += profilePrice(this.lengthWithGap(SolidSurfaceConfigPieces.A) - pieceB.depth);
                } else {
                    profilesCost += profilePrice(this.lengthWithGap(SolidSurfaceConfigPieces.A));
                }
            }

            if (pieceA.profiles[SolidSurfaceConfigPieceProfiles.S]) {
                profilesCost += profilePrice(pieceA.depth);
            }

            if (pieceA.profiles[SolidSurfaceConfigPieceProfiles.W]) {
                profilesCost += profilePrice(this.lengthWithGap(SolidSurfaceConfigPieces.A));
            }

            // Upstand Cost
            let upstandLength = 0;
            if (pieceA.upstands[SolidSurfaceConfigPieceProfiles.N]) {
                upstandLength += pieceA.depth;
            }

            if (pieceA.upstands[SolidSurfaceConfigPieceProfiles.E]) {
                if (this.hasB) {
                    upstandLength += (this.lengthWithGap(SolidSurfaceConfigPieces.A) - pieceB.depth);
                } else {
                    upstandLength += this.lengthWithGap(SolidSurfaceConfigPieces.A);
                }
            }

            if (pieceA.upstands[SolidSurfaceConfigPieceProfiles.S]) {
                upstandLength += pieceA.depth;
            }

            if (pieceA.upstands[SolidSurfaceConfigPieceProfiles.W]) {
                upstandLength += this.lengthWithGap(SolidSurfaceConfigPieces.A);
            }

            if (upstandLength > 0) {
                upstandsCost += this.sqrMtrPrice(upstandLength, upstandHeight, worktop.price);
            }

            // Downturn Cost
            let downturnLength = 0;
            if (pieceA.downturns[SolidSurfaceConfigPieceProfiles.N]) {
                downturnLength += pieceA.depth;
            }

            if (pieceA.downturns[SolidSurfaceConfigPieceProfiles.E]) {
                if (this.hasB) {
                    downturnLength += (this.lengthWithGap(SolidSurfaceConfigPieces.A) - pieceB.depth);
                } else {
                    downturnLength += this.lengthWithGap(SolidSurfaceConfigPieces.A);
                }
            }

            if (pieceA.downturns[SolidSurfaceConfigPieceProfiles.S]) {
                downturnLength += pieceA.depth;
            }

            if (pieceA.downturns[SolidSurfaceConfigPieceProfiles.W]) {
                downturnLength += this.lengthWithGap(SolidSurfaceConfigPieces.A);
            }

            if (downturnLength > 0) {
                downturnsCost += this.sqrMtrPrice(downturnLength, downturnHeight, worktop.price);
            }

            // Splashbacks and Cills
            splashbacksCost += splashbackPrice(pieceA);
            cillsCost += cillPrice(pieceA);

            // Corners
            cornersCost += cornerPrice(pieceA.corners[SolidSurfaceConfigPieceCorners.NE]);
            cornersCost += cornerPrice(pieceA.corners[SolidSurfaceConfigPieceCorners.SE]);
            cornersCost += cornerPrice(pieceA.corners[SolidSurfaceConfigPieceCorners.SW]);
            cornersCost += cornerPrice(pieceA.corners[SolidSurfaceConfigPieceCorners.NW]);
        }

        if (this.hasB) {
            topLength += pieceB.length;
            piecePrice(pieceB);

            // Profile Cost
            if (pieceB.profiles[SolidSurfaceConfigPieceProfiles.N]) {
                profilesCost += profilePrice(this.lengthWithGap(SolidSurfaceConfigPieces.B));
            }

            if (pieceB.profiles[SolidSurfaceConfigPieceProfiles.E] && !this.hasC) {
                profilesCost += profilePrice(pieceB.depth);
            }

            if (pieceB.edges[SolidSurfaceConfigPieceProfiles.S]) {
                profilesCost += profilePrice(this.lengthWithGap(SolidSurfaceConfigPieces.B));
            }

            // Upstand Cost
            let upstandLength = 0;
            if (pieceB.upstands[SolidSurfaceConfigPieceProfiles.N]) {
                upstandLength += this.lengthWithGap(SolidSurfaceConfigPieces.B);
            }

            if (pieceB.upstands[SolidSurfaceConfigPieceProfiles.E] && !this.hasC) {
                upstandLength += pieceB.depth;
            }

            if (pieceB.upstands[SolidSurfaceConfigPieceProfiles.N]) {
                upstandLength += this.lengthWithGap(SolidSurfaceConfigPieces.B);
            }

            if (upstandLength > 0) {
                upstandsCost += this.sqrMtrPrice(upstandLength, upstandHeight, worktop.price);
            }

            // Downturn Cost
            let downturnLength = 0;
            if (pieceB.downturns[SolidSurfaceConfigPieceProfiles.N]) {
                downturnLength += this.lengthWithGap(SolidSurfaceConfigPieces.B);
            }

            if (pieceB.downturns[SolidSurfaceConfigPieceProfiles.E] && !this.hasC) {
                downturnLength += pieceB.depth;
            }

            if (pieceB.downturns[SolidSurfaceConfigPieceProfiles.N]) {
                downturnLength += this.lengthWithGap(SolidSurfaceConfigPieces.B);
            }

            if (downturnLength > 0) {
                downturnsCost += this.sqrMtrPrice(downturnLength, downturnHeight, worktop.price);
            }

            // Splashbacks and Cills
            splashbacksCost += splashbackPrice(pieceB);
            cillsCost += cillPrice(pieceB);

            // Corners
            cornersCost += cornerPrice(pieceB.corners[SolidSurfaceConfigPieceCorners.NE]);
            cornersCost += cornerPrice(pieceB.corners[SolidSurfaceConfigPieceCorners.SE]);
            cornersCost += cornerPrice(pieceB.corners[SolidSurfaceConfigPieceCorners.SW]);
            cornersCost += cornerPrice(pieceB.corners[SolidSurfaceConfigPieceCorners.NW]);
        }

        if (this.hasC) {
            rightLength = pieceC.length;
            piecePrice(pieceC);
            profilesCost += (pieceC.edges.w ? profilePrice(this.lengthWithGap(SolidSurfaceConfigPieces.C)) : 0);

            // Profile Cost
            if (pieceC.profiles[SolidSurfaceConfigPieceProfiles.N]) {
                profilesCost += profilePrice(pieceC.depth);
            }

            if (pieceC.profiles[SolidSurfaceConfigPieceProfiles.E]) {
                profilesCost += profilePrice(this.lengthWithGap(SolidSurfaceConfigPieces.C));
            }

            if (pieceC.profiles[SolidSurfaceConfigPieceProfiles.S] && !this.hasD) {
                profilesCost += profilePrice(pieceC.depth);
            }

            if (pieceC.edges[SolidSurfaceConfigPieceProfiles.W]) {
                if (this.hasB) {
                    profilesCost += profilePrice(this.lengthWithGap(SolidSurfaceConfigPieces.C) - pieceB.depth);
                } else {
                    profilesCost += profilePrice(this.lengthWithGap(SolidSurfaceConfigPieces.C));
                }
            }

            // Upstand Cost
            let upstandLength = 0;
            if (pieceC.upstands[SolidSurfaceConfigPieceProfiles.N]) {
                upstandLength += pieceC.depth;
            }

            if (pieceC.upstands[SolidSurfaceConfigPieceProfiles.E]) {
                upstandLength += this.lengthWithGap(SolidSurfaceConfigPieces.C);
            }

            if (pieceC.upstands[SolidSurfaceConfigPieceProfiles.S] && !this.hasD) {
                upstandLength += pieceC.depth;
            }

            if (pieceC.upstands[SolidSurfaceConfigPieceProfiles.W]) {
                if (this.hasB) {
                    upstandLength += (this.lengthWithGap(SolidSurfaceConfigPieces.C) - pieceB.depth);
                } else {
                    upstandLength += this.lengthWithGap(SolidSurfaceConfigPieces.C);
                }
            }

            if (upstandLength > 0) {
                upstandsCost += this.sqrMtrPrice(upstandLength, upstandHeight, worktop.price);
            }

            // Downturn Cost
            let downturnLength = 0;
            if (pieceC.downturns[SolidSurfaceConfigPieceProfiles.N]) {
                downturnLength += pieceC.depth;
            }

            if (pieceC.downturns[SolidSurfaceConfigPieceProfiles.E]) {
                downturnLength += this.lengthWithGap(SolidSurfaceConfigPieces.C);
            }

            if (pieceC.downturns[SolidSurfaceConfigPieceProfiles.S] && !this.hasD) {
                downturnLength += pieceC.depth;
            }

            if (pieceC.downturns[SolidSurfaceConfigPieceProfiles.W]) {
                if (this.hasB) {
                    downturnLength += (this.lengthWithGap(SolidSurfaceConfigPieces.C) - pieceB.depth);
                } else {
                    downturnLength += this.lengthWithGap(SolidSurfaceConfigPieces.C);
                }
            }

            if (downturnLength > 0) {
                downturnsCost += this.sqrMtrPrice(downturnLength, downturnHeight, worktop.price);
            }

            // Splashbacks and Cills
            splashbacksCost += splashbackPrice(pieceC);
            cillsCost += cillPrice(pieceC);

            // Corners
            cornersCost += cornerPrice(pieceC.corners[SolidSurfaceConfigPieceCorners.NE]);
            cornersCost += cornerPrice(pieceC.corners[SolidSurfaceConfigPieceCorners.SE]);
            cornersCost += cornerPrice(pieceC.corners[SolidSurfaceConfigPieceCorners.SW]);
            cornersCost += cornerPrice(pieceC.corners[SolidSurfaceConfigPieceCorners.NW]);
        }

        if (this.hasD) {
            rightLength += pieceD.depth;
            piecePrice(pieceD);

            // Profile Cost
            if (pieceD.edges[SolidSurfaceConfigPieceProfiles.N]) {
                profilesCost += profilePrice(this.lengthWithGap(SolidSurfaceConfigPieces.D) - pieceC.depth);
            }

            if (pieceD.profiles[SolidSurfaceConfigPieceProfiles.E]) {
                profilesCost += profilePrice(pieceD.depth);
            }

            if (pieceD.profiles[SolidSurfaceConfigPieceProfiles.S]) {
                profilesCost += profilePrice(this.lengthWithGap(SolidSurfaceConfigPieces.D));
            }

            if (pieceD.profiles[SolidSurfaceConfigPieceProfiles.W]) {
                profilesCost += profilePrice(pieceD.depth);
            }

            // Upstand Cost
            let upstandLength = 0;
            if (pieceD.upstands[SolidSurfaceConfigPieceProfiles.N]) {
                upstandLength += (this.lengthWithGap(SolidSurfaceConfigPieces.D) - pieceC.depth);
            }

            if (pieceD.upstands[SolidSurfaceConfigPieceProfiles.E]) {
                upstandLength += pieceD.depth;
            }

            if (pieceD.upstands[SolidSurfaceConfigPieceProfiles.S]) {
                upstandLength += this.lengthWithGap(SolidSurfaceConfigPieces.D);
            }

            if (pieceD.upstands[SolidSurfaceConfigPieceProfiles.W]) {
                upstandLength += pieceD.depth;
            }

            if (upstandLength > 0) {
                upstandsCost += this.sqrMtrPrice(upstandLength, upstandHeight, worktop.price);
            }

            // Downturn Cost
            let downturnLength = 0;
            if (pieceD.downturns[SolidSurfaceConfigPieceProfiles.N]) {
                downturnLength += (this.lengthWithGap(SolidSurfaceConfigPieces.D) - pieceC.depth);
            }

            if (pieceD.downturns[SolidSurfaceConfigPieceProfiles.E]) {
                downturnLength += pieceD.depth;
            }

            if (pieceD.downturns[SolidSurfaceConfigPieceProfiles.S]) {
                downturnLength += this.lengthWithGap(SolidSurfaceConfigPieces.D);
            }

            if (pieceD.downturns[SolidSurfaceConfigPieceProfiles.W]) {
                downturnLength += pieceD.depth;
            }

            if (downturnLength > 0) {
                downturnsCost += this.sqrMtrPrice(downturnLength, downturnHeight, worktop.price);
            }

            // Splashbacks and Cills
            splashbacksCost += splashbackPrice(pieceD);
            cillsCost += cillPrice(pieceD);

            // Corners
            cornersCost += cornerPrice(pieceD.corners[SolidSurfaceConfigPieceCorners.NE]);
            cornersCost += cornerPrice(pieceD.corners[SolidSurfaceConfigPieceCorners.SE]);
            cornersCost += cornerPrice(pieceD.corners[SolidSurfaceConfigPieceCorners.SW]);
            cornersCost += cornerPrice(pieceD.corners[SolidSurfaceConfigPieceCorners.NW]);
        }

        if (this.hasI) {
            piecePrice(pieceI);

            // Profile Cost
            if (pieceI.edges[SolidSurfaceConfigPieceProfiles.N]) {
                profilesCost += profilePrice(this.lengthWithGap(SolidSurfaceConfigPieces.I));
            }

            if (pieceI.edges[SolidSurfaceConfigPieceProfiles.E]) {
                profilesCost += profilePrice(pieceI.depth);
            }

            if (pieceI.edges[SolidSurfaceConfigPieceProfiles.S]) {
                profilesCost += profilePrice(this.lengthWithGap(SolidSurfaceConfigPieces.I));
            }

            if (pieceI.edges[SolidSurfaceConfigPieceProfiles.W]) {
                profilesCost += profilePrice(pieceI.depth);
            }

            // Upstand Cost
            let upstandLength = 0;
            if (pieceI.upstands[SolidSurfaceConfigPieceProfiles.N]) {
                upstandLength += this.lengthWithGap(SolidSurfaceConfigPieces.I);
            }

            if (pieceI.upstands[SolidSurfaceConfigPieceProfiles.E]) {
                upstandLength += pieceI.depth;
            }

            if (pieceI.upstands[SolidSurfaceConfigPieceProfiles.S]) {
                upstandLength += this.lengthWithGap(SolidSurfaceConfigPieces.I);
            }

            if (pieceI.upstands[SolidSurfaceConfigPieceProfiles.W]) {
                upstandLength += pieceI.depth;
            }

            if (upstandLength > 0) {
                upstandsCost += this.sqrMtrPrice(upstandLength, upstandHeight, worktop.price);
            }

            // Downturn Cost
            let downturnLength = 0;
            if (pieceI.downturns[SolidSurfaceConfigPieceProfiles.N]) {
                downturnLength += this.lengthWithGap(SolidSurfaceConfigPieces.I);
            }

            if (pieceI.downturns[SolidSurfaceConfigPieceProfiles.E]) {
                downturnLength += pieceI.depth;
            }

            if (pieceI.downturns[SolidSurfaceConfigPieceProfiles.S]) {
                downturnLength += this.lengthWithGap(SolidSurfaceConfigPieces.I);
            }

            if (pieceI.downturns[SolidSurfaceConfigPieceProfiles.W]) {
                downturnLength += pieceI.depth;
            }

            if (downturnLength > 0) {
                downturnsCost += this.sqrMtrPrice(downturnLength, downturnHeight, worktop.price);
            }

            // Splashbacks and Cills
            splashbacksCost += splashbackPrice(pieceI);
            cillsCost += cillPrice(pieceI);

            // Corners
            cornersCost += cornerPrice(pieceI.corners[SolidSurfaceConfigPieceCorners.NE]);
            cornersCost += cornerPrice(pieceI.corners[SolidSurfaceConfigPieceCorners.SE]);
            cornersCost += cornerPrice(pieceI.corners[SolidSurfaceConfigPieceCorners.SW]);
            cornersCost += cornerPrice(pieceI.corners[SolidSurfaceConfigPieceCorners.NW]);
        }

        if (this.hasE) {
            piecePrice(pieceE);

            // Profile Cost
            if (pieceA.edges[SolidSurfaceConfigPieceProfiles.N]) {
                profilesCost += profilePrice(this.lengthWithGap(SolidSurfaceConfigPieces.A));
            }

            if (pieceA.profiles[SolidSurfaceConfigPieceProfiles.E]) {
                profilesCost += profilePrice(pieceA.depth);
            }

            if (pieceA.profiles[SolidSurfaceConfigPieceProfiles.S]) {
                profilesCost += profilePrice(this.lengthWithGap(SolidSurfaceConfigPieces.A));
            }

            if (pieceA.profiles[SolidSurfaceConfigPieceProfiles.W]) {
                profilesCost += profilePrice(pieceA.depth);
            }

            // Upstand Cost
            let upstandLength = 0;
            if (pieceE.upstands[SolidSurfaceConfigPieceProfiles.N]) {
                upstandLength += this.lengthWithGap(SolidSurfaceConfigPieces.E);
            }

            if (pieceE.upstands[SolidSurfaceConfigPieceProfiles.E]) {
                upstandLength += pieceE.depth;
            }

            if (pieceE.upstands[SolidSurfaceConfigPieceProfiles.S]) {
                upstandLength += this.lengthWithGap(SolidSurfaceConfigPieces.E);
            }

            if (pieceE.upstands[SolidSurfaceConfigPieceProfiles.W]) {
                upstandLength += pieceE.depth;
            }

            if (upstandLength > 0) {
                upstandsCost += this.sqrMtrPrice(upstandLength, upstandHeight, worktop.price);
            }

            // Downturn Cost
            let downturnLength = 0;
            if (pieceE.downturns[SolidSurfaceConfigPieceProfiles.N]) {
                downturnLength += this.lengthWithGap(SolidSurfaceConfigPieces.E);
            }

            if (pieceE.downturns[SolidSurfaceConfigPieceProfiles.E]) {
                downturnLength += pieceE.depth;
            }

            if (pieceE.downturns[SolidSurfaceConfigPieceProfiles.S]) {
                downturnLength += this.lengthWithGap(SolidSurfaceConfigPieces.E);
            }

            if (pieceE.downturns[SolidSurfaceConfigPieceProfiles.W]) {
                downturnLength += pieceE.depth;
            }

            if (downturnLength > 0) {
                downturnsCost += this.sqrMtrPrice(downturnLength, downturnHeight, worktop.price);
            }

            // Splashbacks and Cills
            splashbacksCost += splashbackPrice(pieceE);
            cillsCost += cillPrice(pieceE);

            // Corners
            cornersCost += cornerPrice(pieceE.corners[SolidSurfaceConfigPieceCorners.NE]);
            cornersCost += cornerPrice(pieceE.corners[SolidSurfaceConfigPieceCorners.SE]);
            cornersCost += cornerPrice(pieceE.corners[SolidSurfaceConfigPieceCorners.SW]);
            cornersCost += cornerPrice(pieceE.corners[SolidSurfaceConfigPieceCorners.NW]);
        }

        total = installCost + sqrMtrCost + upstandsCost + profilesCost + cutoutsCost +
            splashbacksCost + cillsCost + cornersCost + downturnsCost;

        return {
            installCost: NumberHelper.toPoundsAndPence(installCost),
            sqrMtrCost: NumberHelper.toPoundsAndPence(sqrMtrCost),
            cornersCost: NumberHelper.toPoundsAndPence(cornersCost),
            upstandsCost: NumberHelper.toPoundsAndPence(upstandsCost),
            profilesCost: NumberHelper.toPoundsAndPence(profilesCost),
            cutoutsCost: NumberHelper.toPoundsAndPence(cutoutsCost),
            cillsCost: NumberHelper.toPoundsAndPence(cillsCost),
            splashbacksCost: NumberHelper.toPoundsAndPence(splashbacksCost),
            downturnsCost: NumberHelper.toPoundsAndPence(downturnsCost),
            total: NumberHelper.toPoundsAndPence(total)
        };
    }

    private sqrMtrPrice(length: number, depth: number, price: number, adjustDepth: boolean = false) {
        if (adjustDepth) {
            return (length / 1000) * (this.adjustDepth(depth) / 1000) * price;
        } else {
            return (length / 1000) * (depth / 1000) * price;
        }
    }

    private adjustDepth(depth) {
        let i, roundedUp = [1600, 1500, 1400, 1300, 1200, 1100, 1000, 900, 800, 700, 625, 550, 400, 300, 200, 100];
        for (i = roundedUp.length; i--;) {
            if (depth <= roundedUp[i]) {
                return roundedUp[i];
            }
        }

        return depth;
    }

    private calcPrice() {
        this.prices = this.priceForWorktop(this.worktop);
        this.worktopConfig.prices = this.prices;
    }

    public update(context: string) {
        this.log('Update: ', context);

        this.worktopConfig = this.worktopConfig || JSON.parse(JSON.stringify(this.startingConfig));
        this.worktopConfig.profileOption = this.worktopConfig.profileOption || this.profileOptions[0];

        if (this.worktopConfig.pieces && this.worktopConfig.profileOption) {
            this.setupLayout();
            this.isValidDims();
            this.log(this.worktopConfig);
            this.renderCanvas();
            this.calcPrice();
            this.save();
        }

        if (!this.readOnly && this.stepIndex === 0 && this.worktopConfig && this.worktopConfig.tab && this.worktopConfig.tab > SolidSurfaceConfigTabs.LAYOUT) {
            this.stepper.steps.forEach((step, idx) => {
                if (idx < this.worktopConfig.tab && step.stepControl.valid) {
                    step.completed = true;
                }
            });

            this.stepIndex = this.worktopConfig.tab - 1;
        }
    }

    public setLayout(chosen) {
        this.worktopConfig = JSON.parse(JSON.stringify(this.startingConfig));
        this.worktopConfig.layout = chosen;
        this.worktopConfig.profileOption = this.worktopConfig.profileOption || this.profileOptions[0];

        this.layoutFormGroup.controls.layoutControl.setValue(chosen);

        this.update('set layout');
    }

    private setupLayout() {
        let layouts = Object.values(SolidSurfaceConfigLayout);
        let layoutIdx = layouts.indexOf(this.worktopConfig.layout);

        this.layoutFormGroup.controls.layoutControl.setValue(this.worktopConfig.layout);

        this.hasA = layoutIdx < 5;
        this.hasB = layoutIdx < 5 && layoutIdx > 1;
        this.hasC = (layoutIdx < 5 && layoutIdx > 2) || layoutIdx === 1;
        this.hasD = layoutIdx === 4;
        this.hasE = this.worktopConfig.addExtra;
        this.hasI = layoutIdx === 5 || this.worktopConfig.addIsland;
    }

    public setUpstandHeight(val) {
        this.worktopConfig.upstandHeight = val;
        this.save();
    }

    public changeStyle() {
        const loadChoices = () => {
            const pricedWorktops = Object.keys(this.worktops)
                .filter((key) => this.worktops[key].cat !== 'Laminate' && this.worktops[key].cat !== 'Solid Wood')
                .map((key) => {
                    let worktop = this.worktops[key];
                    worktop.configPrice = this.priceForWorktop(worktop);

                    return worktop;
                });

            this.dialogService.custom(SolidSurfaceConfigChangeStyleDialogComponent, {
                panelClass: 'solid-surface-config-change-style-dialog',
                data: {
                    worktops: pricedWorktops,
                    worktop: this.worktop
                }
            })
                .then((response) => {
                    if (response && response.worktop) {
                        if (this.worktopConfigUuid) {
                            this.worktop = response.worktop;
                            this.update('change style');
                        } else {
                            this.navigationService.navigate([response.worktop.route]);
                        }
                    }
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        };

        if (this.worktops) {
            loadChoices();
        } else {
            this.catalogueService.getWorktops()
                .then((worktops) => {
                    this.worktops = worktops;
                    loadChoices();
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        }
    }

    public layoutName(layout: SolidSurfaceConfigLayout) {
        switch (layout) {
            case SolidSurfaceConfigLayout.SINGLE:
                return 'Single';
            case SolidSurfaceConfigLayout.GALLEY:
                return 'Galley';
            case SolidSurfaceConfigLayout.L_SHAPED:
                return 'L-Shaped';
            case SolidSurfaceConfigLayout.U_SHAPED:
                return 'U-Shaped';
            case SolidSurfaceConfigLayout.G_SHAPED:
                return 'G-Shaped';
            case SolidSurfaceConfigLayout.ISLAND:
                return 'Island Only';
            default:
                return 'Single';
        }
    }

    public shapings(piece: SolidSurfaceConfigPieces, shaping: SolidSurfaceConfigShapings) {
        let shapings = [];

        if (this.worktopConfig.pieces[piece][shaping]) {
            Object.keys(this.worktopConfig.pieces[piece][shaping]).forEach((key) => {
                const edge = <SolidSurfaceConfigPieceEdges>key;
                const shape = this.calculateShaping(piece, shaping, edge);
                const shapeString = this.shapingsTitle(shape, edge);
                if (shapeString) {
                    shapings.push(shapeString);
                }
            });
        }

        if (shaping === SolidSurfaceConfigShapings.PROFILES) {
            if (this.worktopConfig.pieces[piece][SolidSurfaceConfigShapings.CORNERS]) {
                Object.keys(this.worktopConfig.pieces[piece][SolidSurfaceConfigShapings.CORNERS]).forEach((key) => {
                    const edge = <SolidSurfaceConfigPieceEdges>key;
                    const shape = this.calculateShaping(piece, SolidSurfaceConfigShapings.CORNERS, edge);
                    const shapeString = this.shapingsTitle(shape, edge);
                    if (shapeString) {
                        shapings.push(shapeString);
                    }
                });
            }
        }


        return (shapings.length) ? shapings : ['-'];
    }

    private shapingsTitle(shape: ISolidSurfaceConfigShaping, edge: SolidSurfaceConfigPieceEdges): string {
        let shapeString = null;

        if (shape) {
            let edgeName = this.edgeName(edge, true);

            if (shape.length && shape.height) {
                shapeString = `${edgeName}: ${shape.length}mm x ${shape.height}mm`;
            } else if (shape.length) {
                shapeString = `${edgeName}: ${shape.length}mm`;
            } else if (shape.height) {
                shapeString = `${edgeName}: ${shape.height}mm`;
            } else if (shape.type) {
                shapeString = `${edgeName} (Corner): ${StringHelper.titleCase(shape.type)}`;
            }

            if (shapeString) {
                return shapeString;
            }
        }

        return null;
    }

    private edgeName(edge: SolidSurfaceConfigPieceEdges, short: boolean = false) {
        switch (edge) {
            case SolidSurfaceConfigPieceEdges.NW:
                return (short) ? 'NW' : 'North West';
            case SolidSurfaceConfigPieceEdges.N:
                return (short) ? 'N' : 'North';
            case SolidSurfaceConfigPieceEdges.NE:
                return (short) ? 'NE' : 'North East';
            case SolidSurfaceConfigPieceEdges.E:
                return (short) ? 'E' : 'East';
            case SolidSurfaceConfigPieceEdges.SE:
                return (short) ? 'SE' : 'South East';
            case SolidSurfaceConfigPieceEdges.S:
                return (short) ? 'S' : 'South';
            case SolidSurfaceConfigPieceEdges.SW:
                return (short) ? 'SW' : 'South West';
            case SolidSurfaceConfigPieceEdges.W:
                return (short) ? 'W' : 'West';
            default:
                return '';
        }
    }

    private calculateShaping(piece: SolidSurfaceConfigPieces, shaping: SolidSurfaceConfigShapings, edge: SolidSurfaceConfigPieceEdges): ISolidSurfaceConfigShaping {
        const downturnHeight: number = 900;
        const upstandHeight: number = this.worktopConfig.upstandHeight;
        let length: number = 0;

        switch (piece) {
            case SolidSurfaceConfigPieces.A:
            case SolidSurfaceConfigPieces.C:
                switch (edge) {
                    case SolidSurfaceConfigPieceEdges.N:
                    case SolidSurfaceConfigPieceEdges.S:
                        length = this.worktopConfig.pieces[piece].depth;
                        break;
                    case SolidSurfaceConfigPieceEdges.E:
                    case SolidSurfaceConfigPieceEdges.W:
                        length = this.worktopConfig.pieces[piece].length;
                        break;
                }
                break;
            case SolidSurfaceConfigPieces.B:
            case SolidSurfaceConfigPieces.D:
            case SolidSurfaceConfigPieces.E:
            case SolidSurfaceConfigPieces.I:
                switch (edge) {
                    case SolidSurfaceConfigPieceEdges.N:
                    case SolidSurfaceConfigPieceEdges.S:
                        length = this.worktopConfig.pieces[piece].length;
                        break;
                    case SolidSurfaceConfigPieceEdges.E:
                    case SolidSurfaceConfigPieceEdges.W:
                        length = this.worktopConfig.pieces[piece].depth;
                        break;
                }
                break;
        }

        let shapings = null;
        switch (shaping) {
            case SolidSurfaceConfigShapings.PROFILES:
                shapings = {
                    length: length
                };
                break;
            case SolidSurfaceConfigShapings.UPSTANDS:
                shapings = {
                    length: length,
                    height: upstandHeight
                };
                break;
            case SolidSurfaceConfigShapings.DOWNTURNS:
                shapings = {
                    length: length,
                    height: downturnHeight
                };
                break;
            case SolidSurfaceConfigShapings.CORNERS:
                shapings = {
                    type: this.worktopConfig.pieces[piece].corners[edge]
                };
                break;
        }

        if (this.worktopConfig.pieces[piece][shaping][edge]) {
            return shapings;
        }

        return null;
    }

    public addToBasket() {
        const item = new BasketItem({
            code: 'SOLIDSURFACEWORKTOP',
            description: `${this.worktop.product_code} - ${this.worktop.thickness}mm - ${this.worktop.cat} Solid Surface - Includes Template and Fitting`,
            otherColour: this.worktop.product_code,
            depth: this.worktop.thickness,
            qty: 1,
            rankParent: 0,
            category: this.worktop.cat,
            subCategory: this.worktop.sub_cat,
            cost: this.worktopConfig.prices.total,
            worktopConfig: this.worktopConfig
        }, null, ProductType.WORKTOPS);

        // let canvasObj = <HTMLCanvasElement>document.getElementById('worktopCanvas');
        // if (canvasObj) {
        //     const image = canvasObj.toDataURL('image/png');
        //     item.worktopConfigImage = image;
        // }

        if (this.worktopConfigUuid) {
            this.basketService.removeSolidSurface(this.worktopConfigUuid)
                .then(() => {
                    this.basketService.addItem(item, ProductType.WORKTOPS)
                        .then(() => {
                            this.saveEmitter.emit(null);
                            this.navigationService.navigate(['basket']);
                        })
                        .catch((error) => this.dialogService.error(this.constructor.name, error));
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        } else {
            this.basketService.addItem(item, ProductType.WORKTOPS)
                .then(() => {
                    this.saveEmitter.emit(null);
                    this.navigationService.navigate(['basket']);
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        }
    }

    private save() {
        this.saveEmitter.emit(this.worktopConfig);
    }
}
