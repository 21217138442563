@import '/src/theme/variables';

component-monek-legacy-payment {
    display: block;
    margin-bottom: 35px;

    .pay-header {
        font-weight: bold;
    }

    .payment-info {
         display: flex;
         justify-content: space-between;
         flex-direction: row-reverse;
    }

    .payment-options {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
    }

    .payment-option {
        margin: 10px;
        padding: 5px;
        border: 2px solid color('copy-border');
        cursor: pointer;

        .payment_header {
            font-weight: bold;
            margin-bottom: 5px;
        }

        &.payment-selected {
            border: 2px solid color('brand-primary');
            color: color(green);
        }
    }

    .split-me {
        display: flex;
        justify-content: space-between;
    }

    .payment {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 4px solid color('copy-border');
        margin-top: 15px;
        margin-bottom: 15px;
        padding: 10px
    }

    .refund-options {
        display: flex;
        flex-direction: column;
    }

    .allocated {
        font-weight: bold;
        color: color('green');
    }

    .unallocated {
        font-weight: bold;
        color: color('bright-red');
    }

    .single-payment-refund-notice {
        margin-bottom: 15px;
    }

    .split-payment {
        display: flex;
        flex-direction: column;
    }

    .payment-button {
        margin: 10px;
    }

    .mat-mdc-card {
        margin: 15px;
        width: 100%;
        max-width: 250px;
        height: 200px;
        cursor: pointer;
    }

    .mat-mdc-text-field-wrapper {
        &.mdc-text-field--outlined {
            .mdc-notched-outline {
                display: flex;
            }

            &:not(.mdc-text-field--disabled) {

                .mdc-notched-outline__leading,
                .mdc-notched-outline__notch,
                .mdc-notched-outline__trailing {
                    border-color: #ced4da;
                }
            }

            &.mdc-text-field--focused {
                &:not(.mdc-text-field--disabled) {

                    .mdc-notched-outline__leading,
                    .mdc-notched-outline__notch,
                    .mdc-notched-outline__trailing {
                        border-color: #009cde;
                    }
                }

            }

            .mat-mdc-form-field-infix {
                min-height: 38px;
                padding-top: 8px;
                padding-bottom: 8px;
            }

            .mat-mdc-form-field-input-control {
                font-size: 1rem;
                color: #495057;
                font-weight: 400;
            }

        }
    }

    .how-to-pay {
        margin-top: 15px;
    }

    .actions {
        display: flex;
        justify-content: flex-end;

        .mdc-button {
            width: initial;
        }
    }
}