<section class="dark" *ngIf="range && selectedColour && selectedCabinet">
    <div class="contained">
        <div class="range-image" [cdn]="getRangeImage()" [promotion]="'range-detail'" [request]="'product-overlay-image'" [rangeName]="range.name" [colourName]="selectedColour.name">
            <h4>{{range.name}} {{selectedColour.name}}</h4>
        </div>

        <div class="range-colours">
            <div class="flex-center wrap">
                <a *ngFor="let colour of range.colours" [routerLink]="styleUrl(range.name, colour.name)" [title]="range.name + ' ' + colour.name">
                    <div class="swatch" [ngClass]="'swatch-' + colour.css_class" [matTooltip]="colour.name"
                        matTooltipPosition="above" matTooltipClass="tooltip">
                        <mat-icon *ngIf="colour.name === selectedColour.name" class="selected-colour" aria-label="Done">
                            done
                        </mat-icon>
                    </div>
                </a>
            </div>
        </div>

        <hr />

        <section class="range-details">
            <section>
                <h1>{{range.name}}</h1>
                <p [innerHTML]="range.range_detailed_description"></p>

                <hr />

                <h3>Features And Specifications</h3>
                <p>
                    All our high specification kitchen units are supplied fully rigid with all components factory
                    fitted, by organising the design and fitting aspects to the kitchen yourself you can make huge
                    savings against like for like high specification kitchens normally found in high street showrooms
                    and even against the DIY multichains.
                </p>

                <mat-tab-group>
                    <mat-tab label="Door specification">
                        <div class="specs">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Available colours:</th>
                                        <td *ngIf="!hasBespoke">{{range.colours.length}} standard colours</td>
                                        <td *ngIf="hasBespoke">{{range.colours.length-1}} plus any bespoke colour</td>
                                    </tr>
                                    <tr>
                                        <th>Finish:</th>
                                        <td>{{range?.finishes?.join(", ")}}</td>
                                    </tr>
                                    <tr>
                                        <th>Material:</th>
                                        <td>{{range?.door_material}}</td>
                                    </tr>
                                    <tr>
                                        <th>Thickness:</th>
                                        <td>{{range?.door_thickness}}mm</td>
                                    </tr>
                                    <tr *ngIf="hasCurves">
                                        <th>Curved doors:</th>
                                        <td>Yes</td>
                                    </tr>
                                    <tr *ngIf="hasCurves">
                                        <th>Internal curved doors:</th>
                                        <td>Yes</td>
                                    </tr>
                                    <tr *ngIf="hasGlazed">
                                        <th>Glazed doors:</th>
                                        <td>Yes</td>
                                    </tr>
                                    <tr *ngIf="hasPlaterack">
                                        <th>Plate racks:</th>
                                        <td>Yes</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </mat-tab>
                    <mat-tab label="Unit specification">
                        <div class="specs">
                            <p>18mm rigid kitchen, glue &amp; dowel construction, colour co-ordinated both inside &amp;
                                out, 720mm high (+150mm Legs) as standard.</p>
                            <p>Hardwearing colour co-ordinated 2mm thick durable ABS edging.</p>
                            <p>18mm adjustable shelf.</p>
                            <p>Solid, colour co-ordinated 8mm backs, with a 42mm service void behind.</p>
                            <p>Blum Clip Top Metal 110 degree door hinges with 3-way adjustment and soft close
                                additionally available.</p>
                            <p>Fully adjustable 150mm legs for fitting on uneven floors.</p>
                            <p>Blum Tandembox A Design, twin walled metal sided drawer system with 16mm solid base and
                                metal back incorporating full extension runners with integrated soft close dampers.</p>
                            <p>18mm MFC Blanking Panel, 500mm wide on all corner base units.</p>
                            <p>Concealed wall hanging brackets with 3-way adjustment on all wall units. Each pair of
                                brackets can carry 70kgs and conforms to DIN68840. Supplied with wall hanging plates.
                            </p>
                            <p>Blum 450mm & 500mm tandem on drawer packs.</p>
                        </div>
                    </mat-tab>
                </mat-tab-group>

                <hr />

                <div class="flex-space-between wrap-under-800">
                    <div class="flex-item carcase-image text-center" *ngIf="selectedCabinet">
                        <img [cdn]="'carcases/' + selectedCabinet.img_2d" height="450" [alt]="range.name + ' ' + selectedCabinet.colour + ' image'"/>
                        <h5>{{selectedCabinet.colour}} cabinet colour</h5>
                    </div>
                    <div class="flex-item">
                        <h3>Colour match cabinets</h3>
                        <p>By offering cabinets in colours that co-ordinate with door ranges you don't always require
                            additional clad on panels. This will reduce the cost of your kitchen considerably and make it much
                            easier to plan and install.
                        </p>
                        <p>Unlike others, our unit colours match our door colours to give you a finish
                            you'll love. The recommended cabinet colour to match {{selectedColour.name}} doors is
                            {{selectedColour.def_carcase}}</p>
                        <div class="unit-colours flex-center wrap">
                            <button class="swatch" [ngClass]="'swatch-' + cabinetColour.css_class"
                                *ngFor="let cabinetColour of cabinetColours"
                                (click)="selectCabinetColour(cabinetColour)"
                                [matTooltip]="cabinetColour.colour" matTooltipPosition="above" matTooltipClass="tooltip">
                                <mat-icon *ngIf="cabinetColour.colour === selectedCabinet.colour" class="selected-colour">done</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <component-range-actions [range]="range" [selectedColour]="selectedColour"
                    [selectedCabinet]="selectedCabinet" orientation="column"></component-range-actions>
            </section>
        </section>
    </div>
</section>
