import { Component, ViewEncapsulation, HostListener, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { Config } from '@app/config';

import { DialogService } from '@app/services/dialog';
import { MessageGroup } from '@app/services/messaging/models';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute } from '@app/services/navigation/models';
import { OrdersService } from '@app/services/orders';
import { PaymentsService } from '@app/services/payments';
import { RemedialsService } from '@app/services/remedials';
import { RemedialType } from '@app/services/remedials/models';

@Component({
    selector: 'page-remedial',
    templateUrl: './remedial.page.html',
    styleUrls: ['./remedial.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class RemedialPage implements OnInit, OnDestroy {
    public loading = true;

    public order;
    public newProducts = [];

    public showDamages = false;
    public showReturns = false;
    public showMissings = false;
    public showBBuilder = false;
    public showSummary = false;

    public checkoutCustomer: any = {};
    public hasReportableItems = false;
    public checkoutText = 'PROCEED';

    public problemOrder = false;

    public totalAttachmentSize = 0;
    public attachmentSizeLimit = 50 * 1024 * 1024;

    private issues = [];
    private orderNumber;
    private orderItemsIndexed = {};
    private orderItemsOptions = [];
    private busy = false;
    private issueDetails;
    private newOrderNumber;
    private checkout = false;
    private checkoutStep = 1;
    private requestSent = false;
    private shipping = 0;

    private route: Subscription;

    public bbuilderURL;

    private bbReceiver;

    constructor(
        private sanitizer: DomSanitizer,
        private config: Config,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private ordersService: OrdersService,
        private paymentsService: PaymentsService,
        private remedialsService: RemedialsService
    ) {
        this.navigationService.setTitle('DIY Kitchens - View order');

        this.route = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                let routeParams = route.params;
                if (routeParams) {
                    this.orderNumber = routeParams.orderNumber || null;

                    if (routeParams.action === 'returns') {
                        this.showReturns = true;
                    }
                    if (routeParams.action === 'report') {
                        this.showDamages = true;
                    }
                    if (routeParams.action === 'missings') {
                        this.showMissings = true;
                    }
                }

                this.navigationService.setRouteHistory([
                    { title: 'Support', route: '/support' },
                    { title: 'Your orders', route: '/account/orders' },
                    { title: this.orderNumber, route: '/account/order/' + (this.orderNumber || '') }
                ]);
            }
        );
    }

    ngOnInit() {
        this.getOrder(this.orderNumber);
    }

    ngOnDestroy(): void {
        if (this.route) {
            this.route.unsubscribe();
            this.route = null;
        }
    }

    private addNewProducts(items) {
        this.newProducts = this.newProducts.concat(items.map((item) => {
            switch (item.group) {
                case 'Cabinets':
                    item.isCabinet = true;
                    break;
                case 'Doors':
                    item.colour = item.rangeColour;
                    delete item.carcaseColour;
                    delete item.hingeCost;
                    break;
                default:
                    break;
            }

            item.qty = parseInt(item.qty, 10);

            return item;
        }));
    }

    public removeItem(item) {
        this.newProducts = this.newProducts.filter(i => i !== item);
        this.miniSummary();
    }

    private getOrder(orderNumber) {
        if (orderNumber) {
            this.ordersService.getMCSOrder(orderNumber)
                .then((response: any) => {
                    if (!response || !response[0]) {
                        this.problemOrder = true;

                        return;
                    }

                    let order = response[0];
                    let orderItems = order.orderItems;

                    this.orderItemsOptions = [];

                    let activeRanges = [];

                    orderItems.forEach((orderItem) => {
                        this.orderItemsIndexed[orderItem.id] = orderItem;

                        let show = !orderItem.rank.includes('/');
                        let hasComponents = false;
                        if (orderItem.range && orderItem.colour) {
                            orderItem.range = orderItem.range.split('&')[0].trim();
                            let encodedRange = orderItem.range + '|' + orderItem.colour + '|' + orderItem.carcaseColour;
                            let exists = activeRanges.find(ar => ar.range === encodedRange);
                            if (exists) {
                                exists.count++;
                            } else {
                                activeRanges.push({
                                    range: encodedRange,
                                    count: 1
                                });
                            }
                        }

                        let componentChildren = orderItems.find((item) => item.rank.startsWith(`${orderItem.rank}/`));
                        if (componentChildren) {
                            hasComponents = true;
                        }

                        let requestDetail = orderItem.hasActiveRemedial ? null :
                            (orderItem.hasRequest ? 'Replacement requested on ' + orderItem.hasRequest.orderDate : null);
                        let remedialDetail = orderItem.hasActiveRemedial;

                        this.orderItemsOptions.push({
                            id: orderItem.id,
                            rank: orderItem.rank,
                            rankParent: orderItem.rankParent,
                            group: orderItem.group,
                            code: orderItem.code,
                            description: orderItem.description,
                            selected: false,
                            note: null,
                            issues: orderItem.issues,
                            issueType: 1,
                            qty: orderItem.qty,
                            handing: orderItem.handing,
                            max: orderItem.qty,
                            isComponent: orderItem.isComponent,
                            isDryAssembled: orderItem.isDryAssembled,
                            range: orderItem.range,
                            colour: orderItem.colour,
                            carcaseColour: orderItem.carcaseColour,
                            isCabinet: orderItem.group === 'Units',
                            show,
                            hasComponents,
                            customHanding: null,
                            requestDetail: requestDetail,
                            remedialDetail: remedialDetail
                        });
                    });

                    activeRanges.sort((a, b) => b.count - a.count);
                    if (activeRanges[0]) {
                        this.bbuilderURL = this.sanitizer.bypassSecurityTrustResourceUrl('http://localhost:4400/set-style/'
                            + activeRanges[0].range);
                    } else {
                        this.bbuilderURL = this.sanitizer.bypassSecurityTrustResourceUrl('http://localhost:4400/');
                    }

                    this.order = order;
                    this.loading = false;

                    this.checkoutCustomer = Object.assign({}, order.customer);

                    this.hasReportableItems = false;
                    this.orderItemsOptions.forEach(item => {
                        if (!item.requestDetail && !item.remedialDetail) {
                            this.hasReportableItems = true;
                        }
                    });
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        }
    }

    public openDamages() {
        this.navigationService.navigate([
            'order',
            this.order.orderNumber,
            'report'
        ]);
        this.showDamages = true;
    }

    public openReturns() {
        this.navigationService.navigate([
            'order',
            this.order.orderNumber,
            'returns'
        ]);
        this.showReturns = true;
    }

    public openMissings() {
        this.navigationService.navigate([
            'order',
            this.order.orderNumber,
            'missings'
        ]);
        this.showMissings = true;
    }

    public cancel() {
        if (this.showSummary) {
            this.showSummary = false;
        } else {
            this.showMissings = false;
            this.showReturns = false;
            this.showDamages = false;
            this.orderItemsOptions.forEach(item => {
                item.reqReturn = false;
                item.isDamaged = false;
                item.isMissing = false;
                item.issueText = '';
                item.itemAttachments = [];
            });
        }
    }

    public textPlaceholder() {
        if (this.showDamages) {
            return 'Please describe the issue';
        }

        if (this.showReturns) {
            return 'Please add the reason for the return';
        }

        return '';
    }

    public showTextEntry(item) {
        return (this.showDamages && (item.isDamaged || item.isIncorrect)) || (this.showReturns && item.reqReturn);
    }

    private dialogRequest() {
        this.dialogService.html('Request sent',
            `<p>
                Your request has been submitted to our aftersales team for further approval. The team will be back in
                contact with you shortly via email to advise if your request has been accepted or denied. Further
                information may be required, to enable us to process the replacement items. This information will be
                requested via your online dashboard, so please check for any further replies so that any issues can be
                resolved in a timely manner.
            </p>`,
            {
                minHeight: 'auto',
                data: {
                    buttons: [
                        { label: 'CLOSE', action: false }
                    ]
                }
            }
        );
    }

    private dialogReturns() {
        this.dialogService.html('Request sent',
            '<p>You will receive an email shortly containing full instructions of how to return the items.</p>',
            {
                minHeight: 'auto',
                data: {
                    buttons: [
                        { label: 'CLOSE', action: false }
                    ]
                }
            }
        );
    }

    public addExtras() {
        this.showBBuilder = true;
    }

    public closeExtras() {
        this.showBBuilder = false;
    }

    public isDamageChanged(item) {
        item.isMissing = false;
        item.qtyMissing = null;

        if (!item.isDamaged) {
            item.issueText = null;
            item.itemAttachments = null;
        }
    }

    public isIncorrectChanged(item) {
        item.isMissing = false;
        item.qtyMissing = null;
        item.isDamaged = false;

        if (!item.isIncorrect) {
            item.issueText = null;
            item.itemAttachments = null;
        }
    }

    public isMissingChanged(item) {
        item.qtyMissing = null;
        item.isDamaged = false;
        item.issueText = null;
        item.itemAttachments = null;
    }

    public needsDetails(item) {
        return (item.isDamaged && (!item.issueText || !item.itemAttachments))
            || (item.reqReturn && !item.issueText)
            || (item.isMissing && item.qtyMissing === undefined);
    }

    public highlightNeedy() {
        return this.orderItemsOptions.find(item => this.needsDetails(item));
    }

    public submitDamages() {
        this.showDamages = false;
        this.showMissings = false;
        let items = this.orderItemsOptions.filter(item => (item.issueText && item.itemAttachments) || item.isMissing);

        let hasDamages = false;
        let hasMissings = false;
        let hasIncorrect = false;

        let remedialRequest = {
            customer: this.checkoutCustomer,
            orderItems: items.map(item => {
                if (item.issueText && item.itemAttachments) {
                    item.notes = [item.issueText];
                    item.attachments = item.itemAttachments.map((file: any) => {
                        return {
                            name: file.name,
                            type: file.type,
                            binaryContent: file.binaryContent
                        };
                    });
                }
                if (item.isDamaged) {
                    hasDamages = true;
                    item.remedialTypeId = RemedialType.DAMAGED;
                }
                if (item.isIncorrect) {
                    hasIncorrect = true;
                    item.remedialTypeId = RemedialType.INCORRECT;
                }
                if (item.isMissing) {
                    item.notes = [(item.qtyMissing ? item.qtyMissing + ' out of ' + item.qty + ' missing items' : 'Item is missing')];
                    hasMissings = true;
                    item.remedialTypeId = RemedialType.MISSING;
                    item.qty = parseInt(item.qtyMissing, 10) || item.qty;
                    delete item.qtyMissing;
                }
                delete item.isDamaged;
                delete item.isIncorrect;
                delete item.isMissing;
                delete item.issueText;
                delete item.itemAttachments;

                return item;
            }),
            newProducts: this.newProducts,
            groupId: MessageGroup.REMEDIALS
        };

        this.remedialsService.submitDamages(this.order.orderNumber, remedialRequest)
            .then((response) => this.processRequest(response, false))
            .catch((error) => {
                this.loading = false;
                this.dialogService.error(this.constructor.name, 'Order page:', error);
            });
    }

    public submitReturns() {
        this.loading = true;
        this.showReturns = false;

        let returnsRequest = {
            orderItemIds: [],
            orderNumber: this.order.orderNumber
        };

        this.orderItemsOptions.forEach(item => {
            if (item.issueText?.length > 2 && this.itemReturnable(item)) {
                returnsRequest.orderItemIds.push(item.id);
            }
            delete item.issueText;
            delete item.reqReturn;
        });

        this.remedialsService.submitReturns(returnsRequest)
            .then((response) => this.processRequest(response, true))
            .catch((error) => {
                this.loading = false;
                this.dialogService.error(this.constructor.name, error);
            });
    }

    private processRequest(response, isReturns) {
        if (this.totalNewProducts() > 0) {
            // Payment required so generate the form required to launch Cardnet connect payment page
            let body: any = response;
            this.newOrderNumber = body.message;
            this.createHashAndLaunchCardnet(this.newOrderNumber);
        } else { // no payment required so just return to the order view & reload it
            if (isReturns) {
                this.dialogReturns();
            } else {
                this.dialogRequest();
            }

            this.showSummary = false;
            this.newProducts = [];

            this.getOrder(this.orderNumber);
        }

        this.loading = false;
    }

    public countReturns() {
        if (this.orderItemsOptions) {
            return this.orderItemsOptions.filter(i => i.issueText && i.reqReturn).length;
        }

        return 0;
    }

    public countDamages() {
        if (this.orderItemsOptions) {
            return this.orderItemsOptions.filter(i => (i.issueText && i.itemAttachments) || i.isMissing).length;
        }

        return 0;
    }

    public countMissings() {
        if (this.orderItemsOptions) {
            return this.orderItemsOptions.filter(i => i.isMissing).length;
        }

        return 0;
    }

    public miniSummary() {
        if (this.orderItemsOptions) {
            let missings = this.orderItemsOptions.filter(i => i.isMissing).length;
            let incorrect = this.orderItemsOptions.filter(i => i.isIncorrect).length;
            let damages = this.orderItemsOptions.filter(i => i.isDamaged).length;
            let returns = this.orderItemsOptions.filter(i => i.reqReturn).length;
            let positives = [];

            if (missings > 0) {
                positives.push(missings + ' missing');
            }

            if (incorrect > 0) {
                positives.push(incorrect + ' incorrect');
            }

            if (damages > 0) {
                positives.push(damages + ' damaged');
            }

            if (this.newProducts.length > 0) {
                positives.push(this.newProducts.length + ' extra');
            }

            let itemCount = (missings + damages + incorrect + this.newProducts.length);

            if (this.totalNewProducts() > 0) {
                this.checkoutText = 'SUBMIT REQUEST AND ENTER PAYMENT DETAILS';
            } else {
                this.checkoutText = 'SUBMIT REQUEST';
            }

            if (itemCount > 0) {
                return positives.join(', ') + ' item' + (itemCount > 1 ? 's' : '');
            } else if (returns > 0) {
                return returns + ' item' + (returns > 1 ? 's' : '') + ' to return';
            }
        }

        return '';
    }

    public setShown(orderItem) {
        orderItem.showComponents = !orderItem.showComponents;
        this.orderItemsOptions.forEach((option) => {
            if ((option.rankParent === orderItem.rank) && option.isComponent) {
                option.show = orderItem.showComponents;
            }
        });
    }

    // If we're showing the summary only show items that are part of the remedial request
    public orderItemsInContext() {
        return this.orderItemsOptions.filter(item => !this.showSummary ||
            (item.isDamaged || item.isIncorrect || item.isMissing || item.reqReturn));
    }

    public totalNewProducts() {
        let total = 0;
        this.newProducts.forEach(item => total += (item.cost || item.price) * item.qty);

        return parseInt('' + total * 100, 10) / 100; // cut off any rounding errors
    }

    public itemReturnable(item) {
        if ((item.group === 'Units')
            || (item.group === 'Appliances')
            || (item.style && item.style.match(/bespoke/i))
            || (item.rankParent !== '0')
            || (item.code === 'LEGBOX*')
            || (item.code === 'F*')
            || (item.code === 'TECHNICALGUIDEBOX')
            || (item.remedialDetail)
            || (item.requestDetail)
        ) {
            return false;
        }

        return true;
    }

    public gotoSummary() {
        this.showSummary = true;
    }

    public getMediaFile(orderItem) {
        document.getElementById(`file-${orderItem.rank}`).click();
    }

    public fileUpload(e, orderItem) {
        orderItem.itemAttachments = Array.from(e.target.files);

        orderItem.itemAttachments.forEach((file: File) => {
            let reader = new FileReader();
            reader.onload = ((fileToBeUploaded: any) => {
                return (ev) => {
                    fileToBeUploaded.binaryContent = ev.target.result;

                    this.updateTotalAttachmentSize();
                };
            })(file);

            reader.readAsDataURL(file);
        });
    }

    public updateTotalAttachmentSize() {
        this.totalAttachmentSize = 0;

        this.orderItemsOptions
            .forEach((item) => {
                if (item.itemAttachments && item.itemAttachments.length) {
                    item.itemAttachments.forEach((file: any) => {
                        this.totalAttachmentSize += file.size;
                    });
                }
            });
    }

    public displayFileSize(size) {
        return Math.round(size / 1024 / 1024 * 100) / 100;
    }

    public showDamagesProceed() {
        return (this.countDamages() + this.newProducts.length) < 1 ||
            this.highlightNeedy() ||
            this.totalAttachmentSize === 0 ||
            this.totalAttachmentSize > this.attachmentSizeLimit;
    }

    public clearDamageFile(orderItem, file) {
        orderItem.itemAttachments = orderItem.itemAttachments.filter(f => f !== file);
        if (orderItem.itemAttachments.length === 0) {
            orderItem.itemAttachments = null;
        }

        this.updateTotalAttachmentSize();
    }

    private createHashAndLaunchCardnet(orderNumber: string) {
        let subtotal = this.totalNewProducts();
        let shipping = this.shipping;

        this.paymentsService.createHashAndLaunchCardnet(orderNumber, subtotal, shipping);
    }

    public qtyDropdown(orderItem) {
        let array = [];
        for (let i = 1; i <= orderItem.qty; i++) {
            array.push(i);
        }

        return array;
    }

    @HostListener('window:message', ['$event']) onMessage(event) {
        if (
            this.config.trusted.basketBuilder.indexOf(event.origin) !== -1 ||
            event.origin.match(/^https:\/\/[a-z0-9-_\.]+\.diy-kitchens.com/i)
        ) {
            if (event.data && event.data.items) {
                this.showBBuilder = false;
                this.addNewProducts(event.data.items);
            }
        }
    }
}
