@import '/src/theme/variables';

page-appliance-listing {
    padding-top: 12px;

    .products {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;
        padding: 150px 0 15px;
        max-width: calc(100% - 280px);
        min-height: 100vh;
    }

    .small-banners {
        display: flex;
        gap: 20px;
        margin-top: 15px;
        margin-left: 40px;

        .small-banner {
            flex-basis: 33.33%;
        }

        img {
            width: 100%;
            max-width: 100%;
            max-height: 150px;
        }
    }

    @media only screen and (max-width: 1000px) {
        .products {
            padding-top: 0;
            margin-top: 60px;
            margin-left: 0;
            max-width: 100%;
        }
    }

    .no-results {
        padding: 20px;
    }
}
