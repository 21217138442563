@import '/src/theme/variables';

page-order {
    header {
        &.content-contained {
            .panel {
                .panel-left {
                    flex: initial;
                    flex-grow: 1;
                }

                .panel-right {
                    flex: initial;
                    align-content: flex-end;
                    white-space: nowrap;
                }
            }
        }

        .header-logo {
            margin-bottom: 25px;
        }
    }

    section {
        &.content-contained {
            padding-top: 0;

            strong {
                font-weight: bold;
            }
        }
    }

    .order-confirmation {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;

        .hr-1 {
            flex-grow: 3;
        }

        .hr-2 {
            flex-grow: 0;
            margin-right: 10px;
            margin-left: 10px;
            font-style: italic;
            font-weight: bold;
        }

        .hr-3 {
            flex-grow: 11;
        }
    }

    .address {
        display: flex;
        justify-content: space-evenly;
        gap: 15px;
        margin-bottom: 15px;

        .address-section {
            flex-grow: 1;
            border: 1px solid color('copy-border');
            padding: 10px 15px;

            p {
                margin-bottom: 0;
            }
        }
    }

    .details-misc {
        .panel {
            .panel-left {
                flex: initial;
                flex-grow: 1;
            }

            .panel-right {
                flex: initial;
            }
        }
    }

    .items-table {
        border-collapse: collapse;
        width: 100%;

        tr {
            th {
                padding: 5px;
                background-color: color('light-grey');
            }

            td {
                padding: 5px;
                border: 1px solid color('light-grey');
            }

            .description {
                p {
                    margin: 0;
                }
            }

            .spanned {
                border: 0;
                text-align: right;
            }

            .spanned-result {
                background-color: color('light-grey');
                text-align: right;
            }

            .number-display {
                text-align: right;
            }
        }
    }

    .error-message {
        display: flex;
        justify-content: center;
        margin: 50px 0;
    }

    @media print {
        .content-contained {
            padding: 0;
        }
    }
}