import { Component, ViewEncapsulation, EventEmitter, Output, Renderer2, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'component-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class MobileMenuComponent implements OnInit, OnDestroy {
    @Output() closeMenuEvent = new EventEmitter<void>();

    constructor(
        private renderer: Renderer2
    ) {}

    ngOnInit(): void {
        this.renderer.addClass(document.body, 'no-scroll');
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(document.body, 'no-scroll');
    }

    public closeMenu() {
        this.closeMenuEvent.emit();
    }
}
