import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { NavigationService } from '@app/services/navigation';
import { RouteHistory } from '@app/services/navigation/models';

import { StickyTabsCreditRating } from './models';
import { IStickyTab } from '@app/components/sticky-tabs/models';

@Component({
    selector: 'page-about-credit-rating',
    templateUrl: './credit-rating.page.html',
    styleUrls: ['./credit-rating.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AboutCreditRatingPage implements OnInit {
    public StickyTabs = StickyTabsCreditRating;
    public tabs: IStickyTab<StickyTabsCreditRating>[] = [
        { title: 'Buy with confidence', id: StickyTabsCreditRating.CONFIDENCE },
        { title: 'Our credit rating', id: StickyTabsCreditRating.RATING },
        { title: 'Safest way to buy goods', id: StickyTabsCreditRating.SAFEST }
    ];

    private routeHistory: RouteHistory[] = [
        { title: 'About Diy Kitchens', route: '/about-diy-kitchens' },
        { title: 'Our Credit Rating', route: '/credit-rating' },
    ];

    constructor(
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: 'Our Credit Rating',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'description', content: 'We are a family run business that has been manufacturing and supplying high specification kitchens to both trade and retail customers for nearly 30 years.' }
            ],
            routeHistory: this.routeHistory
        });
    }
}
