import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';

import { ChangeCabinetOptionsDialogComponent } from './change-cabinet-options.dialog.component';

@NgModule({
    imports: [
        DesignModule
    ],
    declarations: [
        ChangeCabinetOptionsDialogComponent
    ]
})
export class ChangeCabinetOptionsDialogModule { }
