<mat-dialog-content>
    <div class="container-fluid">
        <h4>Enter Dimensions</h4>
        <div class="options">
            <div class="option">
                <h5>Splashback</h5>
                <mat-form-field appearance="outline">
                    <mat-label>Length (mm)</mat-label>
                    <input matInput placeholder="Length (mm)" type="number" [(ngModel)]="splashback.length" (ngModelChange)="valid('splashback')" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Height (mm)</mat-label>
                    <input matInput placeholder="Height (mm)" type="number" [(ngModel)]="splashback.height" (ngModelChange)="valid('splashback')" />
                </mat-form-field>

                <p class="error-message" *ngIf="!valid('splashback')">
                    Please make sure you select between: <br />
                    Length: {{minLength}}mm and {{maxLength}}mm<br />
                    Height: {{minHeight}}mm and {{maxHeight}}mm
                </p>
            </div>

            <div class="option">
                <h5>Cill</h5>
                <mat-form-field appearance="outline">
                    <mat-label>Length (mm)</mat-label>
                    <input matInput placeholder="Length (mm)" type="number" [(ngModel)]="cill.length" (ngModelChange)="valid('cill')" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Depth (mm)</mat-label>
                    <input matInput placeholder="Depth (mm)" type="number" [(ngModel)]="cill.height" (ngModelChange)="valid('cill')" />
                </mat-form-field>

                <p class="error-message" *ngIf="!valid('cill')">
                    Please make sure you select between: <br />
                    Length: {{minLength}}mm and {{maxLength}}mm<br />
                    Height: {{minHeight}}mm and {{maxHeight}}mm
                </p>
            </div>
        </div>

        <button mat-flat-button color="primary" class="chunky" (click)="add()" [disabled]="!valid('splashback') || !valid('cill')">Add</button>
    </div>
</mat-dialog-content>