import { Component, ViewEncapsulation, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Subscription } from 'rxjs';

import { StringHelper } from '@app/utilities/helpers';
import { FormValidators } from '@app/utilities/validators';

import { BasketService } from '@app/services/basket';
import { AddressType, IBasketAddress, IBasketAddresses } from '@app/services/basket/models';
import { CustomerService } from '@app/services/customer';
import { DialogService } from '@app/services/dialog';
import { OrderService } from '@app/services/order';

@Component({
    selector: 'component-customer-details',
    templateUrl: './customer-details.component.html',
    styleUrls: ['./customer-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CustomerDetailsComponent implements AfterViewInit, OnDestroy {
    @Input() stepper: MatStepper;
    @Input() formGroup: FormGroup;
    @Input() order: any;

    public detailsFormGroup: FormGroup;

    public error: boolean = false;
    public updating: boolean = false;

    public updateDetails: boolean = true;
    public deliverToBilling: boolean = true;

    private addresses: IBasketAddresses;
    private customerDetails;

    public loading: boolean = true;
    private basketSubscription: Subscription = null;
    private valueChangesSubscription: Subscription = null;

    constructor(
        private formBuilder: FormBuilder,
        private basketService: BasketService,
        private customerService: CustomerService,
        private dialogService: DialogService,
        private orderService: OrderService,
    ) {
        this.detailsFormGroup = this.formBuilder.group({
            firstName: new FormControl('', [Validators.required]),
            lastName: new FormControl('', [Validators.required]),
            landline: new FormControl('', [FormValidators.phoneNumber]),
            tel: new FormControl('', [Validators.required, FormValidators.phoneNumber]),
            deliverToBilling: new FormControl(this.deliverToBilling),
            deliveryLine1: new FormControl('', [Validators.required]),
            deliveryLine2: new FormControl(''),
            deliveryTown: new FormControl(''),
            deliveryCounty: new FormControl(''),
            deliveryPostcode: new FormControl('', [Validators.required, FormValidators.postcode]),
            billingLine1: new FormControl('', [Validators.required]),
            billingLine2: new FormControl(''),
            billingTown: new FormControl(''),
            billingCounty: new FormControl(''),
            billingPostcode: new FormControl('', [Validators.required, FormValidators.postcode]),
            deliveryContactName: new FormControl(''),
            deliveryContactNumber: new FormControl('', [FormValidators.phoneNumber])
        });
    }

    ngAfterViewInit() {
        this.getCustomerDetails()
            .then(() => this.getAddresses())
            .then(() => {
                if (this.order) {
                    this.watchChanges();
                    this.loading = false;
                } else {
                    this.basketSubscription = this.basketService.basket$.subscribe((basket) => {
                        if (basket) {
                            this.watchChanges();
                            this.loading = false;
                        }
                    });
                }
            })
            .catch((error) => {
                this.loading = false;
                this.dialogService.error(this.constructor.name, error);
            });
    }

    ngOnDestroy() {
        if (this.basketSubscription) {
            this.basketSubscription.unsubscribe();
            this.basketSubscription = null;
        }

        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
            this.valueChangesSubscription = null;
        }
    }

    public saveAddress() {
        this.error = false;

        if (this.updateDetails) {
            this.updating = true;

            const billingAddress: IBasketAddress = {
                addressTypeId: AddressType.BILLING,
                line1: StringHelper.clean(this.detailsFormGroup.controls.billingLine1.value),
                line2: StringHelper.clean(this.detailsFormGroup.controls.billingLine2.value),
                town: StringHelper.clean(this.detailsFormGroup.controls.billingTown.value),
                county: StringHelper.clean(this.detailsFormGroup.controls.billingCounty.value),
                // TODO: get custom form group interface working
                postcode: this.detailsFormGroup.controls.billingPostcode['cleanValue'],
                firstName: StringHelper.clean(this.detailsFormGroup.controls.firstName.value),
                lastName: StringHelper.clean(this.detailsFormGroup.controls.lastName.value),
                landline: this.detailsFormGroup.controls.landline['cleanValue'],
                mobile: this.detailsFormGroup.controls.tel['cleanValue']
            };

            const deliveryAddress: IBasketAddress = {
                addressTypeId: AddressType.DELIVERY,
                line1: StringHelper.clean(this.detailsFormGroup.controls.deliveryLine1.value),
                line2: StringHelper.clean(this.detailsFormGroup.controls.deliveryLine2.value),
                town: StringHelper.clean(this.detailsFormGroup.controls.deliveryTown.value),
                county: StringHelper.clean(this.detailsFormGroup.controls.deliveryCounty.value),
                // TODO: get custom form group interface working
                postcode: this.detailsFormGroup.controls.billingPostcode['cleanValue'],
                firstName: StringHelper.clean(this.detailsFormGroup.controls.firstName.value),
                lastName: StringHelper.clean(this.detailsFormGroup.controls.lastName.value),
                landline: this.detailsFormGroup.controls.landline['cleanValue'],
                mobile: this.detailsFormGroup.controls.tel['cleanValue'],

            };

            let queries = [];

            if (
                this.addresses.delivery?.line1 !== deliveryAddress.line1 ||
                this.addresses.delivery?.line2 !== deliveryAddress.line2 ||
                this.addresses.delivery?.county !== deliveryAddress.county ||
                this.addresses.delivery?.town !== deliveryAddress.town ||
                this.addresses.delivery?.postcode !== deliveryAddress.postcode ||
                this.addresses.billing?.line1 !== billingAddress.line1 ||
                this.addresses.billing?.line2 !== billingAddress.line2 ||
                this.addresses.billing?.county !== billingAddress.county ||
                this.addresses.billing?.town !== billingAddress.town ||
                this.addresses.billing?.postcode !== billingAddress.postcode ||
                this.addresses.billing?.firstName !== billingAddress.firstName ||
                this.addresses.billing.lastName !== billingAddress.lastName ||
                this.addresses.billing.landline !== billingAddress.lastName ||
                this.addresses.billing.mobile !== billingAddress.mobile
            ) {
                if (this.order) {
                    queries.push(this.orderService.saveOrderAddresses(billingAddress, deliveryAddress, this.order.orderNumber));
                } else {
                    queries.push(this.basketService.saveAddresses(billingAddress, deliveryAddress));
                }
            }

            Promise.all(queries)
                .then(() => {
                    this.updating = false;
                    this.formGroup.setValue({ complete: true });
                    this.stepper.next();
                })
                .catch((error) => {
                    this.error = true;
                    this.updating = false;
                    this.dialogService.error(this.constructor.name, error);
                });
        } else {
            this.formGroup.setValue({ complete: true });
            this.stepper.next();
        }
    }

    private getCustomerDetails(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.customerService.details()
                .then((result) => {
                    if (result) {
                        this.customerDetails = {
                            firstName: result.firstName,
                            lastName: result.lastName,
                            landline: result.landline,
                            tel: result.tel
                        };

                        if (this.detailsFormGroup) {
                            this.detailsFormGroup.patchValue({
                                firstName: result.firstName,
                                lastName: result.lastName,
                                landline: result.landline,
                                tel: result.tel
                            });
                        }
                    }

                    resolve();
                })
                .catch((error) => reject(error));
        });
    }

    private getAddresses(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.order) {
                this.addresses = {
                    delivery: {
                        line1: this.order.billingAddress1,
                        line2: this.order.billingAddress2,
                        county: this.order.deliveryCounty,
                        town: this.order.deliveryCity,
                        postcode: this.order.deliveryPostcode,
                        addressTypeId: 1,
                        firstName: this.order.firstName,
                        lastName: this.order.lastName,
                        landline: this.order.landline,
                        mobile: this.order.mobile
                    },
                    billing: {
                        line1: this.order.deliveryAddress1,
                        line2: this.order.deliveryAddress2,
                        county: this.order.billingCounty,
                        town: this.order.billingCity,
                        postcode: this.order.billingPostcode,
                        addressTypeId: 2,
                        firstName: this.order.firstName,
                        lastName: this.order.lastName,
                        landline: this.order.landline,
                        mobile: this.order.mobile
                    }
                };

                this.detailsFormGroup.patchValue({
                    firstName: this.order.firstName || this.customerDetails.firstName,
                    lastName: this.order.lastName || this.customerDetails.lastName,
                    landline: this.order.landline || this.customerDetails.landline,
                    tel: this.order.mobile || this.customerDetails.tel,
                    billingLine1: this.order.billingAddress1,
                    billingLine2: this.order.billingAddress2,
                    billingTown: this.order.billingCity,
                    billingCounty: this.order.billingCounty,
                    billingPostcode: this.order.billingPostcode,
                    deliveryLine1: this.order.deliveryAddress1,
                    deliveryLine2: this.order.deliveryAddress2,
                    deliveryTown: this.order.deliveryCity,
                    deliveryCounty: this.order.deliveryCounty,
                    deliveryPostcode: this.order.deliveryPostcode
                });

                resolve();
            } else {
                this.basketService.addresses()
                    .then((response) => {
                        if (response) {
                            const deliveryAddress = response.delivery || null;
                            const billingAddress = response.billing || null;

                            this.addresses = {
                                delivery: deliveryAddress,
                                billing: billingAddress
                            };

                            if (billingAddress) {
                                this.detailsFormGroup.patchValue({
                                    billingLine1: billingAddress.line1,
                                    billingLine2: billingAddress.line2,
                                    billingTown: billingAddress.town,
                                    billingCounty: billingAddress.county,
                                    billingPostcode: billingAddress.postcode,
                                    firstName: billingAddress.firstName || this.customerDetails.firstName,
                                    lastName: billingAddress.lastName || this.customerDetails.lastName,
                                    landline: billingAddress.landline || this.customerDetails.landline,
                                    tel: billingAddress.mobile || this.customerDetails.tel,
                                });
                            }

                            if (deliveryAddress) {
                                this.detailsFormGroup.patchValue({
                                    deliveryLine1: deliveryAddress.line1,
                                    deliveryLine2: deliveryAddress.line2,
                                    deliveryTown: deliveryAddress.town,
                                    deliveryCounty: deliveryAddress.county,
                                    deliveryPostcode: deliveryAddress.postcode,
                                    deliveryContactName: deliveryAddress.deliveryContactName || '',
                                    deliveryContactNumber: deliveryAddress.deliveryContactNumber || ''
                                });
                            }
                        }

                        resolve();
                    })
                    .catch((error) => reject(error));
            }
        });
    }

    private watchChanges() {
        this.valueChangesSubscription = this.detailsFormGroup.valueChanges.subscribe(
            (change) => {
                if (change) {
                    this.deliverToBilling = change.deliverToBilling;

                    if (change.deliverToBilling) {
                        if (change.billingLine1 !== change.deliveryLine1) {
                            this.detailsFormGroup.controls.deliveryLine1.setValue(
                                change.billingLine1
                            );

                            this.updateDetails = true;
                        }

                        if (change.billingLine2 !== change.deliveryLine2) {
                            this.detailsFormGroup.controls.deliveryLine2.setValue(
                                change.billingLine2
                            );

                            this.updateDetails = true;
                        }

                        if (change.billingTown !== change.deliveryTown) {
                            this.detailsFormGroup.controls.deliveryTown.setValue(
                                change.billingTown
                            );

                            this.updateDetails = true;
                        }

                        if (change.billingCounty !== change.deliveryCounty) {
                            this.detailsFormGroup.controls.deliveryCounty.setValue(
                                change.billingCounty
                            );

                            this.updateDetails = true;
                        }

                        if (change.billingPostcode !== change.deliveryPostcode) {
                            this.detailsFormGroup.controls.deliveryPostcode.setValue(
                                change.billingPostcode
                            );

                            this.updateDetails = true;
                        }
                    }
                }

                if (this.detailsFormGroup.pristine) {
                    if (this.detailsFormGroup.valid) {
                        this.updateDetails = false;
                        this.formGroup.setValue({ complete: true });
                    }
                } else {
                    this.updateDetails = true;
                    this.formGroup.setValue({ complete: false });

                    if (this.detailsFormGroup.invalid) {
                        this.formGroup.reset();
                    }
                }
            }
        );
    }
}
