import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';

import { AboutShopOnlineRouting } from './shop-online.routing';
import { AboutShopOnlinePage } from './shop-online.page';

@NgModule({
    imports: [
        SharedModule,
        AboutShopOnlineRouting
    ],
    declarations: [
        AboutShopOnlinePage
    ]
})
export class AboutShopOnlinePageModule { }
