import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { DesignModule } from './design.module';
import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { PipesModule } from './pipes/pipes.module';

import { PageNotFoundComponent } from './page-not-found.component';

@NgModule({
    imports: [
        RouterModule,
        DesignModule,
        PipesModule
    ],
    exports: [
        DesignModule,
        ComponentsModule,
        DirectivesModule,
        PipesModule
    ],
    declarations: [
        PageNotFoundComponent
    ]
})
export class SharedModule { }
