import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

import { Config } from '@app/config';

import { HttpApi } from '@app/services/api';
import { HttpApiOptions } from '@app/services/api/models';

import { AuthService } from './auth.service';
import {
    CustomerUser,
    CustomerUserDetails,
    CustomerRegistration,
    CustomerRegistrationNoPassword,
    Universal,
    UserType
} from './models';

@Injectable()
export class AuthCustomerService {
    public authentication: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public change: Subject<void> = new Subject<void>();

    public get isAuthenticated() {
        return (this.authentication && this.authentication.getValue()) ? this.authentication.getValue() : false;
    }

    public get details(): CustomerUserDetails {
        return (this.customerUser && this.customerUser.userDetails) ? this.customerUser.userDetails : null;
    }

    public get applicationToken(): string {
        return (this.customerUser && this.customerUser.applicationToken) ? this.customerUser.applicationToken : null;
    }

    public get universalToken(): string {
        return (this.customerUser && this.customerUser.universal) ? this.customerUser.universal.token : null;
    }

    public get universalUser(): CustomerUserDetails {
        return <CustomerUserDetails>((this.customerUser && this.customerUser.universal) ? this.customerUser.universal.userDetails : null);
    }

    public get isStaff(): boolean {
        return (this.customerUser && this.customerUser.userDetails && this.customerUser.userDetails.isStaff) ?
            this.customerUser.userDetails.isStaff : false;
    }

    private customerUser: CustomerUser;

    constructor(
        private config: Config,
        private httpApi: HttpApi,
        private authService: AuthService
    ) {
        if (config.auth.type === UserType.CUSTOMER) {
            authService.details.subscribe(
                (details) => {
                    this.customerUser = (details) ? details as CustomerUser : null;

                    this.dataChange();
                }
            );

            authService.authentication.subscribe(
                (authentication) => {
                    this.authentication.next(authentication);

                    this.dataChange();
                }
            );

            authService.loaded.subscribe(
                (loaded) => {
                    if (loaded) {
                        this.validateUser(true)
                            .then(() => {
                                authService.ready.next(true);
                                this.dataChange();
                            })
                            .catch((error) => {
                                authService.ready.next(true);
                                this.dataChange();
                            });
                    }
                }
            );
        }
    }

    public login(email, password): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let url = `${this.config.api.endpoints.universal}/customer/login`;
            let params = { email, password };
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                }
            };

            this.httpApi.post<any>(url, params, options).subscribe({
                next: (response) => {
                    if (response && response.success) {
                        this.processAuthentication(response.body)
                            .then(() => resolve())
                            .catch((error) => reject(error));
                    } else {
                        reject(response.body);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public validateEmail(email: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            let url = `${this.config.api.endpoints.universal}/customer/${email}/validate`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                }
            };

            this.httpApi.get<any>(url, options).subscribe({
                next: (response) => {
                    if (response && response.success) {
                        resolve(response.body.resetPasswordToken);
                    } else {
                        reject(response.body);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public loginWithOrderNumber(email, orderNumber): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let url = `${this.config.api.endpoints.universal}/customer/login-order`;
            let params = { email, orderNumber };
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                }
            };

            this.httpApi.post<any>(url, params, options).subscribe({
                next: (response) => {
                    if (response && response.success) {
                        this.processAuthentication(response.body)
                            .then(() => resolve())
                            .catch((error) => reject(error));
                    } else {
                        reject(response.body);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public logout(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.authService.removeUser()
                .then(() => {
                    this.authService.deactivate();
                    resolve();
                })
                .catch((error) => reject(error));
        });
    }

    public register(customer: CustomerRegistration): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (customer.email) {
                const url = `${this.config.api.endpoints.universal}/customer/register`;
                const params = {
                    email: customer.email,
                    password: customer.password,
                    firstName: customer.firstName,
                    lastName: customer.lastName,
                    contact: customer.contactNumber,
                    marketingConsent: true
                };
                const options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id,
                    }
                };
    
                this.httpApi.post<any>(url, params, options).subscribe({
                    next: (response) => {
                        if (response && response.success) {
                            this.processAuthentication(response.body)
                                .then(() => resolve())
                                .catch((error) => reject(error));
                        } else {
                            reject(response);
                        }
                    },
                    error: (error) => reject(error)
                });
            } else {
                reject('There was an issue trying to validation your email address. If the problem persists, please contact support.');
            }
        });
    }

    public requestPasswordReset(email: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (email) {
                const url = `${this.config.api.endpoints.universal}/customer/reset-password`;
                const params = {
                    email: email
                };
                const options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id,
                    }
                };
    
                this.httpApi.post<any>(url, params, options).subscribe({
                    next: (response) => {
                        if (response && response.success) {
                            resolve();
                        } else {
                            reject(response);
                        }
                    },
                    error: (error) => reject(error)
                });
            } else {
                reject('There was an issue trying to validation your email address. If the problem persists, please contact support.');
            }
        });
    }

    public resetPassword(password: string, resetToken: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const url = `${this.config.api.endpoints.universal}/customer/reset-password/${resetToken}`;
            const params = {
                password: password
            };
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                }
            };

            this.httpApi.post<any>(url, params, options).subscribe({
                next: (response) => {
                    if (response && response.success) {
                        resolve();
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public setUniversalToken(token: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!this.customerUser) {
                this.customerUser = {
                    applicationToken: null,
                    universal: {
                        userDetails: null
                    },
                    userDetails: null
                };
            }

            this.customerUser.universal.token = token;

            this.authService.saveUser(this.customerUser)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    }

    public validateUniversal(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const url = `${this.config.api.endpoints.universal}/authenticate/validate/universal`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UniversalToken: this.universalToken
                }
            };

            this.httpApi.get<any>(url, options).subscribe({
                next: (response) => {
                    if (response && response.success) {
                        this.processAuthentication(response.body)
                            .then(() => resolve())
                            .catch((error) => reject(error));
                    } else {
                        this.authService.deactivate();

                        this.authService.removeUser()
                            .then(() => reject(response.body))
                            .catch((error) => reject(error));
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    private validateUser(ignore: boolean = false): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.applicationToken) {
                let options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id,
                        UserToken: this.applicationToken
                    }
                };

                this.httpApi.get<any>(`${this.config.api.endpoints.universal}/customer/validate`, options).subscribe({
                    next: (response) => {
                        if (response && response.success) {
                            this.processAuthentication(response.body, ignore)
                                .then(() => resolve())
                                .catch((error) => reject(error));
                        } else {
                            this.authService.removeUser()
                                .then(() => reject(response.body))
                                .catch((error) => reject(error));
                        }
                    },
                    error: (error) => reject(error)
                });
            } else {
                this.authService.deactivate();
                reject();
            }
        });
    }

    private validUser(ignore: boolean = false) {
        let valid = false;

        if (this.customerUser) {
            if (this.customerUser.applicationToken && this.universalToken) {
                valid = true;
            }
        }

        if (valid) {
            this.authService.activate(ignore, this.applicationToken);
        } else {
            this.authService.deactivate();
        }
    }

    private processAuthentication(response: any, ignore: boolean = false): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const universal: Universal = (response && response.universal) ? response.universal : null;
            const applicationToken = (response && response.token) ? response.token : null;
            const refreshedToken = (response && response.refreshToken) ? response.refreshToken : null;
            const details: CustomerUserDetails = (applicationToken) ? jwtDecode(applicationToken) : null;

            this.customerUser = {
                applicationToken: refreshedToken || applicationToken,
                universal: {
                    token: refreshedToken || applicationToken,
                    // menu: (universal) ? universal.menu : [],
                    userDetails: (universal) ? universal.userDetails : null
                },
                userDetails: details
            };

            this.authService.saveUser(this.customerUser)
                .then(() => {
                    this.validUser(ignore);
                    resolve();
                })
                .catch((error) => reject(error));
        });
    }

    private dataChange() {
        if (this.authService.ready.getValue() === true) {
            this.change.next();
        }
    }

    public registerWithNoPassword(customer: CustomerRegistrationNoPassword): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (customer.email) {
                const url = `${this.config.api.endpoints.universal}/customer/register-no-password`;
                const params = {
                    email: customer.email,
                    firstName: customer.firstName,
                    lastName: customer.lastName,
                    contact: customer.contactNumber
                };

                const options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id,
                        UserToken: this.universalToken
                    }
                };

                this.httpApi.post<any>(url, params, options).subscribe({
                    next: (response) => {
                        if (response && response.success) {
                            resolve(response.body);
                        } else {
                            reject(response);
                        }
                    },
                    error: (error) => reject(error)
                });
            } else {
                reject('There was an issue trying to validation your email address. If the problem persists, please contact support.');
            }
        });
    }
}
