<section class="contained" *ngIf="entry">
    <div class="image" [promotion]="'unit-detail'" [request]="'listing-overlay-image'" [productCode]="entry.item.unit_code" [item]="entry.item">
        <img [ngClass]="{ 
            flip: (
                (entry.item._hingeSide === Handing.LH && entry.item.handingType !== HandingType.DRAWER) || 
                (entry.item._hingeSide === Handing.RH && entry.item.handingType === HandingType.DRAWER)
            )}" src="{{entry.item.media.image}}" [alt]="entry.item.width + 'mm ' + unit.desc"/>
    </div>
    <div class="details">
        <h1 class="title">{{entry.item.width}}mm {{unit.desc}}</h1>
        <p class="code">{{entry.item.unit_code}}</p>
        <p class="desc">{{entry.item.detail}}</p>
        <p class="desc" *ngIf="additionalItemDescription(entry.item)">{{additionalItemDescription(entry.item)}}</p>
        <ng-container *ngIf="activeRange; else cabOnlyPrice">
            <div class="price" [promotion]="'unit-detail'" [request]="type === ProductType.RANGE_PRODUCTS ? 'door-price' : 'unit-price'" [productCode]="entry.item.unit_code" [item]="entry.item" [softClose]="entry.item.softClose">
                <h2 *ngIf="entry.item._cost" [innerHTML]="entry.item._cost + ((entry.item.softClose && entry.item.hingecost) ? entry.item?.originalHingecost || entry.item.hingecost : 0)  | currencyFormat:true:true"></h2>
                <p *ngIf="!entry.item._cost">Unavailable in this range</p>
            </div>
        </ng-container>
        <ng-template #cabOnlyPrice>
            <div class="price" [promotion]="'unit-detail'" [request]="'cab-price'" [productCode]="entry.item.unit_code" [item]="entry.item" [softClose]="entry.item.softClose">
                <h2 [innerHTML]="entry.item.cab_price || entry.item._cost | currencyFormat:true:true"></h2>
                <p *ngIf="!activeRange && type !== 'Accessories'">Cabinet only price</p>
            </div>
        </ng-template>
        <p>Available widths:</p>
        <div class="widths">
            <a *ngFor="let width of sortWidths(entry.widths)" [routerLink]="width.link">
                <button title="{{width.code}}" [class.selected]="width.code === unit.unit_code">
                    {{width.width}}
                </button>
            </a>
        </div>
        <ng-container *ngIf="variants && variants.length > 1">
            <label>Variants:</label>
            <div class="variants">
                <a *ngFor="let variant of variants" [routerLink]="variantLink(entry, variant)">
                    <button [class.selected]="variant.code === entry.item.unit_code" [title]="variant.code">
                        {{variant.variant}}
                    </button>
                </a>
            </div>
        </ng-container>
        <div *ngIf="type === 'Cabinets'" class="configuration">
            <h2>CONFIGURE UNIT</h2>
            <div class="config-items">
                <div class="item">
                    <label>Cabinet</label>
                    <div class="attribute">
                        <p>Code:</p>
                        <p>{{entry.item.unit_code}}</p>
                    </div>
                    <div *ngIf="entry.item.cab_price" class="price-or-config" [promotion]="'unit-detail'" [request]="'cab-price'" [productCode]="entry.item.unit_code" [item]="entry.item">
                        <p>Price:</p>
                        <p [innerHTML]="entry.item.cab_price | currencyFormat:true:true"></p>
                    </div>
                </div>
                <div class="item" *ngIf="activeRange">
                    <label>Cabinet Colour</label>
                    <div class="attribute">
                        <div [ngClass]="getRangeCarcaseSwatch()"></div>
                        <p>{{ activeRange.carcaseColour }} unit</p>
                        <button mat-flat-button color="primary" (click)="selectRange()">Edit Style</button>
                    </div>
                    <p class="price-or-config">INCLUDED</p>
                </div>
                <div class="item" *ngFor="let component of entry.components">
                    <label *ngIf="component.title">
                        {{component.title}}
                        <mat-icon *ngIf="component.title === 'Filler'" matTooltip="
                        A filler is used to fill any gaps between the cabinet and your chosen appliance. The size of the filler is dependent on the appliance and so the 
                        filler will need to be cut to size on site." 
                        matTooltipPosition="above">
                            info
                        </mat-icon>
                    </label>
                    <label *ngIf="!component.title">Cabinet Component</label>
                    <div class="attribute">
                        <ng-container *ngIf="component.editColour && activeRange; else showDescription">
                            <ng-container *ngIf="component.image; else showSwatch">
                                <img src="{{component.image}}" [alt]="activeRange.range.name + ' ' + activeRange.rangeColour" />
                                <p>{{ activeRange.range.name }} {{ activeRange.rangeColour }}<br />Quantity: {{component.qty}} x {{component.description}}</p>
                            </ng-container>
                            <ng-template #showSwatch>
                                <div class="swatch" [ngClass]="'swatch-' + activeRange.colourDetail.css_class"></div>
                                <p>{{ activeRange.range.name }} {{ activeRange.rangeColour }}<br />Quantity: {{component.qty}} x {{component.description}}</p>
                            </ng-template>
                        </ng-container>
                        <ng-template #showDescription>
                            <img *ngIf="component.image" [src]="component.image" [alt]="component.description" />
                            <p>Quantity: {{component.qty}} x {{component.description}}</p>
                        </ng-template>
                        <button mat-flat-button color="primary" *ngIf="component.editColour" (click)="selectRange()">Edit Style</button>
                    </div>
                    <p *ngIf="!component._cost; else showPrice" class="price-or-config">INCLUDED</p>
                    <ng-template #showPrice>
                        <div class="price-or-config" [promotion]="'unit-detail'" [request]="'door-price'" [productCode]="entry.item.unit_code" [item]="component.door" [qty]="component.qty">
                            <p *ngIf="component._cost" class="price-or-config" [innerHTML]="component.qty * component._cost | currencyFormat:true:true"></p>
                        </div>
                    </ng-template>
                </div>
                <div class="item" *ngIf="entry.item.handed">
                    <label>{{hingeWording(entry.item)}} Side</label>
                    <div class="attribute options">
                        <div class="option">
                            <mat-icon *ngIf="entry.item._hingeSide === Handing.LH" class="selected">check_box</mat-icon>
                            <mat-icon *ngIf="entry.item._hingeSide !== Handing.LH" (click)="setHinge(entry, Handing.LH)">check_box_outline_blank</mat-icon>
                            <span class="label">Left</span>
                        </div>
                        <div class="option">
                            <mat-icon *ngIf="entry.item._hingeSide === Handing.RH" class="selected">check_box</mat-icon>
                            <mat-icon *ngIf="entry.item._hingeSide !== Handing.RH" (click)="setHinge(entry, Handing.RH)">check_box_outline_blank</mat-icon>
                            <span class="label">Right</span>
                        </div>
                    </div>
                    <p class="price-or-config">INCLUDED</p>
                </div>
                <div class="item" *ngIf="entry.item.hingecost">
                    <label>Soft close hinges</label>
                    <div class="attribute options">
                        <div class="option">
                            <mat-icon *ngIf="entry.item.softClose" class="selected">check_box</mat-icon>
                            <mat-icon *ngIf="!entry.item.softClose" (click)="toggleSoft(entry.item)">check_box_outline_blank</mat-icon>
                            <span class="label">Yes</span>
                        </div>
                        <div class="option">
                            <mat-icon *ngIf="!entry.item.softClose" class="selected">check_box</mat-icon>
                            <mat-icon *ngIf="entry.item.softClose" (click)="toggleSoft(entry.item)">check_box_outline_blank</mat-icon>
                            <span class="label">No</span>
                        </div>
                    </div>
                    <div class="price-or-config" [promotion]="'unit-detail'" [request]="'hingecost-price'" [productCode]="entry.item.unit_code" [item]="entry.item">
                        <p [innerHTML]="entry.item.originalHingecost || entry.item.hingecost ?? 0 | currencyFormat:true:true" class="price-or-config"></p>
                    </div>
                </div>
                <div class="item" *ngIf="entry.item.width > 600 && entry.item.depth > 600 && !entry.item.dryAssembledAlways">
                    <label>Dry Assembled
                        <mat-icon matTooltip="
                        Due to the dimensions of this unit, it may prove difficult to fit through the entrance of your property safely. 
                        To prevent damage, we recommend that you order this unit as 'Dry Assembled' as this can then be dismantled on site to fit through narrow entrances." 
                        matTooltipPosition="above">
                            info
                        </mat-icon>
                    </label>
                    <div class="attribute options">
                        <div class="option">
                            <mat-icon *ngIf="entry.item.dryAssembled" class="selected">check_box</mat-icon>
                            <mat-icon *ngIf="!entry.item.dryAssembled" (click)="toggleDryAssembled(entry.item)">check_box_outline_blank</mat-icon>
                            <span class="label">Yes</span>
                        </div>
                        <div class="option">
                            <mat-icon *ngIf="!entry.item.dryAssembled" class="selected">check_box</mat-icon>
                            <mat-icon *ngIf="entry.item.dryAssembled" (click)="toggleDryAssembled(entry.item)">check_box_outline_blank</mat-icon>
                            <span class="label">No</span>
                        </div>
                    </div>
                    <p class="price-or-config">INCLUDED</p>
                </div>
            </div>
        </div>
        <div class="basket-actions">
            <ng-container *ngIf="activeRange || type === 'Accessories'; else selectStyle">
                <mat-form-field class="quantity">
                    <input matInput type="number" min="1" placeholder="Qty" [(ngModel)]="qty" />
                </mat-form-field>
                <component-basket-add-button [activeRange]="activeRange" [type]="type" [item]="entry.item" [qty]="qty"></component-basket-add-button>
                <div class="price" [promotion]="'unit-detail'" [request]="type === ProductType.RANGE_PRODUCTS ? 'door-price' : 'unit-price'" [productCode]="entry.item.unit_code" [item]="entry.item" [softClose]="entry.item.softClose">
                    <h2 *ngIf="entry.item._cost" [innerHTML]="entry.item._cost + ((entry.item.softClose && entry.item.hingecost) ? entry.item?.originalHingecost || entry.item.hingecost : 0)  | currencyFormat:true:true"></h2>
                    <p *ngIf="!entry.item._cost">Unavailable in this range</p>
                </div>
            </ng-container>
            <ng-template #selectStyle>
                <div class="price">
                    <p *ngIf="!activeRange && type !== 'Accessories'">Cabinet only price</p>
                </div>
                <button mat-flat-button color="primary" (click)="selectRange()">Change Door Style</button>
            </ng-template>
        </div>
    </div>
    <div class="specs">
        <section>
            <p><em>SPECIFICATION</em></p>
            <p><label>Cabinet code</label>{{entry.item.unit_code}}</p>
            <p><label>Width</label>{{entry.item.width}} mm</p>
            <p *ngIf="entry.item.leg_needed"><label>Height (with legs)</label>{{entry.item.height+150}} mm</p>
            <p *ngIf="entry.item.leg_needed"><label>Height (without legs)</label>{{entry.item.height}} mm</p>
            <p *ngIf="!entry.item.leg_needed"><label>Height</label>{{entry.item.height}} mm</p>
            <p><label>Depth</label>{{entry.item.depth}} mm</p>
            <p *ngIf="entry.item.leg_needed"><label>Leg height</label>150 mm</p>
        </section>
        <section *ngIf="entry.doors && entry.doors.length > 0">
            <p><em>DOOR DIMENSIONS</em></p>
            <div *ngFor="let door of entry.doors" class="sub-section">
                <p><label>Height</label>{{door.height}} mm</p>
                <p><label>Width</label>{{door.width}} mm</p>
                <!-- TODO: doorThickness does not exist on activeRange.range -->
                <!-- <p *ngIf="activeRange"><label>Thickness</label>{{activeRange.range.doorThickness}} mm</p> -->
            </div>
        </section>
        <section *ngIf="entry.drawers && entry.drawers.length > 0">
            <p><em>DRAWER DIMENSIONS</em></p>
            <div *ngFor="let drawer of entry.drawers" class="sub-section">
                <p><label>Height</label>{{drawer.height}} mm</p>
                <p><label>Width</label>{{drawer.width}} mm</p>
                <!-- TODO: doorThickness does not exist on activeRange.range -->
                <!-- <p *ngIf="activeRange"><label>Thickness</label>{{activeRange.range.doorThickness}} mm</p> -->
            </div>
        </section>
        <section *ngIf="entry.fillers && entry.fillers.length > 0">
            <p><em>FILLER DIMENSIONS</em></p>
            <div *ngFor="let filler of entry.fillers" class="sub-section">
                <p><label>Height</label>{{filler.height}} mm</p>
                <p><label>Width</label>{{filler.width}} mm</p>
                <!-- TODO: doorThickness does not exist on activeRange.range -->
                <!-- <p *ngIf="activeRange"><label>Thickness</label>{{activeRange.range.doorThickness}} mm</p> -->
            </div>
        </section>
        <section *ngIf="entry.item.hole_centre || entry.item._finish">
            <ng-container *ngIf="entry.item.hole_centre">
                <label>Hole centre:</label>
                <div>{{entry.item.hole_centre}}mm</div>
            </ng-container>
            <ng-container *ngIf="entry.item._finish">
                <label>Finish:</label>
                <div>{{entry.item._finish | sentenceCase}}</div>
            </ng-container>
        </section>
        <section *ngIf="entry.item.technicalImageLink">
            <p><em>TECHNICAL IMAGE</em></p>
            <img [cdn]="entry.item.technicalImageLink" alt="Technical image"/>
        </section>
    </div>
</section>
<section class="contained" *ngIf="!entry && loaded">
    <h1>Unit not found</h1>
</section>
