<div class="header-banner">
    <div class="header-tint">
        <div class="contained header">
            <div class="header-right">
                <a routerLink="/" class="logo" title="DIY Kitchens" (click)="closeSearch()" aria-label="Home"></a>
                <section [ngClass]="searchText.length > 0 ? 'searching' : ''">
                    <div>
                        <a routerLink="/support" title="Support">
                            <button mat-button color="primary" matRippleDisabled>
                                Support <mat-icon>chat</mat-icon>
                            </button>
                        </a>
                    </div>
                    <div>
                        <button mat-button color="primary" matRippleDisabled [matMenuTriggerFor]="menuStyle">
                            <mat-icon>palette</mat-icon>
                            Change Door Style
                        </button>
                        <mat-menu #menuStyle="matMenu">
                            <div class="flex-space-between chooseStyleMenu">
                                <div class="flex-item">
                                    <strong>Door Style</strong>
                                    <p> {{!activeRange() ? 'Not Selected' : activeRange()?.range?.name + ' ' + activeRange()?.rangeColour}}</p>
                                </div>
                                <div class="flex-item">
                                    <div style="width: 50px; height: 50px; float: right; margin-left: 20px; border-radius: 2px;"
                                        [ngClass]="'swatch-'+activeRange()?.colourDetail?.css_class">
                                    </div>
                                </div>
                            </div>
                            <div class="flex-space-between chooseStyleMenu">
                                <div class="flex-item">
                                    <strong>Cabinet Colour</strong>
                                    <p>{{(!activeRange()?.carcaseColour) ? 'Not Selected' : activeRange()?.carcaseColour}}</p>
                                </div>
                                <div class="flex-item">
                                    <div style="width: 50px; height: 50px; float: right; margin-left: 20px; border-radius: 2px;"
                                        [ngClass]="carcaseSwatch()">

                                    </div>
                                </div>
                            </div>
                            <div class="flex-space-between">
                                <div class="flex-item">
                                    <button mat-flat-button color="primary" (click)="selectRange()">Change</button>
                                </div>
                            </div>
                        </mat-menu>
                    </div>

                    <div class="action login">
                        <button mat-button color="primary" *ngIf="!isAuthenticated" (click)="login($event)">
                            Sign In <mat-icon>person</mat-icon>
                        </button>
                        <button mat-button color="primary" *ngIf="isAuthenticated" [matMenuTriggerFor]="menu">
                            Welcome {{ universalUser.firstName }} <i class="material-icons">keyboard_arrow_down</i>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item routerLink="/support">Your Account</button>
                            <button mat-menu-item routerLink="/account/baskets">Manage baskets</button>
                            <button mat-menu-item routerLink="/account/orders">View Orders</button>
                            <button mat-menu-item (click)="logout($event)">Logout</button>
                        </mat-menu>
                        <div class="cut-off-timer" *ngIf="cutoffTimer">
                            <span [matTooltip]="'Time Remaining to Edit Order'">{{cutoffTimer}}</span>
                        </div>
                    </div>
                </section>
                <div class="search" [ngClass]="searchText.length > 0 ? 'searching' : ''">
                    <mat-form-field appearance="outline">
                        <input matInput #searchInput type="text" placeholder="SEARCH" [(ngModel)]="searchText"
                            name="product-search" />
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>
                <div class="action basket" (click)="closeSearch()">
                    <button class="basket-view" mat-flat-button color="primary" routerLink="/basket">
                        <span [innerHTML]="balance | currencyFormat:true:true"></span> <mat-icon>shopping_basket</mat-icon>
                    </button>
                    <!-- <div class="vat-toggle" *ngIf="showVatToggle"> -->
                    <div class="vat-toggle">
                        <mat-slide-toggle [checked]="config.settings.includeVat" (change)="toggleVat()">
                            <span *ngIf="config.settings.includeVat">Inc. VAT</span>
                            <span *ngIf="!config.settings.includeVat">Ex. VAT</span>
                        </mat-slide-toggle>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="searchText.length > 0" class="search-results">
            <component-header-search [searchInput]="searchInput" [searchText]="searchText" (closeSearch)="closeSearch()"></component-header-search>
        </div>

        <div class="wrapper contained" (clickOutside)="closeMegaMenu()">
            <div class="header-menu">
                <!-- TODO: this should be a <button> instead of an <a> tag -->
                <a class="header-menu-item" href="/kitchens" (click)="openMegaMenu(MegaMenuCategories.KITCHENS, $event)" title="Kitchens">Kitchens</a>
                <a class="header-menu-item" href="/kitchen-units" (click)="openMegaMenu(MegaMenuCategories.UNITS, $event)" title="Kitchen Units">Units</a>
                <a class="header-menu-item" href="/kitchen-doors" (click)="openMegaMenu(MegaMenuCategories.DOORS, $event)" title="Kitchen Doors">Doors</a>
                <a class="header-menu-item" href="/appliances" (click)="openMegaMenu(MegaMenuCategories.APPLIANCES, $event)" title="Appliances">Appliances</a>
                <a class="header-menu-item" href="/sinks-and-taps" (click)="openMegaMenu(MegaMenuCategories.SINKS_AND_TAPS, $event)" title="Sinks & Taps">Sinks & Taps</a>
                <a class="header-menu-item" href="/worktops" (click)="openMegaMenu(MegaMenuCategories.WORKTOPS, $event)" title="Worktops">Worktops</a>
                <a class="header-menu-item" href="/handles" (click)="openMegaMenu(MegaMenuCategories.HANDLES, $event)" title="Handles">Handles</a>
                <a class="header-menu-item" href="/samples" (click)="openMegaMenu(MegaMenuCategories.SAMPLES, $event)" title="Samples">Samples</a>
                <a class="header-menu-item" href="/accessories" (click)="openMegaMenu(MegaMenuCategories.ACCESSORIES, $event)" title="Accessories">Accessories</a>
                <a href="https://advice.diy-kitchens.com/" rel="noreferrer" target="_blank" title="Kitchen Planning Advice">Advice</a>
                <div class="made-in-britain-logo"></div>
            </div>
            @defer {
                <component-mega-menu [(category)]="megaMenuCategory"></component-mega-menu>
            }
        </div>
    </div>
</div>

<div class="mobile-header">
    <div class="header-tint flex">
        <!-- TODO: this should be a <button> instead of an <a> tag -->
        <a class="menu-icon" href="#" (click)="toggleMenu($event)">
            <mat-icon>menu</mat-icon><br />MENU
        </a>

        <a routerLink="/" (click)="hideMenu()" class="logo" aria-label="Hide Menu"></a>

        <div class="search" [ngClass]="searchText.length > 0 ? 'searching' : ''">
            <mat-form-field appearance="outline">
                <input matInput #searchInputMobile type="text" placeholder="SEARCH" [(ngModel)]="searchText" name="product-search" />
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>

        <div class="search-toggle">
            <button mat-flat-button color="primary" (click)="searchToggle()" aria-label="Search"><mat-icon>search</mat-icon></button>
        </div>

        <div class="action basket">
            <button mat-flat-button class="basket-view" color="primary" routerLink="/basket">
                <span class="label" [innerHTML]="balance | currencyFormat:true:true"></span> <mat-icon>shopping_basket</mat-icon>
            </button>
            <div class="vat-toggle" *ngIf="showVatToggle">
                <mat-slide-toggle [checked]="config.settings.includeVat" (change)="toggleVat()">
                    <span *ngIf="config.settings.includeVat">Inc. VAT</span>
                    <span *ngIf="!config.settings.includeVat">Ex. VAT</span>
                </mat-slide-toggle>
            </div>
        </div>

        <!-- <div class="menu" [class.show]="_showMenu" [class.subbuy]="subMenu === 'buy'"
            [class.subabout]="subMenu === 'about'">
            <div class="menu-tint main">
                <a routerLink="/" (click)="hideMenu()" aria-label="Home">
                    Home <mat-icon>keyboard_arrow_right</mat-icon>
                </a>
                <a routerLink="/kitchens" (click)="hideMenu()">
                    High quality kitchens <mat-icon>keyboard_arrow_right</mat-icon>
                </a>
                <a href="#" (click)="buyMenu($event)">Buy online <mat-icon>keyboard_arrow_right</mat-icon>
                </a>
                <a routerLink="/about-diy-kitchens/price-and-quality-comparison" (click)="hideMenu()">
                    How we compare<mat-icon>keyboard_arrow_right</mat-icon>
                </a>
                <a href="#" (click)="aboutMenu($event)">
                    About us <mat-icon>keyboard_arrow_right</mat-icon>
                </a>
                <a href="#" *ngIf="!hideCustomerLogin" (click)="login($event)">
                    Sign In
                </a>
                <a *ngIf="hideCustomerLogin" routerLink="/account" (click)="hideMenu()">
                    Your Account
                </a>
                <a *ngIf="hideCustomerLogin" routerLink="/account/baskets">
                    Manage baskets
                </a>
                <a *ngIf="hideCustomerLogin" routerLink="/account/orders">
                    View Orders
                </a>
                <a href="#" *ngIf="hideCustomerLogin" (click)="logout($event)">
                    Logout
                </a>
            </div>

            <div class="menu-tint buy-menu">
                <div (click)="mainMenu()">
                    <mat-icon>keyboard_arrow_left</mat-icon> Back
                </div>

                <h5>Buy online</h5>

                <a routerLink="/kitchen-units" (click)="hideMenu()">
                    Units<mat-icon>keyboard_arrow_right
                    </mat-icon>
                </a>
                <a routerLink="/kitchen-doors" (click)="hideMenu()">
                    Doors<mat-icon>keyboard_arrow_right
                    </mat-icon>
                </a>
                <a routerLink="/appliances" (click)="hideMenu()">
                    Appliances<mat-icon>keyboard_arrow_right</mat-icon>
                </a>
                <a routerLink="/sinks-and-taps" (click)="hideMenu()">
                    Sinks & Taps<mat-icon>keyboard_arrow_right
                    </mat-icon>
                </a>
                <a routerLink="/worktops" (click)="hideMenu()">
                    Worktops<mat-icon>keyboard_arrow_right</mat-icon>
                </a>
                <a routerLink="/handles" (click)="hideMenu()">
                    Handles<mat-icon>keyboard_arrow_right</mat-icon>
                </a>
                <a routerLink="/samples" (click)="hideMenu()">
                    Samples<mat-icon>keyboard_arrow_right</mat-icon>
                </a>
                <a routerLink="/accessories" (click)="hideMenu()">
                    Accessories<mat-icon>keyboard_arrow_right</mat-icon>
                </a>
            </div>

            <div class="menu-tint about-menu">
                <div (click)="mainMenu()">
                    <mat-icon>keyboard_arrow_left</mat-icon> Back
                </div>

                <h5>About DIY Kitchens</h5>

                <a routerLink="/about-diy-kitchens" (click)="hideMenu()">
                    About DIY Kitchens <mat-icon>keyboard_arrow_right</mat-icon>
                </a>
                <a routerLink="/delivery-information" (click)="hideMenu()">
                    Delivery information <mat-icon>keyboard_arrow_right</mat-icon>
                </a>
                <a routerLink="/about-diy-kitchens/history" (click)="hideMenu()">
                    Our History <mat-icon>keyboard_arrow_right</mat-icon>
                </a>
                <a routerLink="/about-diy-kitchens/price-and-quality-comparison" (click)="hideMenu()">
                    How We Compare <mat-icon>keyboard_arrow_right</mat-icon>
                </a>
                <a routerLink="/support" (click)="hideMenu()">
                    Contact Us <mat-icon>keyboard_arrow_right</mat-icon>
                </a>
                <a routerLink="/about-diy-kitchens/careers" (click)="hideMenu()">
                    Careers <mat-icon>keyboard_arrow_right</mat-icon>
                </a>
                <a routerLink="/terms-and-conditions" (click)="hideMenu()">
                    Terms & Conditions <mat-icon>keyboard_arrow_right</mat-icon>
                </a>
                <a routerLink="/cookie-policy" (click)="hideMenu()">
                    Cookie Policy <mat-icon>keyboard_arrow_right</mat-icon>
                </a>
                <a routerLink="/privacy-policy" (click)="hideMenu()">
                    Privacy Policy <mat-icon>keyboard_arrow_right</mat-icon>
                </a>
            </div>
        </div> -->

        <!-- Mobile Menu -->
        <!-- <div class="menu" [class.show]="_showMenu">
            <div class="menu-tint main">
                @if (this.mobileMenuLevel === 'subCategory' || this.mobileMenuLevel === 'end') {
                    <div class="sub-menu-wrapper">
                        <button mat-icon-button class="back-button" (click)="previousMenuLevel()">
                            <mat-icon>arrow_back_ios_new</mat-icon>
                        </button>
                        @if (this.mobileMenuLevel === 'subCategory') {
                            <p>{{ this.selectedCategory }}</p>
                        } @else {
                            <p>{{ this.selectedSubCategory }}</p>
                        }
                    </div>
                }
                <div class="menu-item-wrapper">
                    @if (this.mobileMenuLevel === 'category') {
                        @for (category of getMobileCategoryKeys(); track category) {
                            <div class="menu-item" (click)="nextMenuLevel(category)">
                                <div class="menu-item-img">
                                    @if (megaMenuMockData[category]?.subCategories) {
                                        <img [src]="megaMenuMockData[category].subCategories[0].items[0].image.url" [alt]="megaMenuMockData[category].title" loading="lazy" />
                                    } @else {
                                        <img [src]="megaMenuMockData[category].items[0].image.url" [alt]="megaMenuMockData[category].title" loading="lazy" />
                                    }
                                </div>                            
                                <p>{{ megaMenuMockData[category].title }}</p>
                            </div>
                        }
                    } @else if (this.mobileMenuLevel === 'subCategory') {
                        @for (subCategory of getMobileSubCategories(); track subCategory) {
                            <div class="menu-item" (click)="nextMenuLevel(subCategory.name)">
                                <div class="menu-item-img">
                                    @if (subCategory.items && subCategory.items[0].image) {
                                        <img [src]="subCategory.items[0].image.url" [alt]="subCategory.name" loading="lazy" />
                                    } @else if (subCategory.items && subCategory.items[0].colour) {
                                        <div [class]="'swatch swatch-' + subCategory.items[0].colour">&nbsp;</div>
                                    }
                                </div>                            
                                <p>{{ subCategory.name }}</p>
                            </div>
                        }
                    } @else if (this.mobileMenuLevel === 'end') {
                        @for (item of getMobileSubCategoryItems(); track item) {
                            <div class="menu-item" [routerLink]="item.url || item.anchor?.url" (click)="hideMenu()">
                                <div class="menu-item-img">
                                    @if (item.image) {
                                        <img [src]="item.image.url" [alt]="item.name" loading="lazy" />
                                    } @else if (item.colour && item.anchor) {
                                        <div [class]="'swatch swatch-' + item.colour">&nbsp;</div>
                                    }
                                </div>                            
                                <p>{{ item.name }}</p>
                            </div>
                        }
                    }
                </div>
            </div>
            <div class="footer-menu-wrapper">
                <div class="footer-item" *ngIf="!isAuthenticated" (click)="login($event)">
                    <mat-icon>person</mat-icon>
                    Sign In
                </div>
                <div class="footer-item" *ngIf="isAuthenticated" (click)="logout($event)">
                    <mat-icon>person</mat-icon>
                    Log Out
                </div>
                <div class="footer-item" routerLink="/about-diy-kitchens" (click)="hideMenu()">
                    <mat-icon>info</mat-icon>
                    About Us
                </div>
                <div class="footer-item" routerLink="/about-diy-kitchens/price-and-quality-comparison" (click)="hideMenu()">
                    <mat-icon>compare_arrows</mat-icon>
                    Compare Us
                </div>
            </div>              
        </div> -->

        @if (_showMenu) {
            <component-mobile-menu (closeMenuEvent)="_showMenu = false"></component-mobile-menu>
        }

        <!-- Secondary Navigation (Visually hidden but accessible to search engine crawlers) -->
        <!-- <div class="menu">
            <div class="menu-tint main">
                <div class="menu-item-wrapper">
                    <nav class="secondary-navigation" aria-label="Site Navigation">
                        <ul>
                            @for (category of getMobileCategoryKeys(); track category) {
                                <li>
                                    <a [routerLink]="megaMenuMockData[category].viewAll">
                                        {{ megaMenuMockData[category].title }}
                                    </a>
                                </li>
                                @if (megaMenuMockData[category].subCategories) {
                                    @for (subCategory of megaMenuMockData[category].subCategories; track subCategory) {
                                        <ul>
                                            <li>{{ subCategory.name }}</li>
                                            <ul>
                                                @for (item of subCategory.items; track item) {
                                                    <li>
                                                        <a [routerLink]="item.url">
                                                            {{ item.name }}
                                                            @if (item.image) {
                                                                <img [src]="item.image.url" [alt]="item.name" loading="lazy" />
                                                            }
                                                        </a>
                                                    </li>
                                                }
                                            </ul>
                                        </ul>
                                    }
                                } @else if (megaMenuMockData[category].items) {
                                    <ul>
                                        @for (item of megaMenuMockData[category].items; track item) {
                                            <li>
                                                <a [routerLink]="item.url">
                                                    {{ item.name }}
                                                    @if (item.image) {
                                                        <img [src]="item.image.url" [alt]="item.name" loading="lazy" />
                                                    }
                                                </a>
                                            </li>
                                        }
                                    </ul>
                                }
                            }
                        </ul>
                    </nav>
                </div>
            </div>
        </div> -->
        
    </div>

    <div class="header-menu">
        <!-- TODO: this should be a <button> instead of an <a> tag -->
        <a class="header-menu-item" href="/kitchens" routerLink="/kitchens" title="Kitchens">Kitchens</a>
        <a class="header-menu-item" href="/kitchen-units" routerLink="/kitchen-units" title="Kitchen Units">Units</a>
        <a class="header-menu-item" href="/kitchen-doors" routerLink="/kitchen-doors" title="Kitchen Doors">Doors</a>
        <a class="header-menu-item" href="/appliances" routerLink="/appliances" title="Appliances">Appliances</a>
        <a class="header-menu-item" href="/sinks-and-taps" routerLink="/sinks-and-taps" title="Sinks & Taps">Sinks & Taps</a>
        <a class="header-menu-item" href="/worktops" routerLink="/worktops" title="Worktops">Worktops</a>
        <a class="header-menu-item" href="/handles" routerLink="/handles" title="Handles">Handles</a>
        <a class="header-menu-item" href="/samples" routerLink="/samples" title="Samples">Samples</a>
        <a class="header-menu-item" href="/accessories" routerLink="/accessories" title="Accessories">Accessories</a>
        <a href="https://advice.diy-kitchens.com/" rel="noreferrer" target="_blank" title="Kitchen Planning Advice">Advice</a>
    </div>

    <div class="mobile-search-banner animate-top" *ngIf="showMobileSearchBanner">
        <div class="search" [ngClass]="searchText.length > 0 ? 'searching' : ''">
            <mat-form-field appearance="outline">
                <input matInput #searchInputMobileBanner type="text" placeholder="SEARCH" [(ngModel)]="searchText" name="product-search" />
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>
    </div>

    <div *ngIf="searchText.length > 0" class="search-results">
        <component-header-search [searchInput]="(showMobileSearchBanner) ? null : searchInputMobile" [searchText]="searchText" (closeSearch)="closeSearch()"></component-header-search>
    </div>
</div>

<div class="sub-menu normal">
    <div class="header-menu">
        <a routerLink="/delivery-information" title="Nationwide delivery">Nationwide delivery</a>
        <a routerLink="/about-diy-kitchens/price-and-quality-comparison" title="How we compare">How we compare</a>
        <a routerLink="/samples" title="Samples">Free samples</a>
        <a routerLink="/about-diy-kitchens/showroom" title="Visit our showroom">Visit our Showroom</a>
        <a routerLink="/about-diy-kitchens/price-promise" title="See our price promise">Price promise</a>
        <a routerLink="/support" title="Expert support 7 days a week">7 day expert support</a>
    </div>
</div>

<div class="breadcrumbs-bar contained">
    <component-breadcrumbs *ngIf="showBreadcrumb"></component-breadcrumbs>
</div>
