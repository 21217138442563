<section class="dark accessories-header-bg">
    <div class="contained">
        <h3>Open Basket</h3>
        <div *ngIf="error">
            <div>{{ error }}</div>
        </div>
        <div *ngIf="showLogin">
            Need to log in to open basket
     
            <button mat-stroked-button color="primary" (click)="login()">
                <ng-container>Sign In</ng-container>
            </button>
        </div>
    </div>
</section>