@import '/src/theme/variables';

component-page-checkout-order {
    button {
        font-size: 0.9em;
    }

    .important-notice-section {
        padding: 43px 48px;
        gap: 48px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 4px;

        &.successful {
            background: rgb(5, 131, 58);
        }

        &.failed {
            background: rgba(255, 83, 83, 1);
        }

        .mat-icon {
            font-size: 102px;
            width: 114px;
            height: 102px;
            color: #ffffff;
        }

        .imp-notice-content {
            h3 {
                margin-bottom: 8px;
                font-family: 'Lato';
                font-size: 21px;
                font-weight: 700;
                text-align: left;
                letter-spacing: 0.05em;
                line-height: 32px;
                color: #ffffff;
                margin-top: 0;
            }

            p {
                font-family: 'Lato', sans-serif;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.9);
                text-align: left;
                letter-spacing: 0.03em;
                line-height: 28px;
                font-weight: 400;
                margin: 0;
            }
        }
    }

    .section-row {
        margin-bottom: 3em;
    }

    .intro {
        h1 {
            margin-bottom: 0;
            color: color('black');
            font-size: 2.5em;
            font-weight: bold;
            line-height: 2.375em;
            letter-spacing: 0.18em;
            text-align: left;
            text-transform: uppercase;
        }

        p {
            margin-bottom: 0;
        }
    }

    .payment-table {
        width: 100%;
        border-collapse: collapse;
        margin: 15px 0;

        thead {
            tr {
                th {
                    font-size: 12px;
                    color: #000000;
                    font-weight: 700;
                    background-color: #E1E1E1;
                    border: 1px solid #000000;
                    padding: 10px;
                }
            }
        }

        tbody {
            tr {
                td {
                    border: 1px solid #CCCCCC;
                    padding: 10px;
                    font-size: 12px;
                    color: #000000;
                    font-weight: 400;
                    word-break: break-all;
                    background-color: #ffffff;
                }

                &.info-row {
                    td {
                        &.header {
                            background-color: #E1E1E1;
                            border: 1px solid #000000;
                            font-size: 13px;
                        }
                    }
                }

                &.sub-total-row {
                    td {
                        &.header {
                            background-color: #E1E1E1;
                            border: 1px solid #000000;
                            font-size: 14px;
                            font-weight: 700;
                            text-align: right;
                        }
                    }

                    .total-info {
                        background-color: #ffffff;
                        border: none;
                        border-left: 1px solid #CCCCCC;
                        font-size: 14px;
                        font-weight: 700;
                        text-align: right;
                    }

                    &:last-child {
                        .total-info {
                            border-bottom: 1px solid #CCCCCC;
                        }
                    }
                }
            }
        }
    }
}
