<mat-dialog-content class="kitchen-layout-dialog-content">
	<div class="kitchen-layout-header">
		<h5>{{style}} - {{layoutName}} Kitchen Layout</h5>
		<div class="close" (click)="close()">
			<mat-icon>close</mat-icon>
		</div>
	</div>

	<div class="lightgrey kitchen-layout-content">
		<div class="content-left">
			<p>What's included in this kitchen layout?</p>

			<ol *ngIf="items && items.length">
				<li *ngFor="let item of items">{{item.description}} <span *ngIf="item.code">({{item.code}})</span></li>
			</ol>

			<p>Appliances, worktops, sinks &amp; taps are <u>NOT</u> included in the pricing.</p>
			<p><strong>Please note:</strong> Image represents layout of the units <u>NOT</u> the style.</p>
		</div>

		<div class="content-right">
			<img *ngIf="layoutImage" [src]="layoutImage" width="100%" [alt]="layoutName + ' Kitchen Layout'"/>

			<div class="price-container">
				<div class="layout-name">{{layoutName}} Kitchen in {{style}}:</div>
				<div class="layout-price" *ngIf="layoutData && layoutData.price" [innerHTML]="layoutData.price | currencyFormat:true:true"></div>
			</div>
		</div>
	</div>
</mat-dialog-content>