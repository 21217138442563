import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { NavigationService } from '@app/services/navigation';
import { RouteHistory } from '@app/services/navigation/models';

@Component({
    selector: 'page-about-careers',
    templateUrl: './careers.page.html',
    styleUrls: ['./careers.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AboutCareersPage implements OnInit {
    public brand: string;

    private routeHistory: RouteHistory[] = [
        { title: 'About Diy Kitchens', route: '/about-diy-kitchens' },
        { title: 'Careers', route: '/about-diy-kitchens/careers' }
    ];

    constructor(
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: 'Careers at DIY Kitchens',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'description', content: 'We are a family run business that has been manufacturing and supplying high specification kitchens to both trade and retail customers for nearly 30 years.' }
            ],
            routeHistory: this.routeHistory
        });
    }
}
