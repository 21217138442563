import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';
import { PipesModule } from '@app/pipes';

import { BasketAddButtonComponent } from './basket-add-button/basket-add-button.component';
import { BasketUpdateButtonComponent } from './basket-update-button/basket-update-button.component';
import { BasketAddRegisterFirstDialogComponent } from './basket-add-button/dialogs/register-first';

@NgModule({
    imports: [
        DesignModule,
        PipesModule
    ],
    exports: [
        BasketAddButtonComponent,
        BasketUpdateButtonComponent
    ],
    declarations: [
        BasketAddButtonComponent,
        BasketUpdateButtonComponent,
        BasketAddRegisterFirstDialogComponent
    ]
})
export class BasketComponentModule { }
