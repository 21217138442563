import {
    Component, ViewEncapsulation, Input, Output, EventEmitter,
    OnInit, OnDestroy, OnChanges, SimpleChange
} from '@angular/core';
import { Subscription } from 'rxjs';

import { StringHelper, URLHelper } from '@app/utilities/helpers';

import { CatalogueService } from '@app/services/catalogue';
import { ActiveRange, Layouts } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute } from '@app/services/navigation/models';

@Component({
    selector: 'component-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class FiltersComponent implements OnInit, OnChanges, OnDestroy {
    @Input() items: any[];
    @Input() sectionURL: string;
    @Input() sectionCats: any[];
    @Input() section: string;
    @Input() filters: any[] = [];
    @Input() showRange: boolean = false;
    @Input() showSort: boolean = true;
    @Input() sortOrder: string = '';

    @Output() filterChange = new EventEmitter<any[]>();

    private categoryTitle: string;
    public fixFilters: boolean = false;
    private filtersElmTop: number = 0;
    public showFilters: boolean = false;
    private subCategory: string;

    public activeRange: ActiveRange;
    public activeFilters = [];
    private filteredResults = [];

    public filterCount: number = 0;
    public filteredCount: number = 0;

    public onlyCategoryFilter: boolean = false;

    private routeSubscription: Subscription;
    private activeRangeSubscription;

    public selectedWidths: string[] = [];
    public selectedHeights: string[] = [];
    public selectedDepths: string[] = [];

    constructor(
        private urlHelper: URLHelper,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                this.subCategory = route.params.subCategory || null;
            }
        );

        this.activeRangeSubscription = this.catalogueService.activeRange$.subscribe((activeRange) => {
            this.activeRange = activeRange;
        });
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }

        if (this.activeRangeSubscription) {
            this.activeRangeSubscription.unsubscribe();
            this.activeRangeSubscription = null;
        }
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        this.initFilters();
    }

    public toggleFilters() {
        this.showFilters = !this.showFilters;
    }

    private initFilters() {
        let products = this.items;
        if (products && products[0]) {
            let firstItem = products[0].item || products[0];
            this.categoryTitle = firstItem.categories ?
                firstItem.categories.category :
                StringHelper.titleCase(StringHelper.dashToSpace(firstItem.categoryName || ''));
            let catFilter = { title: 'Category', opts: [] };
            if (this.sectionCats) {
                this.sectionCats.map((cat) => {
                    const routeEnd = cat.name.toLowerCase().replace(/ /g, '-').replace('all-styles', 'allstyles');
                    let routeSuffix = '';
                    if (
                        this.sectionURL !== 'samples/solid-surface-worktop-samples' &&
                        (cat.name === 'Granite' ||
                            cat.name === 'Dekton' ||
                            cat.name === 'Quartz' || 
                            cat.name === 'Ceramic'
                        )
                    ) {
                        routeSuffix = '-worktops';
                    }

                    catFilter.opts.push({
                        title: cat.title,
                        name: cat.name,
                        count: cat.count,
                        tick: this.section === routeEnd,
                        route: `/${this.sectionURL}/${routeEnd}${routeSuffix}`
                    });
                });
            }
            this.filters.map(filter => {
                filter.opts = [];
            });
            this.filters.unshift(catFilter);
            const addFilterOption = (filter, value, rank, cssClass, product) => {
                if (value || filter.field === 'subCategory') {
                    let token = filter.token || '';
                    let exists = (filter.field === 'subCategory')
                        ? filter.opts.filter(opt => opt.value === product.subCategoryLink)
                        : filter.opts.filter(opt => opt.name === value + token);
                    if (exists.length > 0) {
                        exists[0].count++;
                    } else {
                        if (filter.field === 'subCategory') {
                            let productRoute = this.urlHelper.route(product);
                            const route = productRoute.fullRoute.replace(`/${productRoute.routeEnd}`, '');
                            filter.opts.push({
                                name: product.subCategoryName,
                                value: product.subCategoryLink,
                                count: 1,
                                rank: rank,
                                css_class: cssClass,
                                tick: filter.preselected ? filter.preselected === product.subCategoryLink : false,
                                route: route
                            });
                        } else if (filter.field === 'specific_category' && this.subCategory) {
                            if (product.subcat.toLowerCase() === this.subCategory.toLowerCase().replace(/ /g, '-')) {
                                filter.opts.push({
                                    name: value + token,
                                    value: value,
                                    count: 1,
                                    rank: rank,
                                    css_class: cssClass,
                                    tick: filter.preselected ? filter.preselected === value.toLowerCase().replace(/ /g, '-') : false
                                });
                            }
                        } else {
                            if (filter.field === 'subcat') {
                                let productRoute = this.urlHelper.route(product);
                                const route = productRoute.fullRoute.replace(`/${productRoute.routeEnd}`, '');
                                filter.opts.push({
                                    name: value + token,
                                    value: value,
                                    count: 1,
                                    rank: rank,
                                    css_class: cssClass,
                                    tick: filter.preselected ? filter.preselected === value.toLowerCase().replace(/ /g, '-') : false,
                                    route: route
                                });
                            } else {
                                filter.opts.push({
                                    name: value + token,
                                    value: value,
                                    count: 1,
                                    rank: rank,
                                    css_class: cssClass,
                                    tick: filter.preselected ? filter.preselected === value.toLowerCase().replace(/ /g, '-') : false
                                });
                            }
                        }
                    }
                }
            };
            products.map(entry => {
                let product = entry.item || entry;
                this.filters.map(filter => {
                    if (filter.title === 'Width' && entry.widths) {
                        entry.widths.map(width => {
                            addFilterOption(filter, width.width, null, null, null);
                        });
                    } else {
                        if (filter.field) {
                            if (Array.isArray(product[filter.field])) {
                                product[filter.field].forEach(elm => addFilterOption(filter, elm, null, null, null));
                            } else {
                                const value = (product[filter.field] && product[filter.field] !== '0') ? product[filter.field] : null;
                                if (['Width', 'Height', 'Depth'].includes(filter.title)) {
                                    const subCatFilterSelected = this.filters.find((filter) => filter.title.match(/Sub Category/i) && filter.preselected);
                                    if (subCatFilterSelected && product[subCatFilterSelected.field] && 
                                        StringHelper.spaceToDash(product[subCatFilterSelected.field]).match(subCatFilterSelected.preselected)) {
                                        addFilterOption(filter, value, filter.rankField ? product[filter.rankField] : null, null, product);
                                    } else if (!subCatFilterSelected) {
                                        addFilterOption(filter, value, filter.rankField ? product[filter.rankField] : null, null, product);
                                    }
                                } else {
                                    addFilterOption(filter, value, filter.rankField ? product[filter.rankField] : null, null, product);
                                }
                            }
                        } else if (filter.objectArrayField) {
                            product[filter.objectArrayField.field].forEach(obj => {
                                addFilterOption(filter, obj[filter.objectArrayField.subField],
                                    filter.objectArrayField.rankField ? obj[filter.objectArrayField.rankField] : null, obj[filter.objectArrayField.cssField], null);
                            });
                        }
                    }
                });
            });
            this.activeFilters = this.filters.filter(filter => filter.opts.length > 1);
            this.activeFilters.forEach(filter => {
                if (filter.preselected) {
                    filter.initOpen = true;
                }
                if (filter.opts[0].value !== undefined) {
                    filter.opts.sort((a, b) => {
                        let aValue = (a.rank !== undefined && a.rank !== null) ? a.rank : a.value;
                        let bValue = (b.rank !== undefined && b.rank !== null) ? b.rank : b.value;
                        if (typeof aValue === 'number') {
                            return aValue - bValue;
                        }
                        if (aValue !== null) {
                            return aValue.localeCompare(bValue);
                        }

                        return a.value.localeCompare(b.value);
                    });
                }
            });
            if (!this.activeFilters.find(filter => filter.initOpen)) {
                this.activeFilters[0].initOpen = true;
            }

            this.filterItems();
        }
    }

    public clickOption(option) {
        if (option.route) {
            const route = !option.tick ? option.route : option.route.replace(/\/[^/]*$/, '');
            this.navigationService.navigate([route])
        } else {
            option.tick = !option.tick;
            this.filterItems();
        }
    }

    public updateOrder() {
        const rangePrice = (range) => {
            if (range.guidePrices[range.selectedColour.colour]) {
                return range.guidePrices[range.selectedColour.colour][Layouts.L_SHAPED].price;
            }

            return 0;
        };
        switch (this.sortOrder) {
            default: // popularity
                // TODO - replace category ranks with real popularity data
                this.filteredResults.sort((a, b) => {
                    let itemA = a.item || a;
                    let itemB = b.item || b;
                    if (a.maxPopularity !== undefined) {
                        return b.maxPopularity - a.maxPopularity;
                    }
                    if (itemA.rank !== itemB.rank) {
                        return itemA.rank - itemB.rank;
                    }
                    if (itemA.catRank !== itemB.catRank) {
                        return itemA.catRank - itemB.catRank;
                    }
                    if (itemA.subCatRank !== itemB.subCatRank) {
                        return itemA.subCatRank - itemB.subCatRank;
                    }
                    if (itemA.specificCategoryRank !== itemB.specificCategoryRank) {
                        return itemA.specificCategoryRank - itemB.specificCategoryRank;
                    }

                    return itemA.cost - itemB.cost;
                });
                break;
            case 'low-high':
                if ((this.filteredResults[0].item || this.filteredResults[0]).guidePrices) { // Ranges
                    this.filteredResults.sort((a, b) => {
                        let priceA = rangePrice(a.item || a);
                        let priceB = rangePrice(b.item || b);

                        return priceA - priceB;
                    });
                } else {
                    this.filteredResults.sort((a, b) => {
                        let itemA = a.item || a;
                        let itemB = b.item || b;
                        if (itemA.cost) {
                            return itemA.cost - itemB.cost;
                        }

                        return itemA.price - itemB.price;
                    });
                }
                break;
            case 'high-low':
                if ((this.filteredResults[0].item || this.filteredResults[0]).guidePrices) { // Ranges
                    this.filteredResults.sort((a, b) => {
                        let priceA = rangePrice(a.item || a);
                        let priceB = rangePrice(b.item || b);

                        return priceB - priceA;
                    });
                } else {
                    this.filteredResults.sort((a, b) => {
                        let itemA = a.item || a;
                        let itemB = b.item || b;
                        if (itemA.cost) {
                            return itemB.cost - itemA.cost;
                        }

                        return itemB.price - itemA.price;
                    });
                }
                break;
            case 'a-z':
                this.filteredResults.sort((a, b) => {
                    let itemA = a.item || a;
                    let itemB = b.item || b;
                    if (itemA.title) {
                        return itemA.title.localeCompare(itemB.title);
                    } else if (itemA.name) {
                        return itemA.name.localeCompare(itemB.name);
                    }
                });
                break;
            case 'z-a':
                this.filteredResults.sort((a, b) => {
                    let itemA = a.item || a;
                    let itemB = b.item || b;

                    if (itemA.title) {
                        return itemB.title.localeCompare(itemA.title);
                    } else if (itemA.name) {
                        return itemB.name.localeCompare(itemA.name);
                    }
                });
                break;
        }
        this.filterChange.emit(this.filteredResults);
    }

    private filterItems() {
        let activeWidths = [];
        let activeHeights = [];
        let activeDepths = [];
        let categorySelected = false;

        this.activeFilters.forEach((filter) => {
            filter.opts.forEach((option) => {
                if (option.tick) {
                    if (filter.title === 'Width') {
                        activeWidths.push(option.value);
                    } else if (filter.title === 'Height') {
                        activeHeights.push(option.value);
                    } else if (filter.title === 'Depth') {
                        activeDepths.push(option.value);
                    }

                    if (!option.name.match(/^all /i)) {
                        categorySelected = true;
                    }
                }
            });
        });

        this.filteredResults = this.items.filter((entry) => {
            let item = entry.item || entry;
            let itemCategory = item.categories ? item.categories.category : item.categoryLink;
            // if (categorySelected && itemCategory !== this.categoryTitle) {
            //     return false;
            // }

            let include = true;
            this.filterCount = 0;

            this.activeFilters.forEach((filter) => {
                if (filter.title !== 'Category') {
                    let tested = false;
                    let passed = false;

                    filter.opts.forEach((option) => {
                        if (option.tick) {
                            this.filterCount++;
                            tested = true;

                            if (filter.title === 'Width' && this.sectionURL === 'kitchen-units') {
                                let matchingWidths = entry.widths.filter((width) => option.value === width.width);
                                if (matchingWidths.length > 0) {
                                    passed = true;
                                    // Also select the filtered width
                                    this.catalogueService.getUnitAndDoor(matchingWidths[0].variants[0].code)
                                        .then((unit) => {
                                            if (unit) {
                                                entry.item = unit;
                                            }
                                        })
                                        .catch((error) => this.dialogService.error(this.constructor.name, error));
                                }
                            } else {
                                if (filter.field) {
                                    const filterField = (filter.field === 'subCategory') ? 'subCategoryLink' : filter.field;
                                    if (Array.isArray(item[filterField])) {
                                        if (item[filterField].indexOf(option.value) !== -1) {
                                            passed = true;
                                        }
                                    } else if (item[filterField] === option.value) {
                                        passed = true;
                                    }
                                } else if (filter.objectArrayField) {
                                    let match = item[filter.objectArrayField.field]
                                        .filter(obj => obj[filter.objectArrayField.subField] === option.value);
                                    if (match.length > 0) {
                                        passed = true;
                                    }
                                }
                            }
                        }
                    });

                    if (tested && !passed) {
                        include = false;
                    }
                }
            });
            return include;
        });

        this.filteredCount = this.filteredResults.length;
        if (this.filteredCount) {
            this.updateOrder();
        } else {
            this.filterChange.emit([]);
        }

        if (this.activeFilters && this.activeFilters.length) {
            let totalFilters = 0;
            this.activeFilters.forEach((filter) => {
                if (
                    filter.title !== 'Category' &&
                    filter.title !== 'Width' &&
                    filter.title !== 'Height' &&
                    filter.title !== 'Depth'
                ) {
                    if (filter.opts && filter.opts.length) {
                        totalFilters++;
                    }
                }
            });

            this.onlyCategoryFilter = (totalFilters === 0) ? true : false;
        }

        this.selectedWidths = activeWidths.map(value => value + 'mm');
        this.selectedHeights = activeHeights.map(value => value + 'mm');
        this.selectedDepths = activeDepths.map(value => value + 'mm');
    }

    public selectRange() {
        this.catalogueService.openRangeSelector();
    }

    public getFilterPills(filterName: string, filterValue: string) {
        if (filterName.toLowerCase() === 'sub category') {
            return filterValue;
        } else {
            return `${filterName} : ${filterValue}`;
        }
    }

    public hasSizeFilters() {
        return this.activeFilters.filter(a => a.title == 'Height' || a.title == 'Width' || a.title == 'Depth').length
    }

    public getRangeDoorImage() {
        return `https://static.diy-kitchens.com/doors/${(this.activeRange?.colourDetail?.door_image) ? this.activeRange?.colourDetail?.door_image : 'clayton_alabaster_straight_on_500px.jpg'}`;
    }

    // @HostListener('window:scroll', ['$event'])
    // checkScroll() {
    //     if (this.filtersElmTop) {
    //         let scrollPosition = window.pageYOffset;
    //         this.fixFilters = (scrollPosition > this.filtersElmTop);
    //     } else {
    //         let elm = document.getElementById('filters-component');
    //         if (elm) {
    //             let bodyRect = document.body.getBoundingClientRect();
    //             let elemRect = elm.getBoundingClientRect();
    //             this.filtersElmTop = elemRect.top - bodyRect.top + 15;
    //         }
    //     }
    // }
}
