import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { SolidSurfacesRouting } from './solid-surfaces.routing';
import { SolidSurfacesPage } from './solid-surfaces.page';

@NgModule({
    imports: [
        SharedModule,
        SolidSurfacesRouting
    ],
    declarations: [
        SolidSurfacesPage
    ]
})
export class SolidSurfacesPageModule { }
