import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CheckoutPage } from './checkout.page';

import { CheckoutOrderPage } from './checkout-order/checkout-order.page';
import { ChangeOrderAddressPage } from './change-order-address/change-order-address.page';

import { PageNotFoundComponent } from '@app/shared/page-not-found.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: CheckoutPage
            },
            {
                path: 'order/address/:orderNumber',
                component: ChangeOrderAddressPage,
            },
            {
                path: 'order/gateway-error/:paymentId',
                component: CheckoutOrderPage,
            },
            {
                path: 'order/:orderNumber',
                component: CheckoutOrderPage
            },
            {
                path: 'order/:orderNumber/:paymentResult',
                component: CheckoutOrderPage
            },
            { path: '**', component: PageNotFoundComponent }
        ])
    ],
    exports: [RouterModule]
})
export class CheckoutRouting { }
