import { Directive, ElementRef, Input, OnInit, OnChanges } from '@angular/core';
import { Config } from '@app/config';
import { CatalogueService } from '@app/services/catalogue';
import { DialogService } from '@app/services/dialog';

import { BasketItem } from '@app/services/basket/models';
@Directive({
    selector: '[safeSrc]',
    standalone: false
})
export class BasketImageDirective implements OnInit, OnChanges {
    @Input('safeSrc') url: string;
    @Input() item: BasketItem;

    private legacyData: {
        ccode: string;
        eq_code: string;
        style: string;
        category_thumbnail: string;
        cimageurl: string;
        brand_logo: string;
    }[];

    private noImage = '';

    constructor(
        private config: Config,
        private element: ElementRef,
        private catalogueService: CatalogueService,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        this.catalogueService.getLegacyImageCatalogue()
            .then((imageCatalogue) => {
                this.legacyData = imageCatalogue as unknown as {
                    ccode: string;
                    eq_code: string;
                    style: string;
                    category_thumbnail: string;
                    cimageurl: string;
                    brand_logo: string;
                }[];
                this.addImg();
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    ngOnChanges() {
        if (this.legacyData) {
            this.addImg();
        }
    }

    private addImg(): void {
        this.noImage = `${this.config.api.endpoints.cdn}/assets/images/products/misc/cimageurl/noimage.jpg`;

        const img = new Image();

        if (this.url) {
            img.onload = () => {
                if (this.element.nativeElement.nodeName === 'IMG') {
                    this.element.nativeElement.src = this.url;
                } else {
                    this.element.nativeElement.style.backgroundImage = `url('${this.url}')`;
                }
            };
    
            img.onerror = () => {
                this.addFallbackImage(this.item);
            };
            img.src = this.url;
        }
    }

    private addFallbackImage(item: BasketItem): void {
        let url = this.noImage;

        if (item && item.code) {
            const matchingProduct = this.legacyData.find((product) => product.ccode === item.code);
        
            if (matchingProduct) {
                url = matchingProduct.cimageurl || matchingProduct.category_thumbnail || this.noImage;
            } else {
                if (item.range && item.colour) {
                    const style = `${item.range} ${item.colour}`;
                    const rangeProduct = this.legacyData.find((product) => product.eq_code === item.code && product.style === style);
                    if (rangeProduct) {
                        url = rangeProduct.cimageurl || rangeProduct.category_thumbnail || this.noImage;
                    }
                }
            }
        }
                
        const img = new Image();
        img.onload = () => {
            if (this.element.nativeElement.nodeName === 'IMG') {
                this.element.nativeElement.src = url;
            } else {
                this.element.nativeElement.style.backgroundImage = `url('${url}')`;
            }
        };

        img.onerror = () => {
            if (this.element.nativeElement.nodeName === 'IMG') {
                this.element.nativeElement.src = this.noImage;
            } else {
                this.element.nativeElement.style.backgroundImage = `url('${this.noImage}')`;
            }
        };
        img.src = url;
    }
}
