import { Component, ViewEncapsulation, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Subscription } from 'rxjs';

import { DateHelper } from '@app/utilities/helpers';

import { AuthService } from '@app/services/auth';
import { BasketService } from '@app/services/basket';
import { Basket, IBasketDeliveryAccess } from '@app/services/basket/models';
import { DeliveryType } from '@app/services/checkout/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';
import { StorageService } from '@app/services/storage';

@Component({
    selector: 'component-page-checkout',
    templateUrl: './checkout.page.html',
    styleUrls: ['./checkout.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CheckoutPage implements OnInit, OnDestroy {
    @ViewChild(MatStepper) stepper: MatStepper;

    public loaded: boolean = false;
    public accessSurchargeCheckRequired: boolean = false;

    public basket: Basket = null;
    public isSampleOrder: boolean = false;

    public termsFormGroup: FormGroup = this.formBuilder.group({
        accepted: new FormControl(false, [Validators.requiredTrue])
    });
    public detailsFormGroup: FormGroup = this.formBuilder.group({
        complete: new FormControl(false, [Validators.requiredTrue])
    });
    public deliveryFormGroup: FormGroup = this.formBuilder.group({
        level: new FormControl(null, [Validators.required]),
        complete: new FormControl(false, [Validators.requiredTrue])
    });
    public accessFormGroup: FormGroup = this.formBuilder.group({
        complete: new FormControl(false, [Validators.requiredTrue])
    });
    public selectedAccess: IBasketDeliveryAccess;
    public summaryFormGroup: FormGroup = this.formBuilder.group({
        complete: new FormControl(false, [Validators.requiredTrue])
    });

    private lastStep: number = 0;

    private basketSubscription: Subscription = null;
    private routeSubscription: Subscription = null;
    private routeHistory: RouteHistory[] = [{ title: 'Checkout', route: '/checkout' }];
    private paymentCacheKey = 'payment-gateway-initiated';

    constructor(
        public authService: AuthService,
        private basketService: BasketService,
        private dialogService: DialogService,
        private formBuilder: FormBuilder,
        private navigationService: NavigationService,
        private storageService: StorageService
    ) {
        navigationService.setNavigation({
            title: 'Checkout',
            metaTags: [{ name: 'no-meta', content: 'no metadata' }],
            routeHistory: this.routeHistory
        });
    }

    ngOnInit() {        
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                const queryParams = route?.query || null;
                if (queryParams && typeof queryParams === 'object') {
                    if (queryParams['autoorder'] && typeof queryParams['autoorder'] === 'string' && queryParams['autoorder'].length) {
                        const orderNumber = queryParams['autoorder'];
                        if (orderNumber[0] === 'P') {
                            (window as any).location.href = 'https://planner.diy-kitchens.com/basket/checkout';
                        } else {
                            this.navigationService.navigate([`/checkout/order/${orderNumber}/success`]);
                        }
                    } else {
                        if (route.url.includes('/myorder/default.asp')) {
                            this.navigationService.navigate(['/support']);
                        }
                    }
                }
            }
        );
        
        this.basketSubscription = this.basketService.basket$.subscribe((basket) => {
            if (basket && basket.uuid) {
                this.storageService.get(this.paymentCacheKey)
                    .then((paymentInProgress) => {
                        if (paymentInProgress && paymentInProgress.expiry > DateHelper.now()) {
                            Promise.all([
                                this.storageService.remove(this.paymentCacheKey),
                                this.basketService.clearAll(true)    
                            ])
                                .then(() => this.navigationService.navigate([paymentInProgress.redirect]))
                                .catch((error) => this.dialogService.error(this.constructor.name, error))
                        } else {
                            this.basket = basket;
                            this.isSampleOrder = this.basketService.isSampleOnlyBasket(this.basket);
                            if (this.isSampleOrder) {
                                this.termsFormGroup.controls.accepted.setValue(true);
                            }

                            this.loaded = true;

                            if (!(!this.basket.cutOffDate ? true : basket.cutOffDate > DateHelper.now())) {
                                this.navigationService.navigate(['/basket']);

                                return;
                            }

                            this.accessSurchargeCheckRequired = this.basket.deliveryLevel === DeliveryType.STANDARD;
                        }
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            }
        });            
    }

    ngOnDestroy() {
        if (this.basketSubscription) {
            this.basketSubscription.unsubscribe();
            this.basketSubscription = null;
        }

        if(this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }

    public stepperUpdate(event) {
        if (this.lastStep < event.selectedIndex) {
            this.lastStep = event.selectedIndex;
        } else if (this.lastStep > event.selectedIndex) {
            this.stepper.steps.forEach((step, idx) => {
                if (idx > event.selectedIndex) {
                    step.reset();
                }
            });
        }
    }

    public setAccessSurchargeCheck(check) {
        this.accessSurchargeCheckRequired = check;
    }
}
