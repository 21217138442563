import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { AccessoriesRouting } from './accessories.routing';
import { AccessoriesPage } from './accessories.page';
import { AccessoryListingPage } from './listing/accessory-listing.page';
import { AccessoryDetailPage } from './detail/accessory-detail.page';

@NgModule({
    imports: [
        SharedModule,
        AccessoriesRouting
    ],
    declarations: [
        AccessoriesPage,
        AccessoryListingPage,
        AccessoryDetailPage
    ]
})
export class AccessoriesPageModule { }
