import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { CatalogueService } from '@app/services/catalogue';
import { NavigationService } from '@app/services/navigation';
import { RouteHistory } from '@app/services/navigation/models';

import { StickyTabsEasyOnlineOrdering } from './models';
import { IStickyTab } from '@app/components/sticky-tabs/models';

@Component({
    selector: 'page-about-easy-online-ordering',
    templateUrl: './easy-online-ordering.page.html',
    styleUrls: ['./easy-online-ordering.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AboutEasyOnlineOrderingPage implements OnInit {
    public StickyTabs = StickyTabsEasyOnlineOrdering;
    public tabs: IStickyTab<StickyTabsEasyOnlineOrdering>[] = [
        { title: 'Choose your door style', id: StickyTabsEasyOnlineOrdering.CHOOSE },
        { title: 'Order your kitchen online', id: StickyTabsEasyOnlineOrdering.ORDER },
        { title: 'Finalise your order', id: StickyTabsEasyOnlineOrdering.FINALISE }
    ];

    private routeHistory: RouteHistory[] = [
        { title: 'About Diy Kitchens', route: '/about-diy-kitchens' },
        { title: 'Easy Online Ordering', route: '/easy-online-ordering' },
    ];

    constructor(
        private catalogueService: CatalogueService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: 'Easy Online Ordering',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'description', content: 'We are a family run business that has been manufacturing and supplying high specification kitchens to both trade and retail customers for nearly 30 years.' }
            ],
            routeHistory: this.routeHistory
        });
    }

    public selectRange() {
        this.catalogueService.openRangeSelector();
    }
}
