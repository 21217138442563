import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { AboutRouting } from './about.routing';
import { AboutPage } from './about.page';

import {
    AboutCareersPage,
    AboutComparisonPage,
    AboutCreditRatingPage,
    AboutEasyOnlineOrderingPage,
    AboutHistoryPage,
    AboutOurPartnersPage,
    AboutPricePromisePage,
    AboutShowroomPage,
    AboutWhichPage,
    AboutWhyDiyKitchensPage
} from './content';

import { ComparisonDetailsDialogModule } from './content/comparison/dialogs/details/details.dialog.module';
import { DirectionsDialogModule } from './content/showroom/dialogs/directions/directions.dialog.module';

@NgModule({
    imports: [
        SharedModule,
        AboutRouting,

        ComparisonDetailsDialogModule,
        DirectionsDialogModule
    ],
    declarations: [
        AboutPage,

        AboutCareersPage,
        AboutComparisonPage,
        AboutCreditRatingPage,
        AboutEasyOnlineOrderingPage,
        AboutHistoryPage,
        AboutOurPartnersPage,
        AboutPricePromisePage,
        AboutShowroomPage,
        AboutWhichPage,
        AboutWhyDiyKitchensPage
    ]
})
export class AboutPageModule { }
