<header class="content-contained content-header-background content-header-kitchens-all-styles">
    <h1>Free Door & Cabinet Colour Samples</h1>
    <p>See exactly what you’re buying in the comfort of your own home with our sample service.
        You can order up to 3 FREE door samples* and as many free cabinet colour samples as you
        require to help you make those important decisions as easy as possible. </p>
    <p class="subtext">£6 delivery fee applies</p>
</header>

<hr class="narrow" />

<section class="content-contained">
    <div class="samples" *ngIf="loaded">
        <div class="sample" *ngFor="let sample of freeSamples">
            <a [routerLink]="'/samples/' + sample.route" title="Free Sample">
                <img [cdn]="categories[sample.route].image" width="100%" [alt]="categories[sample.route].name"/>
            </a>
            <h3>{{categories[sample.route].name}}</h3>
            <div class="sample-content">
                <p *ngIf="categories[sample.route].desc">{{categories[sample.route].desc}}</p>
                <div *ngIf="categories[sample.route].descriptionHTML" [innerHTML]="categories[sample.route].descriptionHTML"></div>
            </div>
            <a mat-flat-button color="primary" [routerLink]="'/samples/' + sample.route">{{sample.button}}</a>
        </div>
    </div>
</section>

<hr class="narrow" />

<header class="content-contained content-header-background content-header-worktops">
    <h1>Worktop Samples</h1>
    <p>We offer a range of worktop materials, including laminate, solid timber, quartz, granite and
        Dekton. Colour samples of these materials are available to order for less than £5 per sample.</p>
</header>
<section class="content-contained">
    <div class="samples" *ngIf="loaded">
        <div class="sample" *ngFor="let sample of worktopSamples">
            <a [routerLink]="'/samples/' + sample.route" title="Free Sample">
                <img [cdn]="categories[sample.route].image" width="100%" [alt]="categories[sample.route].name"/>
            </a>
            <h3>{{categories[sample.route].name}}</h3>
            <div class="sample-content">
                <p *ngIf="categories[sample.route].desc">{{categories[sample.route].desc}}</p>
                <div *ngIf="categories[sample.route].descriptionHTML" [innerHTML]="categories[sample.route].descriptionHTML"></div>
            </div>
            <a mat-flat-button color="primary" [routerLink]="'/samples/' + sample.route">{{sample.button}}</a>
        </div>
    </div>
</section>
