<section class="lightgrey">
    <div class="contained categories">
        <ng-container *ngFor="let category of categories">
            <a class="category-link" *ngIf="category.name" [routerLink]="category.route" [title]="category.name">
                <div class="category">
                    <h5>{{ category.name }}</h5>
                    <img [cdn]="category.image" [alt]="category.name"/>
                    <p>{{category.desc}}</p>
                    <div class="count" *ngIf="category.itemCount>0 && type !== 'kitchen-units'">{{ category.itemCount }} {{ category.countName }}</div>
                    <button mat-flat-button color="primary" *ngIf="category.itemCount < 1">VIEW {{category.countName}}</button>
                </div>
            </a>
        </ng-container>
    </div>
</section>
