import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';

import { PipesModule } from '@app/pipes';

import { SolidSurfaceConfigComponent } from './solid-surface-config.component';

import { SolidSurfaceConfigChangeStyleDialogComponent } from './dialogs/change-style/change-style.dialog.component';
import { SolidSurfaceConfigCornerOptionsDialogComponent } from './dialogs/corner-options/corner-options.dialog.component';
import { SolidSurfaceConfigCutoutOptionsDialogComponent } from './dialogs/cutout-options/cutout-options.dialog.component';
import { SolidSurfaceConfigProfileOptionsDialogComponent } from './dialogs/profile-options/profile-options.dialog.component';
import { SolidSurfaceConfigSplashbackCillDialogComponent } from './dialogs/splashback-cill/splashback-cill.dialog.component';

@NgModule({
    imports: [
        DesignModule,
        PipesModule
    ],
    exports: [
        SolidSurfaceConfigComponent
    ],
    declarations: [
        SolidSurfaceConfigComponent,
        SolidSurfaceConfigChangeStyleDialogComponent,
        SolidSurfaceConfigCornerOptionsDialogComponent,
        SolidSurfaceConfigCutoutOptionsDialogComponent,
        SolidSurfaceConfigProfileOptionsDialogComponent,
        SolidSurfaceConfigSplashbackCillDialogComponent
    ]
})
export class SolidSurfaceConfigComponentModule { }
