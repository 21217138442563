import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { AboutService } from '@app/services/about';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { RouteHistory } from '@app/services/navigation/models';

import { ComparisonPills, ComparisonTabs } from './models';

import { ComparisonDetailsDialogComponent } from './dialogs/details';

@Component({
    selector: 'page-about-comparison',
    templateUrl: './comparison.page.html',
    styleUrls: ['./comparison.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AboutComparisonPage implements OnInit {
    public ComparisonPills = ComparisonPills;
    public ComparisonTabs = ComparisonTabs;

    public brandTitles;
    public brandDetails;

    private brands;
    private data;
    private popups;
    private titles;

    public selectedTab: ComparisonTabs = ComparisonTabs.PRICE;
    public selectedPill: ComparisonPills = ComparisonPills.FULLY_BUILT;

    private routeHistory: RouteHistory[] = [
        { title: 'About Diy Kitchens', route: '/about-diy-kitchens' },
        { title: 'How we compare', route: '/about-diy-kitchens/comparison' }
    ];

    constructor(
        private aboutService: AboutService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: 'Price & Quality Comparison',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'description', content: 'We are a family run business that has been manufacturing and supplying high specification kitchens to both trade and retail customers for nearly 30 years.' }
            ],
            routeHistory: this.routeHistory
        });

        this.aboutService.comparison()
            .then((response) => {
                if (response) {
                    if (response.brands) {
                        this.brands = response.brands;
                    }

                    if (response.data) {
                        this.data = response.data;
                    }

                    if (response.popups) {
                        this.popups = response.popups;
                    }

                    if (response.titles) {
                        this.titles = response.titles;
                    }

                    this.updateBrands();
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public selectTab(tab: ComparisonTabs) {
        this.selectedTab = tab;
        this.updateBrands();
    }

    public selectPill(pill: ComparisonPills) {
        this.selectedPill = pill;
        this.updateBrands();
    }

    public openPopup(detail) {
        this.dialogService.custom(ComparisonDetailsDialogComponent, {
            data: {
                title: detail.title,
                popup: detail.popup,
                brand: detail.brand
            }
        })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    private updateBrands() {
        let brandTitles = [];
        let brandDetails: any = [];

        if (this.data && this.data[this.selectedPill] && this.data[this.selectedPill][this.selectedTab]) {
            const data = this.data[this.selectedPill][this.selectedTab];

            Object.keys(data).forEach((key) => {
                const brandData = data[key];

                brandTitles.push(this.brands[brandData.brand]);

                Object.keys(brandData.details).forEach((title) => {
                    const detail = brandData.details[title];

                    if (!brandDetails[title]) {
                        brandDetails[title] = [];
                    }

                    brandDetails[title].push({
                        title: this.titles[title] || '',
                        value: detail.value,
                        showPopup: detail.showPopup,
                        popup: this.popups[title] || null,
                        brandOrder: this.brands[brandData.brand].order,
                        order: detail.order,
                        brand: key
                    });
                });
            });

            brandDetails = Object.values(brandDetails);
        }

        brandTitles.sort((a, b) => a.order - b.order);

        brandDetails.forEach((brandDetail) => {
            brandDetail.sort((a, b) => a.brandOrder - b.brandOrder);
        });
        brandDetails.sort((a, b) => a[0].order - b[0].order);

        this.brandTitles = brandTitles;
        this.brandDetails = brandDetails;
    }
}
