import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { NavigationService } from '@app/services/navigation';
import { RouteHistory } from '@app/services/navigation/models';

@Component({
    selector: 'page-about-cookie-policy',
    templateUrl: './cookie-policy.page.html',
    styleUrls: ['./cookie-policy.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AboutCookiePolicyPage implements OnInit {
    private routeHistory: RouteHistory[] = [
        { title: 'Cookie Policy', route: '/cookie-policy' }
    ];

    constructor(
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.navigationService.setNavigation({
            title: 'Cookie Policy',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'description', content: 'We are a family run business that has been manufacturing and supplying high specification kitchens to both trade and retail customers for nearly 30 years.' }
            ],
            routeHistory: this.routeHistory
        });
    }
}
