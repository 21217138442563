import { IFetchParams, IFetchMethod, IFetchOptions } from './models';

export class FetchApi {
    public head<T>(url, params?: IFetchParams): Promise<T> {
        let options: IFetchOptions = {
            method: IFetchMethod.HEAD
        };

        if (params) {
            if (params.headers) {
                options.headers = params.headers;
            }

            if (params.mode) {
                options.mode = params.mode;
            }

            if (params.cache) {
                options.cache = params.cache;
            }
        }

        return this.request<T>(url, options, params?.signal);
    }

    public get<T>(url, params?: IFetchParams): Promise<T> {
        let options: IFetchOptions = {
            method: IFetchMethod.GET
        };

        if (params && params.headers) {
            options.headers = params.headers;
        }

        return this.request<T>(url, options, params?.signal);
    }

    public post<T>(url, data?: any, params?: IFetchParams): Promise<T> {
        let options: IFetchOptions = {
            method: IFetchMethod.POST
        };

        if (data) {
            let body = data;
            try {
                if (data && typeof data !== 'string') {
                    body = JSON.stringify(data);
                }
            } catch (e) {
                console.error('Fetch Service - unabled to stringify data', url);
            }

            options.body = body;
        }

        if (params && params.headers) {
            options.headers = params.headers;
        }

        return this.request<T>(url, options, params?.signal);
    }

    private request<T>(url: string, options: IFetchOptions, signal?: AbortSignal): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            if (signal?.aborted) {
                reject({
                    type: 'aborted',
                    message: signal.reason
                });
            } else {
                if (!options.headers) {
                    options.headers = {};
                }

                if (!options.headers['Content-Type']) {
                    options.headers['Content-Type'] = 'application/json';
                }

                const timeoutSignal = AbortSignal.timeout(30000);
                let signals = [];
                if (signal) {
                    signals.push(signal);
                }

                signals.push(timeoutSignal);
                options.signal = AbortSignal.any(signals);

                fetch(url, options)
                    .then((response) => {
                        if (!response.ok) {
                            // console.error('Fetch - StatusCode: ', response.status);
                            // console.error('Fetch - StatusMessage: ', response.statusText);
                            // console.error('Fetch - Data: ', ((options && options.body) ? options.body : null));

                            reject({
                                type: 'error',
                                message: response.statusText || response
                            });
                        } else {
                            response.text()
                                .then((responseText: any) => {
                                    let message: T = responseText;

                                    if (responseText && responseText.length) {
                                        try {
                                            message = JSON.parse(responseText);
                                        } catch (e) { }
                                    }

                                    resolve(message);
                                })
                                .catch((error) => {
                                    console.error('Fetch Read Error - ', error);
                                    reject({
                                        type: 'error',
                                        message: error
                                    });
                                })
                        }
                    })
                    .catch((error) => {
                        console.error('Fetch - Error: ', error);

                        if (error && error.name) {
                            let type = 'error';
                            let message = (error && error.message) ? error.message : error;

                            if (error.name === 'TimeoutError') {
                                type = 'timeout';
                            } else if (error.name === 'AbortError') {
                                type = 'abort';
                            }

                            reject({
                                type: type,
                                message: message
                            });
                        } else {
                            reject({
                                type: 'error',
                                message: (error) ? error : null
                            });
                        }
                    });
            }
        });
    }
}
