import {
    Component, ViewEncapsulation, HostListener,
    Input, Output, EventEmitter,
    OnInit, OnDestroy, OnChanges
} from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Subscription } from 'rxjs';

import { NavigationService } from '@app/services/navigation';
import { NavigationRoute } from '@app/services/navigation/models';

import { Config } from '@app/config';

import {
    MegaMenuCategories, MegaMenuTemplates,
    IMegaMenu, IMegaMenuCategory, IMegaMenuItem, IMegaMenuSubCategory
} from './models';

import { mockData } from './mock-data';

@Component({
    selector: 'component-mega-menu',
    templateUrl: './mega-menu.component.html',
    styleUrls: ['./mega-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('fade', [
            transition('void => *', [
                style({ opacity: 0 }),
                animate(250, style({ opacity: 1 }))
            ])
        ])
    ],
    standalone: false
})
export class MegaMenuComponent implements OnInit, OnDestroy, OnChanges {
    @Input() category: MegaMenuCategories;
    @Output() categoryChange: EventEmitter<MegaMenuCategories> = new EventEmitter<MegaMenuCategories>();

    public MegaMenuTemplates = MegaMenuTemplates;

    private menu: IMegaMenu = mockData;
    public menuCategory: IMegaMenuCategory;
    public subCategoryId: number = 0;
    public items: IMegaMenuItem[];
    public additionalItems: IMegaMenuItem[];
    public template: MegaMenuTemplates;
    public categories: IMegaMenuCategory[];

    public isVisible: boolean = false;

    public scrollLimitReached: boolean = false;
    private scrollLimit: number = 250;

    private routeSubscription: Subscription;

    constructor(
        private navigationService: NavigationService,
        private config: Config
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                this.close();
            }
        );

        this.open();

        // Build a hidden div full of the mega-menu links for SEO purposes
        if (this.menu) {
            this.categories = Object.values(this.menu);
        }
    }

    ngOnChanges(changes) {
        if (changes.category && changes.category.currentValue) {
            this.category = changes.category.currentValue;
            this.open();
        } else {
            this.close();
        }
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }

    private open(): void {
        if (this.menu && this.category && this.menu[this.category]) {
            this.menuCategory = this.menu[this.category];

            this.selectSubCategory(0);

            this.isVisible = true;
        } else {
            this.isVisible = false;
        }
    }

    public close(): void {
        this.isVisible = false;
        this.categoryChange.emit(null);
    }

    public selectSubCategory(subCategoryId: number): void {
        this.subCategoryId = subCategoryId;

        if (this.menuCategory && this.menuCategory.subCategories && this.menuCategory.subCategories.length) {
            const subCategory: IMegaMenuSubCategory = this.menuCategory.subCategories.find((subCategory) => subCategory.id === this.subCategoryId);

            this.template = subCategory.template;
            this.items = subCategory.items;
            this.additionalItems = subCategory.additionalItems || null;
        } else if (this.menuCategory && this.menuCategory.items && this.menuCategory.items.length) {
            this.template = MegaMenuTemplates.IMAGE;
            this.items = this.menuCategory.items;
            this.additionalItems = null;
        }
    }

    @HostListener('document:scroll', ['$event'])
    public onViewportScroll() {
        if (this.config.isBrowser && window) {
            this.scrollLimitReached = (window.scrollY > this.scrollLimit) ? true : false;
        }
    }
}
