@import '/src/theme/variables';

.mat-mdc-dialog-container {
    component-dialog-input {
        .mat-mdc-dialog-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            padding-top: 10px;
            padding-bottom: 5px;
            border-bottom: 1px solid #dee2e6;

            &::before {
                display: none;
            }

            .title {
                h1 {
                    margin: 0;
                    color: rgba(0, 18, 36, 0.9);
                    font-size: 1rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    letter-spacing: 0.05rem;
                }

                .sub-title {
                    font-size: 0.8rem;
                    line-height: 1rem;
                }
            }

            .close {
                cursor: pointer;
            }
        }

        .mat-mdc-dialog-content {
            font-size: 14px;
            color: rgba(0, 18, 36, 0.9);
            font-weight: 400;
            line-height: 20px;
        }

        .mat-mdc-dialog-actions {
            justify-content: space-between;
            padding: 10px 24px 20px;

            .mat-mdc-button {
                background: #009cde;
                border-color: #009cde;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 0.1429em;
                color: #ffffff;
                padding: 0 16px;

                &:hover {
                    background: #00a9f1;
                    border-color: #00a9f1;
                }
            }
        }

        .form-field {
            width: 100%;
        }
    }
}
