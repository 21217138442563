export enum BasketHistoryAction {
    ADD = 1,
    EDIT = 2,
    REMOVE = 3,
    SOFT_CLOSE = 10,
    QTY = 11,
    STYLE = 12,
    HANDING = 13,
    CARCASE_COLOUR = 14
}

export class BasketHistory{
    public actionTitle: string;
    public actionIcon : string;
}
