import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Config } from '@app/config';
import { DateHelper, NumberHelper, BasketHelper } from '@app/utilities/helpers';

import { HttpApi } from '@app/services/api';
import { HttpApiOptions, HttpApiResponse } from '@app/services/api/models';
import { AuthService, AuthCustomerService } from '@app/services/auth';
import { DialogService } from '@app/services/dialog';
import { CatalogueService } from '@app/services/catalogue';
import { MarketingService } from '@app/services/marketing';
import { ActiveRange, ProductType } from '@app/services/catalogue/models';
import { StorageService } from '@app/services/storage';

import { BasketApiErrors } from '@app/services/basket/models';

import { DrawerUpgradeDialogComponent } from './dialog/drawer-upgrade';

import {
    Basket,
    BasketItem,
    PanDrawerUpgrade,
    IBasketAddress,
    IBasketDeliveryAccess,
    IBasketUpdateItem,
    IDrawerCostAndQty,
    PanDrawerCodes,
    IBasketValidateStyleParams,
    IBasketValidateStyleResponse,
    IBasketAddresses,
    BasketNote,
    PanDrawerUpgradeCost,
    DrawerBoxUpgrade,
    DrawerBoxUpgradeCost,
    BasketAction
} from './models';
import { DeliveryType } from '@app/services/checkout/models';
import { IPromotionBasketAction, IPromotionBasketItems } from '../marketing/models/promotion.model';

@Injectable()
export class BasketService {
    private storageKey: string = 'active-basket';
    private productTypeOrder: { [x: string]: number } = {};

    private basket: Basket;
    public basket$: BehaviorSubject<Basket> = new BehaviorSubject(null);
    public balance$: BehaviorSubject<number> = new BehaviorSubject(0);

    public saveLaterCounter: number = 0;
    public freeDoorSampleAllowance: number = 3;

    private cutoffTimer: any;
    public cutoffTimer$: BehaviorSubject<string> = new BehaviorSubject(null);

    private solidWorktopCode = 'SOLIDSURFACEWORKTOP ';

    get basketItems() {
        return this.basket?.items;
    }

    constructor(
        private config: Config,
        private httpApi: HttpApi,
        private authService: AuthService,
        private authCustomerService: AuthCustomerService,
        private catalogueService: CatalogueService,
        private storageService: StorageService,
        private dialogService: DialogService,
        private marketingService: MarketingService,
        private ngZone: NgZone
    ) {
        // Setup section category sorting
        this.productTypeOrder[ProductType.SAMPLE_DOORS] = 1;
        this.productTypeOrder[ProductType.SAMPLE_CARCASE] = 2;
        this.productTypeOrder[ProductType.SAMPLE_WORKTOP] = 3;
        this.productTypeOrder[ProductType.CABINETS] = 4;
        this.productTypeOrder[ProductType.DOORS] = 5;
        this.productTypeOrder[ProductType.APPLIANCES] = 6;
        this.productTypeOrder[ProductType.SINK_AND_TAPS] = 7;
        this.productTypeOrder[ProductType.HANDLES] = 8;
        this.productTypeOrder[ProductType.ACCESSORIES] = 9;
        this.productTypeOrder[ProductType.WORKTOPS] = 10;

        // Wait for auth then load basket
        this.authService.authentication.subscribe((isAuthed) => {
            if (isAuthed !== null) {
                if (isAuthed === false && this.basket) {
                    this.clearAll();
                } else {
                    this.load();
                }
            }
        });
    }

    public load(): void {
        this.storageService.get(this.storageKey)
            .then((basket) => {
                const uuid = (basket && basket.uuid) ? basket.uuid : null;
                if (uuid) {
                    this.loadFromApi(uuid)
                        .catch((error) => {
                            this.dialogService.error(this.constructor.name, error);
                            this.clearAll();
                        });
                } else if (this.authService.isAuthenticated) {
                    this.latest()
                        .catch((error) => {
                            this.dialogService.error(this.constructor.name, error);
                            this.clearAll();
                        });
                } else {
                    this.clearAll();
                }
            })
            .catch((error) => {
                this.dialogService.error(this.constructor.name, error);
                this.clearAll();
            });
    }

    public clearAll(save: boolean = false, createEmpty: boolean = true, name = `New Basket - ${DateHelper.now(true)}`): Promise<void> {
        return new Promise((resolve, reject) => {
            this.storageService.remove(this.storageKey)
                .then(() => {
                    if (createEmpty) {
                        this.createEmpty(name);
                    }

                    if (save) {
                        this.save()
                            .then(() => resolve())
                            .catch((error) => this.dialogService.error(this.constructor.name, error));
                    } else {
                        resolve();
                    }
                })
                .catch((error) => {
                    this.dialogService.error(this.constructor.name, error);
                    if (createEmpty) {
                        this.createEmpty(name);
                    }

                    if (save) {
                        this.save()
                            .then(() => resolve())
                            .catch((error) => this.dialogService.error(this.constructor.name, error));
                    } else {
                        resolve();
                    }
                });
        });
    }

    public remove(uuid: string): void {
        const url = `${this.config.api.endpoints.diy}/basket/${uuid}`;
        const options: HttpApiOptions = {
            authorization: {
                ApplicationId: this.config.app.id,
                UserToken: this.authCustomerService.universalToken
            }
        };

        this.storageService.set(this.storageKey, null)
            .then(() => {
                this.httpApi.del<HttpApiResponse<Basket>>(url, options).subscribe({
                    next: (response) => {
                        if (response && response.success) {
                            if (this.basket.uuid === uuid) {
                                this.clearAll(true, true)
                                    .catch((error) => this.dialogService.error(this.constructor.name, error));
                            }
                        } else {
                            // TODO: handle error
                        }
                    },
                    error: (error) => {
                        // TODO: handle error?
                        this.dialogService.error(this.constructor.name, error);
                    }
                });
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    private loadFromApi(uuid: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket` + (uuid ? `/${uuid}` : '');
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.get<HttpApiResponse<Basket>>(url, options).subscribe({
                next: (response) => {
                    if (response && response.success && response.body && response.body.uuid) {
                        // Possible order edited basket
                        // Temporary solution for soft deleting items, needs reworking!
                        let oldBasket = null;
                        if (this.basket && this.basket.uuid === uuid && this.basket.orderNumber) {
                            oldBasket = JSON.parse(JSON.stringify(this.basket));
                        }

                        this.basket = Object.assign(new Basket(), response.body);

                        if (oldBasket) {
                            this.mergeSoftDeleteFlag(oldBasket);
                        }

                        if (this.config.isBrowser) {
                            this.startCutOffTimer();
                        }

                        this.save()
                            .then(() => {
                                resolve();
                                this.emitBasket();
                            })
                            .catch((error) => {
                                reject(error);
                                this.emitBasket();
                            });
                    } else {
                        const error: any = response && response.body ? response.body : null;
                        reject(error);
                    }
                },
                error: (error) => {
                    reject(error);
                }
            });
        });
    }

    private createEmpty(name = null) {
        this.basket = new Basket(name);
        this.emitBasket();
    }

    public switch(uuid?: string): Promise<void> {
        return new Promise((resolve, reject) => {
            if (uuid) {
                const url = `${this.config.api.endpoints.diy}/basket/${uuid}/activate`;
                const options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id,
                        UserToken: this.authCustomerService.universalToken
                    }
                };

                this.httpApi.put<HttpApiResponse<Basket>>(url, null, options).subscribe({
                    next: () => {
                        this.loadFromApi(uuid)
                            .then(() => {
                                let unitOrDoor = this.basket.items.find((basketItem) => [ProductType.CABINETS].indexOf(basketItem.group) !== -1);
                                if (!unitOrDoor) {
                                    unitOrDoor = this.basket.items.find((basketItem) => [ProductType.DOORS].indexOf(basketItem.group) !== -1);
                                }

                                if (unitOrDoor) {
                                    this.catalogueService.getRanges()
                                        .then((ranges) => {
                                            let foundRange = ranges.find((range) => range.name === unitOrDoor.range);
                                            if (foundRange) {
                                                this.catalogueService.updateActiveRange(
                                                    foundRange,
                                                    unitOrDoor.rangeColour,
                                                    unitOrDoor.colour,
                                                    unitOrDoor.bespokeColour,
                                                    unitOrDoor.carcaseColour
                                                );
                                            }

                                            resolve();
                                        })
                                        .catch((error) => {
                                            this.dialogService.error(this.constructor.name, error);
                                            resolve();
                                        });
                                } else {
                                    resolve();
                                }
                            })
                            .catch((error) => {
                                this.dialogService.error(this.constructor.name, error);
                                this.clearAll();
                                resolve();
                            });
                    },
                    error: (error) => {
                        this.dialogService.error(this.constructor.name, error);
                        this.loadFromApi(uuid)
                            .then(() => resolve())
                            .catch((err) => {
                                this.dialogService.error(this.constructor.name, err);
                                this.clearAll();
                                resolve();
                            });
                    }
                });
            } else {
                this.clearAll();
                resolve();
            }
        });
    }

    public latest(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.authCustomerService.isAuthenticated) {
                const url = `${this.config.api.endpoints.diy}/basket/latest`;
                const options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id,
                        UserToken: this.authCustomerService.universalToken
                    }
                };

                this.httpApi.get<HttpApiResponse<Basket>>(url, options).subscribe({
                    next: (response) => {
                        if (response && response.success) {
                            if (response.body && response.body.uuid) {
                                this.loadFromApi(response.body.uuid)
                                    .catch((err) => {
                                        this.dialogService.error(this.constructor.name, err);
                                        this.clearAll();
                                        resolve();
                                    });
                            } else {
                                this.clearAll();
                                resolve();
                            }
                        } else {
                            this.dialogService.error(this.constructor.name, response.body);
                            this.clearAll();
                            resolve();
                        }
                    },
                    error: (error) => {
                        this.dialogService.error(this.constructor.name, error);
                        this.clearAll();
                        resolve();
                    }
                });
            } else {
                resolve();
            }
        });
    }

    private startCutOffTimer() {
        this.stopCutOffTimer();

        if (this.config.isBrowser) {
            this.cutoffTimer = setInterval(() => {
                let now = DateHelper.now();
                if (this.basket && this.basket.cutOffDate) {
                    if (this.basket.cutOffDate > now) {
                        this.cutoffTimer$.next(DateHelper.duration(now, this.basket.cutOffDate));
                    } else {
                        this.cutoffTimer$.next('Order Locked');
                    }
                } else {
                    this.cutoffTimer$.next(null);
                }
            }, 1000);
        }
    }

    private stopCutOffTimer() {
        if (this.cutoffTimer) {
            clearInterval(this.cutoffTimer);
            this.cutoffTimer = null;
            this.cutoffTimer$.next(null);
        }
    }

    public hasPassedCutOffDate() {
        if (this.basket && this.basket.cutOffDate && this.basket.cutOffDate < DateHelper.now()) {
            this.dialogService.notice('Order has been locked', 'Please note that your order has surpassed the 48 hour edit window ', true);
            return true;
        } else {
            return false;
        }
    }

    public rename(name: string, uuid?: string): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!uuid && !this.basket.uuid) {
                this.basket.name = name;
            } else {
                const basketUuid = uuid || this.basket.uuid;
                const url = `${this.config.api.endpoints.diy}/basket/${basketUuid}/rename`;
                const options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id,
                        UserToken: this.authCustomerService.universalToken
                    }
                };
                const params = {
                    name: name
                };

                this.httpApi.put<HttpApiResponse<Basket>>(url, params, options).subscribe({
                    next: (response) => {
                        if (response && response.success) {
                            if (uuid) {
                                resolve();
                            } else {
                                this.basket.name = name;
                                this.save()
                                    .then(() => resolve())
                                    .catch(() => resolve());
                            }
                        } else {
                            // TODO: handle the error?
                            reject(response.body);
                        }
                    },
                    error: (error) => {
                        // TODO: handle the error?
                        reject(error);
                    }
                });
            }
        });
    }

    private createBasket(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!this.basket.uuid) {
                const url = `${this.config.api.endpoints.diy}/basket`;
                const options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id,
                        UserToken: this.authCustomerService.universalToken
                    }
                };
                const params = {
                    name: this.basket.name
                };

                this.httpApi.post<HttpApiResponse<Basket>>(url, params, options).subscribe({
                    next: (response) => {
                        if (response && response.success) {
                            if (response.body && response.body.uuid) {
                                this.basket.uuid = response.body.uuid;
                                this.basket.createdAt = response.body.createdAt;
                                this.basket.modifiedAt = response.body.modifiedAt;

                                this.save()
                                    .then(() => resolve())
                                    .catch(() => resolve());
                            } else {
                                // TODO: retry depending on the error? could have been an API restart or something
                                reject('Failed to create new basket');
                            }
                        } else {
                            reject(response.body);
                        }
                    },
                    error: (error) => reject(error)
                });
            } else {
                resolve();
            }
        });
    }

    private save(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.storageService.get(this.storageKey)
                .then((basket) => {
                    const validated = basket && basket.validated ? basket.validated : null;

                    this.storageService.set(this.storageKey, { uuid: this.basket.uuid, validated: validated })
                        .then(() => resolve())
                        .catch((error) => {
                            // TODO: handle the error - could be too much data trying to save into local storage?
                            // Maybe at a later date if they fails all the time and they customer is not logged in, offer the option to save their basket as a JSON file that they can load back into their browser?
                            this.dialogService.error(this.constructor.name, error);
                            resolve();
                        });
                })
                .catch((error) => {
                    // TODO: handle the error - could be too much data trying to save into local storage?
                    // Maybe at a later date if they fails all the time and they customer is not logged in, offer the option to save their basket as a JSON file that they can load back into their browser?
                    this.dialogService.error(this.constructor.name, error);
                    resolve();
                });
        });
    }

    public addItem(item, productType: ProductType, qty: number = 1): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.hasPassedCutOffDate()) {
                return resolve();
            }

            this.createBasket()
                .then(() => {
                    switch (productType) {
                        case ProductType.CABINETS:
                            this.configureUnit(this.basket, item, qty)
                                .then((configuredItem) => {
                                    if (configuredItem) {
                                        this.addConfiguredItem(configuredItem)
                                            .then(() => resolve())
                                            .catch((error) => this.dialogService.error(this.constructor.name, error));
                                    } else {
                                        reject('No item to add');
                                    }
                                })
                                .catch((error) => reject(error));
                            break;
                        case ProductType.WORKTOPS:
                            if (item.cat && item.cat.match(/laminate|solid wood/gi)) {
                                item.category = item.cat;
                                if (item.cat !== 'Solid Wood') {
                                    item.subCategory = item.cat_type;
                                }
                                item.colour = item.sub_cat;
                                item.width = item.length;
                                item.height = item.depth;
                                item.depth = item.thickness;
                            } else if (item.code === 'SOLIDSURFACEWORKTOP') {
                                item.colour = item.subCategory;
                            }

                            this.addConfiguredItem(new BasketItem(item, null, productType, qty))
                                .then(() => resolve())
                                .catch((error) => this.dialogService.error(this.constructor.name, error));
                            break;
                        default:
                            this.addConfiguredItem(new BasketItem(item, this.catalogueService.activeRange, productType, qty))
                                .then(() => resolve())
                                .catch((error) => this.dialogService.error(this.constructor.name, error));
                            break;
                    }
                })
                .catch((error) => {
                    // TODO: handle the error?
                    this.dialogService.error(this.constructor.name, error);
                });
        });
    }

    private configureUnit(basket: Basket, item, qty: number = 1): Promise<BasketItem> {
        return new Promise((resolve, reject) => {
            const promotionPricePromises = [
                this.marketingService.getCarcaseSalePrice(item, this.catalogueService.activeRange),
                this.marketingService.getHingeCostSalePrice(item, this.catalogueService.activeRange)
            ];

            Promise.all(promotionPricePromises)
                .then(([salePrice, hingeCost]) => {
                    if (salePrice < item._cost) {
                        item.salePrice = salePrice;
                        item.originalHingecost = item.hingeCost;
                        item.hingeCost = hingeCost;
                    }
                    const basketItem = new BasketItem(item, this.catalogueService.activeRange, ProductType.CABINETS, qty);

                    if (basketItem.softClose && basketItem.hingeCost) {
                        basketItem.cost = basketItem.cost + basketItem.hingeCost;
                        if (basketItem.salePrice) {
                            basketItem.salePrice += basketItem.hingeCost;
                        }
                    }

                    if ((!item.panDrawerQty && !item.drawerBoxQty) || this.basket.panDrawerUpgrade >= 0 || this.basket.drawerBoxUpgrade >= 0) {
                        resolve(basketItem);
                    } else {
                        this.drawerUpgradeDialog(basketItem)
                            .then(() => resolve(basketItem))
                            .catch((error) => {
                                reject(error);
                                this.dialogService.error(this.constructor.name, error);
                            });
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public drawerUpgradeDialog(item: BasketItem = null) {
        return new Promise((resolve, reject) => {
            this.dialogService.custom(DrawerUpgradeDialogComponent, {
                width: '100%',
                maxWidth: '1024px',
                panelClass: 'component-drawer-upgrade-dialog',
                data: {
                    item: item || null
                }
            })
                .then(() => resolve(item))
                .catch((error) => reject(error));
        });
    }

    private handleDoors(item: BasketItem): Promise<BasketItem[]> {
        return new Promise((resolve, reject) => {
            if (!(item.doors && item.doors.length) && !(item.ifDoors && item.ifDoors.length)) {
                resolve([]);
                return;
            }

            let addDoors: BasketItem[] = [];
            const doorsToAdd = item.isInframe ? item.ifDoors : item.doors;
            const doors = this.catalogueService.getDoorForUnit(item.code, item.rangeColour);
            let doorsMarketingPromises = doorsToAdd.map(sizeTag => {
                const sizeTagCode = sizeTag.code.toUpperCase().trim();
                const door = doors[sizeTagCode];
                return this.marketingService.getDoorOnlySalePrice(door, this.catalogueService.activeRange)
                    .then(salePrice => {
                        let newDoor = this.createDoorItem(door, item, salePrice);
                        for (let i = sizeTag.qty; i > 0; i--) {
                            addDoors.push(this.clone<BasketItem>(newDoor));
                        }
                    });
            });

            Promise.all(doorsMarketingPromises)
                .then(() => resolve(addDoors))
                .catch((error) => reject(error));
        });
    }

    private createDoorItem(door, item, salePrice): BasketItem {
        let newDoor: BasketItem = {
            code: door.code,
            description: `${item.range} ${item.rangeColour} - ${door.desc}`,
            rangeId: item.rangeId,
            range: item.range,
            rangeColour: item.rangeColour,
            colour: item.colour,
            carcaseColour: item.carcaseColour,
            otherColour: item.otherColour,
            bespokeColour: item.bespokeColour,
            height: door ? door.height || null : null,
            width: door ? door.width || null : null,
            depth: null,
            isInframe: item.isInframe,
            handing: item.handing || null,
            qty: 1,
            rank: '0',
            rankParent: '0',
            supplier: null,
            group: ProductType.DOORS,
            category: door ? door._category || null : null,
            subCategory: null,
            cost: door ? door.price || 0 : 0,
            softClose: false,
            hingeCost: 0,
            salePrice: null,
            promotionPercentage: null
        };

        if (salePrice && salePrice < newDoor.cost) {
            newDoor.promotionPercentage = parseFloat((1 - salePrice / newDoor.cost).toFixed(2)) * 100;
            newDoor.salePrice = salePrice;
        }

        return newDoor;
    }

    private processItems(item: BasketItem, addDoors: BasketItem[], nextItemRank: number): Promise<BasketItem[]> {
        return new Promise((resolve) => {
            // Product Types that we allow qty grouping to occur, i.e. when adding 10 handles to basket group into 1 item
            const qtyGroupTypes: ProductType[] = [
                ProductType.ACCESSORIES,
                ProductType.APPLIANCES,
                ProductType.CORNICE_PELMET,
                ProductType.DOORS,
                ProductType.DRAWER_BOXES,
                ProductType.EXTERNAL_CURVED_CORNICE,
                ProductType.HANDLES,
                ProductType.PANELS,
                ProductType.PLINTHS,
                ProductType.RANGE_PRODUCTS,
                ProductType.SINK_AND_TAPS,
                ProductType.WORKTOPS
            ];
            let addItems: BasketItem[] = [];
            if (qtyGroupTypes.includes(item.group)) {
                addItems.push(item);
            } else {
                for (let i = 0; i < item.qty; i++) {
                    let newItem = this.clone<BasketItem>(item);
                    newItem.rank = `${nextItemRank}`;
                    newItem.rankParent = '0';
                    newItem.qty = 1;
                    addItems.push(newItem);

                    if (item.group === ProductType.CABINETS && addDoors.length) {
                        let childRank = 1;
                        addDoors.forEach(door => {
                            door.rank = `${nextItemRank}.${childRank}`;
                            door.rankParent = `${nextItemRank}`;
                            addItems.push(this.clone<BasketItem>(door));
                            childRank++;
                        });
                    }

                    nextItemRank++;
                }
            }

            resolve(addItems);
        });
    }

    private addChildrenItems(addItems: BasketItem[], children: BasketItem[], nextItemRank: number): Promise<BasketItem[]> {
        return new Promise((resolve) => {
            if (children && children.length) {
                let childRank = 1;
                children.forEach(childItem => {
                    childItem.id = null;
                    childItem.rank = `${nextItemRank}.${childRank}`;
                    childItem.rankParent = `${nextItemRank}`;
                    addItems.push(this.clone<BasketItem>(childItem));
                    childRank++;
                });
            }

            resolve(addItems);
        });
    }

    private getPromotionalItem(productCode: string, group: ProductType): Promise<BasketItem> {
        return new Promise((resolve, reject) => {
            let cataloguePromise = this.catalogueService.getAppliance(productCode);
            switch (group) {
                case ProductType.APPLIANCES:
                    break;
                default:
                    break;
            }
            cataloguePromise
                .then((freeItem) => {
                    if (freeItem) {
                        resolve(new BasketItem(freeItem, this.catalogueService.activeRange, ProductType.APPLIANCES));
                    } else {
                        reject('Unable to add promotional item.');
                    }
                })
                .catch((error) => reject(error));
        });
    }

    /**
     * Check the basket items, alongside the (optional) product being added or removed to determine if a Free Item promotion applies
     * @param item 
     * @param addItems 
     * @param nextItemRank 
     * @param qtyChange 
     * @returns An object of two arrays, one for items to be added to the basket and one for items to be removed.
     */
    private configurePromotionalItems(item?: BasketItem, addItems?: BasketItem[], nextItemRank?: number, qtyChange?: number): Promise<IPromotionBasketItems> {
        return new Promise((resolve, reject) => {

            const mode = Array.isArray(addItems)
                ? BasketAction.ADD
                : (item)
                    ? BasketAction.REMOVE
                    : BasketAction.CHANGE_QTY;
            const descriptionAppend = 'FREE WITH PROMOTION';
            const configuredItems: IPromotionBasketItems = {
                addItems: [],
                removeItems: []
            }
            nextItemRank = nextItemRank || this.nextItemRank();
            let basketItems: BasketItem[] = JSON.parse(JSON.stringify(this.basket.items));
            if (mode === BasketAction.ADD) {
                const newItems = JSON.parse(JSON.stringify(addItems));
                basketItems = [...basketItems, ...newItems];
                configuredItems.addItems = [...configuredItems.addItems, ...newItems];
            }
            basketItems = basketItems.filter(product => {
                const excludedGroups = [
                    ProductType.CABINETS, ProductType.DOORS, ProductType.DELIVERY,
                    ProductType.DRAWER_BOXES, ProductType.PANELS, ProductType.RANGE_PRODUCTS,
                    ProductType.SAMPLE_CARCASE, ProductType.SAMPLE_DOORS, ProductType.SAMPLE_WORKTOP
                ];

                return !excludedGroups.includes(product.group) && ((mode === BasketAction.ADD || mode === BasketAction.CHANGE_QTY) || product.id !== item.id);
            });

            this.marketingService.checkBasketQualifyingFreeItems(basketItems, descriptionAppend)
                .then((actions) => {
                    const actionPromises: Promise<BasketItem>[] = actions.map((action) =>
                        action.action === 'nothing'
                            ? Promise.resolve(null)
                            : this.getPromotionalItem(action.productCode, action.group)
                    );

                    return Promise.all(actionPromises).then<[IPromotionBasketAction[], BasketItem[]]>((actionResponses) => [actions, actionResponses]);
                })
                .then(([actions, actionResponses]) => {
                    actions.forEach((action, index) => {
                        const responseItem = actionResponses[index];

                        if (action.action === 'add' && responseItem) {
                            responseItem.rank = `${nextItemRank + 1}`;
                            responseItem.salePrice = action.value;
                            responseItem.promotionPercentage = parseFloat(((responseItem.cost - responseItem.salePrice) / responseItem.cost * 100).toFixed(2));
                            responseItem.description = `${responseItem.description}. ${descriptionAppend}`;
                            responseItem.isPromotion = true;
                            configuredItems.addItems.push(responseItem);
                            nextItemRank++;
                        } else if (action.action === 'remove' && responseItem) {
                            const candidatesToRemove = this.basket.items.filter((item) => item.code === responseItem.code);
                            if (candidatesToRemove.length) {
                                const itemToRemove = candidatesToRemove.length === 1
                                    ? candidatesToRemove[0]
                                    : this.selectItemToRemove(candidatesToRemove, mode, qtyChange, descriptionAppend);
                                if (itemToRemove) {
                                    configuredItems.removeItems.push(itemToRemove);
                                }
                            }
                        }
                    });

                    resolve(configuredItems);
                })
                .catch((error) => {
                    this.dialogService.error(this.constructor.name, error);
                    resolve(configuredItems);
                });

        });
    }

    private selectItemToRemove(items: BasketItem[], mode: BasketAction, qtyChange: number, descriptionAppend: string): BasketItem | undefined {
        if (mode === BasketAction.ADD || (mode === BasketAction.CHANGE_QTY && qtyChange > 0)) {
            return items.find((item) => !item.description.includes(descriptionAppend));
        } else {
            return items.find((item) => item.description.includes(descriptionAppend));
        }
    }

    private postBasketItems(item: BasketItem, addItems: BasketItem[]): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket/${this.basket.uuid}/items`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };
            const params = {
                items: addItems,
                promotionParams: this.promotionParams(addItems)
            };

            this.httpApi.post<HttpApiResponse<any>>(url, params, options).subscribe({
                next: (response) => {
                    if (response && response.success) {
                        this.basket.modifiedAt = DateHelper.now();
                        if (response.body && response.body.items && response.body.items.length) {
                            response.body.items.forEach((addedItem) => {
                                let addItem = addItems.find((ai) => ai.rank === addedItem.rank);
                                if (addItem) {
                                    addItem.id = addedItem.id;

                                    if (addedItem.worktopConfigUuid) {
                                        addItem.worktopConfigUuid = addedItem.worktopConfigUuid;
                                    }
                                    this.basket.items.push(addItem);
                                }
                            });

                            let queries = [];
                            queries.push(this.updatePanDrawerUpgrade(response.body.panDrawerUpgrade));

                            if (item && item.group === ProductType.SAMPLE_DOORS) {
                                queries.push(this.recalculateSampleDoors());
                            }

                            Promise.all(queries)
                                .then(() => {
                                    resolve();
                                })
                                .catch(() => resolve());
                        } else {
                            // TODO: handle the error?
                            resolve();
                        }
                    } else {
                        this.handleError(response.body.error);

                        // TODO: handle the error?
                        reject(response.body);
                    }
                },
                error: (error) => {
                    // TODO: handle the error?
                    reject(error);
                }
            });
        });
    }

    private addConfiguredItem(item: BasketItem, children: BasketItem[] = []): Promise<void> {
        return new Promise((resolve, reject) => {
            let nextItemRank = this.nextItemRank();
            item = this.clone<BasketItem>(item);
            item.id = null;
            item.rank = `${nextItemRank}`;
            item.rankParent = '0';
            item.qty = item.qty || 1;

            this.handleDoors(item)
                .then((addDoors) => this.processItems(item, addDoors, nextItemRank))
                .then((addItems) => this.addChildrenItems(addItems, children, nextItemRank))
                .then((itemsWithChildren) => this.configurePromotionalItems(item, itemsWithChildren, nextItemRank))
                .then((items) => {
                    const addRemovePromises: Promise<void>[] = [
                        this.postBasketItems(item, items.addItems)
                    ];
                    items.removeItems.forEach((removeItem) => {
                        addRemovePromises.push(
                            this.delBasketItemAPI(removeItem.id)
                        );
                    });
                    return Promise.all(addRemovePromises);
                })
                .then(() => resolve())
                .catch((error) => {
                    this.dialogService.error(this.constructor.name, 'Failed to add configured item:', error);
                    reject(error);
                });
        });
    }

    private handleError(error) {
        switch (error) {
            case BasketApiErrors.BASKET_LOCKED:
                this.dialogService.notice('Order has been locked', 'Please note that your order has surpassed the 48 hour edit window ', true);
                this.load();
                break;
            default:
                break;
        }
    }

    /**
     * @deprecated
     * @param supplier 
     * @param promotionPercentage 
     */
    private setPromotionOnApplianceSupplier(supplier, promotionPercentage) {
        let matchingItems = this.basket.items.filter(
            (basketItem) => basketItem.group === ProductType.APPLIANCES && basketItem.supplier === supplier
        );
        if (matchingItems && matchingItems.length) {
            matchingItems.forEach((basketItem) => {
                if (promotionPercentage) {
                    basketItem.promotionPercentage = promotionPercentage;
                    basketItem.salePrice =
                        basketItem.cost * ((100 - (basketItem.discountPercentage ? basketItem.discountPercentage : promotionPercentage)) / 100);
                } else {
                    basketItem.promotionPercentage = null;
                    if (basketItem.discountPercentage) {
                        basketItem.salePrice = basketItem.cost * ((100 - basketItem.discountPercentage) / 100);
                    } else {
                        basketItem.salePrice = null;
                    }
                }
            });
        }
    }

    private delBasketItemAPI(itemId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket/${this.basket.uuid}/item/${itemId}`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.del<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response && response.success) {
                        this.basket.modifiedAt = DateHelper.now();

                        const item = this.basket.items.find((basketItem) => basketItem.id === itemId);
                        this.basket.items = this.basket.items.filter((basketItem) => {
                            if (basketItem.id === itemId || basketItem.rankParent === item.rank) {
                                return false;
                            }

                            return true;
                        });

                        if (
                            response &&
                            response.body &&
                            response.body.promotions &&
                            response.body.promotions.appliances &&
                            response.body.promotions.appliances.suppliers
                        ) {
                            if (response.body.promotions.appliances.suppliers) {
                                if (response.body.promotions.appliances.suppliers.hasOwnProperty('CDA')) {
                                    this.setPromotionOnApplianceSupplier('CDA', response.body.promotions.appliances.suppliers.CDA);
                                }

                                if (response.body.promotions.appliances.suppliers.hasOwnProperty('AEG')) {
                                    this.setPromotionOnApplianceSupplier('AEG', response.body.promotions.appliances.suppliers.AEG);
                                }
                            }

                            if (response.body.promotions.appliances.added && response.body.promotions.appliances.added.length) {
                                response.body.promotions.appliances.added.forEach((item) => {
                                    this.basket.items.push(item);
                                });
                            }

                            if (response.body.promotions.appliances.removed && response.body.promotions.appliances.removed.length) {
                                this.basket.items = this.basket.items.filter(
                                    (item) => response.body.promotions.appliances.removed.indexOf(item.id) === -1
                                );
                            }
                        }

                        let queries = [];
                        queries.push(this.updatePanDrawerUpgrade(response.body?.panDrawerUpgrade));

                        if (item.group === ProductType.SAMPLE_DOORS) {
                            queries.push(this.recalculateSampleDoors());
                        }

                        Promise.all(queries)
                            .then(() => {
                                resolve();
                                // this.resetRanks()
                                //     .then(() => resolve())
                                //     .catch((error) => reject(error));
                            })
                            .catch(() => resolve());
                    } else {
                        // TODO: handle the error?
                        this.handleError(response.body);

                        reject(response.body);
                    }
                },
                error: (error) => {
                    // TODO: handle the error?
                    this.handleError(error);

                    reject(error);
                }
            });
        });
    }

    public removeItem(itemId: number): Promise<void> {
        return new Promise((resolve) => {
            if (this.hasPassedCutOffDate()) {
                return resolve();
            }

            // Promotion Item Check
            const item = this.basket.items.find((basketItem) => basketItem.id === itemId);
            const promotionalItemPromise: Promise<IPromotionBasketItems> = item ? this.configurePromotionalItems(item) : Promise.resolve(null);
            const removeItemPromises: Promise<void>[] = [
                this.delBasketItemAPI(itemId)
            ];
            promotionalItemPromise
                .then((items) => {
                    if (typeof items === 'object' && Array.isArray(items.removeItems)) {
                        items.removeItems.forEach((item) => {
                            if (!items.addItems.some((addItem) => addItem.code === item.code)) {
                                removeItemPromises.push(this.delBasketItemAPI(item.id));
                            }
                        });
                    }
                    Promise.all(removeItemPromises)
                        .then(() => resolve())
                        .catch((error) => {
                            this.dialogService.error(this.constructor.name, error);
                            resolve();
                        })
                })
                .catch((error) => {
                    this.dialogService.error(this.constructor.name, error);
                    Promise.all(removeItemPromises)
                        .then(() => resolve())
                        .catch((error) => {
                            this.dialogService.error(this.constructor.name, error);
                            resolve();
                        })
                });
        });
    }

    private recalculateSampleDoors(): Promise<void> {
        return new Promise((resolve, reject) => {
            let basketSampleDoors = this.basket.items.filter((basketItem) => basketItem.group === ProductType.SAMPLE_DOORS);
            if (basketSampleDoors && basketSampleDoors.length) {
                this.catalogueService.getSampleDoors()
                    .then((sampleDoors) => {
                        sampleDoors = sampleDoors.map((sampleDoor) => sampleDoor.item);
                        basketSampleDoors.forEach((basketItem) => {
                            let findItem = sampleDoors.find((sample) => sample.desc === basketItem.description);
                            if (findItem) {
                                basketItem.origCost = parseInt(findItem.cost, 10);
                            }
                        });

                        basketSampleDoors.sort((a, b) => {
                            return a.origCost > b.origCost
                                ? 1
                                : (b.origCost > a.origCost ? -1 : 0) || a.description.localeCompare(b.description);
                        });

                        let free = 0;
                        let queries = [];
                        basketSampleDoors.forEach((basketItem) => {
                            if (free++ < this.freeDoorSampleAllowance) {
                                queries.push(this.updateItem(basketItem.id, { cost: 0 }));
                            } else {
                                queries.push(this.updateItem(basketItem.id, { cost: basketItem.origCost || 0 }));
                            }
                        });

                        Promise.all(queries)
                            .then(() => resolve())
                            .catch((error) => reject(error));
                    })
                    .catch((error) => reject(error));
            } else {
                resolve();
            }
        });
    }

    public removeSolidSurface(worktopConfigUuid): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.basket && this.basket.items && this.basket.items.length) {
                const item = this.basket.items.find((basketItem) => {
                    return basketItem.code === 'SOLIDSURFACEWORKTOP' && basketItem.worktopConfigUuid === worktopConfigUuid;
                });

                if (item) {
                    this.removeItem(item.id)
                        .then(() => resolve())
                        .catch((error) => reject(error));
                } else {
                    resolve();
                }
            } else {
                resolve();
            }
        });
    }

    private mergeSoftDeleteFlag(oldBasket: Basket): void {
        if (Array.isArray(this.basket.items) && Array.isArray(oldBasket.items)) {
            this.basket.items.forEach((basketItem) => {
                const oldItem = oldBasket.items.find((old) => old.id === basketItem.id);
                if (oldItem && oldItem.orderItemToBeDeleted) {
                    basketItem.orderItemToBeDeleted = oldItem.orderItemToBeDeleted;
                }
            });
        }
    }

    /** Temporary measure to support order editing with soft deletion. The whole solution needs refinement! */
    public localUpdateBasket(basket): void {
        this.basket = basket;
        this.updateBalance();
    }

    public updateItem(itemId: number, changes: IBasketUpdateItem): Promise<void> {
        return new Promise((resolve, reject) => {
            let updatePromise: Promise<void> = Promise.resolve();
            let qtyChange = 0;

            if (!this.hasPassedCutOffDate()) {
                let updateItem = false;
                let item = this.basket && this.basket.items && this.basket.items.length ? this.basket.items.find((i) => i.id === itemId) : null;

                if (item) {
                    if (typeof changes.carcaseColour !== 'undefined' && item.carcaseColour !== changes.carcaseColour) {
                        updateItem = true;
                    }

                    if (typeof changes.softClose !== 'undefined' && item.softClose != changes.softClose) {
                        // Deliberately != rather !== as item.softClose can be 1 or true
                        updateItem = true;
                    }

                    if (typeof changes.handing !== 'undefined' && item.handing !== changes.handing) {
                        updateItem = true;
                    }

                    if (typeof changes.isDryAssembled !== 'undefined' && item.isDryAssembled !== changes.isDryAssembled) {
                        updateItem = true;
                    }

                    if (typeof changes.qty !== 'undefined' && item.qty !== changes.qty) {
                        updateItem = true;
                        qtyChange = changes.qty - item.qty;
                    }

                    if (typeof changes.cost !== 'undefined' && item.cost !== changes.cost) {
                        updateItem = true;
                    }

                    if (typeof changes.width !== undefined && item.width !== changes.width) {
                        updateItem = true;
                    }

                    if (typeof changes.height !== undefined && item.height !== changes.height) {
                        updateItem = true;
                    }
                }

                if (updateItem) {
                    updatePromise = this.updateBasketItemAPI(itemId, changes, item);
                }
            }


            updatePromise
                .then(() => {
                    this.configurePromotionalItems(null, null, null, qtyChange)
                        .then((items) => {
                            const addRemovePromises: Promise<void>[] = [
                                this.postBasketItems(null, items.addItems)
                            ];
                            items.removeItems.forEach((removeItem) => {
                                addRemovePromises.push(
                                    this.delBasketItemAPI(removeItem.id)
                                );
                            });
                            return Promise.all(addRemovePromises);
                        })
                        .then(() => resolve())
                        .catch((error) => {
                            this.dialogService.error(this.constructor.name, error);
                            resolve();
                        });
                })
                .catch((error) => {
                    this.dialogService.error(this.constructor.name, error);
                    reject(error);
                });
        });
    }

    private updateBasketItemAPI(itemId: number, changes: IBasketUpdateItem, item: BasketItem): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket/${this.basket.uuid}/item/${itemId}`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken
                }
            };
            const params = changes;

            this.httpApi.put<HttpApiResponse<any>>(url, params, options).subscribe({
                next: (response) => {
                    if (response && response.success) {
                        this.basket.modifiedAt = DateHelper.now();

                        if (typeof changes.carcaseColour !== 'undefined' && item.carcaseColour !== changes.carcaseColour) {
                            item.carcaseColour = changes.carcaseColour;
                        }

                        if (typeof changes.softClose !== 'undefined' && item.softClose != changes.softClose) {
                            // Deliberately != rather !== as item.softClose can be 1 or true
                            item.softClose = changes.softClose;
                            if (item.salePrice) {
                                // Update the salePrice with the softClose cost, already accounted for in item.cost but salePrice needs to reflect it.
                                if (item.hingeCost && item.softClose) {
                                    item.salePrice += item.hingeCost;
                                } else if (item.hingeCost && !item.softClose) {
                                    item.salePrice -= item.hingeCost;
                                }
                            }
                        }

                        if (typeof changes.handing !== 'undefined' && item.handing !== changes.handing) {
                            item.handing = changes.handing;
                        }

                        if (typeof changes.isDryAssembled !== 'undefined' && item.isDryAssembled !== changes.isDryAssembled) {
                            item.isDryAssembled = changes.isDryAssembled;
                        }

                        if (typeof changes.qty !== 'undefined' && item.qty !== changes.qty) {
                            item.qty = changes.qty;
                        }

                        if (typeof response.body !== 'undefined') {
                            item.cost = response.body;
                        }

                        if (typeof changes.width !== 'undefined' && item.width !== changes.width) {
                            item.width = changes.width;
                        }

                        if (typeof changes.height !== 'undefined' && item.height !== changes.height) {
                            item.height = changes.height;
                        }

                        this.save()
                            .then(() => {
                                this.emitBasket();

                                resolve();
                            })
                            .catch((error) => {
                                this.handleError(error);
                                this.emitBasket();

                                resolve();
                            });
                    } else {
                        this.handleError(response.body);

                        reject(response.body);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public duplicateItem(itemId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.hasPassedCutOffDate()) {
                return resolve();
            }

            const nextItemRank = this.nextItemRank();
            let basketItem = this.clone<BasketItem>(this.basket.items.find((item) => item.id === itemId));
            let children = [];

            if (basketItem && !basketItem.doors && !basketItem.ifDoors) {
                let childRank = 1;
                children = this.basket.items
                    .filter((item) => item.rankParent === basketItem.rank)
                    .map((item) => {
                        let newItem = this.clone<BasketItem>(item);
                        newItem.rank = `${nextItemRank}`;
                        newItem.rankParent = `${nextItemRank}.${childRank}`;

                        childRank++;

                        return newItem;
                    });
            }

            basketItem.rank = `${nextItemRank}`;

            let queries = [];
            queries.push(this.addConfiguredItem(basketItem, children || null));

            if (basketItem.group === ProductType.SAMPLE_DOORS) {
                queries.push(this.recalculateSampleDoors());
            }

            Promise.all(queries)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    }

    public clone<T>(data: T): T {
        return JSON.parse(JSON.stringify(data));
    }

    /**
     * @deprecated
     * @param basketItems 
     * @returns 
     */
    private promotionParams(basketItems?: BasketItem[]) {
        let allBasketItems = [];
        let promotionParams = {
            nextRank: this.nextItemRank(),
            appliances: {
                freeItems: [],
                suppliers: {
                    AEG: 0,
                    CDA: 0
                }
            }
        };

        if (this.basket && this.basket.items && this.basket.items.length) {
            allBasketItems = this.clone<BasketItem[]>(this.basket.items);
        }

        if (basketItems && basketItems.length) {
            allBasketItems = allBasketItems.concat(this.clone<BasketItem[]>(basketItems));
        }

        allBasketItems.forEach((basketItem) => {
            if (basketItem.group === ProductType.APPLIANCES && !basketItem.isPromotion) {
                if (promotionParams.appliances.suppliers[basketItem.supplier] >= 0) {
                    promotionParams.appliances.suppliers[basketItem.supplier] += basketItem.qty;
                }
            }
        });

        promotionParams.appliances.freeItems = this.basket.items.filter(
            (basketItem) => basketItem.isPromotion && basketItem.group === ProductType.APPLIANCES
        );

        return promotionParams;
    }

    public addNote(basketItemId: number, note: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket/${this.basket.uuid}/note`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken
                }
            };

            const params = {
                basketItemId,
                note
            };

            this.httpApi.post<HttpApiResponse<any>>(url, params, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        let foundBasketItem = this.basket.items.find((basketItem) => basketItem.id === basketItemId);
                        if (foundBasketItem) {
                            if (!foundBasketItem.notes) {
                                foundBasketItem.notes = [];
                            }

                            foundBasketItem.notes.push({
                                id: response.body,
                                basketId: this.basket.id || null,
                                basketItemId: basketItemId,
                                createdBy: `2:${this.authCustomerService.details.id}`,
                                note: note,
                                isAccessIssue: false
                            });
                        }
                        this.emitBasket();

                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public deleteBasketItemNote(note: BasketNote): Promise<void> {
        return new Promise((resolve, reject) => {
            let params = {
                uuid: this.basket.uuid,
                basketItemId: note.basketItemId,
                noteId: note.id
            };
            const endpoint = `basket/${this.basket.uuid}/note-delete`;
            const url = `${this.config.api.endpoints.diy}/${endpoint}`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken
                }
            };
            this.httpApi.post<HttpApiResponse<void>>(url, params, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        const foundBasketItem = this.basket.items.find((basketItem) => basketItem.id === note.basketItemId);
                        if (foundBasketItem) {
                            const noteIdx = foundBasketItem.notes.findIndex((itemNote: BasketNote) => itemNote.id === note.id);
                            if (noteIdx !== -1) {
                                foundBasketItem.notes.splice(noteIdx, 1);
                            }
                        }
                        this.emitBasket();
                        resolve();
                    } else {
                        reject(response.body);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public validateStyleChange(oldBasketItems: BasketItem[], style: ActiveRange): Promise<IBasketValidateStyleParams> {
        return new Promise((resolve, reject) => {
            if (oldBasketItems && oldBasketItems.length) {
                let newBasket = new Basket();

                oldBasketItems.forEach((oldBasketItem) => {
                    let newBasketItem = this.clone<BasketItem>(oldBasketItem);

                    newBasketItem.rangeId = style.range.id;
                    newBasketItem.range = style.range.name;
                    newBasketItem.colour = style.colour;
                    newBasketItem.bespokeColour = style.bespokeColour;
                    newBasketItem.isInframe = style.range.isInframe || false;

                    if (style.carcaseColour) {
                        newBasketItem.carcaseColour = style.carcaseColour;
                    }

                    if (style.rangeColour) {
                        newBasketItem.rangeColour = style.rangeColour;
                    }

                    newBasket.items.push(newBasketItem);
                });

                const url = `${this.config.api.endpoints.diy}/basket/${this.basket.uuid}/style`;
                const options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id,
                        UserToken: this.authCustomerService.universalToken
                    }
                };
                const params = {
                    basket: newBasket
                };

                this.httpApi.post<HttpApiResponse<IBasketValidateStyleResponse>>(url, params, options).subscribe({
                    next: (response) => {
                        if (
                            response &&
                            response.success &&
                            response.body &&
                            response.body.basket &&
                            response.body.basket.items
                        ) {
                            let newBasketItems = [];
                            let basketItemsUnavailable = [];

                            response.body.basket.items.forEach((newBasketItem) => {
                                if (newBasketItem.notAvailableInStyle) {
                                    basketItemsUnavailable.push(newBasketItem);
                                } else {
                                    newBasketItems.push(newBasketItem);
                                }
                            });

                            resolve({
                                basketItems: newBasketItems,
                                basketItemsUnavailable: basketItemsUnavailable
                            });
                        } else {
                            // TODO: handle the error?
                            reject(response.body);
                        }
                    },
                    error: (error) => {
                        // TODO: handle the error?
                        reject(error);
                    }
                });
            } else {
                resolve(null);
            }
        });
    }

    public applyStyleChange(addItems: BasketItem[], updateItems: BasketItem[], removeItems: number[]): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket/${this.basket.uuid}/style`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };
            const params = {
                add: addItems,
                remove: removeItems,
                update: updateItems
            };

            this.httpApi.put<HttpApiResponse<any>>(url, params, options).subscribe({
                next: (response) => {
                    if (response && response.success) {
                        this.loadFromApi(this.basket.uuid)
                            .then(() => resolve())
                            .catch((error) => {
                                this.dialogService.error(this.constructor.name, error);
                            });
                    } else {
                        // TODO: handle the error?
                        reject(response.body);
                    }
                },
                error: (error) => {
                    // TODO: handle the error?
                    reject(error);
                }
            });
        });
    }

    public convertToOrder(uuid: string, payWithBacs: boolean = false): Promise<Basket> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/diy/basket/${uuid}/to-order`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };
            const params = {
                payWithBacs: payWithBacs
            };

            this.httpApi.post<HttpApiResponse<Basket>>(url, params, options).subscribe({
                next: (response) => {
                    if (response && response.success) {
                        this.clearAll();
                        resolve(response.body);
                    } else {
                        reject(response.body);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public cancelOrderEdit(uuid: string, orderNumber: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/order/${uuid}/${orderNumber}/cancel-order-edit`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.del<HttpApiResponse<Basket>>(url, options).subscribe({
                next: () => {
                    resolve();
                },
                error: (error) => reject(error)
            });
        });
    }

    public createQuote(
        customerId,
        competitor,
        competitorPrice,
        leadInformation,
        quoteDetails
    ): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket/staff/${this.basket.uuid}/transfer`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    ApplicationToken: this.authCustomerService.applicationToken
                }
            };
            const params = {
                customerId: customerId,
                competitor: competitor,
                competitorPrice: competitorPrice,
                leadInformation: leadInformation,
                quoteDetails: quoteDetails,
                isQuote: true
            };

            this.httpApi.post<HttpApiResponse<any>>(url, params, options).subscribe({
                next: (response) => {
                    if (response && response.success) {
                        this.clearAll(true, true)
                            .then(() => {
                                this.emitBasket();

                                resolve();
                            })
                            .catch((error) => {
                                this.dialogService.error(this.constructor.name, error);
                            });
                    } else {
                        // TODO: handle the error?
                        reject(response.body);
                    }
                },
                error: (error) => {
                    // TODO: handle the error?
                    reject(error);
                }
            });
        });
    }

    // TODO: update to uuid on anything that calls it
    public paymentDetailsFromApi(uuid: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = `${this.config.api.endpoints.diy}/basket/${uuid}/payment-details`;
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken
                }
            };
            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    // TODO: should just be looking at this.basket instead of passing in the basket?
    public paymentDetails(basket: Basket) {
        let paymentDetails = {
            oustandingAmount: NumberHelper.toPoundsAndPence((basket.cost + (basket.deliveryCost || 0) + (basket.deliverySurcharge || 0)) * 1.2),
            totalPaid: 0,
            preauthAmount: 0,
            preauthRefunded: 0,
            saleAmount: 0,
            saleRefunded: 0
        };

        if (basket.paymentHistory && basket.paymentHistory.length) {
            basket.paymentHistory.forEach((payment) => {
                switch (payment.txntype) {
                    case 'preauth':
                        paymentDetails.preauthAmount += payment.chargeTotal;
                        paymentDetails.preauthRefunded += payment.refundedAmount || 0;
                        paymentDetails.oustandingAmount -= payment.chargeTotal;

                        break;
                    case 'sale':
                        paymentDetails.saleAmount += payment.chargeTotal;
                        paymentDetails.saleRefunded += payment.refundedAmount || 0;
                        paymentDetails.oustandingAmount = paymentDetails.oustandingAmount - payment.chargeTotal + (payment.refundedAmount || 0);

                        break;
                }

                if (payment.isManualPayment) {
                    paymentDetails.saleAmount += payment.chargeTotal || 0;
                    paymentDetails.saleRefunded += payment.refundedAmount || 0;
                    paymentDetails.oustandingAmount = paymentDetails.oustandingAmount - payment.chargeTotal + (payment.refundedAmount || 0);
                }
            });
        }

        paymentDetails = {
            oustandingAmount: NumberHelper.toPoundsAndPence(paymentDetails.oustandingAmount),
            totalPaid: NumberHelper.toPoundsAndPence(paymentDetails.totalPaid),
            preauthAmount: NumberHelper.toPoundsAndPence(paymentDetails.preauthAmount),
            preauthRefunded: NumberHelper.toPoundsAndPence(paymentDetails.preauthRefunded),
            saleAmount: NumberHelper.toPoundsAndPence(paymentDetails.saleAmount),
            saleRefunded: NumberHelper.toPoundsAndPence(paymentDetails.saleRefunded)
        };

        return paymentDetails;
    }

    private nextItemRank(): number {
        let nextItemRank = 1;

        if (this.basket && this.basket.items && this.basket.items.length) {
            this.basket.items.forEach((item) => {
                if (item.rankParent === '0') {
                    const rank = parseInt(item.rank, 10);
                    if (rank >= nextItemRank) {
                        nextItemRank = rank + 1;
                    }
                }
            });
        }

        return nextItemRank;
    }

    private sortBasket(basketItems: BasketItem[], resetRanks: boolean = true): void {
        const unitsCategory = 'Units';
        const nonRangeGroup = 'Non Range Products';
        if (basketItems && basketItems.length) {
            this.sortItemsByRank(basketItems);

            basketItems.forEach((item) => {
                item._sectionGroup = nonRangeGroup;
                if (item.range) {
                    item._sectionGroup = `${item.range} - ${item.rangeColour}`;
                    if (item.rangeColour === 'Bespoke' && item.bespokeColour && item.bespokeColour.length) {
                        item._sectionGroup = `${item.range} - ${item.rangeColour} - ${item.bespokeColour}`;
                    }
                }

                item._sectionCategory = item.group === ProductType.CABINETS ? unitsCategory : item.group;
                if (
                    item.rankParent !== '0' &&
                    (
                        item.group === ProductType.DOORS ||
                        item.group === ProductType.SURCHARGES ||
                        item.group === ProductType.DISCOUNTS
                    )
                ) {
                    const parentItem = basketItems.find((i) => i.rank === item.rankParent);
                    if (parentItem) {
                        item._sectionGroup = parentItem._sectionGroup;
                        item._sectionCategory = parentItem.group === ProductType.CABINETS ? unitsCategory : parentItem.group;
                    }
                } else {
                    if (!item.currentImage && item.code === 'PDSIDES') {
                        item.currentImage = 'https://static.diy-kitchens.com/assets/images/carcase/pandrawer_high_sided.jpg';
                    } else if (!item.currentImage && item.code === 'PDUPGRADEGLASS') {
                        item.currentImage = 'https://static.diy-kitchens.com/assets/images/carcase/pandrawer_glass.jpg';
                    } else if (!item.currentImage && item?.group === ProductType.RANGE_PRODUCTS && item.categoryImage) {
                        if (item.categoryImage.startsWith('/assets/')) {
                            item.currentImage = `${this.config.api.endpoints.cdn}${item.categoryImage}`;
                        }
                    }
                }
            });

            if (resetRanks) {
                basketItems.sort((a, b) => {
                    const aSectionCategory = a._sectionCategory === unitsCategory ? ProductType.CABINETS : a._sectionCategory;
                    const bSectionCategory = b._sectionCategory === unitsCategory ? ProductType.CABINETS : b._sectionCategory;

                    if (a._sectionGroup < b._sectionGroup) {
                        if (a._sectionGroup === nonRangeGroup) {
                            return 1;
                        }

                        return -1;
                    } else if (a._sectionGroup > b._sectionGroup) {
                        if (b._sectionGroup === nonRangeGroup) {
                            return -1;
                        }

                        return 1;
                    } else if (aSectionCategory < bSectionCategory) {
                        if (b._sectionCategory === unitsCategory) {
                            return 1;
                        }

                        return -1;
                    } else if (aSectionCategory > bSectionCategory) {
                        if (a._sectionCategory === unitsCategory) {
                            return -1;
                        }

                        return 1;
                    }

                    return this.sortItemByRank(a, b);
                });

                this.resetRanks();
            }
        }
    }

    private sortItemsByRank(items) {
        if (items && items.rank) {
            items.sort((a, b) => {
                return this.sortItemByRank(a, b);
            });
        }
    }

    private sortItemByRank(a, b) {
        // String Rank
        let aRank = `${a.rank}`;
        let bRank = `${b.rank}`;
        // Split Rank
        let aR = aRank.split(/[\.\/]/);
        let bR = bRank.split(/[\.\/]/);
        // Rank Parent
        let aRP = parseInt(aR[0] || '0', 10);
        let bRP = parseInt(bR[0] || '0', 10);
        // Rank Sub
        let aRS = parseInt(aR[1] || '0', 10);
        let bRS = parseInt(bR[1] || '0', 10);
        // Rank Component
        let aComponent = aRank.match('/') ? true : false;
        let bComponent = bRank.match('/') ? true : false;

        if (aRP < bRP) {
            return -1;
        } else if (aRP > bRP) {
            return 1;
        } else {
            if (!aRS && bRS) {
                return -1;
            } else if (aRS && !bRS) {
                return 1;
            } else if (!aRS && !bRS) {
                return 0;
            } else if (aComponent && !bComponent) {
                return -1;
            } else if (!aComponent && bComponent) {
                return 1;
            } else if (aRS < bRS) {
                return -1;
            } else if (aRS > bRS) {
                return 1;
            }
        }

        return 0;
    }

    private resetRanks(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.basket && this.basket.items && this.basket.items.length) {
                let originalRanks: { [x: number]: { rank: string; rankParent: string } } = {};
                let rankChanges = [];

                // TODO: might be able to simplify this, need data to test though
                let sortIndex: string[] = [];
                let sortItems: any = {};
                this.basket.items.forEach((item) => {
                    originalRanks[item.id] = {
                        rank: item.rank,
                        rankParent: item.rankParent
                    };

                    const rankParent = item.rankParent === '0' ? item.rank : item.rankParent;
                    if (!sortItems[rankParent]) {
                        sortItems[rankParent] = {
                            parent: null,
                            children: []
                        };
                    }

                    if (item.rankParent === '0') {
                        sortIndex.push(rankParent);
                        sortItems[rankParent].parent = item;
                    } else {
                        sortItems[rankParent].children.push(item);
                    }
                });

                let rank = 1;
                sortIndex.forEach((key) => {
                    let rankChild = 1;

                    let sortItem = sortItems[key];
                    if (sortItem.parent) {
                        sortItem.parent.rank = rank.toString();

                        if (originalRanks[sortItem.parent.id]) {
                            let originalRank = originalRanks[sortItem.parent.id];
                            if (
                                originalRank &&
                                (originalRank.rank !== sortItem.parent.rank || originalRank.rankParent !== sortItem.parent.rankParent)
                            ) {
                                rankChanges.push({
                                    id: sortItem.parent.id,
                                    rank: sortItem.parent.rank,
                                    rankParent: sortItem.parent.rankParent
                                });
                            }
                        }
                    }

                    if (sortItem.children && sortItem.children.length) {
                        sortItem.children.forEach((child) => {
                            child.rank = `${rank}.${rankChild}`;
                            child.rankParent = rank.toString();

                            let originalRank = originalRanks[child.id];
                            if (
                                originalRank &&
                                (originalRank.rank !== child.rank || originalRank.rankParent !== child.rankParent)
                            ) {
                                rankChanges.push({
                                    id: child.id,
                                    rank: child.rank,
                                    rankParent: child.rankParent
                                });
                            }

                            rankChild++;
                        });
                    }

                    rank++;
                });

                if (rankChanges.length) {
                    const url = `${this.config.api.endpoints.diy}/basket/${this.basket.uuid}/items/update-ranks`;
                    const options: HttpApiOptions = {
                        authorization: {
                            ApplicationId: this.config.app.id,
                            UserToken: this.authCustomerService.universalToken
                        }
                    };
                    const params = {
                        ranks: rankChanges
                    };

                    this.httpApi.put<HttpApiResponse<Basket>>(url, params, options).subscribe({
                        next: (response) => {
                            if (response && response.success) {
                                this.save()
                                    .then(() => resolve())
                                    .catch(() => resolve());
                            } else {
                                // TODO: handle error?
                                reject(response.body);
                            }
                        },
                        error: (error) => {
                            // TODO: handle error?
                            this.dialogService.error(this.constructor.name, error);
                            reject(error);
                        }
                    });
                } else {
                    resolve();
                }
            } else {
                resolve();
            }
        });
    }

    public createBasketSections(basketItems: BasketItem[], sortRanks: boolean = true): Promise<any> {
        return new Promise((resolve, reject) => {
            this.sortBasket(basketItems, sortRanks);

            Promise.all([
                this.catalogueService.getCabinetsFlat(),
                // this.catalogueService.getUnitsAndDoors(),
                this.catalogueService.getStylesList()
            ])
                .then(([cabinetsFlat, styles]) => {
                    resolve(this.createSections(cabinetsFlat, cabinetsFlat, styles, basketItems));
                })
                .catch((error) => reject(error));
        });
    }

    /** Utility function to return the basket item cost, takes into account the item may be free! */
    private salePriceOrItemCost(item: BasketItem): number {
        if (item.isPromotion && item.promotionPercentage && !isNaN(item.salePrice)) {
            return item.salePrice;
        } else {
            return item.salePrice || item.cost;
        }
    }

    // TODO: change this so that it doesn't alter the basket items? can move away from JSON parse / stringify - less processing
    private createSections(cabinetsFlat, units, styles, basketItems: BasketItem[]): any[] {
        const sectionsObject: any = {};

        const items = JSON.parse(JSON.stringify(basketItems));
        if (items && items.length) {
            items.forEach((item) => {
                const cabinet = cabinetsFlat.find((cab) => cab.unit_code === item.code || item.productCode);
                if (cabinet) {
                    item._cabinet = cabinet;
                }

                if (!sectionsObject[item._sectionGroup]) {
                    sectionsObject[item._sectionGroup] = {};
                }

                if (!sectionsObject[item._sectionGroup][item._sectionCategory]) {
                    sectionsObject[item._sectionGroup][item._sectionCategory] = [];
                }

                if (item.rankParent === '0') {
                    item.total = this.salePriceOrItemCost(item) * item.qty;
                    item._children = [];
                } else {
                    const parentItem = items.find((i) => i.rank === item.rankParent);
                    if (parentItem) {
                        if (parentItem._cabinet && parentItem._cabinet.childCodeUsed) {
                            item.ignore = true;

                            if (!parentItem._children) {
                                parentItem.total = (parentItem.salePrice || parentItem.cost) * parentItem.qty;
                                parentItem._children = [];
                            }

                            parentItem.code = item.code;
                            parentItem.description = item.description;
                            parentItem.total = parentItem.total + this.salePriceOrItemCost(item);

                            parentItem.cost = parentItem.cost + item.cost;

                            if (parentItem.salePrice) {
                                parentItem.salePrice = parentItem.salePrice + (this.salePriceOrItemCost(item) * item.qty);
                            } else if (item.salePrice) {
                                parentItem.salePrice = parentItem.cost + item.salePrice;
                            }

                            if (parentItem.isInframe) {
                                item.cost = 0;
                                item.salePrice = 0;
                            }
                        } else {
                            if (!parentItem._children) {
                                parentItem.total = (parentItem.salePrice || parentItem.cost) * parentItem.qty;
                                parentItem._children = [];
                            }

                            parentItem.total = parentItem.total + (this.salePriceOrItemCost(item) * item.qty);
                            parentItem._children.push(item);

                            if (parentItem.isInframe) {
                                parentItem.cost = parentItem.cost + item.cost;

                                if (parentItem.salePrice) {
                                    parentItem.salePrice = parentItem.salePrice + (this.salePriceOrItemCost(item) * item.qty);
                                } else if (item.salePrice) {
                                    parentItem.salePrice = parentItem.cost + item.salePrice;
                                }

                                item.cost = 0;
                                item.salePrice = 0;
                            }
                        }
                    }
                }

                sectionsObject[item._sectionGroup][item._sectionCategory].push(item);
            });
        }

        let sections: any[] = Object.keys(sectionsObject).map((sectionGroup) => {
            const section: any = {
                title: sectionGroup,
                image: null,
                summary: '',
                subSections: []
            };

            const style = styles.find((s) => s.style.toLowerCase() === section.title);
            if (style && style.doorImage) {
                section.image = style.doorImage;
            }

            Object.keys(sectionsObject[sectionGroup]).forEach((sectionCategory) => {
                if (sectionsObject[sectionGroup][sectionCategory].length > 0) {
                    const sectionItems = sectionsObject[sectionGroup][sectionCategory];
                    const summary = this.sectionSummary(sectionItems[0]);

                    section.subSections.push({
                        summary: summary,
                        category: sectionCategory,
                        items: sectionsObject[sectionGroup][sectionCategory].filter((item) => {
                            return (item.ignore) ? false : true;
                        })
                    });
                }
            });

            return section;
        });
        sections.sort(function (a, b) {
            if (a.title === 'Non Range Products') {
                return 1;
            } else {
                if (a.title < b.title) {
                    return -1;
                } else if (a.title > b.title) {
                    return 1;
                }

                return 0;
            }
        });
        sections = sections.filter((section) => {
            section.subSections = section.subSections.filter((subsection) => {
                subsection.items = subsection.items.filter((item) => {
                    const newItem = units.find((unit) => {
                        // let unitlink = this.urlHelper.routeEnd(item);
                        // if (unitlink) {
                        //     return unitlink === this.urlHelper.routeEnd(unit);
                        // } else {
                        return (unit.unit_code || '').toLowerCase() === (item.code || '').toLowerCase() ||
                            (unit.inframe_code || '').toLowerCase() === (item.code || '').toLowerCase();
                        // }
                    });

                    if (newItem?.handed) {
                        item['handed'] = newItem.handed;
                    }

                    let doorStyle = 'Highline';
                    if (
                        item.group === 'Doors' &&
                        subsection.category === 'Units' &&
                        item.rankParent
                        && item.rankParent !== '0'
                    ) {
                        const parentUnit = subsection.items.find((unit) => unit.rank === item.rankParent);
                        if (parentUnit) {
                            const frontals = (this.catalogueService.activeRange?.range)
                                ? this.catalogueService.getDoorForUnit(parentUnit.unit_code, 'Alabaster')
                                : null;
                            if (frontals) {
                                let doorStyle = parentUnit?.subCategory === 'Drawerline' || parentUnit?.description?.match(/drawerline/i) ? 'Drawerline' : 'Highline';
                                // Switch drawerline image to slab if other frontals (i.e drawers)
                                doorStyle = (doorStyle === 'Drawerline' && Object.keys(frontals).length > 1) ? 'Highline' : doorStyle;
                            }
                        }
                    }

                    this.catalogueService.getImageForItem(item, doorStyle)
                        .then((result: any) => {
                            if (!item.currentImage) {
                                item.currentImage = result.image || item.categoryImage;
                            }
                        })
                        .catch((error) => this.dialogService.error(this.constructor.name, error));

                    if (item.rankParent === '0') {
                        return true;
                    }

                    return false;
                });

                if (subsection.items && subsection.items.length) {
                    return true;
                }

                return false;
            });

            if (section.subSections && section.subSections.length) {
                return true;
            }

            return false;
        });

        return sections;
    }

    private sectionSummary(item: BasketItem) {
        switch (item.group) {
            default:
                return '';
            case ProductType.CABINETS:
            case ProductType.DOORS:
                const range = item.range ? item.range : '';
                const colour = item.rangeColour ? item.rangeColour : '';
                const cabinetColour = item.carcaseColour ? item.carcaseColour : '';

                return `${range} ${colour} doors with ${cabinetColour} carcase`;
        }
    }

    // TODO: uses user token, does this work on not logged in? I'm guessing we can make it work now?
    public getBasketHistory(basketId): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = `${this.config.api.endpoints.diy}/diy/basket/${basketId}/history`;
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken
                }
            };
            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public getDrawerCostAndQty(panDrawerQty: number = 0, drawerBoxQty: number = 0): Promise<IDrawerCostAndQty> {
        return new Promise((resolve, reject) => {
            if (this.basket) {
                this.catalogueService.getCatalogue()
                    .then((catalogue) => {
                        let drawers: IDrawerCostAndQty = {
                            panDrawers: {
                                qty: panDrawerQty,
                                costs: {
                                    [PanDrawerUpgrade.HIGH_SIDES]: 0,
                                    [PanDrawerUpgrade.FROSTED_SIDES]: 0
                                }
                            },
                            drawerBoxes: {
                                qty: drawerBoxQty,
                                costs: {
                                    [DrawerBoxUpgrade.DOVETAILS]: 0
                                }
                            }
                        };

                        this.basket.items.forEach((item) => {
                            const cabinet = catalogue && catalogue.products ? catalogue.products[item.code] || null : null;
                            if (cabinet && cabinet.panDrawerQty) {
                                drawers.panDrawers.qty += cabinet.panDrawerQty;
                            }

                            if (cabinet && cabinet.drawerBoxQty) {
                                drawers.drawerBoxes.qty += cabinet.drawerBoxQty;
                            }
                        });

                        drawers.panDrawers.costs[PanDrawerUpgrade.HIGH_SIDES] = PanDrawerUpgradeCost.HIGH_SIDES;
                        drawers.panDrawers.costs[PanDrawerUpgrade.FROSTED_SIDES] = PanDrawerUpgradeCost.FROSTED_SIDES;

                        drawers.drawerBoxes.costs[DrawerBoxUpgrade.DOVETAILS] = DrawerBoxUpgradeCost.DOVETAILS;

                        resolve(drawers);
                    })
                    .catch((error) => reject(error));
            } else {
                resolve(null);
            }
        });
    }

    public getDrawerUpgrades() {
        let drawerUpgrades = {
            panDrawer: PanDrawerUpgrade.NONE,
            drawerBox: DrawerBoxUpgrade.NONE
        };

        if (this.basket) {
            if (this.basket.panDrawerUpgrade) {
                drawerUpgrades.panDrawer = this.basket.panDrawerUpgrade;
            }

            if (this.basket.drawerBoxUpgrade) {
                drawerUpgrades.drawerBox = this.basket.drawerBoxUpgrade;
            }
        }

        return drawerUpgrades;
    }

    public setDrawerUpgrade(panDrawerUpgrade: PanDrawerUpgrade, drawerBoxUpgrade: DrawerBoxUpgrade): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.hasPassedCutOffDate()) {
                return resolve();
            }

            this.createBasket()
                .then(() => {
                    const update: boolean = (
                        this.basket.panDrawerUpgrade !== panDrawerUpgrade ||
                        this.basket.drawerBoxUpgrade !== drawerBoxUpgrade
                    ) ? true : false;

                    if (update) {
                        const url = `${this.config.api.endpoints.diy}/basket/${this.basket.uuid}/drawer-upgrade`;
                        const options: HttpApiOptions = {
                            authorization: {
                                ApplicationId: this.config.app.id,
                                UserToken: this.authCustomerService.universalToken
                            }
                        };
                        const params = {
                            panDrawerUpgrade: panDrawerUpgrade,
                            drawerBoxUpgrade: drawerBoxUpgrade
                        };

                        this.httpApi.put<HttpApiResponse<{ panDrawerUpgrade: BasketItem }>>(url, params, options).subscribe({
                            next: (response) => {
                                if (response && response.success) {
                                    this.basket.modifiedAt = DateHelper.now();
                                    if (this.basket.drawerBoxUpgrade !== drawerBoxUpgrade) {
                                        this.basket.drawerBoxUpgrade = drawerBoxUpgrade;

                                        this.basket.items.forEach((item) => {
                                            if (item.drawerBoxQty) {
                                                if (drawerBoxUpgrade === DrawerBoxUpgrade.NONE) {
                                                    item.cost = item.cost - item.drawerBoxQty * DrawerBoxUpgradeCost.DOVETAILS;

                                                    if (item.salePrice) {
                                                        item.salePrice = item.salePrice - item.drawerBoxQty * DrawerBoxUpgradeCost.DOVETAILS;
                                                    }
                                                } else {
                                                    item.cost = item.cost + item.drawerBoxQty * DrawerBoxUpgradeCost.DOVETAILS;

                                                    if (item.salePrice) {
                                                        item.salePrice = item.salePrice + item.drawerBoxQty * DrawerBoxUpgradeCost.DOVETAILS;
                                                    }
                                                }
                                            }
                                        });
                                    }

                                    this.updatePanDrawerUpgrade(response.body?.panDrawerUpgrade, panDrawerUpgrade)
                                        .then(() => resolve())
                                        .catch((error) => reject(error));
                                } else {
                                    // TODO: handle the error?
                                    reject(response.body);
                                }
                            },
                            error: (error) => {
                                // TODO: handle the error?
                                reject(error);
                            }
                        });
                    } else {
                        resolve();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    private updatePanDrawerUpgrade(panDrawerItem: BasketItem, panDrawerUpgrade: PanDrawerUpgrade = null): Promise<void> {
        return new Promise((resolve, reject) => {
            if (panDrawerUpgrade !== null) {
                this.basket.panDrawerUpgrade = panDrawerUpgrade;
            }

            let totalPanDrawers = 0;
            this.basket.items = this.basket.items.filter((basketItem) => {
                if (basketItem.panDrawerQty) {
                    totalPanDrawers = totalPanDrawers + basketItem.panDrawerQty;
                }

                if (basketItem.code === PanDrawerCodes.HIGH_SIDES || basketItem.code === PanDrawerCodes.FROSTED_SIDES) {
                    if (!panDrawerItem) {
                        panDrawerItem = basketItem;
                    }

                    return false;
                }

                return true;
            });

            if (panDrawerItem && totalPanDrawers) {
                panDrawerItem.rank = `${this.nextItemRank()}`;
                this.basket.items.push(new BasketItem(panDrawerItem, null, ProductType.DRAWER_BOXES, totalPanDrawers));
            }

            this.emitBasket();

            this.save()
                .then(() => resolve())
                .catch(() => resolve());
        });
    }

    public addresses(): Promise<IBasketAddresses> {
        return new Promise((resolve, reject) => {
            if (this.basket && this.basket.addresses) {
                resolve(this.basket.addresses);
            } else if (this.basket.uuid) {
                const url = `${this.config.api.endpoints.diy}/basket/${this.basket.uuid}/addresses`;
                const options: HttpApiOptions = {
                    authorization: {
                        ApplicationId: this.config.app.id,
                        UserToken: this.authCustomerService.applicationToken
                    }
                };

                this.httpApi.get<HttpApiResponse<IBasketAddresses>>(url, options).subscribe({
                    next: (response) => {
                        if (response.success) {
                            this.basket.addresses = response.body;

                            resolve(response.body);
                        } else {
                            reject(response);
                        }
                    },
                    error: (error) => reject(error)
                });
            } else {
                resolve(null);
            }
        });
    }

    public saveAddresses(billing: IBasketAddress, delivery: IBasketAddress) {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket/${this.basket.uuid}/addresses`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken
                }
            };
            const params = {
                billingAddress: billing,
                deliveryAddress: delivery
            };

            this.httpApi.post<HttpApiResponse<any>>(url, params, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        if (this.basket) {
                            this.basket.addresses = {
                                delivery: delivery,
                                billing: billing
                            };
                        }

                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public setDeliveryAccess(deliveryAccess?: IBasketDeliveryAccess): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket/${this.basket.uuid}/delivery-access`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken
                }
            };
            const params = {
                accessVehicleSize: deliveryAccess ? deliveryAccess.accessVehicleSize : this.basket.accessVehicleSize,
                accessWithin20m: deliveryAccess ? deliveryAccess.accessWithin20m : this.basket.accessWithin20m,
                accessIsSafe: deliveryAccess ? deliveryAccess.accessIsSafe : this.basket.accessIsSafe,
                deliveryLevel: this.basket.deliveryLevel
            };

            this.httpApi.put<HttpApiResponse<{ accessSurcharge: BasketItem }>>(url, params, options).subscribe({
                next: (response) => {
                    if (response.success && response.body) {
                        this.basket.modifiedAt = DateHelper.now();

                        this.updateAccessSurcharge(response.body.accessSurcharge)
                            .then(() => resolve())
                            .catch((error) => reject(error));
                    } else {
                        reject(response.body);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    private updateAccessSurcharge(accessSurcharge: BasketItem): Promise<void> {
        return new Promise((resolve, reject) => {
            this.basket.items = this.basket.items.filter((basketItem) => {
                if (basketItem.code === 'ACCESSSURCHARGE') {
                    return false;
                }

                return true;
            });

            if (accessSurcharge) {
                accessSurcharge.rank = `${this.nextItemRank()}`;
                this.basket.items.push(new BasketItem(accessSurcharge, null, ProductType.DELIVERY));
            }

            // this.emitBasket();
            this.updateBalance();

            this.save()
                .then(() => resolve())
                .catch(() => resolve());
        });
    }

    public setDeliveryLevel(level: DeliveryType, cost: number): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket/${this.basket.uuid}/delivery-level`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken
                }
            };
            const params = {
                level: level,
                cost: cost
            };

            this.httpApi.put<HttpApiResponse<any>>(url, params, options).subscribe({
                next: (response) => {
                    if (response && response.success) {
                        this.basket.modifiedAt = DateHelper.now();

                        this.basket.deliveryLevel = level;
                        this.basket.deliveryCost = cost;

                        this.setDeliveryAccess()
                            .then(() => {
                                this.emitBasket();
                                resolve();
                            })
                            .catch((error) => reject(error));
                    } else {
                        reject(response.body);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public setDelivery(
        level: DeliveryType,
        cost: number,
        deliveryDate: string,
        surcharge: number,
        pickDeliveryLater: boolean = false,
        deliveryNote: string
    ): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket/${this.basket.uuid}/delivery-level/true`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken
                }
            };
            const params = {
                level,
                cost,
                deliveryDate: pickDeliveryLater ? null : deliveryDate,
                surcharge,
                pickDeliveryLater,
                deliveryNote
            };

            this.httpApi.put<HttpApiResponse<any>>(url, params, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        this.basket.modifiedAt = DateHelper.now();

                        this.basket.deliveryCost = cost;
                        this.basket.deliverySurcharge = surcharge;

                        this.setDeliveryAccess()
                            .then(() => {
                                this.updateBalance();
                                resolve();
                            })
                            .catch((error) => reject(error));
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public worktopConfig(uuid: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket/worktops/config/${uuid}`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id
                }
            };

            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public leadtime(styles: string[], carcaseColours: string[], returnAll: boolean = false): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket/leadtimes`;
            const params = {
                styles: styles || null,
                carcaseColours: carcaseColours || null,
                returnAll: returnAll
            };
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id
                }
            };

            this.httpApi.post<HttpApiResponse<any>>(url, params, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        if (response.body) {
                            if (returnAll) {
                                resolve(response.body);
                            } else if (response.body.weekCommencing) {
                                resolve(response.body.weekCommencing);
                            } else {
                                resolve(null);
                            }
                        } else {
                            resolve(null);
                        }
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public validateBasket(skipCache: boolean = false): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.basket.uuid) {
                if (skipCache) {
                    this.callValidateBasket(this.basket.uuid)
                        .then((response) => {
                            if (response && response.body && response.body.updated) {
                                this.loadFromApi(this.basket.uuid)
                                    .then(() => resolve())
                                    .catch((error) => this.dialogService.error(this.constructor.name, error));
                            } else {
                                this.emitBasket();
                                resolve();
                            }
                        })
                        .catch((error) => this.dialogService.error(this.constructor.name, error));
                }

                this.storageService.get(this.storageKey)
                    .then((basket) => {
                        const validated = basket && basket.validated ? basket.validated : null;
                        let now = DateHelper.now();
                        if (!this.basket.modifiedAt) {
                            this.basket.modifiedAt = now;
                        }

                        if (
                            !validated ||
                            DateHelper.format(DateHelper.moment(validated).endOf('day')) < now ||
                            validated < this.basket.modifiedAt
                        ) {
                            this.callValidateBasket(this.basket.uuid)
                                .then((response) => {
                                    if (response && response.body && response.body.updated) {
                                        this.loadFromApi(this.basket.uuid)
                                            .then(() => resolve())
                                            .catch((error) => this.dialogService.error(this.constructor.name, error));
                                    } else {
                                        this.emitBasket();
                                        resolve();
                                    }
                                })
                                .catch((error) => this.dialogService.error(this.constructor.name, error));
                        } else {
                            this.emitBasket();

                            resolve();
                        }
                    })
                    .catch((error) => {
                        this.dialogService.error(this.constructor.name, error);
                        reject(error);
                    });
            } else {
                this.emitBasket();

                resolve();
            }
        });
    }

    private callValidateBasket(uuid: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket/${uuid}/validate`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.applicationToken
                }
            };

            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        let validated = DateHelper.now();
                        this.storageService.set(this.storageKey,
                            {
                                uuid: this.basket.uuid,
                                validated: validated
                            })
                            .then(() => resolve(response))
                            .catch((error) => this.dialogService.error(this.constructor.name, error));
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public acceptTerms() {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket/${this.basket.uuid}/terms`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.put<HttpApiResponse<any>>(url, null, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    private updateBalance() {
        let cost = 0;
        let softDeleteRanks = [];

        if (this.basket.items && this.basket.items.length) {
            this.basket.items.forEach((item) => {
                if (!item.orderItemToBeDeleted && !softDeleteRanks.includes(item.rankParent)) {
                    if (item.isPromotion && item.promotionPercentage && !isNaN(item.salePrice)) {
                        this.basket.cost += item.salePrice;
                    } else {
                        cost += (item.salePrice || item.cost || 0) * (item.qty || 1);
                    }
                } else {
                    softDeleteRanks.push(item.rank);
                }
            });
        }

        // TODO: add delivery costs into balance

        this.basket.cost = NumberHelper.toPoundsAndPence(cost);
        this.balance$.next(this.basket.cost);
    }

    private emitBasket() {
        if (this.basket) {
            this.updateBalance();

            this.basket$.next(this.basket);
        } else {
            this.dialogService.error(this.constructor.name, 'Missing Basket');
            this.basket$.next(null);
            this.balance$.next(0);
        }
    }

    public hasUnavailableItems(): any {
        let availability = {
            style: true,
            discontinued: true
        };

        if (this.basket && this.basket.items) {
            this.basket.items.forEach((basketItem) => {
                if (basketItem.notAvailableInStyle) {
                    availability.style = false;
                }

                if (basketItem.notAvailable) {
                    availability.discontinued = false;
                }
            });
        }

        return availability;
    }

    public duplicateBasket(uuid): Promise<string> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket/${uuid}/duplicate`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.put<HttpApiResponse<any>>(url, null, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body.uuid);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public isSampleOnlyBasket(basket) {
        let nonSampleItems = basket.items.filter((item) => {
            return (
                [ProductType.SAMPLE_CARCASE, ProductType.SAMPLE_DOORS, ProductType.SAMPLE_WORKTOP].indexOf(item.group) === -1 &&
                item.code !== this.solidWorktopCode
            );
        });
        return !(nonSampleItems && nonSampleItems.length);
    }

    public deliveryVariation(order?) {
        let itemsToAnalyze = JSON.parse(JSON.stringify(order || this.basket));
        itemsToAnalyze = itemsToAnalyze.items.filter((item) => !item.orderItemToBeDeleted);

        return BasketHelper.deliveryVariation(itemsToAnalyze);
    }

    public linkBasket(uuid): Promise<string> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket/${uuid}/link`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body.message);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public recover(shopperId: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/basket/recover/${shopperId}`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.get<HttpApiResponse<any>>(url, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body?.uuid || null);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public recoverLocal(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let uniqueId = null;

            this.storageService.get('shopper-id')
                .then((shopperId) => {
                    if (shopperId) {
                        resolve();
                    } else {
                        try {
                            let params: any = {};
                            const DIYKitchens = this.storageService.getCookie('DIYKitchens');
                            if (DIYKitchens) {
                                DIYKitchens.split('&').forEach((param) => {
                                    let p = param.split('=');
                                    params[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
                                });

                                if (
                                    params.uniqueid &&
                                    !params.uniqueid.match(/nothing/i) &&
                                    !params.uniqueid.match(/not.set/i)
                                ) {
                                    uniqueId = params.uniqueid || null;
                                }
                            }
                        } catch (e) {
                            console.error(e);
                        }

                        if (uniqueId) {
                            this.recover(uniqueId)
                                .then((basketUuid) => {
                                    if (basketUuid) {
                                        this.switch(basketUuid)
                                            .then(() => {
                                                this.storageService.set('shopper-id', uniqueId)
                                                    .then(() => resolve())
                                                    .catch((error) => reject(error));
                                            })
                                            .catch((error) => reject(error));
                                    } else {
                                        this.storageService.set('shopper-id', 'none')
                                            .then(() => resolve())
                                            .catch((error) => reject(error));
                                    }
                                })
                                .catch((error) => {
                                    if (
                                        error &&
                                        error.body &&
                                        error.body.message &&
                                        error.body.message === 'Basket Not Found.'
                                    ) {
                                        this.storageService.set('shopper-id', 'not-found')
                                            .then(() => resolve())
                                            .catch((error) => reject(error));
                                    } else {
                                        reject(error);
                                    }
                                });
                        } else {
                            this.storageService.set('shopper-id', 'none')
                                .then(() => resolve())
                                .catch((error) => reject(error));
                        }
                    }
                })
                .catch((error) => reject(error));
        });
    }
}
