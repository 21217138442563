import { Injectable } from '@angular/core';

import { Config } from '@app/config';

import { HttpApi } from '@app/services/api';
import { HttpApiOptions, HttpApiResponse } from '@app/services/api/models';
import { AuthCustomerService } from '@app/services/auth';

@Injectable()
export class RemedialsService {
    constructor(
        private config: Config,
        private httpApi: HttpApi,
        private authCustomerService: AuthCustomerService
    ) { }

    public submitReturns(params) {
        return new Promise<any>((resolve, reject) => {
            const url = `${this.config.api.endpoints.liveDiy}/remedials/customer/order-item-returns/create`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    ApplicationToken: this.authCustomerService.applicationToken,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.post<HttpApiResponse<any[]>>(url, params, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => {
                    reject(error);
                }
            });
        });
    }

    public submitDamages(orderNumber, params) {
        return new Promise<any>((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/remedials/request/${orderNumber}`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    ApplicationToken: this.authCustomerService.applicationToken,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            this.httpApi.post<HttpApiResponse<any[]>>(url, params, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => {
                    reject(error);
                }
            });
        });
    }
}
