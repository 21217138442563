<section class="contained product" *ngIf="item">
    <div class="flex-space-between wrap-under-800 top">
        <div class="flex-item product-image" [promotion]="'appliance-detail'" [request]="'product-overlay-image'" [brand]="item.brand" [productCode]="item.code" [item]="item" [type]="ProductType.APPLIANCES">
            <component-product-image-gallery [images]="productImages"></component-product-image-gallery>
        </div>
        <div class="flex-item appliance-details">
            <img class="brand" src="{{ item?.media?.brandImage }}" alt="Brand image"/>
            <p class="code">{{ item.code }}</p>
            <h1 class="title">{{ item.description }}</h1>
            <div class="offers" [promotion]="'appliance-detail'" [request]="'offer-button'" [brand]="item.brand" [productCode]="item.code" [type]="ProductType.APPLIANCES">
            </div>
            <p class="description">
                {{ item.specific_category }}
            </p>
            <ng-container *ngIf="item?.extended_details?.specifications?.length">
                <ul class="key-features">
                    <ng-container *ngFor="let spec of item.extended_details.specifications">
                        <li *ngIf="spec.name === 'Key Feature' || spec.name === 'Feature'">{{spec.value}}</li>
                    </ng-container>
                </ul>
            </ng-container>
            <div class="price" [promotion]="'appliance-detail'" [request]="'product-price'" [brand]="item.brand" [productCode]="item.code" [item]="item" [type]="ProductType.APPLIANCES">
                <p [innerHTML]="item.cost | currencyFormat:true:true"></p>
            </div>

            <div class="buy">
                <select class="quantity" [(ngModel)]="item.qty">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                </select>
                <div class="basket-button">
                    <component-basket-add-button [activeRange]="activeRange" [type]="type" [item]="item" [qty]="item.qty"></component-basket-add-button>
                </div>
            </div>
        </div>
    </div>

    <hr />
    <div class="product-description">
        <div class="product-details">
            <h4 class="text-center">Product Specification</h4>
            <div class="desc-grid">
                <ng-container *ngIf="item?.extended_details?.specifications?.length; else showDimensions">
                    <div class="desc-grid-item" *ngFor="let spec of item.extended_details.specifications">
                        <ng-container *ngIf="spec.name !== 'Key Feature' && spec.name !== 'Feature'">
                            <label>{{spec.name}}</label>
                            <span>{{spec.value}}</span>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-template #showDimensions>
                    <div class="desc-grid-item">
                        <label>Width</label>
                        <span>{{item.width}}mm</span>
                    </div>
                    <div class="desc-grid-item">
                        <label>Height</label>
                        <span>{{item.height}}mm</span>
                    </div>
                    <div class="desc-grid-item">
                        <label>Depth</label>
                        <span>{{item.depth}}mm</span>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="warranty-info">
            <ng-container *ngIf="item?.extended_details?.details?.length; else showWarrantyInfo">
                <h4>&nbsp;</h4>
                <p *ngFor="let description of item.extended_details.details"
                [ngClass]="{'sub-title': description === description.toUpperCase()}"
                >{{description}}</p>
            </ng-container>
            <ng-template #showWarrantyInfo>
                <ng-container *ngIf="item.warranty && item.brand && warranties[item.brand] && warranties[item.brand][item.warranty]">
                    <h4>Warranty</h4>
                    <div class="warranty-logo">
                        <a [href]="warranties[item.brand][item.warranty].url" target="_blank" title="Warranties">
                            <img [src]="warranties[item.brand][item.warranty].image" width="100%" alt="Warranty logo"/>
                        </a>
                    </div>
                    <!-- <ng-container *ngIf="item.warranty === '5-year' && item.brand === 'CDA'">
                        <p><strong>FREE 5 YEAR PARTS WARRANTY</strong></p>
                        <p>5 year parts & 2 year labour warranty with this CDA product.</p>
                        <p>All CDA appliances come with a 5 year parts guarantee covering mechanical and electrical parts (subject to registration), plus a full parts and labour guarantee in the first 2 years.</p>
                        <p>CDA also offers the option to purchase additional labour cover.</p>
                    </ng-container> -->
                    <ng-container *ngIf="item.warranty === '5-year' && item.brand === 'CDA'">
                        <p><strong>FREE 5 YEAR PARTS WARRANTY</strong></p>
                        <p>5 year parts & 2 year labour warranty with this CDA product.</p>
                        <p>All CDA appliances come with a 5 year parts guarantee covering mechanical and electrical parts (subject to registration), plus a full parts and labour guarantee in the first 2 years.</p>
                        <p>CDA also offers the option to purchase additional labour cover.</p>
                        <div class="offers" *ngIf="item.brand && offers[item.brand]">
                            <ng-container *ngFor="let offer of offers[item.brand]">
                                <div class="offers" [promotion]="'appliance-detail'" [request]="'offer-button'" [brand]="item.brand" [productCode]="item.code" [type]="ProductType.APPLIANCES">
                                </div>
                                <!-- <a class="offer" *ngIf="offer.fragment !== 'cdaoffer1' || item.overlay" [routerLink]="offer.route" [fragment]="offer.fragment">
                                    <p [style.backgroundColor]="offer.colour">
                                        {{offer.title}}
                                        <mat-icon>arrow_forward</mat-icon>
                                    </p>
                                </a> -->
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="item.warranty === '1-year' && item.brand === 'Zanussi'">
                        <p><strong>EXCLUSIVE 1 YEAR WARRANTY COVER</strong></p>
                        <p>Zanussi produce quality home appliances that are built to last and which come with a 1 year manufacturer’s warranty as standard to protect against manufacturing faults and defects that occur during the first year. See the terms and conditions for your manufacturer's warranty.</p>
                    </ng-container>
                    <ng-container *ngIf="item.warranty === '5-year' && item.brand === 'AEG'">
                        <p><strong>EXCLUSIVE 5 YEAR WARRANTY COVER</strong></p>
                        <p>Our AEG Premier Partner Range of appliances not only offers competitive pricing, but also provides the added assurance of a 5 year warranty. We will even take care of registration for you, giving you complete peace of mind.</p>
                        <p><strong>DIY Kitchens | An AEG Premier Partner</strong></p>
                        <a href="https://www.aeg.co.uk/support/warranties/" target="_blank" title="AEG Premier Partner">
                            <img src="https://static.diy-kitchens.com/assets/images/brands/Logo_AEG-Premier-Partner.jpg" height="64px" alt="AEG Premier Partner"/>
                        </a>
                    </ng-container>
                    <hr>
                </ng-container>

                <h4>FOUND A LOWER PRICE? WE'LL MATCH IT!</h4>
                <p><strong>If you find any like for like products cheaper elsewhere we will match it!</strong></p>
                <p>
                    Price matching will hopefully be beneficial to both yourself and to us in the case of kitchen units
                    for example they will be delivered all in one delivery and we will be able to check that you have
                    the
                    correct unit / appliance door configurations.
                </p>
                <p><strong>Please note:</strong> This is at our discretion dependent on retailer.</p>
                <button mat-flat-button routerLink="/about-diy-kitchens/price-promise">OUR PRICE PROMISE</button>
            </ng-template>
        </div>
    </div>
    <hr>
    <ng-container *ngIf="item?.extended_details?.details?.length">
        <div class="flex-space-between wrap-under-800">
            <div class="warranty">
                <h4>Warranty</h4>
                <ng-container *ngIf="item.warranty && item.brand && warranties[item.brand] && warranties[item.brand][item.warranty]">
                    <div class="warranty-logo">
                        <a [href]="warranties[item.brand][item.warranty].url" target="_blank" title="Warranty">
                            <img [src]="warranties[item.brand][item.warranty].image" width="100%" alt="Warranty Logo"/>
                        </a>
                    </div>
                    <!-- <ng-container *ngIf="item.warranty === '5-year' && item.brand === 'CDA'">
                        <p><strong>FREE 5 YEAR PARTS WARRANTY</strong></p>
                        <p>5 year parts & 2 year labour warranty with this CDA product.</p>
                        <p>All CDA appliances come with a 5 year parts guarantee covering mechanical and electrical parts (subject to registration), plus a full parts and labour guarantee in the first 2 years.</p>
                        <p>CDA also offers the option to purchase additional labour cover.</p>
                    </ng-container> -->
                    <ng-container *ngIf="item.warranty === '5-year' && item.brand === 'CDA'">
                        <p><strong>FREE 5 YEAR PARTS WARRANTY</strong></p>
                        <p>5 year parts & 2 year labour warranty with this CDA product.</p>
                        <p>All CDA appliances come with a 5 year parts guarantee covering mechanical and electrical parts (subject to registration), plus a full parts and labour guarantee in the first 2 years.</p>
                        <p>CDA also offers the option to purchase additional labour cover.</p>
                        <div class="offers" *ngIf="item.brand && offers[item.brand]">
                            <div class="offers" [promotion]="'appliance-detail'" [request]="'warranty-button'" [brand]="item.brand" [productCode]="item.code">
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="item.warranty === '1-year' && item.brand === 'Zanussi'">
                        <p><strong>EXCLUSIVE 1 YEAR WARRANTY COVER</strong></p>
                        <p>Zanussi produce quality home appliances that are built to last and which come with a 1 year manufacturer’s warranty as standard to protect against manufacturing faults and defects that occur during the first year. See the terms and conditions for your manufacturer's warranty.</p>
                    </ng-container>
                    <ng-container *ngIf="item.warranty === '5-year' && item.brand === 'AEG'">
                        <p><strong>EXCLUSIVE 5 YEAR WARRANTY COVER</strong></p>
                        <p>Our AEG Premier Partner Range of appliances not only offers competitive pricing, but also provides the added assurance of a 5 year warranty. We will even take care of registration for you, giving you complete peace of mind.</p>
                        <p><strong>DIY Kitchens | An AEG Premier Partner</strong></p>
                        <a href="https://www.aeg.co.uk/support/warranties/" target="_blank" title="Warranty">
                            <img src="https://static.diy-kitchens.com/assets/images/brands/Logo_AEG-Premier-Partner.jpg" height="64px" title="AEG Premier Partner" alt="AEG Premier Partner"/>
                        </a>
                    </ng-container>

                </ng-container>
            </div>
            <div class="price-match">
                <h4>FOUND A LOWER PRICE? WE'LL MATCH IT!</h4>
                <p><strong>If you find any like for like products cheaper elsewhere we will match it!</strong></p>
                <p>
                    Price matching will hopefully be beneficial to both yourself and to us in the case of kitchen units
                    for example they will be delivered all in one delivery and we will be able to check that you have
                    the
                    correct unit / appliance door configurations.
                </p>
                <p><strong>Please note:</strong> This is at our discretion dependent on retailer.</p>
                <button mat-flat-button routerLink="/about-diy-kitchens/price-promise">OUR PRICE PROMISE</button>
            </div>
        </div>
    </ng-container>
</section>

<section *ngIf="housings.length > 0" class="housings">
    <div class="contained">
        <h4>Compatible appliance housing units</h4>
        <div class="products">
            <div class="item" *ngFor="let entry of housings">
                <div class="left">
                    <div class="image" [promotion]="'appliance-detail'" [request]="'listing-overlay-image'" [productCode]="entry.item.unit_code" [type]="ProductType.CABINETS">
                        <img [ngClass]="{ 
                            flip: (
                                (entry.item._hingeSide === Handing.LH && entry.item.handingType !== HandingType.DRAWER) || 
                                (entry.item._hingeSide === Handing.RH && entry.item.handingType === HandingType.DRAWER)
                            )}" src="{{entry.item.media.image}}" [alt]="entry.item.desc + ' image'"/>
                    </div>
                </div>
                <div class="details">
                    <div class="price" [promotion]="'appliance-detail'" [request]="'unit-price'" [productCode]="entry.item.unit_code" [item]="entry.item" [softClose]="entry._softClose" [type]="ProductType.CABINETS">
                        <strong *ngIf="itemCost(entry.item)"
                            [innerHTML]="itemCost(entry.item) + ((entry._softClose) ? entry.item.softCloseCost : 0) | currencyFormat:true:true"></strong>
                        <p *ngIf="!activeRange">Cabinet only price</p>
                        <p *ngIf="!itemCost(entry.item)">Unavailable in this range</p>
                    </div>
                    <div class="title">{{entry.item.desc}}</div>
                    <p class="small">{{entry.item.unit_code}}</p>
                    <p class="desc">{{entry.item.detail}}</p>

                    <div class="dims">
                        <div class="flex">
                            <label>Height:</label>
                            <div>{{entry.item.height}}mm</div>
                        </div>
                        <div class="flex">
                            <label>Depth:</label>
                            <div>{{entry.item.depth}}mm</div>
                        </div>
                        <div class="flex">
                            <label>Width:</label>
                            <div *ngIf="entry.widths.length === 1">{{entry.item.width}}mm</div>
                            <div *ngIf="entry.widths.length>1" class="widths">
                                <button mat-stroked-button *ngFor="let width of sortWidths(entry.widths)"
                                    (click)="selectWidth(entry, width)"
                                    [class.selected]="width.width === entry.item.width">
                                    {{width.width}}
                                </button>
                            </div>
                        </div>
                        <div class="flex" *ngIf="variants(entry).length > 1">
                            <label>Variants:</label>
                            <div class="variants">
                                <button mat-stroked-button class="chunky" *ngFor="let variant of variants(entry)"
                                    (click)="selectVariant(entry, variant)"
                                    [class.selected]="variant.code === entry.item.unit_code" title="{{variant.code}}">
                                    {{variant.variant}}
                                </button>
                            </div>

                        </div>
                    </div>

                    <div class="config" *ngIf="(entry.item.softCloseCost > 0) || entry.item.handed">
                        <label>{{hingeWording(entry.item)}} Side: </label>
                        <button *ngIf="entry.item.handed" class="left-hand"
                            [class.selected]="entry.item._hingeSide === Handing.LH" (click)="setHinge(entry.item, Handing.LH)">
                            LEFT
                        </button>
                        <button *ngIf="entry.item.handed" class="right-hand"
                            [class.selected]="entry.item._hingeSide !== Handing.LH" (click)="setHinge(entry.item, Handing.RH)">
                            RIGHT
                        </button>
                        <button *ngIf="entry.item.softCloseCost > 0" class="right" [class.selected]="entry._softClose"
                            (click)="toggleSoft(entry)">
                            SOFT CLOSING
                        </button>
                    </div>

                    <div class="actions">
                        <button mat-stroked-button class="chunky"
                            [routerLink]="'/kitchen-units/unit/' + entry.item.unit_code">
                            DETAILS
                        </button>
                        <button mat-flat-button class="chunky" color="primary" *ngIf="activeRange"
                            (click)="addUnitToBasket(entry)" [disabled]="!itemCost(entry.item)">
                            ADD TO BASKET
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="noResults" class="no-results">
                <em>No items were found that match your filters...</em>
                Try removing a filter.
            </div>
        </div>
    </div>
</section>
