import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'component-youtube-video-dialog',
    templateUrl: './youtube-video.dialog.component.html',
    styleUrls: ['./youtube-video.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class YouTubeVideoDialogComponent {
    public url: string;

    constructor(
        private dialogRef: MatDialogRef<YouTubeVideoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data && data.url) {
            this.url = data.url;
        }
    }

    public close() {
        this.dialogRef.close();
    }
}
