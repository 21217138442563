import {
    Component, ViewEncapsulation, Input, Output, EventEmitter,
    OnInit, OnChanges, AfterViewInit, HostListener, ChangeDetectorRef
} from '@angular/core';

import { Config } from '@app/config';

import { GalleryItem } from './models';

@Component({
    selector: 'component-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class GalleryComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() galleryItems: GalleryItem[];
    @Input() filter: any;
    @Input() multiSelect: boolean = false;
    @Input() category: string = null;
    @Input() subCategory: string = null;

    public showLeftArrow: Boolean = false;
    public showRightArrow: Boolean = false;
    public scrollPosition: number = 0;
    public scrollWidth: number;
    public clientWidth: number;
    public exceptions: string[] = ['/kitchen-units/base/reduced-height/'];

    private maxScroll: number;
    private imgSize: number = 162;
    private gap: number = 15;
    private scrollIncrement: number;
    private scrollDuration = 500;
    public touchStartX = 0;
    public scrollStartX = 0;
    private existingCategory: string = null;

    private window = {
        maxWidth: 1300,
        breakpoints: [
            {
                break: 1300,
                padding: 0,
                imgSize: 162,
                gap: 15
            },
            {
                break: 992,
                padding: 0,
                imgSize: 162,
                gap: 15
            },
            {
                break: 768,
                padding: 0,
                imgSize: 122,
                gap: 5
            },
            {
                break: 480,
                padding: 0,
                imgSize: 122,
                gap: 5
            }
        ]
    }

    constructor(
        private cdRef: ChangeDetectorRef,
        private config: Config
    ) { }

    ngOnInit() {
        this.galleryItems = this.galleryItems.filter((item) => {
            return !this.exceptions.includes(item.url);
        });
    }

    @HostListener('window:resize')
    ngAfterViewInit(): void {
        this.reset();
        this.cdRef.detectChanges();
    }

    ngOnChanges(): void {
        this.reset();
        this.cdRef.detectChanges();
    }

    private reset(): void {
        if (this.existingCategory !== this.category) {
            this.existingCategory = this.category;
            this.scrollPosition = 0;
            this.showLeftArrow = false;
            this.showRightArrow = false;
        }

        // Calculate the scroll width based on the window width, padding, image size etc.
        let windowWidth: number = 1024;
        if (this.config.isBrowser) {
            windowWidth = window.innerWidth;
        }
        let padding: number = this.window.breakpoints[0].padding;

        for (const breakPoint of this.window.breakpoints) {
            if (windowWidth <= breakPoint.break) {
                padding = breakPoint.padding;
                this.imgSize = breakPoint.imgSize;
                this.gap = breakPoint.gap;
            }
        }

        if (windowWidth > this.window.maxWidth) {
            windowWidth = this.window.maxWidth;
        }

        this.scrollWidth = this.galleryItems.length * this.imgSize + (this.galleryItems.length - 1) * this.gap;
        this.clientWidth = windowWidth - padding;
        this.maxScroll = (this.scrollWidth - this.clientWidth) + 100;
        this.scrollIncrement = Math.floor(this.clientWidth / (this.imgSize + this.gap)) * (this.imgSize + this.gap);
        this.showRightArrow = this.scrollWidth - this.scrollPosition > this.clientWidth ? true : false;
    }

    public galleryClick(item: GalleryItem): void {
        let opt = this.filter?.opts?.find(option => option.value === item.value);
        if (!this.multiSelect) {
            if (opt && opt.tick) {
                opt.tick = false;
            } else {
                let selected = this.filter.opts?.find(option => option.tick);
                if (selected) {
                    selected.tick = false;
                }
                opt.tick = true;
            }
        } else {
            opt.tick = !opt.tick;
        }
    }

    public isSelected(galleryItem: GalleryItem)  {
        return (this.subCategory) ? galleryItem.value === this.subCategory : galleryItem.value === 'all';
    }

    public subCategoryUrl(galleryItem: GalleryItem): string {
        let url: string = `/kitchen-units/${this.category}`;

        if (!this.isSelected(galleryItem) && galleryItem.value !== 'all') {
            url = `${url}/${galleryItem.value}`;
        }

        return url;
    }

    public scrollRight(): void {
        this.scrollToPos(this.scrollPosition + this.scrollIncrement, this.scrollDuration);
        this.showLeftArrow = true;
        if (this.scrollPosition + this.scrollIncrement >= this.maxScroll) {
            this.showRightArrow = false;
        }
    }

    public scrollLeft(): void {
        this.scrollToPos(this.scrollPosition - this.scrollIncrement, this.scrollDuration);
        this.showRightArrow = true;
        if (this.scrollPosition <= this.scrollIncrement) {
            this.showLeftArrow = false;
        }
    }

    private scrollToPos(scrollTarget: number, duration: number): void {
        if (scrollTarget > this.maxScroll) {
            scrollTarget = this.maxScroll;
        } else if (scrollTarget < 0) {
            scrollTarget = 0;
        }
        const change = scrollTarget - this.scrollPosition;
        const increment = 20;
        const step = change / (duration / increment);

        let currentTime = 0;

        const animateScroll = () => {
            currentTime += increment;
            this.scrollPosition += step;
            if (currentTime < duration) {
                requestAnimationFrame(animateScroll);
            }
        };
        animateScroll();
    }

    public onTouchStart(event): void {
        this.touchStartX = event.touches[0].clientX;
        this.scrollStartX = event.currentTarget.scrollLeft;
    }

    public onTouchMove(event): void {
        const touchX = event.touches[0].clientX;
        const scrollX = this.scrollStartX + this.touchStartX - touchX;

        event.currentTarget.scrollLeft = scrollX;
        this.scrollPosition = event.currentTarget.scrollLeft;
    }

    public onTouchEnd(event): void {
        this.scrollPosition = event.currentTarget.scrollLeft;
        this.showLeftArrow = this.scrollPosition > 0 ? true : false;
        this.showRightArrow = this.scrollPosition >= this.maxScroll ? false : true;
    }
}
