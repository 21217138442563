import 'moment/locale/en-gb';
import { Moment } from 'moment-timezone';
import moment from 'moment';

class DateUtility {
    public formatDate = 'YYYY-MM-DD';
    public formatDateTime = 'YYYY-MM-DD HH:mm:ss';

    constructor() {
        moment.updateLocale('en-gb', {
            relativeTime: {
                future: 'in %s',
                past: '%s ago',
                s: 'a few seconds',
                ss: '%d seconds',
                m: 'a minute',
                mm: '%d minutes',
                h: 'an hour',
                hh: '%d hours',
                d: 'a day',
                dd: '%d days',
                M: '',
                MM: '',
                y: '',
                yy: ''
            }
        });
    }

    public now(dateOnly: boolean = false): string {
        return this.format(moment().tz('Europe/London'), dateOnly);
    }

    public moment(date?: string | Moment): Moment {
        return moment(date).tz('Europe/London');
    }

    public format(date: Moment, dateOnly: boolean = false): string {
        if (dateOnly) {
            return date.format(this.formatDate);
        } else {
            return date.format(this.formatDateTime);
        }
    }

    public dayOfWeek(date: string) {
        return moment(date).format('dddd');
    }

    public fromNow(date: string): string {
        let fromDate = moment(date).tz('Europe/London');
        let fromNow = fromDate.fromNow();

        if (fromNow === ' ago') {
            fromNow = 'on ' + fromDate.format('DD MMM YYYY');
        }

        return fromNow;
    }

    public duration(from: string, to?: string): string {
        let time = moment(to).tz('Europe/London').diff(from);
        let duration = moment.duration(time);

        let format = '';

        if (duration.days()) {
            let d = duration.days();
            format += ('00' + d).slice(-2) + `day${(d === 1) ? '' : 's'} - `;
        }

        format += ('00' + duration.hours()).slice(-2) + 'h';
        format += ('00' + duration.minutes()).slice(-2) + 'm';
        format += ('00' + duration.seconds()).slice(-2) + 's';

        return format;
    }

    public timesOverLap(time1Start, time1End, time2Start, time2End) {
        return (time1Start < time2End) && (time2Start < time1End);
    }

    public secondsToTime(seconds: number): string {
        let minutes = Math.round(seconds / 60);
        let hours = 0;

        if (minutes > 59) {
            hours = Math.floor(minutes / 60);
            minutes = minutes - (hours * 60);
        }

        if (hours) {
            return `${hours}h ${minutes}m`;
        } else {
            return `${minutes}m`;
        }
    }

    public dayOfWeekName(dayOfWeekNumber): string {
        const daysOfWeek = {
            1: 'Monday',
            2: 'Tuesday',
            3: 'Wednesday',
            4: 'Thursday',
            5: 'Friday',
            6: 'Saturday',
            7: 'Sunday'
        };

        return daysOfWeek[dayOfWeekNumber || 1];
    }

    addBusinessDays(originalDate, numDaysToAdd) {
        const Sunday = 0;
        const Saturday = 6;
        let daysRemaining = numDaysToAdd;

        const newDate = originalDate.clone();

        while (daysRemaining > 0) {
            newDate.add(1, 'days');
            if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
                daysRemaining--;
            }
        }

        return newDate;
    }
}

export const DateHelper = new DateUtility();
