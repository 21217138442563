@import '/src/theme/variables';

page-remedial {
    .panel {
        padding: 30px;
    }

    .error {
        padding: 5px;
        background-color: color('white');
        color: color('bright-red');
    }

    .mat-card-header-text {
        width: 100%;
    }

    .order-title {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .order-title-order-number {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        padding: 15px;
        background-color: color('mako');
        color: color('white');
        font-weight: bold;

        label {
            font-weight: initial;
        }

        div:last-child {
            text-align: right;
        }
    }

    .issue-tracker-pending {
        padding: 5px;
        background-color: color('outrageous-orange');
        color: color('white');
    }

    .issue-tracker-closed {
        padding: 5px;
        background-color: color('bright-red');
        color: color('white');
    }

    .issue-tracker-sent {
        padding: 5px;
        background-color: color('green');
        color: color('white');
    }

    .order-content {
        display: flex;
    }

    .issue-content {
        display: flex;
    }

    .order-content-section {
        flex-grow: 1;
    }

    .order-content-sub-section {
        margin-bottom: 10px;
    }

    .order-details {
        margin-bottom: 30px;
    }

    .issues-details {
        margin-bottom: 15px;
    }

    .file-inputs {
        display: none;
    }

    .item-border {
        border: 1px solid color('copy-border');

        &.fade {
            opacity: 0.2;
        }
    }

    .order-item-code {
        font-weight: bold;

        button {
            margin-top: 15px;
            width: fit-content;
            font-size: 12px;
            line-height: 28px;

            .mat-icon {
                font-size: 22px;
                vertical-align: middle;
            }
        }
    }

    .order-item-rank {
        font-weight: bold;
    }

    .order-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .order-item-sub-details {
            flex: 5 0 auto;

            .colour-section {
                margin-top: 15px;
            }
        }


    }

    .order-items-table {
        border-collapse: collapse;
        width: 100%;

        td {
            padding: 10px;
            vertical-align: top;
        }

        td:first-child {
            width: fit-content;
        }

        .headers {
            td {
                background: #666;
                color: #FFF;
            }
        }

        .mobile {
            display: none;
        }
    }

    .remedial-detail {
        margin-left: auto;
        width: 200px;
        text-align: center;

        .mat-icon {
            color: #C00;
        }
    }

    .components-toggle {
        margin: 0 15px 15px;

        .mat-icon {
            vertical-align: middle;
        }
    }

    .report-header {
        position: fixed;
        top: 0;
        left: 0;
        padding: 15px;
        box-shadow: 0 1px 9px 5px #333;
        background: rgb(9, 102, 1);
        width: calc(100vw - 30px);
        color: #FFF;
        z-index: 200;

        h5 {
            margin: 0 0 15px;

            .mat-icon {
                margin-right: 10px;
                vertical-align: middle;
            }
        }

        p {
            max-width: 800px;
        }
    }

    .report-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        box-shadow: 0 1px 9px 5px #333;
        background: rgb(9, 102, 1);
        width: 100vw;
        color: #FFF;
        font-weight: bold;
        z-index: 200;
    }

    .problem {
        padding: 15px;
        background: #C00;
        color: #FFF;
    }

    .files {
        display: flex;
        align-items: center;

        img {
            border: 1px solid #CCC;
            max-width: 200px;
            max-height: 200px;
        }

        div {
            margin: 15px 0;
            margin-right: 20px;
            font-size: 0.8rem;
            text-align: center;
        }
    }

    @media only screen and (max-width: 800px) {
        .files {
            flex-wrap: wrap;

            div {
                margin-right: 0;
            }
        }
    }

    textarea {
        margin-top: 15px;
        width: 100%;
        height: 60px;
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            margin: 10px;

            &.go-left {
                margin-right: auto;
            }

            &.go-center {
                margin-right: auto;
                margin-left: auto;
            }
        }

        .mat-checkbox-layout {
            margin: 15px;
        }
    }

    .mini-summary {
        margin: 10px;
    }

    .line-buttons {
        margin: 0 0 0 auto;
        width: fit-content;
    }

    .upload-button {
        margin: 20px 0;
    }

    .reports {
        button {
            background: color('diy-blue');
            color: #FFF;
            text-align: left;

            .mat-icon {
                margin-right: 10px;
            }

            &.lefty {
                margin-right: auto;
                background: initial;
                color: initial;
            }
        }
    }

    @media only screen and (max-width: 1024px) {

        .order-item {
            flex-direction: column;

            .order-item-sub-details {
                margin-left: 65px;
            }
        }
    }

    .issue-name {
        display: inline-block;
        margin-right: 5px;
        padding-right: 5px;
        padding-left: 5px;
        background-color: color('bright-red');
        color: color('white');
    }

    .issue-note {
        display: inline;
    }

    .order-section {
        margin-right: auto;
        margin-left: auto;
    }

    .issues-section {
        margin-right: auto;
        margin-left: auto;
    }

    .issue-types {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .issue-type {
        padding-top: 10px;
        padding-right: 20px;
        padding-bottom: 10px;
        padding-left: 20px;
        background-color: color('mako');
        color: color('white');
        text-align: center;
    }

    .issue-type-selected {
        background-color: color('green');
        color: color('white');
    }

    .new-issue {
        margin: 30px auto 60px;
    }

    .order-item-options {
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid color('copy-border');
        width: 100%;
    }

    .order-item-selected {
        // background-color: color('regent-st-blue', 0.5);
    }

    .issue-actions {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        button {
            margin-bottom: 15px;
        }
    }

    .creator-tabs {
        overflow: hidden;
        height: 100%;
    }

    .issues-overview {
        background-color: color('outrageous-orange', 0.35);
        min-width: 200px;
        max-width: 400px;

        .issues-overview-item {

            &.issue-closed {
                background-color: color('spring-green', 0.5);
            }
        }

        .issue-resolve {
            display: flex;
            justify-content: flex-end;
            padding: 5px;
        }

        .issue-item {
            padding: 5px;
            cursor: pointer;

            &+.issue-item {
                border-top: 1px solid color('copy-border');
            }

            .issue-message {
                margin-bottom: 5px;
            }

            .issue-created-at {
                font-size: 0.725rem;
                text-align: right;
            }

            .issue-created-by {
                font-size: 0.725rem;
                font-weight: 600;
                text-align: right;
            }
        }
    }

    .remedial-created {
        background-color: color('mako');
        color: color('white');
        text-align: center;
    }

    .mat-input-element {
        -webkit-appearance: none;
        -moz-appearance: textfield;
    }

    .issue-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .blank-remedial {
        display: flex;
        flex-wrap: wrap;

        .mat-form-field {
            margin-right: 20px;
            padding-right: 20px;
        }
    }

    .colour-section {
        font-weight: bold;
    }

    .door-handing {
        padding-left: 15px;
        width: 300px;
    }

    .custom-door-handing {
        width: 490px;
    }

    .custom-doors {
        display: flex;
        flex-direction: column;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .expand-input {
        .mat-form-field {
            width: 100%;
        }
    }

    .is-component {
        // background-color: color('outrageous-orange', 0.1);
    }

    @media only screen and (max-width: 768px) {
        height: calc(100% - 20px);
    }

    @media only screen and (max-width: 600px) {
        height: 100%;
    }

    .iframe-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        padding: 0 20px 20px;
        background: rgba(0, 0, 0, 0.8);
        width: 100vw;
        height: 100vh;
        z-index: 220;

        button {
            float: right;
            margin: 0 25px;
            color: #FFF;
            font-weight: bold;

            .mat-icon {
                color: #FFF;
            }
        }

        iframe {
            border: 1px solid #444;
            background: #FFF;
            width: calc(100% - 42px);
            height: calc(100vh - 68px);
        }
    }

    .align-right {
        text-align: right;
    }

    .addresses-header {
        display: flex;
        padding: 20px;

        button {
            margin-left: 20px;
        }
    }

    .addresses {
        display: flex;

        div {
            padding: 20px;
        }
    }

    @media only screen and (max-width: 1023px) {
        .order-title-order-number {
            flex-direction: column;

            div:last-child {
                text-align: left;
            }
        }

        .order-items-table {
            tr {
                display: flex;
                flex-direction: column;
            }

            .headers {
                display: none;
            }

            .mobile {
                display: initial;
            }
        }

        .non-mobile {
            display: none;
        }

        .buttons {
            flex-direction: column;
        }
    }

    .displaynone {
        display: none;
    }
}
