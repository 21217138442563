@import '/src/theme/variables';

page-all-types {

    .all-types-page-content {
        margin: 40px auto;
        width: 100%;
        max-width: 1054px;

        .all-types-head-content {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 30px;
            margin-bottom: 4rem;

            @include responsive-breakpoint-down(md) {
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 0;
            }

            .img-cnt {
                width: 270px;
                height: 170px;
                flex-shrink: 0;

                @include responsive-breakpoint-down(md) {
                    width: 100%;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                }
            }

            .prod-info {
                flex: 1;
                display: flex;
                flex-direction: column;

                .prod-brand-cnt {
                    width: auto;
                    height: 15px;

                    @include responsive-breakpoint-down(md) {
                        height: 14px;
                    }

                    img {
                        height: 100%;
                        width: auto;
                    }
                }

                h2 {
                    font-family: 'Open Sans';
                    font-size: clamp(1.777rem, 1vw + 1.777rem, 1.777rem);
                    font-weight: 900;
                    line-height: clamp(1.777rem, 1vw + 1.777rem, 1.777rem);
                    margin-bottom: 30px;
                    color: rgba(0, 18, 36, 0.9);
                    text-transform: uppercase;
                    margin-top: 0.5rem;
                    margin-bottom: 1.333rem;

                    @include responsive-breakpoint-between(md, xl) {
                        font-size: 1.55rem;
                    }

                    @include responsive-breakpoint-down(md) {
                        font-size: 1.125rem;
                        line-height: normal;
                        padding: 5px 0;
                    }
                }
            }
        }

        .worktops-category {
            display: flex;
            flex-direction: column;
            gap: 7rem;
            padding-bottom: 7rem;

            @include responsive-breakpoint-between(md, xl) {
                gap: 4.5rem;
            }

            @include responsive-breakpoint-down(md) {
                gap: 2.5rem;
            }

            .worktops-item {
                .worktops-item-row {
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    gap: 2rem;

                    @include responsive-breakpoint-down(md) {
                        grid-template-columns: repeat(1, minmax(0, 1fr));
                    }

                    .worktops-item-col {
                        .worktops-item-info {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;

                            h3 {
                                font-family: font('opensans');
                                font-size: clamp(1.333rem, 1vw + 1.333rem, 1.777rem);
                                font-weight: 600;
                                letter-spacing: -0.05rem;
                                line-height: clamp(1.333rem, 1vw + 1.333rem, 1.777rem);
                                margin-bottom: 1.333rem;
                                color: rgba(0, 18, 36, 0.9);

                                @include responsive-breakpoint-between(md, xl) {
                                    font-size: 1.55rem;
                                }

                                @include responsive-breakpoint-down(md) {
                                    font-size: 1.25rem;
                                }
                            }

                            p {
                                font-family: font('opensans');
                                font-size: clamp(1rem, 1vw + 1rem, 1rem);
                                font-weight: 300;
                                line-height: clamp(1.618rem, 1vw + 1.618rem, 1.618rem);
                                margin-top: 0;
                                margin-bottom: 1.333rem;
                                color: rgba(0, 18, 36, 0.9);
                                width: 294px;

                                @include responsive-breakpoint-down(md) {
                                    max-width: 100%;
                                    margin-bottom: 0;
                                }
                            }
                        }

                        .worktops-item-img {
                            width: 100%;
                            height: auto;

                            img {
                                object-fit: cover;
                                width: 100%;
                                height: auto;
                            }
                        }
                    }

                    .worktops-details-info {
                        h4 {
                            font-family: font('opensans');
                            font-size: 1rem;
                            line-height: 1.618rem;
                            color: rgba(0, 18, 36, 0.9);
                            font-weight: bold;
                            letter-spacing: 0.5px;
                            margin-top: 0;
                            margin-bottom: 0.5rem;
                        }

                        .worktops-details-row {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 1.875rem;

                            @include responsive-breakpoint-between(md, xl) {
                                align-items: flex-start;
                                justify-content: space-between;
                            }

                            @include responsive-breakpoint-down(md) {
                                flex-direction: column;
                                align-items: flex-start;
                            }

                            .worktops-size {
                                font-family: 'Open Sans';
                                font-size: 1rem;
                                color: rgba(0, 0, 0, 0.9);
                                text-align: left;
                                letter-spacing: 0.06em;
                                min-width: 180px;

                                @include responsive-breakpoint-between(md, xl) {
                                    max-width: 55%;
                                    line-height: normal;
                                }

                                @include responsive-breakpoint-down(md) {
                                    min-width: inherit;
                                    line-height: normal;
                                }
                            }

                            .worktops-price {
                                min-width: 150px;
                                font-family: 'Open Sans';
                                font-size: 0.75rem;
                                color: rgba(255, 16, 83, 1);
                                letter-spacing: 0.06em;
                                display: flex;
                                flex-direction: column;
                                font-weight: 400;

                                @include responsive-breakpoint-between(md, xl) {
                                    line-height: normal;
                                }

                                sub {
                                    margin: 0 5px;
                                    font-size: 0.75rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
