<ng-container *ngIf="true">
    <header class="content-contained">
        <h1>Deliveries</h1>
        <p>Below are a variety of delivery levels to cater for the wide range of products we supply. Your delivery level can
            be selected when you checkout your order. Please note that we are only able to deliver to mainland UK.</p>
    </header>

    <hr class="narrow" />

    <component-sticky-tabs [tabs]="tabs" [activeTab]="StickyTabs.KITCHENS"
        (activeTabChange)="setActiveTab($event)"></component-sticky-tabs>

    <section class="content-contained" [ngClass]="{'inactive': activeTab !== StickyTabs.KITCHENS}">
        <h2>Kitchen deliveries</h2>

        <div class="delivery-information">
            <div class="delivery-information-block">
                <img src="https://static.diy-kitchens.com/assets/images/delivery/delivery_safety.jpg" width="100%" alt="Beautiful kitchen"/>
                <h3>When can you deliver my kitchen?</h3>

                <p>If you order your kitchen today, we can deliver it to your home from week commencing
                    <span>{{weekCommencing | date: 'dd/MM/YYYY'}}</span>
                    , however please be aware that certain ranges, such as bespoke painted kitchens, may carry a longer lead time.
                    Please check individual ranges for details.</p>
                <p>We deliver 7 days a week – 8am to 10pm.</p>
                <p>Collections can be made Mon-Fri between 9am-12pm and 12.30pm – 2pm</p>
                <p>Please note that if you also order appliances from DIY Kitchens, these will be delivered separately, on any day during your w/c delivery week.</p>
            </div>
            <div class="delivery-information-block">
                <div (click)="showVideo('https://www.youtube.com/embed/30W80vpZlQ0')" class="video-button">
                    <img src="https://static.diy-kitchens.com/assets/images/delivery/delivery_planning.jpg" width="100%" alt="Video control - Play"/>
                </div>
                <h3>Preparing for delivery</h3>

                <p>All units, with the exception of large, corner larder units, are supplied fully assembled, therefore,
                    please make
                    sure you have plenty of space and that there is suitable access prior to delivery.</p>
                <p>Our delivery teams are required to wear safety footwear, therefore, we ask that you make any necessary
                    arrangements
                    to protect flooring and carpets prior to delivery.</p>
            </div>
        </div>

        <div class="pricing-section">
            <div class="pricing-block delivery-standard">
                <div class="price">£99 + VAT</div>
                <h4 class="variant">STANDARD</h4>
                <div class="options">
                    <p>2 person delivery.</p>
                </div>
                <div class="options">
                    <p>Your delivery will arrive between Monday - Sunday on the delivery week commencing you choose. We will give you a 2 day delivery window.</p>
                </div>
                <div class="options">
                    <p>You will receive a notification, by email and text message, the day before your 2 day delivery window.</p>
                </div>
                <div class="options">
                    <p>1 hour text message notice from driver before arrival.</p>
                </div>
                <div class="options">
                    <p>Our drivers will offload the units into your property. *</p>
                </div>
                <ol>
                    <li>You get a 2 person delivery.</li>
                    <li>If you choose a delivery date, you will have 48 working hours to amend your order. Our deliveries
                        are typically made on larger, overnight runs which can cross over multiple days. This is the reason
                        for providing you with a 2 day delivery window.</li>
                    <li>Our drivers will offload the units into your property (ground floor only) with a maximum of 6 steps
                        and 20m walking distance.</li>
                    <li>You will be contacted the day before your 2 day delivery window by email and text message.</li>
                    <li>You will receive a text message from our driver 1 hour before arrival.</li>
                </ol>
            </div>
        </div>
        <div class="pricing-section-notes"><strong>*</strong> Ground floor ONLY - Max 6 steps &amp; Max 20 metres walking distance</div>

        <div class="section-header">
            <h2>Delivery areas</h2>
            <div class="legend">
                <div class="block">
                    <div class="green"></div>
                    <p>Standard Delivery Fee</p>
                </div>
                <div class="block">
                    <div class="red"></div>
                    <p>Surcharge Fee Applies</p>
                </div>
            </div>
            <p>Orders for non-standard areas shown in red on the map below are subject to a further surcharge for delivery.
                Please note we can only deliver to mainland UK.</p>
            <p>Please check all items carefully at the time of delivery. Any damages or faulty items must be reported within
                10 working days of delivery in accordance with our <a routerLink="/terms-and-conditions" title="Terms & Conditions">terms and
                    conditions</a>.</p>
            <p>Please remember all kitchen carcasses are made specifically to your order and the right to cancel for
                consumers as set out in our <a routerLink="/terms-and-conditions" title="Terms & Conditions">terms and conditions</a> does not apply.
            </p>
        </div>
        <div class="map-view">
            <table class="map">
                <tr>
                    <th colspan="5"></th>
                    <td class="red" [map-popup]="['Inverness Delivery Costs',249,325,425]"><i>IV</i>Inverness</td>
                    <td class="red" [map-popup]="['Aberdeen Delivery Costs',224,300,400]"><i>AB</i>Aberdeen</td>
                    <th colspan="3"></th>
                </tr>
                <tr>
                    <th colspan="5"></th>
                    <td class="red" [map-popup]="['Perth Delivery Costs',224,300,400]"><i>PH</i>Perth</td>
                    <td class="red" [map-popup]="['Dundee Delivery Costs',199,275,375]"><i>DD</i>Dundee</td>
                    <th colspan="3"></th>
                </tr>
                <tr>
                    <th colspan="4"></th>
                    <td class="red" [map-popup]="['Paisley Delivery Costs',224,300,400]"><i>PA</i>Paisley</td>
                    <td [map-popup]="['Falkirk Delivery Costs',99,150,250]"><i>FK</i>Falkirk</td>
                    <td [map-popup]="['Kircaldy Delivery Costs',99,150,250]"><i>KY</i>Kircaldy</td>
                    <th colspan="3"></th>
                </tr>
                <tr>
                    <th colspan="4"></th>
                    <td [map-popup]="['Glasgow Delivery Costs',99,150,250]"><i>G</i>Glasgow</td>
                    <td [map-popup]="['Motherwell Delivery Costs',99,150,250]"><i>ML</i>Motherwell</td>
                    <td [map-popup]="['Edinburgh Delivery Costs',99,150,250]"><i>EH</i>Edinburgh</td>
                    <th colspan="3"></th>
                </tr>
                <tr>
                    <th colspan="4"></th>
                    <td [map-popup]="['Kilmarnock Delivery Costs',99,150,250]"><i>KA</i>Kilmarnock</td>
                    <td [map-popup]="['Dumfries & Galloway Delivery Costs',99,150,250]"><i>DG</i>Dumfries & Galloway</td>
                    <td [map-popup]="['Berwick Upon Tweed Delivery Costs',99,150,250]"><i>TD</i>Berwick Upon Tweed</td>
                    <td [map-popup]="['Newcastle Upon Tyne Delivery Costs',99,150,250]"><i>NE</i>Newcastle Upon Tyne</td>
                    <th colspan="2"></th>
                </tr>
                <tr>
                    <th colspan="5"></th>
                    <td [map-popup]="['Carlisle Delivery Costs',99,150,250]"><i>CA</i>Carlisle</td>
                    <td [map-popup]="['Darlington Delivery Costs',99,150,250]"><i>DL</i>Darlington</td>
                    <td [map-popup]="['Durham Delivery Costs',99,150,250]"><i>DH</i>Durham</td>
                    <td [map-popup]="['Sunderland Delivery Costs',99,150,250]"><i>SR</i>Sunderland</td>
                    <th colspan="1"></th>
                </tr>
                <tr>
                    <th colspan="5"></th>
                    <td [map-popup]="['Lancaster Delivery Costs',99,150,250]"><i>LA</i>Lancaster</td>
                    <td [map-popup]="['Bradford Delivery Costs',99,150,250]"><i>BD</i>Bradford</td>
                    <td [map-popup]="['Harrogate Delivery Costs',99,150,250]"><i>HG</i>Harrogate</td>
                    <td [map-popup]="['Teeside Delivery Costs',99,150,250]"><i>TS</i>Teeside</td>
                    <th colspan="1"></th>
                </tr>
                <tr>
                    <th colspan="3"></th>
                    <td [map-popup]="['Blackpool Delivery Costs',99,150,250]"><i>FY</i>Blackpool</td>
                    <td [map-popup]="['Preston Delivery Costs',99,150,250]"><i>PR</i>Preston</td>
                    <td [map-popup]="['Blackburn Delivery Costs',99,150,250]"><i>BB</i>Blackburn</td>
                    <td [map-popup]="['Halifax Delivery Costs',99,150,250]"><i>HX</i>Halifax</td>
                    <td [map-popup]="['Leeds Delivery Costs',99,150,250]"><i>LS</i>Leeds</td>
                    <td [map-popup]="['York Delivery Costs',99,150,250]"><i>YO</i>York</td>
                    <th colspan="1"></th>
                </tr>
                <tr>
                    <th colspan="2"></th>
                    <td [map-popup]="['Liverpool Delivery Costs',99,150,250]"><i>L</i>Liverpool</td>
                    <td [map-popup]="['Wigan Delivery Costs',99,150,250]"><i>WN</i>Wigan</td>
                    <td [map-popup]="['Bolton Delivery Costs',99,150,250]"><i>BL</i>Bolton</td>
                    <td [map-popup]="['Oldham Delivery Costs',99,150,250]"><i>OL</i>Oldham</td>
                    <td [map-popup]="['Huddersfield Delivery Costs',99,150,250]"><i>HD</i>Huddersfield</td>
                    <td [map-popup]="['Wakefield Delivery Costs',99,150,250]"><i>WF</i>Wakefield</td>
                    <td [map-popup]="['Hull Delivery Costs',99,150,250]"><i>HU</i>Hull</td>
                    <th colspan="1"></th>
                </tr>
                <tr>
                    <th colspan="3"></th>
                    <td [map-popup]="['Warrington Delivery Costs',99,150,250]"><i>WA</i>Warrington</td>
                    <td [map-popup]="['Manchester Delivery Costs',99,150,250]"><i>M</i>Manchester</td>
                    <td [map-popup]="['Stockport Delivery Costs',99,150,250]"><i>SK</i>Stockport</td>
                    <td [map-popup]="['Sheffield Delivery Costs',99,150,250]"><i>S</i>Sheffield</td>
                    <td [map-popup]="['Doncaster Delivery Costs',99,150,250]"><i>DN</i>Doncaster</td>
                    <th colspan="2"></th>
                </tr>
                <tr>
                    <th colspan="1"></th>
                    <td class="red" [map-popup]="['Llandudno Delivery Costs',124,200,300]"><i>LL</i>Llandudno</td>
                    <td [map-popup]="['Chester Delivery Costs',99,150,250]"><i>CH</i>Chester</td>
                    <td [map-popup]="['Crewe Delivery Costs',99,150,250]"><i>CW</i>Crewe</td>
                    <td [map-popup]="['Stoke On Trent Delivery Costs',99,150,250]"><i>ST</i>Stoke On Trent</td>
                    <td [map-popup]="['Derby Delivery Costs',99,150,250]"><i>DE</i>Derby</td>
                    <td [map-popup]="['Nottingham Delivery Costs',99,150,250]"><i>NG</i>Nottingham</td>
                    <td [map-popup]="['Lincoln Delivery Costs',99,150,250]"><i>LN</i>Lincoln</td>
                    <th colspan="2"></th>
                </tr>
                <tr>
                    <th colspan="1"></th>
                    <td [map-popup]="['Shrewsbury Delivery Costs',124,200,300]"><i>SY</i>Shrewsbury</td>
                    <td [map-popup]="['Telford Delivery Costs',99,150,250]"><i>TF</i>Telford</td>
                    <td [map-popup]="['Wolverhampton Delivery Costs',99,150,250]"><i>WV</i>Wolverhampton</td>
                    <td [map-popup]="['Walsall Delivery Costs',99,150,250]"><i>WS</i>Walsall</td>
                    <td [map-popup]="['Birmingham Delivery Costs',99,150,250]"><i>B</i>Birmingham</td>
                    <td [map-popup]="['Leicester Delivery Costs',99,150,250]"><i>LE</i>Leicester</td>
                    <td [map-popup]="['Peterborough Delivery Costs',99,150,250]"><i>PE</i>Peterborough</td>
                    <td class="red" [map-popup]="['LincNorwicholn Delivery Costs',124,200,300]"><i>NR</i>Norwich</td>
                    <th colspan="1"></th>
                </tr>
                <tr>
                    <td class="red" [map-popup]="['Swansea Delivery Costs',149,225,325]"><i>SA</i>Swansea</td>
                    <td [map-popup]="['Llandrindod Wells Delivery Costs',124,200,300]"><i>LD</i>Llandrindod Wells</td>
                    <td [map-popup]="['Hereford Delivery Costs',99,150,250]"><i>HR</i>Hereford</td>
                    <td [map-popup]="['Dudley Delivery Costs',99,150,250]"><i>DY</i>Dudley</td>
                    <td [map-popup]="['Worcester Delivery Costs',99,150,250]"><i>WR</i>Worcester</td>
                    <td [map-popup]="['Coventry Delivery Costs',99,150,250]"><i>CV</i>Coventry</td>
                    <td [map-popup]="['Northampton Delivery Costs',99,150,250]"><i>NN</i>Northampton</td>
                    <td [map-popup]="['Cambridge Delivery Costs',99,150,250]"><i>CB</i>Cambridge</td>
                    <td class="red" [map-popup]="['Ipswich Delivery Costs',124,200,300]"><i>IP</i>Ipswich</td>
                    <th colspan="1"></th>
                </tr>
                <tr>
                    <th colspan="1"></th>
                    <td class="red" [map-popup]="['Cardiff Delivery Costs',149,225,325]"><i>CF</i>Cardiff</td>
                    <td class="red" [map-popup]="['Newport Delivery Costs',149,225,325]"><i>NP</i>Newport</td>
                    <td [map-popup]="['Gloucester Delivery Costs',99,150,250]"><i>GL</i>Gloucester</td>
                    <td [map-popup]="['Oxford Delivery Costs',99,150,250]"><i>OX</i>Oxford</td>
                    <td [map-popup]="['Milton Keynes Delivery Costs',99,150,250]"><i>MK</i>Milton Keynes</td>
                    <td [map-popup]="['Stevenage Delivery Costs',99,150,250]"><i>SG</i>Stevenage</td>
                    <td [map-popup]="['Chelmsford Delivery Costs',99,150,250]"><i>CM</i>Chelmsford</td>
                    <td class="red" [map-popup]="['Colcester Delivery Costs',124,200,300]"><i>CO</i>Colcester</td>
                    <th colspan="1"></th>
                </tr>
                <tr>
                    <th colspan="2"></th>
                    <td [map-popup]="['Bristol Delivery Costs',99,150,250]"><i>BS</i>Bristol</td>
                    <td [map-popup]="['Swindon Delivery Costs',99,150,250]"><i>SN</i>Swindon</td>
                    <td [map-popup]="['Hemel Hempstead Delivery Costs',99,150,250]"><i>HP</i>Hemel Hempstead</td>
                    <td [map-popup]="['Luton Delivery Costs',99,150,250]"><i>LU</i>Luton</td>
                    <td [map-popup]="['St Albans Delivery Costs',99,150,250]"><i>AL</i>St Albans</td>
                    <td [map-popup]="['Enfield Delivery Costs',99,150,250]"><i>EN</i>Enfield</td>
                    <td class="red" [map-popup]="['Southend-On-Sea Delivery Costs',124,200,300]"><i>SS</i>Southend-On-Sea
                    </td>
                    <th colspan="1"></th>
                </tr>
                <tr>
                    <th colspan="1"></th>
                    <td class="red" [map-popup]="['Taunton Delivery Costs',124,200,300]"><i>TA</i>Taunton</td>
                    <td [map-popup]="['Bath Delivery Costs',99,150,250]"><i>BA</i>Bath</td>
                    <td [map-popup]="['Salisbury Delivery Costs',99,150,250]"><i>SP</i>Salisbury</td>
                    <td [map-popup]="['Reading Delivery Costs',99,150,250]"><i>RG</i>Reading</td>
                    <td colspan="2" [map-popup]="['London Delivery Costs',99,150,250]"><i>London</i></td>
                    <th colspan="3"></th>
                </tr>
                <tr>
                    <th colspan="1"></th>
                    <td class="red" [map-popup]="['Exeter Delivery Costs',149,250,350]"><i>SS</i>Exeter</td>
                    <td class="red" [map-popup]="['Dorchester Delivery Costs',124,200,300]"><i>DT</i>Dorchester</td>
                    <td class="red" [map-popup]="['Bournemouth Delivery Costs',124,200,300]"><i>BH</i>Bournemouth</td>
                    <td [map-popup]="['Southampton Delivery Costs',99,150,250]"><i>SO</i>Southampton</td>
                    <td [map-popup]="['Guildford Delivery Costs',99,150,250]"><i>GU</i>Guildford</td>
                    <td [map-popup]="['Redhill Delivery Costs',99,150,250]"><i>RH</i>Redhill</td>
                    <td [map-popup]="['Tunbridge Wells Delivery Costs',99,150,250]"><i>TN</i>Tunbridge Wells</td>
                    <td [map-popup]="['Medway Delivery Costs',99,150,250]"><i>ME</i>Medway</td>
                    <td class="red" [map-popup]="['Canterbury Delivery Costs',124,200,300]"><i>CT</i>Canterbury</td>
                </tr>
                <tr>
                    <td class="red" [map-popup]="['Plymouth Delivery Costs',224,300,400]"><i>PL</i>Plymouth</td>
                    <td class="red" [map-popup]="['Torquay Delivery Costs',199,275,375]"><i>TQ</i>Torquay</td>
                    <th colspan="3"></th>
                    <td [map-popup]="['Portsmouth Delivery Costs',99,150,250]"><i>PO</i>Portsmouth</td>
                    <td [map-popup]="['Brighton Delivery Costs',99,150,250]"><i>BN</i>Brighton</td>
                    <th colspan="3"></th>
                </tr>
                <tr>
                    <td class="red" [map-popup]="['Truro Delivery Costs',274,350,450]"><i>TR</i>Truro</td>
                    <th colspan="9"></th>
                </tr>
                <tr>
                    <th colspan="5"></th>
                    <td colspan="2" class="red" [map-popup]="['Isle of Wight Delivery Costs',599,650,750]"><i>PO30 -
                            PO31</i>Isle of Wight</td>
                    <th colspan="3"></th>
                </tr>
            </table>
            <table class="map">
                <tr>
                    <th colspan="5">London</th>
                </tr>
                <tr>
                    <td [map-popup]="['Watford Delivery Costs',99,150,250]"><i>WD</i>Watford</td>
                    <td [map-popup]="['North West London Delivery Costs',99,150,250]"><i>NW</i>North West London</td>
                    <td [map-popup]="['North London Delivery Costs',99,150,250]"><i>N</i>North London</td>
                    <td [map-popup]="['East London Delivery Costs',99,150,250]"><i>E</i>East London</td>
                    <td [map-popup]="['Ilford Delivery Costs',99,150,250]"><i>IG</i>Ilford</td>
                </tr>
                <tr>
                    <td [map-popup]="['Harrow Delivery Costs',99,150,250]"><i>HA</i>Harrow</td>
                    <td [map-popup]="['West London Delivery Costs',99,150,250]"><i>W</i>West London</td>
                    <td [map-popup]="['West Central London Costs',99,150,250]"><i>WC</i>West Central London</td>
                    <td [map-popup]="['East Central London Costs',99,150,250]"><i>EC</i>East Central London</td>
                    <td [map-popup]="['Romford Delivery Costs',99,150,250]"><i>RM</i>Romford</td>
                </tr>
                <tr>
                    <td [map-popup]="['Uxbridge Delivery Costs',99,150,250]"><i>UB</i>Uxbridge</td>
                    <td [map-popup]="['Twickenham Delivery Costs',99,150,250]"><i>TW</i>Twickenham</td>
                    <td [map-popup]="['South West London Costs',99,150,250]"><i>SW</i>South West London</td>
                    <td [map-popup]="['South East London Costs',99,150,250]"><i>SE</i>South East London</td>
                    <td [map-popup]="['Dartford Delivery Costs',99,150,250]"><i>DA</i>Dartford</td>
                </tr>
                <tr>
                    <td [map-popup]="['Slough Delivery Costs',99,150,250]"><i>SL</i>Slough</td>
                    <td [map-popup]="['Kingston Upon Thames Delivery Costs',99,150,250]"><i>KT</i>Kingston Upon Thames
                    </td>
                    <td [map-popup]="['Sutton Costs',99,150,250]"><i>SM</i>Sutton</td>
                    <td [map-popup]="['Croyden Costs',99,150,250]"><i>CR</i>Croyden</td>
                    <td [map-popup]="['Bromley Delivery Costs',99,150,250]"><i>BR</i>Bromley</td>
                </tr>
            </table>

            <p>Orders for non-standard areas shown in red below are subject to a further surcharge for delivery. We can only
                deliver to the mainland UK.</p>
            <p>Please check all items carefully at the time of delivery. Any damages or faulty items must be reported within
                10 working days of delivery in accordance with our <a routerLink="/terms-and-conditions" title="Terms & Conditions">terms and
                    conditions</a>.</p>
            <p>Please remember all kitchen carcasses are made specifically to your order and the right to cancel for
                consumers as set out in our <a routerLink="/terms-and-conditions" title="Terms & Conditions">terms and conditions</a> does not apply.
            </p>
        </div>

        <div class="mobile-view">
            <div class="row red">
                <div class="pc">AB</div>
                <div class="row">
                    <p><strong>Aberdeen Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£224</p>
                    <!-- <p><strong>Gold:</strong> <br />£300</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£400</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">AL</div>
                <div class="row">
                    <p><strong>St Albans Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">B</div>
                <div class="row">
                    <p><strong>Birmingham Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">BA</div>
                <div class="row">
                    <p><strong>Bath Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">BB</div>
                <div class="row">
                    <p><strong>Blackburn Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">BD</div>
                <div class="row">
                    <p><strong>Bradford Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">BH</div>
                <div class="row">
                    <p><strong>Bournemouth Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£124</p>
                    <!-- <p><strong>Gold:</strong> <br />£200</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£300</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">BL</div>
                <div class="row">
                    <p><strong>Bolton Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">BN</div>
                <div class="row">
                    <p><strong>Brighton Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">BR</div>
                <div class="row">
                    <p><strong>Bromley Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">BS</div>
                <div class="row">
                    <p><strong>Bristol Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">CA</div>
                <div class="row">
                    <p><strong>Carlisle Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">CB</div>
                <div class="row">
                    <p><strong>Cambridge Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">CF</div>
                <div class="row">
                    <p><strong>Cardiff Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£149</p>
                    <!-- <p><strong>Gold:</strong> <br />£225</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£325</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">CH</div>
                <div class="row">
                    <p><strong>Chester Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">CM</div>
                <div class="row">
                    <p><strong>Chelmsford Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">CO</div>
                <div class="row">
                    <p><strong>Colcester Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£124</p>
                    <!-- <p><strong>Gold:</strong> <br />£200</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£300</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">CR</div>
                <div class="row">
                    <p><strong>Croyden Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">CT</div>
                <div class="row">
                    <p><strong>Canterbury Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£124</p>
                    <!-- <p><strong>Gold:</strong> <br />£200</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£300</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">CV</div>
                <div class="row">
                    <p><strong>Coventry Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">CW</div>
                <div class="row">
                    <p><strong>Crewe Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">DA</div>
                <div class="row">
                    <p><strong>Darford Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">DD</div>
                <div class="row">
                    <p><strong>Dundee Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£199</p>
                    <!-- <p><strong>Gold:</strong> <br />£275</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£375</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">DG</div>
                <div class="row">
                    <p><strong>Dumfries &amp; Galloway Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">DH</div>
                <div class="row">
                    <p><strong>Durham Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">DE</div>
                <div class="row">
                    <p><strong>Derby Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">DL</div>
                <div class="row">
                    <p><strong>Darlington Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">DN</div>
                <div class="row">
                    <p><strong>Doncaster Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">DT</div>
                <div class="row">
                    <p><strong>Dorchester Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£124</p>
                    <!-- <p><strong>Gold:</strong> <br />£200</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£300</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">DY</div>
                <div class="row">
                    <p><strong>Dudley Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">E</div>
                <div class="row">
                    <p><strong>East London Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">EC</div>
                <div class="row">
                    <p><strong>East Central London Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">EH</div>
                <div class="row">
                    <p><strong>Edinburgh Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">EN</div>
                <div class="row">
                    <p><strong>Enfield Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">EX</div>
                <div class="row">
                    <p><strong>Exeter Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£149</p>
                    <!-- <p><strong>Gold:</strong> <br />£250</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£350</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">FK</div>
                <div class="row">
                    <p><strong>Falkirk Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">FY</div>
                <div class="row">
                    <p><strong>Blackpool Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">G</div>
                <div class="row">
                    <p><strong>Glasgow Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">GL</div>
                <div class="row">
                    <p><strong>Gloucester Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">GU</div>
                <div class="row">
                    <p><strong>Guildford Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">HA</div>
                <div class="row">
                    <p><strong>Harrow Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">HD</div>
                <div class="row">
                    <p><strong>Huddersfield Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">HG</div>
                <div class="row">
                    <p><strong>Harrogate Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">HP</div>
                <div class="row">
                    <p><strong>Hemel Hempstead Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">HR</div>
                <div class="row">
                    <p><strong>Hereford Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">HU</div>
                <div class="row">
                    <p><strong>Hull Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">HX</div>
                <div class="row">
                    <p><strong>Halifax Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">IG</div>
                <div class="row">
                    <p><strong>Ilford Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">IP</div>
                <div class="row">
                    <p><strong>Ipswich Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£124</p>
                    <!-- <p><strong>Gold:</strong> <br />£200</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£300</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">IV</div>
                <div class="row">
                    <p><strong>Inverness Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£249</p>
                    <!-- <p><strong>Gold:</strong> <br />£325</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£425</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">KA</div>
                <div class="row">
                    <p><strong>Kilmarnock Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">KT</div>
                <div class="row">
                    <p><strong>Kigston Upon Thames Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">KY</div>
                <div class="row">
                    <p><strong>Kircaldy Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">L</div>
                <div class="row">
                    <p><strong>Liverpool Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">LA</div>
                <div class="row">
                    <p><strong>Lancaster Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">LD</div>
                <div class="row">
                    <p><strong>Llandrindod Wells Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">LE</div>
                <div class="row">
                    <p><strong>Leicester Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">LL</div>
                <div class="row">
                    <p><strong>Llandudno Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£124</p>
                    <!-- <p><strong>Gold:</strong> <br />£200</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£300</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">LN</div>
                <div class="row">
                    <p><strong>Lincoln Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc"></div>
                <div class="row">
                    <p><strong>London Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">LS</div>
                <div class="row">
                    <p><strong>Leeds Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">LU</div>
                <div class="row">
                    <p><strong>Luton Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">M</div>
                <div class="row">
                    <p><strong>Manchester Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">ME</div>
                <div class="row">
                    <p><strong>Medway Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">MK</div>
                <div class="row">
                    <p><strong>Milton Keynes Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">ML</div>
                <div class="row">
                    <p><strong>Motherwell Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">N</div>
                <div class="row">
                    <p><strong>North London Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">NE</div>
                <div class="row">
                    <p><strong>Newcastle Upon Tyne Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">NG</div>
                <div class="row">
                    <p><strong>Nottingham Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">NN</div>
                <div class="row">
                    <p><strong>Northampton Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">NP</div>
                <div class="row">
                    <p><strong>Newport Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£149</p>
                    <!-- <p><strong>Gold:</strong> <br />£225</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£325</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">NR</div>
                <div class="row">
                    <p><strong>Norwich Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£124</p>
                    <!-- <p><strong>Gold:</strong> <br />£200</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£300</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">NW</div>
                <div class="row">
                    <p><strong>North West London Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">OL</div>
                <div class="row">
                    <p><strong>Oldham Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">OX</div>
                <div class="row">
                    <p><strong>Oxford Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">PA</div>
                <div class="row">
                    <p><strong>Paisley Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£224</p>
                    <!-- <p><strong>Gold:</strong> <br />£300</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£400</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">PE</div>
                <div class="row">
                    <p><strong>Peterborough Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">PH</div>
                <div class="row">
                    <p><strong>Perth Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£224</p>
                    <!-- <p><strong>Gold:</strong> <br />£300</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£400</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">PL</div>
                <div class="row">
                    <p><strong>Plymouth Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£224</p>
                    <!-- <p><strong>Gold:</strong> <br />£300</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£400</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">PO</div>
                <div class="row">
                    <p><strong>Portsmouth Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">PO30<br />to<br />PO41</div>
                <div class="row">
                    <p><strong>Isle Of Wight Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£599</p>
                    <!-- <p><strong>Gold:</strong> <br />£650</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£750</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">PR</div>
                <div class="row">
                    <p><strong>Preston Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">RG</div>
                <div class="row">
                    <p><strong>Reading Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">RH</div>
                <div class="row">
                    <p><strong>Redhill Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">RM</div>
                <div class="row">
                    <p><strong>Romford Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">S</div>
                <div class="row">
                    <p><strong>Sheffield Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">SA</div>
                <div class="row">
                    <p><strong>Swansea Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£149</p>
                    <!-- <p><strong>Gold:</strong> <br />£225</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£325</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">SE</div>
                <div class="row">
                    <p><strong>South East London Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">SG</div>
                <div class="row">
                    <p><strong>Stevenage Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">SK</div>
                <div class="row">
                    <p><strong>Stockport Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">SL</div>
                <div class="row">
                    <p><strong>Slough Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">SM</div>
                <div class="row">
                    <p><strong>Sutton Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">SN</div>
                <div class="row">
                    <p><strong>Swindon Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">SO</div>
                <div class="row">
                    <p><strong>Southampton Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">SP</div>
                <div class="row">
                    <p><strong>Salisbury Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">SR</div>
                <div class="row">
                    <p><strong>Sunderland Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">SS</div>
                <div class="row">
                    <p><strong>Southend-On-Sea Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£124</p>
                    <!-- <p><strong>Gold:</strong> <br />£200</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£300</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">ST</div>
                <div class="row">
                    <p><strong>Stoke On Trent Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">SW</div>
                <div class="row">
                    <p><strong>South West London Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">SY</div>
                <div class="row">
                    <p><strong>Shrewsbury Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">TA</div>
                <div class="row">
                    <p><strong>Taunton Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£124</p>
                    <!-- <p><strong>Gold:</strong> <br />£200</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£300</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">TD</div>
                <div class="row">
                    <p><strong>Berwick Upon Tweed Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">TF</div>
                <div class="row">
                    <p><strong>Telford Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">TN</div>
                <div class="row">
                    <p><strong>Tunbridge Wells Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">TQ</div>
                <div class="row">
                    <p><strong>Torquay Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£199</p>
                    <!-- <p><strong>Gold:</strong> <br />£275</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£375</p> -->
                </div>
            </div>
            <div class="row red">
                <div class="pc">TR</div>
                <div class="row">
                    <p><strong>Truro Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£274</p>
                    <!-- <p><strong>Gold:</strong> <br />£350</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£450</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">TS</div>
                <div class="row">
                    <p><strong>Teeside Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">TW</div>
                <div class="row">
                    <p><strong>Twickenham Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">UB</div>
                <div class="row">
                    <p><strong>Uxbridge Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">W</div>
                <div class="row">
                    <p><strong>West London Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">WA</div>
                <div class="row">
                    <p><strong>Warrington Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">WC</div>
                <div class="row">
                    <p><strong>West Central London Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">WD</div>
                <div class="row">
                    <p><strong>Watford Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">WF</div>
                <div class="row">
                    <p><strong>Wakefield Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">WN</div>
                <div class="row">
                    <p><strong>Wigan Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">WR</div>
                <div class="row">
                    <p><strong>Worcester Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">WS</div>
                <div class="row">
                    <p><strong>Walsall Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">WV</div>
                <div class="row">
                    <p><strong>Wolverhampton Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
            <div class="row">
                <div class="pc">YO</div>
                <div class="row">
                    <p><strong>York Costs</strong></p>
                    <p><strong>Standard:</strong> <br />£99</p>
                    <!-- <p><strong>Gold:</strong> <br />£150</p> -->
                    <!-- <p><strong>Platinum:</strong> <br />£250</p> -->
                </div>
            </div>
        </div>
    </section>

    <section class="content-contained" [ngClass]="{'inactive': activeTab !== StickyTabs.DOORS}">
        <div class="section-header">
            <h2>Kitchen doors, accessories & handles</h2>
            <p>If ordered with sinks and taps, or as part of a complete kitchen you will receive
                <strong>FREE DELIVERY</strong> on these items and the delivery of
                the whole order will be collated together and delivered in accordance with the delivery
                timescales for sinks, taps or complete kitchens.</p>
            <p>If ordered separately and in addition to a full kitchen order, the following delivery fees and lead times will apply:</p>
        </div>
        <div class="pricing-section">
            <div class="pricing-block doors">
                <div class="price">£29.99 + VAT</div>
                <h4 class="variant">DOORS, ACCESSORIES, HANDLES & STORAGE</h4>
                <div class="options">
                    <p>Delivery will be 14 to 20 working days.</p>
                </div>
                <div class="options">
                    <p>Delivered on a nationwide carrier.</p>
                </div>
            </div>
        </div>
    </section>

    <section class="content-contained" [ngClass]="{'inactive': activeTab !== StickyTabs.SINKS}">
        <div class="section-header">
            <h2>Sinks & taps</h2>
            <p>If ordered as part of a complete kitchen you will receive <strong>FREE DELIVERY</strong>
                on these items and the delivery of the whole order will be collated together
                and delivered in accordance with the delivery timescales for complete kitchens.
            </p>
            <p>If ordered separately and in addition to a full kitchen order, the following delivery fees and lead times will apply:</p>
        </div>
        <div class="pricing-section">
            <div class="pricing-block doors">
                <div class="price">£29.99 + VAT</div>
                <h4 class="variant">STAINLESS STEEL SINKS & TAPS</h4>
                <div class="options">
                    <p>Delivery will be 7 to 10 working days.</p>
                </div>
                <div class="options">
                    <p>Delivered on a nationwide carrier.</p>
                </div>
            </div>
            <div class="pricing-block doors">
                <div class="price">£29.99 + VAT</div>
                <h4 class="variant">CERAMIC, COMPOSITE & GRANITE SINKS</h4>
                <div class="options">
                    <p>Ceramic, Composite & Granite Sinks will be delivered in 7 to 10 working days.</p>
                </div>
                <div class="options">
                    <p>Delivered on our own transport.</p>
                </div>
            </div>
        </div>
    </section>

    <section class="content-contained" [ngClass]="{'inactive': activeTab !== StickyTabs.APPLIANCES}">
        <div class="section-header">
            <h2>Appliances</h2>
            <p>Delivery of appliances is free, when ordered as part of a kitchen delivery or as an addition to an existing order.  If ordered as part of a kitchen, your appliances will be delivered separately and on any day during your w/c delivery week.  Appliances must be ordered as part of a full kitchen order or as an addition to an existing order.</p>
        </div>
        <div class="pricing-section">
            <div class="pricing-block appliances">
                <div class="price">Free Delivery</div>
                <h4 class="variant">APPLIANCES</h4>
                <div class="options">
                    <p>Your appliances will be delivered separate to your kitchen, direct from our appliance partners AEG (Electrolux) and CDA.  Delivery will be on any day during your w/c delivery week.</p>
                </div>
                <div class="options">
                    <p>If ordered as an addition to an existing order, please allow at least 14 working days for delivery.</p>
                </div>
            </div>
        </div>
    </section>

    <section class="content-contained" [ngClass]="{'inactive': activeTab !== StickyTabs.WORKTOPS}">
        <div class="section-header">
            <h2>Worktops</h2>
            <p>If ordered with sinks and taps, or as part of a complete kitchen you will receive <strong>FREE
                    DELIVERY</strong> on these items and the delivery of the whole order will be collated together and
                delivered in accordance with the delivery timescales for sinks, taps or complete kitchens.</p>
            <p>If ordered separately and in addition to a full kitchen order, the following delivery fees and lead times will apply:</p>
        </div>
        <div class="pricing-section">
            <div class="pricing-block worktops">
                <div class="price">£29.99 + VAT</div>
                <h4 class="variant">WOOD WORKTOPS</h4>
                <div class="options">
                    <p>Delivery will be 7 to 10 working days.</p>
                </div>
                <div class="options">
                    <p>Delivered on a nationwide carrier.</p>
                </div>
            </div>
            <div class="pricing-block worktops">
                <div class="price">£29.99 + VAT</div>
                <h4 class="variant">LAMINATE WORKTOPS</h4>
                <div class="options">
                    <p>Delivery will be 7 to 10 working days.</p>
                </div>
                <div class="options">
                    <p>Delivered on our own transport.</p>
                </div>
            </div>
        </div>
    </section>

    <component-latest-offers></component-latest-offers>
</ng-container>
