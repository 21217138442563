import moment from 'moment';

export interface TimeDetails {
    value: number;
    units: string;
}

function pluralise(value: number, unit: string) {
    return value !== 1 ? `${unit}s` : unit;
}

export function toFriendlyTimeSpan(start: moment.Moment, end: moment.Moment): TimeDetails {
    if (!start || !end) {
        return { value: null, units: null };
    }

    const daysDiff = end.diff(start, 'days');
    if (daysDiff <= 2) {
        const value = end.diff(start, 'hours');

        return {
            value,
            units: pluralise(value, 'hour')
        };
    } else if (daysDiff < 7) {
        return {
            value: daysDiff,
            units: pluralise(daysDiff, 'day')
        };
    } else if (daysDiff < 31) {
        const value = Math.round(daysDiff / 7);

        return {
            value,
            units: pluralise(value, 'week')
        };
    } else {
        const value = end.diff(start, 'months');

        return {
            value,
            units: pluralise(value, 'month')
        };
    }
}

export function friendlyTimeFromNow(endDate: moment.Moment): TimeDetails {
    return toFriendlyTimeSpan(moment(), endDate);
}
