import { Component, ViewEncapsulation, Input, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { Subscription } from 'rxjs';

import { BasketService } from '@app/services/basket';
import { DeliveryAccess, IBasketDeliveryAccess } from '@app/services/basket/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { OrderService } from '@app/services/order';

@Component({
    selector: 'component-delivery-access',
    templateUrl: './delivery-access.component.html',
    styleUrls: ['./delivery-access.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DeliveryAccessComponent implements AfterViewInit, OnChanges, OnDestroy {
    @Input() step: MatStep;
    @Input() stepSelected: any;
    @Input() stepper: MatStepper;
    @Input() formGroup: FormGroup;
    @Input() order: any;

    public accessFormGroup: FormGroup = this.formBuilder.group({
        accessVehicleSize: new FormControl(null),
        accessWithin20m: new FormControl(null),
        accessIsSafe: new FormControl(null)
    });

    public choice = DeliveryAccess.ACCESS_VEHICLE_SIZE;
    public DeliveryAccess = DeliveryAccess;
    private deliveryAccessResponse: IBasketDeliveryAccess = {};
    private savingResponse: boolean = false;

    private selectedAccess: IBasketDeliveryAccess;

    private basketSubscription: Subscription = null;

    constructor(
        private formBuilder: FormBuilder,
        private basketService: BasketService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private orderService: OrderService
    ) { }

    ngAfterViewInit() {
        this.basketSubscription = this.basketService.basket$.subscribe((basket) => {
            if (basket) {
                if (
                    basket.accessIsSafe !== null &&
                    basket.accessVehicleSize !== null &&
                    basket.accessWithin20m !== null
                ) {
                    this.selectedAccess = {
                        accessIsSafe: (basket.accessIsSafe) ? true : false,
                        accessVehicleSize: (basket.accessVehicleSize) ? true : false,
                        accessWithin20m: (basket.accessWithin20m) ? true : false
                    };
                }

                if (this.selectedAccess) {
                    this.accessFormGroup.patchValue(this.selectedAccess);
                    setTimeout(() => {
                        this.formGroup.setValue({ complete: true });
                    }, 100);
                }
            }
        });
    }

    ngOnChanges(): void {
        if (this.stepSelected === this.step) {
            this.choice = DeliveryAccess.ACCESS_VEHICLE_SIZE;
            this.deliveryAccessResponse = {};
            this.savingResponse = false;
        }
    }

    ngOnDestroy(): void {
        if (this.basketSubscription) {
            this.basketSubscription.unsubscribe();
            this.basketSubscription = null;
        }
    }

    public answer(answer: boolean) {
        if (this.choice === DeliveryAccess.ACCESS_VEHICLE_SIZE) {
            this.deliveryAccessResponse.accessVehicleSize = answer;
            this.choice = DeliveryAccess.ACCESS_WITHIN_20M;
        } else if (this.choice === DeliveryAccess.ACCESS_WITHIN_20M) {
            this.deliveryAccessResponse.accessWithin20m = answer;
            this.choice = DeliveryAccess.ACCESS_IS_SAFE;
        } else {
            this.deliveryAccessResponse.accessIsSafe = answer;

            this.saveDeliveryAccess();
        }
    }

    private saveDeliveryAccess() {
        if (!this.savingResponse) {
            this.savingResponse = true;

            if (this.order) {
                this.orderService.setOrderDeliveryAccess(this.order.id, this.deliveryAccessResponse)
                    .then(() => {
                        this.savingResponse = false;
                        this.navigationService.navigate([`/checkout/order/${this.order.orderNumber}/delivery`]);
                        this.formGroup.setValue({ complete: true });
                        this.stepper.next();
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            } else {
                this.basketService.setDeliveryAccess(this.deliveryAccessResponse)
                    .then(() => {
                        this.savingResponse = false;
                        this.formGroup.setValue({ complete: true });
                        this.stepper.next();
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            }
        }
    }
}
