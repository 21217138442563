import { Component, ViewEncapsulation, OnInit, OnDestroy, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

import { NavigationService } from '@app/services/navigation';
import { RouteHistory } from '@app/services/navigation/models';

@Component({
    selector: 'component-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
    public breadcrumbs: RouteHistory[];

    private routeSubscription: Subscription;
    private breadcrumbSchemaScript: HTMLScriptElement;

    constructor(
        private navigationService: NavigationService,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private doc: Document
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.routeHistory.subscribe((history) => {
            this.breadcrumbs = history
            if (history && history.length) {
                this.addBreadcrumbSchema(history);
            }
        });
        
    }

    addBreadcrumbSchema(breadcrumbs: RouteHistory[]) {
        const breadcrumbListSchema = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: breadcrumbs.map((breadcrumb, index) => ({
                '@type': 'ListItem',
                position: index + 1,
                item: {
                    '@id': `https://www.diy-kitchens.com${breadcrumb.route}`,
                    name: breadcrumb.title,
                },
            })),
        };
        
        if (this.breadcrumbSchemaScript) {
            this.renderer.removeChild(this.doc.head, this.breadcrumbSchemaScript);
        }

        this.breadcrumbSchemaScript = this.renderer.createElement('script');
        this.renderer.setAttribute(this.breadcrumbSchemaScript, 'type', 'application/ld+json');

        this.breadcrumbSchemaScript.innerHTML = JSON.stringify(breadcrumbListSchema);

        this.renderer.appendChild(this.doc.head, this.breadcrumbSchemaScript);
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }

        if (this.breadcrumbSchemaScript) {
            this.renderer.removeChild(this.doc.head, this.breadcrumbSchemaScript);
        }
    }
}
