import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

import { Config } from '@app/config';

import { AuthService } from '@app/services/auth';
import { BasketService } from '@app/services/basket';
import { ActiveRange, ProductType } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';
import { CatalogueService } from '@app/services/catalogue';

import { BasketAddRegisterFirstDialogComponent } from './dialogs/register-first';
import { BasketItemHanding } from '@app/services/basket/models';
import { ICabinet } from '@app/services/catalogue/models/product.models';

@Component({
    selector: 'component-basket-add-button',
    templateUrl: './basket-add-button.component.html',
    styleUrls: ['./basket-add-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class BasketAddButtonComponent {
    @Input() activeRange: ActiveRange;
    @Input() type: ProductType;
    @Input() item: any;
    @Input() allowFree: boolean;
    @Input() qty: number = 1;
    @Input() overridePrice: number;
    @Input() disabled: boolean = false;
    @Input() customLabel: string;
    @Output() status = new EventEmitter<'success' | 'failure'>();

    get isUnit(): boolean {
        return this.type === ProductType.CABINETS;
    }

    public addingToBasket: boolean = false;
    public addedToBasket: boolean = null;

    constructor(
        private config: Config,
        private authService: AuthService,
        private basketService: BasketService,
        private dialogService: DialogService,
        private catalogueService: CatalogueService
    ) { }

    public addToBasket(itemParam) {
        if (!this.authService.isAuthenticated) {
            if (this.basketService.saveLaterCounter === 0) {
                this.basketService.saveLaterCounter++;
                this.dialogService.custom(BasketAddRegisterFirstDialogComponent)
                    .then((saveLater) => {
                        if (saveLater === true || saveLater === false) {
                            this.addItemToBasket(itemParam);
                        }
                    })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            } else {
                this.addItemToBasket(itemParam);
                this.basketService.saveLaterCounter++;

                if (this.basketService.saveLaterCounter >= 3) {
                    this.basketService.saveLaterCounter = 0;
                }
            }
        } else {
            this.addItemToBasket(itemParam);
        }
    }

    /**
     * Check if the item has been handed LH and needs to be swapped, for the fixed-handed items.
     * @param itemParam 
     * @returns Either the original itemParam or the LH version of a fixed-handed item.
     */
    private checkProductLink(itemParam): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.type === ProductType.CABINETS && itemParam?.productLink) {
                const unitCodeParts = itemParam.code.split('-');
                const firstOfCode = unitCodeParts.shift();
                if (firstOfCode.endsWith('R') && itemParam._hingeSide === BasketItemHanding.LH) {
                    this.catalogueService.getUnitsAndDoors()
                        .then((cabinets: any) => {
                            const leftHandedCab = cabinets.find((cabinet: ICabinet) => cabinet?.code === itemParam.productLink);
                            if (leftHandedCab) {
                                resolve(leftHandedCab);
                            }
                        })
                        .catch((error) => reject(error));
                } else {
                    resolve(itemParam);
                }
            } else {
                resolve(itemParam);
            }
        }); 
    }

    private addItemToBasket(itemParam) {
        this.checkProductLink(itemParam)
            .then((checkedItemParam) => {
                let item = JSON.parse(JSON.stringify(checkedItemParam));

                this.addingToBasket = true;
                if (typeof this.overridePrice !== 'undefined') {
                    item.cost = this.overridePrice;
                }

                this.basketService.addItem(item, this.type, this.qty)
                    .then(() => {
                        this.addingToBasket = false;
                        this.addedToBasket = true;

                        if (this.config.isBrowser) {
                            setTimeout(() => this.addedToBasket = null, 2000);
                        } else {
                            this.addedToBasket = null;
                        }
                        this.status.emit('success');
                    })
                    .catch((error) => {
                        this.addingToBasket = false;
                        this.addedToBasket = false;

                        if (this.config.isBrowser) {
                            setTimeout(() => this.addedToBasket = null, 2000);
                        } else {
                            this.addedToBasket = null;
                        }
                        this.status.emit('failure');
                    });
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error))
    }
}
