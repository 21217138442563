import { ProductType } from '@app/services/catalogue/models';
import { DeliveryVariant } from '@app/services/basket/models';

class BasketUtility {
    private solidWorktopCode = 'SOLIDSURFACEWORKTOP';
    private accessSurcharge = 'ACCESSSURCHARGE';
    private solidWoodCategory = 'Solid Wood';
    private largeUnitHeight = 2150;
    private largeUnitWidth = 600;

    // private panelSubCategories = [
    //     'Kitchen End Panel',
    //     'Kitchen End Panels',
    //     'Kitchen Filler Panel',
    //     'Kitchen Fly Over Shelf'
    // ];

    public deliveryVariation(itemsToAnalyze) {
        //remove items that should not count
        itemsToAnalyze = itemsToAnalyze.filter((item) => item.code !== this.accessSurcharge);
        itemsToAnalyze = itemsToAnalyze.filter((item) => !item.isPromotion);

        let counts = this.getItemTypes(itemsToAnalyze);

        return this.getDeliveryVariation(counts);
    }

    private getDeliveryVariation(counts: ICount) {
        if (this.hasUnit(counts)) {
            if (counts.total > 750 || counts.units > 3 || counts.largeUnits) {
                return DeliveryVariant.UNIT;
            } else {
                return DeliveryVariant.SMALL_ORDER_UNITS;
            }
        } else if (this.isSampleOnlyBasket(counts)) {
            return DeliveryVariant.SAMPLE;
        } else if (this.DoorsAndPanelsOnly(counts)) {
            if (counts.total > 750) {
                return DeliveryVariant.DOOR_LARGE_ORDER;
            } else {
                return DeliveryVariant.DOOR;
            }
        } else if (this.handlesOnly(counts)) {
            return DeliveryVariant.HANDLE;
        } else if (this.solidSurfaceWorktopsOnly(counts)) {
            return DeliveryVariant.SOLID_SURFACE_WORKTOP;
        } else if (this.woodWorktopsOnly(counts)) {
            return DeliveryVariant.WOOD_WORKTOP;
        } else if (this.laminateWorktopsOnly(counts)) {
            return DeliveryVariant.LAMINATES;
        } else if (this.sinkAndTapOnly(counts)) {
            return DeliveryVariant.SINK_AND_TAPS;
        } else if (this.applianceOnly(counts)) {
            return DeliveryVariant.APPLIANCES;
        } else if (this.accessoriesOnly(counts)) {
            return DeliveryVariant.ACCESSORIES;
        } else if (this.smallAccessoriesOnly(counts)) {
            return DeliveryVariant.SMALL_ACCESSORIES;
        } else {
            return DeliveryVariant.DEFAULT;
        }
    }

    private getItemTypes(basketItems): any {
        let counts: ICount = {
            total: 0,
            units: 0,
            largeUnits: 0,
            samples: 0,
            doors: 0,
            panels: 0,
            handles: 0,
            solidSurfaceWorktops: 0,
            woodWorktops: 0,
            laminateWorktops: 0,
            sinksAndTaps: 0,
            appliances: 0,
            accessories: 0,
            smallAccessories: 0
        }

        if (!basketItems || !basketItems.length) {
            return counts;
        }

        basketItems.forEach((basketItem) => {
            counts.total += basketItem.salePrice || basketItem.cost || 0;

            switch (basketItem.group) {
                case ProductType.CABINETS:
                    counts.units += basketItem.qy || 1;

                    if (basketItem.width && basketItem.height && (basketItem.height >= this.largeUnitHeight || basketItem.width >= this.largeUnitWidth)) {
                        counts.largeUnits += basketItem.qy || 1;
                    }
                    break;
                case ProductType.SAMPLE_CARCASE:
                case ProductType.SAMPLE_DOORS:
                case ProductType.SAMPLE_WORKTOP:
                    counts.samples += basketItem.qy || 1;
                    break;
                case ProductType.DOORS:
                    counts.doors += basketItem.qy || 1;
                    break;
                case ProductType.HANDLES:
                    counts.handles += basketItem.qy || 1;
                    break;
                case ProductType.SINK_AND_TAPS:
                    counts.sinksAndTaps += basketItem.qy || 1;
                    break;
                case ProductType.APPLIANCES:
                    counts.appliances += basketItem.qy || 1;
                    break;
                case ProductType.ACCESSORIES:
                    if (
                        basketItem.code &&
                        basketItem.code.match(/^CAREKIT/i) ||
                        basketItem.code.match(/^IN-M-ABGPTOP/i) ||
                        basketItem.code.match(/^SOFTCLOSEHINGE/i) ||
                        basketItem.code.match(/^170SOFTCLOSEHINGE/i) ||
                        basketItem.code.match(/^ZERO-PRO-BLUM/i)
                    ) {
                        counts.smallAccessories += basketItem.qy || 1;
                    } else {
                        counts.accessories += basketItem.qy || 1;
                    }
                    break;
                case ProductType.PANELS:
                    counts.panels += basketItem.qy || 1;
                    break;
                case ProductType.RANGE_PRODUCTS:
                    // if (this.panelSubCategories.indexOf(basketItem.subCategory) !== -1) {
                    counts.panels += basketItem.qy || 1;
                    // } else {
                    //     console.log('Unknown range accessory');
                    // }
                    break;
                case ProductType.WORKTOPS:
                    if (basketItem.code === this.solidWorktopCode) {
                        counts.solidSurfaceWorktops += basketItem.qy || 1;
                    } else if (basketItem.category === this.solidWoodCategory) {
                        counts.woodWorktops += basketItem.qy || 1;
                    } else {
                        counts.laminateWorktops += basketItem.qy || 1;
                    }
                    break;
            }
        });

        return counts;
    }

    private typeCounts(counts: ICount, countType: ProductType, subType: string = '') {
        let result = {
            count: 0,
            other: 0
        };

        if (countType !== ProductType.CABINETS) {
            result.other += counts.units;
        } else {
            result.count += counts.units;
        }

        if ([ProductType.SAMPLE_CARCASE, ProductType.SAMPLE_DOORS, ProductType.SAMPLE_WORKTOP].indexOf(countType) === -1) {
            result.other += counts.samples;
        } else {
            result.count += counts.samples;
        }

        if (countType !== ProductType.DOORS) {
            result.other += counts.doors;
        } else {
            result.count += counts.doors;
        }

        if (countType !== ProductType.PANELS) {
            result.other += counts.panels;
        } else {
            result.count += counts.panels;
        }

        if (countType !== ProductType.HANDLES) {
            result.other += counts.handles;
        } else {
            result.count += counts.handles;
        }

        if (countType !== ProductType.SINK_AND_TAPS) {
            result.other += counts.sinksAndTaps;
        } else {
            result.count += counts.sinksAndTaps;
        }

        if (countType !== ProductType.APPLIANCES) {
            result.other += counts.appliances;
        } else {
            result.count += counts.appliances;
        }

        if (countType !== ProductType.ACCESSORIES) {
            result.other += counts.accessories;
            result.other += counts.smallAccessories;
        } else {
            if (subType === 'small') {
                result.count += counts.smallAccessories;
                result.other += counts.accessories;
            } else {
                result.other += counts.smallAccessories;
                result.count += counts.accessories;
            }
        }

        if (countType !== ProductType.WORKTOPS) {
            result.other += counts.solidSurfaceWorktops;
            result.other += counts.woodWorktops;
            result.other += counts.laminateWorktops;
        } else {
            if (subType === this.solidWorktopCode) {
                result.count += counts.solidSurfaceWorktops;
            } else if (subType === this.solidWoodCategory) {
                result.count += counts.woodWorktops;
            } else {
                result.count += counts.laminateWorktops;
            }
        }

        return result;
    }

    private hasUnit(counts) {
        let result = this.typeCounts(counts, ProductType.CABINETS);
        return result.count;
    }

    private isSampleOnlyBasket(counts) {
        let result = this.typeCounts(counts, ProductType.SAMPLE_CARCASE);
        return result.count && !result.other;
    }

    private DoorsAndPanelsOnly(counts) {
        let doorsCount = this.typeCounts(counts, ProductType.DOORS);
        let panelsCount = this.typeCounts(counts, ProductType.PANELS);
        let result = false;

        if (doorsCount.count || panelsCount.count) {
            if (
                (doorsCount.count && doorsCount.other === panelsCount.count) ||
                (panelsCount.count && panelsCount.other === doorsCount.count)
            ) {
                result = true;
            }
        }
        return result;
    }

    private handlesOnly(counts) {
        let result = this.typeCounts(counts, ProductType.HANDLES);
        return result.count && !result.other;
    }

    private solidSurfaceWorktopsOnly(counts) {
        let result = this.typeCounts(counts, ProductType.WORKTOPS, this.solidWorktopCode);
        return result.count && !result.other;
    }

    private woodWorktopsOnly(counts) {
        let result = this.typeCounts(counts, ProductType.WORKTOPS, this.solidWoodCategory);
        return result.count && !result.other;
    }

    private laminateWorktopsOnly(counts) {
        let result = this.typeCounts(counts, ProductType.WORKTOPS);
        return result.count && !result.other;
    }

    private sinkAndTapOnly(counts) {
        let result = this.typeCounts(counts, ProductType.SINK_AND_TAPS);
        return result.count && !result.other;
    }

    private applianceOnly(counts) {
        let result = this.typeCounts(counts, ProductType.APPLIANCES);
        return result.count && !result.other;
    }

    private accessoriesOnly(counts) {
        let result = this.typeCounts(counts, ProductType.ACCESSORIES);
        return result.count && !result.other;
    }

    private smallAccessoriesOnly(counts) {
        let result = this.typeCounts(counts, ProductType.ACCESSORIES, 'small');
        return result.count && !result.other;
    }
}

interface ICount {
    total: number;
    units: number;
    largeUnits: number;
    samples: number;
    doors: number;
    panels: number;
    handles: number;
    solidSurfaceWorktops: number;
    woodWorktops: number;
    laminateWorktops: number;
    sinksAndTaps: number;
    appliances: number;
    accessories: number;
    smallAccessories: number;
}

export const BasketHelper = new BasketUtility();
