import { Component, ViewEncapsulation } from '@angular/core';

import { Config } from '@app/config';

import { DialogService } from '@app/services/dialog';
import { MarketingService } from '@app/services/marketing';

@Component({
    selector: 'component-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class FooterComponent {
    public plannerURL;
    public showCookieConsent: boolean = false;

    constructor(
        private config: Config,
        private dialogService: DialogService,
        private marketingService: MarketingService
    ) {
        this.plannerURL = this.config?.api?.endpoints?.planner;
    }

    ngOnInit() {
        if (this.config.isBrowser) {
            this.marketingService.cookieConsent()
                .then((hasConsent) => {
                    if (!hasConsent) {
                        this.showCookieConsent = true;
                    }
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        }
    }

    public markAsRead() {
        this.marketingService.cookieConsent(true)
            .then(() => {
                this.showCookieConsent = false;
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
