import { NgModule } from '@angular/core';

import { OrdersService } from './orders.service';

@NgModule({
    providers: [
        OrdersService
    ]
})
export class OrdersServiceModule { }
