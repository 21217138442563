import { Injectable } from '@angular/core';

import { Config } from '@app/config';

import { HttpApi } from '@app/services/api';
import { HttpApiOptions, HttpApiResponse } from '@app/services/api/models';
import { AuthCustomerService } from '@app/services/auth';
import { DialogService } from '@app/services/dialog';

@Injectable()
export class MonekService {
    constructor(
        private config: Config,
        private httpApi: HttpApi,
        private authCustomerService: AuthCustomerService,
        private dialogService: DialogService
    ) { }

    public createOrderAndGoToMonek(uuid: string, refundsToDo, paymentReference: string, amount: number): Promise<number> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/monek/transact/basket/${uuid}`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            const params = {
                url: this.config.api.endpoints.diy,
                paymentReference: paymentReference,
                amount: amount,
                refundsToDo: refundsToDo
            };

            this.httpApi.post<HttpApiResponse<any>>(url, params, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }

    public goToMonek(orderNumber: string, refundsToDo, paymentReference: string, amount: number): Promise<number> {
        return new Promise((resolve, reject) => {
            const url = `${this.config.api.endpoints.diy}/monek/transact/order/${orderNumber}`;
            const options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UserToken: this.authCustomerService.universalToken
                }
            };

            const params = {
                url: this.config.api.endpoints.diy,
                paymentReference: paymentReference,
                amount: amount,
                refundsToDo: refundsToDo
            };

            this.httpApi.post<HttpApiResponse<any>>(url, params, options).subscribe({
                next: (response) => {
                    if (response.success) {
                        resolve(response.body);
                    } else {
                        reject(response);
                    }
                },
                error: (error) => reject(error)
            });
        });
    }
    
}
