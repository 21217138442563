import { Component, ViewEncapsulation, Signal } from '@angular/core';

import { Config } from '@app/config';
import { StringHelper, URLHelper, SignalHelper } from '@app/utilities/helpers';

import { CatalogueService } from '@app/services/catalogue';
import { ActiveRange, CabinetColour, ProductType } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';
import { IMedia, IProductSpecification } from '@app/services/catalogue/models/product.models/common';
import { DimensionsInputCalculatorDialogComponent } from '@app/shared/dialogs/dimensions-input-calculator';
@Component({
    selector: 'page-accessory-detail',
    templateUrl: './accessory-detail.page.html',
    styleUrls: ['./accessory-detail.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AccessoryDetailPage {
    public ProductType = ProductType;
    public item: any;
    public qty: number = 1;
    public type: ProductType = ProductType.ACCESSORIES;;
    public activeRange: Signal<ActiveRange>;
    public productImages: IMedia[] = [];
    public carcaseColours = [];

    private routeSignal: Signal<NavigationRoute>;
    private routeHistory: RouteHistory[] = [{ title: 'Accessories', route: '/accessories' }];

    constructor(
        private config: Config,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService,
        private urlHelper: URLHelper
    ) { 
        this.activeRange = SignalHelper.toSignal<ActiveRange>(this.catalogueService.activeRange$, {initialValue: null});
        this.routeSignal = SignalHelper.toSignalWithEffect<NavigationRoute>(this.navigationService.route, (route) => {
            this.getCatalogueData(route.params.details || null);
        });
    }

    private getCatalogueData(details: string) {
        this.catalogueService.getAccessoryByDetails(details)
            .then((accessory: any) => {
                let routeHistory = this.routeHistory.slice();

                if (accessory) {
                    this.item = accessory;
                    this.item.qty = 1;
                    this.item._cost = this.item.price || 0;

                    if (this.isCarcaseColoured() && this.activeRange()) {
                        this.catalogueService.getCabinetColours()
                            .then((carcaseColours: CabinetColour[]) => {
                                if (Array.isArray(carcaseColours)) {
                                    this.carcaseColours = carcaseColours;
                                    if (this.activeRange()?.carcaseColour) {
                                        const matchingColour = this.carcaseColours.find((colour: CabinetColour) => this.activeRange().carcaseColour === colour.colour);
                                        if (matchingColour) {
                                            this.item.carcaseColour = this.activeRange().carcaseColour;
                                        }
                                    }
                                }
                            })
                            .catch((error) => this.dialogService.error(this.constructor.name, error));
                    }

                    this.productImages = this.item.extended_details?.images?.map(image => ({
                        image: `${this.config.api.endpoints.cdn}/assets/images/products/misc/cimageurl/${image}`
                    }));

                    if (!this.item.extended_details?.images?.length && this.item.media) {
                        this.productImages = [this.item.media];
                    }

                    const removeDuplicates = (arr: IProductSpecification[]): IProductSpecification[] => {
                        return arr.filter((obj, index) => {
                            return !arr.some((otherObj, otherIndex) => {
                                return otherIndex < index && obj.name === otherObj.name && obj.value === otherObj.value;
                            });
                        });
                    }

                    if (this.item.extended_details?.specifications) {
                        this.item.extended_details.specifications = removeDuplicates(this.item.extended_details.specifications);
                    }

                    this.item.extended_details?.specifications?.forEach((specification => {
                        specification.name = StringHelper.titleCase(specification.name.replace(/_/g, ' '));
                    }));

                    const category = StringHelper.spaceToDash(this.item.cat);
                    routeHistory.push({
                        title: this.item.cat,
                        route: `/accessories/${category}`
                    });

                    routeHistory.push({
                        title: this.item.product_code,
                        route: this.urlHelper.fullRoute(this.item)
                    });

                    const metaDescription = `The ${this.item?.brand + ' ' || ''}${this.item?.product_code} ${this.item?.desc} from the DIY Kitchens Accessories range`;
                    this.navigationService.setNavigation({
                        title: this.item?.title,
                        metaTags: [
                            { name: 'no-meta', content: 'no metadata' },
                            { name: 'description', content: metaDescription},
                        ]
                    });
                }

                this.navigationService.setRouteHistory(routeHistory);
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public openDimensionsInputDialog(): void {
        this.catalogueService.getCabinetColours()
            .then((carcaseColours: any) => {
                this.dialogService.custom(DimensionsInputCalculatorDialogComponent, {
                    maxWidth: '500px',
                    data: {
                        item: this.item,
                        carcaseColours: carcaseColours,
                        activeRange: this.activeRange
                    }
                })
                    .catch((error) => this.dialogService.error(this.constructor.name, error));
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public isCarcaseColoured(): boolean {
        return this.catalogueService.isCarcaseColoured(this.item);
    }

    public onColourSwatchClick(colour: CabinetColour): void {
        this.item.carcaseColour = colour.colour;
    }
}
