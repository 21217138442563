<section class="flex range-action-types" [ngClass]="orientation" *ngIf="range">
    <div class="flex-item type">
        <h5>I want to create a plan online</h5>
        <div>
            <p>Our easy to use planning tool will guide you through creating your own plan with 3D visuals.
            </p>
        </div>
        <button mat-flat-button color="primary" class="chunky" (click)="plannerLink()">
            PLAN ONLINE NOW</button>
    </div>
    <!-- <div class="flex-item type">
        <h5>Create a plan</h5>
        <div>
            <p>With just a few details, our expert planning team will create your dream kitchen for free.
            </p>
        </div>
        <button mat-flat-button color="primary" class="chunky" routerLink="/free-kitchen-design">
            GET YOUR PLAN</button>
    </div> -->
    <div class="flex-item type">
        <h5>Already have a plan</h5>
        <div>
            <p>If you've got a plan you're happy with, simply send it through to us for a free quotation in this style.
            </p>
        </div>
        <button mat-flat-button color="primary" class="chunky" routerLink="/support">
            SEND US YOUR PLAN</button>
    </div>
    <div class="flex-item type" *ngIf="selectedColour && selectedCabinet">
        <h5>I want to buy units</h5>
        <div>
            <p>Perfect if you have a fixed plan you are working to. You'll find all the units you need here.
            </p>
            <p class="bold text-center">{{range.name}} {{selectedColour.name}} doors<br />
                with {{selectedCabinet.colour}} cabinets</p>
        </div>
        <button mat-flat-button color="primary" class="chunky" (click)="selectRange('units')">
            START BUYING UNITS</button>

    </div>
    <div class="flex-item type" *ngIf="selectedColour">
        <h5>I want to buy doors</h5>
        <div>
            <p>You can order just doors from our extensive range of sizes for this style.</p>
            <p class="bold text-center">{{range.name}} {{selectedColour.name}} doors</p>
        </div>
        <button mat-flat-button color="primary" class="chunky" (click)="selectRange('doors')">
            START BUYING DOORS</button>

    </div>
    <div class="flex-item type" *ngIf="selectedColour">
        <h5>Order samples for this style</h5>
        <div>
            <p>Get kitchen door and carcase samples delivered straight to your home.</p>
        </div>
        <button mat-flat-button color="primary" class="chunky" (click)="selectRange('samples')">
            ORDER SAMPLES</button>
    </div>
</section>