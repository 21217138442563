import { Component, ViewEncapsulation, OnDestroy, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Config } from '@app/config';
import { FormValidators } from '@app/utilities/validators';

import { AuthCustomerService } from '@app/services/auth';

@Component({
    selector: 'component-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class LoginComponent implements OnDestroy {
    @ViewChild('focusEmail') focusEmailElement: ElementRef;
    @ViewChild('focusName') focusNameElement: ElementRef;
    @ViewChild('focusPassword') focusPasswordElement: ElementRef;

    @Output() loginComplete: EventEmitter<any> = new EventEmitter<any>();

    public universalTokenLocation: string = this.config.api.endpoints.universalUI;
    public validatedUniversalToken: boolean = false;
    public validatingUniversalToken: boolean = false;

    public state: number = 0;
    public loginType: number = 1;

    public authenticated: boolean = false;
    public authenticating: boolean = false;
    public disablePasswordReset: boolean = false;
    public errorMessage: string;
    public noticeMessage: string;

    public background: string;

    public loginForm: FormGroup;
    public email: FormControl = new FormControl('', [Validators.required, FormValidators.email]);
    public password: FormControl = new FormControl('', [Validators.required]);
    public passwordConfirm: FormControl = new FormControl('', [Validators.required]);
    public orderNumber: FormControl = new FormControl('', [Validators.required]);
    public firstName: FormControl = new FormControl('', [Validators.required]);
    public lastName: FormControl = new FormControl('', [Validators.required]);
    public contactNumber: FormControl = new FormControl('', [Validators.required, FormValidators.phoneNumber]);

    private resetPasswordToken: string = null;
    private authChange: Subscription;

    constructor(
        private config: Config,
        private formBuilder: FormBuilder,
        private authCustomerService: AuthCustomerService
    ) {
        this.loginForm = this.formBuilder.group({
            username: this.email,
            password: this.password,
            passwordConfirm: this.passwordConfirm
        });

        this.authChange = this.authCustomerService.change.subscribe(
            () => {
                this.checkState()
            }
        );

        this.checkState();
    }

    ngOnDestroy() {
        if (this.authChange) {
            this.authChange.unsubscribe();
            this.authChange = null;
        }
    }

    public login() {
        this.state = 4;
        this.disablePasswordReset = false;
        this.email.enable();

        this.focus('email', true);
    }

    public register() {
        this.state = 5;
        this.disablePasswordReset = false;
        this.email.enable();

        this.focus('name', true);
    }

    public forgotPassword() {
        this.state = 6;
        this.disablePasswordReset = false;
        this.email.enable();

        this.focus('email', true);
    }

    public switchType(loginType: number) {
        this.loginType = loginType;
        this.state = 4;
        this.focus('email', true);
    }

    public authenticate() {
        this.errorMessage = null;
        this.noticeMessage = null;

        if (!this.authenticating) {
            this.authenticating = true;

            switch (this.state) {
                case 4:
                    this.resetPasswordToken = null;

                    if (this.loginType === 1) {
                        // TODO: get custom form group interface working
                        this.authCustomerService.validateEmail(this.email['cleanValue'])
                            .then((response) => {
                                this.authenticating = false;
                                if (!response) {
                                    this.state = 8;
                                } else {
                                    this.resetPasswordToken = response;
                                    this.state = 9;
                                }

                                this.focus('password');
                            })
                            .catch((error) => {
                                this.errorMessage = this.handleError(error);
                                this.authenticating = false;
                            });
                    } else {
                        // TODO: get custom form group interface working
                        this.authCustomerService.loginWithOrderNumber(this.email['cleanValue'], this.orderNumber.value)
                            .then(() => {
                                this.loginComplete.emit(true);

                                this.authenticated = true;
                                this.authenticating = false;
                                this.checkState();
                            })
                            .catch((error) => {
                                this.errorMessage = this.handleError(error);
                                this.authenticating = false;
                            });
                    }

                    break;
                case 5:
                    this.resetPasswordToken = null;

                    if (this.password.value === this.passwordConfirm.value) {
                        this.authCustomerService.register({
                            // TODO: get custom form group interface working
                            email: this.email['cleanValue'],
                            password: this.password.value,
                            firstName: this.firstName.value,
                            lastName: this.lastName.value,
                            // TODO: get custom form group interface working
                            contactNumber: this.contactNumber['cleanValue']
                        })
                            .then(() => {
                                this.loginComplete.emit(true);

                                this.authenticated = true;
                                this.authenticating = false;
                                this.checkState();
                            })
                            .catch((error) => {
                                this.errorMessage = this.handleError(error);
                                this.authenticating = false;
                            });
                    } else {
                        this.errorMessage = 'Passwords do not match';
                    }
                    break;
                case 6:
                    this.resetPasswordToken = null;
                    this.disablePasswordReset = true;
                    this.email.disable();

                    // TODO: get custom form group interface working
                    this.authCustomerService.requestPasswordReset(this.email['cleanValue'])
                        .then(() => {
                            this.noticeMessage = 'If the email address exists, an email will be sent with instructions on resetting your password.';
                            this.authenticating = false;
                        })
                        .catch((error) => {
                            this.errorMessage = this.handleError(error);
                            this.authenticating = false;
                            this.disablePasswordReset = false;
                            this.email.enable();
                        })
                    break;
                case 8:
                    this.resetPasswordToken = null;

                    // TODO: get custom form group interface working
                    this.authCustomerService.login(this.email['cleanValue'], this.password.value)
                        .then(() => {
                            this.loginComplete.emit(true);

                            this.authenticated = true;
                            this.authenticating = false;
                            this.checkState();
                        })
                        .catch((error) => {
                            this.errorMessage = this.handleError(error);
                            this.authenticating = false;
                        });
                    break;
                case 9:
                    if (this.password.value === this.passwordConfirm.value) {
                        this.authCustomerService.resetPassword(this.password.value, this.resetPasswordToken)
                            .then(() => {
                                // TODO: get custom form group interface working
                                this.authCustomerService.login(this.email['cleanValue'], this.password.value)
                                    .then(() => {
                                        this.loginComplete.emit(true);

                                        this.authenticated = true;
                                        this.authenticating = false;
                                        this.checkState();
                                    })
                                    .catch((error) => {
                                        this.errorMessage = this.handleError(error);
                                        this.authenticating = false;
                                    });
                            })
                            .catch((error) => {
                                this.errorMessage = this.handleError(error);
                                this.authenticating = false;
                            })
                    } else {
                        this.errorMessage = 'Passwords do not match';
                        this.authenticating = false;
                    }
                    break;
            }
        }
    }

    private focus(type: 'email' | 'name' | 'password', reset: boolean = false) {
        if (reset) {
            this.email.setErrors(null);
            this.firstName.setErrors(null);
            this.password.setErrors(null);
        }

        setTimeout(() => {
            switch (type) {
                case 'email':
                    this.focusEmailElement.nativeElement.focus();
                    break;
                case 'name':
                    this.focusNameElement.nativeElement.focus();
                    break;
                case 'password':
                    this.focusPasswordElement.nativeElement.focus();
                    break;
            }
        }, 50);
    }

    private handleError(error) {
        let errorMessage = 'Something went wrong.';

        if (error && error.message) {
            errorMessage = error.message;
        } else if (
            error &&
            error.body &&
            error.body.message &&
            typeof error.body.message === 'string' &&
            !error.body.message.match(/\{/)
        ) {
            errorMessage = error.body.message;
        }

        return errorMessage;
    }

    private checkState() {
        // if (!this.authCustomerService.universalToken) {
        //     console.log('Setting State 1');
        //     this.state = 1;
        // } else if (!this.validatedUniversalToken) {
        //     console.log('Setting State 2');
        //     this.state = 2;

        //     this.validateUniversalToken();
        // } else if (this.authCustomerService.isAuthenticated && !this.authenticated) {
        if (this.authCustomerService.isAuthenticated) {
            this.state = 3;
        } else if (!this.authCustomerService.isAuthenticated && !this.authenticated) {
            if ([4, 5, 6, 7, 8, 9].indexOf(this.state) === -1) {
                this.state = 4;
                this.focus('email');
            }
        } else if (this.authenticated && !this.errorMessage) {
            this.state = 10;
        } else if (this.authenticated && this.errorMessage) {
            this.state = 11;
        }
    }

    // private validateUniversalToken() {
    //     if (!this.validatingUniversalToken) {
    //         this.validatingUniversalToken = true;
    //         this.errorMessage = null;

    //         this.authCustomerService.validateUniversal()
    //             .then(() => {
    //                 this.validatedUniversalToken = true;
    //                 this.validatingUniversalToken = false;
    //                 this.checkState();
    //             })
    //             .catch((error) => {
    //                 this.validatedUniversalToken = true;
    //                 // console.log('Error 4', error);
    //                 this.errorMessage = ((typeof error === 'string') ? error : error.message) || 'Something went wrong';
    //                 this.checkState();
    //             });
    //     }
    // }

    // @HostListener('window:message', ['$event']) onMessage(event) {
    //     if (event.origin === this.config.api.endpoints.universalUI) {
    //         if (event.data) {
    //             if (event.data.token) {
    //                 this.authCustomerService.setUniversalToken(event.data.token);
    //             }
    //         }
    //     }
    // }
}
