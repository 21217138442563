import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UuidGuard implements CanActivate {
    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const uuid = route.paramMap.get('uuid');

        if (uuid && this.isValidUuid(uuid)) {
            return true;
        } else {
            this.router.navigate(['/basket']);
            return false;
        }
    }

    private isValidUuid(uuid: string): boolean {
        return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(uuid);
    }
}
