import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

import { Config } from '@app/config';
import { HttpApi } from '@app/services/api';
import { HttpApiOptions } from '@app/services/api/models';
import { DialogService } from '@app/services/dialog';

import { AuthService } from './auth.service';
import { StaffUser, StaffUserDetails, Universal, UserType } from './models';

@Injectable()
export class AuthStaffService {
    public authentication: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public change: Subject<void> = new Subject<void>();

    public get isAuthenticated() {
        return (this.authentication && this.authentication.getValue()) ? this.authentication.getValue() : false;
    }

    public get details(): StaffUserDetails {
        return (this.staffUser && this.staffUser.userDetails) ? this.staffUser.userDetails : null;
    }

    public get applicationToken(): string {
        return (this.staffUser && this.staffUser.applicationToken) ? this.staffUser.applicationToken : null;
    }

    public get universalToken(): string {
        return (this.staffUser && this.staffUser.universal) ? this.staffUser.universal.token : null;
    }

    public get universalUser(): StaffUserDetails {
        return <StaffUserDetails>((this.staffUser && this.staffUser.universal) ? this.staffUser.universal.userDetails : null);
    }

    private staffUser: StaffUser;

    constructor(
        private config: Config,
        private httpApi: HttpApi,
        private authService: AuthService,
        private dialogService: DialogService
    ) {
        if (config.auth.type === UserType.STAFF) {
            authService.details.subscribe(
                (details) => {
                    this.staffUser = (details) ? details as StaffUser : null;

                    this.dataChange();
                }
            );

            authService.authentication.subscribe(
                (authentication) => {
                    this.authentication.next(authentication);

                    this.dataChange();
                }
            );

            authService.loaded.subscribe(
                (loaded) => {
                    if (loaded) {
                        this.validateUser(true)
                            .then(() => {
                                authService.ready.next(true);
                                this.dataChange();
                            })
                            .catch((error) => {
                                authService.ready.next(true);
                                this.dataChange();
                            });
                    }
                }
            );
        }
    }

    public login(clockNumber: string, pinNumber: string, tfaToken?: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let params: any = { clockNumber, pinNumber };
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UniversalToken: this.universalToken
                }
            };

            if (tfaToken) {
                params.tfaToken = tfaToken;
            }

            this.httpApi.post<any>(`${this.config.api.endpoints.universal}/authenticate`, params, options).subscribe({
                next: (response) => {
                    if (response && response.success) {
                        this.processAuthentication(response.body)
                            .then(() => resolve())
                            .catch((error) => reject(error));
                    } else {
                        reject(response.body);
                    }
                },
                error: (error) => this.dialogService.error(this.constructor.name, error)
            });
        });
    }

    public hasAccount(clockNumber: string) {
        return new Promise<any>((resolve, reject) => {
            let params: any = { clockNumber };
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UniversalToken: this.universalToken
                }
            };

            this.httpApi.post<any>(`${this.config.api.endpoints.universal}/authenticate/account`, params, options).subscribe(
                (response) => {
                    if (response && response.success) {
                        resolve(response.body);
                    } else if (response && !response.success) {
                        reject(response.body);
                    } else {
                        reject(response);
                    }
                },
                (error) => this.dialogService.error(this.constructor.name, error)
            );
        });
    }

    public validateAccount(clockNumber: string, pinNumber: string) {
        return new Promise<void>((resolve, reject) => {
            let params: any = {
                clockNumber,
                pinNumber
            };
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UniversalToken: this.universalToken
                }
            };

            this.httpApi.post<any>(`${this.config.api.endpoints.universal}/authenticate/validate/account`, params, options).subscribe(
                (response) => {
                    if (response && response.success) {
                        resolve();
                    } else if (response && !response.success) {
                        reject(response.body);
                    } else {
                        reject(response);
                    }
                },
                (error) => this.dialogService.error(this.constructor.name, error)
            );
        });
    }

    public logout(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UniversalToken: this.universalToken
                }
            };

            this.httpApi.get<any>(`${this.config.api.endpoints.universal}/authenticate/logout`, options).subscribe(
                (response) => {
                    this.authService.deactivate();

                    if (response && response.success) {
                        this.authService.removeUser()
                            .then(() => resolve())
                            .catch((error) => reject(error));
                    } else {
                        reject(response.body);
                    }
                },
                (error) => reject(error)
            );
        });
    }

    public hasAccess(claims: string[]): boolean {
        if (this.staffUser && this.staffUser.userDetails && this.staffUser.userDetails.claims) {
            return claims.some((claim) => {
                let claimRegex = new RegExp('^' + claim + '\\.');
                let claimFound = this.staffUser.userDetails.claims.some((clm) => {
                    return claim === clm || !!(clm.match(claimRegex));
                });

                return claimFound;
            });
        }

        return false;
    }

    public setUniversalToken(token: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!this.staffUser) {
                this.staffUser = {
                    applicationToken: null,
                    universal: {
                        // menu: null,
                        userDetails: null
                    },
                    userDetails: null
                };
            }

            this.staffUser.universal.token = token;

            this.authService.saveUser(this.staffUser)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    }

    public validateUniversal(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    UniversalToken: this.universalToken
                }
            };

            this.httpApi.get<any>(`${this.config.api.endpoints.universal}/authenticate/validate/universal`, options).subscribe(
                (response) => {
                    if (response && response.success) {
                        this.processAuthentication(response.body)
                            .then(() => resolve())
                            .catch((error) => reject(error));
                    } else {
                        this.authService.deactivate();

                        this.authService.removeUser()
                            .then(() => reject(response.body))
                            .catch((error) => reject(error));
                    }
                },
                (error) => reject(error)
            );
        });
    }

    private validateUser(ignore: boolean = false): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let options: HttpApiOptions = {
                authorization: {
                    ApplicationId: this.config.app.id,
                    ApplicationToken: this.applicationToken,
                    UniversalToken: this.universalToken
                }
            };

            this.httpApi.get<any>(`${this.config.api.endpoints.universal}/authenticate/validate/application`, options).subscribe(
                (response) => {
                    if (response && response.success) {
                        this.processAuthentication(response.body, ignore)
                            .then(() => resolve())
                            .catch((error) => reject(error));
                    } else {
                        this.authService.deactivate();

                        reject(response.body);
                    }
                },
                (error) => reject(error)
            );
        });
    }

    private validUser(ignore: boolean = false) {
        let valid = false;

        if (this.staffUser) {
            if (this.staffUser.applicationToken && this.universalToken) {
                valid = true;
            }
        }

        if (valid) {
            this.authService.activate(ignore);
        } else {
            this.authService.deactivate();
        }
    }

    private processAuthentication(response: any, ignore: boolean = false): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let universal: Universal = (response && response.universal) ? response.universal : null;
            let applicationToken = (response && response.token) ? response.token : null;
            let details: StaffUserDetails = (applicationToken) ? jwtDecode(applicationToken) : null;

            this.staffUser = {
                applicationToken: applicationToken,
                universal: {
                    token: (this.staffUser && this.staffUser.universal && this.staffUser.universal.token) ?
                        this.staffUser.universal.token : null,
                    // menu: (universal) ? universal.menu : [],
                    userDetails: (universal) ? universal.userDetails : null
                },
                userDetails: details
            };

            this.authService.saveUser(this.staffUser)
                .then(() => {
                    this.validUser(ignore);
                    resolve();
                })
                .catch((error) => reject(error));
        });
    }

    private dataChange() {
        if (this.authService.ready.getValue() === true) {
            this.change.next();
        }
    }
}
