@import '/src/theme/variables';

component-range-actions {
    .range-action-types {
        &.column {
            width: 300px;
            margin-left: 30px;
        }

        justify-content: space-evenly;
        flex-wrap:wrap;
        padding: 20px 0;

        .type {
            min-width: 210px;
            max-width: 25%;
            margin: 10px 0;
            padding: 20px;
            border: 1px solid color('light-grey');
            position: relative;

            h5 {
                margin: 0 0 10px 0;
                font-size: 1.1rem;
                font-weight: 600;
                letter-spacing: 1px;
            }

            div {
                margin-bottom: 80px;

                p {
                    line-height: 1.4rem;
                }
            }

            a,
            button {
                bottom: 20px;
                position: absolute;
                width: calc(100% - 40px);
            }
        }

        .type:first-child {
            margin-left: 0;
        }

        .type:last-child {
            margin-right: 0;
        }
    }

    @media only screen and (max-width: 1023px) {
        .range-action-types {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));

            &.column {
                width: 90vw;
                margin: 0;
            }

            .type {
                margin: 10px auto !important;
            }
        }
    }
}