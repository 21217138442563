import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';

import { TransferBasketDialogComponent } from './transfer-basket.dialog.component';
import { CustomerLookupComponentModule } from '@app/shared/components/customer-lookup/customer-lookup.component.module';

@NgModule({
    imports: [
        DesignModule,
        CustomerLookupComponentModule
    ],
    declarations: [
        TransferBasketDialogComponent
    ]
})
export class TransferBasketDialogModule { }
