<header class="content-contained">
    <h1>TERMS & CONDITIONS</h1>
    <p>This page (together with the documents referred to on it) tells you the terms and conditions on which we supply any of the Products (Products) listed on our website www.diy-kitchens.com (our site) to you. Please read these terms and conditions carefully and make sure that you understand them, before ordering any Products from our site. You should understand that by ordering any of our Products, you agree to be bound by these terms and conditions. You should print a copy of these terms and conditions for future reference.</p>
</header>

<hr class="narrow" />

<section class="content-contained">
    <h4>1. Information about us</h4>
    <p><strong>1.1.</strong> We operate the website www.diy-kitchens.com. We are <strong>ULTIMA FURNITURE SYSTEMS
            LIMITED</strong>, a company registered in England and Wales under company number 01918871 and with our
        registered office at Unit 26, Lidgate Crescent, Langthwaite Business Park, South Kirkby, West Yorkshire, WF9
        3NR. Our main trading address is Unit 26, Lidgate Crescent, Langthwaite Business Park, South Kirkby, West
        Yorkshire, WF9 3NR. Our VAT number is 436 502 956.</p>
    <p><strong>1.2.</strong> Our trading name is <strong>DIY KITCHENS.</strong>
    </p>
    <p><strong>1.3.</strong> We also operate a telephone help service to deal with enquiries and after sales issues for
        those customers who wish to purchase or have purchased Products as listed on our site. Our telephone number is
        01977608418.</p>
    <h4>2. Your status</h4>
    <p>By placing an order through our site, you warrant that:</p>
    <p><strong>2.1.</strong> you are legally capable of entering into binding contracts;</p>
    <p><strong>2.2.</strong> you are at least 18 years old; and</p>
    <p><strong>2.3.</strong> you have read and understood the guidance on our site as to how orders should be made; and
    </p>
    <p><strong>2.4.</strong> you are resident in the United Kingdom.</p>
    <h4>3. Samples, quotations &amp; telephone assistance</h4>
    <p><strong>3.1.</strong> We may, on our site or on request, provide to you samples, descriptions and/or drawings of
        the Products or advertising catalogues or brochures, these are produced solely to provide you with an
        approximate idea of the Products they describe. They are not provided to give an indication of the quality,
        size, colour or description of the Products which are for sale on our site.</p>
    <p><strong>3.2.</strong> Certain samples are subject to a small charge which will be notified to you at the time the
        sample is requested. Such charge will be refunded either when a full order is made and Dispatch Confirmation
        sent or when they are returned to us, in either case, provided they are returned to us in good and resalable
        condition and you have complied with your obligations under the Contract.</p>
    <p><strong>3.3.</strong> Any quotation given for the Products is given on the basis that a binding contract shall
        only come into existence in accordance with clause 4. A quotation shall be valid for a period of 30 calendar
        days from its date of issue unless we notify you in writing that we have withdrawn it during this period.</p>
    <p><strong>3.4.</strong> As set out in clause 1.3 above, we provide a telephone helpline. This helpline is there
        purely to answer enquiries and provide advice and assistance to you in relation to our Products. It does not
        create any further obligations to you from us contractual or otherwise, unless any such representations made are
        agreed with you in writing by a director or our company. Save in relation to fraud or fraudulent
        misrepresention, we shall have no liability for any representations not agreed in writing, even if they prove
        untrue or misleading.</p>
    <h4>4. How the contract is formed between you &amp; us</h4>
    <p><strong>4.1.</strong> After placing an order, you will receive an e-mail from us acknowledging that we have
        received your order. Please note that this does not mean that your order has been accepted. Your order
        constitutes an offer to us to buy Product(s) subject to these terms and conditions. All orders are subject to
        acceptance by us, and we will confirm such acceptance to you by sending you an e-mail that confirms that the
        Product has been dispatched (Dispatch Confirmation). The Dispatch Confirmation will include an order number,
        please quote this order number in all subsequent correspondence between us. The contract between us (Contract)
        will only be formed when we send you the Dispatch Confirmation.</p>
    <p><strong>4.2.</strong> The Contract will relate only to those Products whose dispatch we have confirmed in the
        Dispatch Confirmation. We will not be obliged to supply any other Products which may have been part of your
        order until the dispatch of such Products has been confirmed in a separate Dispatch Confirmation.</p>
    <h4>5. Our status</h4>
    <p><strong>5.1.</strong> Please note that some of the Products belong to third party sellers. In some cases, we
        accept orders as agents on behalf of those third party sellers. The resulting legal contract is between you and
        that third party seller, and is subject to the terms and conditions of that third party seller, which they will
        advise you of themselves. You should carefully review their terms and conditions applying to the transaction.
    </p>
    <p><strong>5.2. We shall not be liable for any losses that result from the failure of third party Products to
            perform or where such Products are defective. We will however, transfer to you such rights as we have
            against such third party sellers and, so far as we are able, without incurring any costs, we will assist you
            in rectifying the situation with the third party supplier.</strong></p>
    <p><strong>5.3.</strong> We may also provide links on our site to the websites of other companies, whether
        affiliated with us or not. We cannot give any undertaking that Products you purchase from third party sellers
        through our site, or from companies to whose website we have provided a link on our site, will be of
        satisfactory quality, and any such warranties are <strong>DISCLAIMED</strong> by us absolutely. This
        <strong>DISCLAIMER</strong> does not affect your statutory rights against the third party seller. We will notify
        you when a third party is involved in a transaction, and we may disclose your personal information related to
        that transaction to the third party seller.</p>
    <h4>6. Consumer rights</h4>
    <p><strong>6.1.</strong> If you are contracting as a consumer, you may cancel a Contract at any time within seven
        working days, beginning on the day after you received the Products unless the Products are made to your
        specification or have been clearly personalised (which shall be dealt with in accordance with clause 6.3). If
        the Products are not made to your specification or personalised, you will subject to the Products being returned
        unused, in their original packaging and in the condition they were delivered to you, receive a full refund of
        the price paid for the Products in accordance with our refunds policy (set out in clause 10 below).</p>
    <p><strong>6.2.</strong> To cancel a Contract, you must inform us in writing. You must also return any samples and
        Products to us as soon as reasonably practicable, and at your own cost. You have a legal obligation to take
        reasonable care of the Products while they are in your possession. If you fail to comply with this obligation,
        we may have a right of action against you for compensation.</p>
    <p><strong>6.3. Where the Products have been made to your specification or clearly personalised you do not have the
            right to cancel the Contract unless the provisions of clause 10.2 apply.</strong></p>
    <p><strong>6.4.</strong> Details of your legal rights, and an explanation of how to exercise them, are available
        from your local Citizens' Advice Bureau or trading standards office. This provision does not affect your other
        statutory rights as a consumer.</p>
    <h4>7. Availability &amp; delivery</h4>
    <p><strong>7.1.</strong> We will use all reasonable endeavours to fulfil your order between or on the delivery dates
        set out in the Dispatch Confirmation or, if no delivery date is specified, then within a reasonable time of the
        date of the Dispatch Confirmation, unless there are exceptional circumstances and the delivery is affected by
        factors beyond our control.</p>
    <p><strong>7.2.</strong> In certain circumstances were Products are provided by third party sellers they are
        responsible for delivery of those Products. We shall not be liable for any delay or failure of delivery in these
        circumstances and the provisions of clauses 5.1 and 5.2 apply.</p>
    <p><strong>7.3.</strong> Delivery dates are not guaranteed and we will let you know if we become aware of an
        unexpected delay and will arrange a new delivery date with you.</p>
    <p><strong>7.4.</strong> If you fail to take delivery of an order on the specified delivery date, we will notify you
        that the products are ready for delivery, or we cannot deliver due to your failure to provide relevant
        instructions, documents, licenses or authorisations then, except where this failure is caused by our failure to
        comply with these terms and conditions or by an event beyond your control:</p>
    <ul>
        <li><strong>7.4.1.</strong> we will store the Goods until delivery takes place and may charge you a reasonable
            sum currently £20 a day to cover expenses and insurance;</li>
        <li><strong>7.4.2.</strong> we shall have no liability to you for late delivery; and</li>
        <li><strong>7.4.3.</strong> where delivery has taken place as agreed but we were unable to leave the Products we
            will have the right to any additional delivery charges if you require a further delivery at a later date.
        </li>
    </ul>
    <p><strong>7.5.</strong> If you have not taken delivery of the Products within two weeks of our notifying you that
        they are ready, we may, after giving you reasonable prior notice in writing, resell or otherwise dispose of part
        or all of the Products and, after deducting reasonable storage and selling costs, or the price if they are
        bespoke Products and cannot be resold, pay you for any excess over the price of the Products or charge you for
        any shortfall below their original price.</p>
    <p><strong>7.6.</strong> If we are not able to deliver the whole of the order at one time due to operational reasons
        or shortage of stock, we will deliver the order in instalments. We will not charge you extra delivery costs for
        this. If you ask us to deliver the order in instalments, we may charge you extra delivery costs. Each instalment
        shall constitute a separate Contract. If we are late delivering an instalment or one instalment is faulty, that
        will not entitle you to cancel any other instalment.</p>
    <p><strong>7.7.</strong> Upon delivery of the Products you are responsible for inspecting the Products and reporting
        to us within 10 days of any alleged defect, fault or error before the Products are used or fitted. We shall have
        no liability for any claim in respect of an alleged defect, fault or error which would be apparent on inspection
        before use or fitting.</p>
    <p><strong>7.8.</strong> You may find that you need to add items to your kitchen order, for example if you have
        extended your kitchen or if you have missed something. Whilst we will endeavour to keep lead times to a minimum,
        please be aware that a standard lead time may apply. This can be found on the range page of the website.</p>
    <p><strong>7.9.</strong> For the safety of our delivery drivers, we require them to wear safety footwear when
        delivering your kitchen. Please make any necessary arrangements to protect your flooring, prior to your kitchen
        arriving.</p>
    <h4>8. Risk &amp; title</h4>
    <p><strong>8.1.</strong> The Products will be your responsibility from the time of delivery.</p>
    <p><strong>8.2.</strong> Ownership of the Products will only pass to you when we receive full payment in cleared
        funds of all sums due in respect of the Products, including delivery charges.</p>
    <h4>9. Price &amp; payment</h4>
    <p><strong>9.1.</strong> The price of the Products and our delivery charges will be as quoted on our site from time
        to time, except in cases of obvious error.</p>
    <p><strong>9.2.</strong> Product prices &amp; delivery charges exclude VAT.</p>
    <p><strong>9.3.</strong> Product prices and delivery charges are liable to change at any time, but changes will not
        affect orders in respect of which we have already sent you a Dispatch Confirmation.</p>
    <p><strong>9.4.</strong> Our site contains a large number of Products and it is always possible that, despite our
        best efforts, some of the Products listed on our site may be incorrectly priced. We will normally verify prices
        as part of our dispatch procedures so that, where a Product's correct price is less than our stated price, we
        will charge the lower amount when dispatching the Product to you. If a Product's correct price is higher than
        the price stated on our site, we will normally, at our discretion, either contact you for instructions before
        dispatching the Product, or reject your order and notify you that we are rejecting it.</p>
    <p><strong>9.5.</strong> If the pricing error is obvious and unmistakeable and could have been reasonably recognised
        by you as an error, we do not have to provide the Products to you at the incorrect (lower) price.</p>
    <p><strong>9.6.</strong> Payment for all Products must be by credit or debit card. We accept all major credit and
        debit cards with the exception of American Express.</p>
    <p><strong>9.7.</strong> Any amendments to orders that require an additional payment will be taken from the card
        details originally provided. If we are unable to do so, we will contact you for the additional payment.</p>

    <h4>10. Our refunds policy</h4>
    <p><strong>10.1.</strong> It is advisable when returning any item(s) to us you cover with the appropriate additional
        insurance to cover the total item(s) value. If this additional cover isn't applied to item(s) that are returned
        to us we cannot be held responsable for any damage that may occur in transit by the carrier.</p>
    <p><strong>10.2.</strong> If you return a Product to us because you have cancelled the Contract between us within
        the seven-day cooling-off period (see clause 6.1 above), we will process the refund due to you as soon as
        possible and, in any case, within 10 days of the day on which you returned the Products to us. In this case, we
        will refund the price of the Product in full, and any applicable delivery charges. However, you will be
        responsible for the cost of returning the Products to us. Products returned after the 10 day period will be
        subject to a restocking charge of up to 25%.</p>
    <p><strong>10.3.</strong> If you return the Products to us for any other reason for instance, because you consider
        that the Product is defective, or in circumstances where you notify us the Products are defective and we agree
        to collect them from you on a date agreed between us or we ask you to return the Products to us at our cost or
        we agree that you can provide suitable photographic evidence, we will examine the returned Product or evidence
        as appropriate and will notify you:</p>
    <ul>
        <li><strong>10.3.1.</strong> that we will provide you with a full or partial refund via e-mail within a
            reasonable period of time; or</li>
        <li><strong>10.3.2.</strong> replace the Products; or</li>
        <li><strong>10.3.3.</strong> repair the Products.</li>
    </ul>
    <p><strong>10.4.</strong> We will usually process any refund due to you as soon as possible and, in any case, within
        30 days of the day we confirmed to you via e-mail that you were entitled to a refund. We will, where we agree a
        refund, refund the price of a defective Product in full, any applicable delivery charges and if relevant, any
        reasonable costs you incur in returning the item to us.</p>
    <p><strong>10.5.</strong> We will usually refund any money received from you using the same method originally used
        by you to pay for your purchase.</p>
    <p><strong>10.6.</strong> These terms and conditions will apply to any repaired or replacement Products we supply to
        you.</p>
    <p><strong>10.7.</strong> Non returnable products:</p>
    <ul>
        <li><strong>10.7.1.</strong> Kitchens Units as these are make bespoke to your order;</li>
        <li><strong>10.7.2.</strong> Bespoke painted doors;</li>
        <li><strong>10.7.3.</strong> Bespoke painted cornices, plinths, pelmet &amp; decorative items;</li>
        <li><strong>10.7.4.</strong> Colour matched doors, cornices, plinths, pelmet &amp; decorative items;</li>
        <li><strong>10.7.5.</strong> Any items made as a special request;</li>
        <li><strong>10.7.6.</strong> Any other painted items maybe rejected at our discretion;</li>
    </ul>
    <h4>11. Warranty</h4>
    <p><strong>11.1.</strong> We warrant to you that any Product purchased from us through our site will, on delivery,
        conform in all material respects with its description, be of satisfactory quality, and be reasonably fit for all
        the purposes for which Products of that kind are commonly supplied.</p>
    <p><strong>11.2.</strong> This warranty does not apply to any defect in the Products arising from:</p>
    <ul>
        <li><strong>11.2.1.</strong> fair wear and tear;</li>
        <li><strong>11.2.2.</strong> wilful damage, accident or negligence by you or any third party;</li>
        <li><strong>11.2.3.</strong> if you use the Products in a way that we do not recommend;</li>
        <li><strong>11.2.4.</strong> your failure to follow our instructions;</li>
        <li><strong>11.2.5.</strong> any alteration or repair you carry out without our prior written approval; or</li>
        <li><strong>11.2.6.</strong> any incorrect instructions or plans submitted by you on our site to enable us to
            provide the Products.</li>
    </ul>
    <p><strong>11.3.</strong> Our site is provided on an "as is" basis and we make no representations or warranties of
        any kind in respect of our site, including, without limitation, any warranties relating to accuracy or
        completeness of our site or suitability of the information provided.</p>
    <h4>12. Our liability</h4>
    <p><strong>12.1.</strong> Subject to clause 12.4, if we fail to comply with these terms and conditions, we shall
        only be liable to you for the purchase price of the Products and, subject to clause 12.2, any losses that you
        suffer as a result of our failure to comply (whether arising in contract, tort (including negligence), breach of
        statutory duty or otherwise) which are a foreseeable consequence of such failure.</p>
    <p><strong>12.2.</strong> Subject to clause 12.4, we will not be liable for losses that result from our failure to
        comply with these terms and conditions that fall into the following categories even if such losses result from
        our deliberate breach:</p>
    <ul>
        <li><strong>12.2.1.</strong> loss of income or revenue;</li>
        <li><strong>12.2.2.</strong> loss of business;</li>
        <li><strong>12.2.3.</strong> loss of profits;</li>
        <li><strong>12.2.4.</strong> loss of anticipated savings;</li>
        <li><strong>12.2.5.</strong> loss of data; or</li>
        <li><strong>12.2.6.</strong> waste of management or office time.</li>
    </ul>
    <p>However, this clause 12.2 will not prevent claims for loss of or damage to your physical property that are
        foreseeable or any other claims for direct loss that are not excluded by categories 12.2.1 to 12.2.6 inclusive
        of this clause 12.2.</p>
    <p><strong>12.3.</strong> We only supply the Products for domestic and private use. You agree not to use the
        Products for any commercial, business or re-sale purpose, and we have no liability to you where Products are
        used other than for domestic and private use.</p>
    <p><strong>12.4.</strong> Nothing in this agreement excludes or limits our liability for:</p>
    <ul>
        <li><strong>12.4.1.</strong> death or personal injury caused by our negligence;</li>
        <li><strong>12.4.2.</strong> fraud or fraudulent misrepresentation;</li>
        <li><strong>12.4.3.</strong> any breach of the obligations implied by section 12 of the Sale of Goods Act 1979
            or section 2 of the Supply of Goods and Services Act 1982;</li>
        <li><strong>12.4.4.</strong> defective Products under the Consumer Protection Act 1987; or</li>
        <li><strong>12.4.5.</strong> any other matter for which it would be illegal for us to exclude or attempt to
            exclude our liability.</li>
    </ul>
    <p><strong>12.5.</strong> Where you buy any Product from a third party seller through our site, the seller's
        individual liability will be set out in the seller's terms and conditions.</p>
    <p><strong>12.6.</strong> You are responsible for installing the Products in your property and we will not be
        responsible for any damage caused to the Products or your property arising during the course of installation.
    </p>
    <p><strong>12.7.</strong> Where kitchen unit carcasses are ordered by you and manufactured to your specific
        measurements we accept no liability where you have failed to allow adequate room for ventilation or ordered
        incorrect sizes.</p>
    <h4>13. Import duty</h4>
    <p><strong>13.1.</strong> If you order Products from our site for delivery outside the UK, they may be subject to
        import duties and taxes which are levied when the delivery reaches the specified destination. You will be
        responsible for payment of any such import duties and taxes. Please note that we have no control over these
        charges and cannot predict their amount. Please contact your local customs office for further information before
        placing your order.</p>
    <p><strong>13.2.</strong> Please also note that you must comply with all applicable laws and regulations of the
        country for which the Products are destined. We will not be liable for any breach by you of any such laws.</p>
    <h4>14. Written communications</h4>
    <p>Applicable laws require that some of the information or communications we send to you should be in writing. When
        using our site, you accept that communication with us will be mainly electronic. We will contact you by e-mail
        or provide you with information by posting notices on our website. For contractual purposes, you agree to this
        electronic means of communication and you acknowledge that all contracts, notices, information and other
        communications that we provide to you electronically comply with any legal requirement that such communications
        be in writing. This condition does not affect your statutory rights.</p>
    <h4>15. Bespoke, custom made kitchen units, bespoke painted kitchens &amp; oak timber kitchens</h4>
    <p><strong>15.1. </strong>Our site allows you to provide your own specific measurements and specifications, it also
        provides you with the opportunity to send to us any special requirements or instructions you may have. Please
        ensure all of these are completed fully and accurately as we shall not be liable for any defects or incorrect
        Products delivered as a result of your failure to provide correct information to enable us to deliver the
        Products in accordance with the Contract.</p>
    <p><strong>15.2. </strong>Consumers cannot cancel, return or obtain refunds for items that are made to the
        customer's own specification or size (Unless manufactured incorrectly by DIY Kitchens). It is essential that you
        are happy with your kitchen order prior to confirming/paying for the order online on our website. <br />
        <br />
        All the kitchen units we produce are made to order (not taken from existing stock) to the customer's own
        specification. (<a
            href="https://webarchive.nationalarchives.gov.uk/20140402142426/https://www.oft.gov.uk/shared_oft/business_leaflets/general/oft913.pdf"
            target="_blank" title="Distance Selling Regulations">Read more on bespoke/custom items on page 9 of the
            distance selling document here</a>)</p>
    <p><strong>15.3. </strong>When placing an order for a painted kitchen please be aware of the following:-</p>
    <ul>
        <li><strong>15.3.1.</strong> Due to the bespoke nature of a painted product, it is not possible to cancel or
            return orders once they have entered the production stage or have been delivered.</li>
        <li><strong>15.3.2.</strong> Although we strive to maintain consistency, for a bespoke painted product there
            will be batch to batch variances and we cannot guarantee an exact colour match between orders.</li>
        <li><strong>15.3.3.</strong> We will hold the paint batch for your kitchen for 1 month from the kitchen going
            into production and we highly recommend you double check your order before completing to avoid any part
            orders or additional extras. After 1 month all batches of paint are disposed with, if you require us to
            order more for additional extras there will be a charge for this and again we cannot guarantee the batch
            variances.</li>
        <li><strong>15.3.4.</strong> Bespoke painted products are susceptible to cracking along joints. Touch-up paints
            are available to order.</li>
        <li><strong>15.3.5.</strong> The paint colours on our sample doors are indicative of the colours in our bespoke
            painted range but may not be exact due to batch variances mentioned above.</li>
    </ul>
    <p><strong>15.4. </strong>When placing an order for a oak timber kitchen please be aware of the following:-</p>
    <ul>
        <li><strong>15.4.1.</strong> Oak is a natural product and incorporates features such as knots and medullary
            rays. Different grain structures and subtle differences in hue make each piece of oak unique. We specially
            select our timber to give a balance between character and consistency.</li>
    </ul>
    <p><strong>15.5 </strong>As a natural product, timber is susceptible to changes in temperature, light and humidity.
        The timber we use to make shaker kitchen doors is thoroughly kiln dried ready for processing to minimise any
        movement in the timber. When painted, environmental changes can sometimes cause very slight 'whisper lines' in
        the paint along flush joints. This is normal for a timber product and won't affect the door when in use.</p>
    <p><strong>15.6 </strong>We have invested in state of the art spray lines in order to achieve a high quality,
        furniture finish on all our painted doors. We quality check our doors to ensure consistently high standards.
        Doors are inspected for defects by holding at arm's length. This is an industry standard method for checking
        painted timber and timber derived materials such as MDF. The reason doors are checked at arm's length and not
        scrutinised close-up is that the nature of this type of product means that sometimes very small particles may be
        present in the lacquered surface. These tiny inclusions are normal and won't affect the overall look of the
        kitchen or the durability of the door when in use.</p>

    <h4>16. Cancellation by us</h4>
    <p><strong>16.1. </strong>We reserve the right to cancel the Contract between us if:</p>
    <ul>
        <li><strong>16.1.1.</strong> we or our suppliers have insufficient stock to deliver the Products you have
            ordered or such Products have been discontinued;</li>
        <li><strong>16.1.2.</strong> we do not deliver to your area; or</li>
        <li><strong>16.1.3.</strong> one or more of the Products you ordered was listed at an incorrect price due to a
            typographical error or an error in the pricing information received by us from our supplier.</li>
    </ul>
    <p><strong>16.2.</strong> If we do cancel your Contract we will notify you by e-mail and will re-credit you account
        by any sum deducted by us from your credit card as soon as possible but in any event within 30 days of your
        order. We will not be liable to pay any additional compensation for disappointment suffered.</p>
    <p><strong>16.3.</strong> When we or our suppliers have insufficient stock to deliver the Products you have ordered
        or such Products have been discontinued we reserve the right to provide substitute Products to you of the
        equivalent quality and price at our discretion instead of exercising our right to cancel in condition 16.1
        above. If you, as a consumer exercise your right to cancel as set out in condition 6 the cost of returning such
        substitute Products to us will be met by us.</p>
    <h4>17. Notices</h4>
    <p>All notices given by you to us must be given to Ultima Furniture Systems Limited at our address set out above or
        by email to sales&#64;diy-kithcens.com. We may give notice to you at either the e-mail or postal address you provide
        to us when placing an order, or in any of the ways specified in clause 14 above. Notice will be deemed received
        and properly served immediately when posted on our website, 24 hours after an e-mail is sent, or three days
        after the date of posting of any letter. In proving the service of any notice, it will be sufficient to prove,
        in the case of a letter, that such letter was properly addressed, stamped and placed in the post and, in the
        case of an e-mail, that such e-mail was sent to the specified e-mail address of the addressee.</p>
    <h4>18. Transfer of rights &amp; obligations</h4>
    <p><strong>18.1.</strong> The contract between you and us is binding on you and us and on our respective successors
        and assignees.</p>
    <p><strong>18.2.</strong> You may not transfer, assign, charge or otherwise dispose of a Contract, or any of your
        rights or obligations arising under it, without our prior written consent.</p>
    <p><strong>18.3.</strong> We may transfer, assign, charge, sub-contract or otherwise dispose of a Contract, or any
        of our rights or obligations arising under it, at any time during the term of the Contract.</p>
    <h4>19. Events outside our control</h4>
    <p><strong>19.1.</strong> We will not be liable or responsible for any failure to perform, or delay in performance
        of, any of our obligations under a Contract that is caused by events outside our reasonable control
        <strong>(Force Majeure Event)</strong>.</p>
    <p><strong>19.2. </strong>A Force Majeure Event includes any act, event, non-happening, omission or accident beyond
        our reasonable control and includes in particular (without limitation) the following:</p>
    <ul>
        <li><strong>19.2.1.</strong> strikes, lock-outs or other industrial action;</li>
        <li><strong>19.2.2.</strong> civil commotion, riot, invasion, terrorist attack or threat of terrorist attack,
            war (whether declared or not) or threat or preparation for war;</li>
        <li><strong>19.2.3.</strong> fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural
            disaster;</li>
        <li><strong>19.2.4.</strong> impossibility of the use of railways, shipping, aircraft, motor transport or other
            means of public or private transport;</li>
        <li><strong>19.2.5.</strong> impossibility of the use of public or private telecommunications networks;</li>
        <li><strong>19.2.6.</strong> the acts, decrees, legislation, regulations or restrictions of any government; and
        </li>
        <li><strong>19.2.7.</strong> pandemic or epidemic.</li>
    </ul>
    <p><strong>19.3.</strong> Our performance under any Contract is deemed to be suspended for the period that the Force
        Majeure Event continues, and we will have an extension of time for performance for the duration of that period.
        We will use our reasonable endeavours to bring the Force Majeure Event to a close or to find a solution by which
        our obligations under the Contract may be performed despite the Force Majeure Event.</p>
    <h4>20. Waiver</h4>
    <p><strong>20.1.</strong> If we fail, at any time during the term of a Contract, to insist upon strict performance
        of any of your obligations under the Contract or any of these terms and conditions, or if we fail to exercise
        any of the rights or remedies to which we are entitled under the Contract, this will not constitute a waiver of
        such rights or remedies and will not relieve you from compliance with such obligations.</p>
    <p><strong>20.2.</strong> A waiver by us of any default will not constitute a waiver of any subsequent default.
    </p>
    <p><strong>20.3.</strong> No waiver by us of any of these terms and conditions will be effective unless it is
        expressly stated to be a waiver and is communicated to you in writing in accordance with clause 14 above.</p>
    <h4>21. Severability</h4>
    <p>If any court or competent authority decides that any of the provisions of these terms and Conditions or any
        provisions of a Contract are invalid, unlawful or unenforceable to any extent, the term will, to that extent
        only, be severed from the remaining terms, which will continue to be valid to the fullest extent permitted by
        law.</p>
    <h4>22. Entire agreement</h4>
    <p><strong>22.1.</strong> These terms and conditions and any document expressly referred to in them constitute the
        whole agreement between us and supersede all previous discussions, correspondence, negotiations, previous
        arrangement, understanding or agreement between us relating to the subject matter of any Contract.</p>
    <p><strong>22.2.</strong> We each acknowledge that, in entering into a Contract, neither of us relies on, or will
        have any remedies in respect of, any representation or warranty (whether made innocently or negligently) that is
        not set out in these terms and conditions or the documents referred to in them. Any changes to the specification
        of the Products or variations to the contract or these terms and conditions that you agree with our authorised
        employees or agents will only be binding if recorded in writing and signed by a director of the company.</p>
    <p><strong>22.3.</strong> Each of us agrees that our only liability in respect of those representations and
        warranties that are set out in this agreement (whether made innocently or negligently) will be for breach of
        contract.</p>
    <p><strong>22.4.</strong> Nothing in this clause limits or excludes any liability for fraud.</p>
    <h4>23. Our right to vary these terms &amp; conditions</h4>
    <p><strong>23.1.</strong> We have the right to revise and amend these terms and conditions from time to time to
        reflect changes in market conditions affecting our business, changes in technology, changes in payment methods,
        changes in relevant laws and regulatory requirements and changes in our system's capabilities.</p>
    <p><strong>23.2.</strong> You will be subject to the policies and terms and conditions in force at the time that you
        order Products from us, unless any change to those policies or these terms and conditions is required to be made
        by law or governmental authority (in which case it will apply to orders previously placed by you), or if we
        notify you of the change to those policies or these terms and conditions before we send you the Dispatch
        Confirmation (in which case we have the right to assume that you have accepted the change to the terms and
        conditions).</p>
    <h4>24. Law &amp; jurisdiction</h4>
    <p>Contracts for the purchase of Products through our site and any dispute or claim arising out of or in connection
        with them or their subject matter or formation (including non-contractual disputes or claims) will be governed
        by English law. Any dispute or claim arising out of or in connection with such Contracts or their formation
        (including non-contractual disputes or claims) will be subject to the non-exclusive jurisdiction of the courts
        of England and Wales.</p>
    <h4>25. Third party rights</h4>
    <p>A person who is not party to these terms and conditions or a Contract shall not have any rights under or in
        connection with them under the Contracts (Rights of Third Parties) Act 1999.</p>
    <h4>26. Email &amp; SMS Communications</h4>
    <p>By providing your contact details to DIY Kitchens, you will be indicating to us your consent for us to contact
        you by email and SMS to let you know about your order delivery or offers/promotions, which may be of interest to
        you, unless you indicate an objection to receiving such messages. You can also unsubscribe from marketing email
        communications from within our emails.</p>

    <h4>27. Minimum order value</h4>
    <p>We have a minimum order value of £750 for complete kitchen orders. This is due to the manufacturing process of
        making each kitchen to order. Please note that small orders for extras for existing kitchen orders and door/unit
        samples can still be ordered through the website.</p>

    <h4>28. Time allowed to check your delivery</h4>
    <p>We do wrap our units and accessories extremely well but should any of your order arrive damaged then we allow you
        the customer, 10 days in which to check your order for damages. Please ensure that you have checked your kitchen
        delivery within those first 10 days after delivery, as we are unable to replace damages after that point.</p>
</section>

<component-latest-offers></component-latest-offers>