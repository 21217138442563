import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { Config } from '@app/config';
import { StringHelper } from '@app/utilities/helpers';

import { CatalogueService } from '@app/services/catalogue';
import { IRangeColour } from '@app/services/catalogue/models';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';

import {
    IKitchenFinish,
    IKitchenStyle,
    ICustomerReview,
    IVideoSafeResourceUrl,
    IKitchenFilterOption,
    IKitchenFilters
} from './models';

@Component({
    selector: 'page-reviews',
    templateUrl: './reviews.page.html',
    styleUrls: ['./reviews.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ReviewsPage implements OnInit, OnDestroy {
    public loaded: boolean = false;

    public pageIndex: number = 0;
    public pageSize: number = 5;

    public selectedRangeType: string;
    public selectedColour: string;
    public selectedFinish: string;
    public selectedReviewType: string
    public filters: IKitchenFilters;
    public customerReviews: ICustomerReview[];
    public filteredResults: ICustomerReview[] = [];
    public paginatedResults: ICustomerReview[] = [];

    private routeHistory: RouteHistory[] = [{ title: 'Customer Reviews', route: '/customer-reviews' }];
    private routeSubscription: Subscription;

    constructor(
        private config: Config,
        protected sanitizer: DomSanitizer,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {

                this.selectedRangeType = (route.params.rangeType) ? route.params.rangeType.toLowerCase() : 'allstyles';
                this.selectedColour = (route.params.colour) ? route.params.colour.toLowerCase() : 'allcolours';
                this.selectedFinish = (route.params.finish) ? route.params.finish.toLowerCase() : 'allfinishes';
                this.selectedReviewType = (route.params.reviewType) ? route.params.reviewType.toLowerCase() : 'allreviewtypes';

                this.navigationService.setNavigation({
                    title: 'Customer Reviews',
                    metaTags: [
                        { name: 'no-meta', content: 'no metadata' },
                        { name: 'description', content: 'See kitchens, designed and installed in real homes by real people throughout the UK.'},
                        { property: 'og:description', content: 'See kitchens, designed and installed in real homes by real people throughout the UK.'}
                    ],
                    routeHistory: this.routeHistory
                });

                (async () => {
                    try {
                        await this.initFilters();
                        await this.filterReviews();
                        await this.updatePage();
                        this.loaded = true;
                    } catch (error) {
                        this.dialogService.error(this.constructor.name, error);
                    }
                })();

            });
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }

    private processRawReviews(reviews: ICustomerReview[]): ICustomerReview[] {
        const videoPreferenceSort = (a: ICustomerReview, b: ICustomerReview): number => {
            const hasVideo = (review: ICustomerReview): boolean => { return review.video_id && review.video_id.length > 2 }
            if (hasVideo(a) && !hasVideo(b)) return -1;
            if (!hasVideo(a) && hasVideo(b)) return 1;
            return b.id - a.id;
        }

        reviews.sort(videoPreferenceSort);

        // Add categories, colours, & finished arrays to each review from the # separated list for each.
        reviews.forEach((review: ICustomerReview) => {
            review.categories = review.filter_cat1.split('#').filter(c => c && !c.match(/all-styles/i));
            review.colours = review.filter_cat2.split('#').filter(c => c && !c.match(/all-colours/i));
            review.finish = review.filter_cat3.split('#').filter(c => c && !c.match(/all-finishes/i));
            if (review.video_id && review.video_id.length > 2) {
                review.videoURL = <IVideoSafeResourceUrl>this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + review.video_id + '?rel=0');
            }
            review.paras = StringHelper.extractSentencesFromHtml(review.review);
            review.images = Array.from({ length: review.image_count }, (_, i) => ({
                image: `https://static.diy-kitchens.com/assets/images/kitchen_review_images/${review.id}/large/${i + 1}.jpg`,
                alt: `${review.customer_name} Review`
            }));
        });

        // Replace colours that don't match existing colours
        const colourReplacements: Record<string, string> = {
            'bespoke-painted': 'bespoke',
            'grey': 'light-grey',
            'blue': 'petrol-blue',
            'green': 'fir-green',
            'oak-effect': 'oak',
            'wood-effect': 'oak',
            'beige': 'mussel',
            'pink': 'bespoke',
            'vanilla': 'bespoke',
            'cream': 'bespoke',
            'wenge': 'bespoke',
            'brown': 'bespoke',
            'red': 'bespoke',
            'violet': 'bespoke',
            'ivory': 'bespoke'
        };
        reviews.forEach((review) => {
            if (Array.isArray(review.colours)) {
                review.colours = review.colours.map(colour => colourReplacements[colour] || colour);
            }
        });
        // Replace finishes that don't match existing finishes
        const finishReplacements: Record<string, string> = {
            'smooth-painted': 'smooth',
            'grained-timber': 'wood-effect',
            'painted-timber': 'painted',
            'gloss': 'high-gloss',
            'matt-textured': 'matt',
            'textured-painted': 'painted',
            'painted-timber-effect': 'wood-effect',
            'timber-effect': 'wood-effect',
            'matt-smooth': 'smooth',
            'matt-grained': 'matt'
        }
        reviews.forEach((review) => {
            if (Array.isArray(review.finish)) {
                review.finish = review.finish.map(finish => finishReplacements[finish] || finish);
            }
        });

        return reviews;
    }

    private initFilters(): Promise<boolean> {
        return Promise.all([
            this.catalogueService.getKitchenStyles(),
            this.catalogueService.getReviews(),
            this.catalogueService.getRanges(),
            this.catalogueService.getKitchenFinishes()
        ])
            .then(([styles, reviews, ranges, finishes]: [IKitchenStyle[], ICustomerReview[], any[], IKitchenFinish[]]) => {
                if (!Array.isArray(reviews) || !Array.isArray(styles) || !Array.isArray(ranges)) return false;

                reviews = this.processRawReviews(reviews);

                let rangeColoursSet = new Set<string>();
                ranges.forEach((range) => {
                    const colours: IRangeColour[] = range.colours;
                    if (Array.isArray(colours)) {
                        colours.forEach((colour: IRangeColour) => {
                            rangeColoursSet.add(colour.colour_selector);
                        });
                    }
                });
                const rangeColoursArray = Array.from(rangeColoursSet);

                function extractAndMap(reviews: ICustomerReview[], key: string, mapFunction: (value: string) => IKitchenFilterOption,
                    filterFunction: (values: Set<string>) => Set<string> = (uniqueValues) => { return uniqueValues }) {

                    let uniqueValues = new Set<string>();

                    reviews.forEach((review: ICustomerReview) => {
                        if (Array.isArray(review[key])) {
                            review[key].forEach((value: string) => {
                                uniqueValues.add(value);
                            });
                        }
                    });

                    uniqueValues = filterFunction(uniqueValues);
                    return Array.from(uniqueValues).map(mapFunction);
                }

                const mapCategory = (reviewCategory: string): IKitchenFilterOption => {
                    const id: string = StringHelper.spaceToDash(reviewCategory).toLowerCase();
                    return {
                        id: id,
                        name: StringHelper.titleCase(StringHelper.dashToSpace(reviewCategory)),
                        link: `/customer-reviews/${id}/${this.selectedColour}/${this.selectedFinish}/${this.selectedReviewType}`,
                        image: `https://static.diy-kitchens.com/assets/images/kitchens/${id}-icon.jpg`
                    };
                };

                const mapColour = (reviewColour: string): IKitchenFilterOption => {
                    const id: string = StringHelper.spaceToDash(reviewColour).toLowerCase();
                    return {
                        id: id,
                        name: StringHelper.titleCase(StringHelper.dashToSpace(reviewColour)),
                        link: `/customer-reviews/${this.selectedRangeType}/${id}/${this.selectedFinish}/${this.selectedReviewType}`,
                        image: `innova-${id}`
                    };
                };

                const mapFinish = (reviewFinish: string): IKitchenFilterOption => {
                    const id: string = StringHelper.spaceToDash(reviewFinish).toLowerCase();
                    return {
                        id: id,
                        name: StringHelper.titleCase(StringHelper.dashToSpace(reviewFinish)),
                        link: `/customer-reviews/${this.selectedRangeType}/${this.selectedColour}/${id}/${this.selectedReviewType}`,
                        image: `https://static.diy-kitchens.com/door_finishes/door-finish_${id}.png`
                    };
                }

                const filterCategory = (categories: Set<string>): Set<string> => {
                    let filteredCategory = Array.from(categories).filter((category) => styles.some((style) => style.name.toLowerCase() === category));
                    return new Set(filteredCategory);
                };

                const filterColour = (colours: Set<string>): Set<string> => {
                    const getColourSelector = (name: string): string => StringHelper.spaceToDash(name).toLowerCase();
                    const filteredColours = Array.from(colours).filter((colour) => rangeColoursArray.some((rangeColour) => getColourSelector(rangeColour) === getColourSelector(colour)));
                    return new Set(filteredColours);
                };

                const filterFinish = (reviewFinishes: Set<string>): Set<string> => {
                    const getFinishSelector = (name: string): string => StringHelper.spaceToDash(name).toLowerCase();
                    let filteredFinishes = Array.from(reviewFinishes).filter((reviewFinish) => finishes.some((finish) => getFinishSelector(finish.name) === getFinishSelector(reviewFinish)));
                    return new Set(filteredFinishes);
                };

                const optionSort = (a: IKitchenFilterOption, b: IKitchenFilterOption): number => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                }

                let filterRangesOptions = extractAndMap(reviews, 'categories', mapCategory, filterCategory);
                let filterColoursOptions = extractAndMap(reviews, 'colours', mapColour, filterColour).sort(optionSort);
                let filterFinishesOptions = extractAndMap(reviews, 'finish', mapFinish, filterFinish);

                this.filters = {
                    results: 0,
                    rangeType: {
                        selected: this.selectedRangeType,
                        options: [{
                            id: 'allstyles',
                            name: 'All Styles',
                            link: `/customer-reviews/allstyles/${this.selectedColour}/${this.selectedFinish}/${this.selectedReviewType}`,
                            image: 'https://static.diy-kitchens.com/assets/images/kitchens/highgloss-icon.jpg'
                        }].concat(filterRangesOptions)
                    },
                    colour: {
                        selected: this.selectedColour,
                        options: [{
                            id: 'allcolours',
                            name: 'All Colours',
                            link: `/customer-reviews/${this.selectedRangeType}/allcolours/${this.selectedFinish}/${this.selectedReviewType}`,
                            image: 'all'
                        }].concat(filterColoursOptions)
                    },
                    finish: {
                        selected: this.selectedFinish,
                        options: filterFinishesOptions
                    },
                    reviewType: {
                        selected: this.selectedReviewType,
                        options: [
                            {
                                id: 'gallery',
                                name: 'Gallery',
                                link: `/customer-reviews/${this.selectedRangeType}/${this.selectedColour}/${this.selectedFinish}/gallery`,
                                image: 'photo_library'
                            },
                            {
                                id: 'video',
                                name: 'Video',
                                link: `/customer-reviews/${this.selectedRangeType}/${this.selectedColour}/${this.selectedFinish}/video`,
                                image: 'video_library'
                            },
                        ]
                    }
                };

                this.customerReviews = reviews;

                return true;
            })
            .catch((error) => {
                return false;
            });
    }

    private filterReviews(): Promise<boolean> {
        this.filteredResults = this.customerReviews;
        if (Array.isArray(this.customerReviews)) {
            if (this.selectedRangeType && this.selectedRangeType === 'allstyles') this.filteredResults = this.filteredResults;
            else if (this.selectedRangeType) {
                this.filteredResults = this.filteredResults.filter((review: ICustomerReview) => review.categories.some((category) => category === this.selectedRangeType));
            }
            if (this.selectedColour && this.selectedColour === 'allcolours') this.filteredResults = this.filteredResults;
            else if (this.selectedColour) {
                this.filteredResults = this.filteredResults.filter((review: ICustomerReview) => review.colours.some((colour) => colour === this.selectedColour));
            }
            if (this.selectedFinish && this.selectedFinish === 'allfinishes') this.filteredResults = this.filteredResults;
            else if (this.selectedFinish) {
                this.filteredResults = this.filteredResults.filter((review: ICustomerReview) => review.finish.some((finish) => finish === this.selectedFinish));
            }
            if (this.selectedReviewType && this.selectedReviewType === 'allreviewtypes') this.filteredResults = this.filteredResults;
            else if (this.selectedReviewType) {
                this.filteredResults = this.filteredResults.filter((review: ICustomerReview) => (this.selectedReviewType === 'video') ? review.videoURL : !review.videoURL);
            }
        }
        this.filters.results = this.filteredResults.length;
        return Promise.resolve(true);
    }

    public updatePage(event?: { pageIndex: number; pageSize: number; }): void {
        event = event || {
            pageIndex: 0,
            pageSize: 5
        };
        let idx = event.pageIndex * event.pageSize;
        this.paginatedResults = this.filteredResults.slice(idx, idx + event.pageSize);

        if (this.config.isBrowser) {
            window.scrollTo(0, 0);
        }
    }
}
