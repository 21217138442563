<button mat-flat-button class="chunky basket-update-button" color="primary"
    [ngClass]="{ 'basket-updating': updatingBasket, 'basket-success': (updatedBasket === true), 'basket-failure': (updatedBasket === false) }"
    [disabled]="updatingBasket || disabled" (click)="updateBasketItem(item, updates)">
    <span class="label" *ngIf="!updatingBasket && updatedBasket === null">Update</span>
    <span class="label" *ngIf="updatingBasket && updatedBasket === null">Updating...</span>
    <span class="label" *ngIf="!updatingBasket && updatedBasket === true">Updated!</span>
    <span class="label" *ngIf="!updatingBasket && updatedBasket === false">Error</span>
    <mat-icon class="basket-update-success">done</mat-icon>
    <mat-icon class="basket-update-failure">close</mat-icon>
    <mat-spinner class="basket-spinner" diameter="25" color="accent"></mat-spinner>
</button>
