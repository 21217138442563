@import '/src/theme/variables';

component-delivery-options {
    p {
        &.error-message {
            font-weight: 600;
        }
    }

    .delivery-panel {
        display: flex;
        grid-gap: 30px;

        .panel-left,
        .panel-right {
            flex-basis: 50%;
            padding: 0;
        }

        ul {
            li {
                font-size: 14px;
                font-weight: 400;
            }
        }
    }

    .selected-delivery-option {
        margin: 15px 0;
        border: 4px solid #cccccc;
        border-top: none;

        .selected-delivery-header {
            padding: 20px 25px;
            background: #cccccc;

            .selected-delivery-title {
                font-weight: 700;
                font-size: 13px;
                line-height: 1.618rem;
                color: rgba(0, 18, 36, 0.9);
            }
        }

        .selected-delivery-option-content {
            padding: 20px;

            .checkout-delivery-panel {
                margin-bottom: 8px;
                box-shadow: none;

                &:last-child {
                    margin-bottom: 0;
                }

                .panel {
                    gap: 30px;
                }

                .delivery-later {
                    margin-bottom: 15px;
                }

                .mat-expansion-panel-header {
                    flex-direction: row-reverse;
                    padding: 3px 16px;
                    border-radius: 0;
                    border-bottom: 1px solid transparent;
                    background: #80deea;

                    &:hover,
                    &:focus {
                        background: #b2ebf2;
                    }

                    .mat-content {
                        padding-left: 30px;
                    }

                    .mat-expansion-panel-header-title {
                        .delivery-name {
                            font-size: 16px;
                            font-weight: normal;
                            color: #000000;
                            line-height: 21px;
                            font-weight: bold;
                            text-transform: uppercase;
                            letter-spacing: 0.13em;
                        }
                    }

                    .mat-expansion-panel-header-description {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .delivery-title {
                            font-size: 16px;
                            font-weight: normal;
                            color: #000000;
                            line-height: 21px;
                            font-weight: 300;
                        }

                        .delivery-cost {
                            font-size: 16px;
                            font-weight: normal;
                            color: #000000;
                            text-align: right;
                            line-height: 21px;
                            font-weight: 400;
                        }                        
                    }

                    .mat-expansion-indicator {
                        transform: none !important;

                        &::after {
                            content: '\002B';
                            font-size: 24px;
                            color: #000000;
                            font-weight: bold;
                            transform: none !important;
                            border: none;
                        }
                    }

                    &.mat-expanded {
                        height: 48px;
                        background: #b2ebf2;
                        border-bottom-color: rgba(0, 0, 0, 0.1);

                        .mat-expansion-indicator {
                            &::after {
                                content: '\2212';
                            }
                        }
                    }
                }

                .mat-expansion-panel-content {
                    background: #b2ebf2;

                    .mat-expansion-panel-body {
                        padding: 15px;

                        .delivery-cost {
                            display: block;
                            margin-top: 48px;
                            margin-bottom: 48px;
                            font-size: 38px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.9);
                            text-align: left;
                            line-height: 38px;

                            .delivery-cost-vat {
                                font-size: 1rem;
                            }
                        }

                        .delivery-cost-alt {
                            display: block;
                            margin-top: 48px;
                            margin-bottom: 48px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.9);
                            text-align: left;
                            line-height: 38px;
                        }
                    }
                }

                &:nth-child(even) {
                    .mat-expansion-panel-header {
                        background: #ce93d8;

                        &:hover,
                        &:focus {
                            background: #e1bee7 !important;
                        }

                        &.mat-expanded {
                            background: #e1bee7;
                        }
                    }

                    .mat-expansion-panel-content {
                        background: #e1bee7;
                    }
                }
            }
        }
    }

    .delivery-note {
        width: 100%;
        margin-bottom: 15px;
    }

    @media only screen and (max-width: 768px) {
        .selected-delivery-option {
            .selected-delivery-option-content {
                .checkout-delivery-panel {
                    .mat-expansion-panel-header {
                        .mat-expansion-panel-header-description {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}