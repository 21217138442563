import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';

import { NotesDialogComponent } from './notes.dialog.component';

@NgModule({
    imports: [
        DesignModule
    ],
    declarations: [
        NotesDialogComponent
    ]
})
export class NotesDialogModule { }
