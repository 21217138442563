@import '/src/theme/variables';

component-sticky-tabs {
    .tabs-container {
        margin-top: 56px;

        &.tabs-sticky {
            height: 62px;

            .tabs {
                position: fixed;
                top: 0;
                left: 0;
                margin: 0;
                padding: 0;
                width: 100%;
                max-width: 100%;
                box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
                z-index: 1000;

                .tab {
                    &:first-child {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
        
                    &:last-child {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
            }
        }
    }

    .tabs {
        display: flex;
        align-items: stretch;
        justify-content: space-evenly;

        .tab {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 22px 16px;
            border: 1px solid color('light-grey');
            background-color: color('white');
            width: 100%;
            font-size: 0.75rem;
            font-weight: 600;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;

            &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            &+.tab {
                border-left: 0;
            }

            &.active {
                background-color: color('brand-primary');
                color: color('white');
            }
        }
    }

    @media (max-width: 992px) {
        display: none;
    }
}