<header class="content-contained">
    <h1>Easy online ordering</h1>
    <p>Take some time to familiarise yourself with our website, there's lots of useful hints, tips and information that will make ordering your kitchen online as simple as possible.</p>
</header>

<hr class="narrow" />

<component-sticky-tabs [tabs]="tabs" type="scroller"></component-sticky-tabs>

<section class="content-contained" [id]="StickyTabs.CHOOSE">
    <h3 class="playfair">Choose Your Door Style</h3>
    <h4>Doors/Units section</h4>
    <p>Browse our complete range of kitchens with full photography of every kitchen style. Simple filters allow
        you to easily narrow down your selection. Specific range features, worktop ideas and hints and tips are
        included for every kitchen available. We've also included customer reviews of every kitchen style so you
        can see how our kitchens look once installed!</p>
    <div class="button-wrapper">
        <a routerLink="/kitchens" title="View Kitchens">
            <button mat-flat-button color="primary" title="See our kitchens">View Kitchens</button>
        </a>
        <a routerLink="/kitchen-units" title="View Kitchens">
            <button mat-flat-button color="primary" title="See our kitchen units" rel="nofollow">View Kitchen Units</button>
        </a>
    </div>
    <!-- <h4>Style Your Kitchen Tool</h4>
    <p>This unique feature allows you to compare different door styles, handles, worktop styles and how they
        suit each kitchen style. You can even change the floor and wall colour options and see just how the
        kitchen will look when it's finished! A useful feature of the Style Your Kitchen Tool is that it will
        dynamically update our price indicator, so you can see what effect the changes you make will have on the
        cost of buying each kitchen...</p>
    <div class="button-wrapper">
        <a routerLink="/style-kitchen-tool">
            <button mat-flat-button color="primary" title="Style your kitchen today!">Style your kitchen today</button>
        </a>
    </div> -->
    <h4>Unit &amp; Door Configurator</h4>
    <p>This useful tool allows you to view each kitchen door style together with every cabinet colour and also
        provides a simple price indication. Why not experiment with different door styles and cabinet colours to
        create your own unique combination. The configurator will automatically default to our recommended
        cabinet colour, which is always the best colour match available!</p>
    <div class="button-wrapper">
        <button mat-flat-button color="primary" (click)="selectRange()" title="Choose a style &amp; start buying!">Choose a style &amp; start buying</button>
    </div>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.ORDER">
    <h3 class="playfair">Order your kitchen online</h3>
    <h4>Creating A Shopping Cart</h4>
    <p>Browse our complete range of over 1,000 different kitchen units. Our aim is to offer you the ultimate
        choice of cabinet options, but make the process of selecting and ordering them as easy as possible.</p>
    <ul>
        <li>Before selecting a door style prices displayed are for cabinets only. On selecting your door style
            all prices update to include the relevant unit, door and fittings.</li>
        <li>Our Transparent Pricing Policy means costs are clearly displayed at all times. We even state the
            cost of the unit and door separately so you can decide how to use your budget. All cabinet colours
            are the same price, so it's the door style that affects how much your kitchen costs. When you add
            items to the cart both elements combine to give you an overall cost.</li>
        <li>As you select units you will be given the option to select which hinge side you require where
            applicable. Each unit also has an Extra Information box where you can specify units to be dry
            assembled for access reasons, or make other requests. You can also change the depths of units if
            necessary. Innovative upgrade options for drawers and mechanisms are clearly displayed throughout
            the ordering process. This information can all be reviewed within the shopping cart list at anytime.
        </li>
    </ul>
    <h4>Unique Shopper ID / Shopping Cart System</h4>
    <p>Our Unique Shopper ID allows you to build up your kitchen order gradually and view it at any computer
        worldwide. As soon as you add an item to the shopping cart a shopper ID is created. This unique code can
        be used to open your shopping cart from any device that can access the internet. Simply make a note of
        you ID number and log in using the website support tools section. It also allows our sales advisers to
        access your cart should you call us to discuss your order. As you add items to your shopping cart it
        will remain in your browser memory (provided you don't delete your cookies of course) and be
        automatically saved. This is really useful, and means that you can put your kitchen together at your own
        pace. You don't have to complete your order all in one go, fit it around your lifestyle and break it
        down into small periods. The diy-kitchens website is live and open for business 365 days a year 24 hours
        a day, you can order your kitchen from the comfort of your own home.</p>
    <h4>Change Your Kitchen Style</h4>
    <p>At any point in the process of creating your shopping cart you can change the style of kitchen you are
        interested in. Prices will dynamically update to show you the cost of the items in your cart in
        different kitchen styles. This means can add the units you require to the shopping cart and make
        decisions based on your kitchen style and budget later! If you select an item in one range then try that
        isn't available in another the shopping cart will let you know.</p>
    <h4>Alternative Unit Options</h4>
    <p>Whenever you are viewing or selecting units alternative options will be displayed. The alternatives have
        the same dimensions, but offer different features eg drawers, wirework accessories etc. This allows you
        to tailor your order to suit your budget and requirements Why not create your shopping cart with basic
        unit types, once you have all the items you require in the cart you can look at upgrade options that
        suit your budget.</p>
    <h4>Hints, Tips &amp; Videos</h4>
    <p>Our aim is to make the selection of products and ordering process as simple as possible. Unlike other
        kitchen retailers, we try to demystify the kitchen purchasing journey. Our website is designed to guide
        you through the process. Useful hints, Tips &amp; Videos are available to explain any of the items you
        may have questions about. The website will also recommend relevant appliances for each appliance housing
        unit you select. If you can't find the information you require, please feel free to call our sales
        support line!</p>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.FINALISE">
    <h3 class="playfair">Finalise your order</h3>
    <p>When you are happy with the items in your shopping cart simply hit the checkout button. For complete
        kitchen orders three delivery and service options are available see our <a routerLink="/delivery-information">
            <strong>delivery section</strong>
        </a> for more details.</p>
    <p>Once your order has been placed one of our experienced sales operators will double check your order before processing.</p>
    <p>Sit back and wait for your new kitchen to arrive, we are sure you will be delighted with the quality and value of your new kitchen!</p>
</section>

<component-latest-offers></component-latest-offers>
