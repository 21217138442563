import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';

import { SharedModule } from '@app/shared';

import { DialogService } from './dialog.service';
import { DialogComponent } from './components/dialog';
import { DialogIFrameComponent } from './components/dialog-iframe';
import { DialogInputComponent } from './components/dialog-input';

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        DatePipe,
        DialogService
    ],
    declarations: [
        DialogComponent,
        DialogIFrameComponent,
        DialogInputComponent
    ]
})
export class DialogServiceModule { }
