import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { CatalogueService } from '@app/services/catalogue';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';

@Component({
    selector: 'page-appliances',
    templateUrl: './appliances.page.html',
    styleUrls: ['./appliances.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AppliancesPage implements OnInit {
    public loaded = false;
    public categories = [];
    public items: any;

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) {
        this.navigationService.setNavigation({
            title: 'Appliances',
            metaTags: [
                { name: 'no-meta', content: 'no metadata' },
                { name: 'description', content: 'See our range of appliances available at trade prices.'},
                { property: 'og:description', content: 'See our range of appliances available at trade prices.' },
            ],
            routeHistory: [{ title: 'Appliances', route: '/appliances' }]
        });
    }

    ngOnInit() {
        this.getCatalogueData();
    }

    private getCatalogueData() {
        this.catalogueService.getAppliances()
            .then((products: any) => {
                if (products) {
                    let categories = [];
                    products.forEach((product) => {
                        if (categories.indexOf(product.category) === -1) {
                            categories.push(product.category);
                        }
                    });

                    this.categories = categories.filter(cat => cat).map(category => {
                        this.items = products.filter(product => product.category === category);
                        const itemCount = this.items.length;

                        return {
                            name: category,
                            route: category.toLowerCase().replace(/ /g, '-'),
                            article: null,
                            tag: category.toLowerCase().replace(/ /g, ' '),
                            itemCount: itemCount,
                            countName: 'appliances'
                        };
                    });

                    this.loaded = true;
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }
}
