import { NgModule } from '@angular/core';

import { DesignModule } from '@app/shared/design.module';

import { PipesModule } from '@app/shared/pipes';

import { BasketComponentModule } from '@app/shared/components/basket/basket.component.module';

import { DimensionsInputCalculatorDialogComponent } from './dimensions-input-calculator.dialog.component';

@NgModule({
    imports: [
        DesignModule,
        PipesModule,

        BasketComponentModule
    ],
    declarations: [
        DimensionsInputCalculatorDialogComponent
    ]
})
export class DimensionsInputCalculatorDialogModule { }
