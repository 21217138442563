@import '/src/theme/variables';

page-about-unit-specifications {
    .selector {
        position: relative;

        .selector-title {
            padding: 16px;
            background-color: color('brand-primary');
            color: color('white');
            font-size: 0.75rem;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;

            &::after {
                display: inline-block;
                margin-left: 0.255em;
                border-top: 0.3em solid;
                border-right: 0.3em solid transparent;
                border-bottom: 0;
                border-left: 0.3em solid transparent;
                vertical-align: 0.255em;
                content: '';
            }
        }

        .selector-options {
            position: absolute;
            left: -1px;
            padding: 10px 0;
            border: 1px solid color('copy-border');
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background: color('white');
            width: 100%;
            text-align: left;
            z-index: 100;

            .selector-option {
                padding: 5px 20px;
                font-weight: 400;
                text-transform: uppercase;
                cursor: pointer;

                &.selected {
                    background-color: color('link-hover');
                    color: color('white');
                }

                &:hover {
                    background-color: color('copy-border');
                    color: color('link');
                }
            }
        }
    }

    .unit-display {
        padding-top: 24px;

        h3 {
            text-align: left;
        }

        .unit-overview {
            position: relative;
            margin-top: 56px;
            max-width: 480px;

            .unit-information {
                position: absolute;
                padding: 8px 8px 6px;
                border-radius: 20px;
                background-color: color('brand-primary');

                .mat-icon {
                    color: color('white');
                }

                .popup {
                    bottom: 50px;
                    left: calc(50% - 120px);
                }

                &:hover {
                    .popup {
                        display: block;
                    }
                }
            }
        }
    }

    .image-gallery {
        display: flex;
        flex-wrap: wrap;

        .image-block {
            padding: 15px;
            width: calc(33.33% - 30px);
        }
    }

    .colour-gallery {
        display: flex;
        flex-wrap: wrap;

        .image-block {
            position: relative;
            padding: 15px;
            width: calc(16.66% - 30px);
            cursor: pointer;

            &:hover {
                .popup {
                    display: block;
                }
            }
        }
    }

    .popup {
        display: none;
        position: absolute;
        left: calc(50% - 115px);
        padding: 10px 15px 0 15px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.9);
        width: 200px;
        color: white;
        text-align: center;
        z-index: 100;

        .arrow-up {
            position: absolute;
            top: -10px;
            left: calc(50% - 5px);
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid rgba(0, 0, 0, 0.9);
            width: 0;
            height: 0;
        }

        .arrow-down {
            position: absolute;
            bottom: -10px;
            left: calc(50% - 5px);
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid rgba(0, 0, 0, 0.9);
            width: 0;
            height: 0;
        }

        p {
            font-size: 0.75rem;
        }
    }
}