import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AccessoriesPage } from './accessories.page';
import { AccessoryListingPage } from './listing/accessory-listing.page';
import { AccessoryDetailPage } from './detail/accessory-detail.page';

import { PageNotFoundComponent } from '@app/shared/page-not-found.component';
import { ProductCategoryGuard } from '@app/utilities/guards/product-category.guard';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: AccessoriesPage,
                pathMatch: 'full'
            },
            {
                path: ':category',
                component: AccessoryListingPage,
                canActivate: [ProductCategoryGuard],
                data: { reuse: true, vatToggle: true }
            },
            {
                path: ':category/:subcategory',
                component: AccessoryListingPage,
                canActivate: [ProductCategoryGuard],
                data: { reuse: true, vatToggle: true }
            },
            {
                path: ':category/:subcategory/:details',
                component: AccessoryDetailPage,
                data: { vatToggle: true }
            },
            { path: '**', component: PageNotFoundComponent }
        ])
    ],
    exports: [RouterModule]
})
export class AccessoriesRouting { }
