import { NgModule } from '@angular/core';

import { MarketingService } from './marketing.service';

@NgModule({
    providers: [
        MarketingService
    ]
})
export class MarketingServiceModule { }
