<header class="content-contained">
    <h1>Our Partners</h1>
    <p>Building mutually beneficial partnerships with our suppliers matters to us. By creating positive relationships
        and using our purchasing power, we are able to secure the best value goods and services for our customers,
        helping us to constantly improve our business and that of our supply chain.</p>
</header>

<hr class="narrow" />

<component-sticky-tabs [tabs]="tabs" type="scroller"></component-sticky-tabs>

<section class="content-contained" [id]="StickyTabs.INNOVA">
    <div class="panel">
        <div class="panel-left">
            <iframe width="100%" height="318" src="https://www.youtube.com/embed/SChvCpc7Inw?rel=0" frameborder="0" allowfullscreen=""></iframe>
        </div>
        <div class="panel-right">
            <img src="https://static.diy-kitchens.com/assets/images/brands/innova_logo.gif" class="partner-logo" alt="Innova" title="Innova" />
            <h3 class="playfair">Kitchen doors, sinks, taps &amp; worktops</h3>
            <p>Introducing Innova, our own exclusive online brand with quality sourced materials and components
                to give you that designer look but without the hefty price tag.</p>
            <p>Our own brand Innova products include kitchen units, doors, sinks, taps, handles, granite &amp;
                quartz worktops. Our products give you an unrivalled versatility to create an inspirational
                interior that is perfected suited to your home and lifestyle.</p>
            <p>Take a look at what we have to offer, and we guarantee that you'll be amazed by what you see.</p>

            <div class="button-wrapper">
                <a mat-flat-button color="primary" routerLink="/kitchens" title="Kitchens">Kitchens</a>
                <a mat-flat-button color="primary" routerLink="/sinks-and-taps" title="Sinks & Taps">Sinks & Taps</a>
                <a mat-flat-button color="primary" routerLink="/handles" title="Handles">Handles</a>
                <a mat-flat-button color="primary" routerLink="/worktops" title="Worktops">Worktops</a>
            </div>
        </div>
    </div>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.EGGER">
    <div class="panel">
        <div class="panel-left">
            <iframe width="100%" height="318" src="https://www.youtube.com/embed/jprKSDeX1Pk?rel=0" frameborder="0" allowfullscreen=""></iframe>
        </div>
        <div class="panel-right">
            <img src="https://static.diy-kitchens.com/assets/images/about/partners/egger.jpg" class="partner-logo" alt="Egger" title="Egger" />
            <h3>18mm thick MFC unit material</h3>
            <p>Egger place a huge emphasis on working with leading door suppliers, such as Multiwood, to supply
                very accurate cabinet colours, to match a full range of different door styles.</p>
            <p>Feedback from our customers regarding our colour matching is excellent and this greatly adds to
                the aesthetics of the kitchen. Furthermore, in certain door ranges, savings can be made as clad-on
                side panels are not essential. More Information is available regarding this, on each individual door
                style section, on the website.</p>

            <div class="button-wrapper">
                <a mat-flat-button color="primary" href="https://www.egger.com/shop/en_GB/about-us" target="_blank" title="Company History">Company history</a>
                <a mat-flat-button color="primary" href="https://www.egger.com/shop/en_GB/forestry" target="_blank" title="Sustainability">Sustainability</a>
            </div>
        </div>
    </div>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.BLUM">
    <div class="panel">
        <div class="panel-left">
            <iframe width="100%" height="318" src="https://www.youtube.com/embed/smZlwZUYkHw?rel=0" frameborder="0" allowfullscreen=""></iframe>
        </div>
        <div class="panel-right">
            <img src="https://static.diy-kitchens.com/assets/images/about/partners/blum.jpg" class="partner-logo" alt="Blum" title="Blum" />
            <h3>Soft close hinges &amp; drawer boxes</h3>
            <p>We moved to using Blum as our hinge and drawer box supplier in 2007. Since the move, we have not
                had one single problem reported with a hinge or drawer system, due to the quality of their product.
            </p>
            <p>The testing and quality of the Blum products are phenomenal. We use the highest specification
                Blum clip top metal 110 deg door hinges with 3-way adjustment, and soft close, combined with the Blum
                Tandembox 'A' design. This design also features a twin walled metal solid drawer system with
                16mm solid base and metal back, incorporating full extension runners with integrated soft close dampers.
            </p>
            <p>Whilst many kitchen suppliers use Blum due to the strength of the Blum brand name, very few use
                the higher specification hinges and drawer boxes used with all our units. All pan drawers are
                supplied 500mm deep for optimum storage.</p>

            <div class="button-wrapper">
                <a mat-flat-button color="primary" href="https://www.blum.com/gb/en/04/10/40/" target="_blank" title="Company History">Company history</a>
                <a mat-flat-button color="primary" href="https://www.blum.com/gb/en/company/sustainability/" target="_blank" title="Sustainability">Sustainability</a>
            </div>
        </div>
    </div>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.KESSEBOHMER">
    <div class="panel">
        <div class="panel-left">
            <iframe width="100%" height="318" src="https://www.youtube.com/embed/ZRUQ-jEgSr4?rel=0" frameborder="0" allowfullscreen=""></iframe>
        </div>
        <div class="panel-right">
            <img src="https://static.diy-kitchens.com/assets/images/about/partners/kessebohmer.jpg" class="partner-logo" alt="Kessebohmer" title="Kessebohmer" />
            <h3>Storage solutions</h3>
            <p>Your new kitchen should look just as good on the inside as it does on the outside. Not only that,
                it should also provide some useful storage space for everyday essentials, such as utensils,
                cookware, cutlery, food and household items.</p>
            <p>Everything in its place and within easy reach, just as it should be.</p>
            <p>At DIY Kitchens, we use 'Clever Storage' by Kessebohmer. They are experts at maximising potential
                storage space in your kitchen. Their range of products ensures that the internal cabinet space
                of your kitchen furniture is utilised more effectively than in some storage systems.</p>
            <p>Kessebohmer products are manufactured to exacting standards and have won many European awards for
                design and innovation. In specifying Kessebohmer products, you can be confident your kitchen
                storage solutions, will provide a lifetime of trouble-free service.</p>
            <p>All Kessebohmer baskets are made of high-grade steel. And, with their meticulous processing and
                durable and attractive surface finishes, create a uniquely high-quality look &amp; feel.</p>

            <div class="button-wrapper">
                <a mat-flat-button color="primary" href="https://www.kesseboehmer.world/en/history/" target="_blank" title="Company History">Company history</a>
                <a mat-flat-button color="primary" href="https://www.kesseboehmer.world/en/environmental-protection/" target="_blank" title="Sustainability">Sustainability</a>
            </div>
        </div>
    </div>
</section>

<hr class="narrow" />

<section class="content-contained" [id]="StickyTabs.DUROPAL">
    <div class="panel">
        <div class="panel-left">
            <iframe width="100%" height="318" src="https://www.youtube.com/embed/puviIAaM7xc?rel=0" frameborder="0" allowfullscreen=""></iframe>
        </div>
        <div class="panel-right">
            <img src="https://static.diy-kitchens.com/assets/images/about/partners/duropal.jpg" class="partner-logo" alt="Duropal" title="Duropal" />
            <h3>Laminate worktops</h3>
            <p>Duropal GmbH is part of the Pfleiderer AG Group, one of the worldwide leading manufacturers of
                engineered wood, surfaced finished products and laminate flooring.</p>
            <p>Since 1958, Duropal has been a world leader in manufacturing decorative high-pressure laminate
                (HPL). For over 40 years, the bestselling worktops have been manufactured from a dense wood particle
                board core, surfaced with a decorative high-pressure laminate (HPL).</p>
            <p>The majority of kitchens feature this type of worktop, as it is easy to fit, durable and hygienic
                with many decor options available, and at reasonable prices.</p>
            <p>Melamine resin is the hardest of all synthetically produced organic materials. This is the reason
                for the high scratch and wear resistance of Duropal worktops. The high impact resistance of the
                material, is due to the elasticity of phenolic resin.</p>
            <p>These properties also explain the high resistance of Duropal worktops to chemicals and heat
                sources. Burning a cigarette onto the surface of a Duropal worktop, is one of the quality tests specified
                in BS/EN 438, which also determines all the other conditions that are important for this type of product.</p>

            <div class="button-wrapper">
                <a mat-flat-button color="primary" href="https://www.duropal.co.uk/about/" target="_blank" title="Company History">Company history</a>
                <a mat-flat-button color="primary" href="https://www.pfleiderer.com/ukb2b-en/products/decorative-panels/detail/duropal-hpl-imo" target="_blank" title="Sustainability">Sustainability</a>
            </div>
        </div>
    </div>
</section>

<component-latest-offers></component-latest-offers>
