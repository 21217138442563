import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from '@app/shared/page-not-found.component';
import { LaminateWorktopsPage } from './laminate-worktops.page';
import { AllTypesPage } from './content/all-types/all-types.page';
import { EdgeProfilesPage } from './content/edge-profiles/edge-profiles.page';

const routes: Routes = [];

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: LaminateWorktopsPage,
                pathMatch: 'full',
                data: { vatToggle: true }
            },
            {
                path: ':worktopLink/edge-profiles',
                component: EdgeProfilesPage,
                pathMatch: 'full',
                data: { vatToggle: true }
            },
            {
                path: 'options/:edgeProfile/:worktopLink/all-types',
                component: AllTypesPage,
                pathMatch: 'full',
                data: { vatToggle: true }
            },
            {
                path: 'options/:edgeProfile/:worktopLink/:section',
                component: AllTypesPage,
                pathMatch: 'full',
                data: { vatToggle: true }
            },
            { path: '**', component: PageNotFoundComponent }
        ])
    ],
    exports: [RouterModule]
})
export class LaminateWorktopsRouting { }
