import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';

import { AboutKitchenSalesRouting } from './kitchen-sales.routing';
import { AboutKitchenSalesPage } from './kitchen-sales.page';

@NgModule({
    imports: [
        SharedModule,
        AboutKitchenSalesRouting
    ],
    declarations: [
        AboutKitchenSalesPage
    ]
})
export class AboutKitchenSalesPageModule { }
