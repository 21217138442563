@import '/src/theme/variables';

page-worktop-config {
    section {
        padding: 20px 0;
    }

    .not-found {
        text-align: center;
        color: color('bright-red')
    }
}