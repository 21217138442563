import { NgModule } from '@angular/core';

import { BasketService } from './basket.service';
import { BasketServiceDialogModule } from './dialog/basket.dialog.module';

@NgModule({
    imports: [
        BasketServiceDialogModule
    ],
    providers: [
        BasketService
    ]
})
export class BasketServiceModule { }
