<section id="filters-component">
    <section [ngClass]="{ fixed: fixFilters }">
        <div class="count-sort">
            <div class="count-sort-content">
                <div>
                    {{filteredCount}} RESULTS
                </div>
                <div class="active-range" *ngIf="showRange">
                    <a (click)="selectRange()" *ngIf="!activeRange">
                        Door Style <mat-icon aria-label="Change Door Style">edit</mat-icon>
                    </a>
                    <div class="door-style" *ngIf="activeRange">
                        <img [src]="getRangeDoorImage()" height="100%" [alt]="activeRange.range.name + ' ' + activeRange.rangeColour"/>
                    </div>
                    <div class="door-style-display" *ngIf="activeRange">
                        <mat-label class="door-style-label">Door Style</mat-label>
                        <a (click)="selectRange()" class="current-range">
                            {{ activeRange.range.name }}&nbsp;{{ activeRange.rangeColour }}
                            &nbsp;<i class="material-icons">keyboard_arrow_down</i>
                        </a>
                    </div>
                </div>
                <div *ngIf="showSort">
                    <mat-form-field class="simple-select">
                        <mat-label>Sort By</mat-label>
                        <mat-select [(ngModel)]="sortOrder" (selectionChange)="updateOrder()">
                            <mat-option [value]="''">Popularity</mat-option>
                            <mat-option [value]="'low-high'">Price (Low to high)</mat-option>
                            <mat-option [value]="'high-low'">Price (High to low)</mat-option>
                            <mat-option [value]="'a-z'">Title (a-z)</mat-option>
                            <mat-option [value]="'z-a'">Title (z-a)</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </section>

    <div class="width-dropdown" *ngIf="hasSizeFilters()>0">
        <div *ngFor="let filter of activeFilters; let idx=index">
            <div *ngIf="filter.title === 'Width' || filter.title === 'Height' || filter.title === 'Depth'">
                <mat-form-field class="simple-select">
                    <mat-label>{{filter.title}}</mat-label>
                    <mat-select multiple [(ngModel)]="selectedWidths" *ngIf="filter.title === 'Width'">
                        <mat-option *ngFor="let opt of filter.opts" (click)="clickOption(opt)" [value]="opt.name" selected>
                            {{opt.title || opt.name}}
                        </mat-option>
                    </mat-select>
                    <mat-select multiple [(ngModel)]="selectedHeights" *ngIf="filter.title === 'Height'">
                        <mat-option *ngFor="let opt of filter.opts" (click)="clickOption(opt)" [value]="opt.name" selected>
                            {{opt.title || opt.name}}
                        </mat-option>
                    </mat-select>
                    <mat-select multiple [(ngModel)]="selectedDepths" *ngIf="filter.title === 'Depth'">
                        <mat-option *ngFor="let opt of filter.opts" (click)="clickOption(opt)" [value]="opt.name" selected>
                            {{opt.title || opt.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div [class.show-on-mobile]="showFilters">
        <div class="filter-mobile-header">
            <div *ngIf="showSort">
                <mat-form-field class="simple-select">
                    <mat-select [(ngModel)]="sortOrder" (selectionChange)="updateOrder()">
                        <mat-option [value]="''">Popularity</mat-option>
                        <mat-option [value]="'low-high'">Price (Low to high)</mat-option>
                        <mat-option [value]="'high-low'">Price (High to low)</mat-option>
                        <mat-option [value]="'a-z'">Title (a-z)</mat-option>
                        <mat-option [value]="'z-a'">Title (z-a)</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="refine" (click)="toggleFilters()">
                <em *ngIf="filterCount>0">{{filterCount}} filter<span *ngIf="filterCount>1">s</span></em>
                <button><i class="material-icons">menu</i> Refine search</button>
            </div>
            <div class="results" (click)="toggleFilters()">
                <button>SHOW {{filteredCount}} RESULT<span *ngIf="filteredCount>1">S</span></button>
            </div>
        </div>
        <div [ngClass]="hasSizeFilters() > 0 ? 'filters-with-size' : 'filters'">
            <!-- <div class="resets" *ngIf="filterCount>0">
                <ng-container *ngFor="let filter of activeFilters">
                    <ng-container *ngIf="filter.title !== 'Category'">
                        <ng-container *ngFor="let opt of filter.opts">
                            <button *ngIf="opt.tick" class="pill-button" (click)="clickOption(opt)">
                                {{getFilterPills(filter.title,opt.name)}}
                                <mat-icon class="close-icon">close</mat-icon>
                            </button>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div> -->
            <mat-accordion multi>
                <div *ngFor="let filter of activeFilters; let idx=index">
                    <div [ngClass]="{'no-show-desktop': filter.title === 'Width' || filter.title === 'Height' || filter.title === 'Depth'}">
                        <mat-expansion-panel [expanded]="(filter.title === 'Category' && !onlyCategoryFilter) ? false : true">
                            <mat-expansion-panel-header>{{filter.title}}</mat-expansion-panel-header>
                            <div *ngFor="let opt of filter.opts" class="opt" (click)="clickOption(opt)">
                                <mat-icon class="selected" *ngIf="opt.tick">check_box</mat-icon>
                                <mat-icon *ngIf="!opt.tick">check_box_outline_blank</mat-icon>
                                <span class="title">{{opt.title || opt.name}}</span>
                                <!-- <span class="count">{{opt.count}}</span> -->
                            </div>
                        </mat-expansion-panel>
                    </div>
                </div>
            </mat-accordion>
        </div>
    </div>
</section>