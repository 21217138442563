@use '@angular/material' as mat;
@import '/src/theme/variables';

component-mobile-menu {

    @include mat.expansion-overrides((
        header-text-size: 18px,
        header-text-weight: 600,
        container-text-size: 16px
    ));
      
      .mobile-menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
        overflow-y: scroll;
      }
      
      .menu-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border-bottom: 1px solid #ccc;
        color: rgb(0, 0, 0);
      
        h3 {
          margin: 0;
        }
      }
      
      .menu-links a {
        display: block;
        padding: 8px 0 16px 0;
        text-decoration: none;
        color: inherit;
      
        &:hover {
          background-color: #f0f0f0;
        }
      }
      
      .section-title {
        text-transform: uppercase;
      }
      
}
