import { Component, ViewEncapsulation, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

import { Config } from '@app/config';
import { NumberHelper } from '@app/utilities/helpers';

import { AuthService } from '@app/services/auth';
import { BasketService } from '@app/services/basket';
import { CheckoutService } from '@app/services/checkout';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';
import { NavigationRoute, RouteHistory } from '@app/services/navigation/models';
import { OrderService } from '@app/services/order';
import { StorageService } from '@app/services/storage';

@Component({
    selector: 'component-page-checkout-order',
    templateUrl: './checkout-order.page.html',
    styleUrls: ['./checkout-order.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CheckoutOrderPage implements OnInit, OnDestroy {
    @ViewChild(MatStepper) stepper: MatStepper;

    public order: any = {};
    public steps: string[] = ['summary', 'payment'];
    public currentStep: number = 0;
    public paymentResult: string = null;
    public orderNumber: string = null;
    public loaded: boolean = false;
    public authed: boolean = false;
    public Math = Math;

    private routeHistory: RouteHistory[] = [{ title: 'Checkout', route: '/checkout' }];
    private routeSubscription: Subscription;
    private authSubscription: Subscription;

    public paymentDetailsFetched: boolean = false;
    public outstandingAmount = null;
    public weekCommencing: string;
    public delivery: string;
    public paymentMode: number;
    public userCancelled: boolean = false;
    public paymentHistory = [];

    public summaryFormGroup: FormGroup = this.formBuilder.group({
        complete: new FormControl(false, [Validators.requiredTrue])
    });

    private paymentCacheKey = 'payment-gateway-initiated';
    private basketSubscription: Subscription = null;

    constructor(
        private config: Config,
        private authService: AuthService,
        private basketService: BasketService,
        private checkoutService: CheckoutService,
        private dialogService: DialogService,
        private formBuilder: FormBuilder,
        private googleAnalyticsService: GoogleAnalyticsService,
        private navigationService: NavigationService,
        private orderService: OrderService,
        private storageService: StorageService
    ) {
    }

    private setTitle(orderNumber) {
        this.navigationService.setNavigation({
            title: `Kitchens - Order ${orderNumber || ''}`,
            metaTags: [{ name: 'no-meta', content: 'no metadata' }],
            routeHistory: this.routeHistory
        });
    }

    ngOnInit() {
        this.setTitle('');

        this.basketSubscription = this.basketService.basket$.subscribe((basket) => {
            this.basketService.clearAll(true)
                .catch((error) => this.dialogService.error(error));
        });

        this.storageService.remove(this.paymentCacheKey)
            .catch((error) => this.dialogService.error(error));

        this.routeSubscription = this.navigationService.route.subscribe(
            (route: NavigationRoute) => {
                if (route.params && route.params.orderNumber) {
                    this.orderNumber = route.params.orderNumber;
                    this.setTitle(this.orderNumber);
                }

                if (route.params && route.params.paymentResult) {
                    this.paymentResult = route.params.paymentResult;
                }
                this.loaded = false;

                this.loadPage();
            }
        );

        this.authSubscription = this.authService.authentication.subscribe(
            (isAuthed) => {
                if (isAuthed) {
                    this.authed = true;
                    this.loadPage();
                } else if (this.authService.loaded.value) {
                    this.authService.loginPrompt()
                        .catch((error) => this.dialogService.error(error));
                }
            }
        );
    }

    private loadPage() {
        if (!this.orderNumber || !this.authed) {
            return;
        }

        // Update order status for an order with transferred_payment status
        this.orderService.updateOrderStatus(this.orderNumber).catch((error) => this.dialogService.error(this.constructor.name, error));

        this.getPaymentDetails()
            .then(() => {
                if (this.paymentResult) {
                    switch (this.paymentResult) {
                        case 'fail':
                            this.paymentMode = 1;
                            break;
                        case 'success':
                            this.paymentMode = 2;
                            break;
                        case 'bacs':
                            this.paymentMode = 3;
                            break;
                        case 'delivery':
                            this.paymentMode = 4;
                            break;
                        case 'payment':
                            this.paymentMode = 5;
                            break;
                        default:
                            this.paymentMode = 0;
                            break;
                    }
                }
                if (this.outstandingAmount !== 0) {
                    this.orderService
                        .getOrderDetails(this.orderNumber)
                        .then((order) => {
                            if (order) {
                                this.order = order;
                                this.loaded = true;
                            } else {
                                this.dialogService.error(this.constructor.name, 'Failed to load Order');
                            }
                        })
                        .catch((error) => this.dialogService.error(this.constructor.name, error));
                }
                if (this.config.isBrowser && this.outstandingAmount === 0 && this.paymentMode > 1) {
                    this.orderService.getOrderDetails(this.orderNumber)
                        .then((order) => {
                            if (this.config.isBrowser && order) {
                                // Google Ads Event snippet
                                this.googleAnalyticsService.gtag('conversion', {
                                    send_to: '1070518970/eweRCLTv9QEQuqW7_gM',
                                    value: order.orderTotal,
                                    currency: 'GBP',
                                    transaction_id: this.orderNumber,
                                });
                                // Google Enhanced eCommerce in Google Analytics
                                this.googleAnalyticsService.gtag('purchase', {
                                    transaction_id: this.orderNumber,
                                    affiliation: 'DIY Kitchens',
                                    value: order.orderTotal,
                                    currency: 'GBP',
                                    tax: order.vat,
                                    shipping: this.delivery,
                                    items: [
                                        {
                                            id: this.orderNumber,
                                            name: 'Kitchen',
                                            category: order.firstName + '-' + order.lastName,
                                            price: order.orderTotal,
                                            quantity: 1,
                                        },
                                    ],
                                });
                            } else {
                                this.dialogService.error(this.constructor.name, 'Failed to load Order');
                            }
                        })
                        .catch((error) => this.dialogService.error(this.constructor.name, error));
                }
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    private getPaymentDetails(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.orderService.getPaymentDetails(null, this.orderNumber)
                .then((response) => {
                    this.paymentDetailsFetched = true;

                    this.outstandingAmount = response.orderDetails.orderTotal;
                    this.weekCommencing = response.orderDetails.wcDate ? `on week commencing ${response.orderDetails.wcDate}` : '.'

                    this.delivery = this.checkoutService.deliveryTypeName(response.orderDetails.deliveryType);

                    if (response.preAuths && response.preAuths.length) {
                        response.preAuths.forEach((preAuth) => {
                            if (!preAuth.processedAt) {
                                preAuth.isPreAuth = true;
                                preAuth.createdAt = preAuth.transactionTime;
                                preAuth.chargetotal = preAuth.transAmount;
                                preAuth.status = 'Pending';
                                preAuth.ccbrand = 'Pre authorization';
                                
                                response.paymentHistory.push(preAuth);
                            }
                        });
                    }

                    if (response.paymentHistory && response.paymentHistory.length) {
                        response.paymentHistory.forEach((payment) => {
                            if (payment.status === 'APPROVED' && payment.txntype !== 'return') {
                                this.outstandingAmount = NumberHelper.toPoundsAndPence(this.outstandingAmount - payment.chargetotal + (payment.refundedAmount || 0));
                            }

                            if (payment.isPreAuth) {
                                this.outstandingAmount = NumberHelper.toPoundsAndPence(this.outstandingAmount - payment.chargetotal);
                            }
                        });
                        this.paymentHistory = response.paymentHistory.filter((payment) => payment.status && payment.status.length);
                        this.paymentHistory.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0))
                    }

                    resolve();
                })
                .catch((error) => reject(error));
        });
    }

    public goToPaymentOptions() {
        this.navigationService.navigate([`/checkout/order/${this.order.orderNumber}`]);
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }

        if (this.authSubscription) {
            this.authSubscription.unsubscribe();
            this.authSubscription = null;
        }

        if (this.basketSubscription) {
            this.basketSubscription.unsubscribe();
            this.basketSubscription = null;
        }
    }
}
