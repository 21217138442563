import { AbstractControl, ValidationErrors } from '@angular/forms';

import { FormValidator } from './models';

export class EmailValidator implements FormValidator {
    // eslint-disable-next-line max-len
    private emailRegExp = new RegExp(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/);

    public validate(control: AbstractControl): ValidationErrors | null {
        if (control.value) {
            const cleanValue = control.value.toLowerCase();

            // TODO: get custom form group interface working
            control['cleanValue'] = cleanValue;

            if (cleanValue.match(this.emailRegExp)) {
                return null;
            } else {
                return { emailValid: false };
            }
        } else {
            return null;
        }
    }
}
