<div mat-dialog-title>
    <div class="title">
        <h1>{{ title }}</h1>
        <p class="sub-title" *ngIf="subTitle">{{ subTitle }}</p>
    </div>
    <div class="close">
        <mat-icon (click)="close()">close</mat-icon>
    </div>
</div>
<mat-dialog-content *ngIf="!html">{{ content }}</mat-dialog-content>
<mat-dialog-content *ngIf="html" [innerHTML]="content | sanitizeHtml"></mat-dialog-content>
<mat-dialog-actions *ngIf="buttons">
    <ng-container *ngFor="let button of buttons">
        <button mat-stroked-button [color]="button.colour || 'primary'" *ngIf="button.type === 'stroked'" class="skinny" mat-icon (click)="action(button.action)">
            {{ button.label || '' }}
            <mat-icon *ngIf="button.icon">{{ button.icon.off || button.icon }}</mat-icon>
        </button>
        <button mat-raised-button [color]="button.colour || 'primary'" *ngIf="button.type === 'raised'" class="skinny" mat-icon (click)="action(button.action)">
            {{ button.label || '' }}
            <mat-icon *ngIf="button.icon">{{ button.icon.off || button.icon }}</mat-icon>
        </button>
        <button mat-flat-button [color]="button.colour || 'primary'" *ngIf="!button.type || button.type === 'flat'" class="skinny" mat-icon (click)="action(button.action)">
            {{ button.label || '' }}
            <mat-icon *ngIf="button.icon">{{ button.icon.off || button.icon }}</mat-icon>
        </button>
    </ng-container>
</mat-dialog-actions>