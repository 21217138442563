import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { CatalogueService } from '@app/services/catalogue';
import { DialogService } from '@app/services/dialog';
import { NavigationService } from '@app/services/navigation';

@Component({
    selector: 'page-kitchens',
    templateUrl: './kitchens.page.html',
    styleUrls: ['./kitchens.page.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class KitchensPage implements OnInit {
    public styles: any[] = [];

    constructor(
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) {
        this.navigationService.setNavigation({
            title: 'Kitchens & Kitchen Units at Trade Prices',
            metaTags: [
                { name: 'Kitchens', content: 'Kitchens page' }, 
                { name: 'Description', content: 'See our range of Kitchens & kitchen units. All of our Kitchens & kitchen units are available at trade prices.' },
                { property: 'og:description', content: 'See our range of Kitchens & kitchen units. All of our Kitchens & kitchen units are available at trade prices.' }
            ],
            routeHistory: [{ title: 'Kitchens', route: '/kitchens' }]
        });
    }

    ngOnInit() {
        this.catalogueService.getKitchenStyles()
            .then((styles) => {
                this.styles = styles;
            })
            .catch((error) => this.dialogService.error(this.constructor.name, error));
    }

    public getRouteEnd(url: string): string {
        return url.match(/[^/]*$/)[0];
    }
}
