<header class="content-contained">
    <h1>How we compare</h1>
    <p>All our high specification kitchen units are supplied fully rigid with quality factory fitted components, at extremely affordable prices. As you will see below, we are able to offer the convenience of beautiful, fully built units, at a price below many flat pack retailers.</p>
</header>

<hr class="narrow" />

<section class="content-contained">
    <div class="comparison-header">
        <div class="comparison-tabs">
            <div class="comparison-tab" [ngClass]="{ selected: (selectedTab === ComparisonTabs.PRICE) }" (click)="selectTab(ComparisonTabs.PRICE)">Price</div>
            <div class="comparison-tab" [ngClass]="{ selected: (selectedTab === ComparisonTabs.BASE_UNITS) }" (click)="selectTab(ComparisonTabs.BASE_UNITS)">Base Units</div>
            <div class="comparison-tab" [ngClass]="{ selected: (selectedTab === ComparisonTabs.WALL_UNITS) }" (click)="selectTab(ComparisonTabs.WALL_UNITS)">Wall Units</div>
            <div class="comparison-tab" [ngClass]="{ selected: (selectedTab === ComparisonTabs.GENERAL) }" (click)="selectTab(ComparisonTabs.GENERAL)">General</div>
        </div>
        <div class="comparison-pills">
            <div class="comparison-pill" [ngClass]="{ selected: (selectedPill === ComparisonPills.FULLY_BUILT) }" (click)="selectPill(ComparisonPills.FULLY_BUILT)">
                <div class="comparison-button">Fully Built</div>
            </div>
            <div class="comparison-pill" [ngClass]="{ selected: (selectedPill === ComparisonPills.FLATPACK) }" (click)="selectPill(ComparisonPills.FLATPACK)">
                <div class="comparison-button">Flat Pack</div>
            </div>
        </div>
    </div>

    <div class="comparison-data" *ngIf="brandTitles && brandDetails" [ngClass]="selectedPill">
        <div class="brand-titles">
            <div *ngFor="let brandTitle of brandTitles">
                <div class="brand-title" [ngStyle]="{ 'background-color': brandTitle.colour }">
                    <h4>{{brandTitle.name}}</h4>
                    <p *ngIf="brandTitle.options && brandTitle.options[selectedPill]">{{brandTitle.options[selectedPill]}}</p>
                </div>
                <ng-container *ngFor="let details of brandDetails">
                    <ng-container *ngFor="let detail of details">
                        <ng-container *ngIf="detail.brand === brandTitle.name">
                            <div class="brand-detail">
                                <h3 *ngIf="detail.value !== true && detail.value !== false && detail.value.substring(0,4) !== 'Y - ' && detail.value.substring(0,4) !== 'N - '">{{detail.value}}</h3>

                                <img *ngIf="detail.value === true" src="https://static.diy-kitchens.com/assets/images/general/icon-yes.svg" height="23" alt="Green tick"/>
                                <img *ngIf="detail.value === false" src="https://static.diy-kitchens.com/assets/images/general/icon-no.svg" height="23" alt="Red cross"/>

                                <div class="icon-plus" *ngIf="detail.value !== true && detail.value !== false && (detail.value.substring(0,4) === 'Y - ' || detail.value.substring(0,4) === 'N - ')">
                                    <img *ngIf="detail.value.substring(0,4) === 'Y - '" src="https://static.diy-kitchens.com/assets/images/general/icon-yes.svg" height="23" alt="Green tick" />
                                    <img *ngIf="detail.value.substring(0,4) === 'N - '" src="https://static.diy-kitchens.com/assets/images/general/icon-no.svg" height="23" alt="Red cross" />
                                    <p>({{detail.value.substring(4)}})</p>
                                </div>

                                <p>{{detail.title}}</p>

                                <p *ngIf="detail.showPopup" class="popup-button" (click)="openPopup(detail)">More Info</p>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</section>