import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

@Injectable()
export class CustomHammerGestureConfig extends HammerGestureConfig {
    overrides = <any>{
        pinch: { enable: false },
        rotate: { enable: false }
    };
}
