@import '/src/theme/variables';

page-about-comparison {
    section {
        &.content-contained {
            padding-top: 0;
        }
    }

    .comparison-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid color('copy-border');
        overflow-x: auto;

        .comparison-tabs {
            display: flex;

            .comparison-tab {
                padding: 24px;
                font-weight: 600;
                font-size: 0.75rem;
                text-transform: uppercase;
                cursor: pointer;

                &.selected {
                    border-bottom: 4px solid color('brand-primary');
                }
            }
        }

        .comparison-pills {
            display: flex;
            align-items: center;
            padding: 0 20px;
            border-left: 1px solid color('copy-border');

            .comparison-pill {
                padding: 5px;

                &.selected {
                    .comparison-button {
                        background-color: color('selection');
                        color: color('white');
                    }
                }

                .comparison-button {
                    padding: 8px;
                    border: 1px solid color('gray-chateau');
                    border-radius: 20px;
                    font-weight: 600;
                    font-size: 0.75rem;
                    text-transform: uppercase;
                    cursor: pointer;
                }
            }
        }
    }

    .comparison-data {

        .brand-titles {
            display: grid;
            gap: 24px;
            margin-top: 36px;

            .brand-title {
                margin-bottom: 24px;
                color: color('white');

                h4 {
                    margin: 0;
                    padding: 8px 16px;
                    border-bottom: 1px solid color('white');
                }

                p {
                    margin: 0;
                    padding: 8px 16px;
                    font-size: 0.75rem;
                    font-weight: 700;
                }
            }
        }

        .brand-detail {
            padding: 24px 12px;

            &:nth-of-type(even) {
                background-color: color('copy-border');
            }

            .icon-plus {
                display: flex;

                p {
                    margin: 0;
                }
            }

            h3 {
                font-weight: 400;
                font-size: 1.125rem
            }

            img {
                margin-bottom: 10px;
            }

            p {
                font-size: 0.875rem;
                font-weight: 400;

                &.popup-button {
                    margin-bottom: 0;
                    color: color('link');
                    font-size: 0.75rem;
                    font-weight: 600;
                    text-transform: uppercase;
                    cursor: pointer;

                    &:hover {
                        color: color('link-hover');
                    }
                }
            }
        }
    }

    @media screen and (min-width: 480px) {
        .comparison-data .brand-titles {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    @media screen and (min-width: 992px) {
        .comparison-data .brand-titles {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}
