<section class="dark accessories-header-bg">
    <div class="contained">
        <div *ngIf="paymentMode === 4">
            <div class="important-notice-section successful">
                <mat-icon>info_outline</mat-icon>
                <div class="imp-notice-content">
                    <h3>Delivery Details Updated</h3>
                    <p>
                        Thank you for updating your delivery details.
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="outstandingAmount === 0 && paymentMode !== 4">
            <div class="important-notice-section successful">
                <mat-icon>info_outline</mat-icon>
                <div class="imp-notice-content">
                    <h3>Order is fully paid</h3>
                    <p>
                        Thank you for your order. Your order number {{orderNumber}}, due for {{delivery}} {{weekCommencing}}.
                    </p>
                    <p>
                        If you have any questions regarding your order, please email us at sales&#64;diy-kitchens.com or call our customer services team on 01977 608 418.
                    </p>
                </div>
            </div>
            <br/>
        </div>

        <div *ngIf="outstandingAmount && outstandingAmount !== 0">
            <div *ngIf="!paymentDetailsFetched && paymentResult">
                Confirming Payment Result
            </div>
            <div *ngIf="paymentDetailsFetched ">
                <div *ngIf="paymentMode === 3">  
                    <div class="important-notice-section successful">
                        <mat-icon>info_outline</mat-icon>
                        <div class="imp-notice-content">
                            <h3>Thank you for your order</h3>
                            <p>
                                Please see below our bank details.
                            </p>
                        </div>
                    </div>
                    <h4>Bank details</h4>
                    <table class="bank-details">
                        <tr>
                            <td>
                                <h4>Recipient:</h4>
                            </td>
                            <td>
                                <p>Ultima Furniture Systems Ltd</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4>Account number:</h4>
                            </td>
                            <td>
                                <p>42757037</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4>Sort code:</h4>
                            </td>
                            <td>
                                <p>54-30-64</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4>Reference</h4>
                            </td>
                            <td>
                                <p>{{order.orderNumber}}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4>Amount</h4>
                            </td>
                            <td>
                                <p>{{ outstandingAmount | currencyFormat }}</p>
                            </td>
                        </tr>
                    </table>
                    <div class="actions">
                        <button mat-flat-button color="primary" class="chunky payment-button" (click)="goToPaymentOptions()">Other payment options</button>
                    </div>
                </div>
                <div *ngIf="paymentMode === 2">                    
                    <div class="important-notice-section successful">
                        <mat-icon>info_outline</mat-icon>
                        <div class="imp-notice-content">
                            <h3>Payment successful</h3>
                            <p>
                                Thank you for your payment. You will shortly receive a payment acknowledgment to your email.
                            </p>
                        </div>
                    </div>
                    <br />
                </div>
                <div *ngIf="paymentMode === 1">
                    <div class="important-notice-section failed">
                        <mat-icon>info_outline</mat-icon>
                        <div class="imp-notice-content">
                            <h3>Payment unsuccessful</h3>
                            <p>
                                Unfortunately, we have been unable to take payment. Please try again. 
                            </p>
                            <p>
                                If the problem continues, please contact our accounts department at 01977 608418 to allow us to process your order.
                            </p>
                        </div>
                    </div>
                    <br />
                </div>
                <div *ngIf="paymentHistory && paymentHistory.length && paymentMode !== 3">
                    <h3>Payment History</h3>
                    <table class="payment-table">
                        <thead>
                            <tr class="header">
                                <th>Date</th>
                                <th>Type</th>
                                <th>Amount</th>
                                <th>Payment Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="info-row" *ngFor="let payment of paymentHistory">
                                <td>{{payment.createdAt}}</td>
                                <td>{{payment.ccbrand || payment.txntype}}</td>
                                <td>
                                    <ng-container *ngIf="!payment.isManualPayment">
                                        {{ (payment.txntype === 'return' ? (payment.chargetotal * -1) : payment.chargetotal) | currencyFormat}}
                                    </ng-container>
                                    <ng-container *ngIf="payment.isManualPayment">
                                        {{ (payment.chargetotal ? payment.chargetotal : (payment.refundedAmount * -1)) | currencyFormat }}
                                    </ng-container>
                                </td>
                                <td>{{payment.status}}</td>
                            </tr>
                            <tr class="info-row">
                                <td colspan="3">
                                    <div *ngIf="outstandingAmount > 0">
                                        Outstanding amount:
                                    </div>

                                    <div *ngIf="outstandingAmount < 0">
                                        Refund amount:
                                    </div>

                                    <div *ngIf="outstandingAmount === 0">
                                        Outstanding amount:
                                    </div>
                                </td>
                                <td>
                                    <div *ngIf="outstandingAmount > 0">
                                        {{ outstandingAmount | currencyFormat }}
                                    </div>

                                    <div *ngIf="outstandingAmount < 0">
                                        {{ Math.abs(outstandingAmount) | currencyFormat }}
                                    </div>

                                    <div *ngIf="outstandingAmount === 0">
                                        Your order is fully paid
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="loaded && paymentMode !== 3">
            <div class="important-notice-section successful" *ngIf="this.orderNumber && !this.paymentMode">
                <mat-icon>info_outline</mat-icon>
                <div class="imp-notice-content">
                    <h3>Order Placed</h3>
                    <p>
                        Thank you for placing your order. Your order number is {{ this.orderNumber }}
                    </p>
                </div>
            </div>
            <!-- <component-lloyd-payment [order]="order"></component-lloyd-payment> -->
            <!-- <component-monek-payment [stepper]="stepper" [order]="order"></component-monek-payment> -->
            <component-monek-legacy-payment [stepper]="stepper" [order]="order"></component-monek-legacy-payment>
            <component-order-summary [order]="order"></component-order-summary>
        </div>
    </div>
</section>
