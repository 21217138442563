<div class="loader" *ngIf="loading">
    <mat-spinner></mat-spinner>

    <div class="loader-message">Loading Information...</div>
</div>

<form [formGroup]="formGroup">
    <div class="terms">
        <div class="terms-column">
            <h4>Important Manufacturing Information</h4>
            <p>Manufacturing a fitted kitchen requires many complex processes, with many different stages involved in the
                making of it.</p>
            <p>
                Although the quality of our kitchen manufacturing is extremely high, during each one of the manufacturing
                processes, manual quality
                inspections are carried out.
            </p>
            <p>
                On some occasions though, inspections sometimes reveal small issues such as damage, either through machining
                or physical damage that
                has not been spotted until the final assembly stage or just before the kitchen is about to be loaded onto our
                vehicles to be delivered
                to you.
            </p>
            <p>
                In order not to delay your delivery and installation, your kitchen will still be sent and any door/drawers
                or trims will follow by
                carrier shortly afterwards.
            </p>
            <p>
                Any additional items that need to be sent to you will be drilled for hinges & fittings to facilitate ease of
                installation. We will
                also endeavour to give you accurate delivery information, as to the anticipated dispatch date, as soon as
                the factory provides the
                information on the damaged or rejected item from your order.
            </p>

            <hr class="narrow" />

            <h4>Painted Doors & Panels</h4>
            <p>
                Please note that any doors & panels that are painted by us, both standard and custom paint to order colours,
                can take up to 7 working
                days to go through the production process. Each door style that we offer indicates whether it is a stock
                colour or custom painted.
            </p>

            <hr class="narrow" />

            <h4>Unpainted Doors, Panels & Accessories</h4>
            <p>
                If any issues are found with your items prior to dispatch, we will deliver the remainder of your order and
                deliver your replacement
                items to you within 5 working days.
            </p>

        </div>
        <div class="terms-column">
            <h4>Extra / Additional Order Items</h4>
            <p>
                You may find that you need to add items to your kitchen order, for example if you have extended your kitchen
                or if you have missed something.
                Whilst we will endeavour to keep lead times to a minimum, please be aware that a standard lead time may
                apply.
                This can be found on the range page of the website.
            </p>

            <hr class="narrow" />

            <h4>Appliance Delivery</h4>
            <p>If you have ordered appliances with your kitchen, please note that these will be delivered separately, direct from the appliance supplier and will be delivered on any day during your w/c delivery week.</p>

            <hr class="narrow" />

            <h4>Terms & Conditions</h4>
            <p>Please read our terms and conditions carefully and make sure that you understand them before placing your order with us. By placing an order with us, you agree to be bound by these terms and conditions.</p>

            <div class="button-full">
                <a routerLink="/terms-and-conditions" title="Terms & Conditions">
                    <button mat-stroked-button>
                        View our full Terms & Conditions
                    </button>
                </a>
            </div>

            <div class="button-full" *ngIf="!loading && !formGroup.controls?.accepted.value">
                <button mat-flat-button color="primary" type="submit" (click)="accept()">
                    Accept Terms & Conditions
                </button>
            </div>

            <div class="thanks-banner" *ngIf="formGroup.controls.accepted.value">
                <h3>Thank You For Confirming</h3>
                <p>Thank you for confirming you have read and understood both our important manufacturing information and our full terms & conditions.</p>
            </div>
        </div>
    </div>

    <button mat-flat-button color="primary" *ngIf="formGroup.controls.accepted.value" (click)="stepper.next()">
        <mat-icon>arrow_forward</mat-icon>Continue to next step
    </button>
</form>
