import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { StringHelper } from '@app/utilities/helpers';

import { BasketService } from '@app/services/basket';
import { BasketItem } from '@app/services/basket/models';
import { CatalogueService } from '@app/services/catalogue';
import { DialogService } from '@app/services/dialog';

@Component({
    selector: 'component-change-style-dialog',
    templateUrl: './change-style.dialog.component.html',
    styleUrls: ['./change-style.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ChangeStyleDialogComponent {
    private itemIds: number[];
    public basketItems: BasketItem[] = [];
    public selectedBasketItems: BasketItem[] = [];
    public basketItemsUnavailable: BasketItem[] = [];
    public basketItemChanges: BasketItem[] = [];
    public compareBasketItems: boolean = false;

    public checkingAvailability: boolean = false;

    public currentRange: string;
    public currentRangeColour: string;
    public currentRangeCarcaseColour: string;
    public currentColour: string;
    public currentBespokeColour: string;

    public rangeOptions: { id: string; name: string; isInframe: boolean; rangeColours: string[]; rangeCarcaseColours: string[]}[];
    public selectedRange: { id: string; name: string; isInframe: boolean; rangeColours: string[]; rangeCarcaseColours: string[]};
    public selectedRangeColour: string;
    public selectedCarcaseColour: string;
    public selectedColour: string;
    public selectedBespokeColour: string;

    public bespokeColours: any[];
    public filteredBespokeColours: any[] = [];
    public searchText: string;

    public oldTotal: number = 0;
    public newTotal: number = 0;

    public allChecked: boolean = true;

    constructor(
        private basketService: BasketService,
        private catalogueService: CatalogueService,
        private dialogService: DialogService,
        private dialogRef: MatDialogRef<ChangeStyleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data && data.itemIds && data.itemIds.length) {
            this.itemIds = data.itemIds;
        }
    }

    ngOnInit() {
        if (this.itemIds && this.itemIds.length) {
            this.load();
        } else {
            this.close();
        }
    }

    private load() {
        const basketItems = this.basketService.basketItems;
        if (basketItems && basketItems.length) {
            basketItems.forEach((basketItem) => {
                if (this.itemIds.indexOf(basketItem.id) !== -1) {
                    if (!this.currentRange && basketItem.range) {
                        this.currentRange = basketItem.range;
                    }

                    if (!this.currentRangeColour && basketItem.rangeColour) {
                        this.currentRangeColour = basketItem.rangeColour;
                    }

                    if (!this.currentRangeCarcaseColour && basketItem.carcaseColour) {
                        this.currentRangeCarcaseColour = basketItem.carcaseColour;
                    }

                    if (!this.currentColour && basketItem.colour) {
                        this.currentColour = basketItem.colour;
                    }

                    if (!this.currentBespokeColour && basketItem.bespokeColour) {
                        this.currentBespokeColour = basketItem.bespokeColour;
                    }

                    this.oldTotal = this.oldTotal + basketItem.cost;

                    let basketItemClone: BasketItem = this.basketService.clone<BasketItem>(basketItem);
                    if (basketItemClone.rankParent === '0') {
                        basketItemClone.checked = true;
                    }

                    this.basketItems.push(basketItemClone);
                }
            });

            this.selectedBasketItems = this.getSelectedItems();
        }

        if (this.basketItems.length) {
            Promise.all([
                this.catalogueService.getStylesList(),
                this.catalogueService.getBespokeColours(),
                this.catalogueService.getCabinetColours()
            ])
                .then(([styles, bespokeColours, cabinetColours]) => {
                    let ranges = {};
                    if (styles && styles.length) {
                        styles.sort((a, b) => {
                            if (a.range < b.range) {
                                return -1;
                            } else if (a.range > b.range) {
                                return 1;
                            } else if (a.rangeColour < b.rangeColour) {
                                return -1;
                            } else if (a.rangeColour > b.rangeColour) {
                                return 1;
                            }

                            return 0;
                        });

                        styles.forEach((style) => {
                            if (!ranges[style.range]) {
                                ranges[style.range] = {
                                    id: style.rangeId,
                                    name: style.range,
                                    isInframe: style.isInframe,
                                    rangeColours: [],
                                    rangeCarcaseColours: []
                                };
                            }

                            ranges[style.range].rangeColours.push(style.rangeColour);

                            if (Array.isArray(cabinetColours)) {
                                ranges[style.range].rangeCarcaseColours = cabinetColours.map((carcaseDetail) => carcaseDetail.colour);
                            }
                        });
                    }

                    if (bespokeColours && bespokeColours.length) {
                        this.bespokeColours = bespokeColours;
                    }

                    this.rangeOptions = Object.values(ranges);
                })
                .catch((error) => this.dialogService.error(this.constructor.name, error));
        }
    }

    public updateAllChecked() {
        this.allChecked = this.basketItems.every((basketItem) => basketItem.checked);

        this.selectedBasketItems = this.getSelectedItems();
    }

    public someChecked(): boolean {
        return this.basketItems.filter((basketItem) => basketItem.checked).length > 0 && !this.allChecked;
    }

    public setAll(checked: boolean) {
        this.allChecked = checked;
        this.basketItems.forEach((basketItem) => {
            if (basketItem.checked === true || basketItem.checked === false) {
                basketItem.checked = checked;
            }
        });
    }

    public resetSelected() {
        this.selectedRangeColour = null;
        this.selectedCarcaseColour = null;
        this.selectedColour = null;
        this.selectedBespokeColour = null;

        this.compareBasketItems = false;
        this.basketItemsUnavailable = [];
        this.newTotal = 0;
    }

    public resetSelectedColour() {
        this.selectedColour = null;
        this.selectedBespokeColour = null;

        this.compareBasketItems = false;
        this.basketItemsUnavailable = [];
        this.newTotal = 0;
    }

    public filterBespokeColours() {
        const filterValue = this.searchText.toLowerCase();
        this.filteredBespokeColours = this.bespokeColours
            .filter((bespokeColour) => bespokeColour.code.toLowerCase().includes(filterValue));
    }

    public onBespokeColourSelected(event: MatAutocompleteSelectedEvent): void {
        const selectedName = event.option.value;
        const selectedColour = this.filteredBespokeColours.find((bespokeColour) => bespokeColour.code === selectedName);

        this.selectedColour = selectedColour.code;
        this.selectedBespokeColour = null;
    }

    public confirmColour() {
        if (this.searchText) {
            const foundBespoke = this.bespokeColours.find((bespokeColour) => bespokeColour.code === this.searchText);

            if (foundBespoke) {
                this.selectedColour = foundBespoke.code;
                this.selectedBespokeColour = null;
            } else {
                this.selectedColour = 'Special Paint Colour';
                this.selectedBespokeColour = StringHelper.titleCase(this.searchText)
            }
        } else {
            this.selectedColour = null;
            this.selectedBespokeColour = null;
        }
    }

    public isSelectionValid(): boolean {
        let valid = false;

        if (this.selectedRangeColour === 'Bespoke') {
            if (this.selectedColour && this.selectedColour.length && !this.selectedBespokeColour) {
                valid = true;
            } else if (this.selectedBespokeColour && this.selectedBespokeColour.length) {
                valid = true;
            }
        } else if (this.selectedRangeColour || this.selectedCarcaseColour) {
            valid = true;
        }

        if (
            valid &&
            this.selectedRange &&
            this.selectedRange.name === this.currentRange &&
            this.selectedRangeColour === this.currentRangeColour &&
            this.selectedCarcaseColour === this.currentRangeCarcaseColour
        ) {
            if (this.selectedRangeColour === 'Bespoke') {
                if (
                    this.selectedColour === this.currentColour &&
                    this.selectedBespokeColour === this.currentBespokeColour
                ) {
                    valid = false;
                }
            } else {
                valid = false;
            }
        }

        return valid;
    }

    public checkAvailability() {
        this.checkingAvailability = true;
        this.compareBasketItems = false;
        this.newTotal = 0;

        this.basketService.validateStyleChange(this.selectedBasketItems, {
            range: {
                id: this.selectedRange.id,
                name: this.selectedRange.name,
                isInframe: this.selectedRange.isInframe
            },
            rangeColour: this.selectedRangeColour || null,
            colour: this.selectedColour || this.selectedRangeColour,
            bespokeColour: this.selectedBespokeColour,
            carcaseColour: this.selectedCarcaseColour || null
        })
            .then((response) => {
                if (response && response.basketItems) {
                    this.basketItemsUnavailable = response.basketItemsUnavailable;
                    this.basketItemChanges = response.basketItems;

                    response.basketItems.forEach((newBasketItem) => {
                        this.newTotal = this.newTotal + (newBasketItem.cost || 0);
                    });

                    let checkedRank = null;
                    this.basketItems.forEach((basketItem) => {
                        if (!basketItem.checked && basketItem.rankParent === '0') {
                            checkedRank = basketItem.rank;
                            this.newTotal = this.newTotal + (basketItem.cost || 0);
                        } else if (basketItem.rankParent === checkedRank) {
                            this.newTotal = this.newTotal + (basketItem.cost || 0);
                        }
                    });

                    this.compareBasketItems = true;
                }

                this.checkingAvailability = false;
            })
            .catch((error) => {
                this.dialogService.error(this.constructor.name, error);
                this.checkingAvailability = false;
            });
    }

    private getSelectedItems() {
        let checkedRank = null;

        return this.basketItems.filter((basketItem) => {
            if (basketItem.checked) {
                checkedRank = basketItem.rank;

                return true;
            } else if (basketItem.rankParent === checkedRank) {
                return true;
            }

            return false;
        });
    }

    public itemStatus(item): number {
        if (item.old && item.new) {
            if (item.new.notAvailableInStyle) {
                return 9;
            } else if (item.old.cost === item.new.cost) {
                return 1;
            } else {
                return 2;
            }
        } else if (item.old) {
            return 3;
        } else if (item.new) {
            return 4;
        } else {
            return 0;
        }
    }

    public applyChanges() {
        this.checkingAvailability = true;

        let removeItemIds: number[] = [];
        let addItems: BasketItem[] = [];
        let updateItems: BasketItem[] = [];

        this.selectedBasketItems.forEach((basketItem) => {
            removeItemIds.push(basketItem.id);
        });

        this.basketItemChanges.forEach((basketItemChange) => {
            addItems.push(basketItemChange);
        });

        this.basketService.applyStyleChange(addItems, updateItems, removeItemIds)
            .then(() => this.close())
            .catch((error) => {
                this.dialogService.error(this.constructor.name, error);
                this.checkingAvailability = false;
            });
    }

    public close() {
        this.dialogRef.close();
    }
}
