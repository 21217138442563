<section>
    <div class="contained">
        <div class="all-types-page-content">
            <div class="all-types-head-content">
                <div class="img-cnt">
                    <img [src]="worktopImage" [alt]="worktopName" />
                </div>
                <div class="prod-info">
                    <div class="prod-brand-cnt">
                        <img src="https://static.diy-kitchens.com/assets/images/laminateworktops/duropal-logo.svg" alt="Duropal Logo"/>
                    </div>
                    <h2>{{worktopName}} Worktops</h2>
                    <p class="small" *ngIf="edgeProfileLink === 'square-edge-laminate'">Please note that Square Edged Worktops are <strong>NOT</strong> supplied with Edging Strips. Edging Strips will need to be ordered separately.</p>
                </div>
            </div>

            <div class="worktops-category">
                <div class="worktops-item" *ngFor="let product of products">
                    <div class="worktops-item-row">
                        <div class="worktops-item-col">
                            <div class="worktops-item-info">
                                <h3 [id]="getAnchor(sections[edgeProfile][product.name]?.title)">{{sections[edgeProfile][product.name]?.title}}</h3>
                                <p>{{sections[edgeProfile][product.name]?.description}}</p>
                            </div>
                        </div>
                        <div class="worktops-item-col">
                            <div class="worktops-item-img">
                                <img [src]="sections[edgeProfile][product.name]?.image" [alt]="product.name.title + ' image'"/>
                            </div>
                        </div>
                        <ng-container *ngFor="let item of product.items">
                            <div class="worktops-item-col">
                                <div class="worktops-details-info">
                                    <h4>{{item.product_code}}</h4>
                                    <div class="worktops-details-row">
                                        <div class="worktops-size">{{item.desc}}</div>
                                        <div class="worktops-price" [innerHTML]="item.price | currencyFormat:true:true" [promotion]="'laminate-worktops'" [request]="'product-price'" [brand]="item.brand" [productCode]="item.product_code" [item]="item" [type]="ProductType.WORKTOPS">
                                            <sub>ex</sub> VAT
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="worktops-item-col">
                                <component-basket-add-button [type]="type" [item]="item" [qty]="qty"></component-basket-add-button>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
