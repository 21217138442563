<div mat-dialog-title>
    <div class="title">
        <h1>{{ title }}</h1>
        <p class="sub-title" *ngIf="subTitle">{{ subTitle }}</p>
    </div>
    <div class="close">
        <mat-icon (click)="close()">close</mat-icon>
    </div>
</div>
<mat-dialog-content>
    <ng-container *ngIf="content && content.length">
        <p *ngIf="!html" class="small">{{content}}</p>
        <div *ngIf="html" [innerHTML]="content | sanitizeHtml"></div>
    </ng-container>

    <mat-form-field class="form-field" appearance="outline"
        *ngIf="(inputType === dialogTypes.TEXT) || (multiMode.indexOf(dialogTypes.TEXT) !== -1)">
        <mat-label>{{ inputLabel }}</mat-label>
        <input [attr.maxLength]="(inputMaxLength) ? inputMaxLength : null" matInput [formControl]="inputControl" (keydown)="optionalFormat($event)">
    </mat-form-field>

    <mat-form-field class="form-field"
        *ngIf="(inputType === dialogTypes.DATE) || (multiMode.indexOf(dialogTypes.DATE) !== -1)">
        <mat-label>{{ inputLabel }}</mat-label>
        <input matInput [formControl]="dateInputControl" [matDatepicker]="picker" autocomplete="off"
            placeholder="Select Date" (click)="picker.open()" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="form-field" appearance="outline"
        *ngIf="(inputType === dialogTypes.TEXTAREA) || (multiMode.indexOf(dialogTypes.TEXTAREA) !== -1)">
        <mat-label>{{ inputLabel }}</mat-label>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="6"
            [formControl]="textAreaControl"></textarea>
    </mat-form-field>

    <mat-form-field class="form-field" appearance="outline"
        *ngIf="(inputType === dialogTypes.OPTIONS) || (multiMode.indexOf(dialogTypes.OPTIONS) !== -1)">
        <mat-label>{{ optionsLabel || inputLabel }}</mat-label>
        <mat-select [(ngModel)]="selectedOption" placeholder="selectPlaceHolcer">
            <mat-option [value]="option.value" *ngFor="let option of selectOptions">
                {{ option.text }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions *ngIf="buttons">
    <ng-container *ngFor="let button of buttons">
        <button mat-stroked-button [color]="button.colour || 'primary'" *ngIf="button.type === 'stroked'" class="skinny" mat-icon (click)="action(button.action)">
            {{ button.label || '' }}
            <mat-icon *ngIf="button.icon">{{ button.icon.off || button.icon }}</mat-icon>
        </button>
        <button mat-raised-button [color]="button.colour || 'primary'" *ngIf="button.type === 'raised'" class="skinny" mat-icon (click)="action(button.action)">
            {{ button.label || '' }}
            <mat-icon *ngIf="button.icon">{{ button.icon.off || button.icon }}</mat-icon>
        </button>
        <button mat-flat-button [color]="button.colour || 'primary'" *ngIf="!button.type || button.type === 'flat'" class="skinny" mat-icon (click)="action(button.action)">
            {{ button.label || '' }}
            <mat-icon *ngIf="button.icon">{{ button.icon.off || button.icon }}</mat-icon>
        </button>
    </ng-container>
</mat-dialog-actions>