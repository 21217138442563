@import '/src/theme/variables';

page-unit-detail {
    section {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-areas:
            'productImage    productDetails'
            'productSpecs    productDetails'
            'productSpecs    extraInfo';
        gap: 20px;

        p {
            margin: 0;
            padding: 0;
            font-size: 1rem;
        }

        h1 {
            margin: 0;
            padding: 0;
            font-size: 1.6rem;
            line-height: 1.6rem;
            font-weight: 600;
            text-transform: uppercase;
        }

        h2 {
            margin: 0;
            padding: 0;
            font-size: 1.2rem;
            line-height: 1.2rem;
            font-weight: 600;
            text-transform: uppercase;
        }

        .image {
            grid-area: productImage;
            display: inline-block;
	        position: relative;
            padding-top: 20px;
            text-align: center;

            img:not(.offer-overlay) {
                max-width: 80%;

                &.flip {
                    transform: scaleX(-1);
                }
            }

        }

        .details {
            grid-area: productDetails;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .widths {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
                gap: 5px;

                button {
                    padding: 15px 0;
                    border: 1px solid #999;
                    border-radius: 4px;
                    background-color: color('white');
                    min-width: 60px;
                    font-weight: 600;
                    letter-spacing: 0.05rem;
                    cursor: pointer;

                    &.selected {
                        padding-bottom: 12px;
                        border-bottom: 4px solid color('brand-primary');
                    }
                }
            }

            .variants {
                display: flex;
                gap: 5px;

                button {
                    padding: 15px 0;
                    border: 1px solid #999;
                    border-radius: 4px;
                    background-color: color('white');
                    min-width: 120px;
                    font-weight: 600;
                    letter-spacing: 0.05rem;
                    cursor: pointer;

                    &.selected {
                        padding-bottom: 12px;
                        border-bottom: 4px solid color('brand-primary');
                    }
                }
            }

            .configuration {
                padding: 20px 0;

                .config-items {
                    display: flex;
                    flex-direction: column;

                    .item {
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        grid-template-areas:
                            'title  descriptor  descriptor  priceOrConfig';
                        padding: 10px 0;
                        border-bottom: 1px solid #999;

                        &>* {
                            margin: auto 0;
                        }

                        button {
                            min-width: 100px;
                            max-width: 100px;
                        }

                        label {
                            grid-area: title;
                            font-size: 1rem;
                            font-weight: 600;
                            text-transform: uppercase;
                        }

                        .attribute {
                            grid-area: descriptor;
                            grid-column: span 2;
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            .swatch {
                                width: 100%;
                                max-width: 20%;
                                height: 50px;
                            }

                            button {
                                margin-right: 10px;
                                margin-left: auto;
                            }
                        }

                        .price-or-config {
                            grid-area: priceOrConfig;
                            min-width: 150px;
                            text-align: right;
                        }

                        .options {
                            display: flex;

                            .option {
                                display: flex;
                                align-items: center;
                                min-width: 150px;

                                mat-icon {
                                    color: color('brand-primary');
                                    font-size: 3rem;
                                    width: 3rem;
                                    height: 3rem;
                                }
                            }
                        }

                        img {
                            max-width: 20%;
                        }
                    }
                }
            }

            .price {
                display: flex;
                align-items: center;
                grid-gap: 10px;
            }

            .basket-actions {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }

        .specs {
            grid-area: productSpecs;

            section {
                display: flex;
                flex-direction: column;

                em {
                    display: block;
                    padding: 10px 0;
                    font-style: normal;
                    font-weight: bold;
                    letter-spacing: 0.1em;
                }

                p {
                    margin: 0;
                    padding: 5px 0;
                }

                label {
                    display: inline-block;
                    width: 190px;
                    font-weight: 600;
                }

                .sub-section {
                    margin-bottom: 5px;
                    border-bottom: 1px solid color('black', 0.06);

                    &:last-child {
                        border-bottom: 0;
                    }
                }

                img {
                    max-width: 70%;
                }
            }
        }

        .extra-info {
            grid-area: extraInfo;
            padding-bottom: 30px;

            h4 {
                font-size: 1.1rem;
            }

            p {
                line-height: 1.3rem;
            }

            button {
                padding: 7px;
                border: 1px solid #999;
                width: 100%;
            }
        }

        @media only screen and (max-width: 992px) {
            .details {
                .configuration {
                    .config-items {
                        .item {
                            grid-template-columns: 1fr;
                            gap: 10px;
                            grid-template-areas:
                                'title       title'
                                'descriptor  priceOrConfig';

                            .attribute {
                                grid-column: span 1;
                            }
                        }
                    }
                }
            }

            .specs {
                section {
                    p {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }

        @media only screen and (max-width: 768px) {
            grid-template-columns: 1fr;
            grid-template-areas:
                'productImage'
                'productDetails'
                'productSpecs'
                'extraInfo';

            .image {
                img {
                    max-width: 50%;
                }
            }
        }

        @media only screen and (max-width: 480px) {
            .details {
                .basket-actions {
                    flex-direction: column;
                }

                .configuration {
                    .config-items {
                        .item {
                            grid-template-areas:
                                'title'
                                'descriptor'
                                'priceOrConfig';
                        }
                    }
                }
            }
        }
    }
}
