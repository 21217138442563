import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { UnitsRouting } from './units.routing';
import { UnitsPage } from './units.page';
import { UnitListingPage } from './listing/unit-listing.page';
import { UnitDetailPage } from './detail/unit-detail.page';
import { GalleryComponent } from './listing/components/gallery/gallery.component';
import { OptionBarComponent } from './listing/components/option-bar/option-bar.component';

@NgModule({
    imports: [
        SharedModule,
        UnitsRouting
    ],
    declarations: [
        UnitsPage,
        UnitListingPage,
        UnitDetailPage,
        GalleryComponent,
        OptionBarComponent
    ]
})
export class UnitsPageModule { }
