import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { SinksAndTapsRouting } from './sinks-and-taps.routing';
import { SinksAndTapsPage } from './sinks-and-taps.page';
import { SinksAndTapsListingPage } from './listing/sinks-and-taps-listing.page';
import { SinksAndTapsDetailPage } from './detail/sinks-and-taps-detail.page';

@NgModule({
    imports: [
        SharedModule,
        SinksAndTapsRouting
    ],
    declarations: [
        SinksAndTapsPage,
        SinksAndTapsListingPage,
        SinksAndTapsDetailPage
    ]
})
export class SinksAndTapsPageModule { }
