import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { forkJoin, of, Subject } from 'rxjs';
import { takeUntil, filter, first, switchMap } from 'rxjs/operators';

import { AuthService } from '@app/services/auth';
import { CatalogueService } from '@app/services/catalogue';
import { MarketingService } from '@app/services/marketing';


export const ApiDataResolver: ResolveFn<void> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    authService: AuthService = inject(AuthService),
    catalogueService: CatalogueService = inject(CatalogueService),
    marketingService: MarketingService = inject(MarketingService)
): Promise<void> => {
    return new Promise((resolve, reject) => {
        const ready = new Subject<boolean>();

        forkJoin({
            authReady: authService.ready.pipe(
                filter((isReady) => isReady === true),
                first()
            ),
            catalogueLoaded: of(catalogueService.getCatalogue()).pipe(switchMap((promise) => promise)),
            activeRangeLoaded: catalogueService.activeRangeLoaded$.pipe(
                filter((isLoaded) => isLoaded === true),
                first()
            ),
            promotionHeadersLoaded: of(marketingService.getActivePromotionHeaders()).pipe(switchMap((promise) => promise)),
            fullPromotionsLoaded: of(marketingService.getActiveFullPromotions()).pipe(switchMap((promise) => promise)),
            extendedDetails: of(catalogueService.getProductExtendedDetails()).pipe(switchMap((promise) => promise))
        })
            .pipe(takeUntil(ready))
            .subscribe({
                next: () => {
                    ready.next(true);
                    resolve();
                },
                error: (err) => {
                    console.error('Error in loading', err);
                    ready.next(true);
                    resolve();
                }
            });
    });
};
