<header class="content-contained content-header-background" [ngClass]="headerCssClass">
    <h1>{{categoryDisplay()}}</h1>
    <ng-container *ngIf="category === 'components'">
        <p>If you require a few extra components for your kitchen such as legs, plinth moisture barriers & hinges, then these can all be ordered below.</p>
    </ng-container>
    <ng-container *ngIf="category === 'cutlery-inserts'">
        <p>We have solid wood and plastic cutlery tray inserts available in a multitude of sizes, ranging from 400mm to 1000mm in width.</p>
    </ng-container>
    <ng-container *ngIf="category === 'drawer-boxes'">
        <p>If you want to adapt an existing unit and require a drawer box to do this then we have a choice of over 20 Blum drawer boxes below.</p>
    </ng-container>
    <ng-container *ngIf="category === 'glass-shelves'">
        <p>If you want to add those finishing touches to your kitchen and create even more of a wow factor then some glass shelves to adorn your wall units, can do just that!</p>
    </ng-container>
    <ng-container *ngIf="category === 'lighting'">
        <p>Some well-placed unit lighting, can illuminate the insides of any dark cupboards and help you to see and access the contents inside. If you are wanting a little more light, to illuminate your worktops whilst preparing food then some under unit lighting would be a great investment.</p>
    </ng-container>
    <ng-container *ngIf="category === 'waste-systems'">
        <p>If you don’t have space in your kitchen for a freestanding bin or quite frankly, you don’t like the sight of them then why not integrate a waste bin inside a unit, so that it is completely out of sight!</p>
    </ng-container>
    <ng-container *ngIf="category === 'worktops'">
        <p>Keep worktops in top condition with Danish Oil, Hotrods and other accessories.</p>
    </ng-container>
    <ng-container *ngIf="category === 'sinks'">
        <p>A selection of accessories from plumbing packs to colanders for your sink.</p>
    </ng-container>
    <ng-container *ngIf="category === 'unit-accessories'">
        <p>When designing your kitchen, you may require a few additional unit accessories, such as spare shelves, angled corner posts or even glazed doors to create a display feature in your kitchen. All these unit accessories can be found below.</p>
    </ng-container>
</header>
<section class="lightgrey">
    <div class="contained flex-space-between">
        <div>
            <component-filters [items]="items" [filters]="filterFields" [sectionCats]="sectionCats" [section]="category"
                [sectionURL]="'accessories'" (filterChange)="updateItems($event)" sortOrder="low-high">
            </component-filters>
        </div>
        <div class="products" *ngIf="items">
            <div class="banners">
                <div class="panel">
                    <div class="panel-left">
                        <a routerLink="/about-diy-kitchens/price-and-quality-comparison" title="Price & Quality Comparison">
                            <img src="https://static.diy-kitchens.com/assets/images/banner/listings-banner-trustpilot.jpg" width="100%" alt="Trustpilot Best rated national kitchen retailer."/>
                        </a>
                    </div>
                    <div class="panel-right">
                        <a routerLink="/customer-reviews/shaker" title="Customer Reviews">
                            <img src="https://static.diy-kitchens.com/assets/images/banner/offer-banner_real-customers_oct2020.jpg" width="100%" alt="Real customer kitchen videos. Take a look."/>
                        </a>
                    </div>
                </div>
            </div>
            <component-product-listing *ngFor="let entry of filteredItems()"
                [type]="type" [route]="route" [products]="products" [product]="entry">
            </component-product-listing>

            <div *ngIf="noResults" class="no-results">
                <em class="common-em">No items were found that match your filters...</em>
                Try removing a filter.
            </div>
        </div>
    </div>
</section>
