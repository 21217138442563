import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';

import { HomeRouting } from './home.routing';
import { HomePage } from './home.page';

@NgModule({
    imports: [
        SharedModule,
        HomeRouting
    ],
    declarations: [
        HomePage
    ]
})
export class HomePageModule { }
