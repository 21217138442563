<header class="content-header-corner content-header-background" *ngIf="header" [ngClass]="header.imageClass">
    <header class="content-contained">
        <h1>{{header.name}} {{header.colour}} Doors</h1>
        <p>{{header.description}}</p>
    </header>
</header>

<hr class="narrow" />

<section class="content-contained">
    <div class="kitchen-container">
        <div class="filters" *ngIf="filters">
            <div class="filter filter-range-type">
                <h4>Door Style</h4>
                <div class="filter-options">
                    <div class="filter-option" [ngClass]="{ selected: filterOption.id === filters.rangeType.selected }" *ngFor="let filterOption of filters.rangeType.options">
                        <a [routerLink]="filterOption.link">
                            <img class="filter-image" [src]="filterOption.image" [alt]="filterOption.name + ' filter image'"/>
                            <span>{{filterOption.name}}</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="filter filter-colour">
                <h4>Door Colour</h4>
                <div class="filter-options">
                    <div class="filter-option" [ngClass]="{ selected: filterOption.id === filters.colour.selected }" *ngFor="let filterOption of filters.colour.options">
                        <a [routerLink]="filterOption.link" [matTooltip]="filterOption.name" [title]="filterOption.name">
                            <div class="swatch" [ngClass]="'swatch-' + filterOption.image"></div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="filteredRanges" class="kitchens">
            <div class="banners">
                <div class="panel">
                    <div class="panel-left">
                        <a routerLink="/about-diy-kitchens/price-and-quality-comparison" title="Price & Quality Comparison">
                            <img src="https://static.diy-kitchens.com/assets/images/banner/listings-banner-trustpilot.jpg" width="100%" alt="Trustpilot Best rated national kitchen retailer."/>
                        </a>
                    </div>
                    <div class="panel-right">
                        <a routerLink="/customer-reviews/shaker" class="video-outer" title="Customer Reviews">
                            <img src="https://static.diy-kitchens.com/assets/images/banner/offer-banner_real-customers_oct2020.jpg" width="100%" alt="Real customer kitchen videos. Take a look."/>
                        </a>
                    </div>
                </div>

                <hr class="narrow" />

                <div class="banner">
                    <img src="https://static.diy-kitchens.com/assets/images/samples/free-sample_listings.jpg" width="100%" alt="Free samples. Get 3 free door samples straight to your home. £6 delivery charge. Must be delivered to same address."/>
                </div>
            </div>

            <div class="kitchen" *ngFor="let range of filteredRanges" [id]="getId(range.name)">
                <div class="kitchen-header">
                    <div class="header-left">
                        <h2>{{range.name}}</h2>
                        <h3>{{range.selectedColour.name}}</h3>
                    </div>
                    <div class="header-right">
                        <div class="kitchen-door">
                            <img [src]="getRangeDoorImage(range)" class="kitchendoor" width="100%" [alt]="range.name" />
                        </div>
                    </div>
                </div>

                <div class="range-image" [promotion]="'door-listing'" [request]="'listing-overlay-image'" [rangeName]="range.name" [colourName]="range.selectedColour.name">
                    <img class="range-image" [cdn]="getRangeImage(range)" width="100%" [alt]="range.name + ' ' + range.selectedColour.name + ' image'"/>
                </div>

                <div class="common-swatches">
                    <button *ngFor="let colour of range.colours" (click)="switchRangeColour(range, colour)"
                        class="button-link"
                        [ngClass]="{ active: range.selectedColour.name === colour.name }"
                        [matTooltip]="colour.name" matTooltipPosition="above" matTooltipClass="tooltip">
                        <div class="swatch swatch-{{colour.css_class}}">
                            <mat-icon class="selected-colour">done</mat-icon>
                        </div>
                    </button>
                </div>

                <div class="kitchen-information">
                    <p>Door style: <strong>{{range.filters.join(', ')}}</strong> </p>
                    <p [innerHTML]="range.range_detailed_description"></p>
                    <p class="bold">{{range.door_material}}, {{range.door_thickness}}mm thick doors</p>
                </div>
                <div class="actions">
                    <div class="action">
                        <a routerLink="/samples/door-samples" title="Door Samples">
                            <button mat-stroked-button class="detailButton" (click)="selectRange(range)">
                                Free Sample
                            </button>
                        </a>
                    </div>
                    <div class="action">
                        <a [routerLink]="rangeLink(range)" title="Buy Doors">
                            <button mat-flat-button (click)="selectRange(range)" class="chunky" color="primary">
                                Buy Doors
                            </button>
                        </a>
                    </div>
                </div>
            </div>

            <div *ngIf="filteredRanges && !filteredRanges.length" class="no-results">
                <em>No Styles were found that match your filters.</em>
                Try removing a filter.
            </div>
        </div>
    </div>
</section>

<component-latest-offers></component-latest-offers>
