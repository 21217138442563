@import '/src/theme/variables';

page-about-our-partners {
    .partner-logo {
        margin-bottom: 48px;
    }

    .button-wrapper {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 15px 30px;
        margin-top: 48px;
    }

    @media (max-width: 992px) {
        .partner-logo {
            margin-top: 32px;
        }
    }
}