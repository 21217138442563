@import '/src/theme/variables';

component-product-listing {
    display: block;
    width: 100%;

    .product {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        margin: 15px 0 0 40px;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
        background-color: color('white');

        .left {
            flex: 0 0 auto;
            padding: 30px;
            border-right: 0.5px solid color('brand_lightgrey');
            width: 380px;
            position: relative;

            @media screen and (min-width: 1200px) and (max-width: 1280px) {
                width: 240px;
            }

            @include responsive-breakpoint-between(md, xl){
                width: 140px;
            }

            @include responsive-breakpoint-down(md){
                width: 100%;
            }

            .image {
                display: inline-block;
	            position: relative;
                margin: 0 auto;

                a {
                    display: block;
                    width: 100%;
                }

                img {
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 32px;
                    max-width: 100%;
                    max-height: 300px;

                    &.gray {
                        -webkit-filter: grayscale(100%); // Safari 6.0 - 9.0
                        filter: grayscale(100%);
                    }

                    &.flip {
                        transform: scaleX(-1);
                    }
                }
            }
        }
        

        .details {
            flex-grow: 1;
            padding: 30px;
            width: 100%;

            .price {
                display: flex;
                flex-direction: column;
                float: right;
                margin-left: 40px;
                text-align: right;

                .currency {
                    font-size: 1.4rem;
                    font-weight: 600;
                }

                p {
                    font-size: 12px;
                    line-height: 19px;
                    text-transform: uppercase;
                }
            }

            .title {
                margin-bottom: 5px;
                font-size: 16px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 0.03em;
                text-align: left;

                @media screen and (min-width: 1200px) and (max-width: 1280px) {
                    max-width: 60%;
                }

                @include responsive-breakpoint-between(lg, xl){
                    max-width: 54%;
                }

                @include responsive-breakpoint-down(md){
                    max-width: 50%;
                    font-size: 14px;
                }
            }

            p {
                font-size: 16px;
                font-weight: 300;
                line-height: 32px;
                letter-spacing: 0.48px;
                text-align: left;
            }

            .small {
                font-size: 12px;
                line-height: 19px;
                letter-spacing: 3px;
                text-align: left;
                text-transform: uppercase;
            }

            .brand-logo {
                margin-bottom: 20px;

                img {
                    max-height: 40px;
                }
            }

            .desc {
                line-height: 26px;
            }

            .dims {
                font-weight: 600;
                line-height: 1.5rem;

                label {
                    flex: 0 0 auto;
                    margin: 0;
                    width: 110px;
                    color: color('pale-sky');
                    font-weight: 400;
                }
            }

            .config {
                display: flex;
                flex-direction: row;
                justify-items: flex-start;
                margin: 30px 0;

                button {
                    margin: 0 10px 0 0;
                    padding: 8px 15px;
                    border: 1px solid rgba(0, 0, 0, 0.12);
                    border-radius: 20px;
                    background-color: color('white');
                    color: color('black');
                    font-size: 0.8rem;
                    letter-spacing: 0.05rem;
                    cursor: pointer;

                    &.left-hand {
                        margin-right: -1px;
                        border-radius: 20px 0 0 20px;
                    }

                    &.right-hand {
                        margin-left: 0;
                        border-radius: 0 20px 20px 0;
                    }

                    &.selected {
                        padding-bottom: 4px;
                        border-bottom: 4px solid color('brand-primary');
                    }

                    &.right {
                        margin-right: 0;
                        margin-left: auto;
                    }
                }
            }

            .widths,
            .variants {
                button {
                    margin: 5px 5px 5px 0;
                    padding: 5px 10px;
                    min-width: 48px;
                    font-weight: 600;
                    letter-spacing: 0.05rem;

                    &.selected {
                        padding-bottom: 2px;
                        border-bottom: 4px solid color('brand-primary');
                    }
                }
            }

            .variants {
                button {
                    text-align: left;
                }
            }
        }

        .actions {
            display: grid;
            gap: 10px;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            margin-top: 30px;

            a {
                width: 100%;
            }

            .qty-and-add-button {
                display: flex;
                gap: 10px;

                .quantity {
                    display: inline-block;
                    margin-left: 10px;
                    width: 70px;
                    font-size: 1.125rem;
                    
                    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
                        height: 52px;
                    }                    
                }
            }

            
        }
    }

    .warranty-logo {
        margin-top: 10px;
        margin-left: auto;
        max-width: 65px;
        max-height: 65px;
    }

    .offers {
        display: flex;
        margin-bottom: 15px;

        // .offer {
        //     & + .offer {
        //         margin-left: 15px;
        //     }

        //     &:hover {
        //         p {
        //             text-decoration: underline;
        //         }
        //     }

        //     p {
        //         display: flex;
        //         align-items: center;
        //         margin: 0;
        //         padding: 5px 10px;
        //         border-radius: 5px;
        //         color: white;
        //         font-weight: 400;
        //     }
        // }
    }

    @media only screen and (max-width: 1000px) {
        .product {
            flex-wrap: nowrap;
            margin: 15px 0;
            max-width: 100%;

            .details {
                width: auto;
                min-width: 300px;
            }
        }
    }

    @media only screen and (max-width: 800px) {
        .product {
            flex-wrap: wrap;
            justify-content: center;
            margin: 15px 0;

            .left {
                padding: 20px 20px 0;
                border: 0;
                text-align: center;

                .image {
                    img {
                        margin-bottom: 0;
                    }
                }
            }

            .details {
                width: 100%;

                .price {
                    .currency {
                        font-size: 1.2rem;

                        @include responsive-breakpoint-down(md){
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
}
