<section *ngIf="busy" class="loader">
    <mat-spinner></mat-spinner>
</section>

<mat-drawer-container class="my-container basket-drawer" [hasBackdrop]="true">
    <mat-drawer #drawer class="drawer" mode="over" position="end">
        <component-basket-history [refreshBasketHistory]="loadBasketHistory" [basketId]="basket?.id" [cutOffDate]="basket?.cutOffDate"></component-basket-history>
    </mat-drawer>
    <mat-drawer-content>
        <section class="whitebg">
            <div id="basket-print-logo">
                <img src="https://static.diy-kitchens.com/assets/images/homepage/diy-kitchens-logo.jpg"
                    alt="DIY Kitchens - Discover Kitchens Online" title="DIY Kitchens - Discover Kitchens Online"
                    width="262" height="62">
            </div>
            <div *ngIf="basket && validated">
                <div class="basket-title-outer">
                    <div class="contained basket-header">
                        <div class="basket-title">
                            <ng-container *ngIf="!basket.orderNumber">
                                <h1 class="small" *ngIf="!editingBasketName">
                                    {{basket.name}} <span *ngIf="basket.id" class="basket-id">({{ basket.id }})</span><mat-icon class="hide-printing" (click)="editBasketName()">edit</mat-icon>
                                </h1>

                                <ng-container *ngIf="editingBasketName">
                                    <mat-form-field>
                                        <input matInput placeholder="Basket Name" [value]="basket.name" #basketName />
                                    </mat-form-field>
                                    <button mat-icon-button (click)="editBasketName(null)">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <button mat-icon-button (click)="editBasketName(basketName.value)">
                                        <mat-icon>done</mat-icon>
                                    </button>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="basket.orderNumber">
                                <h2>Editing Order - {{basket.orderNumber}}</h2>
                            </ng-container>
                        </div>
                        <div class="view-baskets">
                            <button mat-stroked-button color="secondary" (click)="viewBaskets()">
                                <mat-icon>list</mat-icon>
                                Manage Baskets
                            </button>
                        </div>
                    </div>
                </div>

                <div class="contained">
                    <div class="basket-actions" id="section-to-hide-print">
                        <button mat-stroked-button color="secondary" class="chunky-dark" *ngIf="basket.items.length > 0" (click)="printBasket()">
                            <ng-container *ngIf="!basket.orderNumber">Print Basket</ng-container>
                            <ng-container *ngIf="basket.orderNumber">Print Order</ng-container>
                        </button>
                        <a *ngIf="(basket.items.length > 0) && !basket.orderNumber" class="chunky-dark" routerLink="/kitchen-units" title="Kitchen Units">
                            <button mat-stroked-button color="secondary">Continue Shopping</button>
                        </a>

                        <ng-container *ngIf="isStaff()">
                            <button mat-stroked-button color="secondary" class="chunky-dark" (click)="createQuote()">
                                <ng-container>Create Quote</ng-container>
                            </button>
                        </ng-container>

                        <ng-container *ngIf="isLoggedIn()">
                            <button mat-stroked-button color="secondary" class="chunky-dark" (click)="showBasketHistory()">
                                <ng-container *ngIf="!basket.orderNumber">Basket History</ng-container>
                                <ng-container *ngIf="basket.orderNumber">Order History</ng-container>
                            </button>
                        </ng-container>
                    </div>

                    <ng-container *ngIf="basket.orderNumber">
                        <div class="important-notice-section">
                            <mat-icon class="hide-printing">info_outline</mat-icon>
                            <div class="imp-notice-content">
                                <h3>You are currently editing your order.</h3>
                                <p>
                                    Please ensure you check out again to confirm any changes or click 'Discard' changes if you no longer need to edit this order.
                                </p>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="basket.cutOffDate && basket.cutOffDate > now">
                        <div class="important-notice-section">
                            <mat-icon class="hide-printing">info_outline</mat-icon>
                            <div class="imp-notice-content">
                                <h3>Your order will soon be locked.</h3>
                                <p>
                                    Your order is still within the 48 hour grace period. Please ensure your order is
                                    correct.
                                </p>
                                <p>
                                    Once the grace period is over, any amendments you wish to make may be regarded
                                    as a
                                    new and separate order with different delivery times and additional delivery
                                    charges.
                                </p>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="basket.cutOffDate && basket.cutOffDate < now">
                        <div class="important-notice-section">
                            <mat-icon class="hide-printing">info_outline</mat-icon>
                            <div class="imp-notice-content">
                                <h3>Your order has been locked.</h3>
                                <p>
                                    Your order is outside of the 48 hour grace period.
                                </p>
                                <p>
                                    Once the grace period is over, any amendments you wish to make may be regarded
                                    as a new and separate order with different delivery times and additional delivery
                                    charges.
                                </p>
                            </div>
                        </div>
                    </ng-container>

                    <div *ngIf="basket.items.length">
                        <div class="item-details-header">
                            <div class="item-details-header-col">ITEM DETAILS</div>
                            <div class="item-details-header-col">QUANTITY</div>
                            <div class="item-details-header-col">TOTAL</div>
                        </div>
                        <div *ngFor="let section of sections">
                            <div class="section-header">
                                <img *ngIf="section.image" [src]="'https://static.diy-kitchens.com/doors/' + section.image" width="80px" [alt]="section.title + ' image'"/>
                                <!-- <div class="section-title" [sticky]="true"> -->
                                <div class="section-title">
                                    <h5>{{ section.title }}</h5>
                                </div>
                                <button mat-stroked-button color="secondary" *ngIf="section.title !== 'Non Range Products'" (click)="changeStyle(section)" id="hide-printing" [disabled]="reachedCutOff">
                                    Change Style
                                </button>
                            </div>
                            <div class="basket-item-details" *ngFor="let subSection of section.subSections">
                                <!-- <p *ngIf="section.title != 'Non Range Products' && subSection.summary">
                                    {{ subSection.summary }}
                                </p> -->

                                <mat-card>
                                    <mat-card-content>
                                        <div class="group-description">
                                            <!-- <span class="description" [sticky]="true" [sticky-offset]="60"> -->
                                            <span class="description">
                                                {{ subSection.category }}
                                            </span>
                                        </div>
                                        <ng-container *ngFor="let item of subSection.items">
                                            <div *ngIf="item._children" class="line-item">
                                                <!-- TODO: Make this a template -->
                                                <!-- Parent Item -->
                                                <div class="sub-section-description" [ngClass]="{ alerts: (item.notAvailableInStyle || !item.notAvailableInStyle && item.notAvailable), 'to-be-deleted': item.orderItemToBeDeleted }">
                                                    <span class="rank">
                                                        {{ item.rank }}
                                                    </span>
                                                    <span class="flex description">
                                                        {{ item.description }} 
                                                        <span class="alert-message" *ngIf="item.notAvailableInStyle"> {{getItemNotAvailableInStyleMessage(item)}} </span>
                                                        <span class="alert-message" *ngIf="!item.notAvailableInStyle && item.notAvailable"> {{getItemNotAvailableMessage(item)}} </span>
                                                    </span>
                                                    <span class="item-total" *ngIf="item.total || (item.isPromotion && item.total === 0)">
                                                        <span class="item-total-text">Item Total:</span> <span class="item-total-cost" [innerHTML]="item.total | currencyFormat:true:true"></span>
                                                    </span>
                                                </div>
                                                <div class="sub-section-drawer-upgrade" *ngIf="item._cabinet?.panDrawerQty > 0 || item._cabinet?.drawerBoxQty > 0" [ngClass]="{ 'to-be-deleted': item.orderItemToBeDeleted }">
                                                    <div class="sub-section-drawer-upgrade-text">
                                                        <p class="drawer-upgrade-text" *ngIf="item._cabinet?.panDrawerQty > 0 && panDrawerUpgrade === PanDrawerUpgrade.NONE">Upgrade your pan drawers to high sided steel or frosted glass sides from just <span [innerHTML]="PanDrawerUpgradeCost.HIGH_SIDES | currencyFormat:true:true"></span></p>
                                                        <p class="drawer-upgrade-text" *ngIf="item._cabinet?.panDrawerQty > 0 && panDrawerUpgrade === PanDrawerUpgrade.HIGH_SIDES">We will fit High Sided sides to your pan drawers for only <span [innerHTML]="PanDrawerUpgradeCost.HIGH_SIDES * item._cabinet?.panDrawerQty | currencyFormat:true:true"></span></p>
                                                        <p class="drawer-upgrade-text" *ngIf="item._cabinet?.panDrawerQty > 0 && panDrawerUpgrade === PanDrawerUpgrade.FROSTED_SIDES">We will fit Glass sides to your pan drawers for only <span [innerHTML]="PanDrawerUpgradeCost.FROSTED_SIDES * item._cabinet?.panDrawerQty | currencyFormat:true:true"></span></p>
                                                        <p class="drawer-upgrade-text" *ngIf="item._cabinet?.drawerBoxQty > 0 && drawerBoxUpgrade === DrawerBoxUpgrade.NONE">Upgrade now to Dovetail drawer boxes for only <span [innerHTML]="DrawerBoxUpgradeCost.DOVETAILS * item._cabinet?.drawerBoxQty | currencyFormat:true:true"></span></p>
                                                        <p class="drawer-upgrade-text" *ngIf="item._cabinet?.drawerBoxQty > 0 && drawerBoxUpgrade === DrawerBoxUpgrade.DOVETAILS">We will fit Dovetail drawer boxes for only <span [innerHTML]="DrawerBoxUpgradeCost.DOVETAILS * item._cabinet?.drawerBoxQty | currencyFormat:true:true"></span></p>
                                                    </div>
                                                    <button mat-stroked-button color="secondary" class="skinny" (click)="drawerUpgrade()" [disabled]="reachedCutOff">More Details</button>
                                                </div>
                                                <div class="sub-section" [ngClass]="{ 'to-be-deleted': item.orderItemToBeDeleted }">
                                                    <div class="sub-section-image">
                                                        <img *ngIf="item.group !== ProductType.SURCHARGES && item.group !== ProductType.DISCOUNTS" [ngClass]="{ 
                                                            flip: (
                                                                (item.handing === Handing.LH && item.handingType !== HandingType.DRAWER) || 
                                                                (item.handing === Handing.RH && item.handingType === HandingType.DRAWER)
                                                            )}" [safeSrc]="item.currentImage || item.media?.image" [alt]="item.description + ' image'" [item]="item"/>
                                                        <mat-icon *ngIf="item.group === ProductType.SURCHARGES">add_shopping_cart</mat-icon>
                                                        <mat-icon *ngIf="item.group === ProductType.DISCOUNTS">remove_shopping_cart</mat-icon>
                                                    </div>
                                                    <div class="sub-section-details">
                                                        <table>
                                                            <tr *ngIf="item.group === ProductType.RANGE_PRODUCTS">
                                                                <th>Range:</th>
                                                                <td>{{ rangeNames[item.rangeId] }} {{ item.rangeColour }}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Code:</th>
                                                                <td>{{ item.code }}</td>
                                                            </tr>
                                                            <tr *ngIf="item.group === 'Cabinets' || isCarcaseMaterial(item)">
                                                                <th>Width:</th>
                                                                <td>
                                                                    {{ item.width }}mm
                                                                    @if (isCarcaseMaterial(item)) {
                                                                        <button mat-stroked-button color="secondary" class="skinny" (click)="changeCarcaseMaterial(item)" [disabled]="reachedCutOff">CHANGE</button>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="item.group === 'Cabinets' || isCarcaseMaterial(item)">
                                                                <th>Height:</th>
                                                                <td>
                                                                    {{ item.height }}mm
                                                                    @if (isCarcaseMaterial(item)) {
                                                                        <button mat-stroked-button color="secondary" class="skinny" (click)="changeCarcaseMaterial(item)" [disabled]="reachedCutOff">CHANGE</button>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="isCarcaseColoured(item) && item.group !== ProductType.CABINETS">
                                                                <th>Colour:</th>
                                                                <td>
                                                                    {{ item.carcaseColour }}
                                                                    <button mat-stroked-button color="secondary" class="skinny" (click)="changeCarcaseMaterial(item)" [disabled]="reachedCutOff">CHANGE</button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Item Price:</th>
                                                                <td>{{ itemPrice(item) | currencyFormat }}</td>
                                                            </tr>
                                                            <tr *ngIf="canShowDetail('cabinet', item)">
                                                                <th>Cabinet:</th>
                                                                <td>
                                                                    <span>{{ item.carcaseColour }}</span>
                                                                    <button mat-stroked-button color="secondary" class="skinny" (click)="changeCabinetOptions(item)" [disabled]="reachedCutOff">CHANGE</button>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="canShowDetail('hingeSide', item) && item.handed">
                                                                <th>{{hingeWording(item)}}:</th>
                                                                <td>
                                                                    <span>{{item.handing === Handing.RH ? 'Right' : 'Left' }}</span>
                                                                    <button mat-stroked-button color="secondary" class="skinny" (click)="changeCabinetOptions(item)" [disabled]="reachedCutOff">CHANGE</button>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="canShowDetail('softClose', item) && item.hingeCost">
                                                                <th>Soft Close:</th>
                                                                <td>
                                                                    <span>{{ toYesNo(item.softClose) }}</span>
                                                                    <button mat-stroked-button color="secondary" class="skinny" (click)="changeCabinetOptions(item)" [disabled]="reachedCutOff">CHANGE</button>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="canShowDetail('softClose', item) && item.hingeCost && item.softClose">
                                                                <th>Soft Close Price</th>
                                                                <td>
                                                                    <span>{{ item.hingeCost | currencyFormat }}</span>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="canShowDetail('dryAssembled', item) && item.width > 600 && item.depth > 600 && !item.dryAssembledAlways">
                                                                <th>Dry Assembled:</th>
                                                                <td>
                                                                    <span>{{ toYesNo(item.isDryAssembled) }}</span>
                                                                    <button mat-stroked-button color="secondary" class="skinny" (click)="changeCabinetOptions(item)" [disabled]="reachedCutOff">CHANGE</button>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="quantity">
                                                        <div class="qty">
                                                            <p>Quantity: </p>
                                                            <mat-form-field class="qty-input" *ngIf="allowQtyEdit(item); else qtyLabel">
                                                                <input matInput placeholder="Qty" [ngModel]="item.qty" (ngModelChange)="updateQty($event, item)" (blur)="updateQty(null, item)" />
                                                            </mat-form-field>
                                                            <ng-template #qtyLabel>
                                                                <p>{{ item.qty }}</p>
                                                            </ng-template>
                                                        </div>
                                                        <div class="qty" *ngIf="item._cabinet?.drawerBoxQty > 0">
                                                            <p>Drawer Boxes: {{ item._cabinet?.drawerBoxQty }}</p>
                                                        </div>
                                                        <div class="qty" *ngIf="item._cabinet?.panDrawerQty > 0">
                                                            <p>Pan Drawers: {{ item._cabinet?.panDrawerQty }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="sub-section-cost">
                                                        <div class="sub-section-field offer-container" *ngIf="canShowIndividualPrices(item)">
                                                            <ng-container *ngIf="item.discountPercentage || item.promotionPercentage">
                                                                <div>
                                                                    <span class="strike-through" [innerHTML]="(item.cost * (item.qty || 1)) | currencyFormat:true:true"></span>
                                                                </div>
                                                                <div class="offer">
                                                                    <span>OFFER PRICE </span>
                                                                    <span [innerHTML]="(item.salePrice * (item.qty || 1)) | currencyFormat:true:true"></span>
                                                                </div>
                                                                <div>
                                                                    <span>YOU SAVED </span>
                                                                    <span [innerHTML]="((item.cost - item.salePrice) * (item.qty || 1)) | currencyFormat:true:true"></span>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="!item.discountPercentage && !item.promotionPercentage">
                                                                <span [innerHTML]="(item.cost * (item.qty || 1)) | currencyFormat:true:true"></span>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Item Children -->
                                                <div class="line-item-children" [ngClass]="{ 'to-be-deleted': item.orderItemToBeDeleted }">
                                                    <!-- Drawer Box Upgrades -->
                                                    <div class="line-item-child" *ngIf="item.drawerBoxQty > 0 && drawerBoxUpgrade === DrawerBoxUpgrade.DOVETAILS">
                                                        <div class="sub-section-description">
                                                            <span class="rank">{{item.rank}}.0</span>
                                                            <span class="flex description">
                                                                Dovetail drawer box upgrade
                                                            </span>
                                                            <span class="link">
                                                                LINKED TO ITEM {{item.rank}} <mat-icon>link</mat-icon>
                                                            </span>
                                                        </div>
                                                        <div class="sub-section"  [ngClass]="isSubItem(item) ? 'has-parent' : ''">
                                                            <div class="sub-section-image">
                                                                <img cdn="images/misc/drawer_box-dovetail.jpg" width="100%" alt="Dovetail Drawer Box" />
                                                            </div>
                                                            <div class="sub-section-details">
                                                                <table>
                                                                    <tr>
                                                                        <th>Code:</th>
                                                                        <td>DOVETAIL</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Item Price:</th>
                                                                        <td>{{DrawerBoxUpgradeCost.DOVETAILS | currencyFormat}}</td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                            <div class="quantity">
                                                                <div class="qty">
                                                                    <p>Quantity: {{ item.drawerBoxQty }}</p>
                                                                </div>
                                                            </div>
                                                            <div class="sub-section-cost">
                                                                <div class="sub-section-field">
                                                                    <span>INCLUDED</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Child Items -->
                                                    <div class="line-item-child" *ngFor="let childItem of item._children">
                                                        <div class="sub-section-description" [ngClass]="{ alerts: (item.notAvailableInStyle || !item.notAvailableInStyle && item.notAvailable) }">
                                                            <span class="rank">
                                                                {{ childItem.rank }}
                                                            </span>
                                                            <span class="flex description">
                                                                {{ childItem.description }}
                                                                
                                                                <span class="alert-message" *ngIf="childItem.notAvailableInStyle"> {{getItemNotAvailableInStyleMessage(childItem)}} </span>
                                                                <span class="alert-message" *ngIf="!childItem.notAvailableInStyle && childItem.notAvailable"> {{getItemNotAvailableMessage(childItem)}} </span>
                                                            </span>
                                                            <span class="link">
                                                                LINKED TO ITEM {{childItem.rankParent}} <mat-icon>link</mat-icon>
                                                            </span>
                                                        </div>
                                                        <div class="sub-section"  [ngClass]="isSubItem(item) ? 'has-parent' : ''">
                                                            <div class="sub-section-image">
                                                                <img *ngIf="childItem.group !== ProductType.SURCHARGES && childItem.group !== ProductType.DISCOUNTS" [safeSrc]="childItem.currentImage || childItem.media?.image" [alt]="childItem.description + ' image'" [item]="childItem"/>
                                                                <mat-icon *ngIf="childItem.group === ProductType.SURCHARGES">add_shopping_cart</mat-icon>
                                                                <mat-icon *ngIf="childItem.group === ProductType.DISCOUNTS">remove_shopping_cart</mat-icon>
                                                            </div>
                                                            <div class="sub-section-details">
                                                                <table>
                                                                    <tr>
                                                                        <th>Code:</th>
                                                                        <td>{{ childItem.code }}</td>
                                                                    </tr>
                                                                    <tr *ngIf="(childItem.cost || childItem.cab_price || 0) > 0">
                                                                        <th>Item Price:</th>
                                                                        <td>{{ (childItem.cost || childItem.cab_price || 0) | currencyFormat }}</td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                            <div class="quantity">
                                                                <div class="qty">
                                                                    <p>Quantity: {{ childItem.qty }}</p>
                                                                </div>
                                                                <div class="qty" *ngIf="childItem._cabinet?.drawerBoxQty > 0">
                                                                    <p>Drawer Boxes: {{ childItem._cabinet?.drawerBoxQty }}</p>
                                                                </div>
                                                                <div class="qty" *ngIf="childItem._cabinet?.panDrawerQty > 0">
                                                                    <p>Pan Drawers: {{ childItem._cabinet?.panDrawerQty }}</p>
                                                                </div>
                                                            </div>
                                                            <div class="sub-section-cost">
                                                                <ng-container *ngIf="(childItem.cost || childItem.cab_price || 0) > 0">
                                                                    <div class="sub-section-field offer-container" *ngIf="canShowIndividualPrices(item)">
                                                                        <ng-container *ngIf="childItem.discountPercentage || childItem.promotionPercentage">
                                                                            <div>
                                                                                <span class="strike-through" [innerHTML]="(childItem.cost * (childItem.qty || 1)) | currencyFormat:true:true"></span>
                                                                            </div>
                                                                            <div class="offer">
                                                                                <span>OFFER PRICE </span>
                                                                                <span [innerHTML]="(childItem.salePrice * (childItem.qty || 1)) | currencyFormat:true:true"></span>
                                                                            </div>
                                                                            <div>
                                                                                <span>YOU SAVED </span>
                                                                                <span [innerHTML]="((childItem.cost - childItem.salePrice) * (childItem.qty || 1)) | currencyFormat:true:true"></span>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="!childItem.discountPercentage && !childItem.promotionPercentage">
                                                                            <span [innerHTML]="(childItem.cost * (childItem.qty || 1)) | currencyFormat:true:true"></span>
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="item-actions">
                                                    <div class="notes">
                                                        <ng-container *ngIf="item.notes && item.notes.length">
                                                            <h5>Notes</h5>
                                                            <div *ngFor="let note of item.notes" class="note">
                                                                <div class="note-remove" *ngIf="!isStaffNote(note)">
                                                                    <button mat-icon-button (click)="deleteNote(note)">
                                                                        <mat-icon>delete</mat-icon>
                                                                    </button>
                                                                </div>
                                                                <p class="small">{{ note.note }}</p>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <div *ngIf="isDrawerUpgrade(item)" class="item-action hide-printing">
                                                        <button mat-stroked-button color="secondary" (click)="drawerUpgrade()" [disabled]="reachedCutOff">
                                                            <mat-icon>edit</mat-icon>
                                                            Change Upgrade
                                                        </button>
                                                    </div>
                                                    <div *ngIf="canEditItem(item)" class="item-action hide-printing">
                                                        @if (!item.orderItemToBeDeleted) {
                                                            <button mat-stroked-button color="secondary" *ngIf="item.worktopConfigUuid" [routerLink]="'/solid-surfaces/config/viewer/' + item.worktopConfigUuid">
                                                                <mat-icon>visibility</mat-icon>
                                                                View
                                                            </button>
                                                            <button mat-stroked-button color="secondary" *ngIf="item.worktopConfigUuid && !reachedCutOff" [routerLink]="'/solid-surfaces/config/edit/' + item.worktopConfigUuid">
                                                                <mat-icon>edit</mat-icon>
                                                                Edit
                                                            </button>
                                                            <button mat-stroked-button color="secondary" (click)="addNote(item)" [disabled]="reachedCutOff">
                                                                <mat-icon>speaker_notes</mat-icon> Add Note
                                                            </button>
                                                            <button mat-stroked-button color="secondary" *ngIf="canDuplicateItem(item)" [disabled]="reachedCutOff || item.isPromotion === 1" (click)="duplicateItem(item)">
                                                                <mat-icon>content_copy</mat-icon> Duplicate {{(item._sectionCategory === 'Units') ? 'Unit' : ''}}
                                                            </button>
                                                            <button mat-stroked-button class="delete" *ngIf="canRemoveItem(item)" (click)="removeItem(item)" [disabled]="reachedCutOff || item.isPromotion === 1">
                                                                <mat-icon>delete_forever</mat-icon>Delete {{(item._sectionCategory === 'Units') ? 'Unit' : ''}}
                                                            </button>
                                                        } @else {
                                                            This item will be removed from the order when you confirm the changes at the bottom of this page.
                                                            <button mat-stroked-button color="secondary" *ngIf="canRemoveItem(item)" (click)="undoRemovedOrderItem(item)" [disabled]="reachedCutOff || item.isPromotion === 1">
                                                                <mat-icon>undo</mat-icon>Undo Delete
                                                            </button>
                                                        }                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </mat-card-content>
                                </mat-card>
                                <br /><br />
                            </div>
                        </div>

                        <div *ngIf="basketHasAppliances()" class="pay-ess-banner-section">
                            <a routerLink="/appliances" title="Shop Appliances">
                                <div class="pay-less-banner-main pay-less-banner">
                                    <img
                                        src="https://static.diy-kitchens.com/assets/images/banner/Banner_Basket_Pay-Less-Appliances.png" alt="Pay less for your appliances. It's a simple message, but we believe in honest value for money. That's why all our AEG and CDA appliances won't be beaten on price by anyone, including Wren, AO, Howdens and Argos."/>
                                </div>
                                <div class="pay-less-banner-main pay-less-banner-mobile">
                                    <img
                                        src="https://static.diy-kitchens.com/assets/images/banner/Banner_Basket_Pay-Less-Appliances_Mobile.jpg" alt="Pay less for your appliances. We believe in honest value for money. That's why all our appliances won't be beaten on price by anyone, including Wren, AO, Howdens and Argos."/>
                                </div>
                            </a>
                            <div class="pay-less-banner-main pay-less-banner">
                                <h5>Appliance Delivery</h5>
                                <p>
                                    Your appliances will arrive on a separate delivery, directly from our appliance partners AEG, CDA and Electrolux. 
                                </p>
                                <img src="https://static.diy-kitchens.com/assets/images/banner/AppliancePartners_191224.jpg" alt="Your appliances will arrive on a separate delivery, directly from our appliance partners AEG and CDA." />
                            </div>
                        </div>
                        <div class="important-notice-section" *ngIf="!basket.orderNumber && !checkBasketForErrors(BasketError.CUTOFF_DATE_REACHED)">
                            <mat-icon class="hide-printing">info_outline</mat-icon>
                            <div class="imp-notice-content">
                                <h3>Important notice</h3>
                                <p>Before proceeding please double check the quantities on your cart items are correct
                                    and if
                                    applicable please check each kitchen unit hinge side configuration is also correct.
                                </p>
                            </div>
                        </div>
                        
                        <!-- Warnings -->
                        <div class="warnings-container" *ngIf="activeBasketWarnings()">
                            <ng-container *ngFor="let warning of basketWarnings; let isLast = last">
                                <div class="warning" [class.hidden]="warning.isDismissed" *ngIf="warning.shouldDisplay()" [id]="warning.id">
                                    <div class="warning-icon">
                                        <img *ngIf="warning.image" [src]="warning.image" alt="Warning" />
                                    </div>
                                    <div class="warning-body">
                                        <div class="warning-title">
                                            <h3>{{ warning.title }}</h3>
                                        </div>
                                        <div class="warning-label">
                                            <p>{{ warning.label }}</p>
                                            <button mat-flat-button class="chunky" color="primary" (click)="dismissWarning(warning)">Dismiss</button>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider *ngIf="!warning.isDismissed && warning.shouldDisplay() && !isLast"></mat-divider>
                            </ng-container>
                        </div>
                        
                        <div class="total-outer">
                            <div class="total-inner">
                                <div class="totals" *ngIf="deliveriesLoaded">
                                    <div class="total">
                                        <label class="total-label">Sub total (ex VAT)</label>
                                        <p class="total-value">{{ getOrderValue() | currencyFormat }}</p>
                                    </div>
                                    <div class="total">
                                        <mat-label class="total-label">DELIVERY (ex VAT)</mat-label>
                                        <mat-form-field>
                                            <mat-label>{{(selectedDeliveryLevel || '') | titlecase}}</mat-label>
                                            <mat-select [(ngModel)]="selectedDeliveryLevel" (selectionChange)="selectDeliveryOption($event)" [disabled]="reachedCutOff">
                                                <mat-select-trigger>{{selectedDeliveryCost() | currencyFormat}}</mat-select-trigger>
                                                <mat-option *ngFor="let deliveryType of deliveryTypes" [value]="deliveryType.level">
                                                    {{(deliveryType.level || '') | titlecase}} - {{ deliveryType.cost | currencyFormat}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="total">
                                        <label class="total-label">VAT</label>
                                        <p class="total-value">{{ getOrderValue(true) | currencyFormat:false:false:true }}
                                        </p>
                                    </div>

                                    <div class="total total-grand">
                                        <label class="total-label">Total</label>
                                        <p class="total-value">{{ totalCost | currencyFormat:false:true }}</p>
                                    </div>

                                    <div class="total total-grand" *ngIf="costs.charged">
                                        <label class="total-label">Total Paid</label>
                                        <p class="total-value">{{ costs.charged | currencyFormat }}</p>
                                    </div>

                                    <div class="total total-grand" *ngIf="costs.outstandingAmount > 0">
                                        <label class="total-label">Outstanding Cost</label>
                                        <p class="total-value">{{ costs.outstandingAmount | currencyFormat }}</p>
                                    </div>

                                    <div class="total total-grand" *ngIf="costs.outstandingAmount < 0">
                                        <label class="total-label">Outstanding Refund</label>
                                        <p class="total-value">{{ Math.abs(costs.outstandingAmount) | currencyFormat }}</p>
                                    </div>
                                </div>

                                <div class="error-box" *ngIf="checkBasketForErrors()">
                                    <h4 *ngIf="!basket.orderNumber">Issue with your shopping basket</h4>
                                    <h4 *ngIf="basket.orderNumber">Issue with your order</h4>
                                    <!-- <div *ngIf="checkBasketForErrors(BasketError.NO_UNIT_IN_ORDER)">
                                        <p><strong>Please note:</strong> We don't accept door, appliance, worktop, sink, tap or handle only orders.</p>
                                        <p>If this order is an addition to an existing full kitchen order, please enter your existing order details below to proceed.</p>
                                    </div> -->

                                    <div *ngIf="checkBasketForErrors(BasketError.CONTAINS_OUT_OF_RANGE_ITEM)">
                                        <p><strong>Please note:</strong> Some of your items are unavailable in specific range</p>
                                    </div>

                                    <div *ngIf="checkBasketForErrors(BasketError.CONTAINS_UNAVAILABLE_ITEM)">
                                        <p><strong>Please note:</strong> Some of your items have been discontinued</p>
                                    </div>

                                    <div *ngIf="checkBasketForErrors(BasketError.CUTOFF_DATE_REACHED)">
                                        <p><strong>Please note:</strong> The cutoff period for the order has already passed. If you need something
                                            changed on your order, please contact customer support.</p>
                                    </div>

                                    <div *ngIf="checkBasketForErrors(BasketError.UNACKNOWELDGED_WARNINGS)">
                                        <p><strong>Please note:</strong> Your {{(!basket.orderNumber) ? 'basket' : 'order'}} has warnings that require your attention. 
                                            Please read and dismiss any warnings above before proceeding.</p>
                                    </div>

                                    <ng-container *ngIf="!isLoggedIn()">
                                        <div *ngIf="checkBasketForErrors(BasketError.MINIMUM_ORDER_AMOUNT_NOT_REACHED)">
                                            <p><strong>Please note:</strong> A minimum order value of {{ minOrderValue | currencyFormat }} inc. VAT is required to checkout this order, unless this is an addition to an existing order. This cost excludes the delivery fee.
                                                Please ensure the order value reaches the minimum amount or alternatively, please <a class="link-style" (click)="login()" title="Sign In">sign in</a> to your account.
                                            </p>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="isLoggedIn()">
                                        <div *ngIf="checkBasketForErrors(BasketError.MINIMUM_ORDER_AMOUNT_NOT_REACHED)">
                                            <p><strong>Please note:</strong> A minimum order value of {{ minOrderValue | currencyFormat }} inc. VAT is required to checkout this order. This cost excludes the delivery fee.
                                            Please ensure the order value reaches the minimum amount in order to proceed to checkout.
                                        </p>
                                        </div>
                                    </ng-container>

                                </div>

                                <div class="actions" id="section-to-hide-print1">
                                    <button mat-stroked-button class="chunky" routerLink="/" *ngIf="!basket.orderNumber">
                                        Continue Shopping
                                    </button>
                                    <button mat-stroked-button class="delete" *ngIf="basket.orderNumber" (click)="cancelOrderChanges()">
                                        Discard Changes
                                    </button>
                                    <button mat-flat-button class="chunky"
                                        [disabled]="checkBasketForErrors()"
                                        [ngClass]="{ 'spinner': checkOutBasket || busy, 'disabled': checkBasketForErrors() }" (click)="proceedToCheckout()"
                                        color="primary">
                                        {{ checkoutMessage() }}
                                    </button>
                                </div>

                                <!-- <div class="alerts"> -->
                                    <!-- <p *ngIf="disabledMessage()">
                                        {{ disabledMessage() }}
                                    </p> -->
                                    <!-- <p *ngIf="!isLoggedIn()">
                                        Please sign in to checkout your order
                                    </p>
                                    <p *ngIf="!selectedDeliveryOption">
                                        Please select the delivery option.
                                    </p> -->
                                    <!-- <p *ngIf="disabledCheckoutIsItemsNotInStyle()">
                                        (Units unavaiable in specific range)
                                    </p> -->
                                    <!-- <p *ngIf="isAdmin">
                                        Checkout is disable for an admin user. Once editing complete notify the user to
                                        update
                                        the order
                                    </p> -->
                                    <!-- <p *ngIf="!basket.canEdit">
                                        The cutoff period for the order has already passed. If you need something
                                        changed on
                                        your order, please contact customer support.
                                    </p> -->
                                    <!-- <p *ngIf="noUnitInOrder()">
                                        We don't accept door, appliance, worktop, sink, tap or handle only orders.
                                    </p>                                     -->
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!basket.items.length">
                        <h5>Your basket contains no items</h5>
                    </div>
                </div>
            </div>
        </section>
    </mat-drawer-content>
</mat-drawer-container>
