<div class="customer-registration-container">
    <form class="registration-form" [formGroup]="registrationFormGroup" (keydown.enter)="authenticate()">
        <div class="customer-registration-form">
            <h3>Create New Customer</h3>

            <div class="details">
                <mat-form-field>
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="firstName" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="lastName" />
                </mat-form-field>
            </div>

            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Contact Number</mat-label>
                <input matInput formControlName="contactNumber" />
            </mat-form-field>
            <div class="error-message" *ngIf="registrationFormGroup.controls.contactNumber?.errors?.phoneNumberValid === false">
                Please enter a valid UK contact number. i.e. 07XX XXX XXXX
            </div>
        </div>

        <div class="customer-registration-terms">
            <p *ngIf="errorMessage" class="error-message">{{errorMessage}}</p>

            <button mat-raised-button type="submit" color="primary" [disabled]="authenticating" (click)="authenticate()">
                Next
            </button>
        </div>
    </form>
</div>