import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';

import { AboutDeliveryInformationRouting } from './delivery-information.routing';
import { AboutDeliveryInformationPage } from './delivery-information.page';

@NgModule({
    imports: [
        SharedModule,
        AboutDeliveryInformationRouting
    ],
    declarations: [
        AboutDeliveryInformationPage
    ]
})
export class AboutDeliveryInformationPageModule { }
