import { NgModule } from '@angular/core';

import { ArticlesService } from './articles.service';

@NgModule({
    providers: [
        ArticlesService
    ]
})
export class ArticlesServiceModule { }
